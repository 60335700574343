import type { MailboxInfo } from 'owa-client-types';
import { default as folderStore } from 'owa-folders';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export function isPrimaryAccountFromMailboxInfo(mailboxInfo?: MailboxInfo): boolean {
    // default to primary account in case mailboxInfo is null or undefined
    const key = getIndexerValueForMailboxInfo(mailboxInfo ?? getGlobalSettingsAccountMailboxInfo());
    return key === getIndexerValueForMailboxInfo(getGlobalSettingsAccountMailboxInfo());
}

export function isPrimaryAccountFromFolderId(folderId: string): boolean {
    const folder = folderStore.folderTable.get(folderId);

    return isPrimaryAccountFromMailboxInfo(folder?.mailboxInfo);
}
