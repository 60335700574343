import { lazyGetRibbonModeMenuSections } from 'owa-mail-ribbon-sections/lib/sections';

import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onRibbonModeMenuClicked = () => {
    lazyGetRibbonModeMenuSections.import().then(getRibbonMenuOptionsSections => {
        setMenuDefinition(
            'RibbonModeOptionsMenuDefinitionStore',
            undefined /* projectionTabId */,
            () => {
                return {
                    sections: getRibbonMenuOptionsSections(),
                };
            }
        );
    });
};
