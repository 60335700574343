import type { SearchTableQuery } from 'owa-mail-list-search';
import { getAppliedRefiners } from 'owa-search-refiners';
import SearchScenarioId from 'owa-search-store/lib/store/schema/SearchScenarioId';

export default function isSearchRefinerApplied(searchTableQuery?: SearchTableQuery) {
    return (
        getAppliedRefiners(SearchScenarioId.Mail).length > 0 ||
        (!!searchTableQuery?.viewFilter && searchTableQuery?.viewFilter !== 'All')
    );
}
