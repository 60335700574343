import { logUsage } from 'owa-analytics';
import { getLoggableToken } from './getLoggableToken';

export function logAttachmentDownloadToken(downloadToken: string | undefined, isFirst: boolean) {
    const loggableToken = getLoggableToken(downloadToken);
    logUsage('AttachmentDownloadToken', {
        Token: loggableToken,
        IsFirst: isFirst,
    });
}
