import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getApplicationSettings } from 'owa-application-settings';
import { getGuid } from 'owa-guid';
import { getAccessTokenforResourceAsLazy } from 'owa-tokenprovider';

type TokenRequestDictionary = {
    [resource: string]: Promise<string> | (() => Promise<string>);
};

async function fetchShellToken(apiName: string, resource: string): Promise<string> {
    let [token, tokenPromise] = getAccessTokenforResourceAsLazy(
        resource,
        apiName /* apiName */,
        getGuid() /* requestId */,
        undefined /* targetTenantId */,
        undefined /* wwwAuthenticateHeader */,
        undefined /* showFullTokenResponse */,
        undefined /* preferIdpToken */,
        getGlobalSettingsAccountMailboxInfo() /*mailbox info*/
    );
    if (!token) {
        token = (await tokenPromise) as string;
    }

    if (!!token) {
        if (typeof token === 'object' && token?.accessToken) {
            return token.accessToken;
        }
        if (typeof token === 'string') {
            return token;
        }
    }

    return '';
}

export const fetchTokenForShell: () => TokenRequestDictionary = () => {
    const resourceApiNames = {
        // The resource is needed for pinning apps to and unpinning apps from the App Launcher.
        webshell: 'fetchWebShellForShell',
        // The resource is needed for getting the profile picture of Me Control.
        graph: 'fetchGraphForShell',
        // The resource is needed for getting MRU document list in the App Launcher.
        office: 'fetchOfficeApiForShell',
    };

    const resourceUrls = {
        webshell: getApplicationSettings('PreAuthShellResourceUrls').webShellResourceUrl,
        graph: getApplicationSettings('PreAuthShellResourceUrls').graphResourceUrl,
        office: getApplicationSettings('PreAuthShellResourceUrls').officeApiResourceUrl,
    };

    return {
        [resourceUrls.webshell]: () => {
            return fetchShellToken(resourceApiNames.webshell, resourceUrls.webshell);
        },
        [resourceUrls.graph]: () => {
            return fetchShellToken(resourceApiNames.graph, resourceUrls.graph);
        },
        [resourceUrls.office]: () => {
            return fetchShellToken(resourceApiNames.office, resourceUrls.office);
        },
    };
};
