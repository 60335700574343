import type { SearchResultPerson, SearchPerson } from '../../data/schema/SubstrateSearchResponse';

const convertFirstCharAndAfterSpaceToUpper = (text: string) => {
    text = text.toLowerCase();
    const words = text.split(' ');
    for (let i = 0; i < words.length; i++) {
        if (words[i].length > 0) {
            words[i] = words[i][0].toUpperCase() + words[i].slice(1);
        }
    }
    text = words.join(' ');
    return text;
};

export default function convertSearchResultPersonToSearchPersonType(
    SearchResultPerson: SearchResultPerson
): SearchPerson {
    return {
        ItemId: SearchResultPerson.Id,
        name: SearchResultPerson.DisplayName,
        officeLocation: SearchResultPerson.OfficeLocation ?? '',
        role: SearchResultPerson?.JobTitle
            ? convertFirstCharAndAfterSpaceToUpper(SearchResultPerson.JobTitle)
            : '',
        email: SearchResultPerson.EmailAddresses[0]?.toLowerCase(),
        InstanceKey: SearchResultPerson.ReferenceId,
    };
}
