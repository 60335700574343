import { mutatorAction } from 'satcheljs';
import getStore from '../store/store';
import type { OcpsStoreState } from '../store/schema/OcpsPolicyStore';

export const setOcpsPolicyStoreState = mutatorAction(
    'setOcpsPolicyStoreState',
    (newState: OcpsStoreState) => {
        getStore().state = newState;
    }
);
