import { areDisplayAdsShown } from 'owa-mail-ads-checker';
import { getOwaWorkload, type OwaWorkload } from 'owa-workloads';

export function isPanePinningEnabled(): boolean {
    return (
        // feature is currently restricted to Mail app, but this may change in the future
        getOwaWorkload() === 1 &&
        // feature is currently restricted to sessions where display ads are disabled, so as to avoid potential revenue disruption
        !areDisplayAdsShown()
    );
}
