import getNonBootUserConfiguration from '../getNonBootUserConfiguration';
import type { MailboxInfo } from 'owa-client-types';

export default async function isSearchFoldersMigrationComplete(
    mailboxInfo: MailboxInfo
): Promise<boolean> {
    const { IsSearchFoldersMigrationComplete = false } =
        (await getNonBootUserConfiguration(mailboxInfo, true /* skipAnonymousCheck */)) || {};
    return IsSearchFoldersMigrationComplete;
}
