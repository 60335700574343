import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';

/**
 * This file contains the controls that have special cases when scaling (both initial scaling and
 * regular scaling)
 */

/**
 * Add the controls here that should never display their labels
 */
export const controlsWithoutLabels: RibbonControlId[] = [7016];

/**
 * Add the controls here that should NOT have their labels dropped when scaling
 * Note: These controls are ones that start off with labels. For controls that never
 * have labels, please place in controlsWithoutLabels section
 */
export const controlsDontDropLabels: RibbonControlId[] = [588, 552, 550];

/**
 * Add the controls here that should NOT move to the overflow when scaling
 */
export const controlsDontMoveToOverflow: RibbonControlId[] = [7016];

export const controlsFirstMoveToOverflow: RibbonControlId[] = [];
