import brandSubscriptionStore from '../store/Store';
import type BrandSubscriptionModel from '../store/schema/BrandSubscriptionModel';

function getSubscriptionFromCache(smtpAddress?: string): BrandSubscriptionModel | undefined {
    if (smtpAddress && brandSubscriptionStore.subscriptions.has(smtpAddress.toLowerCase())) {
        return brandSubscriptionStore.subscriptions.get(smtpAddress.toLowerCase());
    }

    return undefined;
}

export { getSubscriptionFromCache };
