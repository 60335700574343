import { checkUniqueByStrictEquality } from './checkUniqueByStrictEquality';

/**
 * Creates a clone of the array containing only unique values.
 *
 * Consumers should define their own `isUniqueValue` predicate if not comparing by strict equality.
 *
 * Useful for ensuring that a selected calendars list only contains unique values
 */
export function dedupeArrayValues<T>(
    originalArr: T[],
    isUniqueValue: (arr: T[], value: T) => boolean = checkUniqueByStrictEquality
): T[] {
    // handle nil case
    if (!originalArr) {
        return [];
    }

    // clone a de-duped array
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS7034 (23,11): Variable 'dedupeArr' implicitly has type 'any[]' in some locations where its type cannot be determined.
    // @ts-expect-error
    const dedupeArr = [];
    if (originalArr) {
        originalArr.forEach(val => {
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS7005 (29,31): Variable 'dedupeArr' implicitly has an 'any[]' type.
            // @ts-expect-error
            if (isUniqueValue(dedupeArr, val)) {
                dedupeArr.push(val);
            }
        });
    }

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS7005 (38,12): Variable 'dedupeArr' implicitly has an 'any[]' type.
    // @ts-expect-error
    return dedupeArr;
}
