import { mutatorAction } from 'satcheljs';
import getMailFavoritesViewState from '../selectors/getMailFavoritesViewState';
import store from '../store/store';

export default mutatorAction(
    'showFindFavoritesPicker',
    function showFindFavoritesPicker(accountKey: string, shouldShow: boolean) {
        const mailFavoritesViewState = getMailFavoritesViewState(accountKey);
        if (mailFavoritesViewState) {
            mailFavoritesViewState.shouldShowFindFavoritesPicker = shouldShow;
        } else {
            store.mailFavoritesViewState.set(accountKey, {
                contextMenuState: null,
                shouldShowFindFavoritesPicker: shouldShow,
            });
        }
    }
);
