import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ImmersiveReaderStore" */ './lazyIndex')
);

export const lazyShowInImmersiveReader = new LazyAction(lazyModule, m => m.showInImmersiveReader);

// Non-delayed loaded exports
export { default as store, getStore } from './store/Store';
