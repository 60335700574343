import type { PartialTheme } from '@fluentui/theme';
import type { DensityModeString } from 'owa-fabric-theme';
import { owaComputedFn } from 'owa-computed-fn';

export const getInboxPivotTheme = owaComputedFn(
    (densityModeString: DensityModeString | undefined): PartialTheme =>
        densityModeString == 'compact'
            ? inboxPivotTheme('12px', '-4px', '0px')
            : densityModeString == 'medium'
            ? inboxPivotTheme('14px', '-1px', '5px')
            : densityModeString == 'full'
            ? inboxPivotTheme('16px', '6px', '5px')
            : {}
);

function inboxPivotTheme(fontSize: string, textMarginBottom: string, rootMarginTop: string) {
    return {
        components: {
            Pivot: {
                styles: {
                    text: {
                        fontSize,
                        marginBottom: textMarginBottom,
                    },
                    root: {
                        marginTop: rootMarginTop,
                    },
                },
            },
        },
    };
}
