import type { BaseTheme } from '@1js/suiteux-shell';
import { getHeaderImageDataFull } from './getHeaderImageDataFull';

export default function getHeaderImageData(
    themeId?: string,
    isDarkTheme?: boolean
): Readonly<Pick<BaseTheme, 'BackgroundImageUrl' | 'BackgroundImageRepeat'>> {
    const { primary, repeat } = getHeaderImageDataFull(themeId, isDarkTheme);

    return {
        BackgroundImageUrl: primary,
        BackgroundImageRepeat: repeat,
    };
}
