import { addCoprincipalAccountToStore } from 'owa-account-source-list/lib/utils/sourceList';
import { enableAccountStartupCheck } from 'owa-account-source-list/lib/utils/accountStartupCheck';
import { setPreAccountLoadLookupHelpers } from 'owa-account-source-list/lib/utils/initializeLookupHelpers';
import type { CoprincipalAccount } from 'owa-account-storage-types';
import { CoprincipalAccount_Version1 } from 'owa-account-storage-types';
import {
    getGlobalSettingsAccount,
    isAccountSourceListStoreInitialized,
} from 'owa-account-source-list-store';
import { AccountSourceType } from 'owa-account-source-list-types';
import { getPersistenceIdForDefaultAccount } from './getPersistenceIdForDefaultAccount';
import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';

/**
 * Monarch multiple accounts introduces support for the owa-account-source-list-store, when not
 * in Monarch we initialize the store with the information in the default user configuration.
 */
export function initOwaAccountSourceListStore(userConfiguration: OwaUserConfiguration): string {
    if (isAccountSourceListStoreInitialized()) {
        // The account source list store is already initialized we do not need to
        // add the default account to the source list store.
        return getGlobalSettingsAccount().sourceId;
    }

    setPreAccountLoadLookupHelpers();

    // Enable the check to make sure the data set during startup is as expected
    enableAccountStartupCheck();

    const persistenceId = getPersistenceIdForDefaultAccount(userConfiguration);
    const settings = userConfiguration.SessionSettings;
    const emailAddress = settings?.UserEmailAddress ?? '';
    const userIdentity = settings?.LogonEmailAddress ?? '';
    const aliases = settings?.UserProxyAddresses ?? [];

    let accountType = AccountSourceType.OutlookDotCom;
    if (settings?.WebSessionType === 0) {
        accountType = AccountSourceType.Office365;
    } else if (settings?.WebSessionType === 2) {
        accountType = AccountSourceType.Google;
    }

    const account: CoprincipalAccount = {
        uuid: persistenceId,
        version: CoprincipalAccount_Version1,
        accountType,
        emailAddress,
        userIdentity,
        aliases,
    };

    return addCoprincipalAccountToStore(account);
}
