import { OwsOptionsFeatureType, PinnablePane } from 'owa-outlook-service-option-store';
import { isPanePinningEnabled } from './isPanePinningEnabled';
import { tryAutoOpen } from './tryAutoOpen';

/**
 * Set up pinned pane on app boot, by referencing Prime boot settings returned in app startup data
 */
export function initializeSuiteHeaderPinnedPane(primeBootSettingsOptions: any[]): void {
    const pinnedPane = isPanePinningEnabled()
        ? getPinnedPaneSettingFromBootSettings(primeBootSettingsOptions)
        : PinnablePane.None;

    tryAutoOpen(pinnedPane);
}

function getPinnedPaneSettingFromBootSettings(primeBootSettingsOptions: any[]): PinnablePane {
    const primeCommandingOptions: any[] = primeBootSettingsOptions.filter(
        item => item?.feature == OwsOptionsFeatureType.PinnedPane
    );

    return primeCommandingOptions[0]?.pinnedPane ?? PinnablePane.None;
}
