import { lazyGetConversationsSections } from 'owa-mail-ribbon-sections/lib/sections';

import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onConversationsMenuClicked = () => {
    lazyGetConversationsSections.import().then(getConversationsSections => {
        setMenuDefinition(
            'ConversationsMenuDefinitionStore',
            undefined /* projectionTabId */,
            () => {
                return {
                    sections: getConversationsSections(),
                };
            }
        );
    });
};
