import { createStore } from 'satcheljs';
import type { AppHostStore } from './schema/AppHostStore';
import { ObservableMap } from 'mobx';

const store = createStore<AppHostStore>('AppHostStore', {
    hostedApp: undefined,
    selectedEntityId: '',
    selectedSubEntityId: '',
    subEntityLabel: '',
    isInError: false,
    sharePointSiteInfo: new ObservableMap(),
    userClickTime: 0,
})();
export default () => store;
