import React from 'react';
import { touchBehavior, type TouchState, type TouchThreshold } from 'touch-behavior';
import type MailSwipeAction from 'owa-service/lib/contract/MailSwipeAction';
import type Message from 'owa-service/lib/contract/Message';
import { getSwipeFromLeftAction, getSwipeFromRightAction } from 'owa-mail-list-swipe-utils';
import { getSwipeHandler } from 'owa-mail-list-item-shared';
import type { MailListItemPartProps } from 'owa-mail-list-item-shared';
import getItemForMailList from 'owa-mail-store/lib/selectors/getItemForMailList';
import { TOUCH_THRESHOLD } from '../utils/constants';
import { logSwipeAction, skipHandleTouchCallback, swipePerfMeasurer } from '../utils/helpers';
import { SwipeInProgressPlaceholder } from './SwipeInProgressPlaceholder';

export default function mailListItemPartTouchBehavior(
    MailListItemPartComponent: React.FC<MailListItemPartProps>
): React.ComponentClass<MailListItemPartProps> {
    return touchBehavior<MailListItemPartProps>({
        Component: MailListItemPartComponent,
        originalLeftSwipeRenderer: swipeRenderer(true /*isLeftSwipe*/),
        originalRightSwipeRenderer: swipeRenderer(false /*isLeftSwipe*/),
        originalGetLeftSwipeAction: getLeftSwipeAction,
        originalGetRightSwipeAction: getRightSwipeAction,
        skipHandleTouchCallback,
        threshold: TOUCH_THRESHOLD,
        notifyOnSwipe: swipePerfMeasurer,
        shouldHandleRTLScenario: false,
        renderStaticSwipeRenderer: true,
    });
}

const getLeftSwipeAction = () => (props: MailListItemPartProps) => {
    const swipeAction = getSwipeFromLeftAction();
    const { nodeId, tableViewId } = props;

    if (swipeAction !== 0) {
        logSwipeAction(
            true /*isSwipeFromLeft*/,
            swipeAction,
            nodeId,
            tableViewId,
            true /*isItemPart*/
        );
        getSwipeHandler(swipeAction, true /*isItemPart*/)?.(tableViewId, nodeId, 'Swipe');
    }
};

const getRightSwipeAction = () => (props: MailListItemPartProps) => {
    const swipeAction = getSwipeFromRightAction();
    const { nodeId, tableViewId } = props;

    if (swipeAction !== 0) {
        logSwipeAction(
            false /*isSwipeFromLeft*/,
            swipeAction,
            nodeId,
            tableViewId,
            true /*isItemPart*/
        );
        getSwipeHandler(swipeAction, true /*isItemPart*/)?.(tableViewId, nodeId, 'Swipe');
    }
};

const swipeRenderer =
    (isLeftSwipe: boolean) =>
    (props: MailListItemPartProps, state: TouchState, threshold: TouchThreshold) => {
        const { nodeId, isFirstLevelExpansion } = props;

        const item: Message | null | undefined = getItemForMailList(nodeId, isFirstLevelExpansion);
        const isUnread = item?.IsRead === false;

        return (
            <SwipeInProgressPlaceholder
                isLeftSwipe={isLeftSwipe}
                state={state}
                threshold={threshold}
                isUnread={isUnread}
            />
        );
    };
