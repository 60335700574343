import { action } from 'satcheljs';
import type { ClientItemId } from 'owa-client-ids';

const onMailServiceForwardMessage = action(
    'onMailServiceForwardMessage',
    (clientItemId: ClientItemId) => ({
        clientItemId,
    })
);

export default onMailServiceForwardMessage;
