import copilotSettingsStore from 'owa-copilot-settings-store/lib/store/store';
import { LicenseType } from 'owa-copilot-settings-store/lib/services/types/serviceSettingTypes';
import getGlobalSettingsAccountMailboxInfo from 'owa-account-source-list-store/lib/selectors/getGlobalSettingsAccountMailboxInfo';
import type { MailboxInfo } from 'owa-client-types';

export const hasM365CopilotLicense = (
    mailboxInfo: MailboxInfo = getGlobalSettingsAccountMailboxInfo()
): boolean => {
    const store = copilotSettingsStore(mailboxInfo);
    if (store.isEnabled) {
        return store.licenses.some(license => license === LicenseType.M365Copilot);
    }

    return false;
};

export default hasM365CopilotLicense;
