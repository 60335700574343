import type { M365Acquisition } from 'owa-m365-acquisitions/lib/types';

/**
 * Filters out acquisitions that were artificially created to add pinned apps.
 *
 * If there are bootstrapped titles from the server, we need to filter them out and replace with app service data.
 * since the app service is the source of truth for these titles.
 * @param acquisition
 * @returns `true` if `acquisition` was **not** added artificiously because it is a pinned app.
 */
export function filterBootstrapTitles(acquisition: M365Acquisition): boolean {
    return acquisition.titleDefinition.ingestionSource !== 'UserPinnedAcquisition';
}
