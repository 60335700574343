import type { MailboxInfo } from 'owa-client-types';
import isFeatureEnabled from './isFeatureEnabled';

export default function (
    mailboxInfo?: MailboxInfo,
    dontThrowErrorIfNotInitialized?: boolean
): boolean {
    return isFeatureEnabled(
        'fwk-useoutlookgateway-getSchedule',
        mailboxInfo,
        dontThrowErrorIfNotInitialized
    );
}
