import store from '../store/Store';
import { mutatorAction } from 'satcheljs';
import isKeyboardShortcutsDisabled from 'owa-hotkeys/lib/utils/isKeyboardShortcutsDisabled';

export default mutatorAction(
    'setIsHotkeysMapVisible',
    function setIsHotkeysMapVisible(isVisible: boolean, checkIfDisabled?: boolean) {
        if (!checkIfDisabled || !isKeyboardShortcutsDisabled()) {
            store.isVisible = isVisible;
        }
    }
);
