import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import type { ClientItem } from 'owa-mail-store';

export function isItemInFolder(item: ClientItem, folderIds: string[]) {
    const parentFolderId = item.ParentFolderId;
    if (!parentFolderId) {
        return true;
    }
    const folderId = folderIdToName(parentFolderId.Id);
    return folderIds.includes(folderId);
}
