import { checkUniqueByStrictEquality } from './checkUniqueByStrictEquality';
import type { ObservableMap } from 'mobx';

/**
 * Creates a flat list of unique values from the entries of an observable map.
 *
 * Consumers should define their own `isUniqueValue` predicate if not comparing by strict equality.
 *
 * Useful for generating a single unique list of selected calendars across all connected accounts
 */
export function flattenObservableMapValues<K, V>(
    originalMap: ObservableMap<K, V[]>,
    isUniqueValue: (arr: V[], value: V) => boolean = checkUniqueByStrictEquality
): V[] {
    // handle nil case
    if (!originalMap) {
        return [];
    }

    // iterate through map entries to build flat list
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS7034 (24,11): Variable 'flattenedValues' implicitly has type 'any[]' in some locations where its type cannot be determined.
    // @ts-expect-error
    const flattenedValues = [];
    originalMap.forEach(value => {
        if (value) {
            value.forEach(val => {
                // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                // -> Error TS7005 (31,35): Variable 'flattenedValues' implicitly has an 'any[]' type.
                // @ts-expect-error
                if (isUniqueValue(flattenedValues, val)) {
                    flattenedValues.push(val);
                }
            });
        }
    });

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS7005 (41,12): Variable 'flattenedValues' implicitly has an 'any[]' type.
    // @ts-expect-error
    return flattenedValues;
}
