import { SingleLine, Multiline, constructKeytip } from 'owa-command-ribbon';

import { type HelpRibbonTabId } from 'owa-ribbon-ids/lib/helpControlId';
import type { RibbonTabDefinitionBase } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { type CommandingViewMode } from 'owa-outlook-service-option-store/lib/store/schema/options/CommandingOptions';
import Strings from 'owa-locstrings/lib/strings/ribbon_app_buttons_and_tabs.locstring.json';
import { getStringRibbonId } from 'owa-ribbon-ids/lib/ribbonId';
import loc from 'owa-localize';
import { getIsViewModeSelected } from 'owa-command-ribbon-store';
import { owaComputedFn } from 'owa-computed-fn';

/**
 * The Help Tab is lazy-loaded, and thus on boot it doesn't appear until a few frames/seconds after
 * the boot animation finishes. Due to this, there's a short flicker where a user can see the
 * Help tab pop into existence. This placeholder SLR Help Tab is meant to help alleviate that by simply
 * rendering the tab "Help", but without any controls.
 */
export const getPlaceholderHelpTab = owaComputedFn(
    function getPlaceholderHelpTab(): RibbonTabDefinitionBase {
        return {
            id: getStringRibbonId(11000),
            type: getIsViewModeSelected(1) ? SingleLine : Multiline,
            title: loc(Strings.help_tab),
            controlGroups: {
                type: getIsViewModeSelected(1) ? SingleLine : Multiline,
                groups: [],
            },
            keytipProps: constructKeytip([], 11000, true /* hasMenu */),
        };
    }
);
