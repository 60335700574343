import type { MailboxInfo } from 'owa-client-types';
import type { FeatureName } from '../store/featureDefinitions';
import flags from '../store/Store';

type FlightsUpdatedCallback = () => void;
const callbacks: FlightsUpdatedCallback[] = [];

export default function setFlightInternal(
    feature: FeatureName,
    isOn: boolean,
    mailboxInfo?: MailboxInfo
) {
    flags(mailboxInfo).featureFlags.set(feature.toLowerCase(), isOn);

    for (const cb of callbacks) {
        cb();
    }
}

export function registerFlightUpdatedCallback(cb: FlightsUpdatedCallback) {
    callbacks.push(cb);
}
