import { type ActionSource, type ClientItem, type ClientMessage } from 'owa-mail-store';
import {
    getSelectedTableView,
    shouldSuppressServerMarkReadOnReplyOrForward,
} from 'owa-mail-list-store';
import {
    lazyAddSafeSenderFromReadingPane,
    lazyArchiveMailSingleItem,
    lazyBlockLastSenderFromReadingPane,
    lazyDeleteItems,
    lazyMarkItemJunkNotJunkFromReadingPane,
    lazyMessageRecall,
    lazyReportItems,
} from 'owa-mail-triage-action';
import {
    lazyForwardItem,
    lazyForwardItemAsAttachment,
    lazyReplyToItem,
    lazyResendMessage,
    lazyNewMessage,
} from 'owa-mail-message-actions';
import { lazyPrint, lazyReplyByMeeting } from 'owa-mail-reading-pane-store';
import {
    onForward,
    onForwardAsAttachment,
    onReply,
    onReplyAll,
    onReplyWithMeeting,
    onCopilotReplyWithMeeting,
} from 'owa-mail-commands/lib/actions/onResponse';
import type { ClientItemId } from 'owa-client-ids';
import type FlagType from 'owa-service/lib/contract/FlagType';
import type { MailboxInfo } from 'owa-client-types';
import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import type Message from 'owa-service/lib/contract/Message';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type RetentionTagType from 'owa-service/lib/contract/RetentionTagType';
import closePopout from 'owa-popout-v2/lib/utils/closePopout';
import { isPopout } from 'owa-popout-v2';
import { getActionSourceFromCommandingViewMode } from 'owa-command-ribbon';
import { getCurrentGroupInformationStore } from 'owa-groups-shared-store/lib/CurrentGroupInformationStore';
import getDefaultDisposalType from 'owa-mail-reading-pane-store/lib/utils/getDefaultDisposalType';
import { getGroupInformation } from 'owa-groups-shared-store/lib/selectors/getGroupInformation';
import { getMailMenuItemShouldShow } from 'owa-mail-filterable-menu-behavior';
import { getStore as getMailStore } from 'owa-mail-store/lib/store/Store';
import { getRibbonMailboxInfo } from 'owa-mail-ribbon-utils/lib/getRibbonMailboxInfo';
import isFeatureEnabled from 'owa-feature-flags/lib/utils/isFeatureEnabled';
import { isGroupSelected } from 'owa-group-utils';
import { lazyCreateRuleFromItem } from 'owa-mail-inbox-rules';
import { lazyMarkItemAsReadFromReadingPane } from 'owa-mail-mark-read-actions';
import { lazyMountAndShowFullOptions } from 'owa-options-view';
import { lazyOnAssignPolicy } from 'owa-mail-retention-policies';
import { lazyOpenRulesPanelInModal } from 'owa-groups-inbox-rules-option';
import onArchive from 'owa-mail-commands/lib/actions/onArchive';
import onBlock from 'owa-mail-commands/lib/actions/onBlock';
import onCreateRule from 'owa-mail-commands/lib/actions/onCreateRule';
import onDelete from 'owa-mail-commands/lib/actions/onDelete';
import { onFlagUnflag } from 'owa-mail-commands/lib/actions/onFlagUnflag';
import onJunkNotJunk from 'owa-mail-commands/lib/actions/onJunkNotJunk';
import onMarkAsPhishing from 'owa-mail-commands/lib/actions/onMarkAsPhishing';
import onMarkAsReadUnread from 'owa-mail-commands/lib/actions/onMarkAsReadUnread';
import onNeverBlock from 'owa-mail-commands/lib/actions/onNeverBlock';
import onPrint from 'owa-mail-commands/lib/actions/onPrint';
import onShowInImmersiveReader from 'owa-mail-commands/lib/actions/onShowInImmersiveReader';
import { onShowInImmersiveReader as onShowInImmersiveReaderSingleItem } from 'owa-immersive-reader-store/lib/utils/onShowInImmersiveReader';
import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import renderZoomButtonsCallout from './renderZoomButtonsCallout';
import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { lazyShowReadReceiptsDialog } from 'owa-track-read-receipts';
import { ReportType } from 'owa-reporting';
import {
    TeamsRespondType,
    lazyChatAroundEmail,
    lazyRunTeamsControlChecks,
    lazyShareEmailToTeamsChat,
    lazyShowCannotShareNotification,
} from 'owa-teams-integration';
import { getSingleSelectedItemFromView } from './getSelectedItemFromView';
import type EmailAddressWrapper from 'owa-service/lib/contract/EmailAddressWrapper';
import { lazyCreateMailResponseBodyContent } from 'owa-mail-content-utils';
import { lazyGetRespondSubject } from 'owa-mail-compose-actions';
import { type ComposeOperation } from 'owa-mail-compose-store';
import { lazyCreateCopyItem, lazyIsTabFileActivationCached } from 'owa-mail-file-support';
import { type CommandingViewActionSource } from 'owa-analytics-types';
import {
    getShareToTeamsRecipientChecks,
    getShareToTeamsMessageChecks,
} from 'owa-teams-integration/lib/utils/teamsControlCheck';
import type CLPLabel from 'owa-mail-protection-types/lib/schema/CLPLabel';
import { lazyGetCLPLableFromItem } from 'owa-mail-protection';
import type { AppButtonOnExecuteParameter } from '@1js/acui-button/lib/components/AppButton/AppButton.Props';
import type { MessageExtensionType } from 'owa-graph-schema';
import { messageExtensionsRibbonButtonOnExecute } from 'owa-message-extension-flyout/lib/utils/messageExtensionsRibbonButtonOnExecute';
import onSweep from 'owa-mail-commands/lib/actions/onSweep';

export const onArchiveClicked = (
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window,
    itemId: ClientItemId | undefined
) => {
    if (itemId) {
        lazyArchiveMailSingleItem.import().then(archiveMailSingleItem => {
            const targetWindow = windowRetriever(tabId);
            archiveMailSingleItem(itemId.Id).then(() => {
                closePopout(targetWindow);
            });
        });
    } else {
        onArchive(getActionSourceFromCommandingViewMode());
    }
};

export const onBlockClicked = (
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window,
    itemId: ClientItemId | undefined
) => {
    const actionSource = getActionSourceFromCommandingViewMode();
    if (itemId) {
        const targetWindow = windowRetriever(tabId);
        lazyBlockLastSenderFromReadingPane
            .importAndExecute(
                itemId.Id,
                itemId.mailboxInfo,
                [] /* instrumentationContext */,
                actionSource,
                targetWindow
            )
            .then(blockAttempted => {
                if (blockAttempted) {
                    closePopout(targetWindow);
                }
            });
    } else {
        onBlock(actionSource);
    }
};

export const onDeleteClicked = (
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window,
    itemId: ClientItemId | undefined
) => {
    const targetWindow = windowRetriever(tabId);
    // We should check both popout and deeplink scenarios
    const isPopoutOrDeepLink = isPopout(targetWindow);
    if (itemId) {
        lazyDeleteItems.import().then(deleteItems => {
            deleteItems(
                [itemId.Id],
                getDefaultDisposalType(itemId.Id),
                [] /* instrumentationContext */,
                getActionSourceFromCommandingViewMode(),
                itemId.mailboxInfo,
                targetWindow,
                isPopoutOrDeepLink
            ).then(() => {
                if (isPopoutOrDeepLink) {
                    closePopout(targetWindow);
                }
            });
        });
    } else {
        onDelete(
            getActionSourceFromCommandingViewMode() /* actionSourceMailStore */,
            getActionSourceFromCommandingViewMode() /* actionSourceAnalyticsActions */,
            undefined /* isExplicitSoftDelete */,
            targetWindow
        );
    }
};

export const onFlagUnflagClicked = (
    flagType: FlagType | undefined,
    override: boolean | undefined,
    itemId: ClientItemId | undefined
) => {
    onFlagUnflag(getActionSourceFromCommandingViewMode(), flagType, override, itemId);
};

export const onClickSweep = (
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window,
    mailboxInfo: MailboxInfo,
    itemId?: string
) => {
    const targetWindow = windowRetriever(tabId);
    onSweep(mailboxInfo, targetWindow, itemId);
};

export const onForwardClicked = (
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window,
    itemId: ClientItemId | undefined
) => {
    const actionSource = getActionSourceFromCommandingViewMode();
    if (itemId) {
        const targetWindow = windowRetriever(tabId);

        lazyIsTabFileActivationCached.importAndExecute(tabId).then(isFileActivation => {
            if (isFileActivation) {
                handleLocalForwardItem(itemId, actionSource, targetWindow);
            } else {
                forwardItem(itemId, actionSource, targetWindow);
            }
        });
    } else {
        onForward(actionSource);
    }
};

export const onMarkAsPhishingClicked = (
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window,
    itemId: ClientItemId | undefined
) => {
    const actionSource = getActionSourceFromCommandingViewMode();
    if (itemId) {
        const targetWindow = windowRetriever(tabId);

        lazyReportItems
            .importAndExecute(
                ReportType.Phishing,
                [itemId.Id],
                getSelectedTableView(),
                actionSource,
                targetWindow
            )
            .then(reported => {
                if (reported) {
                    closePopout(targetWindow);
                }
            });
    } else {
        onMarkAsPhishing(actionSource);
    }
};

export const onMarkAsReadUnreadClicked = (itemId: ClientItemId | undefined) => {
    const actionSource = getActionSourceFromCommandingViewMode();
    if (itemId) {
        const item: Message = getMailStore().items.get(itemId.Id) as Message;
        lazyMarkItemAsReadFromReadingPane.importAndExecute(
            itemId.Id,
            getSelectedTableView(),
            !item?.IsRead /* isReadValue */,
            [] /* instrumentationContext */,
            actionSource
        );
    } else {
        onMarkAsReadUnread(actionSource);
    }
};

export const onPrintClicked = (
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window,
    itemId: ClientItemId | undefined
) => {
    const actionSource = getActionSourceFromCommandingViewMode();
    if (itemId) {
        const targetWindow = windowRetriever(tabId);
        lazyPrint.importAndExecute(
            {
                mailboxInfo: itemId.mailboxInfo,
                Id: itemId.Id,
            },
            actionSource,
            targetWindow
        );
    } else {
        onPrint(actionSource);
    }
};

export const onReplyAllClicked = (
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window,
    itemId: ClientItemId | undefined
) => {
    const actionSource = getActionSourceFromCommandingViewMode();

    if (itemId) {
        const targetWindow = windowRetriever(tabId);

        lazyIsTabFileActivationCached.importAndExecute(tabId).then(isFileActivation => {
            // If the item is local and the tab is a file activation tab, we need to create a new message instead of ReplyAll since the item
            // does not exist on the server
            if (isFileActivation) {
                const item: ClientMessage = getMailStore().items.get(itemId.Id) as ClientMessage;
                const fromRecipient: EmailAddressWrapper = {
                    Name: item.From?.Mailbox?.Name,
                    EmailAddress: item.From?.Mailbox?.EmailAddress,
                };
                const toRecipients: EmailAddressWrapper[] = [
                    fromRecipient,
                    ...(item.ToRecipients || []),
                ];
                invokeNewMessage(
                    item,
                    2,
                    actionSource,
                    targetWindow,
                    toRecipients,
                    item.CcRecipients
                );
            } else {
                lazyReplyToItem.importAndExecute(
                    itemId,
                    true /** isReplyAll */,
                    actionSource,
                    [] /* instrumentationContext */,
                    !!shouldSuppressServerMarkReadOnReplyOrForward(getSelectedTableView()),
                    targetWindow
                );
            }
        });
    } else {
        onReplyAll(actionSource);
    }
};

export const onReplyClicked = (
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window,
    itemId: ClientItemId | undefined
) => {
    const actionSource = getActionSourceFromCommandingViewMode();
    if (itemId) {
        const targetWindow = windowRetriever(tabId);

        lazyIsTabFileActivationCached.importAndExecute(tabId).then(isFileActivation => {
            // If the item is local and the tab is a file activation tab, we need to create a new message instead of Reply since the item
            // does not exist on the server
            if (isFileActivation) {
                const item: ClientMessage = getMailStore().items.get(itemId.Id) as ClientMessage;
                const toRecipients: EmailAddressWrapper[] = [
                    {
                        Name: item.From?.Mailbox?.Name,
                        EmailAddress: item.From?.Mailbox?.EmailAddress,
                    },
                ];
                invokeNewMessage(item, 1, actionSource, targetWindow, toRecipients);
            } else {
                lazyReplyToItem.importAndExecute(
                    itemId,
                    false /** isReplyAll */,
                    actionSource,
                    [] /* instrumentationContext */,
                    !!shouldSuppressServerMarkReadOnReplyOrForward(getSelectedTableView()),
                    targetWindow
                );
            }
        });
    } else {
        onReply(actionSource);
    }
};

export const onReplyWithMeetingClicked = (itemId: ClientItemId | undefined) => {
    const actionSource = getActionSourceFromCommandingViewMode();
    if (itemId) {
        lazyReplyByMeeting.importAndExecute(
            itemId.Id,
            false /* isCopilot */,
            undefined /*calendarInlineComposeViewState*/,
            actionSource
        );
    } else {
        onReplyWithMeeting(actionSource);
    }
};

export const onCopilotReplyWithMeetingClicked = (itemId: ClientItemId | undefined) => {
    const actionSource = getActionSourceFromCommandingViewMode();
    if (itemId) {
        lazyReplyByMeeting.importAndExecute(
            itemId.Id,
            true /* isCopilot */,
            undefined /*calendarInlineComposeViewState*/,
            actionSource
        );
    } else {
        onCopilotReplyWithMeeting(actionSource);
    }
};

export const onShowInImmersiveReaderClicked = (
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window,
    itemId: ClientItemId | undefined
) => {
    const actionSource = getActionSourceFromCommandingViewMode();
    if (itemId) {
        const targetWindow = windowRetriever(tabId);
        onShowInImmersiveReaderSingleItem(
            itemId /* itemId */,
            actionSource,
            undefined,
            targetWindow
        );
    } else {
        onShowInImmersiveReader(actionSource);
    }
};

export const onCreateRuleClicked = (
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window,
    itemId: ClientItemId | undefined
) => {
    const source = getActionSourceFromCommandingViewMode();
    if (itemId) {
        const item: Message = getMailStore().items.get(itemId.Id) as Message;
        lazyCreateRuleFromItem.import().then(createRuleFromItem => {
            const targetWindow = windowRetriever(tabId);
            createRuleFromItem(item, source, targetWindow);
        });
    } else {
        onCreateRule(source);
    }
};

export const onManageRulesClicked = (
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window,
    mailboxInfo: MailboxInfo
) => {
    if (isFeatureEnabled('grp-loadFolders') && isGroupSelected()) {
        const group = getGroupInformation(
            mailboxInfo,
            getCurrentGroupInformationStore().smtpAddress
        );

        const groupName = group?.basicInformation?.DisplayName;

        if (groupName) {
            lazyOpenRulesPanelInModal.importAndExecute(groupName);
        }
    } else {
        const targetWindow = windowRetriever(tabId);
        lazyMountAndShowFullOptions.importAndExecute(
            'mail',
            'rules',
            undefined,
            targetWindow,
            mailboxInfo
        );
    }
};

export const onReportSplitButtonClicked = (
    props: ReadOnlyRibbonControlDefProps,
    windowRetriever: (tabId: string | undefined) => Window
) => {
    const mailboxInfo = getRibbonMailboxInfo(props);
    const isCloudCache =
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * Adding IsShadowMailbox to restricted properties/methods.
         *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
        !!getAccountScopeUserSettings(mailboxInfo)?.SessionSettings?.IsShadowMailbox;

    /**
     * We don't expose "Report phishing" for cloud cache accounts, so we need to
     * change default action to "Report junk" in that scenario.
     */
    if (isCloudCache) {
        onJunkNotJunkClicked(props, windowRetriever);
    } else {
        onMarkAsPhishingClicked(props.projectionTabId, windowRetriever, props.itemId);
    }
};

export const onJunkNotJunkClicked = (
    props: ReadOnlyRibbonControlDefProps,
    windowRetriever: (tabId: string | undefined) => Window
) => {
    const { projectionTabId: tabId, itemId } = props;
    const actionSource = getActionSourceFromCommandingViewMode();
    if (itemId) {
        const targetWindow = windowRetriever(tabId);
        lazyMarkItemJunkNotJunkFromReadingPane
            .importAndExecute(itemId.Id, getSelectedTableView(), actionSource, targetWindow)
            .then(isReportSuccessful => {
                if (isReportSuccessful) {
                    closePopout(targetWindow);
                }
            });
    } else {
        const mailboxInfo = getRibbonMailboxInfo(props);
        onJunkNotJunk(actionSource, getMailMenuItemShouldShow(24, mailboxInfo));
    }
};

export const onForwardEmailAsAttachmentClicked = (
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window,
    itemId: ClientItemId | undefined
) => {
    const actionSource = getActionSourceFromCommandingViewMode();
    if (itemId) {
        const targetWindow = windowRetriever(tabId);
        lazyIsTabFileActivationCached.importAndExecute(tabId).then(isFileActivation => {
            // If the item is local and the tab is a file activation tab, we need to create a new message instead of Forward as attachment
            // since the item does not exist on the server
            if (isFileActivation) {
                const item: ClientMessage = getMailStore().items.get(itemId.Id) as ClientMessage;
                invokeNewMessage(
                    item,
                    3,
                    actionSource,
                    targetWindow,
                    undefined /* toEmailAddressWrappers */,
                    undefined /* ccEmailAddressWrappers */,
                    true /* attachBody */
                );
            } else {
                lazyForwardItemAsAttachment.importAndExecute(itemId, actionSource, targetWindow);
            }
        });
    } else {
        onForwardAsAttachment(actionSource);
    }
};

export const onNeverBlockClicked = (
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window,
    itemId: ClientItemId | undefined
) => {
    const actionSource = getActionSourceFromCommandingViewMode();
    if (itemId) {
        const targetWindow = windowRetriever(tabId);
        lazyAddSafeSenderFromReadingPane.importAndExecute(
            itemId.Id,
            actionSource,
            itemId.mailboxInfo,
            targetWindow
        );
    } else {
        onNeverBlock(actionSource);
    }
};

export const onMoreRetentionPoliciesClicked = () => {
    lazyMountAndShowFullOptions.importAndExecute('mail', 'retentionPolicies');
};

export const onAssignArchivePolicyClicked = (
    retentionId: string,
    isArchivePolicyClicked: boolean,
    selectedRetentionTagType: RetentionTagType | null | RetentionTagType[],
    folderId: string | null,
    clientItem: ClientItem | undefined,
    actionSource: ActionSource,
    targetWindow: Window | undefined
) => {
    lazyOnAssignPolicy.import().then(onAssignPolicy => {
        onAssignPolicy(
            retentionId,
            isArchivePolicyClicked,
            selectedRetentionTagType,
            folderId,
            clientItem,
            actionSource,
            targetWindow
        );
    });
};

export const onMessageRecallClicked = (
    itemId: ClientItemId | undefined,
    mailboxInfo: MailboxInfo | undefined,
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window
) => {
    const actionSource = getActionSourceFromCommandingViewMode();
    const targetWindow = windowRetriever(tabId);
    lazyMessageRecall.importAndExecute(itemId?.Id, actionSource, mailboxInfo, targetWindow);
};

export const onZoomClicked = (
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window
) => {
    const targetWindow = windowRetriever(tabId);
    renderZoomButtonsCallout((688).toString(), targetWindow);
};

export const onTrackReadReceiptsClicked = (
    itemId: ClientItemId | undefined,
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window
) => {
    const actionSource = getActionSourceFromCommandingViewMode();
    const targetWindow = windowRetriever(tabId);
    lazyShowReadReceiptsDialog.importAndExecute(itemId?.Id, actionSource, targetWindow);
};

export const onChatAroundEmailButtonsClicked = (
    isSLR: boolean,
    itemId: ClientItemId | undefined,
    mailboxInfo: MailboxInfo,
    respondType: TeamsRespondType
) => {
    const message: ClientItem | undefined = !!itemId
        ? getMailStore().items.get(itemId.Id)
        : getSingleSelectedItemFromView(getSelectedTableView());

    if (message) {
        lazyChatAroundEmail.importAndExecute(
            respondType,
            message,
            mailboxInfo,
            'chat',
            isSLR ? 'SimplifiedRibbon' : 'MultiLineRibbon'
        );
    }
};

export const onMessageResendClicked = (
    itemId: ClientItemId | undefined,
    tabId: string | undefined,
    windowRetriever: (tabId: string | undefined) => Window
) => {
    const message: ClientItem | undefined = !!itemId
        ? getMailStore().items.get(itemId.Id)
        : getSingleSelectedItemFromView(getSelectedTableView());

    if (message?.ItemId) {
        const actionSource = getActionSourceFromCommandingViewMode();
        const targetWindow = windowRetriever(tabId);
        lazyResendMessage.importAndExecute(
            {
                ...message.ItemId,
                mailboxInfo: message.MailboxInfo,
            },
            actionSource,
            [] /* instrumentationContext */,
            !!shouldSuppressServerMarkReadOnReplyOrForward(getSelectedTableView()),
            targetWindow
        );
    }
};

export const onShareToTeamsButtonClicked = (
    itemId: ClientItemId | undefined,
    mailboxInfo: MailboxInfo
) => {
    const message: ClientItem | undefined = !!itemId
        ? getMailStore().items.get(itemId.Id)
        : getSingleSelectedItemFromView(getSelectedTableView());

    if (message) {
        lazyGetCLPLableFromItem.importAndExecute(message).then((clpLabel: CLPLabel | undefined) => {
            if (!clpLabel?.isEncryptingLabel) {
                // If the label is encrypting label, we should not share to teams
                lazyRunTeamsControlChecks
                    .importAndExecute(
                        message,
                        mailboxInfo,
                        getShareToTeamsRecipientChecks(),
                        getShareToTeamsMessageChecks(),
                        TeamsRespondType.ReplyAll
                    )
                    .then(hasValidRecipient => {
                        if (message && hasValidRecipient) {
                            lazyShareEmailToTeamsChat.importAndExecute(
                                message,
                                message.MailboxInfo
                            );
                        } else {
                            lazyShowCannotShareNotification.importAndExecute();
                        }
                    });
            } else {
                lazyShowCannotShareNotification.importAndExecute();
            }
        });
    }
};

// This function is used to forward an item from the server
function forwardItem(
    itemId: ClientItemId,
    actionSource: CommandingViewActionSource,
    targetWindow: Window
) {
    lazyForwardItem.importAndExecute(
        itemId,
        actionSource,
        [] /* instrumentationContext */,
        !!shouldSuppressServerMarkReadOnReplyOrForward(getSelectedTableView()),
        targetWindow
    );
}

// This function is used to forward an item that was created locally e.g. opening a message from a file
function handleLocalForwardItem(
    itemId: ClientItemId,
    actionSource: CommandingViewActionSource,
    targetWindow: Window
) {
    const item: ClientMessage = getMailStore().items.get(itemId.Id) as ClientMessage;

    // If the item has attachments, we need to create a copy of the item and forward the copy to ensure the attachments are included
    if (item.Attachments && item.Attachments.length > 0) {
        handleLocalForwardItemWithAttachments(item, actionSource, targetWindow);
    } else {
        // If the item does not have attachments, we can just forward the original item
        invokeNewMessage(item, 3, actionSource, targetWindow);
    }
}

// This function is used to forward an item that was created locally and has attachments
function handleLocalForwardItemWithAttachments(
    item: ClientMessage,
    actionSource: CommandingViewActionSource,
    targetWindow: Window
) {
    // Create the item in the server and forward it
    lazyCreateCopyItem
        .importAndExecute(item, '' /* folderId */, item.MailboxInfo)
        .then(res => {
            const newItemId = res.ResponseMessages?.Items?.[0].Items?.[0].ItemId;
            if (newItemId) {
                forwardItem(
                    { ...newItemId, mailboxInfo: item.MailboxInfo },
                    actionSource,
                    targetWindow
                );
            }
        })
        .catch(() => {
            // If the item creation fails (e.g. disconnected from the internet), we should just forward the original item
            invokeNewMessage(item, 3, actionSource, targetWindow);
        });
}

// This function is used to create a new message from a local item with the appropriate subject and quoted body
async function invokeNewMessage(
    item: ClientMessage,
    operation: ComposeOperation,
    actionSource: ActionSource,
    targetWindow: Window,
    toEmailAddressWrappers?: EmailAddressWrapper[],
    ccEmailAddressWrappers?: EmailAddressWrapper[],
    attachBody?: boolean
) {
    // Get the subject with appropriate prefix
    const getRespondSubject = await lazyGetRespondSubject.import();
    const subject = getRespondSubject(item, operation);

    // Get the quoted body which includes header information and separator, then call NewMessage
    const createMailResponseBodyContent = await lazyCreateMailResponseBodyContent.import();
    const quotedBody = !attachBody
        ? createMailResponseBodyContent(item, true /* isHtml */, subject)
        : undefined;

    lazyNewMessage.importAndExecute(actionSource, {
        mailboxInfo: item.MailboxInfo,
        subject,
        toEmailAddressWrappers,
        ccEmailAddressWrappers,
        body: quotedBody,
        mailItems: attachBody ? [item] : undefined,
        isPopout: false,
        targetWindow,
    });
}

export const onMessageExtensionsFlyoutButtonClicked = (
    params: AppButtonOnExecuteParameter,
    targetWindow: Window,
    formId: string,
    messageExtensionType: MessageExtensionType,
    mailboxInfo?: MailboxInfo,
    readingPaneItemId?: string
) => {
    messageExtensionsRibbonButtonOnExecute(
        params,
        targetWindow,
        formId,
        messageExtensionType,
        mailboxInfo,
        readingPaneItemId
    );
};
