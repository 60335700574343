import type ItemId from 'owa-service/lib/contract/ItemId';
import type { MailRibbonTabId } from 'owa-ribbon-ids';
import { owaComputedFn } from 'owa-computed-fn';

/**
 * There is a caching mechanism in place for the Ribbon. This requires that any popout window has a unique Tab Id for its Ribbon.
 * This function will return a unique string that is a conjunction of viewState / itemId and whether it's a popout or not.
 * @param tabId The regular MailRibbonTabId of this tab
 * @param editorId The id of current editorViewState
 * @param itemId The ItemId (this is used for Reading Pane Popouts)
 * @param isPopout Whether this is a popout or not
 */
export const getUniqueTabId = owaComputedFn(function getUniqueTabId(
    tabId: MailRibbonTabId,
    editorId: string | undefined,
    itemId: ItemId | undefined,
    isPopout: boolean | undefined
): string {
    const tabIdString: string = tabId.toString();

    /**
     * If it's not a popout scenario, then we're in the main window.
     * In that case, we don't and shouldn't have any suffixes to the end of the ID.
     * This allows us to change to Message tab when a user creates a new mail, because we need
     * a predictable ID to change to Message tab.
     */
    if (!isPopout) {
        return tabIdString;
    }

    if (!!editorId) {
        return `${tabIdString}_${editorId}_popout`;
    } else if (itemId) {
        return `${tabIdString}_${itemId.Id}_popout`;
    } else {
        return `${tabIdString}_popout`;
    }
});
