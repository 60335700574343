import {
    createLazyComponent,
    registerLazyOrchestrator,
    LazyImport,
    LazyAction,
} from 'owa-bundling';
import { resetSearchStore } from 'owa-search-actions/lib/actions/resetSearchStore';
import { startSearchSession } from 'owa-search-actions/lib/actions/startSearchSession';
import { startSearch } from 'owa-search-actions/lib/actions/startSearch';
import { invoke3sSearchForTab } from './actions/invoke3sSearchForTab';
import { lazyModule } from './lazyFunctions';

// Export interfaces and enums
export { TabbedSearchLoadingState } from './store/TabbedSearchStore';
export type { default as TabbedSearchStore } from './store/TabbedSearchStore';

// Export lazy selectors
export const lazyGetIsNonMailEntitySearchTab = new LazyImport(
    lazyModule,
    m => m.getIsNonMailEntitySearchTab
);
export const lazyGetActiveSearchTab = new LazyImport(lazyModule, m => m.getActiveSearchTab);
export const lazyGetActiveScopeTab = new LazyImport(lazyModule, m => m.getActiveScopeTab);
export const lazyGetActiveScopeTabIndex = new LazyImport(lazyModule, m => m.getActiveScopeTabIndex);
export const lazyGetSearchItemsByTab = new LazyImport(lazyModule, m => m.getSearchItemsByTab);

// Export lazy actions
export const lazySetLoadingState = new LazyAction(lazyModule, m => m.setLoadingState);
export const lazyResetTabbedSearchResults = new LazyAction(
    lazyModule,
    m => m.resetTabbedSearchResults
);
export const lazySetActiveSearchTab = new LazyAction(lazyModule, m => m.setActiveSearchTab);
export const lazySetActiveScopeTab = new LazyAction(lazyModule, m => m.setActiveScopeTab);
export const lazyInvoke3sSearchForTab = new LazyAction(lazyModule, m => m.invoke3sSearchForTab);

export { lazySetShouldSuppressTabs } from './lazyFunctions';

// Export lazy components
export const TabbedSearchPivot = createLazyComponent(lazyModule, m => m.TabbedSearchPivot);
export const TabbedScopedSuggestions = createLazyComponent(
    lazyModule,
    m => m.TabbedScopedSuggestions
);
export const TabbedSearchPane = createLazyComponent(lazyModule, m => m.TabbedSearchPane);

// Register lazy orchestrator
registerLazyOrchestrator(resetSearchStore, lazyModule, m => m.resetSearchStoreOrchestrator);
registerLazyOrchestrator(invoke3sSearchForTab, lazyModule, m => m.invoke3sSearchForTabOrchestrator);
registerLazyOrchestrator(startSearchSession, lazyModule, m => m.searchSessionStartedOrchestrator);
registerLazyOrchestrator(startSearch, lazyModule, m => m.startSearchOrchestrator);

// Export utils
export { default as isTabbedSearchEnabled } from './utils/isTabbedSearchEnabled';
