import type { PanelView } from '../store/schema/TimePanelSettingsStore';
import type { TimePanelSelectedViewType } from 'owa-scenario-settings-service';

const panelViewToTypeMapping: {
    [T in PanelView]: TimePanelSelectedViewType;
} = {
    Conflicts: 'Calendar',
    EventDetails: 'Calendar',
    AttendeeTracking: 'Calendar',
    Calendar: 'Calendar',
    ComposeForm: 'Calendar',
    Tasks: 'Tasks',
};

export function getPanelViewType(view: PanelView | null): TimePanelSelectedViewType | null {
    if (!view) {
        return null;
    }
    return panelViewToTypeMapping[view];
}
