import { owaComputedFn } from 'owa-computed-fn';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { getSelectedTableView } from 'owa-mail-list-store';

export const isNotesScenario = owaComputedFn(function isNotesScenario() {
    const tableView = getSelectedTableView();
    const isNotesFolder = tableView?.tableQuery?.folderId
        ? folderIdToName(tableView?.tableQuery.folderId) === 'notes'
        : false;
    return isNotesFolder;
});
