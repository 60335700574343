import type { TableView } from 'owa-mail-list-store';
import { MailRowDataPropertyGetter } from 'owa-mail-list-store';

export function getAllCategoriesOnRows(tableView: TableView, rowKeys: string[]): string[] {
    const rowsAllCategories = [];

    // Get all categories for all rows in rowsAllCategories collection
    for (let i = 0; i < rowKeys.length; i++) {
        const rowCategories = MailRowDataPropertyGetter.getCategories(rowKeys[i], tableView) || [];
        rowsAllCategories.push(...rowCategories);
    }

    return rowsAllCategories;
}

/**
 * A function which returns properties that decide whether to show certain category actions
 * includes a collection of common categories for the given rows, and whether any of the row has categories
 * @param tableView to which the rows belong
 * @param rowKeys of the rows for which to get the intersection of categories
 * @returns list of categories that are on all the rows
 */
export function getCommonCategoriesOnRows(tableView: TableView, rowKeys: string[]): string[] {
    const commonCategoriesToReturn: string[] = [];
    const rowCategoryMap = {};

    // Get all categories for all rows in rowsAllCategories collection
    const rowsAllCategories = getAllCategoriesOnRows(tableView, rowKeys);

    // Build a category name <=> count map for the categories in rowsAllCategories collection
    for (let i = 0; i < rowsAllCategories.length; i++) {
        const categoryName = rowsAllCategories[i];
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS7053 (38,14): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
        // @ts-expect-error
        if (!rowCategoryMap[categoryName]) {
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS7053 (42,13): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
            // @ts-expect-error
            rowCategoryMap[categoryName] = 1;
        } else {
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS7053 (47,13): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
            // @ts-expect-error
            rowCategoryMap[categoryName] = rowCategoryMap[categoryName] + 1;
        }
    }

    // Iterate map to get the categories for which the count is same as the rowKeys count,
    // which means the category appeared in all rows
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    Object.keys(rowCategoryMap).forEach(key => {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS7053 (57,31): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
        // @ts-expect-error
        const categoryCount = rowCategoryMap[key];
        if (categoryCount == rowKeys.length) {
            commonCategoriesToReturn.push(key);
        }
    });

    return commonCategoriesToReturn;
}
