import { getDefaultFavoriteTreeData } from '../store/getDefaultFavoriteTreeData';
import { setFavoriteTreeData } from '../mutators/setFavoriteTreeData';
import initializeShouldHideFavoritesList from '../mutators/initializeShouldHideFavoritesList';
import favoritesStore from '../store/store';
import type { MailboxInfo } from 'owa-client-types';
import { isPrimaryAccountFromMailboxInfo } from './isPrimaryAccount';

export function initializeFavoriteTreeData(mailboxInfoKey: string, mailboxInfo: MailboxInfo) {
    if (!favoritesStore.favoriteTreeData.has(mailboxInfoKey)) {
        setFavoriteTreeData(mailboxInfoKey, getDefaultFavoriteTreeData(mailboxInfoKey));
    }
    if (isPrimaryAccountFromMailboxInfo(mailboxInfo)) {
        initializeShouldHideFavoritesList();
    }
}
