/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - satchel stores in the worker will need their data synced
 *	> 'createStore' import from 'satcheljs' is restricted. Satchel stores should not be created in the worker as the data will need to be synced */
import { createStore } from 'satcheljs';
import type { ModuleContextMailboxInfoData } from './schema/ModuleContextMailboxInfoData';
import type { Module } from 'owa-workloads';
import type { MailboxInfo } from 'owa-client-types';

const defaultStore: ModuleContextMailboxInfoData = {
    moduleContextMailboxInfos: new Map<Module, MailboxInfo>(),
};

const store = createStore<ModuleContextMailboxInfoData>(
    'ModuleContextMailboxInfoData',
    defaultStore
)();

const getModuleContextMailboxInfoStore = () => store;
export default getModuleContextMailboxInfoStore;
