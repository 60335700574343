import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Remove reference to getHostValue
 *	> While generally we don't want to use getHostValue in code - it is acceptable to use in a capability as it clearly ties back to the feature needing it. */
import { NATIVE, getHostValue } from 'owa-config';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 *
 * This capability is being used to check in which platforms the shy header is enabled
 */
export const shyHeaderCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return getHostValue() !== NATIVE;
    },
    isSupported: (_mailboxInfo?: MailboxInfo) => {
        return true;
    },
};
