import type { CoreOption, default as Option, LazyOption } from '../store/schema/Option';
import type OptionSubCategory from '../store/schema/OptionSubCategory';

export type FullOption = Pick<Option, keyof CoreOption | 'fullOptionFormSettings'>;

export function tryLoadAllFullOptionsSync(subcategory: OptionSubCategory): FullOption[] | null {
    const loadedOptions: FullOption[] = subcategory.options
        ? subcategory.options
              .filter(
                  (option): option is Option | LazyOption =>
                      isFullOption(option) || isLazyFullOption(option)
              )
              .map(option =>
                  isFullOption(option)
                      ? option
                      : {
                            ...option,
                            fullOptionFormSettings: option.tryImportFullOptionFormSettingsSync?.(),
                        }
              )
        : [];

    // If some options haven't yet loaded, return `null`
    return loadedOptions.some(({ fullOptionFormSettings }) => !fullOptionFormSettings)
        ? null
        : loadedOptions;
}

function isFullOption(option: any): option is Option {
    return option['fullOptionFormSettings'] ?? false;
}

function isLazyFullOption(option: any): option is LazyOption {
    return option['tryImportFullOptionFormSettingsSync'] ?? false;
}
