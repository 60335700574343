import { getAccountScopeUserSettings, getUserConfiguration } from 'owa-session-store';
import { isOfflineMailComposeEnabled } from './isOfflineMailComposeEnabled';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';

// Any changes you make here, make sure to add them to similar logic in getFolderStorageInformation
/**
 * Determines if folder can be shown to the user depending on the userconfig settings
 * @param distinguishedFolderIdName folder name
 */
export function shouldShowFolder(
    distinguishedFolderIdName: string | undefined | null,
    mailboxInfo: MailboxInfo
): boolean {
    const segmentationSettings = getUserConfiguration().SegmentationSettings;
    const isShadowMailbox =
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * Adding IsShadowMailbox to restricted properties/methods.
         *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
        getAccountScopeUserSettings(mailboxInfo).SessionSettings?.IsShadowMailbox;

    // Skip adding Notes folder if it's disabled by admin or if running against
    // Cloud Cache mailbox.  In CC we don't sync data from Google Keep, so users
    // shouldn't be able to create notes.
    if (distinguishedFolderIdName === 'notes') {
        return (
            !!segmentationSettings?.StickyNotes &&
            isFeatureEnabled('cal-stickyNotesFolder') &&
            !isShadowMailbox
        );
    }

    if (distinguishedFolderIdName === 'conversationhistory') {
        return !isShadowMailbox;
    }

    if (distinguishedFolderIdName === 'outbox') {
        return isOfflineMailComposeEnabled(mailboxInfo);
    }

    return true;
}
