import type { BootStrategies } from 'owa-shared-start';
import type { PromiseWithKey } from 'owa-performance';

export function preloadStrategies(
    strategies: BootStrategies | undefined
): PromiseWithKey<unknown>[] {
    const promises: PromiseWithKey<unknown>[] = [];
    if (strategies) {
        for (const strategy of Object.keys(strategies)) {
            const lazyAction = strategies[strategy as keyof typeof strategies];
            if (lazyAction) {
                if (strategy == 'settingsMigration') {
                    promises.push({
                        promise: lazyAction
                            .import()
                            .then((func: () => Promise<void>) => func())
                            .catch(() => {}),
                        key: strategy,
                    });
                } else {
                    promises.push({
                        promise: lazyAction.import(),
                        key: strategy,
                    });
                }
            }
        }
    }
    return promises;
}
