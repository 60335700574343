import { ObservableMap } from 'mobx';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getGroupListNodesViewState } from '../store/groupListNodesViewStateStore';
import type { GroupListNodesViewStateStore } from '../store/schema/GroupListNodesViewStateStore';
import type { GroupFolderTreeViewState } from '../store/schema/GroupFolderTreeViewState';

export default function getGroupListNodesViewStateStore(
    mailboxInfo: MailboxInfo
): GroupListNodesViewStateStore {
    const index = getIndexerValueForMailboxInfo(mailboxInfo);
    const listNodesViewStates =
        getGroupListNodesViewState().groupListNodesViewStatesByMailBoxInfo.get(index);

    if (!listNodesViewStates) {
        const defaultListNodesViewStates: GroupListNodesViewStateStore = {
            groupListNodesViewStates: new ObservableMap<string, GroupFolderTreeViewState>(),
        };

        getGroupListNodesViewState().groupListNodesViewStatesByMailBoxInfo.set(
            index,
            defaultListNodesViewStates
        );

        return defaultListNodesViewStates;
    }

    return listNodesViewStates;
}
