const COMPOSE_PREFIX = 'extCompose';
const READ_PREFIX = 'extRead';
const MULTISELECT_PREFIX = 'extMultiSelect';

export const getComposeHostItemIndex = (id: string): string => {
    return `${COMPOSE_PREFIX}_${id}`;
};

export const getReadHostItemIndex = (itemId: string): string => {
    return `${READ_PREFIX}_${itemId}`;
};

export const getMultiSelectHostItemIndex = (id: string): string => {
    return `${MULTISELECT_PREFIX}_${id}`;
};

export const getScenarioFromHostItemIndex = (hostItemIndex: string): string => {
    if (hostItemIndex.indexOf(COMPOSE_PREFIX) === 0) {
        return 'Compose';
    } else if (hostItemIndex.indexOf(READ_PREFIX) === 0) {
        return 'Read';
    } else if (hostItemIndex.indexOf(MULTISELECT_PREFIX) === 0) {
        return 'MultiSelect';
    }

    return '';
};
