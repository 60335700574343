import type SecondaryReadingPaneTabData from '../store/schema/SecondaryReadingPaneTabData';
import { TabType } from '../store/schema/TabViewState';
import { getStore } from '../store/tabStore';

/**
 * Used to check if any tabs in the tab store represent the id given
 *
 * @param id the item or conversation id
 * @returns true if any of the secondary still exist with the given id
 */
export default function secondaryTabsHaveId(id: string): boolean {
    const store = getStore();
    for (const tab of store.tabs) {
        if (
            tab.type !== TabType.Primary &&
            tab.type !== TabType.SxS &&
            tab.type !== TabType.Reminders &&
            tab.type !== TabType.AddCalendars &&
            (tab.data as SecondaryReadingPaneTabData)?.id?.Id == id
        ) {
            return true;
        }
    }
    return false;
}

/**
 * Used to check if any other tabs in the tab store represent the same item (checks against the given tab id)
 *
 * @param id the item if
 * @param tabId the unique tab id that should be omitted from the check
 * @returns true if any other secondary tabs represent the same item or conversation, not including the given tab id
 */
export function otherSecondaryTabsRepresentSameItem(id: string, tabId: string): boolean {
    const store = getStore();
    for (const tab of store.tabs) {
        if (
            tab.type !== TabType.Primary &&
            tab.type !== TabType.SxS &&
            tab.type !== TabType.Reminders &&
            tab.type !== TabType.AddCalendars &&
            (tab.data as SecondaryReadingPaneTabData)?.id?.Id == id &&
            tab.id != tabId
        ) {
            return true;
        }
    }
    return false;
}
