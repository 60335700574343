import setFlightInternal from '../utils/setFlightInternal';
import type FeatureOverride from '../store/schema/FeatureOverride';
import type { MailboxInfo } from 'owa-client-types';
import type { FeatureName } from '../store/featureDefinitions';

function updateActiveFlights(
    featureOverrides: FeatureOverride[],
    mailboxInfo: MailboxInfo | undefined
) {
    for (const override of featureOverrides) {
        setFlightInternal(override.name as FeatureName, override.isEnabled, mailboxInfo);
    }
}

export default updateActiveFlights;
