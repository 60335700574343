import type { MailboxInfo } from 'owa-client-types';
import { logStartUsage } from 'owa-analytics-start';
import { getGuid } from 'owa-guid';
import { type ConsumerTokenRequestParams } from './schema/TokenRequestParams';
import { ScenarioType } from './schema/Scenario';
import { type AuthTokenResponse } from './schema/AuthTokenResponse';
import { getToken } from './getToken';

export async function getDelegationTokenForOwa(
    scope: string,
    apiName?: string,
    requestId?: string,
    showFullTokenResponse?: boolean,
    mailboxInfo?: MailboxInfo
): Promise<string | AuthTokenResponse | undefined> {
    const params: ConsumerTokenRequestParams = {
        requestId: requestId || getGuid(),
        scenarioType: ScenarioType.Consumer,
        mailboxInfo,
        apiName,
        scope,
    };

    return new Promise<string | AuthTokenResponse | undefined>(resolve => {
        getToken(params)
            .then(tokenResponse => {
                resolve(showFullTokenResponse ? tokenResponse : tokenResponse?.accessToken);
            })
            .catch((err: Error) => {
                resolve(undefined);
                logStartUsage('FailedToRetrieveTokenForConsumer', {
                    stack: err?.stack,
                    message: err?.message,
                });
            });
    });
}
