import { mutatorAction } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import { getGroupFolderViewState } from '../selectors/getGroupFolderViewState';

/**
 * Expands or collapses the group folder
 * @param groupId - smtp address of a group
 * @param folderId - Id of the group folder
 * @param mailboxInfo - mailbox info of the account
 */
export default mutatorAction(
    'toggleGroupFolderNodeExpansion',
    (groupId: string, folderId: string, mailboxInfo: MailboxInfo): void => {
        const groupFolderViewState = getGroupFolderViewState(groupId, folderId, mailboxInfo);
        if (groupFolderViewState) {
            groupFolderViewState.isExpanded = !groupFolderViewState.isExpanded;
        }
    }
);
