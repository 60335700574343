import { getUserConfiguration } from 'owa-session-store';

/** When Shared Mailbox is opened using Open Another Mailbox option in OWA, mailbox type is UserMailbox.
 * So, we are checking using explicit logon.*/
export default function isSharedMailboxExplicitLogon() {
    if (getUserConfiguration()?.SessionSettings?.IsExplicitLogon) {
        return true;
    }
    return false;
}
