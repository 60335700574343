import favoritesStore from '../store/store';
import { mutatorAction } from 'satcheljs';
import type { FolderTreeLoadStateEnum } from 'owa-folders';

export const setLoadingStateForFavorites = mutatorAction(
    'setLoadingStateForFavorites',
    (accountKey: string, loadingState: FolderTreeLoadStateEnum) => {
        const favoriteTreeDataLoadingState = favoritesStore.favoriteTreeData.get(accountKey);
        if (favoriteTreeDataLoadingState) {
            favoriteTreeDataLoadingState.loadingState = loadingState;
        }
    }
);
