import { LazyModule, createLazyComponent } from 'owa-bundling';
import { isFeatureEnabled } from 'owa-feature-flags';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "NewOutlookPromoDialog" */ './lazyIndex')
);

export const NewOutlookPromoDialogInner = createLazyComponent(
    lazyModule,
    m => m.NewOutlookPromoDialogInner
);

export const SharedDialogSurface = createLazyComponent(lazyModule, m => m.SharedDialogSurface);
export const PremiumNewOutlookPromoDialog = createLazyComponent(
    lazyModule,
    m => m.PremiumNewOutlookPromoDialog,
    undefined,
    undefined,
    {
        onlyImportIf: () => isFeatureEnabled('acct-newOutlookPromoPremiumConsumers'),
    }
);
