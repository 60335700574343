import { createStore } from 'satcheljs';

export enum LoadState {
    OptionsNotLoaded,
    OptionsLoading,
    OptionsLoaded,
}

export interface OwsOptionsLoadStateStore {
    loadState: LoadState;
}

const defaultStore: OwsOptionsLoadStateStore = {
    loadState: LoadState.OptionsNotLoaded,
};

const store = createStore('owsOptionsLoadStateStore', defaultStore)();
export const getOptionsLoadState = () => store;
