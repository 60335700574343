import { errorThatWillCauseAlert } from 'owa-trace';

import { getM365AcquisitionsQueryTypePolicy } from './graphql/type-policy/M365AcquisitionsTypePolicy';

import type { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';

export function registerM365AcquisitionsTypePolicies(client: ApolloClient<NormalizedCacheObject>) {
    try {
        const M365AcquisitionsQueryTypePolicy = getM365AcquisitionsQueryTypePolicy();
        (client.cache as InMemoryCache).policies.addTypePolicies(M365AcquisitionsQueryTypePolicy);
    } catch (e) {
        errorThatWillCauseAlert('AppBar_M365Acquisitions_TypePolicyRegister_Error', e);
    }
}
