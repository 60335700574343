import React from 'react';
import { makeStyles, tokens, Button, Tooltip } from '@fluentui/react-components';
import { NavigationRegular } from '@fluentui/react-icons';
import { observer } from 'owa-mobx-react';
import type { ToggleButtonProps } from './ToggleButtonProps';

export const V9ToggleButton = observer(({ label, onClick }: ToggleButtonProps) => {
    const { button, icon } = useStyles();
    const navigationIcon = React.useMemo(() => <NavigationRegular className={icon} />, [icon]);

    return (
        <Tooltip content={label} positioning="below" relationship="label" withArrow={true}>
            <Button
                appearance="transparent"
                className={button}
                icon={navigationIcon}
                onClick={onClick}
            />
        </Tooltip>
    );
}, 'V9ToggleButton');

const useStyles = makeStyles({
    button: {
        height: '32px',
        margin: '0 0 0 6px',
        maxWidth: 'none',
        padding: '3px 9px',
        ':hover': {
            backgroundColor: tokens.colorNeutralBackground2Hover,
        },
    },
    icon: {
        color: tokens.colorNeutralForeground1,
        '@media (forced-colors: active)': {
            color: 'CanvasText',
            ':hover': {
                color: 'Highlight',
            },
        },
        fontWeight: tokens.fontWeightRegular,
    },
});
