import type FavoritesStore from './schema/FavoritesStore';
import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';

const defaultFavoritesStore: FavoritesStore = {
    favoritesFolderNodes: new ObservableMap(),
    favoritesPersonaNodes: new ObservableMap(),
    favoriteSearches: new ObservableMap(),
    favoriteCategories: new ObservableMap(),
    orderedFavoritesNodeIds: [],
    orderedCombinedOutlookFavoritesIds: [],
    outlookFavorites: new ObservableMap(),
    favoriteSecondaryKeyMap: new ObservableMap(),
    favoritingInProgressMap: new ObservableMap(),
    favoritesLoaded: false,
    favoriteTreeData: new ObservableMap(),
    hideFavoritesList: false,
    unusedFavorites: new ObservableMap(),
    unusedFavoriteKeyMap: new ObservableMap(),
};

const store = createStore<FavoritesStore>('favorites', defaultFavoritesStore)();
export default store;
export const getStore = () => store;
