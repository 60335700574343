import { createStore } from 'satcheljs';
import type ImmersiveReaderStore from './schema/ImmersiveReaderStore';
import { getGuid } from 'owa-guid';

const initialImmersiveReaderStore: ImmersiveReaderStore = {
    sessionId: getGuid(),
};

const store = createStore<ImmersiveReaderStore>('immersivereader', initialImmersiveReaderStore)();
export default store;
export const getStore = () => store;
