import { observer } from 'owa-mobx-react';
import React from 'react';
import { Icon } from '@fluentui/react';
import { tokens } from '@fluentui/react-theme';
import { getDensityModeString } from 'owa-fabric-theme';
import { type TouchState, type TouchThreshold } from 'touch-behavior';
import { getPaletteAsRawColors } from 'owa-theme';
import {
    getBackgroundColorForSwipeAction,
    getSwipeFromLeftAction,
    getSwipeFromRightAction,
    getIconColorForSwipeAction,
    getIconForSwipeAction,
} from 'owa-mail-list-swipe-utils';

import {
    swipeContainer,
    swipeFromLeft,
    swipeFromRight,
    swipeIcon,
    swipeIconFull,
} from './SwipeInProgressPlaceholder.scss';
import classnames from 'owa-classnames';

export interface SwipeInProgressPlaceholderProps {
    isLeftSwipe: boolean;
    state: TouchState;
    threshold: TouchThreshold;
    isUnread: boolean;
}

export const SwipeInProgressPlaceholder = observer(function SwipeInProgressPlaceholder(
    props: SwipeInProgressPlaceholderProps
) {
    const { isLeftSwipe, state, threshold, isUnread } = props;
    const swipeAction = isLeftSwipe ? getSwipeFromLeftAction() : getSwipeFromRightAction();
    const iconForSwipeAction = getIconForSwipeAction(swipeAction, isUnread);
    const swipeIconColor = getIconColorForSwipeAction(swipeAction);
    const densityModeString = getDensityModeString();

    const isBackgroundColorEnabled = React.useMemo(() => {
        return isLeftSwipe
            ? state.moveX > threshold.action || state.leftAction
            : state.moveX < -threshold.action || state.rightAction;
    }, [isLeftSwipe, state]);

    const swipeContainerBgStyles = React.useMemo(
        () => ({
            backgroundColor: isBackgroundColorEnabled
                ? getBackgroundColorForSwipeAction(swipeAction)
                : getPaletteAsRawColors().paneLighterBackground,
        }),
        [swipeAction, isBackgroundColorEnabled, getPaletteAsRawColors().paneLighterBackground]
    );

    const swipeIconAdditionalStyles: React.CSSProperties = React.useMemo(() => {
        const marginToSet = isLeftSwipe ? 'marginLeft' : 'marginRight';

        return {
            color: isBackgroundColorEnabled ? swipeIconColor : tokens.colorNeutralForeground1,
            [marginToSet]: '15px',
        };
    }, [isLeftSwipe, isBackgroundColorEnabled, swipeIconColor]);
    return (
        <div
            className={classnames(swipeContainer, isLeftSwipe ? swipeFromLeft : swipeFromRight)}
            style={swipeContainerBgStyles}
        >
            <Icon
                className={classnames(swipeIcon, densityModeString === 'full' && swipeIconFull)}
                style={swipeIconAdditionalStyles}
                iconName={iconForSwipeAction}
            />
        </div>
    );
},
'SwipeInProgressPlaceholder');
