import { logUsage } from 'owa-analytics';
import type { MailRibbonGroup, RibbonConfiguration } from 'owa-mail-ribbon-store-shared-types';
import { defaultConfig } from 'owa-mail-ribbon-store-shared-types';
import type { RibbonId } from 'owa-ribbon-ids';
import { isUserCreatedRibbonGroupId } from 'owa-ribbon-ids';

/**
 * This function scans through the user's ribbon configuration and ensures that the RibbonIds
 * are found in defaultConfig. This scenario is possible because a user may log into OWA,
 * customize their Ribbon, and then log into a really old version of Monarch where those RibbonIds
 * don't exist in the product yet. In that case, Monarch would break because it's trying to parse RibbonIds
 * that do not exist yet, and that has no concept of yet.
 *
 * To remedy this, we scan through the user's ribbon configuration, and as long as the RibbonIds exist
 * within defaultConfig, we consider the configuration valid. However, if we find a RibbonId that does not
 * exist at all in defaultConfig, then we consider the config corrupted.
 *
 * @param ribbonConfig the ribbon config that the user currently has
 * @param shouldPrune if true, we will remove ids from the ribbonConfig that are not found in defaultConfig
 * @returns true if the configuration is valid, false if not.
 */
export function ribbonValidityCheck(
    singlelineRibbonConfig: RibbonConfiguration,
    version: number,
    shouldPrune?: boolean
): boolean {
    const homeTabValidDefaultConfigIds: Set<RibbonId> = getSetOfValidDefaultConfigIds(
        defaultConfig.singleline.homeTab.layout
    );
    const viewTabValidDefaultConfigIds: Set<RibbonId> = getSetOfValidDefaultConfigIds(
        defaultConfig.singleline.viewTab.layout
    );

    const homeTabValid = validateUserConfigLayout(
        homeTabValidDefaultConfigIds,
        singlelineRibbonConfig.homeTab.layout,
        version,
        shouldPrune
    );

    const viewTabValid = validateUserConfigLayout(
        viewTabValidDefaultConfigIds,
        singlelineRibbonConfig.viewTab.layout,
        version,
        shouldPrune
    );

    return homeTabValid && viewTabValid;
}

/**
 * Returns a set of valid config Ids from a specific tab layout. Useful to be called for different tabs.
 * @param layout A MailRibbonGroup[], should be from defaultConfig
 */
function getSetOfValidDefaultConfigIds(layout: MailRibbonGroup[]): Set<RibbonId> {
    const validDefaultConfigIds: Set<RibbonId> = new Set();
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    layout.forEach(group => {
        validDefaultConfigIds.add(group.groupId);
        group.controlIds.forEach(controlId => {
            validDefaultConfigIds.add(controlId);
        });
    });
    return validDefaultConfigIds;
}

/**
 * Go through the user's layout and ensure that all IDs are considered valid.
 * @param validDefaultConfigIds Set of valid RibbonIds from defaultConfig
 * @param userConfigLayout User's layout that we are testing
 * @param version The version of the user's layout, for telemetry purposes
 * @param shouldPrune if true, we will remove ids from the ribbonConfig that are not found in defaultConfig
 * return allValidIds, which will be true if all IDs were valid, and false if it found at least one error
 */
function validateUserConfigLayout(
    validDefaultConfigIds: Set<RibbonId>,
    userConfigLayout: MailRibbonGroup[],
    version: number,
    shouldPrune: boolean | undefined
): boolean {
    let allValidIds: boolean = true;
    for (let i = userConfigLayout.length - 1; i >= 0; i--) {
        const group = userConfigLayout[i];
        if (!isGroupIdValid(validDefaultConfigIds, group.groupId)) {
            logUsage('RibbonValidityCheck_FailedGroupIdValidityCheck', {
                ribbonVersion: version,
                groupId: group.groupId,
            });
            if (shouldPrune) {
                userConfigLayout.splice(i, 1);
                allValidIds = false;
            } else {
                return false;
            }
        } else {
            for (let j = group.controlIds.length - 1; j >= 0; j--) {
                const controlId = group.controlIds[j];
                if (!isControlIdValid(validDefaultConfigIds, controlId)) {
                    logUsage('RibbonValidityCheck_FailedControlIdValidityCheck', {
                        ribbonVersion: version,
                        controlId,
                    });
                    if (shouldPrune) {
                        group.controlIds.splice(j, 1);
                        allValidIds = false;
                    } else {
                        return false;
                    }
                }
            }
        }
    }
    return allValidIds;
}

/**
 * Checks if the groupId is valid, meaning within our current version of defaultConfig or a user-created GroupId.
 * @param validDefaultConfigIds Set of valid RibbonIds from defaultConfig
 * @param groupId The RibbonId we're testing to see if valid
 * @returns true if valid, false if found an invalid RibbonId
 */
function isGroupIdValid(validDefaultConfigIds: Set<RibbonId>, groupId: RibbonId): boolean {
    return isUserCreatedRibbonGroupId(groupId) || validDefaultConfigIds.has(groupId);
}

/**
 * Checks if the controlId is valid, meaning within our current version of defaultConfig.
 * @param validDefaultConfigIds Set of valid RibbonIds from defaultConfig
 * @param controlId The RibbonId we're testing to see if valid
 * @returns true if valid, false if found an invalid RibbonId
 */
function isControlIdValid(validDefaultConfigIds: Set<RibbonId>, controlId: RibbonId): boolean {
    return validDefaultConfigIds.has(controlId);
}
