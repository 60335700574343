import type { MailboxInfo } from 'owa-client-types';
import isFeatureEnabled from './isFeatureEnabled';
import isGetScheduleEnabled from './isGetScheduleEnabled';

export default function (
    mailboxInfo: MailboxInfo,
    dontThrowErrorIfNotInitialized?: boolean
): boolean {
    return (
        isFeatureEnabled('cal-hybrid-working-hours', mailboxInfo, dontThrowErrorIfNotInitialized) &&
        isGetScheduleEnabled(mailboxInfo, dontThrowErrorIfNotInitialized)
    );
}
