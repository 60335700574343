import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { shouldPopulateAddinsForAccount, isCloudCacheAccount } from './utils/populateAddinsUtils';
import { isOffline } from 'owa-offline';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 */
export const populateAddinsCapability: Capability = {
    isEnabled: (mailboxInfo?: MailboxInfo) => {
        const isAddinEnabledForUserAccount =
            !!mailboxInfo &&
            shouldPopulateAddinsForAccount(mailboxInfo) &&
            !isCloudCacheAccount(mailboxInfo);
        return isAddinEnabledForUserAccount;
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        // Accounts, such as PST files and when offline, we cannot make service requests for addin/extensibility
        return !isOffline() && isServiceRequestSupportedForMailbox(mailboxInfo);
    },
};
