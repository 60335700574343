import type { StrictVariables } from 'owa-apollo-types';
import type { TypedDocumentNode, ApolloQueryResult, OperationVariables } from '@apollo/client';
import type { StrictQueryOptions } from './strictQuery';
import { strictQuery } from './strictQuery';

export const query = <
    TSchemaVars extends OperationVariables,
    TActualVars extends StrictVariables<TSchemaVars, TActualVars>,
    TData
>(
    queryDoc: TypedDocumentNode<TData, TSchemaVars>,
    options: StrictQueryOptions<TActualVars, TData> = {}
): Promise<ApolloQueryResult<TData>> => {
    return strictQuery(queryDoc, options);
};
