import type MailSwipeAction from 'owa-service/lib/contract/MailSwipeAction';
import DeleteRegular from 'owa-fluent-icons-svg/lib/icons/DeleteRegular';
import ArchiveRegular from 'owa-fluent-icons-svg/lib/icons/ArchiveRegular';
import PinRegular from 'owa-fluent-icons-svg/lib/icons/PinRegular';
import FlagRegular from 'owa-fluent-icons-svg/lib/icons/FlagRegular';
import MailRegular from 'owa-fluent-icons-svg/lib/icons/MailRegular';
import MailReadRegular from 'owa-fluent-icons-svg/lib/icons/MailReadRegular';
import SubtractCircleRegular from 'owa-fluent-icons-svg/lib/icons/SubtractCircleRegular';

const SWIPE_ACTION_ICONS = {
    [1]: DeleteRegular,
    [2]: ArchiveRegular,
    [3]: PinRegular,
    [5]: FlagRegular,
    [4]: MailRegular,
    [0]: SubtractCircleRegular,
};

export function getIconForSwipeAction(swipeAction: MailSwipeAction, isUnread?: boolean): string {
    // If we're marking an unread item as read, we should show the MailRead icon.
    if (swipeAction === 4 && isUnread) {
        return MailReadRegular;
    }

    return SWIPE_ACTION_ICONS[swipeAction] || '';
}
