import { getStore } from '../store';
import { mutator } from 'satcheljs';
import { setDateFormat } from '../actions';
import { initializeOwaDateTimeStore } from '../actions/internalActions';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(initializeOwaDateTimeStore, ({ DateFormat = '' }) => {
    getStore().DateFormat = DateFormat;
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(setDateFormat, ({ dateFormat }) => {
    getStore().DateFormat = dateFormat;
});
