import type { IOfficeFileLinkHandlingInfo } from 'flora-types';
import { logUsage } from 'owa-analytics';
import { getEnhancedHostExtendedHost } from 'owa-config/lib/enhancedHost';
import type { ExtendedHost } from 'owa-config';

export async function GetOfficeFileLinkHandlingInfo(): Promise<
    IOfficeFileLinkHandlingInfo | undefined
> {
    const extendedHost: ExtendedHost | undefined = getEnhancedHostExtendedHost();

    if (extendedHost) {
        const officeFileLinkHandlingInfo: IOfficeFileLinkHandlingInfo | undefined =
            await extendedHost.FLORA;

        if (
            officeFileLinkHandlingInfo &&
            (await officeFileLinkHandlingInfo.IsUniversalSettingSupported())
        ) {
            logUsage('GetOfficeFileLinkHandlingInfo');
            return officeFileLinkHandlingInfo;
        }
    }

    return undefined;
}
