import CheckmarkRegular from 'owa-fluent-icons-svg/lib/icons/CheckmarkRegular';
import ChevronDownFilled from 'owa-fluent-icons-svg/lib/icons/ChevronDownFilled';
import ChevronDownRegular from 'owa-fluent-icons-svg/lib/icons/ChevronDownRegular';
import ChevronLeftRegular from 'owa-fluent-icons-svg/lib/icons/ChevronLeftRegular';
import ChevronRightRegular from 'owa-fluent-icons-svg/lib/icons/ChevronRightRegular';
import DismissRegular from 'owa-fluent-icons-svg/lib/icons/DismissRegular';
import MoreHorizontalRegular from 'owa-fluent-icons-svg/lib/icons/MoreHorizontalRegular';

export const appIcons = {
    overflowMenuLauncher: MoreHorizontalRegular,
    spinButtonDownChevron: ChevronDownRegular,
    spinButtonUpChevron: ChevronDownRegular,
    checkboxCheckmark: CheckmarkRegular,
    downChevron: {
        icon8: ChevronDownFilled,
        icon12: ChevronDownRegular,
    },
    submenuLauncher: ChevronRightRegular,
    submenuLauncherRtl: ChevronLeftRegular,
    tabListOverflowLauncher: ChevronDownRegular,
    closeIcon: DismissRegular,
};
