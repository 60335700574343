import { trace } from 'owa-trace';
import { Module } from 'owa-workloads';
import { getModuleForTasks, ModuleForTasks } from 'owa-todo-module-utils';
import { M365AppId } from 'owa-m365-acquisitions/lib/data/M365AppId';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export function getM365AppIdForModule(owaModule: Module): M365AppId {
    switch (owaModule) {
        case Module.Mail:
            return M365AppId.Mail;
        case Module.MailDeepLink:
            return M365AppId.Mail;
        case Module.Calendar:
            return M365AppId.Calendar;
        case Module.CalendarDeepLink:
            return M365AppId.Calendar;
        case Module.People:
            return M365AppId.People;
        case Module.Groups:
            return M365AppId.Groups;
        case Module.FilesHub:
            return M365AppId.Files;
        case Module.Tasks:
            return getModuleForTasks(getGlobalSettingsAccountMailboxInfo()) === ModuleForTasks.ToDo
                ? M365AppId.ToDo
                : M365AppId.Tasks;
        default:
            trace.warn(`No module for ${owaModule.toString()}`);
            return M365AppId.Mail;
    }
}
