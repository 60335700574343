import type {
    Disposable,
    IEnvironment,
    MutationConfig,
    MutationParameters,
    CacheConfig,
    FetchPolicy,
    GraphQLTaggedNode,
    OperationType,
    RenderPolicy,
    VariablesOf,
    SelectorStoreUpdater,
    UploadableMap,
    DeclarativeMutationConfig,
    PayloadError,
} from 'relay-runtime';

/* eslint-disable @typescript-eslint/no-restricted-imports --
 * Relay hook imports are allowed only in Nova GraphQL integration
 *	> 'useMutation', 'useFragment', 'useLazyLoadQuery', 'useRefetchableFragment', 'usePaginationFragment' imports are restricted from being used. */
import {
    useMutation as relayUseMutation,
    useLazyLoadQuery as relayUseLazyLoadQuery,
    useFragment as relayUseFragment,
    useRefetchableFragment as relayUseRefetchableFragment,
    usePaginationFragment as relayUsePaginationFragment,
    useSubscription as relayUseSubscription,
} from 'react-relay';
/* eslint-enable @typescript-eslint/no-restricted-imports  */

import { debugErrorThatWillShowErrorPopupOnly } from 'owa-trace';

export function useLazyLoadQuery<TQuery extends OperationType>(
    gqlQuery: GraphQLTaggedNode,
    variables: VariablesOf<TQuery>,
    options?: {
        fetchKey?: string | number | undefined;
        fetchPolicy?: FetchPolicy | undefined;
        networkCacheConfig?: CacheConfig | undefined;
        UNSTABLE_renderPolicy?: RenderPolicy | undefined;
        context?: Record<string, any>;
    }
): {
    error?: Error;
    data?: TQuery['response'];
} {
    const data = relayUseLazyLoadQuery(gqlQuery, JSON.parse(JSON.stringify(variables)), {
        ...options,
        networkCacheConfig: {
            ...(options?.networkCacheConfig || {}),
            metadata: {
                ...(options?.networkCacheConfig?.metadata || {}),
                context: options?.context || {},
            },
        },
    });

    return { data };
}

export function graphql(_document: TemplateStringsArray): GraphQLTaggedNode {
    debugErrorThatWillShowErrorPopupOnly(
        'graphql: Unexpected call to graphql. This should have been compiled with Relay compiler and replaced with embded document loader plugin.'
    );
    return {} as GraphQLTaggedNode;
}

export const useFragment = (fragmentInput: GraphQLTaggedNode, fragmentRef: any) => {
    return relayUseFragment(fragmentInput, fragmentRef);
};

export const useRefetchableFragment = (fragmentInput: GraphQLTaggedNode, fragmentRef: any) => {
    return relayUseRefetchableFragment(fragmentInput as any, fragmentRef as any);
};

export const usePaginationFragment = (fragmentInput: GraphQLTaggedNode, fragmentRef: any) => {
    return relayUsePaginationFragment(fragmentInput as any, fragmentRef as any) as any;
};

export function useSubscription<TSubscription extends OperationType>(config: {
    cacheConfig?: CacheConfig | undefined;
    configs?: readonly DeclarativeMutationConfig[] | undefined;
    subscription: GraphQLTaggedNode;
    variables: {
        [name: string]: unknown;
    };
    context?: {
        [name: string]: unknown;
    };
    onCompleted?: (() => void) | undefined;
    onError?: ((error: Error) => void) | undefined;
    onNext?: ((response: TSubscription['response'] | null | undefined) => void) | undefined;
    updater?: SelectorStoreUpdater<TSubscription['response']> | undefined;
}) {
    return relayUseSubscription<TSubscription>({
        ...config,
        cacheConfig: {
            ...(config?.cacheConfig || {}),
            metadata: {
                ...(config?.cacheConfig?.metadata || {}),
                context: config?.context || {},
            },
        },
    });
}

type OwaUseMutationConfig<TMutation extends MutationParameters> = {
    variables: VariablesOf<TMutation>;
    updater?: SelectorStoreUpdater<TMutation['response']> | null | undefined;
    uploadables?: UploadableMap | undefined;
    optimisticUpdater?: SelectorStoreUpdater<TMutation['response']> | null | undefined;
    optimisticResponse?: TMutation['rawResponse'] | undefined;
    configs?: DeclarativeMutationConfig[] | undefined;
    onError?: ((error: Error) => void | null) | undefined;
    onCompleted?:
        | ((response: TMutation['response'], errors: PayloadError[] | null) => void | null)
        | undefined;
    onUnsubscribe?: (() => void | null) | undefined;
    context?: Record<string, any>;
};

export function useMutation<TMutation extends MutationParameters>(
    mutation: GraphQLTaggedNode,
    commitMutationFn?: (environment: IEnvironment, config: MutationConfig<TMutation>) => Disposable
): [(config: OwaUseMutationConfig<TMutation>) => Disposable, boolean] {
    const [commitMutation, isInFlight] = relayUseMutation(mutation, commitMutationFn);

    const comFn = (config: OwaUseMutationConfig<TMutation>): Disposable => {
        return commitMutation({
            ...config,
            cacheConfig: {
                metadata: {
                    context: config.context || {},
                },
            },
        } as any);
    };

    return [comFn, isInFlight];
}
