import type { MailboxInfo } from 'owa-client-types';
import { action } from 'satcheljs';

export const featureFlagsInitialized = action(
    'FEATURE_FLAGS_INITIALIZED',
    (enabledFlights: string[], mailboxInfo?: MailboxInfo) => ({
        enabledFlights,
        mailboxInfo,
    })
);
