import type { ConversationType } from 'owa-graph-schema';
import type ItemId from 'owa-service/lib/contract/ItemId';
import type { TableView } from 'owa-mail-list-store';
import type TableViewConversationRelation from 'owa-mail-list-store/lib/store/schema/TableViewConversationRelation';
import getShouldShowRSVPForConversationAndPrepareItem from 'owa-listview-rsvp/lib/utils/getShouldShowRSVPForConversationAndPrepareItem';

function getEffectiveMentioned(conversationType: ConversationType): boolean {
    return conversationType.GlobalMentionedMe || conversationType.GlobalAtAllMention || false;
}

export default function mapConversationTypeToConversationRelation(
    conversationType: ConversationType,
    tableView: TableView
): TableViewConversationRelation {
    return {
        categories: conversationType.Categories ?? [],
        draftItemIds: conversationType.DraftItemIds
            ? conversationType.DraftItemIds.map(baseDraftItemId => (<ItemId>baseDraftItemId).Id)
            : [],
        effectiveMentioned: getEffectiveMentioned(conversationType),
        executeSearchSortOrder: conversationType.SortOrderSource ?? 'DateTime',
        flagStatus: conversationType.FlagStatus ?? 'NotFlagged',
        hasAttachments: !!conversationType.HasAttachments,
        isTaggedForBigScreen: !!conversationType.IsTaggedForBigScreen,
        hasIrm: !!conversationType.HasIrm,
        iconIndex: conversationType.IconIndex ?? 'Default',
        id: conversationType.ConversationId.Id,
        importance: conversationType.Importance ?? 'Normal',
        instanceKey: conversationType.InstanceKey ?? '',
        itemClasses: conversationType.ItemClasses ?? [],
        itemIds: conversationType.ItemIds?.map(baseItemId => (<ItemId>baseItemId).Id) ?? [],
        lastDeliveryOrRenewTimeStamp: conversationType.LastDeliveryOrRenewTime,
        lastDeliveryTimeStamp: conversationType.LastDeliveryTime,
        lastSentTimeStamp: conversationType.LastSentTime || conversationType.LastDeliveryTime, // LastSentTime is undefined for search result. Fallback to LastDeliveryTime
        lastModifiedTimeStamp: conversationType.LastModifiedTime ?? '',
        lastSender: conversationType.LastSender
            ? conversationType.LastSender
            : conversationType.From
            ? conversationType.From
            : undefined, // lastSender is null in search response, use From instead
        parentFolderId: conversationType.ParentFolderId ? conversationType.ParentFolderId.Id : '', // This value only exists in search items
        previewText: conversationType.Preview ?? '',
        returnTime: conversationType.ReturnTime ? conversationType.ReturnTime : '',
        uniqueRecipients: conversationType.UniqueRecipients ?? [],
        uniqueSenders: conversationType.UniqueSenders ?? [],
        unreadCount: conversationType.UnreadCount,
        shouldShowRSVP: getShouldShowRSVPForConversationAndPrepareItem(conversationType, tableView),
        mailboxGuids: conversationType.MailboxGuids ?? [],
        instrumentationContext: null,
        conversationToMe: conversationType.ConversationToMe ?? null,
        conversationCcMe: conversationType.ConversationCcMe ?? null,
        copilotInboxScore: conversationType.ConversationCopilotInboxScore ?? -1,
        copilotInboxHeadline: undefined,
    };
}
