import { logUsage } from 'owa-analytics';

const Prefix = 'data:image/';
const Base64 = ';base64,';
const PrefixLength = 11; // Length of "data:image/"
const ContentTypeStartIndex = 5; // Length of "data:"
const Base64Length = 8; // Length of ";base64,"

export function parseDataUri(source: string): {
    dataUri: string;
    contentType: string;
} | null {
    const base64Index = source.indexOf(Base64);

    if (source.startsWith(Prefix) && base64Index > PrefixLength) {
        const contentType = source.substring(ContentTypeStartIndex, base64Index);
        const dataUri = source.substring(ContentTypeStartIndex + contentType.length + Base64Length);

        return { contentType, dataUri };
    } else {
        return null;
    }
}

export default function createFileFromImageSource(source: string): File | null {
    let file: File | null = null;

    const parseResult = parseDataUri(source);

    if (parseResult) {
        const { contentType, dataUri } = parseResult;
        let byteString: string | null = null;

        try {
            byteString = atob(dataUri);
        } catch (error) {
            logUsage('createFileFromImageSource', [error.message]);
        }

        if (byteString) {
            const arrayBuilder = new ArrayBuffer(byteString.length);
            const unit8Array = new Uint8Array(arrayBuilder);

            for (let i = 0; i < byteString.length; i++) {
                unit8Array[i] = byteString.charCodeAt(i);
            }

            file = new File([arrayBuilder], 'image.png', { type: contentType });
        }
    } else {
        logUsage('createFileFromImageSource', ['Fail to parse source url']);
    }

    return file;
}
