import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "HelpCharm" */ './lazyIndex'), {
    name: 'HelpCharm',
});

export const lazyHandleSocPostMessageEvents = new LazyAction(
    lazyModule,
    m => m.handleSocPostMessageEvents
);
