import type {
    LinkedCalendarEntry,
    LinkedCalendarEntryV2,
    LocalCacheForRemoteCalendarEntry,
    LocalCalendarEntry,
} from 'owa-graph-schema';
import { isLinkedCalendarEntry } from './isLinkedCalendarEntry';
import { isLinkedCalendarEntryV2 } from './isLinkedCalendarEntryV2';

export function isFreeBusyPopulatedCalendar(
    calendarEntry:
        | LocalCalendarEntry
        | LinkedCalendarEntry
        | LocalCacheForRemoteCalendarEntry
        | LinkedCalendarEntryV2
): boolean {
    /* A linked calendar entry who has shared their primary calendar */
    const isPrimarySharedCalendar =
        (!calendarEntry.FolderId || !calendarEntry.FolderId.Id) &&
        (isLinkedCalendarEntry(calendarEntry) || isLinkedCalendarEntryV2(calendarEntry));

    /* A non-group linked calendar entry for whom one does not have permissions to access */
    const isNonGroupNoPermission =
        // Isn't a group calendar and there are no effective rights assigned
        (!calendarEntry.IsGroupMailboxCalendar && !calendarEntry.EffectiveRights) ||
        // Or we don't have effective rights to read the calendar and this is the default calendar of the owner
        !!(
            (!calendarEntry.EffectiveRights || !calendarEntry.EffectiveRights?.Read) &&
            ((calendarEntry as LinkedCalendarEntry).IsGeneralScheduleCalendar ||
                (calendarEntry as LinkedCalendarEntryV2).IsGeneralScheduleCalendar)
        );

    // There must be an identified owner and this should pass any of the checks above to be considered a free busy populated calendar
    return (
        !!(
            (calendarEntry as LinkedCalendarEntry).OwnerEmailAddress ||
            (calendarEntry as LinkedCalendarEntryV2).OwnerEmailAddress
        ) &&
        (isPrimarySharedCalendar || isNonGroupNoPermission)
    );
}
