import type { PreviewPaneMode, PreviewPaneViewStateBase } from './PreviewPaneViewState';

interface MsPdfPreviewState extends PreviewPaneViewStateBase {
    mode: PreviewPaneMode.MsPdf;
    previewUrl: string | null;
    data: Uint8Array | null;
    printDisabled: boolean;
    copyAllowed: boolean;
    exportAllowed: boolean;
    passwordState?: MsPdfPasswordState;
}

export enum MsPdfPasswordState {
    Default = 0,
    NeedPassword = 1,
    IncorrectPassword = 2,
}

export default MsPdfPreviewState;
