import type TableViewItemRelation from '../store/schema/TableViewItemRelation';
import getTableToRowRelationKey from './getTableToRowRelationKey';
import getListViewStore from '../store/Store';

/**
 * Get the table view item relation based on the rowKey and tableView
 * @param itemRowKey the message/item rowKey
 * @param tableViewId id of the table view
 * @returns the table view item relation
 * Note that relation could return null, when we try to append conversations in seek to FindConversation response and user has already
 * deleted the first conversation in response
 */
export default function getTableItemRelation(
    itemRowKey: string,
    tableViewId: string
): TableViewItemRelation {
    const relationKey = getTableToRowRelationKey(itemRowKey, tableViewId);
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (21,5): Type 'TableViewItemRelation | undefined' is not assignable to type 'TableViewItemRelation'.
    // @ts-expect-error
    return getListViewStore().tableViewItemRelations.get(relationKey);
}
