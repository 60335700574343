import { GraphQLError } from 'graphql';
import { IdbFallbackReason } from './IdbFallbackReason';
import type { IdbFallbackResult } from './IdbFallbackResult';

export const idbNotSupportedResult = (msg: string): IdbFallbackResult => {
    const extensions = {
        idbFallbackReason: IdbFallbackReason.NotSupportedByIdb,
        message: msg ?? '',
    };

    const err = new GraphQLError(
        extensions.message,
        null,
        null,
        null,
        null,
        null,
        extensions
    ) as Required<GraphQLError>;
    return err;
};
