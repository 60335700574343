import { setAnimationPreferenceMutator } from '../mutators/setAnimationPreferenceMutator';
import { getStore } from '../store/Store';
import type { MailLayoutOptions } from 'owa-outlook-service-option-store';
import { lazyCreateOrUpdateOptionsForFeature } from 'owa-outlook-service-options';
import {
    type AnimationPreference,
    getOptionsForFeature,
    OwsOptionsFeatureType,
} from 'owa-outlook-service-option-store';
import { setShouldAnimateMutator } from '../mutators/setShouldAnimateMutator';

const query = '(prefers-reduced-motion: no-preference)';
const mediaQueryList = window.matchMedia(query);

export function setAnimationPreference(animationPreference: AnimationPreference) {
    // Get existing settings on server
    const userOptions = getOptionsForFeature<MailLayoutOptions>(OwsOptionsFeatureType.MailLayout);
    // Update settings on server
    lazyCreateOrUpdateOptionsForFeature.importAndExecute(OwsOptionsFeatureType.MailLayout, {
        ...userOptions,
        animationPreference,
    } as MailLayoutOptions);

    // Update store

    if (animationPreference == 0) {
        mediaQueryList.addListener(handleMotionPreferenceChange);
        setShouldAnimateMutator(mediaQueryList.matches);
        setAnimationPreferenceMutator(animationPreference);
    } else {
        if (getStore().animationPreference == 0) {
            mediaQueryList.removeListener(handleMotionPreferenceChange);
        }
        setShouldAnimateMutator(animationPreference == 1);
        setAnimationPreferenceMutator(animationPreference);
    }
}

function handleMotionPreferenceChange(this: MediaQueryList) {
    if (this.matches != getStore().shouldAnimate) {
        setShouldAnimateMutator(this.matches);
    }
}
