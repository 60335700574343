import { ObservableMap as ObservableMapClass } from 'mobx';
import type { IObservableMapInitialValues, IEnhancer } from 'mobx';
import type ClientItem from './ClientItem';

export class ClientItemObservableMap<K = string, V = ClientItem> extends ObservableMapClass<K, V> {
    private beforeSetFunction: ((value: V) => void) | undefined;
    constructor(
        initialData?: IObservableMapInitialValues<K, V>,
        enhancer?: IEnhancer<V>,
        name?: string,
        beforeSetFunction?: (value: V) => void
    ) {
        super(initialData, enhancer, name);
        this.beforeSetFunction = beforeSetFunction;
    }

    override set(key: K, value: V): this {
        this.beforeSetFunction?.(value);
        return super.set(key, value);
    }
}

export default ClientItemObservableMap;
