import { lazyMountAndShowPhotoPickerDialog } from 'owa-photo-picker-dialog';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export default async function changePhotoRouteHandler() {
    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();
    const accountScopeUserSettings = getAccountScopeUserSettings(mailboxInfo);
    if (!accountScopeUserSettings.SegmentationSettings?.SetPhoto) {
        return;
    }

    await lazyMountAndShowPhotoPickerDialog.importAndExecute(mailboxInfo);
}
