import { shouldTableSupportFlag } from './shouldTableSupportFlag';
import shouldShowFolderTag from '../selectors/shouldShowFolderTag';
import { isGroupTableQuery } from 'owa-group-utils';
import { isSingleLineListView } from 'owa-mail-layout';
import type { MailListTableProps } from 'owa-mail-list-item-shared';
import { shouldTableShowCirclePersonas } from 'owa-mail-list-item-shared';
import { shouldTableSortByRenewTime } from 'owa-mail-triage-common';
import { isDumpsterSearchTable } from 'owa-mail-list-search';
import {
    isDumpsterTable,
    getStore as getListViewStore,
    type TableQueryType,
} from 'owa-mail-list-store';
import { getStore as getMailSearchStore } from 'owa-mail-search/lib/store/store';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import type { PrimaryMailboxSearchScope } from 'owa-search-service/lib/data/schema/SearchScope';
import { isCapabilityEnabled } from 'owa-capabilities';
import { mailboxModificationCapability } from 'owa-capabilities-definitions/lib/mailboxModificationCapability';
import getMailboxInfo from 'owa-mail-mailboxinfo/lib/getMailboxInfo';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { logUsage } from 'owa-analytics';

export default function getMailListTableProps(tableViewId: string): MailListTableProps {
    const tableView = getListViewStore().tableViews.get(tableViewId);
    const mailboxInfo = getMailboxInfo(tableView);
    const isMailboxModifiable = isCapabilityEnabled(mailboxModificationCapability, mailboxInfo);
    const tableQuery = tableView?.tableQuery;

    // If there's no table view in the store, return a set of default props so
    // we don't crash the app.
    if (!tableQuery) {
        logUsage('getMailListTableProps_MissingTableView', { tableViewId });

        return {
            tableViewId,
            supportsPinning: false,
            supportsFlagging: false,
            showCirclePersonas: false,
            showPreviewText: false,
            isSearchNotInDraftOrSentItemFolder: false,
            isSingleLine: false,
            highlightTerms: [],
            listViewType: 1,
            showCondensedPinnedRows: false,
            supportsHoverIcons: false,
            supportsDoubleClick: false,
            showFolderTag: false,
            supportsSnooze: false,
            canDragFromTable: false,
            isDraggable: false,
            supportsMove: false,
        };
    }

    const isDumpsterOrDumpsterSearchTable =
        isDumpsterTable(tableQuery) || isDumpsterSearchTable(tableQuery);
    const tableSupportsPinning = shouldTableSortByRenewTime(tableQuery) && isMailboxModifiable;
    const tableSupportsSnooze = !isGroupTableQuery(tableQuery) && isMailboxModifiable;
    const staticSearchScope = getMailSearchStore().staticSearchScope;

    // Show condensed pinned rows only if table supports pinning and is of type Folder
    // as Search table also supports pinning
    const tableSupportsCondensedPinnedRows =
        tableView.tableQuery.type === 0 && tableSupportsPinning;

    // The ability to show preview text in list view can be disabled by OWA policy setting
    const showPreviewTextDisabled =
        getUserConfiguration().PolicySettings?.MessagePreviewsDisabled == true;

    // As we do not support moving all items in the folder, we disable dragging
    // in select all mode. We also disable dragging from Group table as that is not supported.
    const canDragFromTable =
        (tableQuery.folderId &&
            folderIdToName(tableQuery.folderId) !== 'notes' &&
            !tableView.isInVirtualSelectAllMode &&
            tableView.tableQuery.type == 0 &&
            isMailboxModifiable) ||
        tableView?.tableQuery.type == 2 ||
        tableView?.tableQuery.type == 3 ||
        tableView.tableQuery.type == 1;

    const searchScopeFolderName = (staticSearchScope as PrimaryMailboxSearchScope)?.folderId
        ? folderIdToName((staticSearchScope as PrimaryMailboxSearchScope).folderId)
        : undefined;

    const isSearchNotInDraftOrSentItemFolder =
        tableView?.tableQuery?.type == 1 &&
        searchScopeFolderName != 'sentitems' &&
        searchScopeFolderName != 'drafts';

    const folderName = tableQuery.folderId ? folderIdToName(tableQuery.folderId) : undefined;
    const supportsMove = folderName
        ? !isDumpsterOrDumpsterSearchTable &&
          folderName != 'notes' &&
          folderName != 'drafts' &&
          folderName != 'outbox' &&
          isMailboxModifiable
        : false;

    const supportsHoverIcons = !isDumpsterOrDumpsterSearchTable && isMailboxModifiable;

    return {
        tableViewId,
        supportsPinning: tableSupportsPinning,
        supportsFlagging: shouldTableSupportFlag(tableQuery),
        showCirclePersonas: shouldTableShowCirclePersonas(tableQuery),
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (84,9): Type 'boolean | undefined' is not assignable to type 'boolean'.
        // @ts-expect-error
        showPreviewText:
            getUserConfiguration().UserOptions?.ShowPreviewTextInListView &&
            !showPreviewTextDisabled,
        isSearchNotInDraftOrSentItemFolder,
        isSingleLine: isSingleLineListView(),
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (95,9): Type 'string[] | undefined' is not assignable to type 'string[]'.
        // @ts-expect-error
        highlightTerms: tableView.highlightTerms,
        listViewType: tableQuery.listViewType,
        showCondensedPinnedRows: tableSupportsCondensedPinnedRows,
        supportsHoverIcons,
        supportsDoubleClick: !isDumpsterOrDumpsterSearchTable,
        showFolderTag: shouldShowFolderTag(tableQuery),
        supportsSnooze: tableSupportsSnooze,
        canDragFromTable,
        isDraggable: true,
        supportsMove,
    };
}
