import type { ConditionalFormattingModification } from '../store/schema/ConditionalFormattingRule';
import { ConditionalFormattingModificationType } from '../store/schema/ConditionalFormattingModificationType';
import { getIsDarkTheme } from 'owa-fabric-theme';
import { lightColorCodeValueMap } from 'owa-categories/lib/store/helpers/getLightThemeColorCodeValueMap';
import { darkColorCodeValueMap } from 'owa-categories/lib/store/helpers/getDarkThemeColorCodeValueMap';

// This will be called whenever we need to apply conditional formatting modifications to any text (eg. timestamp, 2nd row/column of mail list item)
export function getConditionalFormattingStylesForText(
    conditionalFormattingModifications: ConditionalFormattingModification[]
): React.CSSProperties | undefined {
    // Currently building support to only check the first item in the array since we can only apply one modification as we only support one modification type.
    const { type, value } = conditionalFormattingModifications[0];

    switch (type) {
        case ConditionalFormattingModificationType.Color:
            const textColor = getIsDarkTheme()
                ? darkColorCodeValueMap[value].iconColor
                : lightColorCodeValueMap[value].iconColor;
            return {
                color: textColor,
            };

        default:
            return undefined;
    }
}
