import { isFeatureEnabled } from 'owa-feature-flags';
import { isPollAddinAppId } from 'owa-m365-acquisitions/lib/utils/isPollAddinAppId';

import type { AddinCommandSurfaceItem } from 'owa-addins-types';
import type { MailboxInfo } from 'owa-client-types';
import { isFluidEnabledForSource, LoopType } from 'owa-fluid-validations';

/**
 * Hide pinned Polls add-in when native Polls add-in is present.
 * @param addIn
 * @returns boolean
 */
function storePollFilter(addIn: AddinCommandSurfaceItem, mailboxInfo: MailboxInfo): boolean {
    const isNativePollsPresent =
        isFeatureEnabled('cmp-fluid-chapterThree') &&
        isFluidEnabledForSource('MailCompose', mailboxInfo, LoopType.Poll);
    if (isNativePollsPresent && isPollAddinAppId(addIn.key)) {
        return false;
    }
    return true;
}

/**
 * Removes add-ins entries that are not buttons.
 * @param addIn
 * @returns
 */
function invalidAddInsFilter(addIn: AddinCommandSurfaceItem): boolean {
    return (
        addIn.key !== 'AddinMarketplace' &&
        addIn.key !== 'AddinMarketplaceDivider' &&
        addIn.key !== 'AddinsHeader'
    );
}

export { invalidAddInsFilter, storePollFilter };
