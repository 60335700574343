import { createStore } from 'satcheljs';
import type MailLayoutStore from './schema/MailLayoutStore';
import { type AnimationPreference } from 'owa-outlook-service-option-store';

const mailLayoutStore: MailLayoutStore = {
    clientReadingPanePosition: null,
    showReadingPane: false,
    showListPane: true,
    showFolderPane: true,
    useSingleLineMessageListWithRightReadingPane: true,
    animationPreference: 0,
    shouldAnimate: false,
    senderColumnWidth: null,
    subjectColumnWidth: null,
    receivedColumnWidth: null,
};

const store = createStore<MailLayoutStore>('maillayout', mailLayoutStore)();
export const getStore = () => store;
