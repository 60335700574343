import type { RibbonId } from 'owa-ribbon-ids/lib/ribbonId';
import { owaComputedFn } from 'owa-computed-fn';
import type { OnExecuteParameter } from '@1js/acui-common';
import { assertNever } from 'owa-assert';

/**
 * Map to ensure each computedFn only gets generated once.
 * For most cases, use RibbonId. Type `string` is for dynamically generated buttons.
 */
const onExecuteMap = new Map<RibbonId | string, (...args: any[]) => any>();

/**
 * `getComputedCallback` takes in a function and wraps it in a `owaComputedFn(() => fn)`.
 * This is used because @1js's onExecute expects a () => {} function callback, however simply using () => fn will
 * create a new object and cuse unnecessry re-renders, and thus needs to be memoized.
 *
 * Use this function for all onExecute() callbacks within Ribbon code.
 * Usage Example:
 *   If calling func(a, b, c) for RibbonId.Test, then: getComputedCallback(RibbonId.Test, func, a, b, c);
 *
 * @param id The RibbonId of the control that is being executed. Keep it at its unique base ID when possibe.
 *           Popout ribbons that use ids appended with the current window should just use the ID.
 * @param fn The callback function that should be executed when the button is executed.
 * @param props The properties that should be passed into the callback function.
 *
 * Usage Example:
 *  If calling func(a, b, c) for RibbonId.Test, then: getComputedCallback(RibbonId.Test, func, a, b, c);
 */
export const getComputedCallback = <TCallbackArgs extends unknown[], TCallbackType>(
    id: RibbonId | string,
    fn: (...props: TCallbackArgs) => TCallbackType,
    ...props: TCallbackArgs
): (() => (...returnProps: TCallbackArgs) => TCallbackType) => {
    if (!onExecuteMap.has(id)) {
        onExecuteMap.set(
            id,
            owaComputedFn((...innerProps: TCallbackArgs) => {
                return () => fn(...innerProps);
            })
        );
    }
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return onExecuteMap.get(id)!(...props);
};

export const getComputedCallbackWith1JsParam = <TCallbackArgs extends unknown[], TCallbackType>(
    id: RibbonId | string,
    fn: (onExecuteParameter: OnExecuteParameter, ...props: TCallbackArgs) => TCallbackType,
    ...props: TCallbackArgs
): ((
    onExecuteParameter: OnExecuteParameter
) => (onExecuteParameter: OnExecuteParameter, ...returnProps: TCallbackArgs) => TCallbackType) => {
    if (!onExecuteMap.has(id)) {
        onExecuteMap.set(
            id,
            owaComputedFn((...innerProps: TCallbackArgs) => {
                return (onExecuteParameter: OnExecuteParameter) =>
                    fn(onExecuteParameter, ...innerProps);
            })
        );
    }

    const result = onExecuteMap.get(id)?.(...props);
    if (result) {
        return result;
    } else {
        assertNever(result as never);
    }
};
