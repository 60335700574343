import { mutatorAction } from 'satcheljs';
import type {
    EditOnSend,
    AddinViewState,
} from 'owa-editor-addin-plugin-types/lib/utils/AddinViewState';

export default mutatorAction(
    'appendOnSendAction',
    (viewState: AddinViewState, prop: EditOnSend) => {
        const appendOnSend = viewState.appendOnSend;
        if (!appendOnSend) {
            return;
        }
        let foundAndReplaced = false;
        for (let i = 0; i < appendOnSend.length; i++) {
            if (appendOnSend[i].id === prop.id) {
                // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                // -> Error TS2532 (17,17): Object is possibly 'undefined'.
                // @ts-expect-error
                viewState.appendOnSend[i] = prop;
                foundAndReplaced = true;
                break;
            }
        }
        if (!foundAndReplaced) {
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2532 (26,13): Object is possibly 'undefined'.
            // @ts-expect-error
            viewState.appendOnSend.push(prop);
        }
    }
);
