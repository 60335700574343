import type { OWAConnectedAccount } from './schema/OWAConnectedAccount';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - satchel stores in the worker will need their data synced
 *	> 'createStore' import from 'satcheljs' is restricted. Satchel stores should not be created in the worker as the data will need to be synced */
import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';
import { AccountsStoreState } from './schema/AccountsStoreState';

const owaAccountsStore = createStore('OWAAccountsStore', {
    accountsMap: new ObservableMap<string, OWAConnectedAccount>({}),
    accountsLoadedState: AccountsStoreState.None,
})();

const getOWAAccountsStore = () => owaAccountsStore;
export default getOWAAccountsStore;
