import { PinnablePane } from 'owa-outlook-service-option-store';
import { flexPaneClosed } from 'owa-suite-header-apis';
import { orchestrator } from 'satcheljs';
import { getPinnablePaneRegistration } from '../utils/getPinnablePaneRegistrations';
import { getPinnedPaneSetting } from '../utils/getPinnedPaneSetting';
import { isPanePinningEnabled } from '../utils/isPanePinningEnabled';
import { savePinnedPaneSetting } from '../utils/savePinnedPaneSetting';

// if closed flex pane was a pinnable pane, silently clear user settings to stop pinning it
orchestrator(flexPaneClosed, ({ flexPaneId }) => {
    if (!isPanePinningEnabled()) {
        return;
    }

    // if there is no pinned pane in settings, there is nothing to manage
    const pinnedPaneSetting = getPinnedPaneSetting();
    if (pinnedPaneSetting === PinnablePane.None) {
        return;
    }

    const pinnedPaneRegistration = getPinnablePaneRegistration(pinnedPaneSetting);
    if (flexPaneId === pinnedPaneRegistration.flexPaneId) {
        savePinnedPaneSetting(PinnablePane.None);
    }
});
