export type SignoutPromiseCallback = () => Promise<any>;

export const signoutPromisesCallbacks: SignoutPromiseCallback[] = [];

export function addSignoutPromise(callback: SignoutPromiseCallback) {
    signoutPromisesCallbacks.push(callback);
}

export function executeSignoutPromises(): Promise<any>[] {
    return signoutPromisesCallbacks.map(cb => cb());
}
