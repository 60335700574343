import type { MailboxInfo } from 'owa-client-types';
import type CategoryType from 'owa-service/lib/contract/CategoryType';
import { getAccountScopeUserSettings, type ReadOnlyAccountConfiguration } from 'owa-session-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { isCapabilityEnabled } from 'owa-capabilities';
import { categoryListCapability } from 'owa-capabilities-definitions/lib/categoryListCapability';

/**
 * Gets the MasterCategoryList for the given mailboxInfo.
 * @param mailboxInfo The mailbox to fetch the user configuration for
 */
export default function getMasterCategoryList(mailboxInfo: MailboxInfo): CategoryType[] {
    let userConfiguration: ReadOnlyAccountConfiguration;

    if (!isCapabilityEnabled(categoryListCapability, mailboxInfo)) {
        // if the account doesn't support a category list of its own, fall back to using the
        // category list of the global settings account
        userConfiguration = getAccountScopeUserSettings(getGlobalSettingsAccountMailboxInfo());
    } else {
        userConfiguration = getAccountScopeUserSettings(mailboxInfo);
    }

    const masterCategoryList = userConfiguration?.MasterCategoryList;
    return <CategoryType[]>masterCategoryList?.MasterList || [];
}
