import { getIsViewModeSelected } from 'owa-command-ribbon-store';
import type { CommandingViewActionSource } from 'owa-analytics-types';
import { type CommandingViewMode } from 'owa-outlook-service-option-store/lib/store/schema/options/CommandingOptions';
import { errorThatWillCauseAlert } from 'owa-trace';

// This function converts the CommandingViewMode into a string meant for telemetry purposes.
// The names of the string we want to use for telemetry may differ than the
// string we use internally in our code base.
export default function getActionSourceFromCommandingViewMode(): CommandingViewActionSource {
    if (getIsViewModeSelected(1)) {
        return 'SimplifiedRibbon';
    }

    if (getIsViewModeSelected(2)) {
        return 'MultiLineRibbon';
    }

    errorThatWillCauseAlert('Unknown view mode selected in getActionSourceFromCommandingViewMode');
    return 'SimplifiedRibbon';
}
