import type { MailboxInfo } from 'owa-client-types';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getApp } from 'owa-config';
import { errorThatWillCauseAlert } from 'owa-trace';
import { getOfflineSettingDisabledReason } from './isOfflineSettingEnabled';
import { getOfflineSyncUnavailableReason } from './isOfflineSyncAvailable';
import type { OfflineDisabledReason } from './OfflineDisabledReason';

export function isOfflineSyncEnabled(mailboxInfo?: MailboxInfo | null): boolean {
    return getOfflineSyncDisabledReason(mailboxInfo) === undefined;
}

export function getOfflineSyncDisabledReason(
    mailboxInfo?: MailboxInfo | null
): OfflineDisabledReason | undefined {
    if (!isOfflineSyncEnabledForApp()) {
        return 'App';
    }

    return (
        getOfflineSyncUnavailableReason() ||
        getOfflineSettingDisabledReason(mailboxInfo || getGlobalSettingsAccountMailboxInfo())
    );
}

// We only want to sync in apps that use local data. Enabling offline based on the first app seems good enough while avoiding the complexity of starting and stopping sync when switching apps.
let firstApp: string | undefined;
export function isOfflineSyncEnabledForApp() {
    if (!firstApp) {
        firstApp = getApp();
        if (!firstApp) {
            errorThatWillCauseAlert(
                'IsOfflineSyncEnabled called before we know what app is running'
            );
        }
    }

    return doesAppSupportOfflineSync(firstApp);
}

export function resetFirstAppForTest() {
    firstApp = undefined;
}

function doesAppSupportOfflineSync(app: string) {
    switch (app) {
        case 'Mail':
        case 'MailDeepLink':
        case 'Calendar':
        case 'CalendarDeepLink':
        case 'People':
        case 'Places':
        case 'BackgroundSync':
            return true;
        default:
            return false;
    }
}
