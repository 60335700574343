import { action } from 'satcheljs';
import type { OpenPstScenario } from '../store/schema/OpenPstFileExperienceStore';
import type { AccountConfigDialogLaunchOrigin } from 'owa-account-config-shared';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Dispatch this action to render the remediation experience to attempt to
 * get the given PST account into a healthy state
 */
export const remediatePstAccount = action(
    'remediatePstAccount',
    (
        mailboxInfo: MailboxInfo,
        targetWindow: Window,
        entryPoint?: AccountConfigDialogLaunchOrigin
    ) => ({
        scenario: 'RemediatePstAccount' as OpenPstScenario,
        mailboxInfo,
        targetWindow,
        entryPoint,
    })
);
