import type { ConditionalFormattingModification } from '../store/schema/ConditionalFormattingRule';
import { getConditionalFormattingStore } from '../store/store';
import type { MailboxInfo } from 'owa-client-types';

export default function getConditionalFormattingModificationForRow(
    key: string,
    mailboxInfo: MailboxInfo
): ConditionalFormattingModification[] {
    const rowToModifications = getConditionalFormattingStore(mailboxInfo).rowToModificationsMap;
    return rowToModifications.get(key) ?? [];
}
