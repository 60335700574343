import { getStore } from '../store/store';

/**
 * Returns whether a FlexPane is shown.
 * If `flexPaneId` is supplied, will return whether that specific FlexPane is shown.
 * If not supplied, will simply return whether the OWA FlexPane is shown in general.
 */
export function isFlexPaneShown(flexPaneId?: string): boolean {
    if (flexPaneId) {
        return getStore().flexPaneStateMap.get(flexPaneId)?.isShown ?? false;
    }
    return getStore().currentFlexPaneState.isShown;
}
