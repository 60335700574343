import { mutatorAction } from 'satcheljs';
import type {
    EditOnSend,
    AddinViewState,
} from 'owa-editor-addin-plugin-types/lib/utils/AddinViewState';

/*
 * Update if already exists or create prepend on send data in viewState
 */
export default mutatorAction(
    'prependOnSendAction',
    (viewState: AddinViewState, prop: EditOnSend) => {
        const prependOnSend = viewState.prependOnSend;

        if (!prependOnSend) {
            return;
        }

        let foundAndReplaced = false;
        for (const index of prependOnSend.keys()) {
            if (prependOnSend[index].id === prop.id) {
                prependOnSend[index] = prop;
                foundAndReplaced = true;
                break;
            }
        }

        if (!foundAndReplaced) {
            prependOnSend.push(prop);
        }
    }
);
