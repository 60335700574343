import { action } from 'satcheljs';
import getDefaultOptions from '../data/defaultOptions';
import type { DefaultOptionsType } from '../data/defaultOptions';
import type { MailboxInfo } from 'owa-client-types';

export const applyDefaultOptionsOnLoadFail = action(
    'APPLY_DEFAULT_OPTIONS_ON_LOAD_FAIL',
    (mailboxInfo: MailboxInfo, defaultOptions: DefaultOptionsType = getDefaultOptions()) => ({
        mailboxInfo,
        defaultOptions,
    })
);
