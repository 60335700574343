import { getStore } from '../store/store';
import { mutatorAction } from 'satcheljs';

export default mutatorAction('reTriggerMessageAdList', function (): void {
    const store = getStore();
    if (store) {
        // -1 is the initialized value of showAdCount so that the actual Ad show account could follow the user flight
        store.inboxFolderShowAdCount = -1;
    }
});
