import doesActiveExperiencesInclude from './doesActiveExperiencesInclude';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Checks to see if the user has teams
 * @returns true if the user has teams
 */
export default function isTeamsUser(mailboxInfo?: MailboxInfo) {
    return doesActiveExperiencesInclude('Teams', mailboxInfo);
}
