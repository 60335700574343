import { getFallbackValueIfNull } from 'owa-options-core';
import getStore from '../store/store';
import getComposeFormOption from '../services/getComposeFormOption';

export default function isComposePopoutEnabled(): boolean {
    const store = getStore().composeFormOptionState;
    const savedOptions = getComposeFormOption();
    return getFallbackValueIfNull(
        store ? store.composeFormInPopOutEnabled : null,
        savedOptions ? savedOptions.composeFormInPopOutEnabled : false
    );
}
