import { type AccessibilityBitFlagsMasks, getIsBitSet } from '../utils/accessibilityBitFlagsUtil';
import { updateUserConfiguration } from 'owa-session-store';

export function setAccessibilityBit(setBit: boolean, bitMask: AccessibilityBitFlagsMasks) {
    // Already same value, no need to set again and update user config
    if (getIsBitSet(bitMask) === setBit) {
        return;
    }

    updateUserConfiguration(userConfig => {
        if (userConfig.ViewStateConfiguration) {
            const flags = userConfig.ViewStateConfiguration.AccessibilityBitFlags ?? 0;

            if (setBit) {
                // Set the bit to 1
                userConfig.ViewStateConfiguration.AccessibilityBitFlags = flags | bitMask;
            } else {
                // Clear the bit to 0
                userConfig.ViewStateConfiguration.AccessibilityBitFlags = flags & ~bitMask;
            }
        }
    });
}
