import {
    createLazyComponent,
    LazyModule,
    LazyImport,
    registerLazyOrchestrator,
    LazyAction,
} from 'owa-bundling';
import renderMeetingPreviewPlaceHolder from './components/MailListItemMeetingPreviewPlaceholder';
import BigHoverActionPlaceholder from './components/BigHoverAction/BigHoverActionPlaceholder';
import { onModuleClick } from 'owa-workloads';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailListItemLazy"*/ './lazyIndex'),
    { name: 'MailListItemLazy' }
);

// Delayed Loaded Components
export const MailListItemExpansion = createLazyComponent(lazyModule, m => m.MailListItemExpansion);
export const MailListItemMeetingPreview = createLazyComponent(
    lazyModule,
    m => m.MailListItemMeetingPreview,
    () => renderMeetingPreviewPlaceHolder()
);
export const MailPersonaSearchResultsHeader = createLazyComponent(
    lazyModule,
    m => m.MailPersonaSearchResultsHeader
);

export const lazyInitializeThirdRow = new LazyImport(lazyModule, m => m.initializeThirdRow);
registerLazyOrchestrator(onModuleClick, lazyModule, m => m.onModuleClickOrchestrator);

export const SelectAllCheckbox = createLazyComponent(lazyModule, m => m.SelectAllCheckbox);

export const BigHoverAction = createLazyComponent(
    lazyModule,
    m => m.BigHoverAction,
    BigHoverActionPlaceholder
);

export const LazyMailListHeaderIconContainer = createLazyComponent(
    lazyModule,
    m => m.LazyMailListHeaderIconContainer
);

export const lazySetupTriageActionKeys = new LazyAction(lazyModule, m => m.setupTriageActionKeys);
