import { observer } from 'owa-mobx-react';
import React from 'react';
import { Shimmer, ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react/lib/Shimmer';
import type { IShimmerColors } from '@fluentui/react/lib/Shimmer';
import { getDensityModeString, getDensityModeCssClass, getPalette } from 'owa-fabric-theme';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';

import {
    shimmerRow,
    full,
    compact,
    paddingFirstMessage,
    paddingMessage,
    mailListShimmerContainer,
} from './ShimmerRowSLV.scss';

import classnames from 'owa-classnames';

const shimmerElementsForGroup = [
    { type: ShimmerElementType.line, width: '12%' },
    { type: ShimmerElementType.gap, width: '6%', height: 28 },
    { type: ShimmerElementType.line, width: '60%' },
    { type: ShimmerElementType.gap, width: '14%', height: 28 },
    { type: ShimmerElementType.line, width: '6%' },
    { type: ShimmerElementType.gap, width: '2%', height: 28 },
];

export interface ShimmerRowSLVProps {
    isFirstMessage: boolean;
}

const ShimmerRowSLV = observer(function ShimmerRowSLV(props: ShimmerRowSLVProps) {
    const densityModeString = getDensityModeString();

    const computedShimmerColors = useComputedValue(
        (): IShimmerColors => ({
            background: getPalette().neutralPrimarySurface,
            shimmer: getPalette().neutralLight,
            shimmerWave: getPalette().neutralLighter,
        }),
        []
    );

    const stylesForDensity1 = useComputedValue(
        () => ({ width: densityModeString === 'full' ? '5%' : '4%' }),
        [densityModeString]
    );
    const stylesForDensity2 = useComputedValue(
        () => ({ width: densityModeString === 'full' ? '95%' : '96%' }),
        [densityModeString]
    );

    const shimmerElementsStyles = useComputedValue(
        () => [
            { type: ShimmerElementType.gap, width: '32px', height: 28 },
            densityModeString === 'full'
                ? { type: ShimmerElementType.circle, height: 28 }
                : { type: ShimmerElementType.gap, width: '28px', height: 28 },
            { type: ShimmerElementType.gap, width: '24px', height: 28 },
        ],
        [densityModeString]
    );

    const row = React.useMemo((): JSX.Element => {
        return (
            <div className={shimmerRow}>
                <div style={stylesForDensity1}>
                    <ShimmerElementsGroup
                        width={'100%'}
                        shimmerElements={shimmerElementsStyles}
                        backgroundColor={getPalette().neutralPrimarySurface}
                    />
                </div>
                <div style={stylesForDensity2}>
                    <ShimmerElementsGroup
                        shimmerElements={shimmerElementsForGroup}
                        backgroundColor={getPalette().neutralPrimarySurface}
                    />
                </div>
            </div>
        );
    }, [
        stylesForDensity1,
        stylesForDensity2,
        shimmerElementsStyles,
        getPalette().neutralPrimarySurface,
    ]);

    return (
        <div
            className={classnames(
                getDensityModeCssClass(full, undefined, compact),
                props.isFirstMessage ? paddingFirstMessage : paddingMessage,
                mailListShimmerContainer
            )}
        >
            <Shimmer
                improveCSSPerformance={true}
                customElementsGroup={row}
                shimmerColors={computedShimmerColors}
            />
        </div>
    );
}, 'ShimmerRowSLV');
export default ShimmerRowSLV;
