import { type AttachmentPolicyInfoBarId } from '../schema/AttachmentPolicyInfoBarId';
import type AccessIssue from '../schema/AccessIssue';
import { assertNever } from 'owa-assert';

export function getInfoBarId(issue: AccessIssue): AttachmentPolicyInfoBarId {
    switch (issue) {
        case 0:
            return 'ReadOnlyAccess';
        case 1:
            return 'ReadOnlyPlusAttachmentsBlockedAccess';
        case 2:
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (15,13): Type 'null' is not assignable to type 'AttachmentPolicyInfoBarId'.
            // @ts-expect-error
            return null;
        default:
            return assertNever(issue);
    }
}
