import type SingleGroup3sSearchScopeType from '../../data/schema/type/SingleGroup3sSearchScopeType';

export default function getSingleGroupSearchScopeType(
    data: SingleGroup3sSearchScopeType
): SingleGroup3sSearchScopeType {
    const result: SingleGroup3sSearchScopeType = {
        __type: 'SingleGroupSearchScopeType:#Exchange',
        ...data,
    };

    return result;
}
