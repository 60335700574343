import { owaComputedFn } from 'owa-computed-fn';
import { isReadingPaneConversationEnabled } from 'owa-mail-unstacked';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { getUserConfiguration, isShadowMailboxUser } from 'owa-session-store';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

const shouldShowUnstackedReadingPane = owaComputedFn(() => {
    // Show unstacked reading pane if:
    // - React list view type is conversation
    // - Reading pane conversation is disabled
    // - User is not a shadow mailbox user (cloud cache)
    const globalListViewTypeReact = getUserConfiguration()?.UserOptions?.GlobalListViewTypeReact;
    return (
        globalListViewTypeReact == 0 &&
        !isReadingPaneConversationEnabled() &&
        !isShadowMailboxUser(getModuleContextMailboxInfo())
    );
});

export default shouldShowUnstackedReadingPane;
