import type {
    SearchFile,
    TeamsMessage,
    SearchPerson,
} from 'owa-search-service/lib/data/schema/SubstrateSearchResponse';
import type { SearchTab } from 'owa-search-types/lib/types/SearchTab';
import type { Refiner } from 'owa-search-types';

export default interface TabbedSearchStore {
    activeSearchTab?: SearchTab;
    activeScopeTab?: SearchTab;
    fileSearchResults: SearchFile[];
    teamsSearchResults: TeamsMessage[];
    peopleSearchResults: SearchPerson[];
    loadingState: TabbedSearchLoadingState;
    queryString: string;
    isLoadingMoreResults: boolean;
    isTeamsChatEnabled: boolean;
    shouldSuppressTabs: boolean; // Allows tabs to be turned off temporarily, e.g., for a Find Related Conversation search
    selectedSearchResultId?: string; // For tabbed search types with the concept of an open/selected search result, such as people tab
    searchTerms: string[];
    appliedRefiners: Refiner[];
    arrowsScrollIndex: number;
}

export enum TabbedSearchLoadingState {
    NotStarted,
    InProgress,
    Complete,
    Error,
}
