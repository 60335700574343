import doesTableSupportAutoMarkRead from './doesTableSupportAutoMarkRead';
import type TableView from '../store/schema/TableView';
import isUnreadSearchFolder from '../utils/isUnreadSearchFolder';
import getViewFilterForTable from '../utils/getViewFilterForTable';

/**
 * Determines whether the given table can be out of sync with the server
 * @param tableView tableView
 * @returns true if the table can be out of sync
 */
export default function canTableBeOutOfSyncWithServer(tableView: TableView) {
    // An unread table or unread search folder that supports auto mark as read can be out of sync with the server
    const isUnreadFilter = getViewFilterForTable(tableView) == 'Unread';
    const isUnreadTypeSearchFolder = isUnreadSearchFolder(tableView);
    const supportsAutoMarkRead = doesTableSupportAutoMarkRead(tableView);
    return (isUnreadFilter || isUnreadTypeSearchFolder) && supportsAutoMarkRead;
}
