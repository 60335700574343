import type { ComposeViewState } from 'owa-mail-compose-store/lib/store/schema/ComposeViewState';
import getConversationReadingPaneViewState from './getConversationReadingPaneViewState';
import findInlineComposeViewState from 'owa-mail-compose-actions/lib/utils/findInlineComposeViewState';
import getFullComposeShowingInTabContentArea from 'owa-mail-compose-actions/lib/selectors/getFullComposeShowingInTabContentArea';
import { isReadingPanePositionOff, shouldShowReadingPane } from 'owa-mail-layout';
import { owaComputedFn } from 'owa-computed-fn';
import getItemReadingPaneViewState from 'owa-mail-reading-pane-store/lib/utils/getItemReadingPaneViewState';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';

/**
 * Get ComposeviewState from the active tab, which could be either full compose or inline compose
 * @returns compose view state from the current active tab
 */
export const getComposeViewState = owaComputedFn(function getComposeViewState():
    | ComposeViewState
    | undefined {
    return getFullComposeShowingInTabContentArea() ?? getInlineComposeViewState();
});

/**
 * Get inline compose state from active tab
 * @returns {ComposeState} compose state
 */
export function getInlineComposeViewState(): ComposeViewState | undefined {
    if (isReadingPanePositionOff() && !shouldShowReadingPane()) {
        return undefined;
    }
    const conversationReadingPaneState = getConversationReadingPaneViewState();
    const isConversationView: boolean = !!conversationReadingPaneState;
    const id = isConversationView
        ? conversationReadingPaneState.conversationId?.Id
        : getItemReadingPaneViewState()?.itemId;
    const inlineComposeViewState: ComposeViewState | undefined = findInlineComposeViewState(
        id,
        isConversationView ? 0 : 1 /* idType */
    );
    return inlineComposeViewState;
}
