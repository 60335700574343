import { observer } from 'owa-mobx-react';
import type { FolderForestTreeType } from 'owa-graph-schema';
import React from 'react';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import type { RenderFolderNodeFunc } from './MailFolderNodeTreeProps';
import { SEARCH_FOLDER_ROOT_DISTINGUISHED_ID } from 'owa-folders-constants';

interface MailFolderNodeChildrenProps {
    nestDepth: number;
    folderIdsToRender: string[];
    treeType: FolderForestTreeType;
    distinguishedFolderParentIds?: string[];
    isFavoritesSupported?: boolean;
    isRootExpanded?: boolean;
    ignoreSearchFolderParent?: boolean;

    renderFolderNode: RenderFolderNodeFunc;
    onContextMenu?: (
        evt: React.MouseEvent<unknown>,
        folderId: string,
        distinguishedFolderParentIds: string[]
    ) => void;
}

/**
 * Component responsible for rendering the nested folder tree
 */
export default observer(function MailFolderNodeChildren(props: MailFolderNodeChildrenProps) {
    const {
        distinguishedFolderParentIds,
        folderIdsToRender,
        isFavoritesSupported,
        isRootExpanded,
        nestDepth,
        renderFolderNode,
        treeType,
        ignoreSearchFolderParent,
    } = props;

    const onContextMenu = React.useCallback(
        (
            evt: React.MouseEvent<unknown, MouseEvent>,
            folderId: string,
            distinguishedFolderParentIdsInner: string[]
        ) => {
            props.onContextMenu?.(evt, folderId, distinguishedFolderParentIdsInner);
        },
        [props.onContextMenu]
    );

    const renderChildren = (folderIds: string[], currentNestDepth: number): JSX.Element[] => {
        const renderedNodes: JSX.Element[] = [];
        if (currentNestDepth == 0) {
            if (isRootExpanded) {
                const renderedChildNodes = renderChildren(folderIds, currentNestDepth + 1);
                Array.prototype.push.apply(renderedNodes, renderedChildNodes);
            }
        } else {
            folderIds.forEach(folderId => {
                const folderNode = renderFolderNode(
                    folderId,
                    currentNestDepth,
                    treeType,
                    !!isFavoritesSupported,
                    onContextMenu,
                    distinguishedFolderParentIds
                );

                const ignoreSearchFolder =
                    ignoreSearchFolderParent &&
                    folderIdToName(folderId) == SEARCH_FOLDER_ROOT_DISTINGUISHED_ID;

                if (folderNode && !ignoreSearchFolder) {
                    renderedNodes.push(folderNode);
                }
            });
        }

        return renderedNodes;
    };

    return <>{renderChildren(folderIdsToRender, nestDepth)}</>;
}, 'MailFolderNodeChildren');
