import type { OperationVariables, QueryResult } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import { useManagedQuery } from './useManagedQuery';
import { isFeatureEnabled } from 'owa-feature-flags';

export function useNovaQuery<
    TData = any,
    TVariables extends OperationVariables = OperationVariables
>(
    query: DocumentNode,
    variables?: {
        [name: string]: unknown;
    },
    options?: {}
): QueryResult<TData, TVariables> {
    const wrappedOptions: OperationVariables = {
        variables,
        returnPartialData: true,
        ...(isFeatureEnabled('mos-mos3AppService') ? { fetchPolicy: 'cache-only' } : {}),
        ...options,
    };
    return useManagedQuery(query, wrappedOptions);
}
