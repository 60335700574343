import { logStartGreyError } from 'owa-analytics-start';
import type { ILogger } from './ILogger';

export class Logger implements ILogger {
    eventName: string;
    hasEnded = false;
    private startTime: number | undefined;
    checkPoints: {
        [key: string]: number;
    };
    customData: {
        [key: string]: string;
    };
    duration: number | undefined;

    constructor(eventName: string) {
        this.eventName = eventName;
        this.startTime = Date.now();
        this.checkPoints = {};
        this.customData = {};
    }

    addCustomData(key: string, value: string) {
        if (!this.customData) {
            this.customData = {};
        }
        this.customData[key] = value;
    }

    addCustomError(eventName: string, error?: Error) {
        if (!this.customData) {
            this.customData = {};
        }
        this.customData[eventName + '_Message'] = error?.message ?? '';
        this.customData[eventName + '_Stack'] = error?.stack ?? '';
    }

    addCheckpoint(checkPoint: string): void {
        const time = this.timeFromStart();
        this.addToCheckPointEvents(checkPoint, time);
    }

    logLatency(): void {
        this.addCheckpoint('TokenFetchLatency');
    }

    private addToCheckPointEvents(key: string, time: number) {
        if (!this.checkPoints) {
            this.checkPoints = {};
        }
        this.checkPoints[key] = time;
    }

    end(): void {
        if (!this.hasEnded) {
            this.hasEnded = true;

            /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
             * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
             *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
            logStartGreyError(this.eventName, undefined, {
                ...this.customData,
                ...this.checkPoints,
            });
        }
    }

    private timeFromStart(): number {
        return this.startTime != null ? Date.now() - this.startTime : 0;
    }
}
