import type TabViewState from '../store/schema/TabViewState';
import { TabType } from '../store/schema/TabViewState';
import { getTabHandler } from '../utils/TabHandler';

export default function getTabTitle(viewState: TabViewState): string | undefined {
    if (!viewState) {
        return undefined;
    }

    const tabHandler = getTabHandler(viewState.type);

    if (tabHandler?.getWindowTitle) {
        return tabHandler.getWindowTitle(viewState);
    }

    switch (viewState.type) {
        case TabType.SecondaryReadingPane:
            return viewState.data.subject;
        case TabType.Reminders:
            return viewState.data;
        case TabType.AddCalendars:
            return viewState.data;
        default:
            return undefined;
    }
}
