import type { MailFolderScenarioType } from '../store/schema/MailFolderTableQuery';

export function isSearchFolderScenario(scenarioType: MailFolderScenarioType): boolean {
    return (
        scenarioType === 'persona' ||
        scenarioType === 'privatedistributionlist' ||
        scenarioType === 'category' ||
        scenarioType === 'searchFolder'
    );
}
