import { isFeatureEnabled, isAddinMultiAccountEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { isCapabilityEnabled } from 'owa-capabilities';
import { populateAddinsCapability } from 'owa-capabilities-definitions/lib/populateAddinsCapability';
import { isMOS3AppServiceAvailable } from './isMOS3AppServiceAvailable';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export function isMessageExtensionsFlyoutEnabled(mailboxInfo?: MailboxInfo): boolean {
    const mInfo = mailboxInfo ?? getModuleContextMailboxInfo();
    return (
        isFeatureEnabled('mos-mos3AppService', mInfo) &&
        // Flyout and addins are enabled if the feature flag is enabled for the primary mailbox
        (isPrimaryAccountFromMailboxInfo(mailboxInfo) || isAddinMultiAccountEnabled()) &&
        (isMOS3AppServiceAvailable(mInfo) || isCapabilityEnabled(populateAddinsCapability, mInfo))
    );
}

export function isPrimaryAccountFromMailboxInfo(mailboxInfo?: MailboxInfo): boolean {
    // default to primary account in case mailboxInfo is null or undefined
    const key = getIndexerValueForMailboxInfo(mailboxInfo ?? getGlobalSettingsAccountMailboxInfo());
    return key === getIndexerValueForMailboxInfo(getGlobalSettingsAccountMailboxInfo());
}
