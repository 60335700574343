import type LeftNavGroupsSchema from './schema/LeftNavGroups';
import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';
import type LeftNavGroupsState from './schema/LeftNavGroupsState';

const defaultLeftNavGroupsStore: LeftNavGroupsState = {
    leftNavGroupsByMailboxInfo: new ObservableMap<string, LeftNavGroupsSchema>(),
};

const store = createStore<LeftNavGroupsState>('leftNavGroupsStore', defaultLeftNavGroupsStore)();
export const getLeftNavGroupsStore = () => store;
