import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailRibbonLazyFunctions"*/ './lazyIndex'),
    { name: 'MailRibbonLazyFunctions' }
);

export const lazyGetAppCheckBoxControlRenderer = new LazyAction(
    lazyModule,
    m => m.getDefaultCheckBoxRenderer
);

export const lazyGetAppComboBoxControlRenderer = new LazyAction(
    lazyModule,
    m => m.getDefaultComboBoxRenderer
);

export const lazyGetAppToggleButtonControlRenderer = new LazyAction(
    lazyModule,
    m => m.getDefaultRibbonToggleButtonRenderer
);
