import type TableView from '../store/schema/TableView';
import { getUserConfiguration } from 'owa-session-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';

export default function shouldFirstRowBeSelectedOnLoad(tableView: TableView | undefined) {
    // First row should only be selected on load when the user option is set
    // and the user is not in the junk folder (don't want to accidentally open
    // malicious email). Also, if OWA policy setting MessagePreviewsDisabled
    // is set to true, disable selecting first row on load.
    const userConfig = getUserConfiguration();
    const disabledByPolicySetting = userConfig.PolicySettings?.MessagePreviewsDisabled == true;
    return (
        userConfig.UserOptions?.ShowReadingPaneOnFirstLoad &&
        !disabledByPolicySetting &&
        tableView &&
        tableView?.tableQuery?.folderId &&
        folderIdToName(tableView.tableQuery.folderId) !== 'junkemail'
    );
}
