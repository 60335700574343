import { OWA_ITEM_CLIPBAORD_MIME } from '../constants';

interface ClipboardItemWithSupports extends ClipboardItem {
    readonly supports: ((mime: string) => boolean) | null | undefined;
}

const isClipboardWebCustomFormatSupported = (): boolean => {
    if (!window.ClipboardItem) {
        return false;
    }

    const clipboardItem = window.ClipboardItem as any as ClipboardItemWithSupports;
    return !!clipboardItem.supports?.(OWA_ITEM_CLIPBAORD_MIME);
};

export default isClipboardWebCustomFormatSupported;
