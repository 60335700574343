import { isFeatureEnabled } from 'owa-feature-flags';
import type { OwaTeamsPolicies } from 'owa-teams-policies-lazy/lib/store/schema';

/**
 * Bypasses the policy check until the policies exist.
 *
 * This function takes in policies and checks if they are null.
 * If the policies are null or an empty object (in the case of getTeamsPolicies API call returning 204 status),
 * it returns true if the feature flags 'fwk-teamsPolicies' and
 * 'cal-chat-optimisticTeamsPolicyCheck' are enabled. This allows
 * the policy check to be bypassed until the policies are available.
 *
 * @param {OwaTeamsPolicies | null} policies - The policies to check.
 * @returns {boolean} - True if policies are null and the feature flag is enabled, otherwise false.
 */
export const skipPolicyCheckIfNotLoaded = (policies: OwaTeamsPolicies | null) => {
    return (
        isFeatureEnabled('fwk-teamsPolicies') &&
        isFeatureEnabled('cal-chat-optimisticTeamsPolicyCheck') &&
        (!policies || Object.keys(policies).length === 0)
    );
};
