import { owaComputedFn } from 'owa-computed-fn';
import getAddinCollection from 'owa-addins-surface-actions/lib/utils/getAddinCollection';
import {
    storePollFilter,
    invalidAddInsFilter,
} from 'owa-addins-surface-actions/lib/utils/getAddinFilters';
import { ExtensibilityModeEnum } from 'owa-addins-types';
import { getDefaultRibbonStyles } from 'owa-command-ribbon-styles/lib/util/getDefaultRibbonStyles';
import { createAddinButtonForReadInRibbon } from 'owa-mail-compose-controls/lib/components/pinnedAddIns';
import { retrieveWindowForRibbon } from 'owa-mail-compose-controls/lib/utils/retrieveEditingInfoForRibbon';
import { type MailRibbonGroupId } from 'owa-ribbon-ids/lib/mailRibbonId';

import type { AddinCommandSurfaceItem } from 'owa-addins-types';
import type { MailRibbonGroup } from 'owa-mail-ribbon-store-shared-types';
import type { RuntimeControlId } from 'owa-ribbon-ids';
import type {
    RibbonReadSurfaceRuntimeControlsGroup,
    RibbonRuntimeControl,
} from './getReadSurfaceRuntimeControls';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';

const getInstalledAddInsNew = owaComputedFn(
    (mailboxInfo: MailboxInfo, targetWindow: Window): AddinCommandSurfaceItem[] => {
        const isSharedItem = false;

        const filteredAddin = getAddinCollection(
            ExtensibilityModeEnum.IntegratedSpam,
            isSharedItem,
            '',
            mailboxInfo,
            targetWindow
        )
            .filter(invalidAddInsFilter)
            .filter(item => storePollFilter(item, mailboxInfo));

        return filteredAddin;
    }
);

export function getInstalledReadSurfaceAddInRuntimeControls(): RibbonReadSurfaceRuntimeControlsGroup {
    const ribbonInstalledAddIns = new Map<string, RibbonRuntimeControl>();
    let INSTALLED_ADDIN_CONTROL_ID: RuntimeControlId.InstalledAddIns = 91000;
    const runtimeControlGroup: RibbonReadSurfaceRuntimeControlsGroup = {
        controlsGroupName: 'ReadAddInsInstalled',
        isControlGroupEnabledForSpamIntegration() {
            return isFeatureEnabled('addin-enableIntegratedSpam');
        },
        shouldAddScalingSteps() {
            return false;
        },
        shouldAddControlsToTabGroup(group: MailRibbonGroup) {
            return (
                runtimeControlGroup.isControlGroupEnabledForSpamIntegration() &&
                192 === group.groupId
            );
        },
        getControlsProps: owaComputedFn(props => {
            // Do not populate controls for popout window if item is not selected
            if (props.isPopout && !props.itemId) {
                return [];
            }
            const defaultStyles = getDefaultRibbonStyles();
            const targetWindow = retrieveWindowForRibbon(props.projectionTabId) ?? window;

            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            const pinnedAddins = getInstalledAddInsNew(props.mailboxInfo!, targetWindow);
            return pinnedAddins.map(addIn => {
                let controlId = ribbonInstalledAddIns.get(addIn.key)?.controlId;
                if (!controlId) {
                    controlId = ++INSTALLED_ADDIN_CONTROL_ID;
                }

                const runtimeControl: RibbonRuntimeControl = {
                    controlId,
                    buttonProps: createAddinButtonForReadInRibbon(
                        addIn,
                        controlId,
                        targetWindow,
                        defaultStyles,
                        props
                    ),
                };
                ribbonInstalledAddIns.set(addIn.key, runtimeControl);
                return runtimeControl;
            });
        }),
        getControlsId() {
            return Array.from(ribbonInstalledAddIns.values()).map(({ controlId }) => controlId);
        },
    };
    return runtimeControlGroup;
}
