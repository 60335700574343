import type BulkActionStateStore from '../store/schema/BulkActionStateStore';
import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';

const initialStore: BulkActionStateStore = {
    bulkActionInformationMap: new ObservableMap(),
};

const store = createStore<BulkActionStateStore>('bulkActionStore', initialStore)();
export default store;
export const getStore = () => store;
