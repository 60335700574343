import router, { getCurrentRoute } from './routerInstance';
import { resetLastProcessedRoute } from './lastProcessedRoute';
import getScopedPath from 'owa-url/lib/getScopedPath';
import { trace } from 'owa-trace';

let isNavigatingFlag = false;
let forceRouteHandlerFlag = false;

export function isNavigating() {
    return isNavigatingFlag;
}

export function setForceRouteHandler(value: boolean) {
    forceRouteHandlerFlag = value;
}

export function getForceRouteHandler() {
    return forceRouteHandlerFlag;
}

let vdirPrefix: string = '';
export function setVdir(vdir: string) {
    vdirPrefix = getScopedPath(vdir).substr(1) + '/';
}

export function getVdir(): string {
    return vdirPrefix;
}

/** Navigates to a route, given its segments. The segments must NOT include the vdir. */
export default function navigateToRoute(pathSegments: string[]) {
    const encodedPath: string = vdirPrefix + pathSegments.map(fixedEncodeURIComponent).join('/');

    // Because of when director.js calls onChange for html5history, the navigation needs to:
    // 1. Prevent navigation during navigation
    // 2. Prevent navigation if the routes are the SAME due to autorun in keepRouteCurrent
    if (isNavigating() || getCurrentRoute().join('/') == encodedPath) {
        return;
    }

    isNavigatingFlag = true;

    try {
        const newRoute = '/' + encodedPath + location.search;
        trace.info(`Navigating to ${newRoute}`, 'router');
        router.setRoute(newRoute);
    } finally {
        isNavigatingFlag = false;
        forceRouteHandlerFlag = false;
    }

    resetLastProcessedRoute();
}

function fixedEncodeURIComponent(str: string) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
}
