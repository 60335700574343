// Note: The local resolvers, (idb and WEB) take precedence over the remote resolvers and idb takes precedence over web
// So,
// Idb | Web | Remote   => try idb first, fall back to web resolver (remote ignored)
// Idb | Remote         => try idb first, fall back to remote
// Idb | Web            => try idb first, fall back to web resolver (remote ignored)
// Web | Remote         => call web resolver (remote ignored)
// Remote               => only send remote
// Idb                  => call idb, fail if that falls back
// Web                  => call web resolver (remote ignored)
export enum ResolverEnabledFor {
    None = 0,
    Idb = 1 << 0,
    Web = 1 << 1,
    Remote = 1 << 2,
    All = ~(~0 << 3),
}
