import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { isCapabilitySupported } from 'owa-capabilities';
import { copilotThemeCapability } from 'owa-capabilities-definitions/lib/copilotThemeCapability';
import isCopilotFeatureEnabled from './isCopilotFeatureEnabled';

/**
 * @returns true if the feature is enabled, false otherwise
 */
export default function isVisualThemingEnabled(): boolean {
    return (
        isCapabilitySupported(copilotThemeCapability) &&
        !!isCopilotFeatureEnabled(
            'VisualTheming',
            getGlobalSettingsAccountMailboxInfo(),
            true /* skipLanguageCheck */
        )
    );
}
