import type ReportSubmissionPolicy from '../types/ReportSubmissionPolicy';

export default function getDefaultReportSubmissionPolicy(): ReportSubmissionPolicy {
    return {
        EnableReportToMicrosoft: true,
        PreSubmitMessageEnabled: true,
        PostSubmitMessageEnabled: false,
        ReportJunkToCustomizedAddress: false,
        ReportNotJunkToCustomizedAddress: false,
        ReportPhishToCustomizedAddress: false,
    };
}
