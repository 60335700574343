import type { ItemRow } from 'owa-graph-schema';
import type Item from 'owa-service/lib/contract/Item';
import type Message from 'owa-service/lib/contract/Message';

export function mapOwsItemToGqlItemRow(item: Item): ItemRow {
    const itemRow: ItemRow = {
        __typename: 'ItemRow',
        ItemId: {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            Id: item.ItemId!.Id,
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            ChangeKey: item.ItemId!.ChangeKey,
        },
        Categories: item.Categories,
        ConversationId: item.ConversationId?.Id
            ? {
                  Id: item.ConversationId?.Id,
              }
            : undefined,
        DateTimeReceived: item.DateTimeReceived,
        DateTimeSent: item.DateTimeSent,
        DisplayTo: item.DisplayTo,
        EntityNamesMap: item.EntityNamesMap,
        Flag: item.Flag,
        HasAttachments: item.HasAttachments,
        HasProcessedSharepointLink: item.HasProcessedSharepointLink,
        IconIndex: item.IconIndex,
        Importance: item.Importance,
        InstanceKey: item.InstanceKey,
        InferenceClassification: item.InferenceClassification,
        IsDraft: item.IsDraft,
        IsExternalSender: item.IsExternalSender,
        IsSubmitted: item.IsSubmitted,
        ItemClass: item.ItemClass,
        LastModifiedTime: item.LastModifiedTime,
        MentionedMe: item.MentionedMe,
        ParentFolderId: item.ParentFolderId
            ? {
                  __typename: 'FolderId',
                  Id: item.ParentFolderId.Id,
              }
            : undefined,
        Preview: item.Preview,
        ReceivedOrRenewTime: item.ReceivedOrRenewTime,
        ReturnTime: item.ReturnTime,
        Sensitivity: item.Sensitivity,
        Size: item.Size,
        SortOrderSource: item.SortOrderSource,
        Subject: item.Subject,
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        id: item.ItemId!.Id,
        DeferredSendTime: item.DeferredSendTime,
    };

    // Message specific updates
    if (
        !item?.ItemClass || // server treats undefined or null ItemClass as a Message type by default
        item.ItemClass.indexOf('IPM.Note') !== -1 || // Normal messages
        item.ItemClass.indexOf('IPM.Schedule.Meeting') !== -1 || // Meeting request messages
        item.ItemClass.indexOf('IPM.GroupMailbox.JoinRequest') !== -1 || // Group join request messages
        item.ItemClass.indexOf('IPM.Post') !== -1 || //Post Message type
        item.ItemClass.indexOf('IPM.Sharing') !== -1 // Calendar Sharing Message Type
    ) {
        const message = item as Message;

        itemRow.From = message.From;
        itemRow.IsRead = message.IsRead;
        itemRow.IsReadReceiptRequested = message.IsReadReceiptRequested;
        itemRow.IsDeliveryReceiptRequested = message.IsDeliveryReceiptRequested;
        itemRow.AntispamUnauthenticatedSender = message.AntispamUnauthenticatedSender;
        itemRow.MessageToMe = message.MessageToMe;
        itemRow.MessageCcMe = message.MessageCcMe;
        itemRow.ReactionsCount = message.ReactionsCount ?? 0;
        itemRow.CopilotInboxScore = message.CopilotInboxScore ?? -1;
        itemRow.CopilotInboxHeadline = message.CopilotInboxHeadline;
    }

    return itemRow;
}
