import { action } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';

export const onNewFolderNotification = action(
    'onNewFolderNotification',
    (
        folderId: string,
        parentFolderId: string,
        displayName: string,
        unreadCount: number = 0,
        totalCount: number = 0,
        updateMRUList: boolean = true,
        sortPosition: string | null,
        sortParentId: string | null,
        mailboxInfo?: MailboxInfo
    ) => ({
        folderId,
        parentFolderId,
        displayName,
        unreadCount,
        totalCount,
        updateMRUList,
        mailboxInfo,
        sortPosition,
        sortParentId,
    })
);

export const onUpdateFolderNotification = action(
    'onUpdateFolderNotification',
    (
        unreadCount: number,
        totalCount: number,
        folderId: string,
        displayName: string,
        parentFolderId: string,
        sortPosition: string | null,
        sortParentId: string | null
    ) => {
        return {
            unreadCount,
            totalCount,
            folderId,
            displayName,
            parentFolderId,
            sortPosition,
            sortParentId,
        };
    }
);
