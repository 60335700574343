import ___resources_fabricColorVariablesThemeBaseJson from '../resources/fabric.color.variables.theme.base.json';
import ___resources_fabricColorVariablesThemeBaseDarkJson from '../resources/fabric.color.variables.theme.base.dark.json';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - satchel stores in the worker will need their data synced
 *	> 'createStore' import from 'satcheljs' is restricted. Satchel stores should not be created in the worker as the data will need to be synced */
import { createStore } from 'satcheljs';
import type ThemeStore from './schema/ThemeStore';
import { ObservableMap } from 'mobx';
import type { ThemeResources } from 'owa-theme-shared';
import { ThemeConstants } from 'owa-theme-shared';

export const initialStore: ThemeStore = {
    theme: ThemeConstants.BASE_THEME_ID,
    themeData: new ObservableMap<string, ThemeResources>({
        [ThemeConstants.BASE_THEME_ID]: ___resources_fabricColorVariablesThemeBaseJson,
        [ThemeConstants.BASE_DARK_THEME_ID]: ___resources_fabricColorVariablesThemeBaseDarkJson,
    }),
    useSystemDarkModeSettings: false,
};
export const store = createStore<ThemeStore>('themeStore', initialStore)();
