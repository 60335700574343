export function getExplicitLogonHeaders(
    smtp: string | undefined,
    prefixedId?: string
): {
    [headerName: string]: string;
} {
    const headers: Record<string, string> = {};
    if (smtp) {
        headers['X-OWA-ExplicitLogonUser'] = smtp;
    }
    const anchorMailbox = prefixedId ?? smtp;
    if (anchorMailbox) {
        headers['x-anchormailbox'] = anchorMailbox;
    }
    return headers;
}
