import { mutatorAction } from 'satcheljs';
import getListViewState from '../selectors/getListViewState';

export const updateShouldForceShowMultiSelectRP = mutatorAction(
    'updateShouldForceShowMultiSelectRP',
    (shouldForceShowMultiSelectRP: boolean) => {
        const listViewState = getListViewState();
        listViewState.shouldForceShowMultiSelectRP = shouldForceShowMultiSelectRP;
    }
);
