import type { ActionCreator, ActionMessage, OrchestratorFunction } from 'satcheljs';
import { getActionEmitter } from './getActionEmitter';
import { useEffect } from 'react';
import type { DependencyList } from 'react';

/** Uses a non-static handler as listener of a static satcheljs action. */
export function useOrchestrator<T extends ActionMessage>(
    action: ActionCreator<T>,
    handler: OrchestratorFunction<T> | undefined,
    dependencies: DependencyList
) {
    const [emitter, actionId] = getActionEmitter(action);
    useEffect(() => {
        handler && emitter.on(actionId, handler);
        return () => {
            handler && emitter.off(actionId, handler);
        };
    }, [action, handler, ...dependencies]);
}
