import type {
    MutationHookOptions,
    TypedDocumentNode,
    DefaultContext,
    ApolloCache,
    MutationFunctionOptions,
    FetchResult,
    MutationResult,
    OperationVariables,
} from '@apollo/client';
import { useMutation } from '@apollo/client';
import { createManagedMutationOptions } from './util/createManagedOptions';
import type { StrictVariables } from 'owa-apollo';
import type { ResolverContext } from 'owa-graph-schema';
import type { QueuedActionOptions } from 'owa-queued-actions';

export type StrictMutationFunction<
    TData,
    TSchemaVars extends OperationVariables,
    TContext,
    TCache extends ApolloCache<any> = ApolloCache<any>
> = <TActualVars extends StrictVariables<TSchemaVars, TActualVars>>(
    options?: MutationFunctionOptions<TData, TActualVars, TContext, TCache> & QueuedActionOptions
) => Promise<FetchResult<TData>>;

export type StrictMutationTuple<
    TData,
    TSchemaVars extends OperationVariables,
    TContext = DefaultContext,
    TCache extends ApolloCache<any> = ApolloCache<any>
> = [StrictMutationFunction<TData, TSchemaVars, TContext, TCache>, MutationResult<TData>];

export function useManagedMutation<
    TData,
    TSchemaVars extends OperationVariables,
    TActualVars extends StrictVariables<TSchemaVars, TActualVars>,
    TContext = ResolverContext
>(
    query: TypedDocumentNode<TData, TSchemaVars>,
    options?: MutationHookOptions<TData, TActualVars> & QueuedActionOptions
): StrictMutationTuple<TData, TSchemaVars, TContext> {
    const managedOptions = createManagedMutationOptions(
        options as unknown as MutationHookOptions<TData, TSchemaVars>
    );

    const rv = useMutation(query, managedOptions) as StrictMutationTuple<
        TData,
        TSchemaVars,
        TContext,
        ApolloCache<any>
    >;

    const mutationFn: StrictMutationFunction<TData, TSchemaVars, TContext, ApolloCache<any>> = (
        fnOptions?
    ) => {
        const fnManagedOptions = createManagedMutationOptions(
            fnOptions as unknown as MutationHookOptions<TData, TSchemaVars>
        ) as any;
        return rv[0](fnManagedOptions);
    };

    return [mutationFn, rv[1]];
}
