import { readM365ApplicationsFromCache } from 'owa-nova-cache/lib/utils/readM365ApplicationsFromCache';

const pinStatusUpdateContext = () => ({
    m365Apps: readM365ApplicationsFromCache(),
});

export const NovaMutationContexts: Record<string, () => any> = {
    AppBarPinAppMutation: pinStatusUpdateContext,
    AppBarUnpinAppMutation: pinStatusUpdateContext,
    AppBarMovePinnedAppMutation: pinStatusUpdateContext,
};
