import { defaultConfig } from './defaultQuickSteps';
import initializeQuickSteps from '../actions/initializeQuickSteps';
import { lazyReadOcsSettings } from 'owa-ocs-service-gql';
import type { OcsResponseItem } from 'owa-graph-schema';
import { QUICK_STEPS_SETTING_NAME } from '../constants';
import type { QuickStepsConfig } from '../store/schema/QuickStepsConfig';
import { isFeatureEnabled } from 'owa-feature-flags';
import saveQuickStepsToService from './saveQuickStepsToService';
import type { MailboxInfo } from 'owa-client-types';
import { type QuickStepHotkeyId } from 'owa-quick-step-types';
import type { QuickStepFolderType, QuickStepCategoryType } from 'owa-quick-step-types';

const qsRequestSent: {
    [key: string]: boolean;
} = {};

export async function loadMailboxQuickSteps(mailboxInfo: MailboxInfo) {
    // Keep track of requests so we don't send multiple
    if (qsRequestSent[mailboxInfo.userIdentity]) {
        return;
    }
    qsRequestSent[mailboxInfo.userIdentity] = true;

    if (isFeatureEnabled('tri-quickSteps-reset')) {
        hardResetQuickSteps(mailboxInfo);
    } else {
        const ocsResponse: OcsResponseItem[] = await lazyReadOcsSettings.importAndExecute(
            mailboxInfo,
            'ACCOUNT',
            [QUICK_STEPS_SETTING_NAME]
        );

        const json = ocsResponse[0]?.value;
        let quickStepsConfig: QuickStepsConfig;
        if (json) {
            const data = JSON.parse(json);
            if (data.version > defaultConfig.version) {
                quickStepsConfig = defaultConfig;
                quickStepsConfig.errorState = true;
            } else {
                const areActionsModified = validateQuickStepsData(data);
                quickStepsConfig = data;
                quickStepsConfig.errorState = false;
                if (areActionsModified) {
                    const configString = JSON.stringify(quickStepsConfig);
                    saveQuickStepsToService(mailboxInfo, configString);
                }
            }
        } else {
            quickStepsConfig = defaultConfig;
        }
        initializeQuickSteps(mailboxInfo, quickStepsConfig as QuickStepsConfig);
    }
}

function hardResetQuickSteps(mailboxInfo: MailboxInfo) {
    initializeQuickSteps(mailboxInfo, defaultConfig);
    const configString = JSON.stringify(defaultConfig);
    saveQuickStepsToService(mailboxInfo, configString);
}

function validateQuickStepsData(config: QuickStepsConfig): boolean {
    let areActionsModified = false;
    config.quickSteps.forEach(step => {
        // we have removed hotkey zero as an option
        // if the user has that, set it to undefined
        if (step.hotkey === 5) {
            step.hotkey = undefined;
        }
        step.actions.forEach(action => {
            if (action.type === 'moveTo' && action.extraInfo != undefined) {
                const folder = action.extraInfo as QuickStepFolderType;
                if (folder.folderId === undefined && folder.isAlwaysAsk === undefined) {
                    // we should save folderId data from extraInfo string into the folderId field of QuickStepFolderType
                    const newFolder: QuickStepFolderType = {
                        folderId: action.extraInfo as string,
                        isAlwaysAsk: false,
                    };
                    action.extraInfo = newFolder;
                    areActionsModified = true;
                }
            }
            if (action.type === 'category' && action.extraInfo != undefined) {
                if (typeof action.extraInfo === 'string') {
                    const newCategory: QuickStepCategoryType = {
                        categoryId: action.extraInfo as string,
                        isAlwaysAsk: false,
                    };
                    action.extraInfo = newCategory;
                    areActionsModified = true;
                } else if (typeof action.extraInfo === 'object') {
                    const category = action.extraInfo as QuickStepCategoryType;
                    if (category?.categoryId !== null && category?.categoryId !== undefined) {
                        if (
                            typeof category.categoryId === 'string' &&
                            category.isAlwaysAsk !== undefined
                        ) {
                            const newCategory: QuickStepCategoryType = {
                                categoryId: category.categoryId,
                                isAlwaysAsk: category.isAlwaysAsk,
                            };
                            action.extraInfo = newCategory;
                        } else if (typeof category.categoryId === 'object') {
                            // This is a case of fixing up incorrectly saved data on edit of a quick step,
                            // where we accidentally saved the category object instead of the categoryId string
                            // Setting return value areActionsModified to true fixes this up for subsequent loads
                            const categoryObject = category.categoryId as QuickStepCategoryType;
                            if (
                                categoryObject !== null &&
                                categoryObject['categoryId'] !== null &&
                                categoryObject['categoryId'] !== undefined &&
                                typeof categoryObject['categoryId'] === 'string'
                            ) {
                                const newCategory: QuickStepCategoryType = {
                                    categoryId: categoryObject['categoryId'],
                                    isAlwaysAsk: false,
                                };
                                action.extraInfo = newCategory;
                                areActionsModified = true;
                            }
                        }
                    }
                }
            }
        });
    });
    // We have introduced a new runOnce flag to make the Quick Steps UI
    // to appear for new users and anyone that has imported Quick Steps from Win32
    // We don't want to show the UI for existing OWA users that already have a config
    if (config.runOnce === undefined) {
        config.runOnce = true;
        areActionsModified = true;
    }
    return areActionsModified;
}
