import { getNewMessageControl } from '../slr-home-tab/slrHomeTabControlDefinitions';
import {
    getChangeNoteColorControl,
    getDeleteNoteControl,
    getNotesViewEmailControl,
} from '../../sharedControls/readSharedControlDefinitions';
import { owaComputedFn } from 'owa-computed-fn';
import { type MailRibbonTabId, type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { RibbonControlId } from 'owa-ribbon-ids';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { errorThatWillCauseAlert } from 'owa-trace';

/**
 * This function supplies a mapping from RibbonControlId to their respective control definition function.
 * For any new control in a tab, be sure to update the mapping below.
 * Note: This function should *not* have any logic regarding showing/hiding.
 *       That should be in shouldShowRibbonControl.ts instead.
 */
export const getSLRNotesTabControlDefinition = owaComputedFn(
    function getSLRNotesTabControlDefinition(
        controlId: RibbonControlId,
        _tabId: MailRibbonTabId,
        props: ReadOnlyRibbonControlDefProps
    ): RibbonControlProps | undefined {
        switch (controlId) {
            case 587:
                return getNewMessageControl(6, props);
            case 641:
                return getChangeNoteColorControl(props);
            case 640:
                return getDeleteNoteControl(props);
            case 642:
                return getNotesViewEmailControl(props);
            default:
                /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                 * The error name (message) must be a string literal (no variables in it).
                 *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
                errorThatWillCauseAlert(
                    'Unknown control: ' +
                        controlId +
                        ' searched for in getSLRNotesTabControlDefinition.ts'
                );
                return undefined;
        }
    }
);
