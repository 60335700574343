export interface EasidResponse {
    isEasid: boolean;
    displayName: string | null;
    nonEasidReason: string | null;
}

export const isEasidResponse = (obj: any): obj is EasidResponse =>
    obj &&
    typeof obj.isEasid === 'boolean' &&
    (obj.displayName === null || typeof obj.displayName === 'string') &&
    (obj.nonEasidReason === null || typeof obj.nonEasidReason === 'string');
