import { timeout } from 'owa-sleep/lib/timeout';
import { schedule } from './schedule';

interface AbstractTaskQueueConfig {
    taskDelay?: number;
    taskTimeout?: number;
}

export default abstract class AbstractTaskQueue<Task> {
    protected abstract config: Required<AbstractTaskQueueConfig>;

    abstract add(value: any): void;
    abstract remove(): Task | undefined;
    abstract clear(): void;
    protected abstract get canRunMoreTasks(): boolean;
    protected abstract runTask(task: Task): Promise<void>;

    protected numberOfTasksRunning = 0;

    protected scheduleTask() {
        if (this.canRunMoreTasks) {
            // Schedule the task on timer so that the JS thread is not occupied by the queue
            schedule(() => {
                this.tryRunTask();
            }, this.config.taskDelay);
        }
    }

    protected onTaskComplete() {
        this.numberOfTasksRunning--;
        this.scheduleTask();
    }

    protected tryRunTask() {
        if (this.canRunMoreTasks) {
            const task = this.remove();
            if (task) {
                this.numberOfTasksRunning++;

                timeout(this.runTask(task), this.config.taskTimeout, 'Task timed out').then(
                    () => {
                        this.onTaskComplete();
                    },
                    () => {
                        this.onTaskComplete();
                    }
                );
            }

            // Try scheduling parallel tasks
            this.scheduleTask();
        }
    }
}
