import type { Operation } from '@apollo/client';
import executeGraphLink from '../links/executeGraphLink';
import { getMailboxInfoFromOperationStrict } from 'owa-request-options';
import { type ResolverImports } from '../factories/createGraphServerLink';

export function isPstFileOperation(operation: Operation) {
    const mailboxInfo = getMailboxInfoFromOperationStrict(operation);
    return mailboxInfo?.type === 'PstFile';
}

export function pstServerLink(
    context: Record<string, any>,
    getResolvers: () => Promise<ResolverImports>
) {
    async function getResolversProfile() {
        const resolverImports = await getResolvers();

        const resolvers = resolverImports.pst || {};
        const dataSource = undefined;
        const isOfflineEnabled = !!resolverImports.offline;

        return {
            resolvers,
            dataSource,
            isOfflineEnabled,
        };
    }

    return executeGraphLink(getResolversProfile, context);
}
