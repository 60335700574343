import React from 'react';
import { registerIcons } from '@fluentui/style-utilities';
import type { FluentIconsProps } from '@fluentui/react-icons';

export const registerComponentIcon = (
    iconName: string,
    IconComponent: React.FC<FluentIconsProps>
): string => {
    registerIcons({
        icons: { [iconName]: React.createElement(IconComponent) },
    });

    return iconName;
};
