import type { AttachmentModel } from 'owa-attachment-model-store';
import { ATP_EXTENSION } from './constants';

export default function isATPAttachment(attachment: AttachmentModel): boolean {
    if (!attachment.model.Name) {
        return false;
    }

    return attachment.model.Name.indexOf(ATP_EXTENSION) > 0;
}
