import type { MailboxInfo } from 'owa-client-types';
import getFeatureFlagsIndexerForMailboxInfo from './getFeatureFlagsIndexerForMailboxInfo';

export type DefaultFlagMap = {
    [feature: string]: boolean;
};

const defaultFlags: Map<string, DefaultFlagMap> = new Map([['', {}]]);

export function setDefaultFlags(newMap: DefaultFlagMap, mailboxInfo?: MailboxInfo | null) {
    const key = getFeatureFlagsIndexerForMailboxInfo(mailboxInfo);
    defaultFlags.set(key, newMap);
}

export function getDefaultFlags(mailboxInfo?: MailboxInfo | null): DefaultFlagMap {
    const key = getFeatureFlagsIndexerForMailboxInfo(mailboxInfo);
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return defaultFlags.get(key) || defaultFlags.get('')!;
}
