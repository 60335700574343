import getConversationReadingPaneViewState from './getConversationReadingPaneViewState';
import canConversationLoadMore from 'owa-mail-store/lib/utils/canConversationLoadMore';
import { owaComputedFn } from 'owa-computed-fn';

/**
 * Check whether all item parts and their OOF rollup components in the conversation are expanded.
 */
function isAllItemPartsExpandedFn(conversationId: string): boolean {
    const viewState = getConversationReadingPaneViewState(conversationId);
    if (!viewState) {
        return false;
    }

    // Check whether to expand or collapse the conversation.
    // If there exists an item part that is not expanded, or there exists an item part
    // for which the OOFRollup is not expanded, then isAllItemPartsExpanded is false
    const isSomeItemPartOrOofRollupCollapsed = [...viewState.itemPartsMap.values()].some(
        itemPart =>
            !itemPart.isExpanded ||
            (itemPart.oofRollUpViewState.oofReplyNodeIds.length &&
                !itemPart.oofRollUpViewState.isOofRollUpExpanded)
    );
    return !isSomeItemPartOrOofRollupCollapsed && !canConversationLoadMore(conversationId);
}

export const isAllItemPartsExpanded = owaComputedFn(isAllItemPartsExpandedFn);
