import getOrCreateSxSStoreData from '../store/Store';
import type { ClientAttachmentId } from 'owa-client-ids';
import { isClientAttachmentIdEqual } from 'owa-client-ids';

export default function isSxSDisplayed(sxsId?: string | null) {
    return getOrCreateSxSStoreData(sxsId).shouldShow;
}

export function isSxSDisplayedForAttachment(id: ClientAttachmentId, sxsId: string | null) {
    return (
        isSxSDisplayed(sxsId) &&
        isClientAttachmentIdEqual(id, getOrCreateSxSStoreData(sxsId).extendedViewState.attachmentId)
    );
}

/**
 * If composeViewState is truthy, it will check if SxS is displaying that exact same compose view state
 * If composeViewState is falsy, it will check if any compose is displaying in SxS
 */
/* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
 * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
 *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
export function isSxSDisplayedWithCompose(composeViewState?: any, sxsId?: string | null) {
    return (
        isSxSDisplayed(sxsId) &&
        ((composeViewState &&
            composeViewState.composeId === getOrCreateSxSStoreData(sxsId).composeId) ||
            (!composeViewState && getOrCreateSxSStoreData(sxsId).composeId))
    );
}
