import { MailRowDataPropertyGetter, type SortColumn, getSortByForTable } from 'owa-mail-list-store';
import type GroupHeader from '../type/GroupHeader';
import { unknownSenderOrRecipient } from 'owa-locstrings/lib/strings/unknownsenderorrecipient.locstring.json';
import loc from 'owa-localize';
import { SenderGroupHeaderId } from '../type/GroupHeaderId';
import type { GroupHeaderId } from '../type/GroupHeaderId';
import type { LocalizedString } from 'owa-localize';
import type { TableView } from 'owa-mail-list-store';

/**
 * Get group header for row (for From or To sort)
 * @param rowKey rowKey for which we want to lookup time group header
 * @param tableView tableView
 * @return group header for this row
 */
export default function getGroupHeaderForSortByFromTo(
    rowKey: string,
    tableView: TableView
): GroupHeader {
    // Fetch recipients if we're sorting by To, otherwise fetch senders if we're
    // sorting by From
    const uniqueSendersOrRecipients: string[] =
        getSortByForTable(tableView).sortColumn === 9
            ? MailRowDataPropertyGetter.getUniqueRecipients(rowKey, tableView)
            : MailRowDataPropertyGetter.getUniqueSenders(rowKey, tableView);

    let headerTextValue = loc(unknownSenderOrRecipient);
    let headerIdValue: GroupHeaderId = SenderGroupHeaderId.Unknown;

    if (uniqueSendersOrRecipients && uniqueSendersOrRecipients.length > 0) {
        headerTextValue = uniqueSendersOrRecipients[0] as any as LocalizedString;
        headerIdValue = uniqueSendersOrRecipients[0];
    }

    return {
        headerText: () => headerTextValue,
        headerId: headerIdValue,
    };
}
