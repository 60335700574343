import doesActiveExperiencesInclude from './doesActiveExperiencesInclude';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Checks to see if the user has Copilot License
 * @returns true if the user has Copilot License
 */
export function isCopilotBusinessChat(mailboxInfo?: MailboxInfo) {
    return doesActiveExperiencesInclude('CopilotBusinessChat', mailboxInfo);
}
