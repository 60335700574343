import { createStore } from 'satcheljs';
import type AppBackgroundStore from './schema/AppBackgroundStore';

const appBackgroundStore = createStore<AppBackgroundStore>('appBackground', {
    image: undefined,
    backgroundColor: 'transparent',
})();

const getAppBackgroundStore = () => appBackgroundStore;
export default getAppBackgroundStore;
