import { getUserConfiguration } from 'owa-session-store';
export type AccessibilityBitFlagsMasks = any;

/**
 * Gets a boolean to indicate if the n th bit is 1
 * @param bitMask represents the nth bit
 * @return true if nth bit is 1, false if 0
 */
export function getIsBitSet(bitMask: AccessibilityBitFlagsMasks): boolean {
    const accessibilityBitFlags =
        getUserConfiguration().ViewStateConfiguration?.AccessibilityBitFlags || 0;
    return !!(bitMask & accessibilityBitFlags);
}
