import { action } from 'satcheljs';

import type { OwaNovaActionUnion } from '../types';
import { CalendarSidePanelEventTypes } from 'calendar-side-panel-event-types';

export const onAddTimeSelection = action('addTimeSelection', event => ({
    event,
    owaType: CalendarSidePanelEventTypes.addTimeSelection,
})) as OwaNovaActionUnion<typeof CalendarSidePanelEventTypes.addTimeSelection>;

export const onRemoveTimeSelection = action('removeTimeSelection', event => ({
    event,
    owaType: CalendarSidePanelEventTypes.removeTimeSelection,
})) as OwaNovaActionUnion<typeof CalendarSidePanelEventTypes.removeTimeSelection>;

export const onOpenEvenDetails = action('openEventDetails', event => ({
    event,
    owaType: CalendarSidePanelEventTypes.openEventDetails,
})) as OwaNovaActionUnion<typeof CalendarSidePanelEventTypes.openEventDetails>;

export const onInvokeRemoteCommand = action('invokeRemoteCommand', event => ({
    event,
    owaType: CalendarSidePanelEventTypes.invokeRemoteCommand,
})) as OwaNovaActionUnion<typeof CalendarSidePanelEventTypes.invokeRemoteCommand>;

export const onRespondToMeeting = action('respondToMeeting', event => ({
    event,
    owaType: CalendarSidePanelEventTypes.respondToMeeting,
})) as OwaNovaActionUnion<typeof CalendarSidePanelEventTypes.respondToMeeting>;
