import { ONE_DRIVE_CONSUMER, ONE_DRIVE_PRO } from 'owa-attachment-constants/lib/fileProviders';
import { convertToAttachmentDataProviderType } from 'owa-attachment-file-types';
import type AttachmentDataProviderType from 'owa-service/lib/contract/AttachmentDataProviderType';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

const providerTypes: AttachmentDataProviderType[] = [0, 1, 3, 2, 6, 5, 7, 8, 4];

export interface CreateDataProviderInfoMappingResult<T> {
    getDataProviderInfo: (providerType: string, mailboxInfo: MailboxInfo) => T;
    getDefaultDataProviderInfo: (isConsumer: boolean, mailboxInfo: MailboxInfo) => T;
}

export default function createDataProviderInfoMapping<T>(
    createProviderInfo: (type: AttachmentDataProviderType, mailboxInfo: MailboxInfo) => T
): CreateDataProviderInfoMappingResult<T> {
    const dataProviderInfoMaps = new Map<
        string,
        {
            [key: number]: T;
        }
    >();

    const getDataProviderInfoMap = function getDataProviderInfoMap(mailboxInfo: MailboxInfo): {
        [key: number]: T;
    } {
        const index = `${getIndexerValueForMailboxInfo(mailboxInfo)}_protection`;
        let dataProviderInfoMap = dataProviderInfoMaps.get(index);
        if (!dataProviderInfoMap) {
            dataProviderInfoMap = {};

            for (const providerType of providerTypes) {
                dataProviderInfoMap[providerType] = createProviderInfo(providerType, mailboxInfo);
            }

            dataProviderInfoMaps.set(index, dataProviderInfoMap);
        }

        return dataProviderInfoMap;
    };

    const getDataProviderInfo = function getDataProviderInfo(
        providerType: string,
        mailboxInfo: MailboxInfo
    ): T {
        const attachmentDataProviderType = convertToAttachmentDataProviderType(providerType);
        if (attachmentDataProviderType === null) {
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (48,13): Type 'null' is not assignable to type 'T'.
            // @ts-expect-error
            return null;
        }

        return getDataProviderInfoMap(mailboxInfo)[attachmentDataProviderType];
    };

    const getDefaultDataProviderInfo = function getDefaultDataProviderInfo(
        isConsumer: boolean,
        mailboxInfo: MailboxInfo
    ): T {
        if (isConsumer) {
            return getDataProviderInfo(ONE_DRIVE_CONSUMER, mailboxInfo);
        } else {
            return getDataProviderInfo(ONE_DRIVE_PRO, mailboxInfo);
        }
    };

    return {
        getDataProviderInfo,
        getDefaultDataProviderInfo,
    };
}
