import type { PerformanceCoreDatapoint, PerformanceDatapoint } from 'owa-analytics';

/**
 * Peformance datapoint that tracks end to end time from selection of an email in message list to rendering the content on reading pane.
 */
let selectMailItemDp: PerformanceCoreDatapoint | PerformanceDatapoint | undefined = undefined;

export function getSelectMailItemDatapoint():
    | PerformanceCoreDatapoint
    | PerformanceDatapoint
    | undefined {
    return selectMailItemDp;
}

export function setSelectMailItemDatapoint(
    datapoint: PerformanceCoreDatapoint | PerformanceDatapoint | undefined
) {
    selectMailItemDp = datapoint;
}
