import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';
import type { GroupFoldersStoreByMailboxInfo } from './schema/GroupFoldersStoreByMailboxInfo';
import type { GroupFoldersStore } from './schema/GroupFoldersStore';

const defaultLeftNavGroupFoldersByMailboxInfo: GroupFoldersStoreByMailboxInfo = {
    folderTableByMailboxInfo: new ObservableMap<string, GroupFoldersStore>({}),
};

const store = createStore<GroupFoldersStoreByMailboxInfo>(
    'leftNavGroupFoldersStoreByMailboxInfo',
    defaultLeftNavGroupFoldersByMailboxInfo
)();
export const getLeftNavGroupFolders = () => store;
