import type { ActionSource } from 'owa-analytics-types';
import { lazyOnFlagUnflag } from 'owa-mail-commands-actions';
import type { ClientItemId } from 'owa-client-ids';
import type FlagType from 'owa-service/lib/contract/FlagType';

/**
 * Legacy function: Set item(s) to Flagged state
 * Remove with flight clean up notif-emailReminders.
 */
export function onFlag(actionSource: ActionSource) {
    lazyOnFlagUnflag.importAndExecute(actionSource, { FlagStatus: 'Flagged' });
}

/**
 * Set item(s) to Not Flagged state.
 */
export function onUnflag(actionSource: ActionSource) {
    lazyOnFlagUnflag.importAndExecute(actionSource, { FlagStatus: 'NotFlagged' });
}

export function onFlagUnflag(
    actionSource: ActionSource,
    flagType?: FlagType,
    override?: boolean,
    itemId?: ClientItemId
) {
    lazyOnFlagUnflag.importAndExecute(actionSource, flagType, override, itemId);
}
