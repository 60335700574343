import type Message from 'owa-service/lib/contract/Message';
import getItemRightsManagementRestrictions from 'owa-mail-store/lib/utils/getItemRightsManagementRestrictions';

export default function isIRMDownloadAllowed(item: Message): boolean {
    const IRMRestrictions = getItemRightsManagementRestrictions(item);
    return (
        IRMRestrictions.PrintAllowed ||
        IRMRestrictions.CopyAllowed ||
        IRMRestrictions.ForwardAllowed
    );
}
