import { getCoprincipalAccountForMailboxInfo } from 'owa-account-source-list-store';
import { type AccountSourceType } from 'owa-account-source-list-types';
import { type MailboxInfo } from 'owa-client-types';

/**
 * Returns the source type for the specified MailboxInfo
 * @param mailboxInfo Specified the mailbox for which informaiton to be obtained
 * @returns The account source type
 */
export default function getSourceTypeForMailboxInfo(
    mailboxInfo: MailboxInfo
): AccountSourceType | undefined {
    return getCoprincipalAccountForMailboxInfo(mailboxInfo)?.sourceType;
}
