import {
    createLazyComponent,
    LazyModule,
    registerLazyOrchestrator,
    LazyAction,
} from 'owa-bundling';
import { onOpenLivePersonaEditor, onLivePersonaEditorInternal } from 'owa-nova-actions';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OwaNovaLivePersonaEditor" */ './lazyIndex')
);

registerLazyOrchestrator(onOpenLivePersonaEditor, lazyModule, m => m.openLivePersonaEditor);
registerLazyOrchestrator(onLivePersonaEditorInternal, lazyModule, m => m.livePersonaEditorInternal);

export const LivePersonaEditorAsync = createLazyComponent(
    lazyModule,
    m => m.LivePersonaEditorWrapper
);
export { isLivePersonaEditorEnabled } from './utils/isLivePersonaEditorEnabled';

export const lazyCreateProfileLinkedContact = new LazyAction(
    lazyModule,
    m => m.createProfileLinkedContact
);
