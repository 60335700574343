import { getStore } from '../store';

export const CustomTimeZoneId = 'tzone://Microsoft/Custom';
export const CustomTimeZoneName = 'Customized Time Zone';
export const UtcRuleId = 'tzrule://Microsoft/UtcRule';
export const UtcZoneId = 'tzone://Microsoft/Utc';

export default function cleanTimeZoneId(timeZoneId?: string): string {
    // There are some places where the name is sent in place of the ID
    // https://outlookweb.visualstudio.com/Platform/_workitems/edit/208889
    if (timeZoneId == CustomTimeZoneId || timeZoneId == CustomTimeZoneName) {
        return getStore().LocalTimeZone;
    } else if (timeZoneId == UtcRuleId || timeZoneId == UtcZoneId) {
        return 'UTC';
    }
    return timeZoneId || getStore().LocalTimeZone;
}
