import { getOWAConnectedAccount, isConnectedAccount } from 'owa-accounts-store';
import type { MailboxInfo } from 'owa-client-types';
import { getConnectedAccountAuthTokenInfo } from './getConnectedAccountHeaders';

/**
 * When communicating with a connected account the web session header needs to be included, this function
 * is responsible for getting the web session type associated with the mailbox info
 * @param responseHeaders Specifies the response headers received if the request failed to authenticate
 * @param mailboxInfo the MailboxInfo that is used to identify the account
 * @returns If successful returns the web session type, an empty string is returned on failure
 */
export default async function getConnectedAccountAuthToken(
    mailboxInfo: MailboxInfo
): Promise<string> {
    if (isConnectedAccount(mailboxInfo.userIdentity)) {
        // When not in Monarch we are still using the connected accounts API and need to call the
        // legacy connected account auth token logic to get the auth token and web session type.
        const account = getOWAConnectedAccount(mailboxInfo.userIdentity);
        const info = await getConnectedAccountAuthTokenInfo(mailboxInfo.userIdentity, account);
        return info?.token ?? '';
    }

    return '';
}
