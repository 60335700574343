import { lazyReadOcsSettings } from 'owa-ocs-service-gql';
import { CONDITIONAL_FORMATTING_SETTING_NAME } from '../utils/constants';
import { default as updateConditionalFormattingRules } from '../utils/updateConditionalFormattingRules';
import type { MailboxInfo } from 'owa-client-types';

export async function fetchConditionalFormattingRulesService(mailboxInfo: MailboxInfo) {
    const ocsResponse: any[] = await lazyReadOcsSettings.importAndExecute(mailboxInfo, 'ACCOUNT', [
        CONDITIONAL_FORMATTING_SETTING_NAME,
    ]);
    if (ocsResponse[0]?.value) {
        updateConditionalFormattingRules(
            mailboxInfo,
            JSON.parse(ocsResponse[0]?.value as string),
            true /* is from fetch*/
        );
    } else {
        //set empty value
        updateConditionalFormattingRules(mailboxInfo, []);
    }
}
