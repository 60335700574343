import { AuthResult } from '../types';

const authErrorLogString: Record<AuthResult, string> = {
    [AuthResult.Invalid]: 'Invalid',
    [AuthResult.Success]: 'Success',
    [AuthResult.Cancel]: 'Cancel',
    [AuthResult.InteractionRequired]: 'InteractionRequired',
    [AuthResult.TemporarilyUnavailable]: 'TemporarilyUnavailable',
    [AuthResult.ProviderFailure]: 'ProviderFailure',
    [AuthResult.InvalidUiContext]: 'InvalidUiContext',
    [AuthResult.ShuttingDown]: 'ShuttingDown',
    [AuthResult.InternalFailure]: 'InternalFailure',
    [AuthResult.ExtendedError]: 'ExtendedError',
    [AuthResult.InsufficientPermissions]: 'InsufficientPermissions',
};

// Returns the string that should be used when logging the auth error
export function getAuthErrorLogString(authErrorCode?: AuthResult): string {
    return authErrorCode !== undefined ? authErrorLogString[authErrorCode] : 'None';
}
