import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "QuickStepActions"*/ './lazyIndex')
);

export const lazyOnExecuteQuickStep = new LazyAction(lazyModule, m => m.onExecuteQuickStep);
export const lazyOnExecuteQuickStepHotkey = new LazyAction(
    lazyModule,
    m => m.onExecuteQuickStepHotkey
);

// non lazy export
export { getIsQuickStepDisabled } from './utils/getIsQuickStepDisabled';
