import { createStore } from 'satcheljs';

interface ModuleDndStore {
    draggedItemType: string | null;
}

const store = createStore<ModuleDndStore>('moduleDndStore', <ModuleDndStore>{
    draggedItemType: null,
})();
export default () => store;
