import overallPremiumStatusStore from '../store/overallPremiumStatusStore';
import { mutatorAction } from 'satcheljs';

export const setOverallPremiumStatusBit = mutatorAction(
    'SetOverallPremiumStatusBit',
    (overallPremiumBit: number) => {
        // Set the overallPremiumBit to trigger the store change
        // 2 -- means the premium status
        // 1 -- means the non-premium status
        // -1 -- (default value) means the premium status is not set
        overallPremiumStatusStore.overallPremiumBit = overallPremiumBit;
    }
);
