import type { FavoriteData } from 'owa-favorites-types';
import favoritesStore from '../../store/store';
import getSecondaryKey from '../../utils/getSecondaryKey';

export function addFavoriteDataInProgress(favoriteData: FavoriteData): void {
    const { favoritingInProgressMap } = favoritesStore;
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2345 (10,33): Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    // @ts-expect-error
    favoritingInProgressMap.set(getSecondaryKey(favoriteData), true);
}

export function removeFavoriteDataInProgress(favoriteData: FavoriteData): void {
    const { favoritingInProgressMap } = favoritesStore;
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2345 (18,36): Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    // @ts-expect-error
    favoritingInProgressMap.delete(getSecondaryKey(favoriteData));
}
