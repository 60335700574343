import type { MailboxInfo } from 'owa-client-types';
import type NotificationPayloadBase from 'owa-service/lib/contract/NotificationPayloadBase';
import { action } from 'satcheljs';

export default action(
    'FLEXIBLEWORKINGHOURS_NOTIFICATION',
    (notification: NotificationPayloadBase, mailboxInfo?: MailboxInfo) => ({
        notification,
        mailboxInfo,
    })
);
