import { type ApolloLink } from '@apollo/client';
import { localRemoteRouterLink } from '../links/localRemoteRouterLink';
import { DataSource } from 'owa-analytics-types';
import executeGraphLink from '../links/executeGraphLink';
import type { ResolverImports } from '../factories/createGraphServerLink';

export function mailboxServerLink(
    context: Record<string, any>,
    getResolvers: () => Promise<ResolverImports>,
    remoteLink: ApolloLink
) {
    async function getResolversProfile() {
        const resolverImports = await getResolvers();

        const resolvers = resolverImports.offline || resolverImports.web;
        const dataSource = resolverImports.offline ? undefined : DataSource.NETWORK_GQL;
        const isOfflineEnabled = !!resolverImports.offline;

        return {
            resolvers,
            dataSource,
            isOfflineEnabled,
        };
    }

    const localLink = executeGraphLink(getResolversProfile, context);
    return localRemoteRouterLink({
        localLink,
        remoteLink,
        getResolversProfile,
    });
}
