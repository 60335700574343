import type SurfaceActionsOptionState from './schema/SurfaceActionsOptionState';
import { createStore } from 'satcheljs';

const store = createStore<SurfaceActionsOptionState>('surfaceActionsOption', {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (8,5): Type 'null' is not assignable to type 'ReadActionKey[]'.
    // @ts-expect-error
    readSurfaceActions: null,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (12,5): Type 'null' is not assignable to type 'string[]'.
    // @ts-expect-error
    readSurfaceAddins: null,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (16,5): Type 'null' is not assignable to type 'ComposeActionKey[]'.
    // @ts-expect-error
    composeSurfaceActions: null,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (20,5): Type 'null' is not assignable to type 'string[]'.
    // @ts-expect-error
    composeSurfaceAddins: null,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (24,5): Type 'null' is not assignable to type 'HoverActionKey[]'.
    // @ts-expect-error
    hoverSurfaceActions: null,
})();

export default () => store;
