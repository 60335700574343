import { action } from 'satcheljs';

import type {
    AcquisitionEntrypoint,
    AcquisitionExperience,
    LaunchExperience,
} from 'owa-m365-acquisitions/lib/types';

type PostInstallDialog = {
    appId: string;
    appName: string;
    entrypoints: AcquisitionEntrypoint[];
    experiences: LaunchExperience[];
    launchExperience?: Extract<
        AcquisitionExperience,
        'LaunchPage' | 'TaskPane' | 'SearchComposeExtension'
    >;
};
const onShowPostInstallSuccessDialog = action(
    'onShowPostInstallSuccessDialog',
    (payload: PostInstallDialog) => payload
);
export default onShowPostInstallSuccessDialog;
