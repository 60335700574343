import { getApolloClientInternal } from './apolloClient';
import type { StrictVariables } from 'owa-apollo-types';
import type {
    DefaultContext,
    FetchResult,
    OperationVariables,
    TypedDocumentNode,
} from '@apollo/client';
import type { ResolverContext } from 'owa-graph-schema';
import type { StrictMutationOptions } from '../types/StrictMutationOptions';
import { stampQueuedActionOptionsOnContext } from '../util/stampQueuedActionOptionsOnContext';

export const strictMutate = <
    TSchemaVars extends OperationVariables,
    TActualVars extends StrictVariables<TSchemaVars, TActualVars>,
    TData,
    TContext = DefaultContext
>(
    mutation: TypedDocumentNode<TData, TSchemaVars>,
    options: StrictMutationOptions<TData, TActualVars, TContext>
): Promise<FetchResult<TData>> => {
    const client = getApolloClientInternal();
    options = stampQueuedActionOptionsOnContext(options);

    // Add caller stack to the context for better diagnosability
    options.context ||= {} as TContext;
    const stack = new Error('Trace').stack;
    (options.context as ResolverContext).queryStack = stack;

    return client.mutate({
        ...options,
        mutation,
    } as any);
};
