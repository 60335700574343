import { LazyModule, createLazyComponent, registerLazyOrchestrator } from 'owa-bundling';
import { startSearch } from 'owa-search-actions';

const lazyModule = new LazyModule(() => import('./lazyIndex'));

// Components
export const SearchPlusChatEntryPointContainer = createLazyComponent(
    lazyModule,
    m => m.SearchPlusChatEntryPointContainer
);
export const SearchPlusChatRightPaneContainer = createLazyComponent(
    lazyModule,
    m => m.SearchPlusChatRightPaneContainer
);

// Orchestrators
registerLazyOrchestrator(startSearch, lazyModule, m => m.startSearchOrchestrator);
