import React from 'react';
import { bigHoverActionContainer, bigHoverActionContainer_Deprecated } from './BigHoverAction.scss';
import { isFeatureEnabled } from 'owa-feature-flags';

export default function BigHoverActionPlaceholder() {
    const isRelocateHoverActionsFlightEnabled = isFeatureEnabled('tri-mlRelocateHoverActions');

    return (
        <div
            className={
                isRelocateHoverActionsFlightEnabled
                    ? bigHoverActionContainer
                    : bigHoverActionContainer_Deprecated
            }
        ></div>
    );
}
