import type {
    MailRibbonGroup,
    TabScalingAndLayoutConfig,
} from 'owa-mail-ribbon-store-shared-types';
import { defaultConfig } from 'owa-mail-ribbon-store-shared-types';
import type { RibbonId, RibbonGroupId, RibbonControlId } from 'owa-ribbon-ids';
import { isUserCreatedRibbonGroupId } from 'owa-ribbon-ids';

/**
 * The `addTab` versioning function adds a new tab(s).
 * @param ribbonConfig Current user-customized configuration, parsed from a JSON.
 * @param addTabProps @see {ribbonVersionManager} for details about property members.
 */
export function addTab(
    ribbonConfig: any,
    addTabProps: {
        tabVariableName: string;
        tabDefinition: TabScalingAndLayoutConfig;
    }[]
) {
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    addTabProps.forEach(props => {
        ribbonConfig.singleline[props.tabVariableName] = props.tabDefinition;
    });
}

/**
 * The `removeTab` versioning function removes an existing tab(s).
 * @param ribbonConfig Current user-customized configuration, parsed from a JSON.
 * @param removeTabProps @see {ribbonVersionManager} for details about property members.
 */
export function removeTab(
    ribbonConfig: any,
    removeTabProps: {
        tabVariableName: string;
    }[]
) {
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    removeTabProps.forEach(props => {
        delete ribbonConfig.singleline[props.tabVariableName];
    });
}

/**
 * The `addGroup` versioning function adds a group(s) to the specified view mode and tab.
 * This function attempts to add the group in the spot that best represents defaultConfig.
 * The logic for placement is:
 *  - Place the new group before the first group in the tab that is uncustomized and
 *    that would have originally been after the new group.
 * @param ribbonConfig Current user-customized configuration, parsed from a JSON.
 * @param addGroupProps @see {ribbonVersionManager} for details about property members.
 */
export function addGroup(
    ribbonConfig: any,
    addGroupProps: {
        tabVariableName: string;
        groupDefinition: MailRibbonGroup;
    }[]
) {
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    addGroupProps.forEach(props => {
        const ribbonConfigTab = ribbonConfig.singleline[props.tabVariableName];
        const ribbonConfigLayout = ribbonConfigTab?.layout;

        // If ribbonConfigLayout is undefined, that means `tabVariableName` was not found in the JSON.
        // Nowhere to add the new group, therefore we end early.
        if (ribbonConfigLayout == undefined) {
            return;
        }

        const defaultConfigLayout: MailRibbonGroup[] =
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS7053 (90,19): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ homeTab: TabScalingAndLayoutConfig; viewTab: TabScalingAndLayoutConfig; messageTab: TabScalingAndLayoutConfig; insertTab: TabScalingAndLayoutConfig; formatTab: TabScalingAndLayoutConfig; notesTab: TabScalingAndLayoutConfig; }'.
            // @ts-expect-error
            defaultConfig.singleline[props.tabVariableName]?.layout;

        // Add new group to staticGroupIdOrdering
        ribbonConfigTab.staticGroupIdOrdering.push(props.groupDefinition.groupId);

        // Now, we want to calculate the best location to place the new group.
        // Start with building an array of all groups that are after this new group in defaultConfig.
        let groupsPositionedAfter: MailRibbonGroup[] = [];
        if (defaultConfigLayout != undefined) {
            for (let i = 0; i < defaultConfigLayout.length; i++) {
                if (defaultConfigLayout[i].groupId === props.groupDefinition.groupId) {
                    // Found our group in defaultConfig, let's populate the array of all entries after this and return.
                    groupsPositionedAfter = defaultConfigLayout.slice(i);
                    break;
                }
            }
        }

        // Construct array of just groupIds
        const groupIdsPositionedAfter: RibbonGroupId[] = [];
        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        groupsPositionedAfter.forEach(ribbonGroup => {
            groupIdsPositionedAfter.push(ribbonGroup.groupId);
        });

        // Traverse our ribbonConfig and once we see a groupId that is in `groupIdsPositionedAfter`,
        // we know to insert our new group right before it.
        for (let i = 0; i < ribbonConfigLayout.length; i++) {
            if (groupIdsPositionedAfter.includes(ribbonConfigLayout[i].groupId)) {
                // Found group that should be positioned after the new group. Place new group before.
                ribbonConfigLayout.splice(i, 0, props.groupDefinition);
                return;
            }
        }

        // If we get here, means we didn't find any groups that should be positioned after.
        // The user may have customized them all out. In this case, place group at the end.
        ribbonConfigLayout.push(props.groupDefinition);
    });
}

/**
 * The `removeGroup` versioning function removes an existing group(s).
 * This will also remove all controlIds paired with that group from the config,
 * and also any references to those controlIds / groupIds in the tab.
 * @param ribbonConfig Current user-customized configuration, parsed from a JSON.
 * @param removeGroupProps @see {ribbonVersionManager} for details about property members.
 */
export function removeGroup(
    ribbonConfig: any,
    removeGroupProps: {
        tabVariableName: string;
        groupId: RibbonGroupId;
    }[]
) {
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    removeGroupProps.forEach(props => {
        const ribbonConfigTab = ribbonConfig.singleline[props.tabVariableName];

        if (ribbonConfigTab == undefined) {
            return;
        }

        // Find the group to be removed
        const ribbonConfigLayout = ribbonConfigTab.layout;
        for (let i = 0; i < ribbonConfigLayout.length; i++) {
            if (ribbonConfigLayout[i].groupId === props.groupId) {
                // Remove all controls within this group from controlsInOverflow
                ribbonConfigTab.controlsInOverflow = ribbonConfigTab.controlsInOverflow.filter(
                    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                    // -> Error TS7006 (164,21): Parameter 'ribbonId' implicitly has an 'any' type.
                    // @ts-expect-error
                    ribbonId => {
                        return !ribbonConfigLayout[i].controlIds.includes(ribbonId);
                    }
                );

                ribbonConfigLayout.splice(i, 1); // Remove group from tab.
            }
        }

        // Remove groupId references from staticGroupIdOrdering and removedDefaultGroups, if exists
        removeRibbonIdFromArray(ribbonConfigTab.staticGroupIdOrdering, props.groupId);
        removeRibbonIdFromArray(ribbonConfigTab.removedDefaultGroups, props.groupId);
    });
}

/**
 * The `addControl` versioning function adds a new  control(s).
 * This function attempts to add the control in the spot that best represents defaultConfig.
 * The logic for placement is:
 *  - Place the new control before the first control in the tab that is uncustomized and
 *    that would have originally been after the new control.
 * @param ribbonConfig Current user-customized configuration, parsed from a JSON.
 * @param addGroupProps @see {ribbonVersionManager} for details about property members.
 */
export function addControl(
    ribbonConfig: any,
    addControlProps: {
        tabVariableName: string;
        groupId: RibbonGroupId;
        controlId: RibbonControlId;
    }[]
) {
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    addControlProps.forEach(props => {
        const ribbonConfigLayout = ribbonConfig.singleline[props.tabVariableName]?.layout;
        const defaultConfigLayout: MailRibbonGroup[] =
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS7053 (211,19): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ homeTab: TabScalingAndLayoutConfig; viewTab: TabScalingAndLayoutConfig; messageTab: TabScalingAndLayoutConfig; insertTab: TabScalingAndLayoutConfig; formatTab: TabScalingAndLayoutConfig; notesTab: TabScalingAndLayoutConfig; }'.
            // @ts-expect-error
            defaultConfig.singleline[props.tabVariableName]?.layout;

        // If ribbonConfigLayout is undefined, that means `tabVariableName` was not found in the JSON.
        // Nowhere to add the new control, therefore we end early.
        if (ribbonConfigLayout == undefined) {
            return;
        }

        // Find group that matches groupId
        const ribbonConfigGroup = findRibbonGroup(ribbonConfigLayout, props.groupId);
        const defaultConfigGroup = findRibbonGroup(defaultConfigLayout, props.groupId);

        // If we didn't find the group for ribbonConfig, then we can't add the control so we return early.
        if (ribbonConfigGroup == undefined) {
            return;
        }

        // Now, we want to calculate the best location to place the new control in the group.
        // Start with building an array of all controls that are after this new control in defaultConfig.
        let controlsPositionedAfter: RibbonControlId[] = [];
        for (let i = 0; i < defaultConfigGroup.controlIds.length; i++) {
            if (defaultConfigGroup.controlIds[i] === props.controlId) {
                controlsPositionedAfter = defaultConfigGroup.controlIds.slice(i);
                break;
            }
        }

        // Traverse our ribbonConfig and once we see a control that is in `controlsPositionedAfter`,
        // we know to insert our new control right before it.
        for (let i = 0; i < ribbonConfigGroup.controlIds.length; i++) {
            if (controlsPositionedAfter.includes(ribbonConfigGroup.controlIds[i])) {
                // Found control that should be positioned after the new control. Place new control before.
                ribbonConfigGroup.controlIds.splice(i, 0, props.controlId);
                return;
            }
        }

        // If we get here, means we didn't find the control that should be positioned after.
        // The user may have customized them all out. In this case, place control at the end.
        ribbonConfigGroup.controlIds.push(props.controlId);
    });
}

/**
 * The `removeControl` versioning function removes an existing control(s) from a tab completely.
 * @param ribbonConfig Current user-customized configuration, parsed from a JSON.
 * @param removeGroupProps @see {ribbonVersionManager} for details about property members.
 */
export function removeControl(
    ribbonConfig: any,
    removeControlProps: {
        tabVariableName: string;
        controlId: RibbonControlId;
    }[]
) {
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    removeControlProps.forEach(props => {
        const ribbonConfigTab = ribbonConfig.singleline[props.tabVariableName];

        if (ribbonConfigTab == undefined) {
            return;
        }

        // Search for the group that contains the controlId that we want to remove, then remove the control.
        const ribbonConfigLayout = ribbonConfigTab.layout;
        for (let i = 0; i < ribbonConfigLayout.length; i++) {
            if (removeRibbonIdFromArray(ribbonConfigLayout[i].controlIds, props.controlId)) {
                break;
            }
        }

        // Remove controlId from controlsInOverflow, if exists there
        removeRibbonIdFromArray(ribbonConfigTab.controlsInOverflow, props.controlId);
    });
}

/**
 * The `addControlToOverflow` versioning function adds an existing control(s) to a tab's overflow.
 * Note that ordering within the tab's overflow doesn't matter. What matters is the layout's ordering of controls,
 * so we just add the control to the overflow and don't have to worry about matching defaultConfig in ordering.
 * @param ribbonConfig Current user-customized configuration, parsed from a JSON.
 * @param addControlToOverflowProps @see {ribbonVersionManager} for details about property members.
 */
export function addControlToOverflow(
    ribbonConfig: any,
    addControlToOverflowProps: {
        tabVariableName: string;
        controlId: RibbonControlId;
    }[]
) {
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    addControlToOverflowProps.forEach(props => {
        const ribbonConfigOverflow =
            ribbonConfig.singleline[props.tabVariableName]?.controlsInOverflow;

        if (ribbonConfigOverflow != undefined) {
            ribbonConfigOverflow.push(props.controlId);
        }
    });
}

/**
 * The `removeControlFromOverflow` versioning function removes an existing control(s) from a tab's overflow.
 * @param ribbonConfig Current user-customized configuration, parsed from a JSON.
 * @param removeControlFromOverflowProps @see {ribbonVersionManager} for details about property members.
 */
export function removeControlFromOverflow(
    ribbonConfig: any,
    removeControlFromOverflowProps: {
        tabVariableName: string;
        controlId: RibbonControlId;
    }[]
) {
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    removeControlFromOverflowProps.forEach(props => {
        const ribbonConfigOverflow =
            ribbonConfig.singleline[props.tabVariableName]?.controlsInOverflow;

        if (ribbonConfigOverflow != undefined) {
            removeRibbonIdFromArray(ribbonConfigOverflow, props.controlId);
        }
    });
}

/**
 * The `updateGroupOrdering` versioning function replaces a tab's staticGroupIdOrdering, but not
 * any custom-made groups that a user makes.
 * e.g. : In a staticGroupIdOrdering of [A, B, CustomC, CustomD]...
 *        - "A" and "B" are default groups
 *        - "CustomC" and "CustomD" are user-made custom groups
 *        - Then this function only replaces A and B, and keep CustomC and CustomD in their spots.
 * @param ribbonConfig Current user-customized configuration, parsed from a JSON.
 * @param updateGroupOrderingProps @see {ribbonVersionManager} for details about property members.
 */
export function updateGroupOrdering(
    ribbonConfig: any,
    updateGroupOrderingProps: {
        tabVariableName: string;
        newStaticGroupIdOrdering: RibbonGroupId[];
    }[]
) {
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    updateGroupOrderingProps.forEach(props => {
        const ribbonConfigTab = ribbonConfig.singleline[props.tabVariableName];

        if (ribbonConfigTab == undefined) {
            return;
        }

        // Create new ordering array, which will be: [props's new array + any user custom-made groups].
        // This is because custom-made groups are always after default groups.
        const newGroupOrdering: RibbonGroupId[] = props.newStaticGroupIdOrdering;
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS7006 (376,55): Parameter 'id' implicitly has an 'any' type.
        // @ts-expect-error
        ribbonConfigTab.staticGroupIdOrdering.forEach(id => {
            if (isUserCreatedRibbonGroupId(id)) {
                newGroupOrdering.push(id);
            }
        });

        ribbonConfigTab.staticGroupIdOrdering = newGroupOrdering;
    });
}

/**
 * Helper function that returns the group object that matches the groupId.
 * @param layout the Layout object, whether from defaultConfig or user-customized config
 * @param groupId the RibbonGroupId of the group we're looking for
 */
function findRibbonGroup(layout: any[], groupId: RibbonGroupId) {
    if (layout != undefined) {
        for (let i = 0; i < layout.length; i++) {
            if (layout[i].groupId === groupId) {
                return layout[i];
            }
        }
    }
    return undefined;
}

/**
 * Helper function that removes a RibbonId from a RibbonId[], if it exists.
 * @param arr is the ribbonId array.
 * @param id is the ribbonId to remove from the arr.
 * @returns True if found the item and removed. False if did not find the item.
 */
function removeRibbonIdFromArray(arr: RibbonId[], id: RibbonId): boolean {
    const index = arr.indexOf(id);
    if (index !== -1) {
        arr.splice(index, 1);
        return true;
    }
    return false;
}
