import getListViewStore from '../store/Store';
import { getRowIdString } from '../selectors/mailRowDataPropertyGetter';

/**
 * Returns a collection of the rowIdStrings for the given rowKeys
 * @param rowKeys rowKeys to get rowIds for
 * @param tableView tableView to loop up
 */
export default function getRowIdsFromRowKeys(rowKeys: string[], tableViewId: string): string[] {
    const tableView = getListViewStore().tableViews.get(tableViewId);

    if (!tableView) {
        return [];
    }

    const rowIdStrings = rowKeys.map(key => getRowIdString(key, tableView)).filter(key => key);
    return rowIdStrings.filter((item, pos) => rowIdStrings.indexOf(item) == pos);
}
