import { action } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Fetches the dumpster folder for archive mailbox folder tree and
 * adds mapping to session store.
 */

export const fetchArchiveDumpsterFolder = action(
    'FETCH_ARCHIVE_DUMPSTER_FOLDER',
    (mailboxInfo: MailboxInfo) => ({
        mailboxInfo,
    })
);
