import type { ApplyConversationActionContext } from 'owa-mail-triage-action-utils';
import getTableConversationRelation from './getTableConversationRelation';

/**
 * Gets contextual information for ApplyConversationAction for a given conversation
 */
export default function getApplyConversationActionContext(
    conversationRowKey: string,
    tableViewId: string
): ApplyConversationActionContext | undefined {
    const tableConversationRelation = getTableConversationRelation(conversationRowKey, tableViewId);
    if (!tableConversationRelation) {
        return undefined;
    }

    return <ApplyConversationActionContext>{
        //TODO: use ClientItemId type in ApplyConversationActionContext: 23338
        // Work Item 27629: Remove workaround for timezone issue (sending requests in UTC time zone)
        conversationId: tableConversationRelation.id,
        conversationLastSyncTimeStamp: new Date(
            tableConversationRelation.lastModifiedTimeStamp
        ).toISOString(),
    };
}
