import { lazyFetchAccessTokenFromMsalProxy } from 'owa-msaljs/lib/lazyAppBoot';
import { getConfig } from 'owa-service/lib/config';
import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import type TokenResponseCode from 'owa-service/lib/contract/TokenResponseCode';
import type {
    EnterpriseTokenRequestParams,
    TokenRequestParams,
} from '../schema/TokenRequestParams';
import type { ILogger } from './ILogger';

export async function getTokenFromMsalForEnterprise(
    enterpriseParams: TokenRequestParams,
    logger: ILogger
): Promise<TokenResponse> {
    const resource = (enterpriseParams as EnterpriseTokenRequestParams).resource;

    const config = getConfig();
    var tokenResponse = config?.getMsalToken
        ? await config.getMsalToken(
              false /*isConsumer*/,
              resource,
              undefined /*mailboxInfo*/,
              undefined /*scope*/
          )
        : await lazyFetchAccessTokenFromMsalProxy.importAndExecute(resource);
    logger.addCheckpoint('getTokenFromMSAL_FetchedFromMSAL');
    if (tokenResponse) {
        return tokenResponse;
    }
    return { SubErrorCode: undefined, TokenResultCode: 2 };
}
