export enum SearchScopeKind {
    PrimaryMailbox = 'PrimaryMailbox',
    ArchiveMailbox = 'ArchiveMailbox',
    SharedFolders = 'SharedFolders',
    Group = 'Group',
    Calendar = 'Calendar',
    PublicFolder = 'PublicFolder',
    MetaOSApp = 'MetaOSApp',
    PstFile = 'PstFile',
}

export interface PrimaryMailboxSearchScope {
    kind: SearchScopeKind.PrimaryMailbox;
    folderId: string;
}

export interface ArchiveMailboxSearchScope {
    kind: SearchScopeKind.ArchiveMailbox;
    folderId: string;
}

export interface SharedFoldersSearchScope {
    kind: SearchScopeKind.SharedFolders;
    folderId: string;
}

export interface SingleGroupSearchScope {
    kind: SearchScopeKind.Group;
    groupId: string;
}

export interface SingleGroup3sSearchScope extends SingleGroupSearchScope {
    folderId: string;
}

export interface CalendarSearchScope {
    kind: SearchScopeKind.Calendar;
    calendarFolderId?: string;
}

export interface PublicFolderSearchScope {
    kind: SearchScopeKind.PublicFolder;
    folderId: string;
}

export interface MetaOSAppSearchScope {
    kind: SearchScopeKind.MetaOSApp;
    appId: string;
}

export interface PstFileSearchScope {
    kind: SearchScopeKind.PstFile;
    folderId: string;
}

export function primaryMailboxSearchScope(
    data: PrimaryMailboxSearchScope
): PrimaryMailboxSearchScope {
    return { ...data };
}

export function archiveMailboxSearchScope(
    data: ArchiveMailboxSearchScope
): ArchiveMailboxSearchScope {
    return { ...data };
}

export function sharedFoldersSearchScope(data: SharedFoldersSearchScope): SharedFoldersSearchScope {
    return { ...data };
}

export function singleGroupSearchScope(data: SingleGroupSearchScope): SingleGroupSearchScope {
    return { ...data };
}

export function singleGroup3sSearchScope(data: SingleGroup3sSearchScope): SingleGroup3sSearchScope {
    return { ...data };
}

export function calendarSearchScope(data: CalendarSearchScope): CalendarSearchScope {
    return { ...data };
}

export function publicFolderSearchScope(data: PublicFolderSearchScope): PublicFolderSearchScope {
    return { ...data };
}

export function metaOSAppSearchScope(data: MetaOSAppSearchScope): MetaOSAppSearchScope {
    return { ...data };
}

export function pstFileSearchScope(data: PstFileSearchScope): PstFileSearchScope {
    return { ...data };
}

export type SearchScopeForFolder =
    | PrimaryMailboxSearchScope
    | ArchiveMailboxSearchScope
    | SharedFoldersSearchScope
    | PublicFolderSearchScope
    | PstFileSearchScope;

export type SearchScope =
    | SearchScopeForFolder
    | SingleGroupSearchScope
    | SingleGroup3sSearchScope
    | CalendarSearchScope
    | MetaOSAppSearchScope;
