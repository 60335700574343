import { getToDoAccountSources } from './getToDoAccountSources';
import type { MailboxInfo } from 'owa-client-types';
import { getMailboxInfoForToDoAccounts } from './getMailboxInfoForToDoAccounts';
import {
    getGlobalSettingsAccountMailboxInfo,
    isSameMailboxInfo,
} from 'owa-account-source-list-store';
import { canUseToDoFeatures } from './moduleAccessUtils';

/**
 * Check if there's at least one connected account that has access to modern ToDo integration features
 */
export function hasToDoAccountSources(): boolean {
    return getToDoAccountSources().length > 0;
}

/**
 * Return fist valid mailboxInfo for an account that has access to modern ToDo integration features
 */
export function getValidMailboxInfoForTodo(): MailboxInfo {
    // Return the global account if it support mailboxInfo
    const globalAccountMailboxInfo = getGlobalSettingsAccountMailboxInfo();
    if (canUseToDoFeatures(globalAccountMailboxInfo)) {
        return globalAccountMailboxInfo;
    }

    const validMailboxInfos = getMailboxInfoForToDoAccounts();
    return validMailboxInfos?.[0];
}

export function hasMultipleToDoAccountSources(): boolean {
    return getToDoAccountSources().length > 1;
}

export function isValidMailboxInfoForTodo(mailboxInfo: MailboxInfo): boolean {
    const validMailboxInfo = getMailboxInfoForToDoAccounts().find(todoMailboxInfo =>
        isSameMailboxInfo(todoMailboxInfo, mailboxInfo)
    );
    return !!validMailboxInfo;
}
