enum SweepOptionType {
    // Delete all emails
    All,

    // Delete all current and future emails
    AllCurrentAndFuture,

    // Keep the latest email and delete the rest
    AllButOne,

    // Delete emails older than 10 days
    AllOlderThan,
}

export default SweepOptionType;
