import { lazyGetChangeNoteColorSections } from 'owa-mail-ribbon-sections/lib/sections';

import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onChangeNoteColorMenuClicked = (props: ReadOnlyRibbonControlDefProps) => {
    lazyGetChangeNoteColorSections.import().then(getChangeNoteColorSections => {
        setMenuDefinition(
            'ChangeNoteColorMenuDefinitionStore',
            undefined /* projectionTabId */,
            () => {
                return {
                    sections: getChangeNoteColorSections(props),
                };
            }
        );
    });
};
