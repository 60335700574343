import { action } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';

export const leaveGroupSuccess = action(
    'LEAVE_GROUP_SUCCESS',
    (groupId: string, mailboxInfo: MailboxInfo) => ({
        groupId,
        mailboxInfo,
    })
);
