import { mutatorAction } from 'satcheljs';
import { getFavoriteGroupsListNodeViewState } from '../selectors/getFavoriteGroupsListNodeViewState';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

/**
 * Expands or collapses the group
 * @param groupId - smtp address of a group
 * @param mailboxInfo - mailbox info of the account
 */
export default mutatorAction('toggleFavoriteGroupListNodeExpansion', (groupId: string): void => {
    const groupViewState = getFavoriteGroupsListNodeViewState().groupListNodesViewStates.get(
        transformGroupSmtp(groupId)
    );

    if (groupViewState) {
        groupViewState.isExpanded = !groupViewState.isExpanded;
    }
});
