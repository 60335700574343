import getStore from '../store/store';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';
import { getUserConfiguration } from 'owa-session-store';
import { isSharedCoprincipalAccountEnabled } from 'owa-anchormailbox/lib/isSharedCoprincipalAccountEnabled';
import { isMailboxSharedOrDelegateUserMailbox } from 'owa-anchormailbox/lib/isMailboxSharedOrDelegateUserMailbox';

export const getIsCustomSortEnabled = (mailboxInfo: MailboxInfo) => {
    if (isSharedCoprincipalAccountEnabled() && isMailboxSharedOrDelegateUserMailbox(mailboxInfo)) {
        // Custom sorting is not supported for shared coprincipal accounts
        return false;
    }

    const userConfiguration = getUserConfiguration();
    const isExplicitLogon = userConfiguration?.SessionSettings?.IsExplicitLogon;
    return (
        !isExplicitLogon &&
        isFeatureEnabled('fwk-folders-custom-sorted-on-boot', mailboxInfo) &&
        !getStore().isAlphabetizeFolderList
    );
};
