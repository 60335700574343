import { updateUserConfigurationService } from 'owa-service/lib/utils/updateUserConfigurationService';

export default function updateFavoritesUserOptionService(
    newFavoritesNodesRaw: string[]
): Promise<void> {
    return updateUserConfigurationService([
        {
            key: 'FavoriteNodes',
            valuetype: 'StringArray',
            value: newFavoritesNodesRaw,
        },
    ]).then(response => {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2532 (18,34): Object is possibly 'undefined'.
        // @ts-expect-error
        const responseItem = response.Body.ResponseMessages.Items[0];
        if (responseItem.ResponseClass == 'Success') {
            return Promise.resolve();
        } else {
            return Promise.reject(
                /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                 * Error constructor names can only be a string literals.
                 *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
                new Error('updateUserConfiguration failed: ' + responseItem.ResponseClass)
            );
        }
    });
}
