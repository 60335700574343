import { mailFaviconProvider, mailOverlayIconProvider } from './mailFaviconProviders';

// Initialize orchestrators
import '../orchestrators/onFolderSelectedOrchestrator';
import '../orchestrators/onNewMessageOrchestrator';
import '../orchestrators/onPageVisibilityChanged';

export default function getMailFavicon() {
    return { icon: mailFaviconProvider(), overlay: mailOverlayIconProvider() };
}
