import { markAsRead } from 'owa-locstrings/lib/strings/markasread.locstring.json';
import { markAsUnread } from 'owa-locstrings/lib/strings/markasunread.locstring.json';
import { observer } from 'owa-mobx-react';
import React from 'react';
import loc from 'owa-localize';
import {
    lazyToggleRowReadState,
    lazyMarkItemsAsReadBasedOnNodeIds,
} from 'owa-mail-mark-read-actions';

import {
    container,
    unreadBarItemPart,
    unreadBarUnreadItemPart,
    unreadBarHoverTreatmentMLItem,
    unreadBarHoverTreatmentMLItemPart,
    unreadBar,
    unreadBarUnread,
} from './MailListItemUnreadBar.scss';
import { useMailListItemContainerContext } from 'owa-mail-list-item-shared';
import { getConditionalFormattingStylesForButton } from 'owa-conditional-formatting';

import classnames from 'owa-classnames';

export interface MailListItemUnreadBarProps {
    isUnread: boolean;
    rowKey: string;
    tableViewId: string;
    isExpanded?: boolean;
    isItemPart?: boolean;
}

export default observer(function MailListItemUnreadBar(props: MailListItemUnreadBarProps) {
    const { isUnread, rowKey, tableViewId, isExpanded = false, isItemPart = false } = props;

    const { conditionalFormattingModifications } = useMailListItemContainerContext();
    const unreadBarConditionalFormattingStyles = React.useMemo(() => {
        const conditionalFormattingStyles =
            conditionalFormattingModifications && conditionalFormattingModifications.length > 0
                ? getConditionalFormattingStylesForButton(conditionalFormattingModifications)
                : undefined;

        return conditionalFormattingStyles;
    }, [conditionalFormattingModifications]);

    /**
     * If the unread bar is clicked, toggle the read state of the row and
     * stop propagation so the row isn't selected.
     */
    const onClick = React.useCallback(
        async (ev: React.MouseEvent) => {
            ev.stopPropagation();

            if (isItemPart) {
                const markItemsAsReadBasedOnNodeIds =
                    await lazyMarkItemsAsReadBasedOnNodeIds.import();
                markItemsAsReadBasedOnNodeIds(
                    [rowKey],
                    tableViewId,
                    isUnread /* isReadValueToSet */,
                    true /* isExplicit */,
                    null /* actionSource */
                );
            } else {
                lazyToggleRowReadState.importAndExecute(
                    rowKey,
                    tableViewId,
                    null /* actionSource */
                );
            }
        },
        [isUnread, rowKey, tableViewId, isItemPart]
    );

    const shouldShowHoverTreatment = React.useMemo(() => {
        if (isItemPart) {
            // For item parts, we should show the hover treatment only when the
            // item part is hovered and the item part is read (when it's unread,
            // we leave it in its expanded state).
            return !isUnread;
        } else {
            // For top-level rows, we should show the hover treatment when the
            // row is hovered (regardless of read state), or when the row is unread
            // and the row is expanded (so it matches the width of the item part's
            // unread bar).
            return isUnread && isExpanded;
        }
    }, [isItemPart, isUnread, isExpanded]);

    return (
        <div className={container}>
            <button
                /**
                 * Decision to remove button from tab order because there are other keyboard
                 * accessible ways to mark as read (i.e. keyboard shortcut hover action).
                 */
                tabIndex={-1}
                className={classnames(
                    unreadBar,
                    isItemPart && unreadBarItemPart,
                    // Note: In the future, we will need to check if there is a color modification and only if so, we don't apply the unread styles. Since we
                    // currently only support the Color modification, we know that if unreadBarConditionalFormattingStyles is defined, it is modifying color.
                    isUnread &&
                        !unreadBarConditionalFormattingStyles &&
                        (isItemPart ? unreadBarUnreadItemPart : unreadBarUnread),
                    shouldShowHoverTreatment &&
                        (isItemPart
                            ? unreadBarHoverTreatmentMLItemPart
                            : unreadBarHoverTreatmentMLItem)
                )}
                onClick={onClick}
                title={isUnread ? loc(markAsRead) : loc(markAsUnread)}
                style={isUnread ? unreadBarConditionalFormattingStyles : undefined}
            />
        </div>
    );
}, 'MailListItemUnreadBar');
