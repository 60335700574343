import { getSelectedTableView } from 'owa-mail-list-store';
import { lazyRestoreMailListRowsAction } from 'owa-mail-triage-action';
import { resetFocus } from 'owa-mail-focus-manager';
import type { ActionSource } from 'owa-analytics-types';

export default function onRestore(actionSource: ActionSource) {
    const tableView = getSelectedTableView();
    const rowKeys = [...tableView.selectedRowKeys.keys()];

    lazyRestoreMailListRowsAction.importAndExecute(rowKeys, tableView.id, actionSource);
    resetFocus('restoreCommand');
}
