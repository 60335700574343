import { isFeatureEnabled } from 'owa-feature-flags';
import { getCopilotThemes } from '../utils/getModernThemes';
import { getThemeIdFromParameter } from '../utils/optionalArgumentHelpers';

export default function getIsCurrentThemeCopilot(themeId?: string) {
    themeId = getThemeIdFromParameter(themeId);

    return (
        isFeatureEnabled('fwk-copilot-themes') &&
        getCopilotThemes().some(theme => themeId.startsWith(theme))
    );
}
