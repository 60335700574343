import type {
    MailboxInfoInput,
    FolderHierarchyResult,
    MailFolder,
    MailboxType,
} from 'owa-graph-schema';

import {
    FOLDER_TYPE_NAME,
    INBOX_FOLDER_NAME,
    SEARCH_FOLDER_ROOT_DISTINGUISHED_ID,
    SEARCH_FOLDER_TYPE_NAME,
    SHARED_FOLDER_ROOT_DISTINGUISHED_ID,
} from 'owa-folders-constants';

import type BaseFolderType from 'owa-service/lib/contract/BaseFolderType';
import type DistinguishedFolderIdName from 'owa-service/lib/contract/DistinguishedFolderIdName';
import type FindFolderResponseMessage from 'owa-service/lib/contract/FindFolderResponseMessage';
import type FolderType from 'owa-service/lib/contract/Folder';
import type { MailboxInfo } from 'owa-client-types';
import type SearchFolderTemplateId from 'owa-service/lib/contract/SearchFolderTemplateId';
import type SearchFolderType from 'owa-service/lib/contract/SearchFolder';
import { bindTypename } from 'bind-typenames';
import { default as createFolderId } from 'owa-service/lib/factory/folderId';
import getIsFolderHiddenProperty from './getIsFolderHiddenProperty';
import getRemoteFolderDisplayNameProperty from './getRemoteFolderDisplayNameProperty';
import { getReplicaListProperty } from './getReplicaListProperty';
import getSearchFolderAllowAgeoutProperty from './getSearchFolderAllowAgeoutProperty';
import getSourceWellKnownFolderTypeProperty from './getSourceWellKnownFolderTypeProperty';
import { trace } from 'owa-trace';

// Eventually this file should be behind web resolver boundary once other scenarios that
// depend on folders are also migrated

/**
 * Maps the legacy findFolders response to GQL response
 * @param findFolderResponse folder hierarchy response
 * @param mailboxInfo mailboxInfo
 * @param shouldShowNotesFolder whether client supports notes folder
 * @param replicaListMap replica list map
 */
export function mapFindFolderResponseToGql(
    findFolderResponse: FindFolderResponseMessage,
    mailboxInfo: MailboxInfoInput,
    shouldShowNotesFolder: boolean,
    shouldShowConversationHistoryFolder: boolean,
    shouldShowOutboxFolder: boolean
): FolderHierarchyResult {
    /**
     * Throw in case response is not successful or expected data is not found
     */
    const rootFolder = findFolderResponse.RootFolder;
    if (!rootFolder || !rootFolder.Folders || !rootFolder.ParentFolder) {
        throw new Error('mapFindFolderResponseToGql: failed to get folders');
    }
    const distinguishedFolderId = rootFolder?.ParentFolder?.DistinguishedFolderId || 'none';

    const mailFolders: MailFolder[] = [];
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    rootFolder?.Folders.forEach(folder => {
        // Eliminate folders that client wants to hide or does not support.
        if (
            folder &&
            canAddFolderToFolderResult(
                folder,
                shouldShowNotesFolder,
                shouldShowConversationHistoryFolder,
                distinguishedFolderId == SEARCH_FOLDER_ROOT_DISTINGUISHED_ID,
                shouldShowOutboxFolder
            )
        ) {
            const gqlFolder = mapOWSFolderToGql(folder, mailboxInfo);
            if (gqlFolder) {
                mailFolders.push(gqlFolder);
            }
        }
    });
    /**
     * Update distinguished folder id name of root folder for shared folder hierarchies
     */
    const rootDistinguishedFolderIdName = getDistinguishedFolderIdNameForRootFolder(
        distinguishedFolderId,
        mailboxInfo.type
    );

    rootFolder.ParentFolder.DistinguishedFolderId = rootDistinguishedFolderIdName;

    // Shared folders fetch Root or Inbox folder and return whatever user has permission, giving priority
    // to root and falling back to Inbox if it allows. In case of Inbox, we need to:
    // - Create a dummy root folder where the folderId is the parent of Inbox
    // - Add Inbox (returned as ParentFolder) to the list of mail folders
    let mapRootFolder = mapOWSFolderToGql(rootFolder.ParentFolder, mailboxInfo);
    if (distinguishedFolderId === INBOX_FOLDER_NAME && mailboxInfo.type === 'SharedMailbox') {
        const placeHolderFolder: BaseFolderType = {
            FolderId: rootFolder.ParentFolder.ParentFolderId,
            ParentFolderId: createFolderId({
                Id: SHARED_FOLDER_ROOT_DISTINGUISHED_ID,
            }),
            __type: FOLDER_TYPE_NAME,
        };

        // Add Inbox to the list of folders
        if (mapRootFolder) {
            mailFolders.push(mapRootFolder);
        }

        // Overwrite the Root to be a placeholder folder, parent of Inbox
        mapRootFolder = mapOWSFolderToGql(placeHolderFolder, mailboxInfo);
    }

    return {
        __typename: 'FolderHierarchyResult',
        Folders: mailFolders,
        RootFolder: mapRootFolder,
        offset: rootFolder.IndexedPagingOffset,
        TotalItemsInView: rootFolder.TotalItemsInView,
        IncludesLastItemInRange: rootFolder.IncludesLastItemInRange,
        CustomSorted: rootFolder.CustomSorted,
    };
}

/**
 * Helper for getting distinguished id folder name for mailbox root
 * @param distinguishedFolderId - distinguishedFolderId
 * @param mailboxType - mailbox type
 */
export function getDistinguishedFolderIdNameForRootFolder(
    distinguishedFolderId: string,
    mailboxType: MailboxType
): string | undefined {
    // Shared folder roots have distinguishedIdNames as msgFolderRoot which is same as primary mailbox.
    // We should not populate them to avoid confusion at any point of time
    return mailboxType === 'SharedMailbox' ? undefined : distinguishedFolderId;
}

function isOfTypeMailFolder(folderType: string | undefined): boolean {
    return folderType === FOLDER_TYPE_NAME;
}

export function isOfTypeSearchFolder(folderType: string | undefined): boolean {
    return folderType === SEARCH_FOLDER_TYPE_NAME;
}

// Any changes you make here, make sure to add them to similar logic in getFolderStorageInformation
/**
 * Return flag indicating whether folder should be returned
 * @param folder OWS folder type
 * @param shouldShowNotesFolder whether client supports notes folder
 */
export function canAddFolderToFolderResult(
    folder: BaseFolderType,
    shouldShowNotesFolder: boolean,
    shouldShowConversationHistoryFolder: boolean,
    isChildOfSearchFolders: boolean,
    shouldShowOutboxFolder: boolean
): boolean {
    // Do not show the folder if it's not the correct folder type.
    if (isChildOfSearchFolders) {
        if (!isOfTypeSearchFolder(folder?.__type) || getSearchFolderAllowAgeoutProperty(folder)) {
            return false;
        }
    } else {
        if (!isOfTypeMailFolder(folder?.__type)) {
            return false;
        }
    }

    const distinguishedIdName = folder?.DistinguishedFolderId as DistinguishedFolderIdName;
    // We want to show the outbox folder based on the passed in property
    if (distinguishedIdName === 'outbox') {
        return shouldShowOutboxFolder;
    }

    // Hide journal and syncissues folders
    if (distinguishedIdName == 'syncissues' || distinguishedIdName == 'journal') {
        return false;
    }

    // Eliminate hidden folders before returning response
    if (getIsFolderHiddenProperty(folder)) {
        return false;
    }

    // Clutter folder cant be deleted if it is a distinguished folder. We have seen it not being a
    // distinguished folder, which users can actually remove if it bothers them.
    // In case it is distinguished and has no item in it, just hide it
    if (folder.DistinguishedFolderId === 'clutter' && folder.TotalCount === 0) {
        // Skip adding clutter folder if it has no items in it
        return false;
    }

    // Add notes to the folder hierarchy list if client supports showing notes folder
    if (folder.DistinguishedFolderId === 'notes') {
        return !!shouldShowNotesFolder;
    }

    // Don't show non default notes folders
    if (folder.FolderClass === 'IPF.StickyNote') {
        return false;
    }

    // Add the conversation history to the folder hierarchy list if client supports showing that folder
    if (folder.DistinguishedFolderId === 'conversationhistory') {
        return !!shouldShowConversationHistoryFolder;
    }

    return true;
}

export function mapOWSFolderToGql(
    folder_0: BaseFolderType,
    mailboxInfo: MailboxInfo
): MailFolder | null {
    const folder = folder_0 as FolderType;
    const {
        FolderId,
        ParentFolderId,
        DisplayName,
        ArchiveTag,
        PolicyTag,
        ChildFolderCount,
        UnreadCount,
        TotalCount,
        FolderClass,
        DistinguishedFolderId,
        EffectiveRights,
    } = folder;
    if (!FolderId?.Id || !ParentFolderId?.Id) {
        trace.warn(
            'mapOWSFolderToGql: ignoring folder with missing folderId or missing parentFolderId'
        );
        return null;
    }
    const numOfChildFolders = ChildFolderCount || 0;
    const folderId = bindTypename(FolderId, 'FolderId');
    const replicaList = getReplicaListProperty(folder);
    return {
        id: folderId?.Id ?? '',
        changeKey: folderId?.ChangeKey,
        __typename: 'MailFolder',
        type: folder.__type,
        parentFolderId: bindTypename(ParentFolderId, 'FolderId')?.Id ?? '',
        displayName: DisplayName || '',
        distinguishedFolderType: DistinguishedFolderId?.toLowerCase() || null, // DistinguishedFolderId should always be lowercase. Due to service bug, forcing here
        FolderClass: FolderClass || 'IPF.Note',
        UnreadCount: UnreadCount || 0,
        totalMessageCount: DistinguishedFolderId?.toLowerCase() == 'outbox' ? 0 : TotalCount || 0,
        EffectiveRights: bindTypename(EffectiveRights, 'EffectiveRightsType'),
        ArchiveTag: bindTypename(ArchiveTag, 'RetentionTagType'),
        PolicyTag: bindTypename(PolicyTag, 'RetentionTagType'),
        childFolderCount: numOfChildFolders,
        remoteFolderInfo: {
            remoteFolderDisplayName: getRemoteFolderDisplayNameProperty(folder),
            remoteDistinguishedFolderType: getSourceWellKnownFolderTypeProperty(folder),
        },
        replicaList,
        PermissionSet: folder.PermissionSet,
        messageSizeInBytes: getFolderSize(folder),
        pausedTotalCount: null,
        SearchFolderTemplateId: getSearchFolderTemplateId(folder),
        sortPosition: folder.SortPosition ?? null,
        sortParentId: folder.SortParentId ?? null,
        mailboxInfo: {
            type: mailboxInfo.type,
            sourceId: mailboxInfo.sourceId,
            userIdentity: mailboxInfo.userIdentity,
            mailboxSmtpAddress: mailboxInfo.mailboxSmtpAddress,
            auxiliaryMailboxGuid: replicaList?.[0],
            diagnosticData: mailboxInfo.diagnosticData ?? 'FindFolderResponseToGql',
        },
    };
}

function getFolderSize(folder: BaseFolderType): string {
    // ExtendedProperty[0] is the size of the folder. i.e 10MB, Value gives the size in bytes.
    return folder.ExtendedProperty?.[0]?.Value;
}

/* TODO: the service needs to send a number instead of string.
The SearchFolderTemplateId enum is a const enum (as prescribed by the autogen),
so lookups can only be done with string literals, not variables.
The service is sending a string instead of int for template ID,
so this complex casting and switch statement is the only way to get the right type */
function getSearchFolderTemplateId(folder: FolderType): SearchFolderTemplateId | null {
    if (isOfTypeSearchFolder(folder?.__type)) {
        const searchFolderTemplateId = (folder as SearchFolderType).TemplateId;

        // If the enum was set on the client, it is a number as desired and can be used as the enum
        if (typeof searchFolderTemplateId == 'number') {
            return searchFolderTemplateId == undefined ? null : searchFolderTemplateId;
        }

        // If the enum came from the service, it is a string and must be mapped to the enum type manually
        switch (searchFolderTemplateId as unknown as string) {
            case 'UNKNOWN':
                return 0;
            case 'CUSTOM':
                return 1;
            case 'UNREAD':
                return 2;
            case 'FOR_FOLLOWUP':
                return 3;
            case 'UNREAD_OR_FOLLOWUP':
                return 4;
            case 'IMPORTANT_MAIL':
                return 5;
            case 'CONVERSATIONS':
                return 6;
            case 'FROM_PERSON':
                return 7;
            case 'SENT_TO_ME':
                return 8;
            case 'SENT_TO_DISTLIST':
                return 9;
            case 'LARGE_MESSAGES':
                return 10;
            case 'OLD_MAIL':
                return 11;
            case 'WITH_ATTACH':
                return 12;
            case 'WITH_SPECIFIC_WORDS':
                return 14;
            case 'CATEGORIZED':
                return 15;
            default:
                return null;
        }
    }
    return null;
}
