import { getBposNavBarData } from 'owa-bpos-store/lib/bposNavBarData';
import { getNavBarLinkData } from './getNavBarLinkData';

import type NavBarLinkData from 'owa-service/lib/contract/NavBarLinkData';
import type RecursiveReadOnly from 'owa-service/lib/RecursiveReadOnly';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Get BPOS link data for a given app, reading from the module-bound
 * variable in a synchronous fashion.
 * It is useful to be synchronous because we can effectively define
 * static apps in module's top level (e.g. in NativeApps).
 * @param  {string} appId
 * @returns RecursiveReadOnly<NavBarLinkData>
 */
function getAppBposData(
    appId: string,
    mailboxInfo?: MailboxInfo
): RecursiveReadOnly<NavBarLinkData> | null {
    return getNavBarLinkData(appId, getBposNavBarData(mailboxInfo) ?? undefined, mailboxInfo);
}

export { getAppBposData };
