import { addFavoriteCompleted } from '../actions/v2/addFavoriteActions';
import { setHideFavoritesList } from '../utils/setHideFavoritesList';
import { orchestrator } from 'satcheljs';
import { logUsage } from 'owa-analytics';
import { shouldHideFavoritesList } from '../selectors/shouldHideFavoritesList';

export default <(actionMessage: ReturnType<typeof addFavoriteCompleted>) => void | Promise<void>>(
    /* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
     * Baseline. DO NOT COPY AND PASTE!
     *	> Do not register an orchestrator with an action that is defined in the same package */
    orchestrator(addFavoriteCompleted, () => {
        if (shouldHideFavoritesList()) {
            logUsage('FP_ShowFavorite_FavoriteAdded');
            setHideFavoritesList(false);
        }
    })
);
