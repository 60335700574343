import { computed } from 'mobx';
import { getIsViewModeSelected } from 'owa-command-ribbon-store';
import { type CommandingViewMode } from 'owa-outlook-service-option-store';
import {
    getDefaultMlrAppButtonStyles,
    getDefaultMlrAppRibbonButtonStylesForSelected,
} from '../multilineRibbonStyles';
import {
    getDefaultSlrAppRibbonButtonStyles,
    getDefaultSlrAppRibbonButtonStylesForSelected,
} from '../singleLineRibbonStyles';

const defaultRibbonStyles = computed((): any => {
    return getIsViewModeSelected(1)
        ? getDefaultSlrAppRibbonButtonStyles()
        : getDefaultMlrAppButtonStyles();
});

export const getDefaultRibbonStyles = () => defaultRibbonStyles.get();

const defaultRibbonStylesForSelected = computed((): any => {
    return getIsViewModeSelected(1)
        ? getDefaultSlrAppRibbonButtonStylesForSelected()
        : getDefaultMlrAppRibbonButtonStylesForSelected();
});

export const getDefaultRibbonStylesForSelected = () => defaultRibbonStylesForSelected.get();
