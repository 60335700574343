import type { MailboxInfo } from 'owa-client-types';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import { updateUserConfigurationService } from 'owa-service/lib/utils/updateUserConfigurationService';
import { getStoredMailboxJunkEmailConfiguration } from '../utils/getMailboxJunkEmailUtils';
import { errorThatWillCauseAlert } from 'owa-trace';

export default function updateSenderScreeningUserOptionService(mailboxInfo: MailboxInfo) {
    const isSenderScreeningEnabled =
        getStoredMailboxJunkEmailConfiguration(mailboxInfo).SenderScreeningEnabled;

    if (isSenderScreeningEnabled === undefined) {
        const error =
            'SenderScreeningEnabled from StoredMailboxJunkEmailConfiguration is undefined';
        errorThatWillCauseAlert(error);
        return Promise.reject(error);
    }

    return updateUserConfigurationService(
        [
            {
                key: 'IsSenderScreeningSettingEnabled',
                valuetype: 'Boolean',
                value: [String(isSenderScreeningEnabled)],
            },
        ],
        undefined /* configNames */,
        getMailboxRequestOptions(mailboxInfo)
    );
}
