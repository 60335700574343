import type { FrequentlyUsedFolder } from 'owa-mail-store';

export const MAX_FOLDER_LIST_SIZE: number = 10;

export let frequentlyUsedFolders: FrequentlyUsedFolder[] = [];
export function setFrequentlyUsedFolders(value: FrequentlyUsedFolder[]) {
    frequentlyUsedFolders = value;

    // Trim folder list to keep max items
    frequentlyUsedFolders.splice(MAX_FOLDER_LIST_SIZE);
}
