import type { PerformanceDatapoint } from 'owa-analytics';
import type { ReadingPaneSetting } from 'owa-mail-store';
import type LoadItemOptions from '../store/schema/LoadItemOptions';
import { isOfflineMailDataEnabled } from 'owa-offline-sync-feature-flags';

export function addCustomDataToSelectMailItem(
    selectMailItemDatapoint?: PerformanceDatapoint | null,
    readingPaneSetting?: ReadingPaneSetting,
    isSingleLineView?: boolean
) {
    if (!!selectMailItemDatapoint) {
        selectMailItemDatapoint.addToCustomWaterfall(
            1,
            'readingPaneSetting',
            false /* discardIfDefined */,
            readingPaneSetting as string
        );
        if (isSingleLineView !== undefined) {
            selectMailItemDatapoint.addToCustomWaterfall(
                2,
                'isSingleLineView',
                false /* discardIfDefined */,
                isSingleLineView ? 'true' : 'false'
            );
        }
        selectMailItemDatapoint.addToCustomWaterfall(
            6,
            'isOfflineSyncEnabled',
            false /* discardIfDefined*/,
            isOfflineMailDataEnabled() ? 'true' : 'false'
        );
    }
}

export function isCoreScenario(isSmime: boolean, options?: LoadItemOptions): boolean {
    return !(
        isSmime ||
        (options &&
            (options.isDeepLink ||
                options.isPreviewPane ||
                options.isPrint ||
                options.isProjection ||
                options.isReload))
    );
}
