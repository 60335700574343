import type { QuickStepAction } from 'owa-quick-step-types';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import getStore from '../store/store';

export interface QuickStepReturnInfo {
    actions: QuickStepAction[];
    index: number;
}

export function getQuickStepActionsById(mailboxInfo: MailboxInfo, id: string): QuickStepReturnInfo {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    // retrieve the quick steps
    if (getStore().quickStepsStoreMap.has(key)) {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        const quickStepsList = getStore().quickStepsStoreMap.get(key)!.quickSteps;
        const quickStep = quickStepsList.find(quickStepItem => quickStepItem.id === id);
        return {
            actions: quickStep?.actions ?? [],
            index: quickStep ? quickStepsList.indexOf(quickStep) : -1,
        };
    }
    // this function is called when the user selects a quick step in the ribbon
    // we should never be calling this if the quick steps for that mailbox aren't loaded
    // so throw an error if that happends
    else {
        throw new Error('trying to find quick step before they are loaded');
    }
}
