import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';

/**
 * Function name: getGroupsAppIsOptedIn
 * Description: Gets the value of the GroupsAppIsOptedIn flag from the user configuration.
 * @param mailboxInfo: MailboxInfo
 */
export default function getGroupsAppIsOptedIn(mailboxInfo: MailboxInfo): undefined | boolean {
    if (isFeatureEnabled('grp-WeSpaceDeprecation', mailboxInfo)) {
        // If the We Space deprecation is enabled, user is opted in by default
        return true;
    }
    const userConfiguration = getAccountScopeUserSettings(mailboxInfo);
    return userConfiguration?.ViewStateConfiguration?.GroupsAppIsOptedIn;
}
