import type { DatapointOptions, CustomData } from 'owa-analytics-types';
import VerboseDatapoint from '../datapoints/VerboseDatapoint';

export function logVerboseUsage(
    eventName: string,
    customData?: CustomData,
    options?: DatapointOptions
): void {
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a string literals as the first argument of the constructor. */
    const dp = new VerboseDatapoint(eventName, options);
    if (customData) {
        dp.addCustomData(customData);
    }
    dp.end();
}
