import { LazyAction, LazyModule, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "AddinsView" */ './lazyIndex'),
    { name: 'AddinsView' }
);

export const CopilotAppView = createLazyComponent(lazyModule, m => m.CopilotAppView);

export const lazyCreateAddinCommandSurfaceAction = new LazyAction(
    lazyModule,
    m => m.createAddinCommandSurfaceAction
);
export const lazyOnSendAddinItemSwitch = new LazyAction(lazyModule, m => m.onSendAddinItemSwitch);
export const lazyOnLaunchEventTriggered = new LazyAction(lazyModule, m => m.onLaunchEventTriggered);
export const lazyOnHostItemChanged = new LazyAction(lazyModule, m => m.onHostItemChanged);

export const lazyChildWindowMessageListener = new LazyAction(
    lazyModule,
    m => m.childWindowMessageListener
);
export const AddinsFlexPaneHeader = createLazyComponent(lazyModule, m => m.AddinsFlexPaneHeader);
export const AddinsFlexPaneBody = createLazyComponent(lazyModule, m => m.AddinsFlexPaneBody);
export const TaskPaneCollection = createLazyComponent(lazyModule, m => m.TaskPaneCollection);
export const lazyOnItemNavigation = new LazyAction(lazyModule, m => m.onItemNavigation);
export const lazyAllowItemSendAndBlockOnSendAddinEvents = new LazyAction(
    lazyModule,
    m => m.allowItemSendAndBlockOnSendAddinEvents
);
export const lazyOnComposeClose = new LazyAction(lazyModule, m => m.onComposeClose);
export const lazyShowOfflineModeDialog = new LazyAction(lazyModule, m => m.showOfflineModeDialog);
