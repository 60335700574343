import type { GroupMailTableQuery } from 'owa-mail-list-store';
import { MailSortHelper, type TableQueryType } from 'owa-mail-list-store';
import { getFindItemShape, FindConversationShapeName } from 'owa-mail-find-rows';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import type { TraceErrorObject } from 'owa-trace';
import { errorThatWillCauseAlert } from 'owa-trace';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Create the table query to load group mail
 * @param groupId the groupId (group smtp address)
 * @param listViewType the list view type
 * @return tableQuery the tableQuery that needed to load group mail
 */
export default function createGroupMailTableQuery(
    groupId: string,
    listViewType: ReactListViewType,
    mailboxInfo: MailboxInfo
): GroupMailTableQuery {
    if (!groupId) {
        const error: TraceErrorObject = new Error('CantCreateGroupTableQuery');
        error.additionalInfo = { listViewType, ty: typeof groupId };
        errorThatWillCauseAlert(error);
    }

    let requestShapeName;
    switch (listViewType) {
        case 0:
            requestShapeName = FindConversationShapeName;
            break;

        case 1:
            requestShapeName = getFindItemShape();
            break;
    }

    const groupMailTableQuery: GroupMailTableQuery = {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (31,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        categoryName: null,
        folderId: groupId,
        focusedViewFilter: -1,
        listViewType,
        viewFilter: 'All',
        sortBy: MailSortHelper.getDefaultSortBy(),
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (40,9): Type 'string | undefined' is not assignable to type 'string'.
        // @ts-expect-error
        requestShapeName,
        type: 2,
        scenarioType: 'group',
        mailboxInfo,
    };

    return groupMailTableQuery;
}
