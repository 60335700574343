import type { IButtonStyles } from '@fluentui/react/lib/Button';

/* The following is styling to override acui's default compound button
 * (intended for the visible ribbon) in order to use them as entries in
 * a flyout dropdown. Values are based on the styling for the command
 * toolbar 'Attach file' dropdown.
 */
const attachmentMenuItem_PrimaryFontSize: number = 14;
const attachmentMenuItem_SecondaryFontSize: number = 11;
const attachmentMenuItem_RightLeftPadding: number = 11;
const attachmentMenuItem_IconSize: number = 32;

const attachmentMenuItem_PrimaryFontSizePx: string = attachmentMenuItem_PrimaryFontSize + 'px';
const attachmentMenuItem_SecondaryFontSizePx: string = attachmentMenuItem_SecondaryFontSize + 'px';
const attachmentMenuItem_PaddingPx: string = '0px ' + attachmentMenuItem_RightLeftPadding + 'px';
const attachmentMenuItem_IconSizePx: string = attachmentMenuItem_IconSize + 'px';

export const attachmentMenuItemStyle: IButtonStyles = {
    root: {
        fontSize: attachmentMenuItem_SecondaryFontSizePx,
        minHeight: '48px',
        padding: attachmentMenuItem_PaddingPx,

        '.ms-Button-textContainer': {
            marginLeft: '8px',
        },

        '.ms-Button-label': {
            fontSize: attachmentMenuItem_PrimaryFontSizePx,
            fontWeight: 'normal',
            margin: '0px',
            paddingBottom: '1px',
        },

        '.ms-Button-icon': {
            height: attachmentMenuItem_IconSizePx,
            width: attachmentMenuItem_IconSizePx,
        },
    },
};
