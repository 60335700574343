import { ApolloLink } from '@apollo/client';
import { sanitizeVariables } from '../util/sanitizeVariables';

export default function sanitizeVariablesLink() {
    const link = new ApolloLink((operation, forward) => {
        sanitizeVariables(operation);
        return forward(operation);
    });

    return link;
}
