import type SharedMailboxState from './schema/SharedMailboxState';
import { createStore } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

const sharedMailboxStateStores = new Map<string, SharedMailboxState>();

export function getStore(mailboxInfo: MailboxInfo): SharedMailboxState {
    const name = `${getIndexerValueForMailboxInfo(mailboxInfo)}_sharedMailboxState`;
    let cachedStore = sharedMailboxStateStores.get(name);
    if (!cachedStore) {
        const initialStore: SharedMailboxState = {
            initialized: false,
            isSharedMailbox: false,
        };
        const sharedMailboxStateStore = createStore<SharedMailboxState>(name, initialStore)();
        sharedMailboxStateStores.set(name, sharedMailboxStateStore);
        cachedStore = sharedMailboxStateStore;
    }

    return cachedStore;
}
