import { getStore } from '../store/store';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import type InboxViewType from 'owa-service/lib/contract/InboxViewType';
import { addDatapointConfig } from 'owa-analytics-actions';
import type { ActionSource } from 'owa-mail-store';
import { action, orchestrator, mutatorAction } from 'satcheljs';
import { updateAccountScopeUserSettingsAndService } from 'owa-userconfiguration/lib/utils/updateAccountScopeUserSettingsAndService';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Update last visit timestamps for selected view and reset rollup data
 */
const updateWatermarksAndResetRollup = action(
    'updateWatermarksAndResetRollup',
    (
        focusedViewFilterToSelect: FocusedViewFilter,
        actionSource: ActionSource,
        mailboxInfo: MailboxInfo
    ) =>
        addDatapointConfig(
            {
                name: 'TnS_ResetFocusedOtherRollup',
                customData: [actionSource, focusedViewFilterToSelect],
            },
            { focusedViewFilterToSelect, mailboxInfo }
        )
);

/**
 * Reset rollup data in store
 */
export const resetRollupStore = mutatorAction('resetRollupStore', (mailboxInfo: MailboxInfo) => {
    const store = getStore(mailboxInfo);

    store.uniqueSenders = undefined;
    store.unseenCountToDisplay = undefined;
    store.viewType = 0;
});

/**
 * Update folder view watermark for focused/other folders
 */
orchestrator(updateWatermarksAndResetRollup, actionMessage => {
    const currentTime = new Date(Date.now()).toUTCString();

    updateAccountScopeUserSettingsAndService(actionMessage.mailboxInfo, {
        ViewStateConfiguration: {
            FocusedViewWatermark: currentTime,
            ClutterViewWatermark: currentTime,
        },
    });

    resetRollupStore(actionMessage.mailboxInfo);
});

export default updateWatermarksAndResetRollup;
