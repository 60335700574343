import { mutatorAction } from 'satcheljs';
import { getStore } from '../store/zoomStore';
import { ZOOM_INCREMENTS, DEFAULT_ZOOM } from '../utils/constants';
import { saveZoomIncrementToStorage } from '../utils/localStorage';

/*
 * Resets current zoom to default zoom (100%)
 */
export const zoomScaleReset = mutatorAction('zoomScaleReset', () => {
    const store = getStore();
    store.zoomIncrementIndex = ZOOM_INCREMENTS.indexOf(DEFAULT_ZOOM);
    // Save in local storage the current zoom setting
    saveZoomIncrementToStorage(ZOOM_INCREMENTS[store.zoomIncrementIndex]);
});

/*
 * Increases current zoom scale. It uses the zoom increments array index
 * to set the new zoom scale.
 */
export const zoomInScaleUpdate = mutatorAction('zoomInScaleUpdate', () => {
    const store = getStore();
    if (store.zoomIncrementIndex < ZOOM_INCREMENTS.length - 1) {
        store.zoomIncrementIndex += 1;
    }
    // Save in local storage the current zoom setting
    saveZoomIncrementToStorage(ZOOM_INCREMENTS[store.zoomIncrementIndex]);
});

/*
 * Decreases current zoom scale. It uses the zoom increments array index
 * to set the new zoom scale.
 */
export const zoomOutScaleUpdate = mutatorAction('zoomOutScaleUpdate', () => {
    const store = getStore();
    if (store.zoomIncrementIndex > 0) {
        store.zoomIncrementIndex -= 1;
    }
    // Save in local storage the current zoom setting
    saveZoomIncrementToStorage(ZOOM_INCREMENTS[store.zoomIncrementIndex]);
});

/*
 * Sets current zoom scale according to the zoom increments array index.
 * @param zoomIndex: zoom increments array index of the desired zoom
 *                   scale
 */
export const zoomScaleUpdate = mutatorAction('zoomScaleUpdate', zoomIndex => {
    const store = getStore();
    store.zoomIncrementIndex = zoomIndex;
    // Save in local storage the current zoom setting
    saveZoomIncrementToStorage(ZOOM_INCREMENTS[store.zoomIncrementIndex]);
});

/*
 * Show embiggen controls
 */
export const showZoomCallout = mutatorAction('showZoomControl', (context: string) => {
    const store = getStore();
    const viewState = store.zoomViewStates.get(context);
    if (viewState) {
        viewState.isCalloutVisible = true;
    }
});

/*
 * Hide embiggen controls
 */
export const hideZoomCallout = mutatorAction('hideZoomControl', (context: string) => {
    const store = getStore();
    const viewState = store.zoomViewStates.get(context);
    if (viewState) {
        viewState.isCalloutVisible = false;
    }
});
