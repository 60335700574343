import { mutator } from 'satcheljs';
import { getStore } from '../store/Store';
import { setShowFolderPane } from '../actions/setShowFolderPane';
import { logLeftPaneStateChange } from 'owa-left-pane-layout/lib/utils/logLeftPaneStateChange';

/**
 * Sets showFolderPane value in store to passed in value
 * @param showFolderPane Whether to show folder pane
 */
/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
export default mutator(setShowFolderPane, actionMessage => {
    getStore().showFolderPane = actionMessage.showFolderPane;

    if (actionMessage.stateChangeSource === undefined) {
        return;
    }

    logLeftPaneStateChange(actionMessage.showFolderPane, actionMessage.stateChangeSource);
});
