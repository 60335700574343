import { getAccountScopeUserSettings, isPremiumConsumer } from 'owa-session-store';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import type { MailboxInfo } from 'owa-client-types';

/**
 * The encryption feature is enabled when
 * 1. You're a premium consumer user (get the ProtectMessage version)
 * 2. You're an enterprise user with SegmentationSettings.Irm = true
 */
export default function isEncryptionEnabled(mailboxInfo: MailboxInfo) {
    return isConsumer(undefined /* smtpAddress */, mailboxInfo)
        ? isPremiumConsumer(mailboxInfo)
        : !!getAccountScopeUserSettings(mailboxInfo).SegmentationSettings?.Irm;
}
