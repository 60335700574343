import { addEasidAsLicensingMailboxInfo, type AccountSource } from 'owa-account-source-list-store';
import { getEasidResponseFromPrimeSettings } from '../selectors/getEasidResponseFromPrimeSettings';
import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';

export function setLicesningMailboxInfoFromUserConfiguration(
    account: AccountSource,
    owaUserConfig: OwaUserConfiguration
) {
    const easidResponse = getEasidResponseFromPrimeSettings(owaUserConfig?.PrimeSettings);

    if (easidResponse?.isEasid) {
        addEasidAsLicensingMailboxInfo(account.sourceId);
    }
}
