import {
    messageAdStaticContainerTwoAdOneLineExp,
    messageAdStaticContainerTwoAdOneLineExpMedium,
    messageAdStaticContainerTwoAdOneLineExpCompact,
    messageAdStaticContainerOneLineExp,
    messageAdStaticContainerOneLineExpMedium,
    messageAdStaticContainerOneLineExpCompact,
    messageAdStaticContainerTwoAdThreeLineExpDensity,
    messageAdStaticContainerTwoAdThreeLineExpDensityMedium,
    messageAdStaticContainerTwoAdThreeLineExpDensityCompact,
    messageAdStaticContainerThreeLineExpDensity,
    messageAdStaticContainerThreeLineExpDensityMedium,
    messageAdStaticContainerThreeLineExpDensityCompact,
} from './MessageAdListStubElement.scss';

import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import { isSingleLineListView } from 'owa-mail-layout';
import { getFocusedFilterForTable } from 'owa-mail-list-store';
import getSelectedTableView from 'owa-mail-list-store/lib/utils/getSelectedTableView';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import React from 'react';

const MessageAdListPreloadingStubElement = observer(function MessageAdListPreloadingStubElement() {
    const useAdViewOneLine = isSingleLineListView();

    // Allocate two ads space for Other Inbox and one Ad space for primary Ad
    const tableView = getSelectedTableView();
    const inOtherPivot = getFocusedFilterForTable(tableView) == 1;
    const showTwoAds = inOtherPivot;

    const oneLineContainer = showTwoAds
        ? classnames(
              getDensityModeCssClass(
                  undefined,
                  messageAdStaticContainerTwoAdOneLineExpMedium,
                  messageAdStaticContainerTwoAdOneLineExpCompact
              ),
              messageAdStaticContainerTwoAdOneLineExp
          )
        : classnames(
              getDensityModeCssClass(
                  undefined,
                  messageAdStaticContainerOneLineExpMedium,
                  messageAdStaticContainerOneLineExpCompact
              ),
              messageAdStaticContainerOneLineExp
          );
    const threeLinesContainer = showTwoAds
        ? classnames(
              getDensityModeCssClass(
                  undefined,
                  messageAdStaticContainerTwoAdThreeLineExpDensityMedium,
                  messageAdStaticContainerTwoAdThreeLineExpDensityCompact
              ),
              messageAdStaticContainerTwoAdThreeLineExpDensity
          )
        : classnames(
              getDensityModeCssClass(
                  undefined,
                  messageAdStaticContainerThreeLineExpDensityMedium,
                  messageAdStaticContainerThreeLineExpDensityCompact
              ),
              messageAdStaticContainerThreeLineExpDensity
          );
    return <div className={useAdViewOneLine ? oneLineContainer : threeLinesContainer} />;
}, 'MessageAdListPreloadingStubElement');
export default MessageAdListPreloadingStubElement;
