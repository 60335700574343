import { LazyAction, LazyModule, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "HotkeysMap" */ './lazyIndex'));

export { default as setIsHotkeysMapVisible } from './actions/setIsHotkeysMapVisible';

export type { CommandCategory } from './components/CommandCategory';

export const lazyLaunchHotkeysMap = new LazyAction(lazyModule, m => m.launchHotkeysMap);
export const lazyUpdateHotkeysMapStore = new LazyAction(lazyModule, m => m.updateHotkeysMapStore);

export const HotkeysMap = createLazyComponent(lazyModule, m => m.HotkeysMap);
