import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getAccountScopeUserSettings } from 'owa-session-store';
import type PrimeBootSetting from 'owa-service/lib/contract/PrimeBootSetting';

export default function getThemeOptionsFromPrimeSettings() {
    const primeSettingsItems = getAccountScopeUserSettings(getGlobalSettingsAccountMailboxInfo())
        ?.PrimeSettings?.Items;

    const copilotThemePrimeSetting: PrimeBootSetting[] | undefined = primeSettingsItems?.filter(
        (item: PrimeBootSetting) => item?.Id == 'CopilotThemeOptions'
    );

    return copilotThemePrimeSetting?.[0]?.Value[0];
}
