import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';
import type OptionsViewState from './schema/OptionsViewState';

const store = createStore<OptionsViewState>('optionsViewState', {
    isFullOptionsShown: false,
    areAllAllowedSubCategoriesLoaded: false,
    isSaving: false,
    currentCategoryKey: undefined,
    currentSubCategoryKey: undefined,
    subCategoryLoadState: new ObservableMap(),
    currentOptionKey: undefined,
    currentOptionSubKey: undefined,
    allowedOptions: [],
    dirtyOptions: [],
})();
export const getStore = () => store;
