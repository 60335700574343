import React from 'react';
import { RibbonTopBar } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { appIcons, getAppStrings, getAppColors } from 'owa-acui-theme';
import type {
    RibbonTabDefinitionBase,
    RibbonTabDefinitionSLR,
    RibbonTabDefinitionMLR,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { topBar, topBarContainer } from './CommandRibbonTopBar.scss';
import { observer } from 'owa-mobx-react';
import { safeRequestAnimationFrame } from 'owa-performance';

export const CommandRibbonTopBar = observer(function CommandRibbonTopBar({
    extraControlsBeforeTabs,
    extraControlsAfterTabs,
    onTabHeaderClick,
    ribbonTabs,
    selectedTab,
}: {
    extraControlsAfterTabs?: React.ReactNode;
    extraControlsBeforeTabs?: React.ReactNode;
    onTabHeaderClick: (tabId: string) => void;
    ribbonTabs: RibbonTabDefinitionBase[];
    selectedTab: RibbonTabDefinitionSLR | RibbonTabDefinitionMLR;
}) {
    // This is to ensure that dynamic indicator does not cause style recalcs and layout thrashing during initial render
    const [dynamicIndicator, setDynamicIndicator] = React.useState(false);

    React.useEffect(() => {
        safeRequestAnimationFrame(() => {
            setDynamicIndicator(true);
        });
    }, []);

    return (
        <div id="TopBar" className={topBar}>
            {extraControlsBeforeTabs}
            <div className={topBarContainer}>
                <RibbonTopBar
                    appColors={getAppColors()}
                    appStrings={getAppStrings()}
                    displayDynamicTabHeaderIndicator={dynamicIndicator}
                    onTabHeaderClick={onTabHeaderClick}
                    overflowTabHeaderMenuLauncherIcon={appIcons.tabListOverflowLauncher}
                    ribbonTabs={ribbonTabs}
                    selectedTabId={selectedTab.id}
                    isTabIndicatorRounded={true}
                />
            </div>
            {extraControlsAfterTabs}
        </div>
    );
},
'CommandRibbonTopBar');
