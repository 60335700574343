import { TabType } from '../store/schema/TabViewState';
import { getStore } from '../store/tabStore';
import { errorThatWillCauseAlert } from 'owa-trace';

export default function getTabsByTabType(type: TabType) {
    return getStore().tabs.filter(tab => tab.type == type);
}

export function getFullOptionsTab() {
    const tabs = getTabsByTabType(TabType.FullOptions);

    if (tabs.length > 1) {
        errorThatWillCauseAlert('We are having more than 1 FullOptions Projection Tab');
    }

    if (tabs.length === 0) {
        errorThatWillCauseAlert(
            'We are trying to get FullOptions Projection Tab, when it does not exist'
        );

        return undefined;
    }

    return tabs[0];
}
