import type MailSwipeAction from 'owa-service/lib/contract/MailSwipeAction';
import { tokens } from '@fluentui/react-theme';

const SWIPE_ACTION_BACKGROUND_COLORS = {
    [1]: tokens.colorPaletteRedBackground1,
    [2]: tokens.colorPaletteGreenBackground1,
    [3]: tokens.colorBrandBackground2,
    [5]: tokens.colorPaletteMarigoldBackground1,
    [4]: tokens.colorBrandBackground2,
    [0]: tokens.colorNeutralBackground2,
};

export function getBackgroundColorForSwipeAction(swipeAction: MailSwipeAction): string {
    return SWIPE_ACTION_BACKGROUND_COLORS[swipeAction] || 'transparent';
}
