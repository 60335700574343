import getTabById from './getTabById';
import { getTabHandler } from './TabHandler';

export default function getTabDeeplinkUrl(tabId: string): string | null {
    const tab = getTabById(tabId);
    if (tab) {
        const handler = getTabHandler(tab.type);
        return handler?.getTabDeeplinkUrl?.(tab) || null;
    }
    return null;
}
