/* eslint-disable @typescript-eslint/no-restricted-imports --
 *   'react-dom' should only be used within this package. All other packages should import through this package
 */
import type { ReactNode } from 'react';
import { createRoot } from 'react-dom/client';
import { render as ReactDOMRender, unmountComponentAtNode } from 'react-dom';
/* eslint-enable @typescript-eslint/no-restricted-imports */
import React from 'react';
import { isFeatureEnabled } from 'owa-feature-flags';
import { FluentContext } from './FluentContext';
import { CallbackOnMount } from './CallbackOnMount';
import { clearUnmountCallback, setUnmountCallback } from './publicUnmountComponentAtNode';

type UnmountCallback = () => void;

export const render = (
    element: ReactNode,
    container: Element,
    callback?: () => void
): UnmountCallback => {
    const targetDocument = container?.ownerDocument ?? window.document;

    if (isFeatureEnabled('fwk-createRoot')) {
        const root = createRoot(container);
        root.render(
            <CallbackOnMount callback={callback}>
                <React.StrictMode>
                    <FluentContext targetDocument={targetDocument}>{element}</FluentContext>
                </React.StrictMode>
            </CallbackOnMount>
        );

        const unmount = () => {
            clearUnmountCallback(container);
            root.unmount();
        };

        setUnmountCallback(container, unmount);
        return unmount;
    } else {
        ReactDOMRender(
            <React.StrictMode>
                <FluentContext targetDocument={targetDocument}>{element}</FluentContext>
            </React.StrictMode>,
            container,
            callback
        );

        const unmount = () => {
            clearUnmountCallback(container);
            unmountComponentAtNode(container);
        };

        setUnmountCallback(container, unmount);
        return unmount;
    }
};
