import folderStore from 'owa-folders';
import type { ObservableMap } from 'mobx';
import type { MailFolder, FolderForestTreeType } from 'owa-graph-schema';
import { type FolderForestNodeType } from 'owa-favorites-types';
import type { FolderTextFieldOperation } from '../store/schema/FolderTextFieldViewState';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action as deprecatedAction } from 'satcheljs/lib/legacy';
import { action } from 'satcheljs';
import viewStateStore from '../store/store';
import type FolderTreeViewStateStore from '../store/schema/FolderTreeViewStateStore';
import setFolderViewExpansionState from '../actions/setFolderViewExpansionState';
import { FAVORITE_FOLDERS_TREE_TYPE, GROUPS_FOLDERS_TREE_TYPE } from 'owa-folders-constants';
import { setGroupFolderViewExpanded } from 'owa-groups-shared-folders-store';
import type { MailboxInfo } from 'owa-client-types';
import setFavoriteGroupListNodeViewExpanded from 'owa-groups-shared-favorites-view-store/lib/actions/setFavoriteGroupListNodeViewExpanded';
import setFavoriteGroupFolderViewExpanded from 'owa-groups-shared-favorites-view-store/lib/actions/setFavoriteGroupFolderViewExpanded';
export interface FolderStoresState {
    folderTable: ObservableMap<string, MailFolder>;
    viewState: FolderTreeViewStateStore;
}

export const initiateMailFolderTextField = deprecatedAction('initiateMailFolderTextField')(
    function initiateMailFolderTextField(
        folderId: string,
        operation: FolderTextFieldOperation,
        folderTreeType: FolderForestTreeType,
        mailboxSmtpAddress: string,
        state: FolderStoresState = {
            folderTable: folderStore.folderTable,
            viewState: viewStateStore,
        },
        mailboxInfo?: MailboxInfo,
        folderNodeType?: FolderForestNodeType
    ): void {
        if (operation === 'new') {
            if (folderTreeType === GROUPS_FOLDERS_TREE_TYPE) {
                if (mailboxInfo) {
                    setGroupFolderViewExpanded(mailboxSmtpAddress, folderId, mailboxInfo);
                }
            } else if (
                folderTreeType === FAVORITE_FOLDERS_TREE_TYPE &&
                (folderNodeType === 2 || folderNodeType === 6)
            ) {
                setFavoriteGroupListNodeViewExpanded(mailboxSmtpAddress);
                setFavoriteGroupFolderViewExpanded(mailboxSmtpAddress, folderId);
            } else {
                setFolderViewExpansionState(folderId, true);
            }
        }

        state.viewState.folderTextFieldViewState = {
            folderId,
            operation,
            folderTreeType,
            folderNodeType,
            mailboxSmtpAddress,
            mailboxInfo,
        };
    }
);

export const dismissMailFolderTextField = action('dismissMailFolderTextField');
