import React from 'react';
import type { FolderTextFieldProps } from './FolderTextField';
import FolderTextField from './FolderTextField';

const FolderTextFieldWrapper = (props: FolderTextFieldProps) => {
    const fallbackComponent = React.useMemo(
        () => <div className={props.className} />,
        [props.className]
    );
    return (
        <React.Suspense fallback={fallbackComponent}>
            <FolderTextField {...props} />
        </React.Suspense>
    );
};

export default FolderTextFieldWrapper;
