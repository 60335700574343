/**
 * Returns a promise that would wait for a given time before resolving or rejecting
 *
 * @param promise Promise to wait for
 * @param time time in milliseconds to wait for the promise to resolve
 * @param errorMessage Error message to reject the promise with if the promise does not resolve within the given time
 */
export function timeout<T>(promise: Promise<T>, time: number, errorMessage?: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Error constructor names can only be a string literals.
             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
            reject(new Error(errorMessage ?? 'Promise timed out'));
        }, time);

        promise.then(
            value => {
                clearTimeout(timeoutId);
                resolve(value);
            },
            error => {
                clearTimeout(timeoutId);
                reject(error);
            }
        );
    });
}
