import type { MailboxInfo } from 'owa-client-types';
import getExtensibilityState from '../store/getExtensibilityState';
import { ExtensibilityModeEnum } from 'owa-addins-types';

export const isSpamAddinInstalled = (mailboxInfo: MailboxInfo): boolean => {
    const enabledAddinCommands = getExtensibilityState(mailboxInfo).EnabledAddinCommands;
    const isSpamAddinEnabled = enabledAddinCommands?.getExtensionPoint(
        ExtensibilityModeEnum.IntegratedSpam
    );
    return isSpamAddinEnabled?.length > 0;
};
