import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import { FocusedItemArea } from 'owa-mail-reading-pane-store/lib/store/schema/FocusedItemPart';
import type ItemPartViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemPartViewState';
import getConversationReadingPaneViewState from '../utils/getConversationReadingPaneViewState';

export interface FocusedItemPartState {
    conversationReadingPaneState: ConversationReadingPaneViewState;
}

export function getFocusedItemPart(conversationId?: string): ItemPartViewState | undefined {
    const state = getConversationReadingPaneViewState(conversationId);
    return state?.focusedItemPart?.itemPart;
}

export function getFocusedItemArea(conversationId?: string): FocusedItemArea | undefined {
    const state = getConversationReadingPaneViewState(conversationId);
    return state?.focusedItemPart?.focusedItemArea;
}

export function getInitiallySelectedItemPart(
    state: FocusedItemPartState = {
        conversationReadingPaneState: getConversationReadingPaneViewState(),
    }
): ItemPartViewState | undefined {
    return state.conversationReadingPaneState?.initiallySelectedItemPart
        ? state.conversationReadingPaneState.initiallySelectedItemPart.itemPart
        : undefined;
}

export function isItemPartSelected(itemPart?: ItemPartViewState, conversationId?: string): boolean {
    const selectedItemPart = getFocusedItemPart(conversationId);
    const selectedItemArea = getFocusedItemArea(conversationId);
    return selectedItemPart == itemPart && selectedItemArea == FocusedItemArea.Item;
}
