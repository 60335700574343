import type { AccountConfigurationOrigin } from '../types/AccountConfigurationOrigin';
import type { MailboxInfo } from 'owa-client-types';
import {
    getAccountKeyForMailboxInfo,
    isSameCoprincipalAccountByMailboxAndAccountKey,
} from 'owa-client-types';
import { logStartUsage } from 'owa-analytics-start';
import { getGlobalSettingsAccountKey } from 'owa-account-source-list-store';

const accountConfigurationSetCount = new Map<string, number>();

/**
 * Logs information about setting of account configuration information, in particular we want to
 * make sure know
 * @param configurationOrigin
 * @param mailboxInfo
 * @param forceIsGlobalAccountMailboxInfoResult
 */
export function logSetAccountConfiguration(
    configurationOrigin: AccountConfigurationOrigin,
    mailboxInfo: MailboxInfo,
    forceIsGlobalAccountMailboxInfoResult: boolean
) {
    // build a unique key for the account configuration data being set
    const key = `${forceIsGlobalAccountMailboxInfoResult}:${getAccountKeyForMailboxInfo(
        mailboxInfo
    )}`;

    const setCount = (accountConfigurationSetCount.get(key) || 0) + 1;
    accountConfigurationSetCount.set(key, setCount);

    const globalSettingsAccountKey = getGlobalSettingsAccountKey(false /*throwIfNotInitialized*/);

    logStartUsage('SetAccountConfiguration', {
        origin: configurationOrigin,
        setCount,
        isForceGlobal: forceIsGlobalAccountMailboxInfoResult,
        globalSettingsKeyLength: globalSettingsAccountKey?.length || 0,
        isGlobalSettingsAccount: isSameCoprincipalAccountByMailboxAndAccountKey(
            mailboxInfo,
            globalSettingsAccountKey
        ),
    });
}
