import { getIsDarkTheme } from 'owa-fabric-theme';

export enum FolderColorNames {
    cranberry = 'cranberry',
    peach = 'peach',
    gold = 'gold',
    bronze = 'bronze',
    lime = 'lime',
    dark_green = 'dark_green',
    light_teal = 'light_teal',
    dark_teal = 'dark_teal',
    light_blue = 'light_blue',
    dark_blue = 'dark_blue',
    lavender = 'lavender',
    dark_purple = 'dark_purple',
    pink = 'pink',
    magenta = 'magenta',
    silver = 'silver',
}

const lightModeColorMap: Map<string, string> = new Map([
    [FolderColorNames.cranberry, '#C50F1F'],
    [FolderColorNames.peach, '#FF8C00'],
    [FolderColorNames.gold, '#EAA300'],
    [FolderColorNames.bronze, '#A74109'],
    [FolderColorNames.lime, '#73AA24'],
    [FolderColorNames.dark_green, '#0B6A0B'],
    [FolderColorNames.light_teal, '#00B7C3'],
    [FolderColorNames.dark_teal, '#006666'],
    [FolderColorNames.light_blue, '#3A96DD'],
    [FolderColorNames.dark_blue, '#003966'],
    [FolderColorNames.lavender, '#7160E8'],
    [FolderColorNames.dark_purple, '#401B6C'],
    [FolderColorNames.pink, '#E43BA6'],
    [FolderColorNames.magenta, '#BF0077'],
    [FolderColorNames.silver, '#859599'],
]);

const darkModeColorMap: Map<string, string> = new Map([
    [FolderColorNames.cranberry, '#DC626D'],
    [FolderColorNames.peach, '#FFBA66'],
    [FolderColorNames.gold, '#F2C661'],
    [FolderColorNames.bronze, '#CA8057'],
    [FolderColorNames.lime, '#A4CC6C'],
    [FolderColorNames.dark_green, '#4DA64D'],
    [FolderColorNames.light_teal, '#58D3DB'],
    [FolderColorNames.dark_teal, '#41A3A3'],
    [FolderColorNames.light_blue, '#83BDEB'],
    [FolderColorNames.dark_blue, '#4178A3'],
    [FolderColorNames.lavender, '#A79CF1'],
    [FolderColorNames.dark_purple, '#7E5CA7'],
    [FolderColorNames.pink, '#EF85C8'],
    [FolderColorNames.magenta, '#D957A8'],
    [FolderColorNames.silver, '#B3BFC2'],
]);

export const getFolderColor = (colorName: string): string | undefined => {
    const isDarkMode = getIsDarkTheme();
    return isDarkMode ? darkModeColorMap.get(colorName) : lightModeColorMap.get(colorName);
};
