import { ribbonExperiments } from '@1js/acui-experiments';
import { isFeatureEnabled } from 'owa-feature-flags';

// Each `feature` is defined in
// https://office.visualstudio.com/Office/_git/1JS?path=/ooui/packages/acui-experiments/src/ribbonExperiments.ts

export const getRibbonExperiments = (feature: string): boolean => {
    return (
        feature === ribbonExperiments.multilineRibbonIsEnabled ||
        feature === ribbonExperiments.reactSharedHeaderIsEnabled ||
        feature === ribbonExperiments.shouldMergeAppToggleButtonMenuItemStyles ||
        feature === ribbonExperiments.delayRenderTooltipsIsEnabled ||
        feature === ribbonExperiments.multiWindowSupportIsEnabled ||
        feature === ribbonExperiments.appTooltipAppContextIsEnabled ||
        feature === ribbonExperiments.undefinedCheckForHeaderCoordinates ||
        (isFeatureEnabled('mon-ribbon-fluent-v9-ribbon') &&
            feature === ribbonExperiments.fluentV9RibbonControlsIsEnabled)
    );
};
