import { MIN_FOLDER_PANE_WIDTH } from '../internalConstants';
import { APP_BAR_STATIC_WIDTH } from 'owa-layout';
import { getLeftNavMaxWidth } from './getMaxWidths';

interface LeftNavMinMaxWidths {
    dataMin: number;
    dataMax: number;
}

/**
 * Gets the min and max values for the left navigation pane widths for resize purposes
 * User can resize the left nav widths within these bounds.
 */
export function getDataMinMaxWidthsForLeftNav(): LeftNavMinMaxWidths {
    const maxLeftNavWidth = getLeftNavMaxWidth();
    return {
        dataMin: MIN_FOLDER_PANE_WIDTH + APP_BAR_STATIC_WIDTH,
        dataMax: maxLeftNavWidth,
    };
}
