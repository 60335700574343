import { addEventTimeToSourceMapping } from 'owa-performance';

export function addWrappedEventListener<K extends keyof HTMLElementEventMap>(
    source: string,
    elem: HTMLElement | undefined | null,
    eventName: K,
    handler: (evt: HTMLElementEventMap[K]) => void,
    options?: boolean | AddEventListenerOptions
): (() => any) | undefined {
    if (elem && typeof handler == 'function') {
        const cb = (evt: HTMLElementEventMap[K]) => {
            addEventTimeToSourceMapping(evt, source);
            handler(evt);
        };
        elem.addEventListener<K>(eventName, cb, options);

        return () => {
            elem.removeEventListener<K>(eventName, cb, options);
        };
    }

    return undefined;
}

export function addDocumentEventListener<K extends keyof DocumentEventMap>(
    source: string,
    eventName: K,
    handler: (evt: DocumentEventMap[K]) => void,
    document?: Document,
    options?: boolean | AddEventListenerOptions
): (() => any) | undefined {
    const targetDocument = document || self.document;
    if (targetDocument && typeof handler == 'function') {
        const cb = (evt: DocumentEventMap[K]) => {
            addEventTimeToSourceMapping(evt, source);
            handler(evt);
        };
        targetDocument.addEventListener(eventName, cb, options);

        return () => {
            targetDocument.removeEventListener(eventName, cb, options);
        };
    }

    return undefined;
}
