import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';

// Open popout if user is double clicking item or using the popout shortcut regardless of selection state
export default function shouldOpenPopout(selectionSource: MailListItemSelectionSource): boolean {
    return (
        selectionSource === MailListItemSelectionSource.MailListItemBodyDoubleClick ||
        selectionSource === MailListItemSelectionSource.MailListItemSimulatedDoubleClick ||
        selectionSource === MailListItemSelectionSource.KeyboardShiftEnter
    );
}
