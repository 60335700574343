import {
    getGlobalSettingsAccountMailboxInfo,
    onPreCoprincipalAccountRemoved,
} from 'owa-account-source-list-store';
import { isMultiAccountsCombinedFavoritesEnabled, removeFavoritesForAccount } from 'owa-favorites';

import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { orchestrator } from 'satcheljs';
import selectFolder from '../actions/selectFolder';

// subscribe to account removed action
orchestrator(onPreCoprincipalAccountRemoved, actionMessage => {
    if (isMultiAccountsCombinedFavoritesEnabled()) {
        removeFavoritesForAccount(actionMessage.removedAccount.mailboxInfo);
    }

    // If we are still selected on the removed mailbox, select the inbox of the primary account instead
    const currentMailbox = getModuleContextMailboxInfo();
    if (
        getIndexerValueForMailboxInfo(currentMailbox) ===
        getIndexerValueForMailboxInfo(actionMessage.removedAccount.mailboxInfo)
    ) {
        selectFolder(
            folderNameToId('inbox', getGlobalSettingsAccountMailboxInfo()),
            'primaryFolderTree' /* treeType */,
            'ResetInbox'
        );
    }
});
