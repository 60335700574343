// File for specifying the (limited) palette used by svg icons in each mode.
import { assertNever } from 'owa-assert';
import { getIsDarkTheme } from 'owa-fabric-theme';
import { getIsMsHighContrast } from 'owa-high-contrast';
type RibbonIconMode = any;
export type RibbonIconColor = any;

const lightModeRibbonIconPalette: Map<RibbonIconColor, string> = new Map([
    [0, '#A4262C'],
    [1, '#CA5010'],
    [2, '#AA8413'],
    [3, '#498205'],
    [4, '#0078D4'],
    [5, '#881798'],
    [6, '#616161'],
]);

const darkModeRibbonIconPalette: Map<RibbonIconColor, string> = new Map([
    [0, '#DC5F63'],
    [1, '#D67540'],
    [2, '#EAA300'],
    [3, '#6BA02B'],
    [4, '#3595DE'],
    [5, '#BB68C6'],
    [6, '#BDBDBD'],
]);

const getModeRibbonIconPalette = (mode: RibbonIconMode): Map<RibbonIconColor, string> => {
    switch (mode) {
        case 0:
            return lightModeRibbonIconPalette;

        case 1:
            return darkModeRibbonIconPalette;

        default:
            assertNever(mode); // Did we forget a new mode (or change the name)?
    }
};

export const getRibbonIconModeColor = (color: RibbonIconColor): string | undefined => {
    if (getIsMsHighContrast()) {
        // Let the defaults colors defined by 1JS+Fluent be used on High Contrast mode
        return undefined;
    }

    const mode = getIsDarkTheme() ? 1 : 0;

    return getModeRibbonIconPalette(mode).get(color);
};
