import { getSharePointResourceTokenPrefetchState } from './getSharePointResourceTokenPrefetchState';
import {
    type SharePointResourceTokenPrefetchStatus,
    type SharePointResourceTokenPrefetchError,
} from '../Schema/SharePointResourceTokenPrefetchState';
import { type GroupSiteStatus } from 'owa-groups-sharepoint-commands';
import { getGroupSiteStatus } from 'owa-groups-shared-store/lib/selectors/groupSiteStatusStoreSelectors';
import { isGroupDetailsValid, getGroupResourceUrl, getGroupDetails } from 'owa-groups-shared-store';
import type { IComputedValue } from 'mobx';
import {
    LogSpAbsent,
    LogSpLoadingStatus,
    LogSpFilesSuccess,
    LogSpFilesFailure,
    LogSpFilesTokenTransientFailure,
    LogGroupDetailsComplete,
    LogSpMissingSMTP,
    LogSpMissingGroup,
} from '../logging/logListLoadPerformanceAndReliability';
import type { MailboxInfo } from 'owa-client-types';
import { getGroupInformation } from 'owa-groups-shared-store/lib/selectors/getGroupInformation';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

export enum LoadingStage {
    AwaitingSiteUrl,
    AwaitingToken,
    AwaitingSiteProvisioning,
}

export enum ErrorReason {
    AdalException,
    GetGroupDetailsError,
    SiteNotFound,
    SiteNotProvisioned,
    SPItemsViewError,
}

export interface LoadingStateData {
    smtpAddress: string;
    isLoading: true;
    loadingStage: LoadingStage;
}

export interface ErrorStateData {
    smtpAddress: string;
    isError: true;
    listUrl: string;
    errorReason: ErrorReason;
}

export interface SPAbsentStateData {
    smtpAddress: string;
    spAbsent: true;
}

export interface StateData {
    smtpAddress: string;
    groupWebAbsoluteUrl: string;
    listUrl: string;
}

export type SharePointListState =
    | LoadingStateData
    | ErrorStateData
    | SPAbsentStateData
    | StateData
    | null;

export const isLoading = (state: SharePointListState): state is LoadingStateData => {
    return (state as LoadingStateData).isLoading === true;
};

export const isError = (state: SharePointListState): state is ErrorStateData => {
    return (state as ErrorStateData).isError === true;
};

export const isSpAbsent = (state: SharePointListState): state is SPAbsentStateData => {
    return (state as SPAbsentStateData).spAbsent === true;
};

export const getSpListViewStateData = (
    mailboxInfo: MailboxInfo,
    groupSmtpAddress?: string
): SharePointListState => {
    if (!groupSmtpAddress) {
        LogSpMissingSMTP();
        return null;
    }

    const group = getGroupInformation(mailboxInfo, transformGroupSmtp(groupSmtpAddress));
    if (!group) {
        LogSpMissingGroup();
        return null;
    }

    if (!isGroupDetailsValid(mailboxInfo, groupSmtpAddress)) {
        LogSpFilesFailure(ErrorReason.GetGroupDetailsError);
        return {
            smtpAddress: groupSmtpAddress,
            isError: true,
            listUrl: '', // For the GetGroupDetailsError scenario, we do not use the listUrl
            errorReason: ErrorReason.GetGroupDetailsError,
        };
    } else {
        LogGroupDetailsComplete(mailboxInfo, groupSmtpAddress);
    }

    const groupWebAbsoluteUrl = getGroupResourceUrl(mailboxInfo, groupSmtpAddress, 'Site');
    const listUrl = getGroupResourceUrl(mailboxInfo, groupSmtpAddress, 'Files');
    const groupDetails = getGroupDetails(mailboxInfo, groupSmtpAddress);
    if (groupDetails && (!groupWebAbsoluteUrl || !listUrl)) {
        LogSpAbsent();
        return {
            smtpAddress: groupSmtpAddress,
            spAbsent: true,
        };
    }

    if (!groupWebAbsoluteUrl || !listUrl) {
        LogSpLoadingStatus(LoadingStage.AwaitingSiteUrl);
        return {
            smtpAddress: groupSmtpAddress,
            isLoading: true,
            loadingStage: LoadingStage.AwaitingSiteUrl,
        };
    }

    const currentResourceTokenStatus = getSharePointResourceTokenPrefetchState(listUrl);

    // if the token is available, we go ahead and display the sharepoint component
    // if token status is SharePointResourceTokenStatus.Error, we will still go ahead and display the sharepoint component as the SP component will try to get the token itself and there are chances that the call might succeed
    // if we have already requested for the access token for sharepoint resource, we will wait for the call to complete before showing the SP component as we don't want the SP component to make another call but to await the existing call
    // if the status is not available, we will rely on filesNavigation to start the call to get the token rather than duplicating the logic here to start the call. - The reason for having the check for NoTAvailable here is when groupDetailsStore is changed, either this render or Navigate to files can be called first.
    // if the token is not available (error scenario), SP Items view will make the call to try to get access token.
    // we cannot update the status of the store in the callback(EnsureAuthToken) which sharepoint calls to get the token, as this view re-renders on the store change. so to avoid the loop, we will let the next call to fetchSharePointResourceToken to update the store
    // no extra server call will be made as the token will already be stored.
    if (currentResourceTokenStatus.status == 0 || currentResourceTokenStatus.status == 1) {
        LogSpLoadingStatus(LoadingStage.AwaitingToken);
        return {
            smtpAddress: groupSmtpAddress,
            isLoading: true,
            loadingStage: LoadingStage.AwaitingToken,
        };
    }

    if (currentResourceTokenStatus.status == 3 && currentResourceTokenStatus.error == 0) {
        LogSpFilesFailure(ErrorReason.AdalException);
        return {
            smtpAddress: groupSmtpAddress,
            isError: true,
            listUrl,
            errorReason: ErrorReason.AdalException,
        };
    }

    // if it's just an error while trying to get the token but we still want to go ahead and display the sharepoint component assuming that it's transient exception.
    // For our datapoint we will still log it as a transient failure.
    if (currentResourceTokenStatus.status == 3) {
        LogSpFilesTokenTransientFailure();
    }

    // if group site status is ready, we will go ahead and display the sharepoint component
    // if group site status is not found, we show the error message
    // if group site status is error, the site could still be provisioning: we show a warning message
    const groupSiteStatus = getGroupSiteStatus(mailboxInfo, groupSmtpAddress);
    switch (groupSiteStatus) {
        case 1:
            LogSpLoadingStatus(LoadingStage.AwaitingSiteProvisioning);
            return {
                smtpAddress: groupSmtpAddress,
                isLoading: true,
                loadingStage: LoadingStage.AwaitingSiteProvisioning,
            };
        case 2:
            LogSpFilesSuccess();
            return {
                smtpAddress: groupSmtpAddress,
                groupWebAbsoluteUrl,
                listUrl,
            };
        case 0:
            LogSpFilesFailure(ErrorReason.SiteNotFound);
            return {
                smtpAddress: groupSmtpAddress,
                isError: true,
                listUrl,
                errorReason: ErrorReason.SiteNotFound,
            };
        case 3:
        default:
            LogSpFilesFailure(ErrorReason.SiteNotProvisioned);
            return {
                smtpAddress: groupSmtpAddress,
                isError: true,
                listUrl,
                errorReason: ErrorReason.SiteNotProvisioned,
            };
    }
};

export type __unused_declarations = IComputedValue<never> | never;
