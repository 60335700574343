import and from './factory/and';
import type BaseFolderId from './contract/BaseFolderId';
import constant from './factory/constant';
import fieldURIOrConstantType from './factory/fieldURIOrConstantType';
import findFolders from './findFolders';
import isEqualTo from './factory/isEqualTo';
import propertyUri from './factory/propertyUri';
import restrictionType from './factory/restrictionType';
import type RequestOptions from './RequestOptions';
import type MailboxRequestOptions from './MailboxRequestOptions';

export default async function findFolder(
    parentFolder: BaseFolderId,
    displayName: string,
    requestOptions?: RequestOptions | MailboxRequestOptions
) {
    const folders = await findFolders(
        parentFolder,
        restrictionType({
            Item: and({
                Items: [
                    isEqualTo({
                        Item: propertyUri({ FieldURI: 'FolderDisplayName' }),
                        FieldURIOrConstant: fieldURIOrConstantType({
                            Item: constant({ Value: displayName }),
                        }),
                    }),
                ],
            }),
        }),
        requestOptions
    );

    return folders.length ? folders[0] : undefined;
}
