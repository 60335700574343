import { lazyStartSearchWithCategory } from 'owa-mail-search';
import type React from 'react';

export default async function onCategoryClicked(
    ev: React.MouseEvent<unknown>,
    category: string,
    actionSource: string
) {
    ev.stopPropagation();

    const startSearchWithCategory = await lazyStartSearchWithCategory.import();
    startSearchWithCategory(actionSource, category);
}
