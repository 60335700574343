import { getItem, itemExists, removeItem } from 'owa-local-storage';

const USER_RETENTION_EXP_TRIGGER = 'userretentiontrigger';

export default function isNewUserPostJit(): boolean {
    if (
        itemExists(window, USER_RETENTION_EXP_TRIGGER) &&
        getItem(window, USER_RETENTION_EXP_TRIGGER) === '1'
    ) {
        return true;
    }

    return false;
}

export function removeNewUserStorageIfExists() {
    if (isNewUserPostJit()) {
        removeItem(window, USER_RETENTION_EXP_TRIGGER);
    }
}
