import type TableView from '../store/schema/TableView';
import getComputedIsVirtualSelectAllExclusionListEmpty from './getComputedIsVirtualSelectAllExclusionListEmpty';
import { owaComputedFn } from 'owa-computed-fn';
import getSelectedTableView from './getSelectedTableView';

// Determines whether in virtual select all mode with an exclusion list.
export default function isInVirtualSelectionExclusionMode(tableView: TableView): boolean {
    const { isInVirtualSelectAllMode } = tableView;
    return isInVirtualSelectAllMode && !getComputedIsVirtualSelectAllExclusionListEmpty();
}

/**
 * Computed version of isInVirtualSelectionExclusionMode. Notably, this function
 * does not have a parameter and is assuming you want to use the selected table view.
 * This is important as computedFn caches based on parameters, and tableView changes enough
 * that we would otherwise get too many cache misses due to the tableView changing.
 */
export const getComputedIsInVirtualSelectionExclusionMode = owaComputedFn(
    function getComputedIsInVirtualSelectionExclusionMode(): boolean {
        const tableView = getSelectedTableView();
        if (!tableView) {
            return false;
        }
        return isInVirtualSelectionExclusionMode(tableView);
    }
);
