import type AttachmentPreviewWellView from './schema/AttachmentPreviewWellView';
import type ConversationItem from './schema/ConversationItem';
import type ListViewState from './schema/ListViewState';
import type ListViewStore from './schema/ListViewStore';
import type TableView from './schema/TableView';
import type TableViewConversationRelation from './schema/TableViewConversationRelation';
import type TableViewItemRelation from './schema/TableViewItemRelation';
import { ObservableMap } from 'mobx';
import type { AttachmentFullViewState } from 'owa-attachment-well-data';
import { createStore } from 'satcheljs';
import type { ClientItem } from 'owa-mail-store';
export { ObservableMap };
import type ActionButtonData from 'txp-data/lib/schema/viewSchema/ActionButtonData';
import type ReactionsGroupedByType from 'owa-service/lib/contract/ReactionsGroupedByType';

const defaultListViewStore: ListViewStore = {
    tableViews: new ObservableMap<string, TableView>({}),
    conversationItems: new ObservableMap<string, ConversationItem>({}),
    tableViewConversationRelations: new ObservableMap<string, TableViewConversationRelation>({}),
    tableViewItemRelations: new ObservableMap<string, TableViewItemRelation>({}),
    attachmentViewStates: new ObservableMap<string, AttachmentFullViewState>({}),
    rowAttachmentPreviewWellViews: new ObservableMap<string, AttachmentPreviewWellView>({}),
    meetingMessageItems: new ObservableMap<string, ClientItem>({}),
    txpActionButtonData: new ObservableMap<string, ActionButtonData>({}),
    listViewStateByModule: new ObservableMap<string, ListViewState>({}),
    reactionInformationByRowId: new ObservableMap<
        string,
        {
            reactions: ReactionsGroupedByType[];
            lastRequested: number;
        }
    >({}),
    shouldShowSelectedRowInRp: true,
};

const listViewStore = createStore<ListViewStore>('listview', defaultListViewStore)();
const getStore = () => listViewStore;
export default getStore;
