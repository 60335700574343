import type { AppPaneUnderlayViewState } from '../store/store';
import getStore from '../store/store';
import { action, mutator } from 'satcheljs';

const setAppPaneUnderlayVisibility = action(
    'setAppPaneUnderlayVisibility',
    (
        key: string,
        isShown: boolean,
        shrinkable?: boolean,
        isPlaceholder?: boolean,
        underlayViewState?: AppPaneUnderlayViewState
    ) => ({
        key,
        isShown,
        shrinkable,
        isPlaceholder,
        underlayViewState,
    })
);

mutator(setAppPaneUnderlayVisibility, actionMessage => {
    const store = actionMessage.underlayViewState || getStore();
    if (!actionMessage.isShown) {
        store.shrinkable.delete(actionMessage.key);
        store.isPlaceholder.delete(actionMessage.key);
    } else {
        store.shrinkable.set(actionMessage.key, actionMessage.shrinkable);
        store.isPlaceholder.set(actionMessage.key, actionMessage.isPlaceholder);
    }

    store.isShown.set(actionMessage.key, actionMessage.isShown);
});

export default setAppPaneUnderlayVisibility;
