import type TableView from '../store/schema/TableView';
import { type TableQueryType } from '../store/schema/TableQuery';
import type ViewFilter from 'owa-service/lib/contract/ViewFilter';
import type MailFolderTableQuery from '../store/schema/MailFolderTableQuery';

/**
 * @param tableView for which we want to get the view filter
 * @return Returns the view filter for the table view (null if not found)
 */
export default function getViewFilterForTable(tableView: TableView | undefined): ViewFilter | null {
    if (!tableView) {
        return null;
    }

    switch (tableView.tableQuery.type) {
        case 0:
        case 2:
        case 3:
        case 1:
            return (tableView.tableQuery as MailFolderTableQuery).viewFilter;
        default:
            return null;
    }
}
