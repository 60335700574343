import { lazyGetCategorizeSections } from 'owa-mail-ribbon-sections/lib/sections';

import type { MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onCategorizeMenuClicked = (
    keytipTabId: MailRibbonTabId | undefined,
    props: ReadOnlyRibbonControlDefProps
) => {
    lazyGetCategorizeSections.import().then(getCategorizeSections => {
        setMenuDefinition('CategorizeMenuDefinitionStore', props.projectionTabId, () => {
            return {
                sections: getCategorizeSections(keytipTabId, props),
            };
        });
    });
};
