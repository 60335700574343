import type { RibbonControlId } from 'owa-ribbon-ids';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';

export function modifyIdsForCompose(
    idList: RibbonControlId[],
    editorId?: string
): (number | string)[] {
    if (editorId === undefined) {
        return idList;
    }

    const returnIdList: (number | string)[] = [];

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    idList.forEach(id => {
        returnIdList.push(getComposeRibbonId(id, editorId));
    });
    return returnIdList;
}
