import AttachmentPreviewMethod from '../schema/AttachmentPreviewMethod';
import type AttachmentViewState from '../schema/AttachmentViewState';
import type ImageLoadState from '../schema/ImageLoadState';
import type { AttachmentFileType } from 'owa-attachment-file-types';
import type { ClientAttachmentId, ClientItemId } from 'owa-client-ids';

export default function createAttachmentViewState(
    attachmentId: ClientAttachmentId,
    isReadOnly: boolean,
    isCloudy: boolean,
    isPlaceholderAttachment: boolean,
    fileType: AttachmentFileType,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (16,5): Type 'null' is not assignable to type 'ClientItemId'.
    // @ts-expect-error
    parentItemId: ClientItemId = null
): AttachmentViewState {
    const viewState: AttachmentViewState = {
        attachmentId,
        thumbnailLoadState: 0,
        previewLoaded: false,
        isCloudy,
        isReadOnly,
        isPlaceholderAttachment,
        strategy: {
            allowEdit: false,
            previewMethod: AttachmentPreviewMethod.Unsupported,
            supportedOpenActions: [],
        },
        parentItemId,
        fileType,
    };

    return viewState;
}
