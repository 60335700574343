import { LazyAction, createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "SelectionPopup"*/ './lazyIndex')
);

export const SelectionPopup = createLazyComponent(lazyModule, m => m.SelectionPopup);

export const lazyOnSelectionMade = new LazyAction(lazyModule, m => m.onSelectionMade);
export const lazyDismissPopup = new LazyAction(lazyModule, m => m.dismissPopup);
