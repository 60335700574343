import React from 'react';
import { CommandBarButton } from '@fluentui/react/lib/Button';
import LineHorizontal3 from 'owa-fluent-icons-svg/lib/icons/LineHorizontal3Regular';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { observer } from 'owa-mobx-react';
import { getPalette } from 'owa-theme';
import { toggleButton, toggleButtonIcon } from './ExtraTopRibbonControlsBeforeTabs.scss';
import type { ToggleButtonProps } from './ToggleButtonProps';

const defaultIconProps = {
    iconName: LineHorizontal3,
};

export const V8ToggleButton = observer(({ label, onClick }: ToggleButtonProps) => {
    const { neutralDark } = getPalette();

    const styles = React.useMemo(
        () => ({
            root: toggleButton,
            icon: toggleButtonIcon,
            iconHovered: {
                color: neutralDark,
                '@media (forced-colors: active)': {
                    color: 'Highlight',
                },
            },
        }),
        [neutralDark]
    );

    return (
        <TooltipHost content={label} key="ToggleButton_ExtraTopRibbonControlsBeforeTabs">
            <CommandBarButton
                aria-label={label}
                iconProps={defaultIconProps}
                onClick={onClick}
                styles={styles}
            />
        </TooltipHost>
    );
}, 'V8ToggleButton');
