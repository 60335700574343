import { orchestrator } from 'satcheljs';
import { Module, onModuleClick } from 'owa-workloads';
import { setSelectedApp } from '../utils/AppBarApolloState';
import { getM365AppIdForModule } from '../utils/getM365AppIdForModule';

orchestrator(onModuleClick, actionMessage => {
    const { module: newModule, currentlySelectedModule: currentModule } = actionMessage;
    // Update the app bar's local state. The query uses this reactive variable to rerender
    if (newModule !== Module.AppHost && newModule !== currentModule) {
        setSelectedApp(getM365AppIdForModule(newModule));
    }
});
