import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import getCoprincipalAccountForIndexerValue from 'owa-account-source-list-store/lib/utils/getCoprincipalAccountForIndexerValue';
import { isEnterprise } from 'owa-account-source-list-internal';
import type { MailboxInfo } from 'owa-client-types';

export function getAnchorMailboxForSharedOrDelegateUserMailbox(mailboxInfo: MailboxInfo): string {
    const indexer = getIndexerValueForMailboxInfo(mailboxInfo);
    const accountSource = getCoprincipalAccountForIndexerValue(indexer);
    if (accountSource) {
        if (isEnterprise(accountSource)) {
            return mailboxInfo.mailboxSmtpAddress;
        }

        return `MSA:${mailboxInfo.mailboxSmtpAddress}`;
    }

    return mailboxInfo.mailboxSmtpAddress;
}
