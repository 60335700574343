import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import { type TableQueryType } from '../store/schema/TableQuery';
import type MailFolderTableQuery from '../store/schema/MailFolderTableQuery';
import type TableView from '../store/schema/TableView';

/**
 * Gets the focused view filter for the given table
 * @returns the focused view filter
 */
export default function getFocusedFilterForTable(
    tableView: TableView | undefined
): FocusedViewFilter {
    // In deeplink or filehub, the tableView may be empty.
    if (!tableView) {
        return -1;
    }

    switch (tableView.tableQuery.type) {
        case 0:
        case 2:
        case 3:
            // In case we are casting to a wrong type or to a non-set property, handle the case and return valid data
            // Main problem here is FocusedViewFilter.focused is 0 on the enum
            const focusedViewFilter = (tableView.tableQuery as MailFolderTableQuery)
                .focusedViewFilter;
            return focusedViewFilter === undefined || focusedViewFilter === null
                ? -1
                : focusedViewFilter;

        case 1:
            return -1;

        default:
            throw new Error('Table Query should be either of type search, mail folder or group');
    }
}
