import type { MailboxInfo } from 'owa-client-types';
import type EmailAddressWrapper from 'owa-service/lib/contract/EmailAddressWrapper';
import { isBusiness } from 'owa-session-store';
import isSenderSelf from 'owa-mail-store/lib/selectors/isSenderSelf';

export function hasEmailAddress(
    recipient: EmailAddressWrapper,
    _mailboxInfo: MailboxInfo
): boolean {
    return !!recipient.EmailAddress;
}

export function isNotGroupDL(recipient: EmailAddressWrapper, _mailboxInfo: MailboxInfo): boolean {
    return (
        !!recipient.MailboxType &&
        recipient.MailboxType != 'GroupMailbox' &&
        recipient.MailboxType != 'PrivateDL' &&
        recipient.MailboxType != 'PublicDL'
    );
}

export function isNotExternalUser(
    recipient: EmailAddressWrapper,
    mailboxInfo: MailboxInfo
): boolean {
    return !(
        isBusiness(mailboxInfo) &&
        (recipient.MailboxType == 'ExternalMailbox' || recipient.MailboxType == 'OneOff')
    );
}

export function isNotSenderSelf(recipient: EmailAddressWrapper, mailboxInfo: MailboxInfo): boolean {
    return !(
        !!recipient.EmailAddress &&
        isSenderSelf(recipient.EmailAddress.toLocaleLowerCase(), mailboxInfo)
    );
}

export function isNotNoReplyAccount(
    recipient: EmailAddressWrapper,
    _mailboxInfo: MailboxInfo
): boolean {
    return !(!!recipient.Name && recipient.Name.toLocaleLowerCase().includes('noreply') == true);
}
