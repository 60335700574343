import type { HotkeyCommand, Hotkeys } from '../types/HotkeyCommand';

export type HotkeyProcessor = (hotkey: Hotkeys) => Hotkeys;

function processHotkeys(
    keys: Hotkeys | undefined,
    processor: HotkeyProcessor
): Hotkeys | undefined {
    if (!keys) {
        return keys;
    } else {
        return processor(keys);
    }
}

function processCommand(command: HotkeyCommand, processors: HotkeyProcessor[]): HotkeyCommand;
function processCommand(command: Hotkeys, processors: HotkeyProcessor[]): Hotkeys;
function processCommand(
    command: HotkeyCommand | Hotkeys,
    processors: HotkeyProcessor[]
): HotkeyCommand | Hotkeys;
function processCommand(
    command: HotkeyCommand | Hotkeys,
    processors: HotkeyProcessor[]
): HotkeyCommand | Hotkeys {
    if (Array.isArray(command) || typeof command === 'string') {
        return processors.reduce((mappedCommand, processor) => processor(mappedCommand), command);
    } else {
        return {
            hotmail: processors.reduce(
                (mappedCommand, processor) => processHotkeys(mappedCommand, processor),
                command.hotmail
            ),
            owa: processors.reduce(
                (mappedCommand, processor) => processHotkeys(mappedCommand, processor),
                command.owa
            ),
            gmail: processors.reduce(
                (mappedCommand, processor) => processHotkeys(mappedCommand, processor),
                command.gmail
            ),
            yahoo: processors.reduce(
                (mappedCommand, processor) => processHotkeys(mappedCommand, processor),
                command.yahoo
            ),
            outlook: processors.reduce(
                (mappedCommand, processor) => processHotkeys(mappedCommand, processor),
                command.outlook
            ),
        };
    }
}

export default processCommand;
