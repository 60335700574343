import getStore from '../store/store';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

export default function isCopilotPrioritizeSettingsInitialized(mailboxInfo: MailboxInfo): boolean {
    const store = getStore().copilotPrioritizeSettingsStoreMap.get(
        getIndexerValueForMailboxInfo(mailboxInfo)
    );

    return store?.isInitialized ?? false;
}
