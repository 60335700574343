import type { FavoriteData } from 'owa-favorites-types';
import favoritesStore from '../../store/store';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import isMultiAccountsCombinedFavoritesEnabled from '../../utils/isMultiAccountsCombinedFavoritesEnabled';
import { addFavoriteToCache } from './offlineCacheHelpers';
import setFavoriteDataToStore from './setFavoriteDataInStore';

export default function addFavoriteDataToStore(favoriteData: FavoriteData, newIndex?: number) {
    const key = getIndexerValueForMailboxInfo(
        favoriteData?.mailboxInfo ?? getModuleContextMailboxInfo()
    );
    const orderedOutlookFavoritesIds = isMultiAccountsCombinedFavoritesEnabled()
        ? favoritesStore.orderedCombinedOutlookFavoritesIds
        : favoritesStore.favoriteTreeData.get(key)?.orderedOutlookFavoritesIds;

    // Check whether this favoriteId already exists in the list.
    // If so, do nothing as Prime returned an already existing favorite (Upsert).
    if (
        !orderedOutlookFavoritesIds ||
        orderedOutlookFavoritesIds.some(id => id === favoriteData.favoriteId)
    ) {
        return;
    }

    // Local update to add node to favorites store
    if (newIndex !== undefined) {
        orderedOutlookFavoritesIds.splice(newIndex, 0, favoriteData.favoriteId);
    } else {
        orderedOutlookFavoritesIds.push(favoriteData.favoriteId);
    }

    if (isMultiAccountsCombinedFavoritesEnabled()) {
        addFavoriteToCache(favoriteData, newIndex);
    }

    setFavoriteDataToStore(favoriteData);
}
