import { isReadingPanePositionRight } from '../selectors/readingPanePosition';
import { getListViewDimensions } from './getListViewDimensions';
import { MAX_LIST_VIEW_WIDTH_SLV_RP } from '../internalConstants';
import { getStore } from '../store/Store';

export function shouldShowSingleLineViewWithRightReadingPane(): boolean {
    return (
        getStore().useSingleLineMessageListWithRightReadingPane &&
        isReadingPanePositionRight() &&
        isWidthTooLargeForCompactView()
    );
}

function isWidthTooLargeForCompactView(): boolean {
    const listViewWidth = getListViewDimensions().listViewWidth;

    return listViewWidth > MAX_LIST_VIEW_WIDTH_SLV_RP;
}
