import { hasQueryStringParameter, getQueryStringParameter } from 'owa-querystring';

const MODE_PARAMETER_NAME: string = 'Mode';
const MODE_PARAMETER_VALUE: string = 'FreeUpMSQSpace';
export function getShouldOverrideToSortBySizeBasedOnURL(): boolean {
    if (hasQueryStringParameter(MODE_PARAMETER_NAME)) {
        const filterValue: string = getQueryStringParameter(MODE_PARAMETER_NAME);
        if (filterValue === MODE_PARAMETER_VALUE) {
            return true;
        }
    }
    return false;
}
