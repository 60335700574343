import type { ILatencyStore } from './utils/ILatencyStore';
import { LatencyStore } from './utils/LatencyStore';

let latencyStore: ILatencyStore;
export function getTokenLatencyStore(): ILatencyStore {
    if (!latencyStore) {
        latencyStore = new LatencyStore();
    }
    return latencyStore;
}
