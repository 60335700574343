import type { MailListRowDataType, TableView } from 'owa-mail-list-store';
import {
    MailRowDataPropertyGetter,
    isItemOfMessageType,
    canTableBeOutOfSyncWithServer,
    isConversationView,
} from 'owa-mail-list-store';
import { type TombstoneReasonType } from 'owa-mail-list-tombstone';
import type { ConversationType, ItemRow } from 'owa-graph-schema';
import type Message from 'owa-service/lib/contract/Message';
import arrayEquals from 'array-equal';

/**
 * Property sync map that stores the tombstone reason type to sync functions
 */
const clientToServerPropertySyncMap = new Map<
    TombstoneReasonType,
    (serverRow: MailListRowDataType, tableView: TableView) => boolean
>();

// Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
// -> Error TS7053 (29,1): Element implicitly has an 'any' type because expression of type 'TombstoneReasonType.Pin' can't be used to index type 'Map<TombstoneReasonType, (serverRow: MailListRowDataType, tableView: TableView) => boolean>'.
// @ts-expect-error
clientToServerPropertySyncMap[1] = syncClientPinPropertyToServerRow;
// Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
// -> Error TS7053 (33,1): Element implicitly has an 'any' type because expression of type 'TombstoneReasonType.Category' can't be used to index type 'Map<TombstoneReasonType, (serverRow: MailListRowDataType, tableView: TableView) => boolean>'.
// @ts-expect-error
clientToServerPropertySyncMap[0] = syncClientCategoryPropertyToServerRow;
// Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
// -> Error TS7053 (37,1): Element implicitly has an 'any' type because expression of type 'TombstoneReasonType.Read' can't be used to index type 'Map<TombstoneReasonType, (serverRow: MailListRowDataType, tableView: TableView) => boolean>'.
// @ts-expect-error
clientToServerPropertySyncMap[2] = syncClientReadPropertyToServerRow;

// No/Op for Meeting removes. This is just a way to signal the LV to not load info for same meeting events in the same conversation
// Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
// -> Error TS7053 (43,1): Element implicitly has an 'any' type because expression of type 'TombstoneReasonType.MeetingRemove' can't be used to index type 'Map<TombstoneReasonType, (serverRow: MailListRowDataType, tableView: TableView) => boolean>'.
// @ts-expect-error
clientToServerPropertySyncMap[4] = function () {};

/**
 * Syncs the client (local) PIN property by stamping it on the server row payload
 * @param serverRow row payload from server
 * @param tableView tableView to which the row belongs
 * @returns flag indicating whether the pin operation property value was already in sync
 */
function syncClientPinPropertyToServerRow(
    serverRow: MailListRowDataType,
    tableView: TableView
): boolean {
    let serverPinPropertyValue;
    const isConversationListViewType = isConversationView(tableView);

    if (isConversationListViewType) {
        serverPinPropertyValue = (serverRow as ConversationType).LastDeliveryOrRenewTime;
    } else {
        serverPinPropertyValue = (serverRow as ItemRow).ReceivedOrRenewTime;
    }

    const clientPinPropertyValue = MailRowDataPropertyGetter.getLastDeliveryOrRenewTimeStamp(
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2345 (68,9): Argument of type 'Maybe<string> | undefined' is not assignable to parameter of type 'string'.
        // @ts-expect-error
        serverRow.InstanceKey,
        tableView
    );

    const isPinPropertyInSync =
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2769 (76,18): No overload matches this call.
        // @ts-expect-error
        new Date(serverPinPropertyValue).toString() === new Date(clientPinPropertyValue).toString();

    if (!isPinPropertyInSync) {
        if (isConversationListViewType) {
            (serverRow as ConversationType).LastDeliveryOrRenewTime = clientPinPropertyValue;
        } else {
            (serverRow as ItemRow).ReceivedOrRenewTime = clientPinPropertyValue;
        }
    }

    return isPinPropertyInSync;
}

/**
 * Syncs the client (local) ReceivedTime property by stamping it on the server row payload
 * only for conversation type rows. This is used for a special scenario to sync the time property
 * for update notifications happening in out-of-sync tables
 * @param serverRow row payload from server
 * @param tableView tableView to which the row belongs
 */
function syncClientReceivedTimeToServerConversationRow(
    serverRow: MailListRowDataType,
    tableView: TableView
) {
    const conversationRow = serverRow as ConversationType;
    const serverReceivedTime = conversationRow.LastDeliveryTime;
    const clientReceivedTime = MailRowDataPropertyGetter.getLastDeliveryTimeStamp(
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2345 (106,9): Argument of type 'Maybe<string> | undefined' is not assignable to parameter of type 'string'.
        // @ts-expect-error
        serverRow.InstanceKey,
        tableView
    );

    const isReceivedTimeInSync =
        new Date(serverReceivedTime).toString() === new Date(clientReceivedTime).toString();

    if (!isReceivedTimeInSync) {
        conversationRow.LastDeliveryTime = clientReceivedTime;
    }
}

/**
 * Syncs the client (local) CATEGORY property by stamping it on the server row payload
 * @param serverRow row payload from server
 * @param tableView tableView to which the row belongs
 * @returns flag indicating whether the category property value was already in sync
 */
function syncClientCategoryPropertyToServerRow(
    serverRow: MailListRowDataType,
    tableView: TableView
): boolean {
    const clientCategories = MailRowDataPropertyGetter.getCategories(
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2345 (132,9): Argument of type 'Maybe<string> | undefined' is not assignable to parameter of type 'string'.
        // @ts-expect-error
        serverRow.InstanceKey,
        tableView
    );
    const clientCategoriesSorted = clientCategories ? clientCategories.slice().sort() : [];
    const serverCategoriesSorted = serverRow.Categories ? serverRow.Categories.slice().sort() : [];
    const isCategoryPropertyInSync = arrayEquals(clientCategoriesSorted, serverCategoriesSorted);

    if (!isCategoryPropertyInSync) {
        serverRow.Categories = clientCategories;
    }

    return isCategoryPropertyInSync;
}

/**
 * Syncs the client (local) READ property by stamping it on the server row payload
 * @param serverRow row payload from server
 * @param tableView tableView to which the row belongs
 * @returns flag indicating whether the read property value was already in sync
 */
function syncClientReadPropertyToServerRow(
    serverRow: MailListRowDataType,
    tableView: TableView
): boolean {
    let payloadRow;
    let payloadUnreadCount;
    let isReadPropertyInSync;

    const clientUnreadCount = MailRowDataPropertyGetter.getUnreadCount(
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2345 (164,9): Argument of type 'Maybe<string> | undefined' is not assignable to parameter of type 'string'.
        // @ts-expect-error
        serverRow.InstanceKey,
        tableView
    );

    const isConversationListViewType = isConversationView(tableView);

    if (isConversationListViewType) {
        payloadRow = serverRow as ConversationType;
        payloadUnreadCount = payloadRow.UnreadCount;
        isReadPropertyInSync = clientUnreadCount == payloadUnreadCount;
        if (!isReadPropertyInSync) {
            payloadRow.UnreadCount = clientUnreadCount;
        }

        /**
         * If this is a table that can be out of sync with the server
         * (currently only the unread filter table can be out of sync when server setting
         * for keep read item in unread view is enabled) we could get update notifications
         * for large conversations as unread count is gradually decreasing. In this case
         * only syncing the Read value is not enough as the row can move in the view
         * as the new local timestamp property will be that of the latest unread item in the conversation
         * which is changing with each notification. So we would also sync the time based properties
         * that matter for the sorting. For non date sorted tables, this is still required as other wise
         * the timestamps shall flip which also can cause flickers.
         */
        if (canTableBeOutOfSyncWithServer(tableView)) {
            syncClientReceivedTimeToServerConversationRow(serverRow, tableView);
            syncClientPinPropertyToServerRow(serverRow, tableView);
        }
    } else {
        // For non-message types we shall not sync anything
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2345 (198,33): Argument of type 'MailListRowDataType' is not assignable to parameter of type 'Item'.
        // @ts-expect-error
        if (isItemOfMessageType(serverRow)) {
            payloadRow = serverRow as Message;
            payloadUnreadCount = payloadRow.IsRead ? 0 : 1;
            isReadPropertyInSync = clientUnreadCount == payloadUnreadCount;

            if (!isReadPropertyInSync) {
                payloadRow.IsRead = clientUnreadCount == 0;
            }
        } else {
            isReadPropertyInSync = true;
        }
    }

    return isReadPropertyInSync;
}

/**
 * Syncs client triage property for the given triage operation in the server row payload
 * @param serverRow row payload from server
 * @param tableView tableView to which the row belongs
 * @param tombstoneReason type of tombstone reason used to determine which property to sync
 * @returns flag indicating whether the triage property for given tombstone reason is in sync
 */
export default function syncClientPropertyToServerRow(
    serverRow: MailListRowDataType,
    tableView: TableView,
    tombstoneReason: TombstoneReasonType
): boolean {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS7053 (229,44): Element implicitly has an 'any' type because expression of type 'TombstoneReasonType' can't be used to index type 'Map<TombstoneReasonType, (serverRow: MailListRowDataType, tableView: TableView) => boolean>'.
    // @ts-expect-error
    const syncFunctionForTombstoneReason = clientToServerPropertySyncMap[tombstoneReason];
    return syncFunctionForTombstoneReason(serverRow, tableView);
}
