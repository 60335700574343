import type {
    FavoriteData,
    FavoriteCategoryData,
    FavoriteFolderData,
    FavoritePersonaData,
    FavoriteGroupData,
    FavoriteSearchData,
    FavoritePublicFolderData,
} from 'owa-favorites-types';

export function getUniqueDataForFavoriteType(favoriteData: FavoriteData): string {
    let uniqueId;
    switch (favoriteData.type) {
        case 'folder':
            uniqueId = (favoriteData as FavoriteFolderData).folderId;
            break;
        case 'category':
            uniqueId = (favoriteData as FavoriteCategoryData).categoryId;
            break;
        case 'persona':
            uniqueId = (favoriteData as FavoritePersonaData).mainEmailAddress;
            break;
        case 'group':
            uniqueId = (favoriteData as FavoriteGroupData).groupSmtp;
            break;
        case 'search':
            uniqueId = (favoriteData as FavoriteSearchData).searchQuery;
            break;
        case 'publicFolder':
            uniqueId = (favoriteData as FavoritePublicFolderData).publicFolderId;
            break;
        case 'news':
            break; // News is not supported in combined favorites
        default:
            break; // Unknown favorite type, will be logged in loadOutlookFavorites
    }
    return uniqueId ?? '';
}
