import type WindowFeaturesOptions from './store/schema/WindowFeaturesOptions';
import type { vDirType } from 'owa-vdir-types';

export default {
    PopoutAddPopout: {
        name: 'PopoutAddPopout',
        // Only store first section of route since the other parts may contain id which is PII.
        customData: (
            _vdir: vDirType,
            route: string,
            _data?: object | (() => Promise<object>),
            _options?: Partial<WindowFeaturesOptions>,
            _skipOptinCheck?: boolean,
            actionSource?: string
        ) => [(route || '').split('/')[0], actionSource],
    },
};
