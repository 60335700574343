import { SelectionDirection, getListViewState } from 'owa-mail-list-store';

export function getNextRowIndexInternalHelper(
    selectedIndex: number,
    selectionDirection: SelectionDirection,
    tableRowKeys: string[]
): number {
    const listViewState = getListViewState();
    const start = listViewState.startIndexVLV; // Subtracting by 1 to account for the pre-rows component
    const end = listViewState.endIndexVLV; // Subtracting by 1 to account for the pre-rows component
    const numVisibleRows = end - start + 1; // Using the current number of visible rows in the list view as the page size

    // If the user hits the down arrow key, we want to select the next row unless selection is already on the last row in the list view.
    if (selectionDirection === SelectionDirection.Next) {
        if (selectedIndex < tableRowKeys.length - 1) {
            selectedIndex++;
        }
    } // If the user hits the up arrow key, we want to select the previous row unless selection is already on the first row in the list view.
    else if (selectionDirection === SelectionDirection.Previous) {
        if (selectedIndex > 0) {
            selectedIndex--;
        }
    } // If the user hits the PgDn key:
    else if (selectionDirection === SelectionDirection.NextPage) {
        // If the current selectedIndex is not the last visible index in the list view, select the last visible index in the list view.
        if (selectedIndex !== end) {
            selectedIndex = Math.min(end, tableRowKeys.length - 1);
        } // If the current selectedIndex is the last visible index in the list view, then load another page of rows below
        else {
            // If the new index to select is less than or equal to the index of the last row in the tableView, then select that newIndex. Else, select the last row in the tableView.
            if (selectedIndex + numVisibleRows <= tableRowKeys.length - 1) {
                selectedIndex += numVisibleRows;
            } else {
                selectedIndex = tableRowKeys.length - 1;
            }
        }
    } // If the user hits the PgUp key:
    else if (selectionDirection === SelectionDirection.PreviousPage) {
        // If the current selectedIndex is not the first visible index in the list view, select the first visible index in the list view.
        if (selectedIndex !== start) {
            selectedIndex = start;
        } // If the current selectedIndex is the first visible index in the list view, then load another page of rows above
        else {
            // If the new index to select is greater than or equal to 0 (first index in tableView), then select that new index. Else, set the new index to select to 0.
            if (selectedIndex - numVisibleRows >= 0) {
                selectedIndex -= numVisibleRows;
            } else {
                selectedIndex = 0;
            }
        }
    }
    return selectedIndex;
}
