import type {
    DatapointConfig,
    CoreDatapointConfig,
    CustomData,
    DatapointOptions,
    ActionSource,
    UnifiedEvent,
} from 'owa-analytics-types';
import { errorThatWillCauseAlert } from 'owa-trace';
import type { AnalyticsCoreEventNames } from 'owa-analytics-events';

interface BaseDatapointConfigWithFunctions<U extends any[]> {
    customData?: CustomData | ((...args: U) => CustomData | undefined);
    options?: DatapointOptions | ((...args: U) => DatapointOptions | undefined);
    cosmosOnlyData?: string | ((...args: U) => string | undefined);
    actionSource?: ActionSource | ((...args: U) => ActionSource | undefined);
    unifiedTelemetry?: UnifiedEvent | ((...args: U) => UnifiedEvent | undefined);
    skipAutoEndDatapoint?: boolean | ((...args: U) => boolean);
    disableIsCore?: boolean | ((...args: U) => boolean);
}

export interface DatapointConfigWithFunctions<U extends any[]>
    extends BaseDatapointConfigWithFunctions<U> {
    name: string | ((...args: U) => string);
}

export interface CoreDatapointConfigWithFunctions<U extends any[]>
    extends BaseDatapointConfigWithFunctions<U> {
    name: AnalyticsCoreEventNames | ((...args: U) => AnalyticsCoreEventNames);
}

export default function extractConfig<U extends any[]>(
    config: DatapointConfigWithFunctions<U> | CoreDatapointConfigWithFunctions<U>,
    middlewareParams: U
):
    | (DatapointConfig & {
          skipAutoEndDatapoint?: string;
      })
    | (CoreDatapointConfig & {
          skipAutoEndDatapoint?: string;
          disableIsCore?: boolean;
      }) {
    const name = extractPropertySafely(config, 'name', middlewareParams);
    return {
        name,
        customData: extractPropertySafely(config, 'customData', middlewareParams, name),
        options: extractPropertySafely(config, 'options', middlewareParams, name),
        cosmosOnlyData: extractPropertySafely(config, 'cosmosOnlyData', middlewareParams, name),
        actionSource: extractPropertySafely(config, 'actionSource', middlewareParams, name),
        unifiedTelemetry: extractPropertySafely(config, 'unifiedTelemetry', middlewareParams, name),
        skipAutoEndDatapoint: extractPropertySafely(
            config,
            'skipAutoEndDatapoint',
            middlewareParams,
            name
        ),
        disableIsCore: extractPropertySafely(config, 'disableIsCore', middlewareParams, name),
    };
}

function extractPropertySafely<U extends any[]>(
    config: DatapointConfigWithFunctions<U> | CoreDatapointConfigWithFunctions<U>,
    property: keyof DatapointConfigWithFunctions<U>,
    middlewareParams: U,
    name?: string
): any {
    if (config[property]) {
        try {
            const currentValue = config[property];
            return typeof currentValue === 'function'
                ? currentValue(...middlewareParams)
                : currentValue;
        } catch (e) {
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * The error name (message) must be a string literal (no variables in it).
             *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
            errorThatWillCauseAlert(`${name || 'unknown'} failed to extract ${property}`, e);
        }
    }
    return undefined;
}
