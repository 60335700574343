import { observer } from 'owa-mobx-react';
import { ContextualMenu } from '@fluentui/react';
import React from 'react';
import { createPortal } from 'owa-react-dom';
import { onNativeContextMenuDismiss } from '../utils/nativeContextMenuEventHandlers';
import getNativeContextMenuStateStore from '../store/store';

// Native Context Menu is only enabled on Monarch
function NativeContextMenu() {
    const store = getNativeContextMenuStateStore();

    return createPortal(
        <ContextualMenu
            items={store.contextualMenuItems}
            hidden={!store.showContextMenu}
            target={store.point}
            onDismiss={onNativeContextMenuDismiss}
            doNotLayer={true}
        />,
        document.body
    );
}

export default observer(NativeContextMenu, 'NativeContextMenu');
