import type ActivityFeedNotificationPayload from 'owa-service/lib/contract/ActivityFeedNotificationPayload';
import type { MailboxInfo } from 'owa-client-types';
import { action } from 'satcheljs';

export default action(
    'ACTIVITYFEED_NOTIFICATION',
    (notification: ActivityFeedNotificationPayload, mailboxInfo?: MailboxInfo) => ({
        notification,
        mailboxInfo,
    })
);
