import type {
    AddInExtensionControl,
    AppPermissionsNode,
    M365Acquisition,
    Maybe,
    RuntimeContextualLaunch,
} from 'owa-graph-schema';
import type { AcquisitionEntrypoint } from '../types';

/**
 * Verifies whether a M365 acquisition has add-in extension capabilities
 * @param acquisition M365Acquisition
 * @returns `true` if title has at least one `AddInExtension` element
 */
export function hasExtensionAddIn(acquisition: M365Acquisition): boolean {
    const addInExtensions = acquisition.titleDefinition?.elementDefinitions?.extensions ?? [];
    return addInExtensions.length > 0;
}

/**
 * @param acquisition M365Acquisition
 * @returns `true` if title has at least one `Extension` element with mail compose capabilities
 */
export function hasExtensionAddInComposeCommand(acquisition: M365Acquisition): boolean {
    return (
        hasExtensionAddIn(acquisition) && hasExtensionAddInCommand(acquisition, 'MessageCompose')
    );
}

/**
 * @param acquisition M365Acquisition
 * @returns true if acquisition has least one `Extension` element with mail reading pane capabilities
 */
export function hasExtensionAddInReadCommand(acquisition: M365Acquisition): boolean {
    return hasExtensionAddIn(acquisition) && hasExtensionAddInCommand(acquisition, 'MessageRead');
}

/**
 * @param acquisition M365Acquisition
 * @returns `true` if title has at least one `Extension` element with mail compose capabilities and is supported in shared folders
 */
export function hasExtensionAddInSharedFolderComposeCommand(acquisition: M365Acquisition): boolean {
    return (
        hasExtensionAddInComposeCommand(acquisition) && hasExtensionAddinSharedFolders(acquisition)
    );
}

/**
 * @param acquisition M365Acquisition
 * @returns true if acquisition has least one `Extension` element with mail reading pane capabilities  and is supported in shared folders
 */
export function hasExtensionAddInSharedFolderReadCommand(acquisition: M365Acquisition): boolean {
    return hasExtensionAddInReadCommand(acquisition) && hasExtensionAddinSharedFolders(acquisition);
}

/**
 * @param acquisition M365Acquisition
 * @returns `true` if title has at least one `Extension` element with calendar compose capabilities
 */
export function hasExtensionAddInCalendarComposeCommand(acquisition: M365Acquisition): boolean {
    return (
        hasExtensionAddIn(acquisition) && hasExtensionAddInCommand(acquisition, 'CalendarCompose')
    );
}

/**
 * @param acquisition M365Acquisition
 * @returns true if acquisition has has least one `Extension` element with calendar reading pane capabilities
 */
export function hasExtensionAddInCalendarReadCommand(acquisition: M365Acquisition): boolean {
    return hasExtensionAddIn(acquisition) && hasExtensionAddInCommand(acquisition, 'CalendarRead');
}

const ExtensionEntrypointTerm = {
    MessageRead: 'mailRead',
    MessageCompose: 'mailCompose',
    CalendarRead: 'meetingDetailsAttendee',
    CalendarCompose: 'meetingDetailsOrganizer',
} as const;

/**
 * @param a Array of T e.g, `[1, 2]`
 * @param b Array of T e.g, `[3, 4]`
 * @returns Flatten concatenation of `a` and `b`
 *
 * ```
 * >>> [[1, 2], [5], [3, 4]].reduce(flatten)
 * [1, 2, 5, 3, 4]
 * ```
 */
const flatten = <T>(a?: Maybe<T[]>, b?: Maybe<T[]>): T[] => (a ?? []).concat(b ?? []);

/**
 * We need to look at `extensions[i].ribbon[i].contexts[i]` to try to find `ReadMessage` or
 * `ComposeMessage` capabilities in an ExchangeAddIn.
 *
 * @param acquisition M365Acquisition
 * @param command MessageRead or MessageCompose
 * @returns Whether acquisition has at least one instance of `command` capability
 */
function hasExtensionAddInCommand(
    acquisition: M365Acquisition,
    entrypoint: Extract<AcquisitionEntrypoint, keyof typeof ExtensionEntrypointTerm>
): boolean {
    const extensions = acquisition.titleDefinition.elementDefinitions?.extensions;
    if (!extensions || extensions.length === 0) {
        return false;
    }

    const allRibbons = extensions.map(extension => extension.ribbons).reduce(flatten, []);
    if (!allRibbons || allRibbons?.length === 0) {
        return false;
    }

    const entrypointTerm = ExtensionEntrypointTerm[entrypoint];
    const allRibbonContexts = allRibbons.map(ribbon => ribbon?.contexts ?? []).reduce(flatten, []);

    return !!allRibbonContexts.find(context => context === entrypointTerm);
}

function hasControlSubmenu(control?: AddInExtensionControl | null): boolean {
    return (
        control?.type?.toLowerCase() === 'menu' ||
        control?.type?.toLowerCase() === 'menuitem' ||
        control?.type?.toLowerCase() === 'button'
    );
}

/**
 * @param acquisition `M365Acquisition`
 * @returns `true` if `acquisition` has at least one sub-command launch experience for `Extension` element
 */
export function hasExtensionAddInSubcommands(acquisition: M365Acquisition): boolean {
    return (
        hasExtensionAddIn(acquisition) &&
        !!acquisition.titleDefinition.elementDefinitions?.extensions?.some(extension => {
            return extension.ribbons?.some(ribbon =>
                ribbon?.tabs?.some(tab =>
                    tab?.groups?.some(group => group?.controls?.some(hasControlSubmenu))
                )
            );
        })
    );
}

function hasRuntimeActionTaskPane(action: RuntimeContextualLaunch | null): boolean {
    return action?.type.toLowerCase() === 'openpage';
}

/**
 * @param acquisition `M365Acquisition`
 * @returns `true` if `acquisition` has at least one side-pane launch experience for `Extension` element
 */
export function hasExtensionAddInTaskPane(acquisition: M365Acquisition): boolean {
    return (
        hasExtensionAddIn(acquisition) &&
        !!acquisition.titleDefinition.elementDefinitions?.extensions?.some(extension => {
            return extension.runtimes?.some(runtime =>
                runtime?.actions?.some(hasRuntimeActionTaskPane)
            );
        })
    );
}

/**
 * @param acquisition `M365Acquisition`
 * @returns `true` if `acquisition` has an extension element that supports shared folders
 */
function hasExtensionAddinSharedFolders(acquisition: M365Acquisition): boolean {
    if (!hasExtensionAddIn(acquisition)) {
        return false;
    }
    const resourceSpecificPermissions: Maybe<AppPermissionsNode>[] = acquisition.titleDefinition
        ?.authorization?.permissions?.resourceSpecific
        ? acquisition.titleDefinition?.authorization?.permissions?.resourceSpecific
        : [];
    for (let i = 0; i < resourceSpecificPermissions?.length; i++) {
        if (
            resourceSpecificPermissions[i]?.name == 'Mailbox.SharedFolder' &&
            resourceSpecificPermissions[i]?.type == 'Delegated'
        ) {
            return true;
        }
    }
    return false;
}
