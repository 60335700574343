import { mutatorAction } from 'satcheljs';
import type ClientItem from 'owa-mail-store/lib/store/schema/ClientItem';
import { dispatchReadingPaneCurrentItemToNotesSDK } from '../actions/dispatchReadingPaneCurrentItemToNotesSDK';
import getStore from '../store/store';

export const dispatchReadingPaneCurrentItem = mutatorAction(
    'DISPATCH_READINGPANE_CURRENT_ITEM',
    (item: ClientItem) => {
        if (getStore().isPanelOpen) {
            dispatchReadingPaneCurrentItemToNotesSDK(item);
        }
    }
);
