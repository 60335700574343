import type Item from 'owa-service/lib/contract/Item';

export function isMeetingMessage(item?: Item) {
    if (!item) {
        return false;
    }
    return (
        item.ItemClass &&
        item.ItemClass.match(/^ipm\.(appointment|schedule\.meeting(?!\.notification))/i)
    );
}
