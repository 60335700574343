import React from 'react';
import {
    useId,
    createCSSRuleFromTheme,
    fluentProviderClassNames,
    useIsomorphicLayoutEffect,
    type FluentProviderState,
} from '@fluentui/react-components';
import { trace } from 'owa-trace';

const createStyleTag = (
    target: Document | undefined,
    elementAttributes: Record<string, string>
) => {
    if (!target) {
        return undefined;
    }

    const tag = target.createElement('style');

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    Object.keys(elementAttributes).forEach(attrName => {
        tag.setAttribute(attrName, elementAttributes[attrName]);
    });

    target.head.appendChild(tag);
    return tag;
};

const insertSheet = (tag: HTMLStyleElement, rule: string) => {
    const sheet = tag.sheet;

    if (sheet) {
        if (sheet.cssRules.length > 0) {
            sheet.deleteRule(0);
        }
        sheet.insertRule(rule, 0);
    } else {
        trace.warn(
            'FluentProvider: No sheet available on styleTag, styles will not be inserted into DOM.'
        );
    }
};

const shouldInsertStyleTag = (
    currentStyleTag: HTMLStyleElement | undefined | null,
    targetDocument: Document | undefined
) => {
    if (currentStyleTag || !targetDocument?.documentElement) {
        return true;
    }

    // Check if there are any style tags with the fluent provider class name
    // If there are none, that means we are the first provider and should insert the style tag
    const fluentProviderStyleTag = targetDocument.head.querySelector(
        `style[id^="${fluentProviderClassNames.root}"]`
    );
    if (!fluentProviderStyleTag) {
        return true;
    }

    // Check if the fluent provider class name is not present on the document element
    return !Array.from(targetDocument.documentElement.classList).some(className =>
        className.startsWith(fluentProviderClassNames.root)
    );
};

/**
 * Writes a theme as css variables in a style tag on the provided targetDocument as a rule applied to a CSS class
 * @internal
 * @returns CSS class to apply the rule
 */
export const useHeadlessFluentProviderThemeStyleTag = (
    options: Pick<FluentProviderState, 'theme' | 'targetDocument'> & {
        rendererAttributes: Record<string, string>;
    }
) => {
    const { targetDocument, theme, rendererAttributes } = options;

    const styleTag = React.useRef<HTMLStyleElement | undefined | null>();
    const styleTagId = useId(fluentProviderClassNames.root);
    const styleElementAttributes = rendererAttributes;
    const insertStyleTag = shouldInsertStyleTag(styleTag.current, targetDocument);

    const rule = React.useMemo(
        () => (insertStyleTag ? createCSSRuleFromTheme(`.${styleTagId}`, theme) : ''),
        [theme, styleTagId, insertStyleTag]
    );

    React.useMemo(() => {
        // Heads up!
        // .useMemo() is used because it is called during render and DOM for _current_ component is not mounted yet. Also,
        // this allows to do checks with strict mode enabled as .useEffect() will be called with incremented IDs because
        // of double render.

        if (targetDocument) {
            const providerSelector = `.${fluentProviderClassNames.root}.${styleTagId}`;
            const providerElements = targetDocument.querySelectorAll(providerSelector);

            // In SSR, we will have DOM upfront. To avoid false positives the check on nested style tag is performed
            const isSSR =
                targetDocument.querySelector(`${providerSelector} > style[id="${styleTagId}"]`) !==
                null;
            const elementsCount = isSSR ? 1 : 0;

            if (providerElements.length > elementsCount) {
                trace.warn(
                    [
                        '@fluentui/react-provider: There are conflicting ids in your DOM.',
                        'Please make sure that you configured your application properly.',
                        '\n',
                        '\n',
                        'Configuration guide: https://aka.ms/fluentui-conflicting-ids',
                    ].join(' ')
                );
            }
        }
    }, []);

    useHandleSSRStyleElements(targetDocument, styleTagId);
    useIsomorphicLayoutEffect(() => {
        if (insertStyleTag) {
            // The style element could already have been created during SSR - no need to recreate it
            const ssrStyleElement = targetDocument?.getElementById(styleTagId);
            if (ssrStyleElement) {
                styleTag.current = ssrStyleElement as HTMLStyleElement;
            } else {
                styleTag.current = createStyleTag(targetDocument, {
                    ...styleElementAttributes,
                    id: styleTagId,
                });
                if (styleTag.current) {
                    insertSheet(styleTag.current, rule);
                }
            }
        }

        return () => {
            styleTag.current?.remove();
        };
    }, [styleTagId, targetDocument, rule, styleElementAttributes, insertStyleTag]);

    return { styleTagId, rule, insertStyleTag };
};

function useHandleSSRStyleElements(
    targetDocument: Document | undefined | null,
    styleTagId: string
) {
    // Using a state factory so that this logic only runs once per render
    // Each FluentProvider can create its own style element during SSR as a slot
    // Moves all theme style elements to document head during render to avoid hydration errors.
    // Should be strict mode safe since the logic is idempotent.
    React.useState(() => {
        if (!targetDocument) {
            return;
        }

        const themeStyleElement = targetDocument.getElementById(styleTagId);
        if (themeStyleElement) {
            targetDocument.head.append(themeStyleElement);
        }
    });
}
