import type { ShellControl } from '@1js/suiteux-shell-react';
import { isGulping, isGulpOrBranching } from 'owa-config';
import React from 'react';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Avoid deprecated APIs, use portals instead of rendering to a new root
 *	> 'render' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root */
import { render } from 'owa-react-dom';
import { devSourceLabel } from './OwaSuiteHeader.scss';

// No need to localize this string as it is a DEV feature only
const gulpingText = 'gulping...';

// No need to localize this string as it is a DEV feature only
const branchingText = 'branching...';

// Render the gulping / branching label
function renderDevSource(container: HTMLDivElement) {
    if (isGulpOrBranching()) {
        render(
            <label className={devSourceLabel}>{isGulping() ? gulpingText : branchingText}</label>,
            container
        );
    }
}

// Define the shell control that will display the dev source
export const OwaDevSourceLabel = {
    id: 'owaDevSourceLabel',
    flex: '0 1 auto',
    render: renderDevSource,
} as ShellControl;
