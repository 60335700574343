import { cancelActiveRowAnimations } from './cancelActiveRowAnimations';
import { mailListItemAnimationStore } from '../store/Store';

export const clearAnimationStore = () => {
    if (mailListItemAnimationStore.activeAnimations.length > 0) {
        const activeAnimations = mailListItemAnimationStore.activeAnimations;

        for (const activeAnimation of activeAnimations) {
            cancelActiveRowAnimations(activeAnimation);
        }

        for (const activeAnimation of activeAnimations) {
            activeAnimation.animationCallback?.();
        }

        mailListItemAnimationStore.activeAnimations = [];
    }

    if (mailListItemAnimationStore.pendingAnimations.length > 0) {
        const pendingAnimations = mailListItemAnimationStore.pendingAnimations;

        for (const pendingAnimation of pendingAnimations) {
            pendingAnimation.animationCallback?.();
        }

        mailListItemAnimationStore.pendingAnimations = [];
    }
};
