import favoritesStore from '../store/store';
import { mutatorAction } from 'satcheljs';
import { FolderTreeLoadStateEnum } from 'owa-folders';
import { isFeatureEnabled } from 'owa-feature-flags';
import { setLoadingStateForFavorites } from './setLoadingStateForFavorites';

export const setFavoritesLoaded = (accountKey: string) => {
    if (isFeatureEnabled('tri-favorites-roaming')) {
        setLoadingStateForFavorites(accountKey, FolderTreeLoadStateEnum.Loaded);
    } else {
        setFavoritesLoadedV1();
    }
};

const setFavoritesLoadedV1 = mutatorAction('setFavoritesLoadedV1', () => {
    // Favorites V1 state
    favoritesStore.favoritesLoaded = true;
});
