export type FluidLoadSource = 'Fluid';

export enum FluidLoadOwaScenario {
    ReadingPane,
    ComposeWithNewComponent,
    ComposeWithExistingComponent,
}

export type FluidOwaSource =
    | 'MailReadingPane'
    | 'NewsletterPreview'
    | 'MailCompose'
    | 'NewsletterCompose'
    | 'CalendarReadingPane'
    | 'CalendarCompose'
    | 'CalendarEventBody'
    | 'MailCalendarCard'
    | 'ForwardEventEditor'
    | 'MOS'
    | 'None'; // Used for scenarios like clearing cache, which happens outside of a specific scenario
