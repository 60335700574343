import { addClass, removeClass } from 'owa-griffel-utils';
import { shadowBoxFadeIn, shadowBoxFadeOut, shadowBoxSidePanelFadeIn } from './ShadowBox.scss';

export function fadeOutShadowBox(shadowBox: HTMLElement) {
    removeClass(shadowBox, shadowBoxFadeIn);
    addClass(shadowBox, shadowBoxFadeOut);
}

export function fadeInShadowBox(shadowBox: HTMLElement) {
    removeClass(shadowBox, shadowBoxFadeOut);
    addClass(shadowBox, shadowBoxFadeIn);
}

export function fadeOutSidePanelShadowBox(shadowBox: HTMLElement) {
    removeClass(shadowBox, shadowBoxSidePanelFadeIn);
    addClass(shadowBox, shadowBoxFadeOut);
}

export function fadeInSidePanelShadowBox(shadowBox: HTMLElement) {
    removeClass(shadowBox, shadowBoxFadeOut);
    addClass(shadowBox, shadowBoxSidePanelFadeIn);
}
