import type { KeyboardKeys } from '../types/HotkeyCommand';

const _MAP: {
    [code: number]: KeyboardKeys;
} = {
    8: 'backspace',
    9: 'tab',
    13: 'enter',
    // 16: 'shift',
    // 17: 'ctrl',
    // 18: 'alt',
    // 20: 'capslock',
    27: 'esc',
    32: 'space',
    33: 'pageup',
    34: 'pagedown',
    35: 'end',
    36: 'home',
    37: 'left',
    38: 'up',
    39: 'right',
    40: 'down',
    45: 'ins',
    46: 'del',
    91: 'meta',
    92: 'meta',
};

const _KEYCODE_MAP: {
    [code: number]: KeyboardKeys;
} = {
    // 106: '*',
    107: '+',
    109: '-',
    110: '.',
    111: '/',
    186: ';',
    187: '=',
    188: ',',
    189: '-',
    190: '.',
    191: '/',
    // 192: '`',
    219: '[',
    // 220: '\\',
    221: ']',
    // 222: '\''
};

// f numbers
for (let i = 1; i < 20; ++i) {
    _MAP[111 + i] = ('f' + i) as KeyboardKeys;
}

/**
 * loop through to map numbers on the numeric keypad
 */
for (let i = 0; i <= 9; ++i) {
    _KEYCODE_MAP[i + 96] = i.toString() as KeyboardKeys;
}

export const REVERSE_MAP: {
    [P in KeyboardKeys]?: 1;
} = {};
for (const k of Object.keys(_MAP)) {
    REVERSE_MAP[_MAP[k as unknown as number] as KeyboardKeys] = 1;
}

export function getCharacterFromEvent(ev: KeyboardEvent): {
    baseChar: string;
    char: string;
} {
    const keyCode = typeof ev.which != 'number' ? ev.keyCode : ev.which;
    if (ev.type == 'keypress') {
        const char = String.fromCharCode(keyCode);
        const baseChar = ev.shiftKey ? char : char.toLowerCase();
        return { baseChar, char: baseChar };
    }

    const baseChar =
        _MAP[keyCode] || _KEYCODE_MAP[keyCode] || String.fromCharCode(keyCode).toLowerCase();
    let char = baseChar;

    // order matters for the shift, alt and control keys
    if (ev.shiftKey) {
        char = 'shift+' + char;
    }
    if (ev.altKey) {
        char = 'alt+' + char;
    }
    if (ev.ctrlKey || ev.metaKey) {
        char = 'ctrl+' + char;
    }

    return { char, baseChar };
}
