import { getBposNavBarData } from 'owa-bpos-store';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Checks to see if the user has the flag in the activeExperiences
 */
export default function doesActiveExperiencesInclude(flag: string, mailboxInfo?: MailboxInfo) {
    const bposData = getBposNavBarData(mailboxInfo);
    if (bposData?.ClientData) {
        const clientData = JSON.parse(bposData.ClientData);
        return (
            !!clientData?.ActiveExperiences &&
            clientData?.ActiveExperiences.split(',').includes(flag)
        );
    }

    return false;
}
