import { action } from 'satcheljs';

import type { OwaNovaActionUnion } from '../types';

export const onSettingsContextMenuTrigger = action('SettingsContextMenuTrigger', event => ({
    owaType: 'settings',
    event: {
        ...event,
        data: () => {
            const serializedData = event.data?.();
            return serializedData
                ? JSON.parse(serializedData as unknown as string)
                : serializedData;
        },
    },
})) as OwaNovaActionUnion<'settings'>;
