import { createStore } from 'satcheljs';
import type CurrentGroupInformation from './schema/CurrentGroupInformation';

const initialState: CurrentGroupInformation = {
    // TODO: 154319 - add current mailboxInfo
    smtpAddress: '',
    groupId: '',
    tenantId: '',
};

const store = createStore('currentGroupInformationStore', initialState)();
export const getCurrentGroupInformationStore = () => store;
