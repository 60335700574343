import type { BrandVariants } from '@fluentui/react-components';
import type { ThemeResources } from 'owa-theme-shared';
import getResourcesForTheme from './getResourcesForTheme';
import { getIsDarkThemeFromParameter } from '../utils/optionalArgumentHelpers';
import { owaComputedFn } from 'owa-computed-fn';

// Note: the getResourcesForTheme -> fetchThemeResource codepath,
// responsible for providing the `resources` parameter to this method,
// isn't correctly memoizing so this is getting computed more than
// necessary, but still providing some benefit.
const createBrandVariants = owaComputedFn(
    (resources: ThemeResources): BrandVariants | undefined => {
        return resources.brandVariants ? { ...resources.brandVariants } : undefined;
    }
);

/**
 * Returns the brand ramp color values for various theme symbols.
 * @param themeId Theme if to get values for. If not provided, defaults to the current theme.
 */
export default function getBrandVariants(
    themeId?: string,
    isDarkTheme?: boolean
): BrandVariants | undefined {
    isDarkTheme = getIsDarkThemeFromParameter(isDarkTheme);
    const resources: ThemeResources | undefined = getResourcesForTheme(themeId, isDarkTheme);

    if (!resources) {
        return undefined;
    }

    return createBrandVariants(resources);
}
