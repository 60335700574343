import type { MutationParameters, GraphQLTaggedNode, PayloadError } from 'relay-runtime';
import type { NovaGraphQL } from '@nova/types';

/* eslint-disable @typescript-eslint/no-restricted-imports --
 * Relay hook imports are allowed only in Nova GraphQL integration
 *	> 'useMutation', 'useFragment', 'useLazyLoadQuery', 'useRefetchableFragment', 'usePaginationFragment' imports are restricted from being used. */
import { useMutation as useMutationRelay } from 'react-relay';
/* eslint-enable @typescript-eslint/no-restricted-imports  */

type NovaMutation<T> = Required<NovaGraphQL<T>>['useMutation'];
type OwaUseMutationConfig<T> = Parameters<ReturnType<NovaMutation<T>>[0]>[0];

export function useMutation<TMutation extends MutationParameters>(
    mutation: GraphQLTaggedNode
): [
    (config: OwaUseMutationConfig<TMutation>) => Promise<{
        data?: TMutation['response'];
        errors?: PayloadError[];
    }>,
    boolean
] {
    const [commitMutation, isInFlight] = useMutationRelay(mutation);

    const comFn = (
        config: OwaUseMutationConfig<TMutation>
    ): Promise<{
        data?: TMutation['response'];
        errors?: PayloadError[];
    }> => {
        const relayCompatibleOptimisticResponse =
            typeof config.optimisticResponse === 'object'
                ? config.optimisticResponse ?? undefined
                : undefined;

        return new Promise(resolve => {
            commitMutation({
                variables: config.variables,
                optimisticResponse: relayCompatibleOptimisticResponse,
                onCompleted: data => {
                    config.onCompleted?.(data);
                    resolve({ data });
                },
            });
        });
    };

    return [comFn, isInFlight];
}
