import { createStore } from 'satcheljs';
import type { OwaTeamsPoliciesStore } from './schema';
import { FetchState } from './schema';

const owaTeamsPoliciesStore: OwaTeamsPoliciesStore = {
    policies: null,
    fetchState: FetchState.NotStarted,
};

const store = createStore<OwaTeamsPoliciesStore>('OwaTeamsPoliciesStore', owaTeamsPoliciesStore)();
export const getStore = () => store;
