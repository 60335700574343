import { dedupeArrayValues } from 'owa-selected-calendars-utils';
import { trace } from 'owa-trace';
import type { MailboxInfo } from 'owa-client-types';
import { updateAccountScopeUserSettingsAndService } from 'owa-userconfiguration/lib/utils/updateAccountScopeUserSettingsAndService';

export function updateSelectedCalendarsState(
    selectedCalendars: string[],
    mailboxInfo: MailboxInfo
) {
    if (selectedCalendars) {
        updateAccountScopeUserSettingsAndService(mailboxInfo, {
            ViewStateConfiguration: {
                SelectedCalendarsDesktop: dedupeArrayValues(selectedCalendars),
            },
        }).catch(() => {
            trace.warn('Could not save new selected calendars list to user configuration');
        });
    }
}
