import { getAccountScopeUserSettings } from 'owa-session-store';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { getUserPermissionForFolderId, doesUserHaveActionPermission } from '../index';
import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import { getMailboxInfoFromFolderId } from 'owa-mail-mailboxinfo';

/**
 * This method restricts the movement of the items by drag and drop if user doesn't have enough permission for shared folder
 * @param sourceFolderId - source folder id
 * @param destinationFolderId - destination folder id
 */
export default function isSharedFolderItemMovementPermissible(
    sourceFolderId: string,
    destinationFolderId: string
) {
    const userEmail = getAccountScopeUserSettings(getMailboxInfoFromFolderId(sourceFolderId))
        .SessionSettings?.UserEmailAddress;

    if (!userEmail) {
        return false;
    }

    const assignedSourceFolderPermission = getUserPermissionForFolderId(sourceFolderId, userEmail);
    const assignedDestinationFolderPermission = getUserPermissionForFolderId(
        destinationFolderId,
        userEmail
    );

    /**
     *  Check write permission for both source and destination folder
     */
    const doesUserHavePermission =
        doesUserHaveActionPermission(29, assignedSourceFolderPermission) &&
        doesUserHaveActionPermission(29, assignedDestinationFolderPermission);
    return doesUserHavePermission;
}
