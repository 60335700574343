import { getOpxHostApp } from 'owa-config';

import { type RibbonControlId } from 'owa-ribbon-ids';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';

export function shouldHideRibbonControlBasedOnHostApp(controlId: RibbonControlId): boolean {
    const hostApp = getOpxHostApp() || 'default';
    return flags[controlId]?.[hostApp];
}

type HOST_APP_FLAG = {
    [key: string]: boolean;
};

const flags: {
    [key: number]: HOST_APP_FLAG;
} = {
    [620]: {
        sharepoint: true,
    },
    [506]: {
        sharepoint: true,
    },
    [7015]: {
        sharepoint: true,
    },
    [7012]: {
        sharepoint: true,
    },
    [7011]: {
        sharepoint: true,
    },
    [7000]: {
        sharepoint: true,
    },
    [529]: {
        sharepoint: true,
    },
    [7022]: {
        sharepoint: true,
    },
    [7016]: {
        sharepoint: true,
    },
    [7010]: {
        sharepoint: true,
    },
    [7021]: {
        sharepoint: true,
    },
    [7014]: {
        sharepoint: true,
    },
    [560]: {
        sharepoint: true,
    },
    [7035]: {
        sharepoint: true,
    },
    [7013]: {
        sharepoint: true,
    },
    [7005]: {
        sharepoint: true,
    },
    [7006]: {
        sharepoint: true,
    },
    [6085]: {
        sharepoint: true,
    },
    [6079]: {
        sharepoint: true,
    },
    [6084]: {
        sharepoint: true,
    },
    [6083]: {
        sharepoint: true,
    },
    [6080]: {
        sharepoint: true,
    },
};
