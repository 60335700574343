import getStore from '../store/store';
import updateModule from '../actions/updateModule';
import { mutator } from 'satcheljs';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(updateModule, actionMessage => {
    const newModule = actionMessage.newModule;
    const store = getStore();
    if (store.module != newModule) {
        store.module = newModule;
    }
});

export default updateModule;
