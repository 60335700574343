import numberToByteQuantifiedStringConverter from 'owa-file/lib/utils/numberToByteQuantifiedStringConverter';
import { isAttachmentsSearchFolder } from 'owa-session-store/lib/selectors/isAttachmentsSearchFolder';
import loc, { format } from 'owa-localize';
import {
    noItemsSelected,
    oneItemSelected,
    someItemsSelected,
} from 'owa-locstrings/lib/strings/someitemsselected.locstring.json';
import { getItemToShowFromNodeId } from 'owa-mail-store/lib/utils/conversationsUtils';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import getListViewState from '../selectors/getListViewState';
import getListViewStore from '../store/Store';
import getFocusedFilterForTable from '../utils/getFocusedFilterForTable';
import getIsEverythingSelectedInTable from './getIsEverythingSelectedInTable';
import {
    noConversationsSelected,
    oneConversationSelected,
    oneConversationSelectedWithSize,
    oneItemSelectedWithSize,
    someConversationsSelected,
    someConversationsSelectedWithSize,
    someItemsSelectedWithSize,
} from './getItemsOrConversationsSelectedText.locstring.json';
import isItemPartOperation from './isItemPartOperation';
import { getSize } from './mailRowDataPropertyGetter';

import isInVirtualSelectionExclusionMode from '../utils/isInVirtualSelectionExclusionMode';
import getRowSelectionStringForSelectAll from '../utils/getRowSelectionStringForSelectAll';

export default function getItemsOrConversationsSelectedText(tableViewId: string): string {
    const listViewStore = getListViewStore();
    const tableView = listViewStore.tableViews.get(tableViewId);
    const focusedFilter = getFocusedFilterForTable(tableView);

    if (!tableView) {
        return '';
    }

    const { tableQuery, selectedRowKeys } = tableView;

    const isEverythingSelectedInTable = getIsEverythingSelectedInTable(tableView);
    // User is in select all mode or in virtual select mode with an exclusion list
    if (isEverythingSelectedInTable || isInVirtualSelectionExclusionMode(tableView)) {
        return getRowSelectionStringForSelectAll(
            isEverythingSelectedInTable,
            tableView,
            focusedFilter
        );
    }

    // User has selected a specific number of rows or row parts
    const numSelections = isItemPartOperation()
        ? getListViewState().expandedConversationViewState.selectedNodeIds.length
        : selectedRowKeys.size;

    let totalMailSize: number = 0;
    let sizeString: string = '';
    const shouldDisplaySize: boolean = isAttachmentsSearchFolder(tableQuery.folderId);
    if (shouldDisplaySize) {
        if (isItemPartOperation()) {
            for (const nodeId of getListViewState().expandedConversationViewState.selectedNodeIds) {
                totalMailSize += getItemToShowFromNodeId(nodeId)?.Size || 0;
            }
        } else {
            for (const rowKey of selectedRowKeys.keys()) {
                totalMailSize += getSize(rowKey, tableView);
            }
        }

        sizeString = numberToByteQuantifiedStringConverter(totalMailSize);
    }

    if (tableQuery.listViewType == 1 || isItemPartOperation()) {
        // Designate string for rows
        if (numSelections === 0) {
            return loc(noItemsSelected);
        } else if (numSelections === 1) {
            return shouldDisplaySize
                ? format(loc(oneItemSelectedWithSize), sizeString)
                : loc(oneItemSelected);
        }
        return shouldDisplaySize
            ? format(loc(someItemsSelectedWithSize), numSelections, sizeString)
            : format(loc(someItemsSelected), numSelections);
    }
    // Designate string for conversations

    if (numSelections === 0) {
        return loc(noConversationsSelected);
    } else if (numSelections === 1) {
        return shouldDisplaySize
            ? format(loc(oneConversationSelectedWithSize), sizeString)
            : loc(oneConversationSelected);
    }
    return shouldDisplaySize
        ? format(loc(someConversationsSelectedWithSize), numSelections, sizeString)
        : format(loc(someConversationsSelected), numSelections);
}
