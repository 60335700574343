import { isReadingPanePositionOff } from 'owa-mail-layout/lib/selectors/readingPanePosition';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import SelectionDirection from 'owa-mail-list-store/lib/store/schema/SelectionDirection';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';

// Considered a non-core select mail item if:
// - Opening a popout (since this will drag down perf scenarios)
// - In SLV and will not load the RP
// - Selecting the same item in 3 col
export default function isSelectMailItemCore(
    mailListItemSelectionSource: MailListItemSelectionSource,
    tableView: TableView,
    rowKey: string | undefined,
    selectionDirection: SelectionDirection | undefined
) {
    return !(
        willOpenPopout(mailListItemSelectionSource) ||
        (isReadingPanePositionOff() && !willLoadRPInSLV(mailListItemSelectionSource)) ||
        isSelectingSameItem3Col(mailListItemSelectionSource, tableView, rowKey, selectionDirection)
    );
}

function willOpenPopout(mailListItemSelectionSource: MailListItemSelectionSource) {
    return (
        mailListItemSelectionSource === MailListItemSelectionSource.MailListItemRichPreview ||
        mailListItemSelectionSource === MailListItemSelectionSource.KeyboardShiftEnter ||
        mailListItemSelectionSource === MailListItemSelectionSource.MailListItemBodyDoubleClick ||
        mailListItemSelectionSource === MailListItemSelectionSource.MailListItemSimulatedDoubleClick
    );
}

function willLoadRPInSLV(mailListItemSelectionSource: MailListItemSelectionSource) {
    return (
        mailListItemSelectionSource === MailListItemSelectionSource.KeyboardEnter ||
        mailListItemSelectionSource === MailListItemSelectionSource.MailListItemBody ||
        mailListItemSelectionSource === MailListItemSelectionSource.RouteHandler ||
        mailListItemSelectionSource === MailListItemSelectionSource.CommandBarArrows ||
        (mailListItemSelectionSource === MailListItemSelectionSource.ImmersiveReadingPaneDelete &&
            getUserConfiguration().UserOptions?.NextSelection !== 'ReturnToView')
    );
}

function isSelectingSameItem3Col(
    mailListItemSelectionSource: MailListItemSelectionSource,
    tableView: TableView,
    rowKey?: string,
    selectionDirection?: SelectionDirection
) {
    if (isReadingPanePositionOff()) {
        return false;
    }
    if (mailListItemSelectionSource === MailListItemSelectionSource.KeyboardUpDown) {
        const anchorIndex = tableView.selectionAnchorRowKey
            ? tableView.rowKeys.indexOf(tableView.selectionAnchorRowKey)
            : -1;
        if (anchorIndex !== -1 && tableView.selectedRowKeys.size === 1) {
            const selectedIndex = tableView.rowKeys.indexOf(
                [...tableView.selectedRowKeys.keys()][0]
            );
            // If doing keyboard up/down, selecting the same item if, for the given direction, anchor is
            // above/below current selected item or selection is at the end of the list
            return selectionDirection === SelectionDirection.Next
                ? selectedIndex === anchorIndex + 1 ||
                      (selectedIndex === tableView.rowKeys.length - 1 &&
                          anchorIndex === tableView.rowKeys.length - 1)
                : selectedIndex === anchorIndex - 1 || (selectedIndex === 0 && anchorIndex === 0);
        }
        return false;
    }
    return rowKey && tableView.selectedRowKeys.has(rowKey) && tableView.selectedRowKeys.size === 1;
}
