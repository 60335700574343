import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MoveMruActions"*/ './lazyIndex')
);

export const lazyAddFolderIdToMoveToMruList = new LazyAction(
    lazyModule,
    m => m.addFolderIdToMoveToMruListMutator
);

export const lazyRemoveFolderIdFromMoveToMruList = new LazyAction(
    lazyModule,
    m => m.removeFolderIdFromMoveToMruListMutator
);

export const lazyGetMoveToMruList = new LazyAction(lazyModule, m => m.getMoveToMruList);
