import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import { getStore } from 'owa-mail-reading-pane-store/lib/store/Store';
import getActiveTabId from 'owa-mail-reading-pane-store/lib/utils/getActiveTabId';

export default function getConversationReadingPaneViewState(
    conversationId?: string
): ConversationReadingPaneViewState {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (11,5): Type 'ConversationReadingPaneViewState | undefined' is not assignable to type 'ConversationReadingPaneViewState'.
    // @ts-expect-error
    return getStore().loadedConversationReadingPaneViewStates.get(
        conversationId || getCurrentRenderedConversationId()
    );
}

export function getCurrentRenderedConversationId(): string {
    // If SxS is displayed, this should be considered the current rendered conversation
    // Otherwise go with the active tab id
    return (getStore().sxsItemId || getActiveTabId()) ?? '';
}
