import type { TimePanelSource } from '../TimePanelSource';

let isPanelOpen: boolean = false;
let source: TimePanelSource | null;

export function isTimePanelOpen(): boolean {
    return isPanelOpen;
}

export function openedTimePanel() {
    isPanelOpen = true;
}

export function closedTimePanel() {
    isPanelOpen = false;
}

export function getTimePanelSource(): TimePanelSource | null {
    return source;
}

export function setTimePanelSource(timePanelSource: TimePanelSource | null) {
    source = timePanelSource;
}
