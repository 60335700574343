import { getStore as getMailStore } from 'owa-mail-store/lib/store/Store';
import type { ClientItemId } from 'owa-client-ids';
import type { ClientItem } from 'owa-mail-store';

/**
 * This function is not wrapped in computedFn, as it could potentially return undefined when the item is still not
 * in the mailStore.
 * And subsecuent calls for the same item would incorrectly return undefined, even if the item is already in the store.
 */
export function getItem(itemId: ClientItemId | undefined): ClientItem | undefined {
    if (!itemId) {
        return undefined;
    }
    return getMailStore().items.get(itemId.Id);
}
