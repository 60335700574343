export enum AttachmentSelectionSource {
    MailList = 0,
    ReadingPane = 1,
    FilesHub = 2,
    PhotoHub = 3,
    GroupFilesHub = 4, // 5 was conversationAttachmentWell. This has been removed. Keeping the later values for backwards compatibility
    Deeplink = 6,
    FileSuggestion = 7,
    LinkSuggestion = 8,
    ComposeDocLink = 9,
    ReadingPaneDocLink = 10,
    ConsumerGroupFilesHub = 11,
    Feed = 12,
}
