import { formatToArray } from 'owa-localize';
import { getLocalizedString } from 'owa-datetime-formatters-core';

export default function formatWeekRangeWeekNumber(
    weekRangeElement: JSX.Element,
    weekNumber: number
) {
    return formatToArray(
        getLocalizedString('weekRangeWeekNumber'),
        weekRangeElement,
        weekNumber.toString()
    );
}
