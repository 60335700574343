import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import type ItemPartViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemPartViewState';
import { action } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';

export default action(
    'initializeExtendedCardForConversationReadingPane',
    (
        conversationViewState: ConversationReadingPaneViewState,
        loadedItemParts: ItemPartViewState[] | null,
        mailboxInfo: MailboxInfo
    ) => ({
        conversationViewState,
        loadedItemParts,
        mailboxInfo,
    })
);
