import type MailSwipeAction from 'owa-service/lib/contract/MailSwipeAction';
import { tokens } from '@fluentui/react-theme';

export function getIconColorForSwipeAction(swipeAction: MailSwipeAction): string {
    const swipeActionIconColorMap = {
        [1]: tokens.colorPaletteRedForeground1,
        [2]: tokens.colorPaletteGreenForeground1,
        [3]: tokens.colorBrandForeground2,
        [5]: tokens.colorPaletteMarigoldForeground1,
        [4]: tokens.colorBrandForeground2,
        [0]: tokens.colorNeutralForeground2,
    };
    return swipeActionIconColorMap[swipeAction] || 'transparent';
}
