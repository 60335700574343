enum SelectionDirection {
    // None
    None = 0,

    // Previous
    Previous = 1,

    // Next
    Next = 2,

    // Previous page
    PreviousPage = 3,

    // Next page
    NextPage = 4,
}

export default SelectionDirection;
