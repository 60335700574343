/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * This is the facade over 'classnames'; all other imports are funneled through here.
 *	> 'classnames' import is restricted from being used. Use owa-classnames instead */
import classnames, { type Argument } from 'classnames';
import { mergeClasses } from '@griffel/react';

function classnamesArgToArray(arg: Argument): string | string[] {
    if (typeof arg === 'string') {
        return arg;
    } else if (!arg) {
        return [];
    } else if (Array.isArray(arg)) {
        return arg.flatMap(classnamesArgToArray);
    } else if (typeof arg === 'object') {
        return Object.entries(arg)
            .filter(([, condition]) => !!condition)
            .map(([classname]) => classname);
    }
    return [];
}

const wrappedClassnames = process.env.USE_GRIFFEL
    ? (...args: Parameters<typeof classnames>) => {
          // mergeClasses() expects each slot value to be passed as a separate argument, rather than
          // concatenated into a single string.
          // As such, we need to run a facsimile of `classnames()` that returns an array of each resulting
          // argument without concatenating them together.
          const normalizedArgs: string[] = args.flatMap(classnamesArgToArray);
          return mergeClasses(...normalizedArgs);
      }
    : classnames;

export default wrappedClassnames;

export function mergeStyleSets(
    styleSetA: Record<string, string>,
    styleSetB: Record<string, string>
): Record<string, string> {
    const keys = new Set<string>([...Object.keys(styleSetA), ...Object.keys(styleSetB)]);
    return Object.fromEntries(
        [...keys].map(key => [key, wrappedClassnames(styleSetA[key], styleSetB[key])])
    );
}
