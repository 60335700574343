import { LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "EmbedChatCallout" */ './lazyIndex'),
    {
        name: 'EmbedChatCallout',
    }
);

export const lazyTriggerEmbedChatCallout = new LazyAction(
    lazyModule,
    m => m.triggerEmbedChatCallout
);
