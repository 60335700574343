import type RecipientWellWithFindControlViewState from 'owa-recipient-types/lib/types/RecipientWellWithFindControlViewState';
import type ReadWriteRecipientViewState from 'owa-recipient-types/lib/types/ReadWriteRecipientViewState';

const getRecipientsFromRecipientWells = (
    recipientWells: RecipientWellWithFindControlViewState[]
): ReadWriteRecipientViewState[] => {
    if (!recipientWells || recipientWells.length === 0) {
        return [];
    }

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2740 (14,5): Type 'RecipientWellWithFindControlViewState' is missing the following properties from type 'ReadWriteRecipientViewState[]': length, pop, push, concat, and 27 more.
    // @ts-expect-error
    return (
        recipientWells
            .filter(recipientWell => !!recipientWell)
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2769 (20,41): No overload matches this call.
            // @ts-expect-error
            .reduce((acc, recipeintWell) => [...acc, ...getRecipients(recipeintWell)], [])
    );
};

function getRecipients(recipientWell: RecipientWellWithFindControlViewState) {
    /**
     * recipients.slice() is required since recipients is a mobx observable array
     * and in order to get javascript array we need to use splice
     */
    return recipientWell?.recipients ? recipientWell.recipients.slice() : [];
}

export default getRecipientsFromRecipientWells;
