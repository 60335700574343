import { getStore } from '../store';
import { initializeOwaDateTimeStore } from '../actions/internalActions';
import { mutator, mutatorAction } from 'satcheljs';
import { timeZoneRanges } from '../utils/timeZoneRanges';
import type TimeZoneRangeType from 'owa-service/lib/contract/TimeZoneRangeType';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki) legacy
import { observable } from 'mobx';
import type TimeZoneOffsetsType from 'owa-service/lib/contract/TimeZoneOffsetsType';

// Initialize the store with time zone presents in sessiondata...
/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(initializeOwaDateTimeStore, actionMessage => {
    if (actionMessage.MailboxTimeZoneOffset) {
        for (var ii = 0; ii < actionMessage.MailboxTimeZoneOffset.length; ii++) {
            const offset = actionMessage.MailboxTimeZoneOffset[ii];
            if (offset.TimeZoneId) {
                // Avoid deep and proxy since the object is big and never changes internally (we would just replace with a new one)
                getStore().TimeZoneRanges[offset.TimeZoneId] = observable.array(
                    timeZoneRanges(offset.OffsetRanges as Required<TimeZoneRangeType>[]),
                    {
                        deep: false,
                        proxy: false,
                    }
                );
            }
        }
    }
});

export const setTimeZoneRange = mutatorAction(
    'setTimeZoneRange',
    (timeZoneOffsets: TimeZoneOffsetsType[]) => {
        const store = getStore();

        for (const { TimeZoneId, OffsetRanges, IanaTimeZones } of timeZoneOffsets) {
            if (TimeZoneId) {
                store.TimeZoneAlternateNames.set(TimeZoneId, IanaTimeZones || []);
                // Avoid deep and proxy since the object is big and never changes internally (we would just replace with a new one)
                store.TimeZoneRanges[TimeZoneId] = observable.array(
                    timeZoneRanges(
                        OffsetRanges as Required<TimeZoneRangeType>[],
                        true /* optimizeOrder */
                    ),
                    {
                        deep: false,
                        proxy: false,
                    }
                );
            }
        }
    }
);
