import type FavoriteNodeViewState from './schema/FavoriteNodeViewState';
import type FavoritesViewState from './schema/FavoritesViewState';
import type MailFavoritesStore from './schema/MailFavoritesStore';
import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';

const defaultMailFavoritesStore: MailFavoritesStore = {
    mailFavoritesViewState: new ObservableMap<string, FavoritesViewState>({}),
    favoritePersonasRootFolderId: undefined,
    isFavoritingInProgress: new ObservableMap<string, boolean>({}),
    favoriteNodeViewStates: new ObservableMap<string, FavoriteNodeViewState>({}),
};

const store = createStore<MailFavoritesStore>('mailFavorites', defaultMailFavoritesStore)();
export default store;
export const getStore = () => store;
