import type RetentionPolicyTag from 'owa-service/lib/contract/RetentionPolicyTag';
import { getStore } from '../store/store';

/**
 * Selector for the shared folder policy tags
 */
export default function getSharedFolderPolicyTagList(userIdentity: string): RetentionPolicyTag[] {
    const retentionPolicyTags = getStore().retentionPolicyTags;
    if (userIdentity && retentionPolicyTags.has(userIdentity)) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (13,9): Type 'RetentionPolicyTag[] | undefined' is not assignable to type 'RetentionPolicyTag[]'.
        // @ts-expect-error
        return retentionPolicyTags.get(userIdentity);
    }

    return [];
}
