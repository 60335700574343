import { errorThatWillCauseAlert } from 'owa-trace';

import { getM365ApplicationTypePolicy } from './graphql/type-policy/M365ApplicationType';

import type { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';

export function registerNovaTypePolicies(client: ApolloClient<NormalizedCacheObject>) {
    try {
        const M365ApplicationTypePolicy = getM365ApplicationTypePolicy();
        (client.cache as InMemoryCache).policies.addTypePolicies(M365ApplicationTypePolicy);
    } catch (e) {
        errorThatWillCauseAlert('AppBar_TypePolicyRegister_Error', e);
    }
}
