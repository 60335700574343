import {
    lazyRefreshSafelinksPolicy,
    lazyGetSafelinksPolicyRefreshStatus,
    SafelinksPolicyRefreshStatus,
} from 'owa-safelinks-shared';
import type { MailboxInfo } from 'owa-client-types';

export async function prefetchSafelinksPolicy(mailboxInfo: MailboxInfo) {
    const getSafelinksPolicyRefreshStatus = await lazyGetSafelinksPolicyRefreshStatus.import();
    const refreshSafelinksPolicy = await lazyRefreshSafelinksPolicy.import();

    //Fetch Safelinks policy here since lazy governor can take a while before it intiates the lazy action of fetching Safelinks policy
    //This should happen at most once per session
    if (getSafelinksPolicyRefreshStatus(mailboxInfo) === SafelinksPolicyRefreshStatus.NotStarted) {
        refreshSafelinksPolicy(mailboxInfo);
    }
}
