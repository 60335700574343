import { mutatorAction } from 'satcheljs';
import categoryStore from '../store/store';

/**
 * Sets the move to find text in the categoryMenu store
 * @param findText the find text
 */
export default mutatorAction(
    'setCategoryMenuFindText',
    function setCategoryMenuFindText(findText: string) {
        categoryStore.categoryMenuViewState.findText = findText;
    }
);
