import type { MailboxInfo } from 'owa-client-types';
import { getFolderTable, getMailboxInfoForFolderId } from 'owa-folders';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

const cachedMailboxInfo: Map<string, MailboxInfo> = new Map();

/**
 * Utility which returns user mailbox info as per folder id
 * @param folderId folder for which to fetch mailbox info
 */
export default function fetchUserMailboxInfoFromFolderId(
    folderId: string | undefined | null
): MailboxInfo {
    // Return request options as default mailbox info if folderId is not present or hasn't been added to the satchel store yet
    if (!folderId || !getFolderTable()?.has(folderId)) {
        return getGlobalSettingsAccountMailboxInfo();
    }

    // Save recomputation by storing the request options in a map per folder
    if (!cachedMailboxInfo.has(folderId)) {
        const mailboxInfo = getMailboxInfoForFolderId(folderId, true /* routeToAuxIfAuxArchive */);
        cachedMailboxInfo.set(folderId, mailboxInfo);
    }

    return cachedMailboxInfo.get(folderId) ?? getGlobalSettingsAccountMailboxInfo();
}
