import { getSelectedTableView } from 'owa-mail-list-store';
import type { ActionSource } from 'owa-analytics-types';
import { lazyReportItems } from 'owa-mail-triage-action';
import { lazyOnSingleSelectionChanged } from 'owa-mail-mark-read-actions';
import { ReportType } from 'owa-reporting';

export default async function onJunkNotJunk(actionSource: ActionSource, isJunkValueToSet: boolean) {
    const tableView = getSelectedTableView();
    const rowKeys = [...tableView.selectedRowKeys.keys()];

    //Mark as read when junk from menu
    if (tableView.selectedRowKeys.size === 1) {
        const selectedRowKey = [...tableView.selectedRowKeys.keys()][0];
        lazyOnSingleSelectionChanged.importAndExecute(selectedRowKey, tableView, true);
    }

    lazyReportItems.importAndExecute(
        isJunkValueToSet ? ReportType.Junk : ReportType.NotJunk,
        rowKeys,
        tableView,
        actionSource
    );
}
