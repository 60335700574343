import type { TableView } from 'owa-mail-list-store';
import { MailRowDataPropertyGetter } from 'owa-mail-list-store';
import { shouldTableSortByRenewTime } from 'owa-mail-triage-common';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';

/**
 * Compare the date time of two rows
 * @param targetLastDeliveryOrRenewTimeStamp the lastDeliveryOrRenewTimeStamp of the conversation to insert
 * @param targetLastDeliveryTimeStamp the lastDeliveryTimeStamp of the conversation to insert
 * @param rowKeyOfReferenceRow the conversation rowKey of the reference row
 * @param tableView the table view
 * @returns
 * 1) < 0 if reference row is earlier than target row
 * 2) 0 if have the same time
 * 3) > 0 if reference row is later than target row
 */
export default function compareRowDateTime(
    targetLastDeliveryOrRenewTimeStamp: string,
    targetLastDeliveryTimeStamp: string,
    rowKeyOfReferenceRow: string,
    tableView: TableView
): number {
    const isDraftsFolder = folderIdToName(tableView.tableQuery.folderId) === 'drafts';
    const lastDeliveryTimeStampOfReferenceRow = isDraftsFolder
        ? MailRowDataPropertyGetter.getLastModifiedTimeStamp(rowKeyOfReferenceRow, tableView)
        : MailRowDataPropertyGetter.getLastDeliveryTimeStamp(rowKeyOfReferenceRow, tableView);

    const diffDeliveryUTCTime =
        new Date(lastDeliveryTimeStampOfReferenceRow).getTime() -
        new Date(targetLastDeliveryTimeStamp).getTime();

    if (shouldTableSortByRenewTime(tableView.tableQuery)) {
        // If table supports pin, use renew time as primary sort, and delivery time as secondary sort
        const diffRenewTimeInUTC =
            new Date(
                MailRowDataPropertyGetter.getLastDeliveryOrRenewTimeStamp(
                    rowKeyOfReferenceRow,
                    tableView
                )
            ).getTime() - new Date(targetLastDeliveryOrRenewTimeStamp).getTime();
        return diffRenewTimeInUTC || diffDeliveryUTCTime;
    }

    return diffDeliveryUTCTime;
}
