import { LINK_CHICLET_OPEN_FAILURE } from './constants/safelinksDatapoints';

export {
    lazyCreateUrlDetectionContentHandler,
    lazyRefreshSafelinksPolicy,
    lazyHandleLinkClick,
    lazyGetSafelinksPolicyRefreshStatus,
    addOnClickHandler,
    lazyOpenJunkLinkVerification,
} from './lazyFunctions';
export { SafelinksPolicyRefreshStatus } from './schema/SafelinksPolicyRefreshStatus';
/* eslint-disable-next-line owa-custom-rules/forbid-package-reexport  -- (https://aka.ms/OWALintWiki)
 * Baseline comment; do not copy-paste. Rexporting from a separate package complicates the package graph.
 *	> Do not re-export from a separate package */
export { ClickSource } from 'owa-safelinks-evaluator/lib/utils/SourceEnums';
export const SafelinksDatapoints = {
    LINK_CHICLET_OPEN_FAILURE,
};
export { SAFELINKS_CLICK_HANDLER, SafelinksElement } from './schema/constants';
