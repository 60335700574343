import fetchUserMailboxInfoFromFolderId from './fetchUserMailboxInfoFromFolderId';
import type { MailboxInfo } from 'owa-client-types';
import { isPublicFolder } from 'owa-folders';
import { getGroupIdFromTableQuery, getGroupMailboxInfoFromTableQuery } from 'owa-group-utils';
import type { TableQuery, TableView } from 'owa-mail-list-store';
import { getPublicFolderMailboxInfo } from 'owa-publicfolder-utils';
import type MailboxRequestOptions from 'owa-service/lib/MailboxRequestOptions';
import { getStore as getListViewStore } from 'owa-mail-list-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export default function getMailboxInfo(tableView: TableView | undefined): MailboxInfo {
    if (tableView) {
        return getMailboxInfoFromTableQuery(tableView.tableQuery);
    }
    return getGlobalSettingsAccountMailboxInfo();
}

export function getMailboxInfoFromTableViewId(tableViewId: string): MailboxInfo {
    const tableView = getListViewStore().tableViews.get(tableViewId);
    return getMailboxInfo(tableView);
}

export function getMailboxInfoFromTableQuery(tableQuery: TableQuery): MailboxInfo {
    const groupId = getGroupIdFromTableQuery(tableQuery);

    const folderId = tableQuery?.folderId;

    return groupId != null
        ? getGroupMailboxInfoFromTableQuery(groupId, tableQuery)
        : getMailboxInfoFromFolderId(folderId);
}

export function getMailboxInfoFromFolderId(folderId: string | null | undefined): MailboxInfo {
    if (isPublicFolder(folderId)) {
        return getPublicFolderMailboxInfo(folderId);
    } else {
        return fetchUserMailboxInfoFromFolderId(folderId);
    }
}

export function getMailboxRequestOptionsFromTableQuery(
    tableQuery: TableQuery
): MailboxRequestOptions {
    const mailboxInfo = getMailboxInfoFromTableQuery(tableQuery);
    return {
        mailboxInfo,
    };
}
