import type { ConditionalFormattingRule } from '../store/schema/ConditionalFormattingRule';
import { getConditionalFormattingStore } from '../store/store';
import type { MailboxInfo } from 'owa-client-types';
import { isPaid } from 'owa-session-store';

export default function getConditionalFormattingRulesByMailbox(
    mailboxInfo: MailboxInfo
): ConditionalFormattingRule[] {
    return isPaid(mailboxInfo)
        ? getConditionalFormattingStore(mailboxInfo).conditionalFormattingRules
        : [];
}
