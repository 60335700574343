import type { ReadOnlyUserOptionsType } from 'owa-service/lib/ReadOnlyTypes';
import { updateOwaDateTimeStore } from './updateOwaDateTimeStore';
import type { GetTimeZoneOverrides } from './initializeTimeZones';
import type TimeZoneEntry from 'owa-service/lib/contract/TimeZoneEntry';
import type TimeZoneOffsetsType from 'owa-service/lib/contract/TimeZoneOffsetsType';

let initializationPromise: Promise<
    [TimeZoneOffsetsType[] | undefined, TimeZoneEntry[] | undefined]
>;

/**
 * Initializes the date time store with options and initializes time zones
 * @param options
 * @param shouldInitializeTimeZoneAnonymously
 */
export function initializeOwaDateTimeStore(
    options:
        | Pick<
              ReadOnlyUserOptionsType,
              'DateFormat' | 'TimeFormat' | 'TimeZone' | 'MailboxTimeZoneOffset'
          >
        | undefined,
    shouldInitializeTimeZoneAnonymously?: boolean,
    getTimeZoneOverrides?: GetTimeZoneOverrides
): Promise<[TimeZoneOffsetsType[] | undefined, TimeZoneEntry[] | undefined]> {
    if (!initializationPromise) {
        initializationPromise = updateOwaDateTimeStore(
            options,
            shouldInitializeTimeZoneAnonymously,
            getTimeZoneOverrides
        );
    }
    return initializationPromise;
}

/**
 * Makes sure any previously initiated initialization has completed.  Will NOT
 * kick off a new initialization.
 * @returns the initialization promise result if initialization has been started or undefined if not
 */
export async function ensurePendingDateTimeStore(): Promise<
    [TimeZoneOffsetsType[] | undefined, TimeZoneEntry[] | undefined] | undefined
> {
    return initializationPromise;
}
