import type Message from 'owa-service/lib/contract/Message';
import isSenderSelf from 'owa-mail-store/lib/selectors/isSenderSelf';
import type { MailboxInfo } from 'owa-client-types';

export function shouldDisableMessageResend(
    item: Message | undefined,
    mailboxInfo: MailboxInfo
): boolean {
    if (item?.IsDraft) {
        return true;
    }

    const from = item?.From?.Mailbox?.EmailAddress || '';
    const sender = item?.Sender?.Mailbox?.EmailAddress || '';

    return !isSenderSelf(from, mailboxInfo) || !isSenderSelf(sender, mailboxInfo);
}
