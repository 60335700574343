import TreeNode from 'owa-tree-node/lib/components/TreeNode';
import React from 'react';
import { observer } from 'owa-mobx-react';
import Strings from 'owa-folders-constants/lib/strings/LoadMore.locstring.json';
import loc from 'owa-localize';

export interface LoadMoreNodeProps {
    // Callback on click of the Load More button
    onLoadMoreClickedCallback: () => void;
}

export default observer(function LoadMoreFolderNode(props: LoadMoreNodeProps) {
    return (
        <TreeNode
            displayName={loc(Strings.loadMoreFoldersAction)}
            isCustomActionNode={true}
            isRootNode={false}
            key={'loadMore'}
            onClick={props.onLoadMoreClickedCallback}
        />
    );
}, 'LoadMoreFolderNode');
