import type { MailboxInfo } from 'owa-client-types';
import { mutatorAction } from 'satcheljs';
import { logStartGreyError } from 'owa-analytics-start';
import { getStore } from '../store/store';
import { getAccountSettingsInternal } from '../utils/getAccountSettingsInternal';
import { getOrInitializeSharedMailboxFolderInformationMap } from '../utils/sharedMailboxFolderInformationUtils';

export default mutatorAction(
    'ADD_FOLDER_TO_DEFAULT_FOLDER_MAPPINGS',
    (defaultFolderName: string, folderId: string, mailboxInfo: MailboxInfo) => {
        const store = getStore();
        if (defaultFolderName && folderId) {
            if (mailboxInfo.type == 'SharedMailbox') {
                const additionalAccountInformation =
                    getOrInitializeSharedMailboxFolderInformationMap(mailboxInfo);
                if (additionalAccountInformation) {
                    additionalAccountInformation.defaultFolderNameToIdMap?.set(
                        defaultFolderName,
                        folderId
                    );
                    additionalAccountInformation.defaultFolderIdToNameMap?.set(
                        folderId,
                        defaultFolderName
                    );
                    store.sharedMailboxFolderIdToNameMap?.set(folderId, defaultFolderName);
                }
            } else {
                const settings = getAccountSettingsInternal(mailboxInfo);
                if (settings) {
                    settings.defaultFolderNameToIdMap?.set(defaultFolderName, folderId);
                    settings.defaultFolderIdToNameMap?.set(folderId, defaultFolderName);
                    store.defaultFolderIdToNameMap?.set(folderId, defaultFolderName);
                }
            }
        } else {
            const error = new Error('AddFolderToDefaultFolderMappingsInvalidParameters');
            logStartGreyError('AddFolderToDefaultFolderMappingsInvalidParameters', error);
        }
    }
);
