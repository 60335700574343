import type {
    MailFolderTableQuery,
    TableQuery,
    GroupFolderTableQuery,
    GroupMailTableQuery,
} from 'owa-mail-list-store';
import { type TableQueryType } from 'owa-mail-list-store';
import type { SearchTableQuery } from 'owa-mail-list-search';

export default function getTableId(tableQuery: TableQuery): string {
    const tableId = [];
    switch (tableQuery.type) {
        case 0:
            tableId.push('folderId:' + tableQuery.folderId);

            const mailTableQuery = tableQuery as MailFolderTableQuery;
            tableId.push(
                'lVT:' + tableQuery.listViewType,
                'vF:' + mailTableQuery.viewFilter,
                'fVF:' + mailTableQuery.focusedViewFilter,
                'sC:' + mailTableQuery.sortBy.sortColumn,
                'sD:' + mailTableQuery.sortBy.sortDirection
            );

            if (mailTableQuery.messageSenderScreeningFilter) {
                tableId.push('sSF:' + mailTableQuery.messageSenderScreeningFilter);
            }

            if (mailTableQuery.categoryName) {
                tableId.push('catName:' + mailTableQuery.categoryName);
            }

            tableId.push('sT:' + mailTableQuery.scenarioType);
            break;

        case 1:
            tableId.push('folderId:' + tableQuery.folderId);

            const searchTableQuery = tableQuery as SearchTableQuery;
            tableId.push(
                'id:' + searchTableQuery.searchNumber,
                'query:' + searchTableQuery.queryString,
                'lVT:' + tableQuery.listViewType,
                'sT:' + searchTableQuery.scenarioType,
                'sP:' + searchTableQuery.searchProvider
            );
            break;

        case 2:
            tableId.push('gId:' + tableQuery.folderId);

            const groupMailTableQuery = tableQuery as GroupMailTableQuery;
            tableId.push(
                'lVT:' + tableQuery.listViewType,
                'vF:' + groupMailTableQuery.viewFilter,
                'acctId:' + groupMailTableQuery.mailboxInfo.userIdentity
            );
            break;
        case 3:
            tableId.push('folderId:' + tableQuery.folderId);

            const groupFolderTableQuery = tableQuery as GroupFolderTableQuery;
            tableId.push(
                'gId:' + groupFolderTableQuery.groupId,
                'lVT:' + tableQuery.listViewType,
                'vF:' + groupFolderTableQuery.viewFilter,
                'sC:' + groupFolderTableQuery.sortBy.sortColumn,
                'sD:' + groupFolderTableQuery.sortBy.sortDirection,
                'acctId:' + groupFolderTableQuery.mailboxInfo.userIdentity
            );
            break;
    }

    return tableId.join(';').toString();
}
