import { action } from 'satcheljs';
import type { SearchTab } from 'owa-search-types/lib/types/SearchTab';
import type { PerformanceDatapoint } from 'owa-analytics';
import type { Refiner } from 'owa-search-types';

/**
 * Action which kicks off a 3S search query for a given non-mail tab
 */
export const invoke3sSearchForTab = action(
    'invoke3sSearchForTab',
    (
        tab: SearchTab,
        queryString: string,
        searchEndToEndDatapoint: PerformanceDatapoint,
        appliedRefinersCount: number,
        appliedRefiners: Refiner[]
    ) => ({
        tab,
        queryString,
        searchEndToEndDatapoint,
        appliedRefinersCount,
        appliedRefiners,
    })
);
