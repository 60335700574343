import { useIntersectionObserver } from 'owa-react-hooks/lib/useIntersectionObserver';
import React from 'react';
import { logGreyError, logUsage } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';

type DivProperties = {
    id: string;
    scrollTopValue: number;
    scrollHeightValue: number;
    height: number;
    width: number;
};

const appContainerDivName = 'appContainer';

function getDivProperties(appId: string): DivProperties | undefined {
    const appElement = document.getElementById(appId) as HTMLElement;
    if (!appElement) {
        return undefined;
    }

    const style = window.getComputedStyle(appElement);
    /* eslint-disable no-restricted-properties --
     * Reading restricted properties from the DOM is necessary for detecting elements causing the app to render improperly in the viewport
     */
    const divProperties: DivProperties = {
        id: appElement.id,
        scrollTopValue: appElement.scrollTop,
        scrollHeightValue: appElement.scrollHeight,
        height: parseInt(style.height, 10),
        width: parseInt(style.width, 10),
    };
    /* eslint-enable no-restricted-properties */

    return divProperties;
}

export function useInitializeViewportAdjustment(titleBarRef: React.RefObject<HTMLDivElement>) {
    const intersectionCallback = React.useCallback(
        (entry: IntersectionObserverEntry) => {
            if (titleBarRef) {
                const titleBarElement = titleBarRef.current;
                if (
                    titleBarElement &&
                    entry.target == titleBarElement &&
                    entry.isIntersecting &&
                    entry.boundingClientRect.top != 0
                ) {
                    handleTitleBarAboveViewportError(entry);
                }
            }
        },
        [titleBarRef.current]
    );

    useIntersectionObserver('TitleBarAboveViewport', titleBarRef, intersectionCallback, {
        root: null, // Use the viewport as the root
        rootMargin: '-1px 0px 0px 0px', // Trigger when the element's top is less than 0
        threshold: 0, // Trigger as soon as any part of the element is visible
    });
}

var timesLoggedTitleBarAboveViewportError = 0;

function handleTitleBarAboveViewportError(entry: IntersectionObserverEntry) {
    logTitleBarAboveViewportError(entry);

    // Error repros when appContainer div's scrollTop is a postive number
    // This code resets the scrollTop to 0 to fix the issue
    // Gaurded behind a flight that is 'off' by default - we can enable the flight if this
    // causes worse regression and it will disable this behavior
    if (!isFeatureEnabled('fwk-disableResettingScrollTop')) {
        const appContainerElement = document.getElementById(appContainerDivName) as HTMLElement;
        if (!appContainerElement) {
            logUsage('TitleBarAboveViewport_FailedResettingAppContainerScrollTop');
            return;
        }

        logUsage('TitleBarAboveViewport_ResettingAppContainerScrollTop');

        /* eslint-disable-next-line no-restricted-properties --
         * Workaround to fix the app shifting out of the viewport is to reset the scrollTop to 0
         */
        appContainerElement.scrollTop = 0;
    }
}

// Title bar should be positioned at the top of the window with y-coord 0
// If it's not, OWA is rendering improperly and this will log the position
// Limit to 10x per session to reduce volume in long-lived sessions with this error
function logTitleBarAboveViewportError(entry: IntersectionObserverEntry) {
    if (timesLoggedTitleBarAboveViewportError < 10) {
        timesLoggedTitleBarAboveViewportError++;

        const appContainerDiv = getDivProperties(appContainerDivName);

        logGreyError(
            'TitleBarNotPositionedAtTopOfWindow',
            new Error('TitleBar not positioned at y-coordinate 0'),
            {
                titleBarTop: entry.boundingClientRect.y,
                appContainerScrollTop: appContainerDiv?.scrollTopValue,
                appContainerScrollHeight: appContainerDiv?.scrollHeightValue,
                appContainerHeight: appContainerDiv?.height,
                appContainerWidth: appContainerDiv?.width,
            }
        );
    }
}
