import type { ModulesEnabledForSwitch, Module } from 'owa-workloads';

const enabledModules: {
    [P in ModulesEnabledForSwitch]: true;
} = {
    Mail: true,
    Calendar: true,
    People: true,
    FilesHub: true,
    AppHost: true,
    Groups: true,
};

export function isModuleEnabledForModuleSwitch(module: Module): boolean {
    return enabledModules[module as ModulesEnabledForSwitch];
}

export function isModuleSwitchEnabled(
    currentModule: Module | undefined,
    newModule: Module
): boolean {
    return (
        (!currentModule || isModuleEnabledForModuleSwitch(currentModule)) &&
        isModuleEnabledForModuleSwitch(newModule)
    );
}
