import { createLazyComponent, LazyModule, LazyAction, LazyImport } from 'owa-bundling';

export { Team1 } from './services/UserVoiceFeedbackTags';
export type {
    UserVoiceFeedbackTags,
    UserVoiceFeedbackTxpTags,
} from './services/UserVoiceFeedbackTags';
export type { SuggestionDialogOptions } from './actions/showUserVoiceSuggestionDialog';
export { FeedbackMaxCharsAllowed } from './utils/UserVoiceConstantsForExport';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "UserVoice" */ './lazyIndex'));

export const lazyLaunchInAppFeedback = new LazyAction(lazyModule, m => m.launchInAppFeedback);
export const lazyPostSuggestionAndSendLogDatapoint = new LazyImport(
    lazyModule,
    m => m.postSuggestionAndSendLogDatapoint
);
export const lazyShowUserVoiceSuggestionDialog = new LazyImport(
    lazyModule,
    m => m.showUserVoiceSuggestionDialog
);
export const UserVoiceNps = createLazyComponent(lazyModule, m => m.UserVoiceNps);

export const lazyUserVoiceSuggestionStore = new LazyImport(
    lazyModule,
    m => m.userVoiceSuggestionStore
);
