import type { MailboxInfo } from 'owa-client-types';
import getAccountCategoryProps from './getAccountCategoryProps';

export function getCategoryUnreadCount(categoryName: string, mailboxInfo: MailboxInfo) {
    const unreadCount =
        getAccountCategoryProps(mailboxInfo)?.categoryDetails.get(categoryName)?.unreadCount;
    return unreadCount ? unreadCount : 0;
}

export function getCategoryTotalCount(categoryName: string, mailboxInfo: MailboxInfo) {
    const totalCount =
        getAccountCategoryProps(mailboxInfo)?.categoryDetails?.get(categoryName)?.totalCount;
    return totalCount ? totalCount : 0;
}
