import { getConditionalFormattingStore } from '../store/store';
import type { MailboxInfo } from 'owa-client-types';

export default function getKeyForRowIdInRowToModificationMap(
    rowId: string,
    mailboxInfo: MailboxInfo
): string | null {
    const rowToModifications = getConditionalFormattingStore(mailboxInfo).rowToModificationsMap;
    const keys = [...rowToModifications.keys()];
    for (const key of keys) {
        if (key.indexOf(rowId) >= 0) {
            return key;
        }
    }

    return null;
}
