import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';
import { mutatorAction } from 'satcheljs';
import { getSelectedCalendars } from '../selectors/getSelectedCalendars';

export const removeSelectedCalendarsForUser = mutatorAction(
    'removeSelectedCalendarsForUser',
    (mailboxInfo: MailboxInfo) => {
        const key = getIndexerValueForMailboxInfo(mailboxInfo);
        getSelectedCalendars().delete(key);
    }
);
