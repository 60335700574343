// The 6 different days of the Rokuyou calendar.  The values here are designed such
// that they can be used as bit fields since the user can select a combination of
// days that they wish to see.
export enum RokuyouDay {
    Senshou = 1,
    Tomobiki = 2,
    Sakimake = 4,
    Butsumetsu = 8,
    Taian = 16,
    Shakku = 32,
}
