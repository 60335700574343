import { updateUserConfiguration } from 'owa-session-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import setUserThemeRequest from 'owa-service/lib/factory/setUserThemeRequest';
import setUserThemeOperation from 'owa-service/lib/operation/setUserThemeOperation';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';

export default async function migrateThemeId(themeId: string) {
    updateUserConfiguration(config => {
        if (config?.UserOptions != null) {
            config.UserOptions.ThemeStorageId = themeId;
        }
    });

    await setUserThemeOperation(
        {
            request: setUserThemeRequest({
                ThemeId: themeId,
                UserThemeJson: '',
            }),
        },
        getMailboxRequestOptions(getGlobalSettingsAccountMailboxInfo())
    );
}
