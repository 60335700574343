import { useCallback } from 'react';

import { lazyGetDefaultRibbonControlInMenuRendererFunction } from '../lazyFunctions';
import { appIcons, getAppStrings } from 'owa-acui-theme';
import type {
    ControlInMenuRendererFunction,
    ControlInMenuRendererFunctionParameters,
} from '@1js/acui-menu';

const getEmptyControlInMenuRendererFunction: () => ControlInMenuRendererFunction = () => () => [];

/**
 * Custom hook to lazy load the in-menu renderer function.
 */
export const useControlInMenuRendererFunction = (
    isProjectionPopout: boolean
): ControlInMenuRendererFunction => {
    const appStrings = getAppStrings();
    const getInMenuRenderer =
        lazyGetDefaultRibbonControlInMenuRendererFunction.tryImportForRender();

    return useCallback(
        (parameters: ControlInMenuRendererFunctionParameters) => {
            // Get the renderer function or fallback to empty renderer function
            const rendererFunction = getInMenuRenderer
                ? getInMenuRenderer(appIcons, appStrings, isProjectionPopout)
                : getEmptyControlInMenuRendererFunction();

            // Ensure that we are passing the necessary parameters and invoke the renderer function
            return rendererFunction(parameters);
        },
        [getInMenuRenderer, appIcons, appStrings, isProjectionPopout]
    );
};
