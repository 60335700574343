import { LATENCY_LOCAL_STORAGE_KEY } from '../const/constants';
import type { LatencyStoreValue } from '../schema/LatencyStoreValue';
import type { ILatencyStore } from './ILatencyStore';
import { isFeatureEnabled } from 'owa-feature-flags';
import { setItem, getItem, removeItem } from 'owa-local-storage';

/*
Checks to see if auth-diag-panel is enabled before performing each operation.
In local storage, the auth-latency key corresponds to a stringified array of LatencyStoreValues.
Thus, to access an object inside the array we must get the item associated with 'auth-latency' then parse it.
*/

export class LatencyStore implements ILatencyStore {
    createLatencyObject(
        key: string,
        newLatency: number,
        newNumRequests: number
    ): LatencyStoreValue {
        const newLatencyObject: LatencyStoreValue = {
            storeKey: key,
            latencySoFar: newLatency,
            numRequests: newNumRequests,
        };
        return newLatencyObject;
    }

    setLatency(windowObj: Window, key: string, latency: number): void {
        // If the key already exists, then get the associated object and update it, then push it back to local storage
        if (isFeatureEnabled('auth-diag-panel')) {
            const currentValueArray = this.getCurrentValueArray(windowObj);
            for (var i = 0; i < currentValueArray.length; i++) {
                const currentValue = currentValueArray[i];
                const currentResource = currentValue.storeKey;
                // Case insensitive comparison
                if (currentResource?.toUpperCase() === key?.toUpperCase()) {
                    currentValueArray[i] = this.createLatencyObject(
                        key,
                        currentValue.latencySoFar + latency,
                        currentValue.numRequests + 1
                    );
                    setItem(
                        windowObj,
                        LATENCY_LOCAL_STORAGE_KEY,
                        JSON.stringify(currentValueArray)
                    );
                    return;
                }
            }

            // If the key does not exist, then create a new object and push it to local storage
            currentValueArray.push(this.createLatencyObject(key, latency, 1));
            setItem(windowObj, LATENCY_LOCAL_STORAGE_KEY, JSON.stringify(currentValueArray));
        }
    }

    clearLatencyValueArray(windowObj: Window): void {
        if (isFeatureEnabled('auth-diag-panel')) {
            removeItem(windowObj, LATENCY_LOCAL_STORAGE_KEY);
        }
    }

    getCurrentValueArray(windowObj: Window): LatencyStoreValue[] {
        if (isFeatureEnabled('auth-diag-panel')) {
            const currentValueArray = JSON.parse(
                getItem(windowObj, LATENCY_LOCAL_STORAGE_KEY) ?? '[]'
            );
            return currentValueArray;
        }
        return [];
    }

    getAllEntries(windowObj: Window): string[] {
        if (isFeatureEnabled('auth-diag-panel')) {
            const currentValueArray = this.getCurrentValueArray(windowObj);
            const allStoreKeys: string[] = [];

            for (var i = 0; i < currentValueArray.length; i++) {
                const currentValue = currentValueArray[i];
                const currentStoreKey = currentValue.storeKey;
                // Only add a resource to be displayed if it's a non-empty string
                if (currentStoreKey) {
                    allStoreKeys.push(currentStoreKey);
                }
            }
            return allStoreKeys;
        }
        return [];
    }

    calculateAverageLatency(windowObj: Window, key: string): number {
        if (isFeatureEnabled('auth-diag-panel')) {
            const currentValueArray = this.getCurrentValueArray(windowObj);
            var runningLatency: number = 0;
            var numRequests: number = 0;

            // Go through all LatencyStoreValues and add up the total latency and number of requests
            for (var i = 0; i < currentValueArray.length; i++) {
                const currentValue = currentValueArray[i];
                // Case insensitive comparison
                if (currentValue.storeKey?.toUpperCase() === key?.toUpperCase()) {
                    // Need to change the strings to numbers before adding
                    runningLatency = Number(currentValue.latencySoFar);
                    numRequests = Number(currentValue.numRequests);
                    break;
                }
            }
            // Round to two decimal places
            return Number((runningLatency / numRequests).toFixed(2));
        }
        return 0;
    }
}
