import { getSelectedTableView } from 'owa-mail-list-store';
import type { ActionSource } from 'owa-mail-store';
import { resetFocus } from 'owa-mail-focus-manager';
import { lazyToggleIgnoreConversations } from 'owa-mail-triage-action';

export default async function onIgnoreStopIgnore(
    actionSource: ActionSource,
    shouldIgnore: boolean
) {
    const tableView = getSelectedTableView();
    const rowKeys = [...tableView.selectedRowKeys.keys()];

    const toggleIgnoreConversations = await lazyToggleIgnoreConversations.import();
    toggleIgnoreConversations(rowKeys, tableView, shouldIgnore, actionSource);
    resetFocus('ignoreCommand');
}
