import { createStore } from 'satcheljs';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type InboxViewType from 'owa-service/lib/contract/InboxViewType';
import type { MailboxInfo } from 'owa-client-types';
import type FocusedInboxRollupStore from './schema/FocusedInboxRollupStore';

function getDefaultStore() {
    return {
        uniqueSenders: undefined,
        unseenCountToDisplay: undefined,
        viewType: 0,
    };
}

const mailboxInfoToStoreMap = new Map<string, FocusedInboxRollupStore>();

export const getStore = (mailboxInfo: MailboxInfo): FocusedInboxRollupStore => {
    const key = getIndexerValueForMailboxInfo(mailboxInfo) + '_focusedInboxRollupStore';

    let cachedStore = mailboxInfoToStoreMap.get(key);
    if (!cachedStore) {
        const store = createStore<FocusedInboxRollupStore>(key, getDefaultStore())();
        mailboxInfoToStoreMap.set(key, store);
        cachedStore = store;
    }

    return cachedStore;
};
