import type { SearchScope } from 'owa-search-service';
import { getKey, SearchScopeKind } from 'owa-search-service';
import folderStore from 'owa-folders';
import { default as getSearchScopeDisplayName } from './getSearchScopeDisplayName';
import type { IContextualMenuItem, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import shouldShowInScopeSelector from './shouldShowInScopeSelector';
import type { MailboxInfo } from 'owa-client-types';
import { isMessageFolderRoot } from 'owa-folders/lib/util/isFolderOrSubFolderOfDistinguishedFolder';
import { isFolderInFavorites } from 'owa-favorites/lib/selectors/isInFavorites';
import {
    currentFolderScope,
    recursiveFolderScope,
    favoritesFolderScope,
} from './getFolderContextualMenuOptions.locstring.json';
import loc from 'owa-localize';

/**
 * Returns array of dropdown options for folders dropdown.
 */
export default function getFolderContextualMenuOptions(
    contextualMenuItems: IContextualMenuItem[],
    searchScopes: SearchScope[],
    selectedMailboxInfo?: MailboxInfo
) {
    // Add the All folders folder first)
    // Add the UI for the Current folder,Subfolders, and Favorites folders (Without functionality for now)
    const favoritesFolderItems = getFavoritesFolder(searchScopes, selectedMailboxInfo);
    contextualMenuItems.push(
        ...getFolderOptions(searchScopes),
        { key: 'currentFolder', text: loc(currentFolderScope) },
        { key: 'recursiveSearch', text: loc(recursiveFolderScope) }
    );

    if (favoritesFolderItems.length > 0) {
        contextualMenuItems.push({
            key: 'favorites',
            text: loc(favoritesFolderScope),
            subMenuProps: {
                items: favoritesFolderItems,
            },
        });
    }

    // Convert the menu props into a IContextualMenuProps object
    const menuPropsWithItems: IContextualMenuProps = {
        items: contextualMenuItems,
    };
    return menuPropsWithItems;
}
/**
 * Helper function to get the All folders and convert it
 * to an array of IContextualMenuItem objects to get rendered.
 */
function getFolderOptions(
    searchScopes: SearchScope[],
    selectedMailboxInfo?: MailboxInfo
): IContextualMenuItem[] {
    const contextualMenuItems: IContextualMenuItem[] = [];
    searchScopes
        ?.filter((scope: SearchScope) => {
            switch (scope.kind) {
                case SearchScopeKind.PrimaryMailbox:
                    const folder = folderStore.folderTable.get(scope.folderId);
                    return folder && isMessageFolderRoot(folder);
                default:
                    return false;
            }
        })
        .map((scope: SearchScope) => {
            if (shouldShowInScopeSelector(scope)) {
                contextualMenuItems.push({
                    key: getKey(scope),
                    text: getSearchScopeDisplayName(scope, selectedMailboxInfo),
                });
            }
        });
    return contextualMenuItems;
}
/**
 * Helper function to get the user's Favorites folders and convert them
 * to an array of key-text pairs to get rendered as subMenuProps.
 */
function getFavoritesFolder(
    searchScopes: SearchScope[],
    selectedMailboxInfo?: MailboxInfo
): IContextualMenuItem[] {
    const favoritesFolder:
        | IContextualMenuItem[]
        | {
              key: string;
              text: string;
          }[] = [];
    searchScopes
        ?.filter((scope: SearchScope) => {
            switch (scope.kind) {
                case SearchScopeKind.PrimaryMailbox:
                    return isFolderInFavorites(scope.folderId);
                default:
                    return false;
            }
        })
        .map((scope: SearchScope) => {
            favoritesFolder.push({
                key: getKey(scope),
                text: getSearchScopeDisplayName(scope, selectedMailboxInfo),
            });
        });
    return favoritesFolder;
}
