import type { SearchTableQuery } from 'owa-mail-list-search';
import type { TableQuery, TableView } from 'owa-mail-list-store';
import { type TableQueryType } from 'owa-mail-list-store';
import { SearchScopeKind } from 'owa-search-service/lib/data/schema/SearchScope';
import type {
    SharedFoldersSearchScope,
    ArchiveMailboxSearchScope,
    PrimaryMailboxSearchScope,
} from 'owa-search-service/lib/data/schema/SearchScope';

/**
 * @param tableQuery table Query
 */
function isFolderSearch(tableQuery: TableQuery): boolean {
    if (tableQuery.type != 1) {
        return false;
    }

    const currentSearchScope = (tableQuery as SearchTableQuery).searchScope;
    const foldersSearchScopeKinds = [
        SearchScopeKind.PrimaryMailbox,
        SearchScopeKind.ArchiveMailbox,
        SearchScopeKind.SharedFolders,
    ];
    // Return true only if the table query's search scope type
    // is either primary mailbox or archive mailbox or shared folders
    return foldersSearchScopeKinds.indexOf(currentSearchScope.kind) > -1;
}

/**
 * @param tableView table View
 * @returns folderId based on the type and search scope of the table query
 */
export default function getFolderIdFromTableView(tableView: TableView) {
    const tableQuery = tableView.tableQuery;
    if (tableQuery.type === 0) {
        return tableQuery.folderId;
    } else if (isFolderSearch(tableQuery)) {
        const folderSearchScope = (tableQuery as SearchTableQuery).searchScope as
            | SharedFoldersSearchScope
            | ArchiveMailboxSearchScope
            | PrimaryMailboxSearchScope;
        return folderSearchScope.folderId;
    }

    return null;
}
