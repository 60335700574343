import isOneSpecifierBeforeTheOther from './isOneSpecifierBeforeTheOther';
import { format } from 'owa-localize';
import { localizedFormatter } from './localizedFormatter';
import { getWeekDayFormat } from './formatWeekDay';
import { getLocalizedString } from 'owa-datetime-formatters-core';

/**
 * Common output is 'dddd, MMM d' or 'dddd, d MMM'.
 */
export function getShortWeekDayMonthDayFormat(dateFormat: string, _timeFormat: string) {
    const dayFirst = isOneSpecifierBeforeTheOther(dateFormat, 'd', 'M');
    const formatString = getLocalizedString(
        dayFirst ? 'monthDayWeekDayFormatDayFirst' : 'monthDayWeekDayFormatMonthFirst'
    );
    return format(
        formatString,
        getWeekDayFormat(),
        getLocalizedString('MMM'),
        getLocalizedString('d')
    );
}

export default localizedFormatter(getShortWeekDayMonthDayFormat);
