import is3SServiceAvailable from 'owa-search/lib/utils/is3SServiceAvailable';
import getSearchProvider from '../selectors/getSearchProvider';
import isBusiness from 'owa-session-store/lib/utils/isBusiness';
import { SearchProvider } from 'owa-search-service/lib/data/schema/SearchProvider';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import store from '../store/store';

export default function isAnswerFeatureEnabled() {
    return isCommonAnswerEnabled() || isBusinessAnswersEnabled();
}

function isCommonAnswerEnabled(): boolean {
    return is3SServiceAvailable();
}

function isBusinessAnswersEnabled(): boolean {
    return is3SServiceAvailable() && isBusiness(getModuleContextMailboxInfo());
}

export function isSingleCallForAnswersAndSerpEnabled(): boolean {
    return (
        isAnswerFeatureEnabled() &&
        getSearchProvider(store.staticSearchScope) === SearchProvider.SubstrateV2
    );
}
