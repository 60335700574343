import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import getRootVdirName from 'owa-url/lib/getRootVdirName';

export function getModuleUrl(sessionType: WebSessionType): string {
    return (
        window.location.origin +
        '/' +
        getRootVdirName() +
        getPathWithIndex(sessionType) +
        '?bO=5&nlp=1&isc=' +
        sessionType
    );
}

export function getModuleUrlForNewAccount(): string {
    return getModuleUrl(2) + '&nojit=2';
}

function getPathWithIndex(sessionType: WebSessionType): string {
    switch (sessionType) {
        case 1:
            return '/0/';
        case 2:
            return '/1/';
        default:
            return '';
    }
}
