import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';
import type {
    GroupActivityState,
    GroupActivityStateByMailboxInfo,
} from './schema/GroupMemberRequestData';

const store = createStore<GroupActivityStateByMailboxInfo>('groupActivityStoreSchema', {
    groupActivityStateByMailboxInfo: new ObservableMap<string, GroupActivityState>(),
})();

export const getGroupsActivityStore = () => store;
