import {
    MAIN_WINDOW_ID,
    getStore,
    emptyMenuDefinition,
    placeholderMenuDefinition,
} from '../store/menuDefinitionStore';
import type { MenuDefinition } from '@1js/acui-menu';
import type { MenuDefinitionStoreNames } from '../store/menuDefinitionStore';
import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

/**
 * Creates an entrypoint in the MenuDefinition map manager for a given projectionTabId.
 * Used specifically for projection popout scenarios.
 */
export const createMenuDefinitionStore = mutatorAction(
    'createMenuDefinitionStore',
    function createMenuDefinitionStore(projectionTabId: string | undefined) {
        if (projectionTabId === undefined) {
            // We create a store on-boot for the main window (undefined), so return early here.
            return;
        }
        getStore().menuDefinitionMapManager.set(projectionTabId, {
            MenuDefinitionMap: new ObservableMap<MenuDefinitionStoreNames, () => MenuDefinition>(),
        });
    }
);

/**
 * Removes the entrypoint in the MenuDefinition map manager for a given projectionTabId.
 * Used specifically for projection popout scenarios.
 */
export const removeMenuDefinitionStore = mutatorAction(
    'removeMenuDefinitionStore',
    function removeMenuDefinitionStore(projectionTabId: string | undefined) {
        if (projectionTabId === undefined) {
            // We never want to delete the main window store, so we return early here.
            return;
        }

        const { menuDefinitionMapManager } = getStore();
        const projectionMap = menuDefinitionMapManager.get(projectionTabId);

        if (projectionMap) {
            projectionMap.MenuDefinitionMap.clear();
        }

        menuDefinitionMapManager.delete(projectionTabId);
    }
);

/**
 * Resets a store's menuDefinition back to default value of { sections: [] }.
 * Use this mutator in onAfterMenuDismiss callback only if you explicitly do not want
 * the menu to be loaded even after the first click.
 * An example of when this is useful is Flag/Unflag and Snooze, of which their menuDefinition has a call
 * to observableNow(), which causes the Ribbon to render every 60-seconds even if nothing is happening.
 */
export const resetMenuDefinition = mutatorAction(
    'resetMenuDefinition',
    function resetMenuDefinition(storeName: MenuDefinitionStoreNames, projectionTabId?: string) {
        const id = projectionTabId || MAIN_WINDOW_ID;
        const { menuDefinitionMapManager } = getStore();
        const rootMap = menuDefinitionMapManager.get(id);
        const storeMap = rootMap && rootMap.MenuDefinitionMap.get(storeName);

        if (storeMap) {
            rootMap.MenuDefinitionMap.set(storeName, () =>
                id === MAIN_WINDOW_ID ? emptyMenuDefinition : placeholderMenuDefinition
            );
        }
    }
);
