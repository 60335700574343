import type { MailboxInfo } from 'owa-client-types';
import { getGroupFolderHierarchy } from './getGroupFolderHierarchy';

export function getAllFolderIdsForGroup(groupId: string, mailboxInfo: MailboxInfo): string[] {
    const groupFolderIds: string[] = [];
    const folderHierarchy = getGroupFolderHierarchy(groupId, mailboxInfo)?.folderHierarchy;

    if (folderHierarchy) {
        for (const groupFolderId of folderHierarchy.keys()) {
            groupFolderIds.push(groupFolderId);
        }
    }

    return groupFolderIds;
}
