import type { MailboxInfo } from 'owa-client-types';
import { getApplicationSettings } from 'owa-application-settings';
import { isOfflinePeopleDataViaPDVEnabled } from './isOfflinePeopleDataViaPDVEnabled';
import { isFeatureEnabled } from 'owa-feature-flags';

export const isPersonSourcesEnabled = (mailboxInfo: MailboxInfo) => {
    // For OWA, we don't have PDV support yet, so for now we only check if one-person-view flight is enabled
    return (
        (getApplicationSettings('OnePersonView').enabled &&
            getApplicationSettings('Jana').lpeNova &&
            isOfflinePeopleDataViaPDVEnabled(mailboxInfo)) ||
        isFeatureEnabled('peo-one-person-view')
    );
};
