import { lazyGetFolderMenuOptionsSections } from 'owa-mail-ribbon-sections/lib/sections';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onFolderMenuOptionsMenuClicked = (props: ReadOnlyRibbonControlDefProps) => {
    lazyGetFolderMenuOptionsSections.import().then(getFolderMenuOptions => {
        setMenuDefinition(
            'FolderMenuOptionsMenuDefinitionStore',
            undefined /* projectionTabId */,
            () => {
                return {
                    sections: getFolderMenuOptions(props),
                };
            }
        );
    });
};
