import getRetentionPolicyTagListForMailboxInfo from './getRetentionPolicyTagListForMailboxInfo';
import getRetentionPolicyTagsFromTableView from './getRetentionPolicyTagsFromTableView';
import mailStore from 'owa-mail-store/lib/store/Store';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { isFeatureEnabled } from 'owa-feature-flags';
import { trace } from 'owa-trace';
import type { TableView } from 'owa-mail-list-store';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';

/**
 * Returns true if item has retention policy of type record (IsImmutable)
 */
export function isRecord(retentionId: string, tableView: TableView): boolean {
    const mailboxInfo = getMailboxInfo(tableView);
    if (isFeatureEnabled('rp-recordManagement') && !isConsumer(undefined, mailboxInfo)) {
        const retentionPolicyTags = getRetentionPolicyTagsFromTableView(tableView);
        if (retentionPolicyTags) {
            for (const tag of retentionPolicyTags) {
                if (tag && tag.RetentionId === retentionId && tag.IsImmutable) {
                    return true;
                }
            }
        } else {
            trace.warn('Cannot check retention policy. Could not obtain retention policy taglist');
        }
    }
    return false;
}

/**
 * Returns true if item has retention policy of type regulatory record (IsRegulatory)
 */
export function isRegulatoryRecord(retentionId: string, tableView: TableView): boolean {
    const mailboxInfo = getMailboxInfo(tableView);
    if (isFeatureEnabled('rp-recordManagement') && !isConsumer(undefined, mailboxInfo)) {
        const retentionPolicyTags = getRetentionPolicyTagsFromTableView(tableView);
        if (retentionPolicyTags) {
            for (const tag of retentionPolicyTags) {
                if (tag && tag.RetentionId === retentionId && tag.IsRegulatory) {
                    return true;
                }
            }
        } else {
            trace.warn('Cannot check retention policy. Could not obtain retention policy taglist');
        }
    }
    return false;
}

/**
 * Returns true if item has retention policy of type record (IsImmutable) or regulatory record (IsRegulatory)
 */
export function isRecordOrRegulatoryRecord(itemId: string): boolean {
    if (!isFeatureEnabled('rp-recordManagement')) {
        return false;
    }
    const item = mailStore.items.get(itemId);
    if (!item) {
        trace.warn('Cannot check retention policy. Item not in mail store');
        return false;
    }
    const retentionPolicyOnItem = item.PolicyTag?.Value;
    if (retentionPolicyOnItem && !isConsumer(undefined, item.MailboxInfo)) {
        const retentionPolicyTags = getRetentionPolicyTagListForMailboxInfo(item.MailboxInfo);
        if (retentionPolicyTags) {
            for (const tag of retentionPolicyTags) {
                if (
                    tag &&
                    tag.RetentionId === retentionPolicyOnItem &&
                    (tag.IsImmutable || tag.IsRegulatory)
                ) {
                    return true;
                }
            }
        } else {
            trace.warn(
                'Cannot check retention policy. Could not obtain retention policy taglist for mailbox info'
            );
        }
    }
    return false;
}
