import { scrubForPii } from 'owa-config';

/**
 * Graphql sometimes serializes user data into error messages, so we try to sanitize the known cases.
 * https://github.com/graphql/graphql-js/issues/2629
 */
export function sanitizeGraphqlErrorMessage(message?: string) {
    if (!message) {
        return message;
    }

    message = truncateAfterBlockedPrefixes(message);

    // Protect against huge data sent to kusto
    if (message.length > MAX_LENGTH) {
        message = message.substring(0, MAX_LENGTH - 3) + '...';
    }

    return scrubForPii(message);
}

function truncateAfterBlockedPrefixes(message: string) {
    for (const exception of allow_exceptions) {
        if (message.includes(exception)) {
            return message;
        }
    }

    for (const prefix of blocked_prefixes) {
        const index = message.indexOf(prefix);
        if (index !== -1) {
            message = message.substring(0, index + prefix.length) + '...';
        }
    }

    return message;
}

const MAX_LENGTH = 256;

// These generally mean serialized user data might appear soon
const blocked_prefixes = ['cannot represent', 'got invalid value'];

// Allow these special cases through
const allow_exceptions = ['got invalid value null', 'got invalid value undefined'];
