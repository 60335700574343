import type { AriaDatapointType, DataPointEventType } from 'owa-analytics-types';
import { lazyLogDatapoint } from '../lazyFunctions';
import { addStackTraceToEvent } from 'owa-analytics-utils/lib/addStackTraceToEvent';

export function logDatapoint(
    datapoint: AriaDatapointType,
    overrideEventType?: DataPointEventType
): Promise<void> {
    // Add original stack trace
    addStackTraceToEvent(datapoint.getOptions());

    return lazyLogDatapoint.importAndExecute(datapoint, overrideEventType);
}
