import {
    irm_featureDisabled,
    irm_corruptProtectedMessage,
    irm_viewRightNotGranted,
    irm_serverMisConfigured,
    irm_reachNotConfigured,
    irm_preLicensingFailure,
    irm_externalLicensingDisabled,
    irm_useLicenseAcquisitionFailed,
    irm_rmsErrorCode,
    irm_outlookTrialDownloadUrl,
    irm_microsoftOfficeOutlook,
    irm_downloadOutlookFreeTrialHtml,
} from './getAlternateBodyForIRM.locstring.json';
import loc, { format } from 'owa-localize';
import type RightsManagementFailureCode from 'owa-mail-store/lib/store/schema/RightsManagementFailureCode';
import type RightsManagementLicenseDataType from 'owa-service/lib/contract/RightsManagementLicenseDataType';

export default function getDefaultBodyForIrm(
    rightsManagementLicenseData: RightsManagementLicenseDataType | undefined
): string | undefined {
    if (!rightsManagementLicenseData) {
        return undefined;
    }

    let alternateBody: string;

    if (
        rightsManagementLicenseData?.RightsManagedMessageDecryptionStatus &&
        rightsManagementLicenseData.RightsManagedMessageDecryptionStatus != 0
    ) {
        switch (rightsManagementLicenseData.RightsManagedMessageDecryptionStatus) {
            case -2147160052:
            case -2147160012:
            case -2147160011:
                alternateBody = format(loc(irm_featureDisabled), getOutlookDownloadURL());
                break;

            case -2147160010:
                alternateBody = loc(irm_corruptProtectedMessage);
                break;

            case -2147168507:
                alternateBody = format(loc(irm_viewRightNotGranted), getOutlookDownloadURL());
                break;

            case -2147160014:
                alternateBody = loc(irm_serverMisConfigured);
                break;

            case -2147160054:
            case -2147160055:
                alternateBody = loc(irm_reachNotConfigured);
                break;

            case -2147160064:
            case -2147160013:
                alternateBody = loc(irm_preLicensingFailure);
                break;

            case -2147160051:
                alternateBody = loc(irm_externalLicensingDisabled);
                break;

            case -2147160060:
                alternateBody = loc(irm_useLicenseAcquisitionFailed);
                break;

            case -2147160009:
            // For now, treating missing license as generic error.
            // Once we add retry logic, we'll show a "Loading" instead.
            // O15:2313547
            default:
                // For now, just show the error code.
                // Once we have the error message and error location, we should show that with a "Details" button.
                // O15:2313547
                alternateBody = format(
                    loc(irm_rmsErrorCode),
                    rightsManagementLicenseData.RightsManagedMessageDecryptionStatus
                );
                break;
        }
    }

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2454 (85,12): Variable 'alternateBody' is used before being assigned.
    // @ts-expect-error
    return alternateBody;
}

function getOutlookDownloadURL() {
    const anchor = format(
        '<a href="{0}" target="_blank">{1}</a>',
        loc(irm_outlookTrialDownloadUrl),
        loc(irm_microsoftOfficeOutlook)
    );
    return format(loc(irm_downloadOutlookFreeTrialHtml), anchor);
}
