import type {
    default as SubstrateSearchResponse,
    SearchResultSet,
} from '../../data/schema/SubstrateSearchResponse';

export default function getSearchResultSet(responseBody: SubstrateSearchResponse): SearchResultSet {
    if (responseBody?.EntitySets?.[0]?.ResultSets?.[0]) {
        return responseBody.EntitySets[0].ResultSets[0];
    }

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (14,5): Type 'null' is not assignable to type 'SearchResultSet'.
    // @ts-expect-error
    return null;
}
