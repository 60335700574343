import { action } from 'satcheljs';
import type { OwsOptionsFeatureType } from '../store/schema/OwsOptionsFeatureType';
import type OwsOptionsBase from '../store/schema/OwsOptionsBase';
import type OwsOptionsStore from '../store/schema/OwsOptionsStore';
import type { MailboxInfo } from 'owa-client-types';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export const setOptionValue = action(
    'setOptionValue',
    (
        feature: OwsOptionsFeatureType,
        value: OwsOptionsBase,
        mailboxInfo: MailboxInfo = getGlobalSettingsAccountMailboxInfo()
    ) => ({
        feature,
        value,
        mailboxInfo,
    })
);

export const initializeOptionValues = action(
    'initializeOptionValues',
    (
        potentialOptionValues: any[],
        mailboxInfo: MailboxInfo = getGlobalSettingsAccountMailboxInfo()
    ) => ({
        potentialOptionValues,
        mailboxInfo,
    })
);

export const setExtendedOptionValue = action(
    'setOptionValue',
    (
        feature: OwsOptionsFeatureType,
        value: Partial<OwsOptionsBase>,
        mailboxInfo: MailboxInfo = getGlobalSettingsAccountMailboxInfo()
    ) => ({
        feature,
        value,
        mailboxInfo,
    })
);

export const setServiceOptionStoreData = action(
    'setServiceOptionStoreData',
    (storeData: { [key: string]: OwsOptionsStore }) => ({
        storeData,
    })
);
