import { getCoprincipalAccountForIndexerValue } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Returns the MailboxInfo associated with the specified indexer value
 * @param indexerValue Value obtained via a getIndexerValueForMailboxInfo call
 * @returns MailboxInfo if found or undefined if not found, not finding the MailboxInfo is expected
 * if the account was deleted
 */
export default function getMailboxInfoForIndexerValue(
    indexerValue: string
): MailboxInfo | undefined {
    return getCoprincipalAccountForIndexerValue(indexerValue, true /*logIfNotFound*/)?.mailboxInfo;
}
