import { AccountSourceType } from '../AccountSourceType';
import type { MailboxProvider } from 'owa-client-types';

/**
 * Map from AccountSourceType to MailboxProvider
 */
export const sourceToProviderMap: Record<AccountSourceType, MailboxProvider> = {
    [AccountSourceType.Office365]: 'Office365',
    [AccountSourceType.OutlookDotCom]: 'Outlook',
    [AccountSourceType.Google]: 'Google',
    [AccountSourceType.Yahoo]: 'Yahoo',
    [AccountSourceType.ICloud]: 'ICloud',
    [AccountSourceType.IMAP]: 'IMAP',
    [AccountSourceType.POP3]: 'POP3',
    [AccountSourceType.PstFile]: 'PstFile',
    [AccountSourceType.Other]: 'Unknown',
};

/**
 * Retursn the MailboxProvider for the given AccountSourceType
 * @param source Specifies the AccountSourceType
 * @returns MailboxProvider value
 */
export default function sourceToProvider(source: AccountSourceType): MailboxProvider {
    return sourceToProviderMap[source];
}
