import React from 'react';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import CommandRibbonStrings from 'owa-locstrings/lib/strings/commandRibbon.locstring.json';
import { appRibbon, appRibbonBleedThrough } from './AppRibbonContainer.scss';
import { isCapabilityEnabled } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';
import classNames from 'owa-classnames';

export const AppRibbonContainer = observer(function AppRibbonContainer({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <div
            tabIndex={-1}
            className={classNames(
                appRibbon,
                isCapabilityEnabled(bleedThroughCapability) && appRibbonBleedThrough
            )}
            role="region"
            data-app-section="Ribbon"
            aria-label={loc(CommandRibbonStrings.commandRibbonLabel)}
        >
            {children}
        </div>
    );
},
'AppRibbonContainer');
