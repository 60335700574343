/**
 * Checks if the given id is a runtime control id
 *
 * @export
 * @param {number} id
 * @returns {boolean}
 */
export function isSpamIntegrationRuntimeControlId(id: number): boolean {
    // Following the reference from: File: ' / packages / owa - ribbon - ids / src / actionControlId.ts;'
    // The runtime control ids are in the range of 91000 - 99999

    return id >= 91000 && id <= 99999;
}
