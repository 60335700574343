import type { QuickStepHotkeyId } from 'owa-quick-step-types';
import { getQuickSteps } from './getQuickSteps';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { loadMailboxQuickSteps } from '../util/loadQuickSteps';
import getStore from '../store/store';
import type { QuickStepReturnInfo } from './getQuickStepById';

export async function getQuickStepActionsByHotkey(
    mailboxInfo: MailboxInfo,
    hotkey: QuickStepHotkeyId
): Promise<QuickStepReturnInfo> {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    // if we've haven't already loaded the quick steps for that mailbox, load them
    if (!getStore().quickStepsStoreMap.has(key)) {
        await loadMailboxQuickSteps(mailboxInfo);
    }

    const quickStepsList = getQuickSteps(mailboxInfo);
    const quickStep = quickStepsList.find(quickStepItem => quickStepItem.hotkey === hotkey);
    return {
        actions: quickStep?.actions ?? [],
        index: quickStep ? quickStepsList.indexOf(quickStep) : -1,
    };
}
