import { getLocalizedString } from 'owa-datetime-formatters-core';
import { format } from 'owa-localize';
import { localizedFormatter } from './localizedFormatter';
import { getDayFormat } from './formatDay';

export function getShortWeekDayShortMonthDayYearFormat(dateFormat: string, timeFormat: string) {
    const dayFormat = getDayFormat(dateFormat, timeFormat);
    return format(
        getLocalizedString('shortWeekDayMonthDayYearFormat'),
        getLocalizedString('MMM'),
        dayFormat,
        getLocalizedString('yyyy')
    );
}

export default localizedFormatter(getShortWeekDayShortMonthDayYearFormat);
