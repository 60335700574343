import { copilotSettingsStore, LicenseType } from 'owa-copilot-settings-store';
import type { MailboxInfo } from 'owa-client-types';

const hasTeamsRecapRelatedLicense = (mailboxInfo: MailboxInfo): boolean => {
    const store = copilotSettingsStore(mailboxInfo);
    if (store.isEnabled) {
        return store.licenses.some(
            license => license === LicenseType.M365Copilot || license === LicenseType.TeamsCopilot
        );
    }

    return false;
};

export default hasTeamsRecapRelatedLicense;
