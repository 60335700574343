import { mailRibbonVersionManager } from './mailRibbonVersionManager';
import { ribbonValidityCheck } from './ribbonValidityCheck';
import {
    removeTab,
    removeGroup,
    removeControl,
    removeControlFromOverflow,
    addTab,
    addGroup,
    addControl,
    addControlToOverflow,
    updateGroupOrdering,
} from './ribbonVersionHandlerDefinitions';
import type RibbonVersionManager from './ribbonVersionManager';
import { defaultConfig } from 'owa-mail-ribbon-store-shared-types';
import { updateAccountScopeUserSettingsAndService } from 'owa-userconfiguration/lib/utils/updateAccountScopeUserSettingsAndService';
import { logUsage } from 'owa-analytics';
import type { MailboxInfo } from 'owa-client-types';

/**
 * This is the handler for Ribbon Versioning that updates the ribbonConfig parameter with
 * the changes in each version. Once updated, this function will save the changes back
 * to the user's persisted store.
 *
 * @param ribbonConfig is the configuration from the user that may be of old version.
 *                     It is of type any because it may be an old enough version that
 *                     it doesn't fit into MailRibbonConfigStore anymore.
 * @returns nothing, as it directly changes the parameter `ribbonConfig`.
 */
export function ribbonVersionHandler(ribbonConfig: any, mailboxInfo: MailboxInfo): void {
    if (defaultConfig.version != mailRibbonVersionManager.length) {
        throw new Error(
            "Mismatch in defaultConfig's version and mailRibbonVersionManager's length."
        );
    }

    if (ribbonConfig.version > defaultConfig.version) {
        // If we're running ribbonVersionHandler on an older version, we don't want to version handle this.
        // That's because we'd be trying to update OWS` with older versioning code, which will cause issues
        // for future versioning updates.
        return;
    }

    for (let i = ribbonConfig.version; i < defaultConfig.version; i++) {
        const versionManager: RibbonVersionManager = mailRibbonVersionManager[i];

        // If the version manager has a flag that says it's past the six month limit,
        // then we won't version handle this. Instead, we'll just return defaultConfig.
        // This is to help alleviate bundle size bloat and shorten boot load times.
        if (versionManager.oldVersioningPastSixMonthLimit) {
            logUsage('RibbonVersioning_OldVersioningPastSixMonthLimit', [
                ribbonConfig.version,
                defaultConfig.version,
            ]);
            resetRibbonToDefaultConfig(ribbonConfig, mailboxInfo);
            return;
        }

        // Remove commands first,
        // to eliminate chances of duplicate RibbonIds in case of multiple commands in one version update.
        if (versionManager.removeTab) {
            removeTab(ribbonConfig, versionManager.removeTab);
        }
        if (versionManager.removeGroup) {
            removeGroup(ribbonConfig, versionManager.removeGroup);
        }
        if (versionManager.removeControl) {
            removeControl(ribbonConfig, versionManager.removeControl);
        }
        if (versionManager.removeControlFromOverflow) {
            removeControlFromOverflow(ribbonConfig, versionManager.removeControlFromOverflow);
        }

        // Add commands
        if (versionManager.addTab) {
            addTab(ribbonConfig, versionManager.addTab);
        }
        if (versionManager.addGroup) {
            addGroup(ribbonConfig, versionManager.addGroup);
        }
        if (versionManager.addControl) {
            addControl(ribbonConfig, versionManager.addControl);
        }
        if (versionManager.addControlToOverflow) {
            addControlToOverflow(ribbonConfig, versionManager.addControlToOverflow);
        }

        // Utility / Miscellaneous commands last
        if (versionManager.updateGroupOrdering) {
            updateGroupOrdering(ribbonConfig, versionManager.updateGroupOrdering);
        }
    }

    // We've finished updating the content in config, let's double check via validityChecker to ensure
    // that every ID within this user's custom config is able to be found in the default config.
    // If not, something went wrong and we can't guarantee that the app will be able to render the user's config.
    // We reset the Ribbon to default in that scenario.
    if (!ribbonValidityCheck(ribbonConfig.singleline, ribbonConfig.version)) {
        logUsage('RibbonVersioning_FailedValidityCheck_AfterVersioningCompleted', [
            ribbonConfig.version,
            defaultConfig.version,
        ]);
        resetRibbonToDefaultConfig(ribbonConfig, mailboxInfo);
        return;
    }

    logUsage('RibbonVersioning_PassedValidityCheck_AfterVersioningCompleted', [
        ribbonConfig.version,
        defaultConfig.version,
    ]);

    // Update version number
    ribbonConfig.version = defaultConfig.version;

    // Publish changes to the persisted server store.
    const ribbonConfigString: string = JSON.stringify(ribbonConfig);
    updateAccountScopeUserSettingsAndService(mailboxInfo, {
        ViewStateConfiguration: {
            MailRibbonConfig: ribbonConfigString,
        },
    });
    return;
}

/**
 * Resets the ribbon to the default configuration.
 */
function resetRibbonToDefaultConfig(ribbonConfig: any, mailboxInfo: MailboxInfo) {
    // Save the default config to the user's persisted store.
    const ribbonConfigString: string = JSON.stringify(defaultConfig);
    updateAccountScopeUserSettingsAndService(mailboxInfo, {
        ViewStateConfiguration: {
            MailRibbonConfig: ribbonConfigString,
        },
    });

    // Deep copy the default config to the ribbonConfig.
    ribbonConfig.singleline = JSON.parse(JSON.stringify(defaultConfig.singleline));
    ribbonConfig.multiline = JSON.parse(JSON.stringify(defaultConfig.multiline));
    ribbonConfig.version = defaultConfig.version;
}
