import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import type MailSwipeAction from 'owa-service/lib/contract/MailSwipeAction';

export function getSwipeFromLeftAction(): MailSwipeAction {
    return getUserConfiguration()?.ViewStateConfiguration?.MailRightSwipeAction ?? 4; // Default to ToggleRead if the MailRightSwipeAction is not set
}

export function getSwipeFromRightAction(): MailSwipeAction {
    return getUserConfiguration()?.ViewStateConfiguration?.MailLeftSwipeAction ?? 1; // Default to Delete if the MailLeftSwipeAction is not set
}
