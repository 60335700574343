import { getCountBucket } from 'owa-mail-reading-pane-store/lib/datapoints';
import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import { logUsage } from 'owa-analytics';
import { mutatorAction } from 'satcheljs';
import { getItemsCountInCollapsedItemsRollUp } from '../utils/rollUp/collapsedItemsRollUpUtils';

export function expandCollapsedItemsRollUpMutator(
    viewState: ConversationReadingPaneViewState,
    isAuto: boolean = false
) {
    logUsage('RPCountExpCollItemsRollUp', [
        getCountBucket(getItemsCountInCollapsedItemsRollUp(viewState)),
        isAuto,
    ]);
    clearConversationNodeIdsInCollapsedItemsRollUpExcludingRSVPMutator(viewState);
}

const clearConversationNodeIdsInCollapsedItemsRollUpExcludingRSVPMutator = mutatorAction(
    'expandCollapsedItemsRollUp',
    (viewState: ConversationReadingPaneViewState) => {
        viewState.conversationNodeIdsInCollapsedItemsRollUpExcludingRSVP = [];
    }
);
