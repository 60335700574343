import getResourcesForTheme from './getResourcesForTheme';
import { getThemeIdFromParameter } from '../utils/optionalArgumentHelpers';

export default function getBackgroundImageData(
    themeId?: string,
    isDarkTheme?: boolean
): string | undefined {
    themeId = getThemeIdFromParameter(themeId);

    return getResourcesForTheme(themeId, isDarkTheme).background?.image;
}
