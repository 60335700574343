import type { OcsPatchEntryInput } from 'owa-graph-schema';
import { lazyWriteOcsSettings } from 'owa-ocs-service-gql';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { CONDITIONAL_FORMATTING_SETTING_NAME } from '../utils/constants';
import type { MailboxInfo } from 'owa-client-types';
import { logUsage } from 'owa-analytics';

export default function saveConditionalFormattingRulesService(
    mailboxInfo: MailboxInfo,
    rules: string
) {
    const ocsConditionalFormattingPatch: OcsPatchEntryInput = {
        name: CONDITIONAL_FORMATTING_SETTING_NAME,
        value: rules,
        type: 'JSON',
        scope: getAccountScopeUserSettings(mailboxInfo).SessionSettings?.UserEmailAddress || '',
        itemClass: 'RoamingSetting',
    };
    lazyWriteOcsSettings
        .importAndExecute(mailboxInfo, 'ACCOUNT', [ocsConditionalFormattingPatch])
        .catch(err => {
            logUsage('TnS_ConditionalFormatting_ErrorSave', {
                message: err.message,
            });
        });
}
