import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () =>
        import(
            /* webpackChunkName: "GetMailboxSpecificRequestOptionsV2" */ './getMailboxSpecificRequestOptionsV2'
        )
);

export const lazyGetMailboxSpecificRequestOptionsV2 = new LazyAction(lazyModule, m => m.default);

export const lazyGetMailboxSpecificRequestOptionsFromMailboxInfo = new LazyAction(
    lazyModule,
    m => m.getMailboxSpecificRequestOptionsFromMailboxInfo
);
