import nativeadsUpsellEffectStore from '../store/nativeadsUpsellEffectStore';
import { mutatorAction } from 'satcheljs';

export const setNativeadsUpsellEffectBit = mutatorAction(
    'SetNativeadsUpsellEffectBit',
    (isEffectiveBit: boolean) => {
        // Set the isEffectiveBit bit to trigger the store change
        nativeadsUpsellEffectStore.isEffective = isEffectiveBit;
    }
);
