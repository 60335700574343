import {
    createLazyComponent,
    LazyModule,
    LazyAction,
    registerLazyOrchestrator,
} from 'owa-bundling';
import initializeNotificationsPaneForAccountAction from 'owa-app-notifications-core/lib/actions/initializeNotificationsPaneForAccountAction';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ActivityFeedAsync" */ './lazyIndex'),
    { name: 'ActivityFeedAsync' }
);

registerLazyOrchestrator(
    initializeNotificationsPaneForAccountAction,
    lazyModule,
    m => m.initializeNotificationsPaneForAccount
);

export const ActivityFeed = createLazyComponent(lazyModule, m => m.ActivityFeed);
export const lazyInitializeActivityFeed = new LazyAction(lazyModule, m => m.initializeActivityFeed);
export { ActivityType } from './service/ActivityFeedStorageItem';
export const ActivityFeedTitle = createLazyComponent(lazyModule, m => m.ActivityFeedTitle);
export const lazyLoadActivityFeed = new LazyAction(lazyModule, m => m.loadActivityFeed);
export const lazyGetUnseenItemsCount = new LazyAction(lazyModule, m => m.getUnseenItemsCount);
export { ImageType } from './utils/ImageType';
export const ImageAndMessage = createLazyComponent(lazyModule, m => m.ImageAndMessage);
