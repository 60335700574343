import { observer } from 'owa-mobx-react';
import type { FavoriteNodeCommonProps } from './FavoriteNode';
import DragAndDroppable from 'owa-dnd/lib/components/DragAndDroppable';
import type { DragData } from 'owa-dnd/lib/utils/dragDataUtil';
import { DraggableItemTypes } from 'owa-dnd/lib/utils/DraggableItemTypes';
import {
    GroupRightCharm,
    GroupRightCharmHover,
    GroupFolderRightCharm,
} from 'owa-group-left-nav-mail';
import { lazyDropMailListRowsOnGroup } from 'owa-group-mail-left-nav-actions';
import { GroupNode } from 'owa-groups-left-nav-view';
import {
    getFavoriteNodeViewStateFromId,
    lazyInitializeFavoriteNodeViewState,
} from 'owa-mail-favorites-store';
import { showFavoritesContextMenu } from 'owa-mail-favorites-store/lib/actions/favoritesContextMenu';
import {
    isGroupNodeSelected,
    lazySelectGroup,
    lazySelectGroupFolder,
} from 'owa-mail-folder-forest-actions';
import { DRAG_X_OFFSET, DRAG_Y_OFFSET, shouldRenderNodeInEditMode } from 'owa-mail-folder-view';
import { getAnchorForContextMenu } from 'owa-positioning';
import React from 'react';
import { getFavoriteIdFromGroupId } from 'owa-favorites';
import type { FavoriteNodeDragData } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import type { MailListRowDragData } from 'owa-mail-types/lib/types/MailListRowDragData';
import { PeopleCommunityRegular, PeopleCommunityFilled, bundleIcon } from '@fluentui/react-icons';
import type { MailboxInfo } from 'owa-client-types';
import {
    canDropFavorite,
    canDropFolder,
    onDropFolder,
    onDropFavorite,
} from '../util/dragDropUtils';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { dragPreview } from './FavoriteNode.scss';
import type DropEffect from 'owa-dnd/lib/store/schema/DropEffect';
import { getGroupViewState } from 'owa-groups-shared-folders-store/lib/selectors/getGroupViewState';
import { hasUserCreatedFolders } from 'owa-groups-shared-folders-store/lib/selectors/hasUserCreatedFolders';
import { getGroupFolderId } from 'owa-groups-shared-folders-store/lib/selectors/getGroupFolderId';
import { getGroupFolder } from 'owa-groups-shared-folders-store/lib/selectors/getGroupFolder';
import { type GroupFolderWellKnownName, FAVORITE_FOLDERS_TREE_TYPE } from 'owa-folders-constants';
import { GroupFolderTreeContainer } from 'owa-group-left-nav-folders-mail';
import { getFavoriteGroupViewState } from 'owa-groups-shared-favorites-view-store/lib/selectors/getFavoriteGroupViewState';
import toggleFavoriteGroupListNodeExpansion from 'owa-groups-shared-favorites-view-store/lib/actions/toggleFavoriteGroupListNodeExpansion';
import { lazyLoadGroupFolders } from 'owa-groups-shared-folders-actions';
import type { ChevronProps } from 'owa-tree-node/lib/components/TreeNode';
import { errorThatWillCauseAlert } from 'owa-trace';
import { isFeatureEnabled } from 'owa-feature-flags';

const PeopleCommunity = bundleIcon(PeopleCommunityFilled, PeopleCommunityRegular);

export interface FavoriteGroupNodeProps extends FavoriteNodeCommonProps {
    displayName: string;
    groupId: string;
    mailboxInfo: MailboxInfo;
    ellipsesOnHover?: boolean;
}

export default observer(function FavoriteGroupNode(props: FavoriteGroupNodeProps) {
    // Besides the isDragOver property in store, we also add this property here to distingush from the dropping on MailFolderNode
    const isDragOver = React.useRef<boolean>();

    React.useEffect(() => {
        lazyInitializeFavoriteNodeViewState.importAndExecute(props.favoriteId);
    }, []);

    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onContextMenu = (evt: React.MouseEvent<HTMLElement>) => {
        evt.stopPropagation();
        evt.preventDefault();
        showFavoritesContextMenu(props.favoriteId, 2, getAnchorForContextMenu(evt));
    };
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onDrop = async (
        dragData: DragData,
        _pageX: number,
        _pageY: number,
        _currentTarget?: HTMLElement,
        ctrlKey?: boolean
    ) => {
        const itemType = dragData.itemType;
        switch (itemType) {
            case DraggableItemTypes.MailFolderNode:
                await onDropFolder(dragData, props.mailboxInfo, props.favoriteId);
                break;
            case DraggableItemTypes.FavoriteNode:
                await onDropFavorite(dragData, props.favoriteId);
                break;
            case DraggableItemTypes.MultiMailListMessageRows:
            case DraggableItemTypes.MailListRow:
                lazyDropMailListRowsOnGroup.importAndExecute(
                    dragData as MailListRowDragData,
                    props.groupId,
                    ctrlKey
                );
                break;
        }
    };
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onDragOver = () => {
        isDragOver.current = true;
    };
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onDragLeave = () => {
        isDragOver.current = false;
    };
    const canDrop = React.useCallback(
        (dragData: DragData, dragEvent?: React.DragEvent<HTMLElement>): DropEffect => {
            const itemType = dragData.itemType;
            switch (itemType) {
                case DraggableItemTypes.FavoriteNode:
                    return canDropFavorite(dragData, props.mailboxInfo) ? 'move' : 'none';
                case DraggableItemTypes.MailFolderNode:
                    return canDropFolder(dragData, props.mailboxInfo) ? 'move' : 'none';
                case DraggableItemTypes.MailListRow:
                case DraggableItemTypes.MultiMailListMessageRows:
                case DraggableItemTypes.MultiMailListConversationRows:
                    const draggedItemsMailboxInfo = getModuleContextMailboxInfo();
                    // Drag and drop not allowed on Group Node from Group Mailboxes
                    if (draggedItemsMailboxInfo.type === 'GroupMailbox') {
                        return 'none';
                    }

                    // Drag and drop not allowed on Group Node across accounts
                    if (
                        getIndexerValueForMailboxInfo(draggedItemsMailboxInfo) !==
                        getIndexerValueForMailboxInfo(props.mailboxInfo)
                    ) {
                        return 'none';
                    }

                    return dragEvent?.ctrlKey ? 'copy' : 'move';
                default:
                    return 'none';
            }
        },
        [props.mailboxInfo]
    );

    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const getDragData = () => {
        const displayName = props.displayName;
        const groupNodeDragData: FavoriteNodeDragData = {
            itemType: DraggableItemTypes.FavoriteNode,
            favoriteId: props.favoriteId,
            favoriteType: 2,
            displayName,
            itemData: {
                mailboxInfo: props.mailboxInfo,
            },
        };
        return groupNodeDragData;
    };
    const favoriteId = getFavoriteIdFromGroupId(props.groupId);
    const viewState = getFavoriteNodeViewStateFromId(favoriteId);

    const renderRightCharm = React.useCallback(
        (groupId: string, _customData: any, nodeType: FolderForestNodeType, folderId?: string) => {
            switch (nodeType) {
                case 2: {
                    return !!getFavoriteGroupViewState(groupId)?.isExpanded ? null : (
                        <GroupRightCharm groupId={groupId} mailboxInfo={props.mailboxInfo} />
                    );
                }

                case 6: {
                    if (folderId) {
                        return (
                            <GroupFolderRightCharm
                                groupId={groupId}
                                folderId={folderId}
                                mailboxInfo={props.mailboxInfo}
                                treeType={FAVORITE_FOLDERS_TREE_TYPE}
                            />
                        );
                    } else {
                        errorThatWillCauseAlert(
                            'Folder Id not present in Group Folder Right Charm'
                        );
                        return null;
                    }
                }

                default: {
                    errorThatWillCauseAlert('Unexpected FolderForestNodeType in renderRightCharm');
                    return null;
                }
            }
        },
        [props.mailboxInfo]
    );

    const getGroupListNodeChevronProps = React.useCallback((): ChevronProps | undefined => {
        const onChevronClicked = (evt: React.MouseEvent<unknown> | KeyboardEvent) => {
            evt.stopPropagation();

            // Ensure folders are loaded.
            lazyLoadGroupFolders.importAndExecute(props.groupId, props.mailboxInfo).then(() => {
                toggleFavoriteGroupListNodeExpansion(props.groupId);
            });
        };

        if (shouldRenderGroupFolders(props.groupId, props.mailboxInfo)) {
            const isExpanded = !!getFavoriteGroupViewState(props.groupId)?.isExpanded;

            return { isExpanded, onClick: onChevronClicked };
        }

        return undefined;
    }, []);

    const renderRightCharmHover = React.useCallback(
        (groupId: string): JSX.Element => {
            return <GroupRightCharmHover groupId={groupId} mailboxInfo={props.mailboxInfo} />;
        },
        [props.mailboxInfo]
    );

    const isGroupNodeSelectedInternal = React.useCallback(
        (groupId: string): boolean => {
            return isGroupNodeSelected(groupId, 'favorites', props.mailboxInfo);
        },
        [props.mailboxInfo]
    );

    const renderGroupFolderChildren = React.useCallback((): JSX.Element | null => {
        const shouldRenderFolders =
            !!getFavoriteGroupViewState(props.groupId)?.isExpanded &&
            shouldRenderGroupFolders(props.groupId, props.mailboxInfo);

        if (shouldRenderFolders) {
            return (
                <GroupFolderTreeContainer
                    groupId={props.groupId}
                    mailboxInfo={props.mailboxInfo}
                    renderRightCharm={renderRightCharm}
                    treeType={FAVORITE_FOLDERS_TREE_TYPE}
                    ellipsesOnHover={props.ellipsesOnHover}
                />
            );
        }
        return null;
    }, [props.groupId, props.mailboxInfo]);

    const selectGroupInternal = React.useCallback(
        async (groupId: string) => {
            await lazySelectGroup.importAndExecute(
                groupId,
                FAVORITE_FOLDERS_TREE_TYPE,
                props.mailboxInfo
            );

            if (
                isFeatureEnabled('grp-loadFolders') &&
                isFeatureEnabled('grp-mailFavoritesGroupFoldersSupport') &&
                shouldRenderGroupFolders(groupId, props.mailboxInfo)
            ) {
                toggleFavoriteGroupListNodeExpansion(groupId);
                lazySelectGroupFolder.importAndExecute(
                    groupId,
                    'inbox',
                    FAVORITE_FOLDERS_TREE_TYPE,
                    props.mailboxInfo
                );
            }
        },
        [props.mailboxInfo]
    );

    if (!viewState?.drop) {
        return null;
    }

    return (
        <DragAndDroppable
            dragViewState={viewState.drag}
            getDragData={getDragData}
            getDragPreview={getDragPreview}
            xOffset={DRAG_X_OFFSET}
            yOffset={DRAG_Y_OFFSET}
            dropViewState={viewState.drop}
            onDrop={onDrop}
            canDrop={canDrop}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
        >
            <GroupNode
                selectGroup={selectGroupInternal}
                isSelected={isGroupNodeSelectedInternal}
                renderRightCharm={renderRightCharm}
                renderRightCharmHover={renderRightCharmHover}
                displayName={props.displayName}
                groupId={props.groupId}
                chevronProps={
                    isFeatureEnabled('grp-loadFolders') &&
                    isFeatureEnabled('grp-mailFavoritesGroupFoldersSupport')
                        ? getGroupListNodeChevronProps()
                        : undefined
                }
                customIconComponent={PeopleCommunity}
                isDroppedOver={viewState?.drop?.isDragOver && isDragOver.current}
                isBeingDragged={viewState?.drag?.isBeingDragged}
                key={props.groupId}
                onContextMenu={onContextMenu}
                showHoverStateOnDroppedOver={
                    viewState?.drop?.draggableItemType == DraggableItemTypes.MailListRow ||
                    viewState?.drop?.draggableItemType ==
                        DraggableItemTypes.MultiMailListMessageRows
                }
                isFavorited={true}
                mailboxInfo={props.mailboxInfo}
                ellipsesOnHover={props.ellipsesOnHover}
                shouldShowFocusBorder={true}
            />
            {isFeatureEnabled('grp-loadFolders') &&
                isFeatureEnabled('grp-mailFavoritesGroupFoldersSupport') &&
                renderGroupFolderChildren()}
        </DragAndDroppable>
    );
}, 'FavoriteGroupNode');

function shouldRenderGroupFolders(groupId: string, mailboxInfo: MailboxInfo): boolean {
    const deletedGroupFolder = getGroupFolder(
        groupId,
        getGroupFolderId(groupId, 'deleteditems', mailboxInfo),
        mailboxInfo
    );
    const areUserCreatedFoldersPresent = !!getGroupViewState(groupId, mailboxInfo)
        ?.areUserCreatedFoldersPresent;

    return (
        areUserCreatedFoldersPresent ||
        hasUserCreatedFolders(groupId, mailboxInfo) ||
        shouldRenderNodeInEditMode(
            getGroupFolderId(groupId, 'inbox', mailboxInfo),
            'new',
            groupId,
            FAVORITE_FOLDERS_TREE_TYPE,
            mailboxInfo
        ) ||
        !!deletedGroupFolder?.TotalItemCount
    );
}

function getDragPreview(groupNodeDragData: DragData) {
    const elem = document.createElement('div');
    elem.className = dragPreview;
    elem.innerText = (groupNodeDragData.itemData as FavoriteNodeDragData).displayName;
    return elem;
}
