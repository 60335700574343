import { addDays, isSameDay, isWithinRange, startOfDay, userDate } from 'owa-datetime';
import {
    formatUserDate,
    formatUserTime,
    formatWeekDayShortUserDate,
    formatWeekDayTime,
} from 'owa-datetime-formatters';
import { observableToday } from './selectors';
import type { OwaDate } from 'owa-datetime';

export default function formatRelativeDate(date: OwaDate | string | null | undefined) {
    if (!date) {
        return '';
    }

    const dateInUserTz = userDate(date);
    const formatter = getRelativeDateFormatter(dateInUserTz, observableToday());
    return formatter(dateInUserTz);
}

function getRelativeDateFormatter(date: OwaDate, today: OwaDate) {
    const day = startOfDay(date);

    if (isSameDay(day, today)) {
        return formatUserTime;
    }

    const isInLastNDays = (n: number) => isWithinRange(day, addDays(today, -n), today);

    if (isInLastNDays(3)) {
        return formatWeekDayTime;
    }

    if (isInLastNDays(30)) {
        return formatWeekDayShortUserDate;
    }

    return formatUserDate;
}
