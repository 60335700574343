import { mutatorAction } from 'satcheljs';
import { getStore } from '../store/store';

/**
 * Set the isSelected bit in the copilot theme store
 * @param status the theme copilot is selected bit
 */
export default mutatorAction('setIsCopilotThemeSelected', (isSelected: boolean) => {
    getStore().options.isSelected = isSelected;
});
