import getIsAppSearchEnabled from '../selectors/getIsAppSearchEnabled';
import { getCurrentModule } from 'owa-app-module-store';
import { Module } from 'owa-workloads';
import { isFeatureEnabled } from 'owa-feature-flags';

export function shouldUseAppHostSearch(): boolean {
    return (
        getIsAppSearchEnabled() &&
        getCurrentModule() === Module.AppHost &&
        isFeatureEnabled('sea-metaOSAppSearchInOWA')
    );
}
