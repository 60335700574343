import { DEFAULT_ZOOM } from 'owa-custom-zoom-store/lib/utils/constants';
import { owaComputedFn } from 'owa-computed-fn';

function getZoomStyling(currentZoomScale: number) {
    if (currentZoomScale === DEFAULT_ZOOM) {
        return undefined;
    }
    const zoomStyling = {
        transform: `scale(${currentZoomScale})`,
        transformOrigin: 'top left',
        height: `calc(100% / ${currentZoomScale})`,
        width: `calc(100% / ${currentZoomScale})`,
    };
    return zoomStyling;
}

export const getZoomStyle = owaComputedFn(getZoomStyling);
