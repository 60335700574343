import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "SuggestedUnsubscribe"*/ './lazyIndex')
);

// Init/Fetching
export const lazyCreateSuggestedUnsubscribeItems = new LazyAction(
    lazyModule,
    m => m.createSuggestedUnsubscribeItems
);

// Lazy actions
export const lazyRemoveSuggestedUnsubscribeItem = new LazyAction(
    lazyModule,
    m => m.removeSuggestedUnsubscribeItem
);

export const lazyUpdateSuggestedUnsubscribeAllowList = new LazyAction(
    lazyModule,
    m => m.updateSuggestedUnsubscribeAllowList
);

// Types
export type { default as SuggestedUnsubscribeItem } from './store/schema/SuggestedUnsubscribeItem';

// Selectors
export { default as getSuggestedUnsubscribeItem } from './selectors/getSuggestedUnsubscribeItem';
export { default as isSuggestedUnsubscribeItem } from './selectors/isSuggestedUnsubscribeItem';

// Utils
export { default as getSuggestedUnsubscribeReasonString } from './utils/getSuggestedUnsubscribeReasonString';
