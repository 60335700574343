import { getSelectedTableView, type TableQueryType } from 'owa-mail-list-store';
import { isFeatureEnabled } from 'owa-feature-flags';

export default function isGroupSelected(): boolean {
    const selectedTable = getSelectedTableView();
    return (
        selectedTable &&
        (selectedTable.tableQuery.type == 2 ||
            (isFeatureEnabled('grp-loadFolders') && selectedTable.tableQuery.type == 3))
    );
}
