import type { MailboxInfo } from 'owa-client-types';
import type { UserConfigurationArgs } from 'owa-graph-schema';
import { createUserConfigurationUpdateFunction } from './createUserConfigurationUpdateFunction';
import { updateAccountScopeUserSettings } from 'owa-session-store/lib/actions/updateAccountScopeUserSettings';
import { lazyUpdateUserConfigurationOperation } from '../lazyFunctions';

export function updateAccountScopeUserSettingsAndService(
    mailboxInfo: MailboxInfo,
    updates: UserConfigurationArgs,
    skipActionQueue?: boolean
): Promise<unknown> {
    updateAccountScopeUserSettings(mailboxInfo, createUserConfigurationUpdateFunction(updates));

    return lazyUpdateUserConfigurationOperation.importAndExecute(
        mailboxInfo,
        updates,
        skipActionQueue
    );
}
