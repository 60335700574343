import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { updateUserConfiguration } from 'owa-session-store/lib/actions/updateUserConfiguration';
export type FavoritesBitFlagsMasks = any;

/**
 * Gets a boolean to indicate if the n th bit is 1
 * @param bitMask represents the nth bit
 * @return true if nth bit is 1, false if 0
 */
export function getIsBitEnabled(bitMask: FavoritesBitFlagsMasks): boolean {
    const favoritesBitFlags = getUserConfiguration().UserOptions?.FavoritesBitFlags || 0;
    return !!(bitMask & favoritesBitFlags);
}

/**
 * Set the n th bit value in local user configuration to 1 or 0 depends on the value
 * @param value set to 1 if true, 0 otherwise
 * @param bitMask represents the nth bit
 */
export function setBit(value: boolean, bitMask: FavoritesBitFlagsMasks) {
    updateUserConfiguration(userConfig => {
        if (userConfig.UserOptions) {
            // Iniitalize the bitflag if it hasn't been used yet
            if (userConfig.UserOptions.FavoritesBitFlags == undefined) {
                userConfig.UserOptions.FavoritesBitFlags = 0;
            }
            if (value) {
                // Set the bit to 1
                userConfig.UserOptions.FavoritesBitFlags |= bitMask;
            } else {
                // Clear the bit to 0
                userConfig.UserOptions.FavoritesBitFlags &= ~bitMask;
            }
        }
    });
}
