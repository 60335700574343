import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';
import type {
    GroupFolderRulePermission,
    GroupFolderRulePermissionByMailboxInfo,
} from './schema/GroupFolderRulePermissionStore';

const store = createStore<GroupFolderRulePermissionByMailboxInfo>('GroupFolderRulePermission', {
    groupFolderRulePermissionByMailboxInfo: new ObservableMap<string, GroupFolderRulePermission>(),
})();

export const getGroupFolderRulePermissionStore = () => store;
