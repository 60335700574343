import type { PerformanceDatapointType } from 'owa-analytics-types';
import { getQueryStringParameter, hasQueryStringParameter } from 'owa-querystring';

const performanceMarkPrefix = 'owa-';

const selfDefined = typeof self !== 'undefined';
const performanceMarkEnabled = selfDefined && self.performance && !!self.performance.mark;
const performanceMarkQueryStringParam = 'pm';

const isPeformanceMarkEnabled = (dp: PerformanceDatapointType) => {
    if (!performanceMarkEnabled) {
        return false;
    }

    return (
        hasQueryStringParameter(performanceMarkQueryStringParam) &&
        (!hasQueryStringParameter(performanceMarkQueryStringParam) ||
            getEventName(dp)
                .toLowerCase()
                .indexOf(getQueryStringParameter(performanceMarkQueryStringParam).toLowerCase()) >
                -1)
    );
};

let nextSetId = 0;
export const addPerfomanceMarkToDatapoint = (
    dp: PerformanceDatapointType | undefined,
    mark: string
) => {
    if (!dp || !isPeformanceMarkEnabled(dp)) {
        return;
    }

    if (dp.markSetId === undefined) {
        dp.markSetId = nextSetId++;
    }

    performance.mark(getMarkName(dp, mark));
    if (dp.prevMark) {
        const measureName = getMeasureName(dp, dp.prevMark, mark);
        try {
            performance.measure(measureName, getMarkName(dp, dp.prevMark));
        } catch (err) {}
    }
    dp.prevMark = mark;
};

export const addPerformanceMarkToDevToolsOnly = (mark: string) => {
    if (performanceMarkEnabled) {
        performance.mark(`${performanceMarkPrefix}${mark}`);
    }
};

const getMarkName = (dp: PerformanceDatapointType, mark: string) =>
    `${performanceMarkPrefix}${getEventName(dp)}_${mark}_${dp.markSetId}`;

const getMeasureName = (dp: PerformanceDatapointType, prevMark: string, mark: string) =>
    `${getEventName(dp)}_${prevMark} to ${mark}`;

const getEventName = (dp: PerformanceDatapointType) => dp.getEventName?.();
