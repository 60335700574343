// For notification channel, we issue an LTI token, this needs to be requested from OWA BE.
// For image proxy, we issue an STI token, this needs to be requested from OWA BE.
// No need to pass the user token in request body as these requests are not OBO requests.

export function shouldFetchFromSTS(resource: string): boolean {
    const outlookGatewayRequest = new RegExp('outlookgatewayb2/.*/graphql');

    return (
        (resource && resource.toLowerCase().indexOf('/notificationchannel') > 0) ||
        resource.toLowerCase().indexOf('/outlookimageproxy.azurewebsites.net') > 0 ||
        outlookGatewayRequest.test(resource.toLowerCase())
    );
}
