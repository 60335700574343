// This file is autogenerated. Please don't modify it directly. See the README for details
import type ControlIcons from './ControlIcons';

export type ControlIconsSubset = {
    [icon in ControlIcons]: string;
};

export const ControlIconsDefinitions: ControlIconsSubset = {
    Accept: '\uE8FB',
    AccessibiltyChecker: '\uF835',
    Accounts: '\uE910',
    Add: '\uE710',
    AddEvent: '\uEEB5',
    AddField: '\uE4C7',
    AddIn: '\uF775',
    AddTo: '\uECC8',
    Airplane: '\uE709',
    AirplaneSolid: '\uEB4C',
    AlarmClock: '\uE919',
    AlertSolid: '\uF331',
    AllApps: '\uE71D',
    Archive: '\uF03F',
    Attach: '\uE723',
    Back: '\uE72B',
    BingLogo: '\uEB6B',
    BirthdayCake: '\uEF8D',
    BlockContact: '\uE8F8',
    Blocked: '\uE733',
    Blocked12: '\uF62E',
    BookingsLogo: '\uEDC7',
    BoxAdditionSolid: '\uF2D4',
    BoxLogo: '\uED75',
    BoxSubtractSolid: '\uF2D3',
    Broom: '\uEA99',
    BulletedList: '\uE8FD',
    Bullseye: '\uF272',
    BullseyeTarget: '\uF5F0',
    Calendar: '\uE787',
    CalendarDay: '\uE8BF',
    CalendarReply: '\uE8F5',
    CalendarSettings: '\uF558',
    CalendarWeek: '\uE8C0',
    CalendarWorkWeek: '\uEF51',
    Cancel: '\uE711',
    Car: '\uE804',
    CaretLeftSolid8: '\uEDD9',
    CaretRightSolid8: '\uEDDA',
    CellPhone: '\uE8EA',
    Chat: '\uE901',
    CheckMark: '\uE73E',
    ChevronDown: '\uE70D',
    ChevronDownMed: '\uE972',
    ChevronDownSmall: '\uE96E',
    ChevronLeft: '\uE76B',
    ChevronRight: '\uE76C',
    ChevronRightMed: '\uE974',
    ChevronRightSmall: '\uE970',
    ChevronUp: '\uE70E',
    ChevronUpMed: '\uE971',
    ChevronUpSmall: '\uE96D',
    ChromeClose: '\uE8BB',
    ChromeMinimize: '\uE921',
    CircleAddition: '\uF2E3',
    CircleAdditionSolid: '\uF2E4',
    CircleFill: '\uEA3B',
    CircleRing: '\uEA3A',
    CityNext: '\uEC06',
    Clear: '\uE894',
    ClearNight: '\uE9C2',
    ClearSelection: '\uE8E6',
    ClipboardList: '\uF0E3',
    Clock: '\uE917',
    ClosePane: '\uE89F',
    Cloud: '\uE753',
    CloudAdd: '\uECA9',
    CloudEdit: '\uE4C8',
    CloudUpload: '\uEC8E',
    CodeEdit: '\uF544',
    CollapseContent: '\uF165',
    Color: '\uE790',
    Comment: '\uE90A',
    CommentUrgent: '\uF307',
    Completed: '\uE930',
    CompletedSolid: '\uEC61',
    Contact: '\uE77B',
    ContactCard: '\uEEBD',
    ContactLock: '\uF400',
    Copy: '\uE8C8',
    DateTime: '\uEC92',
    DateTime2: '\uEA17',
    DeclineCall: '\uF405',
    Delete: '\uE74D',
    DeliveryTruck: '\uEBF4',
    DeveloperTools: '\uEC7A',
    Diamond: '\uED02',
    Dislike: '\uE8E0',
    Documentation: '\uEC17',
    DocumentSearch: '\uEF6C',
    Door: '\uEB75',
    DoubleChevronDown: '\uEE04',
    DoubleChevronUp: '\uEDBD',
    Down: '\uE74B',
    Download: '\uE896',
    Drop: '\uEB42',
    DropboxLogo: '\uED77',
    EaseOfAccess: '\uE776',
    EatDrink: '\uE807',
    Edit: '\uE70F',
    EditNote: '\uED9D',
    EditSolid12: '\uF4B5',
    EgnyteLogo: '\uF373',
    EMI: '\uE731',
    Emoji: '\uE899',
    Emoji2: '\uE76E',
    EmojiTabSymbols: '\uED58',
    Encryption: '\uF69D',
    EndPointSolid: '\uEB4B',
    Error: '\uE783',
    ErrorBadge: '\uEA39',
    Event: '\uECA3',
    EventAccepted: '\uF422',
    EventDeclined: '\uF425',
    EventTentative: '\uF423',
    EventToDoLogo: '\uF869',
    ExploreContent: '\uECCD',
    FabricFolder: '\uF0A9',
    FabricMovetoFolder: '\uF0A5',
    FacebookLogo: '\uECB3',
    FangBody: '\uECEB',
    FavoriteList: '\uE728',
    FavoriteStar: '\uE734',
    FavoriteStarFill: '\uE735',
    Fax: '\uEF5C',
    Feedback: '\uED15',
    FileASPX: '\uF2E9',
    FileImage: '\uF311',
    Filter: '\uE71C',
    Flag: '\uE7C1',
    Forward: '\uE72A',
    ForwardEvent: '\uED8C',
    Frigid: '\uE9CA',
    FullScreen: '\uE740',
    GIF: '\uF4A9',
    GiftCard: '\uEB8E',
    GlobalNavButton: '\uE700',
    Globe: '\uE774',
    Globe2: '\uF49A',
    GoogleDriveLogo: '\uEE0B',
    GotoToday: '\uE8D1',
    GripperDotsVertical: '\uF772',
    Group: '\uE902',
    Heart: '\uEB51',
    HeartBroken: '\uEA92',
    HeartFill: '\uEB52',
    Help: '\uE897',
    Hide3: '\uF6AC',
    Highlight: '\uE7E6',
    History: '\uE81C',
    Home: '\uE80F',
    Hotel: '\uE824',
    Important: '\uE8C9',
    Inbox: '\uF41C',
    InboxCheck: '\uEF64',
    Info: '\uE946',
    InsertSignatureLine: '\uF677',
    InsertTextBox: '\uEC7D',
    Insights: '\uE3AF',
    Label: '\uE932',
    Lightbulb: '\uEA80',
    LightningBolt: '\uE945',
    Like: '\uE8E1',
    LikeSolid: '\uF3BF',
    Link: '\uE71B',
    Link12: '\uF6E3',
    LinkedInLogo: '\uF20A',
    List: '\uEA37',
    Location: '\uE81D',
    LocationDot: '\uE827',
    Lock: '\uE72E',
    Mail: '\uE715',
    MailCheck: '\uED81',
    MailForward: '\uE89C',
    MailOptions: '\uF82C',
    MailPause: '\uED83',
    MailReminder: '\uF418',
    MailSolid: '\uF343',
    MapDirections: '\uE816',
    MapPin: '\uE707',
    MarkAsProtected: '\uF6AE',
    Megaphone: '\uE789',
    MicrosoftTranslatorLogo: '\uF782',
    MiniExpand: '\uE93A',
    MiniExpandMirrored: '\uEA5A',
    More: '\uE712',
    MoreSports: '\uEB22',
    MoreVertical: '\uF2BC',
    MSNVideos: '\uEB1C',
    NavigateExternalInline: '\uF35F',
    NewMail: '\uF7EA',
    NotePinned: '\uED9A',
    NumberField: '\uEDC4',
    NumberSymbol: '\uF7AC',
    OfficeAddinsLogo: '\uEEC7',
    OfficeChat: '\uF70F',
    OneDriveLogo: '\uE941',
    OneNoteLogo: '\uF1E7',
    OpenFile: '\uE8E5',
    OpenInNewTab: '\uF6AB',
    OpenInNewWindow: '\uE8A7',
    OpenPane: '\uE8A0',
    OutlookLogo: '\uF1E9',
    OutlookLogoInverse: '\uEB6D',
    OutlookSpacesBucket: '\uE481',
    OutOfOffice: '\uED34',
    Package: '\uE7B8',
    Page: '\uE7C3',
    PageEdit: '\uEFB6',
    PageLink: '\uE302',
    PageRemove: '\uEFBA',
    PartlyCloudyDay: '\uE9C0',
    PaymentCard: '\uE8C7',
    PDF: '\uEA90',
    PencilReply: '\uEF7B',
    People: '\uE716',
    PeopleAdd: '\uEA15',
    PeopleAlert: '\uED93',
    Phone: '\uE717',
    Photo2: '\uEB9F',
    PhotoBlock: '\uE4C3',
    PieSingle: '\uEB05',
    Pin: '\uE718',
    Pinned: '\uE840',
    PlainText: '\uF834',
    Play: '\uE768',
    POI: '\uECAF',
    Print: '\uE749',
    QRCode: '\uED14',
    Read: '\uE8C3',
    ReceiptReply: '\uEF5A',
    Recent: '\uE823',
    Refresh: '\uE72C',
    Remove: '\uE738',
    RemoveContent: '\uECC7',
    RemoveEvent: '\uED8A',
    RemoveLink: '\uED90',
    ReopenPages: '\uED50',
    Reply: '\uE97A',
    ReplyAll: '\uEE0A',
    ResponsesMenu: '\uF768',
    RevToggleKey: '\uE845',
    Ribbon: '\uE9D1',
    Ringer: '\uEA8F',
    RingerOff: '\uF2C5',
    Save: '\uE74E',
    ScheduleEventAction: '\uF1EF',
    ScrollUpDown: '\uEC8F',
    Search: '\uE721',
    SearchCalendar: '\uF4AF',
    Send: '\uE724',
    Settings: '\uE713',
    Share: '\uE72D',
    SharedNotes: '\uF481',
    ShowTimeAs: '\uF787',
    SkypeForBusinessLogo: '\uF0FC',
    SkypeForBusinessLogo16: '\uF40F',
    SkypeLogo: '\uEB6F',
    SkypeLogo16: '\uF40E',
    SquareShapeSolid: '\uF63D',
    Stack: '\uF26F',
    StatusCircleCheckmark: '\uF13E',
    StatusCircleErrorX: '\uF13D',
    Stopwatch: '\uE916',
    Sunny: '\uE9BD',
    SyncOccurence: '\uF4A3',
    System: '\uE770',
    Tab: '\uE7E9',
    TabCenter: '\uF100',
    Table: '\uED86',
    Tag: '\uE8EC',
    Taskboard: '\uF1C2',
    TeamsLogo: '\uF27B',
    Telemarketer: '\uE7B9',
    TextDocumentShared: '\uF02B',
    Ticket: '\uEB54',
    ToDoLogoOutline: '\uF75B',
    TVMonitor: '\uE7F4',
    Undo: '\uE7A7',
    Unlock: '\uE785',
    Up: '\uE74A',
    Upload: '\uE898',
    Video: '\uE714',
    VideoAdd: '\uE430',
    View: '\uE890',
    ViewAll: '\uE8A9',
    VoicemailForward: '\uED87',
    VoicemailIRM: '\uF421',
    VoicemailReply: '\uED88',
    Volume2: '\uE994',
    Waffle: '\uED89',
    Warning: '\uE7BA',
    WebPublish: '\uF52F',
    Website: '\uEB41',
    WindowEdit: '\uF50E',
    WipePhone: '\uED8D',
    World: '\uE909',
    WorldClock: '\uE918',
    YammerLogo: '\uED19',
    ZoomIn: '\uE8A3',
    ZoomOut: '\uE71F',
};

export default {
    fontFileName: 'controlIcons',
    icons: ControlIconsDefinitions,
};
