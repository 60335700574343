import { mutatorAction } from 'satcheljs';
import { updateFolderViewState } from './folderViewStatesActions';
import getFolderViewStateFromId from '../selectors/getFolderViewStateFromId';
import { setFolderViewStateInner } from '../actions/setFolderViewState';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Expands or collapses the folder
 * @param folderId - unique identifier id for a folder
 * @param doNotPersistUserConfig - should user config be updated or not
 */
export default mutatorAction(
    'toggleFolderNodeExpansion',
    function (
        folderId: string,
        _mailboxInfo?: MailboxInfo,
        doNotPersistUserConfig?: boolean
    ): void {
        const localViewState = getFolderViewStateFromId(folderId);
        localViewState.isExpanded = !localViewState.isExpanded;
        setFolderViewStateInner(folderId, localViewState);

        // User config is not persistent for Archive mailbox, hence we need not call this action in that case
        if (!doNotPersistUserConfig) {
            updateFolderViewState(folderId, undefined, true /* skipMutator */);
        }
    }
);
