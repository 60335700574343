import { isFeatureEnabled } from 'owa-feature-flags';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';

export default function isGetQrConnectEnabled(): boolean {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (9,5): Type 'boolean | undefined' is not assignable to type 'boolean'.
    // @ts-expect-error
    return (
        (isFeatureEnabled('rp-emptyStateQrConnectExp1') ||
            isFeatureEnabled('rp-emptyStateQrConnectExp2')) &&
        (isConsumer() || getUserConfiguration().UserOptions?.MobileAppEducationEnabled)
    );
}
