import { ObservableMap } from 'mobx';
import { getFavoriteListNodesViewStateStore } from '../store/groupFavoriteListNodesViewStateStore';
import type {
    GroupListNodesViewStateStore,
    GroupFolderTreeViewState,
} from 'owa-groups-shared-folders-store';

export function getFavoriteGroupsListNodeViewState(): GroupListNodesViewStateStore {
    let listNodesViewStates = getFavoriteListNodesViewStateStore().groupListNodesViewStates;

    if (!listNodesViewStates) {
        listNodesViewStates = new ObservableMap<string, GroupFolderTreeViewState>();
    }

    return getFavoriteListNodesViewStateStore();
}
