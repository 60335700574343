import type { HoverActionKey } from 'owa-outlook-service-option-store';
// Determines which big hover action will be shown on the right side of the mail item. We will either show Delete or Archive.
// Delete takes precedence over Archive so if we find a delete we can return early.
export function getBigHoverAction(actions: HoverActionKey[]): HoverActionKey {
    if (!actions) {
        return 'None';
    }
    let bigAction: HoverActionKey = 'None';
    for (const action of actions) {
        if (action == 'Delete') {
            return action;
        }
        if (action == 'Archive') {
            bigAction = action;
        }
    }
    return bigAction;
}
