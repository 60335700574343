import type AccessIssue from '../schema/AccessIssue';
import getAttachmentPolicy from 'owa-session-store/lib/utils/getAttachmentPolicy';
import type { MailboxInfo } from 'owa-client-types';
import { errorThatWillCauseAlert } from 'owa-trace';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { isLocalDataBackedMailboxInfo } from 'owa-account-source-list/lib/utils/isLocalDataBackedAccount';

/**
 * This function is used only for conditional access mail tip.
 * For functionality we use getCombinedAccessIssue, as it capture both attachmnets policy and conditional access
 * Supported conditional access for attachments:
 * 1) None: No conditional access policy is applied
 * 2) ReadOnly: Users can't download attachments to their local computer, and can't enable Offline Mode on non-compliant computers. They can still view attachments in the browser.
 * 3) ReadOnlyPlusAttachmentsBlocked: All restrictions from ReadOnly apply, but users can't view attachments in the browser.
 */

export default function getConditionalAccessIssue(mailboxInfo: MailboxInfo): AccessIssue[] {
    let attachmentPolicy = undefined;
    if (isLocalDataBackedMailboxInfo(mailboxInfo)) {
        // If this is PST, use the primary mailbox's attachment policy.
        // Don't check for PST in getAttachmentPolicy because it depends on which policy setting we are looking at.
        attachmentPolicy = getAttachmentPolicy(getGlobalSettingsAccountMailboxInfo());
    } else {
        attachmentPolicy = getAttachmentPolicy(mailboxInfo);
    }

    if (!attachmentPolicy) {
        errorThatWillCauseAlert('attachmentPolicy is undefined in getConditionalAccessIssue');
        return [];
    }

    if (
        attachmentPolicy.ConditionalAccessDirectFileAccessOnPrivateComputersBlocked ||
        attachmentPolicy.ConditionalAccessDirectFileAccessOnPublicComputersBlocked
    ) {
        if (
            attachmentPolicy.ConditionalAccessWacViewingOnPrivateComputersBlocked ||
            attachmentPolicy.ConditionalAccessWacViewingOnPublicComputersBlocked
        ) {
            return [1];
        } else {
            return [0];
        }
    }
    return [];
}
