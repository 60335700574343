import { OcpsPolicy, isOcpsPolicyEnabled } from 'owa-ocps-policy-store';
import { isPrivacyRoamingEnabled } from '../utils/isPrivacyRoamingEnabled';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export const isOptionalExperiencesAllowedByPolicy = () =>
    isPrivacyRoamingEnabled()
        ? isOcpsPolicyEnabled(
              OcpsPolicy.OptionalConnectedExperiences,
              true /* enabledIfUndefined */,
              getGlobalSettingsAccountMailboxInfo()
          )
        : true;
