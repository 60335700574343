import isFeatureEnabled from 'owa-feature-flags/lib/utils/isFeatureEnabled';
import type { MailboxInfo } from 'owa-client-types';

type Version = 'A' | 'B';

/**
 * Function name: isGroupsSwitchEnabled
 * Description: Returns true if the feature is enabled but not consumer and the account is primary.
 * @param mailboxInfo: MailboxInfo
 */
export default function isGroupsSwitchEnabled(mailboxInfo?: MailboxInfo, version?: Version) {
    const versionA = isFeatureEnabled('grp-groupsApp-groupSwitchA', mailboxInfo);
    const versionB = isFeatureEnabled('grp-groupsApp-groupSwitchB', mailboxInfo);

    switch (version) {
        case 'A':
            return versionA;
        case 'B':
            return versionB;
        case undefined:
            return versionA || versionB;
        default:
    }
    return false;
}
