import { isReportItem } from './checkItemType';
import getItemRightsManagementRestrictions from 'owa-mail-store/lib/utils/getItemRightsManagementRestrictions';
import type Item from 'owa-service/lib/contract/Item';
import isSmimeResponseDisabled from 'owa-smime/lib/utils/isSmimeResponseDisabled';
import doesFolderIdEqualName from 'owa-session-store/lib/utils/doesFolderIdEqualName';

export default function checkItemReplyForwardDisabled(
    item: Item,
    isConversationItemPart: boolean
): boolean[] {
    const irmRestrictions = getItemRightsManagementRestrictions(item);
    const smimeResponseDisabled = isSmimeResponseDisabled(item, isConversationItemPart);
    const isReport = isReportItem(item);
    const isJunk =
        !!item?.ParentFolderId?.Id && doesFolderIdEqualName(item.ParentFolderId.Id, 'junkemail');
    const isDraft = !!item?.IsDraft;
    const isOutbox =
        !!item?.ParentFolderId?.Id && doesFolderIdEqualName(item.ParentFolderId.Id, 'outbox');

    const isReplyDisabled =
        !irmRestrictions.ReplyAllowed ||
        isReport ||
        smimeResponseDisabled ||
        isJunk ||
        isDraft ||
        isOutbox;
    const isReplyAllDisabled =
        !irmRestrictions.ReplyAllAllowed ||
        isReport ||
        smimeResponseDisabled ||
        isJunk ||
        isDraft ||
        isOutbox;
    const isForwardDisabled = !irmRestrictions.ForwardAllowed || smimeResponseDisabled || isOutbox;

    return [isReplyDisabled, isReplyAllDisabled, isForwardDisabled];
}
