/**
 * Splits a possible display name + address combination into an address string.
 * Expects display / address strings to be in format
 * "Display Name (address>)"
 * "Display Name <address>"
 * "Display Name [address]"
 * "Display Name {address}"
 * "Display Name email@address"
 *
 * @param parseString the input string to parse
 */
export default function getDisplayNameAndAddressFromRecipientString(
    parseString: string | null | undefined
): {
    address: string;
    displayName?: string;
} {
    const inputString = parseString?.trim();
    if (!inputString) {
        return { address: '' };
    }

    const lastCharacter = inputString[inputString.length - 1];
    let result;
    if (lastCharacter == ')' && (result = findMatchingAndReturnDisplayAddress(inputString, '('))) {
        return result;
    }
    if (lastCharacter == '>' && (result = findMatchingAndReturnDisplayAddress(inputString, '<'))) {
        return result;
    }
    if (lastCharacter == ']' && (result = findMatchingAndReturnDisplayAddress(inputString, '['))) {
        return result;
    }
    if (lastCharacter == '}' && (result = findMatchingAndReturnDisplayAddress(inputString, '{'))) {
        return result;
    }

    result = findMatchingAndReturnDisplayAddress(inputString, ' ');
    // Add back last character
    if (result) {
        result.address = result.address.concat(lastCharacter);
    }
    const parts = result?.address.split('@');
    const isPotentialAddress = parts?.length == 2 && parts[0].length > 0 && parts[1].length > 0;
    return result && isPotentialAddress ? result : { address: inputString };
}

function findMatchingAndReturnDisplayAddress(inputString: string, matchCharacter: string) {
    const lastIndex = inputString.lastIndexOf(matchCharacter);
    // No match
    if (lastIndex < 0) {
        return undefined;
    }
    let displayName = inputString.substring(0, lastIndex).trimEnd();
    // Remove quotes around display name
    if (displayName[0] == '"' && displayName[displayName.length - 1] == '"') {
        displayName = displayName.substring(1, displayName.length - 1);
    }
    // Get address between match characters
    const address = inputString.substring(lastIndex + 1, inputString.length - 1);
    return { displayName: displayName || undefined, address };
}
