import { getFolderPaneWidth } from './getFolderPaneWidth';
import { getLeftNavMaxWidth } from './getMaxWidths';

/**
 * Gets the current left navigation (folder) pane width.
 * This width is used to set the width on the div, so the value of this can be 0 depending on if the
 * components are being shown or hidden
 * @returns width in pixels
 */
export function getLeftNavWidth(): number {
    const totalWidth = getFolderPaneWidth();
    const leftNavMaxWidth = getLeftNavMaxWidth();

    // If the current stored width is > than the MAX width allowed for current window size
    // use the MAX width
    if (totalWidth > leftNavMaxWidth) {
        return leftNavMaxWidth;
    }

    return totalWidth;
}
