import { getJsonRequestHeader } from 'owa-service/lib/ServiceRequestUtils';
import getFolderOperation from 'owa-service/lib/operation/getFolderOperation';
import type FolderInfoResponseMessage from 'owa-service/lib/contract/FolderInfoResponseMessage';
import folderId from 'owa-service/lib/factory/folderId';
import getFolderRequest from 'owa-service/lib/factory/getFolderRequest';
import folderResponseShape from 'owa-service/lib/factory/folderResponseShape';
import type BaseFolderId from 'owa-service/lib/contract/BaseFolderId';
import type ArrayOfResponseMessages from 'owa-service/lib/contract/ArrayOfResponseMessages';
import propertyUri from 'owa-service/lib/factory/propertyUri';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import type { MailboxInfo } from 'owa-client-types';

export default function getPublicFolders(
    targetFolderId: string[],
    mailboxInfo: MailboxInfo
): Promise<ArrayOfResponseMessages<FolderInfoResponseMessage> | null | undefined> {
    if (targetFolderId.length === 0) {
        return Promise.resolve(undefined);
    }

    const folderIds: BaseFolderId[] = targetFolderId.map(targetFolderids => {
        return folderId({ Id: targetFolderids });
    });

    return getFolderOperation(
        {
            Header: getJsonRequestHeader(),
            Body: getFolderRequest({
                FolderShape: folderResponseShape({
                    BaseShape: 'IdOnly',
                    AdditionalProperties: [propertyUri({ FieldURI: 'ReplicaList' })],
                }),
                ShapeName: 'Folder',
                FolderIds: folderIds,
            }),
        },
        getMailboxRequestOptions(mailboxInfo) //requestOptions
    )
        .then(response => {
            return response.Body.ResponseMessages;
        })
        .catch(() => {
            return null;
        });
}
