import type { ActionId } from 'owa-mail-list-item-animation-store';

export function getAnimationTiming(actionId: ActionId | null): KeyframeAnimationOptions {
    switch (actionId) {
        case 'PinInitial':
        case 'PinFinal':
        case 'UnpinInitial':
        case 'UnpinFinal':
            return { duration: 183, easing: 'cubic-bezier(0.4, 0, 0.2, 1)', fill: 'forwards' };
        default:
            return {
                duration: 250,
                easing: 'cubic-bezier(0.17, 0.17, 0.2, 1)',
                fill: 'forwards',
            };
    }
}
