import addRowResponse from './addRowResponse';
import { isItemSortKeyEqual } from './helpers/isSortKeyEqual';
import { addMailListLog, getMailListLogObjectToAddToStore } from 'owa-mail-list-logging';
import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';
import mailStore from 'owa-mail-store/lib/store/Store';
import type { ItemRow } from 'owa-graph-schema';
import { type TableQueryType } from 'owa-mail-list-store';
import type Item from 'owa-service/lib/contract/Item';

export default function prependItemWithSeekToConditionResponse(
    tableView: TableView,
    itemsToPrepend: ItemRow[],
    itemsInView: number
) {
    // Server did not return anything
    if (!itemsToPrepend || itemsToPrepend.length == 0) {
        return;
    }

    // The last item would be the first item from the previous FindItem response.
    // We check if we have that item and do a sort key comparision
    const itemIdUsedForSeekConditionRequest = itemsToPrepend[itemsToPrepend.length - 1].ItemId.Id;
    const item = mailStore.items.get(itemIdUsedForSeekConditionRequest);
    if (
        !item ||
        (tableView.tableQuery.type != 1 && // For find item search, we do not set the sortBy property in tableQuery
            !isItemSortKeyEqual(
                tableView.tableQuery,
                item,
                itemsToPrepend[itemsToPrepend.length - 1] as Item
            ))
    ) {
        // Do not process the response if the last item which was used as an instance key
        // to get the next 'n' items has
        // 1) moved in place in the table
        // Because at this point the state on the server might have changed a lot e.g. new rows might have got
        // added after this item in question and we would end up prepending the new rows before the existing rows if we
        // proceed and hence we discard.
        // 2) Or the row has been deleted on client in which case we do not have anythign to compare to.
        // In both above cases we would rely on the row notifications/ or user scrolling to get more items.
        addMailListLog(
            getMailListLogObjectToAddToStore('PrependItemWithSeekToCondition:Aborted', {
                tableViewId: tableView.id,
                referenceItemId: itemIdUsedForSeekConditionRequest,
                missing: !mailStore.items.has(itemIdUsedForSeekConditionRequest),
            })
        );
        return;
    }

    addRowResponse(
        tableView,
        itemsToPrepend,
        itemsInView,
        'PrependOnLoadMore',
        undefined,
        undefined,
        undefined,
        'PreviousPage'
    );
}
