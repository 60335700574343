import type { AccountProviderType } from '../store/schema/OWAConnectedAccount';

const Office365Provider: AccountProviderType = 'Office365';
const OutllookProvider: AccountProviderType = 'Outlook';

/**
 * Determines if the account provider is a first party account
 * @param accountProvider Specifies the account provider to be checked
 * @returns True if the account provider is a first party account, false otherwise
 */
export function isFirstPartyAccount(accountProvider: AccountProviderType): boolean {
    return accountProvider === Office365Provider || accountProvider === OutllookProvider;
}
