export const irm_featureDisabled = "VSjJEe";
export const irm_corruptProtectedMessage = "ByuHOb";
export const irm_viewRightNotGranted = "UXgnLb";
export const irm_serverMisConfigured = "rP2lKd";
export const irm_reachNotConfigured = "FxGJ4c";
export const irm_preLicensingFailure = "CKxYw";
export const irm_externalLicensingDisabled = "QVQdge";
export const irm_useLicenseAcquisitionFailed = "zQE4x";
export const irm_rmsErrorCode = "gB_Pn";
export const irm_outlookTrialDownloadUrl = "ZyRFnd";
export const irm_microsoftOfficeOutlook = "GDcsLd";
export const irm_downloadOutlookFreeTrialHtml = "VQKENe";
export default {irm_featureDisabled,irm_corruptProtectedMessage,irm_viewRightNotGranted,irm_serverMisConfigured,irm_reachNotConfigured,irm_preLicensingFailure,irm_externalLicensingDisabled,irm_useLicenseAcquisitionFailed,irm_rmsErrorCode,irm_outlookTrialDownloadUrl,irm_microsoftOfficeOutlook,irm_downloadOutlookFreeTrialHtml};