import { action } from 'satcheljs';
import type { StateChangeSource } from 'owa-left-pane-layout/lib/types/StateChangeSource';
export const setShowFolderPane = action(
    'setShowFolderPane',
    function setShowFolderPane(showFolderPane: boolean, stateChangeSource?: StateChangeSource) {
        return {
            showFolderPane,
            stateChangeSource,
        };
    }
);
