export const flagUnflag_Button = "g3VWWb";
export const flagUnflag_Button_MLR = "e4u0Tb";
export const flagCompleteToday_Button = "xTUEHe";
export const flagCompleteTomorrow_Button = "puqAUe";
export const flagCompleteThisWeek_Button = "gUWhW";
export const flagCompleteNextWeek_Button = "ND79Ie";
export const flagCompleteCustom_Button = "nv50Ac";
export const flagCompleteNoDate_Button = "NrLrg";
export const flagMarkComplete_Button = "xmyvsb";
export const flagClearFlag_Button = "CSfWC";
export default {flagUnflag_Button,flagUnflag_Button_MLR,flagCompleteToday_Button,flagCompleteTomorrow_Button,flagCompleteThisWeek_Button,flagCompleteNextWeek_Button,flagCompleteCustom_Button,flagCompleteNoDate_Button,flagMarkComplete_Button,flagClearFlag_Button};