import type { ApplicationSettings } from '../store/schema/ApplicationSettings';
import type { ApplicationSettingsError, ApplicationSettingsReport } from 'owa-analytics-types';

export const generateApplicationSettingsReport = (
    receivedSettings: ApplicationSettings,
    receivedSettingsType: string,
    filters: string,
    lastError?: ApplicationSettingsError
): ApplicationSettingsReport<ApplicationSettings> => {
    const report = {} as ApplicationSettingsReport<ApplicationSettings>;
    if (receivedSettingsType) {
        report.Type = receivedSettingsType;
    }

    report.Settings = receivedSettings;
    report.Filters = filters;

    if (lastError) {
        report.LastError = lastError;
    }

    return report;
};

export const getNoSettingsReport = (settingsError: ApplicationSettingsError) => ({
    NoSettings: true,
    LastError: settingsError,
});
