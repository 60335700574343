import React from 'react';
import type { WhyDidYouRenderOptions } from '@welldone-software/why-did-you-render';
import { hasQueryStringParameter, getQueryStringParameter } from 'owa-querystring';

// WIKI for instructions on how to use it.
// https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/3213/Why-Did-You-Render-Query-String-Parameter

let waitForReact: Promise<void> | undefined;

const TRACK_PERF_QSP = 'trackRender';
if (hasQueryStringParameter(TRACK_PERF_QSP)) {
    const componentsToTrack = getQueryStringParameter(TRACK_PERF_QSP);
    const options: WhyDidYouRenderOptions = componentsToTrack
        ? {
              include: componentsToTrack.split(',').map(c => new RegExp(c)),
          }
        : {
              trackAllPureComponents: true,
          };
    options.collapseGroups = hasQueryStringParameter('trackRenderCollapseGroups');
    options.trackHooks = !hasQueryStringParameter('trackRenderSkipHooks');
    options.logOnDifferentValues = hasQueryStringParameter('trackRenderLogOnDifferentValues');
    waitForReact = import(
        /* webpackChunkName: "WhyDidYouRender" */ '@welldone-software/why-did-you-render'
    ).then(whyDidYouRender => {
        whyDidYouRender.default(React, options);
    });
}

export function getWaitForReact() {
    return waitForReact;
}
