export const important = "UUeYae";
export const hasAttachments = "GHpbw";
export const meeting = "jRIpg";
export const meetingStartsAt = "bn44wb";
export const meetingAccepted = "wc_B1c";
export const meetingDeclined = "htVrLd";
export const meetingTentative = "ta7yKc";
export const meetingCanceled = "GEM93c";
export const conversationCollapsed = "sJVvze";
export const conversationExpanded = "akpeKe";
export const copilotHighPriority = "Ms0hDc";
export const copilotLowPriority = "as1gZc";
export default {important,hasAttachments,meeting,meetingStartsAt,meetingAccepted,meetingDeclined,meetingTentative,meetingCanceled,conversationCollapsed,conversationExpanded,copilotHighPriority,copilotLowPriority};