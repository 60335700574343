import isConditionalFormattingConditionSatisfied from './isConditionalFormattingConditionSatisfied';

import type {
    ConditionalFormattingModification,
    ConditionalFormattingRule,
} from 'owa-conditional-formatting';

export default function getConditionalFormattingModificationsToApplyOnRow(
    conditionalFormattingRules: ConditionalFormattingRule[],
    subject: string,
    messageToMe: boolean | undefined | null,
    messageCcMe: boolean | undefined | null,
    senderEmailAddress: string
): ConditionalFormattingModification[] {
    const rowModifications: ConditionalFormattingModification[] = [];

    // Loop through all of the CF rules to evaluate their conditions
    for (const cfRule of conditionalFormattingRules) {
        if (cfRule?.isEnabled) {
            let allConditionsSatisfied = true;

            // Loop through all of the conditions of a CF rule. All conditions must be satisfied for a rule's modifications to be applied
            for (const condition of cfRule.conditions) {
                if (
                    !isConditionalFormattingConditionSatisfied(
                        condition,
                        subject,
                        messageToMe,
                        messageCcMe,
                        senderEmailAddress
                    )
                ) {
                    allConditionsSatisfied = false;
                    break;
                }
            }

            // If all conditions are satisfied for a given rule, we stop processing other rules and return the modifications array for the rule.
            // This will work for now while we only support one ConditionalFormattingModificationType.
            if (allConditionsSatisfied) {
                rowModifications.push(...cfRule.modifications);
                break;
            }
        }
    }

    return rowModifications;
}
