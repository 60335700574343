import type { MailboxInfo } from 'owa-client-types';
import tryRemoveFromMailStoreItems from 'owa-mail-actions/lib/triage/tryRemoveFromMailStoreItems';
import getAllTableViewsContainingItems from 'owa-mail-triage-table-utils/lib/getAllTableViewsContainingItems';
import { getRowKeysFromRowIds, isConversationView } from 'owa-mail-list-store';
import store from 'owa-mail-store/lib/store/Store';
import { mutator } from 'satcheljs';
import {
    isItemHeldByConversationItemParts,
    isItemHeldByItemReadingPane,
} from 'owa-mail-reading-pane-store';

export default mutator(tryRemoveFromMailStoreItems, actionMessage => {
    const itemId = actionMessage.itemIdToRemove;
    const item = store.items.get(itemId);

    // If the item has already been removed, NOOP
    // Note this can happen right now because reading pane code calls items.delete(itemId) multiple times for the same delete
    // from deleteConversationItemParts and cleanUpItemClientSide.
    if (!item) {
        return;
    }

    const parentConversationId = item.ConversationId?.Id;

    if (
        isItemHeldByConversationItemParts(parentConversationId, actionMessage.removeItemSource) ||
        isItemHeldByItemReadingPane(itemId, actionMessage.removeItemSource) ||
        isItemHeldByListViewTable(itemId, item.MailboxInfo, item.ParentFolderId?.Id)
    ) {
        // The item reference is currently held, we cannot remove it at this time
        return;
    }

    // Delete the item since it's no longer referenced
    store.items.delete(itemId);
});

function isItemHeldByListViewTable(
    itemId: string,
    mailboxInfo: MailboxInfo,
    parentFolderId: string | undefined
): boolean {
    if (!parentFolderId) {
        return false;
    }

    // Don't check removeItemSource here. Even if it's from a listview table,
    // another listview table may be holding on to this item

    const tableViewsForFolder = getAllTableViewsContainingItems(
        mailboxInfo,
        parentFolderId,
        true /* shouldIncludeSearchTable */
    );

    for (const tableView of tableViewsForFolder) {
        if (isConversationView(tableView)) {
            // Only message listviews can hold references to message items
            continue;
        }

        const rowKeys = getRowKeysFromRowIds([itemId], tableView);
        for (const rowKey of rowKeys) {
            if (tableView.rowKeys.indexOf(rowKey) >= 0) {
                return true;
            }
        }
    }

    return false;
}
