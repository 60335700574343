import { lazyGetReadingPaneOptionsSections } from 'owa-mail-ribbon-sections/lib/sections';

import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onReadingPaneOptionsMenuClicked = () => {
    lazyGetReadingPaneOptionsSections.import().then(getReadingPaneOptions => {
        setMenuDefinition(
            'ReadingPaneOptionsMenuDefinitionStore',
            undefined /* projectionTabId */,
            () => {
                return {
                    sections: getReadingPaneOptions(),
                };
            }
        );
    });
};
