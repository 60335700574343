import { getAccountScopeUserSettings } from 'owa-session-store';
import type { MailboxInfo } from 'owa-client-types';
import type ReportSubmissionPolicy from '../types/ReportSubmissionPolicy';

/**
 * Gets the report submission policy from the PrimeSettings property (returned
 * as part of startupdata for primary accounts and post-boot fetches to BootSettings
 * for secondary accounts)
 */
export default function getReportSubmissionPolicyFromPrimeSettings(
    mailboxInfo: MailboxInfo
): ReportSubmissionPolicy | undefined {
    const primeSettingsItems = getAccountScopeUserSettings(mailboxInfo)?.PrimeSettings?.Items;

    const reportSubmissionPolicy: ReportSubmissionPolicy | undefined = primeSettingsItems
        ?.filter(item => item?.Id === 'ReportSubmissionPolicy')
        .map(item => parseReportSubmissionPolicyFromPrime(item?.Value))?.[0];

    return reportSubmissionPolicy;
}

/**
 * Parses the server response into the expected client type (or undefined if
 * unavailable)
 */
export function parseReportSubmissionPolicyFromPrime(
    reportSubmissionPolicyFromServer: any
): ReportSubmissionPolicy | undefined {
    if (!reportSubmissionPolicyFromServer) {
        return undefined;
    }

    return {
        EnableReportToMicrosoft: reportSubmissionPolicyFromServer.EnableReportToMicrosoft,
        PreSubmitMessageEnabled: reportSubmissionPolicyFromServer.PreSubmitMessageEnabled,
        PostSubmitMessageEnabled: reportSubmissionPolicyFromServer.PostSubmitMessageEnabled,
        ReportJunkToCustomizedAddress:
            reportSubmissionPolicyFromServer.ReportJunkToCustomizedAddress,
        ReportNotJunkToCustomizedAddress:
            reportSubmissionPolicyFromServer.ReportNotJunkToCustomizedAddress,
        ReportPhishToCustomizedAddress:
            reportSubmissionPolicyFromServer.ReportPhishToCustomizedAddress,
    };
}
