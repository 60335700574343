// Effectively we're treating the window title as a UI element here; however,
// there's no way to put `observer()` on the window title, so we need to use
// autorun() to observe and react to the state change.
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline exception migrated from TSLint without explicit justification
 *	> 'autorun' import from 'mobx' is restricted. */
import { autorun } from 'owa-mobx-react';

export default function initializeWindowTitle(getWindowTitle: () => string) {
    autorun('initializeWindowTitle', () => {
        document.title = getWindowTitle();
    });
}
