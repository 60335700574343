import type { MailboxInfo } from 'owa-client-types';
import type { TabNameType } from 'owa-mail-ribbon-store-shared-types';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { mutatorAction } from 'satcheljs';
import { getTabControls } from '../selectors/getTabControls';
import { getMailRibbonConfigStore } from '../store/store';

// These controls are special for a variety of reasons and never go into the overflow.
const disallowedFromOverflow: RibbonControlId[] = [587, 683, 684];

export const setTabControlsInOverflow = mutatorAction(
    'setTabControlsInOverflow',
    (mailboxInfo: MailboxInfo, tabName: TabNameType, controlsInOverflow: RibbonControlId[]) => {
        const ribbonViewConfig = getMailRibbonConfigStore(mailboxInfo).singleline;
        const tabConfig = ribbonViewConfig[tabName];

        const tabControls = getTabControls(mailboxInfo, tabName);
        const verifiedOverflowControls = controlsInOverflow.filter(
            control => tabControls.includes(control) && !disallowedFromOverflow.includes(control)
        );

        tabConfig.controlsInOverflow = verifiedOverflowControls;
    }
);
