import { action } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
const closeAddinPane = action(
    'closeAddinPane',
    (mailboxInfo: MailboxInfo, targetWindow: Window, isNewFlexPaneOpened: boolean = false) => ({
        mailboxInfo,
        targetWindow,
        isNewFlexPaneOpened,
    })
);
export default closeAddinPane;
