import { createStore } from 'satcheljs';
import type { MailboxJunkEmailConfigurationStateMap } from './schema/MailboxJunkEmailConfigurationState';
import type MailboxJunkEmailConfigurationState from './schema/MailboxJunkEmailConfigurationState';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { ObservableMap } from 'mobx';

function defaultMailboxJunkEmailConfigurationOption(): MailboxJunkEmailConfigurationState {
    return {
        initialized: false,
        mailboxJunkEmailConfiguration: {},
    };
}

const mailboxJunkEmailConfigurationStoreMap = createStore<MailboxJunkEmailConfigurationStateMap>(
    'junkMailConfiguration',
    {
        entries: new ObservableMap(),
    }
)();

export function getMailboxJunkEmailConfigurationStore(
    mailboxInfo: MailboxInfo
): MailboxJunkEmailConfigurationState {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    let store = mailboxJunkEmailConfigurationStoreMap.entries.get(key);
    if (!store) {
        store = defaultMailboxJunkEmailConfigurationOption();
        mailboxJunkEmailConfigurationStoreMap.entries.set(key, store);
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        return mailboxJunkEmailConfigurationStoreMap.entries.get(key)!;
    }

    return store;
}
