export const home_Tab_Groups = "cka7kd";
export const new_Group_Groups = "P$pxZ";
export const newMail_Button_Groups = "zGmQHc";
export const newEvent_Button_Groups = "OE1L3c";
export const newGroup_Button_Groups = "lbWeYd";
export const discover_Group_Groups = "sKt2Y";
export const discovergroup_Button_Groups = "Scrp2d";
export const view_Tab_Groups = "aKTcBe";
export const settings_Group_Groups = "sDuWke";
export const settings_Button_Groups = "lNZTFd";
export const immersivereader_Group_Groups = "vaKMNd";
export const immersivereader_Button_Groups = "WO9kAc";
export const print_Group_Groups = "KJd6_d";
export const print_Button_Groups = "XnnqP";
export const Actions_Groups = "qj_9Fb";
export const Modify_Groups = "H0xwqc";
export const editGroup_Button_Groups = "BQH6Fc";
export const addMembers_Button_Groups = "KXkgrb";
export const leaveGroup_Button_Groups = "apWBpb";
export const inviteOthers_Button_Groups = "EOa0fb";
export const viewInCalendar_Button_Groups = "ggHzmb";
export const viewCalendar_Groups = "_6B_Je";
export const help_Tab_Groups = "m4kzJe";
export const help_Group_Groups = "Gl_wic";
export const tools_Group_Groups = "UFtkv";
export default {home_Tab_Groups,new_Group_Groups,newMail_Button_Groups,newEvent_Button_Groups,newGroup_Button_Groups,discover_Group_Groups,discovergroup_Button_Groups,view_Tab_Groups,settings_Group_Groups,settings_Button_Groups,immersivereader_Group_Groups,immersivereader_Button_Groups,print_Group_Groups,print_Button_Groups,Actions_Groups,Modify_Groups,editGroup_Button_Groups,addMembers_Button_Groups,leaveGroup_Button_Groups,inviteOthers_Button_Groups,viewInCalendar_Button_Groups,viewCalendar_Groups,help_Tab_Groups,help_Group_Groups,tools_Group_Groups};