import { lazyGetSnoozeSections } from 'owa-mail-ribbon-sections/lib/sections';

import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onSnoozeMenuClicked = () => {
    lazyGetSnoozeSections.import().then(getSnoozeSections => {
        setMenuDefinition('SnoozeMenuDefinitionStore', undefined /* projectionTabId */, () => {
            return {
                sections: getSnoozeSections(),
            };
        });
    });
};
