import type { ComposeViewState } from 'owa-mail-compose-store';
import type RecipientWellWithFindControlViewState from 'owa-recipient-types/lib/types/RecipientWellWithFindControlViewState';
import getRecipientsFromRecipientWells from 'owa-recipient-common/lib/utils/getRecipientsFromRecipientWells';
import type EmailAddressWrapper from 'owa-service/lib/contract/EmailAddressWrapper';

export const getRecipientWellsFromComposeViewState = (
    composeViewState: ComposeViewState
): RecipientWellWithFindControlViewState[] => [
    composeViewState.toRecipientWell,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (13,5): Type 'RecipientWellWithFindControlViewState | null' is not assignable to type 'RecipientWellWithFindControlViewState'.
    // @ts-expect-error
    composeViewState.ccRecipientWell,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (17,5): Type 'RecipientWellWithFindControlViewState | null' is not assignable to type 'RecipientWellWithFindControlViewState'.
    // @ts-expect-error
    composeViewState.bccRecipientWell,
];

export const getToRecipientWellsFromComposeViewState = (
    composeViewState: ComposeViewState
): RecipientWellWithFindControlViewState[] => [composeViewState.toRecipientWell];

export const getCcRecipientWellsFromComposeViewState = (
    composeViewState: ComposeViewState
): RecipientWellWithFindControlViewState[] => {
    return composeViewState.ccRecipientWell !== undefined ? [composeViewState.ccRecipientWell] : [];
};

export const getBccRecipientWellsFromComposeViewState = (
    composeViewState: ComposeViewState
): RecipientWellWithFindControlViewState[] => {
    return composeViewState.bccRecipientWell !== undefined
        ? [composeViewState.bccRecipientWell]
        : [];
};

export const getAllRecipientsAsEmailAddressStrings = (
    composeViewState: ComposeViewState
): string[] => {
    const recipientWells: RecipientWellWithFindControlViewState[] =
        getRecipientWellsFromComposeViewState(composeViewState);
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (29,5): Type '(string | undefined)[]' is not assignable to type 'string[]'.
    // @ts-expect-error
    return getRecipientsFromRecipientWells(recipientWells).map(
        recipient => recipient.persona.EmailAddress.EmailAddress
    );
};

/**
 * Extracts `EmailAddressWrapper`s for recipients from all recipient wells in a given composeViewState
 * @param composeViewState
 * @returns EmailAddressWrapper[]
 */
export const getAllRecipients = (composeViewState: ComposeViewState): EmailAddressWrapper[] => {
    const recipientWells: RecipientWellWithFindControlViewState[] =
        getRecipientWellsFromComposeViewState(composeViewState);
    return getRecipientsFromRecipientWells(recipientWells).map(
        recipient => recipient.persona.EmailAddress
    );
};
