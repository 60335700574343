import type OwsOptionsBase from '../OwsOptionsBase';

export enum PasteType {
    KeepSourceFormatting = 0,
    MergeFormatting = 1,
    KeepTextOnly = 2,
}

interface DefaultPasteTypeOption extends OwsOptionsBase {
    pasteFromEmailsAndEvents: PasteType | null;
    pasteFromOtherPrograms: PasteType | null;
}

export default DefaultPasteTypeOption;
