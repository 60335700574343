import type SubstrateSearchScenario from '../data/schema/SubstrateSearchScenario';
import getSubstrateSearchEndpoint from '../helpers/getSubstrateSearchEndpoint';
import { makeGetRequest } from 'owa-ows-gateway';
import buildQueryParams from '../helpers/buildQueryParams';
import { trace } from 'owa-trace';
import getUrlWithAddedQueryParameters from 'owa-url/lib/getUrlWithAddedQueryParameters';
import type { MailboxInfo } from 'owa-client-types';

/**
 * This route is responsible for providing completions/suggestions for the given prefix.
 * The type of suggestions returned would be based on the configuration done at 3S API.
 */
export default function substrateSearchSuggestionsService(
    queryString: string,
    searchSessionGuid: string,
    cvid: string,
    entityTypes: string,
    substrateSearchScenario: SubstrateSearchScenario,
    /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
     * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
     *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
    customHeaders?: any,
    logicalId?: string,
    serverFlights?: string,
    mailboxInfo?: MailboxInfo
): Promise<Response> {
    const defaultParams = {
        cvid,
        query: queryString,
        textdecorations: '1',
        scenario: substrateSearchScenario,
        entityTypes,
    };

    if (logicalId) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS7053 (34,9): Element implicitly has an 'any' type because expression of type '"logicalId"' can't be used to index type '{ cvid: string; query: string; textdecorations: string; scenario: SubstrateSearchScenario; entityTypes: string; }'.
        // @ts-expect-error
        defaultParams['logicalId'] = logicalId;
    }

    if (serverFlights && queryString?.length > 0) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS7053 (41,9): Element implicitly has an 'any' type because expression of type '"setflight"' can't be used to index type '{ cvid: string; query: string; textdecorations: string; scenario: SubstrateSearchScenario; entityTypes: string; }'.
        // @ts-expect-error
        defaultParams['setflight'] = serverFlights;
    }

    if (searchSessionGuid) {
        /* eslint-disable-next-line owa-custom-rules/require-mailboxInfoInOwsRequest  -- (https://aka.ms/OWALintWiki)
         * MailboxInfo must be providded to OWS calls, see https://aka.ms/multiaccountlinter
         *	> All OWS calls must pass in a MailboxInfo or OwsRequestOptions obtained via getOwsMailboxRequestOptions. */
        return makeGetRequest(
            getUrlWithAddedQueryParameters(
                getSubstrateSearchEndpoint('suggestions'),
                buildQueryParams(defaultParams)
            ),
            undefined /* correlationId */,
            true /* returnFullResponse */,
            customHeaders,
            undefined /* throwServiceError */,
            /* 3S does not need auth cookies so omitting them will decrease request header size */
            false /* includeCredentials */,
            'substrateSearchSuggestions' /* actionName */,
            mailboxInfo
        )
            .then(resp => {
                return resp;
            })
            .catch(err => {
                trace.warn(err);
                return null;
            });
    } else {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (68,9): Type 'Promise<null>' is not assignable to type 'Promise<Response>'.
        // @ts-expect-error
        return Promise.resolve(null);
    }
}
