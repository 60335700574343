import type { FavoritePersonaNode } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import folderStore from 'owa-folders';
import type { MailFolder } from 'owa-graph-schema';
import { getSelectedNode } from 'owa-mail-folder-forest-store';
import publicFolderFavoriteStore from 'owa-public-folder-favorite/lib/store/publicFolderFavoriteStore';

export default function getSelectedFolder(): MailFolder | null | undefined {
    const selectedNode = getSelectedNode();
    const isPublicFolder = selectedNode.type === 5;
    let selectedFolderId = null;
    if (selectedNode.type === 1) {
        selectedFolderId = (selectedNode as FavoritePersonaNode).searchFolderId;
    } else {
        selectedFolderId = selectedNode.id;
    }

    if (!selectedFolderId) {
        return null;
    }

    if (isPublicFolder && publicFolderFavoriteStore.folderTable.has(selectedFolderId)) {
        return publicFolderFavoriteStore.folderTable.get(selectedFolderId);
    }

    return folderStore.folderTable.has(selectedFolderId)
        ? folderStore.folderTable.get(selectedFolderId)
        : null;
}
