import type { SearchScenarioId } from 'owa-search-store';
import { action } from 'satcheljs';

export const setInKeyboardSelectionMode = action(
    'setInKeyboardSelectionMode',
    function setShowSearchBoxInCompactMode(
        scenarioId: SearchScenarioId,
        inKeyboardSelectionMode: boolean
    ) {
        return {
            scenarioId,
            inKeyboardSelectionMode,
        };
    }
);
