import React from 'react';
import type { IPropsWithStyles } from '@fluentui/utilities';
import { styled } from '@fluentui/utilities';
import { root as classes_root, icon } from './fluentIcon.scss';

interface FluentIconCreateFnParams {
    IconComponent: React.FC<
        React.SVGAttributes<SVGElement> & {
            primaryFill?: string | undefined;
            className?: string | undefined;
            filled?: boolean | undefined;
            title?: string | undefined;
        }
    >;
    displayName: string;
}

export const createIconComponent = ({ IconComponent, displayName }: FluentIconCreateFnParams) => {
    const Component = styled(
        ({ styles }: IPropsWithStyles<{}, {}>) => {
            const { root: rootStyle, iconStyle } =
                typeof styles === 'function' ? styles({}) : styles;
            return (
                <span
                    role="presentation"
                    aria-hidden={true}
                    style={rootStyle}
                    className={classes_root}
                >
                    <IconComponent className={icon} style={iconStyle} />
                </span>
            );
        },
        {},
        undefined,
        { scope: 'OwaFluentIcon' }
    );

    Component.displayName = displayName;

    return Component;
};
