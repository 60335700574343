import type { MailboxInfo } from 'owa-client-types';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Make sure this isn't imported in the analytics worker
 *	> 'owa-feature-flags' import is restricted from being used. Feature flags have to be passed in the analytics worker initialization [analytics-worker-lint] */
import { getSuffixForLocalStorage } from 'owa-feature-flags';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Make sure this isn't imported in the analytics worker
 *	> 'owa-local-storage' import is restricted from being used. localStorage is not available in the analytics worker [analytics-worker-lint] */
import { getItem } from 'owa-local-storage';

export const getLocalOverrides = (mailboxInfo: MailboxInfo | undefined) => {
    const localOverrides = getItem(self, getLocalOverrideStorageKey(mailboxInfo));

    if (localOverrides) {
        try {
            return JSON.parse(localOverrides);
        } catch (e) {
            return {};
        }
    }

    return {};
};

export function getLocalOverrideStorageKey(mailboxInfo: MailboxInfo | undefined) {
    const APPLICATION_SETTING_OVERRIDES_LOCAL_STORAGE_KEY = 'applicationSettingOverrides';
    const suffix = getSuffixForLocalStorage(mailboxInfo);
    return `${APPLICATION_SETTING_OVERRIDES_LOCAL_STORAGE_KEY}${suffix}`;
}
