import type { Module } from 'owa-workloads';
import getListViewState from '../selectors/getListViewState';

/**
 * This function is deprecated as we will move away from global getters.
 * Please pass in the tableViewId as parameter or prop from caller components/actions.
 */
export default function getSelectedTableViewId(moduleContext?: Module): string {
    return getListViewState(moduleContext).selectedTableViewId;
}
