import { createStore } from 'satcheljs';

export interface UndoStore {
    hasUndoableAction: boolean;
    undoableActionFolderId: string | null;
}

const undoStoreData = {
    hasUndoableAction: false,
    undoableActionFolderId: null,
};

const undoStore = createStore<UndoStore>('undo', undoStoreData)();
export default undoStore;
export const getUndoStore = () => undoStore;
