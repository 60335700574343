let mailCapabilityCache: boolean = false;
export function isMailCapabilitySupported(): boolean {
    return mailCapabilityCache;
}

export function setIsMailCapabilitySupported(isSupported: boolean): void {
    mailCapabilityCache = isSupported;
}

let calendarCapabilityCache: boolean = false;
export function isCalendarCapabilitySupported(): boolean {
    return calendarCapabilityCache;
}

export function setIsCalendarCapabilitySupported(isSupported: boolean): void {
    calendarCapabilityCache = isSupported;
}

let dialogCapabilityCache: boolean = false;
export function isDialogCapabilitySupported(): boolean {
    return dialogCapabilityCache;
}

export function setIsDialogCapabilitySupported(isSupported: boolean): void {
    dialogCapabilityCache = isSupported;
}

let callCapabilityCache: boolean = false;
export function isCallCapabilitySupported(): boolean {
    return callCapabilityCache;
}

export function setIsCallCapabilitySupported(isSupported: boolean): void {
    callCapabilityCache = isSupported;
}

let profileCapabilityCache: boolean = false;
export function isProfileCapabilitySupported(): boolean {
    return profileCapabilityCache;
}

export function setIsProfileCapabilitySupported(isSupported: boolean): void {
    profileCapabilityCache = isSupported;
}

let stageViewCapabilityCache: boolean = false;
export function isStageViewCapabilitySupported(): boolean {
    return stageViewCapabilityCache;
}

export function setIsStageViewCapabilitySupported(isSupported: boolean): void {
    stageViewCapabilityCache = isSupported;
}
