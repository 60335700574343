import {
    getMailboxJunkEmailConfiguration,
    saveMailboxJunkEmailConfiguration,
} from 'owa-junkmail-configuration';
import getMailHandlingOptionState from '../store/store';
import type { AccountSource } from 'owa-account-source-list-store';
import { getMailAccountSources } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store';
import isSenderScreeningFeatureEnabled from '../utils/isSenderScreeningFeatureEnabled';
import type MailboxJunkEmailConfigurationOptions from 'owa-service/lib/contract/MailboxJunkEmailConfigurationOptions';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { getFolderIdForSelectedNode } from 'owa-mail-folder-forest-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { updateAccountScopeUserSettingsAndService } from 'owa-userconfiguration/lib/utils/updateAccountScopeUserSettingsAndService';

export default async function saveMailHandlingOptionAllAccounts() {
    await Promise.all(
        getMailAccountSources().map((accountSource: AccountSource) =>
            saveMailHandlingOptionHelper(accountSource.mailboxInfo)
        )
    );
}

let loadDefaultTableFunction: ((mailboxInfo: MailboxInfo) => void) | null = null;

export function registerLoadDefaultTable(
    loadDefaultTableParam: (mailboxInfo: MailboxInfo) => void
) {
    loadDefaultTableFunction = loadDefaultTableParam;
}

async function saveMailHandlingOptionHelper(mailboxInfo: MailboxInfo) {
    // Update local mailboxJunkEmailConfiguration
    const mailHandlingOptionState = getMailHandlingOptionState(mailboxInfo);

    if (mailHandlingOptionState.MailHandlingOption === null) {
        return;
    }

    const isSenderScreeningEnabled =
        isSenderScreeningFeatureEnabled(mailboxInfo) &&
        mailHandlingOptionState.SenderScreeningEnabled;

    // trustedListsOnly is true when mailHandlingOption is strict and senderScreening is disabled
    const newTrustedListsOnly =
        !isSenderScreeningEnabled && mailHandlingOptionState.MailHandlingOption === 'Strict';
    const newSenderScreeningEnabled =
        isSenderScreeningEnabled && mailHandlingOptionState.MailHandlingOption === 'Strict';

    const mailboxJunkEmailConfiguration = await getMailboxJunkEmailConfiguration(mailboxInfo);

    if (
        getAccountScopeUserSettings(mailboxInfo).UserOptions?.IsSenderScreeningSettingEnabled !==
        newSenderScreeningEnabled
    ) {
        await updateAccountScopeUserSettingsAndService(mailboxInfo, {
            UserOptions: {
                IsSenderScreeningSettingEnabled: newSenderScreeningEnabled,
            },
        });

        if (folderIdToName(getFolderIdForSelectedNode()) === 'inbox') {
            loadDefaultTableFunction?.(getModuleContextMailboxInfo());
        }
    }

    if (
        mailboxJunkEmailConfiguration.TrustedListsOnly !== newTrustedListsOnly ||
        mailboxJunkEmailConfiguration.SenderScreeningEnabled !== newSenderScreeningEnabled
    ) {
        const newMailboxJunkEmailConfiguration: MailboxJunkEmailConfigurationOptions = {};
        Object.assign(newMailboxJunkEmailConfiguration, mailboxJunkEmailConfiguration);

        newMailboxJunkEmailConfiguration.TrustedListsOnly = newTrustedListsOnly;
        newMailboxJunkEmailConfiguration.SenderScreeningEnabled = newSenderScreeningEnabled;

        await saveMailboxJunkEmailConfiguration(newMailboxJunkEmailConfiguration, mailboxInfo);
    }
}
