import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "RSVP" */ './lazyIndex'), {
    name: 'RSVP',
});

export const lazyTryPrefetchMeetingMessage = new LazyAction(
    lazyModule,
    m => m.tryPrefetchMeetingMessage
);

export const lazyRemoveMeetingMessagesFromStore = new LazyAction(
    lazyModule,
    m => m.removeMeetingMessagesFromStore
);

export const lazyParseGetItemResponse = new LazyAction(
    lazyModule,
    m => m.parseGetItemResponseAction
);
