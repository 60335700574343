import type { MailboxInfo } from 'owa-client-types';
import { getGroupFolderHierarchy } from './getGroupFolderHierarchy';
import type { GroupFolderWellKnownName } from 'owa-folders-constants';

/**
 * @param groupId Smtp address of the group
 * @param wellKnownName WellKnownName of the group folder
 * @param mailboxInfo mailbox info of the account
 * @returns Id of a GroupFolder from the specified well known name
 */
export function getGroupFolderId(
    groupId: string,
    wellKnownName: GroupFolderWellKnownName,
    mailboxInfo: MailboxInfo
): string {
    let folderId = '';
    const folderHierarchy = getGroupFolderHierarchy(groupId, mailboxInfo)?.folderHierarchy;
    if (folderHierarchy) {
        for (const groupFolder of folderHierarchy.values()) {
            if (groupFolder?.WellKnownName === wellKnownName) {
                folderId = groupFolder.Id;
                break;
            }
        }
    }
    return folderId;
}
