import type { MailboxInfo } from 'owa-client-types';
import { getAccessTokenforResourceAsLazy } from 'owa-tokenprovider';

export default async function getOcpsTokenForBusiness(
    tokenResourceUrl: string,
    mailboxInfo: MailboxInfo
) {
    let [token, tokenPromise] = getAccessTokenforResourceAsLazy(
        tokenResourceUrl,
        'OwaOcpsPolicy',
        undefined /* requestId */,
        undefined /* targetTenantId */,
        undefined /* wwwAuthenticateHeader */,
        undefined /* showFullTokenResponse */,
        undefined /* perferIdpToken */,
        mailboxInfo
    );

    if (!token) {
        token = await tokenPromise;
    }
    return token;
}
