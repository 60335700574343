import { getStore } from '../store/Store';
import { mutatorAction } from 'satcheljs';
import type { AnimationPreference } from 'owa-outlook-service-option-store';

export const setAnimationPreferenceMutator = mutatorAction(
    'setAnimationPreferenceMutator',
    (animationPreference: AnimationPreference) => {
        getStore().animationPreference = animationPreference;
    }
);
