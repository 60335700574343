import type { MailboxInfo } from 'owa-client-types';
import { getGroupInformation } from './getGroupInformation';

export default function isGroupDetailsValid(
    mailboxInfo: MailboxInfo,
    groupSmtpAddress: string
): boolean {
    const group = getGroupInformation(mailboxInfo, groupSmtpAddress);

    if (group) {
        return !group.groupDetailsError;
    } else {
        return false;
    }
}
