import { PinnablePane } from 'owa-outlook-service-option-store';
import { isTimePanelAvailable, openTimePanel } from 'owa-time-panel-bootstrap';
import type {
    PinnablePaneRegistration,
    PinnablePaneRegistrations,
    RegisteredPinnablePane,
} from '../schema/PinnablePaneRegistrations';
import { isPinnablePaneAvailable } from './isPinnablePaneAvailable';
import { logCopilotChatEntrypoint } from 'owa-officeai-copilot-chat-shared/lib/utils/logCopilotChatEntrypoint';
import { openFlexPane } from 'owa-suite-header-apis';

const CHAT_COPILOT_FLEXPANE_ID = 'ChatCopilot'; // match with owa-copilot-chat utils

const registrations: PinnablePaneRegistrations = {
    [PinnablePane.MyDay]: {
        flexPaneId: 'OwaTimePanel',
        shouldAutoOpen: isTimePanelAvailable,
        handleAutoOpen: () => openTimePanel('AutoOpen'),
    },
    [PinnablePane.CopilotChat]: {
        flexPaneId: CHAT_COPILOT_FLEXPANE_ID,
        shouldAutoOpen: isPinnablePaneAvailable,
        handleAutoOpen: () => {
            logCopilotChatEntrypoint('StickyOpen');
            openFlexPane(CHAT_COPILOT_FLEXPANE_ID);
        },
    },
};

export function getPinnablePaneRegistration(
    pinnablePane: RegisteredPinnablePane
): PinnablePaneRegistration {
    return registrations[pinnablePane];
}

export function getPinnablePaneByFlexPaneId(
    flexPaneId: string
): RegisteredPinnablePane | undefined {
    for (const [pinnedPane, registration] of Object.entries(registrations)) {
        if (registration.flexPaneId === flexPaneId) {
            // object keys are coerced as strings, so need to convert back to number
            return Number(pinnedPane);
        }
    }
    return undefined;
}
