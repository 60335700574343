import type { ActionId } from 'owa-mail-list-item-animation-store';

export function getNextActionId(actionId: ActionId) {
    switch (actionId) {
        case 'PinInitial':
            return 'PinMiddle';
        case 'PinMiddle':
            return 'PinFinal';
        case 'UnpinInitial':
            return 'UnpinMiddle';
        case 'UnpinMiddle':
            return 'UnpinFinal';
        case 'AddRowInitial':
            return 'AddRowFinal';
    }

    return '-'; // Should never happen
}
