import type { FieldPolicy } from '@apollo/client';
// Using autorun to mirror string values into reactive variables
import loc from 'owa-localize';
import { createObservedTypePolicy } from './createObservedTypePolicy';
import type { ResourceId } from 'owa-localize';

export function createLocstringPolicies<TPolicies extends Record<string, ResourceId>>(
    locstringPolicies: TPolicies
): Record<keyof TPolicies, FieldPolicy> {
    const fieldPolicies: Record<string, FieldPolicy> = {};

    const fieldNames = Object.keys(locstringPolicies);
    for (const fieldName of fieldNames) {
        const resourceId = locstringPolicies[fieldName];
        fieldPolicies[fieldName] = createObservedTypePolicy('createLocstringPolicies', () =>
            loc(resourceId)
        );
    }

    return fieldPolicies as Record<keyof TPolicies, FieldPolicy>;
}
