import { applyDefaultOptionsOnLoadFail } from '../actions/applyDefaultOptionsOnLoadFail';
import { OwsOptionsFeatureType, setExtendedOptionValue } from 'owa-outlook-service-option-store';
import { orchestrator } from 'satcheljs';

/**
 * Add only the options features where the values are ok to have
 * when the call to Load option fails
 *
 * We don't want to aggravate users by potentially having it
 * enabled or potentially non-default values after they explicitly disabled it.
 */

const featuresToUseDefault: OwsOptionsFeatureType[] = [OwsOptionsFeatureType.SurfaceActions];

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(applyDefaultOptionsOnLoadFail, msg => {
    const { mailboxInfo, defaultOptions } = msg;
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    featuresToUseDefault.forEach(featureKey => {
        if (defaultOptions[featureKey]) {
            setExtendedOptionValue(
                Number(featureKey as unknown) as OwsOptionsFeatureType,
                defaultOptions[featureKey],
                mailboxInfo
            );
        }
    });
});
