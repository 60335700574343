import type { MailboxInfo } from 'owa-client-types';
import { isAIX00User } from 'owa-copilot-settings-store';
import { getApplicationSettings } from 'owa-application-settings';
import { isFeatureEnabled } from 'owa-feature-flags';

export function isElaboratePolarisExperienceEnabled(mailboxInfo: MailboxInfo): boolean {
    // It's intentional that the feature check is not passing in mailboxInfo so that if primary
    // account has the flight then we enable the experience fo all accounts. But for AIX00
    // users, we want to scope them specifically as they are special group of users
    // who help with early feedback.
    return (
        isFeatureEnabled('mon-copilot-HighlightRewrite') ||
        (isAIX00User(mailboxInfo) &&
            getApplicationSettings('CopilotAIX00', mailboxInfo).elaboratePolarisEnabled)
    );
}
