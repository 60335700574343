import { hasAddin } from 'owa-m365-acquisitions/lib/utils/hasAddin';
import { hasLaunchPage } from 'owa-m365-acquisitions/lib/utils/hasStaticTab';
import { hasMessageExtension } from 'owa-m365-acquisitions/lib/utils/hasMessageExtension';
import { getSettingsSecondaryAction } from '../data/getSettingsSecondaryAction';
import { isValidImageUri } from '../utils/isValidImageUri';
import { NovaControl } from '../types';
import { getClientTitleId } from 'owa-m365-acquisitions/lib/utils/getClientTitleId';
import { hasExtensionAddInSubcommands } from 'owa-m365-acquisitions/lib/utils/hasExtensionAddIn';
import { hasExchangeAddInSubcommands } from 'owa-m365-acquisitions/lib/utils/hasExchangeAddIn';

import type { PlatformType, InternalIcon, RemoteImage } from 'owa-graph-schema';
import type {
    StrictM365Application,
    StrictM365ApplicationIcon,
    StrictAppSecondaryAction,
} from '../types';
import type { M365Acquisition, RecursiveRequired } from 'owa-m365-acquisitions/lib/types';
import type { AppBarPinnedAppsValue } from 'owa-m365-acquisitions/lib/pinnedApps/getAppBarPinnedAppIdsFromUserSettings';
import { getAppSecondaryActions } from '../utils/getAppSecondaryActions';
import { getAllowedExternalDropContent } from '../utils/getAllowedExternalDropContent';
import type { MailboxInfo } from 'owa-client-types';

type M365ApplicationIcon = InternalIcon | RemoteImage;
type AppBarIcons = {
    normal: RecursiveRequired<M365ApplicationIcon>;
    selected: RecursiveRequired<M365ApplicationIcon>;
    outline: RecursiveRequired<M365ApplicationIcon>;
};

/**
 * Extracts icon data from acquisition.
 * For Native apps, which relies on `InternalIcon`, it maps the following fields:
 *    - iconSmall: regularIcon,
 *    - iconLarge: selectedIcon,
 * @param  {M365Acquisition} acquisition
 */
function extractIcons(acquisition: M365Acquisition): AppBarIcons {
    const defaultIcon: StrictM365ApplicationIcon = { name: 'HomeIcon', __typename: 'InternalIcon' };
    const generateIconSchema = (icon: string | undefined, isRemote: boolean) => {
        if (!icon) {
            return defaultIcon;
        }
        const localPlatformType = acquisition.titleDefinition.categories?.[0];
        return isRemote
            ? {
                  __typename: 'RemoteImage',
                  src: icon,
                  isFullBleed:
                      localPlatformType === 'outlook_app' ||
                      localPlatformType === 'm365_app' ||
                      localPlatformType === 'm365_native_app'
                          ? false
                          : true,
              }
            : { __typename: 'InternalIcon', name: icon };
    };
    const title = acquisition.titleDefinition;
    if (!title.iconLarge && !title.iconSmall) {
        return {
            normal: defaultIcon,
            selected: defaultIcon,
            outline: defaultIcon,
        };
    }

    // TODO: this is a hot fix for phone icon issue, need to merge with other logic in the future
    const isPhoneAppIcon = title?.categories?.includes('phone_icon');
    if (isPhoneAppIcon) {
        return {
            normal: generateIconSchema(title?.iconSmall?.uri, true),
            selected: generateIconSchema(title?.iconLarge?.uri, true),
            outline: generateIconSchema(title?.iconOutline?.uri, true),
        } as AppBarIcons;
    }
    const isAppbarNativeIcon = title?.categories?.includes('appbar_icon');
    const remoteIcon = isValidImageUri(title?.iconLarge?.uri)
        ? title?.iconLarge?.uri
        : title?.iconSmall?.uri;
    return (
        isAppbarNativeIcon
            ? {
                  normal: generateIconSchema(title?.iconSmall?.uri, false),
                  selected: generateIconSchema(title?.iconLarge?.uri, false),
                  outline: generateIconSchema(
                      title?.iconOutline?.uri ?? title?.iconSmall?.uri,
                      false
                  ),
              }
            : {
                  normal: generateIconSchema(remoteIcon, true),
                  selected: generateIconSchema(remoteIcon, true),
                  outline: generateIconSchema(
                      title?.iconOutline?.uri ?? title?.iconSmall?.uri,
                      true
                  ),
              }
    ) as AppBarIcons;
}

/**
 * Extracts a PlatformType from an acquisition, which is defines how the acquisition,
 * when rendered in a Nova surface, should behave; i.e. this is used by NovaEventing
 * to properly route events raised on the tile.
 * @param  {M365Acquisition} acquisition
 */
function extractPlatformType(acquisition: M365Acquisition, novaSurface: NovaControl): PlatformType {
    switch (novaSurface) {
        case NovaControl.AppBar:
            const localPlatformType = acquisition.titleDefinition.categories?.[0];
            if (localPlatformType === 'outlook_app' || localPlatformType === 'm365_native_app') {
                return localPlatformType;
            }
            return 'm365_app';
        case NovaControl.MessageExtension:
            const hasAddinElement = hasAddin(acquisition);
            const hasAddinSubcommands =
                hasAddinElement &&
                (hasExtensionAddInSubcommands(acquisition) ||
                    hasExchangeAddInSubcommands(acquisition));

            if (hasAddinElement && hasMessageExtension(acquisition)) {
                return 'm365_message_extension_addin';
            }
            if (hasAddinElement) {
                return hasAddinSubcommands ? 'm365_addin_subcommands' : 'm365_addin';
            }
            if (hasMessageExtension(acquisition)) {
                return 'm365_message_extension';
            }
            break;
    }
    throw new Error('Failed to map a valid PlatformType for acquisition');
}

function getTileId(acquisition: M365Acquisition): string {
    return getClientTitleId(acquisition);
}

async function getSecondaryActions(
    acquisition: M365Acquisition,
    novaSurface: NovaControl,
    mailboxInfo?: MailboxInfo
): Promise<StrictAppSecondaryAction[]> {
    let secondaryActions: StrictAppSecondaryAction[] = [];

    const hasSettingEnabled =
        acquisition.titleDefinition.elementDefinitions?.composeExtensions?.some(
            composeExtension => !!composeExtension?.canUpdateConfiguration
        );
    if (hasMessageExtension(acquisition) && hasSettingEnabled) {
        secondaryActions = [getSettingsSecondaryAction(acquisition, mailboxInfo)];
    }

    secondaryActions.push(...(await getAppSecondaryActions(acquisition, novaSurface, mailboxInfo)));
    return secondaryActions;
}

export async function convertAcquisitionToM365Application(
    acquisition: M365Acquisition,
    novaSurface: NovaControl,
    appBarPinnedApps?: AppBarPinnedAppsValue,
    mailboxInfo?: MailboxInfo
): Promise<StrictM365Application> {
    const PRIMARY_COLOR = '#0078D4';
    const title = acquisition.titleDefinition;
    const tileId = getTileId(acquisition);
    const platformType = extractPlatformType(acquisition, novaSurface);
    const isOutlookApp = platformType === 'outlook_app';
    const tileIcon = extractIcons(acquisition);
    const isHardcodedApp = title.ingestionSource === 'Hardcoded';
    const { lockedApps, pinnedApps } = appBarPinnedApps || { lockedApps: [], pinnedApps: [] };
    const m365ApplicationNode: StrictM365Application = {
        __typename: isOutlookApp ? 'M365HubApplication' : 'M365PlatformApplication',
        accessibleName: title.name,
        badge: {
            __typename: 'M365ApplicationNumericBadge',
            count: 0,
            countDescription: '',
        },
        brandingColor: title?.accentColor ?? PRIMARY_COLOR,
        canLaunchOut: hasLaunchPage(acquisition) && novaSurface === NovaControl.AppBar,
        platformType,
        enablePlatformCommands: !isHardcodedApp,
        canBeUnacquired: !!acquisition?.canBeUnacquired,
        icon: tileIcon.normal,
        outlineIcon: tileIcon.outline,
        id: getTileId(acquisition),
        isInUse: false,
        isPinnable: novaSurface === NovaControl.AppBar && !lockedApps.includes(tileId),
        lockedIndex: lockedApps.indexOf(tileId),
        name: title.name,
        pinnedIndex: pinnedApps.indexOf(tileId),
        selectedStateIconOverride: tileIcon.selected,
        secondaryActions: await getSecondaryActions(acquisition, novaSurface, mailboxInfo),
        allowedExternalDropContent: getAllowedExternalDropContent(acquisition),
    };
    return m365ApplicationNode;
}
