import type {
    DatapointOptions,
    CustomDataMap,
    InternalDatapointOptions,
} from 'owa-analytics-types';
import type { AnalyticsCoreEventNames } from 'owa-analytics-events';
import GreyErrorDatapoint from '../datapoints/GreyErrorDatapoint';
import { logDatapoint } from './logDatapoint';

export function logCoreGreyError(
    eventName: AnalyticsCoreEventNames,
    error?: Error,
    customData?: CustomDataMap,
    options?: DatapointOptions
): Promise<void> {
    const logGreyErrorOptions = (options || {}) as InternalDatapointOptions;
    logGreyErrorOptions.isCore = true;

    return logDatapoint(new GreyErrorDatapoint(eventName, error, customData, options));
}
