import React from 'react';
import { LivePersonaEditorAsync as LivePersonaEditorNovaAsync } from 'owa-lpe-nova';
import ProjectionContext from 'owa-popout-v2/lib/context/ProjectionContext';
import getProjection from 'owa-popout-v2/lib/utils/getProjection';
import { createPortal } from 'owa-react-dom';
import { ActionResultToasterAsync } from 'owa-nova-action-result-toast';
import { PersonSourcesAsync } from 'owa-person-sources';

export const NovaSharedDialogs = () => {
    const targetWindow = React.useContext(ProjectionContext) || window;
    const tabId = getProjection(targetWindow)?.tabId;

    return createPortal(
        <>
            <ActionResultToasterAsync tabId={tabId} />
            <LivePersonaEditorNovaAsync tabId={tabId} />
            <PersonSourcesAsync tabId={tabId} />
        </>,
        targetWindow.document.body
    );
};

NovaSharedDialogs.displayName = 'NovaSharedDialogs';
