import type { RibbonId } from 'owa-ribbon-ids';
import { getRibbonIconModeColor, type RibbonIconColor } from './ribbonIconModeColors';

export type IconColorMap = Map<RibbonId, RibbonIconColor>;

let iconColorMap: IconColorMap | undefined = undefined;

export function initializeIconColorMap(map: Map<RibbonId, RibbonIconColor>) {
    if (iconColorMap == undefined) {
        iconColorMap = map;
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS7053 (16,34): Element implicitly has an 'any' type because expression of type '0' can't be used to index type '{ (): IterableIterator<RibbonId>; (): IterableIterator<RibbonId>; }'.
        // @ts-expect-error
    } else if (!iconColorMap.has(map.keys[0])) {
        iconColorMap = new Map([...iconColorMap, ...map]);
    }
}

export const getRibbonIconColor = (btnIdentifier: RibbonId | string): string | undefined => {
    let iconColor: RibbonIconColor | undefined = undefined;

    if (iconColorMap) {
        const buttonId: RibbonId | number =
            typeof btnIdentifier == 'string' ? parseInt(btnIdentifier) : btnIdentifier;

        iconColor = iconColorMap.get(buttonId);
    }

    // Even if iconColorMap exists, it may not contain btnIdentifier, and iconColor would be undefined. Default to "gray"
    return getRibbonIconModeColor(iconColor ?? 6);
};
