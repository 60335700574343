import { IsShadowMailboxUser } from 'owa-mail-ads-shared/lib/sharedAdsUtils';
import { isPremiumConsumer } from 'owa-session-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

import { isFeatureEnabled } from 'owa-feature-flags';
import isIndexedPath from 'owa-url/lib/isIndexedPath';

/**
 * Check if its a Cloud cache scenario
 */
export function isCloudCacheScenario() {
    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();
    return (
        isFeatureEnabled('auth-cloudCache') &&
        (isPremiumConsumer(mailboxInfo) || IsShadowMailboxUser(mailboxInfo)) &&
        isIndexedPath()
    );
}
