import getMasterCategoryList from 'owa-categories/lib/utils/getMasterCategoryList';
import type { QuickStepIconId, QuickStepItem, QuickStepCategoryType } from 'owa-quick-step-types';
import FlashRegular from 'owa-fluent-icons-svg/lib/icons/FlashRegular';
import Delete from 'owa-fluent-icons-svg/lib/icons/DeleteRegular';
import MailRead from 'owa-fluent-icons-svg/lib/icons/MailReadRegular';
import MailUnread from 'owa-fluent-icons-svg/lib/icons/MailRegular';
import FolderArrowRight from 'owa-fluent-icons-svg/lib/icons/FolderArrowRightRegular';
import Tag from 'owa-fluent-icons-svg/lib/icons/TagRegular';
import ArrowForward from 'owa-fluent-icons-svg/lib/icons/ArrowForwardRegular';
import MailAttachRegular from 'owa-fluent-icons-svg/lib/icons/MailAttachRegular';
import Flag from 'owa-fluent-icons-svg/lib/icons/FlagRegular';
import CheckmarkRegular from 'owa-fluent-icons-svg/lib/icons/CheckmarkRegular';
import ArchiveSettings from 'owa-fluent-icons-svg/lib/icons/ArchiveSettingsRegular';
import Unpin from 'owa-fluent-icons-svg/lib/icons/PinRegular';
import SpeakerMuteRegular from 'owa-fluent-icons-svg/lib/icons/SpeakerMuteRegular';
import Clock from 'owa-fluent-icons-svg/lib/icons/ClockRegular';
import CalendarReply from 'owa-fluent-icons-svg/lib/icons/CalendarReplyRegular';
import IcFluentBrandToDoMono from 'owa-fluent-mobile-brand-icons-svg/lib/icons/IcFluentBrandToDoMono';
import {
    type RibbonIconColor,
    getRibbonIconModeColor,
} from 'owa-command-ribbon/lib/util/ribbonIconModeColors';
import type { MailboxInfo } from 'owa-client-types';
import getCategoryColorsForCategory from 'owa-categories/lib/utils/getCategoryColorsForCategory';
import CalendarSparkle from 'owa-fluent-icons-svg/lib/icons/CalendarSparkleRegular';

export interface QuickStepIconInformation {
    name: string;
    color: string | undefined;
}

export function getIconInfoFromIconId(
    step: QuickStepItem,
    mailboxInfo: MailboxInfo
): QuickStepIconInformation {
    // It's possible the map doesn't contain the type, if so return the default icon type
    const name = ActionTypeToIconNameMap.get(step.iconId) ?? FlashRegular;
    let iconColor;
    if (step.iconId === 'category' && step.actions[0]) {
        const quickStepCategory = step.actions[0].extraInfo as QuickStepCategoryType | undefined;
        if (quickStepCategory?.isAlwaysAsk) {
            iconColor = getRibbonIconModeColor(6);
        } else if (
            quickStepCategory?.categoryId &&
            typeof quickStepCategory.categoryId === 'string'
        ) {
            iconColor = getCategoryColorsForCategory(
                quickStepCategory.categoryId,
                getMasterCategoryList(mailboxInfo),
                mailboxInfo
            ).iconColor;
        } else if (quickStepCategory) {
            iconColor = getCategoryColorsForCategory(
                quickStepCategory as unknown as string,
                getMasterCategoryList(mailboxInfo),
                mailboxInfo
            ).iconColor;
        }
    } else {
        const iconColorId = ActionTypeToIconColorMap.get(step.iconId) ?? 6;
        iconColor = getRibbonIconModeColor(iconColorId);
    }

    return { name, color: iconColor };
}

// Why are we keying a string to a string here, you may ask? This way, if we update icons
// to new version, we can just do it here, the user's saved quick step won't be broken
const ActionTypeToIconNameMap: Map<QuickStepIconId, string> = new Map<QuickStepIconId, string>([
    ['markAsRead', MailRead],
    ['markAsUnread', MailUnread],
    ['delete', Delete],
    ['softDelete', Delete],
    ['moveTo', FolderArrowRight],
    ['copyTo', FolderArrowRight],
    ['category', Tag],
    ['clearCategories', Tag],
    ['forwardTo', ArrowForward],
    ['forwardAsAttachment', MailAttachRegular],
    ['flag', Flag],
    ['clearFlags', Flag],
    ['markComplete', CheckmarkRegular],
    ['retentionPolicy', ArchiveSettings],
    ['pin', Unpin],
    ['unpin', Unpin],
    ['ignore', SpeakerMuteRegular],
    ['snooze', Clock],
    ['createTask', IcFluentBrandToDoMono],
    ['replyWithMeeting', CalendarReply],
    ['copilotReplyWithMeeting', CalendarSparkle],
    [null, FlashRegular], // Default to the quick steps icon
]);

const ActionTypeToIconColorMap: Map<QuickStepIconId, RibbonIconColor> = new Map<
    QuickStepIconId,
    RibbonIconColor
>([
    ['markAsRead', 6],
    ['markAsUnread', 6],
    ['delete', 6],
    ['softDelete', 6],
    ['moveTo', 4],
    ['copyTo', 4],
    ['clearCategories', 4],
    ['forwardTo', 4],
    ['forwardAsAttachment', 6],
    ['flag', 0],
    ['clearFlags', 0],
    ['markComplete', 3],
    ['retentionPolicy', 4],
    ['pin', 4],
    ['unpin', 4],
    ['ignore', 0],
    ['snooze', 6],
    ['createTask', 6],
    ['replyWithMeeting', 5],
    ['copilotReplyWithMeeting', 5],
    [null, 2], // Default to the quick steps icon
]);
