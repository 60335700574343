import type { MessageExtensionsFlyoutQuery } from './graphql/query/__generated__/MessageExtensionsData.interface';
import type { AppBarControlDataQuery } from './graphql/query/__generated__/AppBarControlData.interface';
import type { NovaControlModelQuery } from './graphql/query/__generated__/NovaControlModel.interface';

import type {
    RecursiveRequired,
    InferArrayType,
    UnionPropertyWith,
} from 'owa-m365-acquisitions/lib/types';

enum NovaControl {
    AppBar,
    MessageExtension,
}

type StrictMessageExtensionsFlyoutQuery = RecursiveRequired<MessageExtensionsFlyoutQuery>;
type StrictAppBarControlDataQuery = RecursiveRequired<AppBarControlDataQuery>;
type StrictNovaControlModelQuery = RecursiveRequired<NovaControlModelQuery>;
type StrictViewData = StrictNovaControlModelQuery['views'];
type StrictLaunchTileBehavior = StrictViewData['appBarControl']['tileBehavior'];
type StrictAppAcquisitionLinkBehavior =
    StrictViewData['appBarControl']['appAcquisitionLinkBehavior'];
type StrictFlyoutSearchBehavior =
    StrictViewData['appBarControl']['flyoutBehavior']['searchBehavior'];
type StrictM365HubApplication = InferArrayType<
    StrictAppBarControlDataQuery['m365Apps']['edges']
>['node'];
type StrictM365Application = UnionPropertyWith<
    StrictM365HubApplication,
    '__typename',
    'M365PlatformApplication'
>;
type StrictM365ApplicationEdge = {
    __typename: 'M365ApplicationEdge';
    node: StrictM365Application;
};
type StrictAppAcquisitionLink = InferArrayType<
    StrictAppBarControlDataQuery['m365Apps']['appAcquisitionLinks']
>;
type StrictM365ApplicationIcon = StrictM365Application['icon'];
type StrictAppSecondaryAction = InferArrayType<StrictM365Application['secondaryActions']>;

export {
    NovaControl,
    type StrictAppBarControlDataQuery,
    type StrictNovaControlModelQuery,
    type StrictAppAcquisitionLink,
    type StrictMessageExtensionsFlyoutQuery,
    type StrictViewData,
    type StrictLaunchTileBehavior,
    type StrictAppAcquisitionLinkBehavior,
    type StrictFlyoutSearchBehavior,
    type StrictM365Application,
    type StrictM365ApplicationEdge,
    type StrictM365ApplicationIcon,
    type StrictAppSecondaryAction,
};
