import type CategoryType from 'owa-service/lib/contract/CategoryType';
import { default as CategoryColor, type CategoryColorValue } from '../store/schema/CategoryColor';
import getMasterCategoryList from '../utils/getMasterCategoryList';
import type { MailboxInfo } from 'owa-client-types';
import { getIsDarkTheme } from 'owa-fabric-theme';
import { lightColorCodeValueMap } from '../store/helpers/getLightThemeColorCodeValueMap';
import { darkColorCodeValueMap } from '../store/helpers/getDarkThemeColorCodeValueMap';

/**
 * Gets category colors for a given category name
 * @param categoryName the name of the category
 * @param categoryList a list of categories to search
 * @param isDarkThemeOverride Optional whether the value of isDarkTheme should be overriden. If
 * undefined, we will get the value of the current theme from the store.
 * @returns the category color properties
 */
export default function getCategoryColorsForCategory(
    categoryName: string,
    categoryList: CategoryType[],
    mailboxInfo: MailboxInfo,
    isDarkThemeOverride?: boolean
): CategoryColorValue {
    const isDarkTheme = isDarkThemeOverride === undefined ? getIsDarkTheme() : isDarkThemeOverride;

    if (!categoryList) {
        categoryList = getMasterCategoryList(mailboxInfo);
    }

    for (let i = 0; i < categoryList.length; i++) {
        const category = categoryList[i];

        if (
            category.Color !== undefined &&
            typeof categoryName === 'string' &&
            category.Name?.toLowerCase() === categoryName.toLowerCase()
        ) {
            return isDarkTheme
                ? darkColorCodeValueMap[category.Color.toString()]
                : lightColorCodeValueMap[category.Color.toString()];
        }
    }

    return getDefaultCategoryColor();
}

/**
 * Gets the default category color
 * @returns a default category color value
 */
export function getDefaultCategoryColor(): CategoryColorValue {
    return getIsDarkTheme()
        ? darkColorCodeValueMap[CategoryColor.Mink]
        : lightColorCodeValueMap[CategoryColor.Mink];
}
