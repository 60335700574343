import { LazyModule, LazyImport } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "FolderCommon" */ './lazyIndex')
);

export const lazyPreparePopoutDataForReadingPane = new LazyImport(
    lazyModule,
    m => m.preparePopoutDataForReadingPane
);

// Export utils
export { default as getFolderIdFromTableView } from './utils/getFolderIdFromTableView';
