import { LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "GroupCreate" */ './lazyIndex')
);

// Exported actions
export const lazyCreateGroup = new LazyImport(lazyModule, m => m.createGroup);

/* eslint-disable-next-line owa-custom-rules/forbid-package-reexport  -- (https://aka.ms/OWALintWiki)
 * Baseline comment; do not copy-paste. Rexporting from a separate package complicates the package graph.
 *	> Do not re-export from a separate package */
export { UserType } from 'owa-group-crud-contract/lib/schema/groupCrudInfo';
