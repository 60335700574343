import { type CopilotThemeOptions } from 'owa-copilot-themes-types';
import { ThemeConstants } from 'owa-theme-shared';

const defaultPrompt = {
    type: 'City',
    location: 'SeattleWA',
    style: 'Realistic',
    refreshRate: 'None',
} as const;

export default function getDefaultThemeOptions(): CopilotThemeOptions {
    return {
        themeId: ThemeConstants.COPILOT_CUSTOM_THEME_ID,
        isSelected: false,
        ...defaultPrompt,
        lastRefreshDate: '',
        lightThemeResources: {} as any,
        darkThemeResources: {} as any,
    };
}
