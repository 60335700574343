import type { MailboxInfo } from 'owa-client-types';
import type SocialActivityNotificationPayload from 'owa-service/lib/contract/SocialActivityNotificationPayload';
import { action } from 'satcheljs';

export default action(
    'REACTION_NOTIFICATION',
    (notification: SocialActivityNotificationPayload, mailboxInfo?: MailboxInfo) => ({
        notification,
        mailboxInfo,
    })
);
