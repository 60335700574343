import { updateUserConfiguration } from 'owa-session-store/lib/actions/updateUserConfiguration';
import type { UserConfigurationArgs } from 'owa-graph-schema';
import { lazyUpdateUserConfigurationOperation } from '../lazyFunctions';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { createUserConfigurationUpdateFunction } from './createUserConfigurationUpdateFunction';

export function updateUserConfigurationAndService(
    updates: UserConfigurationArgs
): Promise<unknown> {
    updateUserConfiguration(createUserConfigurationUpdateFunction(updates));

    return lazyUpdateUserConfigurationOperation.importAndExecute(
        getGlobalSettingsAccountMailboxInfo(),
        updates
    );
}
