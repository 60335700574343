import { isFeatureEnabled } from 'owa-feature-flags';
import type { LogData } from 'owa-logging-utils';
import { readingPaneLogStore } from '../store/store';
import { trace } from 'owa-trace';

const MAX_LOGS = 1000;

/**
 * Action which adds the mail list log to the store
 */
export function addReadingPaneLog(message: string, data: LogData) {
    if (!isFeatureEnabled('rp-logging')) {
        return;
    }

    trace.verbose(`RPLog: ${message}`);
    const { readingPaneLogs } = readingPaneLogStore;
    if (readingPaneLogs.length === MAX_LOGS) {
        // If we already have the maxLogs, then remove the first
        readingPaneLogs.shift();
    }

    readingPaneLogs.push({
        time: new Date(Date.now()).toISOString(),
        message,
        data,
    });
}
