import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import { getStore } from 'owa-mail-reading-pane-store/lib/store/Store';
import { mutatorAction } from 'satcheljs';
import { secondaryTabsHaveId } from 'owa-tab-store';
import getPrimaryTabId from 'owa-mail-reading-pane-store/lib/utils/getPrimaryTabId';

export const addLoadedConversationReadingPaneViewState = mutatorAction(
    'addLoadedConversationReadingPaneViewState',
    function (conversationReadingPaneViewState: ConversationReadingPaneViewState) {
        const conversationId = conversationReadingPaneViewState.conversationId.Id;
        const { loadedConversationReadingPaneViewStates } = getStore();
        if (!loadedConversationReadingPaneViewStates.has(conversationId)) {
            loadedConversationReadingPaneViewStates.set(
                conversationId,
                conversationReadingPaneViewState
            );
        }
    }
);

export const releaseOrphanedLoadedConversationViewStates = mutatorAction(
    'releaseOrphanedLoadedConversationViewStates',
    function () {
        const { loadedConversationReadingPaneViewStates, itemPrintPaneViewStates } = getStore();
        // Release any view states that do not belong to any tabs
        loadedConversationReadingPaneViewStates.forEach((_viewState, loadedConversationId) => {
            if (
                !secondaryTabsHaveId(loadedConversationId) &&
                loadedConversationId != getPrimaryTabId()
            ) {
                loadedConversationReadingPaneViewStates.delete(loadedConversationId);
            }
        });

        // Also release any unused view states from item print pane
        itemPrintPaneViewStates &&
            itemPrintPaneViewStates.forEach((_viewState, tabId) => {
                const itemId = _viewState?.itemId;
                if (itemId && !(getPrimaryTabId() == itemId || secondaryTabsHaveId(itemId))) {
                    itemPrintPaneViewStates.delete(tabId);
                }
            });
    }
);

export const releaseLoadedConversationViewState = mutatorAction(
    'releaseLoadedConversationViewState',
    function (conversationId: string) {
        getStore().loadedConversationReadingPaneViewStates.delete(conversationId);
    }
);
