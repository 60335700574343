import { isTimePanelAvailable } from './isTimePanelAvailable';
import { isSupportedViewForUser } from 'owa-time-panel-settings/lib/utils/isSupportedViewForUser';
import type { MailboxInfo } from 'owa-client-types';
import { canUseToDoFeatures } from 'owa-todo-module-utils';

/**
 * Checks whether Time Panel feature is available in current OWA user/window context,
 * as well as whether To Do functionality is available within Time Panel and if the
 * current account is capable of using To Do features.
 *
 * OWA features that interact with Time Panel and require To Do functionality should
 * be hidden or disabled when these features are not available or not supported for
 * the account currently in use.
 */
export function isTimePanelWithToDoFeaturesAvailable(mailboxInfo: MailboxInfo): boolean {
    return (
        isTimePanelAvailable() && isSupportedViewForUser('Tasks') && canUseToDoFeatures(mailboxInfo)
    );
}
