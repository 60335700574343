import type { MailboxInfo } from 'owa-client-types';
import { getGroupFolderHierarchy } from './getGroupFolderHierarchy';
import type { GroupFolder } from 'owa-groups-shared-folders-schema/lib/schema/GroupFolder';

/**
 * @param groupId Smtp address of the group
 * @param folderId Folder Id of the group folder
 * @param mailboxInfo mailbox info of the account
 * @returns Group folder corresponding to the Folder Id of the specified group
 */
export function getGroupFolder(
    groupId: string,
    folderId: string,
    mailboxInfo: MailboxInfo
): GroupFolder | undefined {
    return getGroupFolderHierarchy(groupId, mailboxInfo)?.folderHierarchy.get(folderId);
}
