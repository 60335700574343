import type { MailboxInfo } from 'owa-client-types';
import { getGroupInformation } from '../selectors/getGroupInformation';

export function isUserGroupOwner(mailboxInfo: MailboxInfo, groupId: string): boolean {
    const group = getGroupInformation(mailboxInfo, groupId);
    return !!(
        group?.groupDetails?.UserGroupRelationship?.IsOwner ||
        group?.basicInformation?.AdditionalProperties?.IsOwner
    );
}
