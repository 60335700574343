import getDateSortTimeStampFromRowData from './getDateSortTimeStampFromRowData';
import { addMailListLog, getMailListLogObjectToAddToStore } from 'owa-mail-list-logging';
import tombstoneOperations, { type TombstoneReasonType } from 'owa-mail-list-tombstone';
import type { MailListRowDataType, TableView } from 'owa-mail-list-store';

/**
 * Function that evaluates if the row payload should be processed when it is
 * received from the server, whether from notification or from Find calls
 * @param serverRow row payload received from server
 * @param tableView to which the row belongs
 * @returns a flag indicating whether the row should be processed further or not
 */
export default function shouldSkipProcessRow(
    serverRow: MailListRowDataType,
    tableView: TableView
): boolean {
    /**
     * Do not process the row if it was earlier than the lastEmptiedTime
     * in case we did an empty folder in this tableView.
     */
    const timeStamp = getDateSortTimeStampFromRowData(serverRow, tableView.tableQuery.listViewType);
    if (
        tableView.lastEmptiedTime &&
        timeStamp.lastModifiedTime &&
        tableView.lastEmptiedTime > new Date(timeStamp.lastModifiedTime)
    ) {
        addMailListLog(
            getMailListLogObjectToAddToStore('shouldSkipProcessRow:FolderEmptiedAfterRowModified', {
                rowInstanceKey: serverRow.InstanceKey,
                lastEmptiedTime: tableView.lastEmptiedTime.toDateString(),
                lastModifiedTime: timeStamp.lastModifiedTime,
            })
        );

        return true;
    }

    /**
     * Row is in tombstoned for remove reason so do not process it further
     */
    const tombstoneReasons = serverRow.InstanceKey
        ? tombstoneOperations.getTombstonedReasons(serverRow.InstanceKey, tableView.serverFolderId)
        : [];

    const rowHasTombstoneRemoveReason = tombstoneReasons.indexOf(3) > -1;

    addMailListLog(
        getMailListLogObjectToAddToStore('shouldSkipProcessRow:IsRowInTombstoneForRemoveReason', {
            rowInstanceKey: serverRow.InstanceKey,
            shouldSkipProcessRow: rowHasTombstoneRemoveReason,
        })
    );

    return rowHasTombstoneRemoveReason;
}
