import type { ComposeViewState } from 'owa-mail-compose-store';
import { lazyOnToggleRmsTemplate } from 'owa-mail-commands-actions';

export default function onToggleRMSTemplate(
    viewState: ComposeViewState,
    RmsTemplateId: string,
    targetWindow: Window
) {
    lazyOnToggleRmsTemplate.importAndExecute(viewState, RmsTemplateId, targetWindow);
}
