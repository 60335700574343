import { getClientVersion } from 'owa-config';
import getPopoutUrl from 'owa-url/lib/getPopoutUrl';
import { POPOUT_V2_QUERY, POPOUT_V2_VALUE } from '../utils/constants';
import type { vDirType } from 'owa-vdir-types';

const VERSION = 'version';

export interface DeeplinkUrlOptions {
    skipOptinCheck?: boolean;
    isStandalone?: boolean;
    urlParameters?: Record<string, string>;
    isStageView?: boolean;
}

export default function getDeeplinkUrl(
    vdir: vDirType,
    route: string,
    options: DeeplinkUrlOptions = {}
): string {
    if (vdir === 'reminders' || vdir === 'addCalendars') {
        return '';
    }

    const parameters: Record<string, string> = options.isStandalone
        ? {}
        : options.isStageView
        ? { [VERSION]: getClientVersion() } // Pass the version number to Stage View window to ensure it loads the same version of script as main window
        : {
              [POPOUT_V2_QUERY]: POPOUT_V2_VALUE, // Use this parameter to tell popout window that it is opened from OWA so possibly there are some data to retrieve from parent window
              [VERSION]: getClientVersion(),
          };

    // merge any additional scenario-based parameters into the final parameters object
    const additionalUrlParameters = options.urlParameters || {};
    Object.keys(additionalUrlParameters).map(key => {
        parameters[key] = additionalUrlParameters[key];
    });

    return getPopoutUrl(vdir, route, options.skipOptinCheck, parameters);
}
