import { LazyModule, createLazyComponent } from 'owa-bundling';
import { PresencePersonaLoading } from './components/PresencePersonaLoading';
import type { PresencePersonaBadgeProps } from './components/PresencePersonaBadgeProps';
import type { PersonaControlWrapperWithPresenceProps } from './components/PersonaControlWrapperWithPresence';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PersonaPresenceBadge" */ './lazyIndex'),
    { name: 'PersonaPresenceBadge' }
);

export const PresencePersonaBadge = createLazyComponent<PresencePersonaBadgeProps, any, any>(
    lazyModule,
    m => m.PresencePersonaBadge,
    PresencePersonaLoading
);

export const PersonaControlWrapperWithPresence = createLazyComponent<
    PersonaControlWrapperWithPresenceProps,
    any,
    any
>(lazyModule, m => m.PersonaControlWrapperWithPresence);
