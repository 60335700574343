import { getDefaultMailboxFolderTreeData } from '../store/getDefaultMailboxFolderTreeData';
import { setMailboxFolderTreeData } from '../mutators/setMailboxFolderTreeData';
import { getMailboxFolderTreeData } from '../selectors/getMailboxFolderTreeDataTable';
import type { MailboxInfo } from 'owa-client-types';

export function initializeFolderTreeData(mailboxInfo: MailboxInfo) {
    if (!getMailboxFolderTreeData(mailboxInfo)) {
        setMailboxFolderTreeData(mailboxInfo, getDefaultMailboxFolderTreeData(mailboxInfo));
    }
}
