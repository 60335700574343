import type { MailComposeTabViewState } from 'owa-tab-store';
import { composeStore } from 'owa-mail-compose-store';

export function findAddinTriggeredComposeViewState(activeComposeTabs: MailComposeTabViewState[]) {
    for (const activeComposeTab of activeComposeTabs) {
        const composeId = activeComposeTab.data;
        const composeViewState = composeStore.viewStates.get(composeId);
        if (composeViewState?.addin.isOnSendEventTriggered) {
            return activeComposeTab;
        }
    }
    return undefined;
}
