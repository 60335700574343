export function setAppBadge() {
    if (typeof self.navigator?.setAppBadge === 'function') {
        navigator.setAppBadge().catch(() => {
            // Ignore the error
        });
    }
}

export function clearAppBadge() {
    if (typeof self.navigator?.clearAppBadge === 'function') {
        navigator.clearAppBadge().catch(() => {
            // Ignore the error
        });
    }
}
