import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import type { AriaProperties } from 'owa-accessibility';
import { AriaRoles, generateDomPropertiesForAria } from 'owa-accessibility';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list/lib/flights';
import { getDensityMode, getDensityModeString, getDensityModeCssClass } from 'owa-fabric-theme';
import { isFeatureEnabled } from 'owa-feature-flags';
import { useKeydownHandlers } from 'owa-hotkeys';
import loc, { isCurrentCultureRightToLeft } from 'owa-localize';
import React from 'react';
import { getUITreeNodeDepth, getTreeNodesFarPadding } from '../utils/treeNodeUIUtils';
import { selectedFolderNodeScreenReaderOnlyText } from './TreeNode.locstring.json';
import { logUsage } from 'owa-analytics';
import {
    full,
    medium,
    compact,
    iconsNext,
    iconsNextFull,
    rootIcon,
    icon,
    folderWithChevron as styles_folderWithChevron,
    leftNavAllFoldersPadding,
    folderIcons,
    regularNodeChevronIcon,
    chevronIconCompact,
    chevronIconMedium,
    chevronIconFull,
    chevronPadding,
    chevronIconButton,
    chevronIconButtonFull,
    chevronIconButtonMedium,
    chevronIconButtonCompact,
    rootNode,
    regularNode,
    roundedCorners,
    densityNodeHeight,
    densityRootNodeHeight,
    showAsHoverOnDroppedOver as styles_showAsHoverOnDroppedOver,
    showBorderOnDroppedOver,
    isBeingDragged as styles_isBeingDragged,
    customActionNode,
    overridenFont,
    selected,
    withContextMenuOpen,
    disabledCursor,
    isDisabled as styles_isDisabled,
    displayName as styles_displayName,
    densityFontSize,
    allFolderIconsRootText,
    rightCharm as styles_rightCharm,
    hoverPadding,
    rightCharmHover as styles_rightCharmHover,
    rootNodeFont,
    chevronAnimation,
    chevronAnimationCollapseFull,
    chevronAnimationCollapse,
    ellipsesIcon,
    rtlStyles,
    ltrStyles,
    rootHover,
    ellipsesOnHover as styles_ellipsesOnHover,
    ellipsesHovered,
    focusStyling,
    ellipsesPadding,
    ellipsesOnHoverTreatmentPresent,
    rightCharmHoverTreatmentPresent,
} from './TreeNode.scss';
import { useMouseWithPerfWrapper } from 'owa-react-hooks/lib/useMouseWithPerfWrapper';
import { useRestoreFocusTarget, Button, makeStyles } from '@fluentui/react-components';
import {
    type FluentIcon,
    ChevronDownRegular,
    ChevronRightRegular,
    MoreHorizontalRegular,
    FolderRegular,
    FolderFilled,
    bundleIcon,
    iconRegularClassName,
    iconFilledClassName,
} from '@fluentui/react-icons';

export type ChevronProps = {
    isExpanded: boolean;
    onClick: (evt: React.MouseEvent<unknown> | KeyboardEvent) => void;
    iconButtonClassName?: string;
    iconClassName?: string;
    isAnimated?: boolean;
};

export interface TreeNodeProps {
    displayName: string;
    isRootNode: boolean;

    // Optional parameters
    chevronProps?: ChevronProps; // Chevron related props, if not passed, we will not show chevron
    customIconComponent?: FluentIcon; // Render an icon for special tree nodes
    customIconClassName?: string;
    onRenderCustomIcon?: (customIconClassName: string, hideIcon?: boolean) => JSX.Element;
    depth?: number; // The indentation int the tree node structure, where 0 is the root
    isBeingDragged?: boolean;
    isCustomActionNode?: boolean; // A node that contains a custom action (e.g. "New folder" node that creates new folder)
    isDroppedOver?: boolean;
    isSelected?: boolean;
    isWithContextMenuOpen?: boolean;
    onClick?: React.MouseEventHandler<EventTarget>;
    onKeyDown?: React.KeyboardEventHandler<EventTarget>;
    onContextMenu?: React.MouseEventHandler<EventTarget>;
    onMouseEnter?: React.MouseEventHandler<EventTarget>;
    onTouchStart?: React.TouchEventHandler<EventTarget>;
    onTouchEnd?: React.TouchEventHandler<EventTarget>;
    renderCustomTreeNodeDisplay?: (textClassName: string) => JSX.Element; // Method to render the main content (if not specified the tree node will render the default UI)
    renderRightCharm?: () => JSX.Element | null; // Method to render the right charm (if any)
    renderRightCharmHover?: () => JSX.Element | null; // Method to render the right charm on hover state (if any)
    isFavorited?: boolean;
    toggleFavorite?: () => void;
    showAsHoverOnDroppedOver?: boolean; // True if we want to show the hover style css when the tree node is dropped over
    isCursorDisabled?: boolean;
    setSize?: number;
    positionInSet?: number;
    isinSharedFolderTree?: boolean;
    ariaLabel?: string;
    isDisabled?: boolean;
    idUsedForTooltip?: string;
    elementRef?: React.RefObject<HTMLDivElement>;
    customTreeNodeTooltip?: string;
    hideIcon?: boolean;
    ellipsesOnHover?: boolean;
    source?: string;
    shouldShowFocusBorder?: boolean;
    ariaIsDisabled?: boolean;
    id?: string;
    customIconColor?: string;
}

export default observer(function TreeNode(props: TreeNodeProps) {
    const localRef = React.useRef<HTMLDivElement>(null);
    const divRef = props.elementRef ?? localRef;

    const densityModeCssClass = getDensityModeCssClass(full, medium, compact);
    const chevronProps = props.chevronProps;
    const ellipsesOnHover = props.ellipsesOnHover;
    const [mountEllipses, setMountEllipses] = React.useState(false);
    const [isEllipsesHovered, setIsEllipsesHovered] = React.useState(false);
    const restoreFocusTargetAttribute = useRestoreFocusTarget();

    const {
        customIconComponent,
        customIconClassName,
        idUsedForTooltip,
        onRenderCustomIcon,
        hideIcon,
    } = props;

    const getContainerStyle = () => {
        // The first level of tree nodes must be inline with root in the UI
        const firstLevelPadding = 0;
        const hasDepthOrIsFavorited = !!depth || props.isFavorited;
        const uiTreeNodeDepth = hasDepthOrIsFavorited
            ? getUITreeNodeDepth(depth ?? 0, getDensityModeString(), props.hideIcon)
            : firstLevelPadding;
        const treePadding =
            uiTreeNodeDepth + (hasDepthOrIsFavorited ? firstLevelPadding : 0) + 'px';
        const farPadding = ellipsesOnHover ? undefined : getTreeNodesFarPadding();
        const isRTL = isCurrentCultureRightToLeft();
        const width = 'auto';
        const paddingRight = isRTL ? treePadding : farPadding;
        const paddingLeft = isRTL ? farPadding : treePadding;

        // Just add paddin so the hover of the tree node is the same width
        // independent on its depth
        return {
            paddingLeft,
            paddingRight,
            width,
        };
    };

    useKeydownHandlers(
        divRef,
        React.useCallback(
            () => [
                {
                    command: 'right',
                    handler: (evt: KeyboardEvent) => {
                        if (chevronProps?.onClick && !chevronProps.isExpanded) {
                            chevronProps.onClick(evt);
                        }
                    },
                },
                {
                    command: 'left',
                    handler: (evt: KeyboardEvent) => {
                        if (chevronProps?.onClick && chevronProps.isExpanded) {
                            chevronProps.onClick(evt);
                        }
                    },
                },
            ],
            [chevronProps?.onClick, chevronProps?.isExpanded]
        )
    );

    const openContextMenu = (evt: React.MouseEvent, src: string) => {
        evt.stopPropagation();
        logUsage('FP_OpenContextMenu', { source: props.source, eventSource: src });
        onContextMenu?.(evt);
    };

    const rightClickContextMenu = React.useCallback(
        (evt: React.MouseEvent<HTMLDivElement>) => {
            openContextMenu(evt, 'Right Click');
        },
        [openContextMenu]
    );

    const {
        isCustomActionNode,
        isDroppedOver,
        isSelected,
        showAsHoverOnDroppedOver,
        isBeingDragged,
        isWithContextMenuOpen,
        isCursorDisabled,
        isRootNode,
        depth,
        onClick,
        onKeyDown,
        onContextMenu,
        onMouseEnter,
        onTouchStart,
        onTouchEnd,
        displayName,
        renderCustomTreeNodeDisplay,
        renderRightCharm,
        renderRightCharmHover,
        isinSharedFolderTree,
        isDisabled,
        customTreeNodeTooltip,
        shouldShowFocusBorder,
        customIconColor,
    } = props;
    const treeNodeClasses = classnames(
        isRootNode ? rootNode : regularNode,
        densityModeCssClass,
        roundedCorners,
        isRootNode ? densityRootNodeHeight : densityNodeHeight,
        ellipsesOnHover && isCurrentCultureRightToLeft() ? rtlStyles : ltrStyles,
        shouldShowFocusBorder && focusStyling,
        {
            [styles_showAsHoverOnDroppedOver]: isDroppedOver && showAsHoverOnDroppedOver,
            [showBorderOnDroppedOver]: isDroppedOver && !showAsHoverOnDroppedOver,
            [styles_isBeingDragged]: isBeingDragged,
            [customActionNode]: overridenFont && isCustomActionNode,
            [selected]: isSelected,
            [withContextMenuOpen]: isWithContextMenuOpen,
            [disabledCursor]: isCursorDisabled,
            [styles_isDisabled]: !!isDisabled,
            [ellipsesHovered]: isEllipsesHovered,
        }
    );

    const ariaProps: AriaProperties = {
        role: AriaRoles.treeitem,
        expanded: chevronProps ? chevronProps.isExpanded : undefined,
        selected: !isRootNode ? isSelected : undefined,
        // temporary fix for OW:15414 because it is currently possible for depth to be null causing aria-level to be NaN
        // aria-level is an integer equal to or greater than 1 where 1 is the root
        level: depth == null ? (isRootNode ? 1 : 2) : depth + 1,
        setSize: props.setSize,
        positionInSet: props.positionInSet,
        label: props.ariaLabel,
        disabled: props.ariaIsDisabled,
    };

    const handleMouseEnter = React.useCallback(
        (event: React.MouseEvent<EventTarget, MouseEvent>) => {
            if (ellipsesOnHover) {
                setMountEllipses(true);
            }
            if (onMouseEnter) {
                onMouseEnter(event);
            }
        },
        [setMountEllipses, onMouseEnter]
    );
    const mouseEnterCb = useMouseWithPerfWrapper(handleMouseEnter, 'TreeNode2');

    const handleMouseLeave = React.useCallback(() => {
        if (ellipsesOnHover) {
            setMountEllipses(false);
        }
    }, [setMountEllipses]);
    const mouseLeaveCb = useMouseWithPerfWrapper(handleMouseLeave, 'TreeNode2');

    return (
        <div
            ref={divRef}
            id={props.id}
            style={getContainerStyle()}
            onClick={onClick}
            onKeyDown={onKeyDown}
            onContextMenu={rightClickContextMenu}
            onMouseEnter={
                isFeatureEnabled('fwk-treenode-always-render') ? onMouseEnter : mouseEnterCb
            }
            onMouseLeave={isFeatureEnabled('fwk-treenode-always-render') ? undefined : mouseLeaveCb}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            className={treeNodeClasses}
            data-is-focusable={true}
            title={customTreeNodeTooltip ?? displayName}
            tabIndex={-1}
            {...generateDomPropertiesForAria(ariaProps)}
            data-folder-name={displayName.toLowerCase()}
            {...restoreFocusTargetAttribute}
        >
            {
                // decides which icon to render on the left of the display name
                // We want to give all folders a folder Icon, except the root folders.
                isRootNode ? (
                    <TreeNodeIcon
                        {...{
                            chevronProps,
                            customIconComponent,
                            customIconClassName,
                            idUsedForTooltip,
                            onRenderCustomIcon,
                        }}
                    />
                ) : (
                    <FolderIcon
                        {...{
                            isSelected,
                            chevronProps,
                            customIconComponent,
                            customIconClassName,
                            hideIcon,
                            idUsedForTooltip,
                            isCustomActionNode,
                            onRenderCustomIcon,
                            customIconColor,
                        }}
                    />
                )
            }

            <TreeNodeDisplay
                {...{
                    displayName,
                    isRootNode,
                    isSelected,
                    renderCustomTreeNodeDisplay,
                }}
            />

            <RightCharm
                {...{
                    ellipsesOnHover,
                    isinSharedFolderTree,
                    renderRightCharm,
                    renderRightCharmHover,
                }}
            />
            {(mountEllipses || isFeatureEnabled('fwk-treenode-always-render')) && (
                <EllipsesIcon
                    {...{ ellipsesOnHover, isRootNode, openContextMenu, setIsEllipsesHovered }}
                />
            )}
        </div>
    );
}, 'TreeNode');

const DefaultFolderIcon = bundleIcon(FolderFilled, FolderRegular);
const useStyles = makeStyles({
    icon: {
        [`& .${iconFilledClassName}`]: {
            display: 'none',
        },
        [`& .${iconRegularClassName}`]: {
            display: 'flex',
        },
    },
    iconSelected: {
        [`& .${iconFilledClassName}`]: {
            display: 'flex',
        },
        [`& .${iconRegularClassName}`]: {
            display: 'none',
        },
    },
});

const CustomIcon = observer(function CustomIcon({
    isSelected,
    customIconComponent,
    customIconClassNames,
    hideIcon,
    idUsedForTooltip,
    customIconColor,
}: {
    isSelected?: boolean;
    customIconComponent?: FluentIcon;
    customIconClassNames: string;
    hideIcon?: boolean;
    idUsedForTooltip?: string;
    customIconColor?: string;
}) {
    const densityModeCssClass = getDensityModeCssClass(iconsNextFull, undefined, undefined);
    const styles = useStyles();

    const classNameForIcon = classnames(customIconClassNames, iconsNext, densityModeCssClass);
    const CustomFluentIcon = customIconComponent;
    const customColor = React.useMemo(() => {
        return isFeatureEnabled('fp-folder-colors') && customIconColor
            ? ({ '--customColor': customIconColor } as React.CSSProperties)
            : undefined;
    }, [customIconColor]);
    return (
        <div className={isSelected ? styles.iconSelected : styles.icon} style={customColor}>
            {CustomFluentIcon ? (
                hideIcon ? (
                    <div className={classNameForIcon} />
                ) : (
                    <CustomFluentIcon
                        className={classNameForIcon}
                        id={
                            isFeatureEnabled('acct-multiacctcomingsoon')
                                ? idUsedForTooltip
                                : undefined
                        }
                    />
                )
            ) : (
                <DefaultFolderIcon className={classNameForIcon} />
            )}
        </div>
    );
},
'CustomIcon');

// Make this button hidden to narrator/jaws since all the aria
// attributes are set on the main content. If we dont do this, narrator reads the content twice.
// This button is hidden from aria/tabs but its action can be performed from the main content as well
// We cant make this an icon since there are consumers
// that uses the click handler from the button different from the chevron
const ariaPropsButton: AriaProperties = {
    role: 'button',
    hidden: true,
};

const ChevronIcon = observer(function ChevronIcon({
    chevronProps,
    isRootNode,
}: {
    chevronProps: ChevronProps;
    isRootNode: TreeNodeProps['isRootNode'];
}) {
    const isMultiAccountEnabled = isMonarchMultipleAccountsEnabled();

    const chevronOnClick = React.useCallback(
        (evt: React.MouseEvent) => {
            evt.stopPropagation();
            chevronProps?.onClick(evt);
        },
        [chevronProps?.onClick]
    );

    const chevronAnimationClassNames = classnames(
        chevronAnimation,
        !chevronProps?.isExpanded &&
            getDensityModeCssClass(
                chevronAnimationCollapseFull,
                chevronAnimationCollapse,
                chevronAnimationCollapse
            )
    );

    const chevronIconClassNames = classnames(
        icon,
        regularNodeChevronIcon,
        getDensityModeCssClass(chevronIconFull, chevronIconMedium, chevronIconCompact),
        chevronProps?.iconClassName,
        chevronProps?.isAnimated ? chevronAnimationClassNames : 'flipForRtl'
    );

    const chevronIcon = React.useMemo(() => {
        const ChevronIconToUse = chevronProps?.isAnimated
            ? ChevronDownRegular // expansion is handled by CSS animation
            : chevronProps?.isExpanded
            ? ChevronDownRegular
            : ChevronRightRegular;

        return <ChevronIconToUse className={chevronIconClassNames} fontSize="12px" />;
    }, [chevronProps?.isAnimated, chevronProps?.isExpanded, chevronIconClassNames]);

    const buttonClassName = !(isMultiAccountEnabled && isRootNode)
        ? classnames(
              isMultiAccountEnabled && chevronPadding,
              chevronIconButton,
              getDensityModeCssClass(
                  chevronIconButtonFull,
                  chevronIconButtonMedium,
                  chevronIconButtonCompact
              ),
              chevronProps?.iconButtonClassName
          )
        : classnames(chevronIconButton, chevronPadding);

    return (
        <Button
            appearance="transparent"
            data-is-focusable={false}
            tabIndex={-1}
            className={buttonClassName}
            icon={chevronIcon}
            onClick={chevronOnClick}
            size="small"
            {...generateDomPropertiesForAria(ariaPropsButton)}
        />
    );
},
'ChevronIcon');

const TreeNodeIcon = observer(function TreeNodeIcon({
    chevronProps,
    customIconComponent,
    customIconClassName,
    idUsedForTooltip,
    onRenderCustomIcon,
}: {
    chevronProps: TreeNodeProps['chevronProps'];
    customIconComponent: TreeNodeProps['customIconComponent'];
    customIconClassName: TreeNodeProps['customIconClassName'];
    idUsedForTooltip: TreeNodeProps['idUsedForTooltip'];
    onRenderCustomIcon: TreeNodeProps['onRenderCustomIcon'];
}) {
    // If tree node has a chevron, always display it (even if there is also a custom icon).
    // Otherwise display a custom icon or nothing if no custom icon is passed in.
    if (chevronProps) {
        return <ChevronIcon {...{ chevronProps }} isRootNode={true} />;
    }

    const customIconClassNames = classnames(
        customIconComponent && rootIcon,
        icon,
        customIconClassName
    );

    if (onRenderCustomIcon) {
        return onRenderCustomIcon(customIconClassNames);
    }

    if (customIconComponent) {
        return <CustomIcon {...{ customIconComponent, customIconClassNames, idUsedForTooltip }} />;
    }

    return null; // Existing code should never reach here
},
'TreeNodeIcon');

const FolderIcon = observer(function FolderIcon({
    isSelected,
    chevronProps,
    customIconComponent,
    customIconClassName,
    hideIcon,
    idUsedForTooltip,
    isCustomActionNode,
    onRenderCustomIcon,
    customIconColor,
}: {
    isSelected: TreeNodeProps['isSelected'];
    chevronProps: TreeNodeProps['chevronProps'];
    customIconComponent: TreeNodeProps['customIconComponent'];
    customIconClassName: TreeNodeProps['customIconClassName'];
    hideIcon: TreeNodeProps['hideIcon'];
    idUsedForTooltip: TreeNodeProps['idUsedForTooltip'];
    isCustomActionNode: TreeNodeProps['isCustomActionNode'];
    onRenderCustomIcon: TreeNodeProps['onRenderCustomIcon'];
    customIconColor: TreeNodeProps['customIconColor'];
}) {
    const densityClass = getDensityModeCssClass(full, medium, compact);

    const customIconClassNames = classnames(
        icon,
        isCustomActionNode && !customIconComponent && 'visibilityHidden',
        customIconClassName,
        leftNavAllFoldersPadding,
        densityClass,
        chevronProps && styles_folderWithChevron
    );

    return (
        <div className={folderIcons}>
            {chevronProps && <ChevronIcon {...{ chevronProps }} isRootNode={false} />}

            {onRenderCustomIcon ? (
                onRenderCustomIcon(customIconClassNames, hideIcon)
            ) : (
                <CustomIcon
                    {...{
                        isSelected,
                        customIconComponent,
                        customIconClassNames,
                        hideIcon,
                        idUsedForTooltip,
                        customIconColor,
                    }}
                />
            )}
        </div>
    );
},
'FolderIcon');

const TreeNodeDisplay = observer(function TreeNodeDisplay({
    displayName,
    isRootNode,
    isSelected,
    renderCustomTreeNodeDisplay,
}: {
    displayName: TreeNodeProps['displayName'];
    isRootNode: TreeNodeProps['isRootNode'];
    isSelected: TreeNodeProps['isSelected'];
    renderCustomTreeNodeDisplay: TreeNodeProps['renderCustomTreeNodeDisplay'];
}) {
    const densityModeCssClass = getDensityModeCssClass(full, medium, compact);

    const textClassNames = classnames(
        styles_displayName,
        densityFontSize,
        densityModeCssClass,
        {
            [selected]: isSelected,
        },
        isRootNode && allFolderIconsRootText,
        isRootNode && rootNodeFont
    );

    if (renderCustomTreeNodeDisplay) {
        return renderCustomTreeNodeDisplay(textClassNames);
    }

    return (
        <>
            <span className={textClassNames}>{displayName}</span>
            {isSelected && (
                <span className="screenReaderOnly">
                    {loc(selectedFolderNodeScreenReaderOnlyText)}
                </span>
            )}
        </>
    );
},
'TreeNodeDisplay');

const RightCharm = observer(function RightCharm({
    ellipsesOnHover,
    isinSharedFolderTree,
    renderRightCharm,
    renderRightCharmHover,
}: {
    ellipsesOnHover: TreeNodeProps['ellipsesOnHover'];
    isinSharedFolderTree: TreeNodeProps['isinSharedFolderTree'];
    renderRightCharm: TreeNodeProps['renderRightCharm'];
    renderRightCharmHover: TreeNodeProps['renderRightCharmHover'];
}) {
    const isMultiAccountEnabled = isMonarchMultipleAccountsEnabled();

    /**
     * Hover treatment is optional and hence the CSS classes to hide the rest treatment with onHover treatment
     * should only be applied if hover treatment is specified.
     */
    const rightCharmClassName = classnames(
        styles_rightCharm,
        isMultiAccountEnabled &&
            !isinSharedFolderTree &&
            renderRightCharmHover &&
            rightCharmHoverTreatmentPresent,
        isMultiAccountEnabled && hoverPadding,
        ellipsesOnHover && rightCharmHoverTreatmentPresent
    );

    const rightCharm = renderRightCharm?.();
    const rightCharmHover = renderRightCharmHover?.();

    return (
        <>
            {rightCharm && <span className={rightCharmClassName}>{rightCharm}</span>}
            {rightCharmHover && <span className={styles_rightCharmHover}>{rightCharmHover}</span>}
        </>
    );
},
'RightCharm');

const EllipsesIcon = observer(function EllipsesIcon({
    ellipsesOnHover,
    isRootNode,
    openContextMenu,
    setIsEllipsesHovered,
}: {
    ellipsesOnHover: TreeNodeProps['ellipsesOnHover'];
    isRootNode: TreeNodeProps['isRootNode'];
    openContextMenu: (evt: React.MouseEvent, src: string) => void;
    setIsEllipsesHovered: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const density = getDensityMode();
    const densityModeCssClass = getDensityModeCssClass(full, medium, compact);
    const ellipseClassName = classnames(
        styles_ellipsesOnHover,
        ellipsesOnHover && ellipsesOnHoverTreatmentPresent
    );

    enum ellipsisFontSize {
        Compact = 16,
        Full = 20,
        Simple = 18,
    }
    const ellipsesIconComponent = React.useMemo(() => {
        const fontSize = ellipsisFontSize[density] + 'px';
        return <MoreHorizontalRegular fontSize={fontSize} className={ellipsesPadding} />;
    }, [density]);

    const ellipsesContextMenu = React.useCallback(
        (evt: React.MouseEvent) => {
            openContextMenu(evt, 'Ellipses');
        },
        [openContextMenu]
    );

    const handleMouseEnterEllipses = React.useCallback(() => {
        setIsEllipsesHovered(true);
    }, [setIsEllipsesHovered]);

    const handleMouseLeaveEllipses = React.useCallback(() => {
        setIsEllipsesHovered(false);
    }, [setIsEllipsesHovered]);

    const mouseEnterEllipsesCb = useMouseWithPerfWrapper(handleMouseEnterEllipses, 'TreeNode1');
    const mouseLeaveEllipsesCb = useMouseWithPerfWrapper(handleMouseLeaveEllipses, 'TreeNode1');

    const classes = classnames(ellipsesIcon, isRootNode && rootHover, densityModeCssClass);

    return (
        <span className={ellipseClassName}>
            <Button
                appearance="transparent"
                data-is-focusable={false}
                tabIndex={-1}
                className={classes}
                icon={ellipsesIconComponent}
                onClick={ellipsesContextMenu}
                onMouseEnter={mouseEnterEllipsesCb}
                onMouseLeave={mouseLeaveEllipsesCb}
                {...generateDomPropertiesForAria(ariaPropsButton)}
            />
        </span>
    );
},
'EllipsesIcon');
