import { LazyAction, LazyModule } from 'owa-bundling';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailListItemSharedLazy"*/ './lazyIndex'),
    { name: 'MailListItemSharedLazy' }
);

export const lazyUpdateRsvpInCalendarCard = new LazyAction(
    lazyModule,
    m => m.updateRsvpInCalendarCard
);
