// If we are logging 50 properties with the same name, then it is probably a bug
// and we want to avoid getting stuck in an infinite loop
const SanityCheck = 50;

export function getUniquePropertyString(
    obj: any,
    propertyName: string,
    eventName: string
): string | undefined {
    if (propertyName == null || propertyName == undefined) {
        return undefined;
    }

    let [propertyNamekey, num] = parsePropertyName(propertyName);
    for (var ii = 0; obj[propertyName] != undefined; ii++) {
        propertyName = propertyNamekey + '_' + num;
        num += 1;
        if (ii >= SanityCheck) {
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Error constructor names can only be a string literals.
             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
            throw new Error('GUP: ' + propertyName + '|EventName:' + eventName);
        }
    }

    return propertyName;
}

export function parsePropertyName(propertyName: string) {
    const lastIndex = propertyName.lastIndexOf('_');
    if (lastIndex > -1) {
        const propertyNameKey = propertyName.slice(0, lastIndex);
        const num = parseInt(propertyName.slice(lastIndex + 1));
        if (!isNaN(num)) {
            return [propertyNameKey, num] as const;
        }
    }
    return [propertyName, 1] as const;
}
