import type NotificationViewState from '../store/schema/NotificationViewState';
import { NotificationActionType } from '../store/schema/NotificationViewState';
import removeNotification from '../actions/removeNotification';

const notificationHandlers: {
    [actionType: string]: (notification: NotificationViewState, eventTimestamp?: number) => void;
} = {};

export function ensureNotificationHandlerRegistered(
    actionType: NotificationActionType,
    handler: (notification: NotificationViewState, eventTimestamp?: number) => void
) {
    const actionTypeString = NotificationActionType[actionType];
    if (!notificationHandlers[actionTypeString]) {
        notificationHandlers[actionTypeString] = handler;
    }
}

export function handleNotification(notification: NotificationViewState, eventTimestamp?: number) {
    const handler = notificationHandlers[NotificationActionType[notification.actionType]];
    if (handler) {
        handler(notification, eventTimestamp);
    }
    removeNotification(notification);
}
