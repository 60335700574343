/* Any additions/edit to this file should also make sure to update
 * the logic in owa-layout, owa-mail-layout (or other new consumers) packages
 */

enum BrowserHeightBucket {
    From418_Below = 418,
    From419_To499 = 499,
    From500_To541 = 541,
    From542_To554 = 554,
    From555_To579 = 579,
    From580_To600 = 600,
    From601_To685 = 685,
    From686_To691 = 691,
    From692_To759 = 759,
    From760_To768 = 768,
    From769_To865 = 865,
    From866_To899 = 899,
    From900_To918 = 918,
    From919_To999 = 999,
    From1000_To1079 = 1079,
    From1080_To1199 = 1199,
    From1200_To1238 = 1238,
    From1239_To1300 = 1300,
    From1301_To1365 = 1365,
    From1366_To1399 = 1399,
    From1400_To1599 = 1599,
    From1600_To1699 = 1600,
    From1700_To1800 = 1800,
    From1801_To2000 = 2000,
    From2001_To2200 = 2200,
    From2201_To2400 = 2400,
    From2401_To2600 = 2600,
    From2601_To2800 = 2800,
    From2801_To3000 = 3000,
    From3001_Above = 3001,
}
export default BrowserHeightBucket;
