export const BOX = 'Box';
export const DROPBOX = 'Dropbox';
export const FACEBOOK = 'Facebook';
export const GDRIVE = 'GDrive';
export const MAILBOX = 'Mailbox';
export const MAIL_MESSAGE = 'MailMessage';
export const ONE_DRIVE_CONSUMER = 'OneDriveConsumer';
export const ONE_DRIVE_PRO = 'OneDrivePro';
export const RECENT_ATTACHMENTS = 'RecentAttachments';
export const WOPI = 'Wopi';
export const WOPI_BOX = 'WopiBox';
export const WOPI_DROPBOX = 'WopiDropbox';
export const WOPI_EGNYTE = 'WopiEgnyte';

export const FileProviders = {
    BOX,
    DROPBOX,
    FACEBOOK,
    GDRIVE,
    MAILBOX,
    ONE_DRIVE_CONSUMER,
    ONE_DRIVE_PRO,
    RECENT_ATTACHMENTS,
    WOPI,
    WOPI_BOX,
    WOPI_EGNYTE,
};
