import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { getAppBarPinnedAppIdsFromUserSettings } from './getAppBarPinnedAppIdsFromUserSettings';
import type PrimeBootSetting from 'owa-service/lib/contract/PrimeBootSetting';
import type { StrictTitle } from '../types';
import type { MailboxInfo } from 'owa-client-types';
import { EPOCH_TIME } from '../data/constants/constants';
import { EMPTY_COPILOT_CAPABILITIES } from '../data/constants/emptyCapability';

const PRIMARY_COLOR = '#0078D4';

interface AppBarTile {
    appId: string;
    name: string;
    largeImageUrl: string;
    outlineImageUrl: string;
}

export function getPinnedAppBarTitles(
    staticTitles: StrictTitle[],
    mailboxInfo?: MailboxInfo
): StrictTitle[] {
    const userTiles = getAllAppBarTiles(mailboxInfo);
    const pinnedAppsSetting = getAppBarPinnedAppIdsFromUserSettings(mailboxInfo);
    if (!pinnedAppsSetting || userTiles.length === 0) {
        return [];
    }
    const { lockedApps, pinnedApps } = pinnedAppsSetting;
    const allPinnedApps = [...lockedApps, ...pinnedApps];
    const dedupedPinnedApps = [...new Set(allPinnedApps)];

    const bootstrapTitles: StrictTitle[] = [];

    for (const pinnedAppId of dedupedPinnedApps) {
        //If the app is already in the static titles, skip it.
        if (
            staticTitles.find((staticTitle: StrictTitle) => staticTitle.ingestionId === pinnedAppId)
        ) {
            continue;
        }

        // Otherwise, if it's in the user tiles store, add it to the output.
        const tileData = userTiles.find(tile => tile.appId === pinnedAppId);
        if (tileData) {
            bootstrapTitles.push(createStubTitle(tileData));
        }
    }
    return bootstrapTitles;
}

/**
 * Note that we're getting these values from the UserConfiguration object instead
 * of from the "owa-outlook-service-options" package for boot bundle size health.
 */
function getAllAppBarTiles(mailboxInfo?: MailboxInfo): AppBarTile[] {
    const primeSettingsItems = getAccountScopeUserSettings(
        mailboxInfo ?? getGlobalSettingsAccountMailboxInfo()
    )?.PrimeSettings?.Items;
    const appBarTilesOption: PrimeBootSetting[] | undefined = primeSettingsItems?.filter(
        (item: PrimeBootSetting) => item?.Id == 'AppBarTiles'
    );
    return appBarTilesOption?.[0]?.Value ?? [];
}

function createStubTitle(tile: AppBarTile): StrictTitle {
    return {
        __typename: 'Title',
        ingestionId: tile.appId,
        name: tile.name,
        acquisitionDate: EPOCH_TIME,
        categories: [],
        longDescription: '',
        shortDescription: '',
        iconSmall: {
            __typename: 'M365AcquisitionIcon',
            uri: tile.largeImageUrl,
        },
        iconLarge: {
            __typename: 'M365AcquisitionIcon',
            uri: tile.largeImageUrl,
        },
        iconOutline: {
            __typename: 'M365AcquisitionIcon',
            uri: tile.outlineImageUrl,
        },
        elementDefinitions: {
            __typename: 'ElementDefinition',
            composeExtensions: [],
            exchangeAddIns: [],
            extensions: [],
            staticTabs: [
                {
                    __typename: 'LaunchPage',
                    entityId: 'bootstrap',
                    contentUrl: '',
                    name: tile.name,
                    scopes: [],
                    websiteUrl: '',
                },
            ],
        },
        accentColor: PRIMARY_COLOR,
        isFullScreen: false,
        isFullTrust: false,
        developerName: '',
        developerUrl: '',
        termsOfUseUrl: '',
        permissions: [],
        validDomains: [''],
        manifestVersion: '0.0.0',
        cultureName: 'en-us',
        showLoadingIndicator: true,
        blockStatus: false,
        ingestionSource: 'UserPinnedAcquisition',
        authorization: {
            __typename: 'AppAuthorization',
            permissions: {
                __typename: 'AppPermissions',
                orgWide: [],
                resourceSpecific: [],
            },
        },
        copilotEnabled: false,
        copilotSettings: {
            __typename: 'CopilotSettings',
            displayName: '',
            elementType: 'Extension',
            capabilities: EMPTY_COPILOT_CAPABILITIES,
        },
        scope: 'Tenant',
        webApplicationInfo: {
            __typename: 'WebApplicationInfo',
            id: tile.appId,
            resource: '',
        },
        version: '0.0.0',
    };
}
