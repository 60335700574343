import groupHeaderCommandBarStore from 'owa-group-header-store/lib/store/CommandBarStore';
import GroupHeaderNavigationButton from 'owa-group-header-store/lib/store/schema/NavigationButton';
import loadInitialMailTable from './loadInitialMailTable';
import type { TableView } from 'owa-mail-list-store';
import type { ActionSource } from 'owa-mail-store';
import type { OnInitialTableLoadComplete } from 'owa-mail-loading-action-types';
import { Module } from 'owa-workloads';

/**
 * Performs the first load for a group folder table
 * @param tableView - the given mail tableView
 * @param onInitialTableLoadComplete - callback for when the initial table load has completed
 * @param actionSource action that initiated the switch folder action
 * @return a promise that resolves when the load initial table has completed either from server or from cache
 */
export default function loadInitialGroupFolderTable(
    tableView: TableView,
    onInitialTableLoadComplete: OnInitialTableLoadComplete,
    actionSource: ActionSource,
    moduleContext?: Module
): Promise<void> {
    const selectedNavButton = groupHeaderCommandBarStore.navigationButtonSelected;

    // If we are booting into a non-email module, do nothing
    let promiseToReturn = Promise.resolve();

    if (
        selectedNavButton === GroupHeaderNavigationButton.Email ||
        moduleContext === Module.Groups
    ) {
        promiseToReturn = loadInitialMailTable(tableView, onInitialTableLoadComplete, actionSource);
    }

    return promiseToReturn;
}
