import { getLogicalRing } from 'owa-config/lib/getLogicalRing';
import { isFeatureEnabled } from 'owa-feature-flags';

import type { MailboxInfo } from 'owa-client-types';

/**
 * Places MetaOS app is enabled if user is in dogfood and places-web-app-enabled flight is enabled.
 */
export function isMosPlacesEnabledForSdf(mailboxInfo?: MailboxInfo) {
    const { isFlightEnabled, isDogfood } = getParameters(mailboxInfo);
    return isFlightEnabled && isDogfood;
}

/**
 * Places MetaOS app is enabled if user places-web-app-enabled flight is enabled.
 */
export function isMosPlacesEnabledForProd(mailboxInfo?: MailboxInfo) {
    const { isFlightEnabled } = getParameters(mailboxInfo);
    return isFlightEnabled;
}

function getParameters(mailboxInfo?: MailboxInfo) {
    return {
        isFlightEnabled:
            isFeatureEnabled('places-web-app-enabled', mailboxInfo) &&
            isFeatureEnabled('places-mos-owa-enabled', mailboxInfo),
        isDogfood: getLogicalRing() === 'Dogfood',
    };
}
