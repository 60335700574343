import { optionTitle_textSizeSpacing } from './DisplayDensityOption.locstring.json';
import type { LazyOption } from 'owa-options-core';
import { createLazyOption } from 'owa-options-core';
import { LazyModule } from 'owa-bundling';
const lazyModule = new LazyModule(() => import('./lazyIndex'));
const titleString = optionTitle_textSizeSpacing;
const DisplayDensityOption: LazyOption = createLazyOption(
    {
        key: 'Density',
        titleStringKey: titleString,
        searchTermsStringKey: titleString,
    },
    lazyModule,
    m => m.fullOption
);
export default DisplayDensityOption;
