import { type OwaWorkload, getOwaWorkload } from 'owa-workloads';
import { orchestrator } from 'satcheljs';
import { onLocaleChanged, getCurrentLanguage } from 'owa-localize';
import { getQueryStringParameter, hasQueryStringParameter } from 'owa-querystring';
import { isGulping } from 'owa-config';

export function initializeManifest() {
    updateManifestLink(getCurrentLanguage());
}

function updateManifestLink(culture: string) {
    const optOut = 16777216 | 16384 | 4194304;
    const initPwa = (optOut & getOwaWorkload()) == 0;
    if (initPwa && !isGulping()) {
        let newManifestUrl = `/mail/manifests/pwa.json?culture=${culture}`;
        if (hasQueryStringParameter('branch')) {
            newManifestUrl += `&branch=${getQueryStringParameter('branch')}`;
        }
        updateOrAddManifestLink(newManifestUrl);
    }
}

function updateOrAddManifestLink(newManifestUrl: string) {
    const elem = document.head.querySelector("link[rel='manifest']") as HTMLLinkElement;
    if (elem) {
        elem.href = newManifestUrl;
    } else {
        const newLinkElement = document.createElement('link');
        newLinkElement.rel = 'manifest';
        newLinkElement.href = newManifestUrl;
        document.head.appendChild(newLinkElement);
    }
}

orchestrator(onLocaleChanged, ({ locale }) => updateManifestLink(locale));
