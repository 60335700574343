import { createLazyComponent, LazyAction, LazyModule } from 'owa-bundling';
import { getRepromptStore } from './store/store';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "RepromptDialog" */ './lazyIndex'),
    { name: 'RepromptDialog' }
);

export const lazyShowRepromptDialogIfRequired = new LazyAction(
    lazyModule,
    m => m.showRepromptDialogIfRequired
);

export const RepromptDialog = createLazyComponent(
    lazyModule,
    m => m.RepromptDialog,
    undefined,
    undefined,
    {
        onlyImportIf: () => getRepromptStore().showPrivacyRepromptDialog,
    }
);
