import getFolderTable from '../../selectors/getFolderTable';
import type { MailFolder, MailFolder as Schema_MailFolder } from 'owa-graph-schema';

/**
 * Creates the Folder by adding client specific properties
 *
 * @param rawFolder Folder object
 */
export function createOrGetFolder(rawFolder: Schema_MailFolder): MailFolder {
    const folder = getFolderTable().get(rawFolder.id);
    if (folder) {
        return folder;
    }

    return {
        childFolderIds: [],
        ...rawFolder,
    };
}
