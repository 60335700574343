import type { MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { validateDumpsterQuota } from '../actions/validateDumpsterQuota';

export function tryValidateDumpsterQuota(
    mailboxInfo: MailboxInfo,
    folderId: string,
    targetWindow?: Window,
    suppressPopup: boolean = false
) {
    if (
        folderId &&
        folderIdToName(folderId) === 'deleteditems' &&
        getAccountScopeUserSettings(mailboxInfo).SessionSettings?.IsDumpsterOverQuota
    ) {
        if (!suppressPopup) {
            validateDumpsterQuota(targetWindow);
        }

        return true;
    }
    return false;
}
