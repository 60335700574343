import type NotesStore from './schema/NotesStore';
import { createStore } from 'satcheljs';

const store = createStore<NotesStore>('notesStore', <NotesStore>{
    isPanelInitialized: false,
    isPanelOpen: false,
    sessionId: undefined,
    panelOpenTimestamp: undefined,
    source: undefined,
    selectedNoteId: undefined,
    selectedNoteColor: undefined,
})();

export default () => store;
