import { getSuggestedUnsubscribeStore } from '../store/Store';
import type { MailboxInfo } from 'owa-client-types';
import type SuggestedUnsubscribeItem from '../store/schema/SuggestedUnsubscribeItem';

/**
 * Determine if a rowId is associated with a suggested unsubscribe item
 *
 * @param rowId Identifier of the message
 * @param mailboxInfo MailboxInfo of the selected account
 *
 * @returns True if there is a suggested unsubscribe item associated with the rowKey
 *          False otherwise
 */
export default function isSuggestedUnsubscribeItem(
    rowId: string,
    mailboxInfo: MailboxInfo
): boolean {
    const suggestedUnsubscribeStore = getSuggestedUnsubscribeStore(mailboxInfo);
    return suggestedUnsubscribeStore.suggestedUnsubscribeItems.some(
        (suggestedUnsubscribeItem: SuggestedUnsubscribeItem) =>
            suggestedUnsubscribeItem.itemId === rowId ||
            suggestedUnsubscribeItem.conversationId === rowId
    );
}
