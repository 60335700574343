import createDataProviderInfoMapping from './createDataProviderInfoMapping';
import shouldPreviewGoogleDoc from '../../utils/shouldPreviewGoogleDoc';
import { assertNever } from 'owa-assert';
import type AttachmentDataProviderType from 'owa-service/lib/contract/AttachmentDataProviderType';
import type AttachmentPermissionLevel from 'owa-service/lib/contract/AttachmentPermissionLevel';
import type ReferenceAttachment from 'owa-service/lib/contract/ReferenceAttachment';
import type { MailboxInfo } from 'owa-client-types';

export interface AttachmentDataProviderInfo {
    supportDownload: boolean;
    supportImageThumbnail: boolean;
    supportPreview: (attachment?: ReferenceAttachment, isConsumer?: boolean) => boolean;
    supportedPermissionLevels: AttachmentPermissionLevel[];
    requiresFetchingSharingInformation: boolean;
}

function createDefaultAttachmentDataProviderInfo(): AttachmentDataProviderInfo {
    return {
        supportDownload: true,
        supportImageThumbnail: false,
        supportPreview: (_, __) => true,
        supportedPermissionLevels: [],
        requiresFetchingSharingInformation: false,
    };
}

function createDataProviderOneDriveProInfo(): AttachmentDataProviderInfo {
    return {
        ...createDefaultAttachmentDataProviderInfo(),
        supportedPermissionLevels: [2, 1],
        requiresFetchingSharingInformation: true,
    };
}

function createDataProviderOneDriveConsumerInfo(): AttachmentDataProviderInfo {
    return {
        ...createDefaultAttachmentDataProviderInfo(),
        supportImageThumbnail: true,
        supportPreview: (_, isConsumer) => !!isConsumer,
        supportedPermissionLevels: [2, 1],
    };
}

function createDataProviderGDriveInfo(): AttachmentDataProviderInfo {
    return {
        ...createDefaultAttachmentDataProviderInfo(),
        supportDownload: false,
        supportImageThumbnail: false,
        supportPreview: attachment => (!!attachment ? shouldPreviewGoogleDoc(attachment) : false),
        supportedPermissionLevels: [4, 3],
    };
}

function createDataProviderBoxInfo(): AttachmentDataProviderInfo {
    return {
        ...createDefaultAttachmentDataProviderInfo(),
        supportDownload: false,
        supportPreview: (_, __) => false,
    };
}

function createDataProviderDropboxInfo(): AttachmentDataProviderInfo {
    return {
        ...createDefaultAttachmentDataProviderInfo(),
        supportPreview: (_, __) => false,
    };
}

function createDataProviderWopiInfo(): AttachmentDataProviderInfo {
    return {
        ...createDefaultAttachmentDataProviderInfo(),
        supportDownload: false,
        supportPreview: (_, __) => false,
    };
}

function createDataProviderMailboxInfo(): AttachmentDataProviderInfo {
    return {
        supportDownload: false,
        supportImageThumbnail: false,
        supportPreview: (_, __) => false,
        supportedPermissionLevels: [],
        requiresFetchingSharingInformation: false,
    };
}

function createDataProviderFacebookInfo(): AttachmentDataProviderInfo {
    return {
        supportDownload: false,
        supportImageThumbnail: true,
        supportPreview: (_, __) => false,
        supportedPermissionLevels: [],
        requiresFetchingSharingInformation: false,
    };
}

function createDataProviderInfo(
    providerType: AttachmentDataProviderType,
    _mailboxInfo: MailboxInfo
): AttachmentDataProviderInfo {
    switch (providerType) {
        case 0:
            return createDataProviderOneDriveProInfo();
        case 1:
            return createDataProviderOneDriveConsumerInfo();
        case 3:
            return createDataProviderBoxInfo();
        case 2:
            return createDataProviderDropboxInfo();
        case 6:
            return createDataProviderFacebookInfo();
        case 5:
            return createDataProviderGDriveInfo();
        case 7:
        case 8:
        case 9:
            return createDataProviderWopiInfo();
        case 4:
        case 10:
            return createDataProviderMailboxInfo();
        default:
            return assertNever(providerType);
    }
}

const { getDataProviderInfo, getDefaultDataProviderInfo } =
    createDataProviderInfoMapping<AttachmentDataProviderInfo>(createDataProviderInfo);

export default getDataProviderInfo;
export { getDefaultDataProviderInfo };
