import { createStore } from 'satcheljs';
import type { PersonaPhotoState, PersonaPhotoStateByMailboxInfo } from './schema/PersonaPhotoState';
import { ObservableMap } from 'mobx';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

const store = createStore<PersonaPhotoStateByMailboxInfo>('personaPhotoStore', {
    personaPhotoStateByMailboxInfo: new ObservableMap<string, PersonaPhotoState>(),
})();

export function getPersonaPhotoUrlsMap(mailboxInfo: MailboxInfo): ObservableMap<string, string> {
    const index = getIndexerValueForMailboxInfo(mailboxInfo);
    const personaPhotoState = store.personaPhotoStateByMailboxInfo.get(index);

    if (!personaPhotoState) {
        const defaultPersonaPhotoUrls = {
            personaPhotoUrls: new ObservableMap<string, string>(),
        };
        store.personaPhotoStateByMailboxInfo.set(index, defaultPersonaPhotoUrls);

        return defaultPersonaPhotoUrls.personaPhotoUrls;
    }

    return personaPhotoState.personaPhotoUrls;
}
