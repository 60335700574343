import { lazyGetAssignPolicySections } from 'owa-mail-ribbon-sections/lib/sections';

import type { MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onAssignPolicyMenuClicked = (
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
) => {
    lazyGetAssignPolicySections.import().then(getAssignPolicySections => {
        setMenuDefinition('AssignPolicyMenuDefinitionStore', props.projectionTabId, () => {
            return {
                sections: getAssignPolicySections(keytipTabId, props),
            };
        });
    });
};
