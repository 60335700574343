import { lazyGetOfflineTimeZoneOffsets, lazyGetOfflineTimeZones } from 'owa-data-worker-client';
import { timeZonesGetter } from './timeZonesGetter';

function getTimeZones() {
    return timeZonesGetter(lazyGetOfflineTimeZones.importAndExecute);
}

function getTimeZoneOffsets() {
    return timeZonesGetter(lazyGetOfflineTimeZoneOffsets.importAndExecute);
}

export const getTimeZoneOverrides = {
    getTimeZones,
    getTimeZoneOffsets,
};
