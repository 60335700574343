import type { MailboxInfo } from 'owa-client-types';
import type { CoprincipalAccountSource } from 'owa-account-source-list-store';
import type { RemovedAccount } from 'owa-account-source-list-types';
import {
    getAccountsRemovedThisSession,
    getCoprincipalAccountForMailboxInfo,
} from 'owa-account-source-list-store';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { MailboxInfoError } from '../MailboxInfoError';

/**
 * Gets the M365 user account for the mailbox info within the coprincipal account
 * @param mailboxInfoInAccount Used to determine the coprincipal account, see getIndexerForMailboxInfo for more details
 * @returns M365 user account associated with the account
 */
export default function getCoprincipalAccount(
    mailboxInfoInAccount: MailboxInfo
): CoprincipalAccountSource | RemovedAccount {
    const account = getCoprincipalAccountForMailboxInfo(mailboxInfoInAccount);
    if (account) {
        return account;
    }

    // We did not find the account, determine if it was a previously removed account
    const accountsRemovedThisSession = getAccountsRemovedThisSession();
    const accountIndexer = getIndexerValueForMailboxInfo(mailboxInfoInAccount);
    const removedAccounts = accountsRemovedThisSession.filter(
        item => getIndexerValueForMailboxInfo(item.mailboxInfo) === accountIndexer
    );

    if (removedAccounts.length > 0) {
        // The account was removed this session, because the current code relies on being able
        // to set a MailboxInfo even when the account has been removed we return enough information
        // about the account to be able to create a MailboxInfo for it even if deleted.
        return removedAccounts[0];
    }

    // The mailboxInfoInAccount does not belong to a known account
    throw new MailboxInfoError('Coprincipal account does not exist');
}
