import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';

/**
 * Whether or not the mailbox supports showing message details
 */
export const viewMessageDetailsCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        // View message details is always enabled
        return true;
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        return (
            !mailboxInfo /*allowed by default*/ || isServiceRequestSupportedForMailbox(mailboxInfo)
        );
    },
};
