import { isFeatureEnabled } from 'owa-feature-flags';
import { getAccountScopeUserSettings, isConsumer } from 'owa-session-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { getMailboxInfoFromFolderId } from 'owa-mail-mailboxinfo/lib/getMailboxInfo';

export function isSenderScreeningEnabledForFolder(folderId: string): boolean {
    const mailboxInfo = getMailboxInfoFromFolderId(folderId);

    return (
        isConsumer(undefined, mailboxInfo) &&
        isFeatureEnabled('tri-sender-screening', mailboxInfo) &&
        folderIdToName(folderId) === 'inbox' &&
        (getAccountScopeUserSettings(mailboxInfo).UserOptions?.IsSenderScreeningSettingEnabled ??
            false)
    );
}
