import type FolderId from 'owa-service/lib/contract/FolderId';
import type { CalendarEntry, LocalCalendarEntry, LinkedCalendarEntry } from 'owa-graph-schema';

export function getCalculatedFolderId(calendar: CalendarEntry | LocalCalendarEntry): FolderId {
    if ((calendar as LocalCalendarEntry).FolderId) {
        // local calendar entry
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (10,9): Type 'Maybe<FolderId> | undefined' is not assignable to type 'FolderId'.
        // @ts-expect-error
        return (calendar as LocalCalendarEntry).FolderId;
    }
    const linkedCalendarEntry: LinkedCalendarEntry = calendar as LinkedCalendarEntry;
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (16,5): Type '{ Id: Maybe<string> | undefined; ChangeKey: string; } | null' is not assignable to type 'FolderId'.
    // @ts-expect-error
    return linkedCalendarEntry.IsGeneralScheduleCalendar
        ? { Id: linkedCalendarEntry.OwnerEmailAddress, ChangeKey: '' }
        : linkedCalendarEntry.SharedFolderId
        ? {
              Id: linkedCalendarEntry.SharedFolderId.Id,
              ChangeKey:
                  linkedCalendarEntry.SharedFolderId.ChangeKey != null
                      ? linkedCalendarEntry.SharedFolderId.ChangeKey
                      : '',
          }
        : null;
}
