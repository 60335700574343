import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';
import isCopilotFeatureEnabled from './isCopilotFeatureEnabled';
import { getApplicationSettings } from 'owa-application-settings';
import { isFeatureEnabled } from 'owa-feature-flags';
import { logUsage } from 'owa-analytics';
import { trace } from 'owa-trace';
import { getItem, setItem } from 'owa-local-storage';
import { isCopilotStoreReady } from 'owa-copilot-settings-store';

/**
 * @param mailboxInfo Mailbox info
 * @returns true if MChat is enabled, false if the feature is disabled, undefined if the feature is in progress or not started
 * based on the feature flags and OCS license check
 */
export default function isMChatEnabled(mailboxInfo: MailboxInfo): boolean | undefined {
    if (isFeatureEnabled('immersive-bizchat-license-check')) {
        const hasCopilotBusinessChatLicense = isMChatFeatureEnabled(mailboxInfo);
        logUsage('hasCopilotBusinessChatLicense', { hasCopilotBusinessChatLicense });
        trace.info(
            'License Check Feature enabled and HasCopilotBusinessChatLicense: ' +
                hasCopilotBusinessChatLicense
        );
        return hasCopilotBusinessChatLicense;
    } else if (
        isFeatureEnabled('immersive-bizchat-feature-a100-users') ||
        isFeatureEnabled('immersive-bizchat-feature')
    ) {
        trace.info('Allow List Validation Successful, Enabling CopilotChat');
        return true;
    } else {
        trace.info('Validation Unsuccessful, disabling CopilotChat');
        return false;
    }
}

/**
 * @param mailboxInfo Mailbox info
 * @param skipLanguageCheck  whether to skip language check. Default is true.
 * @returns true if the MChat Copilot feature is enabled through OCS, false if the feature is disabled, undefined if the feature is in progress or not started
 */
export function isMChatFeatureEnabled(
    mailboxInfo: MailboxInfo,
    skipLanguageCheck?: boolean
): boolean | undefined {
    const copilotStoreReady = isCopilotStoreReady(mailboxInfo);
    const indexer = getIndexerValueForMailboxInfo(mailboxInfo);
    const key = 'isMChatFeatureEnabled' + indexer;

    if (!copilotStoreReady) {
        const cachedMChatFeatureEnabled = getItem(window, key);
        return cachedMChatFeatureEnabled === 'true';
    }

    const enableMultiLanguage =
        skipLanguageCheck ?? getApplicationSettings('Copilot', mailboxInfo).enableMultiLanguage;

    const ocsMChatFeatureEnabled = isCopilotFeatureEnabled(
        'MChat',
        mailboxInfo,
        enableMultiLanguage
    );
    setItem(window, key, (ocsMChatFeatureEnabled ?? false).toString());

    return ocsMChatFeatureEnabled;
}
