import type { MailboxInfo } from 'owa-client-types';
import getLeftNavGroupFoldersStore from '../selectors/getLeftNavGroupFoldersStore';
import type { GroupFolderHierarchy } from '../store/schema/GroupFolderHierarchy';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

/**
 * @param groupId Smtp address of the group
 * @param mailboxInfo mailbox info of the account
 * @returns Group folder heirarchy corresponding to the specified group
 */
export function getGroupFolderHierarchy(
    groupId: string,
    mailboxInfo: MailboxInfo
): GroupFolderHierarchy | undefined {
    return getLeftNavGroupFoldersStore(mailboxInfo).folderTable.get(transformGroupSmtp(groupId));
}
