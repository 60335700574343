import { getStore } from '../store/Store';
import {
    getIsBitSet,
    type ListViewBitFlagsMasks,
} from 'owa-bit-flags/lib/utils/listViewBitFlagsUtil';
import type { TableView } from 'owa-mail-list-store';
import { isConversationView } from 'owa-mail-list-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getListViewColumnDefaultWidths } from '../utils/getListViewColumnDefaultWidths';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getDensityModeString } from 'owa-fabric-theme';

export default function getListViewColumnWidths(tableView?: TableView): {
    senderColumnWidth: number;
    subjectColumnWidth: number;
    receivedColumnWidth: number;
} {
    const isRelocateHoverActionsFlightEnabled = isFeatureEnabled('tri-mlRelocateHoverActions');
    const defaultColumnWidths = getListViewColumnDefaultWidths();

    const defaultSenderColumnWidth = isRelocateHoverActionsFlightEnabled
        ? defaultColumnWidths.senderColumnWidth
        : 332;
    const defaultSubjectColumnWidth = isRelocateHoverActionsFlightEnabled
        ? defaultColumnWidths.subjectColumnWidth
        : 1000;
    const defaultReceivedColumnWidth = isRelocateHoverActionsFlightEnabled
        ? defaultColumnWidths.receivedColumnWidth
        : 150;

    let senderColumnWidth = getStore().senderColumnWidth || defaultSenderColumnWidth;
    let subjectColumnWidth = getStore().subjectColumnWidth || defaultSubjectColumnWidth;
    let receivedColumnWidth = getStore().receivedColumnWidth || defaultReceivedColumnWidth;

    const isSenderImageOff = getIsBitSet(8, getGlobalSettingsAccountMailboxInfo());
    const _isConversationView = tableView && isConversationView(tableView);

    if (isRelocateHoverActionsFlightEnabled) {
        senderColumnWidth += getDensityModeString() === 'full' ? 63 : 59;
        // remove 3 px for column spacing
        subjectColumnWidth -= 3;
    } else {
        if (_isConversationView && !isSenderImageOff) {
            senderColumnWidth += 68;
        }

        if (_isConversationView && isSenderImageOff) {
            senderColumnWidth += 61;
        }

        if (!_isConversationView && !isSenderImageOff) {
            senderColumnWidth += 48;
        }

        if (!_isConversationView && isSenderImageOff) {
            senderColumnWidth += 52;
        }
        // Remove 6 pixels for column spacing
        subjectColumnWidth -= 6;

        // Remove 44px for big hover action spacing
        receivedColumnWidth -= 44;
    }

    return {
        senderColumnWidth,
        subjectColumnWidth,
        receivedColumnWidth,
    };
}
