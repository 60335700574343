import type { ShellFlexPane, CustomShellFlexPane } from '@1js/suiteux-shell-react';
import { triggerResizeEvent } from 'owa-resize-event';
import { flexPaneClosed, flexPaneOpened } from 'owa-suite-header-apis';
import { setIsFlexPaneShown } from 'owa-suite-header-store';

function createOnFlexPaneOpenCloseCallback(
    flexPaneId: string,
    isOpen: boolean,
    callback?: () => void
): () => void {
    return function () {
        triggerResizeEvent();
        setIsFlexPaneShown(flexPaneId, isOpen);
        isOpen ? flexPaneOpened(flexPaneId) : flexPaneClosed(flexPaneId);
        if (callback) {
            callback();
        }
    };
}

export default function createShellFlexPane(flexPane: ShellFlexPane): CustomShellFlexPane {
    return {
        ...flexPane,
        onFlexPaneOpen: createOnFlexPaneOpenCloseCallback(
            flexPane.flexPaneID,
            true /* isOpen */,
            (flexPane as CustomShellFlexPane).onFlexPaneOpen
        ),
        onFlexPaneClose: createOnFlexPaneOpenCloseCallback(
            flexPane.flexPaneID,
            false /* isOpen */,
            (flexPane as CustomShellFlexPane).onFlexPaneClose
        ),
    } as CustomShellFlexPane;
}
