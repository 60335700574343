import type { MailRibbonTabId } from 'owa-ribbon-ids';
import type { TabScalingAndLayoutConfig } from './tabScalingAndLayoutConfig';
import type { ObservableMap } from 'mobx';

const tabNames = [
    'homeTab',
    'viewTab',
    'messageTab',
    'insertTab',
    'formatTab',
    'notesTab',
    'tableFormatTab',
    'readTab',
    'optionsTab',
    'helpTab',
    'drawTab',
    'pictureTab',
] as const;
export type TabNameType = typeof tabNames[number];
export const isTabNameType = (value: any): value is TabNameType => tabNames.includes(value);

export type RibbonConfiguration = Record<TabNameType, TabScalingAndLayoutConfig>;

/**
 * This interface holds, for each tab, information about its configuration. This information
 * consists of the layout of its groups and each group's sub-controls, which controls
 * are in the overflow menu, and which groups have been created but not used by the user.
 *
 * @param version is the version that defaultConfig is on, which is whatever @see {ribbonVersionManager] length is.
 *          Used in versioning, @see {loadMailRibbonConfigurationOrchestrator} for logic in versioning.
 * @param multiline tabs for MLR mode in Ribbon
 * @param singleline tabs for SLR mode in Ribbon
 * @param toolbar tabs for toolbar mode in Ribbon
 * @param contextualTabs Tabs that are not going to be shown in the ribbon by default
 */
export interface MailRibbonConfigStore {
    version: number;
    multiline: RibbonConfiguration;
    singleline: RibbonConfiguration;
    contextualTabs: MailRibbonTabId[];
    // Unlike other tabs, contextual tabs could vary depending on specific 'context' (e.g tables/pictures) on different editors
    // Therefore, it should be aware which editor it resides, so we add the editorId as the key
    contextualTabsMultiWindows: ObservableMap<string, MailRibbonTabId[]>;
}
