import loadTableViewFromTableQuery from './loadTableViewFromTableQuery';
import { getDefaultInboxTableQuery } from 'owa-mail-triage-table-utils/lib/getDefaultInboxTableQueries';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import type { MailboxInfo } from 'owa-client-types';
import { Module } from 'owa-workloads';
import { registerLoadDefaultTable } from 'owa-mailhandling-option';

// Function that loads the default table view.
export function loadDefaultTable(mailboxInfo: MailboxInfo, sessionData?: SessionData) {
    // Load default inbox table
    loadTableViewFromTableQuery(
        getDefaultInboxTableQuery(mailboxInfo),
        undefined /* loadTableViewDatapoint */,
        undefined /* actionSource */,
        sessionData,
        undefined /* onAnswerResponseReceived */,
        undefined /* substrateAnswerRequest */,
        undefined /* substrateAnswersClientFlights */,
        Module.Mail
    );
}

registerLoadDefaultTable(loadDefaultTable);
