import type { ProjectionItem } from '../store/schema/PopoutParentStore';
import { PROJECTION_ROOT_ID, PROJECTION_LOADING_ID } from './constants';

export function getProjectionRoot(projection: ProjectionItem | undefined) {
    return projection?.window.document.getElementById(PROJECTION_ROOT_ID);
}

export function getProjectionLoading(projection: ProjectionItem) {
    return projection?.window.document.getElementById(PROJECTION_LOADING_ID);
}
