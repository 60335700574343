import { ScenarioType } from '../schema/Scenario';
import { getAccountByMailboxInfo } from 'owa-account-source-list-store';
import { AccountSourceType } from 'owa-account-source-list-types';
import type { MailboxInfo } from 'owa-client-types';

export function getScenarioType(mailboxInfo: MailboxInfo): ScenarioType {
    const accountSource = getAccountByMailboxInfo(mailboxInfo);
    switch (accountSource?.sourceType) {
        case AccountSourceType.Office365:
            return ScenarioType.Enterprise;
        case AccountSourceType.OutlookDotCom:
            return ScenarioType.Consumer;
        case AccountSourceType.Google:
        case AccountSourceType.Yahoo:
        case AccountSourceType.IMAP:
        case AccountSourceType.ICloud:
        default:
            return ScenarioType.Enterprise; // default to enterprise for now, because getToken APIs haven't added handling for ScenarioType.ShadowMailbox
    }
}
