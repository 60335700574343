import type { MailRibbonTabId, RibbonControlId } from 'owa-ribbon-ids';
import type {
    RibbonControlDefinitionProps,
    ComposeRibbonControlDefProps,
    ReadOnlyRibbonControlDefProps,
} from 'owa-mail-ribbon-utils';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { MailRibbonGroup } from 'owa-mail-ribbon-store-shared-types';

import {
    getComposeRuntimeControls,
    getReadRuntimeControls,
    isGroupRuntimeControlEnabled,
    isGroupRuntimeControlEnabledForSpamIntegration,
    isReadGroupRuntimeControlEnabled,
} from './getRuntimeControls';
import { owaComputedFn } from 'owa-computed-fn';
import pushRibbonControl from './pushRibbonControl';
import { shouldShowRibbonControl } from 'owa-mail-ribbon-utils/lib/shouldShowRibbonControl';
import { getReadSurfaceRuntimeControls } from './getReadSurfaceRuntimeControls';
import { isFeatureEnabled } from 'owa-feature-flags';
import { areMessageExtensionsEnabledOnReadRibbon } from 'owa-feature-flags/lib/utils/areMessageExtensionsEnabledOnReadRibbon';
/**
 * This function returns an array of the controls to display.
 * For any logic on determining when to show/hide a control, please put it in the helper function `shouldShowRibbonControl`.
 * @param tabId MailRibbonTabId of the calling tab
 * @param controlIds list of all valid controls to be tested to see if should display
 * @param getControlDefinition function that returns the control definition given a controlId
 * @param props Properties for MailRibbonIdToControlMap
 * @returns array of RibbonControlProps which are the controls that should be shown in this group.
 */
export const getControlsToDisplay = owaComputedFn(function getControlsToDisplay<
    T extends RibbonControlDefinitionProps
>(
    tabId: MailRibbonTabId,
    group: MailRibbonGroup,
    getControlDefinition: (
        controlId: RibbonControlId,
        tabId: MailRibbonTabId,
        props: T
    ) => RibbonControlProps | undefined,
    props: T
): RibbonControlProps[] {
    const menuItems: RibbonControlProps[] = new Array();

    for (const controlId of group.controlIds) {
        if (shouldShowRibbonControl(tabId, controlId, props, false /* isCustomizer */)) {
            pushRibbonControl(menuItems, controlId, tabId, getControlDefinition, props);
        }
    }

    // Add Compose add-ins, if any and in the correct group
    if (isGroupRuntimeControlEnabled(group.groupId)) {
        getComposeRuntimeControls()
            .getComposeControlsProps(props as ComposeRibbonControlDefProps)
            .forEach(({ buttonProps }) => {
                menuItems.push(buttonProps);
            });
    }

    // Add read addins
    if (
        isFeatureEnabled('addin-enableIntegratedSpam') &&
        isGroupRuntimeControlEnabledForSpamIntegration(group.groupId)
    ) {
        getReadSurfaceRuntimeControls().forEach(
            ({ shouldAddControlsToTabGroup, getControlsProps }) => {
                if (shouldAddControlsToTabGroup(group)) {
                    getControlsProps(props as ReadOnlyRibbonControlDefProps).forEach(
                        ({ buttonProps }) => {
                            menuItems.push(buttonProps);
                        }
                    );
                }
            }
        );
    }

    // Add read apps/addins that have been pinned
    if (
        areMessageExtensionsEnabledOnReadRibbon() &&
        isReadGroupRuntimeControlEnabled(group.groupId)
    ) {
        getReadRuntimeControls()
            .getReadControlsProps(props as ReadOnlyRibbonControlDefProps)
            .forEach(({ buttonProps }) => {
                menuItems.push(buttonProps);
            });
    }

    return menuItems;
});
