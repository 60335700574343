import type { ModuleConfigMap } from 'owa-shared-start-types';
import type { AppBootstrapOptions } from 'owa-app-module';
import { getMailBootstrapOptions } from 'mail-bootstrap-config';
import {
    lazyGetCalendarBootstrapOptions,
    lazyGetGroupsBootstrapOptionsProxy,
    lazyGetPeopleBootstrapOptionsProxy,
} from 'calendar-bootstrap-config';
import { lazyGetCalendarPreBootstrap } from 'calendar-prebootstrap-config';
import { lazyGetFilesBootstrapOptions } from 'files-bootstrap-config';
import { lazyGetAppHostBootstrapOptions } from 'app-host-bootstrap-config';

export const moduleToConfigMap: ModuleConfigMap<AppBootstrapOptions> = {
    Mail: {
        optionsSync: getMailBootstrapOptions,
    },
    Calendar: {
        options: lazyGetCalendarBootstrapOptions,
        preBootstrap: lazyGetCalendarPreBootstrap,
    },
    People: {
        options: lazyGetPeopleBootstrapOptionsProxy,
    },
    FilesHub: {
        options: lazyGetFilesBootstrapOptions,
    },
    AppHost: {
        options: lazyGetAppHostBootstrapOptions,
    },
    Groups: {
        options: lazyGetGroupsBootstrapOptionsProxy,
    },
};
