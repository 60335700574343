import loc from 'owa-localize';
import { AddInsAppId } from 'owa-m365-acquisitions/lib/data/M365AppId';
import { getAddIns_Button } from 'owa-locstrings/lib/strings/get_addins_button.locstring.json';
import type { StrictAppAcquisitionLink } from '../types';

export function getAddInAppAcquisitionLink(): StrictAppAcquisitionLink {
    const displayText = loc(getAddIns_Button);
    return {
        __typename: 'AppAcquisitionLink',
        id: AddInsAppId.ClientStore,
        text: displayText,
        accessibleName: displayText,
        icon: {
            __typename: 'InternalIcon',
            name: 'AddSquareIcon',
        },
    };
}
