import type { ComposeViewState } from 'owa-mail-compose-store';
import { composeStore } from 'owa-mail-compose-store';
import getRecipientWellValues from './getRecipientWellValues';
import type ReadWriteRecipientWellViewState from 'owa-recipient-types/lib/types/ReadWriteRecipientWellViewState';

export default function findComposeFromRecipientWell(
    recipientWell: ReadWriteRecipientWellViewState | undefined
): ComposeViewState | null {
    let currentViewState: ComposeViewState | null = null;
    if (recipientWell?.recipients) {
        for (const viewState of [...composeStore.viewStates.values()]) {
            const recipients = getRecipientWellValues(viewState);
            if (
                recipients.toRecipients === recipientWell.recipients ||
                recipients.ccRecipients === recipientWell.recipients ||
                recipients.bccRecipients === recipientWell.recipients
            ) {
                currentViewState = viewState;
                break;
            }
        }
    }
    return currentViewState;
}
