import type { ActionId } from 'owa-mail-list-item-animation-store';

export function getAnimationKeyframes(actionId: ActionId): Keyframe[] {
    switch (actionId) {
        case 'Delete':
        case 'Archive':
            return [
                { transform: 'translateX(0%)', opacity: 1 },
                { transform: 'translateX(-100%)', opacity: 0 },
            ];
        case 'Snooze':
        case 'Move':
            return [
                { transform: 'translateY(0%)', opacity: 1 },
                { transform: 'translateY(100%)', opacity: 0 },
            ];

        case 'PinInitial':
            // Slide up from origin, fade out
            return [
                { transform: 'translateY(0) scale(100%)', transformOrigin: 'center', opacity: 1 },
                {
                    transform: 'translateY(-100%) scale(80%)',
                    transformOrigin: 'center',
                    opacity: 0,
                },
            ];
        case 'AddRowFinal':
        case 'UnpinFinal':
            // Slide down from above landing at origin, fade in
            return [
                {
                    transform: 'translateY(-100%) scale(80%)',
                    transformOrigin: 'center',
                    opacity: 0,
                },
                { transform: 'translateY(0) scale(100%)', transformOrigin: 'center', opacity: 1 },
            ];

        case 'PinFinal':
            // Slide up from below landing at origin, fade in
            return [
                { transform: 'translateY(100%) scale(50%)', transformOrigin: 'center', opacity: 0 },
                { transform: 'translateY(0) scale(100%)', transformOrigin: 'center', opacity: 1 },
            ];
        case 'UnpinInitial':
            // Slide down from origin, fade out
            return [
                { transform: 'translateY(0) scale(100%)', opacity: 1 },
                { transform: 'translateY(100%) scale(50%)', opacity: 0 },
            ];

        case 'PinMiddle':
        case 'UnpinMiddle':
        case '-':
        case 'None':
        case 'ReadUnread':
        case 'FlagUnflag':
        default:
            return [];
    }
}
