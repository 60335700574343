import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CopilotThemes" */ './lazyIndex')
);

export const lazyFetchCopilotThemeImage = new LazyAction(lazyModule, m => m.fetchCopilotThemeImage);

export const lazySaveCopilotTheme = new LazyAction(lazyModule, m => m.saveCopilotTheme);

export const lazyTryScheduleThemeUpdate = new LazyAction(lazyModule, m => m.tryScheduleThemeUpdate);
