import { postToLabel } from './PostedToLine.locstring.json';
import publicFolderFavoriteStore from '../store/publicFolderFavoriteStore';
import { observer } from 'owa-mobx-react';
import type { MailFolder } from 'owa-graph-schema';
import loc from 'owa-localize';
import React from 'react';

import { postedToLabel, folderLabel } from './PostedToLine.scss';

export interface PostedToLineProps {
    folderId: string;
}

const PostedToLine = observer(function PostedToLine(props: PostedToLineProps) {
    const postedTo = publicFolderIdToName(props.folderId);
    return (
        <>
            <label className={postedToLabel}>{loc(postToLabel)}</label>
            <label className={folderLabel}>{postedTo}</label>
        </>
    );
}, 'PostedToLine');

function publicFolderIdToName(folderId: string): string {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (28,11): Type 'MailFolder | undefined' is not assignable to type 'MailFolder'.
    // @ts-expect-error
    const folder: MailFolder = publicFolderFavoriteStore.folderTable.get(folderId);
    return folder.displayName;
}

export default PostedToLine;
