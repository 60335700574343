import React from 'react';
import { getBrowserWidth } from 'owa-config';
import { addEventTimeToSourceMapping } from 'owa-performance';
import { swipeableContentContainer, outerContainer } from './TouchBehavior.scss';

export interface TouchState {
    started: boolean;
    isSwiping: boolean;
    startX: number;
    startY: number;
    moveX: number;
    leftAction: boolean;
    rightAction: boolean;
}

export interface TouchThreshold {
    swipe: number;
    action: number;
    edgeSwipe: number;
    longPress: number;
}

export enum SwipeStage {
    Cancelled = 0,
    Started,
    Completed,
}

export interface AccessibilityAnchorButtonParams<P> {
    labelGenerator: (props: P) => string;
    announcementGenerator: (props: P) => string;
    onClick?: (props: P) => void;
}

export enum AccessibilityButtonsPlacement {
    BEFORE,
    AFTER,
}

interface TouchBehaviourProps<P> {
    //Required
    Component: React.ComponentClass<P> | React.FC<P>;
    originalLeftSwipeRenderer: (
        props: P,
        state: TouchState,
        threshold: TouchThreshold
    ) => JSX.Element;
    originalRightSwipeRenderer: (
        props: P,
        state: TouchState,
        threshold: TouchThreshold
    ) => JSX.Element;
    originalGetLeftSwipeAction: () => (props: P) => void;
    originalGetRightSwipeAction: () => (props: P) => void;
    threshold: TouchThreshold;
    skipHandleTouchCallback: (moveX?: number, Component?: any, nativeEvent?: Event) => boolean;

    //Optional
    clickAction?: (props: P) => void; // Removed the usage of this prop in TouchBehavior since no consumers are using it to prevent an extra event handler.
    longPressAction?: (props: P) => void;
    originalLeftSwipeActionInitiated?: (props: P) => void;
    originalRightSwipeActionInitiated?: (props: P) => void;
    alwaysRenderSwipeRenderer?: boolean;
    enableWillChangeTransform?: boolean;
    shouldHandleRTLScenario?: boolean;
    notifyOnSwipe?: (props: P, swipeStage: SwipeStage) => void;
    leftAccessibilityButtonParams?: AccessibilityAnchorButtonParams<P>;
    rightAccessibilityButtonParams?: AccessibilityAnchorButtonParams<P>;
    accessibilityButtonPlacement?: AccessibilityButtonsPlacement;
    scrollBehavior?: () => void;
    renderStaticSwipeRenderer?: boolean;
}

export default function touchBehavior<P>(props: TouchBehaviourProps<P>): React.ComponentClass<P> {
    const {
        Component,
        originalLeftSwipeRenderer,
        originalRightSwipeRenderer,
        originalGetLeftSwipeAction,
        originalGetRightSwipeAction,
        threshold,
        skipHandleTouchCallback,
        longPressAction,
        originalLeftSwipeActionInitiated,
        originalRightSwipeActionInitiated,
        alwaysRenderSwipeRenderer = false,
        enableWillChangeTransform = false,
        notifyOnSwipe,
        leftAccessibilityButtonParams,
        rightAccessibilityButtonParams,
        shouldHandleRTLScenario = true,
        accessibilityButtonPlacement,
        scrollBehavior,
        renderStaticSwipeRenderer = false,
    } = props;
    const isLTR = !shouldHandleRTLScenario;
    // renderers
    const leftSwipeRenderer = isLTR ? originalLeftSwipeRenderer : originalRightSwipeRenderer;
    const rightSwipeRenderer = isLTR ? originalRightSwipeRenderer : originalLeftSwipeRenderer;

    // swipe actions
    const getLeftSwipeAction = isLTR ? originalGetLeftSwipeAction : originalGetRightSwipeAction;
    const getRightSwipeAction = isLTR ? originalGetRightSwipeAction : originalGetLeftSwipeAction;

    // initiators
    const leftSwipeActionInitiated = isLTR
        ? originalLeftSwipeActionInitiated
        : originalRightSwipeActionInitiated;
    const rightSwipeActionInitiated = isLTR
        ? originalRightSwipeActionInitiated
        : originalLeftSwipeActionInitiated;

    /* eslint-disable-next-line owa-custom-rules/prefer-react-functional-components -- (https://aka.ms/OWALintWiki)
     * This react class component should be re-factored as functional component, if is not possible, write a justification.
     *	> When implementing a simple component, write it as a functional component rather than as a class component */
    return class TouchComponent extends React.Component<P, TouchState> {
        private pressTimer: ReturnType<typeof setTimeout> | number = 0;

        private isScrolling = false;
        private isLongPress = false;

        private transitionDiv?: HTMLElement | null;
        private wrappedComponentRef = React.createRef<any>();

        constructor(props_: P) {
            super(props_);
            this.state = {
                started: false,
                isSwiping: false,
                startX: 0,
                startY: 0,
                moveX: 0,
                leftAction: false,
                rightAction: false,
            };
        }

        componentWillUnmount() {
            clearTimeout(this.pressTimer);

            if (this.transitionDiv) {
                this.transitionDiv.removeEventListener('transitionend', this.onTransitionEnd);
                this.transitionDiv.removeEventListener('touchmove', this.onTouchMove);
            }
        }

        public trySetFocus = () => {
            //trySetFocusOnElement(this.wrappedComponentRef, true /* customFocus */);
        };

        notifySwipeStage(swipeStage: SwipeStage) {
            notifyOnSwipe?.(this.props, swipeStage);
        }

        private executeLeftAction() {
            if (getLeftSwipeAction()) {
                if (leftSwipeActionInitiated) {
                    leftSwipeActionInitiated(this.props);
                }
                this.setState({
                    leftAction: true,
                    started: false,
                    startX: 0,
                    startY: 0,
                    moveX: 0,
                });
            }
        }

        private executeRightAction() {
            if (getRightSwipeAction()) {
                if (rightSwipeActionInitiated) {
                    rightSwipeActionInitiated(this.props);
                }
                this.setState({
                    rightAction: true,
                    started: false,
                    startX: 0,
                    startY: 0,
                    moveX: 0,
                });
            }
        }

        private onTouchStart = (evt: React.SyntheticEvent<EventTarget>) => {
            addEventTimeToSourceMapping(evt.nativeEvent, 'TouchBehavior');

            if (this.state.rightAction || this.state.leftAction) {
                // If another touch starts before left or right action finishes,
                // ignore it
                return;
            }

            const nativeEvent = evt.nativeEvent as TouchEvent;
            const touchPosition = nativeEvent.touches[0];
            const pageX = touchPosition.pageX;
            this.isLongPress = false;

            if (pageX < threshold.edgeSwipe || pageX > getBrowserWidth() - threshold.edgeSwipe) {
                // edge swipe
                return;
            }

            if (skipHandleTouchCallback(0, null, evt.nativeEvent)) {
                return;
            }

            if (longPressAction) {
                this.pressTimer = setTimeout(() => {
                    this.isLongPress = true;
                    longPressAction(this.props);
                }, threshold.longPress);
            }

            this.setState({
                started: true,
                startX: pageX,
                startY: touchPosition.pageY,
                isSwiping: false,
            });
        };

        // onTouchMove needs to take nativeEvent as param
        // so preventDefault can take effect on it
        private onTouchMove = (nativeEvent: TouchEvent) => {
            addEventTimeToSourceMapping(nativeEvent, 'TouchBehavior');

            if (this.isScrolling || this.isLongPress || !this.state.started) {
                return;
            }

            const touchPosition = nativeEvent.changedTouches[0];
            const currentX = touchPosition.pageX;
            const moveX = currentX - this.state.startX;
            const absX = Math.abs(moveX);
            const absY = Math.abs(touchPosition.pageY - this.state.startY);

            if (
                skipHandleTouchCallback(
                    moveX,
                    this.wrappedComponentRef.current,
                    nativeEvent as Event
                )
            ) {
                return;
            }

            if (!this.state.isSwiping) {
                if (absX <= absY) {
                    this.isScrolling = true;
                    scrollBehavior?.();
                    clearTimeout(this.pressTimer);
                } else {
                    // Likely swiping, let's prevent the scroll event.
                    nativeEvent.preventDefault();
                    nativeEvent.stopImmediatePropagation();

                    if (absX > threshold.swipe) {
                        this.notifySwipeStage(SwipeStage.Started);

                        this.setState({ isSwiping: true });
                        clearTimeout(this.pressTimer);
                    }
                }
            } else {
                // We are swiping, let's prevent the scroll event.
                nativeEvent.preventDefault();
                nativeEvent.stopImmediatePropagation();

                this.setState({ moveX });
            }
        };

        private onTouchEnd = (evt: React.SyntheticEvent<EventTarget>) => {
            addEventTimeToSourceMapping(evt.nativeEvent, 'TouchBehavior');

            if (!this.state.started && !evt) {
                return;
            }

            clearTimeout(this.pressTimer);
            this.isScrolling = false;

            if (Math.abs(this.state.moveX) > threshold.action) {
                if (this.state.moveX > 0) {
                    this.executeLeftAction();
                } else {
                    this.executeRightAction();
                }
            } else {
                this.state.isSwiping && this.notifySwipeStage(SwipeStage.Cancelled);
                this.setState({
                    started: false,
                    isSwiping: false,
                    startX: 0,
                    startY: 0,
                    moveX: 0,
                });
            }
        };

        private setTransitionDiv = (ref?: HTMLDivElement | null) => {
            if (this.transitionDiv) {
                this.transitionDiv.removeEventListener('transitionend', this.onTransitionEnd);
                this.transitionDiv.removeEventListener('touchmove', this.onTouchMove);
            }

            this.transitionDiv = ref;
            if (this.transitionDiv) {
                this.transitionDiv.addEventListener('transitionend', this.onTransitionEnd);
                this.transitionDiv.addEventListener('touchmove', this.onTouchMove);
            }
        };

        private onTransitionEnd = (evt: Event) => {
            addEventTimeToSourceMapping(evt, 'TouchBehavior');

            if (this.state.rightAction && getRightSwipeAction()) {
                getRightSwipeAction()(this.props);
            } else if (this.state.leftAction && getLeftSwipeAction()) {
                getLeftSwipeAction()(this.props);
            }

            this.notifySwipeStage(SwipeStage.Completed);

            this.setState({
                leftAction: false,
                rightAction: false,
                isSwiping: false,
            });
        };

        onClickRight = () => {
            rightAccessibilityButtonParams?.onClick
                ? rightAccessibilityButtonParams.onClick(this.props)
                : getLeftSwipeAction()(this.props);
        };

        onClickLeft = () => {
            leftAccessibilityButtonParams?.onClick
                ? leftAccessibilityButtonParams.onClick(this.props)
                : getLeftSwipeAction()(this.props);
        };

        render() {
            let translate = null;
            if (this.state.leftAction) {
                translate = { transform: 'translate3d(100%, 0, 0)', transition: 'transform 200ms' };
            } else if (this.state.rightAction) {
                translate = {
                    transform: 'translate3d(-100%, 0, 0)',
                    transition: 'transform 200ms',
                    touchAction: 'none',
                    willChange: 'transform',
                };
            } else if (
                (this.state.moveX > 0 && getLeftSwipeAction()) ||
                (this.state.moveX < 0 && getRightSwipeAction())
            ) {
                translate = {
                    transform: 'translate3d(' + this.state.moveX + 'px, 0, 0)',
                    transition: 'none',
                    touchAction: 'none',
                    willChange: 'transform',
                };
            }

            if (enableWillChangeTransform) {
                if (translate) {
                    //translate = { touchAction: 'none', willChange: 'transform' };
                } else {
                    translate = { touchAction: 'none', willChange: 'transform' };
                }
            }
            const leftAccessibilityButton: JSX.Element | undefined =
                leftAccessibilityButtonParams && (
                    <button
                        key="leftAccessibilityButton"
                        //orientation={AccessibilityAnchorOrientation.Left}
                        onClick={this.onClickLeft}
                        name={
                            leftAccessibilityButtonParams.labelGenerator
                                ? leftAccessibilityButtonParams.labelGenerator(this.props)
                                : ''
                        }
                    />
                );
            const component: JSX.Element = (!Component.prototype?.render && (
                <Component key="wrappedComponent" {...this.props} />
            )) || (
                <Component key="wrappedComponent" ref={this.wrappedComponentRef} {...this.props} />
            );
            const rightAccessibilityButton: JSX.Element | undefined =
                rightAccessibilityButtonParams && (
                    <button
                        key="rightAccessibilityButton"
                        onClick={this.onClickRight}
                        name={
                            rightAccessibilityButtonParams.labelGenerator
                                ? rightAccessibilityButtonParams.labelGenerator(this.props)
                                : ''
                        }
                    />
                );

            /**
             * The reordering of accessibility buttons are done based on the requirement in day view swipe a11y
             * when the boolean value is true then the order will be [component, leftA11yButton, rightA11yButton]
             * when the boolean is false the order will be [leftA11yButton, component, rightA11yButton]
             */
            let childComponents = null;
            switch (accessibilityButtonPlacement) {
                case AccessibilityButtonsPlacement.BEFORE:
                    childComponents = [
                        leftAccessibilityButton,
                        rightAccessibilityButton,
                        component,
                    ];
                    break;
                case AccessibilityButtonsPlacement.AFTER:
                    childComponents = [
                        component,
                        leftAccessibilityButton,
                        rightAccessibilityButton,
                    ];
                    break;
                default:
                    childComponents = [
                        //leftAccessibilityButton,
                        component,
                        //rightAccessibilityButton,
                    ];
            }
            childComponents.filter(Boolean);
            !isLTR && childComponents.reverse();

            const isSwipeInProgress =
                this.state.isSwiping || this.state.leftAction || this.state.rightAction;

            // Added this option to render a static swipeRenderer a layer behind the swipeable content. It gets uncovered and will be visible as the user swipes the swipeable content.
            // This is useful for scenarios where the swipeRenderer has content that needs to be fixed at a particular position and should not move with the swipeable content. For example,
            // the swipeRenderer can have an icon that needs to be fixed at the left or right edge of the visible area and should not move with the swipeable content.
            return renderStaticSwipeRenderer ? (
                <div className={isSwipeInProgress ? outerContainer : undefined}>
                    {((this.state.isSwiping && this.state.moveX > 0) ||
                        this.state.leftAction ||
                        alwaysRenderSwipeRenderer) &&
                        leftSwipeRenderer(this.props, this.state, threshold)}
                    <div
                        role="group"
                        className={isSwipeInProgress ? swipeableContentContainer : undefined}
                        ref={this.setTransitionDiv}
                        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                         *	> Forbidden non-null assertion. */
                        style={translate!}
                        onTouchStart={this.onTouchStart}
                        onTouchEnd={this.onTouchEnd}
                    >
                        {childComponents.map(component_ => component_)}
                    </div>
                    {((this.state.isSwiping && this.state.moveX < 0) ||
                        this.state.rightAction ||
                        alwaysRenderSwipeRenderer) &&
                        rightSwipeRenderer(this.props, this.state, threshold)}
                </div>
            ) : (
                <div
                    role="group"
                    ref={this.setTransitionDiv}
                    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                     *	> Forbidden non-null assertion. */
                    style={translate!}
                    onTouchStart={this.onTouchStart}
                    onTouchEnd={this.onTouchEnd}
                >
                    {(this.state.isSwiping || alwaysRenderSwipeRenderer) &&
                        leftSwipeRenderer(this.props, this.state, threshold)}
                    {childComponents.map(component_ => component_)}
                    {(this.state.isSwiping || alwaysRenderSwipeRenderer) &&
                        rightSwipeRenderer(this.props, this.state, threshold)}
                </div>
            );
        }
    };
}
