import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
import { mutatorAction } from 'satcheljs';
import { getStore } from '../store/store';

export const updateUserConfiguration: (
    updateFunction: (userConfig: OwaUserConfiguration) => void
) => void = mutatorAction(
    'updateUserConfiguration',
    (updateFunction: (userConfig: OwaUserConfiguration) => void) => {
        const { userConfiguration } = getStore();
        if (userConfiguration) {
            updateFunction(userConfiguration);
        }
    }
);
