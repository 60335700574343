import type { StrictFlyoutSearchBehavior } from '../types';
import {
    searchBoxText,
    noResultsTitle,
    noResultsMessage,
} from 'owa-locstrings/lib/strings/typedownsearch.locstring.json';
import loc from 'owa-localize';

export const getFlyoutSearchDefaultBehavior = (): StrictFlyoutSearchBehavior => {
    return {
        __typename: 'FlyoutSearchBehavior',
        isEnabled: true,
        placeholderText: loc(searchBoxText),
        debounceRate: 250,
        noResultsTitle: loc(noResultsTitle),
        noResultsMessage: loc(noResultsMessage),
    };
};
