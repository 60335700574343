import { IdbFallbackReason } from './IdbFallbackReason';
import type { IdbFallbackResult } from './IdbFallbackResult';

export const isIdbFallbackResult = <TReason extends IdbFallbackReason = IdbFallbackReason>(
    result: any,
    code?: TReason
): result is IdbFallbackResult<IdbFallbackReason> => {
    return code !== undefined
        ? result?.extensions?.idbFallbackReason === code
        : result?.extensions?.idbFallbackReason !== undefined;
};

export const isIdbNotSupportedResult = (
    result: any
): result is IdbFallbackResult<IdbFallbackReason.NotSupportedByIdb> => {
    return isIdbFallbackResult(result, IdbFallbackReason.NotSupportedByIdb);
};
