import { type ListViewTypeRestrictionType } from '../Behaviors.types';
import { assertNever } from 'owa-assert';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { getSelectedTableView } from 'owa-mail-list-store';
import shouldShowUnstackedReadingPane from 'owa-mail-store/lib/utils/shouldShowUnstackedReadingPane';

export const listViewTypeRestrictionType = (restrictionType: ListViewTypeRestrictionType) => () => {
    const {
        tableQuery: { listViewType },
    } = getSelectedTableView();

    switch (restrictionType) {
        case 2:
            return listViewType === 1 || shouldShowUnstackedReadingPane();

        case 1:
            return listViewType === 0 && !shouldShowUnstackedReadingPane();

        default:
            throw assertNever(restrictionType);
    }
};
