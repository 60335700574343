import React from 'react';
import { getSearchBoxLeftPadding } from 'owa-mail-layout';
import MailSearchBoxContainer from 'owa-mail-search/lib/components/MailSearchBoxContainer';
import { FolderScopePicker } from 'owa-mail-search/lib/lazyFunctions';
import type { SearchBoxContainerHandle } from 'owa-search/lib/types/SearchBoxContainerHandle';
import { lazySetShowSearchBoxInCompactMode } from 'owa-search-actions/lib/lazyFunctions';
import SearchScenarioId from 'owa-search-store/lib/store/schema/SearchScenarioId';

export default function getMailHeaderProps(
    searchBoxRef: React.RefObject<SearchBoxContainerHandle>
) {
    return {
        props: {
            searchAlignmentWidth: getSearchBoxLeftPadding(),
            renderSearch: () => <MailSearchBoxContainer ref={searchBoxRef} />,
            searchBoxRespond,
            renderSearchScopePicker: () => <FolderScopePicker searchBoxRef={searchBoxRef} />,
            enableResponsiveLayout: true,
            enableDiagnostics: true,
        },
    };
}

/**
 * Let search box respond correctly when it
 * has reached its 0 responsive step OR it reaches
 * to next non-zero responsiveStep defined
 */
async function searchBoxRespond(responsiveStep: number) {
    const setShowSearchBoxInCompactMode = await lazySetShowSearchBoxInCompactMode.import();
    setShowSearchBoxInCompactMode(SearchScenarioId.Mail, responsiveStep === 0);
}
