import { LazyAction, LazyModule, LazyImport } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "GroupHeaderActions" */ './lazyIndex')
);

export const lazySetGroupHeaderCallbacks = new LazyAction(
    lazyModule,
    m => m.setGroupHeaderCallbacks
);

export const lazyGroupHeaderCommandBarStore = new LazyImport(
    lazyModule,
    m => m.groupHeaderCommandBarStore
);

export const lazyGroupHeaderNavigationButton = new LazyImport(
    lazyModule,
    m => m.groupHeaderNavigationButton
);

export const lazyGroupHeaderCommandBarAction = new LazyAction(
    lazyModule,
    m => m.groupHeaderCommandBarAction
);
