import getKeyboardShortcutsMode from './getKeyboardShortcutsMode';
import shouldUseMonarchShortcuts from './shouldUseMonarchShortcuts';
import { MonarchKeyboardShortcutsMode } from 'owa-outlook-service-option-store';
import { type default as WebKeyboardShortcutsMode } from 'owa-service/lib/contract/KeyboardShortcutsMode';

export default function isKeyboardShortcutsDisabled(): boolean {
    const keyboardShortcutsMode = getKeyboardShortcutsMode();

    return shouldUseMonarchShortcuts()
        ? keyboardShortcutsMode === MonarchKeyboardShortcutsMode.Off
        : keyboardShortcutsMode === 1;
}
