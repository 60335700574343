import type { ActionId } from 'owa-mail-list-item-animation-store';
import { getIsDarkTheme } from 'owa-fabric-theme';

export function getAnimationBackgroundColor(actionId: ActionId) {
    const isDarkTheme = getIsDarkTheme();
    switch (actionId) {
        case 'Delete':
            return isDarkTheme ? '#442726' : '#FEF2F3';
        case 'Archive':
            return isDarkTheme ? '#393D1B' : '#DFF6DD';
        case 'Snooze':
        case 'Move':
            return isDarkTheme ? '#FED9CC' : '#FFF4CE';
        case 'PinInitial':
        case 'PinMiddle':
        case 'PinFinal':
        case 'AddRowFinal':
            return isDarkTheme ? '#2B88D8' : '#EFF6FC';

        case '-':
        case 'None':
        case 'ReadUnread':
        case 'FlagUnflag':
        default:
            return 'transparent';
    }
}
