import {
    previewContainer,
    previewDisplayText,
} from 'owa-mail-listitem-styles/lib/scss/MailListItem.scss';
import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { highlightTermsInHtmlElement } from 'owa-highlight';
import { useMessageListTextStyle } from 'owa-mail-listitem-styles/lib/utils/useMessageListTextStyle';
import type { Accuracy } from 'owa-mark/lib/utils/markElements';
import React from 'react';

export interface MailListItemPreviewDisplayProps {
    highlightTerms: string[];
    previewDisplay: string;
    highlightAccuracy?: Accuracy;
}

export default observer(function MailListItemPreviewDisplay(
    props: MailListItemPreviewDisplayProps
) {
    const { highlightTerms, previewDisplay, highlightAccuracy } = props;

    const highLightTerms = React.useCallback(
        (element: HTMLElement) => {
            highlightTermsInHtmlElement(
                element,
                highlightTerms,
                false /* separateWordSearch */,
                highlightAccuracy /* highlightAccuracy */
            );
        },
        [highlightTerms, highlightAccuracy]
    );

    return (
        <div className={previewContainer}>
            <span
                className={classnames(previewDisplayText, useMessageListTextStyle('Major'))}
                ref={highLightTerms}
            >
                {previewDisplay}
            </span>
        </div>
    );
},
'MailListItemPreviewDisplay');
