import { LazyAction, LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "FavoritesStore"*/ './lazyIndex')
);

export const lazyMigrateFavorites = new LazyAction(lazyModule, m => m.migrateFavorites);
export const lazyMoveFavoriteUpDown = new LazyImport(lazyModule, m => m.moveFavoriteUpDown);
export const lazyUpdateFavoritePosition = new LazyImport(lazyModule, m => m.updateFavoritePosition);
export const lazyAddFavoriteCategory = new LazyImport(lazyModule, m => m.addFavoriteCategory);
export const lazyRemoveFavoriteCategory = new LazyImport(lazyModule, m => m.removeFavoriteCategory);
export const lazyAddNewMailboxDefaultFavorites = new LazyAction(
    lazyModule,
    m => m.addNewMailboxDefaultFavorites
);
export const lazyRemoveFavoritePersonaV2 = new LazyImport(
    lazyModule,
    m => m.removeFavoritePersonaV2
);
export const lazyAddFavoritePersonaV2 = new LazyImport(lazyModule, m => m.addFavoritePersonaV2);

export const lazyRemoveFavoriteGroup = new LazyImport(lazyModule, m => m.removeFavoriteGroup);
export const lazyAddFavoriteGroup = new LazyImport(lazyModule, m => m.addFavoriteGroup);

export const lazyRemoveFavoriteFolderV2 = new LazyImport(lazyModule, m => m.removeFavoriteFolderV2);
export const lazyAddFavoriteFolderV2 = new LazyImport(lazyModule, m => m.addFavoriteFolderV2);

export const lazySetOutlookFavoritesBitFlag = new LazyAction(
    lazyModule,
    m => m.setOutlookFavoritesBitFlag
);
// Favoriting actions
export const lazyAddFavoriteToStore = new LazyImport(lazyModule, m => m.addFavoriteToStore);
export const lazyAddFavoriteCompleted = new LazyImport(lazyModule, m => m.addFavoriteCompleted);
export const lazyAddFavoriteFailed = new LazyImport(lazyModule, m => m.addFavoriteFailed);

export const lazyAddMultipleFavoritesToStore = new LazyImport(
    lazyModule,
    m => m.addMultipleFavoritesToStore
);
export const lazyAddMultipleFavoritesCompleted = new LazyImport(
    lazyModule,
    m => m.addMultipleFavoritesCompleted
);

export const lazyRemoveFavoriteFromStore = new LazyImport(
    lazyModule,
    m => m.removeFavoriteFromStore
);
export const lazyRemoveFavoriteCompleted = new LazyImport(
    lazyModule,
    m => m.removeFavoriteCompleted
);
export const lazyRemoveFavoriteFailed = new LazyAction(lazyModule, m => m.removeFavoriteFailed);
export const lazyEditOutlookFavoriteService = new LazyAction(
    lazyModule,
    m => m.editOutlookFavoriteService
);
export const lazyCreateOutlookFavoriteService = new LazyAction(
    lazyModule,
    m => m.createOutlookFavoriteService
);
export const lazyDeleteOutlookFavoriteService = new LazyAction(
    lazyModule,
    m => m.removeOutlookFavoriteHelper
);
export const lazyCreateMultipleOutlookFavoritesService = new LazyAction(
    lazyModule,
    m => m.createMultipleOutlookFavoritesService
);
export const lazyGetOutlookFavoritesService = new LazyAction(
    lazyModule,
    m => m.getOutlookFavoritesService
);
