import { mutatorAction } from 'satcheljs';
import type GroupSiteStatus from 'owa-groups-sharepoint-commands/lib/schema/GroupSiteStatus';
import { getGroupResource } from '../selectors/getGroupResourceUrl';
import type { MailboxInfo } from 'owa-client-types';

export const updateGroupSiteAndDocumentUrl = mutatorAction(
    'updateGroupSiteAndDocumentUrl',
    function updateGroupSiteInfo(
        mailboxInfo: MailboxInfo,
        groupSmtpAddress: string,
        siteStatus: GroupSiteStatus,
        siteUrl?: string,
        documentsUrl?: string
    ) {
        if (siteStatus == 2) {
            const siteUrls = getGroupResource(mailboxInfo, groupSmtpAddress, 'Site');
            if (siteUrls.length > 0) {
                siteUrls[0].Url = siteUrl;
            }

            const filesUrls = getGroupResource(mailboxInfo, groupSmtpAddress, 'Files');
            if (filesUrls.length > 0) {
                filesUrls[0].Url = documentsUrl;
            }
        }
    }
);

export default updateGroupSiteAndDocumentUrl;
