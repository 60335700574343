import type { MailboxInfo } from 'owa-client-types';
import { isOcpsPolicyEnabled, OcpsPolicy } from 'owa-ocps-policy-store';
import { LoopType } from './constants';

export function isOCPSPolicyEnabledForLoopType(
    loopType: LoopType,
    mailboxInfo: MailboxInfo
): boolean {
    let ocpsPolicyEnabled = true;

    switch (loopType) {
        case LoopType.Fluid: {
            ocpsPolicyEnabled =
                isOcpsPolicyEnabled(OcpsPolicy.Loop, true, mailboxInfo) &&
                isOcpsPolicyEnabled(OcpsPolicy.LoopEnabledForOutlook, true, mailboxInfo);
            break;
        }
        case LoopType.Poll: {
            ocpsPolicyEnabled = isOcpsPolicyEnabled(OcpsPolicy.LoopPolls, true, mailboxInfo);
            break;
        }
        case LoopType.VideoRecord:
            ocpsPolicyEnabled = isOcpsPolicyEnabled(OcpsPolicy.LoopVideoRecord, true, mailboxInfo);
            break;
        case LoopType.Planner:
            ocpsPolicyEnabled = isOcpsPolicyEnabled(OcpsPolicy.LoopPlanner, true, mailboxInfo);
            break;
        case LoopType.AnyLoop: {
            // We always return true, since there is no OCPS policy for card loop.
            // This means card loops are always enabled, so there is always some type of loop that is enabled
            break;
        }
    }

    return ocpsPolicyEnabled;
}
