import { LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "GetItemManagerRp"*/ './lazyIndex'),
    { name: 'GetItemManagerRp' }
);

export const lazyInitializeGetItemManagerForRP = new LazyImport(
    lazyModule,
    m => m.initializeGetItemManagerForRP
);
