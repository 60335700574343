import { action, mutator } from 'satcheljs';
import type { FlexPaneState } from '../store/store';
import { getStore } from '../store/store';
import { DEFAULT_FLEXPANE_WIDTH } from '../utils/constants';

export const setIsFlexPaneShown = action(
    'setIsFlexPaneShown',
    (flexPaneId: string, isShown: boolean) => ({
        flexPaneId,
        isShown,
    })
);

export const setIsFlexPaneShownMutator = mutator(setIsFlexPaneShown, actionMessage => {
    let flexPaneState: FlexPaneState | undefined = getStore().flexPaneStateMap.get(
        actionMessage.flexPaneId
    );

    // Update FlexPaneState if it exists, otherwise create one
    if (flexPaneState) {
        flexPaneState.isShown = actionMessage.isShown;
    } else {
        flexPaneState = {
            isShown: actionMessage.isShown,
            isExpanded: false,
            expandedWidth: DEFAULT_FLEXPANE_WIDTH,
        };
        getStore().flexPaneStateMap.set(actionMessage.flexPaneId, flexPaneState);
    }

    getStore().currentFlexPaneState = flexPaneState;
});
