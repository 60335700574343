import { lazyGetMyDayMenuSections } from 'owa-mail-ribbon-sections/lib/sections';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onMyDayMenuClicked = () => {
    lazyGetMyDayMenuSections.import().then(getMyDayOptionsSections => {
        setMenuDefinition(
            'MyDayOptionsMenuDefinitionStore',
            undefined /* projectionTabId */,
            () => {
                return {
                    sections: getMyDayOptionsSections(),
                };
            }
        );
    });
};
