export interface OwaTeamsPolicies {
    teamsUpgraded: boolean;
    allowUserChat: boolean;
    allowMeetNow: boolean;
    meetingChatEnabledType: string;
    allowPrivateMeetingScheduling: boolean;
    teamsFeatureEnabled: boolean;
    allowOutlookAddIn: boolean;
    allowPrivateMeetNow: boolean;
    allowChannelMeetingScheduling: boolean;
    allowWebinars: string;
    allowTownhalls: string;
    allowBroadcastScheduling: boolean;
}

export enum FetchState {
    NotStarted,
    Fetching,
    Succeeded,
    Failed,
    Retry,
}

// Add new enum values at the end of this enum for new scenarios
export enum TeamsPoliciesScenario {
    MailPostBoot,
    MailEmbedChat,
    MailMeetNow,
    CalendarMyDayInit,
    CalendarReadingPane,
    CalendarPeekEvent,
    CalendarItemContextMenu,
    CalendarMeetingTemplate,
    EmbedChatCard,
    MeetingChatCard,
    CalFormsTeamsChatCard,
    SkypeForBusiness,
    OWATabSearch,
    Presence,
}

export interface OwaTeamsPoliciesStore {
    policies: OwaTeamsPolicies | null;
    fetchState: FetchState;
}
