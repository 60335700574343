import type SharedFoldersSearchScopeType from '../../data/schema/type/SharedFoldersSearchScopeType';

export default function sharedFoldersSearchScopeType(
    data: SharedFoldersSearchScopeType
): SharedFoldersSearchScopeType {
    const result: SharedFoldersSearchScopeType = {
        __type: 'SharedFoldersSearchScopeType:#Exchange',
        ...data,
    };

    return result;
}
