import { mutatorAction } from 'satcheljs';
import { getConditionalFormattingStore } from '../store/store';
import type { MailboxInfo } from 'owa-client-types';
import type { ConditionalFormattingModification } from '../store/schema/ConditionalFormattingRule';

export default mutatorAction(
    'addEntryToRowToModificationMap',
    function addEntryToRowToModificationMap(
        key: string,
        modifications: ConditionalFormattingModification[],
        mailboxInfo: MailboxInfo
    ) {
        getConditionalFormattingStore(mailboxInfo).rowToModificationsMap.set(key, modifications);
    }
);
