import { FetchState } from 'owa-teams-policies-lazy/lib/store/schema';
import { getStore } from 'owa-teams-policies-lazy/lib/store/store';
import { getTeamsPolicies } from '../utils/getTeamsPolicies';
import type { TeamsPoliciesScenario } from 'owa-teams-policies-lazy/lib/store/schema';

export function isTeamsFeatureEnabled(scenario?: TeamsPoliciesScenario): boolean {
    const { fetchState, policies } = getStore();
    if (fetchState == FetchState.NotStarted) {
        getTeamsPolicies(scenario);
    }
    return !!policies?.teamsFeatureEnabled;
}
