import type { FolderForestTreeType } from 'owa-graph-schema';
import type DistinguishedFolderIdName from 'owa-service/lib/contract/DistinguishedFolderIdName';

export const MAX_LENGTH_FOLDER_NAME = 256;
export const ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID = 'archivemsgfolderroot';
export const PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID = 'msgfolderroot';
export const ARCHIVE_DELETED_ITEMS_DISTINGUISHED_ID = 'archivedeleteditems';
export const PRIMARY_DELETED_ITEMS_DISTINGUISHED_ID = 'deleteditems';
export const ARCHIVE_DUMPSTER_DISTINGUISHED_ID = 'archiverecoverableitemsdeletions';
export const PRIMARY_DUMPSTER_DISTINGUISHED_ID = 'recoverableitemsdeletions';
export const SHARED_FOLDER_ROOT_DISTINGUISHED_ID = 'msgfolderroot';
export const SHARED_FOLDERS_TREE_TYPE: FolderForestTreeType = 'sharedFolderTree';
export const PRIMARY_FOLDERS_TREE_TYPE: FolderForestTreeType = 'primaryFolderTree';
export const ARCHIVE_FOLDERS_TREE_TYPE: FolderForestTreeType = 'archiveFolderTree';
export const FAVORITE_FOLDERS_TREE_TYPE: FolderForestTreeType = 'favorites';
export const GROUPS_FOLDERS_TREE_TYPE: FolderForestTreeType = 'groups';
export { GroupFolderWellKnownName } from './GroupFolderConstants';
export const SEARCH_FOLDER_ROOT_DISTINGUISHED_ID = 'searchfoldersview';
export const FOLDER_TYPE_NAME = 'Folder:#Exchange';
export const SEARCH_FOLDER_TYPE_NAME = 'SearchFolder:#Exchange';
export const INBOX_FOLDER_NAME: DistinguishedFolderIdName = 'inbox';
export { CreateFolderInvoker, CreateFolderDepth } from './CreateFolderConstants';
export { LoadMoreFoldersActionSource } from './LoadMoreFoldersConstants';
export { SourceMap } from './FoldersContextMenuConstants';
export { FolderColorNames, getFolderColor } from './FolderColorConstants';
