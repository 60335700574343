import { isUrlPresent } from 'owa-config';
import { findMetatag } from 'owa-metatags';
import { isConsumer } from 'owa-session-store';
import { getHostLocation } from 'owa-url/lib/hostLocation';

export function isVanityDomain(): boolean {
    if (isConsumer()) {
        return false;
    }

    const publicUrl = (findMetatag('publicUrl') || '').toLowerCase();

    const hostname = getHostLocation()?.hostname.toLowerCase();

    const OWAResourceUrls = [
        'outlook.office.com',
        'outlook-sdf.office.com',
        'outlook.office365.com',
        'outlook-sdf.office365.com',
        'outlook.cloud.microsoft',
        'sdf.outlook.cloud.microsoft',
        'outlook-sdf.cloud.microsoft',
    ];

    const PlacesResourceUrls = ['places.cloud.microsoft', 'places-sdf.cloud.microsoft'];

    const NeswlettersResourceUrls: string[] = [];

    const AggregatedResourceUrls = [
        ...OWAResourceUrls,
        ...PlacesResourceUrls,
        ...NeswlettersResourceUrls,
    ];

    /* gulp doesn't reliably set the publicUrl metaTag
        so we need to make sure it is a valid domain string first*/
    return (
        isUrlPresent(publicUrl) &&
        publicUrl !== hostname &&
        !AggregatedResourceUrls.includes(hostname)
    );
}
