import { getAnimationBackgroundColor } from './getAnimationBackgroundColor';
import type { ActionId } from 'owa-mail-list-item-animation-store';

export const prepareAnimations = (
    rows: Element[],
    animationAction: ActionId,
    rowItemParts: Map<string, Element[]>,
    animationRowIds: Set<string>,
    removedHeaders: Set<string>
) => {
    // The appropriate styles have already been applied to the rows being animated in the PinInitial and UnpinInitial animations so we do not enter
    // this block for the following 4 animationActions.
    if (
        animationAction != 'PinFinal' &&
        animationAction != 'UnpinFinal' &&
        animationAction != 'PinMiddle' &&
        animationAction != 'UnpinMiddle'
    ) {
        const animationBackgroundColor = getAnimationBackgroundColor(animationAction);

        const removeElement = (
            row: HTMLDivElement,
            isOnlyItemPart?: boolean,
            isGroupHeader?: boolean
        ) => {
            // Since all the rows are already absolute positioned in the virtualized message list, we don't remove any rows from the flow. We just apply some styles
            // to the row being animated in/out and the initial & final positions for each row are already calculated in storeInitialAndFinalPositions.ts using
            // the height of the row(s) being animated in/out.
            if (animationRowIds.has(row.id) || isOnlyItemPart || isGroupHeader) {
                row.style.cssText = `pointer-events: none; z-index:0; background: ${
                    isGroupHeader ? 'transparent' : animationBackgroundColor
                };`;
            }
        };

        for (let index = 0; index < rows.length; index++) {
            const row = rows[index];
            const currentRowItemParts = rowItemParts.get(row.id);
            const previousElement = index > 0 && (rows[index - 1] as HTMLDivElement);

            // edge case related to removing last element from group header- remove the group header too
            if (
                previousElement &&
                previousElement?.id?.slice(0, 11) === 'groupHeader' &&
                animationAction != 'AddRowInitial' &&
                animationAction != 'AddRowFinal'
            ) {
                let groupHeaderStartIdx = index;

                while (animationRowIds.has(rows[groupHeaderStartIdx]?.id) && index < rows.length) {
                    groupHeaderStartIdx++;
                    if (rows[groupHeaderStartIdx]?.id.slice(0, 11) === 'groupHeader') {
                        removedHeaders.add(previousElement.id);
                        removeElement(
                            previousElement,
                            false /*itemPartOnly*/,
                            true /* isGroupHeader */
                        );
                        break;
                    }
                }
            }

            if (
                currentRowItemParts &&
                currentRowItemParts.length == 1 &&
                animationRowIds.has(currentRowItemParts[0].id)
            ) {
                removeElement(currentRowItemParts[0] as HTMLDivElement);
                removeElement(row as HTMLDivElement, true);
            } else {
                removeElement(row as HTMLDivElement);
                rowItemParts.get(row.id)?.forEach(itemPart => {
                    removeElement(itemPart as HTMLDivElement);
                });
            }
        }
    }
};
