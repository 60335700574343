import { isFeatureEnabled } from 'owa-feature-flags';
import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';
import { isPstFileMailboxInfo } from 'owa-account-source-list-internal';

export const crossMailboxMoveCopyItemsCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        // Check policy to see if the action is enabled
        // TODO: Remove comment when we rollout the flight
        //return !!getAccountScopeUserSettings(mailboxInfo)?.PolicySettings
        //    ?.ItemsToOtherAccountsEnabled;

        // Enable without the ItemsToOtherAccountsEnabled so we can test in SDF
        return true;
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        return (
            isFeatureEnabled('mon-move-copy-crossAccount') &&
            (isServiceRequestSupportedForMailbox(mailboxInfo) ||
                (!!mailboxInfo &&
                    isPstFileMailboxInfo(mailboxInfo) &&
                    isFeatureEnabled('acct-pstFileImportExport')))
        );
    },
};
