import { LazyModule, LazyImport, createLazyComponent } from 'owa-bundling';
import store from './store/publicFolderPickerStore';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PublicFolderPickerModule" */ './lazyIndex')
);

export const lazyLoadPublicFolders = new LazyImport(lazyModule, m => m.loadPublicFolders);
export const LazyPublicFolderPicker = createLazyComponent(
    lazyModule,
    m => m.PublicFolderPicker,
    undefined,
    undefined,
    { onlyImportIf: () => store.showPanel || store.showErrorDialog }
);
export const lazyRemovePublicFolderFromFavorites = new LazyImport(
    lazyModule,
    m => m.removePublicFolderFromFavorites
);
