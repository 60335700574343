import getExtensibilityState from '../store/getExtensibilityState';
import type TaskPaneType from '../store/schema/TaskPaneType';
import { mutatorAction } from 'satcheljs';
import getTabIdFromWindow from '../store/getTabIdFromWindow';
import type { MailboxInfo } from 'owa-client-types';

export default mutatorAction(
    'terminateTaskPaneAddin',
    function terminateTaskPaneAddin(
        type: TaskPaneType,
        targetWindow: Window,
        mailboxInfo: MailboxInfo
    ) {
        const { taskPanes } = getExtensibilityState(mailboxInfo);
        const tabId: string = getTabIdFromWindow(targetWindow);

        if (taskPanes.has(tabId)) {
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2532 (24,13): Object is possibly 'undefined'.
            // @ts-expect-error
            taskPanes.get(tabId).delete(type);
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2532 (28,17): Object is possibly 'undefined'.
            // @ts-expect-error
            if (taskPanes.get(tabId).size === 0) {
                taskPanes.delete(tabId);
            }
        }
    }
);
