import React from 'react';

const fluentButtonContext = React.createContext<boolean>(false);
fluentButtonContext.displayName = 'FluentButtonContext';

/** Context used to detect when we should use Fluent v9 buttons, instead of v8 buttons. */
export const FluentButtonContextProvider = fluentButtonContext.Provider;

/** Return true when we should use Fluent v9 buttons. */
export const useFluentButtonContext = () => React.useContext(fluentButtonContext);
