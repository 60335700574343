import React from 'react';

/**
 *
 * @param source A friendly name, used to track the source of this observer callback
 * @param target The target element being observed
 * @param callback The observer callback.
 *     * `latestEntry` represents the most-recent intersection event
 *     * `allEntries` represents all intersection events
 *        that have happened since the last time
 *        this callback was called.
 * @param options Configuration for the IntersectionObserver
 */
export function useIntersectionObserver(
    source: string,
    target: React.RefObject<HTMLDivElement>,
    callback:
        | ((
              latestEntry: IntersectionObserverEntry,
              allEntries: IntersectionObserverEntry[]
          ) => void)
        | undefined,
    options?: IntersectionObserverInit
) {
    React.useEffect(() => {
        if (target.current && callback) {
            const cb = (entries: IntersectionObserverEntry[]) => {
                if (target.current) {
                    // intersection observer can return mutliple entries for the same observed element
                    // if it is removed and added back to the DOM like in the case of drag and drop
                    callback(entries[entries.length - 1], entries);
                }
            };
            try {
                Object.defineProperty(cb, 'name', { value: source + '_intersection' });
            } catch {
                // best effort so do nothing if there was an exception
            }

            const observer = new IntersectionObserver(cb, options);
            observer.observe(target.current);
            return () => observer.disconnect();
        }
        return undefined;
    }, [target.current, callback]);
}
