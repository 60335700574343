import { default as getCurrentThemeId } from '../selectors/getTheme';
import { default as loadTheme } from '../actions/loadTheme';
import { store } from '../store/store';

export function registerOnSystemDarkModeChangeListener() {
    const mediaQueryList = self.matchMedia('(prefers-color-scheme: dark)');

    if (mediaQueryList.addEventListener) {
        mediaQueryList.addEventListener('change', mediaQueryListener);
    } else if (mediaQueryList.addListener) {
        // Safari < 14 doesn't support addEventListener, so we need to use addListener
        mediaQueryList.addListener(mediaQueryListener);
    }
}

function mediaQueryListener(event: MediaQueryListEvent) {
    if (store.useSystemDarkModeSettings) {
        const isDarkTheme = event.matches;
        loadTheme(getCurrentThemeId(), isDarkTheme);
    }
}
