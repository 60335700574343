import type { PlatformType } from 'owa-graph-schema';
import type { StrictTitle } from '../../types';
import { EPOCH_TIME } from '../constants/constants';
import { EMPTY_COPILOT_CAPABILITIES } from '../constants/emptyCapability';

export const BootIndicatorTitle: StrictTitle = {
    __typename: 'Title',
    ingestionId: 'Boot',
    name: '',
    acquisitionDate: EPOCH_TIME,
    categories: ['m365_native_app' as PlatformType],
    longDescription: '',
    shortDescription: '',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: '',
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: '',
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: '',
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        composeExtensions: [],
        exchangeAddIns: [],
        extensions: [],
        staticTabs: [],
    },
    accentColor: '',
    isFullTrust: false,
    isFullScreen: true,
    developerName: '',
    developerUrl: '',
    termsOfUseUrl: '',
    permissions: [],
    validDomains: [],
    showLoadingIndicator: false,
    blockStatus: false,
    ingestionSource: 'BootIndicator',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: 'boot',
        resource: '',
    },
    version: '0.0.0', // NA
    cultureName: 'en-US',
    manifestVersion: '1.13',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
};
