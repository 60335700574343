import type { KeydownConfig } from 'owa-hotkeys';
import { getCommands } from '../utils/MailModuleHotKeys';
import { lazyManualSyncMail } from 'owa-mail-manual-sync';
import { getSelectedTableView } from 'owa-mail-list-store';

export function setupSyncMailboxHotKeys(): KeydownConfig[] {
    return [{ command: getCommands().syncMailbox, handler: syncMailbox }];
}

function syncMailbox() {
    lazyManualSyncMail.importAndExecute(getSelectedTableView());
}
