import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 *
 * This capability is being used to determine if M365 Acquisitions are supported by the MailboxInfo
 */
export const m365AcquisitionsCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return true; // by default
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        // Accounts that cannot make service requests cannot support M365 Acquisitions
        return isServiceRequestSupportedForMailbox(mailboxInfo);
    },
};
