import type { M365Acquisition } from 'owa-m365-acquisitions/lib/types';

/**
 * Filters boot indictator acquisition. This acquisition is used to determine if we've fetched the user's remote apps
 * @param acquisition
 * @returns `true` if `acquisition` was **not** added artificially because it's the boot indicator acquisition.
 */
export function filterBootIndicatorTitle(acquisition: M365Acquisition): boolean {
    return acquisition.titleDefinition.ingestionSource !== 'BootIndicator';
}
