import { mergeArraysDedupe } from '../../utils/apolloCacheUtils';
import { filterAcquisitions } from '../../utils/filterAcquisitions';
import { disableNormalizationForTypes } from 'owa-nova-cache/lib/utils/disableNormalizationForTypes';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { writeM365AppsQuery } from 'owa-nova-cache/lib/transform/writeM365AppsQuery';
import { writeM365MessageExtensionsQuery } from 'owa-nova-cache/lib/transform/writeM365MessageExtensionsQuery';
import type { MailboxInfo } from 'owa-client-types';
import type { M365Acquisition, M365AcquisitionsQueryResult } from 'owa-graph-schema';
import type { TypedTypePolicies } from 'owa-graph-schema-type-policies';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

export const getM365AcquisitionsQueryTypePolicy: () => TypedTypePolicies = () => ({
    /**
     * Force field to be non-normalized (i.e. nested), as it has an (non-unique) `id` property, which Apollo,
     * by default, normalizes the field and uses the `id` as its key.
     * Because many 3rd party apps have generic `id` values, e.g., "search", it is a sure-way for collisions.
     */
    ...disableNormalizationForTypes([
        'M365Acquisition',
        'InputExtensionCommand',
        'Runtime',
        'RuntimeContextualLaunch',
        'AddInExtensionTab',
        'AutoRunEvent',
        'AddInExtensionTabGroup',
        'AddInButtonShowTaskPane',
        'AddInButtonExecuteFunction',
        'AddInControlMenu',
        'WebApplicationInfo',
    ]),
    Query: {
        fields: {
            m365MessageExtensions: {
                keyArgs(_args, context) {
                    const messageExtensionTypeVariable = context?.variables?.messageExtensionType;
                    return `${context.fieldName}:${messageExtensionTypeVariable ?? ''}`;
                },
            },
            m365TitleLaunchInfo: {
                keyArgs(_args, context) {
                    return context?.variables?.mailboxInfo
                        ? `${context.fieldName}:${getIndexerValueForMailboxInfo(
                              context.variables.mailboxInfo
                          )}:${context?.variables?.titleId}`
                        : `${context.fieldName}:${context?.variables?.titleId}`;
                },
            },
            m365Acquisitions: {
                keyArgs(_args, context) {
                    return context?.variables?.mailboxInfo
                        ? `${context.fieldName}:${getIndexerValueForMailboxInfo(
                              context.variables.mailboxInfo
                          )}`
                        : `${context.fieldName}`;
                },
                merge(existing, incoming, { canRead, variables }): M365AcquisitionsQueryResult {
                    if (!incoming?.edges || incoming?.edges.length === 0) {
                        return { edges: existing?.edges, nextInterval: existing?.nextInterval };
                    }
                    const incomingAcquisitions = incoming?.edges.filter(canRead);
                    const existingAcquisitions = existing?.edges.filter(canRead);

                    // Merge and create valid set.
                    const mergedAcquisitions: M365Acquisition[] = existingAcquisitions
                        ? mergeArraysDedupe(existingAcquisitions, incomingAcquisitions)
                        : incomingAcquisitions;

                    // Run filtering step. Skip running in the bootstrap scenario (no existing acquisitions in cache)
                    const validAcquisitions: M365Acquisition[] = existingAcquisitions
                        ? filterAcquisitions(mergedAcquisitions)
                        : mergedAcquisitions;

                    // Side-effect writes.
                    tryWriteNovaQueries(validAcquisitions, variables?.mailboxInfo);

                    return {
                        __typename: 'M365AcquisitionsQueryResult',
                        nextInterval: incoming?.nextInterval,
                        edges: validAcquisitions,
                    };
                },
            },
        },
    },
});

function tryWriteNovaQueries(acquisitions: M365Acquisition[], queryMailboxInfo?: MailboxInfo) {
    writeM365MessageExtensionsQuery(acquisitions, queryMailboxInfo);
    const moduleContextMailboxInfo = getModuleContextMailboxInfo();
    if (
        queryMailboxInfo &&
        getIndexerValueForMailboxInfo(moduleContextMailboxInfo) ===
            getIndexerValueForMailboxInfo(queryMailboxInfo)
    ) {
        writeM365AppsQuery(acquisitions, queryMailboxInfo);
    } else if (!queryMailboxInfo) {
        writeM365AppsQuery(acquisitions);
    }
}
