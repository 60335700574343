import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getStore } from '../store/store';

export function getAccountSettingsInternal(mailboxInfo: MailboxInfo) {
    const store = getStore();
    if (
        store.allowDefaultDataReturn &&
        store.userConfiguration === undefined &&
        store.nonGlobalAccountDataMap.size === 0
    ) {
        // there is not any configuration data set in the store and we allow default data
        // so we just return undefined here rather than checking the global settings account
        return undefined;
    }

    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    const globalKey = getIndexerValueForMailboxInfo(getGlobalSettingsAccountMailboxInfo());
    const settings =
        key === globalKey ? store.userConfiguration : store.nonGlobalAccountDataMap.get(key);
    return settings;
}
