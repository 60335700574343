import { convertModeToKey } from './convertModeToKey';
import fixUpHotkeyCommand from 'owa-hotkeys/lib/utils/fixupHotkeyCommand';
import type { HotkeyCommand } from 'owa-hotkeys/lib/types/HotkeyCommand';
import { convertHotkeyStringToReadableText } from './convertHotkeyStringToReadableText';
import getKeyboardShortcutsMode from 'owa-hotkeys/lib/utils/getKeyboardShortcutsMode';

export function convertHotkeyToReadableText(command: HotkeyCommand) {
    // Get the name of the keyboard shortcuts style the user has enabled.
    const keyboardShortcutsMode = getKeyboardShortcutsMode();
    const modeKey = convertModeToKey(keyboardShortcutsMode);

    return modeKey
        ? convertHotkeyStringToReadableText(fixUpHotkeyCommand(command)[modeKey])
        : undefined;
}
