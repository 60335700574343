// extracted by mini-css-extract-plugin
export var allFolderIconsRootText = "qWi7V";
export var chevronAnimation = "VGV9k";
export var chevronAnimationCollapse = "U3GNP";
export var chevronAnimationCollapseFull = "m7aG4";
export var chevronIconButton = "gJcOF";
export var chevronIconButtonCompact = "Qa4k8";
export var chevronIconButtonFull = "x47ff";
export var chevronIconButtonMedium = "cA0F8";
export var chevronIconCompact = "I4ao2";
export var chevronIconFull = "VuFmm";
export var chevronIconMedium = "hk5Mr";
export var chevronPadding = "uFyEh";
export var compact = "if6B2";
export var customActionNode = "arc_i";
export var densityFontSize = "_8g73";
export var densityNodeHeight = "QreOD";
export var densityRootNodeHeight = "fP63B";
export var disabledCursor = "Cw3Gl";
export var displayName = "gtcPn";
export var ellipsesHovered = "Nm7DL";
export var ellipsesIcon = "Gyuy5";
export var ellipsesOnHover = "qarSX";
export var ellipsesOnHoverTreatmentPresent = "knMrU";
export var ellipsesPadding = "NKqwb";
export var focusStyling = "z6Kje";
export var folderIcons = "ppZg6";
export var folderWithChevron = "cqDRB";
export var full = "LPIso";
export var hoverPadding = "aWRWM";
export var icon = "jgnYx";
export var iconsNext = "MgbmD";
export var iconsNextFull = "EKaMa";
export var isBeingDragged = "nY2XK";
export var isDisabled = "NVk49";
export var leftNavAllFoldersPadding = "SuSxc";
export var ltrStyles = "phvtt";
export var medium = "ORnNd";
export var overridenFont = "vHhj2";
export var regularNode = "C2IG3";
export var regularNodeChevronIcon = "psaT8";
export var rightCharm = "o03Ce";
export var rightCharmHover = "trZ2R";
export var rightCharmHoverTreatmentPresent = "syUUS";
export var rootHover = "MNZUM";
export var rootIcon = "SZ3Jt";
export var rootNode = "XVsEB";
export var rootNodeFont = "_Ptr3";
export var roundedCorners = "oTkSL";
export var rtlStyles = "GG7iR";
export var selected = "wk4Sg";
export var showAsHoverOnDroppedOver = "KxVc9";
export var showBorderOnDroppedOver = "guOtt";
export var withContextMenuOpen = "X0REb";