import isFeatureEnabled from 'owa-feature-flags/lib/utils/isFeatureEnabled';
import getUserConfiguration from '../actions/getUserConfiguration';
import type OutlookNewslettersAccessLevels from 'owa-service/lib/contract/OutlookNewslettersAccessLevels';

export default function isOutlookNewslettersEnabled() {
    return (
        isFeatureEnabled('outlookNewsletters-enabled') &&
        (getUserConfiguration().PolicySettings?.OutlookNewslettersAccessLevel ?? 3) !== 3
    );
}
