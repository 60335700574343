import { onPreCoprincipalAccountRemoved } from 'owa-account-source-list-store';
import { orchestrator } from 'satcheljs';
import { removeSelectedCalendarsForUser } from '../mutators/removeSelectedCalendarsMutator';

orchestrator(onPreCoprincipalAccountRemoved, actionMessage => {
    const removedAccount = actionMessage.removedAccount.mailboxInfo;
    if (removedAccount) {
        removeSelectedCalendarsForUser(removedAccount);
    }
});
