import { getSelectedTableView } from 'owa-mail-list-store';
import { resetFocus } from 'owa-mail-focus-manager';
import type { ActionSource } from 'owa-mail-store';
import { lazyBlockLastSendersFromTable } from 'owa-mail-triage-action';

export default function onBlock(actionSource: ActionSource) {
    const tableView = getSelectedTableView();
    const rowKeys = [...tableView.selectedRowKeys.keys()];

    lazyBlockLastSendersFromTable.importAndExecute(rowKeys, tableView, actionSource);
    resetFocus('blockCommand');
}
