import { isFeatureEnabled } from 'owa-feature-flags';

export default function transformGroupSmtp(smtp: string): string;
export default function transformGroupSmtp(smtp?: string): string | undefined;
export default function transformGroupSmtp(smtp?: string | null): string | undefined | null;

export default function transformGroupSmtp(smtp?: string | null) {
    if (!smtp) {
        return smtp;
    }

    return isFeatureEnabled('grp-preserve-GroupSMTP-Case') ? smtp : smtp.toLowerCase();
}
