import { errorThatWillCauseAlert } from 'owa-trace';

import { getControlBehavior } from './data/getControlBehavior';
import { NovaControlModelDocument } from './graphql/query/__generated__/NovaControlModel.interface';

import type { LaunchTileBehaviorParams } from './launchTileBehaviorParams';
import { writeQuery } from 'owa-apollo';

export function bootstrapNovaCache(params?: LaunchTileBehaviorParams) {
    try {
        const controlModelBehavior = getControlBehavior(params);
        writeQuery(NovaControlModelDocument, { data: controlModelBehavior });
    } catch (e) {
        errorThatWillCauseAlert('AppBar_CachePriming_Error', e);
    }
}
