import { mutatorAction } from 'satcheljs';
import type { MailListItemAnimation } from '../store/schema/MailListItemAnimationStore';
import { mailListItemAnimationStore } from '../store/Store';

export const addRowAnimation = mutatorAction(
    'addRowAnimation',
    (animation: MailListItemAnimation, rowAnimation: Animation) => {
        const animationId = animation.animationId;
        const updatedActiveAnimations: MailListItemAnimation[] =
            mailListItemAnimationStore.activeAnimations.map(activeAnimation => {
                if (animationId === activeAnimation.animationId) {
                    const updatedRowAnimations = activeAnimation.rowAnimations ?? [];
                    updatedRowAnimations.push(rowAnimation);
                    return { ...activeAnimation, rowAnimations: updatedRowAnimations };
                }
                return activeAnimation;
            });
        mailListItemAnimationStore.activeAnimations = updatedActiveAnimations;
    }
);
