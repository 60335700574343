import type PstFileSearchScopeType from '../../data/schema/type/PstFileSearchScopeType';

export default function pstFileSearchScopeType(
    data: PstFileSearchScopeType
): PstFileSearchScopeType {
    const result: PstFileSearchScopeType = {
        __type: 'PstFileSearchScopeType:#Exchange',
        ...data,
    };

    return result;
}
