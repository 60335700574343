import { LazyAction, LazyModule, registerLazyOrchestrator, LazyImport } from 'owa-bundling';
import { selectPreviousNode } from 'owa-mail-actions/lib/selectPreviousNode';
import { validateDumpsterQuota } from 'owa-storage-store/lib/actions/validateDumpsterQuota';
import onFavoritesLoaded from 'owa-favorites/lib/actions/onFavoritesLoaded';
import { leaveGroupSuccess } from 'owa-groups-shared-actions/lib/leaveGroupSuccess';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "FolderForestActions"*/ './lazyIndex'),
    { name: 'FolderForestActions' }
);

export const lazyLoadDumpster = new LazyAction(lazyModule, m => m.loadDumpster);
export const lazyToggleFavoriteFolder = new LazyAction(lazyModule, m => m.toggleFavoriteFolder);
export const lazySelectFavoriteSearch = new LazyAction(lazyModule, m => m.selectFavoriteSearch);
export const lazySelectGroup = new LazyAction(lazyModule, m => m.selectGroup);
export const lazySelectFavoriteCategory = new LazyAction(lazyModule, m => m.selectFavoriteCategory);
export const lazySelectPersona = new LazyAction(lazyModule, m => m.selectPersona);

export const lazyPrefetchFrequentlyUsedFolders = new LazyAction(
    lazyModule,
    m => m.prefetchFrequentlyUsedFolders
);

export const lazyLogConsumerDeletedRetentionPolicy = new LazyAction(
    lazyModule,
    m => m.logConsumerDeletedRetentionPolicy
);

export const lazyLoadPopoutDataToFolderStore = new LazyAction(
    lazyModule,
    m => m.loadPopoutDataToFolderStore
);
export const lazyLoadPopoutDataToPublicFolderFavoriteStore = new LazyAction(
    lazyModule,
    m => m.loadPopoutDataToPublicFolderFavoriteStore
);

export const lazySelectGroupFolder = new LazyAction(lazyModule, m => m.selectGroupFolder);

export const lazyTryLoadAttachmentsSearchFolder = new LazyImport(
    lazyModule,
    m => m.tryLoadAttachmentsSearchFolder
);

registerLazyOrchestrator(selectPreviousNode, lazyModule, m => m.selectPreviousNodeOrchestrator);
registerLazyOrchestrator(
    validateDumpsterQuota,
    lazyModule,
    m => m.validateDumpsterQuotaOrchestrator
);
registerLazyOrchestrator(onFavoritesLoaded, lazyModule, m => m.onFavoritesLoadedOrchestrator);
registerLazyOrchestrator(leaveGroupSuccess, lazyModule, m => m.onLeaveGroupSuccessOrchestrator);
