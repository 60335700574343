import { orchestrator } from 'satcheljs';
import { reevaluateHeightBucket } from '../actions/overflowActions';
import { setAvailableHeightBucket } from '../utils/initializeDynamicLayout';

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(reevaluateHeightBucket, actionMessage => {
    setAvailableHeightBucket(actionMessage.source, true /* forceUpdate */);
});
