import type { ActionSource } from 'owa-analytics-types';
import type { ComposeViewStateInitOptions } from 'owa-mail-compose-actions';
import { lazyOnResponse, type ResponseType } from 'owa-mail-commands-actions';

export function onForward(actionSource: ActionSource, initOptions?: ComposeViewStateInitOptions) {
    lazyOnResponse.importAndExecute(2, actionSource, initOptions);
}

export function onForwardAsAttachment(
    actionSource: ActionSource,
    initOptions?: ComposeViewStateInitOptions
) {
    lazyOnResponse.importAndExecute(3, actionSource, initOptions);
}

export function onReply(actionSource: ActionSource) {
    lazyOnResponse.importAndExecute(0, actionSource);
}

export function onReplyAll(actionSource: ActionSource) {
    lazyOnResponse.importAndExecute(1, actionSource);
}

export function onReplyWithMeeting(actionSource: ActionSource) {
    lazyOnResponse.importAndExecute(4, actionSource);
}

export function onCopilotReplyWithMeeting(actionSource: ActionSource) {
    lazyOnResponse.importAndExecute(5, actionSource);
}

export function onResend(actionSource: ActionSource) {
    lazyOnResponse.importAndExecute(6, actionSource);
}
