import { getFolderColor } from 'owa-folders-constants';
import getFolderViewStateFromId from './getFolderViewStateFromId';
import { isFeatureEnabled } from 'owa-feature-flags';

export const getCustomFolderColor = (folderId: string) => {
    const viewState = getFolderViewStateFromId(folderId);
    return viewState.color;
};

export const getCustomFolderColorHexValue = (colorId: string | undefined) => {
    if (!isFeatureEnabled('fp-folder-colors')) {
        return undefined;
    }
    if (!colorId) {
        return undefined;
    }
    return colorId ? getFolderColor(colorId) : undefined;
};
