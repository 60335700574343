import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import type MailboxMessageConfigurationOptions from 'owa-service/lib/contract/MailboxMessageConfigurationOptions';
import type OptionsResponseBase from 'owa-service/lib/contract/OptionsResponseBase';
import mailboxMessageConfigurationOptions from 'owa-service/lib/factory/mailboxMessageConfigurationOptions';
import setMailboxMessageConfigurationRequest from 'owa-service/lib/factory/setMailboxMessageConfigurationRequest';
import setMailboxMessageConfigurationOperation from 'owa-service/lib/operation/setMailboxMessageConfigurationOperation';
import { getJsonRequestHeader } from 'owa-service/lib/ServiceRequestUtils';
import { getAccountScopeUserSettings } from 'owa-session-store';

export default function setMailboxMessageConfiguration(
    newOptions: MailboxMessageConfigurationOptions,
    mailboxInfo?: MailboxInfo
): Promise<OptionsResponseBase> {
    const mailboxInfoToUse = mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();

    const timeIntervalOriginal =
        getAccountScopeUserSettings(mailboxInfoToUse).UserOptions?.MailSendUndoInterval;

    if (!newOptions.MailSendUndoInterval && timeIntervalOriginal && timeIntervalOriginal < 0) {
        newOptions.MailSendUndoInterval = 0;
    }

    const requestOptions = getMailboxRequestOptions(mailboxInfoToUse);
    return setMailboxMessageConfigurationOperation(
        setMailboxMessageConfigurationRequest({
            Header: getJsonRequestHeader(),
            Options: mailboxMessageConfigurationOptions(newOptions),
        }),
        requestOptions
    );
}
