import BrowserHeightBucket from '../store/schema/BrowserHeightBucket';
import { getBrowserHeight } from 'owa-config';

/**
 * Gets the available height bucket
 */
export default function calculateAvailableHeightBucket(skipCache?: boolean): BrowserHeightBucket {
    const availableHeight = getBrowserHeight(skipCache);

    // Find the first heightBucketValue that is bigger than the currently availableHeight
    const heightBucket = Object.values(BrowserHeightBucket).find(
        heightBucketValue => availableHeight <= heightBucketValue
    );

    // If there's no height bucket, return the biggest one
    if (!heightBucket) {
        return BrowserHeightBucket.From3001_Above;
    }

    return heightBucket;
}
