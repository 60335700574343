import { observer } from 'owa-mobx-react';
import { shouldUseAppHostSearch } from 'owa-app-host-search/lib/utils/shouldUseAppHostSearch';
import React from 'react';
import type { SearchBoxContainerHandle } from 'owa-search/lib/types/SearchBoxContainerHandle';
import SearchBox from 'owa-search/lib/components/SearchBox';
import is3SServiceAvailable from 'owa-search/lib/utils/is3SServiceAvailable';
import { SearchScenarioId } from 'owa-search-store';
import { isReadingPanePositionRight, getSearchBoxWidthFromListView } from 'owa-mail-layout';
import { getStore as getMailSearchStore } from '../store/store';
import { shouldShowInScopeSelector } from 'owa-mail-search-utils';
import { AdvancedSearchWrapper } from 'owa-mail-advanced-search';
import {
    isSearchBarDisabled,
    getSearchPlaceHolderText,
    getAriaLabelForSearchBox,
} from '../utils/mailSearchUtils';
import { isLocalDataBackedMailboxInfo } from 'owa-account-source-list/lib/utils/isLocalDataBackedAccount';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import getSearchProvider from '../selectors/getSearchProvider';
import { SearchProvider } from 'owa-search-service/lib/data/schema/SearchProvider';

export default observer(
    React.forwardRef(function MailSearchBoxContainer(
        _props: {},
        ref: React.Ref<SearchBoxContainerHandle>
    ) {
        const searchBox = React.useRef<SearchBox>(null);
        const [isHovered, setIsHovered] = React.useState<boolean>(false);
        const {
            shouldShowAdvancedSearch,
            advancedSearchViewState,
            initialSearchScope,
            staticSearchScope,
            staticSearchScopeList,
            selectedMailboxInfo,
        } = getMailSearchStore();
        const isSuggestionsCalloutDisabled = !shouldShowInScopeSelector(initialSearchScope);
        const isSearchDisabled = isSearchBarDisabled.get();
        /*
         * Determine whether advanced search is supported in the current mailbox
         * Must use the moduleContextMailboxInfo, since it's up to date when hovering over the searchbox,
         * whereas selectedMailboxInfo is only updated after starting the search session
         */
        const isAdvancedSearchSupported =
            !shouldUseAppHostSearch() &&
            !isLocalDataBackedMailboxInfo(getModuleContextMailboxInfo());

        React.useImperativeHandle(
            ref,
            () => ({
                /**
                 * Publicly  exposed function that allows parent to set focus inside of the
                 * search input when search scope is empty on click of search scope item
                 */
                onSearchScopeSelected(searchScenarioId: SearchScenarioId) {
                    searchBox.current?.onSearchScopeSelected(searchScenarioId);
                },
                /**
                 * Publicly  exposed function that allows parent to set focus inside of the
                 * search input.
                 */
                setFocus() {
                    searchBox.current?.focusSearchInput();
                },
            }),
            []
        );
        /**
         * This function gets the max width of the search box.
         */
        const getCustomCollapsedMaxWidth = (): number | undefined => {
            /**
             * If the user is in 3 column view, the max width of the search box should
             * match the width of the message list. Otherwise, it's unbounded.
             */
            return isReadingPanePositionRight() ? getSearchBoxWidthFromListView() : undefined;
        };

        const onMouseLeaveCallback = React.useCallback(() => {
            setIsHovered(false);
        }, [isHovered]);

        const onMouseEnterCallback = React.useCallback(() => {
            setIsHovered(true);
        }, [isHovered]);

        const onHoverHandlers = React.useMemo(
            () => ({
                onMouseEnter: onMouseEnterCallback,
                onMouseLeave: onMouseLeaveCallback,
            }),
            []
        );

        const renderAdvancedSearch = React.useCallback(() => {
            return isSuggestionsCalloutDisabled ||
                !isAdvancedSearchSupported ||
                isSearchDisabled ? (
                <></>
            ) : (
                <AdvancedSearchWrapper
                    shouldShowAdvancedSearch={shouldShowAdvancedSearch}
                    scenarioId={SearchScenarioId.Mail}
                    advancedSearchViewState={advancedSearchViewState}
                    initialSearchScope={initialSearchScope}
                    staticSearchScope={staticSearchScope}
                    staticSearchScopeList={staticSearchScopeList}
                    mailboxInfo={selectedMailboxInfo}
                />
            );
        }, [
            isSuggestionsCalloutDisabled,
            isAdvancedSearchSupported,
            isSearchDisabled,
            shouldShowAdvancedSearch,
            advancedSearchViewState,
            initialSearchScope,
            staticSearchScope,
            staticSearchScopeList,
            selectedMailboxInfo,
        ]);

        /**
         * We always use 3S for suggestions if we're not
         * in explicit logon.
         */
        return (
            <SearchBox
                customCollapsedMaxWidth={getCustomCollapsedMaxWidth()}
                isUsing3S={is3SServiceAvailable()}
                ref={searchBox}
                renderRefiners={renderAdvancedSearch}
                searchPlaceHolderText={getSearchPlaceHolderText()}
                isDisabled={isSearchDisabled}
                scenarioId={SearchScenarioId.Mail}
                isSuggestCalloutDisabled={isSuggestionsCalloutDisabled}
                onHoverHandlers={onHoverHandlers}
                isHovered={isHovered}
                ariaLabel={getAriaLabelForSearchBox.get()}
                shouldSuppressScopedSuggestions={
                    getSearchProvider(staticSearchScope) === SearchProvider.Offline
                }
            />
        );
    }),
    'MailSearchBoxContainer'
);
