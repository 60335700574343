import { AriaDatapoint } from './AriaDatapoint';
import type {
    DatapointOptions,
    PerformanceDatapointType,
    DataSource,
    CustomWaterfallRange,
    PredefinedCheckpoint,
    PerformanceDatapointObjectType,
    EndProfileFunction,
} from 'owa-analytics-types';
import { hasUserWindowHadFocusSince } from 'owa-analytics-utils/lib/visibilityState';
import { addStackTraceToEvent } from 'owa-analytics-utils/lib/addStackTraceToEvent';
import { lazyLogPerformanceDatapoint } from '../lazyFunctions';
import type { TraceErrorObject } from 'owa-trace';
import { trace } from 'owa-trace';
import { DatapointStatus } from 'owa-analytics-types';
import { safeRequestAnimationFrame } from 'owa-performance';
import type { PerformanceWithMemory } from 'owa-performance';
import type { LazyLoadedModules } from 'owa-bundling-light';
import { getGlobalImportStartTime, getLazyLoadedModulesAndCleanup } from 'owa-bundling-light';
import type { ErrorType } from 'owa-errors';
import { getUniquePropertyString } from 'owa-analytics-core/lib/utils/getUniquePropertyString';
import { getAnalyticsFlightsAndAppSettings } from 'owa-analytics-core/lib/settings/getAnalyticsFlightsAndAppSettings';
import { tryGetTraceObjectErrorFromApolloError } from 'owa-trace/lib/tryGetTraceObjectErrorFromApolloError';
import { addErrorToDatapoint } from '../utils/addErrorToDatapoint';
import getDatapointStatus from '../utils/getDatapointStatus';
import type { StatusAndType } from '../utils/getDatapointStatus';
import { isNetworkDataSource } from '../utils/isNetworkDataSource';
import { logUsage } from '../api/logUsage';
import { scrubForPii } from 'owa-config/lib/scrubForPii';
import { logGreyError } from '../api/logGreyError';
import { addPerfomanceMarkToDatapoint } from '../utils/performanceMarks';
import {
    getCustomColumn,
    getCustomValue,
    getWaterfallColumnName,
} from '../utils/getWaterfallColumnName';
import { getNextPaint } from 'owa-analytics-shared';
import { startSelfProfiler } from '../utils/startSelfProfiler';

// Lazy Module Stats for Health Data
// d: duration in ms
// a: attempts
type LazyModuleStats = {
    d: number;
    a?: number;
};

// Health data
// rc: Number of reactions scheduled for mobx
// ls: LayoutScore
// lr: Number of long running tasks
// md: Memory difference between the start and end of the datapoint
// lm: Lazy Modules loaded during the datapoint execution
// c: number of cores
// loaf: long animation frames sources that occur within the initial event processing
// loafss: the script sources for the loaf property above
// idloaf: long animation frames sources that occur within the input delay
// idloafss: the script sources for the idloaf property above
// inp: Interaction to next paint
// id: Input delay
type Health = {
    rc?: number;
    ls?: number;
    md?: number;
    lm?: LazyModuleStats[];
    c?: number;
    loaf?: string;
    loafss?: string;
    idloaf?: string;
    idloafss?: string;
    inp?: number;
    id?: number;
};

export const defaultDatapointTimeout = 60 * 1000; // 60 seconds

const possibleStatus = Object.values(DatapointStatus);

let datapointLayoutScore: number = -1;
export function setDatapointLayoutScore(score: number) {
    datapointLayoutScore = score;
}

let totalReactionCount = 0;
export function incrementReactionCount() {
    totalReactionCount++;
}

export class PerformanceDatapoint extends AriaDatapoint implements PerformanceDatapointType {
    public static EventTimeToDpMapping: Map<number, PerformanceDatapoint> = new Map();

    private startTime: number | undefined;
    private perfStartTime: number | undefined;
    private startMemory: number | undefined = 0;
    private startScore: number;
    private startReactions: number;
    private timeBeforePause: number = 0;
    private previousEndCalls: string[] = [];
    private timeoutId: number | undefined;
    private invalidated: boolean = false;
    private endTime: number | undefined;

    private scenarioEventTimeoutId: number | undefined;
    private lazyLogArugments:
        | {
              calculatedStatus: DatapointStatus;
              errorType: ErrorType | 'general';
          }
        | undefined;

    private noMarking: boolean;
    private health: Health = {};
    private pendingCallbacks: Array<() => void> = [];
    private isEndPending: boolean = false;
    private endProfile: EndProfileFunction | null = null;
    waterfallTimings:
        | {
              [key: string]: number;
          }
        | undefined;
    duration: number | undefined;
    allRequestIds: string[] = [];
    responseCorrelationVectors: string[] = [];
    madeNetworkRequest = false;
    didExecuteGqlQuery = false;
    dataSource: DataSource | null = null;
    dataRetries: number | undefined;
    greyError?: Error;
    // Allows to check if a datapoint is a PerformanceDatapoint without
    // having to import "owa-analytics" to check with "instance of"
    isPerformanceDatapoint = true;
    constructor(eventName: string, options?: DatapointOptions) {
        super(eventName, undefined, options);
        this.noMarking = !!options?.noMarking;
        const importTime = getGlobalImportStartTime();
        this.startTime = options?.customStartTime || importTime || Date.now();
        this.startScore = datapointLayoutScore;
        if (!this.options?.skipNonMetadataTasks) {
            this.performanceMark('s');

            const eventTimestamp = options?.eventTimestamp;
            if (eventTimestamp) {
                this.setEventTimestamp(eventTimestamp);
            }
        }

        if (importTime) {
            this.addData('BundleTime', Date.now() - importTime);
        }

        this.startMemory = (self?.performance as PerformanceWithMemory)?.memory?.usedJSHeapSize;
        this.startReactions = totalReactionCount;
        this.perfStartTime = self?.performance?.now();

        if (!this.options?.skipNonMetadataTasks) {
            // if the the datapoint doesn't end in 60 seconds, then end it with a timeout error
            this.timeoutId = setTimeout(
                this.endWithTimeout.bind(this) as TimerHandler,
                options && typeof options.timeout == 'number'
                    ? options.timeout
                    : defaultDatapointTimeout
            );

            const capturePerfProfiles = getAnalyticsFlightsAndAppSettings().capturePerfProfiles;
            if (
                capturePerfProfiles &&
                capturePerfProfiles.some(e => e.name == eventName && Date.now() < e.expire)
            ) {
                this.endProfile = startSelfProfiler(eventName, { minDuration: 50 });
            }
        }

        addStackTraceToEvent(this.options);
    }
    /**
     * @deprecated Use addToPredefinedWaterfall or addToCustomWaterfall instead. See https://aka.ms/OWALayerCakeGraph for more inforamttion.
     */
    addCheckmark(checkmarkName: string, timestamp?: number): number {
        this.performanceMark(checkmarkName);
        const time = this.timeFromStart(timestamp);
        this.addToWaterfall(checkmarkName, time);
        return time;
    }
    /**
     * @deprecated Use addToPredefinedWaterfall or addToCustomWaterfall instead. See https://aka.ms/OWALayerCakeGraph for more inforamttion.
     */
    addCheckpoint(checkpoint: string) {
        this.addToWaterfall(checkpoint, 1);
    }
    /**
     * @deprecated Use addToPredefinedWaterfall or addToCustomWaterfall instead. See https://aka.ms/OWALayerCakeGraph for more inforamttion.
     */
    private addToWaterfall(key: string, time: number) {
        if (!this.waterfallTimings) {
            this.waterfallTimings = {};
        }
        try {
            const uniqueKey = getUniquePropertyString(this.waterfallTimings, key, this.eventName);
            if (uniqueKey && !this.hasEnded) {
                this.waterfallTimings[uniqueKey] = time;
            }
        } catch {
            // We add checkmarks automatically within the action framework and there can be a lot
            // with the same exact name. So if we can't find a unique property name, don't add the
            // checkmark
        }
    }

    addToPredefinedWaterfall(
        checkpoint: PredefinedCheckpoint,
        discardIfDefined?: boolean,
        customTime?: number,
        actionName?: string
    ) {
        if (discardIfDefined && this.isWaterfallCheckpointDefined(checkpoint)) {
            trace.warn(
                `Waterfall Timing Discarded. DP: ${this.eventName}. Checkpoint: ${checkpoint}`
            );
            return;
        }

        const time = customTime || this.timeFromStart();
        this.addWaterfallColumn(checkpoint, time.toFixed(), actionName);
        if (!customTime) {
            this.performanceMark(getWaterfallColumnName(checkpoint));
        }
    }

    addToCustomWaterfall(
        index: CustomWaterfallRange,
        checkpoint: string,
        discardIfDefined?: boolean,
        customValue?: number | string
    ) {
        if (discardIfDefined && this.isWaterfallCheckpointDefined(index)) {
            trace.warn(`Waterfall Timing Discarded. DP: ${this.eventName}. Index: ${index}`);
            return;
        }

        const time = customValue || this.timeFromStart();
        const value = getCustomValue(checkpoint, time);

        if (index < 1 || index > 15) {
            /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
             * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
             *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
            logGreyError(
                `The custom waterfall (${checkpoint}) index must be between 1 and 15. Datapoint name: ${this.eventName}`
            );
        } else {
            this.addWaterfallColumn(getCustomColumn(index), value);
            if (!customValue) {
                this.performanceMark(checkpoint);
            }
        }
    }

    addToPredefinedOrCustomWaterfall(
        checkpoint: PredefinedCheckpoint,
        index: CustomWaterfallRange | undefined,
        discardIfDefined?: boolean
    ) {
        if (this.getData(getWaterfallColumnName(checkpoint))) {
            if (!index) {
                logGreyError(
                    'CustomCheckpointColumnIsAreadyUsed',
                    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                     * Error constructor names can only be a string literals.
                     *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
                    new Error(
                        `addToPredefinedOrCustomWaterfall for the checkpoint ${checkpoint} (DP: ${this.eventName}) requires a custom index`
                    )
                );
                return;
            }

            this.addToCustomWaterfall(index, checkpoint, discardIfDefined);
        } else {
            this.addToPredefinedWaterfall(checkpoint, discardIfDefined);
        }
    }

    setEventTimestamp(eventTimestamp: number) {
        this.options.eventTimestamp = eventTimestamp;
        PerformanceDatapoint.EventTimeToDpMapping.set(eventTimestamp, this);
    }

    isWaterfallCheckpointDefined(checkpointOrIndex: PredefinedCheckpoint | CustomWaterfallRange) {
        return !!this.getWaterfallColumnData(checkpointOrIndex);
    }

    setEndPending() {
        if (!this.hasEnded) {
            this.schedulePendingCallbackResolutionOnNextPaint();
            this.isEndPending = true;
        }
    }

    /**
     * Add a callback to be executed either:
     * - When the next paint occurs or,
     * - When the datapoint ends, or
     * - When resolvePendingCallbacks is called
     */
    addCallbackResolvedAfterNextPaint(cb: () => void) {
        if (!this.hasEnded) {
            this.schedulePendingCallbackResolutionOnNextPaint();
            this.pendingCallbacks.push(cb);
        }
    }

    resolvePendingCallbacks() {
        if (!this.hasEnded) {
            this.resolvePendingCallbacksInternal(false);
        }
    }

    private schedulePendingCallbackResolutionOnNextPaint() {
        // Only schedule resolve once per datapoint
        if (this.isEndPending == false && this.pendingCallbacks.length == 0) {
            getNextPaint(() => this.resolvePendingCallbacks());
        }
    }

    private resolvePendingCallbacksInternal(isEnding: boolean) {
        for (const cb of this.pendingCallbacks) {
            cb();
        }

        this.pendingCallbacks = [];

        if (!isEnding && this.isEndPending) {
            this.end();
        }
    }

    private addWaterfallColumn(
        name: `Custom${CustomWaterfallRange}` | PredefinedCheckpoint,
        value: string | number,
        actionName?: string
    ) {
        const key = getWaterfallColumnName(name);
        if (this.getData(key) && !this.dataRetries) {
            /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
             * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
             *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
            logGreyError(
                `The waterfall checkpoint ${name} already exist. It can only be logged once. Datapoint name: ${this.eventName} . ActionName: ${actionName}`
            );
        } else {
            this.addData(key, value);
        }
    }

    endWithError(
        status: DatapointStatus | ((duration?: number) => DatapointStatus),
        error?: TraceErrorObject,
        duration?: number,
        errorType?: ErrorType
    ) {
        const resolvedStatus = typeof status == 'function' ? status(duration) : status;

        // In some cases, Typescript doesn't catch that an error is being passed instead of a DatapointStatus
        // for the status arguments. We manually check that it's a valid input at runtime.
        if (!possibleStatus.includes(resolvedStatus)) {
            const errorString = `Invalid call to "endWithError". Expected status to be of a "DatapointStatus" type but got ${status} for the datapoint named ${this.eventName}`;
            trace.warn(errorString);
            logUsage('InvalidStatusForEndWithError', {
                message: errorString,
                datapoint: this.eventName,
            });
        }

        return this.endInternal('endWithError', duration, status, error, errorType);
    }
    endAfterAnimationFrame() {
        safeRequestAnimationFrame(() => {
            this.endInternal('endAfterAnimationFrame');
        });
    }
    end(
        duration?: number,
        overrideStatus?: DatapointStatus | ((duration?: number) => DatapointStatus),
        errorIn?: TraceErrorObject,
        errorTypeIn?: ErrorType | 'general'
    ): void {
        this.endInternal('end', duration, overrideStatus, errorIn, errorTypeIn);
    }
    markUserPerceivedTime(waitForAnimationFrame?: boolean) {
        if (waitForAnimationFrame) {
            safeRequestAnimationFrame(this.addUserPerceivedTime.bind(this));
        } else {
            this.addUserPerceivedTime();
        }
    }
    invalidate() {
        this.hasEnded = true;
        this.invalidated = true;
    }
    pause() {
        this.timeBeforePause += this.timeFromStart();
        this.startTime = undefined;
    }
    resume() {
        if (!this.startTime) {
            this.startTime = Date.now();
        }
    }
    endAction(overrideStatus?: DatapointStatus, error?: TraceErrorObject): void {
        // The scneario might have ended the datapoint with returnTopExecutingAction.
        // If it has then we don't need to the middleware to end the datapoint.
        if (!this.hasEnded) {
            this.invalidated = true;
            this.addDataWithoutChecks('RequestIds', this.allRequestIds.join(';'));
            this.addDataWithoutChecks('cV', this.responseCorrelationVectors.join(';'));
            this.addDataWithoutChecks('Cache', this.madeNetworkRequest ? 'NoCache' : 'Cache');
            this.endInternal('endAction', undefined, overrideStatus, error);
        }
    }
    addDataSource(dataSource: DataSource) {
        this.dataSource = dataSource;

        if (isNetworkDataSource(dataSource)) {
            this.madeNetworkRequest = true;
        }
    }
    getWaterfallColumnData(checkpointOrIndex: PredefinedCheckpoint | CustomWaterfallRange) {
        return this.getData(
            getWaterfallColumnName(
                typeof checkpointOrIndex === 'number'
                    ? getCustomColumn(checkpointOrIndex)
                    : checkpointOrIndex
            )
        );
    }
    getDataSource() {
        return this.dataSource;
    }
    getStartTime(): number | undefined {
        return this.startTime;
    }
    calculateTotalDuration(duration?: number): number {
        return typeof duration === 'number'
            ? Math.floor(duration)
            : this.timeFromStart() + this.timeBeforePause;
    }
    getE2ETimeElapsed() {
        return this.getData('E2ETimeElapsed') as number | undefined;
    }
    isSent(): boolean {
        return this.hasEnded;
    }

    rehydrateFromJSObject(
        jsObject: PerformanceDatapointObjectType,
        normalizeWaterfallStart?: boolean
    ) {
        if (jsObject.dataSource) {
            this.addDataSource(jsObject.dataSource);
        }

        if (jsObject.properties) {
            for (let [key, value] of Object.entries(jsObject.properties) as [string, string][]) {
                if (value && key?.startsWith('WF_') && !this.getData(key)) {
                    if (normalizeWaterfallStart) {
                        const dpStart = this.getStartTime();
                        const jsObjectStart = jsObject.startTime;

                        if (dpStart && jsObjectStart) {
                            const [name, offset] = value.split('|') as [string, string];
                            value = `${name}|${jsObjectStart - dpStart + Number(offset)}`;
                        }
                    }

                    this.getProperties()[key] = value;
                }
            }
        }

        if (jsObject.propertyBag) {
            for (const key of Object.keys(jsObject.propertyBag)) {
                this.addCustomProperty(key, jsObject.propertyBag[key]);
            }
        }

        this.didExecuteGqlQuery = !!(this.didExecuteGqlQuery || jsObject.didExecuteGqlQuery);
    }

    toJSObject() {
        return JSON.parse(JSON.stringify(this)) as PerformanceDatapointObjectType;
    }

    public static fromPerfDpJSObject(
        datapointObj: PerformanceDatapointObjectType
    ): PerformanceDatapoint {
        return Object.assign(
            /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
             * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
             *	> Datapoint's event names can only be a string literals as the first argument of the constructor. */
            new PerformanceDatapoint(datapointObj.eventName, {
                ...datapointObj.options,
                skipNonMetadataTasks: true,
            }),
            datapointObj
        ) as PerformanceDatapoint;
    }

    private async endInternal(
        type: 'end' | 'endWithError' | 'endAfterAnimationFrame' | 'endAction' | 'endWithTimeout',
        duration?: number,
        overrideStatus?: DatapointStatus | ((duration?: number) => DatapointStatus),
        errorIn?: TraceErrorObject,
        errorTypeIn?: ErrorType | 'general'
    ) {
        if (this.hasEnded) {
            if (!this.invalidated) {
                logUsage('ActionCalledAfterPerfDatapointEndedV2', {
                    event: this.eventName,
                    type,
                    endCalls: this.previousEndCalls.join(','),
                    errMsg: scrubForPii(errorIn?.message),
                    errorTypeIn,
                });
            }
        } else {
            /**
             * The duration should always be the first calculation of this function.
             */

            // if duration is not passed in, then calculate the time based of the current time it was ended and the time the datapoint was created.
            const totalDuration = this.calculateTotalDuration(duration);
            // if the duration was measured elsewhere then we really don't know when the measured interval ended
            if (duration === undefined) {
                this.performanceMark('e');
            }
            this.addDataWithoutChecks('E2ETimeElapsed', totalDuration);

            /**
             * Other checks and calculations can be added here
             */

            this.endProfile?.(true /* shouldLog */);
            this.endProfile = null;

            // resolve any pending waterfall marks before we end
            this.resolvePendingCallbacksInternal(true /* isEnding */);

            this.hasEnded = true;
            this.endTime = typeof performance.now === 'function' ? performance.now() : undefined;

            clearTimeout(this.timeoutId);

            if (this.dataSource) {
                this.addDataWithoutChecks('DataSource', this.dataSource);
            }

            if (this.dataRetries) {
                this.addDataWithoutChecks('DataRetries', this.dataRetries);
            }

            this.addHealth();

            const error = tryGetTraceObjectErrorFromApolloError(errorIn);
            const statusAndType = getStatusAndType(this, error);

            const calculatedStatus =
                (typeof overrideStatus == 'function' ? overrideStatus(duration) : overrideStatus) ||
                statusAndType?.status ||
                DatapointStatus.Success;
            const errorType = errorTypeIn || statusAndType?.type || 'general';

            if (error) {
                trace.warn(`Datapoint ${this.eventName} ended with an error of ${error.message}`);
            }

            // if we have a scenario event, then wait for the correlated performance observer to fire
            if (this.options?.eventTimestamp) {
                // save the values in case we log it later after the scenario event comes in
                this.lazyLogArugments = { calculatedStatus, errorType };

                // if the scenario event doesn't come in after 5 seconds then log it anyways
                this.scenarioEventTimeoutId = self.setTimeout(() => {
                    this.logEvent(calculatedStatus, errorType);
                    this.lazyLogArugments = undefined;
                }, 5000);
            } else {
                this.logEvent(calculatedStatus, errorType);
            }
        }
        this.previousEndCalls.push(type);
    }
    private addHealth() {
        let errorStep: string = 's';
        try {
            const endTime: number | undefined = self?.performance?.now();

            errorStep = 'rc';
            if (this.startScore > -1) {
                this.health.rc = totalReactionCount - this.startReactions;
            }

            errorStep = 'c';
            if (this.startScore > -1) {
                this.health.ls = datapointLayoutScore - this.startScore;
            }

            errorStep = 'm';
            if (this.startMemory) {
                const current = (self?.performance as PerformanceWithMemory)?.memory
                    ?.usedJSHeapSize;
                const difference = Math.floor((current - this.startMemory) / 1024);
                if (difference > 0) {
                    this.health.md = difference; // report in KB
                }
            }

            errorStep = 'lm';
            const lazyLoadedModules = getLazyLoadedModulesAndCleanup(this.perfStartTime);
            if (lazyLoadedModules.length > 0) {
                const lazyModules = this.matchLazyLoadedModules(lazyLoadedModules, endTime);
                if (lazyModules.length > 0) {
                    this.health.lm = lazyModules;
                }
            }

            errorStep = 'hc';
            const hardwareConcurrency = self.navigator?.hardwareConcurrency;
            if (typeof hardwareConcurrency == 'number') {
                this.health.c = hardwareConcurrency;
            }
        } catch (e) {
            logUsage('DatapointHealthError', { error: e?.message, errorStep });
        }
    }
    private matchLazyLoadedModules(
        lazyLoadedModules: LazyLoadedModules[],
        end: number
    ): LazyModuleStats[] {
        const match: LazyModuleStats[] = [];

        for (const { start, duration, attemps } of lazyLoadedModules) {
            if (this.perfStartTime && start >= this.perfStartTime && start + duration <= end) {
                const moduleStats: LazyModuleStats = {
                    d: Math.floor(duration),
                };
                if (attemps > 1) {
                    moduleStats.a = attemps;
                }
                match.push(moduleStats);
            }
        }

        return match;
    }
    private addUserPerceivedTime(): void {
        this.performanceMark('UserPerceivedTime');
        this.addData('UserPerceivedTime', this.timeFromStart());
    }
    private performanceMark(value: string) {
        if (!this.noMarking) {
            addPerfomanceMarkToDatapoint(this, value);
        }
    }
    private endWithTimeout() {
        if (this.startTime && hasUserWindowHadFocusSince(this.startTime)) {
            // end with timeout is a race condition so it is possible that it ends multiple times
            this.invalidated = true;
            this.options = this.options || {};
            this.options.logVerbose = true;
            this.endInternal('endWithTimeout', undefined, DatapointStatus.Timeout);
        }
    }

    private logEvent(calculatedStatus: DatapointStatus, errorType: ErrorType | 'general') {
        if (this.options?.eventTimestamp && this.endTime) {
            this.addDataWithoutChecks(
                'TrueDuration',
                Math.floor(this.endTime - this.options.eventTimestamp)
            );
        }
        if (Object.keys(this.health).length > 0) {
            this.addDataWithoutChecks('Health', JSON.stringify(this.health));
        }
        lazyLogPerformanceDatapoint.importAndExecute(this, calculatedStatus, errorType);
    }
    timeFromStart(timestamp?: number): number {
        return this.startTime ? (timestamp || Date.now()) - this.startTime : 0;
    }

    addScenarioEventTiming(
        entry: PerformanceEventTiming,
        eventLocations: string[],
        eventSources: string[],
        inputDelayLocations: string[],
        inputDelaySources: string[]
    ) {
        self.clearTimeout(this.scenarioEventTimeoutId);

        this.health.loaf = eventLocations.join('|');
        this.health.loafss = eventSources.join('|');
        this.health.idloaf = inputDelayLocations.join('|');
        this.health.idloafss = inputDelaySources.join('|');
        this.health.inp = Math.floor(entry.duration);
        this.health.id = Math.floor(entry.processingStart - entry.startTime);
        if (this.lazyLogArugments) {
            const { calculatedStatus, errorType } = this.lazyLogArugments;
            this.logEvent(calculatedStatus, errorType);
        } else {
            // make sure to unset this so we log the datapoint if end is called afterwards
            this.options.eventTimestamp = undefined;
        }
    }
}

function getStatusAndType(
    datapoint: PerformanceDatapointType,
    error: TraceErrorObject | undefined
): StatusAndType | undefined {
    if (error) {
        const calculatedErrorMessage = addErrorToDatapoint(datapoint, error);
        return getDatapointStatus(calculatedErrorMessage, error);
    }
    return undefined;
}
