import { mutator } from 'satcheljs';
import { default as setModuleContextMailboxInfo } from '../actions/setModuleContextMailboxInfo';
import getModuleContextMailboxInfoStore from '../store/moduleContextMailboxInfoStore';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
export default mutator(setModuleContextMailboxInfo, actionMessage => {
    getModuleContextMailboxInfoStore().moduleContextMailboxInfos.set(
        actionMessage.module,
        actionMessage.mailboxInfo
    );
});
