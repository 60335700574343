import { getIdentityKeyForIdentityInfo, getIdentityInfoForMailboxInfo } from 'owa-auth-types';
import type { IdentityInfo } from 'owa-auth-types';
import type { MailboxInfo } from 'owa-client-types';
import type AccountAuthState from '../store/schema/AccountAuthState';
import { StateTypeEnum } from '../store/schema/AccountAuthState';
import getStore from '../store/Store';

export function getAccountAuthStateForMailboxInfo(mailboxInfo: MailboxInfo): AccountAuthState {
    const identityInfo = getIdentityInfoForMailboxInfo(mailboxInfo);
    if (identityInfo) {
        return getAccountAuthState(identityInfo);
    }

    // We could not get the identity info for the mailbox info, so return a default state
    return {
        stateType: StateTypeEnum.NoError,
    };
}

export default function getAccountAuthState(identityInfo: IdentityInfo): AccountAuthState {
    const identityKey = getIdentityKeyForIdentityInfo(identityInfo);
    const authState = getStore().accountAuthStates.get(identityKey);
    return (
        authState ?? {
            stateType: StateTypeEnum.NoError,
        }
    );
}
