import { getApolloClientInternal } from './apolloClient';
import type { StrictVariables } from 'owa-apollo-types';
import type {
    QueryOptions,
    TypedDocumentNode,
    ApolloQueryResult,
    OperationVariables,
} from '@apollo/client';

export type StrictQueryOptions<TActualVars, TData> = Omit<
    QueryOptions<TActualVars, TData>,
    'query'
>;

export const strictQuery = <
    TSchemaVars extends OperationVariables,
    TActualVars extends StrictVariables<TSchemaVars, TActualVars>,
    TData
>(
    query: TypedDocumentNode<TData, TSchemaVars>,
    options: StrictQueryOptions<TActualVars, TData> = {}
): Promise<ApolloQueryResult<TData>> => {
    // Add caller stack to the context for better diagnosability
    options.context ||= {};
    const stack = new Error('Trace').stack;
    options.context.queryStack = stack;

    const client = getApolloClientInternal();
    return client.query({
        ...(options as unknown as QueryOptions<TSchemaVars, TData>),
        query,
    });
};
