import { mutatorAction } from 'satcheljs';
import { mailListItemAnimationStore } from '../store/Store';
import type { MailListItemAnimation } from '../store/schema/MailListItemAnimationStore';

export const removePendingAnimation = mutatorAction(
    'removePendingAnimation',
    (animationToRemove: MailListItemAnimation) => {
        mailListItemAnimationStore.pendingAnimations =
            mailListItemAnimationStore.pendingAnimations.filter(pendingAnimation => {
                return pendingAnimation.animationId !== animationToRemove.animationId;
            });
    }
);
