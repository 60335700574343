import { lazyGetBlockSections } from 'owa-mail-ribbon-sections/lib/sections';

import type { MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onBlockMenuClicked = (
    keytipTabId: MailRibbonTabId | undefined,
    props: ReadOnlyRibbonControlDefProps
) => {
    lazyGetBlockSections.import().then(getBlockSections => {
        setMenuDefinition('BlockMenuDefinitionStore', props.projectionTabId, () => {
            return {
                sections: getBlockSections(keytipTabId, props),
            };
        });
    });
};
