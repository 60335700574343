import type MailboxJunkEmailConfigurationOptions from 'owa-service/lib/contract/MailboxJunkEmailConfigurationOptions';
import initMailboxJunkEmailConfiguration from '../actions/initMailboxJunkEmailConfiguration';
import { getMailboxJunkEmailConfigurationStore } from '../store/store';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Returns the mailbox junk email configuration object which is observable
 */
export default async function getMailboxJunkEmailConfiguration(
    mailboxInfo: MailboxInfo
): Promise<MailboxJunkEmailConfigurationOptions> {
    const store = getMailboxJunkEmailConfigurationStore(mailboxInfo);

    // Load if the configuration is not set
    if (!store.initialized) {
        await initMailboxJunkEmailConfiguration(mailboxInfo);
    }

    return store.mailboxJunkEmailConfiguration;
}
