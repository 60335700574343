import type { MailFolder } from 'owa-graph-schema';
import folderStore, { getEffectiveFolderDisplayName } from 'owa-folders';
import {
    getStore as getFavoritesStore,
    isMultiAccountsCombinedFavoritesEnabled,
} from 'owa-favorites';
import type { FavoriteFolderData } from 'owa-favorites-types';
import publicFolderFavoriteStore from 'owa-public-folder-favorite/lib/store/publicFolderFavoriteStore';
import loc, { format } from 'owa-localize';
import { duplicateFavoriteDisplayNameTemplate } from './FavoriteDisplayNameForMultiAccounts.locstring.json';

function getFolder(folderId: string): MailFolder {
    if (publicFolderFavoriteStore.folderTable.has(folderId)) {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        return publicFolderFavoriteStore.folderTable.get(folderId)!;
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return folderStore.folderTable.get(folderId)!;
}

export default function getFavoriteFolderDisplayNameForMultiAccount(
    currentFolderId: string
): string {
    const currentFolderDisplayName = getEffectiveFolderDisplayName(getFolder(currentFolderId));

    if (!isMultiAccountsCombinedFavoritesEnabled()) {
        return currentFolderDisplayName;
    }

    const favoriteFolderIds = [...getFavoritesStore().outlookFavorites.values()]
        .filter(favorite => favorite.type === 'folder')
        .map(favorite => (favorite as FavoriteFolderData).folderId);

    let folderName = currentFolderDisplayName;

    for (const folderId of favoriteFolderIds) {
        if (
            currentFolderId !== folderId &&
            currentFolderDisplayName.localeCompare(
                getEffectiveFolderDisplayName(getFolder(folderId)),
                undefined,
                { sensitivity: 'accent' }
            ) === 0
        ) {
            folderName = format(
                loc(duplicateFavoriteDisplayNameTemplate),
                currentFolderDisplayName,
                getFolder(currentFolderId).mailboxInfo.mailboxSmtpAddress
            );
            break;
        }
    }

    return folderName;
}
