export const switchGroupDatapointName = 'SwitchGroup';
export const navigateFromMeToWeDatapointName = 'NavigateFromMeToWe';

// Group Folders CRUD datapoints
export const renameGroupFolderDP = 'RenameGroupFolder';
export const deleteGroupFolderDP = 'DeleteGroupFolder';
export const moveGroupFolderDP = 'MoveGroupFolderDP';

// Groups Usage datapoints
export const ManageGroupEmailDP = 'ManageGroupEmail';
