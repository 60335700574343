import { mutatorAction } from 'satcheljs';
import type { PublicFolderStore } from '../store/publicFolderFavoriteStore';
import type { MailFolder } from 'owa-graph-schema';

const addPublicFolderToStore = mutatorAction(
    'ADD_PUBLIC_FOLDER_TO_STORE',
    (state: PublicFolderStore, item: MailFolder) => {
        const folderId = item.id;

        if (!state.folderTable.has(folderId)) {
            state.folderTable.set(folderId, item);
        }
    }
);

export default addPublicFolderToStore;
