export default function mailRowCleanupRouteHandler(
    _oldRoute?: string[],
    /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
     * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
     *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
    _oldParameters?: any,
    newRoute?: string[],
    /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
     * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
     *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
    _newParameters?: any
) {
    return !!newRoute && newRoute.some(value => value == 'search');
}
