import type {
    RibbonAccCheckerInfo,
    RibbonEmojiInfo,
    RibbonDictationInfo,
    RibbonItemInfo,
} from 'owa-compose-actioncontrols';
import type { RibbonEditingInfo } from 'owa-compose-actioncontrols-sections/lib/schema/RibbonEditingInfo';
import type { RibbonToggleFormatControlInfo } from 'owa-compose-formatcontrols';
import type RibbonCutCopyPasteInfo from 'owa-compose-formatcontrols-sections/lib/schema/RibbonCutCopyPasteInfo';
import type { RibbonFluidMenuInfo } from 'owa-fluid-integrations';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import getProjection from 'owa-popout-v2/lib/utils/getProjection';
import { getRecipientWellsFromComposeViewState } from 'owa-mail-compose-actions/lib/utils/getAllRecipientsAsEmailAddressStrings';
import getCurrentFromAddress from 'owa-mail-compose-actions/lib/utils/getFromAddressFromRecipientWell';
import { tryAddRecipientFromEmailAddressString } from 'owa-mail-compose-actions/lib/utils/tryAddRecipientFromEmailAddressString';
import internalRetrieve from './internalRetrieve';

export interface RibbonComposeInfo
    extends RibbonEditingInfo,
        RibbonAccCheckerInfo,
        RibbonEmojiInfo,
        RibbonCutCopyPasteInfo,
        RibbonToggleFormatControlInfo,
        RibbonMailComposeInfo,
        RibbonItemInfo,
        RibbonDictationInfo {}

export function retrieveWindowForRibbon(tabId: string | undefined): Window {
    if (tabId) {
        const projection = getProjection(tabId);
        return projection?.window || window;
    }
    return window;
}

export function retrieveCommonInfoForRibbon(composeId: string): RibbonComposeInfo | null {
    return internalRetrieve<RibbonComposeInfo>(composeId, (viewState, targetWindow) => ({
        targetWindow,
        composeViewState: viewState,
        editorViewState: viewState,
        infoBarHostViewState: viewState,
        dictationViewState: viewState.dictation,
        accChecker: viewState.accChecker,
        expressionId: viewState.expressionId,
        ribbonViewState: viewState.ribbon,
        isPlainTextEditor: viewState.bodyType != 'HTML',
        itemId: viewState.itemId?.Id,
        conversationId: viewState.conversationId,
        mailboxInfo: viewState.mailboxInfo,
    }));
}

export function retrieveFluidInfoForRibbon(composeId: string): RibbonFluidMenuInfo | null {
    return internalRetrieve<RibbonFluidMenuInfo>(composeId, (viewState, targetWindow) => ({
        targetWindow,
        editorViewState: viewState,
        fileName: viewState.subject,
        composeLinkParameters: {
            getRecipientWells: () => getRecipientWellsFromComposeViewState(viewState),
            fromAddress: getCurrentFromAddress(viewState.toRecipientWell),
            linkIdContainer: viewState.attachmentWell,
        },
        atMentionHandler: (address: string) => {
            tryAddRecipientFromEmailAddressString(viewState, address, targetWindow);
        },
    }));
}
