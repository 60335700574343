import setCombinedFavoritesInCache from './setCombinedFavoritesInCache';
import getCombinedFavoritesFromCache from './getCombinedFavoritesFromCache';
import type { CombinedFavoritesList } from '../store/schema/CombinedFavoritesList';

export function updateFavoriteIdInCache(oldId: string, newId: string) {
    const combinedList: CombinedFavoritesList | undefined = getCombinedFavoritesFromCache();
    if (!combinedList) {
        return;
    }

    setCombinedFavoritesInCache({
        combinedFavorites: combinedList.combinedFavorites.map(favorite => {
            if (favorite && favorite.favoriteId === oldId) {
                return { ...favorite, favoriteId: newId };
            } else {
                return favorite;
            }
        }),
    });
}
