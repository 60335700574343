import type {
    default as SearchTableQuery,
    SearchTableScenarioType,
} from '../store/schema/SearchTableQuery';
import type { TableQuery } from 'owa-mail-list-store';
import { type TableQueryType } from 'owa-mail-list-store';

/**
 * @return Scenario type of the search table query (undefined if the table query
 * is not of search type)
 */
export default function getSearchScenarioType(tableQuery: TableQuery): SearchTableScenarioType {
    const searchTableQuery = tableQuery as SearchTableQuery;
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (16,5): Type 'SearchTableScenarioType | undefined' is not assignable to type 'SearchTableScenarioType'.
    // @ts-expect-error
    return tableQuery.type == 1 ? searchTableQuery.scenarioType : undefined;
}
