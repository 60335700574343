export interface ActionMetadata {
    stack: string[]; // current actions that are firing
    queue: string[];
    count: number;
    actionMap: Map<string, number>;
}

let actionMetadata: ActionMetadata | undefined;

export function getActionMetadata(): ActionMetadata {
    if (!actionMetadata) {
        actionMetadata = {
            stack: [],
            queue: [],
            count: 0,
            actionMap: new Map(),
        };
    }

    return actionMetadata;
}
