import FolderTreeLoadStateEnum from '../store/schema/FolderTreeLoadStateEnum';
import getMailboxFolderTreeDataTable from './getMailboxFolderTreeDataTable';
import type { MailboxInfo } from 'owa-client-types';

export default function getFolderTreeLoadingState(
    folderTreeIdentifier: string,
    mailboxInfo: MailboxInfo
): FolderTreeLoadStateEnum {
    const folderTreeTable = getMailboxFolderTreeDataTable(mailboxInfo);
    const folderTreeData = folderTreeTable && folderTreeTable.get(folderTreeIdentifier);
    return folderTreeData?.loadingState || FolderTreeLoadStateEnum.Uninitialized;
}
