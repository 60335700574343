import React from 'react';
import { observer } from 'owa-mobx-react';
import { CommandRibbon } from 'owa-command-ribbon';
import type { IAttachmentLocalComputerFilePicker } from 'owa-attachment-filepicker';
import { useWindow } from '@fluentui/react-window-provider';
import type { AppRibbonProps } from './AppRibbonProps';

export const AppRibbonContent = observer((props: AppRibbonProps) => {
    const localComputerFilePickerRef = React.useRef<
        React.RefObject<IAttachmentLocalComputerFilePicker>
    >(React.createRef());
    const onlineLocationOneDriveFilePickerRef = React.useRef<
        React.RefObject<IAttachmentLocalComputerFilePicker>
    >(React.createRef());
    const uploadAndShareFromCloudFilePickerRef = React.useRef<
        React.RefObject<IAttachmentLocalComputerFilePicker>
    >(React.createRef());

    const targetWindow: Window = useWindow() ?? window;

    const attachmentMultiPicker = React.useMemo(
        () => ({
            computer: localComputerFilePickerRef,
            onlineLocationOneDrive: onlineLocationOneDriveFilePickerRef,
            uploadAndShareFromCloud: uploadAndShareFromCloudFilePickerRef,
            targetWindow,
        }),
        []
    );

    const inlineImageMultiPicker = React.useRef<
        React.RefObject<IAttachmentLocalComputerFilePicker>
    >(React.createRef());

    const attachmentInlinePickers = React.useMemo(
        () => ({
            ...attachmentMultiPicker,
            computer: inlineImageMultiPicker,
        }),
        []
    );

    const ribbonProps = React.useMemo(
        () => props.getRibbonProps(attachmentInlinePickers, attachmentMultiPicker),
        [props.getRibbonProps, props.ribbonPropsDependency1, props.ribbonPropsDependency2]
    );

    return (
        <>
            {props.renderExtraElements?.(attachmentInlinePickers, attachmentMultiPicker)}
            <CommandRibbon {...ribbonProps} />
        </>
    );
}, 'AppRibbonContent');
