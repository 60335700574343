import { getStore } from '../store/SharePointItemsViewNavigationLocationStore';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

export function getSharePointItemsViewIsAtRootLevel(groupSmtpAddress: string): boolean {
    groupSmtpAddress = transformGroupSmtp(groupSmtpAddress);
    const store = getStore();
    if (store.has(groupSmtpAddress)) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2532 (10,22): Object is possibly 'undefined'.
        // @ts-expect-error
        const path = store.get(groupSmtpAddress).currentPath;
        return !(path && path.length > 0 && path !== '/');
    }

    // No stored path implies user hasnt navigated anywhere and is therefore at the root
    return true;
}
