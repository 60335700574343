import { action } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Action to initialize shared folders
 */
export const initializeSharedFolderRoots = action(
    'INITIALIZE_SHARED_FOLDERS',
    (mailboxInfo: MailboxInfo) => {
        return {
            mailboxInfo,
        };
    }
);
