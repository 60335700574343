import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isMessageRecallEnabled as isMessageRecallEnabledInTenant } from 'owa-nonboot-userconfiguration-manager';

export default function isMessageRecallEnabled(mailboxInfo: MailboxInfo) {
    return (
        isFeatureEnabled('cmp-message-recall', mailboxInfo) &&
        (!isFeatureEnabled('cmp-message-recall-admin-setting', mailboxInfo) ||
            isMessageRecallEnabledInTenant(mailboxInfo))
    );
}
