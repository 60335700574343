import React from 'react';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Avoid deprecated APIs, use portals instead of rendering to a new root
 *	> 'unmountComponentAtNode' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root
 *	> 'render' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root */
import { unmountComponentAtNode, render } from 'owa-react-dom';
import { WindowProvider } from '@fluentui/react-window-provider';
import { DirectionalHint } from '@fluentui/react/lib/ContextualMenu';
import { ZoomButtonsCallout } from 'owa-custom-zoom';
import { getZoomViewState } from 'owa-custom-zoom-store/lib/store/zoomStore';
import getTabIdFromProjection from 'owa-popout-v2/lib/utils/getTabIdFromProjection';

const ZOOM_BUTTONS_DIV_ID = 'zoomButtonsDiv';

const calloutStyles = {
    root: {
        borderRadius: '2px',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.1)',
    },
    calloutMain: {
        borderRadius: '2px',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.1)',
    },
};

export default function renderZoomButtonsCallout(targetId: string, targetWindow: Window) {
    let zoomButtonsDiv = targetWindow.document.getElementById(ZOOM_BUTTONS_DIV_ID);

    if (!zoomButtonsDiv) {
        zoomButtonsDiv = targetWindow.document.createElement('div');
        zoomButtonsDiv.id = ZOOM_BUTTONS_DIV_ID;
        zoomButtonsDiv.style.height = '100%';
        zoomButtonsDiv.style.width = '0';
        zoomButtonsDiv.style.display = 'inline-block';
        const zoomButton = targetWindow.document.getElementById(targetId);
        zoomButton?.parentNode?.insertBefore(zoomButtonsDiv, zoomButton);
    }

    const removeZoomButtonsDiv = () => {
        const zoomButtonsCalloutDiv = targetWindow.document.getElementById(ZOOM_BUTTONS_DIV_ID);
        if (zoomButtonsCalloutDiv) {
            unmountComponentAtNode(zoomButtonsCalloutDiv);
            const zoomButton = targetWindow.document.getElementById(targetId);
            zoomButton?.parentNode?.removeChild(zoomButtonsCalloutDiv);
        }
    };

    const projectionRPTabId = getTabIdFromProjection(targetWindow) ?? undefined;
    const context = (projectionRPTabId ?? 'default') + '_ribbon';

    const zoomViewState = getZoomViewState(context);
    if (zoomViewState) {
        zoomViewState.isCalloutVisible = !zoomViewState.isCalloutVisible;
    }

    if (zoomViewState?.isCalloutVisible) {
        render(
            <React.StrictMode>
                <WindowProvider window={targetWindow}>
                    <ZoomButtonsCallout
                        context={context}
                        targetForCallout={
                            targetWindow.document.getElementById(targetId) ?? undefined
                        }
                        customCalloutStyles={calloutStyles}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        onDismiss={removeZoomButtonsDiv}
                    />
                </WindowProvider>
            </React.StrictMode>,
            zoomButtonsDiv
        );
    } else {
        removeZoomButtonsDiv();
    }
}
