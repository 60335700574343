import type { MailboxInfo } from 'owa-client-types';
import isCopilotFeatureEnabled from './isCopilotFeatureEnabled';
import { isFeatureEnabled } from 'owa-feature-flags';
import getGlobalSettingsAccountMailboxInfo from 'owa-account-source-list-store/lib/selectors/getGlobalSettingsAccountMailboxInfo';
import hasM365CopilotLicense from './hasM365CopilotLicense';

/**
 * @param mailboxInfo Mailbox info
 * @returns true if the feature is enabled, false if the feature is disabled
 */
export default function isAttachmentSummarizationEnabled(
    mailboxInfo: MailboxInfo = getGlobalSettingsAccountMailboxInfo()
): boolean {
    const devFlightEnabled = isFeatureEnabled('doc-attachment-summarization');
    const hasLicense = hasM365CopilotLicense(mailboxInfo);
    const featureEnabled = !!isCopilotFeatureEnabled(
        'AttachmentSummarization',
        mailboxInfo,
        true /* skipLanguageCheck */
    );

    return devFlightEnabled && featureEnabled && hasLicense;
}
