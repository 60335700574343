import type ApplicationSettingsStore from './schema/ApplicationSettingsStore';

import { defaultApplicationSettings } from './applicationSettingsDefinitions';

import { getLocalOverrides } from '../utils/localOverrides';
import type { MailboxInfo } from 'owa-client-types';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Make sure this isn't imported in the analytics worker
 *	> 'owa-feature-flags' import is restricted from being used. Feature flags have to be passed in the analytics worker initialization [analytics-worker-lint] */
import { getFeatureFlagsIndexerForMailboxInfo } from 'owa-feature-flags';
import type { CustomDataMap } from 'owa-analytics-types';
import { getAllowDefaultApplicationSettings } from '../utils/allowDefaultApplicationSettings';

const settingsByAccount: Map<string, ApplicationSettingsStore> = new Map();

function getApplicationSettingsStore(
    mailboxInfo: MailboxInfo | undefined
): ApplicationSettingsStore {
    return {
        settings: defaultApplicationSettings,
        overrides: getLocalOverrides(mailboxInfo),
        configIDs: undefined,
        lastError: undefined,
        initialized: false,
        report: undefined,
        searchKey: undefined,
    };
}

export default function getStore(mailboxInfo: MailboxInfo | undefined) {
    // When getting the store we want to alert unless we are in OOBE and the default settings are allowed
    const errorIfNoAccountKey = !getAllowDefaultApplicationSettings();
    const key = getFeatureFlagsIndexerForMailboxInfo(mailboxInfo, errorIfNoAccountKey);

    let rv = settingsByAccount.get(key);

    if (!rv) {
        const store = getApplicationSettingsStore(mailboxInfo);
        settingsByAccount.set(key, store);
        rv = store;
    }

    return rv;
}

export function areApplicationSettingsInitialized(mailboxInfo: MailboxInfo) {
    const key = getFeatureFlagsIndexerForMailboxInfo(mailboxInfo);
    const settings = settingsByAccount.get(key);
    return settings?.initialized;
}

// Allow the unit tests to reset the store
export function resetStoreForUnitTests() {
    settingsByAccount.clear();
}

// Allow the unit tests to get the values by key
export function getApplicationsSettingsByKeyForUnitTests(
    key: string
): ApplicationSettingsStore | undefined {
    return settingsByAccount.get(key);
}

export function getStoreDiagnosticInfo(): CustomDataMap {
    let initialized: number = 0;
    const keyLengthAndInitState: string[] = [];
    const diagnosticData: CustomDataMap = { settingsStores: settingsByAccount.size, initialized };
    const globalSettingsKey = getFeatureFlagsIndexerForMailboxInfo(undefined);

    const diagnostic = Array.from(settingsByAccount).map(([key, value]) => {
        return {
            isGlobalSettings: key === globalSettingsKey,
            initialized: value.initialized,
            keyLength: key.length,
        };
    });

    const globalInfo = diagnostic.find(info => info.isGlobalSettings);
    if (globalInfo) {
        diagnosticData.hasGlobalSettings = true;
        diagnosticData.isGlobalInitialized = globalInfo.initialized;
    }

    for (const value of diagnostic) {
        if (value.initialized) {
            initialized++;
        }

        keyLengthAndInitState.push(
            `${value.isGlobalSettings ? 'Global' : 'Mailbox'}(${value.keyLength}): ${
                value.initialized
            }`
        );
    }

    diagnosticData.initialized = initialized;
    diagnosticData.keyLengthAndInitStates = keyLengthAndInitState.join();
    return diagnosticData;
}
