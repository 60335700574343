import type TableView from '../../store/schema/TableView';
import { mutatorAction } from 'satcheljs';

export const resetTableViewProperties = mutatorAction(
    'resetTableViewProperties',
    function resetTableViewProperties(tableView: TableView) {
        tableView.isInitialLoadComplete = false;
        tableView.rowKeys = [];
        tableView.isInCheckedMode = false;
        tableView.selectedRowKeys.clear();
    }
);
