import type { ItemRowEdge, ItemRow } from 'owa-graph-schema';
import { mapOwsItemToGqlItemRow } from '../mapOwsItemToGqlItemRow';

export function getStartCursor(items?: any[]): string {
    if (items && items.length > 0) {
        return items[0].InstanceKey ?? '';
    }

    return '';
}

export function getEndCursor(items?: any[]): string {
    if (items && items.length > 0) {
        return items[items.length - 1].InstanceKey ?? '';
    }

    return '';
}

export function getEdges(items?: any[], shouldMap: boolean = true): ItemRowEdge[] {
    if (!items || items.length == 0) {
        return [];
    }

    return items.map(item => {
        return {
            __typename: 'ItemRowEdge',
            cursor: item.InstanceKey ?? '',
            node: shouldMap ? mapOwsItemToGqlItemRow(item) : (item as ItemRow),
        };
    });
}
