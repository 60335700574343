import type ItemReadingPaneViewState from '../store/schema/ItemReadingPaneViewState';
import { getStore } from '../store/Store';
import getActiveTabId from './getActiveTabId';

export default function getItemReadingPaneViewState(itemId?: string): ItemReadingPaneViewState {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (9,5): Type 'ItemReadingPaneViewState | undefined' is not assignable to type 'ItemReadingPaneViewState'.
    // @ts-expect-error
    return getStore().loadedItemReadingPaneViewStates.get(itemId || getActiveTabId());
}
