import { isGroupsEnabled } from 'owa-account-capabilities/lib/isGroupsEnabled';
import type { ClientItem } from 'owa-mail-store';
import type Item from 'owa-service/lib/contract/Item';

function isIRM(item: Item): boolean {
    return item.RightsManagementLicenseData != null;
}

function isSMIME(itemClass: string): boolean {
    return (
        itemClass.indexOf('ipm.note.smime') == 0 &&
        itemClass.indexOf('ipm.note.smime.multipartsigned') == -1
    );
}

function isReport(itemClass: string): boolean {
    return itemClass.indexOf('report') == 0;
}

function isGroupMailbox(item: ClientItem): boolean {
    return !!(
        item.MailboxInfo &&
        isGroupsEnabled(item.MailboxInfo) &&
        item.MailboxInfo.type === 'GroupMailbox'
    );
}

export default function readItemSupportsAddins(item: ClientItem): boolean {
    if (!item || isIRM(item) || item.IsDraft || isGroupMailbox(item)) {
        return false;
    } else {
        const itemClass = !!item.ItemClass ? item.ItemClass.toLowerCase() : undefined;
        if (itemClass && (isSMIME(itemClass) || isReport(itemClass))) {
            return false;
        }
    }

    return true;
}
