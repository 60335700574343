import { FreezeDryItemTypes, EventTypes } from './fdTypes';
import type { FreezeDryItem, FdEventContext } from './fdTypes';

interface FdEventManager {
    listeners: {
        [key in EventTypes]: {
            [itemType in FreezeDryItemTypes]: ((
                item?: FreezeDryItem,
                context?: FdEventContext
            ) => void)[];
        };
    };
    emitFdEvent: (
        eventType: EventTypes,
        itemType: FreezeDryItemTypes,
        item?: FreezeDryItem,
        context?: FdEventContext
    ) => void;
    addFdListener: (
        eventType: EventTypes,
        itemType: FreezeDryItemTypes,
        listener: (item?: FreezeDryItem, context?: FdEventContext) => void
    ) => void;
    removeFdListener: (
        eventType: EventTypes,
        itemType: FreezeDryItemTypes,
        listener: (item?: FreezeDryItem, context?: FdEventContext) => void
    ) => void;
}

export function addFdListener(
    eventType: EventTypes,
    itemType: FreezeDryItemTypes,
    listener: (item?: FreezeDryItem, context?: FdEventContext) => void
) {
    fdEventManager.addFdListener(eventType, itemType, listener);
}

export const fdEventManager: FdEventManager = {
    listeners: {
        [EventTypes.Restore]: {
            [FreezeDryItemTypes.MainWindow]: [],
            [FreezeDryItemTypes.MailItems]: [],
            [FreezeDryItemTypes.MailComposeItems]: [],
            [FreezeDryItemTypes.CalendarItems]: [],
            [FreezeDryItemTypes.CalendarComposeItems]: [],
        },
        [EventTypes.Persist]: {
            [FreezeDryItemTypes.MainWindow]: [],
            [FreezeDryItemTypes.MailItems]: [],
            [FreezeDryItemTypes.MailComposeItems]: [],
            [FreezeDryItemTypes.CalendarItems]: [],
            [FreezeDryItemTypes.CalendarComposeItems]: [],
        },
    },
    emitFdEvent(
        eventType: EventTypes,
        itemType: FreezeDryItemTypes,
        item?: FreezeDryItem,
        context?: FdEventContext
    ) {
        for (const listener of fdEventManager.listeners[eventType][itemType]) {
            listener(item, context);
        }
    },
    addFdListener(
        eventType: EventTypes,
        itemType: FreezeDryItemTypes,
        listener: (item?: FreezeDryItem, context?: FdEventContext) => void
    ) {
        fdEventManager.listeners[eventType][itemType].push(listener);
    },
    removeFdListener(
        eventType: EventTypes,
        itemType: FreezeDryItemTypes,
        listener: (item?: FreezeDryItem, context?: FdEventContext) => void
    ) {
        const index = fdEventManager.listeners[eventType][itemType].indexOf(listener);
        if (index !== -1) {
            fdEventManager.listeners[eventType][itemType].splice(index, 1);
        }
    },
};
