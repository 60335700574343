import { getSelectedNode } from 'owa-mail-folder-forest-store';
import type { FolderForestTreeType } from 'owa-graph-schema';
import type { GroupFolderForestNode } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import type { MailboxInfo } from 'owa-client-types';

export default function isGroupFolderNodeSelected(
    groupId: string,
    folderId: string,
    treeType: FolderForestTreeType,
    mailboxInfo: MailboxInfo
): boolean {
    const selectedNode = getSelectedNode() as GroupFolderForestNode;
    if (selectedNode?.id && selectedNode?.groupId && selectedNode?.mailboxInfo) {
        return (
            selectedNode.treeType === treeType &&
            selectedNode.type === 6 &&
            groupId.toLowerCase() === selectedNode.groupId.toLowerCase() &&
            folderId.toLowerCase() === selectedNode.id.toLowerCase() &&
            mailboxInfo.userIdentity.toLowerCase() ===
                selectedNode.mailboxInfo.userIdentity.toLowerCase()
        );
    }

    return false;
}
