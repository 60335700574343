import type { TableQuery, MailFolderTableQuery } from 'owa-mail-list-store';
import { isSearchFolderScenario, type TableQueryType } from 'owa-mail-list-store';

export default function shouldShowFolderTag(tableQuery: TableQuery): boolean {
    // Show folder tags if user is in a persona or category folder
    if (tableQuery.type === 0) {
        const { scenarioType } = tableQuery as MailFolderTableQuery;
        return isSearchFolderScenario(scenarioType);
    }

    // .... or in search results
    return tableQuery.type === 1;
}
