import type { DatapointOptions, CustomDataMap } from 'owa-analytics-types';
import GreyErrorDatapoint from '../datapoints/GreyErrorDatapoint';
import { logDatapoint } from './logDatapoint';

export function logGreyError(
    eventName: string,
    error?: Error,
    customData?: CustomDataMap,
    options?: DatapointOptions
): Promise<void> {
    return logDatapoint(
        new GreyErrorDatapoint(eventName, error || new Error('GreyError'), customData, options)
    );
}
