import getDefaultFolderIdToLoad from './helpers/getDefaultFolderIdToLoad';
import selectFolder from './selectFolder';
import { isFolderInFavorites } from 'owa-favorites';
import type { FolderForestTreeType } from 'owa-graph-schema';
import type { ActionSource } from 'owa-mail-store';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import { getUserConfiguration } from 'owa-session-store';

export default function selectDefaultFolder(
    actionSource: ActionSource,
    sessionData?: SessionData
): void {
    const defaultFolderIdToLoad = getDefaultFolderIdToLoad();

    let treeType: FolderForestTreeType = 'primaryFolderTree';

    // Attempting to resolve an issue where the folderId is null when calling select folder: https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/207103
    if (!defaultFolderIdToLoad) {
        setTimeout(() => {
            const defaultFolderIdToLoadRetry = getDefaultFolderIdToLoad();
            //special case - if folder selected in favorites and favorites tree is collapsed - fall back to primary folder tree
            if (
                isFolderInFavorites(defaultFolderIdToLoadRetry) &&
                !getUserConfiguration()?.UserOptions?.IsFavoritesFolderTreeCollapsed
            ) {
                treeType = 'favorites';
            }

            selectFolder(
                defaultFolderIdToLoadRetry,
                treeType,
                actionSource,
                undefined,
                sessionData
            );
        }, 500);
    } else {
        //special case - if folder selected in favorites and favorites tree is collapsed - fall back to primary folder tree
        if (
            isFolderInFavorites(defaultFolderIdToLoad) &&
            !getUserConfiguration()?.UserOptions?.IsFavoritesFolderTreeCollapsed
        ) {
            treeType = 'favorites';
        }

        selectFolder(defaultFolderIdToLoad, treeType, actionSource, undefined, sessionData);
    }
}
