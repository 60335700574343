import { orchestrator } from 'satcheljs';
import { isCapabilityEnabled } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';
import { copilotThemeCapability } from 'owa-capabilities-definitions/lib/copilotThemeCapability';
import { lazyFetchCopilotThemeImage } from 'owa-copilot-themes';
import { getIsDarkTheme } from 'owa-fabric-theme';
import { setItem, removeItem } from 'owa-local-storage';
import {
    getCurrentThemeId,
    getBackgroundImageData,
    getFallbackBackgroundColor,
    getIsCurrentThemeModernImage,
} from 'owa-theme';
import { onLoadTheme } from 'owa-theme-common';
import { fetchThemeBackgroundImage, ThemeConstants } from 'owa-theme-shared';
import setBackgroundColor from '../actions/setBackgroundColor';
import setBackgroundImage from '../actions/setBackgroundImage';

let currentCopilotImage: string | undefined;

orchestrator(onLoadTheme, async () => {
    const isDarkTheme = getIsDarkTheme();
    const currentThemeId = getCurrentThemeId();
    const backgroundColor = getFallbackBackgroundColor(currentThemeId, isDarkTheme);
    const isBleedThroughEnabled = isCapabilityEnabled(bleedThroughCapability);

    setBackgroundImage(undefined);
    setBackgroundColor(backgroundColor);

    // Revoke the current copilot image if it exists
    if (currentCopilotImage) {
        URL.revokeObjectURL(currentCopilotImage);
        currentCopilotImage = undefined;
    }

    if (isBleedThroughEnabled) {
        const isCopilotThemeEnabled = isCapabilityEnabled(copilotThemeCapability);
        const isModernImageTheme = getIsCurrentThemeModernImage(currentThemeId);
        const imageUrl = isModernImageTheme
            ? getBackgroundImageData(currentThemeId, isDarkTheme)
            : undefined;

        // The image key is stored in local storage for modern image themes to initiate the request earlier during boot
        // enhancing the speed of future app launches
        if (isModernImageTheme && imageUrl) {
            setItem(self, ThemeConstants.LOCAL_IMAGE_STORAGE_KEY, imageUrl);
        } else if (isCopilotThemeEnabled) {
            removeItem(self, ThemeConstants.LOCAL_IMAGE_STORAGE_KEY);
        }

        const image =
            isModernImageTheme && imageUrl
                ? await fetchThemeBackgroundImage(imageUrl)
                : isCopilotThemeEnabled
                ? await lazyFetchCopilotThemeImage.importAndExecute()
                : undefined;

        if (image) {
            if (isCopilotThemeEnabled) {
                // Save the copilot image to revoke it later
                currentCopilotImage = image;
            }

            setBackgroundImage(image);
        }
    } else {
        // This is to ensure that the image key is not stored in local storage for non-full bleed themes
        removeItem(self, ThemeConstants.LOCAL_IMAGE_STORAGE_KEY);
    }
});
