import type { MailListViewState } from 'owa-mail-store/lib/store/schema/MailListViewState';
import { action } from 'satcheljs';

export const onBulkTriageMailListLoaded = action(
    'onBulkTriageMailListLoaded',
    (tableViewId: string, mailListViewState: MailListViewState) => ({
        tableViewId,
        mailListViewState,
    })
);
