import { useManagedMutation } from './useManagedMutation';
import { NovaMutationContexts } from './util/NovaMutationContexts';

import type {
    MutationHookOptions,
    TypedDocumentNode,
    ApolloCache,
    DefaultContext,
    OperationVariables,
} from '@apollo/client';
import type { StrictMutationFunction } from './useManagedMutation';
import type { OperationDefinitionNode } from 'graphql';
import type { StrictVariables } from 'owa-apollo';

type NovaMutationTuple<
    TData,
    TSchemaVars extends OperationVariables,
    TContext = DefaultContext,
    TCache extends ApolloCache<any> = ApolloCache<any>
> = [StrictMutationFunction<TData, TSchemaVars, TContext, TCache>, boolean];

type NovaMutationContext = {
    [name: string]: unknown;
};

export function useNovaMutation<
    TData,
    TSchemaVars extends OperationVariables,
    TActualVars extends StrictVariables<TSchemaVars, TActualVars>
>(
    query: TypedDocumentNode<TData, TSchemaVars>,
    options?: MutationHookOptions<TData, TActualVars>
): NovaMutationTuple<TData, TSchemaVars, NovaMutationContext> {
    const [callback, result] = useManagedMutation<
        TData,
        TSchemaVars,
        TActualVars,
        NovaMutationContext
    >(query, options);
    const customCallback: StrictMutationFunction<
        TData,
        TSchemaVars,
        NovaMutationContext
    > = mutationOptions => {
        const mutationName = (query.definitions[0] as OperationDefinitionNode)?.name?.value;
        if (mutationName && mutationName in NovaMutationContexts) {
            return callback({
                ...mutationOptions,
                context: {
                    /* eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion -- (https://aka.ms/OWALintWiki)
                     * Baseline. DO NOT COPY AND PASTE!
                     *	> Optional chain expressions can return undefined by design - using a non-null assertion is unsafe and wrong. */
                    ...mutationOptions?.context!,
                    novaContext: NovaMutationContexts[mutationName](),
                },
            });
        }
        return callback(mutationOptions);
    };
    return [customCallback, !result.error];
}
