import getListViewStore from '../store/Store';
import { type TableQueryType } from '../store/schema/TableQuery';

/* When comparing an message's parentFolderId with a tableView's folder id,
a group needs to use its serverFolderId for comparison */
export default function getFolderIdForParentComparison(tableViewId: string): string {
    const tableView = getListViewStore().tableViews.get(tableViewId);

    switch (tableView?.tableQuery.type) {
        case 2:
            return tableView.serverFolderId;
        case 0:
            return tableView.tableQuery.folderId;
        default:
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (18,13): Type 'null' is not assignable to type 'string'.
            // @ts-expect-error
            return null;
    }
}
