import { type MailboxInfo } from 'owa-client-types';
import { type M365Acquisition } from 'owa-graph-schema';
import { M365AppId } from '../data/M365AppId';
import { isMChatFeatureEnabled } from 'owa-copilot-settings-utils';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export function filterBizChatAcquisitionEdges(
    m365Acquisitions: M365Acquisition[],
    mailboxInfo?: MailboxInfo
): M365Acquisition[] {
    // We filter MOS3 response only based on OCS response
    return m365Acquisitions.filter(
        edge =>
            edge.appId !== M365AppId.ImmersiveBizChat ||
            isMChatFeatureEnabled(mailboxInfo ?? getGlobalSettingsAccountMailboxInfo())
    );
}
