import mapConversationTypeToConversationItem from '../../store-factory/mapConversationTypeToConversationItem';
import mapConversationTypeToConversationRelation from '../../store-factory/mapConversationTypeToConversationRelation';
import type { ObservableMap } from 'mobx';
import type { ConversationType, ConversationFork } from 'owa-graph-schema';
import type { ConversationItem, TableView } from 'owa-mail-list-store';
import {
    isFirstLevelExpanded,
    getTableToRowRelationKey,
    getStore as getListViewStore,
    MailRowDataPropertyGetter,
    getListViewState,
} from 'owa-mail-list-store';
import shouldShowUnstackedReadingPane from 'owa-mail-store/lib/utils/shouldShowUnstackedReadingPane';
import type ItemId from 'owa-service/lib/contract/ItemId';
import { errorThatWillCauseAlert } from 'owa-trace';
import { mutatorAction } from 'satcheljs';
import mailStore from 'owa-mail-store/lib/store/Store';
import { isFeatureEnabled } from 'owa-feature-flags';

/**
 * Adds or updated the conversation in the tableView, tableViewConversationRelations
 * This is called whenever a data is fetched from the server and is being stored. This is also called when
 * a rowAdd or rowModified notification occurs
 * @param conversation to add or update
 * @param tableId where to add or update the conversation
 * @param doNotOverwriteData determines if updates should be written (default is false)
 * @param state for unit testing
 */
export const addOrUpdateConversationDataMutator = mutatorAction(
    'addOrUpdateConversationData',
    function addOrUpdateConversationData(
        conversation: ConversationType,
        tableView: TableView,
        doNotOverwriteData: boolean
    ) {
        const { conversationItems, tableViewConversationRelations } = getListViewStore();
        if (!conversation.ConversationId) {
            errorThatWillCauseAlert('addOrUpdateConversationData: ConversationId is undefined');
        }
        const conversationId = conversation.ConversationId.Id;
        const tableConversationRelationKey = getTableToRowRelationKey(
            conversation.InstanceKey ?? '',
            tableView.id
        );
        if (shouldModifyConversationItems(doNotOverwriteData, conversationId, conversationItems)) {
            const conversationItem = mapConversationTypeToConversationItem(conversation, tableView);
            conversationItems.set(conversationId, conversationItem);
        }

        const newRelation = mapConversationTypeToConversationRelation(conversation, tableView);
        if (
            shouldShowUnstackedReadingPane() &&
            isNewLocalMessage(conversation, tableView) &&
            conversation.ItemIds &&
            conversation.InstanceKey
        ) {
            const allItemIds: string[] = conversation.ItemIds?.map(itemId => (itemId as ItemId).Id);
            isFeatureEnabled('mon-change-forkIds')
                ? addNodeAsForkV2(conversation.InstanceKey, conversationId)
                : addNodeAsFork(conversation.InstanceKey, [...allItemIds]);
        }

        // Create or update the relation object
        const oldRelation = tableViewConversationRelations.get(tableConversationRelationKey);
        if (oldRelation) {
            // if we already have the relation then just update the values so we don't have to
            // make the whole object observable
            Object.assign(oldRelation, {
                ...newRelation,
                copilotInboxHeadline: oldRelation.copilotInboxHeadline ?? undefined,
            });
        } else {
            tableViewConversationRelations.set(tableConversationRelationKey, newRelation);
        }
    }
);

/**
 * Helper function to check if the conversationItems map should be modified.
 */
const shouldModifyConversationItems = (
    doNotOverwriteData: boolean,
    conversationId: string,
    conversationItems: ObservableMap<string, ConversationItem>
): boolean => {
    /**
     * If the conversation is new (an add) then we should add it to the
     * conversationItems map.
     */
    const cachedConversationItem = conversationItems.get(conversationId);
    if (!cachedConversationItem) {
        return true;
    }

    /**
     * If the conversation already exists in the store (an update), then respect
     * the parameter passed in by the caller.
     */
    if (doNotOverwriteData) {
        return false;
    }

    return true;
};

const isNewLocalMessage = (conversation: ConversationType, tableView: TableView): boolean => {
    const rowKey = conversation.InstanceKey;
    const selectedRowKeys = [...tableView.selectedRowKeys.keys()];
    if (selectedRowKeys.length == 1 && rowKey === selectedRowKeys[0]) {
        const itemIds = MailRowDataPropertyGetter.getItemIds(rowKey, tableView);
        if (itemIds && itemIds.length < (conversation.MessageCount ?? 0)) {
            return true;
        }
    }
    return false;
};

const addNodeAsFork = (rowKey: string, itemIds: string[]) => {
    const nodeId = itemIds.shift();
    if (isFirstLevelExpanded(rowKey) && nodeId) {
        const listViewState = getListViewState();

        const forks: ConversationFork[] | null = listViewState.expandedConversationViewState.forks;

        // If forks is null, then the conversation is collapsed and we don't need
        // to do anything.
        if (!forks) {
            return;
        }

        const forkIds = forks.map(fork => fork.id);
        if (forkIds.length <= 1) {
            // There is a single thread. We need to add the existing thread and the new incoming one as separate forks
            const selectedNodeId = itemIds[0];
            forks.unshift({
                id: selectedNodeId,
                displayNames: [], // unique senders on the conversation,
                ancestorIds: itemIds, // all nodes
            });
            // Also this should stay selected
            listViewState.expandedConversationViewState.selectedNodeIds.push(selectedNodeId);
        }
        // If the node doesn't already exist, add it to the forks
        // TODO: rationalize if node ids are in different formats EWS vs immutableId
        if (forkIds.indexOf(nodeId) == -1) {
            // Since it is a temporary fork, it is shown as a single message with no ancestors and a single sender
            forks.unshift({
                id: nodeId,
                displayNames: [], // MailListItemPart handles this as we get this from the item corresponding to the nodeId
                ancestorIds: [nodeId], // Since it is a temporary fork, it is shown as a single message
            });
        }
    }
};

/**
 * When a new message is received, if user is viewing the conversation, we need to add the new message temporarily as a fork
 * to avoid changing the current item shown on reading pane.
 */
const addNodeAsForkV2 = (rowKey: string, conversationId: string) => {
    const expandedConversationState = getListViewState().expandedConversationViewState;
    const forks = expandedConversationState?.forks;

    // If the conversation is not first level expanded, we are not viewing forks. Hence we don't need to do anything
    if (!isFirstLevelExpanded(rowKey) || !forks) {
        return;
    }

    const conversationItemParts = mailStore.conversations.get(conversationId);
    const conversationNodeIds = conversationItemParts?.conversationNodeIds;
    // There should be at least 2 nodes in the conversation to add a new node as a fork [current selected node and the new incoming node]
    if (conversationNodeIds && conversationNodeIds.length >= 2) {
        const isNewestOnBottom =
            conversationItemParts.conversationSortOrder == 'ChronologicalNewestOnBottom';
        const newNodeId = isNewestOnBottom
            ? conversationNodeIds[conversationNodeIds.length - 1]
            : conversationNodeIds[0];

        // If there is a single thread, we need to add the current thread and the new incoming node as separate forks
        // in the forks list.
        const forkNodeIds = forks.map(fork => fork.id).filter(forkNodeId => forkNodeId != null);
        if (forkNodeIds.length <= 1) {
            const currentSelectedNodeId = isNewestOnBottom
                ? conversationNodeIds[conversationNodeIds.length - 2]
                : conversationNodeIds[1];

            forks.unshift({
                id: currentSelectedNodeId,
                ancestorIds: conversationNodeIds, // all nodeIds in the conversation
            });
            // Also this should stay selected
            expandedConversationState.selectedNodeIds.push(currentSelectedNodeId);
        }

        // If the node doesn't already exist, add it to the forks
        if (forkNodeIds.indexOf(newNodeId) == -1) {
            // Since it is a temporary fork, it is shown as a single message with no ancestors
            forks.unshift({
                id: newNodeId,
                ancestorIds: [newNodeId], // Since it is a temporary fork, it is shown as a single message
            });
        }
        expandedConversationState.forks = forks;
    }
};
