import isReadingPaneConversationEnabled from '../selectors/isReadingPaneConversationEnabled';
import { onListViewTypeOptionSaved } from 'owa-options-actions/lib/onListViewTypeOptionSaved';
import { getUserConfiguration } from 'owa-session-store';
import { mutatorAction } from 'satcheljs';
import type { ReadingPaneConversationOptions } from 'owa-outlook-service-option-store';
import {
    lazyGetServerOptionsForFeature,
    lazyCreateOrUpdateOptionsForFeature,
} from 'owa-outlook-service-options';
import { getOptionsForFeature, OwsOptionsFeatureType } from 'owa-outlook-service-option-store';

export default async function saveReadingPaneConversationOption(
    conversationEnabledForRP?: boolean
) {
    // Get the server options from OWS'
    const getServerOptionsForFeature = await lazyGetServerOptionsForFeature.import();
    const serverOptions =
        (getServerOptionsForFeature &&
            (await getServerOptionsForFeature<ReadingPaneConversationOptions>(
                OwsOptionsFeatureType.ReadingPaneConversation
            ))) ||
        getOptionsForFeature<ReadingPaneConversationOptions>(
            OwsOptionsFeatureType.ReadingPaneConversation
        );

    // Pick the correct option based on the host app
    const owsConversationEnabledOption = serverOptions?.conversationEnabled;

    // If a value is passed in use that otherwise read from the store
    const conversationEnabled =
        conversationEnabledForRP != undefined
            ? conversationEnabledForRP
            : isReadingPaneConversationEnabled();

    // Create new options to push to server
    const mergedOptions: ReadingPaneConversationOptions = {
        ...serverOptions,
        conversationEnabled,
    };

    // Update on the local userOptions store
    updateOption(serverOptions, mergedOptions);

    // If the server options is different than the new option push to the server
    if (owsConversationEnabledOption != conversationEnabled) {
        // Simulate list view option change to reset the tableview
        const userOptions = getUserConfiguration().UserOptions;
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2532 (59,35): Object is possibly 'undefined'.
        // @ts-expect-error
        onListViewTypeOptionSaved(userOptions.GlobalListViewTypeReact);

        lazyCreateOrUpdateOptionsForFeature.importAndExecute(
            OwsOptionsFeatureType.ReadingPaneConversation,
            mergedOptions
        );
    }
}

const updateOption = mutatorAction(
    'updateReadingPaneConversationOption',
    (
        _readingPaneConversationOptions: ReadingPaneConversationOptions,
        newOptions: ReadingPaneConversationOptions
    ) => {
        _readingPaneConversationOptions = newOptions;
    }
);
