import { sanitize } from 'dompurify';
import type { Config } from 'dompurify';
import { owaTrustedTypesPolicy } from '../trustedTypesPolicies/owaTrustedTypesPolicy';
import { getApplicationSettings } from 'owa-application-settings';

/**
 * This list of safe protocols is used to prevent XSS attacks by only allowing certain protocols in href and src attributes.
 *
 * This list changes so infrequently (only once in few years) that it's safe to hardcode it, must match the list in
 * https://o365exchange.visualstudio.com/O365%20Core/_git/Substrate?path=/sources/dev/CTS/src/Server/TextConverters/Html/UrlSchemaChecker.cs
 *
 */
const defaultSafeProtocols = [
    'about',
    'blob',
    'callto',
    'cid',
    'codeflow',
    'conf',
    'file',
    'ftp',
    'gopher',
    'groove',
    'http',
    'https',
    'im',
    'ipp',
    'mailto',
    'meet',
    'mhtml',
    'mms',
    'msdaipp',
    'ms-excel',
    'ms-powerpoint',
    'ms-word',
    'ms-outlook',
    'news',
    'notes',
    'onenote',
    'sip',
    'tel',
    'wais',
];

function getTrustedHTML(htmlString: string, extendedConfig: Config = {}): string {
    const safeProtocols = [
        ...defaultSafeProtocols,
        ...getApplicationSettings('UrlValidationSettings').whiteListedSchemas,
    ];

    // Documentation https://www.npmjs.com/package/dompurify
    const defaultTrustedTypesConfig: Config = {
        USE_PROFILES: { html: true },
        ALLOWED_URI_REGEXP: new RegExp(
            `^(?:(?:${safeProtocols.join('|')}):|[^a-z]|[a-z+.\\-]+(?:[^a-z+.\\-:]|$))`,
            'i'
        ),
        ADD_ATTR: ['target', 'summary', 'name', 'content', 'originalsrc'],
    };
    defaultTrustedTypesConfig.USE_PROFILES = {
        ...defaultTrustedTypesConfig.USE_PROFILES,
        ...extendedConfig.USE_PROFILES,
    };
    extendedConfig = {
        ...extendedConfig,
        ...defaultTrustedTypesConfig,
    };

    return owaTrustedTypesPolicy
        ? (owaTrustedTypesPolicy.createHTML(htmlString, extendedConfig) as unknown as string)
        : sanitize(htmlString, { ...extendedConfig }).toString();
}

export { getTrustedHTML };
