import type { ConversationRowEdge, ConversationType } from 'owa-graph-schema';

import { mapOwsConversationTypeToGql } from '../mapOwsConversationTypeToGql';

export function getStartCursor(conversations?: any[]): string {
    if (conversations && conversations.length > 0) {
        return conversations[0].InstanceKey ?? '';
    }

    return '';
}

export function getEndCursor(conversations?: any[]): string {
    if (conversations && conversations.length > 0) {
        return conversations[conversations.length - 1].InstanceKey ?? '';
    }

    return '';
}

export function getEdges(
    conversations?: any[],
    folderId?: string,
    shouldMap: boolean = true
): ConversationRowEdge[] {
    if (!conversations || conversations.length == 0) {
        return [];
    }

    return conversations.map(conversation => {
        return {
            __typename: 'ConversationRowEdge',
            cursor: conversation.InstanceKey ?? '',
            node: shouldMap
                ? mapOwsConversationTypeToGql(conversation, folderId)
                : (conversation as ConversationType),
        };
    });
}
