import { isSameCoprincipalAccountMailboxInfos } from 'owa-client-types';
import type { MailboxInfo } from 'owa-client-types';
import { isCapabilitySupported, isCapabilityEnabled } from 'owa-capabilities';
import { crossMailboxMoveCopyItemsCapability } from 'owa-capabilities-definitions/lib/crossMailboxMoveCopyItemsCapability';

/**
 * If Move/Copy cross account is enabled supported
 */
export default function isMoveCopyCrossAccountSupported(
    sourceMailboxInfo: MailboxInfo,
    destinationMailboxInfo: MailboxInfo
) {
    // We check isCapabilityEnabled on the sourceMailboxInfo and isCapabilitySupported on the destinationMailboxInfo
    // Because isCapabilityEnabled calls -> isCapabilityEnabled && isCapabilitySupported,
    // so that the ItemsToOtherAccountsEnabled setting is checked only for sourceMailboxInfo
    return (
        isCapabilityEnabled(crossMailboxMoveCopyItemsCapability, sourceMailboxInfo) &&
        isCapabilitySupported(crossMailboxMoveCopyItemsCapability, destinationMailboxInfo) &&
        !isSameCoprincipalAccountMailboxInfos(sourceMailboxInfo, destinationMailboxInfo)
    );
}
