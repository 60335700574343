import { getStore } from '../store/store';
import { owaComputedFn } from 'owa-computed-fn';

/**
 * Determines whether the search folder creation modal UI should be open for a given account.
 */
export const isAnyModalOpen = owaComputedFn((): boolean => {
    const { createSearchFolderModalOpenState } = getStore();
    if (!createSearchFolderModalOpenState) {
        return false;
    }
    const openValues = [...createSearchFolderModalOpenState.values()];
    return openValues.some(isOpen => isOpen);
});
