import { mutator } from 'satcheljs';
import { setLoadingStateForFolderTree } from '../actions/setLoadingStateForFolderTree';
import getMailboxFolderTreeDataTable from '../selectors/getMailboxFolderTreeDataTable';

/**
 * Mutator that sets the loading state for folder tree.
 */
/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
export default mutator(setLoadingStateForFolderTree, actionMessage => {
    const { folderTreeId, mailboxInfo } = actionMessage;
    const folderTree = getMailboxFolderTreeDataTable(mailboxInfo)?.get(folderTreeId);
    if (folderTree) {
        folderTree.loadingState = actionMessage.loadingState;
    }
});
