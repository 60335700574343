import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';
import type {
    UnifiedGroupsSettingsState,
    UnifiedGroupsSettingsByMailboxInfo,
} from './schema/UnifiedGroupsSettingsState';

const store = createStore<UnifiedGroupsSettingsByMailboxInfo>('unifiedGroupsSettingsStore', {
    unifiedGroupsSettingsByMailboxInfo: new ObservableMap<string, UnifiedGroupsSettingsState>(),
})();

export const getUnifiedGroupsSettingsStore = () => store;
