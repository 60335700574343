import { otherViewFilterText } from 'owa-locstrings/lib/strings/otherviewfiltertext.locstring.json';
import { focusedViewFilterText } from 'owa-locstrings/lib/strings/focusedviewfiltertext.locstring.json';
import { searchFolderName } from 'owa-locstrings/lib/strings/searchfoldername.locstring.json';
import loc from 'owa-localize';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import type TableView from '../store/schema/TableView';
import { type TableQueryType } from '../store/schema/TableQuery';
import getFocusedFilterForTable from '../utils/getFocusedFilterForTable';
import getUserCategoryName from '../selectors/getUserCategoryName';

/**
 * Get the folder scope text
 * @param focusedViewFilter the focused view filter
 * @param tableQueryType the table query type (mail, group, search)
 * @param folderDisplayName display name of the folder on which the action is taken
 * @returns the folder scope text, e.g Focused Inbox, Other Inbox, Sent Items, etc.
 */
export default function getFolderScopeText(
    tableQueryType: TableQueryType,
    tableView: TableView | undefined,
    folderDisplayName: string
): string {
    if (tableQueryType === 1) {
        return loc(searchFolderName);
    }

    if (tableView) {
        if (getUserCategoryName(tableView)) {
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (29,13): Type 'string | null' is not assignable to type 'string'.
            // @ts-expect-error
            return getUserCategoryName(tableView);
        }

        const focusedViewFilter = getFocusedFilterForTable(tableView);
        switch (focusedViewFilter) {
            case 0:
                return loc(focusedViewFilterText);
            case 1:
                return loc(otherViewFilterText);
        }
    }
    return folderDisplayName;
}
