export enum OcpsPolicy {
    Contact,
    Content,
    Feedback,
    NpsSurvey,
    OutlookRecommendation,
    ScreenShot,
    ContactSupport,
    GetDiagnostics,
    DiagnosticsTroubleshooting,
    OptionalConnectedExperiences,
    ConnectedOfficeExperience,
    ExperiencesAnalyzingContent,
    ExperiencesDownloadingContent,
    Loop,
    LoopEnabledForOutlook,
    DisableSharingPermissions,
    EmailCollectionDefault,
    ScreenShotDefault,
    ContentSamplesDefault,
    M365LinksHandlingBrowserChoice,
    LoopPolls,
    ShowCardLoops,
    LoopPlanner,
    LoopVideoPlayback,
    LoopVideoRecord,
}
