import type {
    AccountState,
    Contracts,
    CoprincipalAccountSource,
    CoprincipalAccountExternalData,
    M365AccountSource,
    M365ArchiveMailboxAccountSource,
    M365ConnectedMailboxAccountSource,
    M365GroupMailboxAccountSource,
    M365PublicFolderMailboxAccountSource,
    M365SharedMailboxAccountSource,
    M365TeamsMailboxAccountSource,
    M365UserMailboxAccountSource,
} from 'owa-account-source-list-store';
import {
    AccountDataType,
    BootState,
    ExternalDataType,
    AccountSourceMailboxType,
} from 'owa-account-source-list-store';
import type { CoprincipalAccount } from 'owa-account-storage-types';
import { AccountSourceType, type AdditionalMailboxInfoProps } from 'owa-account-source-list-types';
import {
    CoprincipalMailboxRank,
    type MailboxType,
    ResourceMailboxRank,
    type MailboxRank,
    type MailboxInfo,
} from 'owa-client-types';
import { buildCoprincipalSourceId, buildResourceSourceId } from './sourceIdBuilder';
import {
    resourceTypeArchive,
    resourceTypeGroup,
    resourceTypePublicFolder,
    resourceTypeShared,
    resourceTypeTeams,
} from 'owa-account-source-list-types/lib/utils/sourceIdResourceTypes';
import { buildMailboxContracts } from './buildMailboxContracts';
import { makeMailboxInfo, makeMailboxInfoFromCoprincipalAccount } from './makeMailboxInfo';
import { buildEmptyAdditionalAccountInfo } from './buildEmptyAdditionalAccountInfo';
import { logGreyErrorFromAccounts } from 'owa-account-analytics';
import providerToSource from 'owa-account-source-list-types/lib/utils/providerToSource';
import sourceToProvider from 'owa-account-source-list-types/lib/utils/sourceToProvider';

export function buildOnlineArchiveMailboxSmtp(mailboxGuid: string, orgDomain: string): string {
    return `${mailboxGuid}@${orgDomain}`;
}

// Helper to specify the required properties of a partial type
type RequiredOnPartial<T, RequiredKeys extends keyof T> = Required<Pick<T, RequiredKeys>> &
    Partial<Omit<T, RequiredKeys>>;

/**
 * Builds an M365AccountSource based on partial data passed in
 * @param buildParams Contains the partial data to build
 * @returns
 */
function buildM365AccountSourceFromPartial(
    buildParams: RequiredOnPartial<
        M365AccountSource,
        | 'sourceId'
        | 'sourceType'
        | 'displayName'
        | 'contracts'
        | 'accountSourceMailboxType'
        | 'mailboxInfo' // required params
    >
): M365AccountSource {
    return {
        // by default we assume we are creating a ResourceMailbox
        dataType: AccountDataType.M365Mailbox,
        mailboxRank: ResourceMailboxRank,
        externalData: { type: ExternalDataType.None },
        ...buildParams,
    };
}

/**
 * Builds an M365AccountSource, generating a sourceId for the resource
 */
function buildM365AccountSourceForResource(
    accountSourceMailboxType: AccountSourceMailboxType,
    displayName: string,
    smtpAddress: string,
    resourceType: string,
    accountMailboxInfo: MailboxInfo,
    contractsSupportedByType: Partial<Contracts>,
    coprincipalPersistenceId: string,
    mailboxInfoProps: AdditionalMailboxInfoProps
): M365AccountSource {
    const mailboxRank: MailboxRank = ResourceMailboxRank;
    const sourceId = buildResourceSourceId(coprincipalPersistenceId, resourceType, smtpAddress);
    const contracts = buildMailboxContracts(AccountSourceType.Other, {
        ...contractsSupportedByType,
    });

    if (!mailboxInfoProps.tenantId && !!accountMailboxInfo.tenantId) {
        // resource mailboxes inherit the tenantId from the account
        mailboxInfoProps = { ...mailboxInfoProps, tenantId: accountMailboxInfo.tenantId };
    }

    const mailboxProvider = accountMailboxInfo.mailboxProvider ?? 'Office365';

    const mailboxInfo = makeMailboxInfo(
        sourceId,
        accountSourceMailboxType,
        smtpAddress,
        accountMailboxInfo.userIdentity,
        accountMailboxInfo.localAuthId,
        mailboxRank,
        mailboxProvider,
        mailboxInfoProps
    );

    const sourceType = providerToSource(mailboxProvider);
    return buildM365AccountSourceFromPartial({
        sourceId,
        accountSourceMailboxType,
        displayName,
        contracts,
        mailboxInfo,
        sourceType,
    });
}

/**
 * Builds a M365UserMailboxAccountSource
 */
export function buildM365UserAccountSource(
    sourceId: string,
    displayName: string,
    email: string,
    userIdentity: string,
    persistenceId: string,
    sourceType: AccountSourceType,
    localAuthId?: string,
    aliases?: string[]
): M365UserMailboxAccountSource {
    const contracts = buildMailboxContracts(sourceType);

    const accountSourceMailboxType = AccountSourceMailboxType.User;
    const mailboxInfo = makeMailboxInfo(
        sourceId,
        accountSourceMailboxType,
        email,
        userIdentity,
        localAuthId,
        CoprincipalMailboxRank,
        sourceToProvider(sourceType),
        {}
    );

    const m365AccountSource = buildM365AccountSourceFromPartial({
        sourceId,
        accountSourceMailboxType,
        displayName,
        contracts,
        mailboxInfo,
        sourceType,
    });

    return {
        ...m365AccountSource,
        bootState: BootState.Booting,
        mailboxRank: CoprincipalMailboxRank,
        accountSourceMailboxType,
        groupSources: buildEmptyAdditionalAccountInfo(sourceId),
        sharedSources: buildEmptyAdditionalAccountInfo(sourceId),
        archiveSources: buildEmptyAdditionalAccountInfo(sourceId),
        publicFolderSources: buildEmptyAdditionalAccountInfo(sourceId),
        teamsSources: buildEmptyAdditionalAccountInfo(sourceId),
        persistenceId,
        isAnotherMailbox: false,
        aliases: aliases ?? [],
    };
}

/**
 * Builds an account source that can be used as a place holder for a pending account, where a pending
 * account is one that we have information for but has not yet been added to the account source list
 * @param emailAddress SMTP address of the pending account
 * @param sourceType Type of the pending account
 */
export function buildPendingAccountSource(
    emailAddress: string,
    userIdentity: string,
    sourceType: AccountSourceType
): M365UserMailboxAccountSource {
    return buildM365UserAccountSource(
        '', // Empty source id
        emailAddress,
        emailAddress,
        userIdentity,
        '', // empty persistence id
        sourceType
    );
}

/**
 * Builds a CoprincipalAccountSource from a CoprincipalAccount
 * @param account Specifies the CoprincipalAccount data
 * @returns The CoprincipalAccountSource data source
 */
export function buildCoprincipalAccountSourceFromCoprincipalAccount(
    account: CoprincipalAccount
): CoprincipalAccountSource {
    const externalData: CoprincipalAccountExternalData = {
        type: ExternalDataType.CoprincipalAccount,
        account,
    };

    switch (account.accountType) {
        case AccountSourceType.Other:
            // These were previously added as Outlook.Com accounts, keep this behavior for now but if there
            // are no instances of this grey error we will remove it from code
            const error = new Error('Accounts are not expeected to have Other type');
            logGreyErrorFromAccounts('AccountWithOtherType', error);

            // Override the data passed in but not the external data as this can be used in the diagnostic
            // data to tell that the account was created from an account with Other type
            const accountAsOutlookDotCom = {
                ...account,
                accountType: AccountSourceType.OutlookDotCom,
            };

            return buildM365CoprincipalAccountSourceFromCoprincipalAccount(
                accountAsOutlookDotCom,
                externalData
            );

        case AccountSourceType.PstFile:
            return buildPstFileAccountSourceFromCoprincipalAccount(account, externalData);

        default:
            return buildM365CoprincipalAccountSourceFromCoprincipalAccount(account, externalData);
    }
}

/**
 * Builds a CoprincipalAccountSource from a CoprincipalAccount
 * @param account Specifies the CoprincipalAccount data
 * @param externalData Specifies the data of the account that represents how it is stored
 * @returns The CoprincipalAccountSource data source
 */
function buildPstFileAccountSourceFromCoprincipalAccount(
    account: CoprincipalAccount,
    externalData: CoprincipalAccountExternalData
): CoprincipalAccountSource {
    const sourceId = buildCoprincipalSourceId(account.uuid);
    const mailboxInfo = {
        sourceId,
        type: 'PstFile' as MailboxType,
        userIdentity: account.userIdentity,
        mailboxSmtpAddress: account.emailAddress,
        mailboxRank: CoprincipalMailboxRank,
        diagnosticData: 'ASLSMPstFile',
        auxiliaryMailboxGuid: undefined,
    };

    return {
        // AccountSource
        sourceId,
        sourceType: AccountSourceType.PstFile,
        dataType: AccountDataType.PstFile,
        mailboxRank: CoprincipalMailboxRank,
        displayName: account.emailAddress,
        accountSourceMailboxType: AccountSourceMailboxType.PstFile,
        contracts: buildMailboxContracts(AccountSourceType.PstFile),
        externalData,
        mailboxInfo,
        // CoprincipalAccountSource
        bootState: BootState.Booting,
        persistenceId: account.uuid,
        isAnotherMailbox: false,
        aliases: [],
    };
}

/**
 * Builds a CoprincipalAccountSource from a CoprincipalAccount
 * @param account Specifies the CoprincipalAccount data
 * @param externalData Specifies the data of the account that represents how it is stored
 * @returns The CoprincipalAccountSource data source
 */
function buildM365CoprincipalAccountSourceFromCoprincipalAccount(
    account: CoprincipalAccount,
    externalData: CoprincipalAccountExternalData
): M365UserMailboxAccountSource {
    const accountSourceMailboxType = AccountSourceMailboxType.User;
    const sourceId = buildCoprincipalSourceId(account.uuid);
    const contracts = buildMailboxContracts(account.accountType);
    const mailboxInfo = makeMailboxInfoFromCoprincipalAccount(account);

    const m365AccountSource = buildM365AccountSourceFromPartial({
        sourceId,
        displayName: account.emailAddress,
        contracts,
        accountSourceMailboxType,
        mailboxInfo,
        sourceType: account.accountType,
        externalData,
    });

    const persistenceId = account.uuid;
    const aliases = account.aliases ?? [];

    // If the user principal name (or UPN) used to authenticate the user is different
    // from the email addresses of mailbox then this mailbox is shared with the user
    const isAnotherMailbox = !!account.isAnotherMailbox;
    return {
        ...m365AccountSource,
        bootState: BootState.Booting,
        mailboxRank: CoprincipalMailboxRank,
        persistenceId,
        accountSourceMailboxType,
        aliases,
        isAnotherMailbox,
        groupSources: buildEmptyAdditionalAccountInfo(sourceId),
        sharedSources: buildEmptyAdditionalAccountInfo(sourceId),
        archiveSources: buildEmptyAdditionalAccountInfo(sourceId),
        publicFolderSources: buildEmptyAdditionalAccountInfo(sourceId),
        teamsSources: buildEmptyAdditionalAccountInfo(sourceId),
    };
}

/**
 * create a new M365GroupMailboxAccountSource
 * @param accountMailboxInfo The MailboxInfo of account in which the group is located
 * @param displayName The display name of the group
 * @param smtpAddress The SMTP address of the group
 * @param coprincipalPersistenceId The persistence id of the coprincipal account
 * @param mailboxInfoProps Additional mailbox info properties
 * @returns M365GroupMailboxAccountSource which represents the group
 */
export function buildM365GroupMailboxAccountSource(
    accountMailboxInfo: MailboxInfo,
    displayName: string,
    smtpAddress: string,
    coprincipalPersistenceId: string,
    mailboxInfoProps: AdditionalMailboxInfoProps
): M365GroupMailboxAccountSource {
    const accountSourceMailboxType = AccountSourceMailboxType.Group;
    const m365account = buildM365AccountSourceForResource(
        accountSourceMailboxType,
        displayName,
        smtpAddress,
        resourceTypeGroup,
        accountMailboxInfo,
        {
            isMicrosoft365Hosted: true,
            supportsMail: true,
            supportsCalendar: true,
        },
        coprincipalPersistenceId,
        mailboxInfoProps
    );

    return {
        ...m365account,
        accountSourceMailboxType,
    };
}

/**
 * Creates an M365SharedMailboxAccountSource
 * @param displayName The display name of the shared mailbox
 * @param smtpAddress The SMTP address of the shared mailbox
 * @param accountMailboxInfo the mailbox info of the coprincipal account that hosts this shared mailbox
 * @param isAutomapped Whether the shared mailbox is automapped
 * @param coprincipalPersistenceId The persistence id of the coprincipal account
 * @param mailboxInfoProps Additional mailbox info properties
 * @returns M365SharedMailboxAccountSource
 */
export function buildM365SharedMailboxAccountSource(
    displayName: string,
    smtpAddress: string,
    accountMailboxInfo: MailboxInfo,
    isAutomapped: boolean,
    coprincipalPersistenceId: string,
    mailboxInfoProps: AdditionalMailboxInfoProps
): M365SharedMailboxAccountSource {
    const accountSourceMailboxType = AccountSourceMailboxType.Shared;
    const m365account = buildM365AccountSourceForResource(
        accountSourceMailboxType,
        displayName,
        smtpAddress,
        resourceTypeShared,
        accountMailboxInfo,
        {
            isMicrosoft365Hosted: true,
            supportsMail: true,
            supportsCalendar: true,
        },
        coprincipalPersistenceId,
        mailboxInfoProps
    );

    return {
        ...m365account,
        accountSourceMailboxType,
        isAutomapped,
    };
}

/**
 * Creates an M365ArchiveMailboxAccountSource
 * @param displayName the display name of the archive mailbox
 * @param smtpAddress the SMTP address of the archive mailbox
 * @param accountMailboxInfo the mailbox info of the coprincipal account that hosts this archive
 * @param coprincipalPersistenceId the persistence id of the coprincipal account
 * @param mailboxInfoProps Additional mailbox info properties
 * @returns M365ArchiveMailboxAccountSource
 */
export function buildM365ArchiveMailboxAccountSource(
    displayName: string,
    smtpAddress: string,
    accountMailboxInfo: MailboxInfo,
    coprincipalPersistenceId: string,
    mailboxInfoProps: AdditionalMailboxInfoProps
): M365ArchiveMailboxAccountSource {
    const accountSourceMailboxType = AccountSourceMailboxType.Archive;
    const m365account = buildM365AccountSourceForResource(
        accountSourceMailboxType,
        displayName,
        smtpAddress,
        resourceTypeArchive,
        accountMailboxInfo,
        {
            isMicrosoft365Hosted: true,
            supportsMail: true,
        },
        coprincipalPersistenceId,
        mailboxInfoProps
    );
    return {
        ...m365account,
        accountSourceMailboxType,
    };
}

/**
 * Creates an M365PublicFolderMailboxAccountSource
 * @param displayName The display name of the public folder mailbox
 * @param smtpAddress The smtp address of the public folder mailbox
 * @param accountMailboxInfo the mailbox info of the coprincipal account that hosts this archive
 * @param coprincipalPersistenceId the persistence id of the coprincipal account
 * @param mailboxInfoProps Additional mailbox info properties
 * @returns M365PublicFolderMailboxAccountSource
 */
export function buildM365PublicFolderMailboxAccountSource(
    displayName: string,
    smtpAddress: string,
    accountMailboxInfo: MailboxInfo,
    coprincipalPersistenceId: string,
    mailboxInfoProps: AdditionalMailboxInfoProps
): M365PublicFolderMailboxAccountSource {
    const accountSourceMailboxType = AccountSourceMailboxType.PublicFolder;
    const m365account = buildM365AccountSourceForResource(
        accountSourceMailboxType,
        displayName,
        smtpAddress,
        resourceTypePublicFolder,
        accountMailboxInfo,
        {
            isMicrosoft365Hosted: true,
            supportsMail: true,
            supportsCalendar: true,
        },
        coprincipalPersistenceId,
        mailboxInfoProps
    );
    return {
        ...m365account,
        accountSourceMailboxType,
    };
}

/**
 * Creates an M365M365TeamsMailboxAccountSource
 * @param displayName Display name for the teams Mailbox
 * @param smtpAddress email address for the teams Mailbox
 * @param accountMailboxInfo mailbox info for the coprincipal account that hosts this teams mailbox
 * @param coprincipalPersistenceId persistence id of the coprincipal account
 * @param mailboxInfoProps Additional mailbox info properties
 * @returns M365TeamsMailboxAccountSource
 */
export function buildM365TeamsMailboxAccountSource(
    displayName: string,
    smtpAddress: string,
    accountMailboxInfo: MailboxInfo,
    coprincipalPersistenceId: string,
    mailboxInfoProps: AdditionalMailboxInfoProps
): M365TeamsMailboxAccountSource {
    const accountSourceMailboxType = AccountSourceMailboxType.Teams;
    const m365account = buildM365AccountSourceForResource(
        accountSourceMailboxType,
        displayName,
        smtpAddress,
        resourceTypeTeams,
        accountMailboxInfo,
        {
            isMicrosoft365Hosted: true,
            supportsCalendar: true,
            supportsContacts: true,
        },
        coprincipalPersistenceId,
        mailboxInfoProps
    );
    return {
        ...m365account,
        accountSourceMailboxType,
    };
}

export function buildM365ConnectedMailboxAccountSourceFromCoprincipalAccount(
    account: CoprincipalAccount,
    anchorMailbox: string,
    accountState: AccountState
): M365ConnectedMailboxAccountSource {
    // In OWA connected oaccounts only support calendar, we use Other to get the nothing
    // supported options and then set that we support calendar
    const contracts = buildMailboxContracts(AccountSourceType.Other, {
        isMicrosoft365Hosted: true,
        supportsCalendar: true,
    });

    const accountSourceMailboxType = AccountSourceMailboxType.Connected;
    const mailboxInfo = makeMailboxInfoFromCoprincipalAccount(account);
    const externalData: CoprincipalAccountExternalData = {
        type: ExternalDataType.CoprincipalAccount,
        account,
    };

    const sourceId = buildCoprincipalSourceId(account.uuid);

    const m365AccountSource = buildM365AccountSourceFromPartial({
        sourceId,
        displayName: account.emailAddress,
        contracts,
        accountSourceMailboxType,
        mailboxInfo,
        sourceType: account.accountType,
        externalData,
    });

    return {
        ...m365AccountSource,
        bootState: BootState.Booting,
        mailboxRank: CoprincipalMailboxRank,
        accountState,
        accountSourceMailboxType: AccountSourceMailboxType.Connected,
        persistenceId: account.uuid,
        isAnotherMailbox: false,
        aliases: account.aliases ?? [],
        anchorMailbox,
    };
}
