import type { MailboxInfo } from 'owa-client-types';
import fetchCopilotSettingsFromService from '../services/fetchCopilotSettingsFromService';
import onCopilotSettingsSaved from '../mutators/onCopilotSettingsSaved';
import isCopilotPrioritizeSettingsInitialized from '../selectors/isCopilotPrioritizeSettingsInitialized';
import { isCopilotFeatureEnabled } from 'owa-copilot-settings-utils';
import { isFeatureEnabled } from 'owa-feature-flags';
import isCopilotStyleSettingsInitialized from '../selectors/isCopilotStyleSettingsInitialized';

export default async function fetchCopilotSettings(
    mailboxInfo: MailboxInfo,
    forceRefresh: boolean = false
): Promise<boolean> {
    const isCustomStyleEnabled = isFeatureEnabled('mon-copilot-elaborate-customStyle', mailboxInfo);

    if (
        !isCopilotFeatureEnabled('Inbox', mailboxInfo, true /* skipLanguageCheck */) &&
        !isCustomStyleEnabled
    ) {
        return Promise.resolve(true);
    }

    if (isCopilotPrioritizeSettingsInitialized(mailboxInfo) && !forceRefresh) {
        return Promise.resolve(true);
    }
    if (isCustomStyleEnabled && isCopilotStyleSettingsInitialized(mailboxInfo) && !forceRefresh) {
        return Promise.resolve(true);
    }

    const response = await fetchCopilotSettingsFromService(mailboxInfo);
    if (response) {
        onCopilotSettingsSaved(mailboxInfo, response, isCustomStyleEnabled);
    }

    return Promise.resolve(true);
}
