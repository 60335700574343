import { M365AcquisitionsDocument } from 'owa-m365-acquisitions/lib/graphql/query/__generated__/M365Acquisitions.interface';
import { getBootM365AcquisitionsQuery } from 'owa-m365-acquisitions/lib/getBootM365Acquisitions';
import { errorThatWillCauseAlert } from 'owa-trace';
import { writeQuery } from 'owa-apollo';
import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';
import type { MailboxInfo } from 'owa-client-types';
import { isCapabilityEnabled } from 'owa-capabilities';
import { m365AcquisitionsCapability } from 'owa-capabilities-definitions/lib/m365AcquisitionsCapability';
import { updateServiceConfig } from 'owa-service/lib/config';
import { setLocalPinnedApps } from 'owa-m365-acquisitions/lib/pinnedApps/getAppBarPinnedAppIdsFromUserSettings';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';

export async function bootstrapM365AcquisitionsCache(
    mailboxInfo?: MailboxInfo,
    useColorfulIcons?: boolean
) {
    if (!isCapabilityEnabled(m365AcquisitionsCapability, mailboxInfo)) {
        // this mailbox does not support M365 Acquisitions
        return;
    }

    updateServiceConfig({
        onPinnedAppsChanged: (mboxInfo, newSetting: string) => {
            setLocalPinnedApps(mboxInfo, newSetting);
        },
        getModuleContextMailboxInfo: () => {
            return Promise.resolve(getModuleContextMailboxInfo());
        },
    });
    const dp = new PerformanceDatapoint('M365AcquisitionsCachePriming');
    try {
        const m365Acquisitions = await getBootM365AcquisitionsQuery(mailboxInfo, useColorfulIcons);
        writeQuery(M365AcquisitionsDocument, {
            data: m365Acquisitions,
            variables: {
                mailboxInfo,
            },
        });
        dp.end();
    } catch (e) {
        errorThatWillCauseAlert('M365Acquisitions_CachePriming_Error', e);
        dp.endWithError(DatapointStatus.ClientError, e);
    }
}
