import { setAltGetUserMailboxInfo } from 'owa-client-ids';
import { type AltGetSourceIdFunc, makeAltGetUserMailboxInfo } from './altGetUserMailboxInfo';
import { initializeTryLookupIndexer } from './initializeTryLookupIndexer';
import { initializeTryLookupIdentityInfo } from './initializeTryLookupIdentityInfo';

/**
 * Sets the lookup helpers that are set before the accounts are loaded, these lookup helpers do
 * not rely on the account source list store being initialized before they can be called.
 */
export function setPreAccountLoadLookupHelpers() {
    initializeTryLookupIndexer();
    initializeTryLookupIdentityInfo();
}

/**
 * Sets the lookup helpers that rely on the account source list store being initialized before
 * they be called (commonly they call getGlobalSettingsAccount).
 * @param altGetSourceIdFunc Optionally, specifies the alternate function to get the source ID. This
 * is used from the main thread to try and lookup th euser identity from the connected accounts.
 */
export function setPostAccountLoadLookupHelpers(altGetSourceIdFunc?: AltGetSourceIdFunc) {
    const altGetUserMailboxInfo = makeAltGetUserMailboxInfo(altGetSourceIdFunc);
    setAltGetUserMailboxInfo(altGetUserMailboxInfo);
}

/**
 * Some scenarios, such as OOBE or the data worker, do not have the normal pre/post account load
 * of the main thread. This function sets all the lookup helpers in these scenarios.
 */
export function setPreAndPostAccountLoadLookupHelpers() {
    setPreAccountLoadLookupHelpers();
    setPostAccountLoadLookupHelpers();
}
