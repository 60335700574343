import initializeAttachmentsForItem from './initializeAttachmentsForItem';
import type ItemViewState from '../store/schema/ItemViewState';
import mailStore from 'owa-mail-store/lib/store/Store';
import { lazyInitializeMeetingRequestViewState } from 'owa-meeting-message';
import { isMeetingMessage } from 'owa-meeting-message-utils';
import type { ClientItem } from 'owa-mail-store';

export default async function initializeExtendedStateForItemViewState(
    itemViewState: ItemViewState,
    reinitializeAttachments: boolean = false,
    itemParam?: ClientItem
) {
    const item = itemParam ?? mailStore.items.get(itemViewState.itemId);
    await initializeAttachmentsForItem(itemViewState, item, reinitializeAttachments);
    if (isMeetingMessage(item)) {
        lazyInitializeMeetingRequestViewState.importAndExecute(itemViewState);
    }
}
