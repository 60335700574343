import type MailboxJunkEmailConfigurationOptions from 'owa-service/lib/contract/MailboxJunkEmailConfigurationOptions';
import setMailboxJunkEmailConfigurationOperation from 'owa-service/lib/operation/setMailboxJunkEmailConfigurationOperation';
import { getJsonRequestHeader } from 'owa-service/lib/ServiceRequestUtils';
import type { MailboxInfo } from 'owa-client-types';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';

export default function saveMailboxJunkEmailConfiguration(
    options: MailboxJunkEmailConfigurationOptions,
    mailboxInfo: MailboxInfo
): Promise<MailboxJunkEmailConfigurationOptions> {
    const requestOptions = getMailboxRequestOptions(mailboxInfo);
    return setMailboxJunkEmailConfigurationOperation(
        {
            Header: getJsonRequestHeader(),
            Options: options,
        },
        requestOptions
    );
}
