import { action } from 'satcheljs';
import type { OwaNovaActionUnion } from '../types';

export const onPinMessageExtensionTrigger = action('PinMessageExtensionTrigger', event => ({
    owaType: 'pinMessageExtension',
    event: {
        ...event,
        data: () => {
            const serializedData = event.data?.();

            try {
                return JSON.parse(serializedData as unknown as string);
            } catch (e) {
                return serializedData;
            }
        },
    },
})) as OwaNovaActionUnion<'pinMessageExtension'>;
