import { copilotSettingsStore, LicenseType } from 'owa-copilot-settings-store';
import type { MailboxInfo } from 'owa-client-types';

const hasBizChatLicense = (mailboxInfo: MailboxInfo): boolean => {
    const store = copilotSettingsStore(mailboxInfo);
    if (store.isEnabled) {
        return store.licenses.some(license => license === LicenseType.BizChat);
    }

    return false;
};

export default hasBizChatLicense;
