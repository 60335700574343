import type { MailboxInfo } from 'owa-client-types';
import { type ImplementedCopilotFeatures } from 'owa-copilot-settings-store';
import isCopilotFeatureEnabled from './isCopilotFeatureEnabled';
import { getApplicationSettings } from 'owa-application-settings';

const composeCopilotFeatureNames = [
    'Elaborate',
    'Coach',
] as const satisfies readonly ImplementedCopilotFeatures[];
type ComposeFeatures = typeof composeCopilotFeatureNames[number];

export type CopilotcomposeFeatureNameWithAny = ComposeFeatures | 'Any';

/**
 * @param mailboxInfo Mailbox info
 * @param featureName feature name to check if enabled
 * @param skipLanguageCheck  whether to skip language check. Default is true.
 * @returns true if the feature is enabled, false if the feature is disabled, undefined if the feature is in progress or not started
 */
export default function isComposeCopilotEnabled(
    mailboxInfo: MailboxInfo,
    featureName: CopilotcomposeFeatureNameWithAny,
    skipLanguageCheck?: boolean
): boolean | undefined {
    const enableMultiLanguage =
        skipLanguageCheck ?? getApplicationSettings('Copilot', mailboxInfo).enableMultiLanguage;
    if (featureName == 'Any') {
        if (
            composeCopilotFeatureNames.some(
                name =>
                    isCopilotFeatureEnabled(name, mailboxInfo, enableMultiLanguage) === undefined
            )
        ) {
            return undefined; //Need a separate undefined state when CopilotSettings service call is in progress or not started so that perf optimizations can be made in the calling code
        } else {
            return composeCopilotFeatureNames.some(name =>
                isCopilotFeatureEnabled(name, mailboxInfo, enableMultiLanguage)
            );
        }
    } else {
        return isCopilotFeatureEnabled(featureName, mailboxInfo, enableMultiLanguage);
    }
}
