import getResourcesForTheme from './getResourcesForTheme';
import { getOwaResourceImageUrl } from 'owa-resource-url';
import type { HeaderImageData } from 'owa-theme-shared';
import { getThemeIdFromParameter } from '../utils/optionalArgumentHelpers';

export function getHeaderImageDataFull(themeId?: string, isDarkTheme?: boolean): HeaderImageData {
    themeId = getThemeIdFromParameter(themeId);

    const {
        primary = undefined,
        staticBg = undefined,
        repeat = true,
    } = getResourcesForTheme(themeId, isDarkTheme).headerImages || {};

    return {
        primary: resolveImageUrl(primary),
        staticBg: resolveImageUrl(staticBg),
        repeat,
    };

    function resolveImageUrl(url?: string) {
        return url && getOwaResourceImageUrl(`themes/${themeId}/${url}`);
    }
}
