import { observer } from 'owa-mobx-react';
import React from 'react';
import type { AppPaneUnderlayViewState } from '../store/store';
import isAppPaneUnderlayExpanded from '../utils/isAppPaneUnderlayExpanded';
import isAppPaneUnderlayPlaceholder from '../utils/isAppPaneUnderlayPlaceholder';
import isAppPaneUnderlayShrinkable from '../utils/isAppPaneUnderlayShrinkable';

import { underlay as styles_underlay, shrinkable, placeholder } from './AppPaneUnderlay.scss';

import classnames from 'owa-classnames';

const AppPaneUnderlay = observer(function AppPaneUnderlay({
    underlay,
}: {
    underlay: AppPaneUnderlayViewState | undefined;
}) {
    return isAppPaneUnderlayExpanded(underlay) ? (
        <div
            className={classnames(styles_underlay, {
                [shrinkable]: isAppPaneUnderlayShrinkable(underlay),
                [placeholder]: isAppPaneUnderlayPlaceholder(underlay),
            })}
        />
    ) : null;
},
'AppPaneUnderlay');

export default AppPaneUnderlay;
