import { isCurrentCultureRightToLeft } from './isCurrentCultureRightToLeft';
/**
 * This function is used to check whether the given language is an
 * RTL language or not.
 */

export function isLanguageRightToLeft(language?: string): boolean {
    const rtlLanguages = ['Hebrew', 'Arabic', 'Persian', 'Urdu', 'he', 'ar', 'fa', 'ur'];
    return !isCurrentCultureRightToLeft() && rtlLanguages.some(lang => lang === language);
}
