import type TimeZoneRangeType from 'owa-service/lib/contract/TimeZoneRangeType';
import type { TimeZoneRange } from '../TimeZoneRange';
import {
    MIN_JAVASCRIPT_TIMESTAMP,
    MAX_JAVASCRIPT_TIMESTAMP,
    MILLISECONDS_IN_MINUTE,
} from 'owa-date-constants';

const MIN_CURRENT_PERIOD_INDEX = 4;

export function timeZoneRanges(
    timeZones: Required<Pick<TimeZoneRangeType, 'UtcTime' | 'Offset'>>[],
    optimizeOrder: boolean = false
): TimeZoneRange[] {
    const ranges = timeZones.map(({ UtcTime, Offset }) => {
        const start = Date.parse(UtcTime);
        return {
            start,
            localStart: start + Offset * MILLISECONDS_IN_MINUTE,
            end: MAX_JAVASCRIPT_TIMESTAMP,
            localEnd: MAX_JAVASCRIPT_TIMESTAMP,
            offset: Offset,
        };
    });

    const Today = Date.now();
    let currentPeriodIndex = -1;

    // Each range (except the last) ends where the next starts.
    // The localEnd needs to account for the current range having larger
    // offset than the next one, so we pick up the correct offset.
    for (var i = 0; i < ranges.length - 1; i++) {
        const range = ranges[i];
        const nextRange = ranges[i + 1];
        range.end = nextRange.start;
        range.localEnd =
            range.end + Math.max(range.offset, nextRange.offset) * MILLISECONDS_IN_MINUTE;

        if (Today >= range.start && Today < range.end) {
            currentPeriodIndex = i;
        }
    }

    // Extend the first range to beginning of time.
    // NOTE: According to http://ecma-international.org/ecma-262/5.1/#sec-15.9.1.8
    // a better solution would be to find a similar year, in terms of leap and
    // starting weekday, and use the corresponding dates for daylight start/end.
    ranges[0].start = ranges[0].localStart = MIN_JAVASCRIPT_TIMESTAMP;

    // only optimize if we have enough elements to swap all 3
    if (
        optimizeOrder &&
        currentPeriodIndex >= MIN_CURRENT_PERIOD_INDEX &&
        currentPeriodIndex + 1 < ranges.length
    ) {
        // Optimize order to minimize looping
        // By default the order is chronological, but users are typically
        // using either the curreent range, the next or previous range
        swapElements(ranges, 0, currentPeriodIndex);
        swapElements(ranges, 1, currentPeriodIndex + 1);
        swapElements(ranges, 2, currentPeriodIndex - 1);
    }

    return ranges;
}

function swapElements<T>(arr: T[], index1: number, index2: number) {
    const temp = arr[index1];
    arr[index1] = arr[index2];
    arr[index2] = temp;
}
