import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import type { MailLayoutOptions } from 'owa-outlook-service-option-store';
import { getDefaultOptions } from 'owa-outlook-service-options';
import { OwsOptionsFeatureType } from 'owa-outlook-service-option-store';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { setAnimationPreference } from '../utils/setAnimationPreference';

export function initializeAnimations() {
    setAnimationPreference(getIsEnabledFromPrimeSettings());
}

const getIsEnabledFromPrimeSettings = () => {
    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();
    const primeSettingsItems = getAccountScopeUserSettings(mailboxInfo)?.PrimeSettings?.Items;
    const primeListMailLayoutOptions: any[] | undefined = primeSettingsItems?.filter(
        item => item?.Id == 'MailLayoutOptions'
    );

    const mailLayoutOptions =
        primeListMailLayoutOptions?.[0]?.Value?.options?.[0] ||
        (getDefaultOptions()[OwsOptionsFeatureType.MailLayout] as MailLayoutOptions);
    return mailLayoutOptions?.animationPreference;
};
