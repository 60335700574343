import type { MailboxInfo } from 'owa-client-types';
import { action } from 'satcheljs';

export const onDismissLeftNavBar = action(
    'onDismissLeftNavBar',
    (mailboxInfo: MailboxInfo, isMSQ: boolean) => ({
        mailboxInfo,
        isMSQ,
    })
);
