import type OwsOptionsBase from '../OwsOptionsBase';

// The shape is defined on the server, and should be kept in sync
// In the server-ows-api project: src/Microsoft.OWS.UserSettings/Models/OutlookOptions/OptionsTypes/KeyboardShortcutsOptions.cs
interface KeyboardShortcutsOptions extends OwsOptionsBase {
    keyboardShortcutsMode: MonarchKeyboardShortcutsMode;
}

export enum MonarchKeyboardShortcutsMode {
    OutlookForWindows = 0,
    OutlookForWeb = 1,
    Off = 2,
}

export default KeyboardShortcutsOptions;
