import type { NovaEvent } from '@nova/types';
import type { CardParameters } from 'owa-lpc-types';
import type { MailboxInfo } from 'owa-client-types';
import type { AppHostServiceEventOriginator } from './originator';

export const OpenLPCEventType = 'openLPC' as const;

export interface OpenLPCEvent extends NovaEvent<OpenLPCEventData> {
    originator: typeof AppHostServiceEventOriginator;
    type: typeof OpenLPCEventType;
    data: () => OpenLPCEventData;
}

export interface OpenLPCEventData {
    viewerMailboxInfo: MailboxInfo;
    elementOrBoundingRect: HTMLElement | DOMRect;
    cardParameters: CardParameters;
}
