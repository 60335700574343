import type { ClientItemId } from 'owa-client-ids';
import type { MailboxInfo } from 'owa-client-types';
import { owaComputedFn } from 'owa-computed-fn';
import { getCmdIdFromRibbonId } from 'owa-mail-ribbon-store/lib/util/mailRibbonIdToCmdIdMap';
import { getIsPopoutButtonDisabled } from './getIsPopoutButtonDisabled';
import {
    getMailMenuItemShouldShow,
    getMailMenuItemShouldDisable,
} from 'owa-mail-filterable-menu-behavior';
import { getReadRibbonBaseId } from './getReadRibbonId';
import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';

export const getIsReadButtonDisabled = owaComputedFn(
    (
        controlId: number | string,
        isPopout: boolean,
        mailboxInfo: MailboxInfo,
        itemId: ClientItemId | undefined
    ): boolean => {
        // if we're in a read only pop out, use getIsPopoutButtonDisabled instead
        if (isPopout) {
            return getIsPopoutButtonDisabled(getReadActionControlId(controlId), itemId);
        } else {
            const id: number = getNumericRibbonId(controlId);
            const cmdId = getCmdIdFromRibbonId(id, mailboxInfo);
            switch (cmdId) {
                case 83:
                case 86:
                case 92:
                    return getMailMenuItemShouldDisable(cmdId);
                default:
                    return !getMailMenuItemShouldShow(cmdId, mailboxInfo);
            }
        }
    }
);

const getReadActionControlId = owaComputedFn(function getReadActionControlId(
    id: string | number
): number {
    if (typeof id === 'string') {
        const baseId = getReadRibbonBaseId(id);
        return getNumericRibbonId(baseId);
    } else {
        return id;
    }
});

function getNumericRibbonId(id: number | string): number {
    return typeof id == 'string' ? parseInt(id) : id;
}
