import { LazyModule, createLazyComponent, LazyAction } from 'owa-bundling';
import { GovernPriority } from 'owa-client-types/lib/GovernPriority';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "RebootModal" */ './lazyIndex'),
    { name: 'RebootModal', govern: GovernPriority.Idle }
);

export const RebootModal = createLazyComponent(lazyModule, m => m.RebootModal);
export const lazySoftReboot = new LazyAction(lazyModule, m => m.softReboot);
export const lazyForceReboot = new LazyAction(lazyModule, m => m.forceReboot);
