import { LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailFilterActions"*/ './lazyIndex')
);

export const lazySelectFilter = new LazyAction(lazyModule, m => m.selectFilter);
export const lazySelectSort = new LazyAction(lazyModule, m => m.selectSort);
export const lazyClearFilter = new LazyAction(lazyModule, m => m.clearFilter);
export const lazyRemoveFilterOverrideAndReloadFolder = new LazyAction(
    lazyModule,
    m => m.removeSortByOverrideAndReloadFolder
);
