import {
    type ListViewBitFlagsMasks,
    setBitAndUpdateAccountScopeUserSettingsAndService,
} from 'owa-bit-flags/lib/utils/listViewBitFlagsUtil';
import { getCurrentDensity } from 'owa-fabric-densities';
import { changeDensity } from 'owa-fabric-theme';
import type DisplayDensityMode from 'owa-service/lib/contract/DisplayDensityMode';
import { getUserConfiguration } from 'owa-session-store';
import { updateUserConfigurationAndService } from 'owa-userconfiguration/lib/utils/updateUserConfigurationAndService';
import type { SecondaryDensitySettings } from '../utils/getSecondarySettingDefaultsForMode';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import type { UserConfigurationArgs } from 'owa-graph-schema';

export async function saveDensityOption(
    displayDensityMode: DisplayDensityMode,
    secondarySettings?: SecondaryDensitySettings
) {
    const userOptions = getUserConfiguration().UserOptions;

    changeDensity(displayDensityMode, getCurrentDensity(displayDensityMode));

    const displayDensityChanging = displayDensityMode !== userOptions?.DisplayDensityMode;
    const showInlinePreviewChanging =
        secondarySettings &&
        secondarySettings.enableInlinePreview !== userOptions?.ShowInlinePreviews;

    if (displayDensityChanging || showInlinePreviewChanging) {
        const updates: UserConfigurationArgs = {};
        if (displayDensityChanging) {
            updates['UserOptions'] = {
                DisplayDensityMode: displayDensityMode,
            };
        }
        if (showInlinePreviewChanging) {
            updates['UserOptions'] = {
                ...updates['UserOptions'],
                ShowInlinePreviews: secondarySettings.enableInlinePreview,
            };
        }

        await updateUserConfigurationAndService(updates);
    }

    if (secondarySettings) {
        await setBitAndUpdateAccountScopeUserSettingsAndService(
            secondarySettings.hideSenderImage,
            8,
            getGlobalSettingsAccountMailboxInfo()
        );
    }
}
