import { localStorageExists, setItem } from 'owa-local-storage';
import { CLIENT_CONFIGURATION_COMBINED_FAVORITES_KEY } from '../favoriteConstants';
import type { CombinedFavoritesList } from '../store/schema/CombinedFavoritesList';

export default function setCombinedFavoritesInCache(combinedFavorites: CombinedFavoritesList) {
    if (!localStorageExists(window)) {
        return;
    }
    setItem(window, CLIENT_CONFIGURATION_COMBINED_FAVORITES_KEY, JSON.stringify(combinedFavorites));
}
