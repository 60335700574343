import type { ActionMessage } from 'satcheljs';
import type { CoreDatapointConfig, DatapointConfig } from 'owa-analytics-types';

export default function addDatapointConfig<TMessage extends ActionMessage>(
    config: DatapointConfig,
    actionMessage: TMessage
): TMessage {
    const messageWithDatapoint: TMessage & {
        dp: DatapointConfig;
    } = {
        ...actionMessage,
        dp: config,
    };
    return messageWithDatapoint;
}

export function addCoreDatapointConfig<TMessage extends ActionMessage>(
    config: CoreDatapointConfig,
    actionMessage: TMessage
): TMessage {
    if (config?.options) {
        config.options.isCore = true;
    } else {
        config.options = { isCore: true };
    }
    const messageWithDatapoint: TMessage & {
        dp: CoreDatapointConfig;
    } = {
        ...actionMessage,
        dp: config,
    };
    return messageWithDatapoint;
}
