import type { GlobalCalendarLocale } from './globalCalendarLocale';
import { GlobalCalendarType } from './globalCalendarLocale';

// Certain types of Global Calendars only support displaying dates, and as such don't
// support things like day of week, month and year
export default function isGlobalCalendarDateOnly(
    globalCalendarLocale: GlobalCalendarLocale
): boolean {
    switch (globalCalendarLocale.calendarType) {
        case GlobalCalendarType.Rokuyou:
        case GlobalCalendarType.ChineseLunar:
        case GlobalCalendarType.JapaneseLunar:
        case GlobalCalendarType.KoreanLunar:
            return true;
        default:
            return false;
    }
}
