import type { MailboxInfo } from 'owa-client-types';
import type { ApplicationSettings } from '../store/schema/ApplicationSettings';
import { action } from 'satcheljs';

export const applicationSettingsInitialized = action(
    'APPLICATION_SETTINGS_INITIALIZED',
    (mailboxInfo: MailboxInfo | undefined, settings: ApplicationSettings) => ({
        mailboxInfo,
        settings,
    })
);
