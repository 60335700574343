interface RibbonControlConstants {
    borderRadius: number;
    chevronIconSize: number;
    iconSize: number;
    rootHeight: number;
    rootMarginTop: number;
    splitButtonMenuButtonWidth: number;
}

export const getButtonRootConstants = (isHeroStyle?: boolean): RibbonControlConstants => {
    return {
        borderRadius: 4,
        chevronIconSize: isHeroStyle ? 16 : 12,
        iconSize: 20,
        rootHeight: 32,
        rootMarginTop: 4,
        splitButtonMenuButtonWidth: isHeroStyle ? 28 : 16,
    };
};

export const singleLineRibbonStyleConstants = getButtonRootConstants(false);
