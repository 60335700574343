import { createStore } from 'satcheljs';
import type OwaLayoutStore from './schema/owaLayoutStore';

const owaLayoutStore: OwaLayoutStore = {
    browserHeightBucket: null,
    browserWidthBucket: null,
    areDisplayAdsEnabled: false,
};

const store = createStore<OwaLayoutStore>('OwaLayout', owaLayoutStore)();
export const getStore = () => store;
