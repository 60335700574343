import { LazyAction, LazyModule, createLazyComponent } from 'owa-bundling';
import store from './store/store';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MoveFolderDialog"*/ './lazyIndex')
);

export const lazySetShouldShowMoveToFolderDialog = new LazyAction(
    lazyModule,
    m => m.setShouldShowMoveToFolderDialog
);

export const LazyMoveToFolderDialog = createLazyComponent(
    lazyModule,
    m => m.MoveToFolderDialog,
    undefined,
    undefined,
    { onlyImportIf: () => store.shouldShow }
);
