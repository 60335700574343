import { lazyGetQuickStepsSections } from 'owa-mail-ribbon-sections/lib/sections';

import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onQuickStepsMenuClicked = (props: ReadOnlyRibbonControlDefProps) => {
    lazyGetQuickStepsSections.import().then(getQuickStepsSections => {
        setMenuDefinition('QuickStepsMenuDefinitionStore', undefined /* projectionTabId */, () => {
            return {
                sections: getQuickStepsSections(props),
            };
        });
    });
};
