import { isOfflineMailDataEnabled } from 'owa-offline-sync-feature-flags';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';
import isDeepLink from 'owa-url/lib/isDeepLink';

export function isOfflineMailComposeEnabled(mailboxInfo?: MailboxInfo) {
    return (
        !isDeepLink() &&
        isFeatureEnabled('cmp-offline-mail-newdraft') &&
        isOfflineMailDataEnabled(mailboxInfo)
    );
}
