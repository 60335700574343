import { getStore } from '../store/store';
import { SearchScopeKind } from 'owa-search-service/lib/data/schema/SearchScope';
import { getHasArchive, getAccountScopeUserSettings } from 'owa-session-store';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

export default function isResultFromArchiveFanout(mailboxGuids: string[]): boolean {
    if (!getHasArchive(getModuleContextMailboxInfo())) {
        return false;
    }

    const { staticSearchScope, selectedMailboxInfo } = getStore();
    if (
        staticSearchScope?.kind !== SearchScopeKind.PrimaryMailbox ||
        !mailboxGuids ||
        mailboxGuids.length == 0
    ) {
        return false;
    }

    const currentMailboxInfo = selectedMailboxInfo ?? getModuleContextMailboxInfo();

    const primaryMailboxGuid =
        getAccountScopeUserSettings(currentMailboxInfo)?.SessionSettings?.MailboxGuid;
    if (primaryMailboxGuid) {
        return mailboxGuids[0] !== primaryMailboxGuid;
    }

    return false;
}
