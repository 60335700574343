import type { ComposeViewState } from 'owa-mail-compose-store';
import { composeStore } from 'owa-mail-compose-store';
import { getStore as getTabStore, TabType } from 'owa-tab-store';
import getProjection from 'owa-popout-v2/lib/utils/getProjection';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

export default function internalRetrieve<T>(
    composeId: string,
    callback: (viewState: ComposeViewState, targetWindow: Window) => T
): T | null {
    const viewState = composeStore.viewStates.get(composeId);
    const composeTab = getTabStore().tabs.filter(
        tab => tab.type == TabType.MailCompose && tab.data == composeId
    )[0];
    const projection = composeTab && getProjection(composeTab.id);

    if (viewState) {
        const targetWindow =
            projection?.window || callEditorApi(viewState, 'getDocument')?.defaultView || window;

        return callback(viewState, targetWindow);
    } else {
        return null;
    }
}
