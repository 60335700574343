import type { SearchTableQuery } from 'owa-mail-list-search';
import type { TableQuery, MailFolderTableQuery } from 'owa-mail-list-store';
import { type TableQueryType, type SortColumn } from 'owa-mail-list-store';
import { assertNever } from 'owa-assert';

export type HeaderGeneratorType =
    | 'DateTime'
    | 'RelevanceHybrid'
    | 'FromTo'
    | 'Size'
    | 'Importance'
    | 'Subject'
    | 'Category';

export interface TableViewOptions {
    shouldHighlight: boolean;
    headerGeneratorType: HeaderGeneratorType;
}

export function mapTableQueryToTableViewOptions(tableQuery: TableQuery): TableViewOptions {
    switch (tableQuery.type) {
        case 1:
            return getSearchTableQueryOptions(tableQuery as SearchTableQuery);
        case 0:
        case 2:
        case 3:
            return getMailTableQueryOptions(tableQuery as MailFolderTableQuery);
        default:
            return assertNever(tableQuery.type);
    }
}

function getSearchTableQueryOptions(tableQuery: SearchTableQuery): TableViewOptions {
    switch (tableQuery.scenarioType) {
        case 'mail':
        case 'messageSuggestion':
        case 'tabbedSearch':
        case 'groupsmail':
        case 'offlineSearch':
        case 'pstSearch':
            return { shouldHighlight: true, headerGeneratorType: 'RelevanceHybrid' };
        case 'senderGroupings':
        case 'persona':
        case 'privateDistributionList':
        case 'category':
        case 'copilotChatBulkTriage':
            return { shouldHighlight: false, headerGeneratorType: 'DateTime' };
        default:
            return assertNever(tableQuery.scenarioType);
    }
}

function getMailTableQueryOptions(tableQuery: MailFolderTableQuery): TableViewOptions {
    let headerGeneratorType: HeaderGeneratorType;

    const tableSortColumn =
        tableQuery.type == 0 && (tableQuery as MailFolderTableQuery).sortBy.sortColumn;

    switch (tableSortColumn) {
        case 3:
        case 9:
            headerGeneratorType = 'FromTo';
            break;

        case 7:
            headerGeneratorType = 'Size';
            break;

        case 5:
            headerGeneratorType = 'Importance';
            break;

        case 8:
            headerGeneratorType = 'Subject';
            break;

        case 14:
            headerGeneratorType = 'Category';
            break;

        case 1:
        case 15:
        default: {
            // always fall back to date header generator
            headerGeneratorType = 'DateTime';
            break;
        }
    }

    return { shouldHighlight: true, headerGeneratorType };
}
