import type SweepOptionType from '../store/schema/SweepOptionType';
import sweepStore from '../store/store';
import { mutatorAction } from 'satcheljs';

/**
 * Set the SweepOptionType in sweep store
 * @param key the key as SweepOptionType to set
 * @param state the state which contains the sweep store
 */
export default mutatorAction(
    'setSweepOptionType',
    function setSweepOptionType(key: SweepOptionType) {
        sweepStore.sweepOptionType = key;
    }
);
