import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { logUsage } from 'owa-analytics';
import type { MailboxInfo } from 'owa-client-types';
import { isConsumer } from 'owa-session-store';
import type AccessIssue from '../schema/AccessIssue';
import getAttachmentPolicyBasedOnFlag from './getAttachmentPolicyBasedOnFlag';

/**
 * Those fields combine both conditional access and attachment policy
 * the logic is on the server code
 */
export default function getCombinedAccessIssue(mailboxInfo: MailboxInfo): AccessIssue {
    let attachmentPolicy = getAttachmentPolicyBasedOnFlag(mailboxInfo);
    if (!attachmentPolicy) {
        logUsage('AttachmentPolicyUndefinedForAccessIssue');
        attachmentPolicy = getAttachmentPolicyBasedOnFlag(getGlobalSettingsAccountMailboxInfo());
        if (!attachmentPolicy) {
            const isConsumerUser = isConsumer(undefined /* smtpAddress */, mailboxInfo);
            logUsage('AttachmentPolicyUndefinedForAccessIssueWithFallback', { isConsumerUser });
            if (isConsumerUser) {
                return 2;
            }
            return 1;
        }
    }

    if (
        !attachmentPolicy.DirectFileAccessOnPrivateComputersEnabled ||
        !attachmentPolicy.DirectFileAccessOnPublicComputersEnabled
    ) {
        if (
            !attachmentPolicy.WacViewingOnPrivateComputersEnabled ||
            !attachmentPolicy.WacViewingOnPublicComputersEnabled
        ) {
            return 1;
        } else {
            return 0;
        }
    }
    return 2;
}
