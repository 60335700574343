// extracted by mini-css-extract-plugin
export var categoryIcon = "XWS3i";
export var compact = "xFMKk";
export var full = "Cnnoo";
export var groupHeaderClickable = "MtAK6";
export var iconContainer = "H1SeE";
export var medium = "yNQ4B";
export var outerContainer = "HJne5";
export var scl = "pqyEZ";
export var selectionModeActivated = "Chrlg";
export var senderImageOn = "lzRkn";