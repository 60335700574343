import { ObservableMap } from 'mobx';
import type { MailFolder } from 'owa-graph-schema';
import { mutatorAction } from 'satcheljs';
import getStore from '../store/store';
import getFolderTable from '../selectors/getFolderTable';

export const addFoldersToFolderTableMutator = mutatorAction(
    'addFoldersToFolderTableMutator',
    (folders: { [id: string]: MailFolder }) => {
        const folderTable = getFolderTable();
        if (folderTable.size == 0) {
            getStore().folderTable = new ObservableMap();
        }

        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        Object.keys(folders).forEach(id => {
            const newFolderString = JSON.stringify(folders[id]);
            if (JSON.stringify(getFolderTable().get(id)) != newFolderString) {
                const newFolder = JSON.parse(newFolderString);
                getFolderTable().set(id, newFolder);
            }
        });
    }
);
