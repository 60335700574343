import setFolderViewState from '../actions/setFolderViewState';
import type MailFolderNodeViewState from '../store/schema/MailFolderNodeViewState';
import { default as folderTreeViewStateStore } from '../store/store';
import createDragViewState from 'owa-dnd/lib/utils/createDragViewState';
import createDropViewState from 'owa-dnd/lib/utils/createDropViewState';
import type { SortBy } from 'owa-mail-list-store';
import { MailSortHelper } from 'owa-mail-list-store';
import type { ObservableMap } from 'mobx';

export interface GetFolderViewStateFromIdState {
    folderNodeViewStates: ObservableMap<string, MailFolderNodeViewState>;
}

export default function getFolderViewStateFromId(folderId: string): MailFolderNodeViewState {
    const folderViewState = folderTreeViewStateStore.folderNodeViewStates.get(folderId);

    if (!folderViewState) {
        return getDefaultFolderViewState();
    } else {
        return folderViewState;
    }
}

export const initializeFolderViewStateIfNeeded = (folderId: string) => {
    if (!folderTreeViewStateStore.folderNodeViewStates.has(folderId)) {
        setFolderViewState(folderId, getDefaultFolderViewState());
    }
};

const getDefaultFolderViewState = () => {
    const defaultSortBy: SortBy = MailSortHelper.getDefaultSortBy();
    return {
        isExpanded: false,
        sortColumn: defaultSortBy.sortColumn,
        sortOrder: defaultSortBy.sortDirection,
        drag: createDragViewState(),
        drop: createDropViewState(),
        color: undefined,
    };
};
