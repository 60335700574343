import type { ReadingPaneLog } from '../store/schema/ReadingPaneLogStore';
import { readingPaneLogStore } from '../store/store';

export function getReadingPaneLogsToSendToOds(): string[] {
    return readingPaneLogStore.readingPaneLogs.map(log => getLogString(log));
}

function getLogString(log: ReadingPaneLog): string {
    return `${log.time},${log.message},${Object.keys(log.data)
        .map(key => `${key}: ${log.data[key]}`)
        .join(',')},`;
}
