import { type MailboxInfo, getIndexerValueForMailboxInfo } from 'owa-client-types';
const isPremiumEncryptionMap = new Map<string, boolean>();

export function setHavePremiumEncryption(mailboxInfo: MailboxInfo, value: boolean): void {
    const indexer = getIndexerValueForMailboxInfo(mailboxInfo);
    isPremiumEncryptionMap.set(indexer, value);
}
export function getHavePremiumEncryption(mailboxInfo: MailboxInfo): boolean {
    const indexer = getIndexerValueForMailboxInfo(mailboxInfo);
    return !!isPremiumEncryptionMap.get(indexer);
}
