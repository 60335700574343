import { errorThatWillCauseAlert, trace } from 'owa-trace';
import { areFeatureFlagsInitialized } from '../actions/initializeFeatureFlags';

/**
 * The flight checks normally require feature flags to be enabled. Some application, such as the
 * Out Of Box Experience (OOBE), run client-web code without the global settings account
 * being set. Anonymous Flighting support makes it so that all calls to isFeatureEnabled
 * will return false.
 */
let anonymousFlightingEnabled = false;

/**
 * Turns on Anonymous Flighting. If the flights for the global settings account have been
 * initialized anonymous flights will not be enabled.
 */
export function enableAnonymousFlighting() {
    if (areFeatureFlagsInitialized()) {
        errorThatWillCauseAlert(
            'Attempted to enable Anonymous Flighting after feature flags were initialized.'
        );
    } else {
        trace.info('Anonymous Flighting Enabled');
        anonymousFlightingEnabled = true;
    }
}

/**
 * Checks to see if anonymous flights should be disabled
 */
export function checkIfAnonymousFlightingShouldBeDisabled() {
    if (anonymousFlightingEnabled && areFeatureFlagsInitialized()) {
        // Once the global settings account is initialized we need to disable Anonymous Flighting
        anonymousFlightingEnabled = false;
        trace.info('Anonymous Flighting Disabled');
    }
}

/**
 * Checks to see if Anonymous Flighting is enabled
 * @returns True if Anonymous Flighting is enabled, false otherwise
 */
export default function isAnonymousFlightingEnabled(): boolean {
    checkIfAnonymousFlightingShouldBeDisabled();
    return anonymousFlightingEnabled;
}
