import type { NovaEvent } from '@nova/types';
import type { AppHostServiceEventOriginator } from './originator';

export const StartChatViaSipProtocolEventType = 'startChatViaSipProtocol' as const;

export interface StartChatViaSipProtocolEvent extends NovaEvent<StartChatViaSipProtocolEventData> {
    originator: typeof AppHostServiceEventOriginator;
    type: typeof StartChatViaSipProtocolEventType;
    data: () => StartChatViaSipProtocolEventData;
}

export interface StartChatViaSipProtocolEventData {
    emails: string[];
}
