import { logStartGreyError } from 'owa-analytics-start';
import { isConsumer, isShadowMailboxUser, getAccountScopeUserSettings } from 'owa-session-store';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import getCoprincipalAccountForIndexerValue from 'owa-account-source-list-store/lib/utils/getCoprincipalAccountForIndexerValue';
import isAccountSourceListStoreInitialized from 'owa-account-source-list-store/lib/utils/isAccountSourceListStoreInitialized';
import { isEnterprise, isCloudCache } from 'owa-account-source-list-internal';
import { getAnchorMailboxFromSessionSettings } from './getAnchorMailboxFromSessionSettings';
import { isSharedCoprincipalAccountEnabled } from './isSharedCoprincipalAccountEnabled';
import { isMailboxSharedOrDelegateUserMailbox } from './isMailboxSharedOrDelegateUserMailbox';
import { getAnchorMailboxForSharedOrDelegateUserMailbox } from './getAnchorMailboxForSharedOrDelegateUserMailbox';

import type { MailboxInfo } from 'owa-client-types';

/**
 * Returns the anchor mailbox that should be used with the request
 * This is the lowest level to get the anchormailbox from mailbox Info
 * @param MailboxInfo Specifies the mailbox infor that should be used for routing the request
 * @returns the routing hint that should be used
 */
export function getAnchorMailboxMailboxInfo(mailboxInfo?: MailboxInfo): string {
    if (
        isSharedCoprincipalAccountEnabled() &&
        !!mailboxInfo &&
        isMailboxSharedOrDelegateUserMailbox(mailboxInfo)
    ) {
        return getAnchorMailboxForSharedOrDelegateUserMailbox(mailboxInfo);
    }

    if (
        !!mailboxInfo &&
        mailboxInfo.type === 'UserMailbox' &&
        isAccountSourceListStoreInitialized()
    ) {
        // we are using the primary mailbox associated with the check if it is an MSA Account
        const sessionSettings = getAccountScopeUserSettings(
            mailboxInfo,
            true /*noGreyError*/
        )?.SessionSettings;
        if (sessionSettings) {
            const anchorMailbox = getAnchorMailboxFromSessionSettings(
                {
                    // Call the owa-session-store isShadowMailboxUser function, which will check if the mailbox
                    // is a shadow mailbox, which is the same as being isCloudCache function. The isShadowMailboxUser
                    // is simply an older term for the same concept.
                    isCloudCache: isShadowMailboxUser(mailboxInfo),
                    isConsumer: isConsumer(undefined, mailboxInfo),
                },
                sessionSettings
            );
            if (anchorMailbox) {
                return anchorMailbox;
            }
        }

        // We could not get the routing hint from the session store, this is expected when a
        // coprincipal account is booting. Try and determine the type of account from the
        // owa-account-source-list-store and build the routing hint using the MailboxInfo.
        const indexer = getIndexerValueForMailboxInfo(mailboxInfo);
        const accountSource = getCoprincipalAccountForIndexerValue(indexer);
        if (accountSource) {
            if (isCloudCache(accountSource)) {
                if (mailboxInfo.userIdentity) {
                    return `SMTP:${mailboxInfo.userIdentity}`;
                }
            } else if (mailboxInfo.mailboxSmtpAddress) {
                if (isEnterprise(accountSource)) {
                    return `SMTP:${mailboxInfo.mailboxSmtpAddress}`;
                }

                return `MSA:${mailboxInfo.mailboxSmtpAddress}`;
            }
        }

        const error = new Error('FailedToGetAnchorMailbox');
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logStartGreyError(error.message, error, {
            sset: typeof sessionSettings,
            edtg: typeof sessionSettings?.ExternalDirectoryTenantGuid,
            edtgl: sessionSettings?.ExternalDirectoryTenantGuid?.length,
            up: typeof sessionSettings?.UserPuid,
            upl: sessionSettings?.UserPuid?.length,
            /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
             * Adding IsShadowMailbox to restricted properties/methods.
             *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
            ism: sessionSettings?.IsShadowMailbox,
            wst: sessionSettings?.WebSessionType,
            uea: typeof sessionSettings?.UserEmailAddress,
            ueal: sessionSettings?.UserEmailAddress?.length,
            lea: typeof sessionSettings?.LogonEmailAddress,
            leal: sessionSettings?.LogonEmailAddress?.length,
            asls: typeof accountSource,
            aslsboot: accountSource?.bootState,
            msa: typeof mailboxInfo.mailboxSmtpAddress,
            msal: mailboxInfo.mailboxSmtpAddress?.length,
            ui: typeof mailboxInfo.userIdentity,
            uil: typeof mailboxInfo.userIdentity?.length,
        });
    }

    // Fallback to the previous implementation
    return mailboxInfo?.userIdentity || '';
}
