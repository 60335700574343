import type { PartialTheme } from '@fluentui/theme';
import { getPalette } from 'owa-theme';
import type { DensityModeString } from 'owa-fabric-theme';
import { owaComputedFn } from 'owa-computed-fn';

export const getIconBarTheme = owaComputedFn(
    (densityModeString: DensityModeString): PartialTheme =>
        densityModeString == 'full'
            ? getIconBarStyle('20px', '4px 2px', '11px') // theme for full
            : getIconBarStyle('16px', '2px', '7px') // theme for medium and compact
);

function getIconBarStyle(fontSize: string, margin: string, spaceBetweenIcons: string) {
    return {
        components: {
            IconButton: {
                styles: {
                    root: {
                        height: 'auto',
                        width: 'auto',
                        marginLeft: spaceBetweenIcons,
                    },
                    icon: {
                        // The fluent icon height automatically assigns a line height which we need to override.
                        lineHeight: 'normal',
                        fontSize,
                        alignItems: 'center',
                        display: 'flex',
                        margin,
                    },
                    flexContainer: {
                        height: 'auto',
                    },
                },
            },
            Icon: {
                styles: {
                    root: {
                        fontSize,
                        margin,
                        textAlign: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        color: getPalette().neutralPrimaryAlt,
                        paddingLeft: spaceBetweenIcons,
                    },
                },
            },
        },
    };
}
