import { action } from 'satcheljs';
import type { CalendarComposeInitializationConfig } from 'owa-calendar-helpers-types/lib/CalendarComposeInitializationConfig';

export const launchCalendarFullCompose = action(
    'launchCalendarFullCompose',
    (
        config: CalendarComposeInitializationConfig,
        freezeDryId?: string,
        customStartTime?: number
    ) => ({ config, freezeDryId, customStartTime })
);
