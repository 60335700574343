import type {
    ApplicationSettings,
    ApplicationSettingGroup,
} from '../store/schema/ApplicationSettings';

import { defaultApplicationSettings } from '../store/applicationSettingsDefinitions';

export default function getApplicationSettingsFromSessionData<
    TGroup extends ApplicationSettingGroup
    /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
     * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
     *	> Optional object properties should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
>(
    sessionData: {
        applicationSettings?: any;
    },
    group: TGroup
): ApplicationSettings[TGroup] {
    const sessionGroup = sessionData.applicationSettings?.settings[group];

    return sessionGroup || defaultApplicationSettings[group];
}
