import { getAccountByMailboxInfo } from 'owa-account-source-list-store';
import { getAccountScopeUserSettings, isShadowMailboxUser } from 'owa-session-store';
import { isCloudCache } from 'owa-account-source-list-internal';
import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';

export const inboxRulesCapability: Capability = {
    isEnabled: (mailboxInfo?: MailboxInfo) => {
        if (!mailboxInfo) {
            return false;
        }

        return !!getAccountScopeUserSettings(mailboxInfo)?.SegmentationSettings?.Rules;
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        if (!mailboxInfo) {
            return false;
        }

        const accountSource = getAccountByMailboxInfo(mailboxInfo);
        const isShadowMailbox = isShadowMailboxUser(mailboxInfo);
        const isCloudCacheUser = !!accountSource && isCloudCache(accountSource);

        // Cloud cache / ShadowMailbox accounts don't have support for inbox rules
        // Also, we don't support service requests for these accounts
        if (
            isCloudCacheUser ||
            isShadowMailbox ||
            !isServiceRequestSupportedForMailbox(mailboxInfo)
        ) {
            return false;
        }

        return true;
    },
};
