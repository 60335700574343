import { mergeStyles } from '@fluentui/merge-styles';

export const getRightPaneStyles = (shouldShowLeftNav: boolean, leftNavWidth: number): string => {
    const marginLeft = (shouldShowLeftNav ? 0 : -1) * leftNavWidth;
    return mergeStyles({
        flex: '1 1 auto',
        marginLeft,
        transition: 'margin-left 0.2s',
    });
};
