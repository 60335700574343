import { Reaction } from 'mobx';
import { logGreyError, incrementReactionCount } from 'owa-analytics';
import { getActionMetadata } from 'owa-analytics/lib/utils/getActionMetadata';
import { createErrorWithoutStackTraceLimit } from './createErrorWithoutStackTraceLimit';

const ERROR_NAME = 'TOO_MANY_RERENDERS';
const MAX_RENDERS = 10;

// Track render counts for each component
let counts: Map<any, number> | undefined;

export function patchReaction() {
    // We use a scheduled reaction as a proxy for a render
    const originalSchedule = Reaction.prototype.schedule_;
    Reaction.prototype.schedule_ = function scheduleWrapper() {
        // If the reaction is already scheduled, then this doesn't count as another render
        if (!this.isScheduled()) {
            incrementReactionCount();

            // Initialize the render counts, if necessary
            if (!counts) {
                counts = new Map();

                // Clear render counts as soon as possible on the event loop
                Promise.resolve().then(() => {
                    counts = undefined;
                });
            }

            // Report an error when we surpass MAX_RENDERS (but not for subsequent renders, because we
            // only want to report it once)
            const count = counts.get(this) ?? 0;
            if (count == MAX_RENDERS) {
                if (process.env.NODE_ENV == 'dev') {
                    throw createErrorWithoutStackTraceLimit(ERROR_NAME);
                } else {
                    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
                     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
                     *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
                    logGreyError(ERROR_NAME, createErrorWithoutStackTraceLimit(ERROR_NAME), {
                        lastActions: getActionMetadata().queue.join('|'),
                    });
                }
            }

            // Increment the render count
            counts.set(this, count + 1);
        }

        // Actually call schedule
        originalSchedule.apply(this);
    };
}
