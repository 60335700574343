import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { OcsSettingScope, OcsVersion, readOcsSettings } from 'owa-ocs-service';
import { COPILOT_THEME_SETTING_NAME } from '../constants/constants';

export default async function fetchThemeOptionsFromOcs() {
    const ocsResponse: any[] = await readOcsSettings(
        getGlobalSettingsAccountMailboxInfo(),
        OcsVersion.V1,
        OcsSettingScope.Global,
        [COPILOT_THEME_SETTING_NAME]
    );

    if (ocsResponse && ocsResponse.length > 0) {
        return ocsResponse[0];
    }

    return undefined;
}
