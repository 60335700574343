export function isTenantAcquiredDefaultAddIn(appId: string): boolean {
    const bingMapsAppId = '7a774f0c-7a6f-11e0-85ad-07fb4824019b';
    const unsubscribeAppId = 'd39dee0e-fdc3-4015-af8d-94d4d49294b3';
    const myTemplatesAppId = 'a216ceed-7791-4635-a752-5a4ac0a5eb93';
    const actionItemsAppId = 'f60b8ac7-c3e3-4e42-8dad-e4e1fea59ff7';
    const suggestedMeetingsAppId = 'bc13b9d0-5ba2-446a-956b-c583bdc94d5e';

    const id = appId.toLowerCase();
    return (
        id === bingMapsAppId ||
        id === unsubscribeAppId ||
        id === myTemplatesAppId ||
        id === actionItemsAppId ||
        id === suggestedMeetingsAppId
    );
}
