import { getNonBootUserConfigurationSync } from '../getNonBootUserConfiguration';
import { getAccountScopeUserSettings } from 'owa-session-store';
import type AliasDisplayNameEntry from 'owa-service/lib/contract/AliasDisplayNameEntry';
import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';

const aliasDisplayNamesList = new Map<string, AliasDisplayNameEntry[]>();

function getAliasDisplayNamesFromMailbox(mailboxInfo: MailboxInfo): AliasDisplayNameEntry[] {
    const config = getNonBootUserConfigurationSync(mailboxInfo);
    if (!config) {
        return [];
    }
    const singleMailboxAliasDisplayNamesList: AliasDisplayNameEntry[] =
        config?.AliasDisplayNames || [];
    singleMailboxAliasDisplayNamesList.push({
        Alias: getAccountScopeUserSettings(mailboxInfo).SessionSettings?.UserEmailAddress,
        DisplayName: getAccountScopeUserSettings(mailboxInfo).SessionSettings?.UserDisplayName,
    });
    const mailboxInfoId = getIndexerValueForMailboxInfo(mailboxInfo);
    aliasDisplayNamesList.set(mailboxInfoId, singleMailboxAliasDisplayNamesList);
    return singleMailboxAliasDisplayNamesList;
}

export default function getAliasDisplayNames(mailboxInfo: MailboxInfo): AliasDisplayNameEntry[] {
    const mailboxInfoId = getIndexerValueForMailboxInfo(mailboxInfo);
    let result = aliasDisplayNamesList.get(mailboxInfoId);
    if (!result) {
        result = getAliasDisplayNamesFromMailbox(mailboxInfo);
    }
    return result;
}
