import EventEmitter from 'owa-event-emitter';
import type { ActionCreator, ActionMessage, OrchestratorFunction } from 'satcheljs';
import { orchestrator } from 'satcheljs';
import { assertNever } from 'owa-assert';

const emitter: EventEmitter = new EventEmitter(); // the emitter which keeps track of registered event handlers
const orchestrators: Map<string, OrchestratorFunction<ActionMessage>> = new Map(); // a set of static orchestrators, created on demand, for the given actions

/**
 * Returns an event emitter and the actionId of the given action, which can be used
 * with the emitter's on/off/once methods to execute some code when that action is invoked.
 */
export function getActionEmitter<T extends ActionMessage>(
    action: ActionCreator<T>
): [EventEmitter, string] {
    // __SATCHELJS_ACTION_ID is the stable ID for a Satchel Action.
    const actionId = (action as any).__SATCHELJS_ACTION_ID as string;
    if (!actionId) {
        assertNever('Given function is not an action' as never);
    }

    // the first time we register a non-static event handler for the static action we need
    // to create a static orchestrator for it and keep it on the list; it never goes away since
    // the orchestrator is statically binding itself to the action inside satchel, so we keep
    // it forever here too.
    if (!orchestrators.get(actionId)) {
        /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
         * Move this function to the module scope or wrapped it on a once function
         *	> Function should only be invoked in module scope */
        const o = orchestrator(action, (actionMessage: T) => {
            // handle the static action by emitting an event, which is forwarded to the non-static handlers
            emitter.emit(actionId, actionMessage);
        }) as OrchestratorFunction<ActionMessage>;

        orchestrators.set(actionId, o);
    }

    return [emitter, actionId];
}
