import type { MailboxInfo } from 'owa-client-types';
import { getToDoAccountSources, getAllToDoAccountSources } from './getToDoAccountSources';
import { filterToMailboxInfoForAccountSources } from 'owa-account-source-list-store';

/**
 * Get MailboxInfo for the accounts that support ToDo.
 * @returns MailboxInfos for the accounts that support ToDo
 */
export function getMailboxInfoForToDoAccounts(): MailboxInfo[] {
    return filterToMailboxInfoForAccountSources(getToDoAccountSources());
}

/**
 * Get MailboxInfo for the accounts that support ToDo regardless of BootState.
 * @returns MailboxInfos for the accounts that support ToDo
 */
export function getMailboxInfoForAllToDoAccounts(): MailboxInfo[] {
    return filterToMailboxInfoForAccountSources(getAllToDoAccountSources());
}
