import { getIsDarkTheme } from 'owa-fabric-theme';
import { ThemeConstants } from 'owa-theme-shared';
import generateSuitePaletteFromFabricPalette from './generateSuitePaletteFromFabricPalette';
import React from 'react';
import type { ThemeDataOverride } from '@1js/suiteux-shell-react';
import {
    getCobrandingThemeResources,
    getCurrentThemeId,
    getHeaderImageData,
    getPaletteAsRawColors,
    isUserPersonalizationAllowed,
    getIsMicaEffectEnabled,
    getIsCurrentThemeModern,
} from 'owa-theme';
import { isCapabilityEnabled } from 'owa-capabilities';
import { copilotThemeCapability } from 'owa-capabilities-definitions/lib/copilotThemeCapability';

export default function useOwaThemeData() {
    const themeId = getCurrentThemeId();
    const UserPersonalizationAllowed = isUserPersonalizationAllowed();
    const IsDarkTheme = getIsDarkTheme();
    const isCopilotThemeEnabled = isCapabilityEnabled(copilotThemeCapability);
    const { themePalette } = getCobrandingThemeResources();

    return React.useMemo<Required<ThemeDataOverride>>(
        () => ({
            UserPersonalizationAllowed,
            IsDarkTheme,
            UserThemeId:
                themeId === ThemeConstants.OFFICE_NEUTRAL_THEME_ID ||
                themeId === ThemeConstants.MODERN_OFFICE_NEUTRAL_THEME_ID ||
                isCopilotThemeEnabled
                    ? ThemeConstants.BASE_OFFICE_THEME_ID
                    : themeId, //Shell does not recognize neutralThemeId, we need to pass BaseOfficeThemeId to allow it be treated as an appBrandTheme
            PreferOfficeTheme:
                themeId === ThemeConstants.BASE_OFFICE_THEME_ID ||
                themeId === ThemeConstants.OFFICE_NEUTRAL_THEME_ID ||
                getIsCurrentThemeModern(themeId) || // We need to set this flag as true if there is a modern theme selected to make the mica effect work correctly.
                isCopilotThemeEnabled, // We need to set this flag as true if there is a copilot theme selected to make the mica effect work correctly.
            UserThemePalette: {
                ...generateSuitePaletteFromFabricPalette(getPaletteAsRawColors(themeId)),
                ...getHeaderImageData(themeId),
            },
            TenantThemePalette: generateSuitePaletteFromFabricPalette(themePalette),
            isMicaEnabled: getIsMicaEffectEnabled(),
        }),
        [UserPersonalizationAllowed, IsDarkTheme, themeId, themePalette, isCopilotThemeEnabled]
    );
}
