import { ObservableMap } from 'mobx';
import type ComplianceConfiguration from 'owa-service/lib/contract/ComplianceConfiguration';
import type MessageClassificationType from 'owa-service/lib/contract/MessageClassificationType';
import type { DLPRuleResult } from 'owa-mail-protection-types/lib/schema/DLPPolicies';

export default function getDefaultProtectionStore() {
    return {
        clpLabels: [],
        learnMoreUrl: '',
        hasMandatoryLabel: false,
        hasMandatoryTeamworkLabel: false,
        labelInheritanceType: '',
        rmsTemplates: new ObservableMap<string, ComplianceConfiguration>(),
        messageClassifications: new ObservableMap<string, MessageClassificationType>(),
        dlpRuleConfig: {
            isAnyPolicyEnforced: true,
            dlpRuleResults: new ObservableMap<string, DLPRuleResult>(),
        },
        retainMessageHeaderInOutlook: '',
    };
}
