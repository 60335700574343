import { orchestrator } from 'satcheljs';
import { lazyResetPreviewPane } from 'owa-sxsdata';
import getSxSViewState from '../selectors/getSxSViewState';
import resetSxSViewStore from '../actions/resetSxSViewStore';

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(resetSxSViewStore, () => {
    lazyResetPreviewPane.import().then(resetPreviewPane => resetPreviewPane(getSxSViewState()));
});
