import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import { isReadingPanePositionOff } from 'owa-mail-layout';

// Reading pane should be opened in single line view if message was selected via keyboard enter,
// clicking on mail item body, clicking on the preview image/doc or via route handler.
export default function shouldOpenReadingPaneInSingleLineView(
    selectionSource: MailListItemSelectionSource
): boolean {
    return (
        isReadingPanePositionOff() &&
        (selectionSource == MailListItemSelectionSource.KeyboardEnter ||
            selectionSource == MailListItemSelectionSource.MailListItemBody ||
            selectionSource == MailListItemSelectionSource.MailListItemRichPreview ||
            selectionSource == MailListItemSelectionSource.RouteHandler)
    );
}
