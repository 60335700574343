import type { IOfficeFileLinkHandlingInfo, IOfficeLinkInfo } from 'flora-types';
import { GetOfficeFileLinkHandlingInfo } from './GetOfficeFileLinkHandlingInfo';
import { logUsage } from 'owa-analytics';

async function GetOfficeLinkInfo(url: string): Promise<IOfficeLinkInfo | undefined> {
    const officeFileLinkHandlingInfo: IOfficeFileLinkHandlingInfo | undefined =
        await GetOfficeFileLinkHandlingInfo();
    if (officeFileLinkHandlingInfo) {
        const officeLinkInfo: IOfficeLinkInfo =
            await officeFileLinkHandlingInfo.GetLinkOpenOptionInfo(
                url,
                'monarch' // appId
            );

        if (officeLinkInfo) {
            const isEligible: boolean = await officeLinkInfo.IsEligible();
            logUsage('GetOfficeLinkInfo', { isEligible });

            if (isEligible) {
                return officeLinkInfo;
            }
        }
    }

    return undefined;
}

export async function GetUrlToOpenUsingDefault(url: string): Promise<string | undefined> {
    const officeLinkInfo: IOfficeLinkInfo | undefined = await GetOfficeLinkInfo(url);
    if (officeLinkInfo) {
        const urlToOpenUsingDefault: string = await officeLinkInfo.UrlToOpenUsingDefault();
        const clickCorrelationId: string = await officeLinkInfo.LatestClickCorrelationId();
        logUsage('GetUrlToOpenUsingDefault', { clickCorrelationId });

        return urlToOpenUsingDefault;
    }

    return undefined;
}
