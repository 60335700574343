import { appBootstrap } from 'owa-app-module/lib/appBootstrap';
import { determineModule } from 'owa-workloads/lib/utils/determineModule';
import { moduleToConfigMap } from 'owa-bootstrap-configs';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import type { BootStrategies } from 'owa-shared-start-types';

// TODO VSO 82257: Explicitly call code to set up selected calendars in boot rather than relying on 'owa-calendar-module-selected-calendars-user-config' orchestrators
import 'owa-calendar-module-selected-calendars-user-config/lib/orchestrators';

export async function mailAppBootstrap(
    sessionPromise: Promise<SessionData | undefined>,
    strategies?: BootStrategies
) {
    const config = moduleToConfigMap[determineModule()];
    const options =
        typeof config.optionsSync == 'function'
            ? config.optionsSync(sessionPromise, strategies)
            : await config.options.importAndExecute(sessionPromise, strategies);
    return appBootstrap(options, moduleToConfigMap);
}
