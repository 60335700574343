export function debounce<TParams extends any[]>(
    func: (...args: TParams) => void,
    delay: number
): (...args: TParams) => void {
    let timeout: number;
    return (...args: TParams) => {
        clearTimeout(timeout);
        timeout = self.setTimeout(() => func.apply(null, args), delay);
    };
}
