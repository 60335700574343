import type FindControlViewState from 'owa-recipient-types/lib/types/FindControlViewState';
import SubstrateSearchScenario from 'owa-search-service/lib/data/schema/SubstrateSearchScenario';
import { isFeatureEnabled } from 'owa-feature-flags';

export default function is3SLoggingSupported(viewState: FindControlViewState) {
    const searchScenario = viewState.searchScenario;
    return (
        isFeatureEnabled('rp-3STelemetryEvents') &&
        searchScenario !== undefined &&
        searchScenario === SubstrateSearchScenario.MailCompose
    );
}
