import type { TableView, SelectionDirection } from 'owa-mail-list-store';
import { getNextRowIndexInternalHelper } from './getNextRowIndexInternalHelper';

/**
 * Get the index of the next row index to take action on (focus or selection)
 * @param tableView where the operation is being performed
 * @param selectionDirection direction of the selection
 * @param mailListItemSelectionSource selection mode for the maillist item
 * @return the next index to take action on, or null if no operation should be taken
 */
export default function getNextRowIndexInternal(
    tableView: TableView,
    selectionDirection: SelectionDirection
): number {
    const selectedRowKeys = [...tableView.selectedRowKeys.keys()];
    const tableRowKeys = tableView.rowKeys;
    let selectedIndex = 0;

    // No op if operating on empty table
    if (tableRowKeys.length === 0) {
        return 0;
    }

    // If there is a selection or focus, determine which row to take action on, otherwise choose the first item
    if (selectedRowKeys.length > 0 || tableView.focusedRowKey) {
        const rowKey = tableView.selectionAnchorRowKey || tableView.focusedRowKey || '';
        selectedIndex = tableRowKeys.indexOf(rowKey); // Current row in selection

        selectedIndex = getNextRowIndexInternalHelper(
            selectedIndex,
            selectionDirection,
            tableRowKeys
        );
    }
    return selectedIndex;
}
