import { action } from 'satcheljs';
import type { CalendarReadFormInitializationConfig } from 'owa-calendar-helpers-types/lib/CalendarReadFormInitializationConfig';

export const launchCalendarReadingPane = action(
    'launchCalendarReadingPane',
    (
        config: CalendarReadFormInitializationConfig,
        targetWindow?: Window,
        freezeDryId?: string
    ) => ({
        config,
        targetWindow,
        freezeDryId,
    })
);
