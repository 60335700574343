import { computed } from 'mobx';
import { useMemo } from 'react';

/**
 * @deprecated
 * Please do not use. Migrate existing usage to useComputedValue.
 *
 * The current implementation does not provide memoization, nor add mobx-reactivity. The current
 * implementation does not provide memoization, nor add mobx-reactivity. Migrate existing usage to
 * useComputedValue.Migrate existing usage to useComputedValue.
 *
 * Currently, this hook doesn't actually return an IComputedProperty, as I've been unable to get it to work
 * as expected when gettors reference non-observable values, such as component props.
 * As such, currently just returns a getter.
 * https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/63552
 */
export function useComputed<T>(getter: () => T): {
    get(): T;
} {
    return {
        get: getter,
    };
}

/**
 * Pass a "create" (`getter`) function and an array of dependencies. useComputedValue will
 * only recompute the memoized value when one of the dependencies (from either `deps` or a
 * mobx-observable being observed by `getter`) has changed. This optimization helps to avoid
 * expensive calculations on every render.
 *
 * @param getter The function used to compute the returned value.
 * @param deps Array of dependencies.
 * This array is not passed as arguments to getter.
 * Conceptually, though, that's what they represent: every value referenced inside
 * the function should also appear in the dependencies array.
 * @returns A memoized value.
 */
export function useComputedValue<T>(getter: () => T, deps: React.DependencyList): T {
    const computedValue = useMemo(() => computed(getter), deps);
    return computedValue.get();
}
