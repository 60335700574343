import type { OneNoteFeedPanelSource } from '../OneNoteFeedPanelSource';
import { action } from 'satcheljs';

/**
 * Opens OneNote Feed Panel
 */
export const openOneNoteFeedPanel = action(
    'openOneNoteFeedPanel',
    (source: OneNoteFeedPanelSource) => ({
        source,
    })
);
