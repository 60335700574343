export const OFFICENEUTRAL_LIGHT_THEME_PALETTE_DIFF_BASE = {
    headerBackground: '#F0F0F0',
    headerBackgroundSearch: '#F0F0F0',
    headerBrandText: '#0F6CBD',
    headerTextIcons: '#616161',
    headerSearchBoxBackground: '#FAFAFA',
    headerSearchBoxIcon: '#616161',
    headerSearchPlaceholderText: '#616161',
    headerSearchButtonBackground: '#0F6CBD',
    headerSearchButtonBackgroundHover: '#0C3B5E',
    headerButtonsBackground: '#F0F0F0',
    headerButtonsBackgroundHover: '#FAFAFA',
    headerButtonsBackgroundSearch: '#F0F0F0',
    headerButtonsBackgroundSearchHover: '#FAFAFA',
    optionalHeaderBadgeHoverText: '#616161',
    optionalHeaderBadgeHoverBackground: '#FAFAFA',
    optionalHeaderBadgePressedText: '#616161',
    optionalHeaderBadgePressedBackground: '#FAFAFA',
    optionalHeaderBadgeSelectedText: '#115EAE',
    optionalHeaderBadgeSelectedBackground: '#B4D6FA',
};
