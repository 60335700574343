export const OwaRolloutOverridesButtonID: string = 'owaRolloutOverridesButton';
export const OwaPremiumButtonID: string = 'owaPremiumButton';
export const OwaTimePanelButtonID: string = 'Time';
export const OwaSettingsButtonID: string = 'owaSettingsButton';
export const OwaFeedbackButtonID: string = 'owaFeedbackButton';
export const OwaDiagFeedbackButtonID: string = 'owaDiagFeedbackButton';
export const OwaWhatsNewButtonID: string = 'whatsnew';
export const OwaHelpButtonID: string = 'owahelp';
export const OwaSupportButtonID: string = 'owasupport';
export const OwaGetDiagnosticsButtonID: string = 'owagetdiagnostics';
export const OwaActivityFeedButtonID: string = 'owaActivityFeedButton';
export const OwaDiagnosticsButtonID: string = 'owaDiagnostics';
export const OwaNoteFeedButtonID: string = 'owaNoteFeedButton';
export const OwaMeetNowButtonID: string = 'owaMeetNowButton';
export const OwaCalendarCopilotButtonID: string = 'owaCalendarCopilotButton';
export const OwaChatCopilotButtonID: string = 'owaChatCopilotButton';
export const OwaTipsButtonID: string = 'tips';

export const OwaRolloutOverridesFlexPaneID: string = 'OwaRolloutOverrides';
export const OwaPremiumFlexPaneID: string = 'owaPremiumButton';
export const OwaTimePanelFlexPaneID: string = 'OwaTimePanel';
export const OwaGroupsPanelFlexPaneID: string = 'OwaGroupsPanel';
export const OwaSettingsFlexPaneID: string = 'OwaSettings';
export const OwaFeedbackFlexPaneID: string = 'OwaFeedback';
export const OwaDiagFeedbackFlexPaneID: string = 'OwaDiagFeedback';
export const OwaWhatsNewFlexPaneID: string = 'whatsNewPane';
export const OwaHelpFlexPaneID: string = 'OwaHelp';
export const OwaSupportFlexPaneID: string = 'OwaSupport';
export const OwaGetDiagnosticsFlexPaneID: string = 'OwaGetDiagnostics';
export const OwaActivityFeedFlexPaneID: string = 'OwaActivityFeed';
export const OwaDiagnosticsFlexPaneID: string = 'owaDiagnostics';
export const OwaExpressionFlexPaneID: string = 'Expressions';
export const OwaSkypeFlexPaneID: string = 'Skype';
export const OwaNoteFeedFlexPaneID: string = 'NoteFeed';
export const OwaCalendarCopilotPaneID: string = 'CalendarCopilot';
export const OwaChatCopilotFlexPaneID: string = 'ChatCopilot';
export const OwaDexFlexPaneId: string = 'DexPane';
export const OwaInfoPaneId: string = 'InfoPane';
export const OwaTipsFlexPaneID: string = 'TipsPane';
export const OwaDictationHelpFlexPaneID: string = 'DictationHelpPane';
export const OwaAddinsFlexPaneID: string = 'AddinsPane';
