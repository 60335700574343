import type { TypedTypePolicies } from 'owa-graph-schema-type-policies';
import { getSelectedApp } from 'owa-appbar-state';
import { createObservedTypePolicy } from 'owa-reactive-type-policies';
import { getPaletteAsRawColors } from 'owa-theme';

export function getM365ApplicationTypePolicies(): TypedTypePolicies {
    return {
        M365Application: {
            keyFields: ['id', 'platformType'],
            /* Necessary to avoid AppBar's cache object overwriting from subsequent MessageExtension queries.
             * Without this specification, cache objects would share the same key and would be mistakenly overwritten */
            fields: {
                isInUse: {
                    read(_existing, { readField }) {
                        const idField: string | undefined = readField('id');
                        return getSelectedApp() === idField;
                    },
                },
                brandingColor: createObservedTypePolicy(
                    'M365ApllicationTypePolicies',
                    () => getPaletteAsRawColors().themePrimary
                ),
            },
        },
        ViewData: {
            merge: true,
        },
    };
}
