import type { RibbonScalingStep } from '@1js/acui-ribbon-like';
import type { ClientItemId } from 'owa-client-ids';
import { dropLabel, moveToOverflowWell } from 'owa-command-ribbon';
import type { RibbonControlId } from 'owa-ribbon-ids';
import type { TabScalingAndLayoutConfig } from 'owa-mail-ribbon-store-shared-types';
import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { type LabelPreferenceId } from 'owa-mail-ribbon-store-shared-types/lib/util/labelPreferencesIds';
import { modifyIdsForCompose } from './modifyIdsForCompose';
import { modifyIdsForRead } from './modifyIdsForRead';
import { owaComputedFn } from 'owa-computed-fn';
import { type CommandingViewMode } from 'owa-outlook-service-option-store/lib/store/schema/options/CommandingOptions';
import { getRibbonViewMode } from 'owa-command-ribbon-store';

/**
 * Retrieves the initial scaling steps for a given tab.
 * @param tab what the store has saved as the tab configuration
 * @param controlsWithoutLabels the controls that should never have labels
 * @returns a series of RibbonScalingStep that represent the order in which the initial scaling
 * should occur
 */
export const getInitialScalingSteps = owaComputedFn(function getInitialScalingSteps(
    tab: TabScalingAndLayoutConfig,
    controlsWithoutLabels: RibbonControlId[],
    editorId: string | undefined,
    itemId: ClientItemId | undefined
): RibbonScalingStep[] {
    const initialScalingSteps: RibbonScalingStep[] = [];

    // Multiline Ribbon doesn't have an overflow
    if (getRibbonViewMode() !== 2) {
        initialScalingSteps.push(moveToOverflowWell(558));
        const itemIdList: (number | string)[] = itemId
            ? modifyIdsForRead(tab.controlsInOverflow, itemId)
            : modifyIdsForCompose(tab.controlsInOverflow, editorId);
        if (itemIdList.length !== 0) {
            initialScalingSteps.push(moveToOverflowWell(...itemIdList));
        }
    }

    /**
     * Hide controls without labels, UNLESS the user has customized to show all labels
     */
    if (controlsWithoutLabels.length !== 0 && tab.showLabelsPreference !== 0) {
        const itemIdList: (number | string)[] = itemId
            ? modifyIdsForRead(controlsWithoutLabels, itemId)
            : modifyIdsForCompose(controlsWithoutLabels, editorId);
        if (itemIdList.length !== 0) {
            initialScalingSteps.push(dropLabel(...itemIdList));
        }
    }

    /**
     * Default is all labels - except the labels listed above - are shown,
     * unless the user set the label preference to be never show
     */
    if (tab.showLabelsPreference === 2) {
        let allControlsInRibbon: RibbonControlId[] = [];

        tab.layout.forEach(
            /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
             * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
             *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
            group => (allControlsInRibbon = allControlsInRibbon.concat(...group.controlIds))
        );

        const itemIdList: (number | string)[] = itemId
            ? modifyIdsForRead(allControlsInRibbon, itemId)
            : modifyIdsForCompose(allControlsInRibbon, editorId);
        if (itemIdList.length !== 0) {
            initialScalingSteps.push(dropLabel(...itemIdList));
        }
    }

    return initialScalingSteps;
});
