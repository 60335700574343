import { action, mutator, orchestrator } from 'satcheljs';
import type { AppHostEntryPoint } from '../utils/telemetry';
import type { AppDefinition } from 'owa-graph-schema';
import type { MailboxInfo } from 'owa-client-types';
import getAppHostStore from '../store/appHostStore';
import { log } from '../utils/telemetry';

export type OpenMetaOsAppAction = {
    callerFn: AppHostEntryPoint;
    appDefinition: AppDefinition;
    entityId: string;
    subEntityId: string;
    subEntityLabel?: string;
    mailboxInfo?: MailboxInfo;
    userClickTime: number;
};

/* Do not call this action directly, use utils/openLaunchPageMetaOsApp instead */
const onOpenMetaOsApp = action(
    'onOpenMetaOsApp',
    ({
        callerFn,
        appDefinition,
        entityId,
        subEntityId,
        subEntityLabel,
        mailboxInfo,
        userClickTime,
    }: OpenMetaOsAppAction) => ({
        appDefinition,
        callerFn,
        entityId,
        subEntityId,
        subEntityLabel,
        mailboxInfo,
        userClickTime,
    })
);

mutator(onOpenMetaOsApp, (actionMessage: OpenMetaOsAppAction) => {
    const { appDefinition, entityId, subEntityId, subEntityLabel, userClickTime } = actionMessage;
    if (!appDefinition || !appDefinition.staticTabs || appDefinition.staticTabs.length === 0) {
        getAppHostStore().isInError = true;
        return;
    }

    getAppHostStore().hostedApp = appDefinition;
    getAppHostStore().selectedEntityId = entityId;
    getAppHostStore().selectedSubEntityId = subEntityId;
    getAppHostStore().userClickTime = userClickTime;
    if (!!subEntityLabel) {
        getAppHostStore().subEntityLabel = subEntityLabel;
    }
});

orchestrator(onOpenMetaOsApp, actionMessage => {
    const { appDefinition, callerFn, entityId, subEntityId, subEntityLabel } = actionMessage;
    const logData = {
        callerFn,
        entityId,
        subEntityId,
        subEntityLabel,
        appId: appDefinition.id,
        appName: appDefinition.name,
        appStaticTabs: JSON.stringify(appDefinition.staticTabs),
    };
    log(logData);
});

export default onOpenMetaOsApp;
