import { action } from 'satcheljs';
export type RemoveItemSource = any;

/**
 * Action to try delete an item from mailstore.items
 * Remark: This action should be used for all cases where we want to delete from the cache,
 * so that proper ref checking can be done before safely removing the item
 * @param itemIdToRemove - Id of item attempting to be removed
 */
export default action(
    'TRY_REMOVE_FROM_MAIL_STORE_ITEMS',
    (itemIdToRemove: string, removeItemSource: RemoveItemSource) => {
        return {
            itemIdToRemove,
            removeItemSource,
        };
    }
);
