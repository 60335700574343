export default function getMailboxType(contactType: string) {
    switch (contactType) {
        case 'PublicDistributionList':
            return 'PublicDL';
        case 'PersonalDistributionList':
            return 'PrivateDL';
        case 'UnifiedGroup':
            return 'GroupMailbox';
        case 'ImplicitGroup':
            return 'RecommendedGroup';
        case 'OrganizationUser':
            return 'InternalMailbox';
        case 'LinkedIn':
            return 'LinkedIn';
        // OrganizationContact is the M365 MailContact case,
        // that must map to Contact mailboxType,
        // for consistency with Item mailboxType Contact.
        case 'OrganizationContact':
            return 'Contact';
        case 'PersonalContact':
        case 'ImplicitContact':
        case 'Guest':
        case 'Unknown':
            return 'ExternalMailbox';
        default:
            return contactType;
    }
}
