import type SearchTableQuery from '../store/schema/SearchTableQuery';
import type { OwaDate } from 'owa-datetime';
import type { TableQuery } from 'owa-mail-list-store';
import { type TableQueryType } from 'owa-mail-list-store';
import type { ObservableMap } from 'mobx';
import type { PillSuggestion } from 'owa-search-service/lib/data/schema/SuggestionSet';
import type {
    SearchScope,
    SearchScopeForFolder,
} from 'owa-search-service/lib/data/schema/SearchScope';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import type { SearchProvider } from 'owa-search-service';
import type ViewFilter from 'owa-service/lib/contract/ViewFilter';
import type { SearchTableScenarioType } from '../store/schema/SearchTableQuery';
import type { SearchTab } from 'owa-search-types/lib/types/SearchTab';
import type { SearchScenarioId } from 'owa-search-store/lib/store/schema/SearchScenarioId';

/**
 * Creates the table query for search table.
 */
export default function createMailSearchTableQuery(
    searchScenarioId: SearchScenarioId,
    searchNumber: number,
    queryString: string,
    searchScope: SearchScope,
    selectedPillSuggestions: ObservableMap<string, PillSuggestion>,
    listViewType: ReactListViewType,
    includeAttachments: boolean,
    fromDate: OwaDate | undefined,
    toDate: OwaDate | undefined,
    searchProvider: SearchProvider,
    scenarioType: SearchTableScenarioType,
    appliedFilter?: ViewFilter,
    searchTab?: SearchTab
): TableQuery {
    const pillSuggestions = [...selectedPillSuggestions.values()];

    const searchTableQuery: SearchTableQuery = {
        searchScenarioId,
        folderId: (searchScope as SearchScopeForFolder).folderId,
        type: 1,
        listViewType,
        searchNumber,
        queryString,
        searchScope,
        pillSuggestions,
        includeAttachments,
        fromDate,
        toDate,
        scenarioType,
        lastIndexFetched: 0,
        searchProvider,
        viewFilter: appliedFilter ? appliedFilter : 'All',
        searchTab,
    };

    return searchTableQuery;
}
