import { getStore } from '../store/SharePointItemsViewNavigationLocationStore';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

export const getSharePointItemsViewCurrentPath = (groupSmtpAddress: string) => {
    groupSmtpAddress = transformGroupSmtp(groupSmtpAddress);
    const store = getStore();
    if (store.has(groupSmtpAddress)) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2532 (10,16): Object is possibly 'undefined'.
        // @ts-expect-error
        return store.get(groupSmtpAddress).currentPath;
    }

    return undefined;
};

export const getSharePointItemsViewTargetPath = (groupSmtpAddress: string) => {
    groupSmtpAddress = transformGroupSmtp(groupSmtpAddress);
    const store = getStore();
    if (store.has(groupSmtpAddress)) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2532 (23,16): Object is possibly 'undefined'.
        // @ts-expect-error
        return store.get(groupSmtpAddress).targetPath;
    }

    return undefined;
};
