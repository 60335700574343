import { AriaCoreDatapoint } from 'owa-analytics-core/lib/datapoints/AriaCoreDatapoint';
import type {
    AriaDatapointType,
    DatapointOptions,
    CustomDataMap,
    CustomData,
    UnifiedEvent,
} from 'owa-analytics-types';

export class AriaDatapoint extends AriaCoreDatapoint implements AriaDatapointType {
    private piiData?: string;
    unifiedTelemetry?: UnifiedEvent;

    constructor(
        eventName?: string,
        extraCustomData?: CustomData,
        options?: DatapointOptions,
        props?: CustomDataMap
    ) {
        super(eventName, extraCustomData, options, props);

        if (options?.unifiedTelemetry) {
            this.addUnifiedTelemetryData(options.unifiedTelemetry);
        }
    }

    public addPiiData(value: string) {
        if (!this.piiData) {
            this.piiData = value;
        }
    }

    public addUnifiedTelemetryData(event: UnifiedEvent) {
        this.unifiedTelemetry = event;
    }

    public getPiiData(): string | undefined {
        return this.piiData;
    }
}
