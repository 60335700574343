import { getSelectedTableView } from 'owa-mail-list-store';
import { resetFocus } from 'owa-mail-focus-manager';
import type { ActionSource } from 'owa-mail-store';
import { lazyAddSafeSenderFromTable } from 'owa-mail-triage-action';

export default function onNeverBlock(actionSource: ActionSource) {
    const tableView = getSelectedTableView();
    const rowKeys = [...tableView.selectedRowKeys.keys()];

    lazyAddSafeSenderFromTable.importAndExecute(rowKeys, tableView, actionSource);
    resetFocus('neverBlockCommand');
}
