import { getActiveContentTab } from 'owa-tab-store';
import getSxSIdFromProjection from './getSxSIdFromProjection';

export default function getActiveSxSId(targetWindow: Window): string | null {
    const sxsId =
        targetWindow === window
            ? getActiveContentTab()?.sxsId
            : getSxSIdFromProjection(targetWindow);
    return sxsId ?? null;
}
