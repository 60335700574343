import type EditorViewState from '../store/schema/EditorViewState';
import type { CompositeEditorViewState } from '../store/schema/CompositeEditorViewState';
import type { IEditor } from 'roosterjs-editor-types';

export interface ContentOperator {
    htmlEditor: IEditor | null;
    plainTextEditor: HTMLTextAreaElement | null;
}

const contentOperatorMap: {
    [editorId: string]: ContentOperator;
} = {};

export function addContentOperator(
    viewState: EditorViewState,
    htmlEditor: IEditor | null,
    plainTextEditor: HTMLTextAreaElement | null
) {
    // It is possible there is already content operator for this viewstate
    // in that case, the latest one wins.
    // TODO: (108089) We need to rethink about this, and we should not allow this happen
    contentOperatorMap[viewState.editorId] = {
        htmlEditor,
        plainTextEditor,
    };
}

export function removeContentOperator(
    viewState: EditorViewState,
    htmlEditor: IEditor | null,
    plainTextEditor: HTMLTextAreaElement | null
) {
    const operator = contentOperatorMap[viewState.editorId];

    if (operator) {
        if (operator.htmlEditor == htmlEditor) {
            operator.htmlEditor = null;
        }
        if (operator.plainTextEditor == plainTextEditor) {
            operator.plainTextEditor = null;
        }
        if (!operator.htmlEditor && !operator.plainTextEditor) {
            delete contentOperatorMap[viewState.editorId];
        }
    }
}

/**
 * Operate content using editor from its viewState.
 *
 * !!!    WARNING: Do NOT directly import this function outside owa-editor package    !!!
 * !!! This is only an internal function used for building APIs for content operation !!!
 *
 * @param viewState The EditorViewState to operate on
 * @param htmlCallback Callback for HTML editor (roosterjs)
 * @param plainTextCallback Callback for PlainText editor
 * @param alwaysInvokeCallback By default, callback won't be invoked if there isn't a correlated
 * editor. Setting this parameter to true to force invoke callback even editor doesn't exist.
 * When this is set to true, callback has the responsibility to do null check of editor instance.
 */
export default function operateContentInternal(
    viewState: EditorViewState,
    htmlCallback?: (editor: IEditor | null) => void,
    plainTextCallback?: (editor: HTMLTextAreaElement | null) => void,
    alwaysInvokeCallback?: boolean,
    contentOperatorOverride?: ContentOperator
) {
    if (viewState) {
        // bodyType doesn't exist in EditorViewState, so we treat undefined as HTML
        const bodyType = (<CompositeEditorViewState>viewState).bodyType;
        const isHTML = bodyType === undefined || bodyType === 'HTML';
        const isText = bodyType === 'Text';
        const { htmlEditor, plainTextEditor } =
            contentOperatorOverride || contentOperatorMap[viewState.editorId] || {};

        if (
            isHTML &&
            htmlCallback &&
            ((htmlEditor && !htmlEditor.isDisposed()) || alwaysInvokeCallback)
        ) {
            htmlCallback(htmlEditor);
        } else if (isText && plainTextCallback && (plainTextEditor || alwaysInvokeCallback)) {
            plainTextCallback(plainTextEditor);
        }
    }
}

export function operateHTMLContentInternal(
    editorId: string | null,
    htmlCallback: (editor: IEditor) => void
) {
    if (!!editorId) {
        const { htmlEditor } = contentOperatorMap[editorId] || {};

        if (htmlEditor && !htmlEditor.isDisposed()) {
            htmlCallback(htmlEditor);
        }
    }
}
