import type { RibbonId } from 'owa-ribbon-ids';
import type { IKeytipProps } from '@fluentui/react/lib/Keytip';
import { getKeytipString } from './ribbonKeytipMap';

/**
 * Keytip caching map to ensure each keytip gets generated only once.
 * @map_key string is a concatenation of the RibbonId, prefixIdSequence, and hasMenu.
 *   The reason for a concat is because our RibbonIds are  not unique enough to be used as a key, as
 *   a few RibbonIds may have differing prefixIdSequences, such as:
 *     With no tab, prefix is []
 *     With "Home" tab, prefix is ['h']
 *     With "Home" tab and within a flyout, prefix may be ['h', 'zr']
 *   Due to this, instead of calculating whether the prefixIdSequence[] has changed,
 *   we can just generate a new key for each case.
 *   The amount of cases is <2N where N is all keytips (as most keytips do not have differing prefixes).
 * @map_value IKeytipProps object
 */
const keytipMapCache = new Map<string, IKeytipProps>();

/**
 * Helper function to construct IKeytipProps object
 * @param prefixIdSequence Array of prefix RibbonIds that sequence before this keytip.
 *                         If none (i.e. top-level control, like a tab), then input empty array e.g. []
 *                         Otherwise, input all keytips before this one.
 *                         Example: [MailRibbonControlId.Tab_Home] would be a prefix for all controls within its tab,
 * @param ribbonId The ribbonId of the control that is using the final keytip.
 *                 Used to map the ribbonId to a string using `ribbonKeytipMap.ts`.
 *                 Also used for memoization purposes in `getComputedKeytip`.
 * @param hasMenu Whether this control has a dropdown menu associated with it.
 * @returns IKeytipProps object
 */
export function constructKeytip(
    prefixIdSequence: RibbonId[],
    ribbonId: RibbonId,
    hasMenu?: boolean
): IKeytipProps {
    hasMenu = !!hasMenu;
    const mapKey = `${ribbonId}-${prefixIdSequence.join('-')}-${hasMenu}`;

    if (!keytipMapCache.has(mapKey)) {
        const keytipContent = getKeytipString(ribbonId);
        keytipMapCache.set(mapKey, {
            content: keytipContent,
            keySequences: [
                ...prefixIdSequence.map(id => getKeytipString(id).toLocaleLowerCase()),
                keytipContent.toLocaleLowerCase(),
            ],
            hasMenu,
        });
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return keytipMapCache.get(mapKey)!;
}
