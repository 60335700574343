import {
    getSelectMailItemDatapoint,
    setSelectMailItemDatapoint,
} from './selectMailItemDatapointGetterAndSetter';
import { addTimingsAndEndDatapointOnRender } from 'owa-analytics-shared';

export default function endSelectMailItemDatapoint(
    contentType: string = '',
    shouldInvalidate?: boolean
) {
    const selectMailItemDp = getSelectMailItemDatapoint();
    if (selectMailItemDp && !selectMailItemDp.hasEnded) {
        if (shouldInvalidate) {
            selectMailItemDp.invalidate();
        } else {
            addContentTypeToCustomDataOfSmi(contentType);
            // Passing logOnly as false ends the datapoint after logging render end time if it has not already ended.
            addTimingsAndEndDatapointOnRender(selectMailItemDp, false /* logOnly */);
            setSelectMailItemDatapoint(undefined);
        }
    }
}

function addContentTypeToCustomDataOfSmi(contentType: string) {
    const selectMailItemDp = getSelectMailItemDatapoint();
    selectMailItemDp?.addCustomData({ contentType });
}
