import React from 'react';
import type { IRawStyle, IStyle } from '@fluentui/merge-styles';
import { ThemeProvider } from '@fluentui/react/lib/Theme';

export interface FluentIconStyleProviderProps {
    rootStyle?: IStyle;
    iconStyle?: IRawStyle;
    children: React.ReactNode;
}

export function FluentIconStyleProvider(props: FluentIconStyleProviderProps) {
    const theme = React.useMemo(() => {
        return {
            components: {
                OwaFluentIcon: {
                    styles: {
                        iconStyle: {
                            forcedColorAdjust: 'unset',
                            ...props.iconStyle,
                        },
                        root: props.rootStyle,
                    },
                },
            },
        };
    }, [props.rootStyle, props.iconStyle]);

    return (
        <ThemeProvider applyTo="none" theme={theme}>
            {props.children}
        </ThemeProvider>
    );
}

export { createIconComponent } from './createIconComponent';
export { createIconModule } from './createIconModule';
