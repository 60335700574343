function normalizeArgs(classes: string[]): string[] {
    return classes.flatMap(entry => entry?.split(/\s/));
}

export function addClass(element: Element, ...classes: string[]): void {
    element.classList.add(...normalizeArgs(classes));
}

export function removeClass(element: Element, ...classes: string[]): void {
    element.classList.remove(...normalizeArgs(classes));
}
