import { logUsage } from 'owa-analytics';

export const LOCKED_APPS = ['Mail', 'Calendar'];

export function isInvalidAppBarPinnedAppsSetting(callerFn: string, appBarPinnedApps: string) {
    const splitIds = appBarPinnedApps.split(',');
    if (
        containsDuplicateIds(splitIds) ||
        splitIds.length < 2 ||
        splitIds[0] !== LOCKED_APPS[0] ||
        splitIds[1] !== LOCKED_APPS[1] ||
        appBarPinnedApps.includes('undefined') ||
        appBarPinnedApps.includes('true') ||
        appBarPinnedApps.includes('false')
    ) {
        logUsage('AppBarPinnedApps_InvalidState', { caller: callerFn, value: appBarPinnedApps });
        return true;
    }
    return false;
}

function containsDuplicateIds(splitIds: string[]) {
    const uniqueIds = new Set(splitIds);
    return splitIds.length !== uniqueIds.size;
}
