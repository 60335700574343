import createAttachmentFiles from '../../utils/createAttachmentFiles';
import type { AttachmentDetails } from 'owa-addins-core';
import type EmailAddressWrapper from 'owa-service/lib/contract/EmailAddressWrapper';
import { lazyOpenCompose } from 'owa-mail-compose-actions';
import getSelectedFolderMailboxInfoOrDefault from 'owa-mail-store/lib/utils/getSelectedFolderMailboxInfoOrDefault';
import type { ComposeViewStateInitProps } from 'owa-mail-compose-store';
import { type ComposeOperation, type ComposeTarget } from 'owa-mail-compose-store';

export function displayNewMessageForm(
    toRecipients?: EmailAddressWrapper[],
    ccRecipients?: EmailAddressWrapper[],
    bccRecipients?: EmailAddressWrapper[],
    subject?: string,
    htmlBody?: string,
    attachments?: AttachmentDetails[]
) {
    const composeInitProps: ComposeViewStateInitProps = {
        mailboxInfo: getSelectedFolderMailboxInfoOrDefault(),
        operation: 0,
        bodyType: 'HTML',
        subject,
        newContent: htmlBody,
        to: toRecipients,
        cc: ccRecipients,
        bcc: bccRecipients,
        preferAsyncSend: true,
        attachmentFilesToUpload: createAttachmentFiles(attachments),
    };
    lazyOpenCompose.importAndExecute(composeInitProps, 2);
}
