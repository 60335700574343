import React from 'react';
import {
    subscribeToWindowEvent,
    unsubscribeToWindowEvent,
} from './internal/subscribeToWindowEvent';

// Reuses a single attached event handle for the resize event, rather than attaching
//  a new handler to the DOM for each component using this hook.
export function useWindowEvent<K extends keyof WindowEventMap>(
    eventName: K,
    handler: (event: WindowEventMap[K]) => void,
    scenario: string,
    deps?: React.DependencyList,
    targetWindow?: Window
): void {
    const handlerRef = React.useRef<(event: WindowEventMap[K]) => void>();
    const currentWindow = targetWindow ?? window;

    // Update the handler ref in every render
    handlerRef.current = handler;

    // Create a stable function that calls the latest handler
    const stableHandler = React.useCallback((event: WindowEventMap[K]) => {
        handlerRef.current?.(event);
    }, []);

    React.useEffect(() => {
        subscribeToWindowEvent(currentWindow, eventName, handlerRef, scenario, stableHandler);

        return () => unsubscribeToWindowEvent(currentWindow, eventName, handlerRef);
    }, [stableHandler, eventName, currentWindow, ...(deps || [])]);
}
