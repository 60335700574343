import { orchestrator } from 'satcheljs';
import changeTheme from '../actions/changeTheme';
import mutateTheme from '../mutators/mutateTheme';
import updateFabricTheme from './updateFabricTheme';

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(changeTheme, actionMessage => {
    mutateTheme(actionMessage.themeSymbols, actionMessage.isDarkTheme);
    updateFabricTheme();
});
