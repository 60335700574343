import type CalendarFolderTypeEnum from 'owa-service/lib/contract/CalendarFolderTypeEnum';
import type { LocalCalendarEntry } from 'owa-graph-schema';
import { isLinkedCalendarEntry } from './isLinkedCalendarEntry';

// We do not want to show 'Rename' and 'Remove' options for "well known" folders like default calendar and birthday calendar
// We will honor the server setting of IsRemovable to whether show or hide the option.
// For birthday and schedule, server sets the IsRemovable setting to true, hence we check their folder type.
// We don't want to show these for Group Mailbox Calendars either, and IsRemovable property doesn't exist on LinkedCalendarEntry,
// which is what we have for Group Calendars, we will make an explicit check for them.
export function canDeleteCalendar(calendarEntry: LocalCalendarEntry | undefined): boolean {
    return calendarEntry
        ? (calendarEntry.IsRemovable == null || calendarEntry.IsRemovable) &&
              (calendarEntry.CalendarFolderType == null || calendarEntry.CalendarFolderType != 3) &&
              !calendarEntry.IsGroupMailboxCalendar
        : false;
}

export function canRenameCalendar(calendarEntry: LocalCalendarEntry | undefined): boolean {
    return calendarEntry
        ? canDeleteCalendar(calendarEntry) &&
              !isLinkedCalendarEntry(calendarEntry) &&
              !calendarEntry.IsGroupMailboxCalendar
        : false;
}

export function canChangeCalendarColor(calendarEntry: LocalCalendarEntry | undefined): boolean {
    return calendarEntry ? !calendarEntry.IsGroupMailboxCalendar : false;
}

// calendarFolderId is only defined for LocalCalendarEntries, which are the only ones that can set charms
export function canChangeCalendarCharm(calendarEntry: LocalCalendarEntry | undefined): boolean {
    return calendarEntry && !calendarEntry.IsGroupMailboxCalendar
        ? calendarEntry.FolderId != undefined
        : false;
}

export function canEditCalendar(calendarEntry: LocalCalendarEntry | undefined): boolean {
    return calendarEntry
        ? canRenameCalendar(calendarEntry) ||
              canChangeCalendarColor(calendarEntry) ||
              canChangeCalendarCharm(calendarEntry)
        : false;
}
