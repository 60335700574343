import type RouteHandlers from './RouteHandlers';
import { getCurrentRoute } from './routerInstance';

export interface ProcessedRouteRecord {
    route: string[] | undefined;
    handlers: RouteHandlers | undefined;
    parameters: any;
}

const lastProcessedRoute: ProcessedRouteRecord = {
    route: ['no_route'],
    handlers: undefined,
    parameters: undefined,
};

/** Gets an array with the last processed route segments, including the vdir. */
export function getLastProcessedRoute() {
    return lastProcessedRoute;
}

export function updateLastProcessedRouteHandlers(handlers: any, parameters: any) {
    lastProcessedRoute.handlers = handlers;
    lastProcessedRoute.parameters = parameters;
}

export function resetLastProcessedRoute() {
    lastProcessedRoute.route = getCurrentRoute();
}
