const loggableTokenLength = 8;

export function getLoggableToken(token: string | undefined): string {
    if (token) {
        if (token.length <= loggableTokenLength) {
            return token;
        } else {
            return token.substring(token.length - loggableTokenLength);
        }
    }

    return '';
}
