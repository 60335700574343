import { AccountSourceType } from '../AccountSourceType';
import type { MailboxProvider } from 'owa-client-types';

const providerToSourceMap: Record<MailboxProvider, AccountSourceType> = {
    ['Office365']: AccountSourceType.Office365,
    ['Outlook']: AccountSourceType.OutlookDotCom,
    ['Google']: AccountSourceType.Google,
    ['Yahoo']: AccountSourceType.Yahoo,
    ['ICloud']: AccountSourceType.ICloud,
    ['IMAP']: AccountSourceType.IMAP,
    ['POP3']: AccountSourceType.POP3,
    ['PstFile']: AccountSourceType.PstFile,
    ['Unknown']: AccountSourceType.Other,
};

export default function providerToSource(provider: MailboxProvider): AccountSourceType {
    return providerToSourceMap[provider];
}
