import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "TeamsPolicies" */ './lazyIndex'),
    { name: 'TeamsPolicies' }
);

export const lazyGetTeamsPolicies = new LazyAction(lazyModule, m => m.getTeamsPolicies);

export const lazyIsTeamsChatEnabled = new LazyAction(lazyModule, m => m.isTeamsChatEnabled);

export const lazyIsTeamsMeetNowEnabled = new LazyAction(lazyModule, m => m.isTeamsMeetNowEnabled);

export const lazyIsPrivateMeetingSchedulingEnabled = new LazyAction(
    lazyModule,
    m => m.isPrivateMeetingSchedulingEnabled
);
