/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Make sure this isn't imported in the analytics worker
 *	> 'owa-feature-flags' import is restricted from being used. Feature flags have to be passed in the analytics worker initialization [analytics-worker-lint] */
import { isFeatureEnabled } from 'owa-feature-flags';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';

import { isDataWorkerPlatformSupported } from './isDataWorkerPlatformSupported';

export function isDataWorkerPlatformEnabled(): boolean {
    return isDataWorkerPlatformSupported() && isWorkerEnabledForMetaOSContext();
}

/**
 *  Meta OS context initialization
 *      import { app } from '@microsoft/teams-js';
 *
 *      app.initialize()
 *
 *  is not worker friendly at the moment (e.g., it has dependencies on 'window' being available).
 */
function isUsingMetaOsContext() {
    return (
        isHostAppFeatureEnabled('platformAppSdk') &&
        !isFeatureEnabled('platform-disableHostAppSpecificCustomizations')
    );
}

function isWorkerEnabledForMetaOSContext(): boolean {
    return !isUsingMetaOsContext() || isFeatureEnabled('platform-enableWorkerForMetaOSContext');
}
