import type FolderId from 'owa-service/lib/contract/FolderId';
import type SortDirection from 'owa-service/lib/contract/SortDirection';
import type ReadingPanePosition from 'owa-session-store/lib/store/schema/ReadingPanePosition';
import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';

import type { MailboxInfo } from 'owa-client-types';
import { MailSortHelper, type SortColumn } from 'owa-mail-list-store';

export enum FolderView {
    ConversationView = 1,
    DateView = 2,
    DateViewOnly = 3,
    ItemGroupView = 4,
    BundledView = 5,
}

export interface FolderViewState {
    FolderId: FolderId;
    View: FolderView;
    Width: number;
    SortOrder: SortDirection;
    SortColumn: SortColumn;
    ReadingPanePosition: ReadingPanePosition;
    IsExpanded: boolean;
    SearchScope: SearchRefinerScopeType;
    Color?: string;
}

export enum SearchRefinerScopeType {
    SearchRefinerScopeAll = 0,
    SearchRefinerScopePrimaryMailbox = 1,
    SearchRefinerScopeCurrentFolder = 2,
    SearchRefinerScopeCurrentFolderAndSubfolders = 3,
}

export function getFolderViewStatesFromUserConfig(mailboxInfo: MailboxInfo): {
    [key: string]: FolderViewState;
} {
    const folderViewStatesFromUserConfig =
        getAccountScopeUserSettings(mailboxInfo).ViewStateConfiguration?.FolderViewState || [];
    const parsedFolderViewStates: {
        [key: string]: FolderViewState;
    } = {};

    for (let i = 0; i < folderViewStatesFromUserConfig.length; i++) {
        const folderViewState = getParseFolderViewStateWithSortConversionToString(
            folderViewStatesFromUserConfig[i]
        );
        parsedFolderViewStates[folderViewState.FolderId.Id] = folderViewState;
    }

    return parsedFolderViewStates;
}

export function getFolderViewStateString(folderViewState: FolderViewState): string {
    return JSON.stringify(folderViewState, function (key, value) {
        if (
            key === 'SortOrder' &&
            (value === MailSortHelper.DESCENDING_SORT_DIR ||
                value === MailSortHelper.ASCENDING_SORT_DIR)
        ) {
            return value === MailSortHelper.DESCENDING_SORT_DIR ? 1 : 0;
        }

        return value;
    });
}

export function getParseFolderViewStateWithSortConversionToString(
    folderViewStateRaw: string
): FolderViewState {
    // The SortOrder is a enum flag - /sources/dev/store/src/Microsoft.Exchange.Mapi/sortorder.cs
    // where 0 is Ascending and 1 is Descending, so convert to client SortDirection type
    return JSON.parse(folderViewStateRaw, function (key, value) {
        if (key === 'SortOrder' && (value === 1 || value === 0)) {
            return value === 1
                ? MailSortHelper.DESCENDING_SORT_DIR
                : MailSortHelper.ASCENDING_SORT_DIR;
        }

        return value;
    }) as FolderViewState;
}
