import { action } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';

export default action(
    'INITIALIZE_NOTIFICATIONS_PANE_FOR_ACCOUNT',
    (isOpx: boolean, mailboxInfo: MailboxInfo, callback?: () => void) => ({
        isOpx,
        mailboxInfo,
        callback,
    })
);
