import { action } from 'satcheljs';
import type FolderTreeLoadStateEnum from '../store/schema/FolderTreeLoadStateEnum';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Action to set loading state for folder tree in particular mailbox.
 */
export const setLoadingStateForFolderTree = action(
    'SET_LOADING_STATE_FOR_FOLDER_TREE',
    (folderTreeId: string, loadingState: FolderTreeLoadStateEnum, mailboxInfo: MailboxInfo) => ({
        folderTreeId,
        loadingState,
        mailboxInfo,
    })
);
