import { getStore, timeZoneRangeLoadState } from '../store';
import type { TimeZoneRange } from '../TimeZoneRange';
import cleanTimeZoneId from './cleanTimeZoneId';
import type { TraceErrorObject } from 'owa-trace';

const millisecondsInTwoHours = 7200000;
let errorReported = false;

/**
 * Find the offset of the given time, in the given time zone.
 *
 * @param date The date to lookup.
 * @param timeZone Time zone to search. Defaults to the user's primary time zone.
 * @param localDate `true` if the date is a local to the respective time zone. Defaults to `false`.
 *
 * NOTE: OWA returns negative numbers for timezones that are behind UTC. Ex: PST is -480.
 */
export default function getTimeZoneOffset(
    date: number | Date,
    timeZone?: string,
    localDate?: boolean
): number {
    return getTimeZoneOffsetInternal(date, timeZone, localDate, false).offset;
}

export function getTimeZoneOffsetWithDstHint(
    date: number | Date,
    timeZone?: string,
    localDate?: boolean
): {
    offset: number;
    isDstHour: boolean;
} {
    return getTimeZoneOffsetInternal(date, timeZone, localDate, true);
}

export function getTimeZoneOffsetInternal(
    date: number | Date,
    timeZone?: string,
    localDate?: boolean,
    provideDstBoundaryHint?: boolean
): {
    offset: number;
    isDstHour: boolean;
} {
    timeZone = cleanTimeZoneId(timeZone);

    // Shortcut for tests, bootstrap and min/max UTC ranges.
    if (timeZone == 'UTC') {
        return { offset: 0, isDstHour: false };
    }
    const ranges = getTimeZoneRanges(timeZone);
    const value = date.valueOf();
    const startProp: keyof TimeZoneRange = localDate ? 'localStart' : 'start';
    const endProp: keyof TimeZoneRange = localDate ? 'localEnd' : 'end';

    for (const range of ranges) {
        if (value >= range[startProp] && value < range[endProp]) {
            if (
                (provideDstBoundaryHint && value >= range[endProp] - millisecondsInTwoHours) ||
                value <= range[startProp] + millisecondsInTwoHours
            ) {
                return { offset: range.offset, isDstHour: true };
            }

            return { offset: range.offset, isDstHour: false };
        }
    }

    const error: TraceErrorObject = new Error('Offset not found in time zone ranges');
    throw throttleError(
        error,
        `Offset not found for ${date.toString()} in time zone ${timeZone}. Ranges: ${JSON.stringify(
            ranges
        )}`
    );
}

function getTimeZoneRanges(timeZone: string) {
    const store = getStore();
    const ranges = store.TimeZoneRanges[timeZone];
    if (!ranges) {
        const error: TraceErrorObject = new Error('Requested time zone is not available');
        throw throttleError(
            error,
            `Time zone '${timeZone}' is not available. TimeZoneRangeLoadState: ${timeZoneRangeLoadState}, Range keys: ${JSON.stringify(
                Object.keys(store.TimeZoneRanges)
            )}`
        );
    }
    return ranges;
}

/**
 * Returns an error which will block subsequent telemetry reports for 2.5 seconds
 * This prevents scenarios where a user hits this a bunch of times in a short period
 * and each instance is reported to telemetry.
 *
 * @param errorMessage
 * @param diagnosticInfo
 * @returns
 */
function throttleError(error: TraceErrorObject, diagnosticInfo: string) {
    error.reported = errorReported;

    if (!errorReported) {
        errorReported = true;

        setTimeout(() => {
            errorReported = false;
        }, 2500);
    }

    error.additionalInfo = { diagnosticInfo };

    return error;
}
