import type IdentityKey from './IdentityKey';
import type { LicensingMailboxInfo } from 'owa-account-source-list-types';
import getIdentityKeyForIdentityInfo from './getIdentityKeyForIdentityInfo';
import { getIdentityInfoForLicensingMailboxInfo } from './getIdentityInfoForMailboxInfo';

/**
 * Gets the IdentityKey for the specified LicensingMailboxInfo
 * @param licensingMailboxInfo LicensingMailboxInfo for which the IdentityKey is to be obtained
 * @returns IdentityKey
 */
export default function getIdentityKeyForLicensingMailboxInfo(
    licensingMailboxInfo: LicensingMailboxInfo
): IdentityKey {
    return getIdentityKeyForIdentityInfo(
        getIdentityInfoForLicensingMailboxInfo(licensingMailboxInfo)
    );
}
