import { mutatorAction } from 'satcheljs';
import { getStore } from '../store/store';
import type { MailboxInfo } from 'owa-client-types';

export default mutatorAction(
    'SET_IS_SHARED_MAILBOX',
    (isSharedMailbox: boolean, mailboxInfo: MailboxInfo) => {
        getStore(mailboxInfo).initialized = true;
        getStore(mailboxInfo).isSharedMailbox = isSharedMailbox;
    }
);
