import type ItemId from 'owa-service/lib/contract/ItemId';

export const getReadRibbonId = (baseId: number | string, itemId?: ItemId): number | string => {
    return itemId ? `${baseId}_${itemId.Id}` : baseId;
};

export const getReadRibbonBaseId = (id: string | number): string | number => {
    if (typeof id === 'string') {
        const split = id.split('_');
        return split[0];
    } else {
        return id;
    }
};
