import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';

const setAccountConfigurationLogs: {
    fIsGsa: boolean; // forceIsGlobalAccountMailboxInfoResult
    isGsa: boolean; // isGlobalSettingsMailbox
    tDomain?: string;
    orgDomain?: string;
    mbguid?: string;
    pae?: boolean; // personalAccountsEnabled
    aae?: boolean; // additionalAccountsEnabled
    csae?: boolean; // changeSettingsAccountEnabled
    aoad?: string[]; // allowedOrganizationAccountDomains
    tset: string; // timeUserConfigSet
    src?: string; // source
    srcHistory?: string; // sourceHistory
}[] = [];

export function addSetAccountConfigurationLog(
    forceIsGlobalAccountMailboxInfoResult: boolean,
    isGlobalSettingsMailbox: boolean,
    userConfiguration: OwaUserConfiguration
) {
    setAccountConfigurationLogs.push({
        fIsGsa: forceIsGlobalAccountMailboxInfoResult,
        isGsa: isGlobalSettingsMailbox,
        tDomain: userConfiguration.SessionSettings?.TenantDomain,
        orgDomain: userConfiguration.SessionSettings?.OrganizationDomain,
        mbguid: userConfiguration.SessionSettings?.MailboxGuid,
        pae: userConfiguration.PolicySettings?.PersonalAccountsEnabled,
        aae: userConfiguration.PolicySettings?.AdditionalAccountsEnabled,
        csae: userConfiguration.PolicySettings?.ChangeSettingsAccountEnabled,
        tset: new Date().toISOString(),
        src: userConfiguration.PolicySettings?.Source,
        srcHistory: userConfiguration.PolicySettings?.SourceHistory,
        aoad: userConfiguration.PolicySettings?.AllowedOrganizationAccountDomains,
    });
}

export function getSetAccountConfigurationLogs() {
    return setAccountConfigurationLogs;
}
