import isTeamsProCustomizationUser from './isTeamsProCustomizationUser';
import isTeamsProManagementUser from './isTeamsProManagementUser';
import isTeamsProProtectionUser from './isTeamsProProtectionUser ';
import isTeamsProVirtualAppointmentsUser from './isTeamsProVirtualAppointmentsUser';
import isTeamsProWebinarUser from './isTeamsProWebinarUser';
import type { MailboxInfo } from 'owa-client-types';

function isTeamsPremiumUser(mailboxInfo?: MailboxInfo) {
    return (
        isTeamsProCustomizationUser(mailboxInfo) ||
        isTeamsProManagementUser(mailboxInfo) ||
        isTeamsProProtectionUser(mailboxInfo) ||
        isTeamsProVirtualAppointmentsUser(mailboxInfo) ||
        isTeamsProWebinarUser(mailboxInfo)
    );
}

export default isTeamsPremiumUser;
