import { getSelectedNode } from 'owa-mail-folder-forest-store';
import type { FolderForestTreeType } from 'owa-graph-schema';
import type { MailboxInfo } from 'owa-client-types';
import type { GroupForestNode } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';

export default function isGroupNodeSelected(
    groupId: string,
    treeType: FolderForestTreeType,
    mailboxInfo: MailboxInfo
): boolean {
    const selectedNode = getSelectedNode() as GroupForestNode;
    return (
        selectedNode?.id !== undefined &&
        selectedNode.mailboxInfo == mailboxInfo &&
        selectedNode.treeType === treeType &&
        selectedNode.type === 2 &&
        groupId?.toLowerCase() === selectedNode?.id?.toLowerCase()
    );
}
