import viewStateStore from '../store/store';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';

export function getShouldOverrideToSortBySizeForMailFolder(): boolean {
    return (
        !!viewStateStore?.shouldOverrideToSortBySizeBasedOnURL &&
        isConsumer(undefined, getModuleContextMailboxInfo())
    );
}
