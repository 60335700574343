import { type MailboxInfo } from 'owa-client-types';
import copilotSettingsStore from '../store/store';
import type { AddinSettings, ImplementedCopilotFeatures } from '../store/store';

export function getAddinSettingsForScenario(
    scenario: ImplementedCopilotFeatures,
    mailboxInfo: MailboxInfo
): AddinSettings | undefined {
    const store = copilotSettingsStore(mailboxInfo);
    if (!!store && store.isEnabled) {
        return store.extensibility.scenarioToAddinSettingsMap[scenario];
    }
    return undefined;
}
