import { getTokenScheme } from './getTokenScheme';
import { BEARER_SCHEME } from './constants';

export function getTokenFromPrefixedToken(prefixedToken: string): string | undefined {
    if (prefixedToken) {
        const tokenParts = prefixedToken.split(' ');
        const scheme = getTokenScheme(tokenParts);

        return scheme == BEARER_SCHEME ? tokenParts[1] : prefixedToken;
    }

    return undefined;
}
