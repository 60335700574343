import { action, mutator } from 'satcheljs';
import type { FlexPaneState } from '../store/store';
import { getStore } from '../store/store';
import { DEFAULT_FLEXPANE_WIDTH } from '../utils/constants';

export const setIsFlexPaneExpanded = action(
    'setIsFlexPaneExpanded',
    (flexPaneId: string, isExpanded: boolean, expandedWidth?: number) => ({
        flexPaneId,
        isExpanded,
        expandedWidth,
    })
);

/**
 * Mutator to set the expanded state of a flex pane.
 * Saves to the FlexPaneState map to cache the expanded state for the scenario
 * where a user closes this pane and reopens it later.
 */
export const setIsFlexPaneExpandedMutator = mutator(setIsFlexPaneExpanded, actionMessage => {
    let flexPaneState: FlexPaneState | undefined = getStore().flexPaneStateMap.get(
        actionMessage.flexPaneId
    );

    // Update FlexPaneState if it exists, otherwise create one
    if (flexPaneState) {
        flexPaneState.isExpanded = actionMessage.isExpanded;
        flexPaneState.expandedWidth = actionMessage.expandedWidth ?? DEFAULT_FLEXPANE_WIDTH;
    } else {
        flexPaneState = {
            isShown: true, // Currently, must be opened pane in order to be expanded
            isExpanded: actionMessage.isExpanded,
            expandedWidth: actionMessage.expandedWidth ?? DEFAULT_FLEXPANE_WIDTH,
        };
        getStore().flexPaneStateMap.set(actionMessage.flexPaneId, flexPaneState);
    }

    getStore().currentFlexPaneState = flexPaneState;
});
