import type SurfaceActionIcons from './SurfaceActionIcons';

export type SurfaceActionIconsSubset = {
    [icon in SurfaceActionIcons]: string;
};

export const SurfaceActionIconsDefinitions: SurfaceActionIconsSubset = {
    InsertSignatureLine: '\uF677',
    ArrangeByFrom: '\uF678',
    Phishing: '\uF679',
    CreateMailRule: '\uF67A',
    Encryption: '\uF69D',
    ReadOutLoud: '\uF112',
};

export default {
    fontFileName: 'surfaceActionIcons',
    icons: SurfaceActionIconsDefinitions,
};
