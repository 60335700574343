export enum FeedActionType {
    None,
    PromptUpdateNativeHost,
    PromptLoadServiceRequestFailure,
}

export interface FeedAction {
    promptFeedAction: boolean;
    type: FeedActionType;
    feedActionCallback(): void;
}

export function feedAction(): FeedAction {
    return {
        promptFeedAction: false,
        type: FeedActionType.None,
        feedActionCallback: () => {},
    };
}
