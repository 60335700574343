import { getQueryStringParameters } from 'owa-querystring';
import { getCookie } from './universalCookies';
import { getWindowData } from 'owa-window-data';
import { findMetatag } from 'owa-metatags';

let shouldUseMetatagValue: boolean | undefined = undefined;
function shouldUseMetatag() {
    if (shouldUseMetatagValue === undefined) {
        const locationObj = getWindowData().location;
        shouldUseMetatagValue = /\/(bookwithme|findtime|book)/i.test(
            locationObj.pathname + locationObj.search
        );
    }
    return shouldUseMetatagValue;
}

/**
 * By default, this uses the `DefaultAnchorMailbox` cookie, but can optionally use a metatag
 * hint from `owa-web-server` instead.
 *
 * In order to opt into using this metatag hint, the regex in this file must be updated.
 * This regex is used so that checks don't need to be placed throughout the boot process and
 * the apps themselves.
 *
 * _Note: In order to use a metatag hint, the controller in `owa-web-server` **must** fill
 * out the metatag._
 * @returns {boolean} Whether the current user is anonymous
 */
export function isAnonymousUser() {
    const defaultAnchorMailbox = getCookie('DefaultAnchorMailbox');
    if (!shouldUseMetatag()) {
        return (
            defaultAnchorMailbox == null ||
            defaultAnchorMailbox.trim().length == 0 ||
            hasAnonymousRetryParam()
        );
    } else {
        // Temporarily fall back to `defaultAnchorMailbox` while it isn't being filled by owa-web-server.
        return (
            (findMetatag('owaIsAuthenticated')?.toLowerCase() != 'true' &&
                (defaultAnchorMailbox == null || defaultAnchorMailbox.trim().length == 0)) ||
            hasAnonymousRetryParam()
        );
    }
}

export function hasAnonymousParam() {
    const queryStringValues = getQueryStringParameters(getWindowData().location);
    return Object.keys(queryStringValues).indexOf('anonymous') >= 0;
}

export function hasAnonymousRetryParam() {
    const queryStringValues = getQueryStringParameters(getWindowData().location);
    return Object.keys(queryStringValues).indexOf('anonRetry') >= 0;
}
