import type { NovaEvent } from '@nova/types';
import type { AppHostServiceEventOriginator } from './originator';
import type { MailboxInfo } from 'owa-client-types';

export const ExportContactsEventType = 'exportContacts' as const;

export interface ExportContactsEvent extends NovaEvent<ExportContactsEventData> {
    originator: typeof AppHostServiceEventOriginator;
    type: typeof ExportContactsEventType;
    data: () => ExportContactsEventData;
}

export interface ExportContactsEventData {
    mailboxInfo: MailboxInfo;
}
