import { getUserConfiguration } from 'owa-session-store';
import { getStore } from '../store/store';

export default function isTabbedSearchEnabled(isFromQfScopeTabs: boolean): boolean {
    // Suppress SERP tabs if the store flag is set and the check is not from QF scope tabs
    if (getStore().shouldSuppressTabs && !isFromQfScopeTabs) {
        return false;
    }
    // Tabbed search of any type is not supported in explicit logon scenarios
    return !getUserConfiguration()?.SessionSettings?.IsExplicitLogon;
}
