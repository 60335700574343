import type { AriaDatapointType } from 'owa-analytics-types';
import type { TraceErrorObject } from 'owa-trace';
import { getServerErrorName } from '../utils/getServerErrorName';
import { scrubForPii } from 'owa-config/lib/scrubForPii';
import { getActionMetadata } from '../utils/getActionMetadata';

export function addErrorToDatapoint(
    datapoint: AriaDatapointType,
    error: TraceErrorObject | string
): string | undefined {
    let errorMessage: string | undefined;
    if (error) {
        if (typeof error == 'string') {
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Error constructor names can only be a string literals.
             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
            error = new Error(error);
        }
        errorMessage = error.message;
        let errorDetails = error.stack;
        const errorResponse = error.response;

        if (errorResponse) {
            errorMessage = getServerErrorName(errorResponse) ?? undefined;
            errorDetails = `Server InnerException: ${getServerInnerError(
                errorResponse
            )}; client stack: ${error.stack}`;

            addErrorHeaders(datapoint, errorResponse.headers);
            datapoint.getProperties().ErrorRequestUrl = scrubForPii(errorResponse.url);
        } else if (error.headers) {
            addErrorHeaders(datapoint, error.headers);
        }

        const responseCode = error.responseCode;
        if (responseCode) {
            datapoint.addCustomProperty('ResponseCode', responseCode);
        }

        if (error.fetchErrorType) {
            datapoint.addCustomProperty('FetchErrorType', error.fetchErrorType);
        }
        if (typeof error.retriable == 'boolean') {
            datapoint.addCustomProperty('Retriable', error.retriable);
        }

        let diagnosticInfo = JSON.stringify({
            ...error.additionalInfo,
            diagnosticInfo: error.diagnosticInfo || '',
        });

        const { stack } = getActionMetadata();
        if (stack.length > 0) {
            diagnosticInfo += '|' + stack;
        }
        datapoint.addCustomProperty('Diagnostics', scrubForPii(diagnosticInfo));

        if (typeof error.httpStatus == 'number') {
            datapoint.addCustomProperty('ErrorStatusCode', error.httpStatus);
        }
        datapoint.getProperties().ErrorMessage = scrubForPii(errorMessage);
        if (errorDetails) {
            datapoint.getProperties().ErrorDetails = scrubForPii(errorDetails);
        }
    }
    return errorMessage;
}

function addErrorHeaders(datapoint: AriaDatapointType, headers: Headers) {
    if (headers && typeof headers.get == 'function') {
        datapoint.addCustomProperty('FEServer', headers.get('x-feserver'));
        datapoint.addCustomProperty('BEServer', headers.get('x-beserver'));
        datapoint.addCustomProperty('BEStatus', headers.get('x-backendhttpstatus'));
        datapoint.addCustomProperty('ServerVersion', headers.get('x-owa-version'));
        datapoint.addCustomProperty('ErrorRequestId', headers.get('request-id'));
        datapoint.addCustomProperty('ErrorAFDRef', headers.get('x-msedge-ref'));
        datapoint.addCustomProperty('ErrorMessageId', headers.get('x-owaerrormessageid'));
    }
}

function getServerInnerError(errorResponse: Response) {
    return errorResponse?.headers && errorResponse.headers.get('x-innerexception');
}
