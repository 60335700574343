import { mutatorAction } from 'satcheljs';
import getListViewState from '../selectors/getListViewState';

export const updateStartAndEndIndicesInVLV = mutatorAction(
    'updateStartAndEndIndicesInVLV',
    (start: number, end: number) => {
        const listViewState = getListViewState();

        // Subtracting both of these by 1 to account for the pre-rows component which takes index 0 in the VLV.
        // We want these indices to match the indices of the rows in the tableView.rowKeys.
        listViewState.startIndexVLV = start - 1;
        listViewState.endIndexVLV = end - 1;
    }
);
