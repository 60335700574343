import getTabIdFromProjection from 'owa-popout-v2/lib/utils/getTabIdFromProjection';
import { getStore } from '../store/Store';
import isDeepLink from 'owa-url/lib/isDeepLink';

export function getTabIdFromTargetWindow(targetWindow?: Window): string {
    if (isDeepLink()) {
        return getStore().deeplinkId?.id;
    }

    const tabId = targetWindow ? getTabIdFromProjection(targetWindow) : 'mainWindow';

    return tabId ?? 'mainWindow';
}
