import { format } from 'owa-localize';

/**
 * Generates the  mailbox smtp address
 * @param mailboxGuid - mailbox guid
 * @param orgDomain - domain of the user's organization
 * @returns the mailbox header
 */
export function generateMailboxSmtpAddress(mailboxGuid?: string, orgDomain?: string): string {
    if (!mailboxGuid || !orgDomain) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (51,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        return null;
    }
    const mailboxHeaderFormat = '{0}@{1}';
    return format(mailboxHeaderFormat, mailboxGuid, orgDomain);
}
