import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';

export interface AppPaneUnderlayViewState {
    isShown: Map<string, boolean>;
    shrinkable: Map<string, boolean | undefined>;
    isPlaceholder: Map<string, boolean | undefined>;
}

const appPaneUnderlayViewStateStore = createStore<AppPaneUnderlayViewState>(
    'appPaneUnderlayViewState',
    {
        isShown: new ObservableMap(),
        shrinkable: new ObservableMap(),
        isPlaceholder: new ObservableMap(),
    }
)();

const getAppPaneUnderlayViewStateStore = () => appPaneUnderlayViewStateStore;
export default getAppPaneUnderlayViewStateStore;
