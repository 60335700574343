import { type QuickStepHotkeyId } from 'owa-quick-step-types';
import Strings from './getHotkeyDropdownOptions.locstring.json';
import loc from 'owa-localize';

export function getQuickStepHotkeyText(hotkey: QuickStepHotkeyId | undefined) {
    switch (hotkey) {
        case 0:
            return loc(Strings.hotkeyFive);
        case 1:
            return loc(Strings.hotkeySix);
        case 2:
            return loc(Strings.hotkeySeven);
        case 3:
            return loc(Strings.hotkeyEight);
        case 4:
            return loc(Strings.hotkeyNine);
        default:
            return undefined;
    }
}
