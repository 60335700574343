import { isFeatureEnabled } from 'owa-feature-flags';
import getIsCurrentThemeModern from './getIsCurrentThemeModern';
import getIsCurrentThemeFullBleed from './getIsCurrentThemeFullBleed';

export default function getIsMicaEffectEnabled(): boolean {
    if (isFeatureEnabled('mon-transparency-usingSystemBackdrop')) {
        return getIsCurrentThemeModern() || getIsCurrentThemeFullBleed(); // theme check
    } else {
        return getIsCurrentThemeFullBleed();
    }
}
