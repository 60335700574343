import type { CommandingOptions } from 'owa-outlook-service-option-store';
import { lazyCreateOrUpdateOptionsForFeature } from 'owa-outlook-service-options';
import { getOptionsForFeature, OwsOptionsFeatureType } from 'owa-outlook-service-option-store';
import { getCommandingOptionsFromServer } from '../selectors/getCommandingOptionsFromServer';

export async function saveCommandingOptions(options: Partial<CommandingOptions>) {
    const serverOptions =
        (await getCommandingOptionsFromServer()) ||
        getOptionsForFeature<CommandingOptions>(OwsOptionsFeatureType.Commanding);
    const mergedOptions: CommandingOptions = {
        ...serverOptions,
        ...options,
    };
    lazyCreateOrUpdateOptionsForFeature.importAndExecute(
        OwsOptionsFeatureType.Commanding,
        mergedOptions
    );
}
