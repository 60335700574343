import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';
import { isMOSLaunchPagesEnabled } from './isMOSLaunchPagesEnabled';

/**
 * OneDrive MetaOS app is enabled if mos-oneDriveApp feature flag is enabled and MOS launch pages are enabled.
 */
export function isOneDriveMetaOsEnabled(mailboxInfo?: MailboxInfo) {
    return isFeatureEnabled('mos-oneDriveApp', mailboxInfo) && isMOSLaunchPagesEnabled(mailboxInfo);
}

export function isOneDriveMetaOsConsumerEnabled(mailboxInfo?: MailboxInfo) {
    return (
        isFeatureEnabled('mos-oneDriveApp-consumer', mailboxInfo) &&
        isMOSLaunchPagesEnabled(mailboxInfo)
    );
}
