import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';
import type { NotificationSettingsSchema } from './schema';

// The 'notif-settings-new' flight uses store instead of the multiAccountStore
const store = createStore<NotificationSettingsSchema>('desktopNotificationsSettingsStore', {
    desktopNotificationSettings: new ObservableMap(),
})();

export const getStore = () => store;
