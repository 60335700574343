import React from 'react';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Avoid deprecated APIs, use portals instead of rendering to a new root
 *	> 'unmountComponentAtNode' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root
 *	> 'render' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root */
import { unmountComponentAtNode, render } from 'owa-react-dom';
import { ErrorBoundary, ErrorComponentType } from 'owa-error-boundary';
import { WindowProvider } from '@fluentui/react/lib/WindowProvider';

export interface OwaModalProps<T> {
    onDismiss: (t: T) => void;
}

let modalId = 0;

/**
 * Show a modal dialog and get a value when it closes.
 *
 * If the dialog is closed from outside (via the returned dismiss function), the Promise rejects.
 *
 * @param Modal The constructor for the modal dialog to show
 *
 * @returns A tuple of [Promise that resolves on dialog close with dialog return value, dismiss function]
 */
export function showModal<T>(
    Modal: React.ComponentType<OwaModalProps<T>>,
    targetWindow?: Window
): [Promise<T>, () => void] {
    const document = (targetWindow || window).document;
    const modalHost = document.createElement('div');
    modalHost.id = `owa-modal-${modalId++}`;
    document.body.appendChild(modalHost);

    let dismissModalCalled = false;
    const dismissModal = () => {
        if (dismissModalCalled) {
            return;
        }
        dismissModalCalled = true;
        unmountComponentAtNode(modalHost);
        document.body.removeChild(modalHost);
    };

    let modalReject: () => void;

    const modalPromise = new Promise<T>((resolve, reject) => {
        modalReject = reject;

        render(
            <WindowProvider window={targetWindow || window}>
                <ErrorBoundary
                    type={ErrorComponentType.None}
                    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
                     * Baseline, please do not copy and paste this justification
                     *	> JSX attribute values should not contain functions created in the same scope */
                    onError={() => {
                        dismissModal();

                        reject(new Error('Unidentified error in showModal.tsx'));
                    }}
                >
                    <Modal
                        /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
                         * Baseline, please do not copy and paste this justification
                         *	> JSX attribute values should not contain functions created in the same scope */
                        onDismiss={t => {
                            dismissModal();
                            resolve(t);
                        }}
                    />
                </ErrorBoundary>
            </WindowProvider>,
            modalHost
        );
    });

    return [
        modalPromise,
        () => {
            dismissModal();
            modalReject();
        },
    ];
}
