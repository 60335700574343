import { hasComposeMessageExtension } from 'owa-m365-acquisitions/lib/utils/hasMessageExtension';
import {
    hasExchangeAddInComposeCommand,
    hasExchangeAddInCalendarComposeCommand,
    hasExchangeAddInReadCommand,
    hasExchangeAddInCalendarReadCommand,
    hasExchangeAddInSharedFolderComposeCommand,
    hasExchangeAddInSharedFolderReadCommand,
    hasExchangeAddInMultiSelectCommand,
    hasExchangeAddinNoContextCommand,
} from 'owa-m365-acquisitions/lib/utils/hasExchangeAddIn';
import {
    hasExtensionAddInCalendarComposeCommand,
    hasExtensionAddInComposeCommand,
    hasExtensionAddInReadCommand,
    hasExtensionAddInCalendarReadCommand,
    hasExtensionAddInSharedFolderComposeCommand,
    hasExtensionAddInSharedFolderReadCommand,
} from 'owa-m365-acquisitions/lib/utils/hasExtensionAddIn';
import type { IEnabledAddinCommands, IAddin } from 'owa-addins-types';
import { ExtensibilityModeEnum } from 'owa-addins-types';
import type { M365Acquisition, MessageExtensionType } from 'owa-graph-schema';

/**
 * Which kind of apps should be shown in a particular flyout?
 * i.e. which apps should be available/displayed in a particular flyout?
 *
 * @param messageExtensionType `MessageExtensionType` that determines the surface in which the flyout is being rendered on
 * @returns Function that takes in an `M365Acquisition` and returns a boolean
 */
export function getFlyoutAppFilters(
    messageExtensionType: MessageExtensionType
): (acquisition: M365Acquisition) => boolean {
    switch (messageExtensionType) {
        case 'Read':
            return (acquisition: M365Acquisition) =>
                hasExchangeAddInReadCommand(acquisition) ||
                hasExtensionAddInReadCommand(acquisition);
        case 'ReadCalendar':
            return (acquisition: M365Acquisition) =>
                hasExchangeAddInCalendarReadCommand(acquisition) ||
                hasExtensionAddInCalendarReadCommand(acquisition);
        case 'ReadSharedFolder':
            return (acquisition: M365Acquisition) =>
                hasExchangeAddInSharedFolderReadCommand(acquisition) ||
                hasExtensionAddInSharedFolderReadCommand(acquisition);
        // Add-Ins & MessageExtensions
        case 'Compose':
            return (acquisition: M365Acquisition) =>
                hasComposeMessageExtension(acquisition) ||
                hasExchangeAddInComposeCommand(acquisition) ||
                hasExtensionAddInComposeCommand(acquisition);
        case 'ComposeCalendar':
            return (acquisition: M365Acquisition) =>
                hasExchangeAddInCalendarComposeCommand(acquisition) ||
                hasExtensionAddInCalendarComposeCommand(acquisition);
        case 'ComposeSharedFolder':
            return (acquisition: M365Acquisition) =>
                hasExchangeAddInSharedFolderComposeCommand(acquisition) ||
                hasExtensionAddInSharedFolderComposeCommand(acquisition);
        // MessageExtensions only
        case 'ComposeMessageExtensions':
            return hasComposeMessageExtension;
        case 'MultipleSelection':
            return (acquisition: M365Acquisition) =>
                hasExchangeAddInMultiSelectCommand(acquisition);
        case 'NoItemContext':
            return (acquisition: M365Acquisition) => hasExchangeAddinNoContextCommand(acquisition);
        default:
            return () => false;
    }
}

export function getFlyoutAppFilters_addins(
    messageExtensionType: MessageExtensionType
): (enabledAddins: IEnabledAddinCommands) => IAddin[] {
    switch (messageExtensionType) {
        case 'Read':
            return (enabledAddins: IEnabledAddinCommands) =>
                enabledAddins.getExtensionPoint(ExtensibilityModeEnum.MessageRead);
        case 'ReadSharedFolder':
            return (enabledAddins: IEnabledAddinCommands) =>
                enabledAddins.getExtensionPoint(ExtensibilityModeEnum.MessageRead, true);
        case 'Compose':
            return (enabledAddins: IEnabledAddinCommands) =>
                enabledAddins.getExtensionPoint(ExtensibilityModeEnum.MessageCompose);
        case 'ComposeSharedFolder':
            return (enabledAddins: IEnabledAddinCommands) =>
                enabledAddins.getExtensionPoint(ExtensibilityModeEnum.MessageCompose, true);
        case 'ComposeCalendar':
            return (enabledAddins: IEnabledAddinCommands) =>
                enabledAddins.getExtensionPoint(ExtensibilityModeEnum.AppointmentOrganizer, true);
        case 'ReadCalendar':
            return (enabledAddins: IEnabledAddinCommands) =>
                enabledAddins.getExtensionPoint(ExtensibilityModeEnum.AppointmentAttendee, true);
        case 'ComposeMessageExtensions':
            return () => [];
        default:
            return () => [];
    }
}
