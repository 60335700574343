import { getActivityFeedStore, getActivityFeedAccountStore } from './store';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { isAccountInActivityFeedStore } from '../utils/isAccountInActivityFeedStore';
import type { MailboxInfo } from 'owa-client-types';

export const getUnseenItemsCount = (): number => {
    let count = 0;
    const store = getActivityFeedStore();
    if (!store.isOpx && store.isActivityFeedOpen) {
        return count;
    }
    if (store.accountItems.size > 0) {
        const mailboxInfo = getModuleContextMailboxInfo();
        count = getUnseenItemsCountForMailbox(mailboxInfo);
    }
    return count;
};

export const getUnseenItemsCountForMailbox = (mailboxInfo: MailboxInfo): number => {
    let count = 0;

    // Check if the account has been added to the store since its possible that OwaSuiteHeader calls
    // this function before a newly added account is initialized in the notifications store.
    if (isAccountInActivityFeedStore(mailboxInfo)) {
        const { items } = getActivityFeedAccountStore(mailboxInfo);
        const unseenItems = [...items.values()].filter(item => !item.isSeen);
        count = unseenItems.length;
    }
    return count;
};
