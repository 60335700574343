/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Remove reference to getHostValue
 *	> 'getHostValue' import from 'owa-config' is restricted. Please use isFeatureEnabled/isBootFeatureEnabled instead. Host value is checked with ECS/CT as a filter. */
import { NATIVE, getHostValue, TEAMS_HUB } from 'owa-config';
import { type PlatformType } from 'owa-lpc-types';

export function getPlatformType(): PlatformType {
    if (getHostValue() === NATIVE || getHostValue() === TEAMS_HUB) {
        return 'Desktop';
    }
    return 'Web';
}
