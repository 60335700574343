import { LazyModule, LazyAction, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "BulkActionsStore" */ './lazyIndex'),
    { name: 'BulkActionsStore' }
);

export const BulkActionSpinner = createLazyComponent(lazyModule, m => m.BulkActionSpinner);

export const lazySubscribeToBulkActionNotification = new LazyAction(
    lazyModule,
    m => m.subscribeToBulkActionNotification
);
export const lazyOnBulkActionSubmitted = new LazyAction(lazyModule, m => m.onBulkActionSubmitted);
export const lazyfindBulkActionItemAction = new LazyAction(
    lazyModule,
    m => m.findBulkActionItemAction
);
export const lazyOnBulkActionCancel = new LazyAction(lazyModule, m => m.onBulkActionCancel);
export const lazyOnBulkActionHierarchyNotification = new LazyAction(
    lazyModule,
    m => m.onBulkActionHierarchyNotification
);
export const lazyOnBulkActionDismiss = new LazyAction(lazyModule, m => m.onBulkActionDismiss);
