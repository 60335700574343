import loadTableViewFromTableQuery from '../actions/loadTableViewFromTableQuery';
import { onMailSearchComplete } from 'owa-mail-actions/lib/mailSearchActions';
import type { LoadTableViewFromSearchTableQueryActionMessage } from 'owa-mail-search/lib/actions/publicActions';
import { loadTableViewFromSearchTableQuery } from 'owa-mail-search/lib/actions/publicActions';
import { orchestrator } from 'satcheljs';
import type { SearchTableQuery } from 'owa-mail-list-search';
import { SearchProvider } from 'owa-search-service/lib/data/schema/SearchProvider';

orchestrator(
    loadTableViewFromSearchTableQuery,
    async (actionMessage: LoadTableViewFromSearchTableQueryActionMessage) => {
        const {
            searchTableQuery,
            searchEndToEndPerformanceDatapoint,
            actionSource,
            onAnswerResponseReceived,
            substrateAnswerRequest,
            substrateAnswersClientFlights,
        } = actionMessage;

        // Load table view from search table query (makes search requests in here).
        await loadTableViewFromTableQuery(
            searchTableQuery,
            searchEndToEndPerformanceDatapoint,
            actionSource,
            undefined /*initialSessionData*/,
            onAnswerResponseReceived,
            substrateAnswerRequest,
            substrateAnswersClientFlights
        );

        // Skip the query for a non-mail-entity tabbed search, offline search, or PST search,
        // each of which makes its own query
        const searchScenarioType = (searchTableQuery as SearchTableQuery)?.scenarioType;
        const searchProvider = (searchTableQuery as SearchTableQuery)?.searchProvider;
        if (
            searchScenarioType != 'tabbedSearch' &&
            searchProvider !== SearchProvider.Offline &&
            searchProvider !== SearchProvider.PST
        ) {
            // Once table view has loaded, dispatch action to notify search completed.
            onMailSearchComplete();
        }
    }
);
