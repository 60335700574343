import { getAllFoldersForMoveTo } from '../helpers/getFoldersForMoveTo';
import type { MailboxType, MailFolder } from 'owa-graph-schema';
import { getStore } from '../store/store';
import {
    getPrimaryFolderTreeRootFolder,
    getArchiveFolderTreeRootFolder,
    getFolderTreeLoadingState,
    FolderTreeLoadStateEnum,
} from 'owa-folders';
import type MoveToFolderType from '../store/schema/MoveToFolderType';
import { ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID } from 'owa-folders-constants';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Get the root folder for given mailbox type
 */
export function getRootFolderForGivenContext(
    moveToMailboxType: MailboxType
): MailFolder | undefined {
    const mailboxInfo = getModuleContextMailboxInfo();
    switch (moveToMailboxType) {
        case 'UserMailbox':
            return getPrimaryFolderTreeRootFolder(mailboxInfo);
        case 'ArchiveMailbox':
            return getArchiveFolderTreeRootFolder(mailboxInfo);
        case 'PstFile':
            return getPrimaryFolderTreeRootFolder(mailboxInfo);
        default:
            throw new Error(
                'To get root folder id, the type must be either UserMailbox or ArchiveMailbox as other mbx types like shared folders might be dynamic'
            );
    }
}

/**
 * Get the root folder ID for given mailbox type
 */
export function getRootFolderIdForGivenContext(moveToMailboxType: MailboxType): string | undefined {
    return getRootFolderForGivenContext(moveToMailboxType)?.id;
}

/**
 * Determines whether to show "All folders" menu item depending on the moveToMailboxType
 * @param mailboxTypeToShow - name of the moveTo mailbox we want to get all the folders for. FYI: this mbx type is not the same as current value of store's moveToMailboxType
 * @param moveToMailboxTypesSupported - the supported mbx types for move operation
 * @param foldersShown - folders that are already shown in default mode. As the folders of default mode are from primary mailbox only so this check is required
 * @param shouldShowAllFolders - should show all folders menu
 * @returns boolean - if a mailbox's all folders can be shown or not
 */
export function shouldShowAllFoldersMenuItem(
    mailboxTypeToShow: MailboxType,
    moveToMailboxTypesSupported: MailboxType[],
    foldersShown: MoveToFolderType[],
    shouldShowAllFolders?: boolean
): boolean {
    const moveToStore = getStore();
    const mailboxInfo = getModuleContextMailboxInfo();
    const currentMoveToMailboxType = moveToStore.moveToMailboxType;
    switch (mailboxTypeToShow) {
        case 'UserMailbox':
            // primary mailbox is supported in implementation of MoveToFolder component; AND
            // is primary (UserMailbox) mailbox and move to menu with directly all folders(default) need to be displayed; AND
            // (1. currently moveto store is not in UserMailbox type; OR
            // 2. default view; OR
            // 3. if anything being searched in any mailbox type; OR
            // 4. mailbox type is primary but already shown folders are less than number of total folders in primary mailbox)
            return (
                moveToMailboxTypesSupported.indexOf('UserMailbox') > -1 &&
                (currentMoveToMailboxType != 'UserMailbox' ||
                    shouldShowAllFolders === false ||
                    moveToStore.findText.trim().length > 0 ||
                    foldersShown.length <
                        getAllFoldersForMoveTo('UserMailbox', mailboxInfo).folders.length)
            );

        case 'ArchiveMailbox':
            // archive mailbox is supported in implementation of MoveToFolder component; AND
            // archive mailbox is enabled for the user; AND
            // (1. currently moveto store is not in archive moveTo mailbox type(this includes includes default view); OR
            // 2. if anything being searched in any mbx type; OR
            // 3. mbx type is archive but already shown folders are less than number of total folders in archive mailbox; OR)
            return (
                moveToMailboxTypesSupported.indexOf('ArchiveMailbox') > -1 &&
                !isConsumer() &&
                !!getArchiveFolderTreeRootFolder(mailboxInfo) &&
                (currentMoveToMailboxType != 'ArchiveMailbox' ||
                    moveToStore.findText.trim().length > 0 ||
                    foldersShown.length <
                        getAllFoldersForMoveTo('ArchiveMailbox', mailboxInfo).folders.length)
            );

        case 'SharedMailbox':
            // shared folders is supported in implementation of MoveToFolder component; AND
            // shared folders feature flag is present;
            return moveToMailboxTypesSupported.indexOf('SharedMailbox') > -1 && !isConsumer();

        default:
            return false;
    }
}

/**
 * Determines whether we should show the loading spinner in move to control or not
 * @returns boolean - if a spinner should be shown or not
 */
export function shouldShowMoveToLoadingSpinner(mailboxInfo: MailboxInfo): boolean {
    const { moveToMailboxType, moveToSharedFolderRootId } = getStore();
    switch (moveToMailboxType) {
        case 'ArchiveMailbox':
            return (
                getFolderTreeLoadingState(ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID, mailboxInfo) ===
                FolderTreeLoadStateEnum.Loading
            );
        case 'SharedMailbox':
            return (
                getFolderTreeLoadingState(moveToSharedFolderRootId, mailboxInfo) ===
                FolderTreeLoadStateEnum.Loading
            );
        default:
            return false;
    }
}

/**
 * Determines the folder name to display, by checking whether user has multiple
 * folders of the same name. If so, additional granularity is needed.
 */
export function getFolderNameToShow(
    folderNameCountMap: {
        [key: string]: number;
    },
    folder: MoveToFolderType
): string {
    const folderDisplayName = folder.displayName;
    if (
        folderNameCountMap[folderDisplayName.toLowerCase()] > 1 &&
        folderIdToName(folder.parentFolderId) != 'msgfolderroot'
    ) {
        return folder.parentFolderDisplayName + '/' + folderDisplayName;
    } else {
        return folderDisplayName;
    }
}
