import getFolderInfoForRetentionPolicy from './getFolderInfoForRetentionPolicy';
import { getRetentionPolicyTagList } from './getRetentionPolicyTagList';
import type { TableView } from 'owa-mail-list-store';
import { trace } from 'owa-trace';

/**
 * @param tableView the tableview
 * @returns the list of retention policy tags available to user
 */
export default function getRetentionPolicyTagsFromTableView(tableView: TableView) {
    const folderInfo = getFolderInfoForRetentionPolicy(tableView);
    if (!folderInfo) {
        trace.warn('getRetentionPolicyTagsFromTableView: Could not find folder info');
        return null;
    }
    return getRetentionPolicyTagList(tableView, folderInfo.mailboxInfo);
}
