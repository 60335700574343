import isDeepLink from 'owa-url/lib/isDeepLink';
import closeProjection from '../actions/closeProjection';
import getProjection from '../utils/getProjection';

export default function closePopout(targetWindow: Window | undefined) {
    const isProjectionPopout = targetWindow && targetWindow !== window;
    if (isProjectionPopout) {
        const projection = getProjection(targetWindow);
        if (projection) {
            closeProjection(projection.tabId, false /* redirectToDeeplink */);
        }
    } else if (isDeepLink() && targetWindow) {
        targetWindow.close();
    }
}
