import { isFeatureEnabled } from 'owa-feature-flags';
import isClipboardWebCustomFormatSupported from './isClipboardWebCustomFormatSupported';
import isClipboardWritable from './isClipboardWritable';

const isCopyAttachmentToClipboardEnabled = (): boolean => {
    return (
        isFeatureEnabled('doc-attachment-copyAndPaste') &&
        isClipboardWebCustomFormatSupported() &&
        isClipboardWritable()
    );
};

export default isCopyAttachmentToClipboardEnabled;
