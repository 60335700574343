import getGroupDetails from './getGroupDetails';
import type UnifiedGroupResource from 'owa-service/lib/contract/UnifiedGroupResource';
import type UnifiedGroupDetails from 'owa-service/lib/contract/UnifiedGroupDetails';
import type { MailboxInfo } from 'owa-client-types';

export type ResourceName =
    | 'Site'
    | 'Files'
    | 'Notebook'
    | 'Planner'
    | 'Integrations'
    | 'CustomLeaveGroup'
    | 'Join'
    | 'Team'
    | 'Calendar';

export const getGroupResource = (
    mailboxInfo: MailboxInfo,
    group: string | UnifiedGroupDetails,
    resource: ResourceName
): UnifiedGroupResource[] => {
    const groupDetails = typeof group === 'string' ? getGroupDetails(mailboxInfo, group) : group;
    if (!groupDetails || !groupDetails.GroupResources) {
        return [];
    }

    const siteResources = groupDetails.GroupResources.filter(groupResource => {
        return groupResource.Name === resource;
    });

    if (!siteResources || siteResources.length < 1) {
        return [];
    }

    return siteResources;
};

export const getGroupResourceUrl = (
    mailboxInfo: MailboxInfo,
    group: string | UnifiedGroupDetails,
    resource: ResourceName
): string => {
    const r = getGroupResource(mailboxInfo, group, resource);
    if (!r || r.length == 0) {
        return '';
    }

    return r[0].Url ?? '';
};
