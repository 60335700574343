import type { MailboxInfo } from 'owa-client-types';
import getMailboxJunkEmailConfiguration from '../utils/getMailboxJunkEmailConfiguration';
import { getAccountScopeUserSettings } from 'owa-session-store';
import updateSenderScreeningUserOptionService from '../services/updateSenderScreeningUserOptionService';

export default async function validateSenderScreeningUserOption(
    mailboxInfo: MailboxInfo
): Promise<void> {
    const mailboxJunkEmailConfiguration = await getMailboxJunkEmailConfiguration(mailboxInfo);
    const userConfiguration = getAccountScopeUserSettings(mailboxInfo);

    const isSenderScreeningJunkSettingEnabled =
        mailboxJunkEmailConfiguration.SenderScreeningEnabled;
    const isSenderScreeningUserOptionEnabled =
        userConfiguration.UserOptions?.IsSenderScreeningSettingEnabled;

    if (
        isSenderScreeningJunkSettingEnabled !== undefined &&
        isSenderScreeningUserOptionEnabled !== undefined &&
        isSenderScreeningJunkSettingEnabled !== isSenderScreeningUserOptionEnabled
    ) {
        await updateSenderScreeningUserOptionService(mailboxInfo);
    }
}
