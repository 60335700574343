import { isStringNullOrWhiteSpace } from 'owa-localize';
import type { MailFolderFromServiceFragment } from 'owa-mail-folder-view-graphql';

export const getCustomFolderCount = (
    rootFolderId: string | undefined,
    folders: MailFolderFromServiceFragment[]
): number => {
    if (!rootFolderId) {
        return 0;
    }
    let count = 0;
    for (let i = 0; i < folders.length; i++) {
        const rawFolder = folders[i];
        const distinguishedFolderType = rawFolder.distinguishedFolderType;

        const isFirstLevelChild = rawFolder.parentFolderId == rootFolderId;

        if (isFirstLevelChild && isStringNullOrWhiteSpace(distinguishedFolderType)) {
            count++;
        }
    }
    return count;
};
