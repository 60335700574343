const UNMOUNT_CALLBACK = '__UNMOUNT';

// This is the implementation of `unmountComponentAtNode` that is exposed to the rest of the app.  It exists because
// we need to remove use of this deprecated API, but fixing the existing usage is a significant amount of work.  This
// is intended as a stopgap for existing usage; no new code should call this.
export function publicUnmountComponentAtNode(container: Element): boolean {
    const callback = (container as any)[UNMOUNT_CALLBACK];

    // In some cases our code attempts to unmount at an element that was never rendered into, because of course it
    // does.  Guard against that here.
    if (callback) {
        callback();
        return true;
    } else {
        return false;
    }
}

export function setUnmountCallback(container: Element, callback: () => void) {
    (container as any)[UNMOUNT_CALLBACK] = callback;
}

export function clearUnmountCallback(container: Element) {
    delete (container as any)[UNMOUNT_CALLBACK];
}
