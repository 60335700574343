import getSelectedNode from './getSelectedNode';
import type { FavoritePersonaNode } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';

export default function getFolderIdForSelectedNode(): string {
    const selectedNode = getSelectedNode();
    switch (selectedNode.type) {
        case 1:
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (15,13): Type 'string | undefined' is not assignable to type 'string'.
            // @ts-expect-error
            return (selectedNode as FavoritePersonaNode).searchFolderId;
        case 3:
            // Treat favorite search node the same as search, in that
            // it doesn't map to an actual folder Id
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (27,13): Type 'null' is not assignable to type 'string'.
            // @ts-expect-error
            return null;
        default:
            return selectedNode.id;
    }
}
