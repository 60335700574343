import type { CoprincipalAccountSource } from 'owa-account-source-list-store';
import { initializeFeatureFlags } from 'owa-feature-flags';
import { getLocalStorageOverrides } from 'owa-feature-flags/lib/utils/overrides/localStorageOverrides';
import { setVersionedCacheValue, tryGetVersionedCacheValue } from './versionedCache';

// Update the version if we change the format of what we store in the cache
export const FEATURE_FLAGS_CACHE_VERSION = 2;
export const FEATURE_FLAGS_LOCAL_STORAGE_KEY = 'FeatureFlags';

// Try to initialize feature flags for the account from the cache.
// Return value indicates if we were successful in initializing.
export function tryBootstrapFeatureFlagsForAccount(account: CoprincipalAccountSource) {
    const featureFlags = tryGetVersionedCacheValue<string[]>(
        account.persistenceId,
        FEATURE_FLAGS_LOCAL_STORAGE_KEY,
        FEATURE_FLAGS_CACHE_VERSION
    );
    if (featureFlags) {
        initializeFeatureFlags(
            featureFlags,
            getLocalStorageOverrides(account.mailboxInfo),
            account.mailboxInfo
        );
        return true;
    }

    return false;
}

export function cacheFeatureFlagsForAccount(
    account: CoprincipalAccountSource,
    featureFlags: string[]
) {
    setVersionedCacheValue<string[]>(
        account.persistenceId,
        FEATURE_FLAGS_LOCAL_STORAGE_KEY,
        FEATURE_FLAGS_CACHE_VERSION,
        featureFlags
    );
}
