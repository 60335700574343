let scriptsLoadedAtPlt: string[] = [];
export function getScriptsLoadedAtPlt(): string[] {
    return scriptsLoadedAtPlt;
}

export function setScriptsLoadedAtPlt() {
    if (self.scriptsLoaded) {
        scriptsLoadedAtPlt = Object.keys(self.scriptsLoaded);
    }
}
