import { lazyRemoveFavoriteCategory } from 'owa-favorites';
import {
    lazyRemoveFavoritePersona,
    lazyHandleToggleFavoritePersonaError,
} from 'owa-mail-favorites-store';

export const removeFavoriteCategory = async (categoryId: string) => {
    const removeFavoriteCategoryFunc = await lazyRemoveFavoriteCategory.import();
    removeFavoriteCategoryFunc(categoryId, 'ContextCategory');
};

export const removeFavoritePersona = (favoriteId: string) => {
    lazyRemoveFavoritePersona
        .importAndExecute(favoriteId, 'ContextPersona')
        .catch(() => lazyHandleToggleFavoritePersonaError.importAndExecute(true));
};
