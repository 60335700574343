import { type MailRibbonControlId, type MailRibbonGroupId } from 'owa-ribbon-ids/lib/mailRibbonId';

import type RibbonVersionManager from './ribbonVersionManager';
import { removeExtraViewTabControls } from './utils/removeExtraViewTabControls';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
/**
 * This is the Ribbon Version Manager for OWA Mail Ribbon.
 * For information on how to use and detailed examples, please see https://aka.ms/OWARibbonVersioning.
 * This is the mechanism that allows new changes in the Ribbon to be reflected in a user's customized ribbon,
 * without simultaneously blowing away all of their changes.
 *
 * Note: When adding to this array, be sure to update @see {CURRENT_RIBBON_VERSION} in @see {defaultConfig}.
 *
 * Please start each entry with `// Version #: <Explain what has changed.>`
 *
 * The version manager will have its versions older than 6 months pruned every now and then to help alleviate
 * the possible infinitely-growing bundle size of constant updates.
 */
export const mailRibbonVersionManager: RibbonVersionManager[] = [
    {
        oldVersioningPastSixMonthLimit: true,
        // Version 1: Adds several new view settings groups to the view tab.
        // addGroup: [
        //     {
        //         tabVariableName: 'viewTab',
        //         groupDefinition: {
        //             groupId: MailRibbonGroupId.Group_View_Settings,
        //             groupName: undefined,
        //             controlIds: [MailRibbonControlId.ViewSettings],
        //             hasOwnOverflow: false,
        //         },
        //     },
        //     {
        //         tabVariableName: 'viewTab',
        //         groupDefinition: {
        //             groupId: MailRibbonGroupId.Group_View_Messages,
        //             groupName: undefined,
        //             controlIds: [
        //                 MailRibbonControlId.Conversations,
        //                 MailRibbonControlId.MessagePreview,
        //             ],
        //             hasOwnOverflow: false,
        //         },
        //     },
        //     {
        //         tabVariableName: 'viewTab',
        //         groupDefinition: {
        //             groupId: MailRibbonGroupId.Group_View_Layout,
        //             groupName: undefined,
        //             controlIds: [MailRibbonControlId.Layout, MailRibbonControlId.Density],
        //             hasOwnOverflow: false,
        //         },
        //     },
        // ],
        // updateGroupOrdering: [
        //     {
        //         tabVariableName: 'viewTab',
        //         newStaticGroupIdOrdering: [
        //             MailRibbonGroupId.Group_View_Settings,
        //             MailRibbonGroupId.Group_View_Messages,
        //             MailRibbonGroupId.Group_View_Layout,
        //             MailRibbonGroupId.Group_ImmersiveReader,
        //             MailRibbonGroupId.Group_Print,
        //             MailRibbonGroupId.Group_RibbonCustomizer,
        //         ],
        //     },
        // ],
    },
    {
        oldVersioningPastSixMonthLimit: true,
        // Version 2: Adds PME group to home tab.
        // addGroup: [
        //     {
        //         tabVariableName: 'homeTab',
        //         groupDefinition: {
        //             groupId: MailRibbonGroupId.Group_PlayMyEmails_DEPRECATED,
        //             groupName: undefined,
        //             controlIds: [MailRibbonControlId.PlayMyEmails_DEPRECATED],
        //             hasOwnOverflow: false,
        //         },
        //     },
        // ],
        // updateGroupOrdering: [
        //     {
        //         tabVariableName: 'homeTab',
        //         newStaticGroupIdOrdering: [
        //             MailRibbonGroupId.Group_ToggleLeftPane_DEPRECATED,
        //             MailRibbonGroupId.Group_New,
        //             MailRibbonGroupId.Group_Delete,
        //             MailRibbonGroupId.Group_Respond,
        //             MailRibbonGroupId.Group_QuickSteps,
        //             MailRibbonGroupId.Group_Move,
        //             MailRibbonGroupId.Group_Tags,
        //             MailRibbonGroupId.Group_Find,
        //             MailRibbonGroupId.Group_Speech_DEPRECATED,
        //             MailRibbonGroupId.Group_Undo,
        //             MailRibbonGroupId.Group_AddIns,
        //             MailRibbonGroupId.Group_PlayMyEmails_DEPRECATED,
        //             MailRibbonGroupId.Group_Insights_DEPRECATED,
        //             MailRibbonGroupId.Group_Protection_DEPRECATED,
        //         ],
        //     },
        // ],
    },
    {
        oldVersioningPastSixMonthLimit: true,
        // Version 3: Remove the controls conversations and message preview from the view tab and replace them with messages dropdown.
        // removeControl: [
        //     {
        //         tabVariableName: 'viewTab',
        //         controlId: MailRibbonControlId.Conversations,
        //     },
        //     {
        //         tabVariableName: 'viewTab',
        //         controlId: MailRibbonControlId.MessagePreview,
        //     },
        // ],
        // addControl: [
        //     {
        //         tabVariableName: 'viewTab',
        //         groupId: MailRibbonGroupId.Group_View_Messages,
        //         controlId: MailRibbonControlId.Messages,
        //     },
        // ],
    },
    {
        oldVersioningPastSixMonthLimit: true,
        // Version 4: adds the quick steps control
        // addControl: [
        //     {
        //         tabVariableName: 'homeTab',
        //         groupId: MailRibbonGroupId.Group_Move_and_Delete,
        //         controlId: MailRibbonControlId.QuickStepsGallery,
        //     },
        // ],
        // addControlToOverflow: [
        //     {
        //         tabVariableName: 'homeTab',
        //         controlId: MailRibbonControlId.QuickStepsGallery,
        //     },
        // ],
    },

    {
        oldVersioningPastSixMonthLimit: true,
        // Version 5: Removes the Phishing Control
        // removeControl: [
        //     {
        //         tabVariableName: 'homeTab',
        //         controlId: MailRibbonControlId.Phishing,
        //     },
        // ],
    },

    {
        oldVersioningPastSixMonthLimit: true,
        // Version 6: Adds ReportSplit and BlockSplit controls
        // addControl: [
        //     {
        //         tabVariableName: 'homeTab',
        //         groupId: MailRibbonGroupId.Group_Move_and_Delete,
        //         controlId: MailRibbonControlId.ReportSplit,
        //     },
        //     {
        //         tabVariableName: 'homeTab',
        //         groupId: MailRibbonGroupId.Group_Move_and_Delete,
        //         controlId: MailRibbonControlId.BlockSplit,
        //     },
        // ],
        // addControlToOverflow: [
        //     {
        //         tabVariableName: 'homeTab',
        //         controlId: MailRibbonControlId.BlockSplit,
        //     },
        // ],
    },
    {
        oldVersioningPastSixMonthLimit: true,
        // Version 7: Move the quick steps control from the overflow+move group to tags group
        // removeControlFromOverflow: [
        //     {
        //         tabVariableName: 'homeTab',
        //         controlId: MailRibbonControlId.QuickStepsGallery,
        //     },
        // ],
        // removeControl: [
        //     {
        //         tabVariableName: 'homeTab',
        //         controlId: MailRibbonControlId.QuickStepsGallery,
        //     },
        // ],
        // addControl: [
        //     {
        //         tabVariableName: 'homeTab',
        //         groupId: MailRibbonGroupId.Group_Tags,
        //         controlId: MailRibbonControlId.QuickStepsGallery,
        //     },
        // ],
    },
    {
        oldVersioningPastSixMonthLimit: true,
        // Version 8: Removes the AddressBook Control
        // removeControl: [
        //     {
        //         tabVariableName: 'homeTab',
        //         controlId: MailRibbonControlId.AddressBook_DEPRECATED,
        //     },
        // ],
    },
    {
        oldVersioningPastSixMonthLimit: true,
        // Version 9: Add "View groups" to ribbon
        // addControl: [
        //     {
        //         tabVariableName: 'homeTab',
        //         groupId: MailRibbonGroupId.Group_Find,
        //         controlId: MailRibbonControlId.ViewGroupsPanel,
        //     },
        // ],
    },
    {
        oldVersioningPastSixMonthLimit: true,
        // Version 10: add comms copilot
        // addGroup: [
        //     {
        //         tabVariableName: 'homeTab',
        //         groupDefinition: {
        //             groupId: MailRibbonGroupId.Group_CommsCopilot,
        //             groupName: undefined,
        //             controlIds: [MailRibbonControlId.CommsCopilot],
        //             hasOwnOverflow: false,
        //         },
        //     },
        // ],
    },
    {
        oldVersioningPastSixMonthLimit: true,
        // Version 11: Remove JunkFlyout
        // removeControl: [
        //     {
        //         tabVariableName: 'homeTab',
        //         controlId: MailRibbonControlId.JunkFlyout_DEPRECATED,
        //     },
        // ],
    },
    {
        oldVersioningPastSixMonthLimit: true,
        // Version 12: Add "Respond combined options" to ribbon. Should be displayed only for consumers
        // addControl: [
        //     {
        //         tabVariableName: 'homeTab',
        //         groupId: MailRibbonGroupId.Group_Respond,
        //         controlId: MailRibbonControlId.RespondFlyout,
        //     },
        // ],
    },
    {
        oldVersioningPastSixMonthLimit: true,
        // Version 13: Removes the AddressBook Control again as we've been getting alerting on it
        // removeControl: [
        //     {
        //         tabVariableName: 'homeTab',
        //         controlId: MailRibbonControlId.AddressBook_DEPRECATED,
        //     },
        // ],
    },
    {
        oldVersioningPastSixMonthLimit: true,
        // Version 14: Add restore options to ribbon
        // addControl: [
        //     {
        //         tabVariableName: 'homeTab',
        //         groupId: MailRibbonGroupId.Group_Move_and_Delete,
        //         controlId: MailRibbonControlId.Restore,
        //     },
        //     {
        //         tabVariableName: 'homeTab',
        //         groupId: MailRibbonGroupId.Group_Move_and_Delete,
        //         controlId: MailRibbonControlId.RestoreAll,
        //     },
        // ],
    },
    {
        oldVersioningPastSixMonthLimit: true,
        // Version 15: Removing PME related code
        // removeGroup: [
        //     {
        //         tabVariableName: 'homeTab',
        //         groupId: MailRibbonGroupId.Group_PlayMyEmails_DEPRECATED,
        //     },
        // ],
    },
    {
        oldVersioningPastSixMonthLimit: true,
        // Version 16: ADO 208870, removing ToggleLeftPane from View Tab
        // removeControl: [
        //     {
        //         tabVariableName: 'viewTab',
        //         controlId: MailRibbonControlId.ToggleLeftPane_DEPRECATED,
        //     },
        // ],
    },
    {
        oldVersioningPastSixMonthLimit: true,
        // Version 17: add spam addins group
        // addGroup: [
        //     {
        //         tabVariableName: 'homeTab',
        //         groupDefinition: {
        //             groupId: MailRibbonGroupId.Group_SpamAddins,
        //             groupName: undefined,
        //             controlIds: [],
        //             hasOwnOverflow: false,
        //         },
        //     },
        // ],
    },
    {
        // Version 18: move print from view to home and to reorder groups
        removeGroup: [
            {
                tabVariableName: 'viewTab',
                groupId: 118,
            },
        ],
        addGroup: [
            {
                tabVariableName: 'homeTab',
                groupDefinition: {
                    groupId: 118,
                    groupName: undefined,
                    controlIds: [549],
                    hasOwnOverflow: false,
                },
            },
        ],
        updateGroupOrdering: [
            {
                tabVariableName: 'viewTab',
                newStaticGroupIdOrdering: [152, 153, 154, 110, 122],
            },
            {
                tabVariableName: 'homeTab',
                newStaticGroupIdOrdering: [
                    129, 116, 104, 121, 120, 113, 128, 118, 108, 126, 130, 101, 112, 119,
                ],
            },
        ],
    },
    {
        // Version 19: Remove the print control from viewTab
        removeControl: [
            {
                tabVariableName: 'viewTab',
                controlId: 549,
            },
        ],
    },
    {
        // Version 20: We've received lots of reports dating far back of controls popping up in view tab.
        // The exact reason unknown, however we'll start by versioning these controls out.
        // Suspect that for most users, this will be a no-op.
        removeControl: removeExtraViewTabControls,
    },
    {
        // Version 21: Add Message Recall to home tab.
        addControl: [
            {
                tabVariableName: 'homeTab',
                groupId: 121,
                controlId: 686,
            },
        ],
        addControlToOverflow: [
            {
                tabVariableName: 'homeTab',
                controlId: 686,
            },
        ],
    },
    {
        // Version 22: Add expand/collapse conversation and zoom buttons to viewTab
        addControl: [
            {
                tabVariableName: 'viewTab',
                groupId: 153,
                controlId: 687,
            },
            {
                tabVariableName: 'viewTab',
                groupId: 153,
                controlId: 688,
            },
        ],
    },
    {
        // Version 23: Remove left pane toggle from home tab
        removeGroup: [
            {
                tabVariableName: 'homeTab',
                groupId: 129,
            },
        ],
        removeControl: [
            {
                tabVariableName: 'homeTab',
                controlId: 578,
            },
        ],
    },
    {
        // Version 24: add window group to view tab
        addGroup: [
            {
                tabVariableName: 'viewTab',
                groupDefinition: {
                    groupId: 194,
                    groupName: undefined,
                    controlIds: [690],
                    hasOwnOverflow: false,
                },
            },
        ],
        updateGroupOrdering: [
            {
                tabVariableName: 'viewTab',
                newStaticGroupIdOrdering: [152, 153, 154, 110, 194, 122],
            },
        ],
    },
    {
        // Version 25: Add CopilotReplyWithMeeting to home tab
        addControl: [
            {
                tabVariableName: 'homeTab',
                groupId: 121,
                controlId: 696,
            },
        ],

        addControlToOverflow: [
            {
                tabVariableName: 'homeTab',
                controlId: 696,
            },
        ],
    },
    {
        // Version 26: Add new "Tracking" group and Track Read Receipts to home tab.
        addGroup: [
            {
                tabVariableName: 'homeTab',
                groupDefinition: {
                    groupId: 195,
                    groupName: undefined,
                    controlIds: [697],
                    hasOwnOverflow: false,
                },
            },
        ],
        updateGroupOrdering: [
            {
                tabVariableName: 'homeTab',
                newStaticGroupIdOrdering: [114, 192, 121, 195, 128, 130, 118, 108, 101, 178],
            },
        ],
        addControl: [
            {
                tabVariableName: 'homeTab',
                groupId: 195,
                controlId: 697,
            },
        ],
        addControlToOverflow: [
            {
                tabVariableName: 'homeTab',
                controlId: 697,
            },
        ],
    },
    {
        // Version 27: Adding CopilotReplyWithMeetingFlyout to home tab for enterprise users
        addControl: [
            {
                tabVariableName: 'homeTab',
                groupId: 121,
                controlId: 698,
            },
        ],
        addControlToOverflow: [
            {
                tabVariableName: 'homeTab',
                controlId: 698,
            },
        ],
        removeControl: [
            {
                tabVariableName: 'homeTab',
                controlId: 696,
            },
        ],
        removeControlFromOverflow: [
            {
                tabVariableName: 'homeTab',
                controlId: 696,
            },
        ],
    },
    {
        // Version 28: Add sync mail to viewTab
        addControl: [
            {
                tabVariableName: 'viewTab',
                groupId: 153,
                controlId: 701,
            },
        ],
    },
    {
        // Version 29: Add chat around email to Teams chat group of home/read tab for enterprise users
        addGroup: [
            {
                tabVariableName: 'homeTab',
                groupDefinition: {
                    groupId: 198,
                    groupName: undefined,
                    controlIds: [702],
                    hasOwnOverflow: false,
                },
            },
        ],
        updateGroupOrdering: [
            {
                tabVariableName: 'homeTab',
                newStaticGroupIdOrdering: [114, 192, 121, 198, 195, 128, 130, 118, 108, 101, 178],
            },
        ],
    },
    {
        // Version 30: Add share to teams button to Teams chat group of home/read tab for enterprise users
        addControl: [
            {
                tabVariableName: 'homeTab',
                groupId: 198,
                controlId: 709,
            },
        ],
    },
    {
        // Version 31: Add Message Resend to home tab.
        addControl: [
            {
                tabVariableName: 'homeTab',
                groupId: 121,
                controlId: 712,
            },
        ],
        addControlToOverflow: [
            {
                tabVariableName: 'homeTab',
                controlId: 712,
            },
        ],
    },
    {
        // Version 32: Remove unused section.
        removeControl: [
            {
                tabVariableName: 'homeTab',
                controlId: 664,
            },
        ],
        removeGroup: [
            {
                tabVariableName: 'homeTab',
                groupId: 178,
            },
        ],
    },
    {
        // Version 33: Adding CopilotReplyWithMeetingFlyout to Respond Group in Single Line Ribbon
        removeControlFromOverflow: [
            {
                tabVariableName: 'homeTab',
                controlId: 698,
            },
        ],
    },
    {
        // Version 34: Remove CopilotReplyWithMeetingFlyout and bring back CopilotReplyWithMeeting to home tab for enterprise users
        removeControl: [
            {
                tabVariableName: 'homeTab',
                controlId: 698,
            },
        ],
        removeControlFromOverflow: [
            {
                tabVariableName: 'homeTab',
                controlId: 698,
            },
        ],
        addControl: [
            {
                tabVariableName: 'homeTab',
                groupId: 121,
                controlId: 696,
            },
        ],
        addControlToOverflow: [
            {
                tabVariableName: 'homeTab',
                controlId: 696,
            },
        ],
    },
    {
        // Version 35: Adding Message Extension flyout to Add-ins group in Home and Read tabs
        addControl: [
            {
                tabVariableName: 'homeTab',
                groupId: 101,
                controlId: 7016,
            },
        ],
        updateGroupOrdering: [
            {
                tabVariableName: 'homeTab',
                newStaticGroupIdOrdering: [114, 192, 121, 198, 195, 128, 130, 118, 101, 108],
            },
        ],
    },
    {
        // Version 36: Move folder pane controls to the view tab from the layout menu in SLR
        addControl: [
            {
                tabVariableName: 'viewTab',
                groupId: 154,
                controlId: 647,
            },
        ],
    },
    {
        // Version 37: Move report control from move and delete group to Group_SpamAddins in SLR
        removeControl: [
            {
                tabVariableName: 'homeTab',
                controlId: 657,
            },
        ],
        addControl: [
            {
                tabVariableName: 'homeTab',
                groupId: 192,
                controlId: 657,
            },
        ],
    },
    {
        // Version 38: Move report control from Group_SpamAddins to Group_Report in SLR
        removeControl: [
            {
                tabVariableName: 'homeTab',
                controlId: 657,
            },
        ],
        addGroup: [
            {
                tabVariableName: 'homeTab',
                groupDefinition: {
                    groupId: 197,
                    groupName: undefined,
                    controlIds: [657],
                    hasOwnOverflow: false,
                },
            },
        ],
        updateGroupOrdering: [
            {
                tabVariableName: 'homeTab',
                newStaticGroupIdOrdering: [114, 197, 192, 121, 198, 195, 128, 130, 118, 101, 108],
            },
        ],
    },
];
