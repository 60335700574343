import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';

const puidMap: Map<string, string | undefined> = new Map();

export function getPuidFromMailboxInfo(mailboxInfo: MailboxInfo) {
    const indexer = getIndexerValueForMailboxInfo(mailboxInfo);
    if (puidMap.has(indexer)) {
        return puidMap.get(indexer);
    }

    const userSettings = getAccountScopeUserSettings(mailboxInfo);
    if (userSettings) {
        const puid = userSettings.SessionSettings?.UserPuid;
        if (puid) {
            puidMap.set(indexer, puid);
            return puid;
        }
    }

    return undefined;
}
