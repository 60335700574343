import { action } from 'satcheljs';
import type { OwaNovaActionUnion } from '../types';

export const onPinTaskbarShortcutTrigger = action('PinTaskbarShortcutTrigger', event => ({
    owaType: 'pinTaskbarShortcut',
    event: {
        ...event,
        data: () => {
            const serializedData = event.data?.();
            return serializedData
                ? JSON.parse(serializedData as unknown as string)
                : serializedData;
        },
    },
})) as OwaNovaActionUnion<'pinTaskbarShortcut'>;
