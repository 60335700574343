import getValidFolderPaneWidth from '../utils/getValidFolderPaneWidth';
import logFolderPaneState from '../helpers/logFolderPaneState';
import setFolderPaneWidth from '../legacyActions/setFolderPaneWidth';
import { setShowFolderPane } from '../actions/setShowFolderPane';
import { LayoutChangeSource, APP_BAR_STATIC_WIDTH } from 'owa-layout';
import { getStore } from '../store/Store';
import setFolderPaneCollapsedState from '../services/setFolderPaneCollapsedState';

export function leftPaneResized(leftPaneWidth: number) {
    /**
     * leftPaneWidth is the width of the entire left pane that contains left rail
     */
    const folderPaneWidthAfterResize = leftPaneWidth - APP_BAR_STATIC_WIDTH;

    /**
     * Today upon left pane resize we are always showing the pane and never collapsing it.
     * So upon resize event we should make sure we set the width to a valid number
     */
    const newFolderPaneWidth = getValidFolderPaneWidth(
        folderPaneWidthAfterResize,
        LayoutChangeSource.LeftNavResizeExpand
    );

    const isFolderPaneBeingShown = getStore().showFolderPane;
    setFolderPaneWidth(newFolderPaneWidth);
    setShowFolderPane(true); // Always expand upon left nav resize event

    /**
     * Persist the folder pane expand/collapse state
     */
    setFolderPaneCollapsedState(false /* isCollapsed */);

    if (!isFolderPaneBeingShown) {
        // User expanded the folder from collapsed state through resize, log this
        logFolderPaneState(
            LayoutChangeSource.LeftNavResizeExpand,
            true /* folderPaneNewShowState */,
            false /* folderPaneOldShowState */
        );
    }
}
