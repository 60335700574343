export interface CategoryColorValue {
    primaryColor: string;
    secondaryColor: string;
    iconColor: string;
    textColor: string;
    hoverColor?: string;
    calendarColor?: string;
}

// Note: The key names of these colors are used in the LLM prompts in Augloop and Sydney to support Copilot AppChat scenarios.
// If you change the key names, please update the LLM prompts as well. Team contact: timecopilot-eng
enum CategoryColor {
    None = -1,
    Cranberry = 0,
    Bronze = 1,
    Peach = 2,
    Marigold = 3,
    LightGreen = 4,
    LightTeal = 5,
    Lime = 6,
    SkyBlue = 7,
    Lavender = 8,
    Pink = 9,
    Silver = 10,
    Steel = 11,
    Beige = 12,
    Mink = 13,
    Charcoal = 14,
    DarkRed = 15,
    DarkOrange = 16,
    DarkBrown = 17,
    Gold = 18,
    DarkGreen = 19,
    DarkTeal = 20,
    Forest = 21,
    DarkBlue = 22,
    DarkPurple = 23,
    Plum = 24,
}

export default CategoryColor;
