import { LazyModule, LazyImport, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "FeedbackCommon"*/ './lazyIndex')
);

export { LogType } from './schema/LogType';

export const CaptureFeedbackDatapoint = new LazyImport(lazyModule, m => m.captureFeedbackDatapoint);
export const lazySendFeedbackToOds = new LazyImport(lazyModule, m => m.sendFeedbackToOds);
export const lazySendLogsToOds = new LazyAction(lazyModule, m => m.sendLogsToOds);
export const lazyGetTokenForOds = new LazyAction(lazyModule, m => m.getTokenForOds);
export { default as getPrivacyUrl } from './utils/getPrivacyUrl';

export type { OdsFeedbackOverrides } from './schema/OdsFeedbackOverrides';
