import React from 'react';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - Migrating deprecated icons: https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9297/Migrating-deprecated-icons-in-client-web
 *	> 'ControlIcons' import from owa-control-icons is restricted. Use JSX icons from @fluentui/react-icons, otherwise from owa-fluent-icons-svg if they can only be reference by string. */
import { type ControlIcons } from 'owa-control-icons';
import type { IIconProps } from '@fluentui/react/lib/Icon';
import { Icon, IconType } from '@fluentui/react/lib/Icon';
import { ImageFit } from '@fluentui/react/lib/Image';

import { fallbackIcon } from './SurfaceActionIcon.scss';

const getIconProps = (srcUrl: string): IIconProps => {
    return {
        iconType: IconType.Image,
        imageProps: {
            src: srcUrl,
            width: 16,
            height: 16,
            imageFit: ImageFit.contain,
            shouldFadeIn: false,
        },
        styles: {
            root: {
                width: '16px !important',
                height: '16px !important',
            },
        },
        imageErrorAs: () => (
            <Icon className={fallbackIcon} iconName={'Photo2'} iconType={IconType.Default} />
        ),
    };
};

export default getIconProps;
