import { MailRowDataPropertyGetter } from 'owa-mail-list-store';
import type { TableView } from 'owa-mail-list-store';
import type VirtualizedGroupHeader from '../type/VirtualizedGroupHeader';
import virtualizedMailGroupHeadersStore from '../store/store';

export default function getRowKeysFromCollapsedGroupHeaders(
    tableView: TableView,
    tableSupportsPinning: boolean
) {
    const hiddenRowKeys: string[] = [];

    virtualizedMailGroupHeadersStore().virtualizedGroupHeaders.forEach(
        (header: VirtualizedGroupHeader) => {
            if (header.isCollapsed) {
                header.rowKeys.forEach((rowKey: string) => {
                    const isItemPinned =
                        MailRowDataPropertyGetter.getIsPinned(rowKey, tableView) &&
                        tableSupportsPinning;

                    if (!isItemPinned) {
                        hiddenRowKeys.push(rowKey);
                    }
                });
            }
        }
    );

    return hiddenRowKeys;
}
