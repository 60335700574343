import { LazyModule, createLazyComponent } from 'owa-bundling';
import isBrowserSupportProjection from './utils/isBrowserSupportProjection';
import { isFeatureEnabled } from 'owa-feature-flags';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CalendarBootProjection" */ './lazyFunctions'),
    { name: 'CalendarBootProjection' }
);

export const ProjectionPopoutHost = createLazyComponent(
    lazyModule,
    m => m.ProjectionPopoutHost,
    undefined,
    undefined,
    {
        onlyImportIf: () =>
            isFeatureEnabled('cmp-projection-popout-support-extension') ||
            isBrowserSupportProjection(),
    }
);
