import getStore from '../store/store';
import type { QuickStepItem } from 'owa-quick-step-types';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { canUseQuickSteps, QuickStepLoadState } from './canUseQuickSteps';

export function getQuickSteps(mailboxInfo: MailboxInfo): QuickStepItem[] {
    // if we've already retrieved quick steps for that mailbox, return them
    if (canUseQuickSteps(mailboxInfo) === QuickStepLoadState.Loaded) {
        const key = getIndexerValueForMailboxInfo(mailboxInfo);
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        return getStore().quickStepsStoreMap.get(key)!.quickSteps;
    }
    // otherwise, return empty (the canUseQuickSteps function kicks off the load)
    else {
        return [];
    }
}
