import type SearchScenarioId from 'owa-search-store/lib/store/schema/SearchScenarioId';
import type { SearchRefinersState } from '../store/schema/SearchRefinersState';
import { getSearchRefinersStore, createDefaultRefinersStore } from '../store/store';

/**
 * Returns the refiners store instance for the given scenario ID. If no instance
 * exists for the given ID, return a default refiners store instance object.
 * @param scenarioId
 */
export default function getSearchRefinersState(scenarioId: SearchScenarioId): SearchRefinersState {
    // The store should be initialized with default state by the time this selector is hit, since it's initialized when the SearchBox loads
    // But in case it is not yet initialized, return a default state
    return getSearchRefinersStore().refiners.get(scenarioId) ?? createDefaultRefinersStore();
}
