import { LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailContentUtils" */ './lazyIndex')
);

export const lazyCreateMailResponseBodyContent = new LazyImport(
    lazyModule,
    m => m.createMailResponseBodyContent
);
