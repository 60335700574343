import { getDefaultRoutingKey, getRoutingKeyPrefixForSmtpAddress } from 'owa-client-ids';
import type { MailboxInfo } from 'owa-client-types';
import { isGlobalSettingsMailbox } from 'owa-account-source-list-store';

export function getRoutingKeyForMailboxInfo(mailboxInfo: MailboxInfo): string {
    if (mailboxInfo.type == 'ArchiveMailbox') {
        // For archive mailboxes attachment routing keys use defaultRoutingKey,
        // not the mailboxSmtpAddress (which is anchorMbxGuid@tenantGuid).
        return getDefaultRoutingKey(mailboxInfo);
    }

    const isSecondaryAccount = !isGlobalSettingsMailbox(mailboxInfo);
    const isGroup = mailboxInfo.type === 'GroupMailbox';
    const isShared = mailboxInfo.type === 'SharedMailbox';

    if (isSecondaryAccount && !isGroup && !isShared) {
        return getRoutingKeyPrefixForSmtpAddress(mailboxInfo) + mailboxInfo.userIdentity;
    }

    // For UserMailbox, use getDefaultRoutingKey which will do additional checks
    // e.g. EASI ID checks and add the prefix as needed
    if (mailboxInfo.type === 'UserMailbox') {
        return getDefaultRoutingKey(mailboxInfo);
    } else {
        // For all other mailbox types just use the mailboxSmtpAddress
        // Adding a prefix doesn't work
        return mailboxInfo.mailboxSmtpAddress;
    }
}
