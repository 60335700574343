import type TableQuery from '../store/schema/TableQuery';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import {
    PRIMARY_DUMPSTER_DISTINGUISHED_ID,
    ARCHIVE_DUMPSTER_DISTINGUISHED_ID,
} from 'owa-folders-constants';

/**
 * Returns a flag indicating whether the table is the dumpster table
 * @param tableQuery - the table query
 * @return whether the table is the dumpster table
 */
export default function isDumpsterTable(tableQuery: TableQuery | undefined): boolean {
    return (
        !!tableQuery &&
        (folderIdToName(tableQuery.folderId) === ARCHIVE_DUMPSTER_DISTINGUISHED_ID ||
            folderIdToName(tableQuery.folderId) === PRIMARY_DUMPSTER_DISTINGUISHED_ID)
    );
}
