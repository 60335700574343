import { logGreyErrorFromAccounts } from 'owa-account-analytics';

/**
 * The list of known error stacks that have been logged. We maintain this list so that we don't log
 * the same error multiple times.
 */
const knownErrorStacks = new Set<string>();

export function resetKnownErrorStacks() {
    knownErrorStacks.clear();
}

export enum ActionExecution {
    Synchronous = 'Synchronous',
    Asynchronous = 'Asynchronous',
}

/**
 * Attempts to run the action synchronously, if it fails, it will attempt to run the action
 * asynchronously to get it to complete.
 * @param name The name of the action
 * @param action The action to run
 */
export function runActionWithAsyncFallback(
    name: 'Archive' | 'Groups' | 'PublicFolders' | 'SharedMailboxes' | 'Teams',
    action: () => void
): ActionExecution {
    try {
        action();
        return ActionExecution.Synchronous;
    } catch (err) {
        if (err instanceof Error) {
            const stack = err.stack;
            if (!!stack && !knownErrorStacks.has(stack)) {
                knownErrorStacks.add(stack);
                logGreyErrorFromAccounts('ExceptionRunningAction', err, { name });
            }
        }
    }

    // Attempt to run the action asynchronously, this is to handle the case where the
    // action failed because we are already within an action
    setTimeout(() => {
        try {
            action();
        } catch (err) {
            if (err instanceof Error) {
                logGreyErrorFromAccounts('ExceptionRunningActionAsync', err, { name });
            }
        }
    }, 0);

    return ActionExecution.Asynchronous;
}
