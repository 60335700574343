import { SourceIdDelimiterRegularExpression } from 'owa-client-types/lib/SourceIdPartDelimiter';

const delimiterReplacement = '-';

/**
 * We need to make sure the persistence id is escaped so that it does not contain the sourceId delimiter
 * and will be correctly processed when getting the account key.
 * @param persistenceId Version of the persistence id needed for the account
 * @returns Escaped persistence id
 */
export function escapePersistenceId(persistenceId: string): string {
    return persistenceId.replace(SourceIdDelimiterRegularExpression, delimiterReplacement);
}
