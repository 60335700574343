import isSMIMEItem from 'owa-smime/lib/utils/isSMIMEItem';
import type Message from 'owa-service/lib/contract/Message';
import type { MailboxInfo } from 'owa-client-types';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { getHavePremiumEncryption } from 'owa-cloud-message-recall/lib/utils/getHavePremiumEncryption';

export default function shouldDisableMessageRecall(
    item: Message | undefined,
    mailboxInfo: MailboxInfo
): boolean {
    const isSharedMailbox = !!getUserConfiguration()?.SessionSettings?.IsExplicitLogon;

    return (
        isSMIMEItem(item) ||
        getShouldDisableForEncryptedItem(item, mailboxInfo) ||
        isSharedMailbox ||
        !!item?.IsSendIndividually
    );
}

function getShouldDisableForEncryptedItem(
    item: Message | undefined,
    mailboxInfo: MailboxInfo
): boolean {
    const havePremiumEncryption = getHavePremiumEncryption(mailboxInfo);

    // Encrypted Emails (EOP encryption) Recall is allowed based on PREMIUM_ENCRYPTION service plan license check
    return !!item?.RightsManagementLicenseData && !havePremiumEncryption;
}
