import type TableView from '../store/schema/TableView';
import getSelectedTableViewId from './getSelectedTableViewId';
import getListViewStore from '../store/Store';
import type { Module } from 'owa-workloads';

/**
 * This function is deprecated as we will move away from global getters.
 * Please pass in the tableView or tableViewId as parameter and call getListViewStore().tableViews.get(tableViewId) instead.
 */
export default function getSelectedTableView(moduleContext?: Module): TableView {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (13,5): Type 'TableView | undefined' is not assignable to type 'TableView'.
    // @ts-expect-error
    return getListViewStore().tableViews.get(getSelectedTableViewId(moduleContext));
}
