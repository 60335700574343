import { ObservableMap } from 'mobx';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type { GroupFoldersStore } from '../store/schema/GroupFoldersStore';
import type { GroupFolderHierarchy } from '../store/schema/GroupFolderHierarchy';
import { getLeftNavGroupFolders } from '../store/store';

export default function getLeftNavGroupFoldersStore(mailboxInfo: MailboxInfo): GroupFoldersStore {
    const index = getIndexerValueForMailboxInfo(mailboxInfo);
    const folderTableStates = getLeftNavGroupFolders().folderTableByMailboxInfo.get(index);

    if (!folderTableStates) {
        const defaultFolderTable: GroupFoldersStore = {
            folderTable: new ObservableMap<string, GroupFolderHierarchy>(),
        };

        getLeftNavGroupFolders().folderTableByMailboxInfo.set(index, defaultFolderTable);

        return defaultFolderTable;
    }

    return folderTableStates;
}
