import { type EasidResponse, isEasidResponse } from '../schema/EasidResponse';
import type PrimeBootSettings from 'owa-service/lib/contract/PrimeBootSettings';
import type RecursiveReadOnly from 'owa-service/lib/RecursiveReadOnly';

export function getEasidResponseFromPrimeSettings(
    primeSettings?: PrimeBootSettings | RecursiveReadOnly<PrimeBootSettings>
): EasidResponse | undefined {
    const primeSettingsItems = primeSettings?.Items;
    const easidLinkJson = primeSettingsItems?.find(item => item.Id === 'EasidLink')?.Value?.[0]
        ?.value;
    const easidLink = easidLinkJson ? JSON.parse(easidLinkJson) : undefined;
    const easidResponse = easidLink?.linkedAccountInfo;

    return isEasidResponse(easidResponse) ? easidResponse : undefined;
}
