import React from 'react';
import { observer } from 'owa-mobx-react';
import classNames from 'owa-classnames';
import { appModule, fullBleed } from './AppModuleContainer.scss';
import { isCapabilityEnabled } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';

const AppModuleContainer = observer(function AppModuleContainer({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <div
            className={classNames(
                appModule,
                isCapabilityEnabled(bleedThroughCapability) && fullBleed
            )}
        >
            {children}
        </div>
    );
},
'AppModuleContainer');

export { AppModuleContainer };
