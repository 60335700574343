import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import isFeatureEnabled from 'owa-feature-flags/lib/utils/isFeatureEnabled';

export const isYammerPublisherItemEnabled = (): boolean => {
    if (isConsumer() || !isFeatureEnabled('tri-newYammerPostButton')) {
        return false;
    }

    // This option will be false when user either doesn't have a Yammer license or is not mapped
    //
    // remarks --- this follows N-1 pattern meaning the value is checked and updated after each boot if needed. More details below:
    // During boot, use pre-cached HasYammerLicense value from UserOptions to determine if the license is available or not (default is false).
    // After boot, perform yammer call to check for license (and provision user on yammer on the fly if needed).
    // If needed, update HasYammerLicense both in memory to enable/disable the button and cache value for next bootup (on the server).
    if (!getUserConfiguration()?.UserOptions?.HasYammerLicense) {
        return false;
    }

    return true;
};
