import { changeTheme } from 'owa-fabric-theme';
import { orchestrator } from 'satcheljs';
import refreshTheme from '../refreshTheme';
import { isPwa } from 'owa-config';

export const onThemeChangeOrchestrator: (
    actionMessage: ReturnType<typeof changeTheme>
) => void | Promise<void> = orchestrator(changeTheme, () => {
    if (isPwa()) {
        refreshTheme();
    }
});
