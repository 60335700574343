import type FolderStore from './schema/FolderStore';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - satchel stores in the worker will need their data synced
 *	> 'createStore' import from 'satcheljs' is restricted. Satchel stores should not be created in the worker as the data will need to be synced */
import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';

export const folderStore = createStore<FolderStore>('folderStore', {
    folderTable: new ObservableMap(),
    mailboxFolderTreeData: new ObservableMap(),
    foldersInLoadingState: [],
    isAlphabetizeFolderList: false,
})();
const getStore = () => folderStore;
export default getStore;
