import { getAccountScopeUserSettings } from 'owa-session-store';
import {
    getGlobalSettingsAccountMailboxInfo,
    isAccountSourceListStoreInitialized,
} from 'owa-account-source-list-store';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';
import type { MailboxInfo } from 'owa-client-types';

export function isGroupsEnabled(mailboxInfo?: MailboxInfo) {
    if (!isAccountSourceListStoreInitialized()) {
        return false;
    }

    mailboxInfo = mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();

    const accountScopeUserSettings = getAccountScopeUserSettings(mailboxInfo);

    return (
        accountScopeUserSettings.GroupsEnabled &&
        !accountScopeUserSettings.SessionSettings?.IsExplicitLogon &&
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * Adding IsShadowMailbox to restricted properties/methods.
         *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
        !accountScopeUserSettings.SessionSettings?.IsShadowMailbox &&
        // Accounts, such as PST files, that do not support service requests do not have groups
        isServiceRequestSupportedForMailbox(mailboxInfo)
    );
}
