import type SweepRequestStatus from '../store/schema/SweepRequestStatus';
import SweepOptionType from '../store/schema/SweepOptionType';
import sweepStore from '../store/store';
import { mutatorAction } from 'satcheljs';

/**
 * Show or hide the sweep dialog
 * @param shouldShow whether this is to show the sweep dialog
 * @param state the state which contains the sweep store
 */
export default mutatorAction(
    'showHideSweepDialog',
    function showHideSweepDialog(shouldShow: boolean) {
        sweepStore.isSweepDialogShown = shouldShow;
        if (!shouldShow) {
            // Clear the sweep view state if hiding the sweep dialog
            sweepStore.sweepOptionType = SweepOptionType.All;
            sweepStore.sweepRequestStatus = 0;
        }
    }
);
