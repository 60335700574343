import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailFunctionalBoot" */ './lazyIndex'),
    { name: 'MailFunctionalBoot' }
);

export const lazySetupMailModulePostRender = new LazyAction(
    lazyModule,
    m => m.setupMailModulePostRender
);
