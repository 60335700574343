import type TableView from '../store/schema/TableView';
import type RowOperation from '../store/schema/RowOperation';
import { mutatorAction } from 'satcheljs';

export function setRowOperationMutator(
    tableView: TableView,
    rowNotificationOperation: RowOperation
) {
    setCurrentRowOperationMutator(tableView, rowNotificationOperation);

    // Always reset the RowOperation state after a promise resolve.
    // This will ensure that we won't have a stale out of sync state. Whatever state we set will be good for the synchronous
    // thread, and promise resolve will run afer the synchronous thread to clear the state.
    Promise.resolve().then(() => {
        setCurrentRowOperationMutator(tableView, 0);
    });
}

const setCurrentRowOperationMutator = mutatorAction(
    'setCurrentRowOperation',
    (tableView: TableView, rowNotificationOperation: RowOperation) => {
        tableView.currentRowOperation = rowNotificationOperation;
    }
);
