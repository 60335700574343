import { isFeatureEnabled } from 'owa-feature-flags';
import { getAppBarPinnedAppIdsFromUserSettings } from './getAppBarPinnedAppIdsFromUserSettings';
import type { MailboxInfo } from 'owa-client-types';

export function isAppBarPinnedAppsEnabled(mailboxInfo?: MailboxInfo): boolean {
    return (
        isFeatureEnabled('mos-mos3AppService', mailboxInfo) &&
        !!getAppBarPinnedAppIdsFromUserSettings(mailboxInfo)
    );
}
