import { getStore } from '../store/Store';
import { mutatorAction } from 'satcheljs';
import { setItem } from 'owa-local-storage';

export const onSenderColumnWidthChangedInternal = mutatorAction(
    'onSenderColumnWidthChangedInternal',
    (senderColumnWidth: number) => {
        getStore().senderColumnWidth = senderColumnWidth;

        updateLocalColumnWidths(
            senderColumnWidth,
            getStore().subjectColumnWidth,
            getStore().receivedColumnWidth
        );
    }
);

export const onSubjectColumnWidthChangedInternal = mutatorAction(
    'onSubjectColumnWidthChangedInternal',
    (subjectColumnWidth: number) => {
        getStore().subjectColumnWidth = subjectColumnWidth;

        updateLocalColumnWidths(
            getStore().senderColumnWidth,
            subjectColumnWidth,
            getStore().receivedColumnWidth
        );
    }
);

export const onReceivedColumnWidthChangedInternal = mutatorAction(
    'onReceivedColumnWidthChangedInternal',
    (receivedColumnWidth: number) => {
        getStore().receivedColumnWidth = receivedColumnWidth;

        updateLocalColumnWidths(
            getStore().senderColumnWidth,
            getStore().subjectColumnWidth,
            receivedColumnWidth
        );
    }
);

// This function stores the adjusted column widths (i.e. the user's preferred column
// widths + any adjustments made to fit the columns within the available space) in
// local storage so that we can render the columns at the appropriate width the
// next time the user reloads the page.
const updateLocalColumnWidths = (
    senderColumnWidth: number | null,
    subjectColumnWidth: number | null,
    receivedColumnWidth: number | null
) => {
    if (senderColumnWidth === null || subjectColumnWidth === null || receivedColumnWidth === null) {
        return;
    }

    setItem(
        window,
        `MailListColumnWidths`,
        JSON.stringify({
            senderColumnWidth,
            subjectColumnWidth,
            receivedColumnWidth,
        })
    );
};
