import { AttachmentFileType } from '../types/AttachmentFile';
import type AttachmentItemFile from '../types/AttachmentItemFile';
import type AttachmentDataProviderItem from 'owa-service/lib/contract/AttachmentDataProviderItem';

export default function createAttachmentItemFileFromDataProviderItem(
    providerItem: AttachmentDataProviderItem | null
): AttachmentItemFile | null {
    if (providerItem === null) {
        return null;
    }

    const attachmentItemFile: AttachmentItemFile = {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        attachmentItemId: providerItem.Id!,
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        name: providerItem.Name!,
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        size: providerItem.Size!,
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        fileType: AttachmentFileType.AttachmentItem!,
        type: 'ItemIdAttachment:#Exchange',
    };

    return attachmentItemFile;
}
