import { getImportHistory } from 'owa-account-import-history/lib/getImportHistory';

const migrationGracePeriod = 30; //days
export function isInMigrationAdsFreePeriod(): boolean {
    const firstMigrationTimestamp = getImportHistory().firstMigrationFromWin32TimestampNew;
    return (
        !!firstMigrationTimestamp &&
        Math.ceil((+new Date() - +new Date(firstMigrationTimestamp)) / (1000 * 3600 * 24)) <=
            migrationGracePeriod
    );
}
