import { getItem } from 'owa-local-storage';
import doesCombinedFavoritesExistInCache from '../utils/doesCombinedFavoritesExistInCache';
import type { CombinedFavoritesList } from '../store/schema/CombinedFavoritesList';
import { CLIENT_CONFIGURATION_COMBINED_FAVORITES_KEY } from '../favoriteConstants';

export default function getCombinedFavoritesFromCache(): CombinedFavoritesList | undefined {
    if (!doesCombinedFavoritesExistInCache()) {
        return undefined;
    }

    return JSON.parse(getItem(window, CLIENT_CONFIGURATION_COMBINED_FAVORITES_KEY) ?? '{}');
}
