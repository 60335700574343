import { ApolloLink } from '@apollo/client';
import type { Resolvers, ResolverContext } from 'owa-graph-schema';
import { mailboxServerLink } from '../links/mailboxServerLink';
import { pstServerLink, isPstFileOperation } from '../links/pstServerLink';
import { type DataSource } from 'owa-analytics-types';

export type ResolversProfile = {
    resolvers: Resolvers<ResolverContext>;
    dataSource?: DataSource;
    isOfflineEnabled: boolean;
};

export type ResolverImports = {
    offline?: Resolvers<ResolverContext>;
    pst?: Resolvers<ResolverContext>;
    web: Resolvers<ResolverContext>;
};

export function createGraphServerLink(
    context: Record<string, any>,
    getResolvers: () => Promise<ResolverImports>,
    remoteLink: ApolloLink
) {
    return ApolloLink.split(
        isPstFileOperation,
        pstServerLink(context, getResolvers),
        mailboxServerLink(context, getResolvers, remoteLink)
    );
}
