import { updateAddinOnNavigationToEmptyNullReadingPane } from 'owa-mail-addins';
import type React from 'react';
import { useEffect } from 'react';

const NullReadingPane: React.FC<{}> = () => {
    useEffect(() => {
        updateAddinOnNavigationToEmptyNullReadingPane();
    }, []);

    return null;
};

export default NullReadingPane;
