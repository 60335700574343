import CategoryIcon from 'owa-categories/lib/components/CategoryIcon';
import {
    getCategoryUnreadCount,
    getCategoryTotalCount,
} from 'owa-categories/lib/utils/getCategoryCounts';
import getMasterCategoryList from 'owa-categories/lib/utils/getMasterCategoryList';
import { observer } from 'owa-mobx-react';
import DragAndDroppable from 'owa-dnd/lib/components/DragAndDroppable';
import type DropViewState from 'owa-dnd/lib/store/schema/DropViewState';
import type { DragData } from 'owa-dnd/lib/utils/dragDataUtil';
import { DraggableItemTypes } from 'owa-dnd/lib/utils/DraggableItemTypes';
import type { FavoriteNodeDragData } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import { showFavoritesContextMenu } from 'owa-mail-favorites-store/lib/actions/favoritesContextMenu';
import { lazySelectFavoriteCategory } from 'owa-mail-folder-forest-actions';
import { getSelectedNode } from 'owa-mail-folder-forest-store';
import { DRAG_X_OFFSET, DRAG_Y_OFFSET } from 'owa-mail-folder-view';
import { buildUnreadTotalCountTooltip } from 'owa-folders';
import { getAnchorForContextMenu } from 'owa-positioning';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import type CategoryType from 'owa-service/lib/contract/CategoryType';
import TreeNode from 'owa-tree-node/lib/components/TreeNode';
import { UnreadReadCountBadge } from 'owa-unreadread-count-badge';
import React from 'react';
import { removeFavoriteCategory } from '../util/removeFavorite';
import { dragPreview } from './FavoriteNode.scss';

import type { FavoriteNodeCommonProps } from './FavoriteNode';
import { getFavoriteCategoryDisplayNameForMultiAccount } from '../util/getFavoriteCategoryDisplayNameForMultiAccount';
import type { MailboxInfo } from 'owa-client-types';
import { SourceMap } from 'owa-folders-constants';
import {
    canDropFavorite,
    canDropFolder,
    onDropFolder,
    onDropFavorite,
} from '../util/dragDropUtils';

export interface FavoriteCategoryNodeProps extends FavoriteNodeCommonProps {
    categoryId: string;
    dropViewState: DropViewState;
    mailboxInfo: MailboxInfo;
    ellipsesOnHover?: boolean;
}

export default observer(function FavoriteCategoryNode(props: FavoriteCategoryNodeProps) {
    const mailboxInfo = props.mailboxInfo;

    const category = useComputedValue((): CategoryType => {
        return getMasterCategoryList(mailboxInfo).filter(
            categoryType => categoryType.Id === props.categoryId
        )[0];
    }, [props.categoryId, props.mailboxInfo]);

    const isSelected = useComputedValue((): boolean => {
        return props.categoryId == getSelectedNode().id;
    }, [props.categoryId]);

    const unreadCount = useComputedValue((): number => {
        return getCategoryUnreadCount(category.Name ?? '', mailboxInfo);
    }, [props.mailboxInfo]);

    const totalCount = useComputedValue((): number => {
        return getCategoryTotalCount(category.Name ?? '', mailboxInfo);
    }, [props.mailboxInfo]);

    const renderUnreadCount = React.useCallback((): JSX.Element | null => {
        return unreadCount ? (
            <UnreadReadCountBadge count={unreadCount} isSelected={isSelected} />
        ) : null;
    }, [unreadCount, isSelected]);
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const renderCustomIcon = (customIconClassNames: string): JSX.Element => {
        return (
            <CategoryIcon
                categoryName={category.Name}
                iconClassName={customIconClassNames}
                mailboxInfo={mailboxInfo}
            />
        );
    };
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onClick = (evt: React.MouseEvent<unknown>) => {
        evt.stopPropagation();
        lazySelectFavoriteCategory.importAndExecute(props.categoryId);
    };
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onContextMenu = (evt: React.MouseEvent<HTMLElement>) => {
        evt.stopPropagation();
        evt.preventDefault();
        showFavoritesContextMenu(props.favoriteId, 4, getAnchorForContextMenu(evt));
    };
    // VSO 23480 - [FavoritesDND]Refactor the common DND code into a base component
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const getDragData = () => {
        const folderNodeDragData: FavoriteNodeDragData = {
            itemType: DraggableItemTypes.FavoriteNode,
            favoriteId: props.favoriteId,
            favoriteType: 4,
            displayName: category.Name ?? '',
            itemData: {
                mailboxInfo: props.mailboxInfo,
            },
        };
        return folderNodeDragData;
    };
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const canDrop = (dragData: DragData) => {
        switch (dragData.itemType) {
            case DraggableItemTypes.FavoriteNode:
                return canDropFavorite(dragData, mailboxInfo);
            case DraggableItemTypes.MailFolderNode:
                return canDropFolder(dragData, mailboxInfo);
            default:
                return false;
        }
    };
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onDrop = async (dragData: DragData) => {
        const itemType = dragData.itemType;
        switch (itemType) {
            case DraggableItemTypes.MailFolderNode:
                await onDropFolder(dragData, mailboxInfo, props.favoriteId);
                break;
            case DraggableItemTypes.FavoriteNode:
                await onDropFavorite(dragData, props.favoriteId);
                break;
        }
    };
    // If the categoryId does not exist within the MCL early return
    // This can happen in edge cases such as when a category is favorited and then immediately deleted from the MCL
    if (category === undefined) {
        return null;
    }
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const toggleFavorite = () => {
        removeFavoriteCategory(props.categoryId);
    };
    const displayName = getFavoriteCategoryDisplayNameForMultiAccount(
        category.Name ?? '',
        mailboxInfo
    );

    return (
        <DragAndDroppable
            getDragData={getDragData}
            getDragPreview={getDragPreview}
            xOffset={DRAG_X_OFFSET}
            yOffset={DRAG_Y_OFFSET}
            dropViewState={props.dropViewState}
            onDrop={onDrop}
            canDrop={canDrop}
        >
            <TreeNode
                onRenderCustomIcon={renderCustomIcon}
                displayName={displayName}
                isRootNode={false}
                key={props.categoryId}
                isDroppedOver={props.dropViewState.isDragOver}
                onClick={onClick}
                isSelected={isSelected}
                onContextMenu={onContextMenu}
                renderRightCharm={renderUnreadCount}
                isFavorited={true}
                toggleFavorite={toggleFavorite}
                customTreeNodeTooltip={buildUnreadTotalCountTooltip(
                    displayName,
                    totalCount,
                    unreadCount
                )}
                ellipsesOnHover={props.ellipsesOnHover}
                source={SourceMap.favorite}
                shouldShowFocusBorder={true}
            />
        </DragAndDroppable>
    );
}, 'FavoriteCategoryNode');

function getDragPreview(dragData: DragData) {
    const elem = document.createElement('div');
    elem.className = dragPreview;
    elem.innerText = (dragData as FavoriteNodeDragData)?.displayName ?? '';
    return elem;
}
