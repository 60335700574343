import compareRowDateTime from './compareRowDateTime';
import type { MailFolderTableQuery, TableView } from 'owa-mail-list-store';
import { MailRowDataPropertyGetter, type SortColumn } from 'owa-mail-list-store';
import { ASCENDING_SORT_DIR } from 'owa-mail-list-store/lib/utils/sort/mailSortHelper';

export default function isMessageListOutOfOrder(
    tableView: TableView,
    insertIndex: number
): boolean {
    const mailTableQuery = tableView.tableQuery as MailFolderTableQuery;

    // We only have support to evaluate sort order for DateTime sort on the client side so we will assume the ML is in order for other sorts.
    // If the folder is empty or there are no additional rows below the last inserted row, then we return false.
    if (
        mailTableQuery.sortBy?.sortColumn !== 1 ||
        tableView.rowKeys.length <= insertIndex ||
        tableView.rowKeys.length === 0
    ) {
        return false;
    }

    const rowKeyAtLastIndexInserted = tableView.rowKeys[insertIndex - 1];
    const rowKeyAtInsertIndex = tableView.rowKeys[insertIndex];
    let rowDateTimeComparison = 0;

    const lastDeliveryOrRenewTimeStampRowAtInsertIndex =
        MailRowDataPropertyGetter.getLastDeliveryOrRenewTimeStamp(rowKeyAtInsertIndex, tableView);
    const lastDeliveryTimeStampRowAtInsertIndex =
        MailRowDataPropertyGetter.getLastDeliveryTimeStamp(rowKeyAtInsertIndex, tableView);

    rowDateTimeComparison = compareRowDateTime(
        lastDeliveryOrRenewTimeStampRowAtInsertIndex,
        lastDeliveryTimeStampRowAtInsertIndex,
        rowKeyAtLastIndexInserted,
        tableView
    );

    return mailTableQuery.sortBy.sortDirection === ASCENDING_SORT_DIR
        ? rowDateTimeComparison > 0
        : rowDateTimeComparison < 0;
}
