import { getLocalizedString } from './localization/getLocalizedString';
import { computed } from 'mobx';

export default () => computedFormatDateStrings.get();

const computedFormatDateStrings = computed(getFormatDateStrings);

function getFormatDateStrings() {
    return {
        ddd: [
            getLocalizedString('sundayAbbreviatedLowercase'),
            getLocalizedString('mondayAbbreviatedLowercase'),
            getLocalizedString('tuesdayAbbreviatedLowercase'),
            getLocalizedString('wednesdayAbbreviatedLowercase'),
            getLocalizedString('thursdayAbbreviatedLowercase'),
            getLocalizedString('fridayAbbreviatedLowercase'),
            getLocalizedString('saturdayAbbreviatedLowercase'),
        ],
        dddd: [
            getLocalizedString('sundayUppercase'),
            getLocalizedString('mondayUppercase'),
            getLocalizedString('tuesdayUppercase'),
            getLocalizedString('wednesdayUppercase'),
            getLocalizedString('thursdayUppercase'),
            getLocalizedString('fridayUppercase'),
            getLocalizedString('saturdayUppercase'),
        ],
        MMM: [
            getLocalizedString('januaryAbbreviatedLowercase'),
            getLocalizedString('februaryAbbreviatedLowercase'),
            getLocalizedString('marchAbbreviatedLowercase'),
            getLocalizedString('aprilAbbreviatedLowercase'),
            getLocalizedString('mayAbbreviatedLowercase'),
            getLocalizedString('juneAbbreviatedLowercase'),
            getLocalizedString('julyAbbreviatedLowercase'),
            getLocalizedString('augustAbbreviatedLowercase'),
            getLocalizedString('septemberAbbreviatedLowercase'),
            getLocalizedString('octoberAbbreviatedLowercase'),
            getLocalizedString('novemberAbbreviatedLowercase'),
            getLocalizedString('decemberAbbreviatedLowercase'),
        ],
        MMMM: [
            getLocalizedString('calendarMonth_January'),
            getLocalizedString('calendarMonth_February'),
            getLocalizedString('calendarMonth_March'),
            getLocalizedString('calendarMonth_April'),
            getLocalizedString('calendarMonth_May'),
            getLocalizedString('calendarMonth_June'),
            getLocalizedString('calendarMonth_July'),
            getLocalizedString('calendarMonth_August'),
            getLocalizedString('calendarMonth_September'),
            getLocalizedString('calendarMonth_October'),
            getLocalizedString('calendarMonth_November'),
            getLocalizedString('calendarMonth_December'),
        ],
        standaloneMMMM: [
            getLocalizedString('calendarMonthStandalone_January'),
            getLocalizedString('calendarMonthStandalone_February'),
            getLocalizedString('calendarMonthStandalone_March'),
            getLocalizedString('calendarMonthStandalone_April'),
            getLocalizedString('calendarMonthStandalone_May'),
            getLocalizedString('calendarMonthStandalone_June'),
            getLocalizedString('calendarMonthStandalone_July'),
            getLocalizedString('calendarMonthStandalone_August'),
            getLocalizedString('calendarMonthStandalone_September'),
            getLocalizedString('calendarMonthStandalone_October'),
            getLocalizedString('calendarMonthStandalone_November'),
            getLocalizedString('calendarMonthStandalone_December'),
        ],
        ap: [getLocalizedString('amLowercaseShort'), getLocalizedString('pmLowercaseShort')],
        ampm: [getLocalizedString('amLowercase'), getLocalizedString('pmLowercase')],
        AP: [getLocalizedString('amUppercaseShort'), getLocalizedString('pmUppercaseShort')],
        AMPM: [getLocalizedString('amUppercase'), getLocalizedString('pmUppercase')],
    };
}
