import { isGlobalSettingsMailbox } from 'owa-account-source-list-store';
import { logStartGreyError } from 'owa-analytics-start';
import type { MailboxInfo } from 'owa-client-types';
import { isRunningOnWorker } from 'owa-config';
import type { ReadOnlyAccountConfiguration } from '../store/schema/OwaSessionStore';
import { getAccountSettingsInternal } from '../utils/getAccountSettingsInternal';
import { makeEmptyAccountConfiguration } from '../utils/makeEmptyAccountConfiguration';

export const getAccountScopeUserSettings = (
    mailboxInfo: MailboxInfo,
    noGreyError?: boolean
): ReadOnlyAccountConfiguration => {
    const settings = getAccountSettingsInternal(mailboxInfo);
    if (!settings && !noGreyError && isRunningOnWorker()) {
        logStartGreyError('AccountScopeUserSettingsMissing', undefined /*error*/, {
            isGlobalSettingsMailbox: isGlobalSettingsMailbox(mailboxInfo),
        });
    }
    return settings ?? makeEmptyAccountConfiguration();
};
