import { getAdMarketPublishGroupCode, getMarketCountryCode } from './sharedAdsUtils';

// Brazil:  https://iapp.org/news/a/an-overview-of-brazils-lgpd/.
// China: https://www.cookielawinfo.com/china-pipl/.
// Turkey:
// Canada:
export default function isLgpdAdMarket() {
    const lgpdAdMarkets = ['BR', 'CA', 'CN', 'TR'];
    const countryCode = getMarketCountryCode(getAdMarketPublishGroupCode());
    return lgpdAdMarkets.indexOf(countryCode) >= 0;
}
