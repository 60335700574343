import type { DisplayDateParserConfig } from './DisplayDateParserConfig';
import type { OwaDate } from 'owa-datetime';
import {
    owaDate,
    userDate,
    getYear,
    getMonth,
    getDate,
    getHours,
    getMinutes,
    getSeconds,
    getMilliseconds,
} from 'owa-datetime';
import type WorkingHoursType from 'owa-service/lib/contract/WorkingHoursType';

export default function parseDisplayTimeRegExp(
    value: string,
    config: DisplayDateParserConfig,
    format: string,
    referenceDate?: OwaDate | null | undefined,
    workingHours?: WorkingHoursType
) {
    // null, undefined or empty strings are always a no-match.
    if (!value) {
        return null;
    }

    const matches = getMatches(value, config);

    if (matches == undefined) {
        return null;
    }

    // Start with the reference date.
    referenceDate = referenceDate || userDate(0, 0);
    const year = getYear(referenceDate);
    const month = getMonth(referenceDate);
    const date = getDate(referenceDate);
    let hour = getHours(referenceDate);
    let min = getMinutes(referenceDate);
    const sec = getSeconds(referenceDate);
    const ms = getMilliseconds(referenceDate);
    let pmHour: boolean | undefined;

    [hour, min, pmHour] = matches;
    hour = getHourInTimeRange(hour, pmHour, format, workingHours);

    // only return a date if the values expressed are in the range of possible date times.
    if (0 < date && date < 32 && 0 <= month && month < 12 && hour < 24 && min < 60 && sec < 60) {
        const displaydate = owaDate(referenceDate.tz, year, month, date, hour, min, sec, ms);
        if (getDate(displaydate) === date) {
            return displaydate;
        }
    }

    return null;
}

function getMatches(
    value: string,
    config: DisplayDateParserConfig
): [number, number, boolean?] | undefined {
    let hour = 0;
    let min = 0;
    const timeRegExp = new RegExp(
        '^([^\\s\\d]*)\\s*(\\d\\d?)\\s*((([^\\d]+)\\s*(\\d\\d?))|((\\d\\d)?))\\s*([^\\s\\d]*)$'
    );
    const match = timeRegExp.exec(value.trim());

    if (match) {
        // If value expresses that the first number matched is a minute instead of the default hour.
        const isMinute = match[5] ? config.durationIndicators[match[5].toUpperCase()] : undefined;
        if (isMinute) {
            min = parseInt(match[2]);
            hour = match[6] ? parseInt(match[6]) : match[3] ? parseInt(match[3]) : 0;
        } else {
            hour = parseInt(match[2]);
            min = match[6] ? parseInt(match[6]) : match[3] ? parseInt(match[3]) : 0;
        }

        const timeRange = match[9] ? match[9] : match[1] ? match[1] : undefined;

        return [hour, min, timeRange ? config.timeIndicators[timeRange] : undefined];
    }
    return undefined;
}

function getHourInTimeRange(
    hour: number,
    pmHour: boolean | undefined,
    format: string,
    workingHours?: WorkingHoursType
) {
    if (pmHour != undefined) {
        // If PM was expressed, adjust the hour.
        if (pmHour === true && hour < 12) {
            hour += 12;
        }

        // If AM was expressed and the hour is 12, adjust the hour
        if (pmHour === false && hour === 12) {
            hour = 0;
        }
        return hour;
    }
    // If neither AM or PM is expressed check if 24 hour notation
    const token = format.match(/tt|t/g);
    if (hour > 12 || token == undefined) {
        return hour;
    }

    // If no time period is expressed default to a time period within working hours
    if (workingHours?.WorkHoursStartTimeInMinutes && workingHours.WorkHoursEndTimeInMinutes) {
        if (
            hour < workingHours.WorkHoursStartTimeInMinutes / 60 &&
            hour + 12 <= workingHours.WorkHoursEndTimeInMinutes / 60
        ) {
            hour += 12;
        } else if (
            hour > workingHours.WorkHoursEndTimeInMinutes / 60 &&
            hour - 12 <= workingHours.WorkHoursStartTimeInMinutes / 60
        ) {
            hour -= 12;
        }
    }
    return hour;
}
