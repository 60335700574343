import { isBootFeatureEnabled } from 'owa-metatags';
import { action, mutatorAction } from 'satcheljs';
import { getLocalizedStringStore } from '../store/store';
import { fetchLocstringFile } from './fetchLocstringFile';
import { isGulping } from 'owa-config';
import { setRTL } from '@fluentui/utilities';
import { setItem } from 'owa-local-storage';
import { LocalStoreLocaleKey, clearSuccessfulLoadedUrls } from 'owa-localize-internal';
import type { WindowData, LocWindow } from 'owa-window-data';
import { getWindowData } from 'owa-window-data';
import { logUsage } from 'owa-analytics';
import { setCssDirection } from 'owa-inject-css';

// TODO: Update types
type WindowDataExtended = WindowData & LocWindow;

export async function setLocale(
    locale: string,
    dir: 'ltr' | 'rtl',
    culture?: string,
    userInitiated?: boolean
): Promise<void> {
    const wd: WindowDataExtended = getWindowData() as WindowDataExtended;

    // Normalize locale
    locale = locale.toLowerCase();
    const { currentLocale, currentCulture } = getLocalizedStringStore();
    if (currentLocale !== locale || (culture && currentCulture !== culture)) {
        setItem(self, LocalStoreLocaleKey, locale);
        changeLocaleInStore(locale, culture);

        let dirChanged = false;
        const doc = wd.document;
        if (doc && dir && doc.dir != dir) {
            dirChanged = true;
            doc.dir = dir;
        }
        if (userInitiated) {
            clearSuccessfulLoadedUrls();
            logUsage('LocaleChanged', { locale, dirChanged_1: dirChanged, dir_2: dir });
        }

        const isDirRtl = dir === 'rtl';
        setCssDirection(isDirRtl);

        // setRTL from utilities will set the cached variable, local storage as well as merging the styles.
        setRTL(isDirRtl, true);

        onLocaleChanged(locale);
        if (!isGulping()) {
            await wd._locStrings?.registerHandler(locale, url => fetchLocstringFile(locale, url));

            if (isBootFeatureEnabled('fwk-loc-single-v2')) {
                forceLocObserversToRerender();
            }
        }
    }
}

export const onLocaleChanged = action('ON_LOCALE_CHANGED', (locale: string) => ({ locale }));

const changeLocaleInStore = mutatorAction(
    'MUTATE_LOCSTRING_LOCALE',
    (locale: string, culture?: string) => {
        getLocalizedStringStore().currentLocale = locale;

        if (culture) {
            getLocalizedStringStore().currentCulture = culture;
        }
    }
);

export const forceLocObserversToRerender = mutatorAction('MUTATE_LOCSTRING_VERSION', () => {
    getLocalizedStringStore().version++;
});
