import type { CopilotThemeOptions } from 'owa-copilot-themes-types';
import type { CopilotThemeOptionState } from './schema/CopilotThemeOptionState';
import getDefaultThemeOptions from '../utils/getDefaultThemeOptions';
import getThemeOptionsFromPrimeSettings from '../utils/getThemeOptionsFromPrimeSettings';

let store: CopilotThemeOptionState;

export const getStore: () => CopilotThemeOptionState = () => {
    if (!store) {
        const themeOptionsSetting = getThemeOptionsFromPrimeSettings();
        const copilotThemeOptions: CopilotThemeOptions | undefined = themeOptionsSetting?.value
            ? JSON.parse(themeOptionsSetting.value)
            : undefined;

        store = {
            options: copilotThemeOptions || getDefaultThemeOptions(),
            timestamp: themeOptionsSetting?.Timestamp || '',
        };
    }

    return store;
};
