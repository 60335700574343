import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import { getAccountScopeUserSettings, getUserConfiguration } from 'owa-session-store';
import type { MailboxInfo } from 'owa-client-types';
import { isAddinMultiAccountEnabled } from 'owa-feature-flags';

export function getUserTypeString(mailboxInfo: MailboxInfo): string {
    return getTypeStringFromType(mailboxInfo);
}

function getTypeStringFromType(mailboxInfo: MailboxInfo): string {
    let userType;
    //This check is being added to avoid undefined mailbox being fetched from undefined adapter in OsfControlAdapter
    //This check and else part can be removed after this bug is fixed in VSO#226645
    if (mailboxInfo && isAddinMultiAccountEnabled()) {
        userType = getAccountScopeUserSettings(mailboxInfo).SessionSettings?.WebSessionType;
    } else {
        userType = getUserConfiguration().SessionSettings?.WebSessionType;
    }

    if (userType === 1) {
        return 'outlookCom';
    }
    if (userType === 2) {
        return 'gmail';
    }
    if (userType == 0) {
        return 'office365';
    }

    return 'unknown';
}
