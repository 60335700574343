import { mutatorAction } from 'satcheljs';
import type { MailboxFolderTreeData } from '../store/schema/FolderStore';
import getStore from '../store/store';
import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';

export const setMailboxFolderTreeData = mutatorAction(
    'setMailboxFolderTreeData',
    (mailboxInfo: MailboxInfo, mailboxFolderTreeData: MailboxFolderTreeData) => {
        getStore().mailboxFolderTreeData.set(
            getIndexerValueForMailboxInfo(mailboxInfo),
            mailboxFolderTreeData
        );
    }
);
