import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import { isOcpsPolicyEnabled, OcpsPolicy } from 'owa-ocps-policy-store';
import { getAccountScopeUserSettings } from 'owa-session-store';
import getAggregatePolicyInfo from './utils/getAggregatePolicyInfo';
import { isFeatureEnabled } from 'owa-feature-flags';
import {
    DiagnosticsFeature,
    getAggregateFeedbackSupportFeatureFlag,
} from './utils/getAggregateFeedbackSupportFeatureFlag';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 */
export const diagnosticsFeedbackCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return true; // by default
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        const policies = getAggregatePolicyInfo('isFeedbackEnabled');
        const isfeedbackPolicyEnabled = policies['isFeedbackEnabled'] as boolean;
        const { IsConsumerChild } = getAccountScopeUserSettings(
            mailboxInfo ?? getModuleContextMailboxInfo()
        );
        const enabledIfUndefined = true;
        const isOcpsFeedbackPolicyEnabled_depr = isOcpsPolicyEnabled(
            OcpsPolicy.Feedback,
            enabledIfUndefined
        );

        const isFeedbackEnabledByPolicy = isFeatureEnabled(
            'mon-diag-enableAggregatePolicyCheckForFeedback'
        )
            ? isfeedbackPolicyEnabled
            : isOcpsFeedbackPolicyEnabled_depr;

        return (
            !IsConsumerChild &&
            isFeedbackEnabledByPolicy &&
            getAggregateFeedbackSupportFeatureFlag(DiagnosticsFeature.Feedback)
        );
    },
};
