export const weekRangeSameMonthStart_dMy = "GZeRYb";
export const weekRangeSameMonthEnd_dMy = "XpEfBe";
export const weekRangeSameYearStart_dMy = "Hzqc4b";
export const weekRangeSameYearEnd_dMy = "OuYoqb";
export const weekRangeDifferentYearsStart_dMy = "hP8zNb";
export const weekRangeDifferentYearsEnd_dMy = "iDjlxd";
export const weekRangeSameMonthStart_Mdy = "Os0wLb";
export const weekRangeSameMonthEnd_Mdy = "dVnXne";
export const weekRangeSameYearStart_Mdy = "P4$TQb";
export const weekRangeSameYearEnd_Mdy = "WZH6cb";
export const weekRangeDifferentYearsStart_Mdy = "piSfAb";
export const weekRangeDifferentYearsEnd_Mdy = "q842jd";
export const weekRangeSameMonthStart_ydM = "GErc$b";
export const weekRangeSameMonthEnd_ydM = "X6QCNe";
export const weekRangeSameYearStart_ydM = "HeDzec";
export const weekRangeSameYearEnd_ydM = "O$_LCb";
export const weekRangeDifferentYearsStart_ydM = "hujXZb";
export const weekRangeDifferentYearsEnd_ydM = "iiwIJd";
export const weekRangeSameMonthStart_yMd = "M6C7_b";
export const weekRangeSameMonthEnd_yMd = "bx2vNe";
export const weekRangeSameYearStart_yMd = "NGOsec";
export const weekRangeSameYearEnd_yMd = "UBkFCb";
export const weekRangeDifferentYearsStart_yMd = "nWuQZb";
export const weekRangeDifferentYearsEnd_yMd = "oKHBJd";
export const weekRangeStartEnd = "IG7q_b";
export const weekRangeWeekNumber = "kkYhuc";
export const calendarMonthStandalone_January = "SP3XRd";
export const calendarMonthStandalone_February = "AG1M8d";
export const calendarMonthStandalone_March = "zl7nKe";
export const calendarMonthStandalone_April = "i5vxzc";
export const calendarMonthStandalone_May = "xrX0ye";
export const calendarMonthStandalone_June = "UcsWre";
export const calendarMonthStandalone_July = "IfSVre";
export const calendarMonthStandalone_August = "hoq1Ne";
export const calendarMonthStandalone_September = "XCukk";
export const calendarMonthStandalone_October = "UhVhbd";
export const calendarMonthStandalone_November = "gMX$L";
export const calendarMonthStandalone_December = "ZpaQHd";
export const amLowercaseShort = "TVAeZd";
export const pmLowercaseShort = "EpuFE";
export const amLowercase = "jAWAuc";
export const pmLowercase = "mmX6xc";
export const amUppercaseShort = "mFjcrb";
export const pmUppercaseShort = "tUh3Rc";
export const amUppercase = "OD5Zrd";
export const pmUppercase = "Rp6tvd";
export const minute = "iIoCDc";
export const minuteShort = "o35fYc";
export const minuteLetter = "YCtk_b";
export const userDateTimeFormat = "BZ_Zpc";
export const monthDayFormatDayFirst = "t_05db";
export const monthDayFormatMonthFirst = "luly5";
export const monthDayWeekDayFormatDayFirst = "X5jLfb";
export const monthDayWeekDayFormatMonthFirst = "j$0sGe";
export const MMMM = "Mx5KR";
export const monthDayYearFormat = "UJz90b";
export const yyyy = "_sJEeb";
export const H = "sba";
export const MMM = "XQ4sT";
export const d = "Uba";
export const MM = "OK1w";
export const dd = "AjQD";
export const dayWeekDayFormat = "tiO7he";
export const hT = "ue2E";
export const Th = "SC6y";
export const timeSeparator = "iyDHpd";
export const shortWeekDayMonthDayYearFormat = "YFVLMe";
export const weekDayDateTimeFormat = "YSkZ1d";
export const weekDayDateTimeWithAtFormat = "xk1S$d";
export const weekDayMonthDayFormat = "zdMRV";
export const weekDayMonthDayYearFormat = "_wK4ld";
export const weekDayTimeFormat = "_uDDVb";
export const yearMonthFormatYearFirst = "vwFbud";
export const yearMonthFormatMonthFirst = "CvhRJe";
export const M = "xba";
export const yy = "GW4J";
export const h = "Yba";
export const hh = "Oe2E";
export const HH = "KQwv";
export const m = "bca";
export const mm = "S_uG";
export const oneMinuteAgoText = "s$v1Hc";
export const minutesAgoText = "tMWktb";
export const yesterdayTime = "VWyh7d";
export const weekdayAtTime = "CcRb9";
export const oneHourAgoText = "UDO7Md";
export const hoursAgoText = "BY__Kb";
export const oneDayAgoText = "kXtv1";
export const daysAgoText = "hKc4Cc";
export const oneWeekAgoText = "Q7bD6";
export const weeksAgoText = "Zwk_hb";
export const oneMonthAgoText = "qctijd";
export const monthsAgoText = "XluTec";
export const oneYearAgoText = "fLz7U";
export const yearsAgoText = "OueDNb";
export default {weekRangeSameMonthStart_dMy,weekRangeSameMonthEnd_dMy,weekRangeSameYearStart_dMy,weekRangeSameYearEnd_dMy,weekRangeDifferentYearsStart_dMy,weekRangeDifferentYearsEnd_dMy,weekRangeSameMonthStart_Mdy,weekRangeSameMonthEnd_Mdy,weekRangeSameYearStart_Mdy,weekRangeSameYearEnd_Mdy,weekRangeDifferentYearsStart_Mdy,weekRangeDifferentYearsEnd_Mdy,weekRangeSameMonthStart_ydM,weekRangeSameMonthEnd_ydM,weekRangeSameYearStart_ydM,weekRangeSameYearEnd_ydM,weekRangeDifferentYearsStart_ydM,weekRangeDifferentYearsEnd_ydM,weekRangeSameMonthStart_yMd,weekRangeSameMonthEnd_yMd,weekRangeSameYearStart_yMd,weekRangeSameYearEnd_yMd,weekRangeDifferentYearsStart_yMd,weekRangeDifferentYearsEnd_yMd,weekRangeStartEnd,weekRangeWeekNumber,calendarMonthStandalone_January,calendarMonthStandalone_February,calendarMonthStandalone_March,calendarMonthStandalone_April,calendarMonthStandalone_May,calendarMonthStandalone_June,calendarMonthStandalone_July,calendarMonthStandalone_August,calendarMonthStandalone_September,calendarMonthStandalone_October,calendarMonthStandalone_November,calendarMonthStandalone_December,amLowercaseShort,pmLowercaseShort,amLowercase,pmLowercase,amUppercaseShort,pmUppercaseShort,amUppercase,pmUppercase,minute,minuteShort,minuteLetter,userDateTimeFormat,monthDayFormatDayFirst,monthDayFormatMonthFirst,monthDayWeekDayFormatDayFirst,monthDayWeekDayFormatMonthFirst,MMMM,monthDayYearFormat,yyyy,H,MMM,d,MM,dd,dayWeekDayFormat,hT,Th,timeSeparator,shortWeekDayMonthDayYearFormat,weekDayDateTimeFormat,weekDayDateTimeWithAtFormat,weekDayMonthDayFormat,weekDayMonthDayYearFormat,weekDayTimeFormat,yearMonthFormatYearFirst,yearMonthFormatMonthFirst,M,yy,h,hh,HH,m,mm,oneMinuteAgoText,minutesAgoText,yesterdayTime,weekdayAtTime,oneHourAgoText,hoursAgoText,oneDayAgoText,daysAgoText,oneWeekAgoText,weeksAgoText,oneMonthAgoText,monthsAgoText,oneYearAgoText,yearsAgoText};