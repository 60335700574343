import {
    addFdListener,
    FreezeDryItemTypes,
    EventTypes,
    lazyCreateOrUpdateMainWindowItem,
} from 'owa-freeze-dry';
import { createMainWindowItem } from './createMainWindowItem';

function handlePersistScrollPosition() {
    const mainWindow = createMainWindowItem();
    lazyCreateOrUpdateMainWindowItem.importAndExecute(mainWindow);
}

// Add the listener to 'fdEventManager'
export function initializePersistMailListener() {
    addFdListener(EventTypes.Persist, FreezeDryItemTypes.MainWindow, handlePersistScrollPosition);
}
