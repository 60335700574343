import getDisplayDateParserConfig from './getDisplayDateParserConfig';
import type { OwaDate } from 'owa-datetime';
import { getTimeFormat } from 'owa-datetime-store';
import parseDisplayTimeRegExp from './parseDisplayTimeRegExp';
import type WorkingHoursType from 'owa-service/lib/contract/WorkingHoursType';

/**
 * Parse a display date based on the user's selected time format.
 */
export default function parseUserDate(
    value: string,
    referenceDate?: OwaDate,
    workingHours?: WorkingHoursType
): OwaDate | null {
    const config = getDisplayDateParserConfig();
    return parseDisplayTimeRegExp(value, config, getTimeFormat(), referenceDate, workingHours);
}
