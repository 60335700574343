import React from 'react';
import {
    ThemeProvider,
    type Theme,
    type PartialTheme,
    type ThemeProviderProps,
} from '@fluentui/react/lib/Theme';
import { observer } from 'owa-mobx-react';
import { calendarMacFontsCapability } from 'owa-capabilities-definitions/lib/calendarMacFontsCapability';
import { isCapabilityEnabled } from 'owa-capabilities';
import { mergeThemes } from '@fluentui/react';
import { fontFamilyForMac } from './fontFamilyForMac';

interface OwaThemeProviderProps extends ThemeProviderProps {
    theme?: Theme;
    partialTheme?: PartialTheme;
}

export const macFontStyleV8 = {
    defaultFontStyle: {
        fontFamily: fontFamilyForMac,
    },
} as const;

const defaultFontStyle = {};

export const OwaThemeProvider = observer(
    React.forwardRef(function OwaThemeProvider(
        props: OwaThemeProviderProps,
        ref: React.Ref<HTMLElement>
    ) {
        const { children, applyTo, as, theme, partialTheme, className, id } = props;
        const showMacFonts = isCapabilityEnabled(calendarMacFontsCapability);
        const fontStyleV8 = showMacFonts ? macFontStyleV8 : defaultFontStyle;

        // Merge fontStyleV8 with partialTheme if provided
        const themeOverrides = partialTheme ? { ...fontStyleV8, ...partialTheme } : fontStyleV8;

        // Merge the resulting themeOverrides with the base theme if provided
        const mergedTheme = theme ? mergeThemes(theme, themeOverrides) : themeOverrides;

        return (
            <ThemeProvider
                applyTo={applyTo}
                as={as}
                ref={ref}
                theme={mergedTheme}
                className={className}
                id={id}
            >
                {children}
            </ThemeProvider>
        );
    }),
    'OwaThemeProvider'
);
