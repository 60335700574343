import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "NativeOutlookHost"*/ './lazyIndex')
);

export const lazyGetHostCapabilitiesProxy = new LazyAction(
    lazyModule,
    m => m.getHostCapabilitiesProxy
);
