import { lazyAddinCommandSurfaceItemsObserver } from 'owa-addins-core/lib/lazyFunctions';
import type { AddinCommandSurfaceItem, ExtensibilityModeEnum } from 'owa-addins-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';
import { trace } from 'owa-trace';

export default function getAddinCollection(
    mode: ExtensibilityModeEnum,
    isSharedItem: boolean,
    hostItemIndex: string,
    mailboxInfo: MailboxInfo,
    targetWindow: Window
): AddinCommandSurfaceItem[] {
    const addins: AddinCommandSurfaceItem[] = [];
    const addinObserver = lazyAddinCommandSurfaceItemsObserver.tryImportForRender();
    if (addinObserver) {
        const addinSurfaceItems = addinObserver(
            mode,
            isSharedItem,
            hostItemIndex,
            mailboxInfo,
            targetWindow
        ).get();

        if (addinSurfaceItems?.sectionProps) {
            addins.push(
                ...addinSurfaceItems.sectionProps.items.filter(
                    addin => !(addin.name == 'FindTime' && isFeatureEnabled('cal-native-findtime'))
                )
            );
        }
    } else {
        trace.warn('Addin collection getter not available in Mail', 'addins');
    }

    return addins;
}
