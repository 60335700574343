import { mutatorAction } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import { getGroupViewState } from '../selectors/getGroupViewState';

/**
 * Expands the group folder hierarchy
 * @param groupId - smtp address of a group
 * @param folderId - Id of the group folder
 * @param mailboxInfo - mailbox info of the account
 */
export default mutatorAction(
    'setGroupFolderViewExpanded',
    (groupId: string, folderId: string, mailboxInfo: MailboxInfo): void => {
        const groupViewState = getGroupViewState(groupId, mailboxInfo);
        if (groupViewState) {
            groupViewState.isExpanded = true;
            const groupFolderViewState = groupViewState.groupFolderNodeViewStates.get(folderId);
            if (groupFolderViewState) {
                groupFolderViewState.isExpanded = true;
            }
        }
    }
);
