import { LazyAction, LazyBootModule } from 'owa-bundling-light';

const lazyBootModule = new LazyBootModule(
    () => import(/* webpackChunkName: "StartAccountData" */ './lazyIndex'),
    { name: 'StartAccountData' }
);

export const lazyGetStartAccountData = new LazyAction(lazyBootModule, m => m.getStartAccountData);
export const lazySetStartAccountDataOverride = new LazyAction(
    lazyBootModule,
    m => m.setStartAccountDataOverride
);

export type { GetStartAccountDataOverride } from './GetStartAccountDataOverride';
