export function bindTypename<TItem, TTypename>(
    item: TItem | null | undefined,
    name: TTypename,
    _subTransform?: undefined
):
    | (TItem & {
          __typename: TTypename;
      })
    | null;
export function bindTypename<TItem, TTypename, TItemOut = TItem>(
    item: TItem | null | undefined,
    name: TTypename,
    subTransform?: (i: TItem) => TItemOut
):
    | (TItemOut & {
          __typename: TTypename;
      })
    | null;
export function bindTypename(item: any, name: any, subTransform: any): any {
    return item === null || item === undefined
        ? null
        : {
              __typename: name,
              ...(subTransform ? subTransform(item) : item),
          };
}
