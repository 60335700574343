import isDeepLink from 'owa-url/lib/isDeepLink';

/**
 * Checks whether pinning a Pane is available in current OWA user/window context.
 * It disables pinning if OWA was opened from a deep link or from a window.open()
 * or target link that have an opener attribute.
 */
export function isPinnablePaneAvailable(): boolean {
    if (isDeepLink() && window.opener) {
        return false;
    }
    return true;
}
