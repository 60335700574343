import type {
    RefetchQueriesOptions,
    ApolloQueryResult,
    ApolloCache,
    NormalizedCacheObject,
} from '@apollo/client';
import { getApolloClientInternal } from './apolloClient';

export function refetchQueries<
    TCache extends ApolloCache<any> = ApolloCache<NormalizedCacheObject>,
    TResult = Promise<ApolloQueryResult<any>>
>(options: RefetchQueriesOptions<TCache, TResult>) {
    return getApolloClientInternal().refetchQueries(options);
}
