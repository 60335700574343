//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import * as Types from 'owa-graph-schema';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AddInRequirementsFragment = {
    __typename?: 'Requirements';
    scopes?: Array<string> | null;
    capabilities?: Array<{
        __typename?: 'Capability';
        name?: string | null;
        minVersion?: string | null;
        maxVersion?: string | null;
    } | null> | null;
};
export type AddInIconFragment = {
    __typename?: 'AddInExtensionTabIcon';
    size?: any | null;
    url?: string | null;
};
export type AddInControl_AddInButtonExecuteFunction_Fragment = {
    __typename?: 'AddInButtonExecuteFunction';
    id: string;
    type: string;
    label?: string | null;
    tooltip?: string | null;
    enabled?: boolean | null;
    icons?: Array<{
        __typename?: 'AddInExtensionTabIcon';
        size?: any | null;
        url?: string | null;
    } | null> | null;
    supertip?: {
        __typename?: 'Supertip';
        title?: string | null;
        description?: string | null;
    } | null;
};
export type AddInControl_AddInButtonShowTaskPane_Fragment = {
    __typename?: 'AddInButtonShowTaskPane';
    id: string;
    type: string;
    label?: string | null;
    tooltip?: string | null;
    enabled?: boolean | null;
    icons?: Array<{
        __typename?: 'AddInExtensionTabIcon';
        size?: any | null;
        url?: string | null;
    } | null> | null;
    supertip?: {
        __typename?: 'Supertip';
        title?: string | null;
        description?: string | null;
    } | null;
};
export type AddInControl_AddInControlMenu_Fragment = {
    __typename?: 'AddInControlMenu';
    id: string;
    type: string;
    label?: string | null;
    tooltip?: string | null;
    enabled?: boolean | null;
    icons?: Array<{
        __typename?: 'AddInExtensionTabIcon';
        size?: any | null;
        url?: string | null;
    } | null> | null;
    supertip?: {
        __typename?: 'Supertip';
        title?: string | null;
        description?: string | null;
    } | null;
};
export type AddInControlFragment =
    | AddInControl_AddInButtonExecuteFunction_Fragment
    | AddInControl_AddInButtonShowTaskPane_Fragment
    | AddInControl_AddInControlMenu_Fragment;
export type AddInExtensionFragmentFragment = {
    __typename?: 'AddInExtension';
    audienceClaimUrl?: string | null;
    name?: string | null;
    permissions?: Array<string | null> | null;
    requirements?: {
        __typename?: 'Requirements';
        scopes?: Array<string> | null;
        capabilities?: Array<{
            __typename?: 'Capability';
            name?: string | null;
            minVersion?: string | null;
            maxVersion?: string | null;
        } | null> | null;
    } | null;
    runtimes?: Array<{
        __typename?: 'Runtime';
        id: string;
        type: string;
        lifetime?: string | null;
        code?: {
            __typename?: 'Code';
            page: string;
            script?: string | null;
        } | null;
        actions?: Array<{
            __typename?: 'RuntimeContextualLaunch';
            id: string;
            type: string;
            view?: string | null;
            name?: string | null;
            pinnable?: boolean | null;
            displayName?: string | null;
        } | null> | null;
    } | null> | null;
    appDeeplinks?: Array<{
        __typename?: 'AppDeeplink';
        contexts?: Array<string | null> | null;
        actionId?: string | null;
        label?: string | null;
        semanticDescription?: string | null;
        requirements?: {
            __typename?: 'Requirements';
            scopes?: Array<string> | null;
            capabilities?: Array<{
                __typename?: 'Capability';
                name?: string | null;
                minVersion?: string | null;
                maxVersion?: string | null;
            } | null> | null;
        } | null;
    } | null> | null;
    ribbons?: Array<{
        __typename?: 'Ribbon';
        contexts?: Array<string | null> | null;
        requirements?: {
            __typename?: 'Requirements';
            scopes?: Array<string> | null;
            capabilities?: Array<{
                __typename?: 'Capability';
                name?: string | null;
                minVersion?: string | null;
                maxVersion?: string | null;
            } | null> | null;
        } | null;
        tabs: Array<{
            __typename?: 'AddInExtensionTab';
            id?: string | null;
            nativeId?: string | null;
            groups: Array<{
                __typename?: 'AddInExtensionTabGroup';
                id?: string | null;
                label?: string | null;
                icons?: Array<{
                    __typename?: 'AddInExtensionTabIcon';
                    size?: any | null;
                    url?: string | null;
                } | null> | null;
                controls: Array<
                    | {
                          __typename?: 'AddInButtonExecuteFunction';
                          functionName?: string | null;
                          functionFile?: string | null;
                          actionId?: string | null;
                          id: string;
                          type: string;
                          label?: string | null;
                          tooltip?: string | null;
                          enabled?: boolean | null;
                          icons?: Array<{
                              __typename?: 'AddInExtensionTabIcon';
                              size?: any | null;
                              url?: string | null;
                          } | null> | null;
                          supertip?: {
                              __typename?: 'Supertip';
                              title?: string | null;
                              description?: string | null;
                          } | null;
                      }
                    | {
                          __typename?: 'AddInButtonShowTaskPane';
                          sourceLocation?: string | null;
                          supportsPersistence?: boolean | null;
                          actionId?: string | null;
                          id: string;
                          type: string;
                          label?: string | null;
                          tooltip?: string | null;
                          enabled?: boolean | null;
                          icons?: Array<{
                              __typename?: 'AddInExtensionTabIcon';
                              size?: any | null;
                              url?: string | null;
                          } | null> | null;
                          supertip?: {
                              __typename?: 'Supertip';
                              title?: string | null;
                              description?: string | null;
                          } | null;
                      }
                    | {
                          __typename?: 'AddInControlMenu';
                          id: string;
                          type: string;
                          label?: string | null;
                          tooltip?: string | null;
                          enabled?: boolean | null;
                          items?: Array<
                              | {
                                    __typename?: 'AddInButtonExecuteFunction';
                                    functionName?: string | null;
                                    functionFile?: string | null;
                                    actionId?: string | null;
                                    id: string;
                                    type: string;
                                    label?: string | null;
                                    tooltip?: string | null;
                                    enabled?: boolean | null;
                                    icons?: Array<{
                                        __typename?: 'AddInExtensionTabIcon';
                                        size?: any | null;
                                        url?: string | null;
                                    } | null> | null;
                                    supertip?: {
                                        __typename?: 'Supertip';
                                        title?: string | null;
                                        description?: string | null;
                                    } | null;
                                }
                              | {
                                    __typename?: 'AddInButtonShowTaskPane';
                                    sourceLocation?: string | null;
                                    supportsPersistence?: boolean | null;
                                    actionId?: string | null;
                                    id: string;
                                    type: string;
                                    label?: string | null;
                                    tooltip?: string | null;
                                    enabled?: boolean | null;
                                    icons?: Array<{
                                        __typename?: 'AddInExtensionTabIcon';
                                        size?: any | null;
                                        url?: string | null;
                                    } | null> | null;
                                    supertip?: {
                                        __typename?: 'Supertip';
                                        title?: string | null;
                                        description?: string | null;
                                    } | null;
                                }
                              | null
                          > | null;
                          icons?: Array<{
                              __typename?: 'AddInExtensionTabIcon';
                              size?: any | null;
                              url?: string | null;
                          } | null> | null;
                          supertip?: {
                              __typename?: 'Supertip';
                              title?: string | null;
                              description?: string | null;
                          } | null;
                      }
                    | null
                >;
            } | null>;
        } | null>;
    } | null> | null;
    autoRunEvents?: Array<{
        __typename?: 'AutoRunEventDefinition';
        requirements?: {
            __typename?: 'Requirements';
            scopes?: Array<string> | null;
            capabilities?: Array<{
                __typename?: 'Capability';
                name?: string | null;
                minVersion?: string | null;
                maxVersion?: string | null;
            } | null> | null;
        } | null;
        events?: Array<{
            __typename?: 'AutoRunEvent';
            id?: string | null;
            type?: string | null;
            actionId?: string | null;
            options?: {
                __typename?: 'AutoRunEventOption';
                sendMode?: Types.LaunchEventSendMode | null;
            } | null;
        } | null> | null;
    }> | null;
    alternates?: Array<{
        __typename?: 'Alternate';
        requirements?: {
            __typename?: 'Requirements';
            scopes?: Array<string> | null;
            capabilities?: Array<{
                __typename?: 'Capability';
                name?: string | null;
                minVersion?: string | null;
                maxVersion?: string | null;
            } | null> | null;
        } | null;
        hide?: {
            __typename?: 'Hide';
            storeOfficeAddin?: {
                __typename?: 'OfficeAddinDetails';
                officeAddinId?: string | null;
                assetId?: string | null;
            } | null;
            customOfficeAddin?: {
                __typename?: 'OfficeAddinDetails';
                officeAddinId?: string | null;
            } | null;
        } | null;
    } | null> | null;
};
export type MessageExtensionFragmentFragment = {
    __typename?: 'MessageExtension';
    botId?: string | null;
    canUpdateConfiguration?: boolean | null;
    scopes?: Array<Types.AppScope | null> | null;
    commandTypes?: Array<Types.InputExtensionCommandType | null> | null;
    commands?: Array<{
        __typename?: 'InputExtensionCommand';
        context?: Array<Types.InputExtensionCommandContext | null> | null;
        fetchTask?: boolean | null;
        description?: string | null;
        id?: string | null;
        initialRun?: boolean | null;
        title?: string | null;
        type?: Types.InputExtensionCommandType | null;
        parameters?: Array<{
            __typename?: 'InputExtensionCommandParameter';
            name?: string | null;
            title?: string | null;
            description?: string | null;
            inputType?: Types.InputExtensionParameterType | null;
            value?: string | null;
            choices?: Array<{
                __typename?: 'InputExtensionCommandChoiceParameter';
                title?: string | null;
                value?: string | null;
            } | null> | null;
        } | null> | null;
        taskInfo?: {
            __typename?: 'InputExtensionCommandTaskInfo';
            title?: string | null;
            url?: string | null;
            width?: string | null;
            height?: string | null;
        } | null;
    } | null> | null;
    messageHandlers?: Array<{
        __typename?: 'InputExtensionMessageHandler';
        value?: {
            __typename?: 'InputExtensionMessageHandlerValue';
            domains?: Array<string | null> | null;
        } | null;
    } | null> | null;
};
export type M365AcquisitionsQueryVariables = Types.Exact<{
    mailboxInfo?: Types.InputMaybe<Types.MailboxInfoInput>;
}>;
export type M365AcquisitionsQuery = {
    __typename?: 'Query';
    m365Acquisitions: {
        __typename: 'M365AcquisitionsQueryResult';
        nextInterval: any;
        edges: Array<{
            __typename: 'M365Acquisition';
            appId?: string | null;
            titleId: string;
            acquisitionState: Types.AcquisitionState;
            acquisitionContext?: Types.AcquisitionContext | null;
            canBeUnacquired?: boolean | null;
            manifestId?: string | null;
            titleDefinition: {
                __typename: 'Title';
                name: string;
                acquisitionDate?: string | null;
                accentColor?: string | null;
                ingestionId: string;
                blockStatus?: boolean | null;
                ingestionSource?: Types.IngestionSource | null;
                categories?: Array<string> | null;
                developerName?: string | null;
                developerUrl?: string | null;
                isFullTrust?: boolean | null;
                isFullScreen?: boolean | null;
                shortDescription?: string | null;
                longDescription?: string | null;
                permissions?: Array<string> | null;
                showLoadingIndicator?: boolean | null;
                scope?: Types.TitleScope | null;
                cultureName?: string | null;
                termsOfUseUrl?: string | null;
                validDomains?: Array<string> | null;
                version?: string | null;
                manifestVersion?: string | null;
                copilotEnabled?: boolean | null;
                elementDefinitions?: {
                    __typename?: 'ElementDefinition';
                    composeExtensions?: Array<{
                        __typename?: 'MessageExtension';
                        botId?: string | null;
                        canUpdateConfiguration?: boolean | null;
                        scopes?: Array<Types.AppScope | null> | null;
                        commandTypes?: Array<Types.InputExtensionCommandType | null> | null;
                        commands?: Array<{
                            __typename?: 'InputExtensionCommand';
                            context?: Array<Types.InputExtensionCommandContext | null> | null;
                            fetchTask?: boolean | null;
                            description?: string | null;
                            id?: string | null;
                            initialRun?: boolean | null;
                            title?: string | null;
                            type?: Types.InputExtensionCommandType | null;
                            parameters?: Array<{
                                __typename?: 'InputExtensionCommandParameter';
                                name?: string | null;
                                title?: string | null;
                                description?: string | null;
                                inputType?: Types.InputExtensionParameterType | null;
                                value?: string | null;
                                choices?: Array<{
                                    __typename?: 'InputExtensionCommandChoiceParameter';
                                    title?: string | null;
                                    value?: string | null;
                                } | null> | null;
                            } | null> | null;
                            taskInfo?: {
                                __typename?: 'InputExtensionCommandTaskInfo';
                                title?: string | null;
                                url?: string | null;
                                width?: string | null;
                                height?: string | null;
                            } | null;
                        } | null> | null;
                        messageHandlers?: Array<{
                            __typename?: 'InputExtensionMessageHandler';
                            value?: {
                                __typename?: 'InputExtensionMessageHandlerValue';
                                domains?: Array<string | null> | null;
                            } | null;
                        } | null> | null;
                    }> | null;
                    exchangeAddIns?: Array<{
                        __typename?: 'AddInExchange';
                        xmlDefinition: string;
                    }> | null;
                    extensions?: Array<{
                        __typename?: 'AddInExtension';
                        audienceClaimUrl?: string | null;
                        name?: string | null;
                        permissions?: Array<string | null> | null;
                        requirements?: {
                            __typename?: 'Requirements';
                            scopes?: Array<string> | null;
                            capabilities?: Array<{
                                __typename?: 'Capability';
                                name?: string | null;
                                minVersion?: string | null;
                                maxVersion?: string | null;
                            } | null> | null;
                        } | null;
                        runtimes?: Array<{
                            __typename?: 'Runtime';
                            id: string;
                            type: string;
                            lifetime?: string | null;
                            code?: {
                                __typename?: 'Code';
                                page: string;
                                script?: string | null;
                            } | null;
                            actions?: Array<{
                                __typename?: 'RuntimeContextualLaunch';
                                id: string;
                                type: string;
                                view?: string | null;
                                name?: string | null;
                                pinnable?: boolean | null;
                                displayName?: string | null;
                            } | null> | null;
                        } | null> | null;
                        appDeeplinks?: Array<{
                            __typename?: 'AppDeeplink';
                            contexts?: Array<string | null> | null;
                            actionId?: string | null;
                            label?: string | null;
                            semanticDescription?: string | null;
                            requirements?: {
                                __typename?: 'Requirements';
                                scopes?: Array<string> | null;
                                capabilities?: Array<{
                                    __typename?: 'Capability';
                                    name?: string | null;
                                    minVersion?: string | null;
                                    maxVersion?: string | null;
                                } | null> | null;
                            } | null;
                        } | null> | null;
                        ribbons?: Array<{
                            __typename?: 'Ribbon';
                            contexts?: Array<string | null> | null;
                            requirements?: {
                                __typename?: 'Requirements';
                                scopes?: Array<string> | null;
                                capabilities?: Array<{
                                    __typename?: 'Capability';
                                    name?: string | null;
                                    minVersion?: string | null;
                                    maxVersion?: string | null;
                                } | null> | null;
                            } | null;
                            tabs: Array<{
                                __typename?: 'AddInExtensionTab';
                                id?: string | null;
                                nativeId?: string | null;
                                groups: Array<{
                                    __typename?: 'AddInExtensionTabGroup';
                                    id?: string | null;
                                    label?: string | null;
                                    icons?: Array<{
                                        __typename?: 'AddInExtensionTabIcon';
                                        size?: any | null;
                                        url?: string | null;
                                    } | null> | null;
                                    controls: Array<
                                        | {
                                              __typename?: 'AddInButtonExecuteFunction';
                                              functionName?: string | null;
                                              functionFile?: string | null;
                                              actionId?: string | null;
                                              id: string;
                                              type: string;
                                              label?: string | null;
                                              tooltip?: string | null;
                                              enabled?: boolean | null;
                                              icons?: Array<{
                                                  __typename?: 'AddInExtensionTabIcon';
                                                  size?: any | null;
                                                  url?: string | null;
                                              } | null> | null;
                                              supertip?: {
                                                  __typename?: 'Supertip';
                                                  title?: string | null;
                                                  description?: string | null;
                                              } | null;
                                          }
                                        | {
                                              __typename?: 'AddInButtonShowTaskPane';
                                              sourceLocation?: string | null;
                                              supportsPersistence?: boolean | null;
                                              actionId?: string | null;
                                              id: string;
                                              type: string;
                                              label?: string | null;
                                              tooltip?: string | null;
                                              enabled?: boolean | null;
                                              icons?: Array<{
                                                  __typename?: 'AddInExtensionTabIcon';
                                                  size?: any | null;
                                                  url?: string | null;
                                              } | null> | null;
                                              supertip?: {
                                                  __typename?: 'Supertip';
                                                  title?: string | null;
                                                  description?: string | null;
                                              } | null;
                                          }
                                        | {
                                              __typename?: 'AddInControlMenu';
                                              id: string;
                                              type: string;
                                              label?: string | null;
                                              tooltip?: string | null;
                                              enabled?: boolean | null;
                                              items?: Array<
                                                  | {
                                                        __typename?: 'AddInButtonExecuteFunction';
                                                        functionName?: string | null;
                                                        functionFile?: string | null;
                                                        actionId?: string | null;
                                                        id: string;
                                                        type: string;
                                                        label?: string | null;
                                                        tooltip?: string | null;
                                                        enabled?: boolean | null;
                                                        icons?: Array<{
                                                            __typename?: 'AddInExtensionTabIcon';
                                                            size?: any | null;
                                                            url?: string | null;
                                                        } | null> | null;
                                                        supertip?: {
                                                            __typename?: 'Supertip';
                                                            title?: string | null;
                                                            description?: string | null;
                                                        } | null;
                                                    }
                                                  | {
                                                        __typename?: 'AddInButtonShowTaskPane';
                                                        sourceLocation?: string | null;
                                                        supportsPersistence?: boolean | null;
                                                        actionId?: string | null;
                                                        id: string;
                                                        type: string;
                                                        label?: string | null;
                                                        tooltip?: string | null;
                                                        enabled?: boolean | null;
                                                        icons?: Array<{
                                                            __typename?: 'AddInExtensionTabIcon';
                                                            size?: any | null;
                                                            url?: string | null;
                                                        } | null> | null;
                                                        supertip?: {
                                                            __typename?: 'Supertip';
                                                            title?: string | null;
                                                            description?: string | null;
                                                        } | null;
                                                    }
                                                  | null
                                              > | null;
                                              icons?: Array<{
                                                  __typename?: 'AddInExtensionTabIcon';
                                                  size?: any | null;
                                                  url?: string | null;
                                              } | null> | null;
                                              supertip?: {
                                                  __typename?: 'Supertip';
                                                  title?: string | null;
                                                  description?: string | null;
                                              } | null;
                                          }
                                        | null
                                    >;
                                } | null>;
                            } | null>;
                        } | null> | null;
                        autoRunEvents?: Array<{
                            __typename?: 'AutoRunEventDefinition';
                            requirements?: {
                                __typename?: 'Requirements';
                                scopes?: Array<string> | null;
                                capabilities?: Array<{
                                    __typename?: 'Capability';
                                    name?: string | null;
                                    minVersion?: string | null;
                                    maxVersion?: string | null;
                                } | null> | null;
                            } | null;
                            events?: Array<{
                                __typename?: 'AutoRunEvent';
                                id?: string | null;
                                type?: string | null;
                                actionId?: string | null;
                                options?: {
                                    __typename?: 'AutoRunEventOption';
                                    sendMode?: Types.LaunchEventSendMode | null;
                                } | null;
                            } | null> | null;
                        }> | null;
                        alternates?: Array<{
                            __typename?: 'Alternate';
                            requirements?: {
                                __typename?: 'Requirements';
                                scopes?: Array<string> | null;
                                capabilities?: Array<{
                                    __typename?: 'Capability';
                                    name?: string | null;
                                    minVersion?: string | null;
                                    maxVersion?: string | null;
                                } | null> | null;
                            } | null;
                            hide?: {
                                __typename?: 'Hide';
                                storeOfficeAddin?: {
                                    __typename?: 'OfficeAddinDetails';
                                    officeAddinId?: string | null;
                                    assetId?: string | null;
                                } | null;
                                customOfficeAddin?: {
                                    __typename?: 'OfficeAddinDetails';
                                    officeAddinId?: string | null;
                                } | null;
                            } | null;
                        } | null> | null;
                    }> | null;
                    staticTabs?: Array<{
                        __typename: 'LaunchPage';
                        contentUrl?: string | null;
                        entityId?: string | null;
                        name?: string | null;
                        scopes?: Array<Types.AppScope | null> | null;
                        websiteUrl?: string | null;
                    }> | null;
                } | null;
                iconSmall?: {
                    __typename?: 'M365AcquisitionIcon';
                    uri: string;
                } | null;
                iconLarge?: {
                    __typename?: 'M365AcquisitionIcon';
                    uri: string;
                } | null;
                iconOutline?: {
                    __typename?: 'M365AcquisitionIcon';
                    uri: string;
                } | null;
                authorization?: {
                    __typename?: 'AppAuthorization';
                    permissions?: {
                        __typename?: 'AppPermissions';
                        orgWide?: Array<{
                            __typename?: 'AppPermissionsNode';
                            name?: string | null;
                            type?: Types.AppPermissionsNodeType | null;
                        } | null> | null;
                        resourceSpecific?: Array<{
                            __typename?: 'AppPermissionsNode';
                            name?: string | null;
                            type?: Types.AppPermissionsNodeType | null;
                        } | null> | null;
                    } | null;
                } | null;
                webApplicationInfo?: {
                    __typename?: 'WebApplicationInfo';
                    id?: string | null;
                    resource?: string | null;
                } | null;
                copilotSettings?: {
                    __typename?: 'CopilotSettings';
                    elementType: Types.ElementType;
                    displayName: string;
                    capabilities?: {
                        __typename?: 'Capabilities';
                        emailSummary?: {
                            __typename?: 'EmailCapability';
                            pluginId: string;
                            functionName: string;
                        } | null;
                        emailDraft?: {
                            __typename?: 'EmailCapability';
                            pluginId: string;
                            functionName: string;
                        } | null;
                        emailReplySuggestions?: {
                            __typename?: 'EmailCapability';
                            pluginId: string;
                            functionName: string;
                        } | null;
                    } | null;
                } | null;
            };
        }>;
    };
};
export const AddInRequirementsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AddInRequirements' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Requirements' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'scopes' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'capabilities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'minVersion' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maxVersion' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AddInRequirementsFragment, unknown>;
export const AddInIconFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AddInIcon' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'AddInExtensionTabIcon' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AddInIconFragment, unknown>;
export const AddInControlFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AddInControl' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'AddInExtensionControl' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'icons' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'AddInIcon' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'tooltip' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'supertip' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                ],
            },
        },
        ...AddInIconFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<AddInControlFragment, unknown>;
export const AddInExtensionFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AddInExtensionFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AddInExtension' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'audienceClaimUrl' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requirements' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'AddInRequirements' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'runtimes' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'page' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'script' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'lifetime' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'actions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'view' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pinnable' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'displayName' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'appDeeplinks' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'requirements' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'AddInRequirements' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'contexts' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actionId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'semanticDescription' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ribbons' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'requirements' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'AddInRequirements' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'contexts' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tabs' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'nativeId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'groups' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'label' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'icons' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'FragmentSpread',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'AddInIcon',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'controls',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'InlineFragment',
                                                                        typeCondition: {
                                                                            kind: 'NamedType',
                                                                            name: {
                                                                                kind: 'Name',
                                                                                value: 'AddInButtonShowTaskPane',
                                                                            },
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'FragmentSpread',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'AddInControl',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'sourceLocation',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'supportsPersistence',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'actionId',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'InlineFragment',
                                                                        typeCondition: {
                                                                            kind: 'NamedType',
                                                                            name: {
                                                                                kind: 'Name',
                                                                                value: 'AddInButtonExecuteFunction',
                                                                            },
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'FragmentSpread',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'AddInControl',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'functionName',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'functionFile',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'actionId',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'InlineFragment',
                                                                        typeCondition: {
                                                                            kind: 'NamedType',
                                                                            name: {
                                                                                kind: 'Name',
                                                                                value: 'AddInControlMenu',
                                                                            },
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'FragmentSpread',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'AddInControl',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'items',
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections:
                                                                                            [
                                                                                                {
                                                                                                    kind: 'InlineFragment',
                                                                                                    typeCondition:
                                                                                                        {
                                                                                                            kind: 'NamedType',
                                                                                                            name: {
                                                                                                                kind: 'Name',
                                                                                                                value: 'AddInButtonShowTaskPane',
                                                                                                            },
                                                                                                        },
                                                                                                    selectionSet:
                                                                                                        {
                                                                                                            kind: 'SelectionSet',
                                                                                                            selections:
                                                                                                                [
                                                                                                                    {
                                                                                                                        kind: 'FragmentSpread',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'AddInControl',
                                                                                                                        },
                                                                                                                    },
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'sourceLocation',
                                                                                                                        },
                                                                                                                    },
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'supportsPersistence',
                                                                                                                        },
                                                                                                                    },
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'actionId',
                                                                                                                        },
                                                                                                                    },
                                                                                                                ],
                                                                                                        },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'InlineFragment',
                                                                                                    typeCondition:
                                                                                                        {
                                                                                                            kind: 'NamedType',
                                                                                                            name: {
                                                                                                                kind: 'Name',
                                                                                                                value: 'AddInButtonExecuteFunction',
                                                                                                            },
                                                                                                        },
                                                                                                    selectionSet:
                                                                                                        {
                                                                                                            kind: 'SelectionSet',
                                                                                                            selections:
                                                                                                                [
                                                                                                                    {
                                                                                                                        kind: 'FragmentSpread',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'AddInControl',
                                                                                                                        },
                                                                                                                    },
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'functionName',
                                                                                                                        },
                                                                                                                    },
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'functionFile',
                                                                                                                        },
                                                                                                                    },
                                                                                                                    {
                                                                                                                        kind: 'Field',
                                                                                                                        name: {
                                                                                                                            kind: 'Name',
                                                                                                                            value: 'actionId',
                                                                                                                        },
                                                                                                                    },
                                                                                                                ],
                                                                                                        },
                                                                                                },
                                                                                            ],
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'autoRunEvents' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'requirements' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'AddInRequirements' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'events' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'actionId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'options' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'sendMode',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'alternates' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'requirements' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'AddInRequirements' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hide' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'storeOfficeAddin' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'officeAddinId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'assetId',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'customOfficeAddin' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'officeAddinId',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...AddInRequirementsFragmentDoc.definitions,
        ...AddInIconFragmentDoc.definitions,
        ...AddInControlFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<AddInExtensionFragmentFragment, unknown>;
export const MessageExtensionFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MessageExtensionFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageExtension' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'botId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'canUpdateConfiguration' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'scopes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'commandTypes' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'commands' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fetchTask' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'initialRun' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parameters' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'description' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'inputType' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'choices' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'title' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'value' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'taskInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'width' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'height' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messageHandlers' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'domains' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<MessageExtensionFragmentFragment, unknown>;
export const M365AcquisitionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'M365Acquisitions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailboxInfo' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'MailboxInfoInput' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'm365Acquisitions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'mailboxInfo' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'mailboxInfo' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: '__typename' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'appId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'titleId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'acquisitionState' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'acquisitionContext' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'canBeUnacquired' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'manifestId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'titleDefinition' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: '__typename',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'acquisitionDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'accentColor',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'ingestionId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'blockStatus',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'ingestionSource',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'categories',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'developerName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'developerUrl',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'elementDefinitions',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'composeExtensions',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'FragmentSpread',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'MessageExtensionFragment',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'exchangeAddIns',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'xmlDefinition',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'extensions',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'FragmentSpread',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'AddInExtensionFragment',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'staticTabs',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: '__typename',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'contentUrl',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'entityId',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'scopes',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'websiteUrl',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isFullTrust',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isFullScreen',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'iconSmall',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'uri',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'iconLarge',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'uri',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'iconOutline',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'uri',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'shortDescription',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'longDescription',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'permissions',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'showLoadingIndicator',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'scope' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'cultureName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'termsOfUseUrl',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'authorization',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'permissions',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'orgWide',
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections:
                                                                                            [
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'name',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'type',
                                                                                                    },
                                                                                                },
                                                                                            ],
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'resourceSpecific',
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections:
                                                                                            [
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'name',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'type',
                                                                                                    },
                                                                                                },
                                                                                            ],
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'validDomains',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'version',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'manifestVersion',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'webApplicationInfo',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'resource',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'copilotEnabled',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'copilotSettings',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'elementType',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'displayName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'capabilities',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'emailSummary',
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections:
                                                                                            [
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'pluginId',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'functionName',
                                                                                                    },
                                                                                                },
                                                                                            ],
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'emailDraft',
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections:
                                                                                            [
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'pluginId',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'functionName',
                                                                                                    },
                                                                                                },
                                                                                            ],
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'emailReplySuggestions',
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections:
                                                                                            [
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'pluginId',
                                                                                                    },
                                                                                                },
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'functionName',
                                                                                                    },
                                                                                                },
                                                                                            ],
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'nextInterval' } },
                            ],
                        },
                    },
                ],
            },
        },
        ...MessageExtensionFragmentFragmentDoc.definitions,
        ...AddInExtensionFragmentFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<M365AcquisitionsQuery, M365AcquisitionsQueryVariables>;
