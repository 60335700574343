import type { NovaEvent } from '@nova/types';
import type { AppHostServiceEventOriginator } from './originator';

export const StartCallViaSipProtocolEventType = 'startCallViaSipProtocol' as const;

export interface StartCallViaSipProtocolEvent extends NovaEvent<StartCallViaSipProtocolEventData> {
    originator: typeof AppHostServiceEventOriginator;
    type: typeof StartCallViaSipProtocolEventType;
    data: () => StartCallViaSipProtocolEventData;
}

export interface StartCallViaSipProtocolEventData {
    emails: string[];
    withVideo?: boolean;
}
