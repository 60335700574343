import { getMailListLoadStateFromTableView } from 'owa-mail-list-store/lib/utils/getMailListLoadState';
import { isSingleLineListView } from '../selectors/listViewLayout';
import { getStore as getListViewStore } from 'owa-mail-list-store';
import { type MailListViewState } from 'owa-mail-store/lib/store/schema/MailListViewState';

export function shouldRenderColumnHeaders(tableViewId: string) {
    const tableView = getListViewStore().tableViews.get(tableViewId);

    return isSingleLineListView() && getMailListLoadStateFromTableView(tableView) === 'Loaded';
}
