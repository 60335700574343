import type { QuickStepActionType, QuickStepAction } from 'owa-quick-step-types';
import { getMailMenuItemShouldShow } from 'owa-mail-filterable-menu-behavior';
import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { owaComputedFn } from 'owa-computed-fn';
import { getSelectedTableView } from 'owa-mail-list-store';

export const getIsQuickStepDisabled = owaComputedFn((actions: QuickStepAction[]): boolean => {
    // If we're in virtual select all mode, disable quick steps. Actions won't apply properly in this case
    const tableView = getSelectedTableView();
    if (!tableView || tableView.isInVirtualSelectAllMode) {
        return true;
    }

    // If any one of our actions is disabled, this quick step should be disabled
    const mailboxInfo = getModuleContextMailboxInfo();
    for (let i = 0; i < actions.length; i++) {
        if (!getMailMenuItemShouldShow(getCmdIdFromActionType(actions[i].type), mailboxInfo)) {
            return true;
        }
    }
    return false;
});

// function exported for tests
export function getCmdIdFromActionType(type: QuickStepActionType) {
    // It's possible the map doesn't contain the type, if so return the bad item type
    return ActionTypeToCmdIdMap.get(type) ?? -1;
}

const ActionTypeToCmdIdMap: Map<QuickStepActionType, MenuItemType> = new Map<
    QuickStepActionType,
    MenuItemType
>([
    ['markAsRead', 59],
    ['markAsUnread', 59],
    ['delete', 50],
    ['softDelete', 12],
    ['moveTo', 30],
    ['copyTo', 30],
    ['category', 6],
    ['clearCategories', 6],
    ['forwardTo', 52],
    ['forwardAsAttachment', 52],
    ['flag', 51],
    ['clearFlags', 51],
    ['markComplete', 51],
    ['retentionPolicy', 4],
    ['pin', 39],
    ['unpin', 75],
    ['ignore', 20],
    ['snooze', 66],
    ['createTask', 11],
    ['replyWithMeeting', 44],
    ['copilotReplyWithMeeting', 87],
]);
