import type EmailAddressWrapper from 'owa-service/lib/contract/EmailAddressWrapper';

export default function getEmailWithRoutingType(email: EmailAddressWrapper): string {
    if (email.RoutingType === 'SMTP' || email.RoutingType === undefined) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (8,9): Type 'string | undefined' is not assignable to type 'string'.
        // @ts-expect-error
        return email.EmailAddress;
    }

    const addressPart =
        email.RoutingType === 'MAPIPDL'
            ? email.ItemId?.Id || email.EmailAddress
            : email.EmailAddress;

    // For non-smtp recipients, give the full routing address.
    return `${email.RoutingType}:${addressPart}`;
}
