export interface OverallPremiumState {
    overallPremiumBit: PremiumStatusEnum;
    accountsLoadPending: boolean;
}

export default OverallPremiumState;

export enum PremiumStatusEnum {
    Premium = 2,
    NonPremium = 1,
    Unset = -1,
}
