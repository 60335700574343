import { flexPaneOpened } from 'owa-suite-header-apis';
import { orchestrator } from 'satcheljs';
import { getPinnablePaneByFlexPaneId } from '../utils/getPinnablePaneRegistrations';
import { isPanePinningEnabled } from '../utils/isPanePinningEnabled';
import { savePinnedPaneSetting } from '../utils/savePinnedPaneSetting';
import closeAddinPane from 'owa-addins-store/lib/actions/closeAddinPane';
import type TaskPaneType from 'owa-addins-store/lib/store/schema/TaskPaneType';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import terminateTaskPaneAddinCommand from 'owa-addins-store/lib/actions/terminateTaskPaneAddinCommand';
import { isFeatureEnabled } from 'owa-feature-flags';
// if opened flex pane is a pinnable pane, silently mark the pane as pinned in user settings
orchestrator(flexPaneOpened, ({ flexPaneId }) => {
    if (!isPanePinningEnabled()) {
        return;
    }

    const pinnablePane = getPinnablePaneByFlexPaneId(flexPaneId);
    if (!!pinnablePane) {
        savePinnedPaneSetting(pinnablePane);
    }
    if (isFeatureEnabled('addin-renderInFlexPaneMain') && flexPaneId != 'AddinsPane') {
        // We are using getModuleContextMailboxInfo() here as this will get called only from the main window.
        // The popped out windows and calendar do not have flexPane.
        const mailboxInfo = getModuleContextMailboxInfo();
        terminateTaskPaneAddinCommand('Persistent', window, mailboxInfo);
        terminateTaskPaneAddinCommand('NonPersistent', window, mailboxInfo);
        closeAddinPane(mailboxInfo, window, true);
    }
});
