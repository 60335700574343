import type { ClientItemId } from 'owa-client-ids';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { getReadRibbonId } from 'owa-mail-ribbon-utils/lib/getReadRibbonId';

export function modifyIdsForRead(
    idList: RibbonControlId[],
    itemId?: ClientItemId
): (number | string)[] {
    if (itemId === undefined) {
        return idList;
    }

    const returnIdList: (number | string)[] = [];

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    idList.forEach(id => {
        returnIdList.push(getReadRibbonId(id, itemId));
    });
    return returnIdList;
}
