import { owaComputedFn } from 'owa-computed-fn';
import { getMailAccountSources } from 'owa-account-source-list-store';
import { isBusiness } from 'owa-session-store';

/**
 * This function returns true if the user has any account that is Enterprise amongst their accounts.
 * If all of the user's accounts are consumer, then the function will return false.
 */
export const hasEnterpriseAccount = owaComputedFn(function hasEnterpriseAccount(): boolean {
    // return true if the user has at least one enterprise account
    return getMailAccountSources().some(accountSource => isBusiness(accountSource.mailboxInfo));
});
