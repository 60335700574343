import type MailTriageAction from 'owa-service/lib/contract/MailTriageAction';
import type SurfaceActionsOptionState from '../store/schema/SurfaceActionsOptionState';
import type { HoverActionKey } from 'owa-outlook-service-option-store';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
export interface SelectSurfaceActionsOptionState {
    optionState: SurfaceActionsOptionState;
}
export function getMailTriageActionsFromHoverActionKeys(
    hoverActionKeys: HoverActionKey[]
): MailTriageAction[] {
    const actionKeys: MailTriageAction[] = [];
    hoverActionKeys.forEach(action => {
        switch (action) {
            case 'None':
                actionKeys.push(0);
                break;
            case 'Delete':
                actionKeys.push(1);
                break;
            case 'Archive':
                actionKeys.push(2);
                break;
            case 'PinUnpin':
                actionKeys.push(3);
                break;
            case 'ReadUnread':
                actionKeys.push(4);
                break;
            case 'FlagUnflag':
                actionKeys.push(5);
                break;
            case 'Move':
                actionKeys.push(6);
                break;
        }
    });
    return actionKeys;
}

export function getHoverActionKeysFromMailTriageActions(
    mailTriageActions: MailTriageAction[]
): HoverActionKey[] {
    const hoverKeys: HoverActionKey[] = [];
    mailTriageActions.forEach(action => {
        switch (action) {
            case 0:
                hoverKeys.push('None');
                break;
            case 1:
                hoverKeys.push('Delete');
                break;
            case 2:
                hoverKeys.push('Archive');
                break;
            case 3:
                hoverKeys.push('PinUnpin');
                break;
            case 5:
                hoverKeys.push('FlagUnflag');
                break;
            case 4:
                hoverKeys.push('ReadUnread');
                break;
            case 6:
                hoverKeys.push('Move');
                break;
        }
    });
    return hoverKeys;
}

export default function getHoverSurfaceAction(): HoverActionKey[] {
    const mailTriageActions =
        getUserConfiguration()?.ViewStateConfiguration?.MailTriageOnHoverActions;

    if (mailTriageActions) {
        return getHoverActionKeysFromMailTriageActions([...mailTriageActions]);
    }

    return ['FlagUnflag', 'Delete', 'PinUnpin'];
}
