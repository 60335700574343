import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "LocalDataAccountSupport" */ './lazyIndex')
);

export const lazyAddLocalDataAdditionalAccount = new LazyAction(
    lazyModule,
    m => m.addLocalDataAdditionalAccount
);

export const lazyDoCleanupForRemovedLocalDataAccount = new LazyAction(
    lazyModule,
    m => m.doCleanupForRemovedLocalDataAccount
);

export const lazyCheckPrerequisites = new LazyAction(lazyModule, m => m.checkPrerequisites);
