export const DEFAULT_ZOOM = 1;

// Stores the possible scaling values for custom zoom (embiggen)
// Keep this array sorted
export const ZOOM_INCREMENTS: number[] = [
    0.5,
    0.67,
    0.75,
    0.8,
    0.9,
    DEFAULT_ZOOM,
    1.1,
    1.25,
    1.5,
    1.75,
    2,
    2.25,
    2.5,
    2.75,
    3,
    3.25,
    3.5,
    3.75,
    4,
];
