import type { MailboxInfo } from 'owa-client-types';
import { getGroupFolder } from './getGroupFolder';

function addAllChildFoldersToList(
    allFolderIdsList: string[],
    groupId: string,
    folderId: string,
    mailboxInfo: MailboxInfo
) {
    const groupFolder = getGroupFolder(groupId, folderId, mailboxInfo);

    /* eslint-disable-next-line @typescript-eslint/no-shadow, owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * See https://typescript-eslint.io/rules/no-shadow
     *	> 'folderId' is already declared in the upper scope on line 7 column 5.
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead*/
    groupFolder?.ChildFolderIds?.forEach(folderId => {
        allFolderIdsList.push(folderId);
        addAllChildFoldersToList(allFolderIdsList, groupId, folderId, mailboxInfo);
    });
}

/**
 * @param groupId Smtp address of the group
 * @param folderId Folder Id of the group folder
 * @param mailboxInfo mailbox info of the account
 * @returns List of all the sub folders under the specified group folder
 */
export function getAllSubFolderIds(
    groupId: string,
    folderId: string,
    mailboxInfo: MailboxInfo
): string[] {
    const allFolderIdsList: string[] = [];

    if (groupId && folderId) {
        const groupFolder = getGroupFolder(groupId, folderId, mailboxInfo);
        /* eslint-disable-next-line @typescript-eslint/no-shadow  -- (https://aka.ms/OWALintWiki)
         * See https://typescript-eslint.io/rules/no-shadow
         *	> 'folderId' is already declared in the upper scope. */
        groupFolder?.ChildFolderIds?.forEach(folderId => {
            allFolderIdsList.push(folderId);
            addAllChildFoldersToList(allFolderIdsList, groupId, folderId, mailboxInfo);
        });
    }
    return allFolderIdsList;
}
