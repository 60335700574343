import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type { MailboxInfo } from 'owa-client-types';
import { getSelectedCalendars } from './getSelectedCalendars';

export function getSelectedCalendarsForUser(mailboxInfo: MailboxInfo | undefined): string[] {
    // We are checking if the userIdentity is undefined here because there are scenarios where it could be an empty string.
    // An example of a scenario where this can happen is when a customer creates a security group and adds a new user without a mailbox to that security group.
    // That user will share a mailbox with the security group but won't have their own personal mailbox.
    if (!mailboxInfo) {
        return [];
    }
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    const selectedCalendars = getSelectedCalendars().get(key);
    return selectedCalendars ? selectedCalendars : [];
}
