import type { AccountSourceType } from 'owa-account-source-list-types';
import type { CustomDataMap } from 'owa-analytics-types';

export enum CreateAccountErrors {
    UnknownError = 'UNKNOWN',
    GetAuthTokenFailed = 'INVALIDCREDENTIALS',
    CouldNotConnect = 'CONNECTFAILED',
    CouldNotConnectData = 'CONNECTFAILEDDATA',
    DuplicateAccount = 'DUPLICATE',
    DuplicateAccountInfra = 'DUPLICATEINFRA',
    DuplicateAccountStorage = 'DUPLICATESTORAGE',
    ProviderNotSupported = 'NOTSUPPORTED',
    ProviderNotSupported2 = 'NOTSUPPORTEDAAD',
    MissingEmailInformation = 'MISSINGEMAILINFO',
    DuplicateUserIdentity = 'DUPLICATEIDENTITY',
    SharedAndDelegatesNotAllowed = 'NONUSERMAILBOX',
    UserAccountNotAllowed = 'ACCOUNTNOTALLOWED',
    InvalidEmailAddress = 'INVALIDEMAILADDRESS',
    AutoDetectFailure = 'AUTODETECTFAILURE',
    ExceedsAccountsLimit = 'EXCEEDSACCCOUNTSLIMIT',
    ExceedsAccountsLimitInfra = 'EXCEEDSACCCOUNTSLIMITINFRA',
    Cancel = 'CANCEL',
    EASIDAccount = 'EASIDACCOUNT',
    AuthHealthCheckFailed = 'AUTHHEALTHCHECKFAILED',
    MissingProtocolInformation = 'MISSINGPROTOCOLINFO',
    EnterpriseNoOfficeProPlus = 'NOOFFICEPROPLUS',
    AutoDetectFailureImport = 'AUTODETECTFAILUREIMP',
    ProviderNotSupportedPop3 = 'NOTSUPPORTEDPOP3',
    AddLocalDataAccountFailed = 'ADDLOCALDATAACCOUNTFAILED',
    MountPstFileFailed = 'MOUNTPSTFILEFAILED',
    MustConnectToAnotherMailbox = 'MUSTCONNECTTOANOTHERMAILBOX',
    LicencedFullAccount = 'LICENCEDFULLACCOUNT',
}

/**
 * Copy from TokenResponseType in Authentication PIE Schema
 */
export enum AuthResult {
    Invalid = 'INVALID',
    Success = 'SUCCESS',
    Cancel = 'CANCEL',
    InteractionRequired = 'INTERACTIONREQUIRED',
    TemporarilyUnavailable = 'TEMPORARILYUNAVAILABLE',
    ProviderFailure = 'PROVIDERFAILURE', //deprecated, remove after updating dependencies, e.g. telemetry
    InvalidUiContext = 'INVALIDUICONTEXT', //deprecated, remove after updating dependencies, e.g. telemetry
    ShuttingDown = 'SHUTTINGDOWN',
    InternalFailure = 'INTERNALFAILURE', //deprecated, remove after updating dependencies, e.g. telemetry
    ExtendedError = 'EXTENDEDERROR',
    InsufficientPermissions = 'INSUFFICIENTPERMISSIONS',
}

/**
 * Defines the result that will be returned by create accounts calls
 */
export interface CreateAccountResult {
    wasSuccessful: boolean;
    error?: ErrorInfo;
    sourceId?: string;
    smtpAddress?: string;
    sourceType?: AccountSourceType;
    steps?: CustomDataMap;
    isEasidOverrideEmailAddress?: boolean;
    authCorrelationId?: string;
}

export interface ErrorInfo {
    error: CreateAccountErrors;
    authError?: AuthResult;
    errorSubstatus?: string;
    /* This descriptive error message will almost certainly contain PII */
    errorString?: string;
    idpStatusCode?: string;
    authPromptError?: string;
    isAccountPresent?: string;
    authType?: string;
    scopesGranted?: string;
    connectOwaError?: string;
    connectRawErrorBodyMayHavePii?: string;
    connectStatus?: number;
    connectBeServer?: string;
    /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
     * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
     *	> Optional object properties should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
    miscData?: any;
}

export enum RemoveAccountErrors {
    AccountNotFound = 'NOT_FOUND',
    RedirectFailed = 'REDIRECT_FAILED',
}

/**
 * Defines the result that will be returned by remove accounts calls
 */
export interface RemoveAccountResult {
    wasSuccessful: boolean;
    error?: RemoveAccountErrors;
}
