import type {
    RibbonControlGroupDefinitionMLR,
    RibbonTabDefinitionBase,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, Multiline } from 'owa-command-ribbon';
import loc from 'owa-localize';
import Strings from 'owa-locstrings/lib/strings/ribbon_mail_buttons_and_tabs.locstring.json';
import { getMailRibbonConfigStore } from 'owa-mail-ribbon-store/lib/store/store';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { getUniqueTabId } from 'owa-mail-ribbon-utils/lib/getUniqueTabId';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { getInitialScalingSteps } from '../../../util/getInitialScalingSteps';
import { getMLRScalingSteps } from '../../../util/getScalingSteps';
import { getMLRTabGroups } from '../../../util/getTabGroups';
import { getMLRHomeTabCommonGroupFlyout } from './getMLRHomeTabCommonGroupFlyout';
import { getMLRHomeTabControlDefinition } from './getMLRHomeTabControlDefinition';
import { getMLRHomeTabGroupLayouts } from './getMLRHomeTabGroupLayouts';

const emptyCtrlList: RibbonControlId[] = [];

const getMLRHomeTab = owaComputedFn(function getMLRHomeTab(
    props: ReadOnlyRibbonControlDefProps
): RibbonTabDefinitionBase {
    return {
        type: Multiline,
        id: getUniqueTabId(1, undefined /* editorId */, props.itemId, props.isPopout),
        title: props.itemId ? loc(Strings.message_tab) : loc(Strings.home_tab),
        controlGroups: { type: Multiline, groups: getHomeTabGroups(props) },
        initialScalingSteps: getInitialScalingSteps(
            getMailRibbonConfigStore(props.mailboxInfo).multiline.homeTab,
            emptyCtrlList /*controlsWithoutLabels*/,
            undefined /*editorId*/,
            undefined /*itemId*/
        ),
        scalingSteps: getMLRScalingSteps(1),
        keytipProps: constructKeytip([], 1, true /* hasMenu */),
    };
});
export default getMLRHomeTab;

/**
 * Retrieves the groups within the Home tab, as well as the actions that fall under each group.
 * @returns an object that contains the sub-groups and corresponding actions of the Home tab
 */
const getHomeTabGroups = owaComputedFn(function getHomeTabGroups(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlGroupDefinitionMLR[] {
    let homeTabGroups: RibbonControlGroupDefinitionMLR[] = new Array();
    homeTabGroups = getMLRTabGroups(
        1,
        getMailRibbonConfigStore(props.mailboxInfo).multiline.homeTab.layout,
        getMLRHomeTabControlDefinition,
        getMLRHomeTabGroupLayouts,
        getMLRHomeTabCommonGroupFlyout,
        props
    );
    return homeTabGroups;
});
