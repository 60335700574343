import { getAccountKeyForMailboxInfo } from 'owa-client-types';
import { getAccountSettingsInternal } from 'owa-session-store/lib/utils/getAccountSettingsInternal';
import type { MailboxInfo } from 'owa-client-types';
import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';

/**
 * Function used as the callback for when account user configuration is set
 */
type AccountUserConfigurationCallbackFunc = (
    mailboxInfo: MailboxInfo,
    userConfiguration: OwaUserConfiguration
) => void;

/**
 * The actual callback
 */
let accountUserConfigurationCallback: AccountUserConfigurationCallbackFunc | undefined = undefined;

/**
 * When the callback is set we want to pass any previously set values to the callback, this function
 * creates a map (by the accountKey of the MailboxInfo) of the accounts for which user configuration
 * was previously set.
 */
const userConfigurationsPreviouslySet: Map<string, MailboxInfo> = new Map<string, MailboxInfo>();

/**
 * Invokes the callback is set
 * @param mailboxInfo MailboxInfo associated with the account for which the user configuration is set
 * @param userConfiguration User configuration information for the account
 */
export function onAccountUserConfigurationSet(
    mailboxInfo: MailboxInfo,
    userConfiguration: OwaUserConfiguration
) {
    if (!!accountUserConfigurationCallback) {
        accountUserConfigurationCallback(mailboxInfo, userConfiguration);
    } else {
        userConfigurationsPreviouslySet.set(getAccountKeyForMailboxInfo(mailboxInfo), mailboxInfo);
    }
}

/**
 * Sets the callback that will be called whenever an additional account configuraiton is set
 * @param callback Callback to be called when additional account data is loaded
 */
export function setAccountConfigurationCallback(
    callback: (mailboxInfo: MailboxInfo, userConfiguration: OwaUserConfiguration) => void
) {
    accountUserConfigurationCallback = callback;

    if (!!accountUserConfigurationCallback) {
        // Process any previously set user configurations
        userConfigurationsPreviouslySet.forEach((mailboxInfo: MailboxInfo) => {
            const userConfiguration = getAccountSettingsInternal(mailboxInfo);
            if (!!userConfiguration) {
                callback(mailboxInfo, userConfiguration);
            }
        });

        userConfigurationsPreviouslySet.clear();
    }
}
