import type { M365Acquisition } from 'owa-m365-acquisitions/lib/types';
import { FirstPartyAppId } from 'owa-m365-acquisitions/lib/data/M365AppId';
import { DraggableItemTypes } from 'owa-dnd/lib/utils/DraggableItemTypes';
import { isFeatureEnabled } from 'owa-feature-flags';

// Keep in sync with packages/calendar-common/forms/compose/owa-calendar-compose-form-lifecycle/src/actions/openCalendarFullComposeFromDropData.ts
const allowedCalendarExternalDropContent: string[] = [
    DraggableItemTypes.MailListItemPart,
    DraggableItemTypes.MailListRow,
    DraggableItemTypes.MultiMailListConversationRows,
    DraggableItemTypes.MultiMailListMessageRows,
    DraggableItemTypes.LocalFile,
    DraggableItemTypes.Attachment,
];

export function getAllowedExternalDropContent(acquisition: M365Acquisition): string[] {
    let allowedExternalDropContent: string[] = [];
    switch (acquisition.appId) {
        case FirstPartyAppId.Calendar:
            if (isFeatureEnabled('cal-external-appbar-dnd')) {
                // TODO VSO 294289: Add handling for external drop Nova event to launch Calendar Full Compose
                allowedExternalDropContent = allowedCalendarExternalDropContent;
            }
            break;
        default:
            break;
    }
    return allowedExternalDropContent;
}
