import type SessionSettings from 'owa-service/lib/contract/SessionSettingsType';
import type { M365UserMailboxAccountSource } from 'owa-account-source-list-store';
import type { RemovedAccount } from 'owa-account-source-list-types';
import type RecursiveReadOnly from 'owa-service/lib/RecursiveReadOnly';
import { getAccountScopeUserSettings } from 'owa-session-store';

/**
 * Returns the session settings associated with the account, or throws an error
 * if the session settings are not found.
 * @param account Account for which to get the session settings
 * @returns Session settings associated with the account
 */
export default function getSessionSettingsForUserAccount(
    account: M365UserMailboxAccountSource | RemovedAccount
): RecursiveReadOnly<SessionSettings> {
    // we do not delete from account scope user settings when an account is removed (we
    // wait for the next session start to clean the data up) so we can still access the
    // account scope user settings for a removed account
    return getAccountScopeUserSettings(account.mailboxInfo)?.SessionSettings ?? {};
}
