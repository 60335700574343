import type Item from 'owa-service/lib/contract/Item';

export default function isItemOfMessageType(item: Item | undefined): boolean {
    // VSO 31290: Make isItemOfMessageType generic by looking at __type and comparing it with "Message:#Exchange"
    return (
        !item?.ItemClass || // server treats undefined or null ItemClass as a Message type by default
        item.ItemClass.toLowerCase().indexOf('ipm.note') !== -1 || // Normal messages
        item.ItemClass.toLowerCase().indexOf('ipm.schedule.meeting') !== -1 || // Meeting request messages
        item.ItemClass.toLowerCase().indexOf('ipm.groupmailbox.joinrequest') !== -1 || // Group join request messages
        item.ItemClass.toLowerCase().indexOf('ipm.groupmailbox.addmemberrequest') !== -1 || // Group add member request messages
        item.ItemClass.toLowerCase().indexOf('ipm.post') !== -1 || //Post Message type
        item.ItemClass.toLowerCase().indexOf('ipm.sharing') !== -1 || // Calendar Sharing Message Type
        item.ItemClass.toLowerCase().indexOf('ipm.outlook.recall') !== -1 // Recall message
    );
}
