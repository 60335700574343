import {
    ArrowCircleDown16Regular,
    ArrowCircleUp16Filled,
    ArrowCircleUp16Regular,
    EqualCircleRegular,
} from '@fluentui/react-icons';
import classNames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import React from 'react';
import {
    copilotInboxPriorityIconForMLStyles,
    copilotInboxPriorityIconForSettingsStyles,
    copilotInboxPriorityIconForRPStyles,
    copilotInboxPriorityIconForRPStylesCozyAndCompact,
    copilotInboxHighPriorityIconColor,
    copilotInboxLowPriorityIconColor,
    copilotInboxNormalPriorityColor,
    copilotInboxPriorityHighContrastColor,
    iconContainer,
} from './CopilotInboxPriorityIcon.scss';
import getCopilotPrioritizeSettings from 'owa-mail-copilot-prioritize-settings/lib/selectors/getCopilotPrioritizeSettings';
import type { MailboxInfo } from 'owa-client-types';
import {
    highPriorityIconTooltip,
    lowPriorityIconTooltip,
} from './CopilotInboxPriorityIcon.locstring.json';
import loc from 'owa-localize';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import { isCopilotHighPriority, isCopilotLowPriority } from '../utils/priorityUtils';

interface CopilotInboxPriorityIconProps {
    copilotInboxScore?: number;
    priorityOverride?: 'high' | 'low' | 'normal';
    scenario: 'ML' | 'Settings' | 'RP' | 'PrioritySort';
    mailboxInfo?: MailboxInfo;
    isUnread?: boolean;
}

export default observer(function CopilotInboxPriorityIcon(props: CopilotInboxPriorityIconProps) {
    const {
        copilotInboxScore = 0,
        scenario,
        priorityOverride,
        mailboxInfo,
        isUnread = true,
    } = props;

    const prioritizationEnabled = mailboxInfo
        ? getCopilotPrioritizeSettings(mailboxInfo).prioritizationEnabled
        : null;
    const applyLowPriorityLabel = mailboxInfo
        ? getCopilotPrioritizeSettings(mailboxInfo).applyLowPriorityLabel
        : null;

    const isHighPriority =
        priorityOverride === 'high' ||
        (prioritizationEnabled && isCopilotHighPriority(copilotInboxScore));

    const isLowPriority =
        priorityOverride === 'low' ||
        (prioritizationEnabled &&
            (applyLowPriorityLabel || scenario === 'PrioritySort') &&
            isCopilotLowPriority(copilotInboxScore));

    const isNormalPriority = priorityOverride === 'normal';

    let scenarioStyles = copilotInboxPriorityIconForMLStyles;
    switch (scenario) {
        case 'ML':
        case 'PrioritySort':
            scenarioStyles = copilotInboxPriorityIconForMLStyles;
            break;
        case 'Settings':
            scenarioStyles = copilotInboxPriorityIconForSettingsStyles;
            break;
        case 'RP':
            scenarioStyles = getDensityModeCssClass(
                copilotInboxPriorityIconForRPStyles,
                copilotInboxPriorityIconForRPStylesCozyAndCompact,
                copilotInboxPriorityIconForRPStylesCozyAndCompact
            );
            break;
    }

    const iconStyles = classNames(
        isHighPriority && copilotInboxHighPriorityIconColor,
        isLowPriority && copilotInboxLowPriorityIconColor,
        isNormalPriority && copilotInboxNormalPriorityColor,
        copilotInboxPriorityHighContrastColor,
        scenarioStyles
    );

    let icon = null;

    if (isLowPriority) {
        icon = <ArrowCircleDown16Regular className={iconStyles} />;
    } else if (isHighPriority) {
        if (isUnread) {
            icon = <ArrowCircleUp16Filled className={iconStyles} />;
        } else {
            icon = <ArrowCircleUp16Regular className={iconStyles} />;
        }
    } else if (isNormalPriority) {
        icon = <EqualCircleRegular className={iconStyles} />;
    }

    if (!icon) {
        return null;
    }

    const iconTitle = isHighPriority ? loc(highPriorityIconTooltip) : loc(lowPriorityIconTooltip);

    return (
        <div className={iconContainer} title={scenario === 'ML' ? iconTitle : undefined}>
            {icon}
        </div>
    );
}, 'CopilotInboxPriorityIcon');
