const emailAddressRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/i;

export interface MailGroupRouteParameters {
    groupDomain: string;
    groupAlias: string;
    actionId?: string;
    rowId?: string;
}

export interface MailGroupFolderRouteParameters extends MailGroupRouteParameters {
    folderId: string;
}

export function ensureValidGroupSmtpAddress(parameters: MailGroupRouteParameters): string {
    if (!parameters.groupAlias || !parameters.groupDomain) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (19,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        return null;
    }

    const groupSmtp = parameters.groupAlias + '@' + parameters.groupDomain;

    if (!isValidEmailAddress(groupSmtp)) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (28,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        return null;
    }

    return groupSmtp;
}

function isValidEmailAddress(emailAddress: string): boolean {
    // TODO: remove it from here when VSO:1041 is fixed
    return emailAddressRegex.test(emailAddress);
}
