import { createStore } from 'satcheljs';

interface TestServiceStore {
    showTestService: boolean;
}

const store = createStore<TestServiceStore>('testServiceStore', {
    showTestService: false,
})();

export const getStore = () => store;
