import { folderStore } from './store/store';
export { default as getStore } from './store/store';

// Import mutators
import './mutators/setLoadingStateForFolderTree';
export { addFoldersToFolderTableMutator } from './mutators/addFoldersToFolderTableMutator';
import './mutators/folderTreeDataMutators';
export { setFolderLoadingStateMutator } from './mutators/setFolderLoadingStateMutator';

// export types
export type { default as FolderTreeData } from './store/schema/FolderTreeData';

export { SourceWellKnownFolderType } from './store/schema/SourceWellKnownFolderType';
export type { default as FolderStore } from './store/schema/FolderStore';
export { default as FolderTreeLoadStateEnum } from './store/schema/FolderTreeLoadStateEnum';
export { default as getFullFolderId } from './util/getFullFolderId';
export {
    getFolderPermissionsSuccess,
    getFolderPermissionsFailed,
    updateFolderPermissionsSuccess,
    updateFolderPermissionsFailed,
} from './actions/publicActions';
export { initializeSharedFolderRoots } from './actions/initializeSharedFolderRoots';
export { removeFolderTreeData } from './actions/removeFolderTreeData';
export { setLoadingStateForFolderTree } from './actions/setLoadingStateForFolderTree';
export { fetchSearchFolders } from './actions/fetchSearchFolders';

export { default as getArchiveFolderTreeRootFolder } from './selectors/getArchiveFolderTreeRootFolder';
export { default as getPrimaryFolderTreeRootFolder } from './selectors/getPrimaryFolderTreeRootFolder';
export { default as getFolderTable } from './selectors/getFolderTable';
export { default as getFolderTreeLoadingState } from './selectors/getFolderTreeLoadingState';
export { default as getFolderTreeHasMoreData } from './selectors/getFolderTreeHasMoreData';
export { default as getFolderTreeCurrentLoadedIndex } from './selectors/getFolderTreeCurrentLoadedIndex';
export { default as getSharedFolderRoot } from './selectors/getSharedFolderRoot';
export { default as getEffectiveFolderDisplayName } from './selectors/getEffectiveFolderDisplayName';
export { default as isFolderLoaded } from './selectors/isFolderLoaded';
export { default as onFolderHierarchyResponse } from './util/onFolderHierarchyResponse';
export { default as initializeArchiveFolderTree } from './util/initializeArchiveFolderTree';
export { setFolderTreeData } from './mutators/setFolderTreeData';
export { initializeFolderTreeData } from './util/initializeFolderTreeData';
export { getPrimaryMailFolders } from './util/getPrimaryMailFolders';
export { default as onFindFolderSuccess } from './util/onFindFolderSuccess';

export { default as isFolderUnderMsg, isFolderOrSubFolderOfMsgRoot } from './util/isFolderUnderMsg';
export {
    isUnderDistinguishedFolder,
    default as isFolderOrSubFolderOfDistinguishedFolder,
} from './util/isFolderOrSubFolderOfDistinguishedFolder';
export type { FolderTableState } from './util/isFolderOrSubFolderOfDistinguishedFolder';
export {
    getFolderByDistinguishedId,
    getRootFolderForMailboxInfo,
    getMailRootFolderChildIds,
    isFolderInMailboxType,
    areFoldersInSameMailboxType,
    areFoldersInSameAccount,
} from './util/folderUtility';
export { getMailboxInfoForFolderId } from './util/getMailboxInfoForFolderId';
export { isPublicFolder } from './util/idConverter';
export { getMailboxFolderTreeData } from './selectors/getMailboxFolderTreeDataTable';
export { generateMailboxSmtpAddress } from './util/mailboxUtility';
export {
    buildUnreadTotalCountTooltip,
    buildUnreadTotalCountContextMenuLabel,
} from './util/folderCountUtility';
export { getCustomFolderCount } from './util/getCustomFolderCount';

export default folderStore;

export {
    lazyGetArchiveFolders,
    lazyGetFolderPermissions,
    lazyUpdateFolderPermissions,
    lazyUpdateFolderPermissionsInFolderStore,
    lazyFetchArchiveDumpsterFolder,
    lazyArePermissionArraysEqual,
    lazyArePermissionsEqual,
    lazyGetSharedFoldersForSpecificRoot,
    lazyUpdateFolder,
    lazyFetchFavoriteFolders,
    lazyFetchMoreFolders,
    lazyCreateNewFolderInStore,
    lazyDeleteFoldersFromFolderTable,
    lazyRemoveFolderFromParentFolder,
    lazyMoveFolderToParentFolder,
    lazyFetchFavoriteFoldersResponse,
    lazyOutlookFavoritePersonaSearchFoldersLoaded,
    lazyGetChildFolderIds,
    lazyGetChildFolderNames,
    lazyInitializeAlphabetizeFoldersList,
    lazyToggleIsAlphabetizeFoldersEnabled,
    lazyUpdateFoldersParentFolder,
} from './lazyFunctions';

// Required for actions that depend on them
