import {
    getPrimaryMailboxSearchScopeKey,
    getArchiveMailboxSearchScopeKey,
    getSharedFoldersSearchScopeKey,
    getSingleGroupSearchScopeKey,
    getCalendarSearchScopeKey,
    getPublicFolderSearchScopeKey,
    getMetaOSAppSearchScopeKey,
    getPstFileSearchScopeKey,
} from './getSearchScopeKey';
import {
    getPrimaryMailboxSearchScopeType,
    getArchiveMailboxSearchScopeType,
    getSharedFoldersSearchScopeType,
    getSingleGroupSearchScopeType,
    getCalendarSearchScopeType,
    getPublicFolderSearchScopeType,
    getMetaOSAppSearchScopeType,
    getPstFileSearchScopeType,
} from './getSearchScopeType';
import type {
    ArchiveMailboxSearchScope,
    CalendarSearchScope,
    MetaOSAppSearchScope,
    PrimaryMailboxSearchScope,
    PstFileSearchScope,
    PublicFolderSearchScope,
    SearchScope,
    SharedFoldersSearchScope,
    SingleGroupSearchScope,
} from '../../data/schema/SearchScope';
import { SearchScopeKind } from '../../data/schema/SearchScope';
import type BaseSearchScopeType from 'owa-service/lib/contract/BaseSearchScopeType';
import {
    getPrimaryMailboxSearchScopeList,
    getArchiveMailboxSearchScopeList,
    getSharedFoldersScopeList,
    getGroupSearchScopeList,
    getCalendarSearchScopeList,
    getPublicFolderSearchScopeList,
    getMetaOSAppSearchScopeList,
    getPstFileSearchScopeList,
} from './getSearchScopeList';
import type { MailboxInfo } from 'owa-client-types';
import { logUsage } from 'owa-analytics';

interface SearchScenario {
    // Gets the Unique Key related a given SearchScope
    getKey: (scope: SearchScope) => string;

    // Gets the SearchScope list used for the current scope scenario
    getSearchScopeList: (mailboxInfo?: MailboxInfo) => SearchScope[];

    // Gets the BaseSearchScopeType object used for the search request for a given SearchScope
    getSearchScopeType: (scope: SearchScope) => BaseSearchScopeType[];
}

const primaryMailboxScenario: SearchScenario = {
    getKey: (scope: SearchScope) =>
        getPrimaryMailboxSearchScopeKey(scope as PrimaryMailboxSearchScope),
    getSearchScopeList: getPrimaryMailboxSearchScopeList,
    getSearchScopeType: (scope: SearchScope) =>
        getPrimaryMailboxSearchScopeType(scope as PrimaryMailboxSearchScope),
};

const archiveMailboxScenario: SearchScenario = {
    getKey: (scope: SearchScope) =>
        getArchiveMailboxSearchScopeKey(scope as ArchiveMailboxSearchScope),
    getSearchScopeList: getArchiveMailboxSearchScopeList,
    getSearchScopeType: (scope: SearchScope) =>
        getArchiveMailboxSearchScopeType(scope as ArchiveMailboxSearchScope),
};

const sharedFoldersScenario: SearchScenario = {
    getKey: (scope: SearchScope) =>
        getSharedFoldersSearchScopeKey(scope as SharedFoldersSearchScope),
    getSearchScopeList: getSharedFoldersScopeList,
    getSearchScopeType: getSharedFoldersSearchScopeType,
};

const groupScenario: SearchScenario = {
    getKey: (scope: SearchScope) => getSingleGroupSearchScopeKey(scope as SingleGroupSearchScope),
    getSearchScopeList: getGroupSearchScopeList,
    getSearchScopeType: (scope: SearchScope) =>
        getSingleGroupSearchScopeType(scope as SingleGroupSearchScope),
};

const calendarScenario: SearchScenario = {
    getKey: (scope: SearchScope) => getCalendarSearchScopeKey(scope as CalendarSearchScope),
    getSearchScopeList: getCalendarSearchScopeList,
    getSearchScopeType: getCalendarSearchScopeType,
};

const publicFolderScenario: SearchScenario = {
    getKey: (scope: SearchScope) => getPublicFolderSearchScopeKey(scope as PublicFolderSearchScope),
    getSearchScopeList: getPublicFolderSearchScopeList,
    getSearchScopeType: getPublicFolderSearchScopeType,
};

const metaOSAppScenario: SearchScenario = {
    getKey: (scope: SearchScope) => getMetaOSAppSearchScopeKey(scope as MetaOSAppSearchScope),
    getSearchScopeList: getMetaOSAppSearchScopeList,
    getSearchScopeType: (scope: SearchScope) =>
        getMetaOSAppSearchScopeType(scope as MetaOSAppSearchScope),
};

const pstFileScenario: SearchScenario = {
    getKey: (scope: SearchScope) => getPstFileSearchScopeKey(scope as PstFileSearchScope),
    getSearchScopeList: getPstFileSearchScopeList,
    getSearchScopeType: (scope: SearchScope) =>
        getPstFileSearchScopeType(scope as PstFileSearchScope),
};

const pickScenario = (scope: SearchScope) => {
    if (scope?.kind) {
        switch (scope.kind) {
            case SearchScopeKind.PrimaryMailbox:
                return primaryMailboxScenario;
            case SearchScopeKind.ArchiveMailbox:
                return archiveMailboxScenario;
            case SearchScopeKind.SharedFolders:
                return sharedFoldersScenario;
            case SearchScopeKind.Group:
                return groupScenario;
            case SearchScopeKind.Calendar:
                return calendarScenario;
            case SearchScopeKind.PublicFolder:
                return publicFolderScenario;
            case SearchScopeKind.MetaOSApp:
                return metaOSAppScenario;
            case SearchScopeKind.PstFile:
                return pstFileScenario;
            default:
                throw new Error('Unregistered search scope');
        }
    } else {
        logUsage('SearchClientError', {
            Function: 'SearchScenario.PickScenario',
            Error: 'kind is missing on search scope',
        });
        throw new Error('kind is missing on search scope');
    }
};

export const getKey = (scope: SearchScope) => pickScenario(scope).getKey(scope);
export const getSearchScopeList = (scope: SearchScope, mailboxInfo?: MailboxInfo) =>
    pickScenario(scope).getSearchScopeList(mailboxInfo);
export const getSearchScopeType = (scope: SearchScope) =>
    pickScenario(scope).getSearchScopeType(scope);
