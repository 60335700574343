import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import type { ConsumerTokenRequestParams } from '../schema/TokenRequestParams';
import { parse, stringify } from 'querystring';
import type TokenResponseCode from 'owa-service/lib/contract/TokenResponseCode';
import addSeconds from 'owa-date-utc-fn/lib/addSeconds';
import type { ILogger } from './ILogger';

const eventListeners: {
    [key: string]: (e: any) => void;
} = {};

export async function fetchTokenFromMSA(
    logger: ILogger,
    consumerParams: ConsumerTokenRequestParams
): Promise<TokenResponse> {
    const iframeId = encodeURIComponent(
        consumerParams.client_id + consumerParams.scope + consumerParams.requestId
    );
    let tokenResponse: TokenResponse = {};
    try {
        tokenResponse = await internalFetchTokenFromMSA(logger, consumerParams, self, iframeId);
    } catch (exception) {
        tokenResponse.TokenResultCode = 2;
    } finally {
        cleanUpIframe(iframeId);
    }
    return tokenResponse;
}

async function internalFetchTokenFromMSA(
    logger: ILogger,
    consumerParams: ConsumerTokenRequestParams,
    parentWindow: Window,
    iframeId: string
): Promise<TokenResponse> {
    const url = getAuthenticationUrl(consumerParams);
    if (!parentWindow.document.getElementById(iframeId)) {
        const iframe = parentWindow.document.createElement('iframe');
        iframe.src = url.toString();
        iframe.style.display = 'none';
        iframe.id = iframeId;
        parentWindow.document.body.appendChild(iframe);
        return new Promise((resolve, reject) => {
            eventListeners[iframeId] = function () {
                let tokenResponse: TokenResponse = {};
                try {
                    if (
                        iframe.contentWindow &&
                        iframe.contentWindow.location.host == parentWindow.location.host
                    ) {
                        tokenResponse = parseHashParam(
                            logger,
                            iframe.contentWindow.location.hash.substring(1)
                        );
                        logger.addCheckpoint('TokenRetrievedFromMSASuccess');
                    }
                } catch (exception) {
                    //TODO: log something meaningful here
                    tokenResponse.TokenResultCode = 2;
                    logger.addCustomError('FetchFromMSAConsumer', exception);
                    reject(tokenResponse);
                }
                resolve(tokenResponse);
            };
            iframe.addEventListener('load', eventListeners[iframeId], false);
        });
    }

    return { TokenResultCode: 2 };
}

function getSingleValue(queryParam: string | string[] | undefined): string | undefined {
    return Array.isArray(queryParam) ? queryParam[0] : queryParam;
}

export function parseHashParam(logger: ILogger, hashParam: string): TokenResponse {
    const params = parse(hashParam);
    const tokenResponse: TokenResponse = {};
    if (params.access_token) {
        tokenResponse.AccessToken = getSingleValue(params.access_token);
        tokenResponse.AccessTokenExpiry = addSeconds(
            Date.now(),
            parseInt(getSingleValue(params.expires_in) ?? '0')
        ).toISOString();
        tokenResponse.TokenResultCode = 0;
    } else if (
        params.error == 'login_required' ||
        params.error == 'interaction_required' ||
        params.error == 'consent_required'
    ) {
        logger.addCheckpoint(params.error);
        tokenResponse.TokenResultCode = 3;
    } else {
        logger.addCheckpoint('Unknown_Error');
        tokenResponse.TokenResultCode = 2;
    }

    return tokenResponse;
}

function cleanUpIframe(id: string) {
    var element = self.document.getElementById(id);
    if (element != null) {
        element.removeEventListener('load', eventListeners[id]);
        self.document.body.removeChild(element);
        delete eventListeners[id];
    }
}

export function getAuthenticationUrl(consumerParams: ConsumerTokenRequestParams): string {
    const queryParams = {
        response_type: 'token',
        prompt: 'none', // silent auth, don't prompt the user
        redirect_uri: consumerParams.redirect_uri,
        scope: consumerParams.scope,
        client_id: consumerParams.client_id,
    };

    return consumerParams.authorization_uri + '?' + stringify(queryParams);
}
