import { getCoprincipalAccountSourcesByBootState, BootState } from 'owa-account-source-list-store';
import { logUsage } from 'owa-analytics';
import { type FeatureName, isFeatureEnabled } from 'owa-feature-flags';

export enum DiagnosticsFeature {
    Feedback = 'feedback',
    InAppSupport = 'inappsupport',
    GetDiagnostics = 'getdiagnostics',
}

export function getAggregateFeedbackSupportFeatureFlag(
    diagnosticsFeature: DiagnosticsFeature
): boolean {
    if (isFeatureEnabled('mon-diag-enableAggregateDiagnsoticsFeatureFlagCheck')) {
        const accounts = getCoprincipalAccountSourcesByBootState(BootState.StartupComplete);
        let featureFlight: FeatureName = 'mon-diag-feedbackjs';
        switch (diagnosticsFeature) {
            case DiagnosticsFeature.Feedback:
                featureFlight = 'mon-diag-feedbackjs';
                break;

            case DiagnosticsFeature.InAppSupport:
                featureFlight = 'mon-diag-inappsupport';
                break;

            case DiagnosticsFeature.GetDiagnostics:
                featureFlight = 'mon-diag-getDiagnostics';
                break;
        }

        let isDiagnosticsFeatureEnabled = true; // true by default

        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        accounts.forEach(account => {
            const mailboxInfo = account.mailboxInfo;

            isDiagnosticsFeatureEnabled =
                isDiagnosticsFeatureEnabled && isFeatureEnabled(featureFlight, mailboxInfo);
            logUsage('DiagnosticsAndSupport_IsDiagnosticsFeatureFlagEnabled', {
                accountType: mailboxInfo.type,
                isFeedbackFeatureFlagEnabled: isDiagnosticsFeatureEnabled,
                entrypoint: diagnosticsFeature,
                isFeedbackFlightEnabled: isFeatureEnabled(featureFlight, mailboxInfo),
            });
        });

        return isDiagnosticsFeatureEnabled;
    }

    return false;
}
