import type { MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from './getAccountScopeUserSettings';

/**
 * IMPORTANT: Do not use this selector to access outlook options values. These values
 * are stored in `owa-outlook-service-options-store`, and should always be accessed from there.
 */
export function getPrimeBootSettingsOptionsValues(mailboxInfo: MailboxInfo): any[] {
    const primeSettingsItems = getAccountScopeUserSettings(mailboxInfo)?.PrimeSettings?.Items;

    const settings: any[] = [];
    if (primeSettingsItems) {
        for (const primeSetting of primeSettingsItems) {
            const options = primeSetting.Value?.options?.[0];
            if (options) {
                settings.push(options);
            }
        }
    }
    return settings;
}
