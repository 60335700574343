import type { AddinCommandSurfaceItem, AddinLocation } from 'owa-addins-types';

export default function convertToIContextualMenuItem(
    hostItemIndex: string,
    item: AddinCommandSurfaceItem,
    targetWindow: Window
) {
    const addinLocation: AddinLocation = {
        hostItemIndex,
        targetWindow,
    };
    // Set property that will be used in compose command bar collapse logic
    item.isAddin = true;
    if (item.getClickHandler) {
        item.onClick = item.getClickHandler(addinLocation);
    } else if (item.sectionProps) {
        const items = item.sectionProps.items;
        items.forEach(menuItem => {
            convertToIContextualMenuItem(hostItemIndex, menuItem, targetWindow);
        });
    } else if (item.subMenuProps) {
        const items = item.subMenuProps.items;
        items.forEach(menuItem => {
            convertToIContextualMenuItem(hostItemIndex, menuItem, targetWindow);
        });
    }
}
