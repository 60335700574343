import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';

export const setFocusOnMoveToMenuSearchBox = () => {
    const controlId: string = 689 + '_moveToMenu_SearchBox';
    document.getElementById(controlId)?.focus();
};
export const setFocusOnCopyToMenuSearchBox = (
    /** Allow window to be defined since CopyTo may be in a different projection window */
    targetWindow: Window = window
) => {
    const controlId = `${713}_copyToMenu_SearchBox`;
    targetWindow.document.getElementById(controlId)?.focus();
};
