import type { ShellControl } from '@1js/suiteux-shell-react';
import React from 'react';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Avoid deprecated APIs, use portals instead of rendering to a new root
 *	> 'render' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root */
import { render } from 'owa-react-dom';

function renderOutlookLogoSpaceContainer(container: HTMLDivElement) {
    render(<div />, container);
}

// Currently, we leave some space in the tile so that the outlook branding text does not hit the left border directly
// Until the designer finds a suitable logo that works for all themes, we will replace this area with the logo.
export const OwaLogoOnTileSpace = {
    id: 'owaLogoOnTileSpace',
    flex: '0 1 auto',
    justifyContent: 'flex-start',
    minWidth: '10px',
    render: renderOutlookLogoSpaceContainer,
} as ShellControl;
