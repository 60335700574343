import { HeaderHeight } from 'owa-flex-pane/lib/components/FlexPaneVariables';
import { canUseToDoFeatures } from 'owa-todo-module-utils';
import { getOwaWorkload, type OwaWorkload } from 'owa-workloads';
import type { TimePanelConfig } from '../schema/TimePanelConfig';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

// initialize with default values for OWA core modules
let timePanelConfig: TimePanelConfig = {
    abovePanelHeight: HeaderHeight,
    // for Calendar module, default to 'Tasks' for better companion experience
    // evaluation is not deferred, as this is an edge-case optimization only applicable to first-time pane users who do not have a persisted setting
    // Only make Tasks initial panel view if the globalSettingsAccount supports ToDo since we do not expect many first-time pane users to have
    // an account that does not support ToDo features while also having a connected account that does support ToDo features. And even in that case, they
    // will be able to just click on the ToDo tab to get to their connected account's tasks.
    initialPanelView:
        getOwaWorkload() === 2 && canUseToDoFeatures(getGlobalSettingsAccountMailboxInfo())
            ? 'Tasks'
            : 'Calendar',
    initialCalendarView: 'Agenda',
    initialTaskListId: null,
    areFREsDisabled: false,
    // for non-Calendar modules, leverage inline compose over full compose
    // evaluation is deferred to ensure the right compose experience is triggered in case of module-switching
    isInlineComposeEnabled: () => getOwaWorkload() != 2,
    isTasksViewDisabled: false,
    isModuleDeeplinkDisabled: false,
};

export function initializeTimePanelConfig(customConfig: TimePanelConfig) {
    timePanelConfig = { ...customConfig };
}

export function getTimePanelConfig(): TimePanelConfig {
    return timePanelConfig;
}
