import type ReadOnlyRecipientViewState from 'owa-recipient-types/lib/types/ReadOnlyRecipientViewState';
import type EmailAddressWrapper from 'owa-service/lib/contract/EmailAddressWrapper';
import type ItemId from 'owa-service/lib/contract/ItemId';
import getFromAddressWrapper from './fromAddressUtils/getFromAddressWrapper';
import type { MailboxInfo } from 'owa-client-types';

export default function createFromRecipientWell(
    mailboxInfo: MailboxInfo,
    fromAddress?: EmailAddressWrapper,
    referenceItemId?: ItemId
): ReadOnlyRecipientViewState {
    return {
        email: fromAddress ? fromAddress : getFromAddressWrapper(mailboxInfo, referenceItemId),
    };
}
