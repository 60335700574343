import type {
    default as TabViewState,
    TabViewStateBase,
    MailComposeTabViewState,
    SecondaryReadingPaneTabViewState,
    CalendarReadingPaneTabViewState,
    CalendarComposeTabViewState,
    FullOptionTabViewState,
    RemindersTabViewState,
    AddCalendarsTabViewState,
} from '../store/schema/TabViewState';
import { TabType } from '../store/schema/TabViewState';
import type { MailboxInfo } from 'owa-client-types';

interface TabHandler<T extends TabViewStateBase> {
    onActivate?: (viewState: T) => void;
    onDeactivate?: (viewState: T) => void;
    onPopout?: (viewState: T, targetWindow: Window) => void;
    canClose?: (viewState: T) => boolean | Promise<boolean>;
    canShowTab?: (viewState: T) => boolean;
    onBlurProjection?: (viewState: T) => void;
    serializeData?: (viewState: T) => any;

    // Returns the deeplink URL to redirect to when the window closes. Returns 'null' if the window cannot be closed.
    getTabDeeplinkUrl?: (viewState: T) => string | null;
    reloadAsDeeplink?: (viewState: T, urlParams?: Record<string, string>) => void;
    canCloseNativeProjection?: (
        viewState: T,
        targetWindow: Window,
        closeNativeWindow?: (tabId: string) => void
    ) => boolean;
    getWindowTitle?: (viewState: T) => string | undefined;
    getMailboxInfo?: (viewState: T) => MailboxInfo | undefined;
}

export default TabHandler;

const tabHandlers: {
    [type: string]: TabHandler<TabViewState>;
} = {};

export function registerTabHandler(
    type: TabType.MailCompose,
    handler: TabHandler<MailComposeTabViewState>
): void;
export function registerTabHandler(
    type: TabType.SecondaryReadingPane,
    handler: TabHandler<SecondaryReadingPaneTabViewState>
): void;
export function registerTabHandler(
    type: TabType.CalendarReadingPane,
    handler: TabHandler<CalendarReadingPaneTabViewState>
): void;
export function registerTabHandler(
    type: TabType.CalendarCompose,
    handler: TabHandler<CalendarComposeTabViewState>
): void;
export function registerTabHandler(
    type: TabType.FullOptions,
    handler: TabHandler<FullOptionTabViewState>
): void;
export function registerTabHandler(
    type: TabType.Reminders,
    handler: TabHandler<RemindersTabViewState>
): void;
export function registerTabHandler(
    tyoe: TabType.AddCalendars,
    handler: TabHandler<AddCalendarsTabViewState>
): void;
export function registerTabHandler(type: TabType, handler: TabHandler<TabViewState>): void;
export function registerTabHandler(type: TabType, handler: TabHandler<any>): void {
    tabHandlers[TabType[type]] = handler;
}

export function getTabHandler(type: TabType): TabHandler<TabViewState> {
    return tabHandlers[TabType[type]];
}
