import { isSuccessStatusCode } from 'owa-http-status-codes';
import { format } from 'owa-localize';
import { makeGetRequest } from 'owa-ows-gateway';
import type { MailboxInfo } from 'owa-client-types';
import { logUsage } from 'owa-analytics';
import { isEasidResponse } from './schema/EasidResponse';

const GET_IS_EASID_ENDPOINT =
    'ows/beta/CloudCacheMailboxPremiumPolicy/IsEmailAddressAssociatedWithEasid?emailAddress={0}';

export async function isEasidAccount(
    emailAddress: string,
    mailboxInfo: MailboxInfo
): Promise<boolean> {
    try {
        const easidStatusEndpoint = format(GET_IS_EASID_ENDPOINT, emailAddress);

        const response = await makeGetRequest(
            easidStatusEndpoint,
            undefined /* correlationId */,
            true /* returnFullResponse */,
            undefined /* customHeaders */,
            undefined /* throwServiceError */,
            undefined /* includeCredentials */,
            'getEasidStatusForCloudCacheAccount',
            mailboxInfo
        );

        if (isSuccessStatusCode(response.status)) {
            const easidResponse = await response.json();
            return isEasidResponse(easidResponse) && !!easidResponse.isEasid;
        } else {
            logUsage('Failure to get EasId status', { statusCode: response.status });
            return false;
        }
    } catch (err) {
        logUsage('IsEasIdCallFailure', { error: err });
        return false;
    }
}
