import { LazyBootModule, LazyAction } from 'owa-bundling-light';
import { registerLazyOrchestrator } from 'owa-bundling';
import { onPinTaskbarShortcutTrigger } from 'owa-nova-actions';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "NativeControls" */ './lazyIndex')
);

registerLazyOrchestrator(
    onPinTaskbarShortcutTrigger,
    lazyModule,
    m => m.onPinTaskbarShortcutOrchestrator
);

export const lazyIsPinningAvailable = new LazyAction(lazyModule, m => m.isPinningAvailable);
