import { observer } from 'owa-mobx-react';
import type { IAnnouncedProps } from '@fluentui/react/lib/Announced';
import { Announced as FabricAnnounced } from '@fluentui/react/lib/Announced';
import React from 'react';

// This component is responsible for rendering the Announced component for Narrator assistance
const Announced = observer(function Announced(props: IAnnouncedProps) {
    return <FabricAnnounced {...props} />;
}, 'Announced');

export default Announced;
