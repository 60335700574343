import { action } from 'satcheljs';
import { addDatapointConfig } from 'owa-analytics-actions';
import type { OutlookFavoriteKind } from 'owa-favorites-types';

export const updateFavoriteCompleted = action(
    'updateFavoriteCompleted',
    (favoriteKind: OutlookFavoriteKind) =>
        addDatapointConfig(
            {
                name: 'UpdateFavoriteCompleted',
                customData: {},
            },
            { favoriteKind }
        )
);
