import { SweepOptionType } from 'owa-mail-sweep-store';
import type { TableView } from 'owa-mail-list-store';
import { lazySweepConversations } from 'owa-mail-triage-action';
import type EmailAddressWrapper from 'owa-service/lib/contract/EmailAddressWrapper';
import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import getSelectedFolderMailboxInfoOrDefault from 'owa-mail-store/lib/utils/getSelectedFolderMailboxInfoOrDefault';
import { logUsage } from 'owa-analytics';
import { getAggregationBucket } from 'owa-analytics-actions';
import type ApplyBulkItemActionResponseMessage from 'owa-service/lib/contract/ApplyBulkItemActionResponseMessage';

/**
 * Delete all mails from a sender when unsubscribing from suggested
 * unsubscribe. It uses sweep action for deleting.
 *
 * @param lastSenderMailbox sender mailbox from which the user is unsubscrbing.
 * @param tableView current tableView
 */
export default async function deleteAllOnUnsubscribe(
    lastSenderMailbox: EmailAddressWrapper | undefined,
    tableView: TableView
) {
    if (!lastSenderMailbox) {
        return;
    }
    const sweepOptionType = SweepOptionType.All;
    const destinationFolderId = folderNameToId(
        'deleteditems',
        getSelectedFolderMailboxInfoOrDefault()
    );
    // Perform sweep action
    lazySweepConversations.importAndExecute(
        [lastSenderMailbox],
        tableView,
        sweepOptionType,
        destinationFolderId,
        (response: ApplyBulkItemActionResponseMessage) => {
            logUsage('SuggestedUnsubscribeDeletedItems', {
                messageCount: getAggregationBucket({
                    value: response.NumberOfItemsProcessed ?? 0,
                    exactMatches: [0, 1, 2, 3, 4, 5],
                }),
            });
        }
    );
}
