import type { StrictVariables } from 'owa-apollo-types';
import type { OperationVariables, TypedDocumentNode } from '@apollo/client';
import type { StrictWriteQueryOptions } from './strictWriteQuery';
import { strictWriteQuery } from './strictWriteQuery';

export const writeQuery = <
    TSchemaVars extends OperationVariables,
    TActualVars extends StrictVariables<TSchemaVars, TActualVars>,
    TData
>(
    query: TypedDocumentNode<TData, TSchemaVars>,
    options?: StrictWriteQueryOptions<TData, TActualVars>
): void => {
    strictWriteQuery(query, options);
};
