import { action, mutator } from 'satcheljs';
import { mergeLocalizedStrings } from '../getLocalizedStrings';
import type { ResourceId } from '../ResourceId';
import type { LocalizedString } from '../LocalizedString';

export const addLocstringsToStore = action('ADD_LOCSTRINGS', (strings: Record<string, string>) => {
    return { str: strings as Record<ResourceId, LocalizedString> };
});

mutator(addLocstringsToStore, ({ str }) => {
    mergeLocalizedStrings(str);
});
