import getSelectedTableView from '../utils/getSelectedTableView';
import getListViewState from '../selectors/getListViewState';

export function getIsMultipleSelection(): boolean {
    const tableView = getSelectedTableView();
    const selectedRowKeysCount = tableView?.selectedRowKeys?.size;
    const isInVirtualSelectAllMode = tableView?.isInVirtualSelectAllMode;
    const selectedItemPartsCount =
        getListViewState().expandedConversationViewState?.selectedNodeIds?.length;
    return (
        !!isInVirtualSelectAllMode ||
        (!!selectedRowKeysCount && selectedRowKeysCount > 1) ||
        (!!selectedItemPartsCount && selectedItemPartsCount > 1)
    );
}
