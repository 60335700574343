import { logGreyError } from 'owa-analytics';
import { isOfflineSyncEnabled } from 'owa-offline-sync-feature-flags';
import { isOfflineBootEnabled } from 'owa-offline/lib/offlineBoot';

export function timeZonesGetter<T extends () => Promise<any>>(
    action: T
): Promise<Awaited<ReturnType<T>> | undefined> {
    if (!isOfflineBootEnabled() || !isOfflineSyncEnabled()) {
        return Promise.resolve(undefined);
    }

    return action().catch((err: Error) => {
        reportError(err);
        return undefined;
    });
}

const reportError = (error: Error) => {
    logGreyError('offline_tz_error', error);
};
