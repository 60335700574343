import { type SelectionRestrictionType } from '../Behaviors.types';
import {
    getComputedIsEverythingSelectedInTable,
    getComputedIsInVirtualSelectAllMode,
    getComputedIsInVirtualSelectionExclusionMode,
    getComputedIsVirtualSelectAllExclusionListEmpty,
    getComputedSelectedRowKeysSize,
} from 'owa-mail-list-store';
//import { assertNever } from 'owa-assert';

const selectionRestriction = (selectionRestrictionTypes: SelectionRestrictionType[]) => () => {
    const selectedRowsCount: number = getComputedSelectedRowKeysSize();
    const isInVirtualSelectAllMode: boolean = getComputedIsInVirtualSelectAllMode();
    const isInASelectAllMode =
        getComputedIsInVirtualSelectionExclusionMode() || getComputedIsEverythingSelectedInTable();
    let foundSelectionRestriction = false;

    for (var selectionRestrictionType of selectionRestrictionTypes) {
        switch (selectionRestrictionType) {
            case 5:
                // Exclude the select all mode, because the selection is virtual and list view actually has no selected items.
                foundSelectionRestriction = selectedRowsCount == 0 && !isInVirtualSelectAllMode;
                break;

            case 0:
                foundSelectionRestriction = selectedRowsCount > 0 || isInASelectAllMode;
                break;

            case 1:
                foundSelectionRestriction = selectedRowsCount > 0;
                break;

            case 2:
                foundSelectionRestriction = selectedRowsCount == 1;
                break;

            case 3:
                foundSelectionRestriction = selectedRowsCount > 1 || isInASelectAllMode;
                break;

            case 4:
                foundSelectionRestriction = selectedRowsCount > 1;
                break;
            case 6:
                foundSelectionRestriction =
                    isInVirtualSelectAllMode && getComputedIsVirtualSelectAllExclusionListEmpty();
                break;

            // default:
            //     throw assertNever(selectionRestrictionType);
        }

        // break as soon as we find listview satisfying the selection restriction
        if (foundSelectionRestriction) {
            return true;
        }
    }

    return false;
};

export default selectionRestriction;
