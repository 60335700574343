import { BootstrapDocument } from '../graphql/__generated__/bootstrap.interface';
import { isFeatureEnabled } from 'owa-feature-flags';
import { query } from 'owa-apollo';
import { errorThatWillCauseAlert } from 'owa-trace';
import { loadDataWorker } from 'owa-data-worker-loader';
import { precompileSchema } from 'owa-data-worker-client';
import { isDataWorkerPlatformEnabled } from 'owa-data-worker-check';
export type BootstrapType = any;

export function bootstrapDataWorker(bootstrapType: BootstrapType, isDisabled?: boolean) {
    if (isDisabled) {
        return;
    }

    try {
        const worker = loadDataWorker();

        if (worker && bootstrapType > 0 && isDataWorkerPlatformEnabled()) {
            if (bootstrapType === 1 && isFeatureEnabled('fwk-worker-preload')) {
                precompileSchema();
            } else if (bootstrapType === 2) {
                query(BootstrapDocument).catch(errorThatWillCauseAlert);
            }
        }
    } catch {
        // this shouldn't be throwing, but since this is an optimization, there's no
        // good reason to break bootstrap for it.
    }
}
