import type { MailFolder } from 'owa-graph-schema';
import { isStringNullOrWhiteSpace } from 'owa-localize';
import { setFolderTreeData } from '../mutators/setFolderTreeData';
import FolderTreeLoadStateEnum from '../store/schema/FolderTreeLoadStateEnum';
import { addFolderToDefaultFolderMappings } from 'owa-session-store';
import { createOrGetFolder } from '../orchestrators/helpers/createOrGetFolder';
import { updateFolderTable } from '../orchestrators/helpers/updateFolderTable';
import getFolderTable from '../selectors/getFolderTable';
import { isPstFileAccountInfraEnabled } from 'owa-account-source-list/lib/flights';
import { isCoprincipalMailbox } from 'owa-account-source-list-store';
import type { MailFolderFromServiceFragment } from 'owa-mail-folder-view-graphql';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Orchestrator that updates the folder table and sets-up parent child folder relations
 * for gql folder hierarchy response.
 */
export default async function onFolderHierarchyResponse(
    displayName: string,
    folders: MailFolderFromServiceFragment[],
    rootFolder: MailFolderFromServiceFragment,
    folderTreeIdentifier: string,
    includesLastItemInRange: boolean,
    offset: number,
    mailboxInfo: MailboxInfo | null,
    isCustomSorted: boolean
) {
    const createOrGetFolder_0 = (folder: MailFolder) => {
        return createOrGetFolder(folder);
    };

    updateFolderTable(
        displayName,
        rootFolder,
        folders,
        createOrGetFolder_0,
        isCustomSorted,
        mailboxInfo ?? undefined
    );

    const rootMailFolder = getFolderTable().get(rootFolder.id);
    if (rootMailFolder) {
        setFolderTreeData(
            folderTreeIdentifier,
            rootMailFolder, // Update folder table has added root to the table
            FolderTreeLoadStateEnum.Loaded,
            !includesLastItemInRange, // HasMoreData = !IncludesLastItemInRange
            offset,
            mailboxInfo,
            isCustomSorted
        );
    }

    // Ensure we have up-to-date folder id<->DistinguishedFolderId mappings for primary mailbox types
    // (UserMailbox or PstFile) and archive mailboxes
    const mailboxType = rootFolder.mailboxInfo.type;
    const rootFolderId = rootFolder.id;
    const isPrimaryMailboxType = isPstFileAccountInfraEnabled()
        ? isCoprincipalMailbox(rootFolder.mailboxInfo)
        : mailboxType === 'UserMailbox';
    if (
        isPrimaryMailboxType ||
        mailboxType === 'ArchiveMailbox' ||
        mailboxType === 'SharedMailbox'
    ) {
        for (let i = 0; i < folders.length; i++) {
            const rawFolder = folders[i];
            const folderId = rawFolder.id;
            const distinguishedFolderType = rawFolder.distinguishedFolderType;
            const folderMailboxInfo = rawFolder.mailboxInfo;

            const shouldAdd =
                (isPrimaryMailboxType || rawFolder.parentFolderId == rootFolderId) &&
                !isStringNullOrWhiteSpace(distinguishedFolderType);

            if (shouldAdd && distinguishedFolderType) {
                addFolderToDefaultFolderMappings(
                    distinguishedFolderType,
                    folderId,
                    folderMailboxInfo
                );
            }
        }

        if (rootFolder.distinguishedFolderType) {
            addFolderToDefaultFolderMappings(
                rootFolder.distinguishedFolderType,
                rootFolderId,
                rootFolder.mailboxInfo
            );
        }
    }
}
