import getListViewState from '../selectors/getListViewState';
import { owaComputedFn } from 'owa-computed-fn';

export const isZeroSelectedItemParts = owaComputedFn(function IsZeroSelectedItemParts(): boolean {
    return getListViewState().expandedConversationViewState.selectedNodeIds.length === 0;
});

export const isOneSelectedItemParts = owaComputedFn(function IsOneSelectedItemParts(): boolean {
    return getListViewState().expandedConversationViewState.selectedNodeIds.length === 1;
});

export const isMultipleSelectedItemParts = owaComputedFn(
    function IsMultipleSelectedItemParts(): boolean {
        return getListViewState().expandedConversationViewState.selectedNodeIds.length > 1;
    }
);
