import { LazyAction, LazyModule, createLazyComponent } from 'owa-bundling';
import store from './store/store';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "NewFolderDialog"*/ './lazyIndex')
);

export const lazySetupMailFolderListKeys = new LazyAction(
    lazyModule,
    m => m.setupMailFolderListKeys
);

export const LazyNewFolderDialog = createLazyComponent(
    lazyModule,
    m => m.NewFolderDialog,
    undefined,
    undefined,
    { onlyImportIf: () => store.shouldShow }
);
