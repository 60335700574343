import GroupStrings from 'owa-locstrings/lib/strings/ribbon_mail_groups.locstring.json';
import MailGroupStrings from './ribbon_mail_groups.locstring.json';
import HelpGroupStrings from 'owa-locstrings/lib/strings/ribbon_groups.locstring.json';
import type { RibbonGroupId } from 'owa-ribbon-ids';
import { type MailRibbonGroupId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { type HelpRibbonGroupId } from 'owa-ribbon-ids/lib/helpControlId';
import type { ResourceId } from 'owa-localize';

/**
 * Maps each group to its corresponding string for the corresponding group
 */
export const defaultGroupStringMap = new Map<RibbonGroupId, ResourceId>()
    .set(101, GroupStrings.addIns_GroupButton)
    .set(192, GroupStrings.spam_addin_GroupButton)
    .set(102, GroupStrings.clipboard_Group)
    .set(104, GroupStrings.delete_GroupButton)
    .set(197, MailGroupStrings.report_GroupButton)
    .set(105, GroupStrings.download_GroupButton)
    .set(106, GroupStrings.encrypt_Group)
    .set(108, GroupStrings.find_GroupButton)
    .set(134, GroupStrings.basicText_Group)
    .set(135, GroupStrings.paragraph_Group)
    .set(110, GroupStrings.immersiveReader_GroupButton)
    .set(111, GroupStrings.include_Group)
    .set(113, GroupStrings.move_GroupButton)
    .set(114, GroupStrings.moveAndDelete_GroupButton)
    .set(116, GroupStrings.new_GroupButton)
    .set(144, GroupStrings.notes_GroupNew)
    .set(145, GroupStrings.notes_GroupEdit)
    .set(146, GroupStrings.notes_GroupView)
    .set(117, GroupStrings.offline_GroupButton)
    .set(118, GroupStrings.print_GroupButton)
    .set(121, GroupStrings.respond_GroupButton)
    .set(122, '') //Ribbon customizer never has displayed name
    .set(194, GroupStrings.window_GroupButton)
    .set(123, GroupStrings.save_Group)
    .set(124, GroupStrings.send_GroupButton)
    .set(125, GroupStrings.sensitivity_Group)
    .set(127, GroupStrings.sync_GroupButton)
    .set(128, GroupStrings.tags_GroupButton)
    .set(132, '') // Toolbar groups never have displayed named
    .set(133, '') // Toolbar groups never have displayed named
    .set(130, GroupStrings.undo_GroupButton)
    .set(154, GroupStrings.viewLayout_Group)
    .set(153, GroupStrings.viewMessages_Group)
    .set(152, GroupStrings.viewSettings_Group)
    .set(147, GroupStrings.table_GroupStructure)
    .set(148, GroupStrings.table_GroupTableStyles)
    .set(149, GroupStrings.table_GroupMerge)
    .set(150, GroupStrings.table_GroupInsert)
    .set(151, GroupStrings.table_GroupAlignment)
    .set(196, MailGroupStrings.table_GroupBorders)
    .set(167, GroupStrings.tags_Group)
    .set(168, GroupStrings.actions_Group)
    .set(165, GroupStrings.addIns_GroupButton)
    .set(166, GroupStrings.findTime_GroupButton)
    .set(161, GroupStrings.insert_Group)
    .set(162, GroupStrings.options_Group)
    .set(159, GroupStrings.addIns_GroupButton)
    .set(160, GroupStrings.tags_GroupButton)
    .set(158, GroupStrings.include_Group)
    .set(157, GroupStrings.basicText_Group)
    .set(163, GroupStrings.clipboard_Group)
    .set(169, GroupStrings.loop_Group)
    .set(180, GroupStrings.draftWithCopilot_Group)
    .set(170, GroupStrings.save_Group)
    .set(171, GroupStrings.findTime_GroupButton)
    .set(172, GroupStrings.proofing_Group)
    .set(173, GroupStrings.loop_Group)
    .set(174, GroupStrings.format_Group)
    .set(175, GroupStrings.showField_Group)
    .set(176, GroupStrings.tracking_Group)
    .set(120, GroupStrings.quickSteps_Group)
    .set(177, GroupStrings.themes_Group)
    .set(179, GroupStrings.styling_Group)
    .set(187, GroupStrings.voice_Group)
    .set(11001, HelpGroupStrings.help_Group_Groups)
    .set(11002, HelpGroupStrings.tools_Group_Groups)
    .set(181, GroupStrings.draw_Undo_Group)
    .set(182, GroupStrings.draw_Selection_Group)
    .set(183, GroupStrings.draw_Tools_Group)
    .set(184, GroupStrings.draw_Touch_Group)
    .set(185, GroupStrings.draw_Insert_Group)
    .set(188, GroupStrings.pictureFormatting_Group)
    .set(189, GroupStrings.pictureText_Group)
    .set(190, GroupStrings.pictureSize_Group)
    .set(191, GroupStrings.pictureMisc_Group)
    .set(193, GroupStrings.zoom_Group)
    .set(195, GroupStrings.tracking_Group)
    .set(198, MailGroupStrings.teamsChat_Group)
    .set(199, GroupStrings.print_GroupButton);
