import { action } from 'satcheljs';
import type { FavoriteData } from 'owa-favorites-types';

export const addToOrderedFavoriteIdList = action(
    'ADD_TO_ORDERED_FAVORITE_ID_LIST',
    (favoriteData: FavoriteData) => {
        return {
            favoriteData,
        };
    }
);
