import type { MailboxInfo } from 'owa-client-types';
import { setItem } from 'owa-local-storage';

const OFFLINE_CONTACTS_FALLBACKONLINE_ENABLED_KEY = 'OWA-OFFLINE-CONTACTS-FALLBACKNOPGAL';
const OFFLINE_CONTACTS_FALLBACKONLINE_ENABLED_IN_SESSION_KEY =
    'OWA-OFFLINE-CONTACTS-FALLBACKNOPGAL-SESSION';

export function getFallbackToNOPGALLocalStorageKey(mailboxInfo: MailboxInfo) {
    return `${OFFLINE_CONTACTS_FALLBACKONLINE_ENABLED_KEY}-${mailboxInfo.sourceId}`;
}

export function getFallbackToNOPGALInSessionStorageKey(mailboxInfo: MailboxInfo) {
    return `${OFFLINE_CONTACTS_FALLBACKONLINE_ENABLED_IN_SESSION_KEY}-${mailboxInfo.sourceId}`;
}

export function toggleOfflineNoPGAL(mailboxInfo: MailboxInfo, value: string) {
    setItem(self, getFallbackToNOPGALLocalStorageKey(mailboxInfo), value);
}

export function toggleOfflineNoPGALForOutlookSession(mailboxInfo: MailboxInfo, sessionId: string) {
    setItem(self, getFallbackToNOPGALInSessionStorageKey(mailboxInfo), sessionId);
}
