import type { ImplementedCopilotFeatures, CopilotSettingsStore } from 'owa-copilot-settings-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import checkLanguage from './checkLanguage';
import createStoreCheckForGenericEnablement from './createStoreCheckForGenericEnablement';
import type { MailboxInfo } from 'owa-client-types';

type TonePersonalizationScenarios = Extract<ImplementedCopilotFeatures, 'Elaborate'>;
const isTonePersonalizationEnabled = createStoreCheckForGenericEnablement<
    TonePersonalizationScenarios,
    boolean
>('TonePersonalization', isTonePersonalizationEnabledInternal);

export default isTonePersonalizationEnabled;

function isTonePersonalizationEnabledInternal(
    store: CopilotSettingsStore,
    language: string,
    scenario: TonePersonalizationScenarios,
    mailboxInfo: MailboxInfo
): boolean {
    if (store.isEnabled) {
        const featureEnabled = store.featureInfo.get(scenario);
        if (featureEnabled?.isEnabled) {
            return (
                checkLanguage(language, ['en']) && // only enabled for english currently
                isFeatureEnabled('mon-copilot-elaborate-tonePersonalization', mailboxInfo) &&
                !!featureEnabled.capabilities &&
                'tonePersonalization' in featureEnabled.capabilities &&
                featureEnabled.capabilities.tonePersonalization
            );
        }
    }
    return false;
}
