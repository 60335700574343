// Effectively we're treating the address bar as a UI element here; however,
// there's no way to put `observer()` on the address bar, so we need to use
// autorun() to observe and react to the state change.
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * 'autorun' import from 'owa-mobx-react' is restricted.*/
import { autorun } from 'owa-mobx-react';
import { computed } from 'mobx';
import navigateToRoute from './navigateToRoute';
import type RouteGenerator from './RouteGenerator';

export default function keepRouteCurrent(routeGenerator: RouteGenerator) {
    const currentRoute = computed(routeGenerator);
    autorun('keepRouteCurrent', () => {
        navigateToRoute(currentRoute.get());
    });
}
