import type { MailboxInfo } from 'owa-client-types';
import type ReminderNotificationPayload from 'owa-service/lib/contract/ReminderNotificationPayload';
import { action } from 'satcheljs';

export default action(
    'REMINDER_NOTIFICATION',
    (notification: ReminderNotificationPayload, mailboxInfo?: MailboxInfo) => ({
        notification,
        mailboxInfo,
    })
);
