import { action } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Fetches the dumpster folder for archive mailbox folder tree and
 * adds mapping to session store.
 */

export const fetchDumpsterFolder = action('FETCH_DUMPSTER_FOLDER', (mailboxInfo: MailboxInfo) => ({
    mailboxInfo,
}));
