import {
    AccountSourceMailboxType,
    m365MailboxTypeToMailboxType,
} from 'owa-account-source-list-store';
import type { CoprincipalAccount } from 'owa-account-storage-types';
import { AccountSourceType, type AdditionalMailboxInfoProps } from 'owa-account-source-list-types';
import sourceToProvider from 'owa-account-source-list-types/lib/utils/sourceToProvider';
import {
    CoprincipalMailboxRank,
    type MailboxInfo,
    type MailboxRank,
    type MailboxProvider,
} from 'owa-client-types';

/**
 * Constructs a MailboxInfo object based on the supplied types
 * @param sourceId Specifies the sourceId, or key in the account source list store, for the mailbox
 * @param accountSourceMailboxType Specifies the type of Mailbox that is being communicated with
 * @param mailboxSmtpAddress Specified the SMTP address of the mailbox
 * @param userIdentity Specifies the user identity, or user principal name, being used to connect the mailbox
 * @param auxiliaryMailboxGuid Optionally specified the mailbox guid
 * @returns MailboxInfo
 */
export function makeMailboxInfo(
    sourceId: string,
    accountSourceMailboxType: AccountSourceMailboxType,
    mailboxSmtpAddress: string,
    userIdentity: string,
    localAuthId: string | undefined | null,
    mailboxRank: MailboxRank,
    mailboxProvider: MailboxProvider | undefined | null,
    mailboxInfoProps: AdditionalMailboxInfoProps
): MailboxInfo {
    return {
        sourceId,
        type: m365MailboxTypeToMailboxType(accountSourceMailboxType),
        mailboxSmtpAddress,
        userIdentity,
        localAuthId,
        mailboxRank,
        diagnosticData:
            mailboxRank == CoprincipalMailboxRank ? 'ASLSAccountMailbox' : 'ASLSResourceMailbox',
        mailboxProvider,
        ...mailboxInfoProps,
    };
}

/**
 * Constructs a MailboxInfo based on the persisted CoprincipalAccount information
 * @param account
 * @returns
 */
export function makeMailboxInfoFromCoprincipalAccount(account: CoprincipalAccount): MailboxInfo {
    // We can tell the m365 mailbox type based on the account source type
    const accountSourceMailboxType =
        account.accountType == AccountSourceType.PstFile
            ? AccountSourceMailboxType.PstFile
            : AccountSourceMailboxType.User;

    return makeMailboxInfo(
        account.uuid,
        accountSourceMailboxType,
        account.emailAddress,
        account.userIdentity,
        account.localAuthId,
        CoprincipalMailboxRank,
        sourceToProvider(account.accountType),
        { isExplicitLogon: account.isAnotherMailbox }
    );
}
