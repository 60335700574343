import type { MailboxInfo } from 'owa-client-types';
import type { GroupFolderTreeViewState } from '../store/schema/GroupFolderTreeViewState';
import getGroupListNodesViewState from '../selectors/getGroupsListNodeViewState';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

export function getGroupViewState(
    groupId: string,
    mailboxInfo: MailboxInfo
): GroupFolderTreeViewState | null {
    return (
        // TODO : GroupsMultiAccount, this needs to be multi account aware.
        getGroupListNodesViewState(mailboxInfo).groupListNodesViewStates.get(
            transformGroupSmtp(groupId)
        ) ?? null
    );
}
