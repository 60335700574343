import { default as getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store/lib/selectors/getGlobalSettingsAccountMailboxInfo';
import type { PrivacySettingsWithMetaData } from '../store/schema/PrivacySettings';
import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import { parseServerValue } from './parseServerValue';

export function getPrivacySettingsFromStartupData(): PrivacySettingsWithMetaData | undefined {
    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();
    const primeSettingsItems = getAccountScopeUserSettings(mailboxInfo)?.PrimeSettings?.Items;

    const privacySettings: PrivacySettingsWithMetaData[] | undefined = primeSettingsItems
        ?.filter(item => item?.Id === 'PrivacyConfiguration')
        .map(item => parseServerValue(item?.Value, 'startupData'));

    return privacySettings?.[0];
}
