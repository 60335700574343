import type EmailAddressWrapper from 'owa-service/lib/contract/EmailAddressWrapper';
import isValidSmtpAddress from './isValidRecipientAddress';

export default function isValidRecipient(email: EmailAddressWrapper): boolean {
    // Email address should be defined and if it is of smtp type, we check whether it is well formed.
    // All other RoutingType such as FAX, MAPIPDL (private contact lists with mailbox type PrivateDL),
    // and other custom types are always considered valid because formats for those could be anything.
    return (
        email.RoutingType != 'SMTP' ||
        (email.EmailAddress != undefined && isValidSmtpAddress(email.EmailAddress))
    );
}
