import type { AdditionalAccountInfo } from 'owa-account-source-list-store';
import { LoadState } from 'owa-account-source-list-store';

/**
 * Build an empty additional account information structure
 * @param coprincipalSourceId Specified the sourceId of the coprincipal account
 * @returns An initialized AdditionalAccountInfo instance
 */
export function buildEmptyAdditionalAccountInfo(
    coprincipalSourceId: string
): AdditionalAccountInfo {
    return {
        coprincipalSourceId,
        loadState: LoadState.NotStarted,
        sources: [],
    };
}
