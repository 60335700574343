import store from '../store/parentStore';
import type { ProjectionItem } from '../store/schema/PopoutParentStore';

export default function getProjection(tabIdOrWindow: string | Window): ProjectionItem | undefined {
    return store.projections.filter(p => {
        if (typeof tabIdOrWindow === 'string') {
            return p.tabId == tabIdOrWindow;
        } else {
            return p.window == tabIdOrWindow;
        }
    })[0];
}
