import type TabViewState from '../store/schema/TabViewState';
import { type TabState } from '../store/schema/TabViewState';
import { getStore } from '../store/tabStore';
import { errorThatWillCauseAlert } from 'owa-trace';

export default function getActiveContentTab(): TabViewState | null {
    const store = getStore();

    for (const viewState of store.tabs) {
        if (viewState.state == 2) {
            return viewState;
        }
    }

    // We should never see this happen.
    errorThatWillCauseAlert('There is no active tab. Something went very wrong.');
    return null;
}
