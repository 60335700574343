import { OwsOptionsFeatureType, getOptionsForFeature } from 'owa-outlook-service-option-store';
import {
    isAppBarDisplayModeOptionsEnabled,
    getColorfulIconsEnabledOriginalValue,
} from 'owa-feature-flags';
import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import getGlobalSettingsAccountMailboxInfo from 'owa-account-source-list-store/lib/selectors/getGlobalSettingsAccountMailboxInfo';

import type { AppBarDisplayModeOptions } from 'owa-outlook-service-option-store';

export function isAppBarDisplayModeColorfulIconsEnabled(useColorfulIcons?: boolean): boolean {
    if (!isAppBarDisplayModeOptionsEnabled()) {
        return true;
    }

    if (useColorfulIcons !== undefined) {
        return useColorfulIcons;
    }

    let colorfulIconsEnabled = undefined;

    const userOptions = getOptionsForFeature<AppBarDisplayModeOptions>(
        OwsOptionsFeatureType.AppBarDisplayMode
    );

    if (userOptions) {
        colorfulIconsEnabled = userOptions.colorfulIconsEnabled;
    } else {
        const primeSettingsItems = getAccountScopeUserSettings(
            getGlobalSettingsAccountMailboxInfo()
        )?.PrimeSettings?.Items;

        if (primeSettingsItems) {
            for (const primeSetting of primeSettingsItems) {
                if (primeSetting.Id === 'AppBarDisplayModeOptions') {
                    colorfulIconsEnabled = primeSetting.Value?.options?.[0]?.colorfulIconsEnabled;
                    break;
                }
            }
        }
    }

    return colorfulIconsEnabled ?? getColorfulIconsEnabledOriginalValue();
}
