import { LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "NewUserExperience" */ './lazyIndex')
);

export const lazyInvokeUserIntentDialog = new LazyAction(lazyModule, m => m.invokeUserIntentDialog);
export const lazyLoadNewUserDefaultTheme = new LazyAction(
    lazyModule,
    m => m.loadNewUserDefaultTheme
);

export { default as isNewUserPostJit } from './utils/isNewUserPostJit';
