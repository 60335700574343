export const reportAndBlockButton = "k7TXbd";
export const blockActionText = "qGVSEc";
export const reportAndUnsubscribeButton = "WONGFc";
export const unsubscribeActionText = "Osa0Td";
export const confirmReportButton = "gWedne";
export const reportJunkTitle = "GQA5Wd";
export const reportJunkContent = "JNCvhe";
export const removeFromJunkV2 = "IOMqEe";
export const removeFromJunkEnterpriseMessageBodySingleSenderSelected = "WxPjUc";
export const removeFromJunkEnterpriseMessageBodyTwoSendersSelected = "fNeFhb";
export const removeFromJunkEnterpriseMessageBodyThreeSendersSelected = "hPP7Pb";
export const removeFromJunkEnterpriseMessageBodyFourPlusSendersSelected = "x4FVme";
export const reportPhishingContent = "v_DWq";
export const reportPhishingTitle = "EpoNg";
export const reportUnsubscribeBlock = "WqnMxc";
export const reportBlockExternal = "Ap5eHc";
export default {reportAndBlockButton,blockActionText,reportAndUnsubscribeButton,unsubscribeActionText,confirmReportButton,reportJunkTitle,reportJunkContent,removeFromJunkV2,removeFromJunkEnterpriseMessageBodySingleSenderSelected,removeFromJunkEnterpriseMessageBodyTwoSendersSelected,removeFromJunkEnterpriseMessageBodyThreeSendersSelected,removeFromJunkEnterpriseMessageBodyFourPlusSendersSelected,reportPhishingContent,reportPhishingTitle,reportUnsubscribeBlock,reportBlockExternal};