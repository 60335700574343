import React from 'react';

import { onToggleFolderPane } from 'owa-mail-ribbon-command-actions/lib/commandActions';
import { ExtraTopRibbonControlsBeforeTabs } from 'owa-left-pane-layout/lib/components/ExtraTopRibbonControlsBeforeTabs';
import { shouldShowFolderPane } from 'owa-mail-layout/lib/selectors/shouldShowFolderPane';
import { owaComputedFn } from 'owa-computed-fn';
import { type StateChangeSource } from 'owa-left-pane-layout/lib/types/StateChangeSource';

const onToggleFolderPaneWrapper = () => {
    onToggleFolderPane(2, undefined /*newState, will toggle if not passed through*/);
};

export const getExtraTopRibbonControlsBeforeTabs = owaComputedFn(
    function getExtraTopRibbonControlsBeforeTabsMail() {
        return (
            <ExtraTopRibbonControlsBeforeTabs
                onClick={onToggleFolderPaneWrapper}
                isExpanded={shouldShowFolderPane()}
            />
        );
    }
);
