import { getThemeIdFromParameter } from '../utils/optionalArgumentHelpers';
import getIsCurrentThemeCopilot from './getIsCurrentThemeCopilot';
import getIsCurrentThemeModernImage from './getIsCurrentThemeModernImage';

/*
 * This function checks if the current theme is a full bleed theme.
 */
export default function getIsCurrentThemeFullBleed(themeId?: string) {
    themeId = getThemeIdFromParameter(themeId);

    return getIsCurrentThemeModernImage(themeId) || getIsCurrentThemeCopilot(themeId);
}
