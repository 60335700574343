import type { TableQuery, GroupMailTableQuery } from 'owa-mail-list-store';
import type { MailboxInfo } from 'owa-client-types';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { isGroupSearch } from './isGroupTableQuery';

export default function getGroupMailboxInfoFromTableQuery(
    groupId: string,
    tableQuery: TableQuery
): MailboxInfo {
    const userID = isGroupSearch(tableQuery)
        ? getModuleContextMailboxInfo().userIdentity
        : (tableQuery as GroupMailTableQuery).mailboxInfo.userIdentity;

    const groupMailboxInfo: MailboxInfo = {
        type: 'GroupMailbox',
        userIdentity: userID,
        mailboxSmtpAddress: groupId,
    };

    return groupMailboxInfo;
}
