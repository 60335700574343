import type { M365Acquisition } from 'owa-m365-acquisitions/lib/types';

type ManifestVersion = {
    major: number;
    minor: number;
    patch?: number;
    preRelease?: string;
};

/**
 * Parses a string that represents a manifest version into
 * a structured object.
 *
 * ```js
 * >>> parseManifestVersion('1.13.25-beta0.0.0')
 * { major: 1, minor: 13, patch: 25, preRelease: '-beta0.0.0' }
 * >>> parseManifestVersion('0.1')
 * { major: 0, minor: 1, patch: 0, preRelease: undefined }
 * ```
 * @param manifestVersion A string that represents a manifest version e.g. `'1.13.0'` or `'1.13.0-beta0'`.
 * @returns Structured manifest version, `ManifestVersion`.
 */
export function parseManifestVersion(
    manifestVersion: string | undefined | null
): ManifestVersion | null {
    if (!manifestVersion) {
        return null;
    }

    const regExp = /^(?<major>\d+)\.(?<minor>\d+)(\.(?<patch>\d+))?(?<pre>-.*)?/i;
    const manifestVersionRaw = manifestVersion.match(regExp);
    if (!manifestVersionRaw) {
        return null;
    }

    try {
        const { major, minor, patch, pre } = manifestVersionRaw.groups as {
            major: string;
            minor: string;
            patch?: string;
            pre?: string;
        };
        return {
            major: parseInt(major),
            minor: parseInt(minor),
            patch: parseInt(patch ?? '0'),
            preRelease: pre,
        };
    } catch (_e) {
        try {
            const [_a, major, minor, _b, patch, pre] = manifestVersionRaw;
            return {
                major: parseInt(major),
                minor: parseInt(minor),
                patch: parseInt(patch ?? '0'),
                preRelease: pre,
            };
        } catch (_err) {
            return null;
        }
    }
}

const minVersionAllowed: ManifestVersion = {
    major: 1,
    minor: 13,
};
/**
 * @param acquisition `M365Acquisition`
 * @returns `true` if `acquisition` has a valid manifest version i.e. `1.13.0` or higher.
 */
export function filterManifestVersion(acquisition: M365Acquisition): boolean {
    const manifestVersion = parseManifestVersion(acquisition.titleDefinition?.manifestVersion);
    // Filter valid only for MetaOs apps e.g. add-ins do not implement this field.
    // Skip filtering if the app is a dev preview app.
    if (
        acquisition.titleDefinition.ingestionSource !== 'TeamsMiddleTier' ||
        acquisition.titleDefinition.manifestVersion?.toLowerCase() === 'devpreview'
    ) {
        return true;
    }
    if (!manifestVersion) {
        return false;
    }

    return (
        manifestVersion.major >= minVersionAllowed.major &&
        manifestVersion.minor >= minVersionAllowed.minor
    );
}
