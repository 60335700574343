import type { M365Acquisition } from 'owa-graph-schema';
import type { AcquisitionEntrypoint, AcquisitionExperience, InferArrayType } from '../types';
import type { EntrypointsForType } from './getAcquisitionEntrypoints';
import type { ExperiencesForType } from './getAcquisitionExperiences';

/**
 * @param app
 * @returns `true` if title has at least one add-in extension element
 */
export function hasExchangeAddIn(acquisition: M365Acquisition): boolean {
    const addInExchange = acquisition.titleDefinition?.elementDefinitions?.exchangeAddIns ?? [];
    return addInExchange.length > 0;
}

/**
 * @param acquisition M365Acquisition
 * @returns true if acquisition has an ExchangeAddIn (xml) with experiences for compose entry point
 */
export function hasExchangeAddInComposeCommand(acquisition: M365Acquisition): boolean {
    return hasExchangeAddIn(acquisition) && hasExchangeAddInRegExp(acquisition, 'MessageCompose');
}

/**
 * @param acquisition M365Acquisition
 * @returns true if acquisition has an ExchangeAddIn (xml) with experiences for reading pane entry point
 */
export function hasExchangeAddInReadCommand(acquisition: M365Acquisition): boolean {
    return hasExchangeAddIn(acquisition) && hasExchangeAddInRegExp(acquisition, 'MessageRead');
}

/**
 * @param acquisition M365Acquisition
 * @returns true if acquisition has an ExchangeAddIn (xml) with experiences for calendar compose entry point
 */
export function hasExchangeAddInCalendarComposeCommand(acquisition: M365Acquisition): boolean {
    return hasExchangeAddIn(acquisition) && hasExchangeAddInRegExp(acquisition, 'CalendarCompose');
}

/**
 * @param acquisition M365Acquisition
 * @returns true if acquisition has an ExchangeAddIn (xml) with experiences for calendar read entry point
 */
export function hasExchangeAddInCalendarReadCommand(acquisition: M365Acquisition): boolean {
    return hasExchangeAddIn(acquisition) && hasExchangeAddInRegExp(acquisition, 'CalendarRead');
}

/**
 * @param acquisition `M365Acquisition`
 * @returns `true` if `acquisition` has sub-commands launch experience
 */
export function hasExchangeAddInSubcommands(acquisition: M365Acquisition): boolean {
    return hasExchangeAddIn(acquisition) && hasExchangeAddInRegExp(acquisition, 'SubCommands');
}

/**
 * @param acquisition `M365Acquisition`
 * @returns `true` if `acquisition` has side-pane launch experience
 */
export function hasExchangeAddInTaskPane(acquisition: M365Acquisition): boolean {
    return hasExchangeAddIn(acquisition) && hasExchangeAddInRegExp(acquisition, 'TaskPane');
}

type ExchangeAddInExperiences = InferArrayType<typeof ExperiencesForType['AddInExchange']>;
/**
 * @param acquisition M365Acquisition
 * @returns true if acquisition has an ExchangeAddIn (xml) with experiences for compose entry point and is supported in shared folders
 */
export function hasExchangeAddInSharedFolderComposeCommand(acquisition: M365Acquisition): boolean {
    return (
        hasExchangeAddInComposeCommand(acquisition) &&
        hasExchangeAddInRegExp(acquisition, 'MessageComposeSharedFolder')
    );
}

/**
 * @param acquisition M365Acquisition
 * @returns true if acquisition has an ExchangeAddIn (xml) with experiences for read entry point and is supported in shared folders
 */
export function hasExchangeAddInSharedFolderReadCommand(acquisition: M365Acquisition): boolean {
    return (
        hasExchangeAddInReadCommand(acquisition) &&
        hasExchangeAddInRegExp(acquisition, 'MessageReadSharedFolder')
    );
}

/**
 * @param acquisition M365Acquisition
 * @returns true if acquisition has an ExchangeAddIn (xml) with experiences for multi item selection
 */
export function hasExchangeAddInMultiSelectCommand(acquisition: M365Acquisition): boolean {
    return (
        (hasExchangeAddInReadCommand(acquisition) || hasExchangeAddInComposeCommand(acquisition)) &&
        hasExchangeAddInRegExp(acquisition, 'MultipleSelection')
    );
}

/**
 * @param acquisition M365Acquisition
 * @returns true if acquisition has an ExchangeAddIn (xml) with experiences for no item context
 */
export function hasExchangeAddinNoContextCommand(acquisition: M365Acquisition): boolean {
    return (
        (hasExchangeAddInReadCommand(acquisition) || hasExchangeAddInComposeCommand(acquisition)) &&
        hasExchangeAddInRegExp(acquisition, 'NoItemContext')
    );
}

/**
 * Maps experiences and entry points by reading an add-in's XML content.
 *
 * Reference: https://learn.microsoft.com/en-us/javascript/api/manifest/extensionpoint?view=powerpoint-js-preview#extension-points-for-outlook
 */
const ExtensionSearchExperienceRegExp: {
    readonly [key in ExchangeAddInExperiences]: RegExp;
} = {
    SubCommands: /<Control (xsi|schema):type="Menu"/g,
    TaskPane: /(<Action (xsi|schema):type=")(ShowTaskpane|ShowTaskPane)(")/g,
} as const;

type ExchangeAddInEntrypoints = InferArrayType<typeof EntrypointsForType['AddInExchange']>;
/**
 * Maps entrypoints in OWA/Monarch to regular expression that extracts it from an add-in's XML manifest.
 *
 * Reference: https://learn.microsoft.com/en-us/javascript/api/manifest/extensionpoint?view=powerpoint-js-preview#extension-points-for-outlook
 */
const ExtensionSearchEntrypointRegExp: {
    readonly [key in ExchangeAddInEntrypoints]: RegExp;
} = {
    MessageRead: /<ExtensionPoint (xsi|schema):type="MessageReadCommandSurface">/g,
    MessageCompose: /<ExtensionPoint (xsi|schema):type="MessageComposeCommandSurface">/g,
    CalendarRead: /<ExtensionPoint (xsi|schema):type="AppointmentAttendeeCommandSurface">/g,
    CalendarCompose: /<ExtensionPoint (xsi|schema):type="AppointmentOrganizerCommandSurface">/g,
    MessageReadSharedFolder: /<SupportsSharedFolders>true/g,
    MessageComposeSharedFolder: /<SupportsSharedFolders>true/g,
    MultipleSelection: /<SupportsMultiSelect>true|<SupportsNoItemContext>true/g,
    NoItemContext: /<SupportsNoItemContext>true/g,
} as const;

/**
 * @param acquisition M365Acquisition
 * @param command MessageRead or MessageCompose
 * @returns Whether an acquisition has at least one instance of `regExp` match.
 */
export function hasExchangeAddInRegExp(
    acquisition: M365Acquisition,
    regExpKey:
        | Extract<AcquisitionExperience, keyof typeof ExtensionSearchExperienceRegExp>
        | Extract<AcquisitionEntrypoint, keyof typeof ExtensionSearchEntrypointRegExp>
): boolean {
    const exchangeAddIns = acquisition.titleDefinition.elementDefinitions?.exchangeAddIns;
    if (!exchangeAddIns || exchangeAddIns.length === 0) {
        return false;
    }
    const searchRegExp = {
        ...ExtensionSearchExperienceRegExp,
        ...ExtensionSearchEntrypointRegExp,
    };
    const regExp = searchRegExp[regExpKey];
    for (const exchangeAddIn of exchangeAddIns) {
        let xmlDefinitionString = exchangeAddIn.xmlDefinition;
        if (containsBothVersionOverrides(xmlDefinitionString)) {
            // If versionOverridesV1_0 and versionOverridesV1_1 are present, we only want to check versionOverridesV1_1
            // since all new MOS aware clients support the latest API set by default
            const versionV1_1Index = exchangeAddIn.xmlDefinition.indexOf('VersionOverridesV1_1');
            if (versionV1_1Index !== -1) {
                xmlDefinitionString = exchangeAddIn.xmlDefinition.substring(
                    versionV1_1Index,
                    exchangeAddIn.xmlDefinition.length - 1
                );
            }
        }
        if (xmlDefinitionString.match(regExp)) {
            return true;
        }
    }

    return false;
}

/**
 * @param inputXML string
 * @description Checks if the input XML contains both VersionOverridesV1_0 and VersionOverridesV1_1
 * @returns boolean
 */
function containsBothVersionOverrides(inputXML: string): boolean {
    const versionV1_0 = 'VersionOverridesV1_0';
    const versionV1_1 = 'VersionOverridesV1_1';

    return inputXML.includes(versionV1_0) && inputXML.includes(versionV1_1);
}
