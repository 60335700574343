import favoritesStore from '../store/store';
import { addToOrderedFavoriteIdList } from '../actions/v2/addToOrderedFavoriteIdList';
import { mutator } from 'satcheljs';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import isMultiAccountsCombinedFavoritesEnabled from '../utils/isMultiAccountsCombinedFavoritesEnabled';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
export default mutator(addToOrderedFavoriteIdList, actionMessage => {
    const favoriteData = actionMessage.favoriteData;
    const key = getIndexerValueForMailboxInfo(
        favoriteData?.mailboxInfo ?? getModuleContextMailboxInfo()
    );

    const orderedOutlookFavoritesIds = isMultiAccountsCombinedFavoritesEnabled()
        ? favoritesStore.orderedCombinedOutlookFavoritesIds
        : favoritesStore.favoriteTreeData.get(key)?.orderedOutlookFavoritesIds;

    orderedOutlookFavoritesIds?.push(favoriteData.favoriteId);
});
