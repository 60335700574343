import {
    createLazyComponent,
    LazyModule,
    registerLazyOrchestrator,
    LazyAction,
} from 'owa-bundling';
import onShowPostInstallSuccessDialog from 'owa-app-host-actions/lib/actions/onShowPostInstallSuccessDialog';
import { isDetailsDialogOpen } from 'owa-app-host-state/lib/store/dialogStore';
import { appStoreModalStore } from 'owa-app-host-state/lib/store/modalStore';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "AppHostComponents" */ './lazyIndex'),
    { name: 'AppHostComponents' }
);
const AppHostContainer = createLazyComponent(lazyModule, m => m.AppHostContainer);
const AppStoreDetailsDialog = createLazyComponent(
    lazyModule,
    m => m.AppStoreDetailsDialog,
    undefined,
    undefined,
    { onlyImportIf: isDetailsDialogOpen }
);
const AppStoreModal = createLazyComponent(lazyModule, m => m.AppStoreModal, undefined, undefined, {
    onlyImportIf: () => appStoreModalStore.store().isOpen,
});
const JITInstallDialog = createLazyComponent(lazyModule, m => m.JITInstallDialog);
const StageViewModal = createLazyComponent(lazyModule, m => m.StageViewModal);
const TaskModuleDialog = createLazyComponent(lazyModule, m => m.TaskModuleDialog);

registerLazyOrchestrator(
    onShowPostInstallSuccessDialog,
    lazyModule,
    m => m.onShowPostInstallSuccessDialogOrchestrator
);

export {
    AppHostContainer,
    AppStoreDetailsDialog,
    AppStoreModal,
    JITInstallDialog,
    StageViewModal,
    TaskModuleDialog,
};

export const lazyOpenTaskModule = new LazyAction(lazyModule, m => m.openTaskModule);
export const lazyUpdateTaskModule = new LazyAction(lazyModule, m => m.updateTaskModule);
export const lazyOpenStageView = new LazyAction(lazyModule, m => m.openStageView);
