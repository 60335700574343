import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isOfflineSyncEnabled } from './isOfflineSyncEnabled';
import { getItem } from 'owa-local-storage';
import {
    getOfflineContactsFallbackToOnlineInSessionStorageKey,
    getOfflineContactsFallbackToOnlineLocalStorageKey,
} from './utils/toggleOfflineContacts';
import { getSessionId } from 'owa-config';

export function isOfflineDataInLpcEnabled(viewerMailboxInfo: MailboxInfo) {
    // account check for fallback to online
    const fallbackToOnlineForAccount = getItem(
        self,
        getOfflineContactsFallbackToOnlineLocalStorageKey(viewerMailboxInfo)
    );
    const isAccountFallbackToOnlineData: boolean =
        !!fallbackToOnlineForAccount && fallbackToOnlineForAccount.toLocaleLowerCase() == 'true'; // this logic because its a string value

    // session check for fallback to online
    const fallbackToOnlineSession: string =
        getItem(self, getOfflineContactsFallbackToOnlineInSessionStorageKey(viewerMailboxInfo)) ||
        '';
    const isSessionFallbackToOnlineData: boolean =
        !!fallbackToOnlineSession && getSessionId() === fallbackToOnlineSession;

    // We are intentionally not passing in MailboxInfo to below
    // flight checks because framework sync currently is
    // completely conditioned on the global settings mailbox account's
    // flights since they have some trouble at this time
    // to completely flight offline by account.
    return (
        isOfflineSyncEnabled(viewerMailboxInfo) &&
        isFeatureEnabled('peo-offline') &&
        !isAccountFallbackToOnlineData &&
        !isSessionFallbackToOnlineData &&
        !isFeatureEnabled('peo-offline-via-pdv')
    );
}
