import type AppointmentComposeAdapter from './AppointmentComposeAdapter';
import type AppointmentReadAdapter from './AppointmentReadAdapter';
import type LoadedAdapter from './LoadedAdapter';
import type CommonAdapter from './CommonAdapter';
import type MessageComposeAdapter from './MessageComposeAdapter';
import type MessageReadAdapter from './MessageReadAdapter';
import type MultiSelectAdapter from './MultiSelectAdapter';

export type Adapter =
    | CommonAdapter
    | MessageComposeAdapter
    | MessageReadAdapter
    | AppointmentComposeAdapter
    | AppointmentReadAdapter
    | MultiSelectAdapter;

export type LoadedItemAdapter = LoadedAdapter & (MessageComposeAdapter | MessageReadAdapter);

interface Adapters {
    [hostItemIndex: string]: Adapter;
}

interface LoadedItemAdapters {
    [hostItemIndex: string]: LoadedItemAdapter;
}

const loadedItemAdapters: LoadedItemAdapters = {};

const adapters: Adapters = {};

export function addAdapter(hostItemIndex: string, adapter: Adapter) {
    if (isAdapterPresent(hostItemIndex)) {
        deleteAdapter(hostItemIndex);
    }
    adapters[hostItemIndex] = adapter;
}

export function deleteAdapter(hostItemIndex: string) {
    delete adapters[hostItemIndex];
}

export function getAdapter(hostItemIndex: string): Adapter {
    return adapters[hostItemIndex];
}

export function isAdapterPresent(hostItemIndex: string): boolean {
    return !!adapters[hostItemIndex];
}

/**
 * Adds a loaded item adapter associated with the specified host item index and adds a reference to the control ID.
 *
 * @param hostItemIndex - The ID containing surface type (ex: Read/Compose/Multiselect) and the itemID.
 * @param adapter - The adapter that we are adding to the map.
 * @param controlId - The ID of the control to find the corresponding loaded item adapter for.
 */
export function addLoadedItemAdapter(
    hostItemIndex: string,
    adapter: LoadedItemAdapter,
    controlId: string
) {
    if (!isLoadedItemAdapterPresent(hostItemIndex)) {
        loadedItemAdapters[hostItemIndex] = adapter;
        loadedItemAdapters[hostItemIndex].controlIds.push(controlId);
    }
}

/**
 * Adds a reference to the control ID in the loaded item adapter associated with the specified host item index.
 *
 * @param hostItemIndex - The ID containing surface type (ex: Read/Compose/Multiselect) and the itemID.
 * @param controlId - The ID of the control to find the corresponding loaded item adapter for.
 */
export function addReferenceToLoadedItemAdapter(hostItemIndex: string, controlId: string) {
    if (
        isLoadedItemAdapterPresent(hostItemIndex) &&
        !loadedItemAdapters[hostItemIndex].controlIds.includes(controlId)
    ) {
        loadedItemAdapters[hostItemIndex].controlIds.push(controlId);
    }
}

/**
 * Determines if a loaded item adapter is present with the specified host item index.
 *
 * @param hostItemIndex - The ID containing surface type (ex: Read/Compose/Multiselect) and the itemID.
 * @returns True or False depending on whether the adapter was found.
 */
export function isLoadedItemAdapterPresent(hostItemIndex: string): boolean {
    return !!loadedItemAdapters[hostItemIndex];
}

/**
 * Determines if a loaded item adapter is present with the specified control ID.
 *
 * @param controlId - The ID of the control to find the corresponding loaded item adapter for.
 * @returns True or False depending on whether the adapter was found.
 */
export function isLoadedItemAdapterPresentWithControlId(controlId: string): boolean {
    const hostItemIndices = Object.keys(loadedItemAdapters);
    for (const hostItemIndex of hostItemIndices) {
        const loadedItemAdapter = loadedItemAdapters[hostItemIndex];
        if (loadedItemAdapter.controlIds.includes(controlId)) {
            return true;
        }
    }
    return false;
}

/**
 * Dereferences the control ID from the loaded item adapter associated with the specified control ID.
 * If the loaded item adapter no longer has any control IDs associated with it, it is removed.
 *
 * @param controlId - The ID of the control to find the corresponding loaded item adapter for.
 */
export function derefLoadedItemAdapter(controlId: string) {
    const hostItemIndices = Object.keys(loadedItemAdapters);
    for (const hostItemIndex of hostItemIndices) {
        const loadedItemAdapter = loadedItemAdapters[hostItemIndex];
        if (loadedItemAdapter.controlIds.includes(controlId)) {
            loadedItemAdapter.controlIds = loadedItemAdapter.controlIds.filter(
                id => id !== controlId
            );
            if (loadedItemAdapter.controlIds.length === 0) {
                delete loadedItemAdapters[hostItemIndex];
            }
            return;
        }
    }
}

/**
 * Retrieves the loaded item adapter associated with the specified control ID.
 *
 * @param controlId - The ID of the control to find the corresponding loaded item adapter for.
 * @returns The loaded item adapter.
 */
export function getLoadedItemAdapterByControlId(controlId: string): LoadedItemAdapter | undefined {
    let resultHostItemIndex: string = '';
    const hostItemIndices = Object.keys(loadedItemAdapters);
    for (const hostItemIndex of hostItemIndices) {
        const loadedItemAdapter = loadedItemAdapters[hostItemIndex];
        if (loadedItemAdapter.controlIds.includes(controlId)) {
            resultHostItemIndex = hostItemIndex;
            break;
        }
    }
    return loadedItemAdapters[resultHostItemIndex];
}
