import type TableView from '../store/schema/TableView';

/**
 * Gets whether 1 or more items are checkmark-selected in the table
 * @param tableView the table view
 * @return true if 1 or more items are checkmark-selected in table, false otherwise
 */
export default function getTableHasCheckedItems(tableView: TableView): boolean {
    const { isInCheckedMode, isInVirtualSelectAllMode, selectedRowKeys } = tableView;
    // Return true when in virtual select all mode OR when the tableView is in multi-check mode and at least one item is in selection.
    return isInVirtualSelectAllMode || (isInCheckedMode && selectedRowKeys.size >= 1);
}
