import loc from 'owa-localize';
import { favoritesDiagnosticDataDescription } from './favoritesDiagnosticStrings.locstring.json';
import { lazyRegisterDataCollectorIfNotRegistered } from 'diagnostics-data-collection';
import { ObservableMap } from 'mobx';

export async function registerDataCollector() {
    const getFoldersDataCollector = {
        dataCollectionAction: getFolderFavoritessDataCollectionAction,
        name: 'OutlookFavorites',
        odsFileDescription: 'Favorites Data',
        description: loc(favoritesDiagnosticDataDescription),
    };
    await lazyRegisterDataCollectorIfNotRegistered.importAndExecute(getFoldersDataCollector);
}

const favoriteFoldersLog = new ObservableMap<string, string>();

export function setFolderFavoritesDataCollectionInformation(diagnostic: string, value: string) {
    favoriteFoldersLog.set(diagnostic, value);

    // Register the data collector to upload the favorites folders data response to logs if user reports an issue
    registerDataCollector();
}

function getFolderFavoritessDataCollectionAction(): Promise<string> {
    return Promise.resolve(JSON.stringify(favoriteFoldersLog));
}
