import { getLocalizedString } from 'owa-datetime-formatters-core';
import isOneSpecifierBeforeTheOther from './isOneSpecifierBeforeTheOther';
import { format } from 'owa-localize';
import { localizedFormatter } from './localizedFormatter';

export function getShortYearMonthFormat(dateFormat: string, _timeFormat: string) {
    const yearFirst = isOneSpecifierBeforeTheOther(dateFormat, 'y', 'M');
    const formatString = yearFirst
        ? getLocalizedString('yearMonthFormatYearFirst')
        : getLocalizedString('yearMonthFormatMonthFirst');
    return format(formatString, getLocalizedString('MMM'), getLocalizedString('yyyy'));
}

export default localizedFormatter(getShortYearMonthFormat);
