import { isFeatureEnabled } from 'owa-feature-flags';
import type { SearchTableQuery } from 'owa-mail-list-search';
import type { TableView } from 'owa-mail-list-store';

export default function shouldShowSenderGroupingsHeader(tableView: TableView) {
    return (
        isFeatureEnabled('tri-senderGroupings') &&
        (tableView?.tableQuery as SearchTableQuery)?.scenarioType == 'senderGroupings'
    );
}
