import type Item from 'owa-service/lib/contract/Item';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { isFeatureEnabled } from 'owa-feature-flags';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID } from 'owa-folders-constants';
import type { MailboxInfo } from 'owa-client-types';

export default function shouldActivateAddins(
    item: Pick<Item, 'ParentFolderId' | 'ItemClass' | 'IsDraft' | 'RightsManagementLicenseData'>,
    mailboxInfo: MailboxInfo
): boolean {
    if (isIRMItem(item) && !isAddinAllowedForIRMItem(item, mailboxInfo)) {
        // IRM
        return false;
    }
    if (item.IsDraft) {
        // DRAFT
        return false;
    }
    const itemClass = item.ItemClass ? item.ItemClass.toLowerCase() : '';
    if (
        itemClass.indexOf('ipm.note.smime') == 0 &&
        itemClass.indexOf('ipm.note.smime.multipartsigned') == -1
    ) {
        // SMIME Encrypted
        return false;
    }
    if (itemClass.indexOf('report') == 0) {
        // Report includes NDR (non-delivery receipt), DR (delivery receipt), RN (read notification), NRN (not read notification), delay (delay notification), etc.
        return false;
    }

    if (
        isFeatureEnabled('addin-disableAddinsForInPlaceArchiveFolder') &&
        item?.ParentFolderId?.Id &&
        folderIdToName(item.ParentFolderId.Id) === ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID
    ) {
        return false;
    }
    return true;
}

export function isAddinAllowedForIRMItem(
    item: Pick<Item, 'ParentFolderId' | 'ItemClass' | 'IsDraft' | 'RightsManagementLicenseData'>,
    mailboxInfo: MailboxInfo
): boolean {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (49,5): Type 'boolean | undefined' is not assignable to type 'boolean'.
    // @ts-expect-error
    return (
        !isConsumer(undefined /* smtpAddress */, mailboxInfo) &&
        item.RightsManagementLicenseData?.ProgrammaticAccessAllowed
    );
}

export function isIRMItem(
    item: Pick<Item, 'ItemClass' | 'IsDraft' | 'RightsManagementLicenseData'>
): boolean {
    return item.RightsManagementLicenseData != null;
}
