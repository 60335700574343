import { getLocalizedString } from 'owa-datetime-formatters-core';
import { format } from 'owa-localize';
import { localizedFormatter } from './localizedFormatter';
import { getShortUserDateFormat } from './formatShortUserDate';

/**
 * Gets a short version of the user's date format, with just weekday, day and month.
 */
export function getWeekDayShortUserDateFormat(dateFormat: string, _timeFormat: string): string {
    return format(getLocalizedString('weekDayDateFormat'), getShortUserDateFormat(dateFormat));
}

export default localizedFormatter(getWeekDayShortUserDateFormat);
