import type OwaWorkload from '../store/schema/OwaWorkload';
import getOwaWorkload from '../selectors/getOwaWorkload';

export default function getEmailAddressesForCurrentWorkload(): string[] {
    const currentWorkload = getOwaWorkload();
    switch (currentWorkload) {
        case 1:
        case 8:
        case 32:
        case 2415919103:
        case 0:
            return ['outlookweberrors@microsoft.com'];
        case 2:
            return ['outlookweberrors@microsoft.com'];
        case 4:
            return ['outlookweberrors@microsoft.com', 'Opfeedbackoc@service.microsoft.com'];
        case 16384:
            return ['eventifyfeedback@microsoft.com'];
        case 1048576:
            return ['fastfxsp@service.microsoft.com'];
        default:
            return ['outlookweberrors@microsoft.com'];
    }
}
