import type TableView from '../store/schema/TableView';
import type MailFolderTableQuery from '../store/schema/MailFolderTableQuery';
import { type TableQueryType } from '../store/schema/TableQuery';

// Determines if the table view filter is user category and returns a category name.
export default function getUserCategoryName(tableView: TableView) {
    return tableView.tableQuery.type === 0 &&
        (tableView.tableQuery as MailFolderTableQuery).viewFilter === 'UserCategory'
        ? (tableView.tableQuery as MailFolderTableQuery).categoryName
        : null;
}
