import { getAdMarketPublishGroupCode, getMarketCountryCode } from './sharedAdsUtils';
import isGdprAdMarket from './isGdprAdMarket';

export default function isEUEmailAdsPolicyMarket() {
    const countryCode = getMarketCountryCode(getAdMarketPublishGroupCode());

    // Exclude GB from EU from the GDPR country. If it turns out GB needs to apply the policy, we can use the same check as isGdprAdMarket
    if (countryCode == 'GB') {
        return false;
    }

    return isGdprAdMarket();
}
