import { getActiveContentTab } from 'owa-tab-store';
import { getStore } from '../store/Store';

export default function isAnySxSVisibleInMainWindow(): boolean {
    const sxsId = getActiveContentTab()?.sxsId;
    if (sxsId) {
        const store = getStore().sxsStoreMapping.get(sxsId);
        if (!!store) {
            return !store.isInvisible;
        }
    }
    return false;
}
