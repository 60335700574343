export const home_tab = "dlen_d";
export const view_tab = "vzJG4d";
export const message_tab = "NrFgC";
export const insert_tab = "X9_1Lb";
export const format_tab = "VnL4jd";
export const options_tab = "ozkO";
export const notes_tab = "VCft$";
export const format_table_tab = "wjJQie";
export const format_picture_tab = "C4E61d";
export const newMessage_Button = "YKWcZc";
export const newMessage_Button_MLR = "uVnIce";
export const newPost_Button = "x3Zt_";
export const newPostHero_Button = "breDXc";
export const newYammerPost_Button = "UvNRKe";
export const newEMailMessage_Button = "s_8nK";
export const newCalEvent_Button = "fqq_Kc";
export const ignoreEmail_Button = "tUNOXc";
export const stopIgnoringEmail_Button = "KZolPd";
export const blockSender_Button = "TJV50";
export const notJunk_Button = "MyDuCe";
export const archiveEmail_Button = "lsSRKb";
export const sweepEmail_Button = "J9kvxb";
export const replyToEmail_Button = "W$1SF";
export const replyAllToEmail_Button = "fd$tv";
export const replyForwardEmail_Button = "oJvIW";
export const replyForwardEmailAsAttachment_Button = "bxGNuc";
export const replyMeetingToEmail_Button = "nE_X6";
export const replyCopilotMeetingToEmail_Button = "F$mfzb";
export const replyCopilotMeetingToEmail_Button_Tooltip = "BRjs3b";
export const readUnread_Button = "eSe6we";
export const pinUnpin_Button = "cCkT7b";
export const pinUnpin_Button_MLR = "M01Vmb";
export const sendReceiveAllFolders_Button = "yxAKfe";
export const sendAll_Button = "O4hPpb";
export const updateFolder_Button = "qVPIBb";
export const showSyncProgress_Button = "CyMj8c";
export const cancelSync_Button = "EDNrve";
export const workOffline_Button = "zjYaTb";
export const downloadHeaders_Button = "fLP8Ib";
export const move_FlyoutButton = "BqzPEb";
export const move_FlyoutButton_MLR = "xKKlWd";
export const rules_FlyoutButton = "dBgged";
export const createRule_Button = "DkiJjd";
export const manageRules_Button = "nRmsJe";
export const assignPolicy_FlyoutButton = "fjwYUe";
export const assignPolicy_FlyoutButton_MLR = "Zcs5Bd";
export const categorize_FlyoutButton = "ZWYFNc";
export const sendReceiveGroups_Button = "KDUv1d";
export const getMessageExtensions_Button = "EvAiSc";
export const report_Button = "zgnZoc";
export const immersiveReader_Button = "ta3AZb";
export const remindersWindow_Button = "I935sd";
export const printMail_Button = "L8ASvd";
export const snooze_Button = "NgbYWb";
export const undo_Button = "h5j1Yd";
export const block_Button = "kxzoHc";
export const paste_FlyoutButton = "$gE$$d";
export const formatPainter_Button = "HQDsQb";
export const attachSig_Button = "Lqjnbd";
export const encrypt_FlyoutButton = "nNwOce";
export const encrypt_clearPermissionsButton = "xYn5x";
export const sensitivity_FlyoutButton = "zUv9ab";
export const ribbonCustomizer_Button = "yULhsc";
export const insertLink_Button = "m7Zafc";
export const newNote_HeroButton = "Zj6qrd";
export const newNote_MenuButton = "W1MN9c";
export const signatureOptions_Button = "Pd7ZBb";
export const browseGroups_Button = "DH4EHb";
export const viewGroupsPanel_Button = "KZ9sNd";
export const viewGroupsPanel_Button_MLR = "cKdDAe";
export const addGroup_Button = "lCSn2d";
export const moreRetentionPoliciesOptions_Button = "oTeksd";
export const viewSettings_Button = "zyHYFd";
export const conversations_Button = "V79KWd";
export const messagePreview_Button = "UZvQqe";
export const layout_Button = "zLfWNc";
export const folder_Button = "fTNe2";
export const folderMenu_Show = "Hvb3Z";
export const folderMenu_Hide = "KO0O0";
export const density_Button = "HS1gU";
export const manageQuickSteps_Button = "KLHNYb";
export const messages_Button = "Bp9Ndd";
export const quickSteps_Button = "rPFClc";
export const quickSteps_MenuLauncherLabel = "g$f1ae";
export const markAllAsRead_Button = "B3IAcb";
export const newEventifyEvent_Button = "rmTqjc";
export const draw_tab = "ahVQIc";
export const hide_Favorites = "ceK_$";
export const show_Favorites = "bEddH";
export const collapseConversation_Button = "VBo1Td";
export const expandConversation_Button = "IRjPKd";
export const zoom_Button = "UiGgzb";
export const ribbonMode_Button = "wJbLIc";
export const syncMailbox_Button = "mISjR";
export default {home_tab,view_tab,message_tab,insert_tab,format_tab,options_tab,notes_tab,format_table_tab,format_picture_tab,newMessage_Button,newMessage_Button_MLR,newPost_Button,newPostHero_Button,newYammerPost_Button,newEMailMessage_Button,newCalEvent_Button,ignoreEmail_Button,stopIgnoringEmail_Button,blockSender_Button,notJunk_Button,archiveEmail_Button,sweepEmail_Button,replyToEmail_Button,replyAllToEmail_Button,replyForwardEmail_Button,replyForwardEmailAsAttachment_Button,replyMeetingToEmail_Button,replyCopilotMeetingToEmail_Button,replyCopilotMeetingToEmail_Button_Tooltip,readUnread_Button,pinUnpin_Button,pinUnpin_Button_MLR,sendReceiveAllFolders_Button,sendAll_Button,updateFolder_Button,showSyncProgress_Button,cancelSync_Button,workOffline_Button,downloadHeaders_Button,move_FlyoutButton,move_FlyoutButton_MLR,rules_FlyoutButton,createRule_Button,manageRules_Button,assignPolicy_FlyoutButton,assignPolicy_FlyoutButton_MLR,categorize_FlyoutButton,sendReceiveGroups_Button,getMessageExtensions_Button,report_Button,immersiveReader_Button,remindersWindow_Button,printMail_Button,snooze_Button,undo_Button,block_Button,paste_FlyoutButton,formatPainter_Button,attachSig_Button,encrypt_FlyoutButton,encrypt_clearPermissionsButton,sensitivity_FlyoutButton,ribbonCustomizer_Button,insertLink_Button,newNote_HeroButton,newNote_MenuButton,signatureOptions_Button,browseGroups_Button,viewGroupsPanel_Button,viewGroupsPanel_Button_MLR,addGroup_Button,moreRetentionPoliciesOptions_Button,viewSettings_Button,conversations_Button,messagePreview_Button,layout_Button,folder_Button,folderMenu_Show,folderMenu_Hide,density_Button,manageQuickSteps_Button,messages_Button,quickSteps_Button,quickSteps_MenuLauncherLabel,markAllAsRead_Button,newEventifyEvent_Button,draw_tab,hide_Favorites,show_Favorites,collapseConversation_Button,expandConversation_Button,zoom_Button,ribbonMode_Button,syncMailbox_Button};