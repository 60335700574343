import type { MailboxInfo } from 'owa-client-types';
import { updateAccountScopeUserSettingsAndService } from 'owa-userconfiguration/lib/utils/updateAccountScopeUserSettingsAndService';
import { getMailRibbonConfigStore } from '../store/store';

export const pushMailRibbonConfigurationToService = (mailboxInfo: MailboxInfo) => {
    const ribbonConfigString: string = JSON.stringify(getMailRibbonConfigStore(mailboxInfo));
    return updateAccountScopeUserSettingsAndService(mailboxInfo, {
        ViewStateConfiguration: {
            MailRibbonConfig: ribbonConfigString,
        },
    });
};
