import type { MailboxInfo } from 'owa-client-types';
import { action } from 'satcheljs';

/**
 * Action invoked when the user switches accounts
 */
export default action(
    'onAccountSwitched',
    (oldMailBox: MailboxInfo, currentMailBox: MailboxInfo) => ({
        oldMailBox,
        currentMailBox,
    })
);
