import type { TableView } from 'owa-mail-list-store';
import type { ClientItem } from 'owa-mail-store';
import type { ClientItemId } from 'owa-client-ids';
import {
    MailRowDataPropertyGetter,
    isItemPartOperation,
    getListViewState,
} from 'owa-mail-list-store';
import getExpandedItemId from 'owa-mail-commands/lib/utils/getExpandedItemId';
import { getStore as getMailStore } from 'owa-mail-store/lib/store/Store';

/**
 * Gets the selected item from the given table view if only single item is selected.
 * If multiple items are selected in the view, returns undefined.
 * @param tableView the table view to get the selected items from
 */
export function getSingleSelectedItemFromView(tableView?: TableView): ClientItem | undefined {
    var singleItem: ClientItem | undefined = undefined;

    if (tableView) {
        if (isItemPartOperation()) {
            // Conversation view
            const selectedNodeIds: string[] =
                getListViewState().expandedConversationViewState.selectedNodeIds;

            if (selectedNodeIds.length == 1) {
                const clientItemId: ClientItemId = getExpandedItemId(tableView, selectedNodeIds);
                singleItem = getMailStore().items.get(clientItemId.Id);
            }
        } else {
            // Mail List view
            const rowKeys: string[] = tableView.isInVirtualSelectAllMode
                ? tableView.rowKeys
                : [...tableView.selectedRowKeys.keys()];

            if (rowKeys.length == 1) {
                const rowKey: string = rowKeys[0];
                singleItem = MailRowDataPropertyGetter.getItem(rowKey, tableView);
            }
        }
    }

    return singleItem;
}
