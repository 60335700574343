import { action } from 'satcheljs';
import type { ClientItemId } from 'owa-client-ids';

const onMailServiceReplyReplyAllMessage = action(
    'onMailServiceReplyReplyAllMessage',
    (clientItemId: ClientItemId, isReplyAll: boolean) => ({
        clientItemId,
        isReplyAll,
    })
);

export default onMailServiceReplyReplyAllMessage;
