import { getIndexerForConnectedAccount } from './getIndexerForConnectedAccount';
import type { AccountKey } from 'owa-client-types';

/**
 * To suppport shared/delegate mailboxes as coprincipal accounts we need to be able to support multiple
 * coprincipal accounts with the same user identity values (such as user@example.com and shared@example.com
 * that are both authenticated as user@example.com). This means that we need to replace the user identity
 * key in the connected accounts with the account key of the mailbox info. This function converts the
 * account key or user identity value into an account key.
 *
 * @param accountKeyOrUserIdentity Either an account key or user identity.
 * @returns The account key
 */
export function getAccountKeyForConnectedAccount(accountKeyOrUserIdentity: string): AccountKey {
    return getIndexerForConnectedAccount(accountKeyOrUserIdentity);
}
