import { lazyGetViewGroupsSections } from 'owa-mail-ribbon-sections/lib/sections';

import type { MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onViewGroupsMenuClicked = (
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
) => {
    lazyGetViewGroupsSections.import().then(getViewGroupsSections => {
        setMenuDefinition('ViewGroupsMenuDefinitionStore', undefined /* projectionTabId */, () => {
            return {
                sections: getViewGroupsSections(keytipTabId, props),
            };
        });
    });
};
