import { logStartGreyError } from 'owa-analytics-start';
import type DistinguishedFolderIdName from 'owa-service/lib/contract/DistinguishedFolderIdName';
import folderIdToName from './folderIdToName';

export default function doesFolderIdEqualName(
    folderId: string,
    distinguishedFolderName: DistinguishedFolderIdName
): boolean {
    if (!folderId) {
        const error = new Error('DoesFolderIdEqualNameInvalidParameters');
        logStartGreyError('DoesFolderIdEqualNameInvalidParameters', error);

        return false;
    }

    return folderIdToName(folderId) === distinguishedFolderName;
}
