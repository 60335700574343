import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';
import { isMOSLaunchPagesEnabled } from './isMOSLaunchPagesEnabled';

/**
 * ToDo MetaOS app is enabled if mos-todoApp feature flag is enabled and MOS launch pages are enabled.
 */
export function isToDoMetaOsEnabled(mailboxInfo?: MailboxInfo) {
    return isFeatureEnabled('mos-todoApp', mailboxInfo) && isMOSLaunchPagesEnabled(mailboxInfo);
}
