import { isFeatureEnabled } from 'owa-feature-flags';
import type { ElaborateFilterSettings } from '../types/serviceSettingTypes';

export default function createDefaultScenarioOption(): ElaborateFilterSettings {
    return {
        tone:
            isFeatureEnabled('mon-copilot-elaborate-tonePersonalization') &&
            isFeatureEnabled('mon-copilot-elaborate-tonePersonalizeEnhancement')
                ? 'Personalized'
                : 'Direct',
        verbosity: 'Short',
    };
}
