import type { MailboxInfo } from 'owa-client-types';
import type IdentityKey from './IdentityKey';
import getIdentityInfoForMailboxInfo from './getIdentityInfoForMailboxInfo';
import getIdentityKeyForIdentityInfo from './getIdentityKeyForIdentityInfo';

/**
 * Returns the identity key for the specified mailboxInfo
 * @param mailboxInfo MailboxInfo for which the identity key is to be obtained
 * @returns IdentityKey or undefined
 */
export function getIdentityKeyForMailboxInfo(mailboxInfo?: MailboxInfo): IdentityKey | undefined {
    if (!!mailboxInfo) {
        const identityInfo = getIdentityInfoForMailboxInfo(mailboxInfo);
        if (!!identityInfo) {
            return getIdentityKeyForIdentityInfo(identityInfo);
        }
    }

    return undefined;
}
