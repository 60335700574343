import type SearchScenarioId from 'owa-search-store/lib/store/schema/SearchScenarioId';
import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';
import type { default as RefinersState, SearchRefinersState } from './schema/SearchRefinersState';

export const createDefaultRefinersStore = (): SearchRefinersState => {
    return {
        appliedRefiners: [],
        suggestedRefiners: [],
        selectedQfRefiners: [],
        refinersInstrumentationContext: undefined,
    };
};

const refinersState: RefinersState = {
    refiners: new ObservableMap<SearchScenarioId, SearchRefinersState>({}),
};

const store = createStore<RefinersState>('searchRefinersStore', refinersState)();
export const getSearchRefinersStore = () => store;
