import type {
    FavoritePersonaData,
    FavoritePersonaNode,
    OutlookFavoriteServiceDataType,
} from 'owa-favorites-types';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import type { MailboxInfo } from 'owa-client-types';

export function convertFavoritePersonaDataToFavoritePersonaNode(
    data: FavoritePersonaData
): FavoritePersonaNode {
    return {
        id: data.favoriteId,
        treeType: 'favorites',
        type: 1,
        allEmailAddresses: data.allEmailAddresses,
        displayName: data.displayName,
        mainEmailAddress: data.mainEmailAddress,
        searchFolderId: data.searchFolderId,
        isSyncUpdateDone: true,
        isJustAdded: false,
        isSearchFolderPopulated: data.isSearchFolderPopulated,
        markedForDeletion: false,
        dropViewState: undefined,
        personaId: data.personaId,
    };
}

export function createInitialFavoritePersonaData(
    favoriteId: string,
    displayName: string,
    emailAddress: string,
    mailboxInfo?: MailboxInfo
): FavoritePersonaData {
    return {
        treeType: 'favorites',
        type: 'persona',
        favoriteId,
        displayName,
        searchFolderId: undefined,
        client: 'OWA',
        allEmailAddresses: [emailAddress],
        personaId: undefined,
        mainEmailAddress: emailAddress,
        isSearchFolderPopulated: false,
        isSyncUpdateDone: true,
        mailboxInfo: mailboxInfo ?? getModuleContextMailboxInfo(),
    };
}

export function createAddFavoritePersonaServicePayload(
    displayName: string,
    emailAddress: string
): OutlookFavoriteServiceDataType {
    return {
        Type: 'persona',
        DisplayName: displayName,
        SingleValueSettings: [
            {
                Key: 'MainEmailAddress',
                Value: emailAddress,
            },
        ],
        Client: 'OWA',
    };
}
