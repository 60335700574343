import getUserConfiguration from '../actions/getUserConfiguration';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Make sure this isn't imported in the analytics worker
 *	> 'owa-feature-flags' import is restricted from being used. Feature flags have to be passed in the analytics worker initialization [analytics-worker-lint] */
import { isFeatureEnabled } from 'owa-feature-flags';

export function isSharedABTEnabled(): boolean {
    return !!(
        isFeatureEnabled('auth-sharedActivityBasedTimeout') &&
        getUserConfiguration().PolicySettings?.IsSharedActivityBasedTimeoutEnabled
    );
}
