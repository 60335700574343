import type { MailboxInfo } from 'owa-client-types';
import { applicationSettingsInitialized } from '../actions/applicationSettingsInitialized';
import { defaultApplicationSettings } from '../store/applicationSettingsDefinitions';
import type { ApplicationSettings } from '../store/schema/ApplicationSettings';
import type { ApplicationSettingsConfig } from '../store/schema/ApplicationSettingsConfig';
import getStore from '../store/store';
import { setAllowDefaultApplicationSettings } from '../utils/allowDefaultApplicationSettings';
import {
    generateApplicationSettingsReport,
    getNoSettingsReport,
} from '../utils/generateApplicationSettingsReport';

export default function initializeApplicationSettings(
    config?: ApplicationSettingsConfig,
    mailboxInfo?: MailboxInfo
) {
    // Once any store is initialized default application settings are no longer allowed
    setAllowDefaultApplicationSettings(false);

    const store = getStore(mailboxInfo);
    store.initialized = true;

    if (!config) {
        const configValue = config === undefined ? 'undefined' : 'null';
        store.lastError = {
            message: 'Client: config object is ' + configValue,
            stackTrace: '',
            onInitialLoad: true,
        };
        store.report = getNoSettingsReport(store.lastError);
        return;
    }

    if (!config.settings) {
        const settingsValue = config.settings === undefined ? 'undefined' : 'null';
        store.lastError = config.lastError ?? {
            message: 'Client: config.settings object is ' + settingsValue,
            stackTrace: '',
            onInitialLoad: true,
        };
        store.report = getNoSettingsReport(store.lastError);
        return;
    }

    const mergedSettings: {
        [key: string]: any;
    } = {};

    const defaultGroups = Object.keys(
        defaultApplicationSettings
    ) as (keyof typeof defaultApplicationSettings)[];

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    defaultGroups.forEach(group => {
        mergedSettings[group] = {};
        const receivedGroup = config.settings[group];

        const defaultAppSettingGroup = defaultApplicationSettings[group];
        const defaultSettingKeys = Object.keys(
            defaultAppSettingGroup
        ) as (keyof typeof defaultAppSettingGroup)[];

        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        defaultSettingKeys.forEach(setting => {
            mergedSettings[group][setting] =
                receivedGroup && receivedGroup.hasOwnProperty(setting)
                    ? receivedGroup[setting as keyof typeof receivedGroup]
                    : defaultApplicationSettings[group][setting];
        });
    });

    store.configIDs = config.configIDs;
    store.lastError = config.lastError;
    store.settings = mergedSettings as ApplicationSettings;

    // on the service we have abused the configIDs field - that is currently a diagnostics
    // field that contains information on the filters applied during creation.
    // It has a prefix <type>:<filtersName0=filterValue1>,...,<filtersNameN=filterValueN>:<configids>
    // where type indicates what type of config we are getting:
    // - User Context Filter - UCF;
    // - No User Context Filters - NOF.
    // The filters are the ECS filters applied during creation of the config.
    let configType = '';
    const index = config.configIDs?.indexOf(':');
    if (index > 0) {
        configType = config.configIDs?.substring(0, index);
    }

    let filters = '';
    const filtersIndex = config.configIDs?.indexOf(':', index + 1);
    if (filtersIndex > 0) {
        filters = config.configIDs?.substring(index + 1, filtersIndex);
    }

    store.report = generateApplicationSettingsReport(
        config.settings,
        configType,
        filters,
        config.lastError
    );

    applicationSettingsInitialized(mailboxInfo, store.settings);
}
