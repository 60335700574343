import { getGlobalSettingsAccount } from 'owa-account-source-list-store';
import { errorThatWillCauseAlert } from 'owa-trace';
import { checkGlobalSettingsReady } from '../utils/checkGlobalSettingsReady';
import logDefaultOrReflowCall from 'owa-account-source-list-store/lib/utils/logDefaultOrReflowCall';
import { getAccountScopeUserSettings } from './getAccountScopeUserSettings';

export default function getDefaultUserEmailAddress(): string | undefined {
    logDefaultOrReflowCall('getDefaultUserEmailAddress');
    checkGlobalSettingsReady();
    const sessionSettings = getAccountScopeUserSettings(
        getGlobalSettingsAccount().mailboxInfo
    ).SessionSettings;

    // This shouldn't happen in production, but if it does we'll have a hint.
    if (!sessionSettings) {
        errorThatWillCauseAlert('SessionSettings not available');
        return 'no-smtp-address';
    }

    return sessionSettings.UserEmailAddress;
}
