import getSelectedTableView from './getSelectedTableView';
import canTableBeOutOfSyncWithServer from './canTableBeOutOfSyncWithServer';
import type LoadErrorStatus from '../store/schema/LoadErrorStatus';
import { type MailListViewState } from 'owa-mail-store/lib/store/schema/MailListViewState';
import type TableView from '../store/schema/TableView';

// Gets the MailList Loading, loaded, Error states
export const getMailListLoadState = (): MailListViewState => {
    const tableView = getSelectedTableView();

    return getMailListLoadStateFromTableView(tableView);
};

export const getMailListLoadStateFromTableView = (
    tableView: TableView | undefined
): MailListViewState => {
    if (!tableView) {
        return 'Loading';
    }

    // Error scenarios have precedence over other states
    if (tableView.loadErrorStatus == 3) {
        return 'OfflineError';
    }

    const isTableEmpty = tableView.rowKeys.length == 0;

    // do not display error if there're cached items in the table to show
    // when error occurs and the table is truly empty, prioritize showing error instead
    // of no items in the list view.
    // For tables that can be out of sync with the server we want to not show the cached rows
    // as they could be in a different state on the server hence we prioritize showing error for such tables.
    if (
        tableView.loadErrorStatus != 0 &&
        (isTableEmpty || canTableBeOutOfSyncWithServer(tableView))
    ) {
        return 'Error';
    }

    // Loading only if isInitialLoadComplete is not completed
    if (!tableView.isInitialLoadComplete) {
        return 'Loading';
    }

    if (isTableEmpty) {
        return 'Empty';
    }

    return 'Loaded';
};
