import setIsSharedMailbox from '../mutators/setIsSharedMailbox';
import getMailboxPermissionOperation from 'owa-service/lib/operation/getMailboxPermissionOperation';
import getMailboxPermissionRequest from 'owa-service/lib/factory/getMailboxPermissionRequest';
import { getJsonRequestHeader } from 'owa-service/lib/ServiceRequestUtils';
import { getStore } from '../store/store';
import { trace } from 'owa-trace';
import type { MailboxInfo } from 'owa-client-types';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

const isSharedMailboxPromiseMap: Map<string, Promise<void>> = new Map();

export default function getIsSharedMailbox(mailboxInfo: MailboxInfo): Promise<void> {
    if (getStore(mailboxInfo).initialized) {
        return Promise.resolve();
    }

    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    let isSharedMailboxPromise = isSharedMailboxPromiseMap.get(key);
    if (!isSharedMailboxPromise) {
        isSharedMailboxPromise = getMailboxPermissionOperation(
            getMailboxPermissionRequest({
                Header: getJsonRequestHeader(),
                User: mailboxInfo.mailboxSmtpAddress,
            }),
            getMailboxRequestOptions(mailboxInfo)
        )
            .then(mailboxPermissionResponse => {
                setIsSharedMailbox(
                    mailboxPermissionResponse.Permission.RecipientTypeDetails == 4,
                    mailboxInfo
                );
            })
            .catch(error => {
                trace.warn(`getIsSharedMailbox Error: ${error}`);
            });

        isSharedMailboxPromiseMap.set(key, isSharedMailboxPromise);
    }

    return isSharedMailboxPromise;
}
