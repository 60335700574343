import { logUsage } from 'owa-analytics';
import { STATE_CHANGE_SOURCES_MAP } from '../constants/internalConstants';
import type { StateChangeSource } from '../types/StateChangeSource';

export const logLeftPaneStateChange = (
    showLeftPane: boolean,
    stateChangeSource: StateChangeSource
) => {
    if (stateChangeSource === undefined) {
        return;
    }

    const eventName = STATE_CHANGE_SOURCES_MAP[stateChangeSource];

    if (!eventName) {
        return;
    }

    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
    logUsage(eventName, [showLeftPane, stateChangeSource]);
};
