import { orReducer } from './orReducer';
import type { AppPaneUnderlayViewState } from '../store/store';
import getStore from '../store/store';

export default function isAppPaneUnderlayPlaceholder(
    underlay?: AppPaneUnderlayViewState
): boolean | undefined {
    const store = underlay || getStore();
    return [...store.isPlaceholder.values()].reduce(orReducer, false);
}
