export { LivePersonaCardInitializationState } from './store/schema/PersonaControlStore';
export { PersonaPhotoFetchStatus } from './store/schema/PersonaBlob';
import { LazyImport, LazyModule, LazyAction, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OwaPersona" */ './lazyIndex'),
    { name: 'OwaPersona' }
);

export const lazyOnPersonaUpdated = new LazyImport(lazyModule, m => m.onPersonaUpdated);

export const lazyGetContactEditorRenderer = new LazyAction(
    lazyModule,
    m => m.getContactEditorRenderer
);

export const lazyGetEmbeddedExpandedViewAccountId = new LazyAction(
    lazyModule,
    m => m.getEmbeddedExpandedViewAccountId
);

export { isPersonaCardOpen } from './selectors/isPersonaCardOpen';
export {
    lazyCheckAndInitializeLivePersonaEditor,
    lazyEnhanceLpcConfigForOffline,
    lazyMakeOfflineEnhancedLpeOnCloseCallback,
    lazyMakeAddOrEditContactV3Callback,
    lazyPerformShadowPdvValidation,
} from './lazyFunctions';

export type { ContactEditorRenderer, LivePersonaEditorOptions, LPETelemetryConfig } from './types';

export const PersonaControl = createLazyComponent(lazyModule, m => m.PersonaControl);
export const PersonaCardBehavior = createLazyComponent(lazyModule, m => m.PersonaCardBehavior);
