import type SecondaryReadingPaneTabData from './SecondaryReadingPaneTabData';
import type FileErrorTabData from './FileErrorTabData';
import type { MailboxInfo } from 'owa-client-types';

export enum TabType {
    Primary,
    OverflowMenu,
    SecondaryReadingPane,
    MailCompose,
    SxS,
    CalendarCompose,
    CalendarReadingPane,
    FullOptions,
    Reminders,
    AddCalendars,
    FileError,
}
export type TabState = any;

export interface TabViewStateBase {
    id: string;
    state: TabState;
    startTime?: number;
    parentTabId?: string;
    blink: boolean;
    sxsId: string;
    data: object | string | null;
}

export interface PrimaryReadingPaneTabViewState extends TabViewStateBase {
    type: TabType.Primary;
    data: null;
}

export interface SecondaryReadingPaneTabViewState extends TabViewStateBase {
    type: TabType.SecondaryReadingPane;
    data: SecondaryReadingPaneTabData;
}

export interface OverflowTabViewState extends TabViewStateBase {
    type: TabType.OverflowMenu;
    data: TabViewState[];
}

export interface CalendarComposeTabViewState extends TabViewStateBase {
    type: TabType.CalendarCompose;
    data: string /* formId */;
}

export interface CalendarReadingPaneTabViewState extends TabViewStateBase {
    type: TabType.CalendarReadingPane;
    data: string /* formId */;
}

export interface MailComposeTabViewState extends TabViewStateBase {
    type: TabType.MailCompose;
    data: string /* composeId */;
    /**
     * Tab Id of parent projection item reading pane.
     * When specified, it can only be used as inline compose for the given item reading pane tab.
     * All other reading pane tabs (conversation RP, item RP with other tab id) will ignore it.
     */
    projectionRPTabId?: string;
}

export interface SxSTabViewState extends TabViewStateBase {
    type: TabType.SxS;
    data: null;
}

export interface FullOptionTabViewState extends TabViewStateBase {
    type: TabType.FullOptions;
    data: MailboxInfo;
}

export interface RemindersTabViewState extends TabViewStateBase {
    type: TabType.Reminders;
    data: string /* remindersTitle */;
}

export interface AddCalendarsTabViewState extends TabViewStateBase {
    type: TabType.AddCalendars;
    data: string /* addCalendarsTitle */;
}

export interface FileErrorTabViewState extends TabViewStateBase {
    type: TabType.FileError;
    data: FileErrorTabData;
}

type TabViewState =
    | PrimaryReadingPaneTabViewState
    | SecondaryReadingPaneTabViewState
    | OverflowTabViewState
    | MailComposeTabViewState
    | SxSTabViewState
    | CalendarComposeTabViewState
    | CalendarReadingPaneTabViewState
    | FullOptionTabViewState
    | RemindersTabViewState
    | AddCalendarsTabViewState
    | FileErrorTabViewState;

export default TabViewState;
