import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Deprecating getPhysicalRing.ts
 *	> 'isEnvironmentAirGap' import from 'owa-metatags' is restricted. This value is resolved in ECS as a filter. Please create a feature flight if possible. */
import { isEnvironmentAirGap } from 'owa-metatags';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import { isOptionalExperiencesDisabledForPrivacy } from 'owa-privacy-utils/lib/selectors/isOptionalExperiencesDisabledForPrivacy';
import { getUserConfiguration, getAccountScopeUserSettings } from 'owa-session-store';
import getAggregatePolicyInfo from './utils/getAggregatePolicyInfo';
import { OcpsPolicy, isOcpsPolicyEnabled } from 'owa-ocps-policy-store';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 */
export const feedbackCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        // get the user options for the primary account
        const { PolicySettings } = getUserConfiguration();
        return !!PolicySettings?.FeedbackEnabled;
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        const policies = getAggregatePolicyInfo('isFeedbackEnabled');
        const isfeedbackPolicyEnabled = policies['isFeedbackEnabled'] as boolean;
        const isFeatureEnabledForUser = isFeatureEnabled('core-feedback', mailboxInfo);
        const isDisabledForPrivacy = isOptionalExperiencesDisabledForPrivacy();
        const isOcpsFeedbackPolicyEnabled_depr = isOcpsPolicyEnabled(OcpsPolicy.Feedback, true);
        const { IsConsumerChild } = getAccountScopeUserSettings(
            mailboxInfo ?? getModuleContextMailboxInfo()
        );

        const isFeedbackEnabledByPolicy = isFeatureEnabled(
            'mon-diag-enableAggregatePolicyCheckForFeedback'
        )
            ? isfeedbackPolicyEnabled
            : isOcpsFeedbackPolicyEnabled_depr;
        return (
            isFeatureEnabledForUser &&
            !isEnvironmentAirGap() &&
            !IsConsumerChild &&
            isFeedbackEnabledByPolicy &&
            !isDisabledForPrivacy
        );
    },
};
