import fixUpHotkeyCommand from './fixupHotkeyCommand';
import getKeyboardShortcutsMode from './getKeyboardShortcutsMode';
import shouldUseMonarchShortcuts from './shouldUseMonarchShortcuts';
import { MonarchKeyboardShortcutsMode } from 'owa-outlook-service-option-store';
import { type default as WebKeyboardShortcutsMode } from 'owa-service/lib/contract/KeyboardShortcutsMode';
import type { HotkeyCommand, Hotkeys } from '../types/HotkeyCommand';

export default function getKeysForKeyboardShortcutsMode(
    command: HotkeyCommand | Hotkeys | undefined
): Hotkeys | undefined {
    if (!command) {
        return undefined;
    }
    command = fixUpHotkeyCommand(command);
    if (typeof command == 'string' || Array.isArray(command)) {
        return command;
    }

    const keyboardShortcutsMode = getKeyboardShortcutsMode();

    return shouldUseMonarchShortcuts()
        ? getMonarchKeysForKeyboardShortcutsMode(
              command,
              keyboardShortcutsMode as MonarchKeyboardShortcutsMode
          )
        : getWebKeysForKeyboardShortcutsMode(
              command,
              keyboardShortcutsMode as WebKeyboardShortcutsMode
          );
}

function getWebKeysForKeyboardShortcutsMode(
    command: HotkeyCommand,
    userShortcuts: WebKeyboardShortcutsMode
): Hotkeys | undefined {
    switch (userShortcuts) {
        case 0:
            return command.hotmail;
        case 2:
            return command.yahoo;
        case 3:
            return command.gmail;
        case 4:
            return command.owa;
        default:
            return undefined;
    }
}

function getMonarchKeysForKeyboardShortcutsMode(
    command: HotkeyCommand,
    userShortcuts: MonarchKeyboardShortcutsMode
): Hotkeys | undefined {
    switch (userShortcuts) {
        case MonarchKeyboardShortcutsMode.OutlookForWindows:
            return command.outlook;
        case MonarchKeyboardShortcutsMode.OutlookForWeb:
            return command.owa;
        default:
            return undefined;
    }
}
