import { CategoryGroupHeaderId } from '../type/GroupHeaderId';
import { noneGroupHeader } from '../utils/noneGroupHeader.locstring.json';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import { getCategoryForCategorySortByRowKey } from 'owa-mail-category-sort';
import loc from 'owa-localize';
import type GroupHeader from '../type/GroupHeader';
import type { GroupHeaderId } from '../type/GroupHeaderId';
import type { LocalizedString } from 'owa-localize';
import type { TableView } from 'owa-mail-list-store';

export default function getGroupHeaderForSortByCategory(
    rowKey: string,
    tableView: TableView
): GroupHeader {
    const category: string = getCategoryForCategorySortByRowKey(getMailboxInfo(tableView), rowKey);
    let categoryText = loc(noneGroupHeader);
    let categoryGroupHeaderId: GroupHeaderId = CategoryGroupHeaderId.Category;

    if (category !== '') {
        categoryText = category as any as LocalizedString;
        categoryGroupHeaderId = category;
    }

    return {
        headerText: () => categoryText,
        headerId: categoryGroupHeaderId,
    };
}
