import { LazyAction, LazyBootModule } from 'owa-bundling-light';

const lazyIndex = new LazyBootModule(
    () => import(/* webpackChunkName: "OwsGateway" */ './lazyIndex'),
    { name: 'OwsGateway' }
);

type lazyType = typeof import('./lazyIndex');

// OW 55102. We have to cast this functions because LazyAction has a bug where it doesn't assign the
// correct types with function that return any or Promise<any>
const makePostRequest = new LazyAction(lazyIndex, m => m.makePostRequest).importAndExecute as (
    ...args: Parameters<lazyType['makePostRequest']>
) => Promise<any>;
const makePatchRequest = new LazyAction(lazyIndex, m => m.makePatchRequest).importAndExecute as (
    ...args: Parameters<lazyType['makePatchRequest']>
) => Promise<any>;
const makePutRequest = new LazyAction(lazyIndex, m => m.makePutRequest).importAndExecute as (
    ...args: Parameters<lazyType['makePutRequest']>
) => Promise<any>;
const makeGetRequest = new LazyAction(lazyIndex, m => m.makeGetRequest).importAndExecute as (
    ...args: Parameters<lazyType['makeGetRequest']>
) => Promise<any>;
const makeDeleteRequest = new LazyAction(lazyIndex, m => m.makeDeleteRequest).importAndExecute as (
    ...args: Parameters<lazyType['makeDeleteRequest']>
) => Promise<any>;
const makeGraphRequest = new LazyAction(lazyIndex, m => m.makeGraphRequest).importAndExecute as (
    ...args: Parameters<lazyType['makeGraphRequest']>
) => Promise<any>;
const sendOwsPrimeRequest = new LazyAction(lazyIndex, m => m.sendOwsPrimeRequest)
    .importAndExecute as <T>(...args: Parameters<lazyType['sendOwsPrimeRequest']>) => Promise<T>;

export {
    makePostRequest,
    makePatchRequest,
    makePutRequest,
    makeGetRequest,
    makeDeleteRequest,
    makeGraphRequest,
    sendOwsPrimeRequest,
};
