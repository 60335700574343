import {
    totalCountTooltipLabel,
    totalCountUnreadCountTooltipLabel,
    totalCountContextMenuLabel,
    totalCountUnreadCountContextMenuLabel,
} from 'owa-locstrings/lib/strings/folderstooltiplabel.locstring.json';
import loc from 'owa-localize';

/**
 * Function returns localized tooltip label for a folder given its display name and counts of total items and unread items
 * @param displayName - the display name for the folder
 * @param totalCount - count of items in the folder
 * @param unreadCount - count of unread items in the folder
 * */
export function buildUnreadTotalCountTooltip(
    displayName: string,
    totalCount: number,
    unreadCount: number
): string {
    if (totalCount > 0) {
        return loc(totalCountUnreadCountTooltipLabel, displayName, totalCount, unreadCount);
    }
    return loc(totalCountTooltipLabel, displayName, totalCount);
}

/**
 * Function returns localized context menu label for a folder given the counts of its total items and unread items
 * @param totalCount - count of items in the folder
 * @param unreadCount - count of unread items in the folder
 */
export function buildUnreadTotalCountContextMenuLabel(
    totalCount: number,
    unreadCount: number
): string {
    if (totalCount > 0) {
        return loc(totalCountUnreadCountContextMenuLabel, totalCount, unreadCount);
    }
    return loc(totalCountContextMenuLabel, totalCount);
}
