// extracted by mini-css-extract-plugin
export var categoryDuoToneIconChecked = "In7jv";
export var categoryDuoToneIconInMenu = "GtUhI";
export var categoryDuoToneName = "oIxnB";
export var categoryIconInMenu = "fgyDJ";
export var categoryIconV9Style = "AnnDw";
export var categoryIconV9StyleNoTextShadow = "h5J5u";
export var categoryMatchSuggestion = "mi0u2";
export var categoryMenuDivider = "tKJSO";
export var categoryMenuItemContainer = "Jdeh2";
export var categoryMenuItemContainerCommon = "JkjHy";
export var categoryMenuItemContainerCommonV9 = "dHnJH";
export var categorySuggestion = "MUdpc";
export var checkMarkDuoToneIcon = "LuNiK";
export var checkMarkSelected = "yNoBl";
export var disabled = "iVJGo";
export var extendedMenuItems = "G7T1J";
export var highlightItem = "ONX1t";
export var nonIconMenuItem = "Y1kYo";