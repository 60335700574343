import type { MailboxInfo } from 'owa-client-types';
import { getSelectedTableView } from 'owa-mail-list-store';
import getMailboxInfo from './getMailboxInfo';

// This method is temporary and should not be used without a plan of getting it removed
// mailboxInfo should always come from the ItemId
// using this can cause a circular dependency
export default function getCurrentTableMailboxInfo(): MailboxInfo {
    return getMailboxInfo(getSelectedTableView());
}
