import type { MailboxInfo } from 'owa-client-types';
import { mutatorAction } from 'satcheljs';
import type { AccountConfiguration } from '../store/schema/OwaSessionStore';
import { getAccountSettingsInternal } from '../utils/getAccountSettingsInternal';

export function updateAccountScopeUserSettings(
    mailboxInfo: MailboxInfo,
    updateFunction: (accountScopeUserSettings: AccountConfiguration) => void,
    skipMutator?: boolean
) {
    (skipMutator ? updateAccountScopeUserSettingsInner : updateAccountScopeUserSettingsMutator)(
        mailboxInfo,
        updateFunction
    );
}

export const updateAccountScopeUserSettingsMutator = mutatorAction(
    'updateAccountScopeUserSettings',
    updateAccountScopeUserSettingsInner
);

export function updateAccountScopeUserSettingsInner(
    mailboxInfo: MailboxInfo,
    updateFunction: (accountScopeUserSettings: AccountConfiguration) => void
) {
    const settings = getAccountSettingsInternal(mailboxInfo);
    if (settings) {
        updateFunction(settings);
    }
}
