// Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
// -> Error TS7034 (4,5): Variable 'isChannelInitialized' implicitly has type 'any' in some locations where its type cannot be determined.
// @ts-expect-error
let isChannelInitialized;

export function setIsNotificationChannelInitialized() {
    isChannelInitialized = true;
}

export function isNotificationChannelInitialized(): boolean {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS7005 (14,12): Variable 'isChannelInitialized' implicitly has an 'any' type.
    // @ts-expect-error
    return isChannelInitialized;
}
