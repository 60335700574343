import getFocusedFilterForTable from '../utils/getFocusedFilterForTable';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import type TableView from '../store/schema/TableView';
import type ViewFilter from 'owa-service/lib/contract/ViewFilter';

/**
 * @param tableView The table view to check
 * @param viewFilter The view filter of the table view to check
 * @returns True if the table has a view filter applied (excluding UserCategory)
 * or a focused view filter applied
 */
export default function isFilteredTableView(
    tableView: TableView,
    viewFilter: ViewFilter | null
): boolean {
    if (!tableView || viewFilter === null) {
        return false;
    }

    return (
        getFocusedFilterForTable(tableView) !== -1 ||
        (viewFilter !== 'All' && viewFilter !== 'UserCategory')
    );
}
