import { createStore } from 'satcheljs';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { ObservableMap } from 'mobx';
import type { MailboxInfo } from 'owa-client-types';

interface CategorySortStore {
    categoryByRowKey: ObservableMap<string, string>;
}

const defaultStore: CategorySortStore = {
    categoryByRowKey: new ObservableMap<string, string>({}),
};

const mailboxInfoToCategorySortStore = new Map<string, CategorySortStore>();

export const getStore = (mailboxInfo: MailboxInfo): CategorySortStore => {
    const name = getIndexerValueForMailboxInfo(mailboxInfo) + '_categorySortStore';
    let cachedStore = mailboxInfoToCategorySortStore.get(name);
    if (!cachedStore) {
        const store = createStore<CategorySortStore>(name, defaultStore)();
        mailboxInfoToCategorySortStore.set(name, store);
        cachedStore = store;
    }
    return cachedStore;
};
