import getDisplayedMessageBody from 'owa-mail-reading-pane-store/lib/utils/getDisplayedMessageBody';
import type Item from 'owa-service/lib/contract/Item';
import { getSelectedTableView } from 'owa-mail-list-store';
import { getTrustedHTML } from 'owa-content-security-policies/lib/utils/getTrustedHTML';

/**
 * Returns true if the body of an email contains one or more highlight terms from tableView
 * @param item Mail item
 * @returns a boolean - true if one or more keywords exist in the body of the item
 *  */
export default function itemHasHighlightTerms(item: Item): boolean {
    const tableView = getSelectedTableView();
    if (tableView?.highlightTerms) {
        const body = getDisplayedMessageBody(item.RightsManagementLicenseData, item.UniqueBody);
        const messsageElement = document.createElement('div');
        /* eslint-disable-next-line @microsoft/sdl/no-inner-html --
         * The HTML is sanitized by dompurify and the returned value is a TrustedHTML object, so is safe to assing it to innerHTML.
         */
        messsageElement.innerHTML = getTrustedHTML(body);
        const hasMatch = tableView.highlightTerms.some(function (term: string) {
            return messsageElement.innerText.toLowerCase().indexOf(term) >= 0;
        });

        return hasMatch;
    }

    return false;
}
