import isGetMobileFeatureEnabled from './isGetMobileFeatureEnabled';
import { hasQueryStringParameter } from 'owa-querystring';
import type { OwaDate } from 'owa-datetime';
import { owaDate, addDays, subDays } from 'owa-datetime';
import isPhoneCardDismissed from './isPhoneCardDismissed';
import isGetQrConnectEnabled from './isGetQrConnectEnabled';
import { logUsage } from 'owa-analytics';
import isPhoneAppInstalled from '../utils/isPhoneAppInstalled';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';

let shouldShowPromise: Promise<boolean>;

export async function shouldShowEmptyStateMobilePromo(isTest?: boolean): Promise<boolean> {
    if (!shouldShowPromise || isTest) {
        shouldShowPromise = shouldShowPromo();
    }

    return shouldShowPromise;
}

async function shouldShowPromo(): Promise<boolean> {
    const daysToSupressPromo: number = 30;
    const promoDismissDate: OwaDate = subDays(owaDate(), daysToSupressPromo + 10);
    const mobilePromoOverrideParam = 'emptyStateMobilePromo';
    const { PolicySettings } = getUserConfiguration();

    if (hasQueryStringParameter(mobilePromoOverrideParam)) {
        return true;
    }

    if (!PolicySettings?.EmptyStateEnabled) {
        return false;
    }

    if ((isGetMobileFeatureEnabled() || isGetQrConnectEnabled()) && !isPhoneCardDismissed()) {
        const { isAppInstalled, mobilePromoDismissDate, endpointApiFailed, userClients } =
            await isPhoneAppInstalled(promoDismissDate);
        const supressPromoUntil = addDays(
            owaDate('UTC', mobilePromoDismissDate.toString()),
            daysToSupressPromo
        );

        const shouldShowMobilePromo = !isAppInstalled && owaDate() >= supressPromoUntil;

        logUsage('ShouldShowMobilePromo', {
            isAppInstalled,
            promoDismissDate: mobilePromoDismissDate.toString(),
            supressPromoUntil: supressPromoUntil.toString(),
            endpointApiFailed,
            userClients: userClients.toString(),
            shouldShowMobilePromo,
        });

        return shouldShowMobilePromo;
    }

    return false;
}
