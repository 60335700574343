import { convertSearchQueryId } from './converterHelpers';
import type ExecuteSearchResponseMessage from 'owa-service/lib/contract/ExecuteSearchResponseMessage';

export default function createEmptySearchResult(): ExecuteSearchResponseMessage {
    return {
        SearchResults: {
            MoreResultsAvailable: false,
            TotalResultsCount: 0,
            SearchResultsCount: 0,
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (13,13): Type '{ Id: string; } | null' is not assignable to type 'ExecuteSearchQueryId | undefined'.
            // @ts-expect-error
            TDQueryId: convertSearchQueryId(null),
        },
    };
}
