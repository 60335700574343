import { getAccountScopeUserSettings } from 'owa-session-store';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Addresses that should not be exposed to the user, like Cloud Cache and EASID,
 * should be filtered out
 * Return true if this email address should be filtered out, otherwise false
 * @param emailAddress the address to check
 */
export default function shouldSkipEmailAddress(
    emailAddress: string,
    mailboxInfo: MailboxInfo
): boolean {
    let shouldSkip = false;
    if (isConsumer(undefined /* smtpAddress */, mailboxInfo)) {
        // NOTE: This can be skipped for enterprise users because the
        // two cases we are checking are consumer-only scenarios
        const userConfig = getAccountScopeUserSettings(mailboxInfo);
        emailAddress = emailAddress.toLowerCase();
        shouldSkip =
            emailAddress.indexOf('outlook_' + userConfig.HexCID) == 0 ||
            emailAddress.indexOf('@shadow.outlook.com') != -1;
    }

    return shouldSkip;
}
