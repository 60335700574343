import './orchestrators/NewMessageV3Orchestrator';
import type { LazyActionOptions } from 'owa-bundling';
import { LazyAction, LazyModule, registerLazyOrchestrator } from 'owa-bundling';

const lazyActionOptions: LazyActionOptions = { captureBundleTime: true };
const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailMessageActions"*/ './lazyIndex'),
    { name: 'MailMessageActions' }
);

export const lazyNewMessage = new LazyAction(lazyModule, m => m.newMessage, lazyActionOptions);
export const lazyReplyToConversation = new LazyAction(lazyModule, m => m.replyToConversation);
export const lazyForwardConversation = new LazyAction(lazyModule, m => m.forwardConversation);
export const lazyPrintConversation = new LazyAction(lazyModule, m => m.printConversation);
export const lazyReplyToItem = new LazyAction(lazyModule, m => m.replyToItem);
export const lazyForwardItem = new LazyAction(lazyModule, m => m.forwardItem);
export const lazyForwardItemAsAttachment = new LazyAction(
    lazyModule,
    m => m.forwardItemAsAttachment
);
export const lazyPrintItem = new LazyAction(lazyModule, m => m.printItem);
export const lazyShowConversationInImmersiveReader = new LazyAction(
    lazyModule,
    m => m.showConversationInImmersiveReader
);
export const lazyReplyWithText = new LazyAction(lazyModule, m => m.replyWithText);
export { default as ReplyWithTextFeatures } from './contract/ReplyWithTextFeatures';
export const lazyResendMessage = new LazyAction(lazyModule, m => m.resendMessage);

import onMailServiceNewMessage from 'owa-app-host-actions/lib/actions/onMailServiceNewMessage';
import onMailServiceForwardMessage from 'owa-app-host-actions/lib/actions/onMailServiceForwardMessage';
import onMailServiceReplyReplyAllToMessage from 'owa-app-host-actions/lib/actions/onMailServiceReplyReplyAllToMessage';

registerLazyOrchestrator(
    onMailServiceNewMessage,
    lazyModule,
    m => m.onMailServiceNewMessageOrchestrator
);
registerLazyOrchestrator(
    onMailServiceForwardMessage,
    lazyModule,
    m => m.onMailServiceForwardMessageOrchestrator
);
registerLazyOrchestrator(
    onMailServiceReplyReplyAllToMessage,
    lazyModule,
    m => m.onMailServiceReplyReplyAllToMessageOrchestrator
);
