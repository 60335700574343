/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Using transaction to mitigate a perf issue; this should be refactored to use idiomatic
 * Satchel/MobX patterns */
import { transaction } from 'mobx';
import {
    addActiveAnimation,
    addRowAnimation,
    mailListItemAnimationStore,
    removePendingAnimation,
} from 'owa-mail-list-item-animation-store';
import type { LoadMoreListViewHandle } from 'owa-loadmore-listview/lib/components/LoadMoreListView';
import type { VirtualizedLoadMoreListViewRef } from 'owa-loadmore-listview/lib/components/VirtualizedLoadMoreListView';
import type { TableView } from 'owa-mail-list-store';
import { canAnimateRows } from './canAnimateRows';
import { getAnimationTiming } from './getAnimationTiming';
import { handleAnimationComplete } from './handleAnimationComplete';
import { getAnimationKeyframes } from './getAnimationKeyframes';
import { getAnimationKeyframesForShiftingRows } from './getAnimationKeyframesForShiftingRows';
import { getAnimationBackgroundColor } from './getAnimationBackgroundColor';
import { storeInitialAndFinalRowPositions } from './storeInitialAndFinalRowPositions';
import { prepareAnimations } from './prepareAnimations';
import { invokeAnimationCallback } from './invokeAnimationCallback';
import { addToTimingMap } from 'owa-performance/lib/utils/timingMap';

export const runListAnimation = (
    listView: React.RefObject<VirtualizedLoadMoreListViewRef | LoadMoreListViewHandle>,
    tableView: TableView
) => {
    // TODO: Remove `transaction` and factor this into a single Satchel mutator
    // https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/236732
    transaction(() => {
        const { pendingAnimations } = mailListItemAnimationStore;

        for (const animation of pendingAnimations) {
            const { animationRowIds, animationAction } = animation;
            if (animationRowIds && animationRowIds.size > 0 && animationAction) {
                const scrollRegion: HTMLDivElement | undefined =
                    listView.current?.getScrollRegion();

                // Each row is wrapped with two extra divs (one absolute positioned div added by react-window, and one div used for useResizeObserver).
                // Here, we query all the divs that are children of the [data-animatable] div, which include the PreRowsComponent, PostRowsComponent, all mail list items, group headers and the LoadingSpinner.
                // We only want to exclude divs with ids that contain the substring "expansionAnimationWrapper". These divs wrap conversation item parts and we handle those independently when handling their corresponding conversation header row.
                const rows: Element[] = Array.from(
                    scrollRegion?.querySelectorAll(
                        '[data-animatable] > div:not([id*="expansionAnimationWrapper"])'
                    ) || []
                );

                if (
                    scrollRegion &&
                    rows.length > 0 &&
                    canAnimateRows(tableView, rows[0], Array.from(animationRowIds), animationAction)
                ) {
                    removePendingAnimation(animation);
                    addActiveAnimation(animation);
                    const prevChildrenPositions = new Map<
                        string /* rowId */,
                        DOMRect /* top offset */
                    >(); // Create maps for the positions of elements
                    const newChildrenPositions = new Map<
                        string /* rowId */,
                        DOMRect /* offset */
                    >();
                    const rowItemParts = new Map<
                        /*ParentId*/ string,
                        Element[] /* dom child element*/
                    >();
                    const removedHeaders = new Set<string /*row*/>();
                    const isRemovalAction =
                        animationAction !== 'AddRowInitial' &&
                        animationAction !== 'AddRowFinal' &&
                        animationAction !== 'UnpinFinal' &&
                        animationAction !== 'PinFinal';

                    // We calculate the initial and final positions of each row in the list view based on the height(s) of the row(s) being animated in/out of the list view.
                    storeInitialAndFinalRowPositions(
                        rows,
                        animationRowIds,
                        prevChildrenPositions,
                        newChildrenPositions,
                        rowItemParts,
                        isRemovalAction
                    );

                    prepareAnimations(
                        rows,
                        animationAction,
                        rowItemParts,
                        animationRowIds,
                        removedHeaders
                    );

                    const isInstantAnimation =
                        animationAction == 'PinMiddle' ||
                        animationAction == 'UnpinMiddle' ||
                        animationAction == 'AddRowInitial';

                    if (isInstantAnimation) {
                        invokeAnimationCallback(animation);
                    }

                    const isFinalPinUnpin =
                        animationAction == 'PinFinal' || animationAction == 'UnpinFinal';
                    if (isFinalPinUnpin) {
                        rows.forEach(row => {
                            if (animationRowIds.has(row.id)) {
                                (
                                    row as HTMLDivElement
                                ).style.cssText = `background: ${getAnimationBackgroundColor(
                                    animationAction
                                )}`;
                            }
                        });
                    }

                    // We set the display property the of row(s) being animated in via Pin and Unpin to 'none' so we want to
                    // set those back to 'block' here to be able to see them animate in during PinFinal and UnpinFinal.
                    if (
                        animationAction === 'AddRowFinal' ||
                        animationAction === 'PinFinal' ||
                        animationAction === 'UnpinFinal'
                    ) {
                        rows.forEach(row => {
                            if (animationRowIds.has(row.id)) {
                                (row as HTMLDivElement).style.cssText =
                                    'display: block; background:' +
                                    getAnimationBackgroundColor(animationAction) +
                                    ';';
                            }
                        });
                    }

                    let animatedItems = 0;

                    const animateRow = (row: Element) => {
                        const isAnimatedRow =
                            animationRowIds.has(row.id) || removedHeaders.has(row.id);
                        const newChildrenPositionsForRow = newChildrenPositions?.get(row.id);
                        if (newChildrenPositionsForRow || (isAnimatedRow && isFinalPinUnpin)) {
                            let player: Animation | undefined;
                            // Check if the element is visible before animating
                            if (!isAnimatedRow) {
                                const firstBox = prevChildrenPositions?.get(row.id)?.top;
                                const lastBox = newChildrenPositions?.get(row.id)?.top;
                                if (firstBox && lastBox) {
                                    const changeY = lastBox - firstBox;
                                    const keyFrames = getAnimationKeyframesForShiftingRows(
                                        changeY,
                                        isRemovalAction
                                    );
                                    player = (row as HTMLDivElement)?.animate(
                                        // TODO add other movements -- for pin and make this a helper function.
                                        keyFrames,
                                        {
                                            duration: isInstantAnimation ? 35 : 250,
                                            easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
                                            fill: 'forwards',
                                        }
                                    );
                                    if (player) {
                                        addRowAnimation(animation, player);
                                    }
                                }
                            } else {
                                if (!isInstantAnimation) {
                                    player = removedHeaders.has(row.id)
                                        ? (row as HTMLDivElement)?.animate(
                                              getAnimationKeyframes(animationAction),
                                              getAnimationTiming(animationAction)
                                          )
                                        : (row.children[0] as HTMLDivElement)?.animate(
                                              getAnimationKeyframes(animationAction),
                                              getAnimationTiming(animationAction)
                                          );
                                    if (player) {
                                        addRowAnimation(animation, player);
                                    }
                                }
                            }
                            if (animatedItems == 0 && player) {
                                player.onfinish = () => {
                                    addToTimingMap('onfinish', 'runListAnimation');
                                    handleAnimationComplete(animation, rows);
                                };
                                animatedItems = animatedItems + 1;
                            }
                        }
                    };
                    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
                     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
                     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead
                     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
                    rows.forEach((row: Element) => {
                        animateRow(row);
                        rowItemParts.get(row.id)?.forEach((itemPart: Element) => {
                            animateRow(itemPart);
                        });
                    });
                    if (animatedItems == 0) {
                        // If animation nothing in view animated, trigger the action.
                        handleAnimationComplete(animation, rows);
                    }
                } else {
                    invokeAnimationCallback(animation);
                    removePendingAnimation(animation);
                }
            }
        }
    });
};
