import React from 'react';
import { registerIcons } from '@fluentui/style-utilities';
import { createIconComponent } from './createIconComponent';

export function createIconModule<T extends string>(
    iconName: T,
    fluentIconComponent: React.FC<
        React.SVGAttributes<SVGElement> & {
            primaryFill?: string | undefined;
            className?: string | undefined;
            filled?: boolean | undefined;
            title?: string | undefined;
        }
    >
): T {
    const IconComponent = createIconComponent({
        IconComponent: fluentIconComponent,
        displayName: iconName,
    });

    registerIcons({
        icons: {
            [iconName]: <IconComponent />,
        },
    });

    return iconName;
}
