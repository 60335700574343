// extracted by mini-css-extract-plugin
export var compact = "APoc1";
export var dropContainer = "dGZxs";
export var dropHoverTreatment = "tLIEt";
export var full = "K_Q7X";
export var linkContent = "Q8sEA";
export var medium = "SD3tB";
export var nonSelectedPivotLink = "HsYCD";
export var pivotContainerParent = "VDlCs";
export var pivotItem = "KCqY7";
export var pivotItemContainer = "G4ZU0";
export var pivotRoot = "biyV2";
export var pivotText = "cW_Y8";
export var selectedPivotLink = "P9WAL";