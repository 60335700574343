import type { AccountSource } from 'owa-account-source-list-store';
import { AccountSourceType } from 'owa-account-source-list-types';

/**
 * Returns true if the account source is a cloud cache account
 * @param account Account to check
 * @returns True if it is a cloud cache account, false otherwise
 */
export default function isCloudCache(account: AccountSource): boolean {
    return isCloudCacheAccountType(account.sourceType);
}

/**
 * Returns true if the account source type is for a cloud cache account
 * @param account Account type to check
 * @returns True if it is a cloud cache account type, false otherwise
 */
export function isCloudCacheAccountType(accountType: AccountSourceType): boolean {
    switch (accountType) {
        case AccountSourceType.Google:
        case AccountSourceType.ICloud:
        case AccountSourceType.Yahoo:
        case AccountSourceType.IMAP:
        case AccountSourceType.POP3:
            return true;

        default:
            return false;
    }
}
