import isFeatureEnabled from './isFeatureEnabled';

export function isAppBarDisplayModeOptionsEnabled(): boolean {
    //If any of the treatment flights are enabled then the feature is enabled
    return (
        isFeatureEnabled('mos-appBarDisplayMode-ColorfulIconsWithoutLabels') ||
        isFeatureEnabled('mos-appBarDisplayMode-ColorfulIconsWithLabels') ||
        isFeatureEnabled('mos-appBarDisplayMode-OutlineIconsWithoutLabels') ||
        isFeatureEnabled('mos-appBarDisplayMode-OutlineIconsWithLabels')
    );
}

export function getColorfulIconsEnabledOriginalValue(): boolean {
    return (
        isFeatureEnabled('mos-appBarDisplayMode-ColorfulIconsWithoutLabels') ||
        isFeatureEnabled('mos-appBarDisplayMode-ColorfulIconsWithLabels')
    );
}

export function getLabelsEnabledOriginalValue(): boolean {
    return (
        isFeatureEnabled('mos-appBarDisplayMode-ColorfulIconsWithLabels') ||
        isFeatureEnabled('mos-appBarDisplayMode-OutlineIconsWithLabels')
    );
}
