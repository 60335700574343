import type { HotkeyCommand } from 'owa-hotkeys';

interface F6Hotkeys {
    ctrlTab: Readonly<HotkeyCommand>;
    ctrlShiftTab: Readonly<HotkeyCommand>;
    f6: Readonly<HotkeyCommand>;
    shiftF6: Readonly<HotkeyCommand>;
}

export const F6_HOTKEY_COMMANDS: Readonly<F6Hotkeys> = {
    ctrlTab: {
        outlook: 'ctrl+tab',
    },
    ctrlShiftTab: {
        outlook: 'ctrl+shift+tab',
    },
    f6: {
        hotmail: 'ctrl+f6',
        gmail: 'ctrl+f6',
        owa: 'ctrl+f6',
        yahoo: 'ctrl+f6',
        outlook: 'f6',
    },
    shiftF6: {
        hotmail: 'ctrl+shift+f6',
        gmail: 'ctrl+shift+f6',
        owa: 'ctrl+shift+f6',
        yahoo: 'ctrl+shift+f6',
        outlook: 'shift+f6',
    },
};
