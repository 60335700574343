import { mutator } from 'satcheljs';
import store from '../store/store';
import changeFabricDensityAction from '../actions/changeDensity';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
export default mutator(changeFabricDensityAction, actionMessage => {
    const { densityMode, density } = actionMessage;
    store().density = {
        ...density,
        components: {
            ...(density.components || {}),
            CommandBar: { styles: { root: { backgroundColor: store().palette?.neutralLighter } } },
        },
    };
    store().densityMode = densityMode;
});
