import { LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "CalendarPreBoot" */ './lazyIndex'),
    { name: 'CalendarPreBoot' }
);

export const lazyGetCalendarPreBootstrap = new LazyAction(
    lazyModule,
    m => m.getCalendarPreBootstrap
);
