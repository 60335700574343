import type SuggestedUnsubscribeItem from '../store/schema/SuggestedUnsubscribeItem';
import { getSuggestedUnsubscribeStore } from '../store/Store';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Get suggested unsubscribe item from the store
 *
 * @param rowId The rowId of the item where the suggestion will be shown
 * @param mailboxInfo MailboxInfo of the selected account
 *
 * @returns The suggested unsubscribe item
 */
export default function getSuggestedUnsubscribeItem(
    rowId: string,
    mailboxInfo: MailboxInfo
): SuggestedUnsubscribeItem | undefined {
    const suggestedUnsubscribeStore = getSuggestedUnsubscribeStore(mailboxInfo);
    return suggestedUnsubscribeStore.suggestedUnsubscribeItems.filter(
        (n: SuggestedUnsubscribeItem) => n.itemId === rowId || n.conversationId === rowId
    )[0];
}
