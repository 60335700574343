import type {
    QueryResult,
    QueryHookOptions,
    TypedDocumentNode,
    OperationVariables,
} from '@apollo/client';
import { useQuery } from '@apollo/client';
import { createManagedQueryOptions } from './util/createManagedOptions';
import type { StrictVariables } from 'owa-apollo';

export function useManagedQuery<
    TData,
    TSchemaVars extends OperationVariables,
    TActualVars extends StrictVariables<TSchemaVars, TActualVars>
>(
    query: TypedDocumentNode<TData, TSchemaVars>,
    options?: QueryHookOptions<TData, TActualVars>
): QueryResult<TData, TActualVars> {
    const managedOptions = createManagedQueryOptions(
        options as unknown as QueryHookOptions<TData, TSchemaVars>
    );

    const schemaResult: QueryResult<TData, any> = useQuery(query, managedOptions);
    return schemaResult as QueryResult<TData, TActualVars>;
}
