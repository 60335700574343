import getPrimaryFromAddress from './getPrimaryFromAddress';
import { isValidSmtpAddress } from 'owa-recipient-email-address/lib/utils/isValidRecipientAddress';
import { getAccountScopeUserSettings } from 'owa-session-store';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import type { MailboxInfo } from 'owa-client-types';

/*
 * Get the default email address to send from
 * Since consumers can set the default address they send from, this may be different
 * than the address they used when initially creating the account
 */
export default function getDefaultFromAddress(mailboxInfo: MailboxInfo): string {
    const userConfig = getAccountScopeUserSettings(mailboxInfo);
    const address = isConsumer(undefined /* smtpAddress */, mailboxInfo)
        ? userConfig.SessionSettings?.DefaultFromAddress ?? ''
        : userConfig.UserOptions?.SendAddressDefault ?? '';

    // Verify that the address is a valid SMTP address
    // If not, e.g. it is the GUID, fall back to the primary address (VSO 45276)
    return isValidSmtpAddress(address) ? address : getPrimaryFromAddress(mailboxInfo);
}
