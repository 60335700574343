import getTheme from '../selectors/getTheme';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { getApp } from 'owa-config';
import { getIsDarkTheme } from 'owa-fabric-theme';
import { isFeatureEnabled } from 'owa-feature-flags';

export function getThemeIdFromParameter(themeId?: string): string {
    return themeId ?? getTheme();
}

export function getIsDarkThemeFromParameter(isDarkTheme?: boolean): boolean {
    return isDarkTheme ?? getIsDarkTheme();
}

export const isBookingsV2 = (): boolean => {
    return (
        (getApp()?.toLowerCase() === 'bookings' || getApp()?.toLowerCase() === 'bookingsmobile') &&
        isFeatureEnabled('fwk-bookingsv2App') &&
        !!getUserConfiguration()?.ViewStateConfiguration?.Bookingsv2IsOptedIn
    );
};
