import type RequestOptions from 'owa-service/lib/RequestOptions';

export function getRequestOptionsOrDefault(requestOptions?: RequestOptions): RequestOptions {
    // Do NOT take dependencies on satchel stores populated on the main thread.  This method is
    // currently callable from the data worker, which does not have access to main's satchel stores.
    const defaultRequestOptions: RequestOptions = requestOptions ?? {};
    defaultRequestOptions.headers = requestOptions?.headers || new Headers();
    defaultRequestOptions.datapoint = requestOptions?.datapoint || {};
    defaultRequestOptions.datapoint.mailbox = 'Default';
    return defaultRequestOptions;
}
