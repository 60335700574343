import { createStore } from 'satcheljs';

interface RootItemStore {
    rootItemIdMap: Map<string, string>;
}

const rootItemStore: RootItemStore = {
    rootItemIdMap: new Map<string, string>(),
};

const store = createStore<RootItemStore>('rootItem', rootItemStore)();
export default store;
