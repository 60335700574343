import { update } from './mruTableIdList';
import { tryUpdateAlwaysInCacheTableViews } from './alwaysInCacheTableViews';
import type { TableView } from 'owa-mail-list-store';
import { type TableQueryType } from 'owa-mail-list-store';

export function updateTablesInCache(
    tableView: TableView,
    shouldAddToFront: boolean = true
): number | undefined {
    if (tableView.tableQuery.type == 1) {
        throw new Error('updateTablesInCache should never be called for Search table');
    }

    // First, see if the table view to be cached belongs in the "always in cache" list.
    // If tryUpdateAlwaysInCacheTableViews returns true, then the table view was added
    // to the "always in cache" list and doesn't need to be in the MRU cache. However,
    // if it returns false, then the table view wasn't added to the "always in cache"
    // list and should be added to the MRU cache.
    if (tryUpdateAlwaysInCacheTableViews(tableView)) {
        return 0;
    } else {
        return update(tableView, shouldAddToFront);
    }
}
