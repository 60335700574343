import { M365AppId } from '../M365AppId';

import type { StrictTitle, AcquisitionCategoryEncoding } from '../../types';
import { M365AppIcon } from '../M365AppIcon';
import { EPOCH_TIME } from '../constants/constants';
import { EMPTY_COPILOT_CAPABILITIES } from '../constants/emptyCapability';

const YouTube: StrictTitle = {
    __typename: 'Title',
    ingestionId: M365AppId.YouTube,
    name: 'YouTube',
    acquisitionDate: EPOCH_TIME,
    categories: ['m365_message_extension' as AcquisitionCategoryEncoding],
    longDescription:
        'Share a YouTube video with other members of the channel. Simply search for the video you want or paste its URL.',
    shortDescription: 'Search for videos on YouTube',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/YouTube_largemage.png?v=0.3',
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/YouTube_largeimage.png?v=0.3',
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/com.microsoft.teamspace.tab.youtube_smallImage.png?v=1.0.9',
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        exchangeAddIns: [],
        staticTabs: [],
        extensions: [],
        composeExtensions: [
            {
                __typename: 'MessageExtension',
                botId: '05614650-0d94-4601-bd2f-c200a0a7c57c',
                canUpdateConfiguration: false,
                commandTypes: ['query'],
                scopes: ['personal', 'team'],
                commands: [
                    {
                        __typename: 'InputExtensionCommand',
                        context: ['Compose', 'CommandBox'],
                        description: 'Browse YouTube for videos',
                        fetchTask: false,
                        id: 'search.Youtube',
                        initialRun: false,
                        title: 'Browse YouTube',
                        type: 'query',
                        taskInfo: {
                            __typename: 'InputExtensionCommandTaskInfo',
                            title: '',
                            url: '',
                            width: '',
                            height: '',
                        },
                        parameters: [
                            {
                                __typename: 'InputExtensionCommandParameter',
                                name: 'keyword',
                                title: 'Search term',
                                description: 'Enter a search term',
                                inputType: 'Text',
                                value: '',
                                choices: [],
                            },
                        ],
                    },
                ],
                messageHandlers: [
                    {
                        __typename: 'InputExtensionMessageHandler',
                        value: {
                            __typename: 'InputExtensionMessageHandlerValue',
                            domains: ['*.youtube.com', 'youtube.com', '*.youtu.be', 'youtu.be'],
                        },
                    },
                ],
            },
        ],
    },
    accentColor: '#cd2125',
    isFullTrust: false,
    isFullScreen: false,
    developerName: 'Microsoft Teams Ecosystem',
    developerUrl: 'https://www.youtube.com',
    termsOfUseUrl: 'https://www.youtube.com',
    permissions: [],
    validDomains: ['tabs.teams.microsoft.com', '*.youtube.com', 'youtu.be'],
    showLoadingIndicator: true,
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: '',
        resource: '',
    },
    version: '0.0.0', // NA
    cultureName: 'en-US',
    manifestVersion: '1.13',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
};

const WikipediaSearch: StrictTitle = {
    __typename: 'Title',
    ingestionId: M365AppId.WikipediaSearch,
    name: 'Wikipedia Search',
    acquisitionDate: EPOCH_TIME,
    categories: ['m365_message_extension' as AcquisitionCategoryEncoding],
    longDescription:
        'Leverage the power of the services your organization uses directly within Microsoft Teams. Do a quick search for a Wikipedia article and share it in a conversation.',
    shortDescription: 'Share articles from Wikipedia',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/ThirdPartyApps/25492237-70ef-4c56-a36e-dd3405edd40e_largeImage.png?v=1.2',
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/ThirdPartyApps/25492237-70ef-4c56-a36e-dd3405edd40e_largeImage.png?v=1.2',
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/ThirdPartyApps/25492237-70ef-4c56-a36e-dd3405edd40e_smallImage.png?v=1.2',
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        exchangeAddIns: [],
        staticTabs: [],
        extensions: [],
        composeExtensions: [
            {
                __typename: 'MessageExtension',
                botId: '25492237-70ef-4c56-a36e-dd3405edd40e',
                canUpdateConfiguration: false,
                scopes: ['team', 'personal'],
                commandTypes: ['query'],
                commands: [
                    {
                        __typename: 'InputExtensionCommand',
                        context: ['Compose', 'CommandBox'],
                        description: 'Browse Wikipedia for articles',
                        fetchTask: false,
                        id: 'search.Wikipedia',
                        initialRun: false,
                        title: 'Browse Wikipedia',
                        type: 'query',
                        taskInfo: {
                            __typename: 'InputExtensionCommandTaskInfo',
                            title: '',
                            url: '',
                            width: '',
                            height: '',
                        },
                        parameters: [
                            {
                                __typename: 'InputExtensionCommandParameter',
                                name: 'searchKeyword',
                                title: 'Search term',
                                description: 'Enter a search term',
                                inputType: 'Text',
                                value: '',
                                choices: [],
                            },
                        ],
                    },
                ],
                messageHandlers: [
                    {
                        __typename: 'InputExtensionMessageHandler',
                        value: {
                            __typename: 'InputExtensionMessageHandlerValue',
                            domains: ['*.wikipedia.org'],
                        },
                    },
                ],
            },
        ],
    },
    accentColor: '#cd2125',
    isFullTrust: false,
    isFullScreen: false,
    developerName: 'Microsoft Teams Ecosystem',
    developerUrl: 'https://www.microsoft.com/en-us/',
    termsOfUseUrl: 'https://www.microsoft.com/en-us/servicesagreement',
    permissions: [],
    validDomains: ['*.wikipedia.org'],
    showLoadingIndicator: true,
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: '',
        resource: '',
    },
    version: '0.0.0', // NA
    cultureName: 'en-US',
    manifestVersion: '1.13',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
};

const Weather: StrictTitle = {
    __typename: 'Title',
    ingestionId: M365AppId.Weather,
    name: 'Weather',
    acquisitionDate: EPOCH_TIME,
    categories: ['m365_message_extension' as AcquisitionCategoryEncoding],
    longDescription:
        'Find current weather reports for any city, zip code, or location, then share them in a channel or chat.',
    shortDescription: 'Get the latest weather report',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/Weather_largeimage.png?v=0.3',
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/Weather_largeimage.png?v=0.3',
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/Weather_smallimage.png?v=0.3',
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        exchangeAddIns: [],
        staticTabs: [],
        extensions: [],
        composeExtensions: [
            {
                __typename: 'MessageExtension',
                botId: '4fbe105f-b772-4b09-9279-6e8589087289',
                canUpdateConfiguration: false,
                commandTypes: ['query'],
                scopes: ['personal', 'team'],
                commands: [
                    {
                        __typename: 'InputExtensionCommand',
                        context: ['Compose', 'CommandBox'],
                        description: 'Enter a city, zip code, or location',
                        fetchTask: false,
                        id: 'search.Weather',
                        initialRun: false,
                        title: 'Search',
                        type: 'query',
                        taskInfo: {
                            __typename: 'InputExtensionCommandTaskInfo',
                            title: '',
                            url: '',
                            width: '',
                            height: '',
                        },
                        parameters: [
                            {
                                __typename: 'InputExtensionCommandParameter',
                                name: 'searchKeyword',
                                title: 'Location',
                                description: 'Search for a location',
                                inputType: 'Text',
                                value: '',
                                choices: [],
                            },
                        ],
                    },
                ],
                messageHandlers: [],
            },
        ],
    },
    accentColor: '#0378d7',
    isFullTrust: false,
    isFullScreen: false,
    developerName: 'Microsoft Teams Ecosystem',
    developerUrl: 'https://www.microsoft.com/en-us/',
    termsOfUseUrl: 'https://www.microsoft.com/en-us/servicesagreement',
    permissions: ['Identity', 'MessageTeamMembers'],
    validDomains: [],
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    showLoadingIndicator: false,
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: '',
        resource: '',
    },
    version: '0.0.0', // NA
    cultureName: 'en-US',
    manifestVersion: '1.13',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
};

const Stocks: StrictTitle = {
    __typename: 'Title',
    ingestionId: M365AppId.Stocks,
    name: 'Stocks',
    acquisitionDate: EPOCH_TIME,
    categories: ['m365_message_extension' as AcquisitionCategoryEncoding],
    longDescription:
        'Get real-time stock quotes and share them in a conversation. Search by company name or stock symbol.',
    shortDescription: 'Get real-time stock quotes',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/stocks_largeimage.png?v=0.1',
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/stocks_largeimage.png?v=0.1',
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/stocks_smallimage.png?v=0.1',
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        exchangeAddIns: [],
        staticTabs: [],
        extensions: [],
        composeExtensions: [
            {
                __typename: 'MessageExtension',
                botId: '852a6067-4fec-4895-a3ab-a776c77be161',
                canUpdateConfiguration: false,
                commandTypes: ['query'],
                scopes: ['personal', 'team'],
                commands: [
                    {
                        __typename: 'InputExtensionCommand',
                        context: ['Compose', 'CommandBox'],
                        fetchTask: false,
                        description: 'Search for stock quotes',
                        id: 'search.Finance',
                        initialRun: false,
                        title: 'Search stocks',
                        type: 'query',
                        taskInfo: {
                            __typename: 'InputExtensionCommandTaskInfo',
                            title: '',
                            url: '',
                            width: '',
                            height: '',
                        },
                        parameters: [
                            {
                                __typename: 'InputExtensionCommandParameter',
                                name: 'searchKeyword',
                                title: 'searchKeyword',
                                description: 'Enter a stock symbol',
                                inputType: 'Text',
                                value: '',
                                choices: [],
                            },
                        ],
                    },
                ],
                messageHandlers: [],
            },
        ],
    },
    accentColor: '#0378d7',
    isFullTrust: false,
    isFullScreen: false,
    developerName: 'Microsoft Teams Ecosystem',
    developerUrl: 'https://www.microsoft.com/en-us/',
    termsOfUseUrl: 'https://www.microsoft.com/en-us/servicesagreement',
    permissions: ['Identity', 'MessageTeamMembers'],
    validDomains: [],
    showLoadingIndicator: true,
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: '',
        resource: '',
    },
    version: '0.0.0', // NA
    cultureName: 'en-US',
    manifestVersion: '1.13',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
};

const Places: StrictTitle = {
    __typename: 'Title',
    ingestionId: M365AppId.Places,
    name: 'Places',
    acquisitionDate: EPOCH_TIME,
    categories: ['m365_message_extension' as AcquisitionCategoryEncoding],
    longDescription:
        'Places lets you look up detailed info about different businesses, restaurants, venues, and more. Find out the address, hours of operation, or reviews for a business, then share them in a conversation.',
    shortDescription: 'Get info about different places',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/places/Places_96x96.png?v=0.1',
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/places/Places_96x96.png?v=0.1',
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/b4f7d39f-ddc7-4f7e-b320-4344444c5e0e_smallImage.png?v=1.1.0',
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        exchangeAddIns: [],
        staticTabs: [],
        extensions: [],
        composeExtensions: [
            {
                __typename: 'MessageExtension',
                botId: 'b4f7d39f-ddc7-4f7e-b320-4344444c5e0e',
                canUpdateConfiguration: true,
                commandTypes: ['query'],
                scopes: ['personal', 'team'],
                commands: [
                    {
                        __typename: 'InputExtensionCommand',
                        context: ['Compose', 'CommandBox'],
                        description: 'Search for a location to get detailed info',
                        fetchTask: false,
                        id: 'search.Places',
                        initialRun: true,
                        title: 'Search places',
                        type: 'query',
                        taskInfo: {
                            __typename: 'InputExtensionCommandTaskInfo',
                            title: '',
                            url: '',
                            width: '',
                            height: '',
                        },
                        parameters: [
                            {
                                __typename: 'InputExtensionCommandParameter',
                                name: 'searchKeyword',
                                title: 'Search',
                                description: 'Enter a location',
                                inputType: 'Text',
                                value: '',
                                choices: [],
                            },
                        ],
                    },
                ],
                messageHandlers: [],
            },
        ],
    },

    accentColor: '#008272',
    isFullTrust: false,
    isFullScreen: false,
    developerName: 'Microsoft Corporation',
    developerUrl: 'https://www.bing.com/maps',
    termsOfUseUrl: 'https://www.microsoft.com/en-us/servicesagreement',
    permissions: ['Identity', 'MessageTeamMembers'],
    validDomains: ['bots.teams.microsoft.com', 'bots.teams.skype.com'],
    showLoadingIndicator: true,
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: '',
        resource: '',
    },
    version: '0.0.0', // NA
    cultureName: 'en-US',
    manifestVersion: '1.13',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
};

const Images: StrictTitle = {
    __typename: 'Title',
    ingestionId: M365AppId.Images,
    name: 'Images',
    acquisitionDate: EPOCH_TIME,
    categories: ['m365_message_extension' as AcquisitionCategoryEncoding],
    longDescription:
        'Search Bing for the image you need and share it directly in a channel or chat.',
    shortDescription: 'Find and share images from Bing',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/BingImages_largeimage.png?v=0.3',
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/BingImages_largeimage.png?v=0.3',
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/BingImages_smallimage.png?v=0.3',
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        exchangeAddIns: [],
        staticTabs: [],
        extensions: [],
        composeExtensions: [
            {
                __typename: 'MessageExtension',
                botId: 'd5c805b6-2882-45d3-8657-c4f27520f184',
                canUpdateConfiguration: false,
                commandTypes: ['query'],
                scopes: ['personal', 'team'],
                commands: [
                    {
                        __typename: 'InputExtensionCommand',
                        context: ['Compose', 'CommandBox'],
                        fetchTask: false,
                        description: 'Enter a keyword to search for an image',
                        id: 'search.Images',
                        initialRun: false,
                        title: 'Search images',
                        type: 'query',
                        taskInfo: {
                            __typename: 'InputExtensionCommandTaskInfo',
                            title: '',
                            url: '',
                            width: '',
                            height: '',
                        },
                        parameters: [
                            {
                                __typename: 'InputExtensionCommandParameter',
                                name: 'searchKeyword',
                                title: 'Keyword',
                                description: 'Enter a keyword',
                                inputType: 'Text',
                                value: '',
                                choices: [],
                            },
                        ],
                    },
                ],
                messageHandlers: [],
            },
        ],
    },

    accentColor: '#80397B',
    isFullTrust: false,
    isFullScreen: false,
    developerName: 'Microsoft Corporation',
    developerUrl: 'https://www.bing.com/maps',
    termsOfUseUrl: 'https://www.microsoft.com/en-us/servicesagreement',
    permissions: ['Identity', 'MessageTeamMembers'],
    validDomains: [],
    showLoadingIndicator: true,
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: '',
        resource: '',
    },
    version: '0.0.0', // NA
    cultureName: 'en-US',
    manifestVersion: '1.13',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
};

const News: StrictTitle = {
    __typename: 'Title',
    ingestionId: M365AppId.News,
    name: 'News',
    acquisitionDate: EPOCH_TIME,
    categories: ['m365_message_extension' as AcquisitionCategoryEncoding],
    longDescription:
        'Stay up-to-date on current events courtesy of Bing News. Find coverage of local, national, and worldwide news, then share it in a channel.',
    shortDescription: 'Stay on top of the latest news',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/News_largeimage.png?v=0.5',
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/News_largeimage.png?v=0.5',
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: 'https://statics.teams.cdn.office.net/evergreen-assets/apps/News_smallimage.png?v=0.5',
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        extensions: [],
        exchangeAddIns: [],
        staticTabs: [],
        composeExtensions: [
            {
                __typename: 'MessageExtension',
                botId: 'd5c805b6-2882-45d3-8657-c4f27520f184',
                canUpdateConfiguration: false,
                commandTypes: ['query'],
                scopes: ['personal', 'team'],
                commands: [
                    {
                        __typename: 'InputExtensionCommand',
                        context: ['Compose', 'CommandBox'],
                        description: 'Enter a name, city, event, or keyword',
                        fetchTask: false,
                        id: 'search.News',
                        initialRun: true,
                        type: 'query',
                        title: 'Search term',
                        taskInfo: {
                            __typename: 'InputExtensionCommandTaskInfo',
                            title: '',
                            url: '',
                            width: '',
                            height: '',
                        },
                        parameters: [
                            {
                                __typename: 'InputExtensionCommandParameter',
                                name: 'searchKeyword',
                                title: 'Search term',
                                description: 'Enter a search term',
                                inputType: 'Text',
                                value: '',
                                choices: [],
                            },
                        ],
                    },
                ],
                messageHandlers: [],
            },
        ],
    },

    accentColor: '#D03439',
    isFullTrust: false,
    isFullScreen: false,
    developerName: 'Microsoft Corporation',
    developerUrl: 'https://www.bing.com/maps',
    termsOfUseUrl: 'https://www.microsoft.com/en-us/servicesagreement',
    permissions: ['Identity', 'MessageTeamMembers'],
    validDomains: [],
    showLoadingIndicator: true,
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: '',
        resource: '',
    },
    version: '0.0.0', // NA
    cultureName: 'en-US',
    manifestVersion: '1.13',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
};

const ADO: StrictTitle = {
    __typename: 'Title',
    ingestionId: M365AppId.ADO,
    name: 'ADO',
    acquisitionDate: EPOCH_TIME,
    categories: ['m365_message_extension' as AcquisitionCategoryEncoding],
    shortDescription: 'Monitor and collaborate on existing work items.',
    longDescription:
        'Azure DevOps DogFood enables sharing work items with your colleagues and get previews of work items using URLs.',
    iconSmall: {
        __typename: 'M365AcquisitionIcon',
        uri: M365AppIcon[M365AppId.ADO],
    },
    iconLarge: {
        __typename: 'M365AcquisitionIcon',
        uri: M365AppIcon[M365AppId.ADO],
    },
    iconOutline: {
        __typename: 'M365AcquisitionIcon',
        uri: '',
    },
    elementDefinitions: {
        __typename: 'ElementDefinition',
        exchangeAddIns: [],
        staticTabs: [],
        extensions: [],
        composeExtensions: [
            {
                __typename: 'MessageExtension',
                botId: '83b401f7-edaa-4c2d-8400-0a40b3439f9b',
                canUpdateConfiguration: false,
                commandTypes: [],
                scopes: ['personal', 'team', 'groupchat'],
                commands: [],
                messageHandlers: [
                    {
                        __typename: 'InputExtensionMessageHandler',
                        value: {
                            __typename: 'InputExtensionMessageHandlerValue',
                            domains: [
                                'dev.azure.com/outlookecosystemeng/*/_workitems/edit/*',
                                'outlookecosystemeng.visualstudio.com/*/_workitems/edit/*',
                                'dev.azure.com/office/*/_workitems/edit/*',
                                'office.visualstudio.com/*/_workitems/edit/*',
                                'dev.azure.com/outlookweb/*/_workitems/edit/*',
                                'outlookweb.visualstudio.com/*/_workitems/edit/*',
                            ],
                        },
                    },
                ],
            },
        ],
    },
    accentColor: '#1c315f',
    isFullTrust: false,
    isFullScreen: false,
    developerName: 'Microsoft Corporation',
    developerUrl: 'https://www.microsoft.com/en-us/',
    termsOfUseUrl: 'https://www.microsoft.com/en-us/servicesagreement',
    permissions: [],
    validDomains: ['token.botframework.com', 'meec5534.azurewebsites.net'],
    showLoadingIndicator: true,
    blockStatus: false,
    ingestionSource: 'Hardcoded',
    scope: 'Tenant',
    webApplicationInfo: {
        __typename: 'WebApplicationInfo',
        id: '',
        resource: '',
    },
    version: '0.0.0', // NA
    cultureName: 'en-US',
    manifestVersion: '1.13',
    authorization: {
        __typename: 'AppAuthorization',
        permissions: {
            __typename: 'AppPermissions',
            orgWide: [],
            resourceSpecific: [],
        },
    },
    copilotEnabled: false,
    copilotSettings: {
        __typename: 'CopilotSettings',
        displayName: '',
        elementType: 'Extension',
        capabilities: EMPTY_COPILOT_CAPABILITIES,
    },
};

const ComposeMessageExtensionTitles: StrictTitle[] = [
    YouTube,
    WikipediaSearch,
    Weather,
    Stocks,
    Places,
    Images,
    News,
];

const MessageExtensionTitles: StrictTitle[] = [...ComposeMessageExtensionTitles, ADO];

export {
    ComposeMessageExtensionTitles,
    MessageExtensionTitles,
    YouTube,
    WikipediaSearch,
    Weather,
    Stocks,
    Places,
    Images,
    News,
    ADO,
};
