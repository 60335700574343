import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "DatetimeIntlFormatters"*/ './lazyIndex')
);

export const lazyFormatIntlDate = new LazyAction(lazyModule, m => m.formatIntlDate);

export const lazyGetIntlMonth = new LazyAction(lazyModule, m => m.getIntlMonth);

export const lazyGetIntlYear = new LazyAction(lazyModule, m => m.getIntlYear);
