import { lazyGetDeleteSections } from 'owa-mail-ribbon-sections/lib/sections';

import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onDeleteMenuClicked = (props: ReadOnlyRibbonControlDefProps) => {
    lazyGetDeleteSections.import().then(getDeleteSections => {
        setMenuDefinition('DeleteMenuDefinitionStore', undefined /* projectionTabId */, () => {
            return {
                sections: getDeleteSections(props),
            };
        });
    });
};
