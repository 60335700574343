import { ObservableMap } from 'mobx';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - satchel stores in the worker will need their data synced
 *	> 'createStore' import from 'satcheljs' is restricted. Satchel stores should not be created in the worker as the data will need to be synced */
import { createStore } from 'satcheljs';
import type { MailFolder } from 'owa-graph-schema';

export interface PublicFolderStore {
    folderTable: ObservableMap<string, MailFolder>; // where we store the loaded folders
    rootFolder: MailFolder | null; // root folder corresponding to IPM_SUBTREE
}

const initialState: PublicFolderStore = {
    folderTable: new ObservableMap<string, MailFolder>(),
    rootFolder: null,
};

const publicFolderFavoriteStore = createStore<PublicFolderStore>(
    'publicFolderFavoriteStore',
    initialState
)();

export default publicFolderFavoriteStore;
