import type { MessageValidationFunction } from '../types/MessageValidationFunction';
import type { RecipientValidationFunction } from '../types/RecipientValidationFunction';
import {
    isNotGroupDL,
    hasEmailAddress,
    isNotExternalUser,
    isNotSenderSelf,
    isNotNoReplyAccount,
} from './recipientFilterFunctions';
import { canForward } from './MessageCheckFunctions';

export function getShareToTeamsRecipientChecks(): RecipientValidationFunction[] {
    return [hasEmailAddress, isNotGroupDL, isNotExternalUser, isNotSenderSelf, isNotNoReplyAccount];
}

export function getChatAroundEmailRecipientChecks(): RecipientValidationFunction[] {
    return [hasEmailAddress, isNotGroupDL, isNotExternalUser, isNotSenderSelf, isNotNoReplyAccount];
}

export function getShareToTeamsMessageChecks(): MessageValidationFunction[] {
    return [canForward];
}
