import { mutatorAction } from 'satcheljs';
import getFolderViewStateFromId from '../selectors/getFolderViewStateFromId';
import { setFolderViewStateInner } from '../actions/setFolderViewState';

/*
 * Sets folder node expansion state
 * @param folderId the folder whose expansion state we are modifying
 * @param isExpanded the value to set for the expansion state
 */
export default mutatorAction(
    'setFolderViewExpansionState',
    function setFolderViewExpansionState(folderId: string, isExpanded: boolean): void {
        const viewState = getFolderViewStateFromId(folderId);
        viewState.isExpanded = isExpanded;
        setFolderViewStateInner(folderId, viewState);
    }
);
