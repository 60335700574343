import type { MailboxInfo } from 'owa-client-types';
import type { TabNameType } from 'owa-mail-ribbon-store-shared-types';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { getMailRibbonConfigStore } from '../store/store';

export const getTabControls = (
    mailboxInfo: MailboxInfo,
    tabName: TabNameType
): RibbonControlId[] => {
    const ribbonConfig = getMailRibbonConfigStore(mailboxInfo).singleline;
    const tabConfigGroups = ribbonConfig[tabName].layout;
    const allControls = tabConfigGroups.flatMap(group => {
        // Major gotcha: flatMap doesn't recognize ObservableArray as an array and won't flatten. We need to spread
        // this to a "real" array to get flatMap to work properly.
        return [...group.controlIds];
    });
    const uniqueControls = new Set(allControls);
    return Array.from(uniqueControls);
};
