import type { ComposeViewState } from 'owa-mail-compose-store';
import { getAccountAuthStateForMailboxInfo, StateTypeEnum } from 'owa-account-authstate-store';

export function isComposeMailboxStateValid(viewState: ComposeViewState | undefined): boolean {
    return (
        !viewState ||
        !!(
            getAccountAuthStateForMailboxInfo(viewState.mailboxInfo).stateType !==
            StateTypeEnum.AuthError
        )
    );
}
