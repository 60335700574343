import getSortByFolderId from 'owa-mail-folder-store/lib/selectors/getSortByFolderId';
import createMailFolderTableQuery from './createMailFolderTableQuery';
import type { MailFolderTableQuery } from 'owa-mail-list-store';
import {
    getListViewTypeForFolder,
    getShouldOverrideToSortBySizeForMailFolder,
} from 'owa-mail-folder-store';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import { isFocusedInboxEnabled } from 'owa-mail-triage-common';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Gets the tableQuery for regular (non-focused) inbox view
 */
export function getNonFocusedInboxTableQuery(mailboxInfo: MailboxInfo): MailFolderTableQuery {
    const inboxFolderId = folderNameToId('inbox', mailboxInfo);
    const inboxListViewType = getListViewTypeForFolder(inboxFolderId);
    const sortByFolderId = getSortByFolderId(inboxFolderId);
    const shouldOverrideToSortBySize: boolean = getShouldOverrideToSortBySizeForMailFolder();
    const tableQuery = createMailFolderTableQuery(
        inboxFolderId,
        inboxListViewType,
        shouldOverrideToSortBySize ? 'mailFolderSortBySize' : 'mail',
        -1,
        undefined /* viewFilter */,
        undefined /* categoryName */,
        sortByFolderId
    );

    return tableQuery as MailFolderTableQuery;
}

/**
 * Gets the tableQuery for focused inbox view
 */
export function getFocusedInboxTableQuery(mailboxInfo: MailboxInfo): MailFolderTableQuery {
    const tableQuery = getNonFocusedInboxTableQuery(mailboxInfo);
    tableQuery.focusedViewFilter = 0;
    return tableQuery;
}

/**
 * Gets the tableQuery for other inbox view
 */
export function getOtherInboxTableQuery(mailboxInfo: MailboxInfo): MailFolderTableQuery {
    const tableQuery = getNonFocusedInboxTableQuery(mailboxInfo);
    tableQuery.focusedViewFilter = 1;
    return tableQuery;
}

/**
 * Gets the tableQuery for user's current Inbox configuration
 */
export function getDefaultInboxTableQuery(mailboxInfo: MailboxInfo): MailFolderTableQuery {
    if (isFocusedInboxEnabled(mailboxInfo)) {
        return getFocusedInboxTableQuery(mailboxInfo);
    }

    return getNonFocusedInboxTableQuery(mailboxInfo);
}
