import type OwsOptionsStore from '../store/schema/OwsOptionsStore';
import getStore from '../store/store';

export function getServiceOptionStoreData(): {
    [key: string]: OwsOptionsStore;
} {
    const storeData: {
        [key: string]: OwsOptionsStore;
    } = {};

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    getStore().accounts.forEach((value, key) => {
        storeData[key] = value;
    });

    return storeData;
}
