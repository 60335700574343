import { logStartGreyError } from 'owa-analytics-start';
import { getRawStoreInternalOnly } from '../store/store';

export function checkGlobalSettingsReady(context?: string) {
    const { allowDefaultDataReturn, userConfiguration } = getRawStoreInternalOnly();
    if (!allowDefaultDataReturn && userConfiguration === undefined) {
        const error = new Error('OwaSessionStoreGlobalSettingsNotReady');
        logStartGreyError('OwaSessionStoreGlobalSettingsNotReady', error, { context });
    }
}
