import { SelectionType } from 'owa-addins-core';
import { isGroupTableSelected } from 'owa-group-utils';
import updateAddinOnItemNavigation from './updateAddinOnItemNavigation';
import { lazyGovern } from 'owa-tti';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

export default function updateAddinOnNavigationToEmptyNullReadingPane(): void {
    lazyGovern.importAndExecute({
        task: () =>
            updateAddinOnItemNavigation(
                isGroupTableSelected() ? SelectionType.NotSupported : SelectionType.Empty,
                getModuleContextMailboxInfo()
            ),
        name: 'updateAddinOnNavigationToEmptyNullReadingPane',
    });
}
