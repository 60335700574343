import type { MailboxInfo } from 'owa-client-types';
import loadFavoritesV1 from '../actions/v1/loadFavoritesV1';
import loadOutlookFavorites from '../utils/loadOutlookFavorites';
import { isFeatureEnabled } from 'owa-feature-flags';

export default function loadFavorites(mailboxInfo: MailboxInfo) {
    if (isFeatureEnabled('tri-favorites-roaming')) {
        // Load roaming favorites from the Outlook Favorites
        loadOutlookFavorites(mailboxInfo);
    } else {
        // Load favorites from FavoritesNodes
        loadFavoritesV1();
    }
}
