import { type ReadingPaneRestrictionType } from '../Behaviors.types';
import { assertNever } from 'owa-assert';
import {
    isReadingPanePositionOff,
    isReadingPanePositionRight,
    shouldShowReadingPane,
} from 'owa-mail-layout';

export const readingPaneRestrictionType = (restrictionType: ReadingPaneRestrictionType) => () => {
    const showReadingPane = shouldShowReadingPane();
    switch (restrictionType) {
        case 1:
            return showReadingPane && isReadingPanePositionOff();

        case 2:
            return isReadingPanePositionRight();

        case 0:
            return !showReadingPane;

        default:
            throw assertNever(restrictionType);
    }
};
