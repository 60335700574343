import getSourceIdForUserIdentity from 'owa-account-source-list/lib/utils/getSourceIdForUserIdentity';
import {
    accountSourceDataTypeChecker,
    getAccountBySourceId,
    getGlobalSettingsAccount,
    isAccountSourceListStoreInitialized,
} from 'owa-account-source-list-store';
import { getOWAConnectedAccount } from '../selectors';
import { isMonarchMultipleAccountsEnabled } from './flights';

/***
 * Function that checks whether the email address belongs to the connected account
 *
 * Note: In the multi-account by sourceId scenarios (ie. native host), all accounts are
 * stored in owa-account-store, but much of our existing OWA code depends on the AAD
 * accounts not being considered 'connected accounts'. For now, we return false for all
 * AAD accounts. Cleaning up this confusion will be tracked by VSO# 127698.
 */
export function isConnectedAccount(emailAddress: string): boolean {
    if (isMonarchMultipleAccountsEnabled()) {
        const account = getAccountBySourceId(getSourceIdForUserIdentity(emailAddress));
        if (!accountSourceDataTypeChecker.isM365ConnectedMailbox(account)) {
            return false;
        }
    } else {
        const globalUserIdentity = isAccountSourceListStoreInitialized()
            ? getGlobalSettingsAccount().mailboxInfo?.userIdentity
            : 'no-smtp-address';

        if (emailAddress == globalUserIdentity) {
            return false;
        }
    }

    const account = getOWAConnectedAccount(emailAddress);
    return account !== undefined;
}
