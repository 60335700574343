import {
    removeFromJunkEnterpriseMessageBodySingleSenderSelected,
    removeFromJunkEnterpriseMessageBodyTwoSendersSelected,
    removeFromJunkEnterpriseMessageBodyThreeSendersSelected,
    removeFromJunkEnterpriseMessageBodyFourPlusSendersSelected,
} from './preReportDialog.locstring.json';

import loc, { format } from 'owa-localize';

/**
 * Determine report junk dialog text according to the number of  senders
 * that will be potentially added
 */
export function getReportDialogBody(sendersEmailAddresses: string[]): string {
    let reportDialogBody: string;
    const emailAddressesCount = sendersEmailAddresses.length;
    switch (emailAddressesCount) {
        case 0:
            // This should not happen
            reportDialogBody = '';
            break;
        case 1:
            reportDialogBody = format(
                loc(removeFromJunkEnterpriseMessageBodySingleSenderSelected),
                sendersEmailAddresses[0]
            );
            break;
        case 2:
            reportDialogBody = format(
                loc(removeFromJunkEnterpriseMessageBodyTwoSendersSelected),
                sendersEmailAddresses[0],
                sendersEmailAddresses[1]
            );
            break;
        case 3:
            reportDialogBody = format(
                loc(removeFromJunkEnterpriseMessageBodyThreeSendersSelected),
                sendersEmailAddresses[0],
                sendersEmailAddresses[1]
            );
            break;
        default:
            const othersCount = emailAddressesCount - 2;
            reportDialogBody = format(
                loc(removeFromJunkEnterpriseMessageBodyFourPlusSendersSelected),
                sendersEmailAddresses[0],
                sendersEmailAddresses[1],
                othersCount
            );
    }
    return reportDialogBody;
}
