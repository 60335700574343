import getSelectedTableView from './getSelectedTableView';
import { owaComputedFn } from 'owa-computed-fn';

/**
 * This function will return true if the virtual select all exclusion list is
 * either empty, or undefined.
 * Note that `undefined` is returned as true, because we are saying that an undefined
 * exclusion list is the same as an exclusion list of nothing.
 */
const getComputedIsVirtualSelectAllExclusionListEmpty = owaComputedFn(
    function getComputedIsVirtualSelectAllExclusionListEmpty(): boolean {
        const selectedTableView = getSelectedTableView();
        if (!selectedTableView) {
            return true;
        }
        const { virtualSelectAllExclusionList = [] } = selectedTableView;
        return virtualSelectAllExclusionList.length === 0;
    }
);
export default getComputedIsVirtualSelectAllExclusionListEmpty;
