import React from 'react';
import type {
    ReactBroadComponentType,
    LazyComponent,
    LazyModule,
    ErrorHandlerFunction,
    ErrorHandlerComponent,
} from 'owa-bundling';
import { createLazyComponent } from 'owa-bundling';
import { wrapInApolloProvider } from 'owa-apollo/lib/react';

/**
 * VSO - 101850 - Remove createLazyApolloComponent once apollo is enabled as boot flight
 * This wraps the actual component (which is lazy initialized) with ApolloProvider
 */
export function createLazyApolloComponent<
    TProps extends {},
    TModule,
    TInnerRef,
    TPlaceholderRef = void
>(
    lazyModule: LazyModule<TModule>,
    getter: (module: TModule) => ReactBroadComponentType<TProps, TInnerRef>,
    placeholder?: ReactBroadComponentType<TProps, TPlaceholderRef>,
    importErrorHandler?: ErrorHandlerFunction | ErrorHandlerComponent
): LazyComponent<TProps, TInnerRef, TPlaceholderRef> {
    const innerGetter = (m: TModule) => {
        const InnerComponent = getter(m);

        /* All the generics set TS off on a wild chase, so just casting to `any` */
        return (props: TProps) => wrapInApolloProvider(<InnerComponent {...(props as any)} />);
    };

    return createLazyComponent<TProps, TModule, TInnerRef, TPlaceholderRef>(
        lazyModule,
        innerGetter,
        placeholder,
        importErrorHandler
    );
}
