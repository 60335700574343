import { owaComputedFn } from 'owa-computed-fn';
import { getMailAccountSources } from 'owa-account-source-list-store';
import { isPaid } from 'owa-session-store';

/**
 * This function returns true if the user has any account that is a paid (Enterprise + Premium/Paid consumer) amongst their accounts.
 * If all of the user's accounts are consumer (non-paid), then the function will return false.
 */
export const hasPaidAccount = owaComputedFn(function hasPaidAccount(): boolean {
    // return true if the user has at least one enterprise account
    return getMailAccountSources().some(accountSource => isPaid(accountSource.mailboxInfo));
});
