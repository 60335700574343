import loc from 'owa-localize';
import strings from './getHoverTooltip.locstring.json';

import type { ResourceId } from 'owa-localize';

export function getHoverTooltip(
    controlName: ResourceId,
    description: ResourceId | undefined,
    hotkey?: string
) {
    let tooltip = description ? loc(description) : loc(controlName);

    if (hotkey) {
        tooltip = loc(strings.hoverTooltipWithoutDuplication, tooltip, hotkey);
    }

    return tooltip;
}
