import { getStore } from '../../../store/store';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

/**
 * Get the favorite id(guid) from group id(smtp address)
 * @param groupId the group id
 * @returns the favorite id
 */
export default function getFavoriteIdFromGroupId(groupId: string): string {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (12,5): Type 'string | undefined' is not assignable to type 'string'.
    // @ts-expect-error
    return getStore().favoriteSecondaryKeyMap.get(transformGroupSmtp(groupId));
}
