import type {
    CustomWaterfallRange,
    PredefinedCheckpoint,
    WaterfallColumns,
} from 'owa-analytics-types';

export const WATERFALL_COLUMN_PREFIX = 'WF_';

export function getWaterfallColumnName(
    column: `Custom${CustomWaterfallRange}` | PredefinedCheckpoint
): WaterfallColumns {
    return `${WATERFALL_COLUMN_PREFIX}${column}`;
}

export function getCustomColumn(index: CustomWaterfallRange): `Custom${CustomWaterfallRange}` {
    return `Custom${index}`;
}

export function getCustomValue(checkpoint: string, value: string | number) {
    return `${checkpoint}|${typeof value === 'number' ? Math.round(value) : value}`;
}
