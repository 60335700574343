// If we do not have an import date we fallback to the epoch time
// for the last import date value
export const unknownLastImportDate = new Date(0);

/**
 * Returns true if the value is the date used for the unknown import date
 * @param value Date/time value
 * @returns true if the value is the unknown import date
 */
export function isUnknownImportDate(value: Date): boolean {
    return value === unknownLastImportDate;
}
