import type {
    RibbonControlGroupDefinitionSLR,
    RibbonTabDefinitionBase,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, SingleLine } from 'owa-command-ribbon';
import loc from 'owa-localize';
import Strings from 'owa-locstrings/lib/strings/ribbon_mail_buttons_and_tabs.locstring.json';
import { getMailRibbonConfigStore } from 'owa-mail-ribbon-store/lib/store/store';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { getUniqueTabId } from 'owa-mail-ribbon-utils/lib/getUniqueTabId';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { getInitialScalingSteps } from '../../../util/getInitialScalingSteps';
import { getScalingSteps } from '../../../util/getScalingSteps';
import { getTabGroups } from '../../../util/getTabGroups';
import { getSLRViewTabControlDefinition } from './getSLRViewTabControlDefinition';
import {
    controlsDontDropLabels,
    controlsDontMoveToOverflow,
    controlsWithoutLabels,
    controlsFirstMoveToOverflow,
} from './slrViewTabScalingSpecialCases';

const getSLRViewTab = owaComputedFn(function getSLRViewTab(
    props: ReadOnlyRibbonControlDefProps
): RibbonTabDefinitionBase {
    return {
        type: SingleLine,
        id: getUniqueTabId(3, undefined /* editorId */, props.itemId, props.isPopout),
        title: loc(Strings.view_tab),
        controlGroups: { type: SingleLine, groups: getViewTabGroups(props) },
        initialScalingSteps: getInitialScalingSteps(
            getMailRibbonConfigStore(props.mailboxInfo).singleline.viewTab,
            controlsWithoutLabels,
            undefined /*editorId*/,
            undefined /*itemId */
        ),
        scalingSteps: getScalingSteps(
            getMailRibbonConfigStore(props.mailboxInfo).singleline.viewTab.layout,
            getMailRibbonConfigStore(props.mailboxInfo).singleline.viewTab.showLabelsPreference,
            controlsDontMoveToOverflow,
            controlsFirstMoveToOverflow,
            controlsDontDropLabels,
            undefined /*editorId*/,
            undefined /*itemId */
        ),
        keytipProps: constructKeytip([], 3, true /* hasMenu */),
    };
});
export default getSLRViewTab;

/**
 * Retrieves the groups within the View tab, as well as the actions that
 * fall under each group.
 * @returns an object that contains the sub-groups and corresponding actions of the
 * View tab
 */
const getViewTabGroups = owaComputedFn(function getViewTabGroups(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlGroupDefinitionSLR[] {
    let ViewTabGroups: RibbonControlGroupDefinitionSLR[] = new Array();

    ViewTabGroups = getTabGroups(
        3,
        getMailRibbonConfigStore(props.mailboxInfo).singleline.viewTab.layout,
        getSLRViewTabControlDefinition,
        props
    );

    return ViewTabGroups;
});
