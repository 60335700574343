import type { MailboxInfo } from 'owa-client-types';
import getMailboxFolderTreeDataTable from './getMailboxFolderTreeDataTable';

export default function getFolderTreeHasMoreData(
    folderTreeIdentifier: string,
    mailboxInfo: MailboxInfo
): boolean {
    const folderTreeTable = getMailboxFolderTreeDataTable(mailboxInfo);
    const folderTreeData = folderTreeTable && folderTreeTable.get(folderTreeIdentifier);
    return !!folderTreeData?.hasMoreData;
}
