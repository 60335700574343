export const calendarRecurrenceFormatString = "GrmNbc";
export const oneDayAllDayFormatString = "DATqfb";
export const shortStartDateAndTimeFormatString = "MlyPYb";
export const sameDayNoDurationFormatString = "lCkLsb";
export const sameDayFormatString = "MeUAPe";
export const differentDayFormatString = "BOrcfb";
export const formatStringTwoItems = "eKG3Qe";
export const formatStringNItemsStart = "uEJNNd";
export const formatStringNItemsMiddle = "LFncld";
export const formatStringNItemsEnd = "BzX2Vb";
export const fullWeekDayDateTimeFormat = "f5eHId";
export const calendarDayOfWeek_Day = "mhj21b";
export const calendarDayOfWeek_Weekday = "CHMJl";
export const calendarDayOfWeek_WeekendDay = "tKwpmc";
export const calendarDayOfWeekIndex_First = "C9N3vc";
export const calendarDayOfWeekIndex_Second = "seOq3";
export const calendarDayOfWeekIndex_Third = "n2r3c";
export const calendarDayOfWeekIndex_Fourth = "GycxRe";
export const calendarDayOfWeekIndex_Last = "oDKVxe";
export const calendarAbsoluteMonthly1FormatString = "z8r7ge";
export const calendarAbsoluteMonthly2FormatString = "_fu7jd";
export const calendarAbsoluteMonthlyNFormatString = "a9N_k";
export const calendarAbsoluteMonthlyOptionFormatString = "jYWZe";
export const calendarAbsoluteMonthlyOptionFormatLowercaseString = "Mu4TGe";
export const calendarAbsoluteYearlyFormatString = "lANXWb";
export const calendarAbsoluteYearlyOptionFormatString = "myF8s";
export const calendarDaily1Recurrence = "ouT2F";
export const calendarDaily2Recurrence = "fZ3Czc";
export const calendarDailyNRecurrence = "ZiPQCd";
export const calendarEffectiveWithEndFormatString = "yAaPnd";
export const calendarEffectiveWithoutEndFormatString = "wvE4tc";
export const calendarRangeTimeNoDurationFormatString = "Dsraac";
export const calendarRangeTimeFormatString = "ojnQWb";
export const calendarRelativeMonthly1FormatString = "UCuAw";
export const calendarRelativeMonthly2FormatString = "PvB0ie";
export const calendarRelativeMonthlyNFormatString = "RmV3jb";
export const calendarRelativeMonthlyOptionFormatString = "SElun";
export const calendarRelativeMonthlyOptionFormatLowercaseString = "Rm0T4";
export const calendarRelativeYearlyFormatString = "QBpwm";
export const calendarRelativeYearlyOptionFormatString = "LsZgye";
export const calendarWeekly1FormatString = "QORPq";
export const calendarWeekly2FormatString = "LHYdde";
export const calendarWeeklyNFormatString = "Nygheb";
export default {calendarRecurrenceFormatString,oneDayAllDayFormatString,shortStartDateAndTimeFormatString,sameDayNoDurationFormatString,sameDayFormatString,differentDayFormatString,formatStringTwoItems,formatStringNItemsStart,formatStringNItemsMiddle,formatStringNItemsEnd,fullWeekDayDateTimeFormat,calendarDayOfWeek_Day,calendarDayOfWeek_Weekday,calendarDayOfWeek_WeekendDay,calendarDayOfWeekIndex_First,calendarDayOfWeekIndex_Second,calendarDayOfWeekIndex_Third,calendarDayOfWeekIndex_Fourth,calendarDayOfWeekIndex_Last,calendarAbsoluteMonthly1FormatString,calendarAbsoluteMonthly2FormatString,calendarAbsoluteMonthlyNFormatString,calendarAbsoluteMonthlyOptionFormatString,calendarAbsoluteMonthlyOptionFormatLowercaseString,calendarAbsoluteYearlyFormatString,calendarAbsoluteYearlyOptionFormatString,calendarDaily1Recurrence,calendarDaily2Recurrence,calendarDailyNRecurrence,calendarEffectiveWithEndFormatString,calendarEffectiveWithoutEndFormatString,calendarRangeTimeNoDurationFormatString,calendarRangeTimeFormatString,calendarRelativeMonthly1FormatString,calendarRelativeMonthly2FormatString,calendarRelativeMonthlyNFormatString,calendarRelativeMonthlyOptionFormatString,calendarRelativeMonthlyOptionFormatLowercaseString,calendarRelativeYearlyFormatString,calendarRelativeYearlyOptionFormatString,calendarWeekly1FormatString,calendarWeekly2FormatString,calendarWeeklyNFormatString};