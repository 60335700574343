import type { MailListRowDataType, TableView } from 'owa-mail-list-store';
import {
    isUnreadSearchFolder,
    getViewFilterForTable,
    doesTableSupportAutoMarkRead,
} from 'owa-mail-list-store';
import tombstoneOperations, { type TombstoneReasonType } from 'owa-mail-list-tombstone';

/**
 * Pre-processes the delete notification
 * @param serverRow row payload from server
 * @param tableView to which the row belongs
 * @returns a flag indicating whether the row notification should be processed further or not
 */
export default function preProcessServerRowDeleteNotification(
    serverRow: MailListRowDataType,
    tableView: TableView
): boolean {
    const instanceKey = serverRow.InstanceKey;
    if (instanceKey) {
        const folderId = tableView.serverFolderId;
        const tombstoneReasons = tombstoneOperations.getTombstonedReasons(instanceKey, folderId);

        /**
         * Always remove from tombstone as delete notification
         * supercedes all other reasons, so that we clean up the tombstone properly on delete
         */
        if (tombstoneReasons.indexOf(3) > -1) {
            tombstoneOperations.removeMutator(instanceKey, folderId, 3);
            return true;
        }

        /**
         * For unread filter or unread search folder, we do not want to honor the server row delete notification
         * if the row is tombstoned for read reason and auto mark read is enabled.
         * IF the row is deleted from other client it shall get deleted
         * from unread filter when the notification is received for the corresponding All filter table.
         * @see removeRowsFromListViewStoreOrchestrator
         */
        if (
            (getViewFilterForTable(tableView) === 'Unread' || isUnreadSearchFolder(tableView)) &&
            doesTableSupportAutoMarkRead(tableView) &&
            tombstoneReasons.indexOf(2) > -1
        ) {
            tombstoneOperations.removeMutator(instanceKey, folderId, 2);
            return false;
        }
    }

    return true;
}
