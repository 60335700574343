import { LazyModule, LazyAction, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/*webpackChunkName: "PhotoPickerDialog"*/ './lazyIndex')
);

export const lazyMountAndShowPhotoPickerDialog = new LazyAction(
    lazyModule,
    m => m.mountAndShowPhotoPickerDialog
);

export const lazyInitializeLokiConfiguration = new LazyAction(
    lazyModule,
    m => m.initializeLokiConfiguration
);

export const LazyPhotoPickerDialog = createLazyComponent(lazyModule, m => m.PhotoPickerDialog);

export { canUpdatePhoto } from './utils/capabilities';
