import { getStore as getMailListLayoutStore } from 'owa-mail-layout';
import { getStore as getMailListStore } from 'owa-mail-list-store';
import isDeepLink from 'owa-url/lib/isDeepLink';

export function getShouldAnimate() {
    return (
        getMailListLayoutStore().shouldAnimate &&
        !isDeepLink() /* Prevent animation when deeplinked to avoid scenario
    in which popout is closed prior to action acting on the item */ &&
        getMailListStore().shouldShowSelectedRowInRp /* Check if the selected row
    should be shown in the RP and prevent animation if not to prevent us switching
    selection too early and unnecessarily rendering a row that is being removed
    from the ML */
    );
}
