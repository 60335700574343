import getStore from '../store/store';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { canUseQuickSteps, QuickStepLoadState } from './canUseQuickSteps';

export function getQuickStepsRunOnce(mailboxInfo: MailboxInfo): boolean {
    if (canUseQuickSteps(mailboxInfo) === QuickStepLoadState.Loaded) {
        const key = getIndexerValueForMailboxInfo(mailboxInfo);
        const quickStepsConfig = getStore().quickStepsStoreMap.get(key);
        return quickStepsConfig?.runOnce ?? false;
    } else {
        return false;
    }
}
