import { getJsonRequestHeader } from './ServiceRequestUtils';
import type BaseFolderId from './contract/BaseFolderId';
import findFolderOperation from './operation/findFolderOperation';
import findFolderRequest from './factory/findFolderRequest';
import type FindFolderResponseMessage from './contract/FindFolderResponseMessage';
import type FolderId from './contract/FolderId';
import folderResponseShape from './factory/folderResponseShape';
import indexedPageView from './factory/indexedPageView';
import type RestrictionType from './contract/RestrictionType';
import type RequestOptions from './RequestOptions';
import type MailboxRequestOptions from './MailboxRequestOptions';

export interface Folder {
    Id: string;
    FolderId: FolderId;
    DisplayName: string;
}

export default async function findFolders(
    parentFolder: BaseFolderId,
    restriction?: RestrictionType,
    requestOptions?: RequestOptions | MailboxRequestOptions
): Promise<Folder[]> {
    const response = await findFolderOperation(
        {
            Header: getJsonRequestHeader(),
            Body: findFolderRequest({
                FolderShape: folderResponseShape({
                    BaseShape: 'IdOnly',
                }),
                Paging: indexedPageView({ Offset: 0, BasePoint: 'Beginning' }),
                ParentFolderIds: [parentFolder],
                ShapeName: 'Folder',
                Traversal: 'Deep',
                Restriction: restriction,
            }),
        },
        requestOptions
    );

    const folders =
        (response?.Body?.ResponseMessages?.Items?.[0] as FindFolderResponseMessage | undefined)
            ?.RootFolder?.Folders ?? [];

    return folders.map(folder => ({
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        Id: folder.FolderId!.Id,
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        FolderId: folder.FolderId!,
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        DisplayName: folder.DisplayName!,
    }));
}
