import addRecipientsToRecipientWell from 'owa-recipient-common/lib/actions/addRecipientsToRecipientWell';
import getRecipientsFromRecipientWells from 'owa-recipient-common/lib/utils/getRecipientsFromRecipientWells';
import { getReadWriteRecipientViewStateFromRecipientAddressString } from 'owa-recipient-create-viewstate/lib/util/getReadWriteRecipientViewStateFromRecipientAddressString';
import type { ComposeViewState } from 'owa-mail-compose-store';
import canModifyRecipients from './canModifyRecipients';

export function tryAddRecipientFromEmailAddressString(
    viewState: ComposeViewState,
    emailAddress: string,
    targetWindow: Window,
    addToCC: boolean = false
) {
    const recipients = getRecipientsFromRecipientWells([
        viewState.toRecipientWell,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (17,9): Type 'RecipientWellWithFindControlViewState | null' is not assignable to type 'RecipientWellWithFindControlViewState'.
        // @ts-expect-error
        viewState.ccRecipientWell,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (21,9): Type 'RecipientWellWithFindControlViewState | null' is not assignable to type 'RecipientWellWithFindControlViewState'.
        // @ts-expect-error
        viewState.bccRecipientWell,
    ]);
    const recipientPresent = recipients.some(recipient => {
        return recipient.persona.EmailAddress.EmailAddress === emailAddress;
    });
    if (canModifyRecipients(viewState) && !recipientPresent) {
        // VSO 130609: Add to CC even if not shown
        const targetWell =
            addToCC && !!viewState.ccRecipientWell
                ? viewState.ccRecipientWell
                : viewState.toRecipientWell;
        addRecipientsToRecipientWell(
            [getReadWriteRecipientViewStateFromRecipientAddressString(emailAddress)],
            targetWell,
            targetWindow
        );
    }
}
