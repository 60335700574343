import { createStore } from 'satcheljs';

interface ClipboardUtilsStore {
    clipboardWritable: boolean;
}

const defaultStore: ClipboardUtilsStore = {
    clipboardWritable: true,
};

const store = createStore<ClipboardUtilsStore>('owaClipboardUtilsStore', defaultStore)();
export const getStore = () => store;
