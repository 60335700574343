import { mailListItemAnimationStore } from '../store/Store';
import type { MailListItemAnimation } from '../store/schema/MailListItemAnimationStore';
import { getRowKeyFilter } from './getAreRowKeysInExistingAnimation';

export function isRowKeyInRowInsertionAnimation(rowKey: string): boolean {
    const { activeAnimations, pendingAnimations } = mailListItemAnimationStore;

    if (activeAnimations.filter(getRowKeyFilter(rowKey)).filter(rowInsertionFilter).length > 0) {
        return true;
    }

    if (pendingAnimations.filter(getRowKeyFilter(rowKey)).filter(rowInsertionFilter).length > 0) {
        return true;
    }

    return false;
}

const rowInsertionFilter = (animation: MailListItemAnimation) =>
    animation.animationAction === 'AddRowFinal' ||
    animation.animationAction === 'AddRowInitial' ||
    animation.animationAction === 'PinFinal' ||
    animation.animationAction === 'UnpinFinal' ||
    animation.animationAction === 'PinMiddle' ||
    animation.animationAction === 'UnpinMiddle';
