import getExtensionFromFileName from './getExtensionFromFileName';

const videoFileExtensions: string[] = [
    '3g2',
    '.3gp',
    '.3gpp',
    '.asf',
    '.avi',
    '.m2ts',
    '.m4v',
    '.mkv',
    '.mov',
    '.mp4',
    '.mp4v',
    '.mts',
    '.ts',
    '.webm',
    '.wmv',
];

export function isVideoFile(fileName: string | null | undefined): boolean {
    const extension: string | null = getExtensionFromFileName(fileName);

    return isVideoExtension(extension);
}

function isVideoExtension(extension: string | null) {
    if (extension) {
        return videoFileExtensions.indexOf(extension.toLowerCase()) >= 0;
    }

    return false;
}
