import type { CopilotThemeOptions } from 'owa-copilot-themes-types';
import { mutatorAction } from 'satcheljs';
import { getStore } from '../store/store';

/**
 * Updates the copilot theme option data in the copilot theme store
 * @param status the copilot theme option data
 */
export default mutatorAction(
    'updateCopilotThemeOptions',
    (copilotThemeData: CopilotThemeOptions, timestamp: string) => {
        getStore().options = copilotThemeData;
        getStore().timestamp = timestamp;
    }
);
