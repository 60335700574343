import type { MailboxInfo } from 'owa-client-types';
import getUserMailboxInfo from './getUserMailboxInfo';

export default function getGroupMailboxInfo(groupId: string, userIdentity: string): MailboxInfo {
    return {
        type: 'GroupMailbox',
        userIdentity: userIdentity || getUserMailboxInfo().userIdentity, // Fallback to the previous implementation
        mailboxSmtpAddress: groupId,
    };
}
