import type { TabViewState } from 'owa-tab-store';
import { getActiveContentTab, TabType } from 'owa-tab-store';
import { getStore } from 'owa-mail-compose-store/lib/store/composeStore';
import type { ComposeStore } from 'owa-mail-compose-store/lib/store/schema/ComposeStore';
import type { ComposeViewState } from 'owa-mail-compose-store/lib/store/schema/ComposeViewState';

export default function getFullComposeShowingInTabContentArea(): ComposeViewState | undefined {
    const activeTab = getActiveContentTab();
    const store = getStore();
    const composeId = activeTab ? getComposeIdFromTab(activeTab, store) : store.primaryComposeId;
    const viewState = composeId ? store.viewStates.get(composeId) : undefined;
    return viewState && !viewState.isInlineCompose ? viewState : undefined;
}

function getComposeIdFromTab(tab: TabViewState, store: ComposeStore): string | null {
    switch (tab.type) {
        case TabType.Primary:
            return store.primaryComposeId;
        case TabType.MailCompose:
            return tab.data;
        default:
            return null;
    }
}
