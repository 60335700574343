interface PersonaBlob {
    blobUrl: string | null;
    fetchStatus: PersonaPhotoFetchStatus;
    hasFetchFailed: boolean;
}

export enum PersonaPhotoFetchStatus {
    NOT_STARTED,
    IN_PROGRESS,
    COMPLETED,
}

export default PersonaBlob;
