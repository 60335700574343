// extracted by mini-css-extract-plugin
export var SLVConversationViewSenderImageOff = "BeA01";
export var SLVConversationViewSenderImageOn = "V2uYM";
export var SLVItemViewSenderImageOff = "yPKLI";
export var SLVItemViewSenderImageOn = "VS1hH";
export var bulkActionBarPadding = "eiirv";
export var buttonChecked = "aFHvi";
export var checkBox = "vQae6";
export var checkBoxSingleLine = "ccbWZ";
export var checkBoxThreeColumn = "vDvDy";
export var checkboxSpaceReserved = "MtQpS";
export var compact = "QdRvf";
export var conversationViewMCL = "tw_u9";
export var full = "rf8FT";
export var headerIconContainer = "p4pwT";
export var headerIconContainerFull = "bZBXb";
export var headerIconContainerMediumOrCompact = "s27eJ";
export var listViewHasItems = "rk2CU";
export var mailListFirstRow = "Xa56S";
export var mailListHeaderContainer = "bkYAr";
export var mailListSecondRow = "rEzfP";
export var mailListSecondRowCustomContainer = "z8J_3";
export var mailListSecondRowCustomContainerSearchTable = "GVBfx";
export var mailListSecondRowInnerContent = "_EhYJ";
export var mailListSecondRowInnerContentSearchTable = "fcAA5";
export var mailListSecondRowLeftEntity = "JV_Om";
export var mailListThirdRowMCL = "Nu9yt";
export var mailListThirdRowSCL = "c57xl";
export var mcl = "uNaZF";
export var medium = "sInYK";
export var noInboxPivots = "m74HI";
export var notesHeaderSecondRow = "F3Yx_";
export var pivotOffset = "EHnMK";
export var scl = "gkKwQ";
export var searchPivotOffset = "mWWR2";
export var secondRowCustomContainer = "FVCRn";
export var selectAllCheckboxButton = "G9d_n";
export var selectAllCheckboxButtonCompact = "H94YV";
export var selectAllCheckboxButtonMedium = "zdker";
export var selectAllCheckboxButtonSingleLine = "BPfgd";
export var selectAllCheckboxContainer = "ZYym6";
export var selectAllCheckboxContainerSearchTable = "rnJ_c";
export var selectAllHeaderStyle = "HF7j0";
export var selectAllIconFull = "l2BVB";
export var selectAllIconFullMCL = "qVYfT";
export var selectAllIconMediumAndCompact = "xX_ks";
export var selectAllIconMediumAndCompactMCL = "D91Yb";
export var selectLabelSingleLine = "Nugcg";
export var senderImageOff = "SC0Zd";
export var senderImageOn = "Gj2Pr";
export var showCheckbox = "tMqIN";
export var showInboxPivots = "i5TJa";
export var singleLineCheckboxButton = "ac0xq";
export var stickyNotesHeaderPadding = "pB18x";
export var thirdrowPaddingBottom = "lV7Ac";
export var threeColConversationViewSenderImageOff = "oSNO0";
export var threeColConversationViewSenderImageOn = "abF91";
export var threeColItemViewSenderImageOff = "jomXP";
export var threeColItemViewSenderImageOn = "dq6vK";
export var withFirstRow = "e2Pgo";