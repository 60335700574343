import setFocusedRowKey from './mutators/setFocusedRowKey';
import setMultiSelectionAnchorRowKey from './mutators/setMultiSelectionAnchorRowKey';
import setSelectionAnchorRowKey from './mutators/setSelectionAnchorRowKey';
import resetSelectionInternal from './resetSelectionInternal';
import { setSelectionOnRow } from 'owa-mail-actions/lib/setSelectionOnRow';
import type { TableView } from 'owa-mail-list-store';
import { MailRowDataPropertyGetter } from 'owa-mail-list-store';
import getInstrumentationContextsFromTableView from 'owa-mail-list-store/lib/utils/getInstrumentationContextsFromTableView';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import { openTableRowInPopout } from 'owa-popout-utils';
import type ReadingPanePopoutItemFolderInfo from 'owa-popout-utils/lib/schema/ReadingPanePopoutItemFolderInfo';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';
import { lazyPreparePopoutDataForReadingPane } from 'owa-mail-folder-common';
import endSelectMailItemDatapoint from 'owa-mail-logging/lib/utils/endSelectMailItemDatapoint';
import shouldOpenPopout from '../utils/shouldOpenPopout';
import shouldOpenReadingPaneInSingleLineView from '../utils/shouldOpenReadingPaneInSingleLineView';

// Bug 32656: In an orchestrator, the render is triggered several times
// Due to this bug, ReadingPane.render() is triggered several times inside this method.
// To solve the reading pane perf issue, wrap this function as an action.
// When the bug is resolve, please remove the action wrapper.

/**
 * Single select mail row
 * @param tableView where the operation is being performed
 * @param rowKey the rowKey of the item to select
 * @param mailListItemSelectionSource The source of selection on mail item
 */
export default action('singleSelectRowInternal')(function singleSelectRowInternal(
    tableView: TableView,
    rowKey: string,
    mailListItemSelectionSource: MailListItemSelectionSource,
    eventTimestamp?: number
) {
    if (shouldOpenPopout(mailListItemSelectionSource)) {
        const rowKeyValue =
            mailListItemSelectionSource ===
            MailListItemSelectionSource.MailListItemSimulatedDoubleClick
                ? rowKey
                : [...tableView.selectedRowKeys.keys()][0];
        const instrumentationContext = getInstrumentationContextsFromTableView(
            [rowKeyValue],
            tableView
        )[0];
        openTableRowInPopout(
            tableView.tableQuery.listViewType,
            MailRowDataPropertyGetter.getRowClientItemId(rowKeyValue, tableView),
            async () => {
                const preparePopoutDataForReadingPane =
                    await lazyPreparePopoutDataForReadingPane.import();
                const folderInfo = preparePopoutDataForReadingPane(tableView);
                return {
                    ...folderInfo,
                    instrumentationContext,
                } as ReadingPanePopoutItemFolderInfo;
            },
            eventTimestamp
        );

        // For simulated double click, don't return early so that the selection
        // logic kicks in and allows the auto mark as read behavior to work.
        if (
            mailListItemSelectionSource !==
            MailListItemSelectionSource.MailListItemSimulatedDoubleClick
        ) {
            return;
        }
    }

    const isSingleRowSelected =
        tableView.selectedRowKeys.has(rowKey) && tableView.selectedRowKeys?.size === 1;

    // Simply log and retrun if user is selecting the same item and that item is focused unless
    // user has reading pane setting as hidden (single line view) AND the reading pane should be opened.
    if (
        isSingleRowSelected &&
        rowKey === tableView.focusedRowKey &&
        !shouldOpenReadingPaneInSingleLineView(mailListItemSelectionSource)
    ) {
        // End the SelectMailItemNonCritical datapoint here and invalidate since reading pane
        // is already loaded.
        endSelectMailItemDatapoint('' /* contentType */, true /* shouldInvalidate */);
        return;
    }

    // If this rowKey is the only selected row and its twisty is being toggled, there is no need to reset and set anything new.
    if (
        !(
            mailListItemSelectionSource == MailListItemSelectionSource.MailListItemTwisty &&
            isSingleRowSelected
        )
    ) {
        // Reset list view selection
        resetSelectionInternal(tableView);
        setFocusedRowKey(tableView, rowKey);

        // Set the selection anchor for possible future range, keyboard, or multi selections
        setSelectionAnchorRowKey(tableView, rowKey);
        setMultiSelectionAnchorRowKey(tableView, null);

        // Select new item
        setSelectionOnRow(rowKey, tableView.id, true /* shouldSelect */);
    }
});
