import { useRef } from 'react';

/**
 * Hook to initialize a value only on the first render, and never again for a given component instance
 * @param initializer Function to generate the initial value
 */
export function useValueInitializedOnce<T>(initializer: () => T): T {
    // We use an object so we can differentiate when the value has not been initialized,
    // and when the value has been initialized with undefined
    const value = useRef<{
        v: T;
    }>();

    if (!value.current) {
        value.current = { v: initializer() };
    }

    return value.current.v;
}
