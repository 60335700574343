import { lazyGetMessagePreviewSections } from 'owa-mail-ribbon-sections/lib/sections';

import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onMessagePreviewMenuClicked = () => {
    lazyGetMessagePreviewSections.import().then(getMessagePreviewSections => {
        setMenuDefinition(
            'MessagePreviewMenuDefinitionStore',
            undefined /* projectionTabId */,
            () => {
                return {
                    sections: getMessagePreviewSections(),
                };
            }
        );
    });
};
