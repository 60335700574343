import getExtensibilityState from 'owa-addins-store/lib/store/getExtensibilityState';
import { translatorAddinId } from './constants';
import type { MailboxInfo } from 'owa-client-types';

export default function isTranslatorEnabled(mailboxInfo: MailboxInfo): boolean {
    const state = getExtensibilityState(mailboxInfo);
    const context = state ? state.Context : null;
    const installedExts = context?.Extensions
        ? context.Extensions.filter(ext => ext.Id == translatorAddinId)
        : [];
    return installedExts.length > 0 ? !!installedExts[0].Enabled : false;
}
