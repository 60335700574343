/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - satchel stores in the worker will need their data synced
 *	> 'createStore' import from 'satcheljs' is restricted. Satchel stores should not be created in the worker as the data will need to be synced */
import { createStore } from 'satcheljs';
import type { ExtendedTheme } from './schema/ExtendedTheme';

const initialStore: ExtendedTheme = {
    densityMode: 'Full',
    palette: undefined,
    fonts: undefined,
    isInverted: false,
    density: undefined,
};

const store = createStore<ExtendedTheme>('fabricTheme', initialStore)();
export default () => store;
