import React from 'react';
import type DragViewState from '../store/schema/DragViewState';
import type DropEffect from '../store/schema/DropEffect';
import setDragState from '../actions/setDragState';
import type { DragData } from '../utils/dragDataUtil';
import { setDragItemDetails } from '../utils/dragDataUtil';
import type { DataTransferWorkaround } from '../utils/constants';
import { isBrowserSafari } from 'owa-user-agent/lib/userAgent';
import type { AriaProperties } from 'owa-accessibility';
import { generateDomPropertiesForAria } from 'owa-accessibility';
import { useConst } from '@fluentui/react-hooks';
import { addWrappedEventListener } from 'owa-event-listener';

export interface DraggableProps {
    // A callback function to get a HTML element to override default drag preview image
    getDragPreview: (dragData: DragData) => HTMLElement;

    // (Optional) drag ViewState. Pass in this object if the component need to render different layout when dragging
    dragViewState?: DragViewState;

    // (Optional) A callback function to check if current element is able to be dragged
    canDrag?: () => boolean;

    // (Optional) A callback function to check if current element should be a draggable element
    isDraggable?: () => boolean;

    // (Optional) A callback function to call when a drag operation starts
    onDragStart?: (dataTransfer: DataTransfer) => void;

    // (Optional) A callback function to call when a drag operation ends
    onDragEnd?: (dragData: DragData, dropEffect?: DropEffect) => void;

    // (Optional) A callback function to call to allow the consumer to take additional action onMouseDown
    onMouseDown?: (mouseDownEvent: MouseEvent) => void;

    // (Optional) CSS Class name of this element
    classNames?: string;

    // (Optional) X-Offset of the preview image
    xOffset?: number;

    // (Optional) Y-Offset of the preview image
    yOffset?: number;

    // Whether we should show a default no-drag image for items that cannot be dragged
    showDefaultNoDragImage?: boolean;

    children?: React.ReactNode;

    ariaProps?: AriaProperties;

    id?: string;
}

export interface DraggablePropsSync extends DraggableProps {
    // A callback function to call when drag starts to get the data for drag-and-drop
    getDragData: () => DragData;
}

export interface DraggablePropsASync extends DraggableProps {
    // A callback function to call when drag starts to get the data for drag-and-drop
    getAsyncDragData: () => Promise<DragData>;
}

function isDraggablePropsSync(
    obj: DraggablePropsSync | DraggablePropsASync
): obj is DraggablePropsSync {
    return (obj as DraggablePropsSync).getDragData !== undefined;
}

// This is a helper class for better code reusing, this should be only invoked from owadnd classes
export class DraggableCore {
    private div: HTMLDivElement | undefined;
    private dragHelperElement: HTMLElement | null = null;
    private isDragging: boolean = false;
    private removeMouseDownListener: (() => void) | undefined;

    constructor(private props: DraggablePropsSync | DraggablePropsASync) {}

    updateProps(props: DraggablePropsSync | DraggablePropsASync) {
        this.props = props;
    }

    onUnmount() {
        this.onDragEnd(null);
        this.removeMouseDownListener?.();
    }

    onDragStart = (dragEvent: React.DragEvent<HTMLElement>) => {
        dragEvent.dataTransfer.clearData();
        if (this.isDragAllowed()) {
            dragEvent.stopPropagation();

            this.setDragStateAndData(dragEvent);

            // TODO - 9122 https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/9122
            dragEvent.dataTransfer.effectAllowed = 'copyMove';

            this.onDragStartInternal(dragEvent.dataTransfer);
            this.overrideDefaultDragPreviewImage(dragEvent.dataTransfer);
        } else if (this.props.showDefaultNoDragImage) {
            // Drag is not allowed, show the default no-drag image
            this.overrideDefaultNoDragImage(dragEvent.dataTransfer);

            // this is required for the no-drop image to work in Firefox
            dragEvent.dataTransfer.setData('text', 'nodrop');
            dragEvent.dataTransfer.effectAllowed = 'none';
        }
    };

    onDragEnd = async (dragEvent: React.DragEvent<HTMLDivElement> | null) => {
        if (this.isDragging) {
            this.isDragging = false;
            const { onDragEnd } = this.props;
            const dropEffect = dragEvent?.dataTransfer
                ? dragEvent.dataTransfer.dropEffect
                : undefined;
            if (onDragEnd) {
                if (isDraggablePropsSync(this.props)) {
                    onDragEnd(this.props.getDragData(), dropEffect);
                } else {
                    onDragEnd(await this.props.getAsyncDragData(), dropEffect);
                }
            }
        }

        this.resetDragStateAndData();
        this.removeDragHelperElement();
    };

    refCallback = (ref: HTMLDivElement) => {
        this.removeMouseDownListener?.();
        this.div = ref;
        if (this.props.onMouseDown) {
            this.removeMouseDownListener = addWrappedEventListener(
                'Draggable',
                this.div,
                'mousedown',
                this.props.onMouseDown
            );
        }
    };

    getDiv() {
        return this.div;
    }

    private async onDragStartInternal(dataTransfer: DataTransfer) {
        this.isDragging = true;
        const { onDragStart } = this.props;
        if (onDragStart) {
            if (isDraggablePropsSync(this.props)) {
                onDragStart(dataTransfer);
            } else {
                onDragStart(dataTransfer);
            }
        }
    }

    private setDragStateAndData(dragEvent: React.DragEvent<HTMLElement>) {
        const { dragViewState } = this.props;

        if (dragViewState) {
            setDragState(dragViewState, true /*isBeingDragged*/);
        }

        if (isDraggablePropsSync(this.props)) {
            const dragData = this.props.getDragData();

            // We also use a custom MIME type for browsers that support it.
            try {
                /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                 * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                 *	> Forbidden non-null assertion. */
                dragEvent.dataTransfer.setData(dragData.itemType!, JSON.stringify(dragData));
            } catch (e) {
                // Set drag data on the data transfer object
                // 'text' is used because IE11 and Edge reject other MIME types
                // 'text' can cause issues for other cases.
                dragEvent.dataTransfer.setData('text', JSON.stringify(dragData));
            }

            // Store the drag type on a global object to help IE or Edge
            setDragItemDetails(dragData.itemType, dragData.itemData);
        } else {
            const dragData = this.props.getAsyncDragData();

            // We also use a custom MIME type for browsers that support it.
            try {
                dragData.then(dragDataValue => {
                    dragEvent.dataTransfer.setData(
                        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                         *	> Forbidden non-null assertion. */
                        dragDataValue.itemType!,
                        JSON.stringify(dragDataValue)
                    );
                });
            } catch (e) {
                // Set drag data on the data transfer object
                // 'text' is used because IE11 and Edge reject other MIME types
                // 'text' can cause issues for other cases.
                dragData.then(dragDataValue => {
                    dragEvent.dataTransfer.setData('text', JSON.stringify(dragDataValue));
                });
            }

            // Store the drag type on a global object to help IE or Edge
            dragData.then(dragDataValue => {
                setDragItemDetails(dragDataValue.itemType, dragDataValue.itemData);
            });
        }
    }

    private resetDragStateAndData() {
        const { dragViewState } = this.props;

        if (dragViewState) {
            // clear dragging state
            setDragState(dragViewState, false /*isBeingDragged*/);
        }
        setDragItemDetails(null, null);
    }

    private async overrideDefaultDragPreviewImage(dataTransfer: DataTransfer) {
        this.dragHelperElement = await this.getDragHelperElement();
        document.body.appendChild(this.dragHelperElement);

        const xOffset = this.props.xOffset ? this.props.xOffset : 0;
        const yOffset = this.props.yOffset ? this.props.yOffset : 0;
        (dataTransfer as DataTransferWorkaround).setDragImage(
            this.dragHelperElement,
            xOffset,
            yOffset
        );
    }

    private overrideDefaultNoDragImage(dataTransfer: DataTransfer) {
        this.dragHelperElement = document.createElement('div');
        this.dragHelperElement.style.display = 'none';
        document.body.appendChild(this.dragHelperElement);
        (dataTransfer as DataTransferWorkaround).setDragImage(this.dragHelperElement, 0, 0);
    }

    private removeDragHelperElement = () => {
        // If there's no drag helper element, then there's nothing to remove
        if (!this.dragHelperElement) {
            return;
        }

        // Remove the drag helper element from the DOM
        if (document.body?.contains(this.dragHelperElement)) {
            document.body.removeChild(this.dragHelperElement);
        }

        // Set reference to null to prevent memory leaks
        this.dragHelperElement = null;
    };

    private async getDragHelperElement(): Promise<HTMLElement> {
        // Always get the drag preview as it could be different for each drag instance
        let dragHelperElement;
        if (isDraggablePropsSync(this.props)) {
            dragHelperElement = this.props.getDragPreview(this.props.getDragData());
        } else {
            dragHelperElement = this.props.getDragPreview(await this.props.getAsyncDragData());
        }
        dragHelperElement.style.zIndex = '-1000';

        if (!isBrowserSafari()) {
            dragHelperElement.style.top = '0px';
            dragHelperElement.style.left = '-1000px';
            dragHelperElement.style.position = 'fixed';
        } else {
            // prevents iPad drag preview transforming such that the dragHelperElement's contents
            // shrink with a lot of extra padding while dragging
            dragHelperElement.style.width = 'fit-content';
            dragHelperElement.style.height = 'fit-content';
        }
        return dragHelperElement;
    }

    private isDragAllowed() {
        return !this.props.canDrag || this.props.canDrag();
    }
}

export interface DraggableHandle {
    getDiv(): HTMLDivElement | undefined;
}

// TODO: Bug 14032: Consider a framework-independent design of owa-dnd
export default React.forwardRef(function Draggable(
    props: DraggablePropsSync | DraggablePropsASync,
    ref: React.Ref<DraggableHandle>
) {
    React.useEffect(() => {
        return () => {
            core.onUnmount();
        };
    }, []);
    const core = useConst<DraggableCore>(() => new DraggableCore(props));

    // In case the props on the draggable component change, we need to set them
    // explicitly on the DraggableCore's instance. The useRef holds onto the same instance
    // of object
    core.updateProps(props);
    React.useImperativeHandle(
        ref,
        () => ({
            getDiv() {
                return core.getDiv();
            },
        }),
        []
    );
    return (
        <div
            id={props.id}
            draggable={props.isDraggable ? props.isDraggable() : true}
            {...generateDomPropertiesForAria(props.ariaProps)}
            className={props.classNames}
            ref={core.refCallback}
            onDragStart={core.onDragStart}
            onDragEnd={core.onDragEnd}
        >
            {props.children}
        </div>
    );
});
