import getMasterCategoryList from './getMasterCategoryList';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Return whether the category is in master list
 * @param id of the category
 * @param mailboxInfo account identifier to retrieve the categories
 * @returns a boolean which indicates whether a category exists in master list
 */
export default function isCategoryInMasterList(id: string, mailboxInfo: MailboxInfo): boolean {
    return getMasterCategoryList(mailboxInfo).filter(category => category.Id === id).length === 1;
}
