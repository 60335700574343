export const hotkey_keyword_shift = "XLqUab";
export const hotkey_keyword_ctrl = "GvjmJd";
export const hotkey_keyword_alt = "C$1SQe";
export const hotkey_keyword_meta = "KgOdJc";
export const hotkey_keyword_backspace = "w5Q21c";
export const hotkey_keyword_tab = "UMlvg";
export const hotkey_keyword_enter = "bUsm5c";
export const hotkey_keyword_return = "nPjsxd";
export const hotkey_keyword_capslock = "XD67Ae";
export const hotkey_keyword_esc = "gYueTe";
export const hotkey_keyword_escape = "w1rKZ";
export const hotkey_keyword_space = "j3HXfd";
export const hotkey_keyword_pageup = "XAUWy";
export const hotkey_keyword_pagedown = "cBqMqb";
export const hotkey_keyword_end = "i41cTe";
export const hotkey_keyword_home = "wcFJed";
export const hotkey_keyword_left = "ubw2Rb";
export const hotkey_keyword_up = "OXrSub";
export const hotkey_keyword_right = "Z5knM";
export const hotkey_keyword_down = "ZlaCxe";
export const hotkey_keyword_ins = "PZpyVe";
export const hotkey_keyword_del = "qK0ASe";
export const hotkey_keyword_command = "m6ItBe";
export const hotkey_keyword_plus = "NXSkD";
export const keyboardShortcutThen = "idzYU";
export default {hotkey_keyword_shift,hotkey_keyword_ctrl,hotkey_keyword_alt,hotkey_keyword_meta,hotkey_keyword_backspace,hotkey_keyword_tab,hotkey_keyword_enter,hotkey_keyword_return,hotkey_keyword_capslock,hotkey_keyword_esc,hotkey_keyword_escape,hotkey_keyword_space,hotkey_keyword_pageup,hotkey_keyword_pagedown,hotkey_keyword_end,hotkey_keyword_home,hotkey_keyword_left,hotkey_keyword_up,hotkey_keyword_right,hotkey_keyword_down,hotkey_keyword_ins,hotkey_keyword_del,hotkey_keyword_command,hotkey_keyword_plus,keyboardShortcutThen};