import getSelectedTableView from '../utils/getSelectedTableView';
import getListViewState from '../selectors/getListViewState';
import getAreAllChildrenSelectedInConversation from '../utils/getAreAllChildrenSelectedInConversation';

export default function isItemPartOperation() {
    const tableView = getSelectedTableView();

    if (!tableView || !tableView.selectedRowKeys) {
        return false;
    }

    const selectedRowKeys = tableView.selectedRowKeys;

    if (selectedRowKeys.size !== 1) {
        return false;
    }

    const selectedRowKey = [...selectedRowKeys.keys()][0];

    // If a conversation has at least 1 but not all of its item parts selected, then it is an item part operation.
    return (
        getListViewState().expandedConversationViewState?.selectedNodeIds?.length > 0 &&
        !getAreAllChildrenSelectedInConversation(selectedRowKey, tableView)
    );
}
