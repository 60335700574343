import type { ResourceId } from './ResourceId';
import type { LocalizedString } from './LocalizedString';
import { getLocalizedStringStore } from './store/store';
import { isBootFeatureEnabled } from 'owa-metatags';

// Without fwk-loc-single-v2, strings are stored in observable map.
// With fwk-loc-single-v2, localized string are stored in this plain map and
// then use a single "version" field to track anyone that reads out of this map.
// This means we will re-render all components that call loc when this version
// changes (like a new string being added to the store), so we need to be judicious
// and update version only after all strings change (ex, language change, see setLocale).
const localizedStrings = new Map<ResourceId, LocalizedString>();

/**
 * Gets the set of localized strings, for a loc() operation.
 * This function ensures the calling component re-rerenders when language changes.
 * Without fwk-loc-single-v2, re-render happens as each new set of strings is merged.
 * With fwk-loc-single-v2, re-render happens when setLocale finishes merging all strings.
 */
export default function getLocalizedStrings(): ReadonlyMap<ResourceId, LocalizedString> {
    if (isBootFeatureEnabled('fwk-loc-single-v2')) {
        getLocalizedStringStore().version;
        return localizedStrings;
    } else {
        return getLocalizedStringStore().localizedStringsV1;
    }
}

/**
 * Merge a new set of localized strings into the underlying storage.
 * Without fwk-loc-single-v2, this causes individual observers to re-render, based on the strings they read.
 *
 *   We're working with the knowledge our webpack
 *   layer requires the strings to be loaded before the components that will use it
 *   will read them, so we don't really need to bump the version on each merge,
 *   and we only need a full re-render when user changes the language (see setLocale).
 */
export function mergeLocalizedStrings(strings: Record<ResourceId, LocalizedString>) {
    if (isBootFeatureEnabled('fwk-loc-single-v2')) {
        for (const [key, value] of Object.entries(strings)) {
            localizedStrings.set(key as ResourceId, value);
        }
    } else {
        getLocalizedStringStore().localizedStringsV1.merge(strings);
    }
}
