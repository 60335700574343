import { observer } from 'owa-mobx-react';
import showNotificationOnUnsubscribe from '../utils/showNotificationOnUnsubscribe';
import deleteAllOnUnsubscribe from '../utils/deleteAllOnUnsubscribe';
import loc, { format } from 'owa-localize';
import { unsubscribe } from 'owa-locstrings/lib/strings/unsubscribe.locstring.json';
import React from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { DirectionalHint, FocusTrapCallout } from '@fluentui/react/lib/Callout';
import {
    container,
    peekTitle,
    peekSubtitle,
    footerButtonsContainer,
    button,
    unsubscribeButton,
    noThankYouButton,
} from './SuggestedUnsubscribePeek.scss';

import type { Target } from '@fluentui/react/lib/Callout';
import { FluentButton } from 'owa-fluent-v9-shims';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import {
    unsubscribeAsk,
    suggestedUnsubscribeReason,
    deleteAllCheckboxLabel,
    noThankYou,
} from './suggestedUnsubscribe.locstring.json';
import type { TableView } from 'owa-mail-list-store';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import {
    lazyRemoveSuggestedUnsubscribeItem,
    lazyUpdateSuggestedUnsubscribeAllowList,
} from 'owa-mail-suggested-unsubscribe-store';
import type EmailAddressWrapper from 'owa-service/lib/contract/EmailAddressWrapper';
import {
    unsubscribeFromBrandSubscription,
    getSubscriptionFromCache,
} from 'owa-brands-subscription';
import { logUsage } from 'owa-analytics';
import { hasQueryStringParameter } from 'owa-querystring';
import classnames from 'owa-classnames';

export interface SuggestedUnsubscribePeekProps {
    calloutTarget: Target;
    onCalloutDismiss: () => void;
    senderName: string;
    senderEmailAddress: string;
    suggestionReason: string;
    tableView: TableView;
}

const isClickableOutsideFocusTrap = { isClickableOutsideFocusTrap: true };

export default observer(function SuggestedUnsubscribePeek(props: SuggestedUnsubscribePeekProps) {
    const [deleteAll, { toggle: toggleDeleteAll }] = useBoolean(true);

    const mailboxInfo = getMailboxInfo(props.tableView);

    const logSuggestedUnsubscribe = React.useCallback(
        (
            actionSource: string,
            unsubscribed: boolean = false,
            deleteAllChecked: boolean = false
        ) => {
            logUsage('SuggestedUnsubscribe', {
                actionSource,
                unsubscribed,
                deleteAll: deleteAllChecked,
            });
        },
        []
    );

    const onDismiss = React.useCallback(() => {
        logSuggestedUnsubscribe('Dismiss');
        props.onCalloutDismiss();
    }, [props.onCalloutDismiss]);

    const onUnsubscribe = React.useCallback(() => {
        const cacheEntry = getSubscriptionFromCache(props.senderEmailAddress);

        // There may not be subscription cache entry if it is a test item, but delete all should
        // be testable
        if (!cacheEntry && !hasQueryStringParameter('testSuggestedUnsubscribe')) {
            // This is already checked before showing the component, but there may be cases where the entry could have
            // been deleted after that first check. For example, the user unsubscribes from settings before clicking
            // unsubscribe in SuggestedUnsubscribe
            props.onCalloutDismiss();
            lazyRemoveSuggestedUnsubscribeItem.importAndExecute(
                props.senderEmailAddress,
                mailboxInfo
            );
            logSuggestedUnsubscribe('Unsubscribe_NoCacheEntry');
            return;
        }

        let unsubscribed = false;

        // If there's cacheEntry with or without test string parameter, unsubscribe should be performed
        if (cacheEntry) {
            unsubscribeFromBrandSubscription(
                'SuggestedUnsubscribe',
                cacheEntry.smtpInfo.smtpAddress,
                cacheEntry.smtpInfo.smtpIdentifier,
                cacheEntry.unsubscribeSilentUris,
                cacheEntry.unsubscribeHttpUris[0],
                mailboxInfo
            );
            unsubscribed = true;
        }

        if (deleteAll) {
            const lastSenderMailbox: EmailAddressWrapper = {
                Name: props.senderName,
                EmailAddress: props.senderEmailAddress,
            };
            deleteAllOnUnsubscribe(lastSenderMailbox, props.tableView);
        }

        if (hasQueryStringParameter('testSuggestedUnsubscribe')) {
            lazyRemoveSuggestedUnsubscribeItem.importAndExecute(
                props.senderEmailAddress,
                mailboxInfo
            );
        }
        showNotificationOnUnsubscribe(mailboxInfo);
        logSuggestedUnsubscribe('Unsubscribe', unsubscribed, deleteAll);
        props.onCalloutDismiss();
    }, [
        props.senderName,
        props.senderEmailAddress,
        props.tableView,
        props.onCalloutDismiss,
        deleteAll,
    ]);

    const onNoThankYou = React.useCallback(() => {
        logSuggestedUnsubscribe('NoThankYou');
        props.onCalloutDismiss();
        lazyUpdateSuggestedUnsubscribeAllowList.importAndExecute(
            props.senderEmailAddress,
            mailboxInfo
        );
        lazyRemoveSuggestedUnsubscribeItem.importAndExecute(props.senderEmailAddress, mailboxInfo);
    }, [props.onCalloutDismiss, props.senderEmailAddress]);

    const unsubscribeText = loc(unsubscribe);
    const noThankYouText = loc(noThankYou);

    return (
        <FocusTrapCallout
            directionalHint={DirectionalHint.rightTopEdge}
            target={props.calloutTarget}
            focusTrapProps={isClickableOutsideFocusTrap}
            onDismiss={onDismiss}
        >
            <div className={container}>
                <div>
                    <div className={peekTitle}>{format(loc(unsubscribeAsk), props.senderName)}</div>
                    <div className={peekSubtitle}>
                        {format(
                            loc(suggestedUnsubscribeReason),
                            props.senderEmailAddress,
                            props.suggestionReason
                        )}
                    </div>
                </div>
                <div>
                    <Checkbox
                        defaultChecked={deleteAll}
                        onChange={toggleDeleteAll}
                        label={format(loc(deleteAllCheckboxLabel), props.senderName)}
                    />
                </div>
                <div className={footerButtonsContainer}>
                    <FluentButton
                        appearance="primary"
                        title={unsubscribeText}
                        className={classnames(button, unsubscribeButton)}
                        onClick={onUnsubscribe}
                    >
                        {unsubscribeText}
                    </FluentButton>
                    <FluentButton
                        title={noThankYouText}
                        className={classnames(button, noThankYouButton)}
                        onClick={onNoThankYou}
                    >
                        {noThankYouText}
                    </FluentButton>
                </div>
            </div>
        </FocusTrapCallout>
    );
}, 'SuggestedUnsubscribePeek');
