import shouldUseMonarchShortcuts from './shouldUseMonarchShortcuts';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { getOptionsForFeature, OwsOptionsFeatureType } from 'owa-outlook-service-option-store';
import { type default as WebKeyboardShortcutsMode } from 'owa-service/lib/contract/KeyboardShortcutsMode';
import type {
    MonarchKeyboardShortcutsMode,
    KeyboardShortcutsOptions,
} from 'owa-outlook-service-option-store';

export default function getKeyboardShortcutsMode():
    | WebKeyboardShortcutsMode
    | MonarchKeyboardShortcutsMode {
    return shouldUseMonarchShortcuts()
        ? getOptionsForFeature<KeyboardShortcutsOptions>(OwsOptionsFeatureType.KeyboardShortcuts)
              .keyboardShortcutsMode
        : getUserConfiguration().UserOptions?.KeyboardShortcutsMode ?? 4;
}
