import type { MailboxInfo } from 'owa-client-types';
import type { GroupInformation } from '../schema/GroupInformation';
import { getGroupsState } from './getGroupsState';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

export function getGroupInformation(
    mailboxInfo: MailboxInfo,
    groupSmtpAddress: string
): GroupInformation | undefined {
    return getGroupsState(mailboxInfo).groups.get(transformGroupSmtp(groupSmtpAddress));
}
