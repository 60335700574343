import type { MailboxInfo } from 'owa-client-types';
import getUserAccount from './utils/getUserAccount';
import getSessionSettingsForUserAccount from './utils/getSessionSettingsForUserAccount';
import findOrMakeArchiveMailboxInfoForAccount from './utils/findOrMakeArchiveMailboxInfoForAccount';
import { MailboxInfoError } from './MailboxInfoError';

/**
 * Get the MailboxInfo for the archive mailbox associated with the coprincipal account
 * @param mailboxInfoInAccount Used to determine the coprincipal account, see getIndexerForMailboxInfo for more details
 * @returns MailboxInfo for the auxiliary mailbox or throw if it's not found or an error happens
 */
export function getMailboxInfoForArchive(mailboxInfoInAccount: MailboxInfo): MailboxInfo {
    const account = getUserAccount(mailboxInfoInAccount);
    const sessionSettings = getSessionSettingsForUserAccount(account);
    const { ArchiveMailboxGuid, ArchiveDisplayName } = sessionSettings;

    if (!ArchiveMailboxGuid) {
        throw new MailboxInfoError('Archive mailbox guid not found for account');
    }

    return findOrMakeArchiveMailboxInfoForAccount(
        account,
        sessionSettings,
        ArchiveMailboxGuid,
        ArchiveDisplayName
    );
}
