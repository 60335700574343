import { mutator } from 'satcheljs';
import getMailboxFolderTreeDataTable from '../selectors/getMailboxFolderTreeDataTable';
import { removeFolderTreeData } from '../actions/removeFolderTreeData';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(removeFolderTreeData, actionMessage => {
    getMailboxFolderTreeDataTable(actionMessage.mailboxInfo)?.delete(actionMessage.folderTreeId);
});
