import { owaComputedFn } from 'owa-computed-fn';
import { getComposeHostItemIndex } from 'owa-addins-store/lib/utils/hostItemIndexUtils';
import getAddinCollection from 'owa-addins-surface-actions/lib/utils/getAddinCollection';
import {
    storePollFilter,
    invalidAddInsFilter,
} from 'owa-addins-surface-actions/lib/utils/getAddinFilters';
import { ExtensibilityModeEnum } from 'owa-addins-types';
import { getDefaultRibbonStyles } from 'owa-command-ribbon-styles/lib/util/getDefaultRibbonStyles';
import {
    createPinnedAddInButton,
    createAddinButtonForReadInRibbon,
} from 'owa-mail-compose-controls/lib/components/pinnedAddIns';
import {
    retrieveCommonInfoForRibbon,
    retrieveWindowForRibbon,
} from 'owa-mail-compose-controls/lib/utils/retrieveEditingInfoForRibbon';
import getServerOptionsForFeature from 'owa-outlook-service-option-store/lib/selectors/getOptionsForFeature';
import { OwsOptionsFeatureType } from 'owa-outlook-service-option-store/lib/store/schema/OwsOptionsFeatureType';

import type { AddinCommandSurfaceItem } from 'owa-addins-types';
import type { SurfaceActionsOptions } from 'owa-outlook-service-option-store';
import type { ComposeViewState, SharedFolderComposeViewState } from 'owa-mail-compose-store';
import type { RuntimeControlId } from 'owa-ribbon-ids';
import type { RibbonRuntimeControlsGroup, RibbonRuntimeControl } from './getRuntimeControls';
import { isSharedComposeItemCheckForAddins } from 'owa-mail-store/lib/utils/sharedFolderUtilsForAddins';
import createMessageExtensionCommandSurfaceItem from 'owa-addins-view/lib/utils/createMessageExtensionCommandSurfaceItem';
import { readM365AcquisitionsFromCache } from 'owa-m365-acquisitions/lib/readM365AcquisitionsFromCache';
import { hasComposeMessageExtension } from 'owa-m365-acquisitions/lib/utils/hasMessageExtension';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { isFeatureEnabled, isAddinMultiAccountEnabled } from 'owa-feature-flags';
import { isMOS3AppServiceAvailable } from 'owa-m365-acquisitions/lib/utils/isMOS3AppServiceAvailable';
import { logUsage } from 'owa-analytics';
import { getExtensionId } from 'owa-m365-acquisitions/lib/utils/getExtensionId';
import {
    hasExchangeAddInComposeCommand,
    hasExchangeAddInReadCommand,
} from 'owa-m365-acquisitions/lib/utils/hasExchangeAddIn';
import {
    hasExtensionAddInComposeCommand,
    hasExtensionAddInReadCommand,
} from 'owa-m365-acquisitions/lib/utils/hasExtensionAddIn';

const getRibbonPinnedAddIns = owaComputedFn(function getRibbonPinnedAddIns(
    viewState: ComposeViewState,
    targetWindow: Window
): AddinCommandSurfaceItem[] {
    const hostItemIndex = getComposeHostItemIndex(viewState.composeId);
    const isSharedItem = isSharedComposeItemCheckForAddins(
        (viewState as SharedFolderComposeViewState)?.isInSharedFolder
    );

    const allAddIns: AddinCommandSurfaceItem[] = getAddinCollection(
        ExtensibilityModeEnum.MessageCompose,
        isSharedItem,
        hostItemIndex,
        viewState.mailboxInfo,
        targetWindow
    )
        .filter(invalidAddInsFilter)
        .filter(item => storePollFilter(item, viewState.mailboxInfo));

    const pinnedAddInsIds: string[] = getServerOptionsForFeature<SurfaceActionsOptions>(
        OwsOptionsFeatureType.SurfaceActions,
        viewState.mailboxInfo
    ).composeSurfaceAddins;

    const mailboxInfo = viewState.mailboxInfo;

    if (isFeatureEnabled('mos-messageExtensionPinning') && isMOS3AppServiceAvailable(mailboxInfo)) {
        const acquisitions = readM365AcquisitionsFromCache(
            undefined /* datapoint */,
            isAddinMultiAccountEnabled() ? mailboxInfo : undefined
        );

        acquisitions.forEach(acquisition => {
            const extensionId = getExtensionId(acquisition).toLowerCase();
            if (
                (hasComposeMessageExtension(acquisition) ||
                    hasExchangeAddInComposeCommand(acquisition) ||
                    hasExtensionAddInComposeCommand(acquisition)) &&
                pinnedAddInsIds.includes(extensionId ?? '')
            ) {
                // We only add the add-in if it doesn't have any duplicate in the list
                if (!allAddIns.some(addIn => addIn.key === extensionId)) {
                    allAddIns.push(
                        createMessageExtensionCommandSurfaceItem({
                            id: extensionId ?? '',
                            name: acquisition.titleDefinition.name ?? '',
                            description: acquisition.titleDefinition.shortDescription ?? '',
                            iconUrl: acquisition.titleDefinition.iconLarge?.uri ?? '',
                        })
                    );
                } else {
                    logUsage('Duplicate_AddIn_Found_RibbonPinnedList');
                }
            }
        });
    }

    return allAddIns.filter(addIn => pinnedAddInsIds.includes(addIn.key));
});

const getReadRibbonPinnedApps = owaComputedFn(
    function getReadRibbonPinnedAddIns(): AddinCommandSurfaceItem[] {
        const pinnedApps: AddinCommandSurfaceItem[] = [];
        const mailboxInfo = getModuleContextMailboxInfo();

        const pinnedAddInsIds: string[] = getServerOptionsForFeature<SurfaceActionsOptions>(
            OwsOptionsFeatureType.SurfaceActions
        ).readSurfaceAddins;

        const acquisitions = readM365AcquisitionsFromCache(
            undefined /* datapoint */,
            isAddinMultiAccountEnabled() ? mailboxInfo : undefined
        );

        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        acquisitions.forEach(acquisition => {
            const extensionId = getExtensionId(acquisition);
            if (
                (hasExtensionAddInReadCommand(acquisition) ||
                    hasExchangeAddInReadCommand(acquisition)) &&
                pinnedAddInsIds.find(surfaceId => surfaceId.toLowerCase() == extensionId)
            ) {
                pinnedApps.push(
                    createMessageExtensionCommandSurfaceItem({
                        id: extensionId,
                        name: acquisition.titleDefinition.name ?? '',
                        description: acquisition.titleDefinition.name ?? '',
                        iconUrl:
                            acquisition.titleDefinition.iconLarge?.uri ??
                            acquisition.titleDefinition.iconSmall?.uri ??
                            '',
                    })
                );
            }
        });
        return pinnedApps;
    }
);

export function getPinnedAddInsRuntimeControls(): RibbonRuntimeControlsGroup {
    const ribbonPinnedAddIns = new Map<string, RibbonRuntimeControl>();
    let PINNED_ADDIN_CONTROL_ID: RuntimeControlId.ComposePinnedAddIns = 90000;

    const runtimeControlGroup: RibbonRuntimeControlsGroup = {
        controlsGroupName: 'ComposeAddInsPinned',
        shouldAddScalingSteps: () => {
            return true;
        },
        getComposeControlsProps: (props: { composeViewState?: ComposeViewState }) => {
            const viewState = props.composeViewState;
            if (!viewState) {
                return [];
            }
            const defaultStyles = getDefaultRibbonStyles();
            const targetWindow =
                retrieveCommonInfoForRibbon(viewState.composeId)?.targetWindow ?? window;

            const pinnedAddins = getRibbonPinnedAddIns(viewState, targetWindow);
            return pinnedAddins.map(addIn => {
                let controlId = ribbonPinnedAddIns.get(addIn.key)?.controlId;
                if (!controlId) {
                    controlId = ++PINNED_ADDIN_CONTROL_ID;
                }

                const runtimeControl: RibbonRuntimeControl = {
                    controlId,
                    buttonProps: createPinnedAddInButton(
                        addIn,
                        viewState.composeId,
                        viewState.editorId,
                        controlId,
                        targetWindow,
                        defaultStyles
                    ),
                };
                ribbonPinnedAddIns.set(addIn.key, runtimeControl);
                return runtimeControl;
            });
        },
        getReadControlsProps() {
            return [];
        },
        getControlIds() {
            return Array.from(ribbonPinnedAddIns.values()).map(({ controlId }) => controlId);
        },
        getNumControls() {
            return ribbonPinnedAddIns.size;
        },
    };
    return runtimeControlGroup;
}

export function getReadPinnedAppsRuntimeControls(): RibbonRuntimeControlsGroup {
    const ribbonPinnedApps = new Map<string, RibbonRuntimeControl>();
    let PINNED_ADDIN_CONTROL_ID: RuntimeControlId.ReadPinnedAddins = 90500;

    const runtimeControlGroup: RibbonRuntimeControlsGroup = {
        controlsGroupName: 'ReadAppsPinned',
        shouldAddScalingSteps: () => {
            return true;
        },
        getComposeControlsProps: () => {
            return [];
        },
        getReadControlsProps: owaComputedFn(props => {
            const defaultStyles = getDefaultRibbonStyles();
            const targetWindow = retrieveWindowForRibbon(props.projectionTabId) ?? window;

            const pinnedApps = getReadRibbonPinnedApps();
            return pinnedApps.map(app => {
                let controlId = ribbonPinnedApps.get(app.key)?.controlId;
                if (!controlId) {
                    controlId = ++PINNED_ADDIN_CONTROL_ID;
                }

                const runtimeControl: RibbonRuntimeControl = {
                    controlId,
                    buttonProps: createAddinButtonForReadInRibbon(
                        app,
                        controlId,
                        targetWindow,
                        defaultStyles,
                        props
                    ),
                };
                ribbonPinnedApps.set(app.key, runtimeControl);
                return runtimeControl;
            });
        }),
        getControlIds() {
            return Array.from(ribbonPinnedApps.values()).map(({ controlId }) => controlId);
        },
        getNumControls() {
            return getReadRibbonPinnedApps().length;
        },
    };
    return runtimeControlGroup;
}
