import { getAccountScopeUserSettings } from 'owa-session-store';
import { updateAccountScopeUserSettingsAndService } from 'owa-userconfiguration/lib/utils/updateAccountScopeUserSettingsAndService';
import type { MailboxInfo } from 'owa-client-types';
export type FolderPaneBitFlagsMasks = any;

/**
 * Gets a boolean to indicate if the n th bit is 1
 * @param bitMask represents the nth bit
 * @return true if nth bit is 1, false if 0
 */
export function getIsBitSet(bitMask: FolderPaneBitFlagsMasks, mailboxInfo: MailboxInfo): boolean {
    const folderPaneBitFlags =
        getAccountScopeUserSettings(mailboxInfo)?.UserOptions?.FolderPaneBitFlags || 0;

    return !!(bitMask & folderPaneBitFlags);
}

/**
 * Set bit flag
 * @param value set to 1 if true, 0 otherwise
 * @param bitMask represents the nth bit
 */
export async function setBitAndUpdateAccountScopeUserSettingsAndService(
    value: boolean,
    bitMask: FolderPaneBitFlagsMasks,
    mailboxInfo: MailboxInfo
) {
    // Already same value, no need to set again and update user config
    if (getIsBitSet(bitMask, mailboxInfo) === value) {
        return;
    }

    const folderPaneBitFlags =
        getAccountScopeUserSettings(mailboxInfo)?.UserOptions?.FolderPaneBitFlags || 0;

    const newValue: number = value ? folderPaneBitFlags | bitMask : folderPaneBitFlags & ~bitMask;

    await updateAccountScopeUserSettingsAndService(mailboxInfo, {
        UserOptions: {
            FolderPaneBitFlags: newValue,
        },
    });
}
