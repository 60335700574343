import { getItem } from 'owa-local-storage';
import type { MailboxInfo } from 'owa-client-types';

import { OFFLINE_ENABLED_KEY, OFFLINE_DISABLED_REASON_KEY } from './utils/constants';
import type { OfflineDisabledReason } from './OfflineDisabledReason';

export function isOfflineSettingEnabled(mailboxInfo: MailboxInfo) {
    // No offline support for other mailbox types (yet?)
    if (mailboxInfo.type !== 'UserMailbox') {
        return false;
    }

    const value = getItem(self, OFFLINE_ENABLED_KEY);

    return !value || value.toLocaleLowerCase() !== 'false';
}

export function getOfflineSettingDisabledReason(
    mailboxInfo: MailboxInfo
): OfflineDisabledReason | undefined {
    // No offline support for other mailbox types (yet?)
    if (mailboxInfo.type !== 'UserMailbox') {
        return 'MailboxType';
    }

    if (isOfflineSettingEnabled(mailboxInfo)) {
        return undefined;
    }

    const value = getItem(self, OFFLINE_DISABLED_REASON_KEY);

    return value ? (value as OfflineDisabledReason) : 'Setting';
}
