import type { MailboxInfo } from 'owa-client-types';
import { getGroupFolder } from './getGroupFolder';

/**
 * @param groupId Smtp address of the group
 * @param folderId Folder Id of the group folder
 * @param mailboxInfo mailbox info of account
 * @returns Returns wellknown name if any, for the specified group folder
 */
export function getGroupFolderWellKnownName(
    groupId: string,
    folderId: string,
    mailboxInfo: MailboxInfo
): string {
    return getGroupFolder(groupId, folderId, mailboxInfo)?.WellKnownName ?? '';
}
