import type { GroupFolderTreeViewState } from 'owa-groups-shared-folders-store';
import { getFavoriteGroupsListNodeViewState } from './getFavoriteGroupsListNodeViewState';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

export function getFavoriteGroupViewState(groupId: string): GroupFolderTreeViewState | null {
    return (
        getFavoriteGroupsListNodeViewState().groupListNodesViewStates.get(
            transformGroupSmtp(groupId)
        ) ?? null
    );
}
