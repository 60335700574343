import { mutatorAction } from 'satcheljs';
import type DisplayDensityMode from 'owa-service/lib/contract/DisplayDensityMode';
import getDensityOptionState from '../store/store';

export const displayDensityOptionSelected = mutatorAction(
    'DISPLAY_DENSITY_OPTION_SELECTED',
    function displayDensityOptionSelected(newDensityModeSelected: DisplayDensityMode) {
        const densityOption = getDensityOptionState();
        densityOption.densityModeSelected = newDensityModeSelected;
    }
);

export const messageListLineOptionSelected = mutatorAction(
    'MESSAGE_LIST_LINE_OPTION_SELECTED',
    function messageListLineOptionSelected(showSingleLineView: boolean) {
        const densityOption = getDensityOptionState();
        densityOption.useSingleLineMessageStyle = showSingleLineView;
    }
);

export const discardDensityOption = mutatorAction(
    'DISCARD_DENSITY_OPTION',
    function discardDensityOption() {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (27,9): Type 'null' is not assignable to type 'DisplayDensityMode'.
        // @ts-expect-error
        getDensityOptionState().densityModeSelected = null;
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (31,9): Type 'null' is not assignable to type 'boolean'.
        // @ts-expect-error
        getDensityOptionState().useSingleLineMessageStyle = null;
    }
);
