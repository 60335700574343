import { PerformanceCoreDatapoint, PerformanceDatapoint } from 'owa-analytics';
import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';
import type SelectionDirection from 'owa-mail-list-store/lib/store/schema/SelectionDirection';
import type MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import isSelectMailItemCore from './isSelectMailItemCore';
import {
    getSelectMailItemDatapoint,
    setSelectMailItemDatapoint,
} from './selectMailItemDatapointGetterAndSetter';
import { MailRowDataPropertyGetter, type TableQueryType } from 'owa-mail-list-store';
import { isReadingPanePositionOff } from 'owa-mail-layout';
import { getAggregationBucket } from 'owa-analytics-actions';
import { isReadingPaneConversationEnabled } from 'owa-mail-unstacked';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { userDate, differenceInDays, now } from 'owa-datetime';
import { getShouldAnimate } from 'owa-mail-list-item-animation-store';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';

let eventTimestamp: number | undefined = undefined;

export function setInteractionTimestamp(timeStamp?: number) {
    eventTimestamp = timeStamp;
}

export function createSelectMailItemDatapoint(
    mailListItemSelectionSource: MailListItemSelectionSource,
    tableView: TableView,
    rowKey: string | undefined,
    selectionDirection?: SelectionDirection
) {
    // End the previous SMI datapoint if it did not complete before new selection.
    const dp = getSelectMailItemDatapoint();
    dp?.invalidate();
    dp?.end();

    const isCoreAction = isSelectMailItemCore(
        mailListItemSelectionSource,
        tableView,
        rowKey,
        selectionDirection
    );
    const options = {
        timeout: 90 * 1000,
        eventTimestamp,
    };
    if (isCoreAction) {
        setSelectMailItemDatapoint(new PerformanceCoreDatapoint('SelectMailItem', options));
    } else {
        setSelectMailItemDatapoint(new PerformanceDatapoint('SelectMailItemNonCritical', options));
    }
    addCustomDataToSelectMailItemDatapoint(mailListItemSelectionSource, tableView, rowKey ?? '');
}

function addCustomDataToSelectMailItemDatapoint(
    mailListItemSelectionSource: MailListItemSelectionSource,
    tableView: TableView,
    rowKey: string
) {
    const dp = getSelectMailItemDatapoint();
    if (dp) {
        const timeStamp = MailRowDataPropertyGetter.getLastDeliveryOrRenewTimeStamp(
            rowKey,
            tableView
        );

        const daysAgo = timeStamp ? Math.max(0, differenceInDays(now(), userDate(timeStamp))) : -1;
        const autoSelectRow = getUserConfiguration()?.UserOptions?.ShowReadingPaneOnFirstLoad;
        const isConversationOnReadingPane =
            tableView.tableQuery.listViewType == 1 ? false : isReadingPaneConversationEnabled();

        dp.addCustomData([
            mailListItemSelectionSource,
            tableView.tableQuery.listViewType,
            tableView.tableQuery.type,
            tableView.tableQuery.type === 1 && (tableView.tableQuery as any).searchScope.kind,
            0, // deprecated valid coupon indexes
            false, // deprecated isNudge
            !isReadingPanePositionOff(),
            getAggregationBucket({
                value: tableView.rowKeys.indexOf(rowKey),
                buckets: [10, 25, 50, 75, 100, 150, 200, 250, 300, 500],
                exactMatches: [-1, 0],
            }), // Row key index
            undefined, // deprecated nudgedItemId
            isConversationOnReadingPane,
            tableView.tableQuery.type === 0 && folderIdToName(tableView.tableQuery.folderId),
            daysAgo,
            getShouldAnimate(),
            autoSelectRow,
        ]);

        dp.addUnifiedTelemetryData({ eventName: 'ViewMessage' });
    }
}
