import shouldShowUnstackedReadingPane from 'owa-mail-store/lib/utils/shouldShowUnstackedReadingPane';

import {
    flaggedMailListItemColor,
    expandedSelectedHeader,
    expandedMailListItemColor,
    selectedMailListItemColor,
    pinnedMailListItemColor,
    regularMailListItemColor,
} from 'owa-mail-listitem-styles/lib/scss/MailListItemBackgroundColors.scss';

export default function useBackgroundColorClass(
    isSelected: boolean,
    isFlagged: boolean,
    isPinned: boolean,
    isChecked: boolean,
    supportsFlagging: boolean,
    supportsPinning: boolean,
    isRowExpanded: boolean,
    numberOfSelectedItemParts: number | undefined
) {
    if (isSelected || isChecked) {
        const showOnlySubject = shouldShowUnstackedReadingPane() && isRowExpanded;
        if (showOnlySubject) {
            if (supportsFlagging && isFlagged) {
                // This color will eventually be replaced by a lighter shade of yellow to match win32
                return flaggedMailListItemColor;
            }
            const isHeaderSelected = isRowExpanded && numberOfSelectedItemParts == 0;
            return isHeaderSelected ? expandedSelectedHeader : expandedMailListItemColor;
        }

        // If row is expanded, then only apply selection UX if that row is also
        // checked. Otherwise, we want to show the expanded background color.
        if (isRowExpanded) {
            return isChecked ? selectedMailListItemColor : expandedMailListItemColor;
        }

        return selectedMailListItemColor;
    } else if (supportsFlagging && isFlagged) {
        return flaggedMailListItemColor;
    } else if (supportsPinning && isPinned) {
        return pinnedMailListItemColor;
    } else {
        return regularMailListItemColor;
    }
}
