import type OwsOptionsStore from '../store/schema/OwsOptionsStore';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type { MailboxInfo } from 'owa-client-types';
import getStore, { getInitialState } from '../store/store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export function getOwsOptionsStore(
    mailboxInfo: MailboxInfo = getGlobalSettingsAccountMailboxInfo()
): OwsOptionsStore {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    if (!getStore().accounts.get(key)) {
        getStore().accounts.set(key, getInitialState());
    }
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return getStore().accounts.get(key)!;
}
