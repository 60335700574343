import getO365ShellShim from './getO365ShellShim';
import type { CustomHeaderButtonBadgeColors } from '@1js/suiteux-shell-react';

export default function setShellButtonCustomBadgeColors(
    buttonId: string,
    badgeColors: CustomHeaderButtonBadgeColors | null
) {
    // Call after RAF to prevent changing state during render
    requestAnimationFrame(() =>
        getO365ShellShim().Extensibility.SetCustomHeaderButtonBadgeColor(buttonId, badgeColors)
    );
}
