import { normalizeThemeRequest } from '../utils/normalizeThemeId';
import { orchestrator } from 'satcheljs';
import { changeTheme as changeFabricTheme } from 'owa-fabric-theme';
import loadThemeAction from '../actions/loadTheme';
import { createCssVariablesPalette } from '../selectors/getPalette';
import setCurrentTheme from '../mutators/setCurrentTheme';
import setCssVariablesOnDocument from '../utils/setCssVariablesOnDocument';
import { isUserPersonalizationAllowed, onLoadTheme } from 'owa-theme-common';
import getPaletteAsRawColors from '../selectors/getPaletteAsRawColors';

export default <(actionMessage: ReturnType<typeof loadThemeAction>) => void | Promise<void>>(
    /* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
     * Baseline. DO NOT COPY AND PASTE!
     *	> Do not register an orchestrator with an action that is defined in the same package */
    orchestrator(loadThemeAction, message => {
        const { themeId, isDarkTheme } = normalizeThemeRequest(
            message.themeId,
            message.isDarkTheme,
            typeof message.themesAllowed === 'boolean'
                ? message.themesAllowed
                : isUserPersonalizationAllowed()
        );

        // We want to get the palette for the mapped theme
        const palette = createCssVariablesPalette(
            Object.keys(getPaletteAsRawColors(themeId, isDarkTheme))
        );

        setCurrentTheme(themeId);

        changeFabricTheme(palette, !!isDarkTheme);
        setCssVariablesOnDocument();
        onLoadTheme();
    })
);
