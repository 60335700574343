import type { MailboxInfo } from 'owa-client-types';
import { getGroupFolderHierarchy } from './getGroupFolderHierarchy';
import { getGroupFolderId } from './getGroupFolderId';
import { type GroupFolderWellKnownName } from 'owa-folders-constants';
import { getGroupRootFolderId } from './getGroupRootFolderId';

/**
 * @param groupId Smtp address of the group
 * @param mailboxInfo mailbox info of the account
 * @param folderId Folder Id of the group folder
 * @returns Child folders of a Group / Group folder.
 */
export function getGroupFolderChildFolderIds(
    groupId: string,
    mailboxInfo: MailboxInfo,
    folderId?: string
): string[] | undefined {
    const folderHierarchy = getGroupFolderHierarchy(groupId, mailboxInfo)?.folderHierarchy;
    if (folderHierarchy) {
        if (folderId) {
            const folderIds = folderHierarchy.get(folderId)?.ChildFolderIds;
            return folderIds;
        } else {
            const folderIds: string[] = [];
            const inboxParentFolderId = getGroupRootFolderId(groupId, mailboxInfo);

            /* Child Folders for the group to be displayed in below order
                Inbox
                Deleted Items
                User created parallel folders */
            folderIds.push(
                getGroupFolderId(groupId, 'inbox', mailboxInfo),
                getGroupFolderId(groupId, 'deleteditems', mailboxInfo)
            );

            for (const groupFolder of folderHierarchy.values()) {
                if (
                    !groupFolder.WellKnownName &&
                    groupFolder.ParentFolderId === inboxParentFolderId
                ) {
                    // User created parallel folders
                    folderIds.push(groupFolder.Id);
                }
            }
            return folderIds;
        }
    }
    return [];
}
