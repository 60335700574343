import type { ResolverContext } from 'owa-graph-schema';
import type { TypedTypePolicies } from 'owa-graph-schema-type-policies';
import type { ApolloClient, ApolloLink, NormalizedCacheObject } from '@apollo/client';
import { createApolloClient } from './createApolloClient';
import type { TraceErrorObject } from 'owa-trace';
import { isRunningOnWorker } from 'owa-config';

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

/**
 * @deprecated Prefer the direct exports of query, mutate, subscribe, etc, vs explicitly
 * getting the apollo client and calling client.query, client.mutate, client.subscribe, etc.
 * In addition to having a less verbose syntax, the exported methods add additional type checking
 * against variables to make sure they're properly shaped and possible future improvements.
 *
 * If you are wrapping a component with an ApolloProvider, consider using the wrapInApolloProvider method.
 *
 * See https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/4221/StrictVariables-StrictQuery-and-StrictMutate
 */
export const getApolloClient = getApolloClientInternal;

export function getApolloClientInternal(): ApolloClient<NormalizedCacheObject> {
    if (!apolloClient) {
        const err: TraceErrorObject = new Error('ApolloNotInitialized');
        err.addQueue = true;
        throw err;
    }

    return apolloClient;
}

export function initializeApolloClient({
    links,
    context,
    bootTypePolicies,
}: {
    links: Array<ApolloLink>;
    context: Partial<ResolverContext>;
    bootTypePolicies: TypedTypePolicies;
}) {
    apolloClient = createApolloClient({
        links,
        context,
        bootTypePolicies,
    });
    return apolloClient;
}

export function initializeApolloClientOnWorker(client: ApolloClient<NormalizedCacheObject>) {
    if (!isRunningOnWorker()) {
        throw new Error('initializeApolloClientOnWorker should only be called on a worker');
    }

    apolloClient = client;
}
