import getUserConfiguration from '../actions/getUserConfiguration';
import logDefaultOrReflowCall from 'owa-account-source-list-store/lib/utils/logDefaultOrReflowCall';
import { errorThatWillCauseAlert } from 'owa-trace';

// This is implemented as a function so that unit tests can spy on it.
export default function getDefaultLogonEmailAddress(): string | undefined {
    logDefaultOrReflowCall('getDefaultLogonEmailAddress');
    const sessionSettings = getUserConfiguration().SessionSettings;

    // This shouldn't happen in production, but if it does we'll have a hint.
    if (!sessionSettings) {
        errorThatWillCauseAlert('SessionSettings not available');
        return 'no-smtp-address';
    }

    return sessionSettings.LogonEmailAddress;
}
