import { mutatorAction } from 'satcheljs';
import { getConditionalFormattingStore } from '../store/store';
import type { MailboxInfo } from 'owa-client-types';

// We will want to call this every time the conditional formatting rules change
export default mutatorAction(
    'clearRowToModificationMap',
    function clearRowToModificationMap(mailboxInfo: MailboxInfo) {
        getConditionalFormattingStore(mailboxInfo).rowToModificationsMap.clear();
    }
);
