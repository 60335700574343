export enum StateTypeEnum {
    NoError = 'NoError',
    AuthError = 'AuthError',
}

type ErrorInfo = {
    timeStamp?: number; // Time stamp when the error was triggered
    error?: string; // Error code or messages
};

interface AccountAuthState {
    stateType: StateTypeEnum;
    errorData?: ErrorInfo;
}

export default AccountAuthState;
