import { DataSource } from 'owa-analytics-types';

export function isNetworkDataSource(dataSource: DataSource) {
    switch (dataSource) {
        case DataSource.NETWORK:
        case DataSource.NETWORK_GQL:
        case DataSource.NETWORK_GATEWAY:
            return true;
        default:
            return false;
    }
}
