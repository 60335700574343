import registerForAccountStateChange from './registerForAccountStateChange';
import type {
    AccountFilterCallback,
    AccountStateChangeCallback,
    DisposeAccountStateChangeRegisteration,
} from './registerForAccountStateChange';
import type { AccountSource } from 'owa-account-source-list-store';

/**
 * Noop implementation of the AccountStateChangeCallback
 * @param accountSource account source for which state is changing
 */
function noopAccountStateChangeCallback(_accountSource: AccountSource) {}

/**
 * Registers callbacks that will be notified when the startup for the account is complete.
 *
 * @param accountStartupComplete Callback for accounts completing the pre-render process. This includes
 * when the global settings account completes it's startup process. Or in other words this callback will
 * be called for all accounts including any that are currently setup.
 * @returns object that can be used to dispose of the callback registration
 */
export default function registerForAccountStartupComplete(
    accountStartupComplete: AccountStateChangeCallback,
    accountFilter?: AccountFilterCallback
): DisposeAccountStateChangeRegisteration {
    return registerForAccountStateChange(
        accountStartupComplete,
        noopAccountStateChangeCallback,
        accountFilter
    );
}
