import getFolderData from 'owa-mail-actions/lib/getFolderData';
import { LazyModule, registerLazyOrchestrator } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "FolderOrchestration" */ './lazyIndex'),
    { name: 'FolderOrchestration' }
);

// Register orchestrators
registerLazyOrchestrator(getFolderData, lazyModule, m => m.getFolderDataOrchestrator);
