import { createStore } from 'satcheljs';
import type SharedFolderState from './schema/SharedFolderViewState';
import type RetentionPolicyTag from 'owa-service/lib/contract/RetentionPolicyTag';
import { ObservableMap } from 'mobx';

const sharedFolderState: SharedFolderState = {
    showPeoplePickerDialog: false,
    mailboxInfo: undefined,
    retentionPolicyTags: new ObservableMap<string, RetentionPolicyTag[]>({}),
};

const store = createStore<SharedFolderState>('sharedFolderState', sharedFolderState)();
export default store;
export const getStore = () => store;
