import { observer } from 'owa-mobx-react';
import { getFolderTable, getEffectiveFolderDisplayName } from 'owa-folders';
import {
    animationContainer,
    contentContainer,
} from 'owa-expansion-animation/lib/styles/expansionAnimationStyles.scss';
import { transitionStyles } from 'owa-expansion-animation';
import { CSSTransition } from 'react-transition-group';
import getFolderViewStateFromId, {
    initializeFolderViewStateIfNeeded,
} from 'owa-mail-folder-store/lib/selectors/getFolderViewStateFromId';
import React from 'react';
import type { MailFolderNodeTreeProps } from 'owa-mail-folder-view';
import { MailFolderNode, MailFolderNodeChildren } from 'owa-mail-folder-view';
import type { MailFolder } from 'owa-graph-schema';
import { isPaid } from 'owa-session-store';
import { LazyCreateSearchFolderModal } from 'owa-mail-search-folder-view';
import { SEARCH_FOLDER_ROOT_DISTINGUISHED_ID } from 'owa-folders-constants';
import { isFeatureEnabled } from 'owa-feature-flags';

/**
 * Component responsible for rendering the Node and then its children
 */
export default observer(function MailFolderNodeTree(props: MailFolderNodeTreeProps) {
    if (isFeatureEnabled('fp-memo-folderTree')) {
        return <MailFolderNodeTreeInner {...props} />;
    } else {
        return <MailFolderNodeTreeOld {...props} />;
    }
}, 'MailFolderNodeTree');

const MailFolderNodeTreeInner = observer(function MailFoldernodeTreeInner(
    props: MailFolderNodeTreeProps
) {
    const {
        folderId,
        isFavoritesSupported,
        nestDepth,
        onContextMenu,
        treeType,
        mailboxInfo,
        distinguishedFolderParentIds,
        moveFolder,
        renderFolderNode,
        deleteFolder,
        ellipsesOnHover,
    } = props;

    const folder = getFolderTable().get(folderId);

    React.useEffect(() => {
        initializeFolderViewStateIfNeeded(folderId);
    }, []);

    const newDistinguishedFolderParentIds = React.useMemo(() => {
        const idsToUse = distinguishedFolderParentIds ? [...distinguishedFolderParentIds] : [];
        if (folder?.distinguishedFolderType) {
            idsToUse.push(folder.distinguishedFolderType);
        }
        return idsToUse;
    }, [distinguishedFolderParentIds, folder?.distinguishedFolderType]);

    if (!folder) {
        return null;
    }

    /**
     * Add child nodes of this node if the node is expanded
     */
    const viewState = getFolderViewStateFromId(folderId);
    const hasChildFolders = !!(folder.childFolderIds && folder.childFolderIds.length > 0);
    const shouldRenderSubTree = viewState.isExpanded && hasChildFolders;
    const effectiveFolderDisplayName = getEffectiveFolderDisplayName(folder);

    return (
        <>
            {/* Render folder node tree*/}
            <MailFolderNode
                key={folderId}
                depth={nestDepth}
                folderId={folderId}
                isBeingDragged={viewState.drag?.isBeingDragged}
                onContextMenu={onContextMenu}
                shouldHideToggleFavorite={!isFavoritesSupported}
                treeType={treeType}
                folder={folder as MailFolder}
                mailboxInfo={mailboxInfo}
                isFolderExpandable={hasChildFolders}
                effectiveFolderDisplayName={effectiveFolderDisplayName}
                distinguishedFolderParentIds={distinguishedFolderParentIds}
                moveFolder={moveFolder}
                deleteFolder={deleteFolder}
                ellipsesOnHover={ellipsesOnHover}
            />

            {/* Render sub node tree*/}
            <CSSTransition
                in={!!shouldRenderSubTree}
                timeout={200}
                unmountOnExit={true}
                classNames={transitionStyles}
            >
                <div className={animationContainer}>
                    <div className={contentContainer}>
                        <MailFolderNodeChildren
                            folderIdsToRender={folder.childFolderIds ?? emptyArray}
                            nestDepth={nestDepth + 1}
                            treeType={treeType}
                            isFavoritesSupported={isFavoritesSupported}
                            onContextMenu={onContextMenu}
                            renderFolderNode={renderFolderNode}
                            distinguishedFolderParentIds={newDistinguishedFolderParentIds}
                        />
                    </div>
                </div>
            </CSSTransition>

            {/* Render "New search folder" node and creation modal */}
            {isPaid(mailboxInfo) &&
                folder.distinguishedFolderType == SEARCH_FOLDER_ROOT_DISTINGUISHED_ID && (
                    <LazyCreateSearchFolderModal mailboxInfo={mailboxInfo} />
                )}
        </>
    );
},
'MailFoldernodeTreeInner');

const MailFolderNodeTreeOld = observer(function MailFolderNodeTreeOld(
    props: MailFolderNodeTreeProps
) {
    const {
        folderId,
        isFavoritesSupported,
        nestDepth,
        onContextMenu,
        treeType,
        mailboxInfo,
        distinguishedFolderParentIds,
        moveFolder,
        renderFolderNode,
        deleteFolder,
        ellipsesOnHover,
    } = props;

    React.useEffect(() => {
        initializeFolderViewStateIfNeeded(folderId);
    }, []);

    // Gql and Satchel has to be in sync before rendering the tree node
    // since there are a lot of code that will be called that starts depending on satchel store
    if (!getFolderTable().has(props.folderId)) {
        return null;
    }

    const folder = getFolderTable().get(folderId);
    if (!folder) {
        return null;
    }

    /**
     * Add child nodes of this node if the node is expanded
     */
    const viewState = getFolderViewStateFromId(folderId);
    const hasChildFolders = !!(folder.childFolderIds && folder.childFolderIds.length > 0);
    const shouldRenderSubTree = viewState.isExpanded && hasChildFolders;
    const effectiveFolderDisplayName = getEffectiveFolderDisplayName(folder);

    const newDistinguishedFolderParenIds = distinguishedFolderParentIds
        ? [...distinguishedFolderParentIds]
        : [];
    if (folder.distinguishedFolderType) {
        newDistinguishedFolderParenIds.push(folder.distinguishedFolderType);
    }

    return (
        <>
            {/* Render folder node tree*/}
            <MailFolderNode
                key={folderId}
                depth={nestDepth}
                folderId={folderId}
                isBeingDragged={viewState.drag?.isBeingDragged}
                onContextMenu={onContextMenu}
                shouldHideToggleFavorite={!isFavoritesSupported}
                treeType={treeType}
                folder={folder as MailFolder}
                mailboxInfo={mailboxInfo}
                isFolderExpandable={hasChildFolders}
                effectiveFolderDisplayName={effectiveFolderDisplayName}
                distinguishedFolderParentIds={distinguishedFolderParentIds}
                moveFolder={moveFolder}
                deleteFolder={deleteFolder}
                ellipsesOnHover={ellipsesOnHover}
            />

            {/* Render sub node tree*/}
            <CSSTransition
                in={!!shouldRenderSubTree}
                timeout={200}
                unmountOnExit={true}
                classNames={transitionStyles}
            >
                <div className={animationContainer}>
                    <div className={contentContainer}>
                        <MailFolderNodeChildren
                            folderIdsToRender={folder.childFolderIds ?? emptyArray}
                            nestDepth={nestDepth + 1}
                            treeType={treeType}
                            isFavoritesSupported={isFavoritesSupported}
                            onContextMenu={onContextMenu}
                            renderFolderNode={renderFolderNode}
                            distinguishedFolderParentIds={newDistinguishedFolderParenIds}
                        />
                    </div>
                </div>
            </CSSTransition>

            {/* Render "New search folder" node and creation modal */}
            {isPaid(mailboxInfo) &&
                folder.distinguishedFolderType == SEARCH_FOLDER_ROOT_DISTINGUISHED_ID && (
                    <LazyCreateSearchFolderModal mailboxInfo={mailboxInfo} />
                )}
        </>
    );
},
'MailFolderNodeTreeOld');

const emptyArray: string[] = [];
