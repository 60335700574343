import doesActiveExperiencesInclude from './doesActiveExperiencesInclude';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Checks to see if the user has Teams Premium Webinar license
 * @returns true if the user has Teams Premium Webinar license
 */
export default function isTeamsProWebinarUser(mailboxInfo?: MailboxInfo) {
    return doesActiveExperiencesInclude('TeamsProWebinar', mailboxInfo);
}
