import { LazyModule, LazyImport } from 'owa-bundling';
import type * as Mark from 'mark.js';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "MarkJS" */ 'mark.js'), {
    name: 'MarkJS',
});

const lazyMarkJs = new LazyImport(lazyModule, m => m.default);

let MarkJS: typeof Mark | null = null;
export async function getMarkJS(): Promise<typeof Mark> {
    if (!MarkJS) {
        const result = await lazyMarkJs.import();
        MarkJS = result;
    }
    return MarkJS;
}

export const SPAN_ELEMENT_NAME = 'span';
export const UNMARK_ELEMENT_LABEL = 'unmark';

export const MARK_ID_PREFIX = 'mark';
export const MARK_ID_NUMBER_LEN = 9;
