import { getItem, localStorageExists } from 'owa-local-storage';
import { CLIENT_CONFIGURATION_COMBINED_FAVORITES_KEY } from '../favoriteConstants';
import isMultiAccountsCombinedFavoritesEnabled from './isMultiAccountsCombinedFavoritesEnabled';

const doesCombinedFavoritesExistInCache = (): boolean => {
    if (!localStorageExists(window) || !isMultiAccountsCombinedFavoritesEnabled()) {
        return false;
    }
    return !!getItem(window, CLIENT_CONFIGURATION_COMBINED_FAVORITES_KEY);
};

export default doesCombinedFavoritesExistInCache;
