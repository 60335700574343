import { mutator } from 'satcheljs';
import { getSearchRefinersStore, createDefaultRefinersStore } from '../store/store';
import { initializeRefinersScenarioStore } from 'owa-search-actions/lib/actions/initializeRefinersScenarioStore';

mutator(initializeRefinersScenarioStore, actionMessage => {
    const { scenarioId } = actionMessage;

    // If search refiners store already exists, return, as its store is already initialized.
    if (getSearchRefinersStore().refiners.get(scenarioId)) {
        return;
    }

    // Initialize search refiners store with default values.
    getSearchRefinersStore().refiners.set(scenarioId, { ...createDefaultRefinersStore() });
});
