import { observer } from 'owa-mobx-react';
import React from 'react';
import { FluentButton } from 'owa-fluent-v9-shims';
import { KeyCodes } from '@fluentui/react/lib/Utilities';
import type { ITextField } from '@fluentui/react/lib/TextField';
import { TextField } from '@fluentui/react/lib/TextField';
import { saveButtonText } from 'owa-locstrings/lib/strings/savebuttontext.locstring.json';

import { getUITreeNodeDepth } from 'owa-tree-node/lib/utils/treeNodeUIUtils';
import loc, { isCurrentCultureRightToLeft } from 'owa-localize';

export interface FolderTextFieldProps {
    depth: number; // root is 0, every sub node increase this number by 1.
    onEntry: (value: string, folderId: string) => void;
    onDismiss: () => void;
    folderId: string;
    defaultValue?: string;
    className?: string;
    styles?: object;
}

import * as styles from './FolderTextField.scss';

import classnames from 'owa-classnames';

/**
 * Component that renders a TextField that is focused when mounted,
 * and issues a callback with the current value when either blurred or
 * "Enter" is pressed.
 */
export default observer(function FolderTextField(props: FolderTextFieldProps) {
    const textField = React.useRef<ITextField>(null);
    const [textFieldValue, setTextFieldValue] = React.useState(props.defaultValue);
    const getTextboxContainerStyles = () => {
        const treeNodePadding = getUITreeNodeDepth(props.depth);
        const nearTextPadding = treeNodePadding + 40 + 'px';
        return isCurrentCultureRightToLeft()
            ? {
                  paddingRight: nearTextPadding,
              }
            : {
                  paddingLeft: nearTextPadding,
              };
    };

    React.useEffect(() => {
        textField?.current?.focus();
        /**
         * If FolderTextField is rendered with a non-empty defaultValue prop
         * (in "Rename" mode), select all text so user can quickly rename
         * without having to move cursor position manually.
         */
        if (props.defaultValue) {
            textField?.current?.select();
        }
    }, []);

    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onBlur = () => {
        if (textField?.current?.value) {
            props.onEntry(textField.current.value, props.folderId);
        } else {
            props.onDismiss();
        }
    };
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onKeyDown = (evt: React.KeyboardEvent<unknown>) => {
        switch (evt.which) {
            case KeyCodes.enter:
                if (textFieldValue) {
                    props.onEntry(textFieldValue, props.folderId);
                }
                break;
            case KeyCodes.escape:
                props.onDismiss();
                break;
        }
    };

    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onChange = (
        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        setTextFieldValue(newValue);
    };

    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onClickSaveButton = (ev: React.MouseEvent<unknown>) => {
        ev.stopPropagation();
        textFieldValue && props.onEntry(textFieldValue, props.folderId);
    };

    return (
        <div
            className={classnames(props.className, styles.container)}
            style={getTextboxContainerStyles()}
        >
            <TextField
                className={classnames(styles.innerTextField)}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onChange={onChange}
                defaultValue={props.defaultValue}
                componentRef={textField}
                styles={props.styles}
                value={textFieldValue}
            />
            <FluentButton
                disabled={!textFieldValue}
                onClick={onClickSaveButton}
                text={loc(saveButtonText)}
                appearance="transparent"
                className={classnames(styles.newFolderSaveButton, textFieldValue && styles.enabled)}
            />
        </div>
    );
}, 'FolderTextField');
