import type { ReadingPaneConversationOptions } from 'owa-outlook-service-option-store';
import { getOptionsForFeature, OwsOptionsFeatureType } from 'owa-outlook-service-option-store';

export default function isReadingPaneConversationEnabled(): boolean {
    // First check the OWS' settings
    const readingPaneConversationOptions = getOptionsForFeature<ReadingPaneConversationOptions>(
        OwsOptionsFeatureType.ReadingPaneConversation
    );

    return !!readingPaneConversationOptions?.conversationEnabled;
}
