import { getApplicationSettings } from 'owa-application-settings';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline, please do not copy and paste this justification
 *	> 'owa-config/lib/universalCookies' import is restricted from being used. The long term plan is to deprecate the cookie so we should only be using this sparingly */
import { getCookie } from 'owa-config/lib/universalCookies';
import { getCurrentCulture } from 'owa-localize';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import {
    getAccountScopeUserSettings,
    isPremiumConsumer,
    isShadowMailboxUser,
} from 'owa-session-store';
import {
    getAllCoprincipalMailboxInfos,
    getGlobalSettingsAccountMailboxInfo,
} from 'owa-account-source-list-store';

import type { MailboxInfo } from 'owa-client-types';
import { getEnabledFlights } from 'owa-feature-flags';

var astScriptUrl: string;
var adsexpflights: string[] | undefined;

export const supportedMarkets = [
    'ar-sa',
    'da-dk',
    'de-at',
    'de-ch',
    'de-de',
    'el-gr',
    'en-ar',
    'en-at',
    'en-au',
    'en-br',
    'en-ca',
    'en-ch',
    'en-cl',
    'en-co',
    'en-de',
    'en-dk',
    'en-fi',
    'en-gb',
    'en-gr',
    'en-hk',
    'en-ie',
    'en-in',
    'en-mx',
    'en-my',
    'en-nl',
    'en-no',
    'en-nz',
    'en-pe',
    'en-pt',
    'en-sa',
    'en-se',
    'en-sg',
    'en-th',
    'en-tr',
    'en-us',
    'en-za',
    'es-ar',
    'es-cl',
    'es-co',
    'es-ec',
    'es-es',
    'es-mx',
    'es-pe',
    'es-us',
    'es-ve',
    'fi-fi',
    'fr-be',
    'fr-ca',
    'fr-ch',
    'fr-fr',
    'it-it',
    'ja-jp',
    'ko-kr',
    'ms-my',
    'nb-no',
    'nl-be',
    'nl-nl',
    'pt-br',
    'pt-pt',
    'sv-se',
    'th-th',
    'tr-tr',
    'zh-cn',
    'zh-hk',
    'zh-sg',
    'zh-tw',
];

// We do not serve Ad for the following markets
export const embargoedMarkets = ['ar-ir', 'es-cu', 'ko-kp', 'ar-sy', 'en-su', 'uk-ua'];

const countryDefaultMarkets: {
    [key: string]: string;
} = {
    ae: 'ar-ae',
    ar: 'es-ar',
    at: 'de-at',
    au: 'en-au',
    be: 'fr-be',
    br: 'pt-br',
    ca: 'en-ca',
    ch: 'de-ch',
    cl: 'es-cl',
    cn: 'zh-cn',
    co: 'es-co',
    cr: 'es-cr',
    cu: 'es-cu',
    de: 'de-de',
    dk: 'da-dk',
    do: 'es-do',
    ec: 'es-ec',
    eg: 'ar-eg',
    es: 'es-es',
    fi: 'fi-fi',
    fr: 'fr-fr',
    gb: 'en-gb',
    uk: 'en-gb',
    gr: 'el-gr',
    gt: 'es-gt',
    hk: 'zh-hk',
    id: 'id-id',
    ie: 'en-ie',
    in: 'en-in',
    ir: 'ar-ir',
    it: 'it-it',
    jp: 'ja-jp',
    kp: 'ko-kp',
    kr: 'ko-kr',
    mx: 'es-mx',
    my: 'ms-my',
    nl: 'nl-nl',
    no: 'nb-no',
    nz: 'en-nz',
    pe: 'es-pe',
    ph: 'fil-ph',
    pl: 'pl-pl',
    pt: 'pt-pt',
    ru: 'ru-ru',
    sa: 'ar-sa',
    se: 'sv-se',
    sg: 'en-sg',
    su: 'en-su',
    th: 'th-th',
    tr: 'tr-tr',
    tw: 'zh-tw',
    ua: 'uk-ua',
    us: 'en-us',
    ve: 'es-ve',
    vn: 'vi-vn',
    za: 'en-za',
};

var adMarketPublishGroupCode: string;
var isChildConsumerUserValue: boolean | undefined;

export function calculateAdMarketPublishGroupCode(): string {
    const primaryAccountMailboxInfo = getGlobalSettingsAccountMailboxInfo();
    let adMarket = 'en-us';
    const userCulture = getCurrentCulture();

    if (!IsShadowMailboxUser(primaryAccountMailboxInfo)) {
        // For MSA account, we use the live profile's region selected by the user
        // Fall back to the user culture if user has not selected any country in the MSA live profile.
        const userConfiguration = getAccountScopeUserSettings(primaryAccountMailboxInfo);

        // If AdMarket is null which means user does not select any country in the MSA live profile,
        // we will use userCulture and fall back to en-us if userCulture is not available/set as well
        if (!userConfiguration.AdMarket || !/^[a-zA-z\-]*$/.test(userConfiguration.AdMarket)) {
            adMarket = userCulture ? userCulture.toLowerCase() : 'en-us';
        } else {
            adMarket = userConfiguration.AdMarket.toLowerCase();
        }
    } else {
        // For the cloud cache account, we use the Monarch or OWA's language as the ad market.
        adMarket = userCulture ? userCulture.toLowerCase() : 'en-us';
    }

    // If the server-side emit user Ad market is already in the supported market list
    // (which is the majority case and the most ideal case, we will use it directly)
    if (supportedMarkets.indexOf(adMarket) > -1) {
        return adMarket;
    }

    return countryDefaultMarkets[adMarket.split('-')[1]] || adMarket;
}

// This function only checks the passed in mailboxInfo or global account as the premium user account
// If your feature would like to check any account as premium user, please use IsAnyUserPremiumConsumerOrBusiness
export function IsMailboxInfoOrGlobalAccountPremiumConsumerUser(
    mailboxInfo?: MailboxInfo
): boolean {
    return isPremiumConsumer(mailboxInfo ?? getGlobalSettingsAccountMailboxInfo());
}

// This method is accurate for the MSA account check during the runtime.
// This method uses the N-1 value for the Gmail.com CC account's premium status.
export function IsAnyUserPremiumConsumerOrBusiness(): boolean {
    // For each account from account list store, calculate this value
    const primaryMailboxInfo = getGlobalSettingsAccountMailboxInfo();
    const primaryMailboxSettings = getAccountScopeUserSettings(primaryMailboxInfo);
    const primaryMailboxPrimeSettingsItems = primaryMailboxSettings.PrimeSettings?.Items;
    const primaryMailboxPremiumStatusInPrimarySettings: any =
        primaryMailboxPrimeSettingsItems?.filter(
            item => item?.Id === 'PremiumStatusInPrimarySettingsOptions'
        );
    const cachedCCAccounts =
        primaryMailboxPremiumStatusInPrimarySettings?.[0]?.Value?.options?.[0]
            ?.premiumCloudCacheAccounts;

    const mailboxInfos = getAllCoprincipalMailboxInfos();
    return mailboxInfos.some(mailboxInfo => {
        const settings = getAccountScopeUserSettings(mailboxInfo);
        const sessionSettings = settings?.SessionSettings;
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * Adding IsShadowMailbox to restricted properties/methods.
         *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
        if (sessionSettings?.IsShadowMailbox) {
            const hasCached = cachedCCAccounts?.indexOf(sessionSettings.UserEmailAddress) > -1;
            return hasCached;
        } else {
            return sessionSettings?.WebSessionType === 0 || isPremiumConsumer(mailboxInfo);
        }
    });
}

// Checks if Any mailbox is a ShadowMailboxUser
export function IsAnyUserShadowMailboxUser(): boolean {
    // For each account from account list store, calculate this value
    const mailboxInfos = getAllCoprincipalMailboxInfos();
    return mailboxInfos.some(mailboxInfo => {
        return IsShadowMailboxUser(mailboxInfo);
    });
}

export function IsAnyUserBusiness(): boolean {
    // For each account from account list store, calculate this value
    const mailboxInfos = getAllCoprincipalMailboxInfos();
    return mailboxInfos.some(mailboxInfo => {
        const sessionSettings = getAccountScopeUserSettings(mailboxInfo)?.SessionSettings;
        return sessionSettings?.WebSessionType === 0;
    });
}

export function IsChildConsumerUser(mailboxInfo?: MailboxInfo): boolean {
    if (typeof isChildConsumerUserValue !== 'undefined') {
        return isChildConsumerUserValue;
    }

    // For shadow account, we calculate the age based on the shadow account's birthdate
    // For regular account, we use IsConsumerChild flag
    const targetMailboxInfo = mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();
    if (IsShadowMailboxUser(targetMailboxInfo)) {
        isChildConsumerUserValue = calculateAge(targetMailboxInfo) < 18;
    } else {
        isChildConsumerUserValue =
            getAccountScopeUserSettings(targetMailboxInfo)?.IsConsumerChild || false;
    }

    return isChildConsumerUserValue;
}

export function IsShadowMailboxUser(mailboxInfo: MailboxInfo): boolean {
    return isShadowMailboxUser(mailboxInfo);
}

export function getAdMarketPublishGroupCode(): string {
    // Cache the Ad market as it will not change in one OWA session
    if (typeof adMarketPublishGroupCode != 'undefined') {
        return adMarketPublishGroupCode;
    }

    adMarketPublishGroupCode = calculateAdMarketPublishGroupCode();
    return adMarketPublishGroupCode;
}

export function getMarketParts(usermarket: string): string[] {
    if (usermarket != null) {
        return usermarket.toUpperCase().split('-');
    }

    return [];
}

export function getMarketCountryCode(usermarket: string): string {
    return getMarketParts(usermarket)[1] || 'US';
}

export function getMuidCookie(): string {
    return getCookie('MUID') || '';
}

export function calculateAge(mailboxInfo?: MailboxInfo): number {
    const sessionSettings = getAccountScopeUserSettings(
        mailboxInfo ?? getGlobalSettingsAccountMailboxInfo()
    )?.SessionSettings;

    const birthDate = sessionSettings?.BirthdayPrecision;

    if (birthDate) {
        const timeDiff = Math.abs(Date.now() - new Date(birthDate).getTime());
        const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
        return age;
    } else {
        // If we don't have birthdate for the third party account, we will treat the user as the adult. We return 30 as the age.
        return 30;
    }
}

export function getAstScriptUrl(): string {
    // Cache astScript Url during the whole OWA session
    if (astScriptUrl) {
        return astScriptUrl;
    }

    astScriptUrl = getApplicationSettings('Ads').astJsURL;
    return astScriptUrl;
}

export function getAdsexpFlights(): string[] {
    // Cached the adsexp flights during the whole OWA session as the adsexp flights will not change during the session
    if (adsexpflights) {
        return adsexpflights;
    }

    // Get all the ads experience flights (without adsexp-mini/ads-mini which are the mini flights) to Xandr.
    // Ads always use the global account info for targeting.
    // If no ads exp flights are enabled, return an empty array instead of undefined.
    const globalSettingsMailboxInfo = getGlobalSettingsAccountMailboxInfo();
    const enabledFlights = getEnabledFlights(globalSettingsMailboxInfo);
    adsexpflights = enabledFlights.filter(
        (str: string) => str.startsWith('adsexp-') && !str.startsWith('adsexp-mini')
    );

    return adsexpflights;
}

// This is only used for test
export function setAdMarketPublishGroupCodeValue(value: any) {
    adMarketPublishGroupCode = value;
}

// This is only used for test
export function setIsChildConsumerUserValue(value: any) {
    isChildConsumerUserValue = value;
}
