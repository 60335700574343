import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getGroupsStore } from '../GroupsStore';
import type { GroupsState } from '../schema/GroupsState';
import { ObservableMap } from 'mobx';
import type GroupInformation from '../schema/GroupInformation';

export function getGroupsState(mailboxInfo: MailboxInfo): GroupsState {
    const index = getIndexerValueForMailboxInfo(mailboxInfo);
    const groupsState = getGroupsStore().groupsStateByMailboxInfo.get(index);

    if (!groupsState) {
        const defaultGroupsState: GroupsState = {
            groups: new ObservableMap<string, GroupInformation>(),
        };

        getGroupsStore().groupsStateByMailboxInfo.set(index, defaultGroupsState);
        return defaultGroupsState;
    }

    return groupsState;
}
