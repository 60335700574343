import { mutatorAction } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import { getGroupFolderViewState } from '../selectors/getGroupFolderViewState';
import type { Point } from '@fluentui/react/lib/Utilities';

export const showGroupFolderTreeContextMenu = mutatorAction(
    'showGroupFolderTreeContextMenu',
    (groupId: string, folderId: string, anchor: Point, mailboxInfo: MailboxInfo): void => {
        const groupFolderViewState = getGroupFolderViewState(groupId, folderId, mailboxInfo);
        if (groupFolderViewState) {
            groupFolderViewState.groupFolderContextMenuState = {
                groupId,
                folderId,
                anchor,
            };
        }
    }
);

export const hideGroupFolderTreeContextMenu = mutatorAction(
    'hideGroupFolderTreeContextMenu',
    (groupId: string, folderId: string, mailboxInfo: MailboxInfo): void => {
        const groupFolderViewState = getGroupFolderViewState(groupId, folderId, mailboxInfo);
        if (groupFolderViewState) {
            groupFolderViewState.groupFolderContextMenuState = null;
        }
    }
);
