import { getAppAcquisitionLink } from '../data/getAppAcquisitionLink';

import type { StrictAppAcquisitionLink } from '../types';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Which acquisition links should be shown in a particular flyout?
 * i.e. which stores should be available for a particular flyout?
 *
 * @returns `Promise<AppAcquisitionLink[]>`
 */
export async function getFlyoutAcquisitionLinks(
    mailboxInfo?: MailboxInfo
): Promise<StrictAppAcquisitionLink[]> {
    const appStore = await getAppAcquisitionLink(false /* isShownInAppBar */, mailboxInfo);
    return appStore ? [appStore] : [];
}
