import type {
    CustomData,
    CustomDataMap,
    DatapointOptions,
    InternalDatapointOptions,
} from 'owa-analytics-types';
import { AriaDatapoint } from './AriaDatapoint';

export default class GreyErrorDatapoint extends AriaDatapoint {
    private originalCustomData?: CustomDataMap;

    constructor(
        eventName?: string,
        error?: Error,
        customData?: CustomData,
        options?: DatapointOptions,
        originalCustomData?: CustomDataMap
    ) {
        super(eventName, customData, options);

        (this.options as InternalDatapointOptions).isGreyError = true;

        if (error) {
            this.addDataWithPiiScrubbing('message', error.message);
            if (error.stack) {
                this.addDataWithPiiScrubbing('stack', error.stack);
            }
        }

        if (originalCustomData) {
            this.originalCustomData = originalCustomData;
        }
    }

    getOriginalCustomData(): CustomDataMap | undefined {
        return this.originalCustomData;
    }
}
