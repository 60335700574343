import { ConvertableIdFormat } from '../schema';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';
import getIsImmutableIdFeatureOnForConnectedAccount from '../selectors/getIsImmutableIdFeatureOnForConnectedAccount';
import { isMonarchMultipleAccountsEnabled } from 'owa-accounts-store';
import { isGlobalSettingsMailbox } from 'owa-account-source-list-store';

export function getTargetFormat(mailboxInfo: MailboxInfo): ConvertableIdFormat {
    if (isMonarchMultipleAccountsEnabled() || isGlobalSettingsMailbox(mailboxInfo)) {
        return isFeatureEnabled(
            'fwk-immutable-ids',
            mailboxInfo,
            true /* dontThrowErrorIfNotInitialized */
        )
            ? ConvertableIdFormat.EwsImmutableId
            : ConvertableIdFormat.EwsId;
    }

    // OWA connected account
    return getIsImmutableIdFeatureOnForConnectedAccount()
        ? ConvertableIdFormat.EwsImmutableId
        : ConvertableIdFormat.EwsId;
}
