import { SearchTab } from 'owa-search-types/lib/types/SearchTab';
import { lazyGetActiveSearchTab, isTabbedSearchEnabled } from 'owa-tabbed-search';
import type SearchScenarioId from 'owa-search-store/lib/store/schema/SearchScenarioId';
import type { Refiner } from 'owa-search-types';
import getSearchRefinersState from './getSearchRefinersState';

export default function getAppliedRefiners(scenarioId: SearchScenarioId): Refiner[] {
    let currentTab: SearchTab | undefined = SearchTab.Mail;
    if (isTabbedSearchEnabled(false /*isFromQfScopeTabs*/)) {
        const getActiveSearchTab = lazyGetActiveSearchTab.tryImportForRender();
        if (getActiveSearchTab) {
            currentTab = getActiveSearchTab();
        }
    }
    if (!currentTab) {
        return [];
    }
    // Return only those applied refiners which pertain to the current tab
    return (getSearchRefinersState(scenarioId).appliedRefiners ?? []).filter(
        refiner => refiner.applicableTabs.indexOf(currentTab as SearchTab) !== -1
    );
}
