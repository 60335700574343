import type { MutationFunction } from '@apollo/client';
import type { ResolverContext, MailFolder } from 'owa-graph-schema';
import type {
    DeleteFolderMutation,
    DeleteFolderMutationVariables,
} from 'owa-folder-deletefolder/lib/graphql/__generated__/DeleteFolderMutation.interface';
import type {
    RenameFolderMutation,
    RenameFolderMutationVariables,
} from 'owa-folder-rename/lib/graphql/__generated__/RenameFolderMutation.interface';
import type { MailboxInfo } from 'owa-client-types';
import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import { lazyDeleteFolder } from 'owa-folder-deletefolder';
import folderStore from 'owa-folders';
import type { ActionSource } from 'owa-analytics-types';
import { isFeatureEnabled } from 'owa-feature-flags';

export const onDeleteFolder = (
    folderId: string,
    deletedItemsDistinguishedId: string,
    mailboxInfo: MailboxInfo,
    actionSource: ActionSource,
    deleteFolderMutation: MutationFunction<
        DeleteFolderMutation,
        DeleteFolderMutationVariables,
        ResolverContext
    >,
    renameFolderMutation: MutationFunction<
        RenameFolderMutation,
        RenameFolderMutationVariables,
        ResolverContext
    >,
    isSearchFolder?: boolean,
    distinguishedFolderParentIds?: string[]
) => {
    const folder: MailFolder | undefined = folderStore.folderTable.get(folderId);
    if (!folder) {
        return;
    }

    // Can't delete some folder types
    const isDistinguishedFolder = folder.distinguishedFolderType;
    const folderClassOutlookHomepage = folder.FolderClass === 'IPF.Note.OutlookHomepage';
    if (isDistinguishedFolder || folderClassOutlookHomepage) {
        return;
    }

    let isFolderUnderDeletedItemsFolder = false;
    if (
        distinguishedFolderParentIds &&
        distinguishedFolderParentIds.indexOf(deletedItemsDistinguishedId) > -1
    ) {
        isFolderUnderDeletedItemsFolder = true;
    }

    let deletedItemsFolderId = folderNameToId(deletedItemsDistinguishedId, folder?.mailboxInfo);
    let destinationMailboxInfo = mailboxInfo;
    if (
        isFeatureEnabled('fp-shared-delete-folder') &&
        folder?.mailboxInfo.type == 'SharedMailbox'
    ) {
        // If the deleted items folder has been fetched for that shared mailbox, we want to use that as the deletedItemsFolderId
        // otherwise, we will use the primary mailbox's deleted items folder (this can be the case for shared folders)
        const sharedDeletedItemsFolderId = folderNameToId(
            deletedItemsDistinguishedId,
            folder?.mailboxInfo,
            true
        );
        if (sharedDeletedItemsFolderId != '') {
            deletedItemsFolderId = sharedDeletedItemsFolderId;
            destinationMailboxInfo =
                folderStore.folderTable.get(deletedItemsFolderId)?.mailboxInfo ?? mailboxInfo; // mailboxInfo shouldn't be null here, but adding the fallback to make typescript happy
        }
    }

    lazyDeleteFolder.importAndExecute(
        deleteFolderMutation,
        folder,
        actionSource,
        deletedItemsFolderId,
        isFolderUnderDeletedItemsFolder,
        destinationMailboxInfo,
        renameFolderMutation,
        isSearchFolder
    );
};
