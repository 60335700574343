import type { RequestOptions } from './RequestOptions';

/**
 * MailboxRequestOptions is used to intercept requests within owa-service
 * and add appropriate headers for different scenarios like Connected Accounts,
 * Explicit logon etc.
 */
export default interface MailboxRequestOptions extends Omit<RequestOptions, 'mailboxInfo'> {
    /**
     * The mailbox information needed to route the request to the correct mailbox
     * by adding the appropriate headers
     */
    mailboxInfo: Exclude<RequestOptions['mailboxInfo'], undefined>; // mailboxInfo is not optional
}

/**
 * Checks to see if the supplied RequestOptions is a MailboxRequestOptions
 * @param options Options value to be checked
 * @returns True if the passed in options object is a MailboxRequestOptions
 */
export function isMailboxRequestOptions(
    options: RequestOptions | null | undefined
): options is MailboxRequestOptions {
    return !!options?.mailboxInfo;
}
