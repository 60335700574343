import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "GroupsExecuteSearchActions" */ './lazyIndex')
);

export const lazyExecuteGroupSearch = new LazyAction(lazyModule, m => m.executeGroupSearch);
export const lazyloadMoreGroupSubstrateSearch = new LazyAction(
    lazyModule,
    m => m.loadMoreGroupSubstrateSearch
);
