import React from 'react';
import { PremiumDomainConnectSecondStep } from '../lazyFunctions';
import { showModal } from 'owa-modal';

export function launchDomainConnectSetAlias(domain: string, csrfToken: string) {
    mountDomainConnectSecondStep(domain, csrfToken);
}

function mountDomainConnectSecondStep(domain: string, csrfToken: string) {
    const WrappedComponent = () => (
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2741 (14,10): Property 'onClose' is missing in type '{ domainName: string; csrfToken: string; mountFromDomainDashBoard: false; isSecondaryUser: false; }' but required in type 'DomainConnectProps'.
        // @ts-expect-error
        <PremiumDomainConnectSecondStep
            domainName={domain}
            csrfToken={csrfToken}
            mountFromDomainDashBoard={false}
            isSecondaryUser={false}
        />
    );
    showModal(WrappedComponent);
}
