import { getM365AppIdFromPinAppId } from './getM365AppIdFromPinAppId';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getAccountScopeUserSettings } from 'owa-session-store';
import type PrimeBootSetting from 'owa-service/lib/contract/PrimeBootSetting';
import { isInvalidAppBarPinnedAppsSetting, LOCKED_APPS } from './isInvalidAppBarPinnedAppsSetting';
import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';

interface CloudSetting {
    oDataContext: string;
    oDataEtag: string;
    oDataId: string;
    createdDateTime: string;
    id: string;
    itemClass: string;
    lastModifiedDateTime: string;
    name: string;
    parentSetting: string;
    scope: string;
    secondaryKey: string;
    timestamp: number;
    type: string;
    value: string;
}

export type AppBarPinnedAppsValue = {
    lockedApps: string[];
    pinnedApps: string[];
};

const pinnedAppsPerMailboxInfo: Record<string, string> = {};

/**
 * Gets the user array of pinned app ids. This is a roamed OCS setting between Outlook and OWA.
 *
 * @returns An object containing the locked and pinned app ids
 */

export function getAppBarPinnedAppIdsFromUserSettings(
    mailboxInfo?: MailboxInfo
): AppBarPinnedAppsValue | undefined {
    const mboxInfo = mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();
    const accountKey = getIndexerValueForMailboxInfo(mboxInfo);
    //no 'local' pinned apps when first loading, so gets them from global settings
    if (!pinnedAppsPerMailboxInfo[accountKey]) {
        const primeSettingsItems = getAccountScopeUserSettings(mboxInfo)?.PrimeSettings?.Items;
        const appBarPinnedAppsPrimeSetting: PrimeBootSetting[] | undefined =
            primeSettingsItems?.filter((item: PrimeBootSetting) => item?.Id == 'AppBarPinnedApps');

        const appBarPinnedAppsCloudSetting = appBarPinnedAppsPrimeSetting?.[0]?.Value[0] as
            | CloudSetting
            | undefined;
        const appBarPinnedApps: string | undefined = appBarPinnedAppsCloudSetting?.value;

        pinnedAppsPerMailboxInfo[accountKey] = appBarPinnedApps ?? '';
    }
    //'local' pinned apps based on mailbox info
    const updatedPinnedApps = pinnedAppsPerMailboxInfo[accountKey];
    if (
        !updatedPinnedApps ||
        isInvalidAppBarPinnedAppsSetting('getAppBarPinnedAppsFromUserSettings', updatedPinnedApps)
    ) {
        return undefined;
    }
    // Apps that aren't core apps are prepended with 'App|'
    const appIds = updatedPinnedApps
        .split(',')
        .map((pinSettingAppId: string) =>
            pinSettingAppId.includes('App|') ? pinSettingAppId.substring(4) : pinSettingAppId
        );
    const lockedApps = appIds
        .filter(appId => LOCKED_APPS.includes(appId))
        .map(appId => getM365AppIdFromPinAppId(appId, mailboxInfo));
    const pinnedApps = appIds
        .filter(appId => !LOCKED_APPS.includes(appId))
        .map(appId => getM365AppIdFromPinAppId(appId, mailboxInfo));
    return { lockedApps, pinnedApps };
}

export function setLocalPinnedApps(mailboxInfo: MailboxInfo, newSetting: string) {
    pinnedAppsPerMailboxInfo[getIndexerValueForMailboxInfo(mailboxInfo)] = newSetting;
}
