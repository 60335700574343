import type BrowserWidthBucket from '../store/schema/BrowserWidthBucket';
import { getBrowserWidth } from 'owa-config';
import { getFlexPaneWidth } from 'owa-suite-header-store';

/**
 * Gets the available width bucket
 */
export default function calculateAvailableWidthBucket(
    skipCache?: boolean,
    areDisplayAdsEnabled?: boolean,
    isFlexPaneShown?: boolean
): BrowserWidthBucket {
    let availableWidth = getBrowserWidth(skipCache);

    /**
     * Subtract the ads panel width to get the available width
     * to layout the app's main content
     */

    if (areDisplayAdsEnabled) {
        if (availableWidth > 1900) {
            availableWidth = availableWidth - 305;
        } else if (availableWidth > 990) {
            availableWidth = availableWidth - 165;
        }
    }

    if (isFlexPaneShown) {
        availableWidth = availableWidth - getFlexPaneWidth();
    }

    if (availableWidth >= 3001) {
        return 3001;
    }

    if (availableWidth >= 2801) {
        return 3000;
    }

    if (availableWidth >= 2601) {
        return 2800;
    }

    if (availableWidth >= 2401) {
        return 2600;
    }

    if (availableWidth >= 2201) {
        return 2400;
    }

    if (availableWidth >= 2001) {
        return 2200;
    }

    if (availableWidth >= 1801) {
        return 2000;
    }

    if (availableWidth >= 1601) {
        return 1800;
    }

    if (availableWidth >= 1600) {
        return 1600;
    }

    if (availableWidth >= 1400) {
        return 1599;
    }

    if (availableWidth >= 1366) {
        return 1399;
    }

    if (availableWidth >= 1301) {
        return 1365;
    }

    if (availableWidth >= 1239) {
        return 1300;
    }

    if (availableWidth >= 1200) {
        return 1238;
    }

    if (availableWidth >= 1080) {
        return 1199;
    }

    if (availableWidth >= 1000) {
        return 1079;
    }

    if (availableWidth >= 919) {
        return 999;
    }

    if (availableWidth >= 900) {
        return 918;
    }

    if (availableWidth >= 866) {
        return 899;
    }

    if (availableWidth >= 769) {
        return 865;
    }

    if (availableWidth >= 760) {
        return 768;
    }

    if (availableWidth >= 692) {
        return 759;
    }

    if (availableWidth >= 686) {
        return 691;
    }

    if (availableWidth >= 580) {
        return 685;
    }

    if (availableWidth >= 555) {
        return 579;
    }

    if (availableWidth >= 542) {
        return 554;
    }

    if (availableWidth >= 500) {
        return 541;
    }

    if (availableWidth >= 419) {
        return 499;
    }

    return 418;
}
