import type { UserOutlookClientsState } from './schema/UserOutlookClientsState';
import { createStore } from 'satcheljs';

const initialUserOutlookClientsState: UserOutlookClientsState = {
    clients: [],
    isCardDismissed: false,
};

const store = createStore<UserOutlookClientsState>(
    'OutlookClientsStore',
    initialUserOutlookClientsState
)();
// todo: rename to getUserOutlookClientsStore
export const userOutlookClientsStore = () => store;
