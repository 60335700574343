import { BASE_LIGHT_THEME_PALETTE } from 'owa-theme-common';
import { DefaultPalette } from '@fluentui/style-utilities';

export function createCssVariablesPalette<TKeys extends string>(
    paletteKeys: readonly TKeys[]
): Record<TKeys, string> {
    const variablePalette = Object.fromEntries(
        paletteKeys.map(key => [key, `var(--${key})`] as const)
    ) as Record<TKeys, string>;

    return variablePalette;
}

const cssVariablePalette = createCssVariablesPalette([
    ...(Object.keys(DefaultPalette) as (keyof typeof DefaultPalette)[]),
    ...(Object.keys(BASE_LIGHT_THEME_PALETTE) as (keyof typeof BASE_LIGHT_THEME_PALETTE)[]),
] as const);

export default function getPalette(): typeof cssVariablePalette {
    return cssVariablePalette;
}
