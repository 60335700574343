import { getStore } from '../store/store';
import { mutatorAction } from 'satcheljs';

// When this flag it true, we will refresh the Ad if the refreshing action is caused by non-proactive refresh events.
// Otherwise, we will not refresh the Ad if the refreshing action is caused by non-proactive refresh events.
export default mutatorAction(
    'setHonorNonProactiveRefreshFlag',
    function (honorNonProactiveRefreshFlag: boolean): void {
        const store = getStore();
        if (store) {
            store.honorNonProactiveRefreshFlag = honorNonProactiveRefreshFlag;
        }
    }
);
