import type { DialogDimension } from '@metaos/hub-sdk';

export enum DialogContentType {
    url = 'url',
    card = 'card',
    message = 'message',
    error = 'error',
    loader = 'loader',
}

export enum DialogType {
    stage = 'stage', // Will transale to FrameContexts.stage
    task = 'task', // Will transale to FrameContexts.task
    setting = 'setting',
}

export interface DialogInfo {
    type: DialogType;
    contentType: DialogContentType;
    payload: string;
    title?: string;
    width?: number | DialogDimension;
    height?: number | DialogDimension;
    completionBotId?: string;
}
