/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { lazyShowInImmersiveReader } from '../index';
import type { ClientItemId } from 'owa-client-ids';
import type { ActionSource } from 'owa-analytics-types';
import type { ActionSource as SubActionSource } from 'owa-mail-store';
import { lazyReloadSecondaryTabAsDeeplink } from 'owa-tab-store';

export function onShowInImmersiveReader(
    itemId: ClientItemId,
    actionSource: ActionSource,
    subActionSource?: SubActionSource,
    targetWindow?: Window
) {
    const isProjection: boolean | undefined = targetWindow && targetWindow !== window;
    if (isProjection) {
        lazyReloadSecondaryTabAsDeeplink.importAndExecute(itemId.Id, {
            view: 'immersive',
        });
    } else {
        lazyShowInImmersiveReader.importAndExecute(itemId, actionSource, subActionSource);
    }
}
