import { isBulkActionInState, BulkActionStateEnum } from 'owa-bulk-action-store';
import type { TableView, MailFolderTableQuery } from 'owa-mail-list-store';
import { type SortColumn } from 'owa-mail-list-store';

export function getCanTableLoadMorePrevious(tableView: TableView): boolean {
    // If bulk operation is running in this folder, do not load more
    if (isBulkActionInState(tableView.serverFolderId, BulkActionStateEnum.Running)) {
        return false;
    }

    // If we're in a Priority sorted table, then we don't load more from the
    // service (since there's no service-backed table).
    if ((tableView?.tableQuery as MailFolderTableQuery)?.sortBy?.sortColumn === 16) {
        return false;
    }

    // If we are not at the beginning of the list, we can load more previous rows
    return tableView.loadedStartIndex > 0;
}
