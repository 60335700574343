import type { TraceErrorObject } from 'owa-trace';

export function assertNever(obj: never): never {
    const error: TraceErrorObject = new Error('AssertNever: unexpected value');
    try {
        error.additionalInfo = { assertNeverValue: obj };
    } catch {
        error.additionalInfo = { assertNeverValue: '[Unable to serialize]' };
    }
    throw error;
}
