import { getMailListLogs } from '../selectors/getMailListLogs';
import type { MailListLog } from '../store/schema/MailListLogsStore';

export function getMailListLogsToSendToOds(): string[] {
    return getMailListLogs().map(log => getLogString(log));
}

function getLogString(log: MailListLog): string {
    return `${log.time},${log.message},${Object.keys(log.data)
        .map(key => `${key}: ${log.data[key]}`)
        .join(',')},`;
}
