import { LazyModule, registerLazyOrchestrator } from 'owa-bundling';

import { rangeSelectItemPart } from 'owa-mail-actions/lib/mailListSelectionActions';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName:"MailListSelectionActionsV2" */ './lazyIndex')
);

registerLazyOrchestrator(rangeSelectItemPart, lazyModule, m => m.rangeSelectItemPartOrchestrator);

// Import orchestrators and mutators so they get registered
import './orchestrators/initTableSelectionOnLoadOrchestrator';
import './orchestrators/keyboardMultiSelectRowOrchestrator';
import './orchestrators/rangeSelectRowOrchestrator';
import './orchestrators/resetSelectionOrchestrator';
import './orchestrators/selectNewItemUponRowRemovalOrchestrator';
import './orchestrators/selectRowInDirectionOrchestrator';
import './orchestrators/singleSelectRowOrchestrator';
import './orchestrators/toggleSelectAllRowsOrchestrator';
import './orchestrators/toggleSelectRowOrchestrator';

import './mutators/resetSelectionOnTableMutator';
import './mutators/setSelectionOnRowMutator';
import './mutators/updateCheckedStateOfConversationChildren';

export { setLastAutoFocusedRowKey } from './mutators/setLastAutoFocusedRowKey';
export { default as updateCheckedStateOfConversationChildren } from './mutators/updateCheckedStateOfConversationChildren';
