import type { DatapointOptions, CustomData, InternalDatapointOptions } from 'owa-analytics-types';
import type { AnalyticsCoreEventNames } from 'owa-analytics-events';
import { lazyLogUsage } from '../lazyFunctions';
import { addStackTraceToEvent } from 'owa-analytics-utils/lib/addStackTraceToEvent';

export function logCoreUsage(
    eventName: AnalyticsCoreEventNames,
    customData?: CustomData,
    options?: DatapointOptions
): Promise<void> {
    const logUsageOptions = (options || {}) as InternalDatapointOptions;
    logUsageOptions.isCore = true;

    // Add original stack trace
    addStackTraceToEvent(logUsageOptions);

    return lazyLogUsage.importAndExecute(eventName, customData, logUsageOptions);
}
