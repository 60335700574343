import { LazyModule, createLazyComponent, LazyImport } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CalendarBootCopilot" */ './lazyFunctions')
);

// Delay loaded components
export const CalendarCopilotPane = createLazyComponent(lazyModule, m => m.CalendarCopilotPane);
export const CalendarCopilotPaneHeader = createLazyComponent(
    lazyModule,
    m => m.CalendarCopilotPaneHeader
);
export const CalendarCopilotPill = createLazyComponent(lazyModule, m => m.CalendarCopilotPill);
export const CalendarCopilotSuiteHeaderButton = createLazyComponent(
    lazyModule,
    m => m.CalendarCopilotSuiteHeaderButton
);
export const lazyGetCalendarCopilotDebugInfo = new LazyImport(
    lazyModule,
    m => m.lazyGetCalendarCopilotDebugInfo.import
);
