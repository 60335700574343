import React from 'react';
import { RelayEnvironmentProvider } from 'react-relay';
import { mapEventMetadata, NovaEventingProvider, NovaGraphQLProvider } from '@nova/react';
import { getEnvironment } from 'owa-relay';
import { novaGraphQL } from './NovaGraphQL';
import { novaEventing } from './NovaEventing';

interface NovaProviderProps {
    children: React.ReactNode;
}

// Bug 251296: Update @nova/react to support React 18 and remove these hacks
const NovaGraphQLProviderWithChildren = NovaGraphQLProvider as any as WithChildren<
    typeof NovaGraphQLProvider
>;

const NovaEventingProviderWithChildren = NovaEventingProvider as any as WithChildren<
    typeof NovaEventingProvider
>;

export const NovaContextProvider: React.FunctionComponent<NovaProviderProps> = props => {
    const { children } = props;
    return (
        <RelayEnvironmentProvider environment={getEnvironment()}>
            <NovaGraphQLProviderWithChildren graphql={novaGraphQL}>
                <NovaEventingProviderWithChildren
                    eventing={novaEventing}
                    reactEventMapper={mapEventMetadata}
                >
                    {children}
                </NovaEventingProviderWithChildren>
            </NovaGraphQLProviderWithChildren>
        </RelayEnvironmentProvider>
    );
};

NovaContextProvider.displayName = 'NovaContextProvider';
