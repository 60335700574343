//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import * as Types from 'owa-graph-schema';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { LaunchTile_FragmentFragmentDoc } from './AppBarControlData.interface';
export type MessageExtensionsFlyoutQueryVariables = Types.Exact<{
    messageExtensionType: Types.Scalars['String'];
    filter?: Types.InputMaybe<Types.M365AppFilter>;
    searchQuery?: Types.InputMaybe<Types.Scalars['String']>;
}>;
export type MessageExtensionsFlyoutQuery = {
    __typename?: 'Query';
    m365MessageExtensions: {
        __typename?: 'M365ApplicationConnection';
        edges: Array<{
            __typename?: 'M365ApplicationEdge';
            node:
                | {
                      __typename?: 'M365HubApplication';
                      id: string;
                      name: string;
                      platformType?: Types.PlatformType | null;
                      isInUse?: boolean | null;
                      brandingColor?: string | null;
                      lockedIndex?: number | null;
                      pinnedIndex?: number | null;
                      accessibleName?: string | null;
                      canLaunchOut?: boolean | null;
                      enablePlatformCommands?: boolean | null;
                      canBeUnacquired?: boolean | null;
                      isPinnable: boolean;
                      allowedExternalDropContent?: Array<string> | null;
                      icon:
                          | {
                                __typename: 'InternalIcon';
                                name: any;
                            }
                          | {
                                __typename: 'RemoteImage';
                                src: string;
                                isFullBleed?: boolean | null;
                            };
                      outlineIcon?:
                          | {
                                __typename: 'InternalIcon';
                                name: any;
                            }
                          | {
                                __typename: 'RemoteImage';
                                src: string;
                                isFullBleed?: boolean | null;
                            }
                          | null;
                      badge?: {
                          __typename: 'M365ApplicationNumericBadge';
                          count: number;
                          countDescription: string;
                      } | null;
                      selectedStateIconOverride?:
                          | {
                                __typename: 'InternalIcon';
                                name: any;
                            }
                          | {
                                __typename: 'RemoteImage';
                                src: string;
                                isFullBleed?: boolean | null;
                            }
                          | null;
                      secondaryActions?: Array<{
                          __typename?: 'AppSecondaryAction';
                          id: string;
                          text: string;
                          eventType: string;
                          eventData: string;
                          icon?: {
                              __typename?: 'InternalIcon';
                              name: any;
                          } | null;
                      }> | null;
                  }
                | {
                      __typename?: 'M365PlatformApplication';
                      id: string;
                      name: string;
                      platformType?: Types.PlatformType | null;
                      isInUse?: boolean | null;
                      brandingColor?: string | null;
                      lockedIndex?: number | null;
                      pinnedIndex?: number | null;
                      accessibleName?: string | null;
                      canLaunchOut?: boolean | null;
                      enablePlatformCommands?: boolean | null;
                      canBeUnacquired?: boolean | null;
                      isPinnable: boolean;
                      allowedExternalDropContent?: Array<string> | null;
                      icon:
                          | {
                                __typename: 'InternalIcon';
                                name: any;
                            }
                          | {
                                __typename: 'RemoteImage';
                                src: string;
                                isFullBleed?: boolean | null;
                            };
                      outlineIcon?:
                          | {
                                __typename: 'InternalIcon';
                                name: any;
                            }
                          | {
                                __typename: 'RemoteImage';
                                src: string;
                                isFullBleed?: boolean | null;
                            }
                          | null;
                      badge?: {
                          __typename: 'M365ApplicationNumericBadge';
                          count: number;
                          countDescription: string;
                      } | null;
                      selectedStateIconOverride?:
                          | {
                                __typename: 'InternalIcon';
                                name: any;
                            }
                          | {
                                __typename: 'RemoteImage';
                                src: string;
                                isFullBleed?: boolean | null;
                            }
                          | null;
                      secondaryActions?: Array<{
                          __typename?: 'AppSecondaryAction';
                          id: string;
                          text: string;
                          eventType: string;
                          eventData: string;
                          icon?: {
                              __typename?: 'InternalIcon';
                              name: any;
                          } | null;
                      }> | null;
                  };
        }>;
        appAcquisitionLinks?: Array<{
            __typename?: 'AppAcquisitionLink';
            id: string;
            text: string;
            accessibleName?: string | null;
            icon?: {
                __typename?: 'InternalIcon';
                name: any;
            } | null;
        } | null> | null;
    };
};
export const MessageExtensionsFlyoutDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'MessageExtensionsFlyout' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'messageExtensionType' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'M365AppFilter' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchQuery' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'm365MessageExtensions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'messageExtensionType' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'messageExtensionType' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'searchQuery' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'searchQuery' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'platformType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isInUse',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'brandingColor',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'lockedIndex',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'pinnedIndex',
                                                            },
                                                        },
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'LaunchTile_fragment',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'appAcquisitionLinks' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'text' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'accessibleName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'icon' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...LaunchTile_FragmentFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<MessageExtensionsFlyoutQuery, MessageExtensionsFlyoutQueryVariables>;
