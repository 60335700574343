import { default as getSelectedSearchScope } from './getSelectedSearchScope';
import getSearchScopeDisplayName from 'owa-mail-search-utils/lib/utils/getSearchScopeDisplayName';
import { getStore } from '../store/store';

export default function getSelectedSearchScopeDisplayName(): string | null {
    if (!getStore().initialSearchScope) {
        return null;
    }

    const selectedSearchScope = getSelectedSearchScope();
    const selectedFolderScope = getSearchScopeDisplayName(
        selectedSearchScope,
        getStore().selectedMailboxInfo,
        true /* isSearchFromFolderScope*/
    );

    return selectedFolderScope;
}
