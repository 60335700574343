import type VirtualizedGroupHeader from '../type/VirtualizedGroupHeader';
import virtualizedMailGroupHeadersStore from '../store/store';
import { mutatorAction } from 'satcheljs';
import type { GroupHeaderId } from '../type/GroupHeaderId';

// Toggles collapsed status for header
export default mutatorAction(
    'toggleVirtualizedGroupHeaderCollapse',
    function toggleVirtualizedGroupHeaderCollapse(headerName: GroupHeaderId) {
        const groupHeaderForToggle: VirtualizedGroupHeader | undefined =
            virtualizedMailGroupHeadersStore().virtualizedGroupHeaders.get(headerName);
        if (groupHeaderForToggle) {
            groupHeaderForToggle.isCollapsed = !groupHeaderForToggle.isCollapsed;
        }
    }
);
