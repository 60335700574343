import { getQueryStringParameters } from 'owa-querystring';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Deprecating getPhysicalRing.ts
 *	> 'isDogfoodEnv' import from 'owa-metatags' is restricted. This value is resolved in ECS as a filter. Please create a feature flight if possible. */
import { isDogfoodEnv } from 'owa-metatags';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import type { MailboxInfo } from 'owa-client-types';
import getReportSubmissionPolicy from './getReportSubmissionPolicy';

/**
 * Checks if post-report dialogs are enabled for the user
 *
 * @param mailboxInfo mailboxInfo of the mailbox that the user is reporting from
 * @returns boolean representing whether the post-report dialog should be shown
 */
export default function isPostReportDialogEnabled(mailboxInfo: MailboxInfo): boolean {
    // Allow "postreport" query string parameter to override value in SDF
    if (isDogfoodEnv() && getQueryStringParameters().postreport) {
        return getQueryStringParameters().postreport === 'true';
    }

    // Post-report dialogs are disabled for consumer users
    if (isConsumer(undefined, mailboxInfo)) {
        return false;
    }

    // Return admin setting as they control post-report dialog visibility
    return getReportSubmissionPolicy(mailboxInfo).PostSubmitMessageEnabled;
}
