import { useState, useEffect } from 'react';
import { isWin10, isWin11, isMac } from 'owa-user-agent';

export type OperatingSystemName = 'Windows' | 'Mac' | null;

// Custom Hook to get the operating system of the user
export default function useOperatingSystemName(): OperatingSystemName {
    /* eslint-disable-next-line owa-custom-rules/prefer-react-state-without-arrays-or-objects -- (https://aka.ms/OWALintWiki)
     * Please remove the array or object from React.useState() or leave a justification in case is not possible to do so.
     *	> It is preferable not to use arrays or objects as react state, use primitive data types, useReducer or satchel state instead, if its possible. */
    const [os, setOs] = useState<OperatingSystemName>(null);

    useEffect(() => {
        const getOperatingSystem = async () => {
            if (isWin10() || (await isWin11())) {
                setOs('Windows');
            } else if (isMac()) {
                setOs('Mac');
            }
        };
        getOperatingSystem();
    }, []);
    return os;
}
