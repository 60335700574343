import { type MailRibbonGroupId, type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';

import GroupStrings from 'owa-locstrings/lib/strings/ribbon_mail_groups.locstring.json';
import type { MenuDefinition } from '@1js/acui-menu/lib/components/MenuDefinition';
import NoteEdit from 'owa-fluent-icons-svg/lib/icons/NoteEditRegular';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { RibbonFlyoutAnchorProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon';
import type { RibbonGroupId } from 'owa-ribbon-ids';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip } from 'owa-command-ribbon';
import { createMailAppFlyoutAnchor } from 'owa-mail-ribbon-utils/lib/createMailRibbonButton';
import loc from 'owa-localize';

const emptyMenuDefinition: MenuDefinition = {
    sections: [],
};

export const getMLRNotesTabCommonGroupFlyout = owaComputedFn(
    function getMLRNotesTabCommonGroupFlyout(
        groupId: RibbonGroupId,
        props: ReadOnlyRibbonControlDefProps
    ): RibbonFlyoutAnchorProps | undefined {
        switch (groupId) {
            case 145:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.notes_GroupEdit),
                    undefined /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    constructKeytip([6], 145),
                    NoteEdit
                );

            default:
                return undefined;
        }
    }
);
