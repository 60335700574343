import { mutatorAction } from 'satcheljs';
import { getGroupActionState } from '../selectors/joinButtonStoreSelectors';
import type ActionState from '../schema/ActionState';
import type { MailboxInfo } from 'owa-client-types';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

export const setJoinButtonState = mutatorAction(
    'setJoinButtonState',
    function setJoinButtonState(
        mailboxInfo: MailboxInfo,
        groupSmtpAddress: string,
        newState: ActionState
    ) {
        const groupActionState = getGroupActionState(mailboxInfo);
        groupActionState.set(transformGroupSmtp(groupSmtpAddress), newState);
    }
);

export default setJoinButtonState;
