import { getStore } from '../store/store';
import { ATTACHMENTS_SEARCH_FOLDER_DISTINGUISH_NAME } from '../utils/constants';

export function isAttachmentsSearchFolder(folderId: string): boolean {
    const store = getStore();

    return (
        store.defaultFolderIdToNameMap?.get(folderId) === ATTACHMENTS_SEARCH_FOLDER_DISTINGUISH_NAME
    );
}
