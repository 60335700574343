import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { getFromStoreOrLocalStorageOrStartupData } from '../utils/getFromStoreOrLocalStorageOrStartupData';
import { isAnalyzeContentAllowedByPolicy } from './isAnalyzeContentAllowedByPolicy';
import { isPrivacyEnabled } from '../utils/isPrivacyRoamingEnabled';

export function isAnalyzeContentDisabledForPrivacy(): boolean {
    if (!isPrivacyEnabled()) {
        return false;
    }

    return isConsumer()
        ? !getFromStoreOrLocalStorageOrStartupData('analyzeContentEnabled')
        : !isAnalyzeContentAllowedByPolicy();
}
