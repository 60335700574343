import { lazyGetMessagesSections } from 'owa-mail-ribbon-sections/lib/sections';

import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onMessagesMenuClicked = (props: ReadOnlyRibbonControlDefProps) => {
    lazyGetMessagesSections.import().then(getMessagesSections => {
        setMenuDefinition('MessagesMenuDefinitionStore', undefined /* projectionTabId */, () => {
            return {
                sections: getMessagesSections(3, props),
            };
        });
    });
};
