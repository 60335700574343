import type { MailboxInfo } from 'owa-client-types';
import {
    copilotSettingsStore,
    lazyFetchCopilotSettings,
    FetchState,
    type ImplementedCopilotFeatures,
    type CopilotSettingsStore,
} from 'owa-copilot-settings-store';
import { toJS } from 'mobx';
import { type DatapointVariant, logUsage } from 'owa-analytics';
import { getCurrentLanguage } from 'owa-localize';

type GenericEnablementCheckFunction<T extends ImplementedCopilotFeatures, K> = (
    store: CopilotSettingsStore,
    language: string,
    scenario: T,
    mailboxInfo: MailboxInfo,
    skipLanguageCheck?: boolean
) => K;

export default function createStoreCheckForGenericEnablement<
    T extends ImplementedCopilotFeatures,
    K
>(
    funcName: string,
    checkFunc: GenericEnablementCheckFunction<T, K>
): (scenario: T, mailboxInfo: MailboxInfo, skipLanguageCheck?: boolean) => K | undefined {
    return (scenario: T, mailboxInfo: MailboxInfo, skipLanguageCheck?: boolean) => {
        const language = getCurrentLanguage();
        const store = copilotSettingsStore(mailboxInfo);
        switch (store.fetchState) {
            case FetchState.NOT_STARTED:
                // Make sure we do this in a separate microtask as this will call a mutator
                // and this getter should not call mutators to avoid mutators being called
                // within mutators
                Promise.resolve().then(() => {
                    lazyFetchCopilotSettings.importAndExecute(mailboxInfo);
                });
            /* falls through */
            case FetchState.IN_PROGRESS:
                logUsage(
                    'Copilot_CheckedBeforeStorePopulated',
                    {
                        funcName,
                        scenario_1: scenario,
                        state_2: toJS(store.fetchState),
                    },
                    {
                        variant: 2,
                    }
                );
                return undefined; //Need a separate undefined state when service call is in progress or not started so that perf optimizations can be made in the calling code
            case FetchState.SUCCEEDED:
            case FetchState.FAILED:
                return checkFunc(store, language, scenario, mailboxInfo, skipLanguageCheck);
        }
    };
}
