import type MailboxRequestOptions from 'owa-service/lib/MailboxRequestOptions';
import type RequestOptions from 'owa-service/lib/RequestOptions';
import { setHeaders } from 'owa-headers-core';
import { errorThatWillCauseAlert } from 'owa-trace';
import type { MailboxProvider } from 'owa-client-types';

function getHeaderForProvider(
    userIdentity: string,
    mailboxProvider: MailboxProvider | undefined | null
): string | undefined {
    if (!userIdentity) {
        return undefined;
    }

    switch (mailboxProvider) {
        case 'Office365':
            return `AAD-UPN:${userIdentity}`;

        case 'Outlook':
            return `MSA:${userIdentity}`;

        default:
            return undefined;
    }
}

export function getUserPrincipalRequestOptions(
    mailboxRequestOptions: MailboxRequestOptions
): RequestOptions {
    const mailboxInfo = mailboxRequestOptions.mailboxInfo;
    const anchorMailbox = getHeaderForProvider(
        mailboxInfo.userIdentity,
        mailboxInfo.mailboxProvider
    );

    if (!anchorMailbox) {
        errorThatWillCauseAlert(
            'getUserPrincipalRequestOptions: userIdentity and first party mailbox provider must be passed for UserPrincipalName MailboxInfo'
        );
        throw new Error('userIdentity and first party mailbox provider must be passed');
    }

    const req: RequestOptions = mailboxRequestOptions;
    const headers = req.headers?.set ? new Headers(<Headers>req.headers) : new Headers();
    req.datapoint = req.datapoint || {};

    setHeaders(headers, {
        'X-AnchorMailbox': anchorMailbox,
    });

    return {
        ...req,
        headers,
    };
}
