import { format } from 'owa-localize';
import { localizedFormatter } from './localizedFormatter';
import { getLocalizedString } from 'owa-datetime-formatters-core';
import isOneSpecifierBeforeTheOther from './isOneSpecifierBeforeTheOther';

/**
 * This function produces a format similar to getMonthDayFormat but ignores user settings
 * and hardcodes an abbreviated month and the day without leading zeros.
 * This should be used in places where space is at a premium.
 * Common output is 'MMM d' or 'd MMM'.
 */
export function getShortMonthDayFormat(dateFormat: string, _timeFormat: string) {
    const dayFirst = isOneSpecifierBeforeTheOther(dateFormat, 'd', 'M');
    const formatString = dayFirst
        ? getLocalizedString('monthDayFormatDayFirst')
        : getLocalizedString('monthDayFormatMonthFirst');
    return format(formatString, getLocalizedString('MMM'), getLocalizedString('d'));
}

export default localizedFormatter(getShortMonthDayFormat);
