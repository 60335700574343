// These two enums were thrown together by looking at the existing Universal
// implementation.  We need to go back and make sure these values are complete
// and the correct.

import type { RokuyouDay } from './RokuyouDay';

export enum GlobalCalendarType {
    Gregorian = 'gregory',
    Hebrew = 'hebrew',
    Hijri = 'islamic',
    UmmAlQuara = 'islamic-umalqura',
    SakaEra = 'indian',
    JapaneseLunar = 'JapaneseLunar',
    Rokuyou = 'Rokuyou',
    ChineseLunar = 'chinese',
    Persian = 'persian',
    ThaiBuddhist = 'buddhist',
    KoreanLunar = 'KoreanLunar',
}

export enum GlobalCalendarLanguage {
    English = 'en',
    Japanese = 'ja',
    ChineseSimplified = 'zh-cn',
    ChineseTraditional = 'zh-tw',
    Arabic = 'ar',
    Persian = 'fa',
    Thai = 'th',
    Korean = 'ko',
    Hebrew = 'he',
    Hindi = 'hi',
}
export class GlobalCalendarLocale {
    constructor(
        calendarType: GlobalCalendarType,
        calendarLanguage: GlobalCalendarLanguage,
        hijriOffset?: number,
        rokuyouDays?: Set<RokuyouDay>
    ) {
        this._calendarType = calendarType;
        this._calendarLanguage = calendarLanguage;
        this._hijriOffset = hijriOffset ?? 0;
        this._rokuyouDays = rokuyouDays ?? new Set<RokuyouDay>();
    }

    get calendarType(): GlobalCalendarType {
        return this._calendarType;
    }

    get calendarLanguage(): GlobalCalendarLanguage {
        return this._calendarLanguage;
    }

    get hijriOffset(): number {
        return this._hijriOffset;
    }

    get rokuyouDays(): Set<RokuyouDay> {
        return this._rokuyouDays;
    }

    // Generate an IETF language tag.  The "u" indicates an extension and the "ca" indicates
    // that the extension is specifying the calendar type, and "nu" is the numbering system.
    toString(): string {
        return this._calendarLanguage + '-u-ca-' + this._calendarType;
    }

    private _calendarType: GlobalCalendarType;
    private _calendarLanguage: GlobalCalendarLanguage;
    private _hijriOffset: number;
    private _rokuyouDays: Set<RokuyouDay>;
}
