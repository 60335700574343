import type { MailboxInfo } from 'owa-client-types';
import { action } from 'satcheljs';
import { updateSelectedCalendarsInStore, addSelectedCalendarInStore } from './internalActions';
import { updateSelectedCalendarsState } from '../services/updateSelectedCalendarsState';
import { getSelectedCalendarsForUser } from '../selectors/getSelectedCalendarsForUser';

export const updateSelectedCalendars = (calendarIds: string[], mailboxInfo: MailboxInfo) => {
    updateSelectedCalendarsInStore(calendarIds, mailboxInfo);
    selectedCalendarsUpdated(calendarIds, mailboxInfo);
    updateSelectedCalendarsState(calendarIds, mailboxInfo);
};

export const addSelectedCalendar = (calendarId: string, mailboxInfo: MailboxInfo) => {
    addSelectedCalendarInStore(calendarId, mailboxInfo);
    const updatedCalendars = getSelectedCalendarsForUser(mailboxInfo);
    selectedCalendarsUpdated(updatedCalendars, mailboxInfo);
    updateSelectedCalendarsState(updatedCalendars, mailboxInfo);
};

export const selectedCalendarsUpdated = action(
    'SELECTED_CALENDARS_UPDATED',
    (calendarIds: string[], mailboxInfo: MailboxInfo) => ({ calendarIds, mailboxInfo })
);

export const refreshImmutableSelectedCalendars = action(
    'REFRESH_IMMUTABLE_SELECTED_CALENDARS',
    (mailboxInfo: MailboxInfo) => ({ mailboxInfo })
);
