import { Network, Observable, RecordSource, Environment, Store } from 'relay-runtime';
import type {
    GraphQLResponse,
    RequestParameters,
    Variables,
    CacheConfig,
    UploadableMap,
} from 'relay-runtime';
import type {
    LegacyObserver,
    SubscribeFunction,
} from 'relay-runtime/lib/network/RelayNetworkTypes';
import { gql } from 'graphql-tag';
import { ApolloLink } from '@apollo/client';
import type { GraphQLRequest } from '@apollo/client';
import { debugErrorThatWillShowErrorPopupOnly, errorThatWillCauseAlertAndThrow } from 'owa-trace';
import type { ResolverContext } from 'owa-graph-schema';

let environment: Environment;
let mainLink: ApolloLink;
let globalContext: Partial<ResolverContext>;

export function initializeRelayEnvironment({
    links,
    context,
}: {
    links: Array<ApolloLink>;
    context: Partial<ResolverContext>;
}) {
    mainLink = ApolloLink.from(links);
    globalContext = context;

    const store = new Store(new RecordSource());

    environment = new Environment({
        network: Network.create(fetcher, subscription as SubscribeFunction),
        store,
    });

    return environment;
}

const subscription = (
    request: RequestParameters,
    variables: Variables,
    cacheConfig: CacheConfig,
    observer?: LegacyObserver<GraphQLResponse>
): Observable<GraphQLResponse> | Disposable => {
    return Observable.create<GraphQLResponse>(outer => {
        const innerObserver = {
            next: (data: any) => {
                outer.next(data);
                observer?.onNext?.(data);
            },
            complete: () => {
                outer.complete();
                observer?.onCompleted?.();
            },
            error: (e: any) => {
                debugErrorThatWillShowErrorPopupOnly('Relay subscription error', e);
                outer.error(e);
                observer?.onError?.(e);
            },
        };

        const apolloRequest = createApolloRequest(request, variables, cacheConfig);
        ApolloLink.execute(mainLink, apolloRequest).subscribe(innerObserver);
    });
};

const fetcher = (
    request: RequestParameters,
    variables: Variables,
    cacheConfig: CacheConfig,
    _uploadables?: UploadableMap | null
) => {
    return Observable.create<GraphQLResponse>(outer => {
        const innerObserver = {
            next: (data: any) => {
                outer.next(data);
            },
            complete: () => {
                outer.complete();
            },
            error: (e: any) => {
                debugErrorThatWillShowErrorPopupOnly('Relay fetcher error', e);
                outer.error(e);
            },
        };

        const apolloRequest = createApolloRequest(request, variables, cacheConfig);

        ApolloLink.execute(mainLink, apolloRequest).subscribe(innerObserver);
    });
};

export function getEnvironment() {
    if (!environment) {
        return errorThatWillCauseAlertAndThrow('Relay envrionment is not initialized');
    }

    return environment;
}

function createApolloRequest(
    request: RequestParameters,
    variables: Variables,
    cacheConfig: CacheConfig
): GraphQLRequest {
    const operation = {
        query: gql(request.text || ''),
        variables,
        operationName: request.name,
        extensions: {},
        context: Object.assign({}, globalContext, cacheConfig?.metadata?.context || {}),
    };

    return operation;
}
