import { getStore } from '../store/store';
import { mutatorAction } from 'satcheljs';
import type { MailRibbonConfigStore } from 'owa-mail-ribbon-store-shared-types';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Deep copy the new ribbon. Otherwise, these objects are shallow copied and points at
 * either defaultConfig or OWS's ViewStateConfiguration's MailRibbonConfig.
 */
export default mutatorAction(
    'initializeMailRibbonConfiguration',
    (ribbonConfig: MailRibbonConfigStore, mailboxInfo: MailboxInfo) => {
        const store = getStore(mailboxInfo);
        store.ribbonConfig.multiline = JSON.parse(JSON.stringify(ribbonConfig.multiline));
        store.ribbonConfig.singleline = JSON.parse(JSON.stringify(ribbonConfig.singleline));
        store.ribbonConfig.version = ribbonConfig.version; // Not an object, no need to JSON-deep copy.
        store.hasLoaded = true;
    }
);
