/**
 * Because there can be multiple simultaneous compose sessions, we need to
 * differentiate between button actions.  We do this by tacking on the current
 * (and unique) editorId
 * @param baseId is the button designation, often a RibbonId (number) but sometimes a string
 * @param editorId is the id of current EditorViewState
 * @returns a unique id for the button, as described above.
 */
export const getComposeRibbonId = (
    baseId: number | string,
    editorId: string | undefined
): number | string => {
    return editorId ? `${baseId}_${editorId}` : baseId;
};
