import React from 'react';
import { ImageIcon } from '@fluentui/react/lib/Icon';
import { registerIcons, unregisterIcons } from '@fluentui/style-utilities';
import type { AddinCommandSurfaceItem } from 'owa-addins-types';

const AddinIcons = new Set<string>();

const preventContextMenu = (ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
};

export function getAddinIcon(addIn: AddinCommandSurfaceItem): string | undefined {
    const encodeRegisterKey = (surfaceAddIn: AddinCommandSurfaceItem) =>
        `AddIn_${surfaceAddIn?.name?.replace(/\s/g, '_')}`;
    const iconName = encodeRegisterKey(addIn);

    if (!AddinIcons.has(iconName)) {
        if (!addIn?.iconProps || !addIn.iconProps?.imageProps || !addIn.iconProps.imageProps?.src) {
            return undefined;
        }

        const Icon = () => {
            const imageProps = React.useMemo(
                () => ({
                    ...addIn.iconProps?.imageProps,
                    // Sensible overrides for the image icons to match other ribbon's font icon buttons.
                    width: '85%',
                    height: '85%',
                }),
                []
            );

            React.useEffect(
                () => () => {
                    // Unregister the icon when the component unmounts to prevent leaks.
                    unregisterIcons([iconName]);
                    AddinIcons.delete(iconName);
                },
                []
            );

            return <ImageIcon imageProps={imageProps} onContextMenu={preventContextMenu} />;
        };

        registerIcons({
            icons: {
                [iconName]: <Icon />,
            },
        });
        AddinIcons.add(iconName);
    }

    return iconName;
}
