import type SuggestedUnsubscribeReason from '../store/schema/SuggestedUnsubscribeReason';
import { reasonOftenUnread, reasonTooMuchMail } from './suggestedUnsubscribeReasons.locstring.json';
import loc from 'owa-localize';

/**
 * Get suggested unsubscribe reason string
 *
 * @param suggestedUnsubscribeReason The reason mapped to the suggested unsubscribe item
 * @returns The string that describes the suggestion reason
 */
const getSuggestedUnsubscribeReasonString = (
    suggestedUnsubscribeReason: SuggestedUnsubscribeReason | undefined
): string => {
    switch (suggestedUnsubscribeReason) {
        case 0:
            return loc(reasonOftenUnread);
        case 1:
        default:
            return loc(reasonTooMuchMail);
    }
};

export default getSuggestedUnsubscribeReasonString;
