import {
    lazyDeleteMailListRows,
    lazyArchiveMailListRows,
    lazyDeleteItemsBasedOnNodeOrThreadIds,
} from 'owa-mail-triage-action';
import { getStore as getListViewStore } from 'owa-mail-list-store';
import type { ActionSource } from 'owa-analytics-types';
import { resetFocus } from 'owa-mail-focus-manager';
import { lazyShouldSkipRequestToServerDueToPolicy } from 'owa-mail-retention-policies';
import { showActionRestrictionDueToPolicy } from 'owa-mail-retention-policies/lib/actions/showActionRestrictionDueToPolicy';
import { tryValidateDumpsterQuota } from 'owa-storage-store/lib/util/tryValidateDumpsterQuota';
import { getMailboxInfoFromFolderId } from 'owa-mail-mailboxinfo/lib/getMailboxInfo';
import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';

// Return the correct handler for the action/ item type. Currently item part only supports big hover delete.
export function getClickHandler(action: string, isItemPart?: boolean) {
    switch (action) {
        case 'Delete':
            return isItemPart ? deleteItemPartRow : deleteRow;
        case 'Archive':
            return archiveRow;
    }
    return null;
}

export const deleteRow = async (
    rowKey: string,
    tableViewId: string,
    hoverActionSource: ActionSource
) => {
    const tableView = getListViewStore().tableViews.get(tableViewId);
    if (tableView && (await shouldDelete(tableView, false /* isItemPart */, [rowKey]))) {
        await lazyDeleteMailListRows.importAndExecute(
            [rowKey],
            tableViewId,
            false /*isExplicitSoftDelete*/,
            hoverActionSource
        );
    }
};

export const archiveRow = async (
    rowKey: string,
    tableViewId: string,
    hoverActionSource: ActionSource
) => {
    await lazyArchiveMailListRows.importAndExecute(
        [rowKey],
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2345 (40,9): Argument of type 'TableView | undefined' is not assignable to parameter of type 'TableView'.
        // @ts-expect-error
        getListViewStore().tableViews.get(tableViewId),
        hoverActionSource
    );
};

export const deleteItemPartRow = async (
    tableViewId: string,
    nodeId: string,
    hoverActionSource: ActionSource
) => {
    const tableView = getListViewStore().tableViews.get(tableViewId);
    if (tableView && (await shouldDelete(tableView, true /* isItemPart */, [nodeId]))) {
        const deleteItemsBasedOnNodeOrThreadIds =
            await lazyDeleteItemsBasedOnNodeOrThreadIds.import();
        deleteItemsBasedOnNodeOrThreadIds([nodeId], tableView.id, hoverActionSource);
    }

    // Reset focus after the lazy action is complete, to ensure that focus is set
    // after any confirm dialogs are dismissed and the stitch has been raised
    resetFocus('mailListItemDelete');
};

const shouldDelete = async (tableView: TableView, isItemPart: boolean, idsToActOn: string[]) => {
    if (tableView) {
        const folderId = tableView.tableQuery.folderId;
        if (tryValidateDumpsterQuota(getMailboxInfoFromFolderId(folderId), folderId)) {
            return false;
        }

        const shouldSkipRequestToServerDueToPolicy =
            await lazyShouldSkipRequestToServerDueToPolicy.import();
        if (shouldSkipRequestToServerDueToPolicy(tableView, isItemPart, idsToActOn)) {
            showActionRestrictionDueToPolicy('Delete');
            return false;
        }

        // Allow delete if no checks fail
        return true;
    }

    // Block delete if no table view is found
    return false;
};
