import React from 'react';
import type { FluentProviderProps } from '@fluentui/react-provider';
import { addClass, removeClass } from 'owa-griffel-utils';
import { observer } from 'owa-mobx-react';
import { useHeadlessFluentProviderThemeStyleTag } from './useHeadlessFluentProviderThemeStyleTag';

export const FluentThemeWrapper = observer(function FluentThemeWrapper({
    theme,
    targetDocument = window.document,
    children,
}: Pick<FluentProviderProps, 'theme' | 'targetDocument' | 'children'>) {
    const { styleTagId: className, insertStyleTag } = useHeadlessFluentProviderThemeStyleTag({
        theme,
        targetDocument,
        rendererAttributes: {},
    });

    React.useLayoutEffect((): void | (() => void) => {
        if (insertStyleTag && className) {
            addClass(targetDocument.documentElement, className);

            return () => {
                removeClass(targetDocument.documentElement, className);
            };
        }
    }, [targetDocument, className, insertStyleTag]);

    return <>{children}</>;
},
'FluentThemeWrapper');
