import mailStore from 'owa-mail-store/lib/store/Store';

export default function getConversationNodeId(conversationId: string, itemId: string): string {
    if (mailStore.conversations.has(conversationId)) {
        const conversationItemParts = mailStore.conversations.get(conversationId);
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2532 (9,42): Object is possibly 'undefined'.
        // @ts-expect-error
        for (const conversationNodeId of conversationItemParts.conversationNodeIds) {
            const conversationNode = mailStore.conversationNodes.get(conversationNodeId);
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2532 (14,30): Object is possibly 'undefined'.
            // @ts-expect-error
            for (const id of conversationNode.itemIds) {
                if (id == itemId) {
                    return conversationNodeId;
                }
            }
        }
    }
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (24,5): Type 'null' is not assignable to type 'string'.
    // @ts-expect-error
    return null;
}
