import type { FieldPolicy } from '@apollo/client';
import { makeVar } from '@apollo/client';
// Using autorun to mirror string values into reactive variables
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * 'autorun' import from 'owa-mobx-react' is restricted.*/
import { autorun } from 'owa-mobx-react';

export function createObservedTypePolicy<T>(source: string, getObserved: () => T): FieldPolicy {
    const reactiveVar = makeVar(getObserved());
    autorun(`createObservedTypePolicy-${source}`, () => {
        const observedValue = getObserved();
        // Trigger to broadcast apollo state changes incoming from satchel changes
        reactiveVar(observedValue);
    });
    return {
        read() {
            return reactiveVar();
        },
    };
}
