import type TabViewState from '../store/schema/TabViewState';
import { type TabState } from '../store/schema/TabViewState';
import activateTab from './activateTab';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';
import { getStore } from '../store/tabStore';

function getNextVisibleTab(tab: TabViewState): TabViewState | null {
    const tabs = getStore().tabs;
    const startIndex = Math.max(tabs.indexOf(tab), 0);
    const nextActiveTab = tabs.filter(t => t.id == tab.parentTabId && t.state == 1)[0];

    if (nextActiveTab) {
        return nextActiveTab;
    }

    for (let i = startIndex; i < tabs.length; i++) {
        if (tabs[i].state == 1) {
            return tabs[i];
        }
    }

    for (let i = startIndex - 1; i >= 0; i--) {
        if (tabs[i].state == 1) {
            return tabs[i];
        }
    }

    return null;
}

export default action('setTabIsShown')(function (viewState: TabViewState, isShown: boolean) {
    switch (viewState.state) {
        case 0:
            if (isShown) {
                viewState.state = 1;
                viewState.blink = true;
            }
            break;

        case 1:
        case 2:
        case 3:
            if (!isShown) {
                const nextActiveTab = viewState.state == 2 && getNextVisibleTab(viewState);

                if (nextActiveTab) {
                    activateTab(nextActiveTab);
                }

                viewState.state = 0;
            }
            break;
    }
});
