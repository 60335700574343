import { ContextualMenuItemType } from '@fluentui/react/lib/ContextualMenu';
import {
    changeTheViewContextMenuOption,
    densitySectionLabel,
    messageHeightSectionLabel,
    multipleRowsLabel,
    autoSwitchRowStyleLabel,
} from './DisplayDensityMenuItem.locstring.json';
import type { IContextualMenuProps, IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import ReadingPanePosition from 'owa-session-store/lib/store/schema/ReadingPanePosition';
import { getFallbackValueIfNull } from 'owa-options-core';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import store from 'owa-reading-pane-option/lib/store/store';
import {
    options_density_modeCompact,
    options_density_modeRoomy,
    options_density_modeCozy,
} from './strings.locstring.json';
import loc from 'owa-localize';
import saveDensityOptionQuickSettings from './actions/saveDensityOptionQuickSettings';
import getDensityOptionState from './store/store';
import {
    getStore as getMailListLayoutStore,
    setShowSingleLineWithRightReadingPane,
} from 'owa-mail-layout';
import { displayDensityOptionSelected } from './actions/internalActions';
import type DisplayDensityMode from 'owa-service/lib/contract/DisplayDensityMode';
import type { ResourceId } from 'owa-localize';

export default function getDisplayDensityMenuItem(): IContextualMenuItem {
    const displayDensityMenuItem = {
        key: 'MessageHeight',
        name: loc(changeTheViewContextMenuOption),
        subMenuProps: getSubMenuProps(),
    };

    return displayDensityMenuItem;
}

function getSubMenuProps(): IContextualMenuProps {
    return { items: [getDensityModeSubMenuItem(), getMessageHeightSubMenuItem()] };
}

function getDensityModeSubMenuItem(): IContextualMenuItem {
    const createDensitySubMenuItem = (
        densityMode: string,
        title: ResourceId
    ): IContextualMenuItem => {
        const onClick = () => {
            onClickDensityModeString(densityMode);
        };

        const optionState = getDensityOptionState();
        const densityModeSelected = getFallbackValueIfNull(
            optionState.densityModeSelected,
            getUserConfiguration().UserOptions?.DisplayDensityMode
        );

        return {
            key: densityMode,
            text: loc(title),
            canCheck: true,
            isChecked: `${densityModeSelected}` === densityMode,
            onClick,
        };
    };

    const densityMenuItem: IContextualMenuItem = {
        key: 'DensitySection',
        itemType: ContextualMenuItemType.Section,
        sectionProps: {
            bottomDivider: true,
            title: loc(densitySectionLabel),
            items: [
                createDensitySubMenuItem('Full', options_density_modeRoomy),
                createDensitySubMenuItem('Simple', options_density_modeCozy),
                createDensitySubMenuItem('Compact', options_density_modeCompact),
            ],
        },
    };

    return densityMenuItem;
}

const onClickDensityModeString = (densityMode: string) => {
    displayDensityOptionSelected(densityMode as DisplayDensityMode);
    saveDensityOptionQuickSettings();
};

function getMessageHeightSubMenuItem(): IContextualMenuItem {
    const userConfig = getUserConfiguration();
    const userOptions = userConfig.UserOptions;
    const readingPanePosition = `${getFallbackValueIfNull(
        store.readingPanePosition,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2532 (114,9): Object is possibly 'undefined'.
        // @ts-expect-error
        userOptions.GlobalReadingPanePositionReact
    )}`;

    const disableMessageListOptions = readingPanePosition !== `${ReadingPanePosition.Right}`;

    const optionState = getDensityOptionState();
    const messageLineOptionSelected = getFallbackValueIfNull(
        optionState.useSingleLineMessageStyle,
        getMailListLayoutStore().useSingleLineMessageListWithRightReadingPane
    );

    const messageHeightMenuItem: IContextualMenuItem = {
        key: 'MessageHeightSection',
        itemType: ContextualMenuItemType.Section,
        sectionProps: {
            title: loc(messageHeightSectionLabel),
            items: [
                {
                    key: 'ThreeLineOnly',
                    text: loc(multipleRowsLabel),
                    canCheck: true,
                    isChecked: !messageLineOptionSelected,
                    onClick: () => {
                        setShowSingleLineWithRightReadingPane(false);
                    },
                    disabled: disableMessageListOptions,
                },
                {
                    key: 'SwitchToSLV',
                    text: loc(autoSwitchRowStyleLabel),
                    canCheck: true,
                    isChecked: messageLineOptionSelected,
                    onClick: () => {
                        setShowSingleLineWithRightReadingPane(true);
                    },
                    disabled: disableMessageListOptions,
                },
            ],
        },
    };

    return messageHeightMenuItem;
}
