import React from 'react';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import type { PartialTheme } from '@fluentui/react/lib/Theme';
import { observer } from 'owa-mobx-react';
import type { ReactElement, ReactNode } from 'react';
import { useCustomFluentComponentsTheme } from './useCustomFluentComponentsTheme';

interface ContextMenuThemeContextProps {
    children: ReactNode | ReactElement;
}
// The <FluentComponentsThemeContext> sets a global style to all owa fluent components.
export const FluentComponentsThemeContext = observer(function FluentComponentsThemeContext({
    children,
}: ContextMenuThemeContextProps): ReactElement {
    const customFluentComponentsTheme: PartialTheme = useCustomFluentComponentsTheme();

    return (
        <ThemeProvider as={React.Fragment} theme={customFluentComponentsTheme}>
            {children}
        </ThemeProvider>
    );
},
'FluentComponentsThemeContext');
