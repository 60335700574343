import type { M365Acquisition } from 'owa-graph-schema';

/**
 * @param acquisition `M365Acquisition`
 * @returns `true` if `acquisition` has at least one static tab element
 */
export function hasStaticTab(acquisition: M365Acquisition): boolean {
    if (
        acquisition.titleDefinition.ingestionSource === 'Hardcoded' ||
        acquisition.titleDefinition.ingestionSource === 'UserPinnedAcquisition'
    ) {
        // Hard-coded acquisitions (Mail, etc) don't have contentUrl
        return true;
    }

    return (acquisition.titleDefinition?.elementDefinitions?.staticTabs ?? []).length > 0;
}

/**
 * Verifies whether a M36 acquisition has launch page experience
 * @param acquisition `M365Acquisition`
 * @returns `true` if `acquisition` has launch page experience
 */
export function hasLaunchPage(acquisition: M365Acquisition): boolean {
    const staticTabs = acquisition.titleDefinition.elementDefinitions?.staticTabs;
    return (
        hasStaticTab(acquisition) &&
        !!staticTabs?.some(tab =>
            (tab.scopes ?? []).find(scope => scope?.toLowerCase() === 'personal')
        ) &&
        !!staticTabs?.some(staticTab => !!staticTab.contentUrl)
    );
}
