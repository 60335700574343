import type { TimePanelSource } from '../TimePanelSource';
import type { ClientItemId } from 'owa-client-ids';
import type { PanelView } from 'owa-time-panel-settings';
import { action } from 'satcheljs';
import { isTimePanelAvailable } from '../utils/isTimePanelAvailable';
import { verifyValidSource } from '../utils/verifyValidSource';
import { isTimePanelOpen, setTimePanelSource } from '../store/TimePanelBootstrapStore';
import { toggleTimePanel } from '../utils/toggleTimePanel';
/**
 * Opens Time Panel
 *
 * REMINDER: Verify panel availability first using isTimePanelAvailable util and consider implementing a graceful fallback,
 * e.g. opening deep-link to equivalent workflow in new tab, or else feature should be hidden/disabled
 */
export const openTimePanel = (source: TimePanelSource) => {
    // sanity check for feature availability
    if (!isTimePanelAvailable()) {
        return;
    }

    // noop if panel is already open
    if (isTimePanelOpen()) {
        return;
    }

    // verify source for telemetry purposes
    if (!verifyValidSource(source)) {
        return;
    }

    if (!isTimePanelOpen()) {
        setTimePanelSource(source);
    }

    toggleTimePanel();
};

/**
 * Provides an action that can be used to listen for panel-close events and optionally take action,
 * e.g. restore focus to the element or region that previously had focus
 *
 * NOTE: Consumers should use the information provided in the action message to determine whether
 *       the panel scenario is relevant to their workflow before deciding to take action.
 *
 * EXAMPLE: If the panel is invoked from Mail UpNext and later closed with UpNext source and either
 *          Calendar view (if multiple up-next events) or EventDetails view + matching clientItemId
 *          (if single up-next event), then Mail UpNext should consider restoring focus to itself
 *          when the panel is closed.
 */
export const handleTimePanelClosed = action(
    'handleTimePanelClosed',
    (
        source: TimePanelSource | null,
        sessionDuration: number | null,
        lastView: PanelView | null,
        clientItemId?: ClientItemId | null
    ) => ({
        source,
        sessionDuration,
        lastView,
        clientItemId,
    })
);
