import {
    onSearchTextChanged,
    onChangeMoveToMailboxType,
} from 'owa-mail-moveto-control/lib/actions/internalActions';
import moveToStore from 'owa-mail-moveto-control/lib/store/store';

export const resetMoveToSearch = (onEnter: boolean) => {
    // The space is necessary on exit so that if the user now opens the message list r-click
    // MOVE menu, we won't show ALL user mailbox folders
    const searchText: string = onEnter ? '' : ' ';
    onSearchTextChanged(moveToStore.findText, searchText);
    onChangeMoveToMailboxType('UserMailbox');
};
