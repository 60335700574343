import type { MailboxInfo } from 'owa-client-types';
import type { M365UserMailboxAccountSource } from 'owa-account-source-list-store';
import { isRemovedAccount, type RemovedAccount } from 'owa-account-source-list-types';
import { accountSourceDataTypeChecker } from 'owa-account-source-list-store';
import { MailboxInfoError } from '../MailboxInfoError';
import getCoprincipalAccount from './getCoprincipalAccount';

/**
 * Gets the M365 user account for the mailbox info within the coprincipal account
 * @param mailboxInfoInAccount Used to determine the coprincipal account, see getIndexerForMailboxInfo for more details
 * @returns M365 user account associated with the account
 */
export default function getUserAccount(
    mailboxInfoInAccount: MailboxInfo
): M365UserMailboxAccountSource | RemovedAccount {
    const account = getCoprincipalAccount(mailboxInfoInAccount);
    if (isRemovedAccount(account)) {
        return account;
    }

    if (!accountSourceDataTypeChecker.isM365UserMailbox(account)) {
        // This typically happens when access a coprincipal account that is a PST file, or
        // other data source that is not provided by an M365 user mailbox.
        throw new MailboxInfoError('Coprincipal account is not a user mailbox');
    }

    return account;
}
