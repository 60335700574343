import { getApolloClientInternal } from './apolloClient';
import type { StrictVariables } from 'owa-apollo-types';
import type { TypedDocumentNode, DataProxy, OperationVariables } from '@apollo/client';

export type StrictWriteQueryOptions<TData, TVariables> = Omit<
    DataProxy.WriteQueryOptions<TData, TVariables>,
    'query'
>;

export const strictWriteQuery = <
    TSchemaVars extends OperationVariables,
    TActualVars extends StrictVariables<TSchemaVars, TActualVars>,
    TData
>(
    query: TypedDocumentNode<TData, TSchemaVars>,
    options?: StrictWriteQueryOptions<TData, TActualVars>
) => {
    const client = getApolloClientInternal();
    client.writeQuery({
        ...(options as unknown as DataProxy.WriteQueryOptions<TData, TSchemaVars>),
        query,
    });
};
