import { PersonaSize } from '@fluentui/react/lib/Persona';

export const getPersonaSize = (isSingleLine: boolean, densityModeString: string) => {
    switch (densityModeString) {
        case 'compact':
            return PersonaSize.size16;
        case 'medium':
            return PersonaSize.size24;
        default:
            return isSingleLine ? PersonaSize.size28 : PersonaSize.size32;
    }
};

export const getPersonaSizeForMLRow = (isSingleLine: boolean, densityModeString: string) => {
    switch (densityModeString) {
        case 'compact':
            return isSingleLine ? PersonaSize.size16 : PersonaSize.size24;
        case 'medium':
            return isSingleLine ? PersonaSize.size24 : PersonaSize.size28;
        default:
            return isSingleLine ? PersonaSize.size28 : PersonaSize.size32;
    }
};
