import { isFeatureEnabled } from 'owa-feature-flags';
import type { ImplementedCopilotFeatures } from '../store/store';
import { isImplementedScenario } from './isImplementedScenario';
import copilotSettingsStore from '../store/store';
import { type MailboxInfo } from 'owa-client-types';

export function isScenarioExtensible(
    mailboxInfo: MailboxInfo,
    scenarioName: ImplementedCopilotFeatures
) {
    const store = copilotSettingsStore(mailboxInfo);
    const supportedScenarios = store.extensibility.settingsFromService?.supportedScenarios.map(
        scenario => isImplementedScenario(scenario)
    );
    return (
        !!supportedScenarios?.includes(scenarioName) || isFeatureEnabled('mon-copilot-ext-debug')
    );
}
