import { action } from 'satcheljs';
import addDatapointConfig, { addCoreDatapointConfig } from './addDatapointConfig';
import type { CoreDatapointConfig, DatapointConfig } from 'owa-analytics-types';
import type { ActionCreator } from 'satcheljs';

export type DatapointConfigCreator<
    T extends Record<string, unknown>,
    C extends ActionCreator<T>
> = (actionMessage: ReturnType<C>, ...args: Parameters<C>) => Partial<DatapointConfig>;

export function actionWithDatapoint(actionType: string): () => {};

export function actionWithDatapoint<T extends Record<string, unknown>, C extends ActionCreator<T>>(
    actionType: string,
    createActionMessage: C
): C;

export function actionWithDatapoint<
    T extends Record<string, unknown>,
    C extends ActionCreator<T>,
    D extends DatapointConfigCreator<T, C>
>(actionType: string, createActionMessage: C, createDatapointConfig: D): C;

export function actionWithDatapoint<
    T extends Record<string, unknown>,
    C extends ActionCreator<T>,
    D extends DatapointConfigCreator<T, C>
>(actionType: string, createActionMessage?: C, createDatapointConfig?: D): C {
    return action(actionType, ((...args: Parameters<C>) => {
        const { actionMessage, config } = getActionMessageAndDpConfig<T, C, D, DatapointConfig>(
            actionType,
            args,
            createActionMessage,
            createDatapointConfig
        );
        return addDatapointConfig(config, actionMessage);
    }) as C);
}

export function actionWithCoreDatapoint<
    T extends Record<string, unknown>,
    C extends ActionCreator<T>,
    D extends DatapointConfigCreator<T, C>
>(actionType: string, createActionMessage?: C, createDatapointConfig?: D): C {
    return action(actionType, ((...args: Parameters<C>) => {
        const { actionMessage, config } = getActionMessageAndDpConfig<T, C, D, CoreDatapointConfig>(
            actionType,
            args,
            createActionMessage,
            createDatapointConfig
        );
        return addCoreDatapointConfig(config, actionMessage);
    }) as C);
}

function getActionMessageAndDpConfig<
    T extends Record<string, unknown>,
    C extends ActionCreator<T>,
    D extends DatapointConfigCreator<T, C>,
    DC extends DatapointConfig
>(
    actionType: string,
    args: Parameters<C>,
    createActionMessage?: C,
    createDatapointConfig?: D
): {
    actionMessage: T;
    config: DC;
} {
    const actionMessage = createActionMessage ? createActionMessage(...args) : ({} as T);
    const config = (
        createDatapointConfig
            ? createDatapointConfig(actionMessage as ReturnType<C>, ...args)
            : { name: actionType }
    ) as DC;
    config.name = config.name || actionType;
    config.options = config.options || {};

    return {
        actionMessage,
        config,
    };
}
