import { setShowSingleLineViewRightReadingPaneInternal } from '../mutators/setShowSingleLineWithRightReadingPaneMutator';
import type { MailLayoutOptions } from 'owa-outlook-service-option-store';
import { lazyCreateOrUpdateOptionsForFeature } from 'owa-outlook-service-options';
import { getOptionsForFeature, OwsOptionsFeatureType } from 'owa-outlook-service-option-store';
import { setItem } from 'owa-local-storage';

const BOOTSTRAP_MAILLISTITEM_VIEW_SWAP_SETTING = 'bootstrapMailListItemViewSwapSetting';

export function setShowSingleLineWithRightReadingPane(
    useSingleLineMessageListWithRightReadingPane: boolean
) {
    // Get existing settings on server
    const userOptions = getOptionsForFeature<MailLayoutOptions>(OwsOptionsFeatureType.MailLayout);

    // Update settings on server
    lazyCreateOrUpdateOptionsForFeature.importAndExecute(OwsOptionsFeatureType.MailLayout, {
        ...userOptions,
        useSingleLineMessageListWithRightReadingPane,
    } as MailLayoutOptions);

    // Update store
    setShowSingleLineViewRightReadingPaneInternal(useSingleLineMessageListWithRightReadingPane);

    // Persist value in local storage for MailListItem preloading
    setItem(
        window,
        BOOTSTRAP_MAILLISTITEM_VIEW_SWAP_SETTING,
        useSingleLineMessageListWithRightReadingPane.toString()
    );
}
