import { createOrGetFolder } from '../orchestrators/helpers/createOrGetFolder';
import { updateFolderTable } from '../orchestrators/helpers/updateFolderTable';
import type { MailFolder } from 'owa-graph-schema';
import { addFolderToDefaultFolderMappings } from 'owa-session-store';

export default function onFindFolderSuccess(rootFolder: MailFolder, folders: MailFolder[]) {
    const createOrGetFolder_0 = (folder: MailFolder) => {
        return createOrGetFolder(folder);
    };

    updateFolderTable(undefined /* displayName */, rootFolder, folders, createOrGetFolder_0);

    const folderToAdd: MailFolder = rootFolder;
    if (folderToAdd.distinguishedFolderType) {
        addFolderToDefaultFolderMappings(
            folderToAdd.distinguishedFolderType,
            folderToAdd.id,
            folderToAdd.mailboxInfo
        );
    }
}
