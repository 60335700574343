import { observer } from 'owa-mobx-react';
import React from 'react';
import ShimmerRowThreeCol from './ShimmerRowThreeCol';
import ShimmerRowSLV from './ShimmerRowSLV';
import { getMinimumRowHeight, isSingleLineListView } from 'owa-mail-layout';
import { getDensityModeString } from 'owa-fabric-theme';

import classNames from 'owa-classnames';
import { hideScrollContainers, mailListShimmerStateContainer } from './MailListShimmerState.scss';

export interface MailListShimmerStateProps {
    heightOfView: number; // The height of the list view
}

const MailListShimmerState = observer(function MailListShimmerState(
    props: MailListShimmerStateProps
) {
    const shimmerRows = [];
    const isSingleLineViewOff = !isSingleLineListView();
    const densityModeString = getDensityModeString();

    // Calculate height of shimmer rows to add just enough rows to fill list view
    let totalHeightOfView = 0;
    const shimmerRowHeight = getMinimumRowHeight(false /* isCondensedRow */);

    // Account for padding on the first message
    // Should correspond with the paddingFirstMessage/paddingMessage classes in
    // ShimmerRowSLV.scss and ShimmerRowThreeCol.scss
    if (isSingleLineViewOff) {
        densityModeString === 'full' ? (totalHeightOfView += 6) : (totalHeightOfView += 10);
    } else {
        densityModeString === 'full' ? (totalHeightOfView += 4) : (totalHeightOfView += 6);
    }

    let i = 0;
    while (totalHeightOfView < props.heightOfView) {
        shimmerRows.push(
            isSingleLineViewOff ? (
                <ShimmerRowThreeCol key={i} isFirstMessage={i === 0} />
            ) : (
                <ShimmerRowSLV key={i} isFirstMessage={i === 0} />
            )
        );
        ++i;
        totalHeightOfView += shimmerRowHeight;
    }

    return (
        <div className={classNames(hideScrollContainers, mailListShimmerStateContainer)}>
            {shimmerRows}
        </div>
    );
},
'MailListShimmerState');
export default MailListShimmerState;
