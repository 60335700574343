import {
    AppStoreTitles,
    getLaunchPageTitles,
    getNativeTitles,
    MessageExtensionTitles,
} from './data';
import { M365AcquisitionsInvariants } from './data/M365AcquisitionsInvariants';
import { getBposNavBarData, getBposNavBarDataAsync } from 'owa-bpos-store/lib/bposNavBarData';
import { getPinnedAppBarTitles } from './pinnedApps/getPinnedAppBarTitles';
import { BootIndicatorTitle } from './data/titles/BootIndicatorTitle';
import { isMOSLaunchPagesEnabled } from './utils/isMOSLaunchPagesEnabled';
import { getAppBposData } from './utils/getAppBposData';
import { isAppBarPinnedAppsEnabled } from './pinnedApps/isAppBarPinnedAppsEnabled';
import { isMOS3AppServiceAvailable } from './utils/isMOS3AppServiceAvailable';

import type { StrictAcquisition, StrictM365AcquisitionsQuery, StrictTitle } from './types';
import type { M365AppId } from './data/M365AppId';
import type { MailboxInfo } from 'owa-client-types';
import { isConsumer, getUserConfiguration } from 'owa-session-store';
import {
    isAddinMultiAccountEnabled,
    isAppBarDisplayModeOptionsEnabled,
    getColorfulIconsEnabledOriginalValue,
    getLabelsEnabledOriginalValue,
} from 'owa-feature-flags';
import { logUsage } from 'owa-analytics';
import { getCurrentThemeId } from 'owa-theme';
import { getImportHistory } from 'owa-account-import-history/lib/getImportHistory';
import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import getGlobalSettingsAccountMailboxInfo from 'owa-account-source-list-store/lib/selectors/getGlobalSettingsAccountMailboxInfo';

// Only log once per session
let hasLoggedAppBarDisplayMode = false;

/**
 * Filter that selects which static titles should be added based on runtime invariants.
 * @param  {StrictTitle} title
 * @returns StrictTitle
 */
function filterValidApps(title: StrictTitle, mailboxInfo?: MailboxInfo): boolean {
    let shouldShowTile = false;
    const appId = title.ingestionId as M365AppId;
    const appInvariant = M365AcquisitionsInvariants[appId];
    if (M365AcquisitionsInvariants.hasOwnProperty(appId) && !!appInvariant) {
        shouldShowTile = appInvariant(mailboxInfo);
    } else {
        // User pinned 3P apps will not have invariants
        shouldShowTile =
            title.ingestionSource === 'UserPinnedAcquisition' ||
            title.ingestionSource === 'BootIndicator';
    }

    if (
        title.categories.includes('m365_app') &&
        !isMOSLaunchPagesEnabled(mailboxInfo) &&
        !getAppBposData(appId, mailboxInfo)?.Url
    ) {
        shouldShowTile = false;
    }

    return !!shouldShowTile;
}

/**
 * Memoizes so we don't unnecessarily need to re-run the filtering
 * and generating functions.
 * @param {MailboxInfo} mailboxInfo
 * @param useColorfulIcons Value of the app bar icon setting toggle when in a dirty state. When this is undefined, we will use the user's saved setting.
 */
async function getBootM365Titles(mailboxInfo?: MailboxInfo, useColorfulIcons?: boolean) {
    let bootApps: StrictTitle[] = [];
    if (bootApps.length === 0) {
        // Warm-up license data store to generate license-dependent titles.
        if (
            !getBposNavBarData(mailboxInfo) &&
            !(isConsumer(undefined, mailboxInfo) && isAddinMultiAccountEnabled())
        ) {
            await getBposNavBarDataAsync(
                'getStaticTiles',
                'worker',
                true /* ignore auth error */,
                mailboxInfo
            );
        }

        const Apps: StrictTitle[] = [
            ...AppStoreTitles,
            ...getLaunchPageTitles(mailboxInfo, useColorfulIcons),
            ...getNativeTitles(mailboxInfo, useColorfulIcons),
            ...MessageExtensionTitles,
            BootIndicatorTitle,
        ];

        // If MOS3 is unavailable, skip adding 3P app tiles. We don't want to add 3P tiles
        // to the app bar if we can't fetch the full app from MOS3 post-boot.
        if (isAppBarPinnedAppsEnabled(mailboxInfo) && isMOS3AppServiceAvailable(mailboxInfo)) {
            Apps.push(...getPinnedAppBarTitles(Apps, mailboxInfo));
        }
        bootApps = Apps.filter(app => filterValidApps(app, mailboxInfo)) as StrictTitle[];
    }

    if (isAppBarDisplayModeOptionsEnabled() && !hasLoggedAppBarDisplayMode) {
        logAppBarDisplayModeOptionSetting();
    }

    return bootApps;
}

/**
 * Returns the static acquisition list defined in data directory, filtered by each
 * title's invariant.
 * @param useColorfulIcons Value of the app bar icon setting toggle when in a dirty state. When this is undefined, we will use the user's saved setting.
 * @returns Promise
 */
async function getBootM365Acquisitions(
    mailboxInfo?: MailboxInfo,
    useColorfulIcons?: boolean
): Promise<StrictAcquisition[]> {
    const staticTitles: StrictTitle[] = await getBootM365Titles(mailboxInfo, useColorfulIcons);

    const staticAcquisitions: StrictAcquisition[] = staticTitles.map((title: StrictTitle) => ({
        __typename: 'M365Acquisition',
        appId: title.ingestionId,
        titleId: title.ingestionId,
        titleDefinition: title,
        acquisitionState: 'Acquired',
        canBeUnacquired: false,
        acquisitionContext: 'Tenant',
        manifestId: 'NA',
    }));
    return staticAcquisitions;
}

/**
 * Returns the static acquisition list in a M365Acquisitions query format.
 * @param useColorfulIcons Value of the app bar icon setting toggle when in a dirty state. When this is undefined, we will use the user's saved setting.
 * @returns Promise
 */
async function getBootM365AcquisitionsQuery(
    mailboxInfo?: MailboxInfo,
    useColorfulIcons?: boolean
): Promise<StrictM365AcquisitionsQuery> {
    return {
        __typename: 'Query',
        m365Acquisitions: {
            __typename: 'M365AcquisitionsQueryResult',
            edges: await getBootM365Acquisitions(mailboxInfo, useColorfulIcons),
            nextInterval: -1,
        },
    };
}

function logAppBarDisplayModeOptionSetting() {
    const primeSettingsItems = getAccountScopeUserSettings(getGlobalSettingsAccountMailboxInfo())
        ?.PrimeSettings?.Items;

    let colorfulIconsEnabledAtBoot = undefined;
    let showLabelsEnabledAtBoot = undefined;

    if (primeSettingsItems) {
        for (const primeSetting of primeSettingsItems) {
            if (primeSetting.Id === 'AppBarDisplayModeOptions') {
                colorfulIconsEnabledAtBoot = primeSetting.Value?.options?.[0].colorfulIconsEnabled;
                showLabelsEnabledAtBoot = primeSetting.Value?.options?.[0].labelsEnabled;
                break;
            }
        }
    }

    const colorfulIconsOriginalValue = getColorfulIconsEnabledOriginalValue();
    const showLabelsOriginalValue = getLabelsEnabledOriginalValue();

    logUsage('AppBarDisplayModeOption', {
        colorfulIconsEnabled: colorfulIconsEnabledAtBoot ?? colorfulIconsOriginalValue,
        labelsEnabled: showLabelsEnabledAtBoot ?? showLabelsOriginalValue,
        colorfulIconsEnabledExperimentValue: colorfulIconsOriginalValue,
        showLabelsEnabledExperimentValue: showLabelsOriginalValue,
        themeId: getCurrentThemeId(),
        density: getUserConfiguration().UserOptions?.DisplayDensityMode,
        legacyApp: getImportHistory().lastImportApp,
    });

    hasLoggedAppBarDisplayMode = true;
}

export { getBootM365AcquisitionsQuery, getBootM365Acquisitions, getBootM365Titles };
