import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type {
    ElaborateFilterScenarios,
    ElaborateFilterSettings,
    AddinCapabilityNames,
} from '../services/types/serviceSettingTypes';
import { ELABORATE_FILTER_SCENARIOS } from '../services/types/serviceSettingTypes';
import createDefaultScenarioOption from '../services/helpers/createDefaultScenarioOption';
import { type ElaborateSettingsCache, type ImplementedCopilotFeatures } from './store';

export const ScenarioToCapabilityMap: Map<ImplementedCopilotFeatures, AddinCapabilityNames> =
    new Map([
        ['Summarize', 'emailSummary'],
        ['Elaborate', 'emailDraft'],
        ['SuggestedDrafts', 'emailReplySuggestions'],
    ]);

const mailboxIndexerToElaborateSettings = new Map<string, ElaborateSettingsCache>();

function buildElaborateFilterOptionsDefaultMap() {
    const filterOptionMap = new Map<ElaborateFilterScenarios, ElaborateFilterSettings>();
    ELABORATE_FILTER_SCENARIOS.map(scenario => {
        filterOptionMap.set(scenario, createDefaultScenarioOption());
    });
    return filterOptionMap;
}

export function elaborateSettingsCache(mailboxInfo: MailboxInfo): ElaborateSettingsCache {
    const name = getIndexerValueForMailboxInfo(mailboxInfo) + '_elaborateSettingsCache';
    let cachedStore = mailboxIndexerToElaborateSettings.get(name);
    if (!cachedStore) {
        const defaultStore = {
            elaborateFilterOptions: buildElaborateFilterOptionsDefaultMap(),
            tonePersonalizationSeen: false,
        };
        mailboxIndexerToElaborateSettings.set(name, defaultStore);
        cachedStore = defaultStore;
    }
    return cachedStore;
}
