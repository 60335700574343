export const new_GroupButton = "m5qIw";
export const delete_GroupButton = "fuT$sc";
export const moveAndDelete_GroupButton = "bddzbe";
export const respond_GroupButton = "FC_cyd";
export const tags_GroupButton = "Xx5Rce";
export const send_GroupButton = "KX01wd";
export const sync_GroupButton = "zoFXcb";
export const offline_GroupButton = "RFodLd";
export const download_GroupButton = "E60WWb";
export const move_GroupButton = "TXvAxe";
export const find_GroupButton = "D1N5Rd";
export const addIns_GroupButton = "ZsH8qc";
export const spam_addin_GroupButton = "SbYjeb";
export const immersiveReader_GroupButton = "y0VEac";
export const window_GroupButton = "iNgypb";
export const print_GroupButton = "L31lee";
export const undo_GroupButton = "QchBMe";
export const clipboard_Group = "EuJw2c";
export const include_Group = "yieYqd";
export const encrypt_Group = "D8ICQ";
export const save_Group = "Rla0Eb";
export const sensitivity_Group = "vpekMe";
export const basicText_Group = "psYI8";
export const paragraph_Group = "aLtrTb";
export const notes_GroupNew = "XL_Gt";
export const notes_GroupEdit = "JCf_yd";
export const notes_GroupView = "_RPe3d";
export const table_GroupMerge = "wU$Hbd";
export const table_GroupInsert = "lkvIQ";
export const table_GroupStructure = "zswo2d";
export const table_GroupAlignment = "vW1zId";
export const table_GroupTableStyles = "y$GWwd";
export const viewSettings_Group = "oh2uMe";
export const viewMessages_Group = "lzCWkb";
export const viewLayout_Group = "dI5NTb";
export const tags_Group = "ZjfxX";
export const actions_Group = "bQa32c";
export const findTime_GroupButton = "wt1cWd";
export const insert_Group = "dXtdw";
export const options_Group = "ckZOjc";
export const proofing_Group = "qfEj6d";
export const loop_Group = "uyEbKd";
export const format_Group = "rP953b";
export const showField_Group = "R7FZH";
export const tracking_Group = "jL0nNc";
export const quickSteps_Group = "ahUEec";
export const themes_Group = "A49Yd";
export const styling_Group = "qnQfGe";
export const draftWithCopilot_Group = "RxC3tc";
export const draw_Undo_Group = "lUJTSb";
export const draw_Selection_Group = "lPgISd";
export const draw_Tools_Group = "OKgjAd";
export const draw_Touch_Group = "eab3Dd";
export const draw_Insert_Group = "YZKP4";
export const pictureFormatting_Group = "z$xA5b";
export const pictureText_Group = "BOab_c";
export const pictureSize_Group = "tIMyIe";
export const pictureMisc_Group = "uHV2kd";
export const voice_Group = "yNPcJb";
export const zoom_Group = "vTRmDe";
export default {new_GroupButton,delete_GroupButton,moveAndDelete_GroupButton,respond_GroupButton,tags_GroupButton,send_GroupButton,sync_GroupButton,offline_GroupButton,download_GroupButton,move_GroupButton,find_GroupButton,addIns_GroupButton,spam_addin_GroupButton,immersiveReader_GroupButton,window_GroupButton,print_GroupButton,undo_GroupButton,clipboard_Group,include_Group,encrypt_Group,save_Group,sensitivity_Group,basicText_Group,paragraph_Group,notes_GroupNew,notes_GroupEdit,notes_GroupView,table_GroupMerge,table_GroupInsert,table_GroupStructure,table_GroupAlignment,table_GroupTableStyles,viewSettings_Group,viewMessages_Group,viewLayout_Group,tags_Group,actions_Group,findTime_GroupButton,insert_Group,options_Group,proofing_Group,loop_Group,format_Group,showField_Group,tracking_Group,quickSteps_Group,themes_Group,styling_Group,draftWithCopilot_Group,draw_Undo_Group,draw_Selection_Group,draw_Tools_Group,draw_Touch_Group,draw_Insert_Group,pictureFormatting_Group,pictureText_Group,pictureSize_Group,pictureMisc_Group,voice_Group,zoom_Group};