import type { PerformanceDatapoint } from 'owa-analytics';

export let InlineModuleSwitchDatapoint: PerformanceDatapoint | null | undefined = undefined;

export function setInlineModuleSwitchDatapoint(datapoint?: PerformanceDatapoint | null) {
    InlineModuleSwitchDatapoint = datapoint;

    // Ensure we don't hold onto this reference forever
    setTimeout(() => {
        InlineModuleSwitchDatapoint = undefined;
    }, 60000);
}

export function getInlineModuleSwitchDatapoint() {
    return InlineModuleSwitchDatapoint;
}
