import type { MailFolder } from 'owa-graph-schema';

export default function getEffectiveFolderDisplayName(
    folder: Pick<MailFolder, 'displayName'> | null | undefined
): string {
    if (!folder) {
        return '';
    }
    return folder.displayName;
}
