let hasHadFocusSince = 0;

if (typeof self.document !== 'undefined') {
    self.document.addEventListener('visibilitychange', () => {
        hasHadFocusSince = isDocumentVisible() ? new Date().getTime() : Number.MAX_VALUE;
    });
}

export function hasUserWindowHadFocusSince(time: number) {
    return time >= hasHadFocusSince;
}

export function isDocumentVisible() {
    return self.document?.visibilityState == 'visible';
}
