import type { FolderForestNode } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import type FolderForestStore from './schema/FolderForestStore';
import { createStore } from 'satcheljs';

const defaultFolderForestStore: FolderForestStore = {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2352 (9,19): Conversion of type '{ id: null; treeType: "primaryFolderTree"; type: FolderForestNodeType.Folder; }' to type 'FolderForestNode' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
    // @ts-expect-error
    selectedNode: <FolderForestNode>{
        id: null,
        treeType: 'primaryFolderTree',
        type: 0,
    },
};

const folderForestStore = createStore<FolderForestStore>(
    'folderForestStore',
    defaultFolderForestStore
)();
export default folderForestStore;
export const getStore = () => folderForestStore;
