import type { TableView } from 'owa-mail-list-store';
import type MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import { action } from 'satcheljs';

/**
 * Single select mail row action
 * @param tableView where the operation is being performed
 * @param rowKey the rowKey of the item to select
 * @param isUserNavigation whether user's click or action triggered this action
 * @param mailListItemSelectionSource The source of selection on mail item
 * @param eventTimestamp The timestamp of when user clicks on a row to select it.
 */
export const singleSelectRow = action(
    'SINGLE_SELECT_ROW',
    (
        tableView: TableView,
        rowKey: string,
        isUserNavigation: boolean,
        mailListItemSelectionSource: MailListItemSelectionSource,
        eventTimestamp?: number
    ) => ({
        tableView,
        rowKey,
        isUserNavigation,
        mailListItemSelectionSource,
        eventTimestamp,
    })
);
