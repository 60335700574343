import { action, orchestrator } from 'satcheljs';
import { LazyImport, setGlobalImportStartTime } from 'owa-bundling-light';
import type { ActionMessage, ActionCreator, OrchestratorFunction } from 'satcheljs';
import type { Getter, LazyModule } from 'owa-bundling-light';

export interface LazyOrchestrator<TMessage extends ActionMessage> {
    actionCreator: ActionCreator<TMessage>;
    handler: OrchestratorFunction<TMessage>;
    cloneActionName: string;
}

export function createLazyOrchestrator<TMessage extends ActionMessage>(
    actionCreator: ActionCreator<TMessage>,
    cloneActionName: string,
    handler: OrchestratorFunction<TMessage>
): LazyOrchestrator<TMessage> {
    return {
        actionCreator,
        handler,
        cloneActionName,
    };
}

export interface LazyOrchestratorOptions {
    captureBundleTime?: boolean;
    govern?: boolean;
}

export function registerLazyOrchestrator<
    TModule,
    TMessage extends ActionMessage & {
        lazyOrchestrator?: boolean;
    }
>(
    actionCreator: ActionCreator<TMessage>,
    lazyModule: LazyModule<TModule>,
    getter: Getter<LazyOrchestrator<TMessage>, TModule>,
    options?: LazyOrchestratorOptions
) {
    let actionCreatorPromise: Promise<ActionCreator<TMessage>>;

    // Register a non-lazy orchestrator for the original action
    /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
     * Move this function to the module scope or wrapped it on a once function
     *	> Function should only be invoked in module scope */
    orchestrator(actionCreator, async actionMessage => {
        if (!actionCreatorPromise) {
            actionCreatorPromise = importAndRegisterOrchestrator();
        }

        // Dispatch the cloned action now that the lazy orchestrator is registered
        actionMessage.lazyOrchestrator = true;
        const importStartTime = options?.captureBundleTime ? Date.now() : null;
        const cloneActionCreator = await actionCreatorPromise;
        setGlobalImportStartTime(importStartTime);
        cloneActionCreator(actionMessage);
        setGlobalImportStartTime(null);
    });

    async function importAndRegisterOrchestrator() {
        // Import the lazy orchestrator
        const lazyImport = new LazyImport(lazyModule, getter, options?.govern);
        const lazyOrchestrator = await lazyImport.import('lazyOrchestrator');

        // Validate that we're subscribing to the same action the lazy orchestrator was
        // defined for.
        if (actionCreator !== lazyOrchestrator.actionCreator) {
            throw new Error('Lazy orchestrator cannot subscribe to this action.');
        }

        // Define an action creator that clones the original action (but with the new name)
        const cloneActionCreator = action(
            lazyOrchestrator.cloneActionName,
            (originalActionMessage: TMessage) => {
                const cloneActionMessage = { ...originalActionMessage };
                delete cloneActionMessage.type;
                delete cloneActionMessage.lazyOrchestrator;
                return cloneActionMessage;
            }
        );

        // Register the actual orchestrator
        /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
         * Move this function to the module scope or wrapped it on a once function
         *	> Function should only be invoked in module scope */
        orchestrator(cloneActionCreator, lazyOrchestrator.handler);
        return cloneActionCreator;
    }
}
