import type { MailboxInfo } from 'owa-client-types';
import isCopilotFeatureEnabled from './isCopilotFeatureEnabled';
import { getApplicationSettings } from 'owa-application-settings';
import { isFeatureEnabled } from 'owa-feature-flags';
import hasBizChatLicense from './hasBizChatLicense';
import hasM365CopilotLicense from './hasM365CopilotLicense';

/**
 * @param mailboxInfo Mailbox info
 * @returns true if the feature is enabled, false if the feature is disabled, undefined if the feature is in progress or not started
 */
export default function isCalendarMeetingPrepEnabled(
    mailboxInfo: MailboxInfo,
    skipLanguageCheck?: boolean
): boolean | undefined {
    try {
        const appSettings = getApplicationSettings('Copilot', mailboxInfo);
        const enableMultiLanguage = skipLanguageCheck ?? appSettings.enableMultiLanguage;

        // This is controlled through ECS to enable/disable the feature across all users
        const featureKillSwitch = appSettings.meetingPrepSummaryEnabled; //https://ecs.skype.com/Substrate/OutlookWebClient/configurations/1092852

        // if bizchat license is off, it means enterprise grounding should be off
        const isEnterpriseGroundingEnabled = hasBizChatLicense(mailboxInfo);

        const isAIX00EnabledUser = isCopilotFeatureEnabled(
            'MeetingPrepSummary',
            mailboxInfo,
            enableMultiLanguage
        );

        const isRingEnabledUser =
            isFeatureEnabled('time-copilot-meeting-prep-1js-scenario') &&
            hasM365CopilotLicense(mailboxInfo);

        return (
            featureKillSwitch &&
            isEnterpriseGroundingEnabled &&
            (isAIX00EnabledUser || isRingEnabledUser)
        );
    } catch (e) {
        return false;
    }
}
