import { createIconModule, FluentIconStyleProvider } from 'owa-fluent-icons-svg';
import React from 'react';
import CheckmarkFilled from 'owa-fluent-icons-svg/lib/icons/CheckmarkFilled';
import CheckboxUncheckedRegular from 'owa-fluent-icons-svg/lib/icons/CheckboxUncheckedRegular';
import CheckboxCheckedFilled from 'owa-fluent-icons-svg/lib/icons/CheckboxCheckedFilled';
import TagDuoToneSparkle from './TagDuoToneSparkle';
import { Icon } from '@fluentui/react/lib/Icon';
import { getPalette } from 'owa-theme';

const rootStyle = {
    width: '1em',
    height: '1em',
    display: 'inline-flex',
};

const checkmarkFilledIconStyle = { color: getPalette().neutralPrimary, marginRight: '4px' };

const checkboxIconStyle = { color: getPalette().themePrimary, marginRight: '4px' };

function TagDuoToneBundle() {
    return (
        <FluentIconStyleProvider rootStyle={rootStyle}>
            <Icon iconName={CheckmarkFilled} style={checkmarkFilledIconStyle} />
            <Icon iconName={TagDuoToneSparkle} />
        </FluentIconStyleProvider>
    );
}

function TagDuoToneCheckboxBundle() {
    return (
        <FluentIconStyleProvider rootStyle={rootStyle}>
            <Icon iconName={CheckboxUncheckedRegular} style={checkboxIconStyle} />
            <Icon iconName={TagDuoToneSparkle} />
        </FluentIconStyleProvider>
    );
}

function TagDuoToneCheckboxFilledBundle() {
    return (
        <FluentIconStyleProvider rootStyle={rootStyle}>
            <Icon iconName={CheckboxCheckedFilled} style={checkboxIconStyle} />
            <Icon iconName={TagDuoToneSparkle} />
        </FluentIconStyleProvider>
    );
}

export default createIconModule('TagDuoToneSparkleChecked', TagDuoToneBundle);

export const TagDuoToneCheckbox = createIconModule(
    'TagDuoToneSparkleCheckbox',
    TagDuoToneCheckboxBundle
);

export const TagDuoToneCheckboxFilled = createIconModule(
    'TagDuoToneSparkleCheckboxFilled',
    TagDuoToneCheckboxFilledBundle
);
