import { updateShySuiteHeaderMode } from '../actions/publicActions';
import { mutator } from 'satcheljs';
import { getStore } from '../store/store';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
export const updateShySuiteHeaderModeMutator = mutator(updateShySuiteHeaderMode, ({ isShy }) => {
    getStore().isShy = isShy;
});
