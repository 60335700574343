import type { MailboxInfo } from 'owa-client-types';
import { isCapabilityEnabled } from 'owa-capabilities';
import { archiveAndSharedCapability } from 'owa-capabilities-definitions/lib/archiveAndSharedCapability';
import createDragViewState from 'owa-dnd/lib/utils/createDragViewState';
import createDropViewState from 'owa-dnd/lib/utils/createDropViewState';
import {
    getPrimaryMailFolders,
    initializeArchiveFolderTree,
    initializeSharedFolderRoots,
} from 'owa-folders';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import { getHasArchive } from 'owa-session-store';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import getSortByFolderId from '../selectors/getSortByFolderId';
import type MailFolderNodeViewState from '../store/schema/MailFolderNodeViewState';
import type { FolderViewState } from '../utils/folderViewStateUtils';
import { getFolderViewStatesFromUserConfig } from '../utils/folderViewStateUtils';
import { setFolderViewStateInner } from './setFolderViewState';
import { mutatorAction } from 'satcheljs';
import getFolderData from 'owa-mail-actions/lib/getFolderData';
import { isOfflineSyncEnabled } from 'owa-offline-sync-feature-flags';

function isFolderExpanded(
    parsedFolderViewStates: {
        [key: string]: FolderViewState;
    },
    folderId: string
): boolean {
    if (folderId && folderIdToName(folderId) === 'msgfolderroot') {
        // If the folder is the root folder, use UserOptions.IsMailRootFolderTreeCollapsed.
        return !getUserConfiguration().UserOptions?.IsMailRootFolderTreeCollapsed;
    } else {
        // If we do not have a viewstate for this folder consider it expanded.
        const folderViewState: FolderViewState = parsedFolderViewStates[folderId];
        return !folderViewState || folderViewState.IsExpanded;
    }
}

function getFolderColor(
    parsedFolderViewStates: {
        [key: string]: FolderViewState;
    },
    folderId: string
): string | undefined {
    // If we do not have a viewstate for this folder, use the default color (undefined)
    const folderViewState: FolderViewState = parsedFolderViewStates[folderId];
    return folderViewState?.Color;
}

const initializeMailFolderViewStatesMutator = mutatorAction(
    'initializeMailFolderViewStates',
    (mailboxInfo: MailboxInfo) => {
        const sessionDataViewStates = getFolderViewStatesFromUserConfig(mailboxInfo);
        for (const [folderId, folderViewState] of Object.entries(sessionDataViewStates)) {
            const { sortColumn, sortDirection } = getSortByFolderId(folderId, folderViewState);
            const mailFolderViewState: MailFolderNodeViewState = {
                isExpanded: isFolderExpanded(sessionDataViewStates, folderId),
                sortColumn,
                sortOrder: sortDirection,
                drag: createDragViewState(),
                drop: createDropViewState(),
                color: getFolderColor(sessionDataViewStates, folderId),
            };
            setFolderViewStateInner(folderId, mailFolderViewState);
        }
    }
);

export default async function loadMailFolders(mailboxInfo: MailboxInfo, sessionData?: SessionData) {
    // populate folder store
    await getPrimaryMailFolders(mailboxInfo, sessionData);

    // Since outbox is a local only folder we need to make sure we are getting a local count
    // for it after potentially loading the hierarchy from the service.
    if (isOfflineSyncEnabled(mailboxInfo)) {
        const outboxId = folderNameToId('outbox', mailboxInfo);
        if (outboxId) {
            getFolderData(outboxId);
        }
    }

    // raise an action to initialize archive mailbox.
    // initialize shared folders
    if (isCapabilityEnabled(archiveAndSharedCapability, mailboxInfo)) {
        if (getHasArchive(mailboxInfo)) {
            initializeArchiveFolderTree(mailboxInfo);
        }
        initializeSharedFolderRoots(mailboxInfo);
    }

    initializeMailFolderViewStatesMutator(mailboxInfo);
}
