export const overflowWellKeytip = "iMvFLc";
export const bottomBarOverflowMenuLabel = "$H_zbe";
export const commandRibbonLabel = "xQuKXc";
export const tabsContainerLabel = "nMify";
export const tabsContainerOverflowLabel = "fFuONb";
export const peripheralControlsGroupLabel = "hasiee";
export const calloutCloseLabel = "Ip7WLe";
export const taskPaneOverflowMenuLabel = "azVEhc";
export const ribbonModeChevronLabel = "S5savb";
export const ribbonModeChevronKeytip = "g03h8d";
export const taskPaneTabsContainerLabel = "a98dBd";
export const taskPaneRoleDescription = "berb6c";
export const taskPaneOpeningString = "WcYoBb";
export const rightRibbonPaddleLabel = "ADVMKb";
export const leftRibbonPaddleLabel = "Dye2lb";
export const search = "gZJP4c";
export const ribbonSearchLabel = "abxZB";
export const searchableMenuRegionLabel = "T7DXC";
export const clearSearch = "ttWKQd";
export default {overflowWellKeytip,bottomBarOverflowMenuLabel,commandRibbonLabel,tabsContainerLabel,tabsContainerOverflowLabel,peripheralControlsGroupLabel,calloutCloseLabel,taskPaneOverflowMenuLabel,ribbonModeChevronLabel,ribbonModeChevronKeytip,taskPaneTabsContainerLabel,taskPaneRoleDescription,taskPaneOpeningString,rightRibbonPaddleLabel,leftRibbonPaddleLabel,search,ribbonSearchLabel,searchableMenuRegionLabel,clearSearch};