import type VirtualizedGroupHeader from '../type/VirtualizedGroupHeader';
import { mutatorAction } from 'satcheljs';
import getMailListGroupHeader from '../helpers/getMailListGroupHeader';
import getMailListGroupHeaderGenerator from '../helpers/getMailListGroupHeaderGenerator';
import virtualizedMailGroupHeadersStore from '../store/store';
import { getCanTableLoadMore } from 'owa-mail-triage-table-load-extra';
import type { TableView } from 'owa-mail-list-store';

// @param shouldReset - If true, the store is reset before adding the group headers
export default mutatorAction(
    'addVirtualizedGroupHeadersAction',
    function addVirtualizedGroupHeadersAction(tableView: TableView, shouldReset: boolean) {
        const tableRowKeys = [...tableView.rowKeys];
        let previousRowKey: string = '';
        let currentRowKey: string = '';
        let newGroupHeader: VirtualizedGroupHeader;
        let currentGroupHeaderText: string = '';

        // If the store should be reset, all the headers are removed from the store
        if (shouldReset) {
            virtualizedMailGroupHeadersStore().virtualizedGroupHeaders.clear();
        }

        const groupHeadersFromStore = virtualizedMailGroupHeadersStore().virtualizedGroupHeaders;

        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        tableRowKeys.forEach((rowKey: string) => {
            currentRowKey = rowKey;
            const headerText = getMailListGroupHeader(
                previousRowKey,
                currentRowKey,
                tableView,
                getMailListGroupHeaderGenerator(tableView)
            );
            // If a header text is generated a check is run to see if it already exists in the store. If not, it is added to the store.
            if (headerText) {
                // Header text is generated for the row key(and other row keys after it) & the store has it already - eg. new mail list item arrives in mail list
                const preExistingGroupHeaderFromStore = groupHeadersFromStore.get(headerText);
                if (preExistingGroupHeaderFromStore) {
                    currentGroupHeaderText = headerText;

                    // If the row key is not already in the list of row keys attached to the header text, we add it
                    if (!preExistingGroupHeaderFromStore.rowKeys.includes(currentRowKey)) {
                        preExistingGroupHeaderFromStore.rowKeys.push(currentRowKey);
                    }
                } else {
                    // New header text that does not exist in store
                    const previousGroupHeaderLoaded =
                        groupHeadersFromStore.get(currentGroupHeaderText);
                    if (previousGroupHeaderLoaded) {
                        previousGroupHeaderLoaded.isLoadingOfRowKeysComplete = true;
                    }
                    currentGroupHeaderText = headerText;
                    newGroupHeader = {
                        isCollapsed: false,
                        isLoadingOfRowKeysComplete: false,
                        rowKeys: [currentRowKey],
                    };
                    groupHeadersFromStore.set(headerText, newGroupHeader);
                }
                // If no headerText is generated, we add it to the itemIds attached to the last header text
            } else {
                const existingGroupHeaderValue = groupHeadersFromStore.get(currentGroupHeaderText);
                if (
                    existingGroupHeaderValue &&
                    !existingGroupHeaderValue.rowKeys.includes(currentRowKey)
                ) {
                    existingGroupHeaderValue.rowKeys.push(currentRowKey);
                }
            }
            previousRowKey = currentRowKey;
        });

        // For the last header - if all rows in the table are loaded and more cannot be loaded, the loading is considered complete.
        const lastGroupHeaderLoaded = groupHeadersFromStore.get(currentGroupHeaderText);
        if (lastGroupHeaderLoaded && !getCanTableLoadMore(tableView)) {
            lastGroupHeaderLoaded.isLoadingOfRowKeysComplete = true;
        }
    }
);
