/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - satchel stores in the worker will need their data synced
 *	> 'createStore' import from 'satcheljs' is restricted. Satchel stores should not be created in the worker as the data will need to be synced */
import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';
import type { ResourceId } from '../ResourceId';
import type { LocalizedString } from '../LocalizedString';
import { isBootFeatureEnabled } from 'owa-metatags';

export const getLocalizedStringStore = createStore('localizedStrings', {
    currentLocale: '',
    currentCulture: '',
    version: 0,
    localizedStringsV1: new ObservableMap<ResourceId, LocalizedString>({}),
});

export const getLocalizedStringStoreV2 = () => {
    if (isBootFeatureEnabled('fwk-loc-single-v2')) {
        return getLocalizedStringStore();
    } else {
        return getLocalizedStringStore().localizedStringsV1;
    }
};
