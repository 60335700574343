import type { TimePanelSource } from '../TimePanelSource';
import { debugErrorThatWillShowErrorPopupOnly } from 'owa-trace';

export function verifyValidSource(source: TimePanelSource): boolean {
    if (source) {
        return true;
    }
    debugErrorThatWillShowErrorPopupOnly(
        'Source is a required parameter for Time Panel public APIs'
    );
    return false;
}
