import {
    zoomOutScaleUpdate,
    zoomInScaleUpdate,
    showZoomCallout,
} from 'owa-custom-zoom-store/lib/mutators/zoomMutators';

export default function onWheelZoom(event: WheelEvent) {
    if (event.ctrlKey) {
        // Prevent browser's zoom
        event.preventDefault();
        // Show zoom control for showing the user zoom level changes
        showZoomCallout('default');
        if (event.deltaY < 0) {
            // Scroll up
            zoomInScaleUpdate();
        } else if (event.deltaY > 0) {
            // Scroll down
            zoomOutScaleUpdate();
        }
    }
}
