import type { GroupFolderTableQuery, SortBy } from 'owa-mail-list-store';
import { MailSortHelper, type TableQueryType } from 'owa-mail-list-store';
import { getFindItemShape, FindConversationShapeName } from 'owa-mail-find-rows';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import type ViewFilter from 'owa-service/lib/contract/ViewFilter';
import getListViewTypeForGroup from './getListViewTypeForGroup';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Create the table query to load group folder mail
 * @param groupId the groupId (group smtp address)
 * @param folderId the folder id
 * @param listViewType the list view type
 * @param sortBy the sortBy (column and direction)
 * @param viewFilter the view filter
 * @return tableQuery the tableQuery that needed to load group folder mail
 */
export default function createGroupFolderMailTableQuery(
    groupId: string,
    folderId: string,
    listViewType: ReactListViewType,
    mailboxInfo: MailboxInfo,
    sortBy?: SortBy,
    viewFilter?: ViewFilter
): GroupFolderTableQuery {
    const viewFilterToSet = viewFilter ?? 'All';
    const sortByToSet = sortBy ?? MailSortHelper.getDefaultSortBy();

    let requestShapeName;
    switch (listViewType) {
        case 0:
            requestShapeName = FindConversationShapeName;
            break;
        case 1:
            requestShapeName = getFindItemShape();
            break;
        default:
            requestShapeName = getListViewTypeForGroup();
    }

    const groupFolderTableQuery: GroupFolderTableQuery = {
        categoryName: '',
        folderId,
        focusedViewFilter: -1,
        listViewType,
        viewFilter: viewFilterToSet,
        sortBy: sortByToSet,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (52,9): Type 'string | ReactListViewType' is not assignable to type 'string'.
        // @ts-expect-error
        requestShapeName,
        type: 3,
        scenarioType: 'group',
        groupId,
        mailboxInfo,
    };

    return groupFolderTableQuery;
}
