import { setThemingIsEnabled } from '@1js/acui-theme/lib/components/AppThemeManager/AppThemeManager';

let appThemeInitialized = false;

// Should be used before the first render of a WAC Control in order to be respect the dark/light mode toggle
// Used in CommandRibbon, ComposeWacRibbon and the Floatie Ribbon.
export function ensureAppThemeInitialized() {
    if (!appThemeInitialized) {
        setThemingIsEnabled(true);
        appThemeInitialized = true;
    }
}
