import { type ItemPartSelectionRestrictionType } from '../Behaviors.types';
import {
    getComputedIsInCheckedMode,
    isOneSelectedItemParts,
    isMultipleSelectedItemParts,
} from 'owa-mail-list-store';
import { assertNever } from 'owa-assert';

export const itemPartSelectionRestrictionType =
    (restrictionType: ItemPartSelectionRestrictionType) => () => {
        const isInCheckedMode: boolean = getComputedIsInCheckedMode();
        const hasItemPartsSelected = !isInCheckedMode && isMultipleSelectedItemParts();

        switch (restrictionType) {
            case 4:
                return !hasItemPartsSelected;
            case 1:
            case 3:
                return hasItemPartsSelected;
            case 2:
                return !isInCheckedMode && isOneSelectedItemParts();
            default:
                throw assertNever(restrictionType);
        }
    };
