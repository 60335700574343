const FEATURES_KEY_INSETTINGS = 'Features';
export default function extractFlightsFromSettings(settings: any): string[] {
    if (!settings || !settings[FEATURES_KEY_INSETTINGS]) {
        return [];
    }

    return Object.keys(settings[FEATURES_KEY_INSETTINGS] || {}).filter(
        (feature: string) => settings[FEATURES_KEY_INSETTINGS][feature] === true
    );
}
