import { SearchProvider } from 'owa-search-service';
import getIsSearchTableShown from './getIsSearchTableShown';

export default function shouldRenderCopilotForSearchMailResults(searchProvider: SearchProvider) {
    //Render copilot related UI if we are showing search results and we are not offline or in PST
    return (
        getIsSearchTableShown() &&
        searchProvider !== SearchProvider.Offline &&
        searchProvider !== SearchProvider.PST
    );
}
