import type { StoreObject } from '@apollo/client';
import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';
import { DatapointStatus, type PerformanceDatapoint } from 'owa-analytics';
import { extract } from 'owa-apollo';
import type { M365Acquisition, M365AcquisitionsQuery } from './types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { filterBizChatAcquisitionEdges } from './utils/filterBizChatAcquisition';

type ApolloCacheQueryKey = keyof StoreObject;
/**
 * Reads `m365Acquisitions` query's cache content — directly from the Apollo Cache.
 *
 * ## Context
 *
 * We need to read directly from cache because we can't rely on Apollo's
 * `cache-first` heuristics, such as `query(options)` or `readQuery(options)`
 * as `options = {fetchPolicy:'cache-first'}` because it would _never_ hit the
 * cache.
 *
 * That is because it only hits the cache if the specified query AND the cache's content
 * has a **all-fields match**.
 *
 * In other words, all properties specified on `M365AcquisitionsDocument` need
 * to exist in the query's cache, which is not the case for data returned from MOS3, as
 * there is a enormous variance of data because there are many different types of apps.
 * @returns `M365AcquisitionsQueryResult`
 */
export function readM365AcquisitionsFromCache(
    datapoint?: PerformanceDatapoint,
    mailboxInfo?: MailboxInfo
): M365Acquisition[] {
    const cacheCopy = extract();
    const queryKey: ApolloCacheQueryKey = mailboxInfo
        ? getM365AcquisitionsQueryKey(mailboxInfo)
        : 'm365Acquisitions';
    const m365AcquisitionsQuery = cacheCopy?.ROOT_QUERY?.[queryKey] as
        | M365AcquisitionsQuery['m365Acquisitions']
        | undefined;
    const m365AcquisitionsEdges = m365AcquisitionsQuery?.edges as unknown as
        | M365Acquisition[]
        | undefined;
    if (!m365AcquisitionsEdges) {
        datapoint?.endWithError(
            DatapointStatus.ClientError,
            new Error('Failed to fetch Acquisitions cache.')
        );
        return [];
    }
    if (!m365AcquisitionsEdges) {
        datapoint?.endWithError(
            DatapointStatus.ClientError,
            new Error('Unable to read acquisitions cache values')
        );
        return [];
    }

    return isFeatureEnabled('mos-BizChatOCS')
        ? filterBizChatAcquisitionEdges(m365AcquisitionsEdges, mailboxInfo)
        : m365AcquisitionsEdges;
}

function getM365AcquisitionsQueryKey(mailboxInfo: MailboxInfo): string {
    return `m365Acquisitions:${getIndexerValueForMailboxInfo(mailboxInfo)}`;
}
