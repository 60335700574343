import { isDownloadAllowed } from './isDownloadAllowed';
import type Message from 'owa-service/lib/contract/Message';
import type { MailboxInfo } from 'owa-client-types';

export default function checkIfAttachmentsCanBeDownloaded(
    message: Message,
    mailboxInfo: MailboxInfo
): boolean {
    return (
        !message.Attachments ||
        !!message.Attachments?.every(attachment => isDownloadAllowed(attachment, mailboxInfo))
    );
}
