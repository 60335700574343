import { getMarkJS } from 'owa-mark/lib/utils/constants';
import type { Accuracy } from 'owa-mark/lib/utils/markElements';
import { default as markElements } from 'owa-mark/lib/utils/markElements';
import { getElementColors, setElementOgColorsIfUnset } from 'owa-content-colors';

export const HIT_HIGHLIGHTING_HANDLER_NAME = 'hitHighlightingHandler';
const HIGHLIGHTING_BACKGROUND_COLOR = '#FFF100';
const HIGHLIGHTING_COLOR = 'black';

// used to bypass loading markjs if no
// search terms are ever passed in
let hasEverMarked = false;

export default async function highlightTermsInHtmlElement(
    element: HTMLElement,
    highlightTerms: string[],
    separateWordSearch: boolean = false,
    accuracy: Accuracy = 'partially'
) {
    if (element && (highlightTerms?.length || hasEverMarked)) {
        hasEverMarked = true;
        const MarkJS = await getMarkJS();
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2345 (25,34): Argument of type 'typeof Mark' is not assignable to parameter of type 'Mark'.
        // @ts-expect-error
        const marker = MarkJS.call(MarkJS, element);
        if (highlightTerms?.length) {
            if (accuracy === 'prefix') {
                markElements(
                    element,
                    highlightTerms,
                    (_keyword: string, matchedElement: HTMLElement) => {
                        handleHitHighlighting(matchedElement);
                    },
                    false,
                    accuracy
                );
            } else {
                // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                // -> Error TS2339 (44,20): Property 'mark' does not exist on type 'void'.
                // @ts-expect-error
                marker.mark(highlightTerms, {
                    separateWordSearch,
                    accuracy,
                });
            }
        } else {
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2339 (53,20): Property 'unmark' does not exist on type 'void'.
            // @ts-expect-error
            marker.unmark();
        }
    }
}

export function handleHitHighlighting(element: HTMLElement) {
    const originalColors = getElementColors(element);
    element.style.backgroundColor = HIGHLIGHTING_BACKGROUND_COLOR;
    element.style.color = HIGHLIGHTING_COLOR;
    setElementOgColorsIfUnset(element, originalColors);
}
