import type AccessIssue from '../schema/AccessIssue';
import type AttachmentType from 'owa-service/lib/contract/AttachmentType';
import getConditionalAccessIssue from './getConditionalAccessIssue';
import { getInfoBarId } from './getInfoBarId';
import { isAttachmentOfReferenceType as isCloudyAttachment } from 'owa-attachment-type/lib/isAttachmentOfReferenceType';
import type { MailboxInfo } from 'owa-client-types';

export function getAccessIssues(mailboxInfo: MailboxInfo): AccessIssue[] {
    return getConditionalAccessIssue(mailboxInfo);
}

export function getAccessIssuesForAttachments(
    attachments: AttachmentType[],
    mailboxInfo: MailboxInfo
): string[] {
    const accessIssues: AccessIssue[] =
        attachments && attachments.length > 0 && hasNonInlineAttachment(attachments)
            ? getConditionalAccessIssue(mailboxInfo)
            : [];
    const infoBarIdList = accessIssues.map(getInfoBarId);
    return infoBarIdList.filter(id => id !== null) as string[];
}

function hasNonInlineAttachment(attachments: AttachmentType[]) {
    return attachments.some(a => !isInline(a));
}

function isInline(attachment: AttachmentType): boolean {
    return !!(attachment.IsInline && !isCloudyAttachment(attachment));
}
