import { fetchThemeBackgroundImage } from 'owa-theme-shared';
import type { ThemeResources } from 'owa-theme-shared';

export async function loadThemeBackgroundImage(themeResources: ThemeResources | null) {
    if (!themeResources || !themeResources.background?.image) {
        return;
    }

    fetchThemeBackgroundImage(themeResources.background.image);
}
