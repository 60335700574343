import { mutatorAction } from 'satcheljs';
import { getConditionalFormattingStore } from '../store/store';
import type { MailboxInfo } from 'owa-client-types';

export default mutatorAction(
    'removeEntryFromRowToModificationMap',
    function removeEntryFromRowToModificationMap(key: string, mailboxInfo: MailboxInfo) {
        getConditionalFormattingStore(mailboxInfo).rowToModificationsMap.delete(key);
    }
);
