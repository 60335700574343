import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import { isFeatureEnabled } from 'owa-feature-flags';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import type SingleRecipientType from 'owa-service/lib/contract/SingleRecipientType';
import type { TableView } from 'owa-mail-list-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { getIsSearchTableShown } from 'owa-mail-list-store';
import { getSubscriptionFromCache } from 'owa-brands-subscription';

export default function shouldShowSenderGroupingsActionButton(
    tableView: TableView,
    lastSender?: SingleRecipientType
) {
    return (
        isFeatureEnabled('tri-senderGroupings') &&
        isConsumer(undefined, getMailboxInfo(tableView)) &&
        lastSender?.Mailbox?.Name !== undefined &&
        lastSender?.Mailbox?.EmailAddress !== undefined &&
        folderIdToName(tableView.tableQuery.folderId) === 'inbox' &&
        (!!getSubscriptionFromCache(lastSender?.Mailbox?.EmailAddress) ||
            lastSender?.Mailbox?.EmailAddress.startsWith('no-reply')) &&
        !getIsSearchTableShown()
    );
}
