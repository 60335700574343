import type EmailAddressWrapper from 'owa-service/lib/contract/EmailAddressWrapper';
import { getAccountScopeUserSettings } from 'owa-session-store';
import type { MailboxInfo } from 'owa-client-types';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

export function isSameStringIgnoreCase(
    str1: string | null | undefined,
    str2: string | null | undefined
): boolean {
    return str1 === str2 || (!!str1 && !!str2 && str1.toLowerCase() == str2.toLowerCase());
}

export function isSelf(
    address: EmailAddressWrapper | undefined,
    mailboxInfo: MailboxInfo
): boolean {
    return (
        !!address &&
        isSameStringIgnoreCase(
            address.EmailAddress,
            getAccountScopeUserSettings(mailboxInfo ?? getModuleContextMailboxInfo())
                .SessionSettings?.UserEmailAddress
        )
    );
}
