import type { ReadOnlyOwaUserConfiguration } from 'owa-service/lib/ReadOnlyTypes';
import { getCultureOverride } from './getCultureOverride';
import { DEFAULT_LOCALE, getSupportedFallbackForLocale } from './getSupportedFallbackForLocale';
import { hasQueryStringParameter } from 'owa-querystring';

type LocalizationContextType = {
    locale: string;
    culture: string;
    dir: 'ltr' | 'rtl' | undefined;
};

export function getLocalizationContext(
    userConfiguration: ReadOnlyOwaUserConfiguration
): LocalizationContextType {
    const override = getCultureOverride();
    const { UserLanguage, UserCulture, IsUserCultureRightToLeft } =
        userConfiguration.SessionSettings ?? {};

    const locale: string =
        override ||
        (UserLanguage && getSupportedFallbackForLocale(UserLanguage).locale) ||
        DEFAULT_LOCALE;
    let dir: 'ltr' | 'rtl' | undefined;
    if (hasQueryStringParameter('debugRtl')) {
        dir = 'rtl';
    } else if (!override) {
        dir = IsUserCultureRightToLeft ? 'rtl' : 'ltr';
    }

    const culture: string = UserCulture || DEFAULT_LOCALE;

    return { locale, culture, dir };
}
