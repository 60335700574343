import { action } from 'satcheljs';
import type { OpenPstScenario } from '../store/schema/OpenPstFileExperienceStore';
import type { AccountConfigDialogLaunchOrigin } from 'owa-account-config-shared';
import type { PendingAccountInfo } from 'owa-account-pending-store';

/**
 * Dispatch this action to render the remediation experience to create a
 * full account from the given pending account
 */
export const remediatePendingPstAccount = action(
    'remediatePendingPstAccount',
    (
        pendingAccount: PendingAccountInfo,
        targetWindow: Window,
        entryPoint?: AccountConfigDialogLaunchOrigin,
        onCompleted?: () => void
    ) => ({
        scenario: 'RemediatePendingPstAccount' as OpenPstScenario,
        pendingAccount,
        targetWindow,
        entryPoint,
        onCompleted,
    })
);
