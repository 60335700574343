import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailListKeys"*/ './lazyIndex'),
    { name: 'MailListKeys' }
);
export const lazySetupMailListContentKeys = new LazyAction(
    lazyModule,
    m => m.setupMailListContentKeys
);

export const lazySetupMailListItemContainerKeys = new LazyAction(
    lazyModule,
    m => m.setupMailListItemContainerKeys
);

export const lazyLogMailListItemPreloadData = new LazyAction(
    lazyModule,
    m => m.logMailListItemPreloadCorrectness
);
