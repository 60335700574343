import { composeStore } from 'owa-mail-compose-store';
import { lazyDiscardCompose, lazyLoadDraftToCompose } from 'owa-mail-compose-actions';
import { lazyNewMessage } from 'owa-mail-message-actions';
import selectFolderWithFallback from './selectFolderWithFallback';
import getSelectedFolderMailboxInfoOrDefault from 'owa-mail-store/lib/utils/getSelectedFolderMailboxInfoOrDefault';

export interface MailFolderRouteParameters {
    folderId?: string;
    draftId?: string;
}

export async function mailComposeNavigationRouteHandler(parameters: MailFolderRouteParameters) {
    selectFolderWithFallback(parameters.folderId);

    if (parameters.draftId) {
        await lazyLoadDraftToCompose.importAndExecute(
            { Id: parameters.draftId, mailboxInfo: getSelectedFolderMailboxInfoOrDefault() },
            null /* sxsId */,
            'Route' /* actionSource */
        );
    } else {
        await lazyNewMessage.importAndExecute('Route');
    }
}

export function mailComposeCleanupRouteHandler(): Promise<boolean> | boolean {
    if (composeStore.primaryComposeId) {
        const viewState = composeStore.viewStates.get(composeStore.primaryComposeId);
        if (viewState) {
            return lazyDiscardCompose.importAndExecute(viewState).then(
                () => false, // Confirm ('Discard draft') means we should not block navigation
                () => true
            ); // Cancel ('Dont' discard') means we should block navigation
        }
    }
    return false;
}
