import { lazyGetCopilotReplyWithMeetingSections } from 'owa-mail-ribbon-sections/lib/sections';

import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onCopilotReplyWithMeetingMenuClicked = (props: ReadOnlyRibbonControlDefProps) => {
    lazyGetCopilotReplyWithMeetingSections.import().then(getSections => {
        setMenuDefinition('CopilotMeetingMenuDefinitionStore', props.projectionTabId, () => {
            return {
                sections: getSections(props),
            };
        });
    });
};
