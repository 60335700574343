import { createLazyComponent, LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ReactionsView"*/ './lazyIndex'),
    { name: 'ReactionsView' }
);

export const ReactionActionButton = createLazyComponent(lazyModule, m => m.ReactionActionButton);

export const ReactionsContainer = createLazyComponent(lazyModule, m => m.ReactionsContainer);

export const ReactionsOnMailListItemContainer = createLazyComponent(
    lazyModule,
    m => m.ReactionsOnMailListItemContainer
);

export const lazyInitializeSkinTonePreference = new LazyAction(
    lazyModule,
    m => m.initializeSkinTonePreference
);
