export enum SearchProvider {
    Substrate = 'SubstrateSearch',
    SubstrateV2 = 'SubstrateSearchV2',
    ExecuteSearch = 'ExecuteSearch',
    FindItem = 'FindItem',
    Offline = 'Offline',
    PST = 'PST',
}

export default SearchProvider;
