import { makeVar } from '@apollo/client';

const selectedApp = makeVar('');

export function getSelectedApp(): string {
    return selectedApp();
}

export function setSelectedApp(app: string) {
    selectedApp(app);
}
