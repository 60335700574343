import type BaseFolderType from 'owa-service/lib/contract/BaseFolderType';
import getExtendedProperty from './getExtendedProperty';

const SOURCE_WELLKNOWN_FOLDER_TYPE_PROPERTY_NAME = 'SourceWellKnownFolderType';

const getSourceWellKnownFolderTypeProperty = (folderItem: BaseFolderType) => {
    const value = getExtendedProperty(folderItem, SOURCE_WELLKNOWN_FOLDER_TYPE_PROPERTY_NAME);
    const wellKnownFolderValue = (value && parseInt(value, 10 /* radix */)) || -1;

    switch (wellKnownFolderValue) {
        case 86:
            return 'REMOTE_DISTINGUISHED_FOLDER_TYPE_ARCHIVE';
        case 87:
            return 'REMOTE_DISTINGUISHED_FOLDER_TYPE_IMPORTANT';
        case 88:
            return 'REMOTE_DISTINGUISHED_FOLDER_TYPE_STARRED';
        default:
            return 'REMOTE_DISTINGUISHED_FOLDER_TYPE_UNSPECIFIED';
    }
};

export default getSourceWellKnownFolderTypeProperty;
