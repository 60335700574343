import { createLazyComponent, LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CustomZoom" */ './lazyIndex'),
    { name: 'CustomZoom' }
);

// Delayed Loaded Components
export const ZoomButtonsCallout = createLazyComponent(lazyModule, m => m.ZoomButtonsCallout);

// Getters
export { getZoomStyle } from './utils/getZoomStyle';

// Datapoints
export { default as logEmbiggenZoomSettingDatapoint } from './utils/logEmbiggenZoomSettingDatapoint';

// Events
export { default as onWheelZoom } from './utils/onWheelZoom';
export { default as onCtrlZoom } from './utils/onCtrlZoom';

// Lazy Actions
export const lazyOverrideCustomZoomToDefaultScrollbar = new LazyAction(
    lazyModule,
    m => m.overrideCustomZoomToDefaultScrollbar
);
export const lazyOverrideCustomZoomToDefaultSideMargins = new LazyAction(
    lazyModule,
    m => m.overrideCustomZoomToDefaultSideMargins
);
export const lazyOverrideCustomZoomToDefaultInfoBar = new LazyAction(
    lazyModule,
    m => m.overrideCustomZoomToDefaultInfoBar
);

// Lazy getters
export const lazyGetZoomSubMenu = new LazyAction(lazyModule, m => m.getZoomSubMenu);
