import type {
    SessionDataWithGraphQL,
    MailboxInfoInput,
    FolderHierarchyResult,
} from 'owa-graph-schema';

import { addBottleneck } from 'owa-performance';
import { mapFindFolderResponseToGql } from 'owa-folder-gql-mappers';
import { shouldShowFolder } from 'owa-folders-data-utils';

export function extractFolderHierarchy(
    sessionData: SessionDataWithGraphQL | undefined,
    mailboxInfoInput: MailboxInfoInput
): FolderHierarchyResult | undefined {
    const bottleneck = 'ffr';
    let result = sessionData?.folderHierarchy;
    let bottleneckReason = 's';
    const shouldShowNotesFolder = shouldShowFolder('notes', mailboxInfoInput);
    const shouldShowConversationHistoryFolder = shouldShowFolder(
        'conversationhistory',
        mailboxInfoInput
    );
    const shouldShowOutboxFolder = shouldShowFolder('outbox', mailboxInfoInput);
    if (!result) {
        const singleResponseMessage = sessionData?.findFolders?.Body?.ResponseMessages?.Items?.[0];
        if (!singleResponseMessage) {
            bottleneckReason = 'nrm';
        } else if (singleResponseMessage.ResponseClass == 'Error') {
            bottleneckReason = 'e';
            addBottleneck(bottleneck + '_c', singleResponseMessage.ResponseCode || 'un');
        } else {
            try {
                result = mapFindFolderResponseToGql(
                    singleResponseMessage,
                    mailboxInfoInput,
                    shouldShowNotesFolder,
                    shouldShowConversationHistoryFolder,
                    shouldShowOutboxFolder
                );
            } catch (e) {
                bottleneckReason = 'm';
                addBottleneck(bottleneck + '_c', e.message);
            }
        }
    } else {
        // When booting offline, the folder hierarchy is already available. At the point that is retrieved, the code does not
        // have the information to correctly determine if the folowing folders should be shown. So we need to filter them out here.
        if (result.Folders) {
            const filteredFolders = result.Folders.filter(folder => {
                const distinguishedFolderId = folder?.distinguishedFolderType;
                switch (distinguishedFolderId) {
                    case 'notes':
                        return shouldShowNotesFolder;
                    case 'conversationhistory':
                        return shouldShowConversationHistoryFolder;
                    case 'outbox':
                        return shouldShowOutboxFolder;
                    default:
                        return true;
                }
            });
            result.Folders = filteredFolders;
        }
    }

    addBottleneck('ffr', bottleneckReason);
    return result;
}
