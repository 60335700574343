import { observer } from 'owa-mobx-react';
import { HotkeysMap } from 'owa-hotkeys-map';
import React from 'react';
import { getMailHotkeysMapProperties } from '../utils/setupMailHotkeysMapProperties';

// deprecated in full-options in popout.
// this code should be removed when clean up acct-fullOptionsProjectionPopout flight
export const MailHotkeysMap = observer(function MailHotkeysMap() {
    const { commandCategories, goToOptions, hotkeyCommands } = getMailHotkeysMapProperties();

    return (
        <HotkeysMap
            commandCategories={commandCategories}
            goToOptions={goToOptions}
            hotkeyCommands={hotkeyCommands}
        />
    );
}, 'MailHotkeysMap');
