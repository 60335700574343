import { LazyModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OwaAppBarPinnedApps" */ './lazyIndex'),
    { name: 'OwaAppBarPinnedApps' }
);

export const lazyInitializeAppBarPinnedAppsUserSetting = new LazyAction(
    lazyModule,
    m => m.initializeAppBarPinnedAppsUserSetting
);
