import { isFeatureEnabled } from 'owa-feature-flags';
import { errorThatWillCauseAlert, errorThatWillCauseAlertAndThrow } from 'owa-trace';
import closeTab from '../actions/closeTab';
import getTabsByTabType from '../selectors/getTabsByTabType';
import { TabType } from '../store/schema/TabViewState';
import addTab from './addTab';

export default function addFullOptionsTab(isShown: boolean, data: any): string {
    if (!isFeatureEnabled('acct-fullOptionsProjectionPopout')) {
        errorThatWillCauseAlertAndThrow(
            'addFullOptionsTab should NOT called when acct-fullOptionsProjectionPopout flight is OFF'
        );
    }

    const fullOptionsTabs = getTabsByTabType(TabType.FullOptions);

    if (fullOptionsTabs.length > 1) {
        errorThatWillCauseAlert('We are having more than 1 FullOptions Projection Tab');
    }

    if (fullOptionsTabs.length === 1) {
        const tab = fullOptionsTabs[0];

        closeTab(tab, true);
    }

    return addTab(TabType.FullOptions, isShown, true /** makeActive */, data);
}
