import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';
import type {
    GroupListNodesViewStateStore,
    GroupFolderTreeViewState,
} from 'owa-groups-shared-folders-store';

const store = createStore<GroupListNodesViewStateStore>(
    'favoriteGroupListNodesViewStateByMailBoxInfo',
    {
        groupListNodesViewStates: new ObservableMap<string, GroupFolderTreeViewState>({}),
    }
)();

export const getFavoriteListNodesViewStateStore = () => {
    return store;
};
