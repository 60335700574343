import type { AccountActionOrigin } from 'owa-account-source-list-types';
import type { AccountOriginContext } from './AccountOriginContext';

type AccountOriginDiagnostics = {
    [key: string]: string | undefined;
};

/**
 * Gets diagnostic data for an account origin and the optional context information
 * @param actionOrigin Specifies the origin of the action
 * @param originContext Optionally specifies the origin context for the account
 * @returns Diagnostic data for the account origin and context
 */
export function getAccountOriginDiagnostics(
    actionOrigin: AccountActionOrigin,
    originContext?: AccountOriginContext
): AccountOriginDiagnostics {
    return {
        origin: actionOrigin,
        originContext: originContext?.context,
        originSubContext: originContext?.subContext,
    };
}
