import { GetTokenApi } from './GetTokenApi';
import type {
    TokenRequestParams,
    ConsumerTokenRequestParams,
    EnterpriseTokenRequestParams,
} from './schema/TokenRequestParams';
import type { ILogger } from './utils/ILogger';
import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import { NUM_OF_RETRIES, RETRY_DELAY } from './const/constants';
import { fetchTokenFromHostAppForEnterpriseWithRetry } from './utils/fetchTokenFromHostAppForEnterprise';
import { fetchTokenFromHostAppForConsumerWithRetry } from './utils/fetchTokenFromHostAppForConsumer';

export class GetTokenApiUsingHostApp extends GetTokenApi {
    public readonly LoggerPrefix = 'GetTokenApiUsingHostApp';

    public async getTokenForEnterpriseCallback(
        params: TokenRequestParams,
        logger: ILogger
    ): Promise<TokenResponse> {
        const enterpriseParams = params as EnterpriseTokenRequestParams;

        const cacheKey = this.tokenCache.getCacheKey(enterpriseParams);

        logger.addCheckpoint(`${this.LoggerPrefix}EnterpriseCallback`);
        const tokenResponse = await fetchTokenFromHostAppForEnterpriseWithRetry(
            logger,
            NUM_OF_RETRIES,
            RETRY_DELAY,
            enterpriseParams
        );

        this.tokenCache.putCachedToken(cacheKey, enterpriseParams, tokenResponse);

        return tokenResponse;
    }

    public async getTokenForConsumerCallback(
        params: TokenRequestParams,
        logger: ILogger
    ): Promise<TokenResponse> {
        const consumerParams = params as ConsumerTokenRequestParams;
        const cacheKey = this.tokenCache.getCacheKey(consumerParams);

        logger.addCheckpoint(`${this.LoggerPrefix}ConsumerCallback`);
        const tokenResponse = await fetchTokenFromHostAppForConsumerWithRetry(
            logger,
            NUM_OF_RETRIES,
            RETRY_DELAY,
            consumerParams
        );
        this.tokenCache.putCachedToken(cacheKey, consumerParams, tokenResponse);

        return tokenResponse;
    }
}
