import { ObservableMap } from 'mobx';
import type {
    default as ConditionalFormattingStore,
    ConditionalFormattingStoreMap,
} from './schema/ConditionalFormattingStore';
import { createStore } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type {
    ConditionalFormattingModification,
    ConditionalFormattingRule,
} from './schema/ConditionalFormattingRule';
import { getItem, itemExists } from 'owa-local-storage';
import { CONDITIONAL_FORMATTING_SETTING_NAME } from '../utils/constants';
import { trace } from 'owa-trace';

const initialConditionalFormattingStoreMap: ConditionalFormattingStoreMap = {
    entries: new ObservableMap(),
};
const conditionalFormattingStoreMap = createStore<ConditionalFormattingStoreMap>(
    'sharedConditionalFormattingStore',
    initialConditionalFormattingStoreMap
)();

//Initializes the store to get conditional formatting rules.
export function getConditionalFormattingStore(
    mailboxInfo: MailboxInfo
): ConditionalFormattingStore {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    let store = conditionalFormattingStoreMap.entries.get(key);
    if (!store) {
        store = {
            conditionalFormattingRules: fetchRulesFromLocalStorage(key),
            rowToModificationsMap: new ObservableMap<string, ConditionalFormattingModification[]>(),
        };
        conditionalFormattingStoreMap.entries.set(key, store);
        const defaultStore = conditionalFormattingStoreMap.entries.get(key);
        return defaultStore ? defaultStore : store;
    }
    return store;
}

function fetchRulesFromLocalStorage(key: string): ConditionalFormattingRule[] {
    const localStorageKey = CONDITIONAL_FORMATTING_SETTING_NAME + key;
    try {
        return itemExists(window, localStorageKey)
            ? JSON.parse(getItem(window, localStorageKey) as string).filter(
                  (conditionalFormattingRule: ConditionalFormattingRule) =>
                      conditionalFormattingRule
              )
            : [];
    } catch (e) {
        trace.warn(e.message + getItem(window, localStorageKey));
        return [];
    }
}
