import { mutatorAction } from 'satcheljs';
import type { MailListItemAnimation } from '../store/schema/MailListItemAnimationStore';
import { mailListItemAnimationStore } from '../store/Store';

export const addActiveAnimation = mutatorAction(
    'addActiveAnimation',
    (animationToAdd: MailListItemAnimation) => {
        mailListItemAnimationStore.activeAnimations = [
            ...mailListItemAnimationStore.activeAnimations,
            animationToAdd,
        ];
    }
);
