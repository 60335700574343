import { getAreAllChildrenSelectedInConversation } from 'owa-mail-list-store';
import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';
import getListViewState from 'owa-mail-list-store/lib/selectors/getListViewState';
import { isBefore, userDate } from 'owa-datetime';

export default function getIsChecked(
    tableView: TableView | undefined,
    rowKey: string,
    lastDeliveryTimestamp: string
): boolean {
    if (!tableView) {
        return false;
    }

    if (tableView.isInVirtualSelectAllMode) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2532 (17,13): Object is possibly 'undefined'.
        // @ts-expect-error
        if (tableView.virtualSelectAllExclusionList.indexOf(rowKey) > -1) {
            return false;
        }

        // We use this timestamp to determine whether we are in virtual select all mode. So if we get a new item, it should not be a part of the selection, which is what the return below is checking
        if (tableView.selectAllModeTimeStamp && lastDeliveryTimestamp) {
            return isBefore(userDate(lastDeliveryTimestamp), tableView.selectAllModeTimeStamp);
        }

        return true;
    }

    if (tableView.isInCheckedMode && tableView.selectedRowKeys.has(rowKey)) {
        const expansionState = getListViewState().expandedConversationViewState;
        // If the conversation is expanded and has children, return if all its children are selected
        const conversationIsExpandedWithChildren =
            expansionState?.expandedRowKey === rowKey &&
            ((expansionState?.forks?.length ?? 0) > 0 || expansionState?.allNodeIds?.length > 0) &&
            !expansionState?.shouldBeExpanded;
        if (conversationIsExpandedWithChildren) {
            return getAreAllChildrenSelectedInConversation(rowKey, tableView);
        }
        // Conversation is collapsed or expanded conversation doesn't have children
        return true;
    } else {
        return false;
    }
}
