import { isGlobalSettingsMailbox } from 'owa-account-source-list-store';
import { addSetAccountConfigurationLog } from 'owa-account-user-configuration-monitor/lib/addSetAccountConfigurationLog';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
import { mutatorAction } from 'satcheljs';
import { getRawStoreInternalOnly } from '../store/store';
import type { AccountConfigurationOrigin } from '../types/AccountConfigurationOrigin';
import { logSetAccountConfiguration } from '../utils/logSetAccountConfiguration';
import { makeAdditionalAccountConfiguration } from '../utils/makeAdditionalAccountConfiguration';
import { userConfigurationSet } from './userConfigurationSet';

const setAccountConfigurationInternal = mutatorAction(
    'setAccountConfigurationInternal',
    (
        mailboxInfo: MailboxInfo,
        userConfiguration: OwaUserConfiguration,
        forceIsGlobalAccountMailboxInfoResult: boolean
    ): void => {
        const store = getRawStoreInternalOnly();

        const additionalAccountProps = makeAdditionalAccountConfiguration(userConfiguration);
        const config = {
            ...userConfiguration,
            ...additionalAccountProps,
        };

        addSetAccountConfigurationLog(
            forceIsGlobalAccountMailboxInfoResult,
            isGlobalSettingsMailbox(mailboxInfo),
            userConfiguration
        );

        if (forceIsGlobalAccountMailboxInfoResult /*isGlobalSettingsMailbox(mailboxInfo)*/) {
            store.userConfiguration = config;
        } else {
            const accountKey = getIndexerValueForMailboxInfo(mailboxInfo);
            store.nonGlobalAccountDataMap.set(accountKey, config);
        }

        // An aggregate map is also maintained with a merged view of all folder id name mappings for all accounts.
        const currentMap = store.defaultFolderIdToNameMap;
        store.defaultFolderIdToNameMap = new Map([
            ...currentMap,
            ...additionalAccountProps.defaultFolderIdToNameMap,
        ]);
    }
);

export const setAccountConfiguration = (
    configurationOrigin: AccountConfigurationOrigin,
    mailboxInfo: MailboxInfo,
    userConfiguration: OwaUserConfiguration,
    forceIsGlobalAccountMailboxInfoResult: boolean
) => {
    logSetAccountConfiguration(
        configurationOrigin,
        mailboxInfo,
        forceIsGlobalAccountMailboxInfoResult
    );

    setAccountConfigurationInternal(
        mailboxInfo,
        userConfiguration,
        forceIsGlobalAccountMailboxInfoResult
    );

    if (forceIsGlobalAccountMailboxInfoResult /*isGlobalSettingsMailbox(mailboxInfo)*/) {
        const globalConfig = getRawStoreInternalOnly().userConfiguration;
        if (globalConfig) {
            userConfigurationSet(globalConfig);
        }
    } else {
        const indexerValue = getIndexerValueForMailboxInfo(mailboxInfo);
        const accountConfig = getRawStoreInternalOnly().nonGlobalAccountDataMap.get(indexerValue);
        if (accountConfig) {
            userConfigurationSet(accountConfig, indexerValue, mailboxInfo);
        }
    }
};
