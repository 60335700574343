import React from 'react';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * This is used to render the app root, which is allowed */
import { render } from 'owa-react-dom';
import {
    addBottleneck,
    addBootTiming,
    markFunction,
    safeRequestAnimationFrame,
} from 'owa-performance';
import { getWaitForReact } from './whyDidYouRender';
import { setScriptsLoadedAtPlt } from 'owa-shared-post-boot/lib/scriptsLoadedAtPlt';
import { isFeatureEnabled } from 'owa-feature-flags';

export default async function asyncRender(
    node: JSX.Element,
    getContainer: () => HTMLElement
): Promise<void> {
    const waitForReact = getWaitForReact();
    if (waitForReact) {
        await waitForReact;
    }
    return new Promise<void>(function (resolve: () => void, reject: (reason: any) => void) {
        function onMount() {
            setScriptsLoadedAtPlt();
            const loadingScreen = document.getElementById('loadingScreen');
            if (loadingScreen && loadingScreen.parentNode == document.body) {
                addBootTiming('ls_e');
                safeRequestAnimationFrame(() => {
                    document.body.removeChild(loadingScreen);
                });
            }

            // resolve the promise as a new event on the event loop
            // this gives time for the browser to paint the DOM
            setTimeout(resolve);
        }
        function Component() {
            React.useEffect(onMount, []);
            return node;
        }

        const asyncRenderInternal = markFunction((bottleneck: string) => {
            addBottleneck('DR', bottleneck);
            try {
                render(<Component />, getContainer());
            } catch (error) {
                reject(error);
            }
        }, 'ren');

        if (window.document.readyState != 'loading') {
            if (isFeatureEnabled('fwk-worker-preload')) {
                // This is a workaround to make sure postMesage to worker leaves the main thread sooner
                // Once we graduate the flight and are able to move preload up in the boot sequence, we can remove this
                setTimeout(() => asyncRenderInternal('R'), 0);
            } else {
                asyncRenderInternal('R');
            }
        } else if (window.document.addEventListener as any) {
            addDomLoadedEvent(asyncRenderInternal);
        } else {
            window.onload = () => asyncRenderInternal('OL');
        }
    });
}

const domLoadedEvent = 'DOMContentLoaded';
function addDomLoadedEvent(asyncRenderInternal: (bottleneck: string) => void) {
    function domLoaded() {
        window.document.removeEventListener(domLoadedEvent, domLoaded);
        asyncRenderInternal('EL');
    }
    window.document.addEventListener(domLoadedEvent, domLoaded, false);
}
