import { type GroupFolderWellKnownName } from 'owa-folders-constants';
import type { MailboxInfo } from 'owa-client-types';
import { getGroupFolderId } from './getGroupFolderId';
import { getGroupFolder } from './getGroupFolder';

/**
 * @param groupId Smtp address of the group
 * @param mailboxInfo mailbox info of the account
 * @returns Root Folder Id of the specified Group
 */
export function getGroupRootFolderId(
    groupId: string,
    mailboxInfo: MailboxInfo
): string | undefined {
    return getGroupFolder(groupId, getGroupFolderId(groupId, 'inbox', mailboxInfo), mailboxInfo)
        ?.ParentFolderId;
}
