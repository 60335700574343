import { OcpsStoreState } from '../store/schema/OcpsPolicyStore';
import getOcpsPolicyStoreState from '../selectors/getOcpsPolicyStoreState';
import { shouldRetryFetchPoliciesAndSetStore } from './shouldRetryFetchPoliciesAndSetStore';
import { logUsage } from 'owa-analytics';

export default function isOcpsStoreReady(): boolean {
    const storeState = getOcpsPolicyStoreState();
    const isStoreReady =
        storeState === OcpsStoreState.Ready || storeState === OcpsStoreState.FailedRetry;

    if (storeState === OcpsStoreState.FailedRetry) {
        shouldRetryFetchPoliciesAndSetStore();
        logUsage('OcpsStoreFetchRetry');
    }

    return isStoreReady;
}
