import type ConversationsOptionState from './schema/ConversationsOptionState';
import { createStore } from 'satcheljs';

export default createStore<ConversationsOptionState>('conversationsOption', {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (8,5): Type 'null' is not assignable to type 'ConversationSortOrder'.
    // @ts-expect-error
    conversationSortOrder: null,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (12,5): Type 'null' is not assignable to type 'boolean'.
    // @ts-expect-error
    hideDeletedItems: null,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (16,5): Type 'null' is not assignable to type 'ReactListViewType'.
    // @ts-expect-error
    globalListViewType: null,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (20,5): Type 'null' is not assignable to type 'boolean'.
    // @ts-expect-error
    readingPaneConversationEnabled: null,
})();
