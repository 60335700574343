import getGroupDetails from './getGroupDetails';
import type UnifiedGroupDetails from 'owa-service/lib/contract/UnifiedGroupDetails';
import type { MailboxInfo } from 'owa-client-types';

export const getGroupMailboxExternalId = (
    mailboxInfo: MailboxInfo,
    group: string | UnifiedGroupDetails
): string | null => {
    const groupDetails = typeof group === 'string' ? getGroupDetails(mailboxInfo, group) : group;
    if (!groupDetails) {
        return null;
    }

    return groupDetails.ExternalDirectoryObjectId ?? null;
};
