import { getSelectedTableView } from 'owa-mail-list-store';
import type { ActionSource } from 'owa-analytics-types';
import { lazyReportItems } from 'owa-mail-triage-action';
import { ReportType } from 'owa-reporting';

export default function onMarkAsPhishing(actionSource: ActionSource) {
    const tableView = getSelectedTableView();
    const rowKeys = [...tableView.selectedRowKeys.keys()];

    lazyReportItems.importAndExecute(ReportType.Phishing, rowKeys, tableView, actionSource);
}
