import { action } from 'satcheljs';
import type EmailAddressWrapper from 'owa-service/lib/contract/EmailAddressWrapper';
import type { MailboxInfo } from 'owa-client-types';

const onMailServiceNewMessage = action(
    'onMailServiceNewMessage',
    (
        mailboxInfo: MailboxInfo,
        toEmailAddressWrappers?: EmailAddressWrapper[],
        ccEmailAddressWrappers?: EmailAddressWrapper[],
        bccEmailAddressWrappers?: EmailAddressWrapper[],
        subject?: string,
        body?: string
    ) => ({
        mailboxInfo,
        toEmailAddressWrappers,
        ccEmailAddressWrappers,
        bccEmailAddressWrappers,
        subject,
        body,
    })
);

export default onMailServiceNewMessage;
