import type { VirtualElement } from '@popperjs/core/lib/types';
import type { IPoint } from '@fluentui/react/lib/Utilities';
import type { MenuProps } from '@fluentui/react-components';

export function usePositioning(target: IPoint | undefined): MenuProps['positioning'] {
    function createVirtualElement(
        left: number,
        top: number,
        right: number = left + 1,
        bottom: number = top + 1
    ): VirtualElement {
        const rect = {
            top,
            left,
            bottom,
            right,
            x: left,
            y: top,
            height: bottom - top,
            width: right - left,
        };
        return {
            getBoundingClientRect(): DOMRect {
                return {
                    ...rect,
                    toJSON() {
                        return rect;
                    },
                };
            },
        };
    }

    const positioning: MenuProps['positioning'] = {};
    // Target
    positioning.target = createVirtualElement(target?.x ?? 0, target?.y ?? 0);
    positioning.position = 'after';
    positioning.align = 'top';
    positioning.autoSize = 'height';

    return positioning;
}
