import type FolderTreeViewStateStore from './schema/FolderTreeViewStateStore';
import type MailFolderNodeViewState from './schema/MailFolderNodeViewState';
import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';
import { getShouldOverrideToSortBySizeBasedOnURL } from '../utils/getShouldOverrideToSortBySizeBasedOnURL';

// Import mutators so they are initialized at the same time as the store
import '../mutators/updateFolderCountsMutator';

const initialFolderTreeViewStateStore: FolderTreeViewStateStore = {
    folderNodeViewStates: new ObservableMap<string, MailFolderNodeViewState>({}),
    contextMenuState: null,
    folderTextFieldViewState: null,
    isDraggedOver: false,
    withContextMenuFolderId: null,
    shouldOverrideToSortBySizeBasedOnURL: getShouldOverrideToSortBySizeBasedOnURL(),
    customOrderCalloutState: null,
};

const store = createStore<FolderTreeViewStateStore>(
    'folderViewState',
    initialFolderTreeViewStateStore
)();
export default store;
export const getStore = () => store;
