import type { NovaEvent } from '@nova/types';
import type { AppHostServiceEventOriginator } from './originator';

export const StartCallViaPhoneUrlEventType = 'startCallViaPhoneUrl' as const;

export interface StartCallViaPhoneUrlEvent extends NovaEvent<StartCallViaPhoneUrlEventData> {
    originator: typeof AppHostServiceEventOriginator;
    type: typeof StartCallViaPhoneUrlEventType;
    data: () => StartCallViaPhoneUrlEventData;
}

export interface StartCallViaPhoneUrlEventData {
    phoneUrl: string;
}
