import type { MailboxInfo } from 'owa-client-types';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - satchel stores in the worker will need their data synced
 *	> 'createStore' import from 'satcheljs' is restricted. Satchel stores should not be created in the worker as the data will need to be synced */
import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';

const clientIdStore = createStore('OWAClientIdStore', {
    userMailboxInfoMap: new ObservableMap<string, MailboxInfo>(),
})();

const getClientIdStore = () => clientIdStore;
export default getClientIdStore;
