import { lazyFetchAndOpenLaunchPageMetaOsApp } from 'owa-app-host-state/lib/lazyFunctions';
import { isFeatureEnabled } from 'owa-feature-flags';

export const onNewEventifyEvent = () => {
    if (isFeatureEnabled('mos-eventsApp-prod')) {
        lazyFetchAndOpenLaunchPageMetaOsApp.importAndExecute({
            appId: '260f432d-8a76-46c2-bf48-0f0748ac3eba',
            entityId: 'c0adaf60-11f1-4f72-bef6-f44e36877983',
            callerFn: 'NewLargeEventFromMailRibbon',
        });
    } else if (isFeatureEnabled('mos-eventsApp')) {
        lazyFetchAndOpenLaunchPageMetaOsApp.importAndExecute({
            appId: '8527a80e-88e8-4473-bda1-ab1bddcf72e0',
            entityId: '4e392658-8f5f-488a-a476-d3db83f46131',
            callerFn: 'NewLargeEventFromCalendarRibbon',
        });
    } else {
        window.open('/eventify/newEvent', '_blank');
    }
};
