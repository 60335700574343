import { getMailboxJunkEmailConfigurationStore } from '../store/store';
import { assertNever } from 'owa-assert';
import SpamScenarioId from '../store/schema/SpamScenarioId';
import type MailboxJunkEmailConfigurationOptions from 'owa-service/lib/contract/MailboxJunkEmailConfigurationOptions';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Returns a subset of mailbox junk email configuration object which is observable depending on
 * which scenarioId is provided. Ex: Safe senders, Safe domains etc.
 */
export function getMailboxJunkEmailListByScenarioId(
    scenarioId: SpamScenarioId,
    mailboxInfo: MailboxInfo
): string[] | undefined {
    const store = getMailboxJunkEmailConfigurationStore(mailboxInfo);
    switch (scenarioId) {
        case SpamScenarioId.BlockedSendersAndDomains:
            return store.mailboxJunkEmailConfiguration.BlockedSendersAndDomains;
        case SpamScenarioId.SafeSendersAndDomains:
            return store.mailboxJunkEmailConfiguration.TrustedSendersAndDomains;
        case SpamScenarioId.SafeMailingLists:
            return store.mailboxJunkEmailConfiguration.TrustedRecipientsAndDomains;
        default:
            return assertNever(scenarioId);
    }
}

export function getMailboxJunkEmailIsTrustedListsOnly(mailboxInfo: MailboxInfo): boolean {
    const store = getMailboxJunkEmailConfigurationStore(mailboxInfo);
    return !!store.mailboxJunkEmailConfiguration.TrustedListsOnly;
}

export function getMailboxJunkEmailIsContactsTrusted(mailboxInfo: MailboxInfo): boolean {
    const store = getMailboxJunkEmailConfigurationStore(mailboxInfo);
    return !!store.mailboxJunkEmailConfiguration.ContactsTrusted;
}

export function getMailboxJunkEmailIsSenderScreening(mailboxInfo: MailboxInfo): boolean {
    const store = getMailboxJunkEmailConfigurationStore(mailboxInfo);
    return !!store.mailboxJunkEmailConfiguration.SenderScreeningEnabled;
}

export function getStoredMailboxJunkEmailConfiguration(
    mailboxInfo: MailboxInfo
): MailboxJunkEmailConfigurationOptions {
    const store = getMailboxJunkEmailConfigurationStore(mailboxInfo);
    return store.mailboxJunkEmailConfiguration;
}
