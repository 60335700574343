import store from '../store/Store';
import type {
    AccountFeatureName,
    GlobalFeatureName,
    FeatureName,
} from '../store/featureDefinitions';
import { errorThatWillCauseAlert, type TraceErrorObject } from 'owa-trace';
import { areFeatureFlagsInitialized } from '../actions/initializeFeatureFlags';
import type { MailboxInfo } from 'owa-client-types';
import isAnonymousFlightingEnabled from './anonymousFlighting';
import getFeatureFlagsIndexerForMailboxInfo from './getFeatureFlagsIndexerForMailboxInfo';
import { getGlobalSettingsAccountIndexer } from 'owa-account-source-list-store';
import getAccountDiagnosticDataForMailboxInfo from 'owa-account-source-list-store/lib/utils/getAccountDiagnosticDataForMailboxInfo';
import { isTryLookupIndexerFuncSet, tryLookupIndexer } from 'owa-client-types/lib/tryLookupIndexer';
import { checkIfFeatureCheckWouldHaveThrownBeforeAliasLookup } from './checkIfFeatureCheckWouldHaveThrownBeforeAliasLookup';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Temporarily needed for a deployment issue
 *	> 'getHostValue' import from 'owa-config' is restricted. Please use isFeatureEnabled/isBootFeatureEnabled instead. Host value is checked with ECS/CT as a filter. */
import { getApp, isRunningOnWorker, getHostValue } from 'owa-config';

/**
 * Returns the diagnostic information that should be logged to help diagnose issues with feature flags
 * @param feature Specifies the feature for which the diagnostic information is being requested
 * @param mailboxInfo Optionally specifies the mailbox info for which the diagnostic information is being requested
 */
function getDiagnosticInfo(feature: FeatureName, mailboxInfo?: MailboxInfo) {
    const accountDiagnostic = getAccountDiagnosticDataForMailboxInfo(mailboxInfo);
    const globalSettingsIndexer = getGlobalSettingsAccountIndexer(/*throwIfNotInitialized*/ false);
    const tryIndexer = !!mailboxInfo ? tryLookupIndexer(mailboxInfo) : undefined;
    const featureFlagsIndexer = getFeatureFlagsIndexerForMailboxInfo(mailboxInfo);

    return {
        feature,
        hasMailboxInfo: !!mailboxInfo,
        mailboxType: mailboxInfo?.type,
        hasUserId: !!mailboxInfo?.userIdentity,
        hasSmtp: !!mailboxInfo?.mailboxSmtpAddress,
        rank: mailboxInfo?.mailboxRank,
        diagnosticData: mailboxInfo?.diagnosticData,
        isAnonymous: mailboxInfo?.isAnonymous,
        isRemoved: mailboxInfo?.isRemoved,
        hasIndexer: featureFlagsIndexer !== '',
        isGlobalInitalized: areFeatureFlagsInitialized(),
        isTryLookupSet: isTryLookupIndexerFuncSet(),
        hasGlobalSettingsIndexer: !!globalSettingsIndexer,
        hasTryIndexer: !!tryIndexer,
        isTryIndexerGSA: tryIndexer === globalSettingsIndexer,
        isTryIndexderFF: tryIndexer === featureFlagsIndexer,
        isTryIndexderUID: tryIndexer === mailboxInfo?.userIdentity,
        ...accountDiagnostic,
    };
}

function isFeatureEnabledInternal(
    feature: FeatureName,
    mailboxInfo?: MailboxInfo,
    dontThrowErrorIfNotInitialized?: boolean
): boolean {
    checkIfFeatureCheckWouldHaveThrownBeforeAliasLookup(
        feature,
        mailboxInfo,
        dontThrowErrorIfNotInitialized,
        getDiagnosticInfo
    );
    if (!areFeatureFlagsInitialized(mailboxInfo) && !process.env.JEST_WORKER_ID) {
        if (dontThrowErrorIfNotInitialized || isAnonymousFlightingEnabled()) {
            return false;
        }

        // We are adding this clue to see if the flags were not initialized for a specific mailbox info.
        const info = getDiagnosticInfo(feature, mailboxInfo);

        const app = getApp();

        // We are using different error messages based on the type of bugs we are seeing currently in telemetry
        let error: TraceErrorObject;
        if (isRunningOnWorker()) {
            // Typically caused by a systemic problem in sync or the action queue
            error = new Error('Attempted to read feature flag too early on worker');
        } else if (mailboxInfo && !info.hasAccount) {
            // How are we getting and using a mailboxInfo which is not in the accounts list?
            error =
                getHostValue() == 'teamshub'
                    ? new Error('[TeamsHub] Attempted to read feature flag from unknown mailbox')
                    : new Error('Attempted to read feature flag from unknown mailbox');
        } else if (!mailboxInfo || info.isGlobalSettingsAccount) {
            // Typically a feature specific bug where we are looking at a feature flag too early in boot
            error = new Error('Attempted to read global account feature flag too early');
        } else if (info.isRemoved) {
            // Why are we looking at feature flags for a removed account?
            error = new Error(
                'Attempted to read secondary account feature flag of removed account'
            );
        } else if (app == 'MailDeepLink') {
            // We seems to have a bug where we render the pop out of a secondary account mail item
            // before we initialize the secondary account
            error = new Error(
                'Attempted to read secondary account feature flag too early in MailDeepLink'
            );
        } else {
            // Unknown remaining issue
            error = new Error('Attempted to read secondary account feature flag too early');
        }
        error.additionalInfo = info;
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * The error name (message) must be a string literal (no variables in it).
         *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
        errorThatWillCauseAlert(error);
    }

    const result = store(mailboxInfo).featureFlags.get(feature.toLowerCase()) || false;

    return result;
}

/**
 * Checks a feature flag against the global settings account.
 * @param feature Specifies the feature to be checked
 * @param mailboxInfo (Obsolete) mailbox information for the account to be checked
 * @param dontThrowErrorIfNotInitialized True if an error should not be thrown if the feature flags are not initialized
 * @returns True if the feature is enabled, false otherwise
 */
export default function isFeatureEnabled(
    feature: GlobalFeatureName,
    mailboxInfo?: MailboxInfo,
    dontThrowErrorIfNotInitialized?: boolean
): boolean {
    return isFeatureEnabledInternal(feature, mailboxInfo, dontThrowErrorIfNotInitialized);
}

/**
 * Checks if an account scoped feature is enabled. To identify an account scoped feature, the feature
 * name must be included in the AccountScopedFeatures union in the ./store/featureDefinitions.ts file
 * @param feature Specified the feature to be checked
 * @param mailboxInfo Mailbox within the account to be checked
 * @param dontThrowErrorIfNotInitialized True if an error should not be thrown if the feature flags are not initialized
 * @returns True if the feature is enabled, false otherwise
 */
export function isAccountFeatureEnabled(
    feature: AccountFeatureName,
    mailboxInfo: MailboxInfo,
    dontThrowErrorIfNotInitialized?: boolean
): boolean {
    return isFeatureEnabledInternal(feature, mailboxInfo, dontThrowErrorIfNotInitialized);
}
