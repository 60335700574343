import React from 'react';
import { CommandingModeMenu } from 'owa-commanding-mode-menu';
import { modeMenuContainer } from './RibbonModeMenuContainer.scss';

const RibbonModeMenuContainer = React.memo(function RibbonModeMenuContainer() {
    return (
        <div className={modeMenuContainer}>
            <CommandingModeMenu />
        </div>
    );
});

export { RibbonModeMenuContainer };
