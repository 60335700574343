import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getUnifiedGroupsSettingsStore } from '../UnifiedGroupsSettingsStore';
import type { UnifiedGroupsSettingsState } from '../schema/UnifiedGroupsSettingsState';
import { GroupAccessType } from '../schema/UnifiedGroupsSettingsState';

export function getUnifiedGroupsSettings(mailboxInfo: MailboxInfo) {
    const index = getIndexerValueForMailboxInfo(mailboxInfo);
    const groupsSettings =
        getUnifiedGroupsSettingsStore().unifiedGroupsSettingsByMailboxInfo.get(index);

    if (!groupsSettings) {
        const defaultUnifiedGropusSettings: UnifiedGroupsSettingsState = {
            supportedClassifications: [],
            namingPolicySettings: {},
            groupsGuidelinesLink: '',
            orgAllowAddGuests: false,
            groupCreationEnabled: false,
            isSensitivityLabelsEnabled: false,
            IsGroupFoldersAndRulesEnabled: false,
            BlockMoveMessagesForGroupFolders: false,
            IsGroupMemberAllowedToEditContent: false,
            defaultClassification: '',
            defaultGroupAccessType: GroupAccessType.Private,
        };

        getUnifiedGroupsSettingsStore().unifiedGroupsSettingsByMailboxInfo.set(
            index,
            defaultUnifiedGropusSettings
        );
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        return getUnifiedGroupsSettingsStore().unifiedGroupsSettingsByMailboxInfo.get(index)!;
    }

    return groupsSettings;
}

export function getDefaultGroupAccessType(mailboxInfo: MailboxInfo) {
    return getUnifiedGroupsSettings(mailboxInfo).defaultGroupAccessType ?? GroupAccessType.Private;
}

export function getDefaultClassification(mailboxInfo: MailboxInfo) {
    return getUnifiedGroupsSettings(mailboxInfo).defaultClassification ?? '';
}

export function getSupportedClassification(mailboxInfo: MailboxInfo) {
    return getUnifiedGroupsSettings(mailboxInfo).supportedClassifications;
}

export function getNamingPolicySettings(mailboxInfo: MailboxInfo) {
    return getUnifiedGroupsSettings(mailboxInfo).namingPolicySettings;
}

export function getGroupsGuidelinesLink(mailboxInfo: MailboxInfo) {
    return getUnifiedGroupsSettings(mailboxInfo).groupsGuidelinesLink;
}

export function getOrgAlloAddGuests(mailboxInfo: MailboxInfo) {
    return getUnifiedGroupsSettings(mailboxInfo).orgAllowAddGuests;
}

export function getGroupCreationEnabled(mailboxInfo: MailboxInfo) {
    return getUnifiedGroupsSettings(mailboxInfo).groupCreationEnabled;
}

export function getIsSensitivityLabelsEnabled(mailboxInfo: MailboxInfo) {
    return getUnifiedGroupsSettings(mailboxInfo).isSensitivityLabelsEnabled;
}

export function getIsGroupFoldersAndRulesEnabled(mailboxInfo: MailboxInfo) {
    return getUnifiedGroupsSettings(mailboxInfo).IsGroupFoldersAndRulesEnabled;
}

export function getBlockMoveMessagesForGroupFolders(mailboxInfo: MailboxInfo) {
    return getUnifiedGroupsSettings(mailboxInfo).BlockMoveMessagesForGroupFolders;
}

export function getIsGroupMemberAllowedToEditContent(mailboxInfo: MailboxInfo) {
    return getUnifiedGroupsSettings(mailboxInfo).IsGroupMemberAllowedToEditContent;
}
