import type { FavoriteData } from 'owa-favorites-types';
import favoritesStore from '../../store/store';
import getSecondaryKey from '../../utils/getSecondaryKey';

export default function setFavoriteDataInStore(favoriteData: FavoriteData) {
    const { favoriteSecondaryKeyMap, outlookFavorites } = favoritesStore;

    favoriteData.treeType = 'favorites';
    outlookFavorites.set(favoriteData.favoriteId, favoriteData);

    const secondaryKey = getSecondaryKey(favoriteData);
    if (secondaryKey) {
        favoriteSecondaryKeyMap.set(secondaryKey, favoriteData.favoriteId);
    }
}
