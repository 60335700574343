import type { MailboxInfo } from 'owa-client-types';
import { getGroupInformation } from '../selectors/getGroupInformation';
import getGroupActivityState from '../selectors/getGroupActivityState';

export default function updateGroupMembershipRequestData(
    mailboxInfo: MailboxInfo,
    groupId: string
) {
    const groupInformation = getGroupInformation(mailboxInfo, groupId);
    const membershipRequests = groupInformation?.membershipRequests;

    if (membershipRequests) {
        const memberRequestsData = Array.from(membershipRequests.memberRequestsList.values()).map(
            ({ memberRequest }) => {
                return {
                    groupId,
                    requestedUserId: memberRequest.RequestedUser?.EmailAddress?.EmailAddress ?? '',
                    requestedUserLabel: memberRequest.RequestedUser?.DisplayName ?? '',
                    requestorUserId: memberRequest.Requestor?.EmailAddress?.EmailAddress ?? '',
                    requestorUserLabel: memberRequest.Requestor?.DisplayName ?? '',
                    timeStamp: new Date(memberRequest.RequestedDate ?? ''),
                };
            }
        );

        const groupActivityState = getGroupActivityState(mailboxInfo);
        groupActivityState.memberRequestsData.push(...memberRequestsData);
    }
}
