import TreeNode from 'owa-tree-node/lib/components/TreeNode';
import React from 'react';
import Strings from 'owa-folders-constants/lib/strings/LoadMore.locstring.json';
import loc from 'owa-localize';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

export interface LoadMoreNodeProps {
    hasMoreData: boolean;
    shouldShowLoadingSpinner: boolean;
    offset: number;
    fetchMore: (offset: number) => Promise<void>;
}

export default function LoadMore(props: LoadMoreNodeProps) {
    const [loadMoreInProgress, setLoadMoreInProgress] = React.useState<boolean>(false);
    const { fetchMore, offset } = props;

    const onLoadMoreClicked = React.useCallback(async () => {
        setLoadMoreInProgress(true);
        await fetchMore(offset);
        setLoadMoreInProgress(false);
    }, [fetchMore, offset]);

    const shouldShowLoadingSpinner = props.shouldShowLoadingSpinner || loadMoreInProgress;

    return (
        <>
            {/* Loading spinner */}
            {shouldShowLoadingSpinner && <Spinner size={SpinnerSize.medium} />}

            {/* Load more action button */}
            {!shouldShowLoadingSpinner && props.hasMoreData && (
                <TreeNode
                    displayName={loc(Strings.loadMoreFoldersAction)}
                    isCustomActionNode={true}
                    isRootNode={false}
                    key={'loadMore'}
                    onClick={onLoadMoreClicked}
                />
            )}
        </>
    );
}
