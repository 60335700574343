import { lazyGetLayoutSections } from 'owa-mail-ribbon-sections/lib/sections';

import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onLayoutMenuClicked = (props: ReadOnlyRibbonControlDefProps) => {
    lazyGetLayoutSections.import().then(getLayoutSections => {
        setMenuDefinition('LayoutMenuDefinitionStore', undefined /* projectionTabId */, () => {
            return {
                sections: getLayoutSections(3, props),
            };
        });
    });
};
