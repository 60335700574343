import {
    type ListViewBitFlagsMasks,
    setBitAndUpdateAccountScopeUserSettingsAndService,
} from '../utils/listViewBitFlagsUtil';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export function setShouldHideFavoritesList(shouldHideFavoritesList: boolean) {
    const globalAccountMailboxInfo = getGlobalSettingsAccountMailboxInfo();
    setBitAndUpdateAccountScopeUserSettingsAndService(
        shouldHideFavoritesList,
        16384,
        globalAccountMailboxInfo
    );
    return shouldHideFavoritesList;
}
