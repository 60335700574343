import {
    sizeWithGigabyteAbbreviationText,
    sizeWithMegabyteAbbreviationText,
    sizeWithKilobyteAbbreviationText,
    sizeWithByteAbbreviationText,
} from './numberToByteQuantifiedStringConverter.locstring.json';
import loc, { format } from 'owa-localize';

export default function numberToByteQuantifiedStringConverter(bytes: number): string {
    let formatString: string;
    if (bytes >= 1073741824) {
        // GB
        bytes /= 1073741824;
        formatString = loc(sizeWithGigabyteAbbreviationText);
    } else if (bytes >= 1048576) {
        // MB
        bytes /= 1048576;
        formatString = loc(sizeWithMegabyteAbbreviationText);
    } else if (bytes >= 1024) {
        // KB
        bytes /= 1024;
        formatString = loc(sizeWithKilobyteAbbreviationText);
    } else {
        formatString = loc(sizeWithByteAbbreviationText);
    }

    return format(formatString, Math.round(bytes));
}
