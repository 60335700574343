import type TableView from '../store/schema/TableView';
import { type TableQueryType } from '../store/schema/TableQuery';
import { isSearchFolderScenario } from '../selectors/isSearchFolderScenario';
import type MailFolderTableQuery from '../store/schema/MailFolderTableQuery';

/**
 * @param tableView for which we want to get the context folder id
 * @param returnFolderScopeIfEnabled returns context folder id as the original folder Id if the flight is enabled
 * @return Returns folderId for the table view
 */
export default function getContextFolderIdForTable(
    tableView: TableView,
    returnFolderScopeIfEnabled?: boolean
): string | undefined {
    switch (tableView.tableQuery.type) {
        case 0:
            const scenarioType = (tableView.tableQuery as MailFolderTableQuery).scenarioType;
            if (returnFolderScopeIfEnabled && !isSearchFolderScenario(scenarioType)) {
                return tableView.tableQuery.folderId;
            }
            return tableView.serverFolderId;
        case 3:
            return tableView.tableQuery.folderId.split('-').join('/').split('_').join('+');
        case 1:
        case 2:
        default:
            return undefined;
    }
}
