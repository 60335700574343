import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { isPublicFolder } from 'owa-folders/lib/util/idConverter';
import getFolderTable from 'owa-folders/lib/selectors/getFolderTable';
import type { MailFolder } from 'owa-graph-schema';
import { isCapabilityEnabled } from 'owa-capabilities';
import { conversationViewCapability } from 'owa-capabilities-definitions/lib/conversationViewCapability';

const MESSAGE_VIEW_ONLY_FOLDER_NAMES = [
    'drafts',
    'junkemail',
    'recoverableitemsdeletions',
    'outbox',
];

/**
 * Get the list view type for the folder
 * @param folderId the folder id
 */
export default function getListViewTypeForFolder(folderId: string | undefined): ReactListViewType {
    // Returns message view if the folder is default to message view, if
    // folder is or under archive mailbox or shared folders, or if
    // folder is a public folder
    let folder;
    if (folderId) {
        folder = getFolderTable().get(folderId);
    }

    return getListViewTypeForFolderV2(folder);
}

/**
 * Returns the list view type to use depending on the folder
 * @param folder
 */
export function getListViewTypeForFolderV2(folder?: MailFolder): ReactListViewType {
    // Check if folder is undefined : happens during the initial boot, when the folder table has not been populated

    if (
        folder &&
        ((folder.distinguishedFolderType &&
            MESSAGE_VIEW_ONLY_FOLDER_NAMES.indexOf(folder.distinguishedFolderType) != -1) ||
            !isCapabilityEnabled(conversationViewCapability, folder.mailboxInfo) ||
            isPublicFolder(folder.id))
    ) {
        return 1;
    }

    // Return the GlobalListViewTypeReact in user options
    // For backward compatibility, return conversation view for users on utah version less than 15.20.0059.000
    // Because users don't have GlobalListViewTypeReact in the UserOptions
    return getUserConfiguration()?.UserOptions?.GlobalListViewTypeReact || 0;
}
