const GUID_REGEX = /^[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}$/;

function s4() {
    return Math.floor((1 + Math.random()) * 65536)
        .toString(16)
        .substring(1);
}

export function getGuid(): string {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function isGuid(str: string | undefined): boolean {
    return !!str && GUID_REGEX.test(str);
}
