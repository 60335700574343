import NavigationManager from './NavigationManager';

export class FolderPaneNavigationManager {
    private static instance = new NavigationManager();

    public static init(ref: React.RefObject<HTMLElement>) {
        const self = FolderPaneNavigationManager;
        self.instance.init(ref);
    }

    public static handleInput(event: React.KeyboardEvent) {
        FolderPaneNavigationManager.instance.handleInput(event);
    }

    public static resetSearchResults() {
        FolderPaneNavigationManager.instance.resetSearchResults();
    }
}
