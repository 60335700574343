import { mutator } from 'satcheljs';
import {
    addFavoriteCompleted,
    addFavoriteToStore,
    addFavoriteToStoreInitial,
    addMultipleFavoritesCompleted,
    addMultipleFavoritesToStore,
    addFavoriteFailed,
} from '../actions/v2/addFavoriteActions';
import addFavoriteDataToStore from './helpers/addFavoriteDataToStore';
import removeFavoriteDataFromStore from './helpers/removeFavoriteDataFromStore';
import {
    addFavoriteDataInProgress,
    removeFavoriteDataInProgress,
} from './helpers/favoritingInProgressHelpers';
import type { MailboxInfo } from 'owa-client-types';
import isMultiAccountsCombinedFavoritesEnabled from '../utils/isMultiAccountsCombinedFavoritesEnabled';
import setFavoriteDataInStore from './helpers/setFavoriteDataInStore';
import setUnusedFavoriteDataInStore from './helpers/setUnusedFavoriteDataInStore';
import { isFavoriteInCache } from './helpers/offlineCacheHelpers';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(addFavoriteCompleted, actionMessage => {
    const { temporaryData, favoriteData, newIndex } = actionMessage;

    // Temp data will contain mailboxinfo data, so set it to the
    // favorite data that will be persisted in store
    favoriteData.mailboxInfo = temporaryData.mailboxInfo;

    // Cleanup temporary data
    removeFavoriteDataFromStore(temporaryData);

    // Remove favorite data to in progress map
    removeFavoriteDataInProgress(temporaryData);

    // Add new data
    addFavoriteDataToStore(favoriteData, newIndex);
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(addFavoriteToStore, actionMessage => {
    const { favoriteData, newIndex } = actionMessage;

    // Adds favorite data to the store.
    addFavoriteDataToStore(favoriteData, newIndex);

    // Add favorite data to in progress map
    addFavoriteDataInProgress(favoriteData);
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(addFavoriteToStoreInitial, actionMessage => {
    const { favoriteData } = actionMessage;

    if (isMultiAccountsCombinedFavoritesEnabled()) {
        // If the favorite is in the combined favorites list, then fill in the data in the cache
        if (isFavoriteInCache(favoriteData)) {
            setFavoriteDataInStore(favoriteData);
        }
        // If it's not, but it's the primary account, add the favorite
        else if (
            getIndexerValueForMailboxInfo(favoriteData.mailboxInfo) ===
            getIndexerValueForMailboxInfo(getGlobalSettingsAccountMailboxInfo())
        ) {
            // Adds favorite data to the store.
            addFavoriteDataToStore(favoriteData);
        }
        // Otherwise, add the favorite data to the unused favorite list
        // In combined favorites mode, secondary favorites are not automatically shown,
        // but we save them here for easier add later if the user chooses to do so.
        else {
            setUnusedFavoriteDataInStore(favoriteData);
        }
    } else {
        // Adds favorite data to the store.
        addFavoriteDataToStore(favoriteData);
    }
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(addFavoriteFailed, actionMessage => {
    const { favoriteData } = actionMessage;
    // Remove favorite data to in progress map
    removeFavoriteDataInProgress(favoriteData);

    //also remove tempData from the store
    removeFavoriteDataFromStore(favoriteData);
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(addMultipleFavoritesCompleted, actionMessage => {
    const { temporaryFavorites, favoritesData } = actionMessage;
    let mailboxInfo: MailboxInfo;

    // Cleanup
    // Temporary Favorites will contain mailboxinfo data, so set it to the
    // favorite data that will be persisted in store
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    temporaryFavorites.forEach(tempFavorite => {
        mailboxInfo = tempFavorite.mailboxInfo;
        removeFavoriteDataFromStore(tempFavorite);
    });

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    favoritesData.forEach(favorite => {
        favorite.mailboxInfo = mailboxInfo;
        addFavoriteDataToStore(favorite);
    });
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(addMultipleFavoritesToStore, actionMessage => {
    const { favoritesData } = actionMessage;

    favoritesData.forEach(favorite => {
        addFavoriteDataToStore(favorite);
    });
});
