import { getStore } from '../store/store';

/**
 * Returns whether a FlexPane is expanded.
 * If `flexPaneId` is supplied, will return whether that specific FlexPane is expanded.
 * If not supplied, will simply return whether the OWA FlexPane is expanded in general.
 */
export function isFlexPaneExpanded(flexPaneId?: string): boolean {
    if (flexPaneId) {
        return getStore().flexPaneStateMap.get(flexPaneId)?.isExpanded ?? false;
    }
    return getStore().currentFlexPaneState.isExpanded;
}
