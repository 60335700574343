import { mutatorAction } from 'satcheljs';
import { mailListItemAnimationStore } from '../store/Store';
import type { MailListItemAnimation } from '../store/schema/MailListItemAnimationStore';
import { logUsage } from 'owa-analytics';

export const removeActiveAnimation = mutatorAction(
    'removeActiveAnimation',
    (animationToRemove: MailListItemAnimation) => {
        mailListItemAnimationStore.activeAnimations =
            mailListItemAnimationStore.activeAnimations.filter(activeAnimation => {
                return activeAnimation.animationId !== animationToRemove.animationId;
            });
        if (mailListItemAnimationStore.activeAnimations.length === 0) {
            logUsage('MessageListAnimation_RemovedLastActiveAnimation');
        }
    }
);
