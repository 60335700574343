import React from 'react';
import { observer } from 'owa-mobx-react';
import TreeNode from 'owa-tree-node/lib/components/TreeNode';
import type { GroupNodeProps } from './GroupNode';
import { SourceMap } from 'owa-folders-constants';

// returns a default tree node for the lazy group node during loading
export default observer(function GroupNodePlaceholder(props: GroupNodeProps) {
    return (
        <TreeNode
            chevronProps={props.chevronProps}
            customIconComponent={props.customIconComponent}
            onRenderCustomIcon={props.onRenderCustomIcon}
            depth={props.nestDepth || 1}
            displayName={props.displayName}
            isBeingDragged={props.isBeingDragged}
            isDroppedOver={props.isDroppedOver}
            isRootNode={false}
            key={props.groupId}
            onContextMenu={props.onContextMenu}
            renderCustomTreeNodeDisplay={props.renderCustomTreeNodeDisplay}
            showAsHoverOnDroppedOver={props.showHoverStateOnDroppedOver}
            isFavorited={props.isFavorited}
            hideIcon={props.hideIcon}
            ellipsesOnHover={props.ellipsesOnHover}
            source={SourceMap.folder}
        />
    );
}, 'GroupNodePlaceholder');
