import type { MailboxInfo } from 'owa-client-types';
import { setItem } from 'owa-local-storage';

const OFFLINE_CONTACTS_FALLBACKONLINE_ENABLED_KEY = 'OWA-OFFLINE-CONTACTS-FALLBACKONLINE';
const OFFLINE_CONTACTS_FALLBACKONLINE_ENABLED_IN_SESSION_KEY =
    'OWA-OFFLINE-CONTACTS-FALLBACKONLINE-SESSION';

export function getOfflineContactsFallbackToOnlineLocalStorageKey(mailboxInfo: MailboxInfo) {
    return `${OFFLINE_CONTACTS_FALLBACKONLINE_ENABLED_KEY}-${mailboxInfo.sourceId}`;
}

export function getOfflineContactsFallbackToOnlineInSessionStorageKey(mailboxInfo: MailboxInfo) {
    return `${OFFLINE_CONTACTS_FALLBACKONLINE_ENABLED_IN_SESSION_KEY}-${mailboxInfo.sourceId}`;
}

export function toggleOfflineContacts(mailboxInfo: MailboxInfo, value: string) {
    setItem(self, getOfflineContactsFallbackToOnlineLocalStorageKey(mailboxInfo), value);
}

export function toggleOfflineContactsForOutlookSession(
    mailboxInfo: MailboxInfo,
    sessionId: string
) {
    setItem(self, getOfflineContactsFallbackToOnlineInSessionStorageKey(mailboxInfo), sessionId);
}
