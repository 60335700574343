import getSourceIdForUserIdentity from 'owa-account-source-list/lib/utils/getSourceIdForUserIdentity';
import { updateConnectedAccountState as updateConnectedAccountStateInStore } from 'owa-account-source-list-store';
import { logStartUsage } from 'owa-analytics-start';

// Updates the account associated with the specified user identity
export function updateConnectedAccountState(userIdentity: string, accountState: number) {
    const sourceId = getSourceIdForUserIdentity(userIdentity);
    const sourceIdFound = !!sourceId;
    const accountFound = false;
    if (sourceId) {
        updateConnectedAccountStateInStore(sourceId, accountState);
    }

    logStartUsage('updateConnectedAccountStateForAccountSourceList', {
        sourceIdFound,
        accountFound,
    });
}
