import getTableConversationRelation from './getTableConversationRelation';
import getTableItemRelation from './getTableItemRelation';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import type { InstrumentationContext } from 'owa-search-types/lib/types/InstrumentationContext';
import type TableView from '../store/schema/TableView';
import { mutatorAction } from 'satcheljs';

const updateInstrumentationContext = mutatorAction(
    'updateInstrumentationContext',
    (
        rowKey: string | undefined | null,
        tableView: TableView,
        instrumentationContext: InstrumentationContext
    ) => {
        if (!rowKey) {
            return;
        }
        switch (tableView.tableQuery.listViewType) {
            case 0:
                const tableConversationRelation = getTableConversationRelation(
                    rowKey,
                    tableView.id
                );
                if (tableConversationRelation) {
                    tableConversationRelation.instrumentationContext = instrumentationContext;
                }
                break;
            case 1:
                const tableItemRelation = getTableItemRelation(rowKey, tableView.id);
                tableItemRelation.instrumentationContext = instrumentationContext;
                break;
        }
    }
);

export default updateInstrumentationContext;
