import type { TraceErrorObject } from 'owa-trace';
import type { StatusAndType } from './getDatapointStatus';
import { DatapointStatus } from 'owa-analytics-types';

export function tryProcessGraphQLError(error?: TraceErrorObject): StatusAndType | undefined {
    // Check if the error object has an extensions object and the extensions object has code.
    // If not, we will let the regular datapoint categorize the error further
    const code = error?.extensions?.code;
    if (code == null) {
        return undefined;
    }

    switch (code) {
        case 'BAD_REQUEST_ERROR':
        case 'GraphqlClientError':
            return { status: DatapointStatus.ClientError };
        case 'USER_ERROR':
            return { status: DatapointStatus.UserError };
        case 'EXTERNAL_SERVICE_ERROR':
            return { status: DatapointStatus.ServerExpectedError, type: 'external' };
        case 'TRANSIENT_ERROR':
            return { status: DatapointStatus.ServerExpectedError, type: 'transient' };
        case 'GraphqlNetworkError':
            return { status: DatapointStatus.ServerExpectedError, type: 'network' };
        default:
            return { status: DatapointStatus.ServerError };
    }
}
