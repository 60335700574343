import type { AccountSource } from 'owa-account-source-list-store';
import { BootState, getCoprincipalAccountSourcesByBootState } from 'owa-account-source-list-store';
import { canUseToDoFeatures } from './moduleAccessUtils';

export function getToDoAccountSourcesByBootState(bootState: BootState): AccountSource[] {
    return getCoprincipalAccountSourcesByBootState(bootState).filter(accountSource =>
        canUseToDoFeatures(accountSource.mailboxInfo)
    );
}

export function getToDoAccountSources(): AccountSource[] {
    return getToDoAccountSourcesByBootState(BootState.StartupComplete);
}

export function getAllToDoAccountSources(): AccountSource[] {
    return getToDoAccountSourcesByBootState(BootState.StartupComplete).concat(
        getToDoAccountSourcesByBootState(BootState.Booting)
    );
}
