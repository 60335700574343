import { OWA_ITEM_CLIPBAORD_MIME } from '../constants';

const createOwaItemClipboardItem = (owaItemObject: any): ClipboardItem => {
    const jsonString = JSON.stringify(owaItemObject);
    const blob = new Blob([jsonString], { type: OWA_ITEM_CLIPBAORD_MIME });
    const clipboardItem = new ClipboardItem({ [OWA_ITEM_CLIPBAORD_MIME]: blob });
    return clipboardItem;
};

export default createOwaItemClipboardItem;
