import {
    getIsBitSet,
    type ListViewBitFlagsMasks,
} from 'owa-bit-flags/lib/utils/listViewBitFlagsUtil';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import getMailListTableProps from './getMailListTableProps';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export type UserSettingsStyleSelector = `${'SLV' | 'threeCol'}${
    | 'Item'
    | 'Conversation'}ViewSenderImage${'On' | 'Off'}`;

export default function getStyleSelectorAsPerUserSettings(
    tableViewId: string
): UserSettingsStyleSelector {
    const isSingleLineView = getMailListTableProps(tableViewId).isSingleLine;
    const isSenderImageOff = getIsBitSet(8, getGlobalSettingsAccountMailboxInfo());
    const isItemView = getMailListTableProps(tableViewId).listViewType === 1;

    return `${isSingleLineView ? 'SLV' : 'threeCol'}${
        isItemView ? 'Item' : 'Conversation'
    }ViewSenderImage${isSenderImageOff ? 'Off' : 'On'}`;
}
