import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { getFromStoreOrLocalStorageOrStartupData } from '../utils/getFromStoreOrLocalStorageOrStartupData';
import { isOptionalExperiencesAllowedByPolicy } from './isOptionalExperiencesAllowedByPolicy';
import { isPrivacyEnabled } from '../utils/isPrivacyRoamingEnabled';

export function isOptionalExperiencesDisabledForPrivacy(): boolean {
    if (!isPrivacyEnabled()) {
        return false;
    }

    if (isConsumer()) {
        return false;
    }

    const optionalExperiencesEnabled = getFromStoreOrLocalStorageOrStartupData(
        'optionalExperiencesEnabled'
    );

    return !optionalExperiencesEnabled || !isOptionalExperiencesAllowedByPolicy();
}
