import { mutator } from 'satcheljs';
import sweepStore from '../store/store';
import onSweepMoveToFolderClicked from '../actions/onSweepMoveToFolderClicked';
import setSweepDestinationFolderId from '../actions/setSweepDestinationFolderId';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(onSweepMoveToFolderClicked, actionMessage => {
    sweepStore.sweepDestinationFolderId = actionMessage.folderId;
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(setSweepDestinationFolderId, actionMessage => {
    sweepStore.sweepDestinationFolderId = actionMessage.folderId;
});
