export default function disableEdgeCopilotLLMExtraction() {
    // Post a message to that Edge browser interprets as blocking LLM extraction in OWA for its Copilot
    // This has to be sent before initial render because LLM can access the html content of the page
    window.postMessage(
        JSON.stringify({
            type: 'mipProperties',
            isProtected: true,
            usageRestrictions: {
                llmExtract: false,
            },
        })
    );
}
