import type { MailboxInfo } from 'owa-client-types';
import getFeatureFlagsIndexerForMailboxInfo from '../getFeatureFlagsIndexerForMailboxInfo';
import { isPersistenceIdIndexerEnabled } from 'owa-client-types/lib/isPersistenceIdIndexerEnabled';
import { getGlobalSettingsAccountIndexer } from 'owa-account-source-list-store';

/**
 * Returns the suffix the should be used for storing information about the
 * local overrides. For the global settings account no local storage suffix
 * will be returned.
 * @param mailboxInfo the mailbox account
 * @returns suffix to be used for local storage name
 */
export function getSuffixForLocalStorage(mailboxInfo?: MailboxInfo): string {
    const key = getFeatureFlagsIndexerForMailboxInfo(mailboxInfo);
    if (isPersistenceIdIndexerEnabled()) {
        if (key === getGlobalSettingsAccountIndexer(/*throwIfNotInitialized*/ false)) {
            return '';
        }

        return `:${key}`;
    }

    return key ? `:${key}` : '';
}
