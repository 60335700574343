import { default as CategoryColor, type CategoryColorValue } from '../schema/CategoryColor';
export const darkColorCodeValueMap: {
    [color: string]: CategoryColorValue;
} = {
    [CategoryColor.None]: {
        primaryColor: 'transparent',
        secondaryColor: '#666666',
        iconColor: '#ffffff',
        textColor: '#d6d6d6',
        hoverColor: '#383838',
        calendarColor: 'transparent',
    },

    [CategoryColor.DarkRed]: {
        primaryColor: '#420610',
        secondaryColor: '#D69CA5',
        iconColor: '#D69CA5',
        textColor: '#E9C7CD',
        hoverColor: '#861B2C',
        calendarColor: '#750B1C',
    },

    [CategoryColor.Cranberry]: {
        primaryColor: '#6E0811',
        secondaryColor: '#EEACB2',
        iconColor: '#DC626D',
        textColor: '#F6D1D5',
        hoverColor: '#960B18',
        calendarColor: '#C50F1F',
    },

    [CategoryColor.DarkOrange]: {
        primaryColor: '#7A2101',
        secondaryColor: '#F4BFAB',
        iconColor: '#E36537',
        textColor: '#F9DCD1',
        hoverColor: '#A62D01',
        calendarColor: '#DA3B01',
    },

    [CategoryColor.Bronze]: {
        primaryColor: '#5E2405',
        secondaryColor: '#E5BBA4',
        iconColor: '#CA8057',
        textColor: '#F1D9CC',
        hoverColor: '#963A08',
        calendarColor: '#A74109',
    },

    [CategoryColor.Peach]: {
        primaryColor: '#4D2A00',
        secondaryColor: '#FFBA66',
        iconColor: '#FF8C00',
        textColor: '#FFDDB3',
        hoverColor: '#8F4E00',
        calendarColor: '#FF8C00',
    },

    [CategoryColor.Marigold]: {
        primaryColor: '#463100',
        secondaryColor: '#D39300',
        iconColor: '#EAA300',
        textColor: '#FCEFD3',
        hoverColor: '#835B00',
        calendarColor: '#EAA300',
    },

    [CategoryColor.Gold]: {
        primaryColor: '#3A2F00',
        secondaryColor: '#DAC157',
        iconColor: '#C19C00',
        textColor: '#F5EECE',
        hoverColor: '#6D5700',
        calendarColor: '#C19C00',
    },

    [CategoryColor.DarkBrown]: {
        primaryColor: '#2B1710',
        secondaryColor: '#946B5C',
        iconColor: '#CAADA3',
        textColor: '#E3D2CB',
        hoverColor: '#623A2B',
        calendarColor: '#4D291C',
    },

    [CategoryColor.Lime]: {
        primaryColor: '#22330B',
        secondaryColor: '#A4CC6C',
        iconColor: '#A4CC6C',
        textColor: '#E5F1D3',
        hoverColor: '#405F14',
        calendarColor: '#73AA24',
    },

    [CategoryColor.Forest]: {
        primaryColor: '#162702',
        secondaryColor: '#85B44C',
        iconColor: '#6BA02B',
        textColor: '#BDD99B',
        hoverColor: '#376304',
        calendarColor: '#498205',
    },

    [CategoryColor.LightGreen]: {
        primaryColor: '#063004',
        secondaryColor: '#5EC75A',
        iconColor: '#5EC75A',
        textColor: '#CEF0CD',
        hoverColor: '#0B5A08',
        calendarColor: '#13A10E',
    },

    [CategoryColor.DarkGreen]: {
        primaryColor: '#032003',
        secondaryColor: '#2D8E2D',
        iconColor: '#4DA64D',
        textColor: '#9AD29A',
        hoverColor: '#085108',
        calendarColor: '#0B6A0B',
    },

    [CategoryColor.LightTeal]: {
        primaryColor: '#00373A',
        secondaryColor: '#32C8D1',
        iconColor: '#58D3DB',
        textColor: '#A6E9ED',
        hoverColor: '#00666D',
        calendarColor: '#00B7C3',
    },

    [CategoryColor.DarkTeal]: {
        primaryColor: '#003939',
        secondaryColor: '#218B8B',
        iconColor: '#41A3A3',
        textColor: '#92D1D1',
        hoverColor: '#005C5C',
        calendarColor: '#006666',
    },

    [CategoryColor.Steel]: {
        primaryColor: '#00333F',
        secondaryColor: '#4496A9',
        iconColor: '#94C8D4',
        textColor: '#94C8D4',
        hoverColor: '#005265',
        calendarColor: '#005B70',
    },

    [CategoryColor.SkyBlue]: {
        primaryColor: '#004659',
        secondaryColor: '#63D7F7',
        iconColor: '#63D7F7',
        textColor: '#D4F4FD',
        hoverColor: '#006A88',
        calendarColor: '#00BCF2',
    },

    [CategoryColor.DarkBlue]: {
        primaryColor: '#002039',
        secondaryColor: '#4178A3',
        iconColor: '#92B5D1',
        textColor: '#C2D6E7',
        hoverColor: '#0E4A78',
        calendarColor: '#003966',
    },

    [CategoryColor.Lavender]: {
        primaryColor: '#3F3682',
        secondaryColor: '#A79CF1',
        iconColor: '#A79CF1',
        textColor: '#E7E4FB',
        hoverColor: '#5649B0',
        calendarColor: '#7160E8',
    },

    [CategoryColor.DarkPurple]: {
        primaryColor: '#240F3C',
        secondaryColor: '#633E8F',
        iconColor: '#B9A3D3',
        textColor: '#B9A3D3',
        hoverColor: '#512B7E',
        calendarColor: '#401B6C',
    },

    [CategoryColor.Pink]: {
        primaryColor: '#80215D',
        secondaryColor: '#EF85C8',
        iconColor: '#EA66BA',
        textColor: '#FBDDF0',
        hoverColor: '#AD2D7E',
        calendarColor: '#E43BA6',
    },

    [CategoryColor.Plum]: {
        primaryColor: '#240017',
        secondaryColor: '#98246F',
        iconColor: '#D696C0',
        textColor: '#D696C0',
        hoverColor: '#6B0045',
        calendarColor: '#77004D',
    },

    [CategoryColor.Beige]: {
        primaryColor: '#444241',
        secondaryColor: '#AFABAA',
        iconColor: '#AFABAA',
        textColor: '#EAE8E8',
        hoverColor: '#5D5958',
        calendarColor: '#7A7574',
    },

    [CategoryColor.Mink]: {
        primaryColor: '#343231',
        secondaryColor: '#84817E',
        iconColor: '#9E9B99',
        textColor: '#CECCCB',
        hoverColor: '#54514F',
        calendarColor: '#5D5A58',
    },

    [CategoryColor.Silver]: {
        primaryColor: '#282D2E',
        secondaryColor: '#A0AEB1',
        iconColor: '#A0AEB1',
        textColor: '#D8DFE0',
        hoverColor: '#4A5356',
        calendarColor: '#859599',
    },

    [CategoryColor.Charcoal]: {
        primaryColor: '#202020',
        secondaryColor: '#686868',
        iconColor: '#888888',
        textColor: '#C4C4C4',
        hoverColor: '#515151',
        calendarColor: '#393939',
    },
};
