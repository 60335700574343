// extracted by mini-css-extract-plugin
export var headerAdBottom = "HrG1R";
export var headerAdTop = "cZiGh";
export var messageAdStaticContainerHeaderAd = "_4Acm";
export var messageAdStaticContainerOneLineExp = "cJ3F3";
export var messageAdStaticContainerOneLineExpCompact = "W9xfa";
export var messageAdStaticContainerOneLineExpMedium = "gr7y1";
export var messageAdStaticContainerThreeLineExpDensity = "jYY8g";
export var messageAdStaticContainerThreeLineExpDensityCompact = "tayE9";
export var messageAdStaticContainerThreeLineExpDensityMedium = "oB7PD";
export var messageAdStaticContainerTwoAdOneLineExp = "gOGng";
export var messageAdStaticContainerTwoAdOneLineExpCompact = "cGx00";
export var messageAdStaticContainerTwoAdOneLineExpMedium = "geflF";
export var messageAdStaticContainerTwoAdThreeLineExpDensity = "evj9g";
export var messageAdStaticContainerTwoAdThreeLineExpDensityCompact = "oFErI";
export var messageAdStaticContainerTwoAdThreeLineExpDensityMedium = "qUh8P";
export var pureMessageAdStaticContainer = "Ogt7j";