export default function parseOverrideString(allOverrides: string): {
    [index: string]: boolean;
} {
    const overrides: {
        [index: string]: boolean;
    } = {};
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    allOverrides.split(',').forEach(override => {
        // default on, case where there is no qualifier
        let enabled = true;

        // if there is a qualifier, then remove it and set enabled bit based on it
        if (override.indexOf('-') === 0) {
            enabled = false;
            override = override.slice(1, override.length);
        }

        overrides[override.toLowerCase()] = enabled;
    });

    return overrides;
}
