/**
 * Returns the animation key frames for the rows shifting up or down during a row insertion or row removal action.
 * The keyframes returned by this function are not for the row being animated in or out of the list view.
 *
 * @param {number} changeY (a negative changeY means the row will be shifting up as part of a row removal case while a positive changeY means the row will be shifting down as part
 * of a row insertion case.)
 * @returns {Keyframe[]}
 */
export function getAnimationKeyframesForShiftingRows(
    changeY: number,
    isRemovalAction: boolean
): Keyframe[] {
    // Flip the sign of changeY for the row insertion case. The reason we need to flip the sign of changeY for the row insertion case is because once we change the display of the row(s)
    // animating into the list view from 'none' to 'block', the rows below will now have an offsetY of the height of the row(s) animating in. Therefore, we need to start by translating them up
    // by changeY (which would place them at the top coordinate of the row animating in aka their original position in the list view prior to the row insertion animation) and then perform a
    // transform translateY(0) animation to translate them back down to their new positions.
    if (!isRemovalAction) {
        changeY *= -1;
    }

    return isRemovalAction
        ? [
              { transform: 'translateY(0)', offset: 0 },
              {
                  transform: 'translateY(0)',
                  offset: 0.25,
              },
              {
                  transform: 'translateY(' + changeY + 'px)',
                  offset: 1,
              },
          ]
        : [
              {
                  transform: 'translateY(' + changeY + 'px)',
                  offset: 0,
              },
              {
                  transform: 'translateY(' + changeY + 'px)',
                  offset: 0.25,
              },
              { transform: 'translateY(0)' },
          ];
}
