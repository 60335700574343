/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Because this hook applies hotkeys outside of render, we need to
// manually hook into mobx updating the fav icon */
import { autorun } from 'owa-mobx-react';
import { getCurrentModule } from 'owa-app-module-store';
import { getBootstrapOptions } from './optionsRegistry';
import { applyFavicon } from 'owa-favicon';
import { Module } from 'owa-workloads';

const DEFAULT_FAVICON_RELATIVE_PATH = 'images/favicons/mail-seen.ico';

export function autoUpdateFavIcon() {
    autorun('autoUpdateFavIcon', () => {
        const currentModule = getCurrentModule() || Module.Mail;
        const appModuleProps = getBootstrapOptions(currentModule)?.appModuleProps;
        const overrideFavIcon = appModuleProps?.getOverrideFavIcon?.();
        applyFavicon(
            overrideFavIcon?.icon || DEFAULT_FAVICON_RELATIVE_PATH,
            overrideFavIcon?.overlay
        );
    });
}
