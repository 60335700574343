import type MailListRowDataType from '../store/schema/MailListRowDataType';
import type { ConversationType, ItemRow } from 'owa-graph-schema';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { trace } from 'owa-trace';

/**
 * Get the row instance key from the list view type
 * @param row the row data
 * @param listViewType the list view type
 */
export default function getRowKeyFromListViewType(
    row: MailListRowDataType,
    listViewType: ReactListViewType
): string {
    switch (listViewType) {
        case 0:
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (20,13): Type 'Maybe<string> | undefined' is not assignable to type 'string'.
            // @ts-expect-error
            return (row as ConversationType).InstanceKey;

        case 1:
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (26,13): Type 'Maybe<string> | undefined' is not assignable to type 'string'.
            // @ts-expect-error
            return (row as ItemRow).InstanceKey;

        default:
            trace.warn('listViewType:' + listViewType + ' not supported');
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (33,13): Type 'null' is not assignable to type 'string'.
            // @ts-expect-error
            return null;
    }
}
