import { createLazyComponent, LazyModule } from 'owa-bundling';
import GroupNodePlaceholder from './components/GroupNodePlaceholder';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "GroupListView" */ './lazyIndex'),
    { name: 'GroupListView' }
);

// Export delay loaded components
export const GroupList = createLazyComponent(lazyModule, m => m.GroupList);
export const GroupNode = createLazyComponent(lazyModule, m => m.GroupNode, GroupNodePlaceholder);
