import { action } from 'satcheljs';
import { addDatapointConfig } from 'owa-analytics-actions';
import type UnsubscribeResponseType from '../store/schema/unsubscribeResponseType';
import type UnsubscribeSourceType from '../store/schema/unsubscribeSourceType';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Common action to unsubscribe from a Brand Subscription. completedCallback is used for logging purposes only.
 */
export const unsubscribeFromBrandSubscription = action(
    'UNSUBSCRIBE_FROM_BRAND_SUBSCRIPTION',
    (
        unsubscribeSourceType: UnsubscribeSourceType,
        smtpAddress: string,
        smtpIdentifier: string,
        unsubscribeSilentUris: string[],
        unsubscribeHttpUri: string,
        mailboxInfo: MailboxInfo,
        /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
         * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
         *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
        completedCallback?: any
    ) =>
        addDatapointConfig(
            {
                name: 'UnsubscribeSubscription',
                customData: {
                    unsubscribeSourceType,
                },
            },
            {
                unsubscribeSourceType,
                smtpAddress,
                smtpIdentifier,
                unsubscribeSilentUris,
                unsubscribeHttpUri,
                mailboxInfo,
                completedCallback,
            }
        )
);

/**
 * Action dispatched after the unsubscription from brand subscription action is dispatched.
 */
export const onAfterUnsubscribe = action(
    'AFTER_UNSUBSCRIBE',
    (
        smtpIdentifier: string,
        smtpAddress: string,
        unsubscribeSourceType: UnsubscribeSourceType,
        responseType: UnsubscribeResponseType,
        debugMessage: string,
        mailboxInfo: MailboxInfo,
        /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
         * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
         *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
        completedCallback?: any
    ) => ({
        smtpIdentifier,
        smtpAddress,
        unsubscribeSourceType,
        responseType,
        debugMessage,
        mailboxInfo,
        completedCallback,
    })
);
