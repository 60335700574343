import { userConfigurationSet } from 'owa-session-store';
import { orchestrator } from 'satcheljs';
import { selectedCalendarsUpdated } from '../actions/publicActions';
import {
    updateSelectedCalendarsInStore,
    formatInitialSelectedCalendars,
} from '../actions/internalActions';
import { isConversionNeeded } from 'owa-immutable-id-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getMailboxInfoForUserIdentity } from 'owa-account-source-list';
import { registerCalendarModuleSelectedCalendarsCollector } from '../utils/registerCalendarModuleSelectedCalendarsCollector';

/**
 * When the user configuration is set for an account, updated the selected calendars in this store,
 * and dispatch a `selectedCalendarsUpdated` to let consumers know that the store value has changed
 */
orchestrator(userConfigurationSet, actionMessage => {
    const { userConfiguration, userIdentity, mailboxInfo } = actionMessage;
    const calendarIds: string[] | undefined =
        userConfiguration?.ViewStateConfiguration?.SelectedCalendarsDesktop ?? [];
    const primaryAddress = userIdentity
        ? userIdentity
        : getGlobalSettingsAccountMailboxInfo().userIdentity;
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    const calendarMailboxInfo = mailboxInfo ?? getMailboxInfoForUserIdentity(primaryAddress)!;

    if (isConversionNeeded(calendarIds, calendarMailboxInfo)) {
        formatInitialSelectedCalendars(calendarIds, calendarMailboxInfo);
    } else {
        updateSelectedCalendarsInStore(calendarIds, calendarMailboxInfo);
        selectedCalendarsUpdated(calendarIds, calendarMailboxInfo);
    }
    registerCalendarModuleSelectedCalendarsCollector();
});
