import { createStore } from 'satcheljs';
import type { RepromptState } from './schema/RepromptState';

const repromptStore = createStore<RepromptState>('repromptStore', {
    viewIndex: 0,
    showPrivacyRepromptDialog: false,
    showSettingsMigrationDialog: false,
})();

export const getRepromptStore = () => repromptStore;
