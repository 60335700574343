import type { MailboxInfo } from 'owa-client-types';
import is3SServiceAvailable from 'owa-search/lib/utils/is3SServiceAvailable';
import { getUserConfiguration } from 'owa-session-store';

export default function isFindRelatedConversationEnabled(mailboxInfo: MailboxInfo): boolean {
    if (is3SServiceAvailable()) {
        if (getUserConfiguration().SessionSettings?.IsExplicitLogon) {
            return true;
        } else if (mailboxInfo.type == 'UserMailbox') {
            return true;
        } else if (mailboxInfo.type == 'ArchiveMailbox') {
            return true;
        }
    }
    return false;
}
