import { getOWAConnectedAccount, isConnectedAccount } from 'owa-accounts-store';
import type { MailboxInfo } from 'owa-client-types';
import { getWebSessionTypeForAccount } from './getConnectedAccountHeaders';

/**
 * Returns the WebSession type of the account, or an empty is there is not WebSession type for the account
 * @param mailboxInfo Identifies the account to be checked
 * @returns If successful returns the auth token and web session type (if it is a connected account).
 * Note, the return value is a promise so that the callback can be made from the worker.
 */
export default function getConnectedAccountWebSessionType(
    mailboxInfo?: MailboxInfo
): Promise<string> {
    if (mailboxInfo?.userIdentity && isConnectedAccount(mailboxInfo.userIdentity)) {
        // When not in Monarch we are still using the connected accounts API and need to call the
        // legacy connected account auth token logic to get the auth token and web session type.
        const account = getOWAConnectedAccount(mailboxInfo.userIdentity);
        return Promise.resolve(getWebSessionTypeForAccount(account));
    }

    return Promise.resolve('');
}
