import type { M365Application } from 'owa-graph-schema';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Legacy usage of apolloClient
 *	> 'getApolloClient' import from 'owa-apollo' is restricted. Use exports of query, mutate, subscribe, etc. If you're are wrapping a component, use wrapInApolloProvider */
import { getApolloClient } from 'owa-apollo';
import { AppBarControlDataDocument } from '../graphql/query/__generated__/AppBarControlData.interface';

import type { AppBarControlDataQuery } from '../graphql/query/__generated__/AppBarControlData.interface';

/**
 * Reads `m365Apps` query's cache content — directly from the Apollo Cache.
 *
 * ## Context
 *
 * We need to read directly from cache synchronously when we bootstrap the nova queries at boot
 * @param client ApolloClient
 * @returns `M365Acquisition[]`
 */
export function readM365ApplicationsFromCache(): M365Application[] | null {
    const queryResult: AppBarControlDataQuery | null = getApolloClient().readQuery({
        query: AppBarControlDataDocument,
    });
    if (!queryResult?.m365Apps) {
        return null;
    }
    return queryResult.m365Apps.edges.map(edge => edge.node);
}
