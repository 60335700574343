import type { NovaEvent } from '@nova/types';
import type { MailboxInfo } from 'owa-client-types';
import type { AppHostServiceEventOriginator } from './originator';
import type { ActionSource } from 'owa-mail-store-action-source';
import type EmailAddressWrapper from 'owa-service/lib/contract/EmailAddressWrapper';

export const ComposeEmailEventType = 'composeEmail' as const;

export interface ComposeEmailEvent extends NovaEvent<ComposeEmailEventData> {
    originator: typeof AppHostServiceEventOriginator;
    type: typeof ComposeEmailEventType;
    data: () => ComposeEmailEventData;
}

export interface ComposeEmailEventData {
    actionSource: ActionSource;
    toEmailAddressWrappers: EmailAddressWrapper[];
    mailboxInfo: MailboxInfo;
    isPopout: boolean;
}
