import type { ITokenCache } from './utils/ITokenCache';
import { TokenCache } from './utils/TokenCache';

let tokenCache: ITokenCache;
export function getTokenCache(): ITokenCache {
    if (!tokenCache) {
        tokenCache = new TokenCache();
    }
    return tokenCache;
}
