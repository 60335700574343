import type { Contracts } from 'owa-account-source-list-store';
import { AccountSourceType } from 'owa-account-source-list-types';

// Accounts using mailboxes hosted on Microsoft 365 services, whether first party or
// a third party cloud cache support all of the modules
const m365HostedContracts: Contracts = {
    supportsCalendar: true,
    supportsContacts: true,
    supportsMail: true,
    supportsSettings: true,
    isMicrosoft365Hosted: true,
};

// PST files only support mail
const pstFileContracts: Contracts = {
    supportsCalendar: false,
    supportsContacts: false,
    supportsMail: true,
    supportsSettings: false,
    isMicrosoft365Hosted: false,
};

// Other types are not expected and we should treat them as not supporting
// any of the modules
const nothingSupportedContracts: Contracts = {
    supportsCalendar: false,
    supportsContacts: false,
    supportsMail: false,
    supportsSettings: false,
    isMicrosoft365Hosted: false,
};

// Defines the default contracts that are supported based on the account source type
const contractsBySourceType: Record<AccountSourceType, Contracts> = {
    [AccountSourceType.Office365]: m365HostedContracts,
    [AccountSourceType.OutlookDotCom]: m365HostedContracts,
    [AccountSourceType.Google]: m365HostedContracts,
    [AccountSourceType.ICloud]: m365HostedContracts,
    [AccountSourceType.Yahoo]: m365HostedContracts,
    [AccountSourceType.IMAP]: m365HostedContracts,
    [AccountSourceType.POP3]: m365HostedContracts,
    [AccountSourceType.PstFile]: pstFileContracts,
    [AccountSourceType.Other]: nothingSupportedContracts,
};

/**
 * Returns the account support Contracts based on the supplied type and provided overrides
 * @param sourceType The provider for the MailboxInfo used for the acccount
 * @param contractOverrides Optional overrides for contracts
 * @returns Contracts for the account type and overrides
 */
export function buildMailboxContracts(
    sourceType: AccountSourceType,
    contractOverrides?: Partial<Contracts>
): Contracts {
    const contracts = contractsBySourceType[sourceType];
    return contractOverrides ? { ...contracts, ...contractOverrides } : contracts;
}
