import type { MailFolder } from 'owa-graph-schema';
import getRootFolderForFolderTree from './getRootFolderForFolderTree';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Shared folders root folder selector for a given user
 */
export default function getSharedFolderRoot(
    principalSMTPAddress: string,
    mailboxInfo: MailboxInfo
): MailFolder | undefined {
    return getRootFolderForFolderTree(principalSMTPAddress, mailboxInfo);
}
