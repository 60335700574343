import type EditorViewState from '../store/schema/EditorViewState';
import type { IEditor } from 'roosterjs-editor-types';
import operateContentInternal, { operateHTMLContentInternal } from './ContentOperator';

/**
 * These type definitions are just to make the function callEditorApi type-safe
 */

// 1. Define function types
type EditorApiType<P extends any[] = any[], R = any> = (...args: P) => R;

// 2. Select the valid APIs of the root
type KeyOfEditorApi<Key extends keyof IEditor> = IEditor[Key] extends EditorApiType ? Key : never;
type TypeOfEditorApi<Key extends keyof IEditor> = IEditor[Key] extends EditorApiType
    ? IEditor[Key]
    : never;
type CallableEditorApiKeys<Key extends keyof IEditor> = {
    [P in Key]: KeyOfEditorApi<P>;
}[Key];
type CallableEditorApi<Key extends keyof IEditor> = {
    [P in CallableEditorApiKeys<Key>]: TypeOfEditorApi<P>;
};

// 3. All valid APIs
export type EditorApi = CallableEditorApi<keyof IEditor>;

/**
 * Call an editor API with parameter.
 * An editor API must be a method of Editor class
 * If there is no mounted editor correlated to the given viewState, it does nothing and returns undefined.
 * @param viewStateOrEditorId Editor ViewState or editorId of the Editor ViewState
 * @param apiName Name of the format API to call
 * @param args Arguments of the API
 */
export function callEditorApi<T extends keyof EditorApi>(
    viewStateOrEditorId: EditorViewState | string,
    apiName: T,
    ...args: Parameters<IEditor[T]>
): ReturnType<IEditor[T]> | undefined {
    let result: ReturnType<IEditor[T]> | undefined = undefined;
    const executeAPI = (editor: IEditor | null) => {
        if (!!editor) {
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (48,13): Type 'unknown' is not assignable to type 'ReturnType<IEditor[T]>'.
            // @ts-expect-error
            result = editor[apiName].call(editor, ...args);
        }
    };

    if (typeof viewStateOrEditorId === 'string') {
        operateHTMLContentInternal(viewStateOrEditorId, editor => executeAPI(editor));
    } else {
        operateContentInternal(
            viewStateOrEditorId,
            editor => executeAPI(editor),
            undefined /* plainTextCallback */
        );
    }

    return result;
}
