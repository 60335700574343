import { mutator } from 'satcheljs';
import { getStore } from '../store/Store';
import { setShowListPane } from '../actions/setShowListPane';

/**
 * Sets showListPane value in store to passed in value
 * @param showListPane Whether to show list view
 */
/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
export default mutator(setShowListPane, actionMessage => {
    getStore().showListPane = actionMessage.showListPane;
});
