import { unknownLastImportDate } from './isUnknownImportDate';
import type { ImportSources } from './ImportSources';

/**
 * Gets the default value for the ImportSource structure
 * @returns ImportSources with the default value
 */
export function getDefaultImportSources(): ImportSources {
    return {
        Win32Count: 0,
        UniversalCount: 0,
        lastImportDate: unknownLastImportDate,
    };
}
