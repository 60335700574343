import { getFolderIdForSelectedNode } from 'owa-mail-folder-forest-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';

export default function doesFolderSupportConversationGrouping(): boolean {
    const folderId = getFolderIdForSelectedNode();
    if (!folderId) {
        // We could not find the folderId for the selected node, so we should not block operations dependent on grouping ML by conversation
        return true;
    }
    if (folderIdToName(folderId) === 'drafts' || folderIdToName(folderId) === 'junkemail') {
        return false;
    }
    return true;
}
