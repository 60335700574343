import { mutatorAction } from 'satcheljs';
import type { MailListItemAnimation } from '../store/schema/MailListItemAnimationStore';
import { mailListItemAnimationStore } from '../store/Store';
import type { ActionId } from '../util/actionId';

export const addPendingAnimation = mutatorAction(
    'addPendingAnimation',
    (animationRowIds: string[], animationAction?: ActionId, onAnimationComplete?: () => void) => {
        if (animationRowIds) {
            const animationToAdd: MailListItemAnimation = {
                animationId: JSON.stringify(animationRowIds),
                animationAction: animationAction ?? null,
                animationRowIds: new Set(animationRowIds),
                ...(onAnimationComplete && { animationCallback: onAnimationComplete }),
            };

            mailListItemAnimationStore.pendingAnimations = [
                ...mailListItemAnimationStore.pendingAnimations,
                animationToAdd,
            ];
        }
    }
);
