import type { StateChangeSource } from 'owa-left-pane-layout/lib/types/StateChangeSource';
import { setShowFolderPane } from '../actions/setShowFolderPane';
import { shouldShowFolderPaneAsOverlay } from '../selectors/shouldShowFolderPaneAsOverlay';
import { triggerResizeEvent } from 'owa-resize-event';
import { LayoutChangeSource } from 'owa-layout';
import setFolderPaneCollapsedState from '../services/setFolderPaneCollapsedState';
import logFolderPaneState from '../helpers/logFolderPaneState';
import { getStore } from '../store/Store';

export function toggleFolderPaneExpansion(
    stateChangeSource: StateChangeSource,
    persistOnServer: boolean = true,
    newShowFolderPane?: boolean
) {
    const folderPaneOldShowState = getStore().showFolderPane;
    const folderPaneNewShowState = newShowFolderPane ?? !folderPaneOldShowState;

    // Toggle the state
    setShowFolderPane(folderPaneNewShowState /* showFolderPane */, stateChangeSource);

    /**
     * Only persist the folder pane expand/collapse state when user is not
     * in a smaller resolution where folder pane is shown as an overlay
     * If not shown as an overlay, also trigger a resize event.
     */
    if (!shouldShowFolderPaneAsOverlay()) {
        // If we want to persist and the value has changed, persist the state
        if (persistOnServer && folderPaneOldShowState != folderPaneNewShowState) {
            setFolderPaneCollapsedState(folderPaneOldShowState /* isCollapsed */);
        }

        triggerResizeEvent();
    }

    // Log all folder pane state changes
    logFolderPaneState(
        LayoutChangeSource.ToggleFolderPane,
        folderPaneNewShowState, // folderPaneNewShowState
        folderPaneOldShowState // folderPaneOldShowState
    );
}
