import { addFoldersToFolderTableMutator } from '../../mutators/addFoldersToFolderTableMutator';
import type { MailFolder, MailFolder as Schema_MailFolder } from 'owa-graph-schema';
import { updateFolderInformation } from '../../mutators/updateFolderInformation';
import { shouldShowFolder, getFoldersToShowFirst } from 'owa-folders-data-utils';
import type { MailboxInfo } from 'owa-client-types';

export function updateFolderTable(
    displayName: string | undefined,
    rootFolder: Schema_MailFolder,
    allFolders: Schema_MailFolder[],
    createOrGetFolder_0: (folder: Schema_MailFolder) => MailFolder,
    isCustomSorted?: boolean,
    mailboxInfo?: MailboxInfo
) {
    const folders: {
        [id: string]: MailFolder;
    } = {};
    const folderNameIdMap: {
        [name: string]: string;
    } = {};

    // Convert root folder to mobx type
    const root = (folders[rootFolder.id] = createOrGetFolder_0(rootFolder));

    // Convert all folders to mobx type
    const folderIds: string[] = [];
    for (let i = 0; i < allFolders.length; i++) {
        const rawFolder = allFolders[i];
        const folderId = rawFolder.id;
        const distinguishedFolderType = rawFolder.distinguishedFolderType;
        if (shouldShowFolder(distinguishedFolderType, rawFolder.mailboxInfo)) {
            folders[folderId] = createOrGetFolder_0(rawFolder);
            folderIds.push(folderId);

            if (distinguishedFolderType) {
                folderNameIdMap[distinguishedFolderType] = folderId;
            }
        }
    }

    const defaultChildIdsForRootFolder: string[] = [];
    // If the tree is not custom sorted and there are no child folder ids yet (aka first service response), we need to add the default folders to the root folder
    if (!isCustomSorted && (!root.childFolderIds || root.childFolderIds.length == 0)) {
        const foldersToShowFirst = getFoldersToShowFirst(mailboxInfo);
        foldersToShowFirst.forEach(folderToShowFirstWellKnownName => {
            const wellknownFolderId = folderNameIdMap[folderToShowFirstWellKnownName];
            if (wellknownFolderId) {
                defaultChildIdsForRootFolder.push(wellknownFolderId);
            }
        });
    }

    updateFolderInformation(root, displayName, folders, folderIds, defaultChildIdsForRootFolder);

    // Store folders in folder table
    addFoldersToFolderTableMutator(folders);
}
