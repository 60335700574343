import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "AppHostState"*/ './lazyIndex')
);

export const lazyInitializeSharePointSiteInfo = new LazyAction(
    lazyModule,
    m => m.initializeSharePointSiteInfo
);

export const lazyOpenAppStoreModal = new LazyAction(lazyModule, m => m.openAppStoreModal);
export const lazyCloseAppStoreModal = new LazyAction(lazyModule, m => m.closeAppStoreModal);
export const lazyOnOpenAppAboutDialog = new LazyAction(lazyModule, m => m.onOpenAppAboutDialog);

export function closeDetailsDialog() {
    lazyOnOpenAppAboutDialog.importAndExecute(undefined /* appId */);
}

export const lazyOpenLaunchPageMetaOsApp = new LazyAction(
    lazyModule,
    m => m.openLaunchPageMetaOsApp
);
export const lazyFetchAndOpenLaunchPageMetaOsApp = new LazyAction(
    lazyModule,
    m => m.fetchAndOpenLaunchPageMetaOsApp
);
export const lazyOnOpenMetaOsAppFromComposeAppStore = new LazyAction(
    lazyModule,
    m => m.onOpenMetaOsAppFromComposeAppStore
);
export const lazyOnInvalidAppNavigation = new LazyAction(lazyModule, m => m.onInvalidAppNavigation);
export const lazyOnShowMessageExtensionAppInstalledDialog = new LazyAction(
    lazyModule,
    m => m.onShowMessageExtensionAppInstalledDialog
);
export const lazyOnMetaOsAppUninstalled = new LazyAction(lazyModule, m => m.onMetaOsAppUninstalled);
export const lazyOnOpenAppUpgradeDialog = new LazyAction(lazyModule, m => m.onOpenAppUpgradeDialog);
export const lazyOnOpenAppStoreLaunchPage = new LazyAction(
    lazyModule,
    m => m.onOpenAppStoreLaunchPage
);
