import { observer } from 'owa-mobx-react';
import React from 'react';
import { Shimmer, ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react/lib/Shimmer';
import type { IShimmerColors } from '@fluentui/react/lib/Shimmer';
import { getDensityModeString, getDensityModeCssClass, getPalette } from 'owa-fabric-theme';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';

import {
    shimmerSecondRow,
    personaCircle,
    secondRowText,
    full,
    compact,
    paddingFirstMessage,
    paddingMessage,
    mailListShimmerContainer,
} from './ShimmerRowThreeCol.scss';

import classnames from 'owa-classnames';

export interface ShimmerRowThreeColProps {
    isFirstMessage: boolean;
}

const shimmerWithElementFirstRow = [
    { type: ShimmerElementType.gap, width: '12%' },
    { type: ShimmerElementType.line, width: '24%' },
    { type: ShimmerElementType.gap, width: '64%' },
];

const shimmerWithElementSecondRow = [
    { type: ShimmerElementType.line, width: '66%' },
    { type: ShimmerElementType.gap, width: '36%', height: 28 },
    { type: ShimmerElementType.line, width: '10%' },
    { type: ShimmerElementType.gap, width: '4%', height: 28 },
];

const shimmerWithElementThirdRow = [
    { type: ShimmerElementType.gap, width: '12%' },
    { type: ShimmerElementType.line, width: '50%' },
    { type: ShimmerElementType.gap, width: '38%' },
];

const ShimmerRowThreeCol = observer(function ShimmerRowThreeCol(props: ShimmerRowThreeColProps) {
    const densityModeString = getDensityModeString();

    const computedShimmerColors = useComputedValue(
        (): IShimmerColors => ({
            background: getPalette().neutralPrimarySurface,
            shimmer: getPalette().neutralLight,
            shimmerWave: getPalette().neutralLighter,
        }),
        []
    );

    const shimmerElementsStyles = useComputedValue(
        () => [
            { type: ShimmerElementType.gap, width: '16px', height: 28 },
            densityModeString === 'full'
                ? { type: ShimmerElementType.circle, height: 28 }
                : { type: ShimmerElementType.gap, width: '28px', height: 28 },
            { type: ShimmerElementType.gap, width: '100%', height: 28 },
        ],
        [densityModeString]
    );

    const secondRow = React.useMemo((): JSX.Element => {
        return (
            <div className={shimmerSecondRow}>
                <div className={personaCircle}>
                    <ShimmerElementsGroup
                        width={'100%'}
                        shimmerElements={shimmerElementsStyles}
                        backgroundColor={getPalette().neutralPrimarySurface}
                    />
                </div>
                <div className={secondRowText}>
                    <ShimmerElementsGroup
                        shimmerElements={shimmerWithElementSecondRow}
                        backgroundColor={getPalette().neutralPrimarySurface}
                    />
                </div>
            </div>
        );
    }, [shimmerElementsStyles, getPalette().neutralPrimarySurface]);

    return (
        <div
            className={classnames(
                getDensityModeCssClass(full, undefined, compact),
                props.isFirstMessage ? paddingFirstMessage : paddingMessage,
                mailListShimmerContainer
            )}
        >
            <Shimmer
                shimmerElements={shimmerWithElementFirstRow}
                shimmerColors={computedShimmerColors}
            />
            <Shimmer
                improveCSSPerformance={true}
                customElementsGroup={secondRow}
                shimmerColors={computedShimmerColors}
            />
            <Shimmer
                shimmerElements={shimmerWithElementThirdRow}
                shimmerColors={computedShimmerColors}
            />
        </div>
    );
}, 'ShimmerRowThreeCol');
export default ShimmerRowThreeCol;
