import { LazyModule, LazyAction, LazyImport } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "UserStorage" */ './lazyIndex'),
    { name: 'UserStorage' }
);

export const lazyGetAccountInformation = new LazyAction(lazyModule, m => m.getAccountInformation);
export const lazyOpenedStoragePageOnFull = new LazyAction(
    lazyModule,
    m => m.openedStoragePageOnFull
);
export const lazyGetStorageStore = new LazyImport(lazyModule, m => m.getStorageStore);

export const lazyTryValidateDumpsterQuota = new LazyImport(
    lazyModule,
    m => m.tryValidateDumpsterQuota
);

export const lazyShouldDisableSend = new LazyImport(lazyModule, m => m.shouldDisableSend);

export const lazyGetLocalStorageKeyName = new LazyImport(lazyModule, m => m.getLocalStorageKeyName);

export const lazyGetShouldShowStorageUpsellBanner = new LazyImport(
    lazyModule,
    m => m.getShouldShowStorageUpsellBanner
);

export const lazyOnSelectingAttachmentsSearchFolder = new LazyImport(
    lazyModule,
    m => m.onSelectingAttachmentsSearchFolder
);

export const lazyHandleOnAwayFromAttachmentsSearchFolder = new LazyImport(
    lazyModule,
    m => m.handleOnAwayFromAttachmentsSearchFolder
);

export const lazyGetMicrosoftStorageData = new LazyAction(
    lazyModule,
    m => m.getMicrosoftStorageData
);

export const lazymailDeletionTriggerStorageUpdate = new LazyAction(
    lazyModule,
    m => m.mailDeletionTriggerStorageUpdate
);

export const lazyOnStorageUpsell = new LazyAction(lazyModule, m => m.onStorageUpsell);

export {
    MSQ_LB_CRITICAL_D_TIME,
    MSQ_LB_NEARING_D_TIME,
    ESQ_LB_CRITICAL_D_TIME,
    ESQ_LB_NEARING_D_TIME,
    EnforcementState_Active,
    BB_CRITICAL_D_TIME,
    BB_NEARING_D_TIME,
    EnforcementState_Revoked,
    BASIC_TIER_STORAGE_CAP,
    PREMIUM_TIER_STORAGE_CAP,
} from './util/constants';

export { onDismissLeftNavBar } from './actions/onDismissLeftNavBar';
export { onDismissBizBar } from './actions/onDismissBizBar';
