import { LazyAction, LazyModule } from 'owa-bundling';

export type { MoveGroupFoldersTelemetryParams } from './services/moveGroupFolder';
export { translateRestIdToOwsId } from './utils/folderIdConversionUtils';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "GroupSharedFolderActions" */ './lazyIndex'),
    { name: 'GroupSharedFolderActions' }
);

// Delay loaded components
export const lazyLoadGroupFolders = new LazyAction(lazyModule, m => m.loadGroupFolders);
export const lazyCreateNewGroupFolder = new LazyAction(lazyModule, m => m.createNewGroupFolder);
export const lazyDeleteGroupFolder = new LazyAction(lazyModule, m => m.deleteGroupFolder);
export const lazyRenameGroupFolder = new LazyAction(lazyModule, m => m.renameGroupFolder);
export const lazyMoveGroupFolder = new LazyAction(lazyModule, m => m.moveGroupFolder);
export const lazySubscribeToAllGroupFolderHierarchyNotifications = new LazyAction(
    lazyModule,
    m => m.subscribeToAllGroupFolderHierarchyNotifications
);
export const lazySubscribeToGroupFolderHierarchyNotifications = new LazyAction(
    lazyModule,
    m => m.subscribeToGroupFolderHierarchyNotifications
);
export const lazyUnsubscribeFromGroupFolderHierarchyNotifications = new LazyAction(
    lazyModule,
    m => m.unsubscribeFromGroupFolderHierarchyNotifications
);
export const lazyUnsubscribeFromGroupFolderUnreadCountNotificationsForGroup = new LazyAction(
    lazyModule,
    m => m.unsubscribeFromGroupFolderUnreadCountNotificationsForGroup
);
