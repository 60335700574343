import { LazyModule, createLazyComponent, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "WhatsNew" */ './lazyIndex'), {
    name: 'WhatsNew',
});

export { getUnreadWhatsNewCardCount } from './selectors/getUnreadWhatsNewCardCount';

export const WhatsNewFluentPane = createLazyComponent(lazyModule, m => m.WhatsNewPane);
export const initializeWhatsNewCardsLazy = new LazyAction(
    lazyModule,
    m => m.initializeWhatsNewIfNecessary
);
export const lazyOpenPremiumDashboard = new LazyAction(lazyModule, m => m.openPremiumDashboard);

export { WhatsNewInitSource } from './types/WhatsNewInitSource';
