// This is an autogenerated file. Do not manually edit.
// Run `node packages/framework/owa-fluent-mobile-brand-icons-svg/updateIcons.js` to update the icons in this package
import React from 'react';
import { registerIcons } from '@fluentui/style-utilities';
import { createIconComponent } from 'owa-fluent-icons-svg';

export default 'IcFluentBrandToDoMono';

const IcFluentBrandToDoMono = createIconComponent({
    IconComponent: props => (
        <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.6745 5.28645L15.6558 3.32008C15.5582 3.21886 15.4412 3.13834 15.3119 3.08334C15.1825 3.02835 15.0433 3 14.9027 3C14.7621 3 14.623 3.02835 14.4936 3.08334C14.3642 3.13834 14.2472 3.21886 14.1496 3.32008L11.3779 6.03952L7.86355 9.59571L5.84489 7.50384C5.74706 7.39933 5.62881 7.31603 5.49748 7.25908C5.36614 7.20213 5.22451 7.17274 5.08136 7.17274C4.93821 7.17274 4.79658 7.20213 4.66524 7.25908C4.53391 7.31603 4.41566 7.39933 4.31783 7.50384L2.32008 9.59571C2.21886 9.69328 2.13834 9.81026 2.08334 9.93965C2.02835 10.069 2 10.2082 2 10.3488C2 10.4894 2.02835 10.6285 2.08334 10.7579C2.13834 10.8873 2.21886 11.0043 2.32008 11.1019L5.09182 13.8736L7.11048 15.8713C7.20858 15.9714 7.32579 16.0508 7.45516 16.1047C7.58453 16.1586 7.72341 16.1859 7.86355 16.1851C8.00194 16.1845 8.13883 16.1565 8.26631 16.1027C8.39378 16.0488 8.5093 15.9702 8.60617 15.8713L17.6745 6.80306C17.7773 6.70535 17.8592 6.58773 17.9152 6.45737C17.9711 6.32701 18 6.18662 18 6.04475C18 5.90288 17.9711 5.7625 17.9152 5.63214C17.8592 5.50177 17.7773 5.38416 17.6745 5.28645ZM5.1232 12.3465L3.09408 10.2547L5.08136 8.28829L7.10002 10.3069L5.09182 12.3361L5.1232 12.3465ZM10.6876 12.3465L7.84264 15.1183L6.27373 13.5389L5.82398 13.0787L8.59571 10.3069L12.1101 6.78214L14.8818 4.0104L16.9109 6.10228L10.6876 12.3465Z" />
        </svg>
    ),
    displayName: 'IcFluentBrandToDoMono',
});

registerIcons({
    icons: {
        IcFluentBrandToDoMono: <IcFluentBrandToDoMono />,
    },
});
