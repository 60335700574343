// Enterprise Scenario Constants
export const NUM_OF_RETRIES = 3;
export const RETRY_DELAY = 1500;
export const TIME_BEFORE_EXPIRY_TO_REFRESH_IN_MILLISECONDS = 2 * 1000; //try to refresh token 2 seconds before the token expiry
export const MIN_TIME_TO_EXPIRY_IN_MINUTES = 1;
export const MAX_NUM_OF_GET_ACCESS_TOKEN_REQUESTS = 3;
export const DELAY_FOR_GET_ACCESS_TOKEN_REQUESTS = 10; /* Task Delay */
export const TIMEOUT_FOR_TOKEN_REQUEST_PROMISE = 90 * 1000;
export const TOKEN_FETCH_API = 'TokenFetchApi';

// Consumer Scenario Constants
export const SCENARIO_NAME = 'OWA_GetDelegationAccessToken';
export const AUTHORIZATION_URI = 'https://login.live.com/oauth20_authorize.srf';
export const RETURN_URL_PATH = '/owa/auth/dt.aspx';
export const CLIENT_ID = '292841';

// Local Storage Constants for Latency Calculations
export const LATENCY_LOCAL_STORAGE_KEY = 'auth-latency';
