import type {
    OwaTeamsPolicies,
    TeamsPoliciesScenario,
} from 'owa-teams-policies-lazy/lib/store/schema';
import { lazyGetTeamsPolicies } from 'owa-teams-policies-lazy';

export function getTeamsPolicies(
    scenario?: TeamsPoliciesScenario
): Promise<OwaTeamsPolicies | null> {
    return lazyGetTeamsPolicies.importAndExecute(scenario);
}
