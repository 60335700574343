import {
    LazyModule,
    createLazyComponent,
    LazyAction,
    registerLazyOrchestrator,
} from 'owa-bundling';
import { fetchSearchFolders } from 'owa-folders';

const lazyModule = new LazyModule(
    () => import(/*webpackChunkName: "MailSearchFolderView"*/ './lazyIndex'),
    { name: 'MailSearchFolderView' }
);

export { getStore } from './store/store';

// Export lazy components
export const LazyCreateSearchFolderModal = createLazyComponent(
    lazyModule,
    m => m.CreateSearchFolderModal
);

// Export lazy actions
export const lazySetSearchFolderCreateModalVisibility = new LazyAction(
    lazyModule,
    m => m.setSearchFolderCreateModalVisibility
);
export const lazyEditSearchFolder = new LazyAction(lazyModule, m => m.editSearchFolder);

// Export lazy orchestrators
registerLazyOrchestrator(fetchSearchFolders, lazyModule, m => m.fetchSearchFoldersOrchestrator);
