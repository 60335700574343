import favoritesStore from '../../store/store';
import type { FavoriteData } from 'owa-favorites-types';
import getSecondaryKey from '../../utils/getSecondaryKey';

export default function isOutlookFavoritingInProgress(data: string | FavoriteData): boolean {
    if (!data) {
        return false;
    }

    const { favoritingInProgressMap } = favoritesStore;
    if (typeof data === 'string') {
        return favoritingInProgressMap.has(data);
    }

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2345 (18,40): Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    // @ts-expect-error
    return favoritingInProgressMap.has(getSecondaryKey(data));
}
