import { mutatorAction } from 'satcheljs';
import type ItemPartViewState from '../store/schema/ItemPartViewState';

export function setIsExpanded(state: ItemPartViewState, value: boolean) {
    state.isExpanded = value;
    if (value && !state.hasExpanded) {
        state.hasExpanded = value;
    }
}

export const setIsExpandedMutator = mutatorAction('setIsExpanded', setIsExpanded);
