import { LazyAction, LazyModule, LazyImport } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailSafeLinkShared" */ './lazyIndex'),
    {
        name: 'MailSafeLinkShared',
    }
);

export const lazyOnClickComposeLink = new LazyAction(lazyModule, m => m.onClickComposeLink);
export const lazyHandleLinkClickViaSafelinks = new LazyAction(
    lazyModule,
    m => m.handleLinkClickViaSafelinks
);
export const lazyHandleLinkClick = new LazyAction(lazyModule, m => m.handleLinkClick);

export const lazyCreateUrlDetectionContentHandler = new LazyImport(
    lazyModule,
    m => m.createUrlDetectionContentHandler
);
export const lazyRefreshSafelinksPolicy = new LazyImport(lazyModule, m => m.refreshSafelinksPolicy);
export const lazyGetSafelinksPolicyRefreshStatus = new LazyImport(
    lazyModule,
    m => m.getSafelinksPolicyRefreshStatus
);
export const addOnClickHandler = new LazyAction(lazyModule, m => m.addOnClickHandler);

export const lazyOpenJunkLinkVerification = new LazyImport(
    lazyModule,
    m => m.openJunkLinkVerification
);
