import loadConversationReadingPane from './loadConversationReadingPane';
import { updatePrimaryReadingPaneTabId } from 'owa-mail-reading-pane-store/lib/mutators/primaryReadingPaneTabIdMutators';
import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import getConversationReadingPaneViewState from '../utils/getConversationReadingPaneViewState';
import type { ClientItemId } from 'owa-client-ids';
import { lazyMoveComposeToTab } from 'owa-mail-compose-actions';
import findComposeFromTab from 'owa-mail-compose-actions/lib/utils/findComposeFromTab';
import type { ComposeViewState } from 'owa-mail-compose-store';
import { type AsyncSendState } from 'owa-mail-compose-store';
import { getFolderIdForSelectedNode } from 'owa-mail-folder-forest-store';
import mailStore from 'owa-mail-store/lib/store/Store';
import type { InstrumentationContext } from 'owa-search-types/lib/types/InstrumentationContext';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { getActiveContentTab, TabType } from 'owa-tab-store';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';
import type { LoadConversationItemActionSource } from 'owa-mail-store';
import type MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';

export interface LoadConversationReadingPaneForSingleMailItemSelectedState {
    conversationReadingPaneState: ConversationReadingPaneViewState;
    hasConversationInMailStore: boolean;
}

export default action('loadConversationReadingPaneForSingleMailItemSelected')(
    function loadConversationReadingPaneForSingleMailItemSelected(
        conversationId: ClientItemId,
        actionSource: LoadConversationItemActionSource,
        isUserNavigation: boolean,
        instrumentationContext: InstrumentationContext,
        conversationSubject: string,
        conversationCategories: string[],
        conversationCopilotInboxScore: number,
        mailListItemSelectionSource?: MailListItemSelectionSource,
        itemToScrollTo?: string,
        isSingleLineView?: boolean,
        numberOfItemsToRequest?: number,
        state: LoadConversationReadingPaneForSingleMailItemSelectedState = {
            conversationReadingPaneState: getConversationReadingPaneViewState(),
            hasConversationInMailStore: mailStore.conversations.has(conversationId?.Id),
        }
    ): Promise<void> {
        instrumentationContext?.dp?.addCheckpoint?.('LCRPFSMIS');
        // Skip if conversation id is null
        if (!conversationId) {
            return Promise.resolve();
        }
        const activeTab = getActiveContentTab();
        const composeViewState: ComposeViewState | undefined = state.conversationReadingPaneState
            ? findComposeFromTab(activeTab, state.conversationReadingPaneState.conversationId.Id, 0)
            : undefined;
        // Skip if user has not explicitly asked to navigate to a different conversation (!isUserNavigation)
        if (composeViewState && composeViewState.asyncSendState !== 6 && !isUserNavigation) {
            return Promise.resolve();
        }
        // Loading the same conversation in the same folder
        if (
            state.conversationReadingPaneState?.conversationId.Id == conversationId.Id &&
            state.conversationReadingPaneState?.currentSelectedFolderId ==
                getFolderIdForSelectedNode() &&
            state.hasConversationInMailStore
        ) {
            return Promise.resolve();
        }

        if (
            composeViewState &&
            activeTab &&
            activeTab.type != TabType.SecondaryReadingPane &&
            !composeViewState.isInlineCompose
        ) {
            // In the drafts folder, full compose opens in Primary tab.
            lazyMoveComposeToTab.importAndExecute(composeViewState, true, false);
        }

        updatePrimaryReadingPaneTabId(conversationId);

        const loadConversationReadingPanePromise = loadConversationReadingPane(
            conversationId,
            actionSource,
            instrumentationContext,
            conversationSubject,
            conversationCategories,
            conversationCopilotInboxScore,
            itemToScrollTo,
            mailListItemSelectionSource,
            isSingleLineView,
            numberOfItemsToRequest
        );

        return loadConversationReadingPanePromise;
    }
);
