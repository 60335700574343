import type MoveToFolderMenuStore from './schema/MoveToFolderMenuStore';
import { createStore } from 'satcheljs';

export const initialStore: MoveToFolderMenuStore = {
    findText: '',
    isFocusInSearchBox: false,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (10,5): Type 'null' is not assignable to type 'NewFolderViewState'.
    // @ts-expect-error
    newFolderViewState: null,
    shouldShowAllFolders: false,
    moveToMailboxType: 'UserMailbox',
    moveToSharedFolderRootId: '',
};

const moveToStore = createStore<MoveToFolderMenuStore>('moveToStore', { ...initialStore })();
export const getStore = () => moveToStore;
export default moveToStore;
