import { owaComputedFn } from 'owa-computed-fn';
import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import { getGroupFolderAndRulePermission } from 'owa-groups-shared-store/lib/selectors/groupFolderRulePermissionStoreSelectors';
import { doesUserHaveSharedFolderPermissionForWithError } from 'owa-mail-filterable-menu-behavior';
import checkItemReplyForwardDisabled from 'owa-mail-reading-pane-item-actions/lib/utils/checkItemReplyForwardDisabled';
import getItemRightsManagementRestrictions from 'owa-mail-store/lib/utils/getItemRightsManagementRestrictions';
import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { ClientItem } from 'owa-mail-store';
import type Item from 'owa-service/lib/contract/Item';
import type { ClientItemId } from 'owa-client-ids';
import { getAccountScopeUserSettings } from 'owa-session-store';
import {
    PRIMARY_DUMPSTER_DISTINGUISHED_ID,
    ARCHIVE_DUMPSTER_DISTINGUISHED_ID,
} from 'owa-folders-constants';
import { isNativeReportingEnabled } from 'owa-reporting';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { tryValidateDumpsterQuota } from 'owa-storage-store/lib/util/tryValidateDumpsterQuota';
import { isRecordOrRegulatoryRecord } from 'owa-mail-retention-policies/lib/utils/retentionPolicyTypeUtils';
import { getItem } from './getItem';
import { isItemInFolder as isInRestrictedFolder } from './isItemInFolder';
import { isSpamIntegrationRuntimeControlId } from 'owa-mail-ribbon-store/lib/util/isSpamIntegrationRuntimeControlId';
import shouldDisableMessageRecall from 'owa-mail-message-recall/lib/utils/shouldDisableMessageRecall';
import shouldDisabledReadReceipts from 'owa-track-read-receipts/lib/utils/shouldDisabledReadReceipts';
import isFeatureEnabled from 'owa-feature-flags/lib/utils/isFeatureEnabled';
import { shouldDisableMessageResend } from 'owa-message-resend';
import { shouldShowShareToTeamsChat } from './shouldShowRibbonControl';
import { isTeamsChatEnabled } from 'owa-teams-integration/lib/utils/isTeamsChatEnabled';
import { isCapabilityEnabled } from 'owa-capabilities';
import { inboxRulesCapability } from 'owa-capabilities-definitions/lib/inboxRulesCapability';
import { isReadPinnedAppRuntimeControl } from 'owa-mail-ribbon-store/lib/util/isReadPinnedAppRuntimeControl';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import isSenderSelf from 'owa-mail-store/lib/selectors/isSenderSelf';
import type Message from 'owa-service/lib/contract/Message';

export const getIsPopoutButtonDisabled = (
    controlId: number | string,
    itemId?: ClientItemId
): boolean => {
    // if there's no item id, that means we don't want to check anything for that control, so it always is enabled
    // this is true of the categories menu and a few other dropdown controls (as their visibility is controlled by the main button)
    if (!itemId) {
        return false;
    }

    // If we've sent in an id but can't retrieve the item, disable the buttons
    const item: ClientItem | undefined = getItem(itemId);
    if (!item) {
        return true;
    }

    // Identify if the ribbonId corresponding to a runtime control used for spam integration
    if (typeof controlId === 'number' && isSpamIntegrationRuntimeControlId(controlId)) {
        return false;
    }

    // Identify if the ribbonId corresponding to a runtime control used for read pinned addins
    // Since a popout can only be a single selected item, we should never disable read pinned addins for popouts
    if (typeof controlId === 'number' && isReadPinnedAppRuntimeControl(controlId)) {
        return false;
    }

    switch (controlId) {
        case 505:
            return shouldDisableArchive(item);
        case 616:
            return shouldDisableAssignPolicy(item);
        case 509:
            return shouldDisableCategorize(item);

        case 549:
            return shouldDisablePrint(item);

        case 547:
            return shouldDisablePhishing(item);
        case 535:
            return shouldDisableJunk(item);
        case 657:
            return shouldDisableReportSplit(item);

        case 658:
        case 507:
        case 659:
            return shouldDisableBlock(item);

        case 553:
        case 696:
        case 698:
            return isGroupItem(item) || isInRestrictedFolder(item, [junkFolderId, outboxFolderId]);
        case 527:
        case 699:
        case 700:
            return isGroupItem(item) || isInRestrictedFolder(item, [outboxFolderId]);

        case 519:
            return shouldDisableDelete(item);

        case 678:
        case 679:
        case 681:
        case 680:
        case 556:
        case 557:
        case 555:
        case 651:
            return isRespondItemDisabled(controlId, item);

        case 516:
            return shouldDisableCreateRule(item);

        case 686:
            if (!item.MailboxInfo) {
                return true;
            }
            return shouldDisableMessageRecall(item, item.MailboxInfo);

        case 697:
            return shouldDisabledReadReceipts(item);

        case 552:
            return isInRestrictedFolder(item, [outboxFolderId]);

        case 572:
            return shouldDisableSweep(item);
        case 559:
        case 532:
            return false;

        case 702:
        case 703:
            return !(
                isFeatureEnabled('mon-teams-ChatAroundEmail') &&
                isTeamsChatEnabled(item.MailboxInfo)
            );

        case 709:
            return !shouldShowShareToTeamsChat(item.MailboxInfo);

        case 705:
            return !isFeatureEnabled('mon-file-eml-msg');

        case 712:
            if (!item.MailboxInfo) {
                return true;
            }
            return shouldDisableMessageResend(item, item.MailboxInfo);

        case 7016:
            return false;

        default:
            return true;
    }
};

const isGroupItem = owaComputedFn(function isGroupItem(item: ClientItem): boolean {
    return item.MailboxInfo?.type == 'GroupMailbox';
});

const draftsFolderId = 'drafts';
const deletedItemsFolderId = 'deleteditems';
const junkFolderId = 'junkemail';
const archiveFolderId = 'archive';
const notesFolderId = 'notes';
const sentItemsFolderId = 'sentitems';
const scheduledFolderId = 'scheduled';
const clutterFolderId = 'clutter';
const outboxFolderId = 'outbox';

const dumpsterFolderIds: string[] = [
    PRIMARY_DUMPSTER_DISTINGUISHED_ID,
    ARCHIVE_DUMPSTER_DISTINGUISHED_ID,
];

function doesUserHaveSharedPermissionFolder(actionType: MenuItemType, item: ClientItem) {
    try {
        // Last, check user has permission for the action
        const folderId = item.ParentFolderId;
        if (!folderId) {
            return true;
        }
        return doesUserHaveSharedFolderPermissionForWithError(
            actionType,
            folderId.Id,
            item.MailboxInfo
        );
    } catch (e) {
        return true; // the logic from getMailMenuItemShouldShowMap returns true when an error occurs so we assume the user has access
    }
}

const shouldDisableArchive = owaComputedFn(function shouldDisableArchive(
    item: ClientItem
): boolean {
    if (isGroupItem(item)) {
        return true;
    }

    // we want to disable if it's not one of these mailbox types
    if (item.MailboxInfo?.type != 'UserMailbox') {
        return true;
    }

    return isInRestrictedFolder(item, [
        ...dumpsterFolderIds,
        archiveFolderId,
        deletedItemsFolderId,
        draftsFolderId,
        junkFolderId,
        notesFolderId,
        outboxFolderId,
    ]);
});

const shouldDisableDelete = owaComputedFn(function shouldDisableDelete(item: ClientItem): boolean {
    if (isGroupItem(item)) {
        const folderRulePermission = getGroupFolderAndRulePermission(
            item.MailboxInfo,
            item.MailboxInfo?.mailboxSmtpAddress
        );

        return !folderRulePermission?.canDeleteMessage;
    }

    if (!item.ItemId?.Id || !item.ParentFolderId?.Id) {
        return true;
    }

    return (
        isInRestrictedFolder(item, [notesFolderId]) ||
        !doesUserHaveSharedPermissionFolder(12, item) ||
        tryValidateDumpsterQuota(
            item.MailboxInfo,
            item.ParentFolderId.Id,
            undefined /* targetWindow */,
            true /* suppressPopup */
        ) ||
        isRecordOrRegulatoryRecord(item.ItemId.Id)
    );
});

const shouldDisableSweep = owaComputedFn((item: ClientItem): boolean => {
    if (
        isInRestrictedFolder(item, [
            ...dumpsterFolderIds,
            draftsFolderId,
            notesFolderId,
            sentItemsFolderId,
        ])
    ) {
        return true;
    }

    const senderSmtp = (item as Message)?.From?.Mailbox?.EmailAddress?.toLowerCase();
    return !!senderSmtp && isSenderSelf(senderSmtp, item.MailboxInfo);
});

const shouldDisableAssignPolicy = owaComputedFn(function shouldDisableAssignPolicy(
    item: ClientItem
): boolean {
    if (isGroupItem(item)) {
        return true;
    }
    // we want to disable if it's not one of these mailbox types
    if (
        !(
            item.MailboxInfo?.type == 'UserMailbox' ||
            item.MailboxInfo?.type == 'ArchiveMailbox' ||
            item.MailboxInfo?.type == 'SharedMailbox'
        )
    ) {
        return true;
    }
    // Disable if we're in the notes or dumpster folder
    return isInRestrictedFolder(item, [...dumpsterFolderIds, notesFolderId, outboxFolderId]);
});

const shouldDisablePrint = owaComputedFn(function shouldDisablePrint(item: ClientItem): boolean {
    const irmRestrictions = getItemRightsManagementRestrictions(item as Item);
    return (
        (irmRestrictions ? !irmRestrictions.PrintAllowed : false) ||
        isInRestrictedFolder(item, [outboxFolderId])
    );
});

const isRespondItemDisabled = owaComputedFn(function isRespondItemDisabled(
    controlId: number | string,
    item: ClientItem
): boolean {
    const itemResponseDisabled = checkItemReplyForwardDisabled(
        item,
        false /*isConversationItemPart*/
    );
    switch (controlId) {
        case 678:
            return itemResponseDisabled[0] && itemResponseDisabled[1] && itemResponseDisabled[2];
        case 556:
        case 679:
            return itemResponseDisabled[0];
        case 557:
        case 681:
            return itemResponseDisabled[1];
        case 555:
        case 680:
        case 651:
            return itemResponseDisabled[2];
        default:
            return true;
    }
});

const shouldDisableCategorize = owaComputedFn(function shouldDisableCategorize(
    item: ClientItem
): boolean {
    // If it's a group or shadow mailbox, we want to disable
    if (
        isGroupItem(item) ||
        !item.MailboxInfo || // disable if we have no mailbox information, as some mailbox types (ex: shadow) should have categories disabled
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * Adding IsShadowMailbox to restricted properties/methods.
         *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
        getAccountScopeUserSettings(item.MailboxInfo).SessionSettings?.IsShadowMailbox
    ) {
        return true;
    }
    // Disable if we're in the notes or dumpster folder
    if (isInRestrictedFolder(item, [...dumpsterFolderIds, notesFolderId, outboxFolderId])) {
        return true;
    }

    return !doesUserHaveSharedPermissionFolder(6, item);
});

const shouldDisableBlock = owaComputedFn(function shouldDisableBlock(item: ClientItem): boolean {
    return isInRestrictedFolder(item, [
        ...dumpsterFolderIds,
        draftsFolderId,
        sentItemsFolderId,
        clutterFolderId,
        scheduledFolderId,
        notesFolderId,
        outboxFolderId,
    ]);
});

const shouldDisablePhishing = owaComputedFn(function shouldDisablePhishing(
    item: ClientItem
): boolean {
    // If there's no mailbox info, disable because we can't check native reporting
    if (!item.MailboxInfo) {
        return true;
    }

    return (
        isInRestrictedFolder(item, [
            draftsFolderId,
            sentItemsFolderId,
            clutterFolderId,
            scheduledFolderId,
            notesFolderId,
            outboxFolderId,
            ...dumpsterFolderIds,
        ]) || !isNativeReportingEnabled(item.MailboxInfo)
    );
});

const shouldDisableJunk = owaComputedFn(function shouldDisableJunk(item: ClientItem): boolean {
    // If there's no mailbox info, disable because we can't check native reporting
    if (!item.MailboxInfo) {
        return true;
    }

    // disable if native reporting is not enabled
    if (!isNativeReportingEnabled(item.MailboxInfo)) {
        return true;
    }

    return isInRestrictedFolder(item, [
        draftsFolderId,
        sentItemsFolderId,
        clutterFolderId,
        scheduledFolderId,
        notesFolderId,
        outboxFolderId,
        ...dumpsterFolderIds,
    ]);
});

const shouldDisableReportSplit = owaComputedFn(function shouldDisableReportSplit(
    item: ClientItem
): boolean {
    // disable if there's no mailbox info
    if (!item.MailboxInfo) {
        return true;
    }

    // disable if the item is in outbox
    if (isInRestrictedFolder(item, [outboxFolderId])) {
        return true;
    }
    const isConsumerMailbox = isConsumer(undefined, item.MailboxInfo);
    return isConsumerMailbox ? shouldDisableJunk(item) : shouldDisablePhishing(item);
});

const shouldDisableCreateRule = owaComputedFn(function shouldDisableCreateRule(
    item: ClientItem
): boolean {
    // disable if rules are disabled
    if (isGroupItem(item) || !isCapabilityEnabled(inboxRulesCapability, item.MailboxInfo)) {
        return true;
    }

    return isInRestrictedFolder(item, [
        sentItemsFolderId,
        draftsFolderId,
        notesFolderId,
        outboxFolderId,
        ...dumpsterFolderIds,
    ]);
});
