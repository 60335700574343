import { createLazyComponent, LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "AccountConfig" */ './lazyIndex')
);

// Account configuration entrypoints
export const AccountConfig = createLazyComponent(lazyModule, m => m.AccountConfiguration);
export const lazyLaunchAccountConfigDialog = new LazyAction(
    lazyModule,
    m => m.launchAccountConfigDialog
);

// Proxies
export const lazyAuthAccount = new LazyAction(lazyModule, m => m.authAccountProxy);
export const lazySetAuthAccountResult = new LazyAction(
    lazyModule,
    m => m.setAuthAccountResultProxy
);
export const InlineContainer = createLazyComponent(lazyModule, m => m.InlineContainerProxy);
