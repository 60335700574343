import type { ClientItem } from 'owa-mail-store';
import getListViewStore from '../store/Store';

/**
 * Get the meeting message items based on the item id
 * @param itemId the item id
 * @param tableViewId id of the table view
 * @returns the meeting message items
 */
export default function getMeetingMessageItems(itemId: string): ClientItem | undefined {
    return getListViewStore().meetingMessageItems.get(itemId);
}
