import type { SessionData } from 'owa-service/lib/types/SessionData';

let stash: SessionData | undefined;

export function stashSessionData(sd: SessionData | undefined) {
    stash = sd;
}

export function getSessionData(): SessionData | undefined {
    return stash;
}
