import store from '../store/store';
import { getFallbackValueIfNull } from 'owa-options-core';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { getUserConfiguration } from 'owa-session-store';
import type { ReadingPaneConversationOptions } from 'owa-outlook-service-option-store';
import { getOptionsForFeature, OwsOptionsFeatureType } from 'owa-outlook-service-option-store';

export default function isReadingPaneConversationEnabled(): boolean {
    return getFallbackValueIfNull(
        store.readingPaneConversationEnabled,
        getServerConversationEnabledOption()
    );
}

function getServerConversationEnabledOption(): boolean {
    const readingPaneConversationOptions = getOptionsForFeature<ReadingPaneConversationOptions>(
        OwsOptionsFeatureType.ReadingPaneConversation
    );
    if (readingPaneConversationOptions) {
        return readingPaneConversationOptions.conversationEnabled;
    } else {
        const userOptions = getUserConfiguration().UserOptions;
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2532 (36,16): Object is possibly 'undefined'.
        // @ts-expect-error
        return userOptions.GlobalListViewTypeReact === 0;
    }
}
