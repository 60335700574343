import store from '../store/store';
import { computed } from 'mobx';
import type { ITheme } from '@fluentui/style-utilities';
import { createTheme } from '@fluentui/style-utilities';

const extendedTheme = computed<ITheme>(() => {
    return createTheme(store());
});

export default () => extendedTheme.get();
