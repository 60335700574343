import type { MailboxInfo } from 'owa-client-types';
import { type CreateFolderInvoker, type CreateFolderDepth } from 'owa-folders-constants';
import { lazyCreateNewFolder } from 'owa-folder-createnew';
import { lazyCreateNewGroupFolder } from 'owa-groups-shared-folders-actions';

export function createNewFolder(
    displayName: string,
    parentId: string,
    mailboxInfo: MailboxInfo
): Promise<string | null> {
    return lazyCreateNewFolder.importAndExecute(
        {
            parentId,
            displayName,
            mailboxInfo,
        },
        { invoker: 'movetofolder', depth: 'parent' }
    );
}

export function createNewGroupFolder(
    displayName: string,
    parentId: string,
    mailboxInfo: MailboxInfo
): Promise<string | null> {
    return lazyCreateNewGroupFolder.importAndExecute(
        mailboxInfo?.mailboxSmtpAddress,
        displayName.trim(),
        parentId,
        mailboxInfo
    );
}
