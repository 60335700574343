import type {
    FavoriteData,
    FavoriteCategoryData,
    FavoriteGroupData,
    FavoriteFolderData,
    FavoritePersonaData,
    FavoritePublicFolderData,
} from 'owa-favorites-types';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

export default function getSecondaryKey(clientFavoriteData: FavoriteData) {
    switch (clientFavoriteData.type) {
        case 'folder':
            return (clientFavoriteData as FavoriteFolderData).folderId;

        case 'group':
            return transformGroupSmtp((clientFavoriteData as FavoriteGroupData).groupId);

        case 'category':
            return (clientFavoriteData as FavoriteCategoryData).categoryId;

        case 'persona':
            const personaData = clientFavoriteData as FavoritePersonaData;
            return personaData.personaId || personaData.mainEmailAddress;

        case 'publicFolder':
            return (clientFavoriteData as FavoritePublicFolderData).publicFolderId;

        default:
            return undefined;
    }
}
