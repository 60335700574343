import type { MailboxInfo } from 'owa-client-types';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import { getAccountScopeUserSettings } from '../selectors/getAccountScopeUserSettings';
import { mailboxInfoOrTemporaryGlobal } from './mailboxInfoOrTemporaryGlobal';

export default function isBusiness(mailboxInfo?: MailboxInfo): boolean {
    const sessionSettings = getAccountScopeUserSettings(
        mailboxInfoOrTemporaryGlobal(mailboxInfo)
    ).SessionSettings;
    return sessionSettings?.WebSessionType === 0;
}
