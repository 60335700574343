export function isValidImageUri(src: string | undefined = '') {
    if (src.startsWith('//')) {
        return true;
    }

    try {
        new URL(src);
        return true;
    } catch {
        if (src.startsWith('data:image/png;base64')) {
            return true;
        }
        return false;
    }
}
