import { createStore } from 'satcheljs';
import type {
    GroupSiteStatusInformation,
    GroupSiteStatusInformationByMailboxInfo,
} from './schema/GroupSiteStatusInformation';
import { ObservableMap } from 'mobx';

const store = createStore<GroupSiteStatusInformationByMailboxInfo>('getGroupSiteStatusStore', {
    groupSiteStatusByMailboxInfo: new ObservableMap<string, GroupSiteStatusInformation>(),
})();

export const getGroupSiteStatusStore = () => store;
