import { mutatorAction } from 'satcheljs';
import type { MailListItemAnimation } from '../store/schema/MailListItemAnimationStore';

export const cancelActiveRowAnimations = mutatorAction(
    'cancelActiveRowAnimations',
    (animation: MailListItemAnimation) => {
        const activeRowAnimations = animation.rowAnimations ?? [];
        if (activeRowAnimations) {
            for (const activeRowAnimation of activeRowAnimations) {
                activeRowAnimation?.cancel();
            }
        }
    }
);
