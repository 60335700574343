import { getStore } from '../store/store';
import { DEFAULT_FLEXPANE_WIDTH } from '../utils/constants';

/**
 * Returns the FlexPane's width.
 * If 'flexPaneId' is supplied, will return the width of that specific FlexPane.
 * If not supplied, will return the width of the currently shown OWA FlexPane.
 * Returns 0 if no FlexPane is currently shown.
 */
export function getFlexPaneWidth(flexPaneId?: string): number {
    // Return specific flexPaneId's width
    if (flexPaneId) {
        const flexPaneState = getStore().flexPaneStateMap.get(flexPaneId);
        if (flexPaneState && flexPaneState.isExpanded) {
            return flexPaneState.expandedWidth;
        } else {
            return DEFAULT_FLEXPANE_WIDTH; // FlexPane not found in map, id hasn't been registered yet
        }
    }

    // Return currentFlexPaneState's width
    if (getStore().currentFlexPaneState.isShown && getStore().currentFlexPaneState.isExpanded) {
        return getStore().currentFlexPaneState.expandedWidth;
    } else {
        return DEFAULT_FLEXPANE_WIDTH; // FlexPane is not shown, still return default width for TaskPanes or other panes
    }
}
