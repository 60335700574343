import { makeGetRequest } from 'owa-ows-gateway';
import { isSuccessStatusCode } from 'owa-http-status-codes';
import type GetBrandsSubscriptionsServiceResponse from '../store/schema/GetBrandsSubscriptionsServiceResponse';
import populateSubscriptionStore from '../mutators/populateSubscriptionStore';
import type { MailboxInfo } from 'owa-client-types';

const GET_BRANDS_SUBSCRIPTION_URL: string = 'ows/api/beta/subscriptions?orderBy=name, asc';

async function getBrandsSubscriptionsService(
    mailboxInfo: MailboxInfo,
    dontLoadBrandsInfo?: boolean
): Promise<GetBrandsSubscriptionsServiceResponse> {
    const response = await makeGetRequest(
        dontLoadBrandsInfo
            ? GET_BRANDS_SUBSCRIPTION_URL + '&noResolveBrand=true'
            : GET_BRANDS_SUBSCRIPTION_URL,
        undefined /* correlationId */,
        true /* returnFullResponse */,
        undefined /* customHeaders */,
        undefined /* throwServiceError */,
        undefined /* includeCredentials */,
        'getBrandsSubscriptions' /* actionName */,
        mailboxInfo
    );
    if (isSuccessStatusCode(response.status)) {
        const unsubscribeResponse = await response.json();
        populateSubscriptionStore(unsubscribeResponse);
        return unsubscribeResponse;
    }

    return Promise.reject(
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        new Error(
            'getBrandsSubscriptionsService failed. Response status:' +
                response.status +
                ' Response body:' +
                response.body
        )
    );
}

export default getBrandsSubscriptionsService;
