import { updateIsSuiteHeaderRendered } from 'owa-suite-header-store';
import { orchestrator } from 'satcheljs';
import { isPanePinningEnabled } from '../utils/isPanePinningEnabled';
import { clearPendingAutoOpenOperation, getPendingAutoOpenOperation } from '../utils/tryAutoOpen';

// once suite header is rendered, trigger any pending auto-open operation
orchestrator(updateIsSuiteHeaderRendered, ({ isRendered }) => {
    if (!isPanePinningEnabled()) {
        return;
    }

    if (isRendered) {
        const pendingAutoOpenOperation = getPendingAutoOpenOperation();
        if (pendingAutoOpenOperation) {
            pendingAutoOpenOperation();
            clearPendingAutoOpenOperation();
        }
    }
});
