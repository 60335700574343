import { lazyGetCopyToSections } from 'owa-mail-ribbon-sections/lib/sections';
import type { MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onCopyToMenuClicked = (
    keytipTabId: MailRibbonTabId | undefined,
    props: ReadOnlyRibbonControlDefProps
) => {
    lazyGetCopyToSections.import().then(getCopyToSections => {
        setMenuDefinition('CopyToMenuDefinitionStore', props.projectionTabId, () => {
            return {
                sections: getCopyToSections(keytipTabId, props),
            };
        });
    });
};
