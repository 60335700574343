import { getOpxHostApi } from 'owa-opx';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { getBposPromise } from 'owa-bpos-store';
import 'owa-pwa-utils';
import { bootstrapApolloCache } from './initialization/bootstrapApolloCache';
import { getCommonBootstrap } from './getCommonBootstrap';
import initializeNestedAppAuthExecutor from './initialization/initializeNestedAppAuthExecutor';
import type { BootstrapOptions } from './types/BootstrapOptions';

export function bootstrap(options: BootstrapOptions) {
    return getCommonBootstrap({
        bootstrapApolloCache,
        getBposPromise,
        getNestedAppAuthExecutorPromise: initializeNestedAppAuthExecutor,
        onComponentError: () => {
            if (isHostAppFeatureEnabled('opxComponentLifecycle')) {
                getOpxHostApi().onFatalError();
            }
        },
    })(options);
}
