import type { AccountFilterCallback } from './registerForAccountStateChange';
import type { AccountSource } from 'owa-account-source-list-store';

export const OnlyMailStateChanges: AccountFilterCallback = function (
    accountSource: AccountSource
): boolean {
    return accountSource.contracts.supportsMail;
};

export const OnlyCalendarStateChanges: AccountFilterCallback = function (
    accountSource: AccountSource
): boolean {
    return accountSource.contracts.supportsCalendar;
};

export const OnlyContactsStateChanges: AccountFilterCallback = function (
    accountSource: AccountSource
): boolean {
    return accountSource.contracts.supportsContacts;
};
