export const showMessageOptionsText = "VBF4we";
export const switchToPlainTextText = "zQb$Td";
export const switchToHTMLTextText = "Cu5Nne";
export const print_Draft_Button = "eTNHec";
export const save_Button = "AxsHnc";
export const saveAs_Button = "MQRCxc";
export const attachSig_FlyoutButton = "EtaFuc";
export const encrypt_FlyoutButtonText = "UPOZ1";
export const sensitivity_FlyoutButton_ActionControl = "BNalVb";
export const toggleFromText = "Jm4j2";
export const toggleBccText = "VIb5se";
export const toggleCcText = "$cLoIc";
export const readReceiptText = "tkHeQb";
export const deliveryReceiptText = "N$GNke";
export const attachFile_FlyoutButton_ActionControl = "rtyu$b";
export const proofingOptionsText = "ZNI6nc";
export const getAddIns_Button_ActionControl = "Ov4n4d";
export const polls_Button_ActionControl = "DmY9Q";
export const fluidText = "hi82je";
export const recordLoopVideo = "O9dvxe";
export const disallowReactionsText = "KeNRMe";
export const highImportance = "kIyZAc";
export const lowImportance = "SA1E2d";
export const draftWithCopilotText = "ciToVc";
export const coachWithCopilotText = "XST5N";
export const startMailMergeText = "iwOCUd";
export default {showMessageOptionsText,switchToPlainTextText,switchToHTMLTextText,print_Draft_Button,save_Button,saveAs_Button,attachSig_FlyoutButton,encrypt_FlyoutButtonText,sensitivity_FlyoutButton_ActionControl,toggleFromText,toggleBccText,toggleCcText,readReceiptText,deliveryReceiptText,attachFile_FlyoutButton_ActionControl,proofingOptionsText,getAddIns_Button_ActionControl,polls_Button_ActionControl,fluidText,recordLoopVideo,disallowReactionsText,highImportance,lowImportance,draftWithCopilotText,coachWithCopilotText,startMailMergeText};