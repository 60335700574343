import type {
    ComposeRibbonControlDefProps,
    RibbonControlDefinitionProps,
} from './RibbonControlDefinitionProps';
import { comparer } from 'mobx';

import type { MailboxInfo } from 'owa-client-types';
import { owaComputedFn } from 'owa-computed-fn';
import { getComposeViewState } from 'owa-mail-reading-pane-store-conversation/lib/utils/getComposeViewState';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

/**
 * Finds the mailboxInfo using setting on props, then compose view state, then selected tableview
 * @param props control definition props
 * @returns mailboxInfo
 */
export const getRibbonMailboxInfo = owaComputedFn(
    function getRibbonMailboxInfo(props: RibbonControlDefinitionProps | undefined): MailboxInfo {
        return (
            props?.mailboxInfo ??
            ((props as ComposeRibbonControlDefProps)?.composeViewState ?? getComposeViewState())
                ?.mailboxInfo ??
            getModuleContextMailboxInfo()
        );
    },
    {
        equals: comparer.shallow, // Only one layer of depth is needed for MailboxInfo
    }
);
