import { ApolloLink, Observable } from '@apollo/client';
import type { ResolverContext } from 'owa-graph-schema';
import { lazyExecute, lazySubscribe } from 'owa-apollo-execute';
import { asyncIteratorToObservable } from '../util/asyncIteratorToObservable';
import { getOperationType } from '../util/getOperationType';
import { type ResolversProfile } from '../factories/createGraphServerLink';

export default function executeGraphLink(
    getResolvers: () => Promise<ResolversProfile>,
    globalContext: any
) {
    const link = new ApolloLink(operation => {
        if (getOperationType(operation) === 'subscription') {
            return asyncIteratorToObservable(() =>
                getResolvers().then(resolverProfile =>
                    lazySubscribe.importAndExecute(
                        resolverProfile.resolvers,
                        operation,
                        globalContext
                    )
                )
            );
        }

        return new Observable(observer => {
            getResolvers().then(resolverProfile => {
                lazyExecute
                    .importAndExecute(resolverProfile.resolvers, operation, globalContext)
                    .then(result => {
                        if (resolverProfile.dataSource) {
                            const context = operation.getContext() as ResolverContext;
                            const perfDatapoint = context?.perfDatapoint;
                            if (perfDatapoint) {
                                perfDatapoint.dataSource = resolverProfile.dataSource;
                            }
                        }
                        observer.next(result);
                        observer.complete();
                    })
                    .catch(e => {
                        observer.error(e);
                    });
            });
        });
    });

    return link;
}
