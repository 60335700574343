import { xor } from './utils/xor';
import type { TableQueryType } from 'owa-mail-list-store';
import { getSelectedTableView } from 'owa-mail-list-store';

export const tableQueryTypeRestriction =
    (tableQueryTypes: TableQueryType[], shouldHide?: boolean) => () => {
        const tableView = getSelectedTableView();
        if (!tableView) {
            return false;
        }
        const {
            tableQuery: { type },
        } = tableView;
        const isTableQueryInRestrictionList = tableQueryTypes.includes(type);

        return xor(shouldHide, isTableQueryInRestrictionList);
    };
