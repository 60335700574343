import { isFeatureEnabled } from 'owa-feature-flags';
import type MailListColumnHeaderType from '../types/MailListColumnHeaderType';
import { getListViewColumnDefaultWidths } from 'owa-mail-layout';

export default function getColumnMinWidth(headerType: MailListColumnHeaderType): number {
    const isRelocateHoverActionsFlightEnabled = isFeatureEnabled('tri-mlRelocateHoverActions');

    // default width and min width are the same for sender and received columns.
    // since there is no set default width for subject column, keep it as 92 so it never
    // disappears completely.
    const { senderColumnWidth, receivedColumnWidth } = getListViewColumnDefaultWidths();

    switch (headerType) {
        case 0:
            return isRelocateHoverActionsFlightEnabled ? senderColumnWidth : 128;
        case 1:
            return 92;
        case 2:
            return isRelocateHoverActionsFlightEnabled ? receivedColumnWidth : 150;
        default:
            return 0;
    }
}
