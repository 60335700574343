import getStore from '../store/store';
import { getCdnUrl } from 'owa-config';
import { isFeatureEnabled } from 'owa-feature-flags';

const FAVICON_RELATIVE_PATH = 'images/favicons';
const MAIL_SEEN_FAVICON = `${FAVICON_RELATIVE_PATH}/mail-seen.ico`;
const MAIL_UNSEEN_FAVICON = `${FAVICON_RELATIVE_PATH}/mail-unseen.ico`;

const OLK_ICON = getNativeHostIconUrl('outlook-beta.ico');
const OLK_UNSEEN_ICON = getNativeHostIconUrl('outlook.ico'); // Temp placeholder, new icons tbd
const OLK_MAIL_UNSEEN_OVERLAY_ICON = getNativeHostIconUrl('outlook-badge-newmail-win11.ico');

export function mailFaviconProvider(): string {
    var mailSeenIcon: string = MAIL_SEEN_FAVICON;
    var mailUnseenIcon: string = MAIL_UNSEEN_FAVICON;

    if (isFeatureEnabled('mail-updateHostAppIcon')) {
        mailSeenIcon = OLK_ICON;
        mailUnseenIcon = OLK_UNSEEN_ICON;
    }

    return !getStore().areMessagesUnseen ? mailSeenIcon : mailUnseenIcon;
}

export function mailOverlayIconProvider(): string | undefined {
    if (isFeatureEnabled('mail-updateHostAppIcon')) {
        return !getStore().areMessagesUnseen
            ? '' // Explicitly indicates no overlay
            : OLK_MAIL_UNSEEN_OVERLAY_ICON;
    }

    return undefined;
}

function getNativeHostIconUrl(imageRelativePath: string): string {
    return `https:${getCdnUrl()}assets/native-host/icons/${imageRelativePath}`;
}
