import { getPaletteAsRawColors } from 'owa-theme';
import { isFeatureEnabled } from 'owa-feature-flags';
import { addToStorage, removeFromStorage } from './utils/localStorage';
import updateThemeTag from './utils/updateTag';

export default function refreshTheme() {
    if (isFeatureEnabled('fwk-pwa-themed')) {
        const colorcode = getPaletteAsRawColors().themePrimary as string;
        updateThemeTag(colorcode);
        addToStorage(colorcode);
    } else {
        removeFromStorage();
    }
}
