import type TabViewState from '../store/schema/TabViewState';
import { TabType, type TabState } from '../store/schema/TabViewState';
import getActiveContentTab from '../utils/getActiveContentTab';
import setTabIsShown from './setTabIsShown';
import { PerformanceDatapoint } from 'owa-analytics';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';
import { getTabHandler } from '../utils/TabHandler';
import { isFeatureEnabled } from 'owa-feature-flags';
import { errorThatWillCauseAlertAndThrow } from 'owa-trace';

export default action('activateTab')(function (viewState: TabViewState, isUserAction?: boolean) {
    const dp = new PerformanceDatapoint('TabViewActivateTab', { isVerbose: true });
    dp.addCustomData([viewState.type, isUserAction]);
    setTabIsShown(viewState, true /*isShown*/);

    switch (viewState.type) {
        case TabType.Primary:
        case TabType.OverflowMenu:
        case TabType.SecondaryReadingPane:
        case TabType.MailCompose:
        case TabType.FullOptions:
        case TabType.Reminders:
        case TabType.AddCalendars:
            if (
                viewState.type === TabType.FullOptions &&
                !isFeatureEnabled('acct-fullOptionsProjectionPopout')
            ) {
                errorThatWillCauseAlertAndThrow(
                    'activateTab should NOT be called for FullOptions when acct-fullOptionsProjectionPopout flight is OFF'
                );
            }

            if (viewState.state != 2) {
                const activeTab = getActiveContentTab();

                if (activeTab) {
                    activeTab.state = 1;
                    const handler = getTabHandler(activeTab.type);
                    if (handler?.onDeactivate) {
                        handler.onDeactivate(activeTab);
                    }
                }

                if (viewState) {
                    viewState.state = 2;
                    viewState.blink = false;

                    const handler = getTabHandler(viewState.type);
                    if (handler?.onActivate) {
                        handler.onActivate(viewState);
                    }
                }
            }
    }
    dp.end();
});
