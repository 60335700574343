import { mutator } from 'satcheljs';
import store from '../store/store';
import removeMessageAdList from '../actions/removeMessageAdList';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
export default mutator(removeMessageAdList, actionMessage => {
    store.inboxFolderShowAdCount = actionMessage.remainingShowAdCount;
    store.latestAdDeleteTime = Date.now();
});
