import { LazyModule, LazyAction, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "FreezeDry" */ './lazyIndex'), {
    name: 'FreezeDry',
});

export const RestoreItemsDialog = createLazyComponent(lazyModule, m => m.RestoreItemsDialog);
export const lazyCreateOrUpdateMainWindowItem = new LazyAction(
    lazyModule,
    m => m.createOrUpdateMainWindowItem
);
export const lazyKickOffFreezeDry = new LazyAction(lazyModule, m => m.kickOffFreezeDry);
export const lazySetShouldInitializeFreezeDry = new LazyAction(
    lazyModule,
    m => m.setShouldInitializeFreezeDry
);
