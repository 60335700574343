import { wrapInApolloProvider } from 'owa-apollo/lib/react';
import { getDensity } from 'owa-fabric-theme';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Avoid deprecated APIs, use portals instead of rendering to a new root
 *	> 'render' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root */
import { render } from 'owa-react-dom';
import React from 'react';
import { ThemeProvider } from '@fluentui/react/lib/Theme';

const themeProviderStyle = { background: 'inherit' };

export default function renderInsideFlexPane(
    elements: React.ReactNode | React.ReactNode[],
    flexPaneTitle: string,
    className?: string
) {
    return (container: HTMLDivElement) =>
        render(
            wrapInApolloProvider(
                <ThemeProvider
                    theme={getDensity()}
                    tabIndex={-1}
                    role={'complementary'}
                    aria-label={flexPaneTitle}
                    className={className}
                    style={themeProviderStyle}
                >
                    {elements}
                </ThemeProvider>
            ),
            container
        );
}
