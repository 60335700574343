import { createStore } from 'satcheljs';
import { DEFAULT_FLEXPANE_WIDTH } from '../utils/constants';

interface SuiteHeaderStore {
    isRendered: boolean;
    isShy: boolean;
    currentFlexPaneState: FlexPaneState; // State of currently shown FlexPane
    flexPaneStateMap: Map<string /* flexPaneId */, FlexPaneState>;
}

export interface FlexPaneState {
    isShown: boolean;
    isExpanded: boolean;
    expandedWidth: number;
}

const store = createStore<SuiteHeaderStore>('suiteHeaderStore', {
    isRendered: false,
    isShy: false,
    currentFlexPaneState: {
        isShown: false,
        isExpanded: false,
        expandedWidth: DEFAULT_FLEXPANE_WIDTH,
    },
    flexPaneStateMap: new Map<string /* flexPaneId */, FlexPaneState>(),
})();
export const getStore = () => store;
