import loc from 'owa-localize';
import Strings from 'owa-locstrings/lib/strings/ribbon_mail_buttons_and_tabs.locstring.json';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, SingleLine } from 'owa-command-ribbon';
import { getInitialScalingSteps } from '../../../util/getInitialScalingSteps';
import { getMailRibbonConfigStore } from 'owa-mail-ribbon-store/lib/store/store';
import { getScalingSteps } from '../../../util/getScalingSteps';
import { getSLRNotesTabControlDefinition } from './getSLRNotesTabControlDefinition';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { getTabGroups } from '../../../util/getTabGroups';
import type {
    RibbonControlGroupDefinitionSLR,
    RibbonTabDefinitionBase,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { getUniqueTabId } from 'owa-mail-ribbon-utils/lib/getUniqueTabId';
import {
    controlsDontDropLabels,
    controlsDontMoveToOverflow,
    controlsWithoutLabels,
} from './slrNotesTabScalingSpecialCases';

const getSLRNotesTab = owaComputedFn(function getSLRNotesTab(
    props: ReadOnlyRibbonControlDefProps
): RibbonTabDefinitionBase {
    return {
        type: SingleLine,
        tabType: 'contextual',
        id: getUniqueTabId(6, undefined /* editorId */, props.itemId, props.isPopout),
        title: loc(Strings.notes_tab),
        controlGroups: { type: SingleLine, groups: getNotesTabGroups(props) },
        initialScalingSteps: getInitialScalingSteps(
            getMailRibbonConfigStore(props.mailboxInfo).singleline.notesTab,
            controlsWithoutLabels,
            undefined /*editorId*/,
            undefined /*itemId*/
        ),
        scalingSteps: getScalingSteps(
            getMailRibbonConfigStore(props.mailboxInfo).singleline.notesTab.layout,
            getMailRibbonConfigStore(props.mailboxInfo).singleline.notesTab.showLabelsPreference,
            controlsDontMoveToOverflow,
            [] /* controlsFirstMoveToOverflow */,
            controlsDontDropLabels,
            undefined /*editorId*/,
            undefined /*itemId*/
        ),
        keytipProps: constructKeytip([], 6, true /* hasMenu */),
    };
});
export default getSLRNotesTab;

/**
 * Retrieves the groups within the Notes tab, as well as the actions that
 * fall under each group.
 * @returns an object that contains the sub-groups and corresponding actions of the
 * Notes tab
 */
const getNotesTabGroups = owaComputedFn(function getNotesTabGroups(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlGroupDefinitionSLR[] {
    let notesTabGroups: RibbonControlGroupDefinitionSLR[] = new Array();
    notesTabGroups = getTabGroups(
        6,
        getMailRibbonConfigStore(props.mailboxInfo).singleline.notesTab.layout,
        getSLRNotesTabControlDefinition,
        props
    );
    return notesTabGroups;
});
