import { LazyAction, LazyModule } from 'owa-bundling';
export { ReportType, PostReportAction } from './utils/reportUtils';
export { getReportDialogBody } from './utils/preReportDialog/getReportDialogBody';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "Reporting"*/ './lazyIndex'), {
    name: 'Reporting',
});

// Called internally to this package, but exporting this way so we can utilize
// it lazily and reduce impact on the boot bundle size. It is not expected to be
// used outside of this package.
export const lazyFetchReportDialogStrings = new LazyAction(
    lazyModule,
    m => m.fetchReportDialogStrings
);

export const lazyGetReportDialogStrings = new LazyAction(lazyModule, m => m.getReportDialogStrings);
export const lazyShowPostReportDialog = new LazyAction(lazyModule, m => m.showPostReportDialog);
export const lazyGetReportDialogLearnMoreLinkProperties = new LazyAction(
    lazyModule,
    m => m.getReportDialogLearnMoreLinkProperties
);
export const lazyShowPreReportDialog = new LazyAction(lazyModule, m => m.showPreReportDialog);

export { default as getReportSubmissionPolicy } from './selectors/getReportSubmissionPolicy';
export { default as isNativeReportingEnabled } from './selectors/isNativeReportingEnabled';
export { default as isPostReportDialogEnabled } from './selectors/isPostReportDialogEnabled';
export { default as isPreReportDialogEnabled } from './selectors/isPreReportDialogEnabled';
