import type { AttachmentModel } from 'owa-attachment-model-store';
import loc from 'owa-localize';
import { noSubject } from 'owa-locstrings/lib/strings/nosubject.locstring.json';
import { ATP_EXTENSION } from './constants';
import isATPAttachment from './isATPAttachment';

export default function getAttachmentName(attachment?: AttachmentModel): string {
    if (attachment?.model?.Name) {
        if (isATPAttachment(attachment)) {
            return attachment.model.Name.replace(ATP_EXTENSION, '');
        } else {
            return attachment.model.Name;
        }
    } else {
        return loc(noSubject);
    }
}
