import { LazyAction, LazyModule, LazyImport } from 'owa-bundling';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "GroupList" */ './lazyIndex'), {
    name: 'GroupList',
});

// Delay loaded actions
export const lazyToggleGroupTreeExpansion = new LazyAction(
    lazyModule,
    m => m.toggleGroupTreeExpansion
);

export const lazyUnsubscribeFromUnreadNotificationsUsingGql = new LazyAction(
    lazyModule,
    m => m.unsubscribeToUnreadCountNotificationsUsingGql
);

export const lazyUnsubscribeFromUnreadNotifications = new LazyAction(
    lazyModule,
    m => m.unsubscribeFromUnreadNotifications
);

export const lazySubscribeToAllGroupsUnreadNotifications = new LazyAction(
    lazyModule,
    m => m.subscribeToAllGroupsUnreadNotifications
);

export const lazyLoadGroupAccountSources = new LazyAction(
    lazyModule,
    m => m.loadGroupAccountSources
);

export const lazyLoadGroupAccountSourcesForCurrentSession = new LazyAction(
    lazyModule,
    m => m.loadGroupAccountSourcesForCurrentSession
);

export const lazyDoesGroupSmtpExistInLeftNav = new LazyImport(
    lazyModule,
    m => m.doesGroupSmtpExistInLeftNav
);

export const lazyDropMailListRowsOnGroup = new LazyAction(
    lazyModule,
    m => m.dropMailListRowsOnGroup
);
