import loc from 'owa-localize';
import { NovaEventTypes } from 'owa-nova-actions/lib/types';
import { settingsPageButtonLabel } from '../strings/SettingsPageCommand.locstring.json';

import type { M365Acquisition } from 'owa-graph-schema';
import type { StrictAppSecondaryAction } from '../types';
import type { MailboxInfo } from 'owa-client-types';

export function getSettingsSecondaryAction(
    acquisition: M365Acquisition,
    mailboxInfo?: MailboxInfo
): StrictAppSecondaryAction {
    return {
        __typename: 'AppSecondaryAction',
        id: '6b862fc1-9aec-41aa-8194-f332df226bdf',
        icon: {
            __typename: 'InternalIcon',
            name: 'SettingsIcon',
        },
        text: loc(settingsPageButtonLabel) ?? 'Settings',
        eventType: NovaEventTypes.settings,
        eventData: JSON.stringify({
            titleId: acquisition.titleId,
            appId: acquisition?.appId,
            mailboxInfo,
        }),
    };
}
