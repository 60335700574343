import type { RequestOptions } from 'owa-service/lib/RequestOptions';
import { default as getMailboxRequestOptions } from 'owa-service/lib/getMailboxRequestOptions';
import { isMailboxRequestOptions } from 'owa-service/lib/MailboxRequestOptions';
import type { default as MailboxRequestOptions } from 'owa-service/lib/MailboxRequestOptions';
import type { MailboxInfo } from 'owa-client-types';

/**
 * In OWA the token is obtained via connected accounts, so we need to make it possible for OWA
 * to set the connected account token. The GetHeadersForConnectedAccountFunc is the type
 * of callback that we expect OWA to set.
 */
type GetHeadersForConnectedAccountFunc = (
    mailboxRequestOptions: MailboxRequestOptions,
    preserveOriginalHeaders?: boolean
) => Promise<RequestOptions>;

/**
 * Holds the currently set callback for allowing connected accounts to add headers
 */
let thePluggableGetHeadersForConnectedAccountFunc: GetHeadersForConnectedAccountFunc | undefined =
    undefined;

/**
 * Sets the pluggable callback for allowing connected accounts to set headers
 * @param callback Callback for setting the header
 */
export function setConnectedAccountHeadersCallback(callback: GetHeadersForConnectedAccountFunc) {
    thePluggableGetHeadersForConnectedAccountFunc = callback;
}

/**
 * Ensures that the options contains a MailboxInfo
 * @param options RequestOptions to be checked to ensure that it is a MailboxRequestInfo
 * @param mailboxInfo Mailbox info to be used if none is present in the RequestOptions
 * @returns A MailboxRequestInfo value
 */
function ensureMailboxRequestOptions(
    mailboxInfo: MailboxInfo,
    options?: RequestOptions
): MailboxRequestOptions {
    if (isMailboxRequestOptions(options)) {
        return options;
    }

    return {
        mailboxInfo,
        ...options,
    };
}

/**
 * Get the MailboxRequestOptions using the supplied options, mailboxInfo and optionally additional
 * headers set by connected accounts
 * @param mailboxInfo MailboxInfo used to direct the request
 * @param options Optional RequestOptions to be used in the request
 * @returns MailboxRequestOptions for making the request
 */
export async function getAdditionalAccountMailboxRequestOptions(
    mailboxInfo: MailboxInfo,
    options?: RequestOptions
): Promise<MailboxRequestOptions> {
    // We are passing in a defined MailboxInfo it will always return a MailboxRequestOptions;
    const mailboxRequestOptions = ensureMailboxRequestOptions(
        mailboxInfo,
        getMailboxRequestOptions(mailboxInfo, options)
    );

    if (thePluggableGetHeadersForConnectedAccountFunc) {
        return ensureMailboxRequestOptions(
            mailboxInfo,
            await thePluggableGetHeadersForConnectedAccountFunc(mailboxRequestOptions, true)
        );
    }

    return mailboxRequestOptions;
}
