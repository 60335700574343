import type { MailboxInfo } from 'owa-client-types';
import type UnifiedGroupDetails from 'owa-service/lib/contract/UnifiedGroupDetails';
import { getGroupInformation } from './getGroupInformation';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

export default function getGroupDetails(
    mailboxInfo: MailboxInfo,
    groupSmtpAddress: string
): UnifiedGroupDetails | undefined {
    const group = getGroupInformation(mailboxInfo, transformGroupSmtp(groupSmtpAddress));
    return group?.groupDetails;
}
