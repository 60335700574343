import { createStore } from 'satcheljs';
import type {
    SuggestedUnsubscribeStoreMap,
    SuggestedUnsubscribeStore,
} from './schema/SuggestedUnsubscribeStore';
import { ObservableMap } from 'mobx';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

function defaultStore(): SuggestedUnsubscribeStore {
    return {
        lastFetch: 0,
        suggestedUnsubscribeItems: [],
    };
}

const suggestedUnsubscribeStoreMap = createStore<SuggestedUnsubscribeStoreMap>(
    'suggestedUnsubscribeStore',
    {
        entries: new ObservableMap(),
    }
)();

export function getSuggestedUnsubscribeStore(mailboxInfo: MailboxInfo) {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    let store = suggestedUnsubscribeStoreMap.entries.get(key);
    if (!store) {
        store = defaultStore();
        suggestedUnsubscribeStoreMap.entries.set(key, store);
    }
    return store;
}
