// eslint-disable-next-line node/no-deprecated-api  -- (https://aka.ms/OWALintWiki) * This should be fixed, but "new URL()" has somewhat different behavior that makes automated migration difficult. *	> 'url.resolve' was deprecated since v11.0.0. Use 'url.URL' constructor instead. */
import { resolve as urlResolve } from 'url';
import { getPackageBaseUrl, getResourcePath } from 'owa-config/lib/bootstrapOptions';

export default function getOwaResourceUrl(partialUrl: string) {
    return urlResolve(getPackageBaseUrl(), partialUrl);
}

export function getResourcePathUrl(partialUrl: string) {
    return urlResolve(getResourcePath(), partialUrl);
}
