import { getNonBootUserConfigurationSync } from '../getNonBootUserConfiguration';
import type { MailboxInfo } from 'owa-client-types';

export default function isMessageRecallEnabled(mailboxInfo: MailboxInfo): boolean {
    const config = getNonBootUserConfigurationSync(mailboxInfo);
    if (!config) {
        return false;
    }

    // MessageRecallEnabled setting can be null, in this case we still treat the setting as enabled.
    return config.MessageRecallEnabled != false;
}
