import type { DataCollector } from 'diagnostics-data-collection';
import { lazyRegisterDataCollectorIfNotRegistered } from 'diagnostics-data-collection';
import { default as getHostedApp } from '../selectors/getHostedApp';
import { default as getIsInError } from '../selectors/getIsInError';
import strings from '../strings/messageExtensionAppInstalled.locstring.json';
import loc from 'owa-localize';

export default async function registerDataCollectors() {
    const appHostDataCollector: DataCollector = {
        dataCollectionAction: appHostDataCollectionAction,
        name: 'MetaOSAppHostData',
        odsFileDescription: 'App host data',
        description: loc(strings.appHostDiagnosticDataDescription),
    };

    await lazyRegisterDataCollectorIfNotRegistered.importAndExecute(appHostDataCollector);
}

function appHostDataCollectionAction(): Promise<string> {
    const { appDefinition, appId } = getHostedApp();
    let result = {};
    if (!appDefinition) {
        result = {
            appName: 'Not Loaded',
        };
    } else {
        result = {
            appName: appDefinition?.name /* metaOS app name */,
            appID: appId /* metaOS app id */,
            isInError: getIsInError() /* error loading metaOS app */,
        };
    }

    return Promise.resolve(JSON.stringify(result));
}
