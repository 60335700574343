import {
    type AccountState,
    addCoprincipalAccount,
    getAccountBySourceId,
    accountRankTypeChecker,
    updateCoprincipalAccountDisplayName,
} from 'owa-account-source-list-store';
import { AccountSourceType } from 'owa-account-source-list-types';

import type { MailboxInfo } from 'owa-client-types';
import {
    buildCoprincipalAccountSourceFromCoprincipalAccount,
    buildM365ConnectedMailboxAccountSourceFromCoprincipalAccount,
} from './accountSourceBuilder';
import type { CoprincipalAccount } from 'owa-account-storage-types';
import { buildCoprincipalSourceId } from './sourceIdBuilder';

/**
 * Returns the MailboxInfo for the specified sourceId
 * @param sourceId the sourceId for the account
 * @returns The mailbox information, or undefined if the account is not a mailbox
 */
export function getMailboxBySourceId(sourceId: string): MailboxInfo | undefined {
    const sourceInfo = getAccountBySourceId(sourceId);
    return sourceInfo?.mailboxInfo;
}

/**
 * convert the given account to the appropriate CoprincipalAccountSource
 * derivation and adds to the source list store, and returns the sourceId
 * @param account The account to be added
 */
export function addCoprincipalAccountToStore(account: CoprincipalAccount): string {
    const accountSource = buildCoprincipalAccountSourceFromCoprincipalAccount(account);
    if (!getAccountBySourceId(accountSource.sourceId)) {
        addCoprincipalAccount(accountSource);
    }

    return accountSource.sourceId;
}

/**
 * Applies updates to the CoprincipalAccountSource object corresponding to the given CoprincipalAccount
 * from local storage; currently, only a subset of account properties support updates.
 * @param account The stored account with updates to be applied to its corresponding account source object
 */
export function updateCoprincipalAccountInStore(accountFromStorage: CoprincipalAccount): void {
    const sourceIdOfAccountToUpdate = buildCoprincipalSourceId(accountFromStorage.uuid);
    const accountFromStore = getAccountBySourceId(sourceIdOfAccountToUpdate);
    if (!accountFromStore || !accountRankTypeChecker.isCoprincipal(accountFromStore)) {
        throw new Error('Account not found in store');
    }

    // Keep the account's displayName updated with changes to persisted account's emailAddress property
    if (accountFromStore.displayName !== accountFromStorage.emailAddress) {
        updateCoprincipalAccountDisplayName(
            sourceIdOfAccountToUpdate,
            accountFromStorage.emailAddress
        );
    }
}

/**
 * convert the given account to M365ConnectedMailboxAccountSource
 * and adds to the source list store, and returns the sourceId
 * @param account
 */
export function addM365ConnectedMailboxToStore(
    account: CoprincipalAccount,
    anchorMailbox: string,
    accountState: AccountState = {
        connectedAccountState: 0 /** OWAConnectedAccountState.Valid */,
    }
): string {
    if (
        account.accountType === AccountSourceType.Other ||
        account.accountType === AccountSourceType.PstFile
    ) {
        throw new Error('Can not create a Connected Mailbox for an account that has no mailbox');
    }

    const accountSource = buildM365ConnectedMailboxAccountSourceFromCoprincipalAccount(
        account,
        anchorMailbox,
        accountState
    );

    if (!getAccountBySourceId(accountSource.sourceId)) {
        addCoprincipalAccount(accountSource);
    }

    return accountSource.sourceId;
}
