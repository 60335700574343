import type MailFaviconStore from './schema/MailFaviconStore';
import { createStore } from 'satcheljs';

const mailFaviconStoreData: MailFaviconStore = {
    areMessagesUnseen: false,
};

const mailFavIconStore = createStore<MailFaviconStore>('mailFaviconStore', mailFaviconStoreData)();
const getStore = () => mailFavIconStore;
export default getStore;
