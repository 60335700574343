import { lazyGetMoveToSections } from 'owa-mail-ribbon-sections/lib/sections';

import type { MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onMoveToMenuClicked = (
    keytipTabId: MailRibbonTabId | undefined,
    props: ReadOnlyRibbonControlDefProps
) => {
    lazyGetMoveToSections.import().then(getMoveToSections => {
        setMenuDefinition('MoveToMenuDefinitionStore', undefined /* projectionTabId */, () => {
            return {
                sections: getMoveToSections(keytipTabId, props),
            };
        });
    });
};
