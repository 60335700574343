import store from '../store/store';
import { getFallbackValueIfNull } from 'owa-options-core';
import { getUserConfiguration } from 'owa-session-store';
import { updateUserConfigurationAndService } from 'owa-userconfiguration/lib/utils/updateUserConfigurationAndService';

export default async function saveMessagePreviewOption() {
    await onMessagePreviewToggle(store.showPreviewTextInListView);
}

export async function onMessagePreviewToggle(isEnabled: boolean | null) {
    const newValue = getFallbackValueIfNull(
        isEnabled,
        getUserConfiguration().UserOptions?.ShowPreviewTextInListView
    );

    await updateUserConfigurationAndService({
        UserOptions: {
            ShowPreviewTextInListView: newValue,
        },
    });
}
