import React from 'react';
import { addEventTimeToSourceMapping } from 'owa-performance';

export function useMouseWithPerfWrapper<T = Element>(
    cb: React.MouseEventHandler<T> | undefined,
    source: string
): React.MouseEventHandler<T> {
    return React.useCallback(
        (evt: React.MouseEvent<T>) => {
            addEventTimeToSourceMapping(evt.nativeEvent, source);
            cb?.(evt);
        },
        [cb]
    );
}
