import { isFeatureEnabled } from 'owa-feature-flags';
import { onContentDropped } from 'owa-nova-actions';
import { FirstPartyAppId } from 'owa-m365-acquisitions/lib/data/M365AppId';
import { getDragData } from 'owa-dnd';
import { launchFullComposeFromDropData } from 'owa-calendar-forms-launch-handlers';
import { orchestrator } from 'satcheljs';

/**
 * Receives Appbar content drop event and dispatches the appropriate action depending on
 * the tile ID of the app that received the drop event.
 */
export const onContentDroppedOrchestrator = orchestrator(onContentDropped, async actionMessage => {
    const data = actionMessage.event.data?.();
    switch (data?.tileID) {
        case FirstPartyAppId.Calendar:
            if (isFeatureEnabled('cal-external-appbar-dnd')) {
                launchFullComposeFromDropData(getDragData(data.dropData));
            }
            break;
        default:
            break;
    }
});
