import { LazyModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "NativeHostPushNotifications" */ './lazyIndex')
);

export const lazyUnsubscribeToNativePushNotificationForAllAccounts = new LazyAction(
    lazyModule,
    m => m.unsubscribeToNativePushNotificationForAllAccounts
);

export const lazyUnsubscribeToNativePushNotification = new LazyAction(
    lazyModule,
    m => m.unsubscribeToNativePushNotification
);
