import { lazyGetRespondSections } from 'owa-mail-ribbon-sections/lib/sections';

import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onRespondMenuClicked = (props: ReadOnlyRibbonControlDefProps) => {
    lazyGetRespondSections.import().then(getRespondSections => {
        setMenuDefinition('RespondMenuDefinitionStore', props.projectionTabId, () => {
            return {
                sections: getRespondSections(props),
            };
        });
    });
};
