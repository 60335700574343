import type FeatureFlagsStore from './schema/FeatureFlagsStore';
import type { FeatureName } from './featureDefinitions';
import type { MailboxInfo } from 'owa-client-types';
import getFeatureFlagsIndexerForMailboxInfo from '../utils/getFeatureFlagsIndexerForMailboxInfo';

const flags = new Map<string, FeatureFlagsStore>();

export default function store(mailboxInfo?: MailboxInfo | null): FeatureFlagsStore {
    const key = getFeatureFlagsIndexerForMailboxInfo(mailboxInfo);
    let rv = flags.get(key);

    if (!rv) {
        const featureFlagStore: FeatureFlagsStore = {
            featureFlags: new Map<FeatureName, boolean>(),
        };
        flags.set(key, featureFlagStore);
        rv = featureFlagStore;
    }

    return rv;
}

// Allow the unit tests to reset the store
export function resetStoreForUnitTests() {
    flags.clear();
}

// Allow the unit tests to inspect how data is stored in the store
export function getStoreByKeyForUnitTests(key: string): FeatureFlagsStore | undefined {
    return flags.get(key);
}
