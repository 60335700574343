import {
    getSelectedTableView,
    getListViewState,
    MailRowDataPropertyGetter,
} from 'owa-mail-list-store';
import type { ActionSource } from 'owa-analytics-types';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { lazyShowConversationInImmersiveReader } from 'owa-mail-message-actions';
import { lazyShowInImmersiveReader } from 'owa-immersive-reader-store';
import getExpandedItemId from '../utils/getExpandedItemId';

export default function onShowInImmersiveReader(actionSource: ActionSource) {
    const tableView = getSelectedTableView();
    const rowKeys = [...tableView.selectedRowKeys.keys()];

    const nodeIds = getListViewState().expandedConversationViewState.selectedNodeIds;
    if (nodeIds.length === 1) {
        const clientItemId = getExpandedItemId(tableView, nodeIds);
        lazyShowInImmersiveReader.importAndExecute(clientItemId, actionSource);
    } else {
        const selectedRowId = MailRowDataPropertyGetter.getRowClientItemId(rowKeys[0], tableView);
        switch (tableView.tableQuery.listViewType) {
            case 0:
                lazyShowConversationInImmersiveReader.importAndExecute(
                    selectedRowId /* conversationId */,
                    actionSource
                );
                break;

            case 1:
                lazyShowInImmersiveReader.importAndExecute(
                    selectedRowId /* itemId */,
                    actionSource
                );
                break;
        }
    }
}
