import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getAccountScopeUserSettings } from 'owa-session-store';

export default function getCachedEUEmailPolicyOptInBit() {
    // Get the prime settings
    const primeSettings = getAccountScopeUserSettings(
        getGlobalSettingsAccountMailboxInfo()
    )?.PrimeSettings;
    let eUEmailAdsOptions: any;

    if (primeSettings?.Items) {
        for (const primeSetting of primeSettings.Items) {
            if (primeSetting.Id === 'EUEmailAdsOptions') {
                eUEmailAdsOptions = primeSetting.Value?.options?.[0];
                break;
            }
        }
    }

    return eUEmailAdsOptions?.optInBit;
}
