import { getAccountScopeUserSettings } from 'owa-session-store';
import getRoutingKeyPrefixForSmtpAddress from './getRoutingKeyPrefixForSmtpAddress';
import { isFeatureEnabled } from 'owa-feature-flags';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import type { MailboxInfo } from 'owa-client-types';

// This is exported for the sake of unit testing. It is not intended for use by application code.
//
// This handles the address fallback scenarios for the getInstance method below.
export function getFallbackAddress(
    hexCID: string | undefined,
    notManagedAddresses: readonly string[] | undefined,
    proxyAddresses: readonly string[] | undefined
): string {
    if (!!proxyAddresses) {
        for (const proxyAddress of proxyAddresses) {
            if (!isGeneratedAddress(hexCID, proxyAddress)) {
                return proxyAddress;
            }
        }
    }

    if (!!notManagedAddresses && notManagedAddresses.length > 0) {
        return notManagedAddresses[0];
    }

    // Do not change this value, we'll be searching for it in the logs.
    return 'NoResolvableAddress@invalid.outlook.com';
}

// This is exported for the sake of unit testing. It is not intended for use by application code.
// SPECIAL EXCEPTION: widget scenario
//
// Migrated users with EASI-ID accounts ended up with their primary SMTP
// address set to something of the form outlook_HEXADECIMAL@outlook.com,
// where the HEXADECIMAL segment is the account's CID.
//
// Those generated addresses do not exist in mserv, and the OWA server
// resolves consumers via mserv, so we can't use those addresses in download
// URLs. So we need to be able to detect them.
export function isGeneratedAddress(
    hexCID: string | undefined,
    emailAddress: string | undefined
): boolean {
    if (!hexCID || !emailAddress) {
        return false;
    }
    const generatedAddress = 'outlook_' + hexCID + '@outlook.com';
    return generatedAddress.toLowerCase() === emailAddress.toLowerCase();
}

// The value returned from this function is intended for use by CAFE to route
// requests to the correct mailbox server. It should not be used for any other
// purpose. The returned values will change as routing-key requirements change.
export default function getDefaultRoutingKey(mailboxInfo: MailboxInfo): string {
    let routingKey = getDefaultRoutingKeyInternal(mailboxInfo);
    routingKey = encodeURIComponent(routingKey);
    return routingKey;
}

function getDefaultRoutingKeyInternal(mailboxInfo: MailboxInfo): string {
    const accountScopeUserSettings = getAccountScopeUserSettings(mailboxInfo);

    // This will work for business users, and for most consumers.
    const routingKey = accountScopeUserSettings.SessionSettings?.UserEmailAddress;

    if (accountScopeUserSettings.SessionSettings?.WebSessionType === 0) {
        return getRoutingKeyPrefixForSmtpAddress(mailboxInfo) + routingKey;
    }

    if (isFeatureEnabled('doc-attachment-routingKeyPuid')) {
        return (
            'PUID:' +
            accountScopeUserSettings.SessionSettings?.UserPuid +
            '@' +
            accountScopeUserSettings.SessionSettings?.TenantGuid
        );
    }

    /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
     * Adding IsShadowMailbox to restricted properties/methods.
     *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
    if (accountScopeUserSettings.SessionSettings?.IsShadowMailbox) {
        // For shadow mailboxes, UserEmailAddress doesn't resolve in mserv,
        // but SessionSettings.LogonEmailAddress does.
        return (
            getRoutingKeyPrefixForSmtpAddress(mailboxInfo) +
            accountScopeUserSettings.SessionSettings.LogonEmailAddress
        );
    } else if (
        isGeneratedAddress(
            accountScopeUserSettings.HexCID,
            accountScopeUserSettings.SessionSettings?.UserEmailAddress
        )
    ) {
        // For migrated EASI-ID mailboxes, neither UserEmailAddress
        // nor LogonEmailAddress resolves in mserv, but the
        // NotManagedEmailAddresses collection typically contains just
        // one address, which is resolvable. And we'll try the proxy
        // address collection if the not-managed collection is empty.
        return (
            getRoutingKeyPrefixForSmtpAddress(mailboxInfo) +
            getFallbackAddress(
                accountScopeUserSettings.HexCID,
                accountScopeUserSettings.SessionSettings?.NotManagedEmailAddresses,
                accountScopeUserSettings.SessionSettings?.UserProxyAddresses
            )
        );
    }

    return getRoutingKeyPrefixForSmtpAddress(mailboxInfo) + routingKey;
}
