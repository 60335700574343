import {
    BootState,
    getAllMailAccountSources,
    getGlobalSettingsAccount,
    getGlobalSettingsAccountMailboxInfo,
} from 'owa-account-source-list-store';
import { MailFolderTreesParentContainer } from 'owa-mail-folder-tree-container-view';
import TreeNode from 'owa-tree-node/lib/components/TreeNode';
import React from 'react';

export const GlobalSettingsAccountFolderNodeWithDummyList =
    function GlobalSettingsAccountFolderNodeWithDummyList() {
        const accountSources = getAllMailAccountSources();
        const globalSettingsAccountSource = getGlobalSettingsAccount();

        // The pre-render work for the global settings account happens in startup
        const globalSettingsBootState = BootState.StartupComplete;
        const chevronProps = React.useMemo(() => {
            return { isExpanded: false, onClick: () => {} };
        }, []);

        return (
            <>
                <MailFolderTreesParentContainer
                    favoritesNodeCount={1}
                    accountMailboxInfo={getGlobalSettingsAccountMailboxInfo()}
                    accountBootState={globalSettingsBootState}
                />

                {accountSources
                    .filter(
                        accountSource =>
                            accountSource.sourceId !== globalSettingsAccountSource.sourceId
                    )
                    .map(accountSource => (
                        <TreeNode
                            chevronProps={chevronProps}
                            displayName={accountSource.mailboxInfo.mailboxSmtpAddress}
                            depth={0}
                            isRootNode={true}
                            key={accountSource.sourceId}
                            isDroppedOver={false}
                        />
                    ))}
            </>
        );
    };
