import { getPanelViewType } from './getPanelViewType';
import type { PanelView } from '../store/schema/TimePanelSettingsStore';
import { getTimePanelConfig } from 'owa-time-panel-config';
import {
    hasToDoAccountSources,
    canUseToDoFeatures,
    isTodoMultiAccountAvailable,
} from 'owa-todo-module-utils';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export function isSupportedViewForUser(view: PanelView) {
    if (getPanelViewType(view) === 'Tasks') {
        if (isTodoMultiAccountAvailable()) {
            return hasToDoAccountSources() && !getTimePanelConfig().isTasksViewDisabled;
        }

        return (
            canUseToDoFeatures(getGlobalSettingsAccountMailboxInfo()) &&
            !getTimePanelConfig().isTasksViewDisabled
        );
    }
    return true;
}
