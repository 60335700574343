import { getStore } from '../store/store';
import { logUsage } from 'owa-analytics';
import type { CommandingViewMode } from 'owa-outlook-service-option-store/lib/store/schema/options/CommandingOptions';
import { mutatorAction } from 'satcheljs';

export const onViewModeChangedInternal = mutatorAction(
    'onViewModeChangedInternal',
    (viewMode: CommandingViewMode) => {
        logUsage('ViewModeChanged', { from: getStore().viewMode, to: viewMode });

        getStore().viewMode = viewMode;
    }
);
