import { mutatorAction } from 'satcheljs';
import { getConditionalFormattingStore } from '../store/store';
import type { ConditionalFormattingRule } from '../store/schema/ConditionalFormattingRule';
import type { MailboxInfo } from 'owa-client-types';

export default mutatorAction(
    'setConditionalFormattingRules',
    function setConditionalFormattingRules(
        mailboxInfo: MailboxInfo,
        updatedRules: ConditionalFormattingRule[]
    ) {
        getConditionalFormattingStore(mailboxInfo).conditionalFormattingRules = updatedRules;
    }
);
