import { getStore } from '../store';

/**
 * Determines if a time zone is available for use (yet).
 *
 * On first boot we only have the timezone info (ranges) for the primary time zoen
 * So on initial render only the primary time zone can be used.  Later during boot
 * the time zone ranges for all known time zones are fetched.
 *
 * @param timeZone
 * @returns True if the time zone is available; false otherwise
 */
export default function isTimezoneAvailable(timeZone: string) {
    const store = getStore();
    const ranges = store.TimeZoneRanges[timeZone];

    return !!ranges;
}
