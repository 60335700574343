import type AttachmentStore from './schema/AttachmentStore';
import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';

const attachmentStore: AttachmentStore = {
    attachments: new ObservableMap({}),
};

const store = createStore<AttachmentStore>('attachment', attachmentStore)();
export const getStore = () => store;
export default getStore;
