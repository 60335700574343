import { getCopilotPaletteOverrides } from 'owa-theme-common';
import type { ThemeResources } from 'owa-theme-shared';
import { getStore } from '../store/store';

export default function getCopilotThemeResources(isDarkTheme: boolean): ThemeResources {
    return isDarkTheme
        ? {
              ...getStore().options.darkThemeResources,
              themePalette: {
                  ...getStore().options.darkThemeResources.themePalette,
                  ...getCopilotPaletteOverrides(isDarkTheme),
              },
          }
        : {
              ...getStore().options.lightThemeResources,
              themePalette: {
                  ...getStore().options.lightThemeResources.themePalette,
                  ...getCopilotPaletteOverrides(isDarkTheme),
              },
          };
}
