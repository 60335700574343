import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailListLog } from '../store/schema/MailListLogsStore';
import { mailListLogsStore } from '../store/store';
import { trace } from 'owa-trace';

const MAX_LOGS = 1000;

/**
 * Action which adds the mail list log to the store
 */
export function addMailListLog(mailListLog: MailListLog) {
    if (!isFeatureEnabled('mon-messageList-logging')) {
        return;
    }

    trace.verbose(`MailListLog: ${mailListLog.message}`);
    const { mailListLogs } = mailListLogsStore;

    if (mailListLogs.length === MAX_LOGS) {
        // If we already have the maxLogs, then remove the first
        mailListLogs.shift();
    }

    mailListLogs.push(mailListLog);
}
