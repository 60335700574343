import type { AccountSource } from 'owa-account-source-list-store';
import { AccountSourceType } from 'owa-account-source-list-types';

/**
 * Returns true if the account source is an enterprise account
 * @param accountType Type of the account to check
 * @returns True if it is an enterprise account, false otherwise
 */
export function isEnterpriseAccountType(accountType: AccountSourceType): boolean {
    return accountType === AccountSourceType.Office365;
}

/**
 * Returns true if the account source is an enterprise account
 * @param account Account to check
 * @returns True if it is an enterprise account, false otherwise
 */
export default function isEnterprise(account: AccountSource): boolean {
    return isEnterpriseAccountType(account.sourceType);
}
