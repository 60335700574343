import ClientItemObservableMap from './schema/ClientItemObservableMap';
import { ObservableMap } from 'mobx';
import { logUsage } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';
import { createStore } from 'satcheljs';
import type ConversationItemParts from './schema/ConversationItemParts';
import type ConversationReadingPaneNode from './schema/ConversationReadingPaneNode';
import type MailStore from './schema/MailStore';
import type ClientItem from './schema/ClientItem';

const beforeSetItem = (value: ClientItem) => {
    if (!value.MailboxInfo) {
        const errorString = 'SetItemNoMailboxInfo';
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logUsage(errorString, { stack: new Error(errorString).stack });
    }
};

const mailStoreData: MailStore = {
    conversationNodes: new ObservableMap<string, ConversationReadingPaneNode>({}),
    conversations: new ObservableMap<string, ConversationItemParts>({}),
    items: isFeatureEnabled('ring-dogfood', undefined, true)
        ? new ClientItemObservableMap({}, undefined, undefined, beforeSetItem)
        : new ObservableMap({}),
    searchFolderDisplayName: undefined,
    typeOfItemBeingDragged: '',
    triageAnnouncement: {
        message: '',
        politenessSetting: 'off',
    },
};

const store = createStore<MailStore>('mail', mailStoreData)();
export default store;
export const getStore = () => store;
