import { owaDate } from './owaDate';
import type { OwaDate } from '../schema';
import isEqual from './isEqual';
import startOfDay from './startOfDay';
import { getYear, getMonth, getDate } from './getFields';

/**
 * Returns true if two dates, normalized to the same time zone, have the same date, ignoring the time.
 */
export default (left: OwaDate, right: OwaDate | string | number) =>
    isEqual(startOfDay(left), startOfDay(owaDate(left.tz, right)));

/**
 * Returns true if two dates, ignoring the time zone, have the same date, ignoring the time.
 */
export const isSameRawDay = (a: OwaDate, b: OwaDate) =>
    getYear(a) == getYear(b) && getMonth(a) == getMonth(b) && getDate(a) == getDate(b);
