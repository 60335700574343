export enum NotificationActionType {
    AsyncSendFailed,
    AddFavoritePersonaFailedNotification,
    RemoveFavoritePersonaFailedNotification,
    PeopleHubNotification,
    SkypeNotification,
    SkypeForBusinessNotification,
    SaveEventFailed,
    SaveDeskReservationFailed,
    ChatwithParticipantsFailed,
    SaveAndCloseFailed,
    AsyncSendOfflineFailed,
    ShareToTeamsNotAllowed,
    CopyActionFailed,
}

interface NotificationViewState {
    id: number;
    actionType: NotificationActionType;
    title: string;
    description: string;
    actionLink: string;
    cancelLink?: string;
    image?: string;
    /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
     * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
     *	> Optional object properties should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
    actionContext?: any;
    ignore?: boolean;
    timedOut?: boolean;
}

export default NotificationViewState;
