import type TableView from '../store/schema/TableView';
import folderStore from 'owa-folders';
import type SearchFolderTemplateId from 'owa-service/lib/contract/SearchFolderTemplateId';

// Determines whether the table view is showing a search folder which is one of the Unread types
export default function isUnreadSearchFolder(tableView: TableView): boolean {
    if (!tableView) {
        return false;
    }

    const folder = folderStore.folderTable.get(tableView.tableQuery.folderId);
    return (
        folder?.SearchFolderTemplateId != null &&
        SearchFoldersWithUnreadFilter.indexOf(folder.SearchFolderTemplateId) > -1
    );
}

const SearchFoldersWithUnreadFilter: SearchFolderTemplateId[] = [2, 4];
