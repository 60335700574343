import { mutatorAction } from 'satcheljs';
import type { SearchScenarioId } from 'owa-search-store';
import { getScenarioStore } from 'owa-search-store';

export const resetFocusFromSuggestionsToSearchInput = mutatorAction(
    'RESET_FOCUS_FROM_SUGGESTIONS_TO_SEARCH_INPUT',
    (scenarioId: SearchScenarioId) => {
        const searchStore = getScenarioStore(scenarioId);

        searchStore.selectedSuggestionIndex = -1;
    }
);
