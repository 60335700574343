import type { MailboxInfo } from 'owa-client-types';
import { action } from 'satcheljs';

const moveToFolder = action(
    'Move_To_Folder',
    (
        folderName: string,
        mailboxInfo: MailboxInfo,
        hostItemIndex: string,
        targetWindow: Window
    ) => ({
        folderName,
        mailboxInfo,
        hostItemIndex,
        targetWindow,
    })
);

export default moveToFolder;
