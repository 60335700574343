import { FluentButton } from 'owa-fluent-v9-shims';
import type { IButton, IButtonStyles } from '@fluentui/react/lib/Button';
import type { IContextualMenuItem, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { ContextualMenuItemType } from '@fluentui/react/lib/ContextualMenu';
import { observer } from 'owa-mobx-react';
import { getIsViewModeSelected, onViewModeChanged } from 'owa-command-ribbon-store';
import loc from 'owa-localize';
import CommandingModeMenuStrings from 'owa-locstrings/lib/strings/commandingModeMenu.locstring.json';
import { type CommandingViewMode } from 'owa-outlook-service-option-store/lib/store/schema/options/CommandingOptions';
import { getPalette } from 'owa-theme';
import React from 'react';
import type { IKeytipProps } from '@fluentui/react/lib/Keytip';
import { owaComputedFn } from 'owa-computed-fn';
import { HighContrastSelector } from '@fluentui/style-utilities';

const keytip: string = 'ZR';
const keytipLowerCase = keytip.toLocaleLowerCase();
let commandingModeKey = 0;

export default observer(function CommandingModeMenu() {
    const iconButtonRef = React.createRef<IButton>();

    const keytipProps: IKeytipProps = React.useMemo(
        () => ({
            content: keytip,
            hasMenu: true,
            keySequences: [keytipLowerCase],
            onExecute: () => iconButtonRef.current?.openMenu(),
        }),
        [iconButtonRef]
    );

    const title = loc(CommandingModeMenuStrings.ribbonLayoutButtonLabel);

    return (
        <FluentButton
            id="RibbonLayoutButton"
            appearance="icon"
            aria-label={title}
            componentRef={iconButtonRef}
            keytipProps={keytipProps}
            menuProps={getMenuProps(iconButtonRef.current)}
            styles={getFluentButtonStyles()}
            title={title}
            key={commandingModeKey.toString()}
        />
    );
}, 'CommandingModeMenu');

const getFluentButtonStyles = (): IButtonStyles => {
    const { neutralDark, neutralQuaternaryAlt } = getPalette();
    const iconColor = neutralDark;

    return {
        menuIcon: {
            fontSize: '10px',
            fontWeight: 600,
        },
        root: {
            color: iconColor,
            height: '20px',
            padding: 0,

            // Revising default focus state to make it fully visible
            '.ms-Fabric--isFocusVisible &:focus::after': {
                [HighContrastSelector]: {
                    inset: 0,
                },
            },
        },
        rootExpanded: {
            background: neutralQuaternaryAlt,
        },
        rootHovered: {
            color: iconColor,
        },
    };
};

const getMenuProps = owaComputedFn((iconButtonRef: IButton | null): IContextualMenuProps => {
    return {
        items: [
            {
                itemType: ContextualMenuItemType.Section,
                key: 'section1',
                sectionProps: {
                    items: [
                        getContextualMenuItem({
                            iconButtonRef,
                            key: 'C',
                            text: loc(CommandingModeMenuStrings.multiLineRibbonLabel),
                            viewMode: 2,
                        }),
                        getContextualMenuItem({
                            iconButtonRef,
                            key: 'S',
                            text: loc(CommandingModeMenuStrings.singleLineRibbonLabel),
                            viewMode: 1,
                        }),
                    ],
                    title: loc(CommandingModeMenuStrings.layoutHeaderLabel),
                },
            },
        ],
    };
});

const getContextualMenuItem = ({
    iconButtonRef,
    key,
    text,
    viewMode,
}: {
    iconButtonRef: IButton | null;
    key: string;
    text: string;
    viewMode: CommandingViewMode;
}): IContextualMenuItem => {
    const changeViewMode = () => {
        // FluentUI's keytip code registers keytips when a component mounts and calls cleanup before each update.
        // Update the component key so that React re-mounts the component and ensures that FluentUI re-registers the keytip.
        commandingModeKey++;
        iconButtonRef?.dismissMenu();
        onViewModeChanged(viewMode);
    };

    return {
        canCheck: true,
        checked: getIsViewModeSelected(viewMode),
        key,
        keytipProps: {
            content: key,
            keySequences: [keytipLowerCase, key.toLocaleLowerCase()],
            offset: {
                left: 10,
                top: 10,
            },
            onExecute: changeViewMode,
        },
        onClick: changeViewMode,
        text,
    };
};
