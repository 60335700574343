export interface SupportedLocale {
    locale: string;
    dir: 'rtl' | 'ltr';
    localeNotFound?: boolean;
}

export const DEFAULT_LOCALE = 'en';

export const localeManifest = {
    en: {
        dir: 'ltr',
    },
    af: {
        dir: 'ltr',
    },
    am: {
        dir: 'ltr',
    },
    ar: {
        dir: 'rtl',
    },
    'as-IN': {
        dir: 'ltr',
    },
    'az-Latn-AZ': {
        dir: 'ltr',
    },
    bg: {
        dir: 'ltr',
    },
    'bn-IN': {
        dir: 'ltr',
    },
    'bs-Latn-BA': {
        dir: 'ltr',
    },
    ca: {
        dir: 'ltr',
    },
    'ca-Es-VALENCIA': {
        dir: 'ltr',
    },
    cs: {
        dir: 'ltr',
    },
    cy: {
        dir: 'ltr',
    },
    da: {
        dir: 'ltr',
    },
    de: {
        dir: 'ltr',
    },
    el: {
        dir: 'ltr',
    },
    'en-GB': {
        dir: 'ltr',
    },
    es: {
        dir: 'ltr',
    },
    'es-MX': {
        dir: 'ltr',
    },
    et: {
        dir: 'ltr',
    },
    eu: {
        dir: 'ltr',
    },
    fa: {
        dir: 'rtl',
    },
    fi: {
        dir: 'ltr',
    },
    fil: {
        dir: 'ltr',
    },
    fr: {
        dir: 'ltr',
    },
    'fr-CA': {
        dir: 'ltr',
    },
    'ga-IE': {
        dir: 'ltr',
    },
    'gd-gb': {
        dir: 'ltr',
    },
    gl: {
        dir: 'ltr',
    },
    gu: {
        dir: 'ltr',
    },
    he: {
        dir: 'rtl',
    },
    hi: {
        dir: 'ltr',
    },
    hr: {
        dir: 'ltr',
    },
    hu: {
        dir: 'ltr',
    },
    hy: {
        dir: 'ltr',
    },
    id: {
        dir: 'ltr',
    },
    is: {
        dir: 'ltr',
    },
    it: {
        dir: 'ltr',
    },
    ja: {
        dir: 'ltr',
    },
    ka: {
        dir: 'ltr',
    },
    kk: {
        dir: 'ltr',
    },
    'km-KH': {
        dir: 'ltr',
    },
    kn: {
        dir: 'ltr',
    },
    ko: {
        dir: 'ltr',
    },
    kok: {
        dir: 'ltr',
    },
    'lb-LU': {
        dir: 'ltr',
    },
    'lo-LA': {
        dir: 'ltr',
    },
    lt: {
        dir: 'ltr',
    },
    lv: {
        dir: 'ltr',
    },
    'mi-NZ': {
        dir: 'ltr',
    },
    mk: {
        dir: 'ltr',
    },
    ml: {
        dir: 'ltr',
    },
    mr: {
        dir: 'ltr',
    },
    ms: {
        dir: 'ltr',
    },
    'mt-MT': {
        dir: 'ltr',
    },
    'ne-NP': {
        dir: 'ltr',
    },
    nl: {
        dir: 'ltr',
    },
    'nn-NO': {
        dir: 'ltr',
    },
    no: {
        dir: 'ltr',
    },
    or: {
        dir: 'ltr',
    },
    'pa-IN': {
        dir: 'ltr',
    },
    pl: {
        dir: 'ltr',
    },
    pt: {
        dir: 'ltr',
    },
    'pt-PT': {
        dir: 'ltr',
    },
    'quz-PE': {
        dir: 'ltr',
    },
    ro: {
        dir: 'ltr',
    },
    ru: {
        dir: 'ltr',
    },
    sk: {
        dir: 'ltr',
    },
    sl: {
        dir: 'ltr',
    },
    sq: {
        dir: 'ltr',
    },
    sr: {
        dir: 'ltr',
    },
    'sr-Cyrl': {
        dir: 'ltr',
    },
    'sr-Cyrl-BA': {
        dir: 'ltr',
    },
    sv: {
        dir: 'ltr',
    },
    ta: {
        dir: 'ltr',
    },
    te: {
        dir: 'ltr',
    },
    th: {
        dir: 'ltr',
    },
    tr: {
        dir: 'ltr',
    },
    tt: {
        dir: 'ltr',
    },
    'ug-CN': {
        dir: 'rtl',
    },
    uk: {
        dir: 'ltr',
    },
    ur: {
        dir: 'rtl',
    },
    uz: {
        dir: 'ltr',
    },
    vi: {
        dir: 'ltr',
    },
    'zh-Hans': {
        dir: 'ltr',
    },
    'zh-Hant': {
        dir: 'ltr',
    },
};

const localesMap = new Map(
    Object.keys(localeManifest).map(locale => [locale.toLowerCase(), locale])
);

// The locale key should be lowercase to match the string generated by getSupportedFallbackForLocale,
// but the locale value is case-sensitive and should match the name of a locale directory
const FALLBACK_OVERRIDE: {
    [locale: string]: string;
} = {
    'en-us': 'en',
    'nb-no': 'no',
    'ne-ne': 'ne-NP',
    'quz-bo': 'quz-PE',
    'quz-ec': 'quz-PE',
    'zh-cn': 'zh-Hans',
    'zh-hk': 'zh-Hant',
    'zh-mo': 'zh-Hant',
    'zh-sg': 'zh-Hans',
    'zh-tw': 'zh-Hant',
};

export function getSupportedFallbackForLocale(localeCode: string): SupportedLocale {
    const localeParts = localeCode.toLowerCase().split('-');

    while (localeParts.length > 0) {
        const fallbackLocale = localeParts.join('-');

        const fallbackOverrideLocale = FALLBACK_OVERRIDE[fallbackLocale];
        if (fallbackOverrideLocale) {
            return getLocaleObjectForSupportedLocale(fallbackOverrideLocale);
        }

        const foundLocale = localesMap.get(fallbackLocale.toLowerCase());

        if (foundLocale) {
            return getLocaleObjectForSupportedLocale(foundLocale);
        }

        localeParts.pop();
    }

    return { ...getLocaleObjectForSupportedLocale(DEFAULT_LOCALE), localeNotFound: true };
}

function getLocaleObjectForSupportedLocale(localeCode: string): SupportedLocale {
    return {
        locale: localeCode,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS7053 (323,12): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ en: { dir: string; }; af: { dir: string; }; am: { dir: string; }; ar: { dir: string; }; 'as-IN': { dir: string; }; 'az-Latn-AZ': { dir: string; }; bg: { dir: string; }; 'bn-IN': { dir: string; }; 'bs-Latn-BA': { dir: string; }; ca: { dir: string; }; ... 75 more ...; 'zh-Hant': { ...; }; }'.
        // @ts-expect-error
        ...localeManifest[localeCode],
    };
}
