import { getStore } from '../store/store';
import type BrowserWidthBucket from '../store/schema/BrowserWidthBucket';
import BrowserHeightBucket from '../store/schema/BrowserHeightBucket';
import LayoutChangeSource from '../store/schema/LayoutChangeSource';
import calculateAvailableWidthBucket from './calculateAvailableWidthBucket';
import calculateAvailableHeightBucket from './calculateAvailableHeightBucket';
import { onAvailableWidthBucketChanged } from '../actions/onAvailableWidthBucketChanged';
import setAreDisplayAdsEnabled from '../mutators/setAreDisplayAdsEnabled';
import { getAreDisplayAdsEnabled } from '../selectors/getAreDisplayAdsEnabled';
import { isFlexPaneShown } from 'owa-suite-header-store';
import {
    setAvailableHeightAndWidthBuckets,
    setBrowserWidthBucket,
    setBrowserHeightBucket,
} from '../actions/setAvailableHeightAndWidthBuckets';
import { updateFlexPaneInternalWidth } from './updateFlexPaneInternalWidth';
import { addEventTimeToSourceMapping } from 'owa-performance';

// This is needed to prevent the event listener function to change on each module switch
// which prevents it from being removed/updated successfully
let OVERFLOW_WIDTH_BUCKET = 418;
let dynamicLayoutInitialized = false;
const OVERFLOW_HEIGHT_BUCKET = BrowserHeightBucket.From580_To600;
const MAIN_APP_MIN_HEIGHT = '600px';

export function initializeDynamicLayout(
    areDisplayAdsEnabled: boolean,
    customOverflowWidthBucket?: BrowserWidthBucket
) {
    // Update OVERFLOW_WIDTH_BUCKET to save the preferred overflowWidthBucket so the event listener
    // is not affected by the variable value of the overflowWidthBucket
    OVERFLOW_WIDTH_BUCKET = customOverflowWidthBucket ?? 418;

    // Set dynamicLayoutInitialized as true so we only show the vertical scrollbars when the flow has initialized dynamicLayout
    dynamicLayoutInitialized = true;

    // Initialize custom property for flexpane width, starts off at 320px
    updateFlexPaneInternalWidth();

    // Add event listener with new overflow width bucket
    // No need to call removeEventListener while the name of the event remains the same
    window.addEventListener('resize', onWindowResize);

    setAreDisplayAdsEnabled(areDisplayAdsEnabled);

    // Calculate once on boot.
    setAvailableHeightAndWidthBuckets(LayoutChangeSource.Init);
}

/**
 * On Window resize handler.
 */
function onWindowResize(event: UIEvent) {
    addEventTimeToSourceMapping(event, 'initializeDynamicLayout');
    setAvailableHeightAndWidthBuckets(LayoutChangeSource.WindowResize);
}

/**
 * Update the layout on the available app width change
 * @param source source of the width change
 */
export function setAvailableWidthBucket(source: LayoutChangeSource) {
    // Update available app width bucket
    const availableWidthBucket = calculateAvailableWidthBucket(
        source != LayoutChangeSource.Init,
        getAreDisplayAdsEnabled(),
        isFlexPaneShown()
    );

    // No-op if the width bucket did not change
    if (getStore().browserWidthBucket == availableWidthBucket) {
        return;
    }

    setBrowserWidthBucket(availableWidthBucket);

    if (availableWidthBucket <= OVERFLOW_WIDTH_BUCKET) {
        addOverflowXOnBody();
    } else {
        hideOverflowXOnBody();
    }

    /* action to listen to for consumers to know that the availableWidthBucket had updated,
     * passes new availableWidthBucket
     */
    onAvailableWidthBucketChanged(availableWidthBucket, source);
}

/**
 * Update the layout on the available app height change
 * @param source source of the height change
 */
export function setAvailableHeightBucket(source: LayoutChangeSource, forceUpdate?: boolean) {
    // Update available app height bucket
    const availableHeightBucket = calculateAvailableHeightBucket(source != LayoutChangeSource.Init);

    // No-op if the height bucket did not change
    if (!forceUpdate && getStore().browserHeightBucket == availableHeightBucket) {
        return;
    }

    setBrowserHeightBucket(availableHeightBucket);

    if (availableHeightBucket <= OVERFLOW_HEIGHT_BUCKET) {
        addOverflowYOnBody();
    } else {
        hideOverflowYOnBody();
    }
}

export function addOverflowXOnBody() {
    if (window.document?.body) {
        document.body.style.overflowX = 'auto';
    }
}

export function hideOverflowXOnBody() {
    if (window.document?.body) {
        document.body.style.overflowX = 'hidden';
    }
}

export function addOverflowYOnBody() {
    if (dynamicLayoutInitialized) {
        const mainAppElement = document.getElementById('mainApp');
        if (mainAppElement) {
            mainAppElement.style.minHeight = MAIN_APP_MIN_HEIGHT;
            if (mainAppElement.parentElement) {
                mainAppElement.parentElement.style.overflowY = 'auto';
            }
        }
    }
}

export function hideOverflowYOnBody() {
    if (dynamicLayoutInitialized) {
        const mainAppElement = document.getElementById('mainApp');
        if (mainAppElement?.parentElement) {
            mainAppElement.parentElement.style.overflowY = 'hidden';
        }
    }
}
