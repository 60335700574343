import { setItem, removeItem } from 'owa-local-storage';
import { PWA_THEME_LOCAL_STORAGE_KEY } from '../const';

export function addToStorage(colorcode: string) {
    setItem(self, PWA_THEME_LOCAL_STORAGE_KEY, colorcode);
}

export function removeFromStorage() {
    removeItem(self, PWA_THEME_LOCAL_STORAGE_KEY);
}
