import type { NovaEvent } from '@nova/types';
import type { AppHostServiceEventOriginator } from './originator';

export const StartChatViaImAddressUrlEventType = 'startChatViaImAddressUrl' as const;

export interface StartChatViaImAddressUrlEvent
    extends NovaEvent<StartChatViaImAddressUrlEventData> {
    originator: typeof AppHostServiceEventOriginator;
    type: typeof StartChatViaImAddressUrlEventType;
    data: () => StartChatViaImAddressUrlEventData;
}

export interface StartChatViaImAddressUrlEventData {
    imAddressUrl: string;
}
