import type { Layout } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR';
import {
    sections,
    singleRow,
    stack,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR/layoutTypings';
import { owaComputedFn } from 'owa-computed-fn';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { RibbonGroupId } from 'owa-ribbon-ids';
import { getStringRibbonId } from 'owa-ribbon-ids';
import { type MailRibbonGroupId, type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';

export const getMLRNotesTabGroupLayouts = owaComputedFn(function getMLRNotesTabGroupLayouts(
    group: RibbonGroupId,
    _props: ReadOnlyRibbonControlDefProps
): Layout[] | undefined {
    switch (group) {
        case 145:
            return [
                sections(singleRow(getStringRibbonId(640)), singleRow(getStringRibbonId(641))),
                sections(singleRow(stack(getStringRibbonId(640), getStringRibbonId(641), null))),
            ];

        default:
            return undefined;
    }
});
