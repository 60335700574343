import { getQueryStringParameters } from 'owa-querystring';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Deprecating getPhysicalRing.ts
 *	> 'isDogfoodEnv' import from 'owa-metatags' is restricted. This value is resolved in ECS as a filter. Please create a feature flight if possible. */
import { isDogfoodEnv } from 'owa-metatags';
import getReportSubmissionPolicy from './getReportSubmissionPolicy';
import type { MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings, isConsumer } from 'owa-session-store';

/**
 * Checks if pre-report dialog is enabled for the given scenario
 *
 * @param isJunk true if checking for junk scenarios, false if checking for phishing scenarios
 * @param mailboxInfo mailboxInfo of the mailbox that the user is reporting from
 * @returns boolean representing whether the pre-report dialog should be shown
 */
export default function isPreReportDialogEnabled(
    isJunk: boolean,
    mailboxInfo: MailboxInfo,
    hasPostReportAction = false
): boolean {
    // Allow "prereport" query string parameter to override value in SDF
    if (isDogfoodEnv() && getQueryStringParameters().prereport) {
        return getQueryStringParameters().prereport === 'true';
    }

    /**
     * Pre-report dialog is always enabled when consumer users mark mail as
     * phishing and always disabled when consumer users mark mail as junk unless
     * there is a post action (unsubscribe/block).
     */
    if (isConsumer(undefined /* smtpAddress */, mailboxInfo)) {
        return isJunk ? hasPostReportAction : true;
    }

    /**
     * If pre-report dialogs are disabled by the admin, then we'll return false
     * here as there's no need to check the end user's setting.
     */
    if (!getReportSubmissionPolicy(mailboxInfo).PreSubmitMessageEnabled) {
        return false;
    }

    /**
     * If execution reaches this point, then we know the end user is a commercial user
     * in a tenant that has enabled pre-report dialogs. We'll check the end user's
     * setting whether or not they want to see the pre-report dialog.
     *
     * Note that this setting was traditionally only used for the Junk pre-report
     * dialog, but now it's also used for the Phishing pre-report dialog.
     */
    const skipCheckForReportJunkDialog =
        getAccountScopeUserSettings(mailboxInfo)?.UserOptions?.CheckForReportJunkDialog;
    return !skipCheckForReportJunkDialog;
}
