/**
 * This function returns the UI depth of a tree node in pixels. Although a node might be at depth 1,
 * it should be aligned to the root node at depth 0 per redlines. Thus, all other levels should
 * also be decreased by 1 level for the UI only.
 * @param hasIconsForAllFolders A boolean passed in if all folders have icons.
 */
export function getUITreeNodeDepth(
    depth: number,
    densityModeString?: string,
    adjustForTopIcon?: boolean
): number {
    return (
        Math.max(0, depth - 1) * getDepthFactor(densityModeString, adjustForTopIcon) +
        (adjustForTopIcon ? getIconAdjustment(densityModeString) : 0)
    );
}

function getDepthFactor(densityModeString?: string, adjustForTopIcon?: boolean): number {
    switch (densityModeString) {
        case 'full':
            return Math.round((adjustForTopIcon ? 28 : 26) / 3);
        case 'medium':
            return Math.round((adjustForTopIcon ? 24 : 26) / 3);
        case 'compact':
        default:
            return Math.round((adjustForTopIcon ? 24 : 20) / 3);
    }
}

/**
 * Returns the right side padding for a tree node (in LTR mode) and left side padding (in RTL mode)
 */
export function getTreeNodesFarPadding() {
    return '8px';
}

function getIconAdjustment(densityModeString?: string): number {
    switch (densityModeString) {
        case 'full':
            return 20;
        case 'medium':
        case 'compact':
        default:
            return 16;
    }
}
