import type { MailboxInfo } from 'owa-client-types';
import getCoprincipalAccount from './utils/getCoprincipalAccount';

/**
 * Gets the coprincipal MailboxInfo for the mailbox info coprincipal account
 * @param mailboxInfoInAccount Used to determine the coprincipal account, see getIndexerForMailboxInfo for more details
 * @returns Coprincipal MailboxInfo
 */
export function getMailboxInfoForCoprincipalAccount(
    mailboxInfoInAccount: MailboxInfo
): MailboxInfo {
    return getCoprincipalAccount(mailboxInfoInAccount).mailboxInfo;
}
