export enum DiagnosticDataLevel {
    None,
    RequiredOnly,
    RequiredAndOptional,
    Unset,
}

export interface PrivacySettings {
    diagnosticDataLevel: DiagnosticDataLevel;
    downloadContentEnabled: boolean;
    analyzeContentEnabled: boolean;
    optionalExperiencesEnabled: boolean;
    optionalConnectedExperiencesNoticeVersion: number;
    requiredDiagnosticDataNoticeVersion: number;
    optionalDiagnosticDataConsentVersion: number;
    connectedExperiencesNoticeVersion: number;
}

export interface PrivacySettingsWithMetaData extends PrivacySettings {
    rawDiagnosticDataLevel: any;
    rawDownloadContentEnabled: any;
    rawAnalyzeContentEnabled: any;
    rawOptionalExperiencesEnabled: any;
    rawOptionalConnectedExperiencesNoticeVersion: any;
    rawRequiredDiagnosticDataNoticeVersion: any;
    rawOptionalDiagnosticDataConsentVersion: any;
    rawConnectedExperiencesNoticeVersion: any;
}

export interface PrivacySettingsStore extends PrivacySettingsWithMetaData {
    isInitialized: boolean;
}
