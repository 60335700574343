// extracted by mini-css-extract-plugin
export var backButton = "sp3fM";
export var clearBoxButton = "VUNsc";
export var darkMode = "HzveM";
export var draggableRegion = "bILyE";
export var isDisabled = "aeIQx";
export var isInCompactMode = "FJmRM";
export var lightMode = "pzndE";
export var nonDraggableRegion = "b_3A1";
export var searchBox = "WPy_o";
export var searchBoxColumnContainer = "UXl34";
export var searchBoxCommonContainer = "x8k1J";
export var searchBoxContainer = "Q_UnC";
export var searchBoxContainerInSearch = "kDnI3";
export var searchBoxContainerNoBorderRadius = "sML3F";
export var searchButton = "Qfw_q";
export var searchIcon = "_7hUM";
export var searchQueryContainer = "gEOUV";