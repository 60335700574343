import type { Operation } from '@apollo/client';
import { getOperationAST } from 'graphql';

export function getOperationType(operation: Operation) {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    const { operation: operationType } = getOperationAST(operation.query, null)!;
    return operationType;
}
