import doesActiveExperiencesInclude from './doesActiveExperiencesInclude';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Checks to see if the user is in a small business tenant
 * @returns true if the user is in a small business tenant
 */
export default function isVerySmallBusiness(mailboxInfo?: MailboxInfo) {
    return doesActiveExperiencesInclude('Vsb', mailboxInfo);
}
