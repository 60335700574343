/************************************************************************************
 * Note to future editors: static exports have been removed from this file and turned
 * into deep imports. This is a requirement for ongoing perf work.
 *
 * Please do not add a non-lazy export without contacting the Calendar Forms team:
 * zabryant, jareill, yingchon, or manager krsheth
 *
 * Adding an export may require updating both package.json and fence.json before use.
 * See the wiki for more info:
 * https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/12542/Bundle-Perf-Efforts
 ************************************************************************************/
import { LazyAction, LazyModule, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "Categories" */ './lazyIndex'));

export const lazyAddMasterCategories = new LazyAction(lazyModule, m => m.addMasterCategories);
export const lazyRemoveMasterCategories = new LazyAction(lazyModule, m => m.removeMasterCategories);
export const lazyRenameMasterCategories = new LazyAction(lazyModule, m => m.renameMasterCategories);
export const lazyChangeMasterCategories = new LazyAction(lazyModule, m => m.changeMasterCategories);
export const lazyUpdateLastTimeUsedMasterCategories = new LazyAction(
    lazyModule,
    m => m.updateLastTimeUsedMasterCategories
);
export const lazySetInitialCategoryDialogViewState = new LazyAction(
    lazyModule,
    m => m.setInitialCategoryDialogViewState
);
export const lazyFetchCategoryDetails = new LazyAction(lazyModule, m => m.fetchCategoryDetails);
export const lazySubscribeToCategoryNotifications = new LazyAction(
    lazyModule,
    m => m.subscribeToCategoryNotifications
);
export const lazyApplyCategoryOperationHelper = new LazyAction(
    lazyModule,
    m => m.applyCategoryOperationHelper
);
export const lazyIsCategoryNameValid = new LazyAction(lazyModule, m => m.isCategoryNameValid);
export const lazySetBlockedCategoryNames = new LazyAction(
    lazyModule,
    m => m.setBlockedCategoryNames
);

export const lazyOnMenuDismissed = new LazyAction(lazyModule, m => m.onMenuDismissed);

export const CategoryMenu = createLazyComponent(lazyModule, m => m.CategoryMenu);
export const CategoryDialog = createLazyComponent(lazyModule, m => m.CategoryDialog);
export const CategoryColorPickerHost = createLazyComponent(
    lazyModule,
    m => m.CategoryColorPickerHost
);

export const lazyShowCategoryDialog = new LazyAction(lazyModule, m => m.showCategoryDialog);
export const lazyGetColorCodeString = new LazyAction(lazyModule, m => m.getColorCodeString);
