import doesActiveExperiencesInclude from './doesActiveExperiencesInclude';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Checks to see if the user has Teams Premium Secure license
 * @returns true if the user has Teams Premium Secure license
 */
export default function isTeamsProProtectionUser(mailboxInfo?: MailboxInfo) {
    return doesActiveExperiencesInclude('TeamsProProtection', mailboxInfo);
}
