export function buildAuthorizationHeader(token: string | undefined): string {
    if (!token) {
        return '';
    }
    const scheme = token.split(' ')[0].toLowerCase();
    if (scheme == 'msauth1.0' || scheme == 'bearer') {
        return token;
    }

    return `Bearer ${token}`;
}
