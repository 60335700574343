import { isAccountSourceListStoreInitialized } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import { isMsalEnabledForConsumerDomain, isConsumerDomain } from './isMsalEnabledForConsumerDomain';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import { isMonitoringProbe } from './isMonitoringProbe';

/**
 * Checks if the request is for Consumer account and whether token fetch from MSAL is enabled.
 **/
export function isMsalEnabledForConsumer(mailboxInfo?: MailboxInfo): boolean {
    if (isMonitoringProbe() && isConsumerDomain()) {
        // MSEXCHMON monitoring probe should return true to ensure anchor mailbox is set
        return true;
    }

    if (!isMsalEnabledForConsumerDomain()) {
        return false;
    }

    if (!isAccountSourceListStoreInitialized() && !mailboxInfo) {
        // If store is not initialized and no mailboxInfo is passed, check host origin to determine if it is consumer request
        return isConsumerDomain();
    }

    mailboxInfo = mailboxInfo ?? getModuleContextMailboxInfo();
    return isExoConsumer(mailboxInfo);
}

function isExoConsumer(mailboxInfo: MailboxInfo): boolean {
    const sessionType = getAccountScopeUserSettings(mailboxInfo).SessionSettings?.WebSessionType;
    return sessionType === 1;
}
