import { type ComplianceEnvironment } from 'owa-lpc-types';
import { getApplicationSettings } from 'owa-application-settings';
import { logUsage } from 'owa-analytics';

export function getComplianceEnvironment(): ComplianceEnvironment {
    const complianceEnvironment = getApplicationSettings('Loki')
        .complianceEnvironment as ComplianceEnvironment;

    if (!complianceEnvironment) {
        // This is most likely because the Loki compliance environment is not configured for the current environment in ECS
        // see: https://ecs.skype.com/Substrate/OutlookWebClient/configurations/1243269
        logUsage('LokiComplianceEnvironmentNotConfigured');

        return 'Unknown';
    }

    return complianceEnvironment;
}
