import { HoverIcon, PropertyIcon } from './IconBar';
import type { HoverActionIconProps, PropertyIconProps } from 'owa-mail-list-item-shared';
import { pinTitle } from './MailListItemIconBar.locstring.json';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { lazyGetFlagContextMenu } from '../index';
import { DirectionalHint } from '@fluentui/react/lib/Callout';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { getDensityModeString } from 'owa-fabric-theme';
import loc from 'owa-localize';
import { archive } from 'owa-locstrings/lib/strings/archive.locstring.json';
import { deleteItem } from 'owa-locstrings/lib/strings/deleteitem.locstring.json';
import { markAsRead } from 'owa-locstrings/lib/strings/markasread.locstring.json';
import { markAsUnread } from 'owa-locstrings/lib/strings/markasunread.locstring.json';
import { moveTo } from 'owa-locstrings/lib/strings/moveto.locstring.json';
import { unpin } from 'owa-locstrings/lib/strings/unpin.locstring.json';
import { getIconBarTheme } from 'owa-mail-densities/lib/utils/getIconBarTheme';
import { resetFocus } from 'owa-mail-focus-manager';
import { hideMailItemContextMenu } from 'owa-mail-list-actions/lib/actions/itemContextMenuActions';
import getPropertyIcon from 'owa-mail-list-actions/lib/utils/conversationProperty/getPropertyIcon';
import PropertyIcons from 'owa-mail-list-actions/lib/utils/conversationProperty/PropertyIcons';
import getListViewStore from 'owa-mail-list-store/lib/store/Store';
import { lazyToggleRowReadState } from 'owa-mail-mark-read-actions';
import { getMoveToPropertiesForContextMenu } from 'owa-mail-movetofolder-view';
import { lazyToggleRowPinnedState, lazyToggleRowsFlagState } from 'owa-mail-triage-action';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { type SmimeType } from 'smime-types';
import getIconForSmimeType from 'owa-smime/lib/utils/getIconForSmimeType';
import { getBigHoverAction } from 'owa-surface-actions/lib/utils/getBigHoverAction';
import React from 'react';
import {
    archiveRow,
    deleteRow,
    useFlagCompleteHelper,
    hoverActionsOrder,
} from 'owa-mail-list-item-shared';
import type { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import {
    getMailMenuItemShouldShow,
    doesUserHaveSharedFolderPermissionFor,
} from 'owa-mail-filterable-menu-behavior';
import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import type { HoverActionKey } from 'owa-outlook-service-option-store';
import type ImportanceType from 'owa-service/lib/contract/ImportanceType';
import { startAnimationOrHandleActionAsNeeded } from 'owa-mail-list-item-animation-store';
import {
    getHoverSurfaceAction,
    getStore as getHoverSurfaceActionStore,
} from 'owa-surface-actions-option';
import getMailboxInfo from 'owa-mail-mailboxinfo/lib/getMailboxInfo';
import getHoverActionIcon from '../utils/getHoverActionIcon';

// Remark: We have to share css file with MailListItem since we need to trigger child hover selectors
// off of the MailListItem container css class
import {
    hoverActionIcon,
    icon,
    iconBarContainer,
    iconFull,
    iconDisplay,
    iconNoDisplay,
    alwaysShowPropertyIcons,
    iconHide,
    propertyIcon,
    iconContainerNew,
    iconBarLeftMargin,
} from 'owa-mail-listitem-styles/lib/scss/IconBar.scss';
import {
    atMentionIconUnreadMailListItem,
    mailListItemIsPinned,
    pinIcon,
    visibilityHidden,
} from './MailListItemIconBar.scss';
import { flagIcon } from 'owa-mail-listitem-styles/lib/scss/FlagIconStyles.scss';
import { isFeatureEnabled } from 'owa-feature-flags';
const hoverActionSource = 'Hover';

export interface MailListItemIconBarProps {
    supportsHoverIcons?: boolean;
    neverShowHoverIcons?: boolean;
    neverShowPropertyIcons?: boolean;
    tableViewId: string;
    lastDeliveryTimestamp: string;
    rowKey: string;
    isRowExpanded: boolean;
    isFlagged: boolean;
    isComplete: boolean;
    isPinned: boolean;
    isInNotesFolder: boolean;
    isInArchiveFolder: boolean;
    isInOutboxFolder: boolean;
    canDelete: boolean;
    unreadCount: number;
    canArchive: boolean;
    canPin: boolean;
    itemClassIcon: PropertyIcons;
    importance: ImportanceType;
    smimeType: SmimeType;
    hasAttachment: boolean;
    hasSharepointLink: boolean;
    effectiveMentioned: boolean;
    isSnoozed: boolean;
    isTaggedForBigScreen: boolean;
    isSingleLine: boolean;
    supportsFlagging: boolean;
    supportsMove: boolean;
    supportsPinning: boolean;
    containerClassName?: string;
}

/*
Renders the mail list icon bar for both action icons and property icons
*/
export default observer(function MailListItemIconBar(props: MailListItemIconBarProps) {
    const tableView = getListViewStore().tableViews.get(props.tableViewId);
    const mailboxInfo = getMailboxInfo(tableView);
    const isSingleLine = props.isSingleLine;
    const isRelocateHoverActionsFlightEnabled = isFeatureEnabled('tri-mlRelocateHoverActions');

    const onDismissMoveToMenu = React.useCallback(() => {
        hideMailItemContextMenu();
        resetFocus('MailListMoveMenuDismissed');
    }, []);

    const moveRow = React.useCallback((): IContextualMenuProps | undefined => {
        return getMoveToPropertiesForContextMenu(
            props.tableViewId,
            mailboxInfo,
            onDismissMoveToMenu,
            hoverActionSource,
            getMailMenuItemShouldShow,
            DirectionalHint.bottomLeftEdge,
            [props.rowKey]
        );
    }, [props.tableViewId, onDismissMoveToMenu, props.rowKey]);

    const toggleMarkAsReadRow = React.useCallback(() => {
        lazyToggleRowReadState.importAndExecute(props.rowKey, props.tableViewId, hoverActionSource);
        // Reset focus after the stitch to mark the item as read is raised
        resetFocus('MailListToggleMarkAsRead');
    }, [props.rowKey, props.tableViewId]);

    const togglePinnedStateRow = React.useCallback(() => {
        lazyToggleRowPinnedState.importAndExecute(
            props.rowKey,
            props.tableViewId,
            hoverActionSource
        );
        // Reset focus after the stitch to pin the item is raised
        resetFocus('MailListItemIconBarPinned');
    }, [props.rowKey, props.tableViewId]);

    const flaggedOnContextMenu = React.useCallback(
        (evt: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | unknown, MouseEvent>) => {
            evt.stopPropagation();
            evt.persist();
            lazyGetFlagContextMenu.importAndExecute(
                props.rowKey,
                props.tableViewId,
                '' /* nodeId */,
                hoverActionSource,
                evt as React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>
            );
            evt.preventDefault();
        },
        [props.rowKey, props.tableViewId]
    );

    const toggleFlaggedStateRow = React.useCallback(() => {
        lazyToggleRowsFlagState.importAndExecute(
            [props.rowKey],
            props.tableViewId,
            hoverActionSource
        );
        // Reset focus after the stitch to flag the item is raised
        resetFocus('MailListItemIconBarFlagged');
    }, [props.rowKey, props.tableViewId]);

    const shouldHideMCLHoverIcon = (hoverIconKey: string) => {
        switch (hoverIconKey) {
            case 'pinIcon':
                const { isFlagged, isComplete, isPinned } = props;
                return (isFlagged || isComplete) && !isPinned;
            default:
                return false;
        }
    };

    const hoverActionKeys = useComputedValue((): HoverActionKey[] => {
        let hoverSurfaceActionKeys = getHoverSurfaceActionStore().hoverSurfaceActions;

        if (props.isInNotesFolder || props.isInOutboxFolder) {
            hoverSurfaceActionKeys = ['Delete'];
        } else if (hoverSurfaceActionKeys === null) {
            hoverSurfaceActionKeys = getHoverSurfaceAction();
        }

        return hoverSurfaceActionKeys;
    }, [props.isInNotesFolder, props.isInOutboxFolder]);

    const flagEntry = useFlagCompleteHelper(
        {
            title: null,
            key: null,
            onClickCommand: toggleFlaggedStateRow,
            onContextMenu: flaggedOnContextMenu,
        },
        props.isFlagged,
        props.isComplete
    );

    const hoverActionIcons = useComputedValue((): HoverActionIconProps[] => {
        const hoverActions: HoverActionIconProps[] = [];
        const bigHoverAction = getBigHoverAction(hoverActionKeys);

        if (!isRelocateHoverActionsFlightEnabled) {
            hoverActionKeys.forEach(action => {
                switch (action) {
                    case 'Archive':
                        if (bigHoverAction !== action && props.canArchive) {
                            /*We do not want the icon to be shown if already shown in the big hover action.*/
                            hoverActions[0] = {
                                title: loc(archive),
                                key: 'archiveIcon',
                                iconName: getHoverActionIcon(action).iconName,
                                onClickCommand: () => {
                                    const handleArchive = () => {
                                        archiveRow(
                                            props.rowKey,
                                            props.tableViewId,
                                            hoverActionSource
                                        );
                                    };
                                    startAnimationOrHandleActionAsNeeded(
                                        'Archive',
                                        [props.rowKey],
                                        hoverActionSource,
                                        handleArchive
                                    );
                                },
                            };
                        }
                        break;
                    case 'Move':
                        const shouldShowMove =
                            props.supportsMove && doesUserHaveSharedFolderPermissionFor(29);

                        if (shouldShowMove) {
                            hoverActions[1] = {
                                title: loc(moveTo),
                                key: 'moveIcon',
                                iconName: getHoverActionIcon(action).iconName,
                                menuProps: moveRow(),
                            };
                        }
                        break;
                    case 'Delete':
                        if (bigHoverAction !== action && props.canDelete) {
                            hoverActions[2] = {
                                title: loc(deleteItem),
                                key: 'deleteIcon',
                                iconName: getHoverActionIcon(action).iconName,
                                onClickCommand: () =>
                                    deleteRow(props.rowKey, props.tableViewId, hoverActionSource),
                            };
                        }
                        break;
                    case 'ReadUnread':
                        const hasUnread = props.unreadCount > 0;
                        hoverActions[3] = {
                            title: hasUnread ? loc(markAsRead) : loc(markAsUnread),
                            key: 'markReadIcon',
                            iconName: getHoverActionIcon(action, props.unreadCount).iconName,
                            onClickCommand: toggleMarkAsReadRow,
                        };
                        break;
                    case 'FlagUnflag':
                        if (
                            props.supportsFlagging &&
                            doesUserHaveSharedFolderPermissionFor(
                                18
                            ) /* Shared folder permission check */
                        ) {
                            flagEntry.iconClassName = classnames(flagEntry.iconClassName);
                            hoverActions[4] = flagEntry;
                        }
                        break;
                    case 'PinUnpin':
                        const isPinned = props.isPinned;
                        if (
                            props.supportsPinning &&
                            props.canPin &&
                            doesUserHaveSharedFolderPermissionFor(
                                39
                            ) /* Shared folder permission check */
                        ) {
                            const pinClasses = classnames(isPinned && pinIcon);
                            hoverActions[5] = {
                                title: isPinned ? loc(unpin) : loc(pinTitle),
                                key: 'pinIcon',
                                iconClassName: classnames(pinClasses),
                                iconName: getHoverActionIcon(
                                    action,
                                    undefined /* unreadCount */,
                                    isPinned
                                ).iconName,
                                onClickCommand: togglePinnedStateRow,
                            };
                        }
                        break;
                    case 'None':
                        break;
                }
            });
        } else {
            hoverActionKeys.forEach(action => {
                const order = hoverActionsOrder[action];
                const hoverActionIconProps = getHoverActionIcon(
                    action,
                    props.unreadCount,
                    props.isPinned,
                    props.isFlagged,
                    props.isComplete
                );

                if (
                    action == 'Delete' &&
                    (isSingleLine || bigHoverAction !== action) &&
                    props.canDelete
                ) {
                    hoverActions[order] = {
                        ...hoverActionIconProps,
                        onClickCommand: () =>
                            deleteRow(props.rowKey, props.tableViewId, hoverActionSource),
                    };
                } else if (
                    action == 'Archive' &&
                    (isSingleLine || bigHoverAction !== action) &&
                    props.canArchive
                ) {
                    hoverActions[order] = {
                        ...hoverActionIconProps,
                        onClickCommand: () => {
                            const handleArchive = () => {
                                archiveRow(props.rowKey, props.tableViewId, hoverActionSource);
                            };
                            startAnimationOrHandleActionAsNeeded(
                                'Archive',
                                [props.rowKey],
                                hoverActionSource,
                                handleArchive
                            );
                        },
                    };
                } else if (
                    action == 'Move' &&
                    props.supportsMove &&
                    doesUserHaveSharedFolderPermissionFor(29)
                ) {
                    hoverActions[order] = { ...hoverActionIconProps, menuProps: moveRow() };
                } else if (action == 'ReadUnread') {
                    hoverActions[order] = {
                        ...hoverActionIconProps,
                        onClickCommand: toggleMarkAsReadRow,
                    };
                } else if (
                    action == 'FlagUnflag' &&
                    props.supportsFlagging &&
                    doesUserHaveSharedFolderPermissionFor(18)
                ) {
                    hoverActions[order] = {
                        ...hoverActionIconProps,
                        onClickCommand: toggleFlaggedStateRow,
                        onContextMenu: flaggedOnContextMenu,
                    };
                } else if (
                    action == 'PinUnpin' &&
                    props.supportsPinning &&
                    props.canPin &&
                    doesUserHaveSharedFolderPermissionFor(39)
                ) {
                    hoverActions[order] = {
                        ...hoverActionIconProps,
                        onClickCommand: togglePinnedStateRow,
                    };
                }
            });
        }

        return hoverActions;
    }, [
        props.isInNotesFolder,
        props.isInArchiveFolder,
        props.isInOutboxFolder,
        props.rowKey,
        props.canArchive,
        props.tableViewId,
        props.canDelete,
        props.unreadCount,
        props.isPinned,
        props.canPin,
        props.supportsMove,
        props.supportsFlagging,
        props.supportsPinning,
        props.isFlagged,
        props.isComplete,
        flagEntry,
        isSingleLine,
        hoverActionKeys,
    ]);

    const getPropertyIconProps = useComputedValue((): PropertyIconProps[] => {
        const propertyIconProps: PropertyIconProps[] = [];
        const supportsFlagging = props.supportsFlagging;
        const supportsPinning = props.supportsPinning;

        // Build the property icon list from left to right
        // Item class icon first (meeting, note, etc.)
        if (props.itemClassIcon && props.itemClassIcon !== PropertyIcons.None) {
            propertyIconProps.push({
                key: props.itemClassIcon.toString(),
                ...getPropertyIcon(props.itemClassIcon),
            });
        }
        // Importance icon
        if (props.importance == 'Low') {
            propertyIconProps.push({
                key: PropertyIcons.ImportanceLow.toString(),
                ...getPropertyIcon(PropertyIcons.ImportanceLow),
            });
        } else if (props.importance == 'High') {
            propertyIconProps.push({
                key: PropertyIcons.ImportanceHigh.toString(),
                ...getPropertyIcon(PropertyIcons.ImportanceHigh),
            });
        }
        // S/MIME icons
        if (props.smimeType !== 0) {
            const smimeIcon: 'LockClosedRegular' | 'RibbonRegular' = getIconForSmimeType(
                props.smimeType
            );
            smimeIcon &&
                propertyIconProps.push({
                    key: smimeIcon,
                    iconName: smimeIcon,
                });
        }
        // Attachment icon
        if (props.hasAttachment) {
            propertyIconProps.push({
                key: PropertyIcons.Attachment.toString(),
                ...getPropertyIcon(PropertyIcons.Attachment),
            });
        } else if (props.hasSharepointLink) {
            propertyIconProps.push({
                key: PropertyIcons.Link.toString(),
                ...getPropertyIcon(PropertyIcons.Link),
            });
        }
        // At mention icon
        if (props.effectiveMentioned) {
            propertyIconProps.push({
                key: PropertyIcons.GlobalMentionedMe.toString(),
                ...getPropertyIcon(PropertyIcons.GlobalMentionedMe),
                iconClasses: classnames(
                    props.supportsPinning && props.isPinned && mailListItemIsPinned,
                    props.unreadCount > 0 && atMentionIconUnreadMailListItem
                ),
            });
        }
        // If the conversation/item has been snoozed or tagged for big screen, then show the clock icon
        if (props.isSnoozed || props.isTaggedForBigScreen) {
            propertyIconProps.push({
                key: PropertyIcons.Snooze.toString(),
                ...getPropertyIcon(PropertyIcons.Snooze),
            });
        }

        if (
            isRelocateHoverActionsFlightEnabled &&
            hoverActionKeys.includes('FlagUnflag') &&
            !hoverActionKeys.includes('PinUnpin')
        ) {
            // Pin icon if pinned
            // Add flag/complete icon as the right most icon if pinning is not supported
            // If pinning is supported we leave a place for pin hover action/property icon
            if (supportsPinning && (props.isPinned || props.isFlagged || props.isComplete)) {
                const pinIconClasses = classnames(
                    pinIcon,
                    (props.isFlagged || props.isComplete) && !props.isPinned && visibilityHidden
                );

                propertyIconProps.push({
                    key: 'pinIcon',
                    iconClasses: pinIconClasses,
                    iconName: getPropertyIcon(PropertyIcons.Pinned).iconName,
                });

                // Complete icon
                if (supportsFlagging && props.isComplete) {
                    propertyIconProps.push({
                        key: 'completeIcon',
                        iconName: getPropertyIcon(PropertyIcons.Completed).iconName,
                    });
                }

                // Flag icon
                if (supportsFlagging && props.isFlagged && !props.isComplete) {
                    propertyIconProps.push({
                        key: 'flagIcon',
                        iconClasses: flagIcon,
                        iconName: getPropertyIcon(PropertyIcons.Flagged).iconName,
                    });
                }

                // place holder for flag/complete icon so that pin is shifted left.
                if (supportsFlagging && !props.isFlagged && !props.isComplete) {
                    propertyIconProps.push({
                        key: 'flagIcon',
                        iconClasses: flagIcon && visibilityHidden,
                        iconName: getPropertyIcon(PropertyIcons.Flagged).iconName,
                    });
                }
            }
        } else {
            // Complete icon
            if (supportsFlagging && props.isComplete) {
                propertyIconProps.push({
                    key: 'completeIcon',
                    iconName: getPropertyIcon(PropertyIcons.Completed).iconName,
                });
            }

            // Flag icon
            if (supportsFlagging && props.isFlagged && !props.isComplete) {
                propertyIconProps.push({
                    key: 'flagIcon',
                    iconClasses: flagIcon,
                    iconName: getPropertyIcon(PropertyIcons.Flagged).iconName,
                });
            }
            // Pin icon if pinned
            // Add flag/complete icon as the right most icon if pinning is not supported
            // If pinning is supported we leave a place for pin hover action/property icon
            if (supportsPinning && (props.isPinned || props.isFlagged || props.isComplete)) {
                const pinIconClasses = classnames(
                    pinIcon,
                    (props.isFlagged || props.isComplete) && !props.isPinned && visibilityHidden
                );
                propertyIconProps.push({
                    key: 'pinIcon',
                    iconClasses: pinIconClasses,
                    iconName: getPropertyIcon(PropertyIcons.Pinned).iconName,
                });
            }
        }

        return propertyIconProps;
    }, [
        props.itemClassIcon,
        props.importance,
        props.smimeType,
        props.hasAttachment,
        props.hasSharepointLink,
        props.effectiveMentioned,
        props.unreadCount,
        props.isSnoozed,
        props.isTaggedForBigScreen,
        props.rowKey,
        props.isComplete,
        props.isFlagged,
        props.isPinned,
        props.canPin,
        props.supportsFlagging,
        props.supportsPinning,
        hoverActionKeys,
    ]);

    const containerClassNames = classnames(
        iconBarContainer,
        props.containerClassName,
        isRelocateHoverActionsFlightEnabled && iconBarLeftMargin
    );

    const iconKeyIntersectionSet = useComputedValue(() => {
        // Find Icons in both property and hover icons for hovering. if a property icon is only in hover actions, always show the hover action and hide the property icon
        if (!isSingleLine) {
            return null;
        }
        const hoverIconKeySet = new Set();
        const keyIntersectionSet = new Set();

        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        hoverActionIcons.forEach(action => {
            hoverIconKeySet.add(action.key);
        });

        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        getPropertyIconProps.forEach(action => {
            if (hoverIconKeySet.has(action.key)) {
                keyIntersectionSet.add(action.key);
            }
        });
        return keyIntersectionSet;
    }, [hoverActionIcons, getPropertyIconProps, isSingleLine]);

    if (!props.neverShowHoverIcons || !props.neverShowPropertyIcons) {
        return (
            <ThemeProvider
                applyTo="none"
                theme={getIconBarTheme(getDensityModeString())}
                className={containerClassNames}
            >
                {!props.neverShowHoverIcons &&
                    hoverActionIcons.map(hoverProps => {
                        return (
                            <HoverIcon
                                {...hoverProps}
                                key={hoverProps.key}
                                iconClasses={classnames(
                                    hoverProps.iconClasses,
                                    hoverActionIcon,
                                    !isRelocateHoverActionsFlightEnabled && isSingleLine
                                        ? shouldHideMCLHoverIcon(hoverProps.key)
                                            ? classnames(iconDisplay, iconHide) // Display the icon so space is allocated for it, but set its visibility to hidden,
                                            : iconKeyIntersectionSet?.has(hoverProps.key) &&
                                              iconDisplay
                                        : undefined,
                                    icon,
                                    !isRelocateHoverActionsFlightEnabled
                                        ? getDensityModeString() === 'full' && iconFull
                                        : iconContainerNew
                                )}
                            />
                        );
                    })}
                {!props.neverShowPropertyIcons &&
                    getPropertyIconProps.map(propertyIconProps => (
                        <PropertyIcon
                            {...getPropertyIconProps}
                            {...propertyIconProps}
                            key={propertyIconProps.key}
                            iconClasses={classnames(
                                propertyIconProps.iconClasses,
                                propertyIcon,
                                isRelocateHoverActionsFlightEnabled && iconContainerNew,
                                (isRelocateHoverActionsFlightEnabled || !isSingleLine) && // In SCL, if neverShowHoverIcons is true, force to always show the property icon.
                                    props.neverShowHoverIcons &&
                                    alwaysShowPropertyIcons,
                                // In MCL (only leftIconBar b/c neverShowPropertyIcons is true in rightIconBar), if the icon is in the intersection set,
                                // if hover icons are supported, don't show the property icon. Else, always show the property icon.
                                !isRelocateHoverActionsFlightEnabled && isSingleLine
                                    ? iconKeyIntersectionSet?.has(propertyIconProps.key)
                                        ? props.supportsHoverIcons
                                            ? iconNoDisplay
                                            : alwaysShowPropertyIcons
                                        : alwaysShowPropertyIcons
                                    : undefined
                            )}
                        />
                    ))}
            </ThemeProvider>
        );
    } else {
        return null;
    }
}, 'MailListItemIconBar');
