import { getSelectedTableView } from 'owa-mail-list-store';
import type { OwaDate } from 'owa-datetime';
import { lazyScheduleRows } from 'owa-mail-triage-action';
import type { ActionSource } from 'owa-analytics-types';
import { startAnimationOrHandleActionAsNeeded } from 'owa-mail-list-item-animation-store';

export default async function onSnooze(date: OwaDate | undefined, actionSource: ActionSource) {
    const tableView = getSelectedTableView();
    const rowKeys = [...tableView.selectedRowKeys.keys()];
    if (tableView && rowKeys) {
        const scheduleRows = await lazyScheduleRows.import();
        const handleScheduleRows = () => {
            scheduleRows(rowKeys, tableView.id, actionSource, date);
        };

        startAnimationOrHandleActionAsNeeded(
            'Snooze' /*actionId*/,
            rowKeys,
            actionSource,
            handleScheduleRows
        );
    }
}
