import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import type { ConsumerTokenRequestParams } from '../schema/TokenRequestParams';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import { lazyAcquireAccessTokenMsalProxy } from 'owa-msaljs/lib/lazyAppBoot';
import type { ILogger } from './ILogger';
import { getConfig } from 'owa-service/lib/config';
import type TokenResponseCode from 'owa-service/lib/contract/TokenResponseCode';

export async function getTokenFromMsalForConsumer(
    consumerParams: ConsumerTokenRequestParams,
    logger: ILogger
): Promise<TokenResponse> {
    if (!consumerParams.mailboxInfo) {
        logger.addCustomData(
            'MailboxInfo:',
            consumerParams.apiName + ' fallback to module context'
        );
    }

    const config = getConfig();
    const mailboxInfo = consumerParams.mailboxInfo ?? getModuleContextMailboxInfo();
    var tokenResponse = config?.getMsalToken
        ? await config.getMsalToken(
              true /*isConsumer*/,
              undefined /*resource*/,
              mailboxInfo,
              consumerParams.scope
          )
        : await lazyAcquireAccessTokenMsalProxy.importAndExecute(
              mailboxInfo,
              undefined /*resource*/,
              consumerParams.scope
          );
    logger.addCheckpoint('getTokenFromMSAL_FetchedFromMSAL');
    if (tokenResponse) {
        return tokenResponse;
    }
    return { SubErrorCode: undefined, TokenResultCode: 2 };
}
