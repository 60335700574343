import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import type { EnterpriseTokenRequestParams } from '../schema/TokenRequestParams';
import { getUserTokenIfAvailable } from './getUserTokenIfAvailable';
import sleep from 'owa-sleep';
import { getOrigin } from 'owa-url/lib/getOrigin';
import type { ILogger } from './ILogger';
import { fetchTokenFromServer } from './fetchTokenFromServer';
import { getResourceToken } from './getResourceToken';
import { TokenResponseType } from 'owa-tokenprovider-utils/lib/TokenResponseType';
import { isFeatureEnabled } from 'owa-feature-flags';

export async function fetchTokenFromHostAppForEnterpriseWithRetry(
    logger: ILogger,
    retryCount: number,
    retryDelay: number,
    enterpriseParams: EnterpriseTokenRequestParams
): Promise<TokenResponse> {
    if (retryCount < 1) {
        const error = new Error('Max retrying limit (1) reached');
        logger.addCustomError('FetchFromHostAppEnterprise', error);
        return Promise.reject(error);
    }

    return fetchTokenFromHostAppForEnterprise(logger, enterpriseParams).catch(() => {
        // Retry only on non-network related errors
        return sleep(retryDelay).then(() => {
            return fetchTokenFromHostAppForEnterpriseWithRetry(
                logger,
                retryCount - 1,
                retryDelay * 2,
                {
                    ...enterpriseParams,
                }
            );
        });
    });
}

export async function fetchTokenFromHostAppForEnterprise(
    logger: ILogger,
    enterpriseParams: EnterpriseTokenRequestParams
): Promise<TokenResponse> {
    const origin = getOrigin();

    if (enterpriseParams.resource == origin || enterpriseParams.resource == origin + '/') {
        const userToken = await getUserTokenIfAvailable(enterpriseParams);
        if (userToken) {
            logger.addCheckpoint('UserTokenFetched');
            return { AccessToken: userToken };
        }
    }

    logger.addCheckpoint('TokenRetrievalFromHostApp_Start');
    const token = await getResourceToken(
        enterpriseParams.resource,
        enterpriseParams.wwwAuthenticateHeader,
        enterpriseParams.mailboxInfo,
        enterpriseParams.scope
    );

    if (token) {
        logger.addCheckpoint('TokenRetrievalFromHostApp_End');

        if (token?.AccessToken) {
            return token;
        }

        // donot fallback if we got back a SubError from OneAuth as this will cause failure in GATFR call as well
        if (
            isFeatureEnabled('auth-tokenprovider-skipGATFRFallback') &&
            token?.SubErrorCode == TokenResponseType.InteractionRequired
        ) {
            return {};
        }
    }

    logger.addCheckpoint('FetchingSTSToken');
    return fetchTokenFromServer(logger, enterpriseParams);
}
