import { ApolloLink, Observable } from '@apollo/client';
import { lazyGatewayLink } from 'owa-apollo-gateway-link';
import type { Subscription } from 'zen-observable-ts';

// stub to delay load the bulk of the gateway link
export function remoteGraphLink() {
    const gatewayLink: Promise<ApolloLink> = lazyGatewayLink.importAndExecute();

    return new ApolloLink(operation => {
        return new Observable(observer => {
            const subscription: Promise<Subscription | undefined> = gatewayLink.then(gw =>
                gw.request(operation)?.subscribe(observer)
            );

            return () => {
                subscription.then(s => s?.unsubscribe());
            };
        });
    });
}
