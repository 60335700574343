import { mutatorAction } from 'satcheljs';
import getStore from '../store/store';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

export default mutatorAction(
    'onCopilotSettingsSaved',
    (mailboxInfo: MailboxInfo, response: any, isCustomStyleEnabled: boolean) => {
        const key = getIndexerValueForMailboxInfo(mailboxInfo);
        const inboxResponse = response.inboxPrioritizationPreferences;
        if (inboxResponse.version === 1) {
            // get the prioritize store map
            const prioritizeStoreMap = getStore().copilotPrioritizeSettingsStoreMap;
            const prioritizeStore = prioritizeStoreMap.get(key);

            if (prioritizeStore) {
                prioritizeStore.settings = inboxResponse;
                prioritizeStore.isInitialized = true;
            } else {
                prioritizeStoreMap.set(getIndexerValueForMailboxInfo(mailboxInfo), {
                    settings: inboxResponse,
                    isInitialized: true,
                });
            }
        }

        if (isCustomStyleEnabled) {
            const styleResponse = response.userStylePreferences;
            // get the style store map
            const styleSettingStoreMap = getStore().copilotStyleSettingsStoreMap;
            const styleSettingStore = styleSettingStoreMap.get(key);

            if (styleSettingStore) {
                styleSettingStore.settings = styleResponse;
                styleSettingStore.isInitialized = true;
            } else {
                styleSettingStoreMap.set(getIndexerValueForMailboxInfo(mailboxInfo), {
                    settings: styleResponse,
                    isInitialized: true,
                });
            }
        }
    }
);
