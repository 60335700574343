import { isPstFileAccountType } from 'owa-account-source-list-internal';
import type { AccountSource } from 'owa-account-source-list-store';
import type { AccountSourceType } from 'owa-account-source-list-types';

export function isServiceBackedAccount(account: AccountSource): boolean {
    return isServiceBackedAccountType(account.sourceType);
}

export function isServiceBackedAccountType(accountType: AccountSourceType): boolean {
    return !isPstFileAccountType(accountType);
}
