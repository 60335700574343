import getExtensionFromFileName from './getExtensionFromFileName';
import { isFeatureEnabled } from 'owa-feature-flags';

// If this is changed, also update the list of extensions in fileProviderItemsSupportedActions.ts
export function isFluidFile(fileName: string | undefined): boolean {
    const extension = (getExtensionFromFileName(fileName) || fileName || '').toLowerCase();
    return !fileName
        ? false
        : isFeatureEnabled('cmp-prague') &&
              (extension === '.fluid' ||
                  extension === '.note' ||
                  extension === '.loop' ||
                  extension === '.whiteboard');
}

export function isFluidExtension(extension: string): boolean {
    return (
        extension === 'fluid' ||
        extension === 'note' ||
        extension === 'loop' ||
        extension === 'whiteboard'
    );
}
