import type { MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { saveUrlData } from '../utils/filesUrlDataUtils';
import { startIntervalForRefreshDownloadToken } from '../utils/startIntervalForRefreshDownloadToken';
import { logAttachmentDownloadToken } from './logAttachmentDownloadToken';
import { isFeatureEnabled } from 'owa-feature-flags';

const CLIENT_TOKEN_REFRESH_IN_MINUTES = 5;

export function updateUrlData(mailboxInfo: MailboxInfo): void {
    const userConfiguration = getAccountScopeUserSettings(mailboxInfo);
    const applicationSettings = userConfiguration?.ApplicationSettings;
    if (applicationSettings) {
        const downloadUrlBase = applicationSettings.DownloadUrlBase;
        saveUrlData(mailboxInfo.userIdentity, {
            downloadUrlBase,
            downloadToken: applicationSettings.FirstDownloadToken,
        });
        logAttachmentDownloadToken(applicationSettings.FirstDownloadToken, true /* isFirst */);

        if (!!downloadUrlBase) {
            let downloadTokenRefreshMinutes =
                applicationSettings.DownloadTokenRefreshMinutes ?? CLIENT_TOKEN_REFRESH_IN_MINUTES;
            if (isFeatureEnabled('doc-use-new-download-token-lifetime')) {
                // If we use new token lifetime, we have a new strategy for when to start token refresh
                // to avoid sending too many requests to server. Thus we will not the server setting.
                // The server setting will be retired after all client and server changes are rolled out WW.
                downloadTokenRefreshMinutes = CLIENT_TOKEN_REFRESH_IN_MINUTES;
            }

            startIntervalForRefreshDownloadToken(mailboxInfo, downloadTokenRefreshMinutes);
        }
    }
}
