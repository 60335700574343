import { LazyModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "NativePostBoot" */ './lazyIndex')
);

export const lazySetupNativeHostPostBoot = new LazyAction(
    lazyModule,
    m => m.setupNativeHostPostBoot
);
