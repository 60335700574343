import type { TabType } from './store/schema/TabViewState';
import type TabViewState from './store/schema/TabViewState';
import { type TabState } from './store/schema/TabViewState';

export default {
    TabViewAddTab: {
        name: 'TabViewAddTab',
        customData: (type: TabType, isShown: boolean, makeActive: boolean, _data: any) => [
            type,
            isShown,
            makeActive,
        ],
    },
    TabViewCloseTab: {
        name: 'TabViewCloseTab',
        customData: (viewState: TabViewState, forceClose?: boolean) => [
            viewState.type,
            viewState.state == 1 || viewState.state == 2, // isShown
            viewState.state == 2, // isActive
            forceClose,
            viewState.startTime ? new Date().getTime() - viewState.startTime : 0,
        ],
    },
};
