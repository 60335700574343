import { isPrivacyRoamingEnabled } from './isPrivacyRoamingEnabled';

export const PRIVACY_SETTINGS_LOCAL_STORAGE_KEY = 'PRIVACY_SETTINGS_LOCAL_STORAGE_KEY';
const PRIVACY_UTILS_URL = 'ows/beta/PrivacyConfiguration/configuration';

export const getPrivacyUtilsUrl = (): string =>
    PRIVACY_UTILS_URL + (isPrivacyRoamingEnabled() ? '?useRoamingService=true' : '');

// The latest notice version should be in sync with win32 FirstRunDialogVersion.cpp
// https://dev.azure.com/office/Office/_git/Office?path=%2Fprivacy%2Foptions%2FFirstRunDialogVersion.cpp&_a=contents&version=GBmain
// That is, when a new privacy notice version is released, we should update the version number here
// and do the necessary UI adjustments for displaying the updated notice
export const OPTIONAL_CONNECTED_EXPERIENCES_NOTICE_LATEST_VERSION = 2;
export const REQUIRED_DIAGNOSTICS_DATA_NOTICE_LATEST_VERSION = 1;
export const DIAGNOSTIC_DATA_CONSENT_LATEST_VERSION = 1;
export const CONNECTED_EXPERIENCES_NOTICE_LATEST_VERSION = 1;
