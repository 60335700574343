import type { OwaPalette } from 'owa-theme-shared';

export function getCopilotPaletteOverrides(isDarkMode: boolean = false): OwaPalette {
    if (isDarkMode) {
        return {};
    } else {
        return {
            headerTextIcons: '#FFFFFF',
        };
    }
}
