import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import React from 'react';
import { MailListItemInfo } from 'owa-mail-list-item-components';
import { PropertyIcons } from 'owa-mail-list-actions';
import SuggestedUnsubscribePeek from './SuggestedUnsubscribePeek';
import { unsubscribe } from 'owa-locstrings/lib/strings/unsubscribe.locstring.json';
import type { TableView } from 'owa-mail-list-store';
import { logUsage } from 'owa-analytics';
import type { SuggestedUnsubscribeItem } from 'owa-mail-suggested-unsubscribe-store';
import {
    getSuggestedUnsubscribeReasonString,
    getSuggestedUnsubscribeItem,
} from 'owa-mail-suggested-unsubscribe-store';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

export interface SuggestedUnsubscribeProps {
    isSingleLineView: boolean;
    isSelected: boolean;
    rowId: string;
    tableView: TableView;
}

export default observer(function SuggestedUnsubscribe(props: SuggestedUnsubscribeProps) {
    React.useEffect(() => {
        logUsage('SuggestedUnsubscribeRendered');
    }, []);

    let suggestedUnsubscribeReason: string = '';
    const suggestedUnsusbcribeItem: SuggestedUnsubscribeItem | undefined =
        getSuggestedUnsubscribeItem(props.rowId, getModuleContextMailboxInfo());

    const renderPeekComponent = React.useCallback(
        (buttonId: string, onCalloutDismiss: () => void): JSX.Element | null => {
            if (!suggestedUnsusbcribeItem) {
                return null;
            }
            return (
                <SuggestedUnsubscribePeek
                    calloutTarget={`#${buttonId}`}
                    onCalloutDismiss={onCalloutDismiss}
                    senderName={suggestedUnsusbcribeItem.senderName}
                    senderEmailAddress={suggestedUnsusbcribeItem.senderEmailAddress}
                    suggestionReason={suggestedUnsubscribeReason}
                    tableView={props.tableView}
                />
            );
        },
        [props.tableView, suggestedUnsusbcribeItem, suggestedUnsubscribeReason]
    );

    if (!suggestedUnsusbcribeItem) {
        return null;
    }

    suggestedUnsubscribeReason = getSuggestedUnsubscribeReasonString(
        suggestedUnsusbcribeItem.reason
    );

    return (
        <MailListItemInfo
            isSingleLineView={props.isSingleLineView}
            isSelected={props.isSelected}
            itemClassIcon={PropertyIcons.MailDismiss}
            title={suggestedUnsubscribeReason}
            buttonText={loc(unsubscribe)}
            renderPeekComponent={renderPeekComponent}
        />
    );
}, 'SuggestedUnsubscribe');
