import type { FavoriteTreeData } from './schema/FavoritesStore';
import { FolderTreeLoadStateEnum } from 'owa-folders';

/**
 * Gets the default favorite tree data
 */
export function getDefaultFavoriteTreeData(mailboxInfoKey: string): FavoriteTreeData {
    return {
        mailboxInfoKey,
        orderedOutlookFavoritesIds: [],
        loadingState: FolderTreeLoadStateEnum.Uninitialized,
    };
}
