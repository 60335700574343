import { LazyImport, LazyModule } from 'owa-bundling';
const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "RecipientSuggestions"*/ './lazyIndex')
);

export const lazySearchSuggestionsForSinglePersona = new LazyImport(
    lazyModule,
    m => m.searchSuggestionsForSinglePersona
);

export const lazySearchSuggestions = new LazyImport(lazyModule, m => m.searchSuggestions);

export const lazyLogResponseReceived = new LazyImport(lazyModule, m => m.logResponseReceived);
export const lazyLogRenderLayout = new LazyImport(lazyModule, m => m.logRenderLayout);
export const lazyLogClientDataSource = new LazyImport(lazyModule, m => m.logClientDataSource);
export const lazyLogSearchActions = new LazyImport(lazyModule, m => m.logSearchActions);
export const lazyLogManuallyAddedContact = new LazyImport(
    lazyModule,
    m => m.logManuallyAddedContact
);
