import { lazyGetNewGroupSections } from 'owa-mail-ribbon-sections/lib/sections';

import type { MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onNewGroupMenuClicked = (
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
) => {
    lazyGetNewGroupSections.import().then(getNewGroupSections => {
        setMenuDefinition('NewGroupMenuDefinitionStore', undefined /* projectionTabId */, () => {
            return {
                sections: getNewGroupSections(keytipTabId, props),
            };
        });
    });
};
