import type { Capability } from 'owa-capabilities';
import { getApplicationSettings } from 'owa-application-settings';
import { isMac } from '@fluentui/utilities';

/**
 * This capability is used to determine if Mac fonts should be rendered
 */
export const calendarMacFontsCapability: Capability = {
    isEnabled: () => {
        return getApplicationSettings('CalendarMacFonts').enabled;
    },
    isSupported: () => {
        return isMac();
    },
};
