import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { RibbonControlId } from 'owa-ribbon-ids';

/**
 * This file contains the controls that have special cases when scaling (both initial scaling and
 * regular scaling)
 */

/**
 * Add the controls here that should never display their labels
 */
export const controlsWithoutLabels: RibbonControlId[] = [];

/**
 * Add the controls here that should NOT drop their labels when they scale.
 * Note: These controls are ones that start off with labels. For controls that never
 * have labels, please place in controlsWithoutLabels section
 */
export const controlsDontDropLabels: RibbonControlId[] = [588];

/**
 * Add the controls here that should NOT move to the overflow
 * menu when scaling
 */
export const controlsDontMoveToOverflow: RibbonControlId[] = [];
