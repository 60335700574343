import { applyMiddleware } from 'satcheljs';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { legacyApplyMiddleware } from 'satcheljs/lib/legacy';
import type { Middleware } from 'satcheljs';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import type { LegacyMiddleware } from 'satcheljs/lib/legacy';

const cachedMiddleware: Middleware[] = [];
const cachedLegacyMiddleware: LegacyMiddleware[] = [];

export function addNewMiddleWare(middlewares: Middleware[], legacyMiddlewares: LegacyMiddleware[]) {
    for (const middleware of middlewares) {
        cachedMiddleware.push(middleware);
    }

    for (const legacyMiddleware of legacyMiddlewares) {
        cachedLegacyMiddleware.push(legacyMiddleware);
    }

    applyMiddleware.apply(null, cachedMiddleware);
    legacyApplyMiddleware.apply(null, cachedLegacyMiddleware);
}
