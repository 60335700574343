import { action } from 'satcheljs';
import type { MailFolderFromServiceFragment } from 'owa-mail-folder-view-graphql';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Action for fetching the search folders and adding them to the folder tree
 */
export const fetchSearchFolders = action(
    'FETCH_SEARCH_FOLDERS',
    (mailboxInfo: MailboxInfo, rootFolder: MailFolderFromServiceFragment) => ({
        mailboxInfo,
        rootFolder,
    })
);
