import type { MailboxType } from 'owa-graph-schema';
import { action } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Action executed when user changes the text of moveTo mailbox menu search box
 * @param searchText - the searched text
 */
export const onSearchTextChanged = action(
    'ON_MOVETO_SEARCH_TEXT_CHANGES',
    (currentSearchText: string, newSearchText: string) => ({ currentSearchText, newSearchText })
);

/**
 * When user clicks to see all folders of any mailbox (primary or archive) then this action is dispatched
 */
export const onViewAllMenuClicked = action(
    'ON_VIEW_ALL_MENU_CLICKED',
    (mailboxInfo: MailboxInfo) => ({
        mailboxInfo,
    })
);

/**
 * When moveto mbx type has to be changed
 * @param moveToMailboxType - the mailbox type for which all folders should be shown (consequently search and new folders also falls in this context)
 */
export const onChangeMoveToMailboxType = action(
    'ON_CHANGE_MOVE_TO_CONTEXT',
    (moveToMailboxType: MailboxType) => ({ moveToMailboxType })
);

/**
 * When sharedFolderRootId is changed
 * @param sharedFolderRootId - the folderId of the shared folder root
 */
export const onChangeMoveToSharedFolderRootId = action(
    'ON_CHANGE_MOVE_TO_SHARED_FOLDER_ROOT_ID',
    (sharedFolderRootId: string) => ({ sharedFolderRootId })
);

/**
 * When new folder item's text is changed then this action is called
 * @param folderText - the text by which new folder can be created
 */
export const onNewFolderTextChanged = action('SET_NEW_FOLDER_TEXT', (folderText?: string) => ({
    folderText: folderText ?? '',
}));

// set the new folder item's text as invalid
export const onNewFolderTextInvalidated = action('SET_NEW_FOLDER_INVALID_STATE');

/**
 * when focus or blur happens for search box in moveTo mailbox menu
 * @param isFocusInSearchBox - a boolean to say if seach box is focused or not focused(blurred)
 */
export const setIsFocusInSearchBox = action(
    'SET_FOCUS_IN_SEARCH_BOX',
    (isFocusInSearchBox: boolean) => ({ isFocusInSearchBox })
);

// Clicking on new folder menu item will dispatch this action
export const onNewFolderMenuClicked = action('NEW_FOLDER_MENU_CLICK');

// This action is called to reset the new folder menu item state
export const resetNewFolderViewState = action('RESET_NEW_FOLDER_VIEWSTATE');
