import { getStore } from '../store';
import { mutator } from 'satcheljs';
import { initializeOwaDateTimeStore } from '../actions/internalActions';
import { setTimeFormat } from '../actions';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(initializeOwaDateTimeStore, ({ TimeFormat = '' }) => {
    getStore().TimeFormat = TimeFormat;
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(setTimeFormat, ({ timeFormat }) => {
    getStore().TimeFormat = timeFormat;
});
