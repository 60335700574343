import { LazyAction, LazyImport, LazyModule } from 'owa-bundling';
import type { ConfirmCustomizationOptions } from './actions/confirm';
import type { DialogResponse } from './actions/DialogResponse';
import type { textResolver } from './components/ConfirmDialog';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ConfirmDialog"*/ './lazyIndex')
);

// Delay loaded action as import since action does not allow to return non-void values
const lazyConfirm = new LazyImport(lazyModule, m => m.confirmAction);
export const lazyShowConfirmWithChoiceGroupDialog = new LazyAction(
    lazyModule,
    m => m.showConfirmWithChoiceGroupDialog
);

export function confirm(
    title: string | textResolver,
    subText?: string | textResolver,
    resolveImmediately?: boolean,
    customizationOptions?: ConfirmCustomizationOptions
): Promise<DialogResponse> {
    return lazyConfirm.import().then(concreteConfirm => {
        return concreteConfirm(title, subText, resolveImmediately, customizationOptions);
    });
}

// Types
export { DialogResponse } from './actions/DialogResponse';
export type { ConfirmCustomizationOptions } from './actions/confirm';
export type { ChoiceDialogResponse } from './actions/ChoiceDialogResponse';
