import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isAnalyzeContentDisabledForPrivacy } from 'owa-privacy-utils/lib/selectors/isAnalyzeContentDisabledForPrivacy';
import {
    isBrowserChrome,
    isBrowserEDGECHROMIUM,
    isBrowserFirefox,
    isBrowserSafari,
} from 'owa-user-agent/lib/userAgent';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 */
export const editorCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return true; // by default
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        return isFeatureEnabledForUser(mailboxInfo) && !isAnalyzeContentDisabledForPrivacy();
    },
};

function isFeatureEnabledForUser(mailboxInfo?: MailboxInfo): boolean {
    if (!isFeatureEnabled('mc-officeEditorProofingMasterFlight', mailboxInfo)) {
        return false;
    }

    return isBrowserChrome() || isBrowserEDGECHROMIUM() || isBrowserFirefox() || isBrowserSafari();
}
