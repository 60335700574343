import { getUserOutlookClients } from '../services/getUserOutlookClients';
import type { UserOutlookClients } from '../store/schema/UserOutlookClients';
import { setUserOutlookClientsState } from '../mutators/setUserOutlookClientsState';
import { getUserOutlookClientsState } from '../selectors/getUserOutlookClientsState';
import type { OwaDate } from 'owa-datetime';
import { owaDate } from 'owa-datetime';
import type { PhoneAppInstallState } from '../store/schema/PhoneAppInstallState';

export default async function isPhoneAppInstalled(
    promoDismissDate: OwaDate
): Promise<PhoneAppInstallState> {
    let appInstalled = false;
    let endpointApiFailed = false;
    let userClients: UserOutlookClients = getUserOutlookClientsState();
    const userClientsToLog: string[] = [];

    if (userClients.length == 0) {
        userClients = await getUserOutlookClients();
    }

    if (userClients && userClients.length > 0) {
        setUserOutlookClientsState(userClients);

        userClients.forEach(client => {
            const clientPromoDismissDate = owaDate('UTC', client.mobilePromoDismissDate);
            if (userClientsToLog.indexOf(client.clientType) == -1) {
                userClientsToLog.push(client.clientType);
            }

            if (client.clientType == 'AndroidOutlook' || client.clientType == 'IOSOutlook') {
                appInstalled = true;
            } else if (
                client.clientType == 'OWA' &&
                client.mobilePromoDismissDate &&
                promoDismissDate < clientPromoDismissDate
            ) {
                promoDismissDate = clientPromoDismissDate;
            }
        });
    } else {
        // If we dont get a response from endpoint
        // then we assume user already have app installed
        appInstalled = true;
        endpointApiFailed = true;
    }

    return {
        isAppInstalled: appInstalled,
        mobilePromoDismissDate: promoDismissDate,
        userClients: userClientsToLog,
        endpointApiFailed,
    };
}
