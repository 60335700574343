import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ReactionsInMessageList" */ './lazyIndex'),
    { name: 'ReactionsInMessageList' }
);

export const lazyFetchReactionDataForMessageList = new LazyAction(
    lazyModule,
    m => m.fetchReactionDataForMessageList
);
