import { getStore } from '../store/store';
import { WhatsNewCardStatus } from '../types/WhatsNewCardStatus';
import { hasCardExpired } from './hasCardExpired';

export function getUnreadWhatsNewCardCount(): number {
    const store = getStore();

    if (store.cardStates) {
        return Object.keys(store.cardStates).filter(
            key =>
                !hasCardExpired(store.cardStates?.[key]) &&
                store.cardStates?.[key].status === WhatsNewCardStatus.Unread &&
                store.cardStates?.[key].category !== 'carousel'
        ).length;
    }

    return 0;
}
