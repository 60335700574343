import { unreadCount as styles_unreadCount } from 'owa-mail-listitem-styles/lib/scss/MailListItem.scss';
import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { useMailListItemContainerContext } from 'owa-mail-list-item-shared';
import { getConditionalFormattingStylesForText } from 'owa-conditional-formatting';
import { useMessageListTextStyle } from 'owa-mail-listitem-styles/lib/utils/useMessageListTextStyle';
import React from 'react';

export interface MailListItemUnreadCountProps {
    unreadCount: number;
}

export default observer(function MailListItemUnreadCount(
    props: MailListItemUnreadCountProps
): JSX.Element | null {
    const { unreadCount } = props;
    const { conditionalFormattingModifications } = useMailListItemContainerContext();
    const unreadCountConditionalFormattingStyles = React.useMemo(() => {
        const conditionalFormattingStyles =
            conditionalFormattingModifications && conditionalFormattingModifications.length > 0
                ? getConditionalFormattingStylesForText(conditionalFormattingModifications)
                : undefined;

        return conditionalFormattingStyles;
    }, [conditionalFormattingModifications]);

    const unreadCountClasses = classnames(styles_unreadCount, useMessageListTextStyle('Major'));

    return unreadCount > 1 ? (
        <span className={unreadCountClasses} style={unreadCountConditionalFormattingStyles}>
            ({unreadCount})
        </span>
    ) : null;
},
'MailListItemUnreadCount');
