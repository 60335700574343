import loc from 'owa-localize';
import { NovaEventTypes } from 'owa-nova-actions/lib/types';
import {
    pinMessageExtension,
    unpinMessageExtension,
} from '../strings/messageExtensionPinCommands.locstring.json';
import { isMessageExtensionPinned } from 'owa-m365-acquisitions/lib/utils/isMessageExtensionPinned';
import type { StrictAppSecondaryAction } from '../types';
import type { MailboxInfo } from 'owa-client-types';

export function getPinMessageExtensionSecondaryAction(
    tileId: string,
    mailboxInfo?: MailboxInfo
): StrictAppSecondaryAction {
    return {
        __typename: 'AppSecondaryAction',
        id: tileId ?? '',
        icon: {
            __typename: 'InternalIcon',
            name: 'PinIcon',
        },
        text: isMessageExtensionPinned(tileId ?? '')
            ? loc(unpinMessageExtension)
            : loc(pinMessageExtension),
        eventType: NovaEventTypes.pinMessageExtension,
        eventData: JSON.stringify({
            appId: tileId,
            mailboxInfo,
        }),
    };
}
