import { ConvertableIdFormat } from '../schema';
import { partitionIds } from './idHandlingUtils';
import { getTargetFormat } from './getTargetFormat';
import type { MailboxInfo } from 'owa-client-types';

export function isConversionNeeded(ids: (string | null | undefined)[], mailboxInfo: MailboxInfo) {
    const [immutableIds, ewsIds] = partitionIds(ids);
    const targetFormat = getTargetFormat(mailboxInfo);

    return (
        (targetFormat === ConvertableIdFormat.EwsImmutableId && ewsIds.length > 0) ||
        (targetFormat === ConvertableIdFormat.EwsId && immutableIds.length > 0)
    );
}
