import { getStore } from '../store/Store';
import { mutatorAction } from 'satcheljs';

export default mutatorAction(
    'setTypeOfItemBeingDragged',
    function setTypeOfItemBeingDragged(dataItemType: string | null) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (10,9): Type 'string | null' is not assignable to type 'string'.
        // @ts-expect-error
        getStore().typeOfItemBeingDragged = dataItemType;
    }
);
