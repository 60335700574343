import { action } from 'satcheljs';
import type { Module } from '../store/schema/Module';
import type React from 'react';
import type { MailboxInfo } from 'owa-client-types';

export default action(
    'onModuleClick',
    (
        module: Module,
        currentlySelectedModule?: Module,
        activeModuleAction?: (
            ev?: React.MouseEvent<unknown> | React.KeyboardEvent<HTMLElement>
        ) => void,
        mailboxInfo?: MailboxInfo
    ) => ({
        module,
        currentlySelectedModule,
        activeModuleAction,
        mailboxInfo,
    })
);
