import getPrimaryFromAddress from './getPrimaryFromAddress';
import shouldSkipEmailAddress from './shouldSkipEmailAddress';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import type { MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store';
import {
    getSharedAccountSourcesForMailboxInfo,
    ContractsSupportedFilter,
} from 'owa-account-source-list-store';
import type { M365SharedMailboxAccountSource } from 'owa-account-source-list-store';

/**
 * Gets all addresses a user can send from, including the default email address,
 * alias addresses, and connected account addresses
 * @param mailboxInfo, the mailboxInfo of the account
 * @param includeProxyAddressesForEnt, whether or not we should include user
 * @param includeAutomappedAccounts whether or not we should include auto mapped accounts
 * proxy addresses for enterprise users
 */
export default function getAllFromAddresses(
    mailboxInfo: MailboxInfo,
    includeProxyAddressesForEnt: boolean,
    includeAutomappedAccounts: boolean
): string[] {
    let sendAsAddresses: string[] = [];

    // Add the primary account address
    const primaryAddress = getPrimaryFromAddress(mailboxInfo);
    if (!shouldSkipEmailAddress(primaryAddress, mailboxInfo)) {
        sendAsAddresses.push(primaryAddress);
    }

    // Add alias addresses
    const aliasAddresses = getAliasAddresses(
        mailboxInfo,
        primaryAddress,
        includeProxyAddressesForEnt
    );
    sendAsAddresses = sendAsAddresses.concat(aliasAddresses);

    if (includeAutomappedAccounts) {
        // Add automapped shared mailboxes
        getSharedAccountSourcesForMailboxInfo(mailboxInfo, ContractsSupportedFilter.Mail).forEach(
            (sharedSourceAccount: M365SharedMailboxAccountSource) => {
                if (sharedSourceAccount.isAutomapped) {
                    sendAsAddresses.push(sharedSourceAccount.mailboxInfo.mailboxSmtpAddress);
                }
            }
        );
    }

    return sendAsAddresses;
}

/**
 * Gets alias addresses, i.e. user proxy addresses and not managed email addresses
 * @param primaryAddress, the user's primary email address
 * @param includeProxyAddressesForEnt, whether or not we should include user
 * proxy addresses for enterprise users
 */
export function getAliasAddresses(
    mailboxInfo: MailboxInfo,
    primaryAddress: string,
    includeProxyAddressesForEnt: boolean
): string[] {
    let aliasAddresses: string[] = [];
    const isConsumerBool = isConsumer(undefined /* smtpAddress */, mailboxInfo);

    // Add user proxy addresses for enterprise users if the flag is denoted,
    // and always for consumers (VSO 56303)
    if (isConsumerBool || includeProxyAddressesForEnt) {
        const userProxyAddresses = getUserProxyAddresses(mailboxInfo, primaryAddress);
        aliasAddresses = aliasAddresses.concat(userProxyAddresses);
    }

    return aliasAddresses;
}

// NOTE: below functions are exported for testing, otherwise only used internally

/**
 * Gets user proxy addresses
 * @param primaryAddress, the user's primary email address
 */
export function getUserProxyAddresses(mailboxInfo: MailboxInfo, primaryAddress: string): string[] {
    return filterEmailAddresses(
        mailboxInfo,
        primaryAddress,
        getAccountScopeUserSettings(mailboxInfo).SessionSettings?.UserProxyAddresses
    );
}

/**
 * Filters out email addresses that should not be exposed to the client
 * @param primaryAddress, the user's primary email address
 * @param addresses, the email addresses to filter
 */
export function filterEmailAddresses(
    mailboxInfo: MailboxInfo,
    primaryAddress: string,
    addresses: readonly string[] | null | undefined
) {
    const result: string[] = [];

    if (addresses && addresses.length > 0) {
        addresses.forEach(address => {
            if (primaryAddress != address && !shouldSkipEmailAddress(address, mailboxInfo)) {
                result.push(address);
            }
        });
    }

    return result;
}
