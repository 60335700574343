import { mailListItemAnimationStore } from '../store/Store';
import type { MailListItemAnimation } from '../store/schema/MailListItemAnimationStore';

export function getAreRowKeysInExistingAnimation(rowKeys: string[]): boolean {
    const { activeAnimations, pendingAnimations } = mailListItemAnimationStore;

    for (const rowKey of rowKeys) {
        if (activeAnimations.some(getRowKeyFilter(rowKey))) {
            return true;
        }
        if (pendingAnimations.some(getRowKeyFilter(rowKey))) {
            return true;
        }
    }

    return false;
}

export const getRowKeyFilter = (rowKey: string) => (animation: MailListItemAnimation) =>
    animation.animationRowIds?.has(rowKey);
