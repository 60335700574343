import type { MailboxInfo } from 'owa-client-types';
import getMailboxFolderTreeDataTable from './getMailboxFolderTreeDataTable';

export default function getFolderTreeCurrentLoadedIndex(
    folderTreeIdentifier: string,
    mailboxInfo: MailboxInfo
): number {
    const folderTreeTable = getMailboxFolderTreeDataTable(mailboxInfo);
    const folderTable = folderTreeTable && folderTreeTable.get(folderTreeIdentifier);
    return folderTable?.currentLoadedIndex || 0;
}
