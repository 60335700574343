import { mutatorAction } from 'satcheljs';
import { getFavoriteGroupViewState } from '../selectors/getFavoriteGroupViewState';

/**
 * Expands the group folder hierarchy
 * @param groupId - smtp address of a group
 * @param folderId - Id of the group folder
 * @param mailboxInfo - mailbox info of the account
 */
export default mutatorAction(
    'setFavoriteGroupFolderViewExpanded',
    (groupId: string, folderId: string): void => {
        const groupViewState = getFavoriteGroupViewState(groupId);
        if (groupViewState) {
            const groupFolderViewState = groupViewState.groupFolderNodeViewStates.get(folderId);
            if (groupFolderViewState) {
                groupFolderViewState.isExpanded = true;
            }
        }
    }
);
