import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';
import type { GroupListNodesViewStateStore } from './schema/GroupListNodesViewStateStore';
import type { GroupListNodesViewStateByMailBoxInfo } from './schema/GroupListNodesViewStateByMailBoxInfo';

const initialGroupListNodesViewStateByMailboxInfo: GroupListNodesViewStateByMailBoxInfo = {
    groupListNodesViewStatesByMailBoxInfo: new ObservableMap<string, GroupListNodesViewStateStore>(
        {}
    ),
};

const store = createStore<GroupListNodesViewStateByMailBoxInfo>(
    'groupListNodesViewStateByMailBoxInfo',
    initialGroupListNodesViewStateByMailboxInfo
)();
export const getGroupListNodesViewState = () => store;
