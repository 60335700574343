import type RequestOptions from 'owa-service/lib/RequestOptions';
import type MailboxRequestOptions from 'owa-service/lib/MailboxRequestOptions';
import { getConnectedAccountRequestOptions } from 'owa-connected-account-headers';

/**
 * Adds the headers for the connected account to the request options while preserving the original headers
 * @param mailboxRequestOptions Specifies the request options
 * @returns Request options with connected account headers
 */
export default function prepareConnectedAccountRequestOptions(
    mailboxRequestOptions: MailboxRequestOptions
): Promise<RequestOptions> {
    return getConnectedAccountRequestOptions(
        mailboxRequestOptions,
        true /* preserveOriginalHeaders */
    );
}
