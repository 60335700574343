/**
 * Determines if the specified value is a number
 * @param value data to be checked
 * @returns True if it is a number, false otherwise
 */
export function isNumber(value: any): value is number {
    return typeof value === 'number';
}

/**
 * Determines if the specified value is a string
 * @param value data to be checked
 * @returns True if it is a string, false otherwise
 */
export function isString(value: any): value is string {
    return typeof value === 'string';
}

/**
 * Determines if the specified value is a string that represents a Date
 * @param value data to be checked
 * @returns True if it is a string that represents a Date, false otherwise
 */
export function isDateString(value: any): boolean {
    return isString(value) && !isNaN(Date.parse(value));
}

/**
 * Determines if the specified value is a string or undefined
 * @param value data to be checked
 * @returns True if it is a string or undefined, false otherwise
 */
export function isStringOrUndefined(value: any): boolean {
    return value === undefined || isString(value);
}

/**
 * Predicate to determine if the specified value is an array of strings
 * @param value Value to be checked
 * @returns True if if is a string array
 */
export function isArrayOfStrings(value: any): value is string[] {
    return Array.isArray(value) && value.every(isString);
}
