import type { RequestOptions } from 'owa-service/lib/RequestOptions';
import type MailboxRequestOptions from 'owa-service/lib/MailboxRequestOptions';

/**
 * The WorkerConfig is used for functions that need to be accessed from both
 * the worker and the main thread.
 */
export interface WorkerConfig {
    prepareConnectedAccountRequestOptions?: (
        mailboxRequestOptions: MailboxRequestOptions
    ) => Promise<RequestOptions>;
    getTimeZoneAndLanguage?: () => Promise<
        | {
              timeZone: string;
              language: string;
          }
        | undefined
    >;
}

const defaultWorkerConfig = {};

let cachedWorkerConfig: WorkerConfig = defaultWorkerConfig;

export function updateWorkerConfig(config?: Partial<WorkerConfig>) {
    cachedWorkerConfig = {
        ...cachedWorkerConfig,
        ...config,
    };
}

export function getWorkerConfig(): WorkerConfig {
    return cachedWorkerConfig;
}

export function resetWorkerConfigToDefault() {
    cachedWorkerConfig = defaultWorkerConfig;
}
