/************************************************************************************
 * Note to future editors: static exports have been removed from this file and turned
 * into deep imports. This is a requirement for ongoing perf work.
 *
 * Please do not add a non-lazy export without contacting the Calendar Forms team:
 * zabryant, jareill, yingchon, or manager krsheth
 *
 * Adding an export may require updating both package.json and fence.json before use.
 * See the wiki for more info:
 * https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/12542/Bundle-Perf-Efforts
 ************************************************************************************/
import { createLazyComponent, LazyAction, LazyModule, LazyImport } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "NotificationBar" */ './lazyIndex'),
    { name: 'NotificationBar' }
);

export const NotificationBarHost = createLazyComponent(lazyModule, m => m.NotificationBarHost);

export const lazyShowNotification = new LazyAction(lazyModule, m => m.showNotification);

export const lazyDismissNotification = new LazyAction(lazyModule, m => m.dismissNotification);

export const lazyClearNotificationBar = new LazyImport(lazyModule, m => m.clearNotificationBar);
