import { getSharePointResourceTokenPrefetchStateStore } from '../store/SharePointResourceTokenPrefetchStateStore';
import type { SharePointResourceTokenPrefetchState } from '../Schema/SharePointResourceTokenPrefetchState';
import { type SharePointResourceTokenPrefetchStatus } from '../Schema/SharePointResourceTokenPrefetchState';

// eslint-disable-next-line node/no-deprecated-api  -- (https://aka.ms/OWALintWiki) * This should be fixed, but "new URL()" has somewhat different behavior that makes automated migration difficult. *	> 'url.parse' was deprecated since v11.0.0. Use 'url.URL' constructor instead. */
import { parse as parseUrl } from 'url';

const getAuthority = (url: string): string => {
    const webUri = parseUrl(url);
    return `${webUri.protocol}//${webUri.host}`;
};

// Returns the current (or default) status of sharepoint resource token
export function getSharePointResourceTokenPrefetchState(
    resourceUrl: string
): SharePointResourceTokenPrefetchState {
    const sharePointResourceTokenPrefetchStateStore =
        getSharePointResourceTokenPrefetchStateStore();
    const authorityUrl = getAuthority(resourceUrl);

    const currentResourceTokenPrefetchState =
        sharePointResourceTokenPrefetchStateStore.tokenStatusPerResource.get(
            authorityUrl.toLowerCase()
        );
    let resourceTokenState: SharePointResourceTokenPrefetchState;

    if (currentResourceTokenPrefetchState == null) {
        resourceTokenState = {
            status: 0,
        };
    } else {
        resourceTokenState = currentResourceTokenPrefetchState;
    }

    return resourceTokenState;
}
