import React from 'react';
import Strings from '../strings/toggleLeftPaneButton.locstring.json';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import { isFeatureEnabled } from 'owa-feature-flags';
import { V8ToggleButton } from './V8ToggleButton';
import { V9ToggleButton } from './V9ToggleButton';

type ExtraTopRibbonControlsBeforeTabsProps = {
    isExpanded: boolean;
    onClick: () => void;
};

export const ExtraTopRibbonControlsBeforeTabs = observer(
    ({ isExpanded, onClick }: ExtraTopRibbonControlsBeforeTabsProps) => {
        const sharedProps = {
            label: loc(isExpanded ? Strings.hideLeftPaneButton : Strings.showLeftPaneButton),
            onClick,
        };

        return isFeatureEnabled('mon-ribbon-fluent-v9-ribbon') ? (
            <V9ToggleButton {...sharedProps} />
        ) : (
            <V8ToggleButton {...sharedProps} />
        );
    },
    'ExtraTopRibbonControlsBeforeTabs'
);
