import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';
import type AccountScopeCategoryProperties from './schema/AccountScopeCategoryProperties';
import type CategoryStore from './schema/CategoryStore';

const defaultCategoryStore = {
    categoryMenuViewState: {
        findText: '',
        isFocusInSearchBox: false,
        shouldShowAllCategories: false,
    },
    categoryColorPickerViewState: {
        colorPickerTarget: null,
        shouldShowColorPicker: false,
    },
    accountCategoryProps: new ObservableMap<string, AccountScopeCategoryProperties>({}),
    categoryDialogViewState: null,
    isApplyingCategoryOperation: false,
};

// Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
// -> Error TS2345 (30,64): Argument of type '{ categoryIdToNameMap: {}; categoryMenuViewState: { findText: string; isFocusInSearchBox: boolean; shouldShowAllCategories: boolean; }; categoryColorPickerViewState: { colorPickerTarget: null; shouldShowColorPicker: boolean; }; ... 6 more ...; blockedCategoryNames: never[]; }' is not assignable to parameter of type 'CategoryStore'.
// @ts-expect-error
const store = createStore<CategoryStore>('category', defaultCategoryStore)();
export default store;
export const getStore = () => store;
