import type TableView from '../store/schema/TableView';

/**
 * Gets all the rowKeys for the given rowIds
 * @param rowIds Row ids
 * @param tableViewId for which to find the rowKey
 * @returns rowKeys for the given rowId
 */
export default function getRowKeysFromRowIds(rowIds: string[], tableView: TableView): string[] {
    const rowKeysToReturn: string[] = [];
    for (let i = 0; i < rowIds.length; i++) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (15,15): Type 'string[] | undefined' is not assignable to type 'string[]'.
        // @ts-expect-error
        const rowKeys: string[] = tableView.rowIdToRowKeyMap.get(rowIds[i]);
        if (rowKeys) {
            // Row may have been deleted due to notifications when local update code was trying to operate on the rowIds
            Array.prototype.push.apply(rowKeysToReturn, rowKeys);
        }
    }

    return rowKeysToReturn;
}
