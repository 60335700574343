import { logUsage } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isOcpsPolicyEnabled, OcpsPolicy } from 'owa-ocps-policy-store';

export function isSupportEnabled(): boolean {
    const contactSupportPolicy = isOcpsPolicyEnabled(OcpsPolicy.ContactSupport, true);
    const diagnosticsTroubleshootingtPolicy = isOcpsPolicyEnabled(
        OcpsPolicy.DiagnosticsTroubleshooting,
        true
    );

    logUsage('DiagnosticsAndSupport_IsSupportEnabled_Old', {
        isSupportFlightEnabled: isFeatureEnabled('mon-diag-inappsupport'),
        isSupportPolicyEnabled: contactSupportPolicy,
        isTroubleshootingPolicyEnabled: diagnosticsTroubleshootingtPolicy,
    });
    return (
        isFeatureEnabled('mon-diag-inappsupport') &&
        (contactSupportPolicy || diagnosticsTroubleshootingtPolicy)
    );
}
