import { getOpxHostApi, isOpxHostInitialized } from 'owa-opx';
import { getConfig } from 'owa-service/lib/config';

export async function isResourceTokenPrefetchEnabled(): Promise<boolean | undefined> {
    // for Monarch we dont rely on opxHostApi anymore, instead get it from config.
    const serviceConfig = getConfig();
    if (serviceConfig?.isResourceTokenPrefetchEnabled) {
        return true;
    }

    const opxHostApi = isOpxHostInitialized() ? getOpxHostApi() : undefined;
    if (opxHostApi?.isResourceTokenPrefetchEnabled) {
        return opxHostApi.isResourceTokenPrefetchEnabled();
    }

    return false;
}
