import { isNumber, isDateString, isStringOrUndefined } from './typeChecks';
import type { ImportSources } from './ImportSources';

/**
 * Determines if the specified value is an ImportSource
 * @param value data to be checked
 * @returns True if it is an ImportSources, false otherwise
 */
/* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
 * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
 *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
function isImportSources(value?: any): value is ImportSources {
    return (
        isNumber(value?.Win32Count) &&
        isNumber(value?.UniversalCount) &&
        isDateString(value?.lastImportDate) &&
        isStringOrUndefined(value?.audienceGroupSnapshot) &&
        isStringOrUndefined(value?.channelSnapshot) &&
        isStringOrUndefined(value?.correlationId) &&
        isStringOrUndefined(value?.lastImportApp)
    );
}

/**
 * Attempts to parse the JSON and return the ImportSources value
 * @param json JSON value to be parsed
 * @returns ImportSources or undefined
 */
export function tryParseImportSources(json: string | undefined | null): ImportSources | undefined {
    try {
        if (json) {
            const value = JSON.parse(json);
            if (isImportSources(value)) {
                return {
                    ...value,
                    // Convert the lastImportDate from a string to a Date object
                    lastImportDate: new Date(value.lastImportDate),
                };
            }
        }
    } catch {
        // ignore any exceptions getting the value
    }

    return undefined;
}
