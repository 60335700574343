import type { MailboxInfo } from 'owa-client-types';
import { getGroupFolder } from './getGroupFolder';

export function getGroupFolderName(
    groupId: string,
    folderId: string,
    mailboxInfo: MailboxInfo
): string {
    return getGroupFolder(groupId, folderId, mailboxInfo)?.DisplayName ?? '';
}
