import {
    fadeInShadowBox,
    fadeOutShadowBox,
    fadeInSidePanelShadowBox,
    fadeOutSidePanelShadowBox,
} from '../components/ShadowBox';

const searchInputShadowBoxElement = 'searchInputShadowBoxElement';
const sidePanelShadowBoxElement = 'sidePanelShadowBoxElement';

export function removeShadow() {
    const shadowBox = window.document.getElementById(searchInputShadowBoxElement);
    if (shadowBox) {
        fadeOutShadowBox(shadowBox);
    }
    const sidePanelShadowBox = window.document.getElementById(sidePanelShadowBoxElement);
    if (sidePanelShadowBox) {
        fadeOutSidePanelShadowBox(sidePanelShadowBox);
    }
}

export function addShadow(componentId: string, sidePanelId: string) {
    let shadowBox = window.document.getElementById(searchInputShadowBoxElement);
    if (!shadowBox) {
        shadowBox = document.createElement('div');
        shadowBox.id = searchInputShadowBoxElement;
        shadowBox.onclick = removeShadow;
        fadeInShadowBox(shadowBox);

        const componentToAttach = document.getElementById(componentId);
        componentToAttach?.appendChild(shadowBox);
    } else {
        fadeInShadowBox(shadowBox);
    }

    let sidePanelShadowBox = window.document.getElementById(sidePanelShadowBoxElement);
    if (!sidePanelShadowBox) {
        sidePanelShadowBox = document.createElement('div');
        sidePanelShadowBox.id = sidePanelShadowBoxElement;
        sidePanelShadowBox.onclick = removeShadow;
        fadeInSidePanelShadowBox(sidePanelShadowBox);

        const componentToAttach = document.getElementById(sidePanelId);
        componentToAttach?.appendChild(sidePanelShadowBox);
    } else {
        fadeInSidePanelShadowBox(sidePanelShadowBox);
    }
}
