import React from 'react';
import type { PresencePersonaBadgeProps } from './PresencePersonaBadgeProps';
import { getDensityModeString } from 'owa-fabric-theme';

export function PresencePersonaLoading(_props: PresencePersonaBadgeProps) {
    return <div style={getStyle()}></div>;
}

function getStyle() {
    const densityString = getDensityModeString();

    let margin = '4px';
    let width = '12px';
    let height = '12px';
    if (densityString == 'medium') {
        margin = '3px';
        width = '10px';
        height = '10px';
    } else if (densityString == 'compact') {
        margin = '2px';
        width = '10px';
        height = '10px';
    }

    return {
        backgroundColor: '#979593',
        verticalAlign: 'top',
        marginRight: margin,
        borderRadius: '50%',
        width,
        height,
    };
}
