import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "TimeZoneTravelNotification" */ './lazyIndex')
);

export const TimeZoneTravelNotification = createLazyComponent(
    lazyModule,
    m => m.TimeZoneTravelNotification
);
