import { isLocalDataBackedMailboxInfo } from 'owa-account-source-list/lib/utils/isLocalDataBackedAccount';
import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 *
 * This capability determines if the account maintains its own category list
 */
export const categoryListCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return true; // by default
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        if (!mailboxInfo) {
            return true;
        }

        // PST files do not usually have a category list persisted within them - in Win32 the
        // default store's category list is/was typically used in a PST context - so a PST file account
        // in Monarch will not support having its own category list, and can fall back to using the
        // category list of another account (e.g. the global settings account, to align with Win32 behavior)
        return !isLocalDataBackedMailboxInfo(mailboxInfo);
    },
};
