import type { MailboxInfo } from 'owa-client-types';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import getModuleContextMailboxInfoStore from '../store/moduleContextMailboxInfoStore';
import { getCurrentModule } from 'owa-app-module-store';
import { comparer, computed } from 'mobx';

export default function getModuleContextMailboxInfo(): MailboxInfo {
    if (getCurrentModule() === 'Mail' || getCurrentModule() === 'MailDeepLink') {
        // We only run the computed, shallow comparer version of getModuleContextMailboxInfoImpl in Mail scenarios
        // because that's where we're seeing improvements and where we are targetting this particular fix.
        // No need to have other modules like Calendar / People / etc run the computed version which can add extra cycles.
        return computedModuleContextMailboxInfo.get();
    }
    return getModuleContextMailboxInfoImpl();
}

const computedModuleContextMailboxInfo = computed(
    (): MailboxInfo => {
        return getModuleContextMailboxInfoImpl();
    },
    {
        equals: comparer.shallow, // Only one layer of depth is needed for MailboxInfo equivalence
        name: 'computedModuleContextMailboxInfo',
    }
);

function getModuleContextMailboxInfoImpl(): MailboxInfo {
    const currentModule = getCurrentModule();
    return (
        (currentModule &&
            getModuleContextMailboxInfoStore().moduleContextMailboxInfos.get(currentModule)) ??
        getGlobalSettingsAccountMailboxInfo()
    );
}
