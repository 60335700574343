import { getNonBootUserConfigurationSync } from '../getNonBootUserConfiguration';
import type { MailboxInfo } from 'owa-client-types';

export default function externalInOutlookEnabled(mailboxInfo: MailboxInfo) {
    // isAnonymousUser needs to be updated for proper auth checks.
    // Until that is done, bypass anonymous user check to make successful getOwaNonBootUserConfiguration service call.
    // TODO WI: 169415
    const { ExternalInOutlookEnabled = false } =
        getNonBootUserConfigurationSync(mailboxInfo, true /* skipAnonymousCheck */) || {};
    return ExternalInOutlookEnabled;
}
