import keepRouteCurrent from './keepRouteCurrent';
import navigateToRoute, { setVdir, getVdir } from './navigateToRoute';
import type RouterOptions from './RouterOptions';
import router from './routerInstance';
import { getRegisterRouteFunction } from './registerRoute';
import { trace } from 'owa-trace';
import { onModuleClick, determineModule } from 'owa-workloads';
import { resetLastProcessedRoute } from './lastProcessedRoute';

const modulesThatTriedToLoad: Set<string> = new Set();

export async function initializeRouter(routerOptions: RouterOptions) {
    await registerRouterRoutes(routerOptions);

    trace.info(`Set initial vdir to ${routerOptions.vdir}`, 'router');
    setVdir(routerOptions.vdir);
    router.configure({
        notfound: () => {
            // if we didn't find the route and the vdir is different, then it is possible
            // that loading that module would register the route needed. But we will keep track
            // of which modules loaded to make sure we don't get stuck in an infinite loop
            const currentModule = determineModule();
            const currentModuleString = currentModule.toString().toLowerCase();
            if (
                !getVdir().toLowerCase().startsWith(currentModuleString) &&
                !modulesThatTriedToLoad.has(currentModuleString)
            ) {
                modulesThatTriedToLoad.add(currentModuleString);
                trace.info(`Init click module to ${currentModuleString}`, 'router');
                onModuleClick(currentModule);
                resetLastProcessedRoute();
            } else {
                trace.info('RouteNotFound', 'router');
                routerOptions.routeGenerator.then(generator => {
                    navigateToRoute(generator());
                });
            }
        },
        html5history: true,
        convert_hash_in_init: false,
        run_handler_in_init: true,
        strict: false,
        async: true,
    });

    router.init();

    if (!routerOptions.staticUrl) {
        routerOptions.routeGenerator.then(generator => {
            keepRouteCurrent(generator);
        });
    }
}

export function registerRouterRoutes(routerOptions: RouterOptions, registerAll?: boolean) {
    return routerOptions.registerRoutes(
        getRegisterRouteFunction(routerOptions.vdir, routerOptions.setModuleForVdir),
        registerAll
    );
}
