import { getMailMenuItemShouldHideForMailbox } from 'owa-mail-filterable-menu-behavior';
import type { MailboxInfo } from 'owa-client-types';
import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import { getReadRibbonId } from './getReadRibbonId';
import { owaComputedFn } from 'owa-computed-fn';
import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { VerticalControlStack } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR/layoutTypings';
import type { ClientItemId } from 'owa-client-ids';
import { stack } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR/layoutTypings';

export const getIgnoreBlockReportStack = owaComputedFn(function getIgnoreBlockReportStack(
    mailboxInfo: MailboxInfo,
    itemId: ClientItemId | undefined
): VerticalControlStack | string {
    const controlsToDisplay: string[] = [];

    // IgnoreStopIgnore isn't available on popouts
    if (!itemId && !getMailMenuItemShouldHideForMailbox(54, mailboxInfo)) {
        controlsToDisplay.push(getReadRibbonId(531, itemId).toString());
    }

    if (!getMailMenuItemShouldHideForMailbox(5, mailboxInfo)) {
        controlsToDisplay.push(getReadRibbonId(658, itemId).toString());
    }

    // For MLR stacks, we always want 3 items in the stack, so we add a null item if there are only 2
    if (controlsToDisplay.length === 1) {
        return controlsToDisplay[0];
    } else {
        return stack(
            controlsToDisplay[0],
            controlsToDisplay[1],
            controlsToDisplay.length === 2 ? null : controlsToDisplay[2]
        );
    }
});
