import type { ImplementedCopilotFeatures, CopilotSettingsStore } from 'owa-copilot-settings-store';
import { getApplicationSettings } from 'owa-application-settings';
import isCopilotFeatureEnabled from './isCopilotFeatureEnabled';
import createStoreCheckForGenericEnablement from './createStoreCheckForGenericEnablement';
import type { MailboxInfo } from 'owa-client-types';

type RewriteScenarios = Extract<ImplementedCopilotFeatures, 'Rewrite'>;
const isCalendarRewriteEnabled = createStoreCheckForGenericEnablement<RewriteScenarios, boolean>(
    'CalendarRewrite',
    isCalendarRewriteEnabledInternal
);

export default isCalendarRewriteEnabled;

function isCalendarRewriteEnabledInternal(
    store: CopilotSettingsStore,
    _language: string,
    scenario: RewriteScenarios,
    mailboxInfo: MailboxInfo,
    skipLanguageCheck?: boolean
): boolean {
    if (store.isEnabled) {
        const featureEnabled = store.featureInfo.get(scenario);
        if (featureEnabled?.isEnabled) {
            const appSettings = getApplicationSettings('Copilot', mailboxInfo);
            return (
                !!isCopilotFeatureEnabled(
                    scenario,
                    mailboxInfo,
                    skipLanguageCheck ?? appSettings.enableMultiLanguage
                ) &&
                appSettings.calendarRewriteEnabled && // This is controlled through the Killswitch ECS here: https://ecs.skype.com/Substrate/OutlookWebClient/configurations/1092852
                !!featureEnabled.capabilities &&
                'calendarRewrite' in featureEnabled.capabilities &&
                featureEnabled.capabilities.calendarRewrite
            );
        }
    }
    return false;
}
