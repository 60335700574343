import type AttachmentDataProviderType from 'owa-service/lib/contract/AttachmentDataProviderType';
import { FileProviders } from 'owa-attachment-constants/lib/fileProviders';

export default function convertToAttachmentDataProviderType(
    providerType: string
): AttachmentDataProviderType | null {
    switch (providerType ? providerType.toLowerCase() : null) {
        case FileProviders.ONE_DRIVE_PRO.toLowerCase():
            return 0;
        case FileProviders.GDRIVE.toLowerCase():
            return 5;
        case FileProviders.ONE_DRIVE_CONSUMER.toLowerCase():
            return 1;
        case FileProviders.DROPBOX.toLowerCase():
            return 2;
        case FileProviders.BOX.toLowerCase():
            return 3;
        case FileProviders.WOPI_BOX.toLowerCase():
            return 7;
        case FileProviders.WOPI_EGNYTE.toLowerCase():
            return 8;
        case FileProviders.MAILBOX.toLowerCase():
            return 4;
        case FileProviders.FACEBOOK.toLowerCase():
            return 6;
        default:
            return null;
    }
}
