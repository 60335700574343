import type { MailboxInfo } from 'owa-client-types';
import type { OwsRequestOptions } from './OwsRequestOptions';
import type OwsMailboxRequestOptions from './OwsMailboxRequestOptions';
import assert from 'owa-assert/lib/assert';

export default function getOwsMailboxRequestOptions(
    mailboxInfo: MailboxInfo,
    owsRequestOptions?: OwsRequestOptions | undefined | null
): OwsMailboxRequestOptions {
    assert(mailboxInfo, 'MailboxInfo must not be null or undefined');
    return {
        ...owsRequestOptions,
        mailboxInfo,
    } as OwsMailboxRequestOptions;
}
