import {
    type ListViewBitFlagsMasks,
    setBitAndUpdateAccountScopeUserSettingsAndService,
} from 'owa-bit-flags/lib/utils/listViewBitFlagsUtil';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

/**
 * Persist the folder pane collapsed state
 */
export default function setFolderPaneCollapsedState(isCollapsed: boolean) {
    const viewStateConfiguration = getUserConfiguration().ViewStateConfiguration;
    if (viewStateConfiguration) {
        setBitAndUpdateAccountScopeUserSettingsAndService(
            isCollapsed /* value */,
            4,
            getGlobalSettingsAccountMailboxInfo()
        );
    }
}
