import { getStore } from 'owa-protection-store/lib/store/protectionStore';
import type { MailboxInfo } from 'owa-client-types';

export default function isEncryptionTemplateAvailable(
    mailboxInfo: MailboxInfo,
    templateIds?: string[]
): boolean {
    const protectionStore = getStore(mailboxInfo);
    const { rmsTemplates, messageClassifications } = protectionStore;

    if (templateIds && templateIds.length > 0) {
        return templateIds.some(
            curValue => rmsTemplates.has(curValue) || messageClassifications.has(curValue)
        );
    }

    return rmsTemplates.size > 0 || messageClassifications.size > 0;
}
