import type { RegisterRouteFunction } from 'owa-router';
import { lazyMountAndShowDCSecondStep } from '../lazyFunctions';

const DC_ROUTE_KEYWORD = 'domainconnect';
const DC_STEP_KEYWORD = 'setalias';

function DomainConnectRouteHandler() {
    lazyMountAndShowDCSecondStep.importAndExecute();
}

export function initializeDomainConnectRoutes(registerRoute: RegisterRouteFunction) {
    registerRoute(`/${DC_ROUTE_KEYWORD}/${DC_STEP_KEYWORD}`, DomainConnectRouteHandler);
}
