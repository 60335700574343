import { getIsDataWorkerFaulted, getIsOfflineDataFaulted } from 'owa-config';
import { getOfflineSyncDisabledReason } from './isOfflineSyncEnabled';
import type { OfflineFaultOrDisabledReason } from './OfflineFaultOrDisabledReason';

export function getOfflineFaultOrDisabledReason(): OfflineFaultOrDisabledReason | undefined {
    return getIsDataWorkerFaulted()
        ? 'DataWorkerFaulted'
        : getIsOfflineDataFaulted()
        ? 'OfflineDataFaulted'
        : getOfflineSyncDisabledReason();
}
