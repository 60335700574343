import { LazyImport, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "RibbonLightIconFont" */ './lazyIndex'),
    {
        name: 'RibbonLightIconFont',
    }
);

export const lazyIsLightIconFontEnabled = new LazyImport(lazyModule, m => m.isLightIconFontEnabled);
export const lazyRegisterLightIcon = new LazyImport(lazyModule, m => m.registerLightIcon);
