import React from 'react';
import type {
    IButtonProps,
    IRenderFunction,
    IContextualMenuProps,
    IButton,
    IKeytipProps,
} from '@fluentui/react';
/* eslint-disable @typescript-eslint/no-restricted-imports -- Only this shim should import the v8 Buttons */
import {
    DefaultButton,
    PrimaryButton,
    ActionButton,
    CommandButton,
    Icon,
    IconButton,
    KeytipData,
} from '@fluentui/react';
/* eslint-enable @typescript-eslint/no-restricted-imports */
import type {
    ButtonProps as V9ButtonProps,
    ButtonSlots,
    SlotClassNames,
} from '@fluentui/react-components';
import { Button, ToggleButton, MenuButton, mergeClasses } from '@fluentui/react-components';
import { useMergedRefs, useControllableValue } from '@fluentui/react-hooks';
import { FluentMenu } from './Menu';
import { useFluentButtonContext } from 'owa-fluent-v9-context';

export type ButtonClassNames = Partial<SlotClassNames<ButtonSlots>>;

type ButtonPropsBase = Pick<
    IButtonProps,
    | 'allowDisabledFocus'
    | 'checked'
    | 'children'
    | 'className'
    | 'defaultChecked'
    | 'disabled'
    | 'elementRef'
    | 'iconProps'
    | 'menuIconProps'
    | 'menuProps'
    | 'name'
    | 'onAfterMenuDismiss'
    | 'onClick'
    | 'onMenuClick'
    | 'onRenderIcon'
    | 'onRenderText'
    | 'onRenderMenuIcon'
    | 'persistMenu'
    | 'primary'
    | 'styles'
    | 'text'
    | 'toggle'
    | 'split'
    | 'componentRef'
    | 'keytipProps'
> &
    React.AllHTMLAttributes<
        HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement
    >;

export interface ButtonProps extends ButtonPropsBase {
    appearance?: 'primary' | 'transparent' | 'icon' | 'command' | 'subtle' | 'outline';
    as?: 'button';
    type?: 'button' | 'submit' | 'reset';
    shape?: 'rounded' | 'circular' | 'square';

    // TODO: Handle the `size` prop for the new button
    size?: never;

    styleClassNames?: ButtonClassNames;

    // Allow passing props straight through to the v9 implementation
    buttonProps?: Partial<V9ButtonProps>;
}

const ButtonMapping = {
    primary: PrimaryButton,
    transparent: ActionButton,
    icon: IconButton,
    command: CommandButton,
    subtle: IconButton,
    outline: IconButton,
} as const;

function defaultMenuIconRender(props?: ButtonPropsBase) {
    return props?.menuIconProps?.iconName ? (
        <Icon {...props.menuIconProps} />
    ) : props?.onMenuClick ? (
        { onClick: props.onMenuClick }
    ) : null;
}

export function FluentButton(props: ButtonProps) {
    if (useFluentButtonContext()) {
        return <V9ButtonShim {...props} />;
    } else {
        const { appearance, ...baseProps } = props;
        const ButtonType = appearance ? ButtonMapping[appearance] : DefaultButton;
        return <ButtonType {...baseProps} />;
    }
}

function V9ButtonShim(props: ButtonProps) {
    const {
        text,
        name,
        children,
        iconProps,
        appearance,
        primary,
        onRenderIcon,
        elementRef,
        toggle,
        checked,
        defaultChecked,
        allowDisabledFocus,
        menuProps,
        menuIconProps,
        onRenderMenuIcon = defaultMenuIconRender,
        onMenuClick,
        buttonProps,
        // This prop was a performance setting that is not supported in v9
        persistMenu,
        // Given the large difference in rendering, 'styles' cannot be effectively
        // shimmed, and will need to be fixed on a case-by-case basis at the source.
        styles,
        onAfterMenuDismiss,
        onRenderText,
        split,
        componentRef,
        className,
        styleClassNames,
        ...baseProps
    } = props;
    const buttonRef = React.useRef<HTMLAnchorElement | HTMLButtonElement | null>(null);

    // `elementRef` is of type (React.Ref<HTMLElement> | undefined), which is less specific than the required type.
    // However, Typescript doesn't know that less specific is ok in this case
    const ref = useMergedRefs(
        elementRef as unknown as React.Ref<HTMLButtonElement | HTMLAnchorElement> | undefined,
        buttonRef
    );

    const keytipProps = React.useMemo<IKeytipProps | undefined>(
        () =>
            props.keytipProps && props.menuProps
                ? { ...props.keytipProps, hasMenu: true }
                : props.keytipProps,
        [props.keytipProps, props.menuProps]
    );

    const [menuHidden, setMenuHidden] = useControllableValue(menuProps?.hidden, true);

    const toggleMenu = React.useCallback(() => setMenuHidden(value => !value), []);

    React.useImperativeHandle<IButton, IButton>(componentRef, () => ({
        openMenu() {
            setMenuHidden(false);
        },
        dismissMenu() {
            setMenuHidden(true);
        },
        focus() {
            ref.current?.focus();
        },
    }));

    const buttonClassName = mergeClasses(className, styleClassNames?.root);

    const icon = props.onRenderIcon ? (
        props.onRenderIcon(props)
    ) : props.iconProps ? (
        <Icon {...props.iconProps} className={styleClassNames?.icon} />
    ) : undefined;

    const v9ButtonProps: V9ButtonProps = {
        ...baseProps,
        className: buttonClassName,
        appearance: primary
            ? 'primary'
            : appearance === 'icon' || appearance === 'command'
            ? 'transparent'
            : appearance,
        icon,
        children: onRenderText ? onRenderText(props) : (text ?? name) || children,
        disabledFocusable: allowDisabledFocus,
        as: props.href ? 'a' : 'button',
        ...buttonProps,
        // Explicit cast due to `as: 'a'` being flagged as an error
    } as V9ButtonProps;

    const menuIcon = menuIconProps
        ? onRenderMenuIcon(
              {
                  ...props,
                  menuIconProps: {
                      ...menuIconProps,
                      onClick: (ev: React.MouseEvent<HTMLElement>) => {
                          onMenuClick?.(ev);
                          menuIconProps?.onClick?.(ev);
                      },
                  },
              },
              defaultMenuIconRender as IRenderFunction<IButtonProps>
          )
        : undefined;

    let buttonElementFn: (keytipAttributes?: any) => JSX.Element;

    if (toggle) {
        /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
         * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
         *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
        buttonElementFn = (keytipAttributes?: any) => (
            <ToggleButton
                ref={ref}
                checked={checked}
                defaultChecked={defaultChecked}
                {...v9ButtonProps}
                {...keytipAttributes}
            />
        );
    } else if (menuProps) {
        const onMenuDismissed = (contextMenuProps?: IContextualMenuProps) => {
            onAfterMenuDismiss?.();
            menuProps.onMenuDismissed?.(contextMenuProps);
        };
        /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
         * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
         *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
        buttonElementFn = (keytipAttributes?: any) => (
            <>
                <MenuButton
                    ref={ref as any}
                    menuIcon={menuIcon}
                    onClick={toggleMenu}
                    {...v9ButtonProps}
                    {...keytipAttributes}
                />
                <FluentMenu
                    target={ref}
                    {...menuProps}
                    onMenuDismissed={onMenuDismissed}
                    hidden={
                        /* To support the component ref, we need to control the hidden state */ menuHidden
                    }
                    v9Menu={
                        /* Meantime we force to use v9 menu for the buttons that we migrate */ true
                    }
                ></FluentMenu>
            </>
        );
    } else if (menuIconProps) {
        /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
         * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
         *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
        buttonElementFn = (keytipAttributes?: any) => (
            <MenuButton ref={ref} {...v9ButtonProps} {...keytipAttributes} />
        );
    } else {
        /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
         * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
         *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
        buttonElementFn = (keytipAttributes?: any) => (
            <Button ref={ref} {...v9ButtonProps} {...keytipAttributes} />
        );
    }

    return keytipProps ? (
        <KeytipData
            keytipProps={keytipProps}
            aria-describedby={props['aria-describedby']}
            disabled={props.disabled}
        >
            {buttonElementFn}
        </KeytipData>
    ) : (
        buttonElementFn()
    );
}
