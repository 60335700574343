import getFolderViewStateFromId from '../selectors/getFolderViewStateFromId';
import updateFolderViewStateOperation from '../operations/updateFolderViewState';
import folderStore from 'owa-folders';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import getDefaultSearchScopeForFolderView from '../selectors/getDefaultSearchScopeForFolderView';
import { DEFAULT_LIST_VIEW_WIDTH } from 'owa-mail-layout/lib/mailLayoutConstants';
import type MailFolderNodeViewState from '../store/schema/MailFolderNodeViewState';
import type { MailboxInfo } from 'owa-client-types';
import { updateAccountScopeUserSettings } from 'owa-session-store/lib/actions/updateAccountScopeUserSettings';
import { getMailboxInfoFromFolderId } from 'owa-mail-mailboxinfo/lib/getMailboxInfo';
import {
    type FolderViewState,
    getParseFolderViewStateWithSortConversionToString,
    getFolderViewStateString,
} from '../utils/folderViewStateUtils';
import getDefaultFolderView from '../selectors/getDefaultFolderView';

function getNewOrModifiedFolderViewState(
    folderId: string,
    userConfigurationFolderViewState: FolderViewState | null,
    folderViewStateFromStore: MailFolderNodeViewState
): FolderViewState | null {
    const sortOrder = folderViewStateFromStore.sortOrder;
    const sortColumn = folderViewStateFromStore.sortColumn;
    const isExpanded = folderViewStateFromStore.isExpanded;
    const color = folderViewStateFromStore.color;

    // If there is a userConfigurationFolderViewState, just get a version of the
    // FolderViewState based on that with the properties we want modified from the store
    if (userConfigurationFolderViewState) {
        return {
            ...userConfigurationFolderViewState,
            SortOrder: sortOrder,
            SortColumn: sortColumn,
            IsExpanded: isExpanded,
            Color: color,
        } as FolderViewState;
    }

    // If not, just try to create a new FolderViewState with the right properties
    const folder = folderStore.folderTable.get(folderId);
    let changeKey: string | undefined | null = '';
    if (folder) {
        folderId = folder.id;
        changeKey = folder.changeKey;
    }

    // This might be an non-existing folder (custom nodes, root nodes) that we render and want to save the state on the blob
    // to have properties related to the UI treatment persisted on the server
    return {
        FolderId: { Id: folderId, ChangeKey: changeKey },
        View: getDefaultFolderView(folderId),
        Width: DEFAULT_LIST_VIEW_WIDTH,
        // OWA uses 'sortColumn' is a rename of 'SortDirection'
        SortOrder: sortOrder,
        SortColumn: sortColumn,
        IsExpanded: isExpanded,
        ReadingPanePosition: getUserConfiguration().UserOptions?.GlobalReadingPanePositionReact,
        SearchScope: getDefaultSearchScopeForFolderView(folderId),
        Color: color,
    } as FolderViewState;
}

export function updateFolderViewState(
    folderId: string,
    mailboxInfoInput?: MailboxInfo,
    skipMutator?: boolean
) {
    const mailboxInfo = mailboxInfoInput ?? getMailboxInfoFromFolderId(folderId);
    updateAccountScopeUserSettings(
        mailboxInfo,
        config => {
            let isDirty: boolean = false;
            const folderViewStatesFromUserConfig =
                config.ViewStateConfiguration?.FolderViewState || [];

            const folderViewStateFromStore = getFolderViewStateFromId(folderId);

            let existingUserConfigFolderViewState: FolderViewState | null = null;
            let index: number = -1;

            for (let i = 0; i < folderViewStatesFromUserConfig.length; i++) {
                const userConfigFolderViewState: FolderViewState =
                    getParseFolderViewStateWithSortConversionToString(
                        folderViewStatesFromUserConfig[i]
                    );

                // Just save the index in case of an update
                if (userConfigFolderViewState.FolderId.Id === folderId) {
                    existingUserConfigFolderViewState = userConfigFolderViewState;
                    index = i;
                    break;
                }
            }

            const folderViewStateToSave = getNewOrModifiedFolderViewState(
                folderId,
                existingUserConfigFolderViewState,
                folderViewStateFromStore
            );

            // If no folderViewStateToSave, just return
            if (!folderViewStateToSave) {
                return;
            }

            // If found, then update if any property does not match
            if (index !== -1) {
                // This will do a shallow compare which is enough,
                // since the FolderId which is the only complex type wont ever change
                isDirty = existingUserConfigFolderViewState !== folderViewStateToSave;
                // Update if it is dirty
                if (isDirty) {
                    folderViewStatesFromUserConfig[index] =
                        getFolderViewStateString(folderViewStateToSave);
                }
            } else {
                // Need to save new view state configuration to user config
                isDirty = true;
                folderViewStatesFromUserConfig.push(
                    getFolderViewStateString(folderViewStateToSave)
                );
            }

            if (isDirty) {
                updateFolderViewStateOperation(folderViewStatesFromUserConfig, mailboxInfo);
            }
        },
        skipMutator
    );
}
