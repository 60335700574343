import type { ItemRowsQuery } from './graphql/__generated__/ItemRowsQuery.interface';
import { ItemRowsDocument } from './graphql/__generated__/ItemRowsQuery.interface';
import { query } from 'owa-apollo';
import type {
    MailboxInfoInput,
    SessionDataWithGraphQL,
    PagingInfoInput,
    ResolverContext,
} from 'owa-graph-schema';
import { mapFindItemResponseMessageToGql } from 'owa-mail-item-row-gql-mappers';
import type { SortBy } from 'owa-mail-list-store';
import {
    getGqlSortColumnFromOwsSortColumn,
    getGqlViewFilterFromOwsViewFilter,
    getGqlFocusedViewFilterFromOwsFocusedViewFilter,
    getGqlMessageSenderScreeningFilterFromOwsMessageSenderScreeningFilter,
} from 'owa-mail-list-store';
import type BaseFolderId from 'owa-service/lib/contract/BaseFolderId';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import type FolderId from 'owa-service/lib/contract/FolderId';
import type ViewFilter from 'owa-service/lib/contract/ViewFilter';
import type RequestOptions from 'owa-service/lib/RequestOptions';
import type MessageSenderScreeningFilter from 'owa-service/lib/contract/MessageSenderScreeningFilter';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import { extractFindMailFolderItemResponse } from './utils/extractFindMailFolderItemResponse';
import type { PerformanceDatapoint } from 'owa-analytics';
import { returnTopExecutingActionDatapoint } from 'owa-analytics';
import { addTimingsToGQLQuery } from 'owa-analytics-shared';

type ItemRowConnection = ItemRowsQuery['itemRows'];

/**
 * Loads item rows for list view when in Message view
 * @param baseFolderId the base folder id
 * @param startIndex the startIndex to load the table
 * @param paging the paging type
 * @param viewFilter the view filter
 * @param sortBy the sort by properties
 * @param canFolderSortByRenewTime can the folder be sorted by renew time
 * @param focusedViewFilter the focused view filter
 * @param findItemShapeName - the shape for the request
 * @param initialSessionData The initial session data which is only present during the initial bootstrap
 * @param options - optional parameters to be passed to request (method and/or headers)
 * @param categoryName - the category name
 * @param searchFolderId the search folder id
 * @returns a promise of type FindItemResponseMessage
 */
export async function getItemRows(
    baseFolderId: BaseFolderId,
    viewFilter: ViewFilter,
    sortBy: SortBy,
    canFolderSortByRenewTime: boolean,
    focusedViewFilter: FocusedViewFilter,
    findItemShapeName: string,
    mailboxInfoInput: MailboxInfoInput,
    pagingInfoInput: PagingInfoInput,
    initialSessionData: SessionDataWithGraphQL | undefined,
    options: RequestOptions,
    categoryName?: string,
    shouldIgnoreSessionData?: boolean,
    searchFolderId?: BaseFolderId,
    isTablePrefetched?: boolean,
    isReload?: boolean,
    messageSenderScreeningFilter?: MessageSenderScreeningFilter
): Promise<ItemRowConnection> {
    const folderIdString = (baseFolderId as FolderId).Id;

    const lastSwitchMailFolderDp = returnTopExecutingActionDatapoint((dp: PerformanceDatapoint) => {
        return dp.getEventName() == 'SwitchMailFolder';
    });

    // If using filterSortBy override, then ignore the session data as the sortby is different.
    if (
        initialSessionData &&
        folderIdString === folderNameToId('inbox', mailboxInfoInput) &&
        !shouldIgnoreSessionData
    ) {
        lastSwitchMailFolderDp?.addCheckpoint('SMF_GIR_Inbx');
        const sessionDataFindMailFolderItemResponse =
            extractFindMailFolderItemResponse(initialSessionData);
        if (sessionDataFindMailFolderItemResponse) {
            // Return the session data response if available instead of going to the server
            return Promise.resolve(
                mapFindItemResponseMessageToGql(sessionDataFindMailFolderItemResponse)
            );
        } else if (initialSessionData.itemRows) {
            // Offline session data provides rows in graphql schema
            return Promise.resolve(initialSessionData.itemRows);
        }
    }

    lastSwitchMailFolderDp?.addCheckpoint('SMF_GIR_GAC');
    const isOutbox: boolean = folderIdToName(folderIdString) === 'outbox';
    const resolverPolicy = isOutbox ? 'localOnly' : isReload ? 'serverOnly' : undefined;
    const result = await addTimingsToGQLQuery(
        lastSwitchMailFolderDp,
        {
            isEnabled: true,
            discardIfDefined: true,
        },
        wfContext =>
            query(ItemRowsDocument, {
                variables: {
                    folderId: folderIdString,
                    pagingInfo: pagingInfoInput,
                    searchFolderId: searchFolderId ? (searchFolderId as FolderId).Id : undefined,
                    viewFilter: getGqlViewFilterFromOwsViewFilter(viewFilter),
                    focusedViewFilter:
                        getGqlFocusedViewFilterFromOwsFocusedViewFilter(focusedViewFilter),
                    messageSenderScreeningFilter: messageSenderScreeningFilter
                        ? getGqlMessageSenderScreeningFilterFromOwsMessageSenderScreeningFilter(
                              messageSenderScreeningFilter
                          )
                        : undefined,
                    sortBy: {
                        sortColumn: getGqlSortColumnFromOwsSortColumn(sortBy.sortColumn),
                        sortDirection: sortBy.sortDirection,
                        canSortByRenewTime: canFolderSortByRenewTime,
                        isDraftsFolder: folderIdToName(folderIdString) === 'drafts',
                    },
                    category: categoryName,
                    mailboxInfo: mailboxInfoInput,
                    shapeName: findItemShapeName,
                    isScheduledFolder: folderIdToName(folderIdString) === 'scheduled',
                },
                context: {
                    requestOptions: options,
                    throttleOperation: isTablePrefetched,
                    resolverPolicy,
                    queryDeduplication: false, // Avoid waiting for a throttled prefetch
                    ...wfContext,
                } as ResolverContext,
            })
    );

    lastSwitchMailFolderDp?.addCheckpoint('SMF_GIR_APQ');
    return result.data.itemRows;
}
