import type { ApplicationSettingsReport } from 'owa-analytics-types';
import type { ApplicationSettings } from '../store/schema/ApplicationSettings';
import getStore from '../store/store';
import type { MailboxInfo } from 'owa-client-types';

export default function getApplicationSettingsReport(
    mailboxInfo?: MailboxInfo
): ApplicationSettingsReport<ApplicationSettings> | undefined {
    const store = getStore(mailboxInfo);
    return store.report;
}
