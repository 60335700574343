import type { NovaGraphQL } from '@nova/types';
import { useNovaMutation, useNovaQuery } from 'owa-apollo-hooks';
import { useMutation } from './hooks';
/* eslint-disable @typescript-eslint/no-restricted-imports --
 * Relay hook imports are allowed only in Nova GraphQL integration
 *	> 'useMutation', 'useFragment', 'useLazyLoadQuery', 'useRefetchableFragment', 'usePaginationFragment', 'useSubscription' imports are restricted from being used. */
import {
    useFragment,
    useLazyLoadQuery,
    useRefetchableFragment,
    usePaginationFragment,
    useSubscription,
} from 'react-relay';
/* eslint-enable @typescript-eslint/no-restricted-imports  */

export const novaGraphQL: NovaGraphQL = {
    useLazyLoadQuery: (
        query: any,
        variables: {
            [name: string]: unknown;
        },
        options?: Record<string, unknown>
    ) => {
        if (query.kind === 'Request') {
            /* eslint-disable-next-line react-hooks/rules-of-hooks  -- (https://aka.ms/OWALintWiki)
             * Needed to support Apollo and Relay APIs. These hooks are not optionally called during a lifetime of any component
             *	> React Hook "useLazyLoadQuery" is called conditionally . */
            const data = useLazyLoadQuery(query, variables, options);

            return { data };
        } else {
            /* eslint-disable-next-line react-hooks/rules-of-hooks  -- (https://aka.ms/OWALintWiki)
             * Needed to support Apollo and Relay APIs. These hooks are not optionally called during a lifetime of any component
             *	> React Hook "useNovaQuery" is called conditionally . */
            return useNovaQuery(query, variables, options);
        }
    },
    useMutation: (mutation: any) => {
        return mutation.kind === 'Request'
            ? /* eslint-disable-next-line react-hooks/rules-of-hooks  -- (https://aka.ms/OWALintWiki)
               * Needed to support Apollo and Relay APIs. These hooks are not optionally called during a lifetime of any component
               *	> React Hook "useMutation" is called conditionally . */
              (useMutation(mutation) as any)
            : /* eslint-disable-next-line react-hooks/rules-of-hooks  -- (https://aka.ms/OWALintWiki)
               * Needed to support Apollo and Relay APIs. These hooks are not optionally called during a lifetime of any component
               *	> React Hook "useNovaMutation" is called conditionally . */
              useNovaMutation(mutation);
    },
    useSubscription: (config: {
        subscription: any;
        variables: {
            [name: string]: unknown;
        };
        context?: {
            [name: string]: unknown;
        };
        onNext?: (response: unknown) => void;
        onError?: (error: Error) => void;
    }) => {
        if (config.subscription.kind !== 'Request') {
            return;
        }

        /* eslint-disable-next-line react-hooks/rules-of-hooks  -- (https://aka.ms/OWALintWiki)
         * Needed to support Apollo and Relay APIs. These hooks are not optionally called during a lifetime of any component
         *	> React Hook "useSubscription" is called conditionally . */
        useSubscription({
            ...config,
            cacheConfig: {
                metadata: { context: config.context },
            },
        });
    },
    useFragment: (fragment, fragmentRef) => {
        if (fragment.kind === 'Document') {
            return fragmentRef;
        }

        /* eslint-disable-next-line react-hooks/rules-of-hooks  -- (https://aka.ms/OWALintWiki)
         * Needed to support Apollo and Relay APIs. These hooks are not optionally called during a lifetime of any component
         *	> React Hook "useFragment" is called conditionally . */
        return useFragment(fragment, fragmentRef as any);
    },
    useRefetchableFragment: (fragment, fragmentRef) => {
        return useRefetchableFragment(fragment, fragmentRef as any);
    },
    usePaginationFragment: (fragment, fragmentRef) => {
        return usePaginationFragment(fragment, fragmentRef as any);
    },
};
