import shouldUseMonarchShortcuts from 'owa-hotkeys/lib/utils/shouldUseMonarchShortcuts';
import { MonarchKeyboardShortcutsMode } from 'owa-outlook-service-option-store';
import { type default as WebKeyboardShortcutsMode } from 'owa-service/lib/contract/KeyboardShortcutsMode';

/**
 * Converts the selected mode to the object key to get hotkey information.
 * @param keyboardShortcutsMode The selected user's keyboard shortcut mode
 * @return A command object key
 */
export function convertModeToKey(
    keyboardShortcutsMode: WebKeyboardShortcutsMode | MonarchKeyboardShortcutsMode
) {
    return shouldUseMonarchShortcuts()
        ? convertMonarchModeToKey(keyboardShortcutsMode as MonarchKeyboardShortcutsMode)
        : convertWebModeToKey(keyboardShortcutsMode as WebKeyboardShortcutsMode);
}

function convertWebModeToKey(keyboardShortcutsMode: WebKeyboardShortcutsMode) {
    switch (keyboardShortcutsMode) {
        case 0:
            return 'hotmail';
        case 3:
            return 'gmail';
        case 2:
            return 'yahoo';
        case 4:
            return 'owa';
        case 1:
            return null;
    }
}

function convertMonarchModeToKey(keyboardShortcutsMode: MonarchKeyboardShortcutsMode) {
    switch (keyboardShortcutsMode) {
        case MonarchKeyboardShortcutsMode.OutlookForWindows:
            return 'outlook';
        case MonarchKeyboardShortcutsMode.OutlookForWeb:
            return 'owa';
        case MonarchKeyboardShortcutsMode.Off:
            return null;
    }
}
