import { getStore } from '../store/Store';
import { mutatorAction } from 'satcheljs';

export const setShowSingleLineViewRightReadingPaneInternal = mutatorAction(
    'setShowSingleLineViewRightReadingPaneInternal',
    (useSingleLineMessageListWithRightReadingPane: boolean) => {
        getStore().useSingleLineMessageListWithRightReadingPane =
            useSingleLineMessageListWithRightReadingPane;
    }
);
