/**
 * @param v1
 * @param v2
 * @returns true if v1 is greater or equal than v2; false otherwise
 */
export const isGreaterThanOrEqualTo = (v1: string, v2: string): boolean => {
    const [majorA, minorA, patchA] = String(v1)
        .split('.')
        .map(v => Number.parseInt(v));
    const [majorB, minorB, patchB] = String(v2)
        .split('.')
        .map(v => Number.parseInt(v));

    if (majorA < majorB) {
        return false;
    } else if (minorA < minorB) {
        return false;
    }

    return patchA >= patchB;
};
