import type { StrictCopilotCapabilities } from '../../types';

export const EMPTY_COPILOT_CAPABILITIES: StrictCopilotCapabilities = {
    __typename: 'Capabilities',
    emailDraft: {
        __typename: 'EmailCapability',
        pluginId: '',
        functionName: '',
    },
    emailReplySuggestions: {
        __typename: 'EmailCapability',
        pluginId: '',
        functionName: '',
    },
    emailSummary: {
        __typename: 'EmailCapability',
        pluginId: '',
        functionName: '',
    },
};
