import React from 'react';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Avoid deprecated APIs, use portals instead of rendering to a new root
 *	> 'render' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root */
import { render } from 'owa-react-dom';
import { updateIsSuiteHeaderRendered, updateShySuiteHeaderMode } from 'owa-suite-header-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { OwaSettingsButtonID } from '../constants';
import { WebPushDiscovery } from 'owa-webpush-notifications/lib/lazyFunctions';

export function suiteRenderedCallback() {
    if (isFeatureEnabled('fwk-webPushNotification')) {
        const target = document.getElementById(OwaSettingsButtonID);
        if (target !== null) {
            const webPushDiscoveryContainer = document.createElement('div');
            webPushDiscoveryContainer.id = 'webpushdiscoverycontainer';
            document.body.appendChild(webPushDiscoveryContainer);
            render(
                <>
                    {/* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
                     * Baseline, please do not copy and paste this justification
                     *	> JSX attribute values should not contain functions created in the same scope */}
                    <WebPushDiscovery calloutTarget={() => target} />
                </>,
                webPushDiscoveryContainer
            );
        }
    }
    updateIsSuiteHeaderRendered(true);
    if (window.O365Shell) {
        window.O365Shell.Header.OnShyHeaderModeChanged(updateShySuiteHeaderMode);
    }
}
