import { LazyAction } from 'owa-bundling';
import { lazyModule } from './lazyFunctions';

// Register mutators
import './mutators/initializeRefinersScenarioStore';

// selectors
export { default as getAppliedRefiners } from './selectors/getAppliedRefiners';
export { default as getSuggestedRefiners } from './selectors/getSuggestedRefiners';
export { default as getSearchRefinersState } from './selectors/getSearchRefinersState';

// actions
export const lazyClearAppliedRefiners = new LazyAction(lazyModule, m => m.clearAppliedRefiners);
export const lazySetSuggestedRefiners = new LazyAction(lazyModule, m => m.setSuggestedRefiners);
export const lazyClearSuggestedRefiners = new LazyAction(lazyModule, m => m.clearSuggestedRefiners);

export { RefinersContainer } from './lazyFunctions';
export { QfRefinersContainer } from './lazyFunctions';
export { lazyCopyQfRefinersToAppliedRefiners } from './lazyFunctions';
