import { orchestrator } from 'satcheljs';
import { onViewModeChanged } from '../actions/onViewModeChanged';
import { onViewModeChangedInternal } from '../mutators/onViewModeChangedInternal';
import { saveCommandingViewModeOption } from 'owa-commanding-option';

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(onViewModeChanged, async actionMessage => {
    const { viewMode } = actionMessage;

    // Update store
    onViewModeChangedInternal(viewMode);

    // Update OWS'
    return saveCommandingViewModeOption(viewMode);
});
