import { mutatorAction } from 'satcheljs';
import { getStore } from '../store/Store';

export const updateClipboardWritable = mutatorAction(
    'updateClipboardWritable',
    (writable: boolean) => {
        const store = getStore();
        store.clipboardWritable = writable;
    }
);
