import { LazyModule, registerLazyOrchestrator, LazyAction } from 'owa-bundling';
import onAcquisitionsRemoteRefresh from 'owa-m365-acquisitions/lib/actions/onAcquisitionsRemoteRefresh';
import onUninstallM365Acquisition from 'owa-m365-acquisitions/lib/actions/onUninstallM365Acquisition';
import { setOptionalExperiencesEnabled } from 'owa-privacy-utils/lib/actions/actions';
import { onModuleClick } from 'owa-workloads';
import { onAppBarTrigger } from 'owa-nova-actions/lib/actions/onAppBarTrigger';
import onAccountSwitched from 'owa-mail-account-actions/lib/actions/onAccountSwitched';
import closeAddinPane from 'owa-addins-store/lib/actions/closeAddinPane';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "Addins" */ './lazyIndex'), {
    name: 'Addins',
});

registerLazyOrchestrator(closeAddinPane, lazyModule, m => m.closeAddinPaneOrchestrator);
registerLazyOrchestrator(onModuleClick, lazyModule, m => m.moduleChangeOrchestrator);
registerLazyOrchestrator(onAppBarTrigger, lazyModule, m => m.appBarChangeOrchestrator);
registerLazyOrchestrator(onAccountSwitched, lazyModule, m => m.onAccountSwitchedOrchestrator);
registerLazyOrchestrator(
    setOptionalExperiencesEnabled,
    lazyModule,
    m => m.refreshAddinsOnOCESettingChangeOrchestrator
);
registerLazyOrchestrator(
    onUninstallM365Acquisition,
    lazyModule,
    m => m.refreshAddinsOnUninstallM365AcquisitionOrchestrator
);
registerLazyOrchestrator(
    onAcquisitionsRemoteRefresh,
    lazyModule,
    m => m.refreshAddinsOnAcquisitionsRemoteRefreshOrchestrator
);

export const lazyInstallWebAddinsOnMonarchToggle = new LazyAction(
    lazyModule,
    m => m.installWebAddinsOnMonarchToggle
);

export const lazyInitializeExtensibilityContext = new LazyAction(
    lazyModule,
    m => m.initializeExtensibilityContext
);
export const lazyLaunchTranslateCommand = new LazyAction(lazyModule, m => m.launchTranslateCommand);
export const lazyInitializeAddinsForItem = new LazyAction(
    lazyModule,
    m => m.initializeAddinsForItem
);
export const lazyDeinitializeAddinsForItem = new LazyAction(
    lazyModule,
    m => m.deinitializeAddinsForItem
);
export const lazyRunContextualEvaluationAndUpdate = new LazyAction(
    lazyModule,
    m => m.runContextualEvaluationAndUpdate
);
