import { isSharedABTEnabled } from 'owa-session-store';
import { addPerfObserverCallback } from 'owa-performance-observer-manager';

const inactivityThreshold = 1 * 60 * 1000; // 1 min
const DEFAULT_EVENTS = ['mousedown', 'keydown', 'scroll', 'mousewheel'];
let lastActivityTime: number | undefined;
let shouldInitialize = true;
export function isUserIdle(): boolean {
    if (shouldInitialize) {
        shouldInitialize = false;
        if (!isSharedABTEnabled()) {
            addPerfObserverCallback('isUserIdle', 'event', entries => {
                if (entries.some(entry => DEFAULT_EVENTS.includes(entry.name))) {
                    lastActivityTime = Date.now();
                }
            });
        }
    }

    return !!lastActivityTime && Date.now() - lastActivityTime > inactivityThreshold;
}
