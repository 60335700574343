import { LazyAction, LazyModule, LazyImport } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "SearchService" */ './lazyIndex'),
    { name: 'SearchService' }
);

export const lazyLogLocalContentDataSource = new LazyAction(
    lazyModule,
    m => m.logLocalContentDataSource
);

export const lazyLogLocalContentLayout = new LazyAction(lazyModule, m => m.logLocalContentLayout);

export const lazyClearLocalContentInstrumentationCache = new LazyAction(
    lazyModule,
    m => m.clearLocalContentInstrumentationCache
);

export const lazyGetLocalContentId = new LazyImport(lazyModule, m => m.getLocalContentId);

export const lazySubstrateSearchInitOperation = new LazyImport(
    lazyModule,
    m => m.substrateSearchInitOperation
);
export const lazySubstrateSearchPostSuggestionsService = new LazyImport(
    lazyModule,
    m => m.substrateSearchPostSuggestionsService
);

export const lazyDeleteSubstrateSearchHistoryService = new LazyImport(
    lazyModule,
    m => m.deleteSubstrateSearchHistoryService
);
export const lazyExportSubstrateSearchHistoryService = new LazyImport(
    lazyModule,
    m => m.exportSubstrateSearchHistoryService
);

export const lazyCreateImplicitContactFilter = new LazyImport(
    lazyModule,
    m => m.createImplicitContactFilter
);
