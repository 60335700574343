import { logGreyError } from 'owa-analytics';
import { isClientId, convertRestIdToEwsId } from 'owa-identifiers';

// EWS encodes its IDs as base64 encoded ConcatenatedIds . The second byte of a ConcatenatedId is the IdStorageType.
// So, you can decode the base64 and look at the second byte of an EWS id. If it's a 9, then the id is an immutable id.
// If it's something else, it's a different IdStorageType.
// REST ids are similar to EWS ids, except they replace + with -.
// CAPIv3 (and maybe other services) are raw store IDs encoded as base64, sometimes with a spurious trailing 0.
// The first byte of a raw store ID is the id length.  If that is 46, then it's either an immutable id or a folder id.  If it's immutable, the 20th byte in the ID will also be 13.
export function isImmutableId(id: string): boolean {
    let rv = false;

    try {
        if (id && !isClientId(id)) {
            if (id.length % 4 === 1 && id.endsWith('0')) {
                // Some services return storeObjectIds with a spurious trailing 0. If the id is not a multiple of 4 and we see one of those 0, remove it.
                id = id.slice(0, -1);
            }

            // REST encoding uses - instead of + and _ instead of /, so replace those
            id = convertRestIdToEwsId(id);
            const arr = Uint8Array.from(atob(id), c => c.charCodeAt(0));

            if (arr[0] === 46 && arr[21] === 13) {
                // storeobjectid of immutable type
                rv = true;
            } else if (arr[0] === 0 && arr[1] === 9) {
                // concatenatedid of immutable type
                rv = true;
            }
        }
    } catch (e) {
        // unexpected since these are coming from the server, but assume a malformed id is not immutable
        logGreyError('isImmutableIdError', e, { reason: getInvalidBase64Reason(id) });
    }

    return rv;
}

export function getInvalidBase64Reason(id: string | undefined | null): string {
    let reason = 'unknown';

    try {
        if (!id) {
            reason = 'id is null or undefined';
        } else if (typeof id !== 'string') {
            reason = `id is not a string: ${typeof id}`;
        } else if (id.length % 4 !== 0) {
            reason = `id length is not a multiple of 4: ${id.length}. ${id.slice(
                0,
                4
            )}...${id.slice(-4)}`;
        } else {
            for (let i = 0; i < id.length; i++) {
                if (isNotBase64Char(id[i])) {
                    reason = `id contains invalid character at index ${i}: ${id[i]}`;
                    break;
                }
            }
        }
    } catch (e) {
        reason = `unexpected error: ${e.message}`;
    }

    return reason;
}

function isNotBase64Char(char: string): boolean {
    return !/[A-Za-z0-9+/=]/.test(char);
}
