import type OwsOptionsBase from '../store/schema/OwsOptionsBase';
import type { OwsOptionsFeatureType } from '../store/schema/OwsOptionsFeatureType';
import type { MailboxInfo } from 'owa-client-types';
import { getOwsOptionsStore } from './getOwsOptionsStore';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export default function getOptionsForFeature<T extends OwsOptionsBase>(
    feature: OwsOptionsFeatureType,
    mailboxInfo: MailboxInfo = getGlobalSettingsAccountMailboxInfo()
): T {
    return getOwsOptionsStore(mailboxInfo).options[feature] as T;
}
