import { mutator } from 'satcheljs';
import { getStore } from '../store/store';
import { type CommandBarAction } from '../store/schema/CommandBarAction';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import loadCommandBarActions from '../actions/loadCommandBarActions';
import { getCommandBarActionFromString } from '../utils/getCommandBarActionFromString';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(loadCommandBarActions, () => {
    const commandBarConfig = getCommandBarActionFromString(
        getUserConfiguration()?.ViewStateConfiguration?.MailTriageActionConfig
    );

    const indexOfOverflow = commandBarConfig.indexOf(18);
    getStore().surfaceActions = commandBarConfig.slice(0, indexOfOverflow);
    getStore().overflowActions = commandBarConfig.slice(indexOfOverflow + 1);
});
