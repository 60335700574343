import {
    zoomOutScaleUpdate,
    zoomInScaleUpdate,
    zoomScaleReset,
    showZoomCallout,
} from 'owa-custom-zoom-store/lib/mutators/zoomMutators';

export default function onCtrlZoom(event: KeyboardEvent) {
    if (event.ctrlKey) {
        // Including 'CTRL+=' because browsers and Word (and possibly more apps)
        // use it as 'CTRL+' for zooming in. Similar case for 'CTRL+_'.
        if (
            event.key == '+' ||
            event.key == '=' ||
            event.key == '-' ||
            event.key == '_' ||
            event.key == '0'
        ) {
            showZoomCallout('default');
            event.preventDefault();
        }

        if (event.key == '+' || event.key == '=') {
            zoomInScaleUpdate();
            return;
        }

        if (event.key == '-' || event.key == '_') {
            zoomOutScaleUpdate();
            return;
        }

        if (event.key == '0') {
            zoomScaleReset();
            return;
        }
    }
}
