import { mutatorAction } from 'satcheljs';
import { getConversationNodeIds } from 'owa-mail-list-item-shared';
import getListViewState from 'owa-mail-list-store/lib/selectors/getListViewState';
import {
    isFirstLevelExpanded,
    isSecondLevelExpanded,
} from 'owa-mail-list-store/lib/selectors/isConversationExpanded';
import { MailRowDataPropertyGetter } from 'owa-mail-list-store';
import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';
import type { ConversationFork } from 'owa-graph-schema';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';

export default mutatorAction(
    'updateCheckedStateOfConversationChildren',
    function updateCheckedStateOfConversationChildren(
        tableView: TableView,
        rowKey: string,
        shouldSelect: boolean
    ) {
        const listViewState = getListViewState();
        const expansionState = listViewState.expandedConversationViewState;
        if (rowKey !== expansionState.expandedRowKey || tableView.tableQuery.listViewType !== 0) {
            return;
        }

        if (shouldSelect) {
            if (isFirstLevelExpanded(rowKey)) {
                const forks: ConversationFork[] | null =
                    listViewState.expandedConversationViewState.forks;
                const forkNodeIds = [];

                if (forks) {
                    for (const fork of forks) {
                        if (fork.id) {
                            forkNodeIds.push(fork.id);
                        }
                    }
                }

                expansionState.selectedNodeIds = forkNodeIds;
            } else if (isSecondLevelExpanded(rowKey)) {
                const conversationId = MailRowDataPropertyGetter.getConversationId(
                    rowKey,
                    tableView
                );
                const conversationNodeIds = getConversationNodeIds(conversationId);
                const nodeIds = [];
                if (conversationNodeIds) {
                    for (const nodeId of conversationNodeIds) {
                        nodeIds.push(nodeId);
                    }
                }
                expansionState.selectedNodeIds = nodeIds;
            }
        } else {
            expansionState.selectedNodeIds = [];
        }
    }
);
