import getLocalFilesDragData from '../utils/LocalFilesDragData';
import type { DragData } from '../utils/dragDataUtil';
import { DEFAULT_ITEM_TYPE, FIREFOX_DOCTYPE } from './constants';

export function getDragData(dataTransfer?: DataTransfer): DragData {
    let dragData: DragData;

    if (
        dataTransfer?.files &&
        dataTransfer.files.length > 0 &&
        (dataTransfer.types.includes(FIREFOX_DOCTYPE) || dataTransfer.types.length === 1)
    ) {
        dragData = getLocalFilesDragData(dataTransfer.files);
    } else {
        // the dataTransfer object can have multiple types (if outside of OWA).
        // We should identify the non standard drag type if able.
        const dragTypes = dataTransfer?.types;
        let rawData: string | undefined = undefined;
        let nonTextTypeObtained = false;
        if (dragTypes) {
            for (var i = 0; i < dragTypes.length; i++) {
                // IE11 doesn't subclass DOMStringList as array
                const element = dragTypes[i];
                if (element != 'text') {
                    rawData = dataTransfer.getData(element);
                    nonTextTypeObtained = true;
                    break;
                }
            }
        }
        if (!nonTextTypeObtained) {
            rawData = dataTransfer?.getData('text');
        }
        try {
            dragData = JSON.parse(rawData ?? '') as DragData;
        } catch (e) {
            dragData = { itemType: DEFAULT_ITEM_TYPE };
        }
    }
    return dragData;
}
