import { HostBridge } from '@microsoft/pie.sharedbridge';
import { isPieHostBridgeInitialized } from './isPieHostBridgeInitialized';
import type { PIENamespaces } from 'owa-client-pie/lib/PIENamespaces.g';
import * as trace from 'owa-trace';

export async function isPieNamespaceGTE(
    namespaceName: PIENamespaces,
    version: number,
    throwError?: boolean
): Promise<boolean> {
    if (isPieHostBridgeInitialized()) {
        try {
            const namespaceVersion = await HostBridge.getNamespaceVersion(namespaceName);
            return namespaceVersion >= version;
        } catch (errorMessage) {
            // An exception is expected if the namespace is not present, it could also indicate
            // an unexpected. Log the reason the PIE call was rejected.
            trace.trace.warn(errorMessage);
        }
    } else {
        if (throwError) {
            throw new Error('PieUsedOutsideOfNative');
        } else {
            trace.errorThatWillCauseAlert('PieUsedOutsideOfNative');
        }
    }

    return false;
}

export async function getPieNamespaceVersion(namespaceName: PIENamespaces): Promise<number> {
    if (isPieHostBridgeInitialized()) {
        try {
            const namespaceVersion = await HostBridge.getNamespaceVersion(namespaceName);
            return namespaceVersion;
        } catch (errorMessage) {
            // An exception is expected if the namespace is not present, it could also indicate
            // an unexpected. Log the reason the PIE call was rejected.
            trace.trace.warn(errorMessage);
        }
    } else {
        trace.errorThatWillCauseAlert('PieUsedOutsideOfNative');
    }

    return -1;
}
