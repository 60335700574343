import type { SearchScenarioId } from 'owa-search-store';
import { action } from 'satcheljs';

/**
 * Action for initializing the refiners store for the given search scenario
 */
export const initializeRefinersScenarioStore = action(
    'INITIALIZE_REFINERS_SCENARIO_STORE',
    (scenarioId: SearchScenarioId) => ({
        scenarioId,
    })
);
