import type IdentityInfo from './IdentityInfo';
import type IdentityKey from './IdentityKey';
import { isPersistenceIdIndexerEnabled } from 'owa-client-types/lib/isPersistenceIdIndexerEnabled';

/**
 * Provides a string that can be used for in-memory indexer of a IdentityInfo in a map
 * or other structure where an indexer value is needed.
 * @param identityInfo IndentityInfo for which the indexer is to be returned
 * @returns IdentityKey for the IdentityInfo
 */
export default function getIdentityKeyForIdentityInfo(identityInfo: IdentityInfo): IdentityKey {
    return isPersistenceIdIndexerEnabled()
        ? (`${identityInfo.providerType}:${identityInfo.userPrincipalName}` as IdentityKey)
        : (identityInfo.userPrincipalName as IdentityKey);
}
