import { mutatorAction } from 'satcheljs';
import { getFollowButtonStateMap } from '../selectors/followButtonStoreSelectors';
import type ActionState from '../schema/ActionState';
import type { MailboxInfo } from 'owa-client-types';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

export const setFollowButtonState = mutatorAction(
    'setFollowButtonState',
    function setFollowButtonState(
        mailboxInfo: MailboxInfo,
        groupSmtpAddress: string,
        newState: ActionState
    ) {
        const groupActionState = getFollowButtonStateMap(mailboxInfo);

        groupActionState.set(transformGroupSmtp(groupSmtpAddress), newState);
    }
);

export default setFollowButtonState;
