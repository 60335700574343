import type { FolderForestNode, FavoritePersonaNode } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import { getStore as getMailSearchStore } from 'owa-mail-search';
import { getPersonaSuggestionPillHeaderData } from './getPersonaSuggestionPillHeaderData';
import type { HeaderPersonaData } from '../types/HeaderPersonaData';
import type { ActionSource } from 'owa-mail-store';

export function getHeaderPersonaData(
    isSearchTable: boolean,
    tableViewId: string,
    selectedNode: FolderForestNode
): HeaderPersonaData {
    let personaId;
    let displayName;
    let email;
    let origin;
    let mailboxType;

    const personaNode = selectedNode as FavoritePersonaNode;

    //If we are in a search table, then display the header for the personaPill, as it will have the correct info even in the fallback case
    // Otherwise, get information from the selected node.

    if (isSearchTable) {
        const previousNode = getMailSearchStore().previousNode;
        const personaPill = getPersonaSuggestionPillHeaderData(tableViewId)?.[0];

        const isPreviousNodePersona = previousNode && previousNode.type === 1;

        const pillEqualSelectedNode =
            personaPill && personaNode.mainEmailAddress === personaPill.EmailAddresses[0];

        // We are in fallback search if the previous node's SF is not populated, and the pill has the same email
        const isFavoritePersonaFallbackSearch =
            personaNode.type === 1 && !personaNode.isSearchFolderPopulated && pillEqualSelectedNode;

        personaId = undefined;
        if (personaPill) {
            displayName = personaPill.DisplayName;
            email = personaPill.EmailAddresses[0];
            mailboxType = personaPill.MailboxType;
        }

        if (isFavoritePersonaFallbackSearch) {
            origin = 'PersonaHeaderFromFallbackSearch';
        } else {
            origin =
                isPreviousNodePersona && pillEqualSelectedNode
                    ? 'PersonaHeaderFromPersonaSearch'
                    : 'PersonaHeaderFromFolderSearch';
        }
    } else {
        personaId = personaNode.personaId;
        displayName = personaNode.displayName;
        email = personaNode.mainEmailAddress;
        origin = 'PersonaHeader';
    }

    const actionSource = origin as ActionSource;
    return {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (67,9): Type 'string | undefined' is not assignable to type 'string'.
        // @ts-expect-error
        personaId,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (71,9): Type 'string | undefined' is not assignable to type 'string'.
        // @ts-expect-error
        displayName,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (75,9): Type 'string | undefined' is not assignable to type 'string'.
        // @ts-expect-error
        email,
        origin: actionSource,
        mailboxType,
    };
}
