import type { RemovedAccount } from 'owa-account-source-list-types';
import type { CoprincipalAccountSource } from '../store/schema/AccountSourceList';

import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';
import getAccountsRemovedThisSession from '../selectors/getAccountsRemovedThisSession';
import getCoprincipalAccounts, {
    StartupFilter,
    ContractsSupportedFilter,
} from '../selectors/getCoprincipalAccounts';
import getCoprincipalAccountForMailboxInfo from './getCoprincipalAccountForMailboxInfo';
import isAccountSourceListStoreInitialized from './isAccountSourceListStoreInitialized';
import isGlobalSettingsMailbox from './isGlobalSettingsMailbox';

/**
 * Search through the removed accounts looking for any that match the passed in MailboxInfo
 * @param mailboxInfo Specifies the MailboxInfo to check
 * @returns String of indexes of removed accounts that match the MailboxInfo or undefine if the
 * MailboxInfo does not match any removed accounts
 */
function getMatchingRemovedAccountDiagnosticData(mailboxInfo?: MailboxInfo): string | undefined {
    if (!mailboxInfo) {
        // no mailbox info it does not match anything
        return undefined;
    }

    const indexerWithPii = getIndexerValueForMailboxInfo(mailboxInfo);
    const removedAccounts = getAccountsRemovedThisSession();
    const matchingRemoved = removedAccounts
        .map((removedAccount: RemovedAccount, index: number) => {
            if (
                !!indexerWithPii &&
                getIndexerValueForMailboxInfo(removedAccount.mailboxInfo) === indexerWithPii
            ) {
                return index.toString();
            }

            return undefined;
        })
        .filter((value: string | undefined) => value !== undefined);

    if (matchingRemoved.length > 0) {
        return matchingRemoved.join();
    }

    // The indexer value does not match any removed accounts
    return undefined;
}

// Constants used to describe the matching values have different cases
const MisMatchCase = 'mmc';

/**
 * Determines if the supplied id (either an SMTP or user identity) matches the SMTP, user identity,
 * or any of the aliases of the account
 * @param id Specifies the id to check
 * @param idType Specifies the type of id
 * @param account Account ot check
 * @returns Matching values in the account or undefined if there were no matches
 */
function checkForMatchingIdAddresses(
    id: string,
    idType: string,
    account: CoprincipalAccountSource
): string | undefined {
    const lowerCaseId = id.toLowerCase();
    const matches: string[] = [];

    // look for matches to the SMTP address
    if (id === account.mailboxInfo.mailboxSmtpAddress) {
        matches.push(`${idType}-smtp`);
    } else if (lowerCaseId === account.mailboxInfo.mailboxSmtpAddress.toLowerCase()) {
        matches.push(`${idType}-smtp(${MisMatchCase})`);
    }

    if (id === account.mailboxInfo.userIdentity) {
        matches.push(`${idType}-uid`);
    } else if (lowerCaseId === account.mailboxInfo.userIdentity.toLowerCase()) {
        matches.push(`${idType}-uid(${MisMatchCase})`);
    }

    if (account.aliases.includes(id)) {
        matches.push(`${idType}-alias`);
    }

    return matches.length > 0 ? matches.join() : undefined;
}

/**
 * Checks the accounts for matching SMTP or user identity
 * @param mailboxInfo Specifies the MailboxInfo to check
 * @returns String describing the matching accounts or undefined if no matches are found
 */
function getMatchingAccounts(mailboxInfo?: MailboxInfo): string | undefined {
    if (!mailboxInfo) {
        // no mailbox info it does not match anything
        return undefined;
    }
    const allAccounts = getCoprincipalAccounts(
        StartupFilter.StartingOrCompleteOrError,
        ContractsSupportedFilter.Any
    );
    const matchingAccounts = allAccounts
        .map((coprincipalAccount: CoprincipalAccountSource, index: number) => {
            const smtpMatches = !!mailboxInfo
                ? checkForMatchingIdAddresses(
                      mailboxInfo.mailboxSmtpAddress,
                      'SMTP',
                      coprincipalAccount
                  )
                : undefined;

            const uidMathes = !!mailboxInfo
                ? checkForMatchingIdAddresses(mailboxInfo.userIdentity, 'UID', coprincipalAccount)
                : undefined;

            const matches = [smtpMatches, uidMathes].filter(
                (value: string | undefined) => value !== undefined
            );

            return matches.length > 0 ? `${index}:${matches.join()}` : undefined;
        })
        .filter((value: string | undefined) => value !== undefined);

    if (matchingAccounts.length > 0) {
        // found matching SMTP or user identity values
        return matchingAccounts.join();
    }

    // did not find any matching accounts
    return undefined;
}

/**
 * Collects diagnostic data for the MailboxInfo passed in
 * @param mailboxInfo Mailbox infor for which we get related account diagnostic data
 * @returns Map of diagnostic data for the account
 */
export default function getAccountDiagnosticDataForMailboxInfo(mailboxInfo?: MailboxInfo) {
    // Information about the account source list
    const isSourceListInitialized = isAccountSourceListStoreInitialized();

    // Information about the account that would be associated with the MailboxInfo
    const account = !!mailboxInfo ? getCoprincipalAccountForMailboxInfo(mailboxInfo) : undefined;
    const hasAccount = !!account;
    const bootState = account?.bootState;
    const isGlobalSettingsAccount =
        !!mailboxInfo && isSourceListInitialized ? isGlobalSettingsMailbox(mailboxInfo) : undefined;
    const isLicensingAccount = mailboxInfo?.mailboxRank === 'Licensing';

    // infomation about matching removed accounts
    const matchingRemovedAccounts = getMatchingRemovedAccountDiagnosticData(mailboxInfo);
    const isRemovedAccount = !!matchingRemovedAccounts;

    // information about matching smtp and user identity values
    const matchingAccountEmails = getMatchingAccounts(mailboxInfo);
    const isMatchingAccountEmails = !!matchingAccountEmails;

    const accountsInStore = getCoprincipalAccounts(
        StartupFilter.StartingOrCompleteOrError,
        ContractsSupportedFilter.Any
    )
        .map((value: CoprincipalAccountSource, index: number) => {
            return `${index}: ${value.sourceType} ${value.bootState}`;
        })
        .join();

    return {
        hasAccount,
        bootState,
        isGlobalSettingsAccount,
        isRemovedAccount,
        matchingRemovedAccounts,
        isMatchingAccountEmails,
        matchingAccountEmails,
        accountsInStore,
        isLicensingAccount,
    };
}
