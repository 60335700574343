import { action } from 'satcheljs';
import type { ClientItemId } from 'owa-client-ids';
import type { FullComposeEntrySourceType } from 'owa-calendar-helpers-types/lib/ComposeMetricTypes';

export const launchCalendarFullComposeToDuplicate = action(
    'launchCalendarFullComposeToDuplicate',
    (itemId: ClientItemId, entrySource: FullComposeEntrySourceType) => ({
        itemId,
        entrySource,
    })
);
