import { action } from 'satcheljs';
import type { OpenPstScenario } from '../store/schema/OpenPstFileExperienceStore';
import type { AccountConfigDialogLaunchOrigin } from 'owa-account-config-shared';

/**
 * Dispatch this action to render the PST account configuration experience
 */
export const configureNewPstAccount = action(
    'configureNewPstAccount',
    (targetWindow: Window, entryPoint: AccountConfigDialogLaunchOrigin) => ({
        scenario: 'ConfigureNewPstAccount' as OpenPstScenario,
        targetWindow,
        entryPoint,
    })
);
