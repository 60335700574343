import type { MailboxInfo } from 'owa-client-types';
import type { AccountSourceType, LicensingMailboxInfo } from 'owa-account-source-list-types';
import providerToSource from 'owa-account-source-list-types/lib/utils/providerToSource';
import type IdentityInfo from './IdentityInfo';
import { isLicensingMailboxInfo } from 'owa-account-source-list-types/lib/utils/isLicensingMailboxInfo';

/**
 * Type for the function that will get the source type for the specified mailboxInfo
 */
type GetSourceTypeForMailboxInfoFunction = (
    mailboxInfo: MailboxInfo
) => AccountSourceType | undefined;

/**
 * Stores the function that will get the source type for the specified mailboxInfo
 */
let getSourceTypeForMailboxInfoFunc: GetSourceTypeForMailboxInfoFunction | undefined = undefined;

/**
 * Sets the function that will get the source type for the specified mailboxInfo
 * @param func Specifies the function that will get the source type for the specified mailboxInfo
 */
export function setSourceTypeForMailboxInfoFunc(func: GetSourceTypeForMailboxInfoFunction) {
    getSourceTypeForMailboxInfoFunc = func;
}

/**
 * Attempts to get the IdentityInfo associated with the specified MailboxInfo
 * @param mailboxInfo Specifies the MailboxInfo for which the Identity info is to be obtained
 * @returns IdentityInfo or undefined
 */
export default function getIdentityInfoForMailboxInfo(
    mailboxInfo: MailboxInfo
): IdentityInfo | undefined {
    if (mailboxInfo.type === 'UserPrincipalName') {
        if (isLicensingMailboxInfo(mailboxInfo)) {
            return getIdentityInfoForLicensingMailboxInfo(mailboxInfo);
        }

        throw new Error('UserPrincipalNameMustBeLicensingMailboxInfo');
    } else if (getSourceTypeForMailboxInfoFunc) {
        const sourceType = getSourceTypeForMailboxInfoFunc(mailboxInfo);
        if (sourceType) {
            return {
                providerType: sourceType,
                userPrincipalName: mailboxInfo.userIdentity,
            };
        } else if (!!mailboxInfo.mailboxProvider) {
            // If the MailboxInfo contains a mailboxProvider, then we can use that to determine the source type. This is
            // only expected to be used in the case of adding a new account in Monarch.
            return {
                providerType: providerToSource(mailboxInfo.mailboxProvider),
                userPrincipalName: mailboxInfo.userIdentity,
            };
        }
    }

    return undefined;
}

export function getIdentityInfoForLicensingMailboxInfo(
    mailboxInfo: LicensingMailboxInfo
): IdentityInfo {
    return {
        providerType: providerToSource(mailboxInfo.mailboxProvider),
        userPrincipalName: mailboxInfo.userIdentity,
    };
}
