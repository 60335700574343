import { createStore } from 'satcheljs';

interface CopilotInboxStore {
    pendingCopilotInboxRequests: string[]; // Array of rowIds for manually triggered Copilot Inbox requests
}

const defaultStore: CopilotInboxStore = {
    pendingCopilotInboxRequests: [],
};

const initialStore = { ...defaultStore };
const store = createStore<CopilotInboxStore>('copilotInboxStore', initialStore)();

export default store;
export const getStore = () => store;
