import type { PrivacySettingsWithMetaData } from '../store/schema/PrivacySettings';
import { DiagnosticDataLevel } from '../store/schema/PrivacySettings';
import { logUsageFromAccounts } from 'owa-account-analytics';
import {
    OPTIONAL_CONNECTED_EXPERIENCES_NOTICE_LATEST_VERSION,
    REQUIRED_DIAGNOSTICS_DATA_NOTICE_LATEST_VERSION,
    DIAGNOSTIC_DATA_CONSENT_LATEST_VERSION,
    CONNECTED_EXPERIENCES_NOTICE_LATEST_VERSION,
} from './constants';
import { isPrivacyRepromptV2Enabled, isPrivacyRoamingEnabled } from './isPrivacyRoamingEnabled';

export type ServerDiagnosticDataLevel = typeof ServerDiagnosticDataValues[number];
const ServerDiagnosticDataValues = [
    'None',
    'RequiredOnly',
    'RequiredAndOptional',
    'Unset',
] as const;

type ServerPrivacySettings = {
    enableContentAnalyzingExperiences: boolean;
    enableContentDownloadingExperiences: boolean;
    enableDiagnosticData: ServerDiagnosticDataLevel;
    enableOptionalConnectedExperiences: boolean;
    optionalConnectedExperiencesNoticeVersion: number | undefined;
    requiredDiagnosticDataNoticeVersion: number | undefined;
    optionalDiagnosticDataConsentVersion: number | undefined;
    connectedExperiencesNoticeVersion: number | undefined;
};

const isServerPrivacySettings = (value: any): value is ServerPrivacySettings =>
    value &&
    isBoolean(value.enableContentAnalyzingExperiences) &&
    isBoolean(value.enableContentDownloadingExperiences) &&
    ServerDiagnosticDataValues.includes(value.enableDiagnosticData) &&
    isBoolean(value.enableOptionalConnectedExperiences) &&
    isValidNoticeVersion(value.optionalConnectedExperiencesNoticeVersion) &&
    isValidNoticeVersion(value.requiredDiagnosticDataNoticeVersion) &&
    isValidNoticeVersion(value.optionalDiagnosticDataConsentVersion) &&
    isValidNoticeVersion(value.connectedExperiencesNoticeVersion);

// When the flight is disabled, the server returns undefined for all the notice versions.
const isValidNoticeVersion = (value: any) => (isPrivacyRoamingEnabled() ? isNumber(value) : true);

const isNumber = (value: any) => typeof value === 'number';
const isBoolean = (value: any) => typeof value === 'boolean';

const serverToClientDiagnosticsDataLevel = (
    serverDiagnostics: string | undefined
): DiagnosticDataLevel => {
    switch (serverDiagnostics) {
        case 'None':
            return DiagnosticDataLevel.None;
        case 'RequiredOnly':
            return DiagnosticDataLevel.RequiredOnly;
        case 'RequiredAndOptional':
            return DiagnosticDataLevel.RequiredAndOptional;
        case 'Unset':
        default:
            return DiagnosticDataLevel.Unset;
    }
};

function objectToPropertyTypes(obj: any) {
    if (typeof obj !== 'object') {
        return typeof obj;
    }

    if (obj === null) {
        return 'null';
    }

    if (Object.keys(obj).length === 0) {
        return {};
    }

    const propertyTypes: {
        [key: string]: any;
    } = {};

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    Object.keys(obj).forEach(key => {
        propertyTypes[key] = objectToPropertyTypes(obj[key]);
    });

    return propertyTypes;
}

export const parseServerValue = (
    serverResponse: any,
    callerForLogging?: string
): PrivacySettingsWithMetaData => {
    const serverSettings = serverResponse?.settings;

    if (!isServerPrivacySettings(serverSettings)) {
        let objectPropertyTypes = '';

        try {
            objectPropertyTypes = JSON.stringify(objectToPropertyTypes(serverSettings));
        } catch (e) {
            objectPropertyTypes = 'Object_with_cycles';
        }

        logUsageFromAccounts('NonParsablePrivacySettingsObject', {
            serverSettings: objectPropertyTypes,
            callerForLogging,
            isPrivacyRoamingEnabled: isPrivacyRoamingEnabled(),
            isPrivacyRepromptV2Enabled: isPrivacyRepromptV2Enabled(),
        });
    }

    return {
        analyzeContentEnabled: valueOrFallbackToBoolean(
            serverSettings?.enableContentAnalyzingExperiences
        ),
        downloadContentEnabled: valueOrFallbackToBoolean(
            serverSettings?.enableContentDownloadingExperiences
        ),
        diagnosticDataLevel: serverToClientDiagnosticsDataLevel(
            serverSettings?.enableDiagnosticData
        ),
        optionalExperiencesEnabled: valueOrFallbackToBoolean(
            serverSettings?.enableOptionalConnectedExperiences
        ),
        optionalConnectedExperiencesNoticeVersion: valueOrFallback(
            serverSettings?.optionalConnectedExperiencesNoticeVersion,
            OPTIONAL_CONNECTED_EXPERIENCES_NOTICE_LATEST_VERSION
        ),
        requiredDiagnosticDataNoticeVersion: valueOrFallback(
            serverSettings?.requiredDiagnosticDataNoticeVersion,
            REQUIRED_DIAGNOSTICS_DATA_NOTICE_LATEST_VERSION
        ),
        optionalDiagnosticDataConsentVersion: valueOrFallback(
            serverSettings?.optionalDiagnosticDataConsentVersion,
            DIAGNOSTIC_DATA_CONSENT_LATEST_VERSION
        ),
        connectedExperiencesNoticeVersion: valueOrFallback(
            serverSettings?.connectedExperiencesNoticeVersion,
            CONNECTED_EXPERIENCES_NOTICE_LATEST_VERSION
        ),
        rawAnalyzeContentEnabled: serverSettings?.enableContentAnalyzingExperiences,
        rawDownloadContentEnabled: serverSettings?.enableContentDownloadingExperiences,
        rawDiagnosticDataLevel: serverSettings?.enableDiagnosticData,
        rawOptionalExperiencesEnabled: serverSettings?.enableOptionalConnectedExperiences,
        rawOptionalConnectedExperiencesNoticeVersion:
            serverSettings?.optionalConnectedExperiencesNoticeVersion,
        rawRequiredDiagnosticDataNoticeVersion: serverSettings?.requiredDiagnosticDataNoticeVersion,
        rawOptionalDiagnosticDataConsentVersion:
            serverSettings?.optionalDiagnosticDataConsentVersion,
        rawConnectedExperiencesNoticeVersion: serverSettings?.connectedExperiencesNoticeVersion,
    };
};

const valueOrFallbackToBoolean = (value: any): boolean => {
    // Initially, when the privacy project started, we were introducing
    // a way to disable features when the users disable some privacy settings.
    // We chose to default to enable features when the server returns undefined
    // since the feature was new and we did not want to cause disruptions.
    // Today, we want to default to disable features when the server returns undefined
    // since the feature is now established and we want to default
    // to the side of privacy.
    const fallbackValue = isPrivacyRepromptV2Enabled() ? false : true;

    return isBoolean(value) ? value : fallbackValue;
};

const valueOrFallback = (value: any, fallback: number) => {
    // When the isPrivacyRoamingEnabled() === false,
    // the server returns undefined for all the notice versions.
    // but in that case we want to default to show the privacy notices instead of hiding them.
    const fallbackValue = isPrivacyRoamingEnabled() ? fallback : 0;

    return isNumber(value) ? value : fallbackValue;
};
