import type { MailboxInfo, FirstPartyMailboxProvider } from 'owa-client-types';
import type { LicensingMailboxInfo } from '../LicensingMailboxInfo';
import { AccountSourceType } from '../AccountSourceType';

function isValidFirstPartySourceType(sourceType?: AccountSourceType): boolean {
    return (
        !!sourceType &&
        (sourceType === AccountSourceType.Office365 ||
            sourceType === AccountSourceType.OutlookDotCom)
    );
}

const Office365FirstPartyProvider: FirstPartyMailboxProvider = 'Office365';
const OutlookDotComFirstPartyProvider: FirstPartyMailboxProvider = 'Outlook';
function isValidFirstPartyMailboxProvider(mailboxProvider?: FirstPartyMailboxProvider): boolean {
    return (
        !!mailboxProvider &&
        (mailboxProvider === Office365FirstPartyProvider ||
            mailboxProvider === OutlookDotComFirstPartyProvider)
    );
}

/**
 * Type predicate for the LicensingMailboxInfo type
 */
export function isLicensingMailboxInfo(
    mailboxInfo: MailboxInfo
): mailboxInfo is LicensingMailboxInfo {
    const asLicensingMailboxInfo = mailboxInfo as LicensingMailboxInfo;

    return (
        mailboxInfo.type === 'UserPrincipalName' &&
        isValidFirstPartySourceType(asLicensingMailboxInfo.sourceType) &&
        isValidFirstPartyMailboxProvider(asLicensingMailboxInfo.mailboxProvider) &&
        asLicensingMailboxInfo.mailboxRank === 'Licensing'
    );
}
