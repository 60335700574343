import type { QuickStepsConfig } from '../store/schema/QuickStepsConfig';
import { CURRENT_QUICKSTEP_VERSION } from '../constants';

export const defaultConfig: QuickStepsConfig = {
    version: CURRENT_QUICKSTEP_VERSION,
    // TODO 145566 add default quick steps
    quickSteps: [],
    errorState: false,
    runOnce: false,
};
