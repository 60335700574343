import { hideNativeContextMenu } from '../actions/nativeContextMenuActions';

interface ContextMenuEvent extends Event {
    sourceCapabilities: {
        firesTouchEvents: boolean;
    };
}

export const onNativeContextMenuDismiss = (
    ev?: Event | React.MouseEvent | React.KeyboardEvent,
    dismissAll?: boolean
): void => {
    if (ev) {
        const contextMenuEvent = ev as ContextMenuEvent;
        /**
         * If the sourceCapabilities property is truthy, it means that the context menu was triggered by an user
         * touch event so it can be dismissed
         **/
        if (contextMenuEvent.sourceCapabilities || dismissAll) {
            hideNativeContextMenu();
        }
    }
};

export const onNativeContextMenuItemClick = (): boolean | void => {
    // Close the context menu when a menu item is clicked
    hideNativeContextMenu();
};
