import { lazyGetForwardSections } from 'owa-mail-ribbon-sections/lib/sections';

import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onForwardMenuClicked = (props: ReadOnlyRibbonControlDefProps) => {
    lazyGetForwardSections.import().then(getForwardSections => {
        setMenuDefinition('ForwardMenuDefinitionStore', props.projectionTabId, () => {
            return {
                sections: getForwardSections(props),
            };
        });
    });
};
