import getDeeplinkUrl from './getDeeplinkUrl';
import { getReadingPaneRouteForPopoutV2 } from './getReadingPaneRouteForPopoutV2';
import { getOrigin } from 'owa-url/lib/getOrigin';
import joinPath from 'owa-url/lib/joinPath';
import type { ClientItemId } from 'owa-client-ids';
import { type vDirType } from 'owa-vdir-types';

export function getMailReadingPaneDeeplinkUrl(
    itemId: ClientItemId | undefined,
    urlParams?: Record<string, string>,
    isStandalone?: boolean
): string | null {
    if (itemId?.Id) {
        const deeplinkRoute = getDeeplinkUrl(
            'mail',
            getReadingPaneRouteForPopoutV2(itemId.mailboxInfo, itemId.Id),
            {
                isStandalone: !!isStandalone,
                urlParameters: urlParams,
            }
        );

        return joinPath(getOrigin(), deeplinkRoute);
    }

    return null;
}
