import { createStore } from 'satcheljs';
import type CopilotPrioritizeSettings from '../types/CopilotPrioritizeSettings';
import type CopilotStyleSettings from '../types/CopilotStyleSettings';
import { ObservableMap } from 'mobx';

interface CopilotPrioritizeSettingsStore {
    isInitialized: boolean;
    settings: CopilotPrioritizeSettings;
}

interface CopilotStyleSettingsStore {
    isInitialized: boolean;
    settings: CopilotStyleSettings;
}

interface CopilotSettingsStoreMap {
    copilotPrioritizeSettingsStoreMap: ObservableMap<string, CopilotPrioritizeSettingsStore>;
    copilotStyleSettingsStoreMap: ObservableMap<string, CopilotStyleSettingsStore>;
}

const store = createStore<CopilotSettingsStoreMap>('copilotSettingsStore', {
    copilotPrioritizeSettingsStoreMap: new ObservableMap(),
    copilotStyleSettingsStoreMap: new ObservableMap(),
})();

const getStore = () => store;
export default getStore;
