import { LazyModule, createLazyComponent } from 'owa-bundling';

const lazyModules = new LazyModule(
    () => import(/* webpackChunkName: "OwaCopilotChat" */ './lazyIndex'),
    { name: 'OwaCopilotChat' }
);

// Export components
export const ChatCopilotPane = createLazyComponent(lazyModules, m => m.ChatCopilotPane);
export const ChatCopilotPaneHeader = createLazyComponent(lazyModules, m => m.ChatCopilotPaneHeader);
export const ChatCopilotSuiteHeaderButton = createLazyComponent(
    lazyModules,
    m => m.ChatCopilotSuiteHeaderButton
);
