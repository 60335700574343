import { writeQuery } from 'owa-apollo';
import { hasMessageExtension } from 'owa-m365-acquisitions/lib/utils/hasMessageExtension';
import { hasExchangeAddIn } from 'owa-m365-acquisitions/lib/utils/hasExchangeAddIn';
import { hasExtensionAddIn } from 'owa-m365-acquisitions/lib/utils/hasExtensionAddIn';
import { LaunchPageAppId } from 'owa-m365-acquisitions/lib/data/M365AppId';
import { errorThatWillCauseAlert } from 'owa-trace';

import { convertAcquisitionToM365Application } from './convertAcquisitionToM365Application';
import { MessageExtensionsFlyoutDocument } from '../graphql/query/__generated__/MessageExtensionsData.interface';
import {
    getMessageExtensionSurfaceArgs,
    MessageExtensionFlyoutTypes,
} from '../utils/getMessageExtensionSurfaceArgs';
import { NovaControl } from '../types';

import type { M365Acquisition } from 'owa-graph-schema';
import type { DataProxy } from '@apollo/client';
import type {
    StrictAcquisition,
    StrictM365AcquisitionsQuery,
} from 'owa-m365-acquisitions/lib/types';
import type {
    StrictMessageExtensionsFlyoutQuery,
    StrictM365Application,
    StrictM365ApplicationEdge,
} from '../types';
import type { MessageExtensionsFlyoutQueryVariables } from '../graphql/query/__generated__/MessageExtensionsData.interface';
import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { getAddInAppAcquisitionLink } from '../data/getAddinAppAcquisitionLink';

type MessageExtensionWriteQueryArguments = Omit<
    DataProxy.WriteQueryOptions<
        StrictMessageExtensionsFlyoutQuery,
        MessageExtensionsFlyoutQueryVariables
    >,
    'query'
>;

const convertToM365Application = (acquisition: StrictAcquisition, mailboxInfo?: MailboxInfo) =>
    convertAcquisitionToM365Application(
        acquisition,
        NovaControl.MessageExtension,
        undefined,
        mailboxInfo
    );

/**
 * Define a filter of which acquisitions should be included in the
 * Message Extension flyout i.e., AddIns and MessageExtensions MetaOS apps in either
 * reading or compose pane.
 * @param  {StrictM365Acquisition} acquisition
 * @returns boolean
 */
function onlyMailFlyoutApps(acquisition: M365Acquisition | StrictAcquisition): boolean {
    if (
        acquisition.titleId === LaunchPageAppId.AppStore ||
        acquisition.titleId === LaunchPageAppId.AppStoreAbout ||
        acquisition.titleId === LaunchPageAppId.AppStoreModal
    ) {
        // Don't render AppStore tiles – they are displayed through AppAcquisitionLink.
        return false;
    }

    return (
        hasExtensionAddIn(acquisition) ||
        hasExchangeAddIn(acquisition) ||
        hasMessageExtension(acquisition)
    );
}

/**
 * Converts the results of a `m365Acquisitions` query into `m365MessageExtensions` query, which feeds
 * the Message Extension Flyout control.
 * @param queryResult
 * @returns
 */
export async function m365AcquisitionsToM365MessageExtensions(
    queryResult: StrictM365AcquisitionsQuery,
    mailboxInfo?: MailboxInfo
): Promise<MessageExtensionWriteQueryArguments[]> {
    const writeQueryArgs: MessageExtensionWriteQueryArguments[] = [];
    const MessageExtensionsQueryArgumentMap = await getMessageExtensionSurfaceArgs(mailboxInfo);
    const mailFlyoutApps: StrictAcquisition[] =
        queryResult['m365Acquisitions'].edges.filter(onlyMailFlyoutApps);

    // Create a write query for each possible argument of m365MessageExtensions,
    // i.e., for each MessageExtensionType
    for (let i = 0; i < MessageExtensionFlyoutTypes.length; i++) {
        const messageExtensionType = MessageExtensionFlyoutTypes[i];
        const { appAcquisitionLinks, appFilter } =
            MessageExtensionsQueryArgumentMap[messageExtensionType];

        // Write query for each mailbox (e.g. 'ReadMessageExtensions:user@outlook.com')
        const queryMessageExtensionType: string = mailboxInfo
            ? `${messageExtensionType}:${getIndexerValueForMailboxInfo(mailboxInfo)}`
            : messageExtensionType;

        const m365Apps: StrictM365Application[] = await Promise.all(
            mailFlyoutApps.filter(appFilter).map(app => convertToM365Application(app, mailboxInfo))
        );
        const flyoutSurfaceEdges: StrictM365ApplicationEdge[] = m365Apps.map(app => ({
            node: app,
            __typename: 'M365ApplicationEdge',
        }));

        const writeQueryArg: MessageExtensionWriteQueryArguments = {
            data: {
                __typename: 'Query',
                m365MessageExtensions: {
                    __typename: 'M365ApplicationConnection',
                    appAcquisitionLinks: !isConsumer(undefined, mailboxInfo)
                        ? appAcquisitionLinks
                        : [getAddInAppAcquisitionLink()],
                    edges: flyoutSurfaceEdges,
                },
            },
            variables: {
                messageExtensionType: queryMessageExtensionType,
            },
        };
        writeQueryArgs.push(writeQueryArg);
    }

    return writeQueryArgs;
}

/**
 * Note: Function below is used as a transitionary step, gated by a feature flag, to the new app catalog
 * schema.
 *
 * Converts a list of acquisitions cache references to its underlying
 * object value, converts to AppDefinition, then to M365Application type and writes to the `m365MessageExtensions`
 * query in Apollo cache.
 * @param  {InMemoryCache} cache
 * @param  {Reference[]} acquisitionRefs
 */
export async function writeM365MessageExtensionsQuery(
    acquisitions: M365Acquisition[],
    mailboxInfo?: MailboxInfo
) {
    if (!acquisitions || (acquisitions?.length ?? 0) === 0) {
        return;
    }
    try {
        const writeQueryArgs = await m365AcquisitionsToM365MessageExtensions(
            {
                __typename: 'Query',
                m365Acquisitions: {
                    __typename: 'M365AcquisitionsQueryResult',
                    edges: acquisitions as StrictAcquisition[],
                    nextInterval: -1,
                },
            },
            mailboxInfo
        );
        /* To effectively write to m365MessageExtensions' cache entry, we need
           to write to all possible argument values for a query's input */
        writeQueryArgs.forEach(writeQueryArg => {
            writeQuery(MessageExtensionsFlyoutDocument, writeQueryArg);
        });
    } catch (error) {
        errorThatWillCauseAlert('AppCatalogCache_WriteMessageExtensionsQuery_Error', error);
    }
}
