import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import type { TokenError } from '../schema/TokenError';
import type { AuthTokenResponse } from '../schema/AuthTokenResponse';

/**
 * Returns an response of type AuthTokenResponse that has the access token issued
 * and a suberrorcode if token fetching failed due to AdalException. Some of the
 * possible values are: interaction_required, login_required etc. (these are an indication
 * that AAD did not issue a token to OWA until user/ partner teams remediate it)
 */
export function getAuthTokenResponse(tokenResponse?: TokenResponse): AuthTokenResponse {
    const authTokenError: TokenError = {
        subErrorCode: tokenResponse?.SubErrorCode,
    };

    const authTokenResponse: AuthTokenResponse = {
        error: authTokenError,
        accessToken: tokenResponse?.AccessToken,
        accessTokenExpiry: tokenResponse?.AccessTokenExpiry,
        expiresIn: tokenResponse?.ExpiresIn,
    };

    return authTokenResponse;
}
