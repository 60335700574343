import { orchestrator } from 'satcheljs';
import { onAvailableWidthBucketChanged } from '../actions/onAvailableWidthBucketChanged';
import { setIsFlexPaneShown } from 'owa-suite-header-store';
import LayoutChangeSource from '../store/schema/LayoutChangeSource';
import calculateAvailableWidthBucket from '../utils/calculateAvailableWidthBucket';
import { updateFlexPaneInternalWidth } from '../utils/updateFlexPaneInternalWidth';
import { getAreDisplayAdsEnabled } from '../selectors/getAreDisplayAdsEnabled';
import { setBrowserWidthBucket } from '../actions/setAvailableHeightAndWidthBuckets';

orchestrator(setIsFlexPaneShown, actionMessage => {
    const newAvailableWidthBucket = calculateAvailableWidthBucket(
        false /* skipCache */,
        getAreDisplayAdsEnabled(),
        actionMessage.isShown
    );
    setBrowserWidthBucket(newAvailableWidthBucket);
    updateFlexPaneInternalWidth();
    onAvailableWidthBucketChanged(
        newAvailableWidthBucket,
        LayoutChangeSource.OwaSuiteHeaderFlexPane
    );
});
