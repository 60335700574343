import type { OwaDateTimeLocalizedStringResourceId } from 'owa-datetime-formatters-core';
import { getLocalizedString } from 'owa-datetime-formatters-core';
import { format } from 'owa-localize';

import { getDateFormat } from 'owa-datetime-store';
import { getDateSpecifiersOrder } from './getDateSpecifiersOrder';
import { getDaySpecifier } from './getDaySpecifier';

import type { OwaDate, GlobalCalendarLocale } from 'owa-datetime';
import { getYear, getMonth } from 'owa-datetime';
import formatDate from './formatDate';
import { formatIntlDate, getIntlMonth, getIntlYear } from './intlFormatters';

/** Formats the date interval of the given display dates. */
export default function formatDateInterval(
    start: OwaDate,
    end: OwaDate,
    weekNumber?: number,
    locale?: GlobalCalendarLocale
): string {
    const dateFormat = getDateFormat();

    // Account for then the dateFormat and strings are not yet initialized
    // by returning empty string so we don't flash bad strings while OWA is loading.
    if (!dateFormat || !getLocalizedString('weekRangeStartEnd')) {
        return '';
    }

    // Account for the order of day/month/year fields and passing in date format
    const order = getDateSpecifiersOrder(dateFormat);
    const daySpecifier = getDaySpecifier(dateFormat);

    // Account for dates in the same year/month, so we don't repeat info in the output.
    const sameYear = isSameYear(start, end, locale);
    const sameMonth = isSameMonth(start, end, locale);
    const interval = sameYear ? (sameMonth ? 'SameMonth' : 'SameYear') : 'DifferentYears';

    // Format the start and end fields using localized strings and the correct day specifier.
    // Note: we build the formatStringName using a convention to keep the generated code short.
    // The Strings type ensures the strings exist in owa-datetime-strings and allows us to search for their usage.
    function formatIntervalField(date: OwaDate, field: string) {
        const formatStringName = 'weekRange' + interval + field + '_' + order;

        // We should investigate adding an optional GlobalCalendarLocale parameter to getLocalizedString
        // to handle special cases associated with reference calendars that are not in the current OWA
        // language.  See https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/142177/ for
        // more information.  For the basic cases that will enable us to begin testing Global Calendars,
        // the current behavior is fine.
        const formatString = getLocalizedString(
            formatStringName as OwaDateTimeLocalizedStringResourceId
        );
        const formatStringWithDay = format(formatString, daySpecifier);
        return locale
            ? formatIntlDate(date, locale, formatStringWithDay)
            : formatDate(date, formatStringWithDay);
    }

    const startField = formatIntervalField(start, 'Start');
    const endField = formatIntervalField(end, 'End');

    // Append the fields, accounting for RTL languages with localized string
    const dateString = format(getLocalizedString('weekRangeStartEnd'), startField, endField);
    return weekNumber
        ? format(getLocalizedString('weekRangeWeekNumber'), dateString, weekNumber)
        : dateString;
}

function isSameYear(start: OwaDate, end: OwaDate, locale?: GlobalCalendarLocale): boolean {
    return locale
        ? getIntlYear(start, locale, 'numeric') == getIntlYear(end, locale, 'numeric')
        : getYear(start) == getYear(end);
}

function isSameMonth(start: OwaDate, end: OwaDate, locale?: GlobalCalendarLocale): boolean {
    return locale
        ? getIntlMonth(start, locale, 'numeric') == getIntlMonth(end, locale, 'numeric')
        : getMonth(start) == getMonth(end);
}
