import type { HotkeyCommand, Hotkeys } from '../types/HotkeyCommand';
import { isMac } from 'owa-user-agent';
import type { HotkeyProcessor } from '../utils/processCommand';
import processCommand from '../utils/processCommand';
import { convertDelKeyToBackspaceForMacProcessor } from '../utils/macCommandProcessors';

let macProcessors: HotkeyProcessor[] | undefined;

/**
 * Perform any pre-processing on the keys that we need to based upon platform, browser, etc.
 */
function fixUpHotkeyCommand(command: Hotkeys): Hotkeys;
function fixUpHotkeyCommand(command: HotkeyCommand): HotkeyCommand;
function fixUpHotkeyCommand(command: HotkeyCommand | Hotkeys): HotkeyCommand | Hotkeys;
function fixUpHotkeyCommand(command: HotkeyCommand | Hotkeys): HotkeyCommand | Hotkeys {
    if (isMac()) {
        // For Mac,
        // - convert any "del" keys to "backspace"
        // - convert all 'ctrl' to 'cmd'
        if (!macProcessors) {
            macProcessors = [convertDelKeyToBackspaceForMacProcessor];
        }
        return processCommand(command, macProcessors);
    } else {
        return command;
    }
}

export default fixUpHotkeyCommand;
