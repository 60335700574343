import { mutator } from 'satcheljs';
import { setNow, setToday } from './privateActions';
import { timestamp } from 'owa-datetime';
import getStore from './store';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(setNow, () => {
    getStore().now = timestamp();
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(setToday, ({ today }) => {
    getStore().today = today;
});
