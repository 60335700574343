import { onClickMessageExtensionFlyoutButton } from '../actions/onClickMessageExtensionFlyoutButton';

import type { AppButtonOnExecuteParameter } from '@1js/acui-button/lib/components/AppButton/AppButton.Props';
import type { MailboxInfo } from 'owa-client-types';
import type { MessageExtensionType } from 'owa-graph-schema';

export function messageExtensionsRibbonButtonOnExecute(
    params: AppButtonOnExecuteParameter,
    targetWindow: Window,
    formId: string,
    messageExtensionType: MessageExtensionType,
    mailboxInfo?: MailboxInfo,
    readingPaneItemId?: string
) {
    if (!params.location) {
        return;
    }

    onClickMessageExtensionFlyoutButton({
        flyoutButtonElementId: params.id,
        targetWindow,
        formId,
        readingPaneItemId: readingPaneItemId ?? '',
        messageExtensionType,
        mailboxInfo,
    });
}
