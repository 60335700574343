import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { isTenantThemeDataAvailable } from './isTenantThemeDataAvailable';

export function shouldUseCobranding(themeId: string): boolean {
    return (
        themeId === 'base' &&
        isTenantThemeDataAvailable() &&
        !isHostAppFeatureEnabled('loadThemeFromHostApp')
    );
}
