import React from 'react';
import { observer } from 'owa-mobx-react';
import {
    nodeCountSingularScreenReaderOnlyText,
    nodeCountPluralScreenReaderOnlyText,
} from './UnreadReadCountBadge.locstring.json';
import { unreadCountScreenReaderOnlyText } from 'owa-locstrings/lib/strings/unreadcountscreenreaderonlytext.locstring.json';
import loc from 'owa-localize';
import { getDensityModeCssClass } from 'owa-fabric-theme';

export interface UnreadReadCountBadgeProps extends React.HTMLProps<HTMLDivElement> {
    count: number | string;
    shouldDisplayTotalCount?: boolean;
    screenReaderText?: string;
    customStyle?: string;
    isSelected: boolean;
    isListViewBadge?: boolean;
}
import {
    full,
    medium,
    compact,
    listViewBadge,
    unreadOrTotalCount,
    unreadCount,
    plainBadge,
} from './UnreadReadCountBadge.scss';

import classnames from 'owa-classnames';

export default observer(function UnreadReadCountBadge(props: UnreadReadCountBadgeProps) {
    const { count, shouldDisplayTotalCount, screenReaderText, customStyle, isListViewBadge } =
        props;
    const densityMode = getDensityModeCssClass(full, medium, compact);
    const countContainerClassNames = classnames(
        customStyle,
        isListViewBadge && listViewBadge,
        unreadOrTotalCount,
        !shouldDisplayTotalCount && unreadCount,
        densityMode
    );
    return count ? (
        <span className={countContainerClassNames}>
            <span className={classnames(densityMode, plainBadge)}>{count}</span>
            <span className="screenReaderOnly">
                {
                    // for a treeitem, inner content that have aria-hidden=true are not hidden in IE
                    // therefore we cannot hide the visual string and use a screenReaderOnly string that has the count
                    screenReaderText || shouldDisplayTotalCount
                        ? count == 1
                            ? loc(nodeCountSingularScreenReaderOnlyText)
                            : loc(nodeCountPluralScreenReaderOnlyText)
                        : loc(unreadCountScreenReaderOnlyText)
                }
            </span>
        </span>
    ) : null;
}, 'UnreadReadCountBadge');
