import { createLazyComponent, LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailListItemComponents"*/ './lazyIndex'),
    { name: 'MailListItemComponents' }
);

// components
export { default as MailListItemFirstLineText } from './components/MailListItemFirstLineText';
export { default as MailListItemIconBar } from './components/MailListItemIconBar';
export { default as MailListItemLabelsContainer } from './components/MailListItemLabelsContainer';
export { default as MailListItemLineText } from './components/MailListItemLineText';
export { default as MailListItemPreviewDisplay } from './components/MailListItemPreviewDisplay';
export { default as MailListItemRichPreviewWell } from './components/MailListItemRichPreviewWell';
export { default as MailListItemTimestamp } from './components/MailListItemTimestamp';
export { default as MailListItemTwistyButton } from './components/MailListItemTwistyButton';
export { default as MailListItemUnreadBar } from './components/MailListItemUnreadBar';
export { default as MailListItemUnreadCount } from './components/MailListItemUnreadCount';
export { default as PersonaCheckbox } from './components/PersonaCheckbox';
export { default as getHoverActionIcon } from './utils/getHoverActionIcon';
export { HoverIcon, PropertyIcon } from './components/IconBar';

// lazy components
export const MailListItemTypePlaceholder = createLazyComponent(
    lazyModule,
    m => m.MailListItemTypePlaceholder
);
export const FolderTag = createLazyComponent(lazyModule, m => m.FolderTag);
export const MailListActionButton = createLazyComponent(lazyModule, m => m.MailListActionButton);
export const MailListItemMeetingInfo = createLazyComponent(
    lazyModule,
    m => m.MailListItemMeetingInfo
);
export const MailListItemInfo = createLazyComponent(lazyModule, m => m.MailListItemInfo);
export const MailListItemExternalMarker = createLazyComponent(
    lazyModule,
    m => m.MailListItemExternalMarker
);
export const MailListItemUnverifiedMarker = createLazyComponent(
    lazyModule,
    m => m.MailListItemUnverifiedMarker
);
export const lazyGetFlagContextMenu = new LazyAction(lazyModule, m => m.getFlagContextMenu);
