import getReportSubmissionPolicy from './getReportSubmissionPolicy';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import type { MailboxInfo } from 'owa-client-types';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';

/**
 * Checks if native reporting actions (Junk/Phishing) are enabled in the tenant
 *
 * @param mailboxInfo mailboxInfo of the mailbox that the user is reporting from
 * @returns boolean representing whether we should show native reporting actions (Junk/Phishing)
 */
export default function isNativeReportingEnabled(mailboxInfo: MailboxInfo): boolean {
    // Check Report Submission Policy values for enterprise users
    const isServiceRequestAllowed = isServiceRequestSupportedForMailbox(mailboxInfo);
    if (isServiceRequestAllowed && !isConsumer(undefined, mailboxInfo)) {
        const reportSubmissionPolicy = getReportSubmissionPolicy(mailboxInfo);

        return (
            reportSubmissionPolicy.EnableReportToMicrosoft ||
            (reportSubmissionPolicy.ReportJunkToCustomizedAddress &&
                reportSubmissionPolicy.ReportPhishToCustomizedAddress &&
                reportSubmissionPolicy.ReportNotJunkToCustomizedAddress)
        );
    }

    return isServiceRequestAllowed; // Default is off if isServiceRequestAllowed is not allowed
}
