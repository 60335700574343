import { getStore } from '../store/store';
import { isSameCoprincipalAccountByMailboxAndAccountKey, type MailboxInfo } from 'owa-client-types';

export const getTryShowCustomOrderCallout = (mailboxInfo: MailboxInfo): boolean => {
    // If we have not attempted to show the callout, return true so the code will try
    if (!getStore().customOrderCalloutState) {
        return true;
    }
    // Otherwise, only keep showing the callout if the mailbox is the same as the one we tried to show the callout for
    else {
        const customOrderCalloutState = getStore().customOrderCalloutState ?? undefined;
        return isSameCoprincipalAccountByMailboxAndAccountKey(mailboxInfo, customOrderCalloutState);
    }
};
