import {
    setOptionValue,
    setExtendedOptionValue,
    initializeOptionValues,
    setServiceOptionStoreData,
} from '../actions/publicActions';
import { extendObservable } from 'mobx';
import { mutator } from 'satcheljs';
import type { OwsOptionsFeatureType } from '../store/schema/OwsOptionsFeatureType';
import { isOwsOptionsBase } from '../utils/typeGuards';
import type OwsOptionsBase from '../store/schema/OwsOptionsBase';
import getStore from '../store/store';
import { getOwsOptionsStore } from '../selectors/getOwsOptionsStore';
import { type MailboxInfo, getIndexerValueForMailboxInfo } from 'owa-client-types';

const accountInitializationStatus: Map<string, boolean> = new Map();

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(setOptionValue, msg => {
    const { feature, value, mailboxInfo } = msg;
    setValue(feature, value, mailboxInfo);
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(setExtendedOptionValue, msg => {
    const { feature, value, mailboxInfo } = msg;
    extendObservable(getOwsOptionsStore(mailboxInfo).options[feature], value);
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(initializeOptionValues, message => {
    const { potentialOptionValues, mailboxInfo } = message;

    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    if (accountInitializationStatus.get(key)) {
        return;
    }

    accountInitializationStatus.set(key, true);
    for (const option of potentialOptionValues) {
        if (option && isOwsOptionsBase(option)) {
            setValue(option.feature, option, mailboxInfo);
        }
    }
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(setServiceOptionStoreData, message => {
    const store = getStore();
    store.accounts.clear();
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    Object.keys(message.storeData).forEach(key => store.accounts.set(key, message.storeData[key]));
});

function setValue(feature: OwsOptionsFeatureType, value: OwsOptionsBase, mailboxInfo: MailboxInfo) {
    getOwsOptionsStore(mailboxInfo).options[feature] = value;
}
