import { createIconModule } from 'owa-fluent-icons-svg';
import React from 'react';
import {
    TagRegular,
    TagFilled,
    bundleIcon,
    wrapIcon,
    type FluentIconsProps,
} from '@fluentui/react-icons';

const SparkleIcon = wrapIcon((props: FluentIconsProps) => {
    return (
        <svg
            height="14px"
            width="14px"
            role="presentation"
            focusable="false"
            viewBox="-22 -21 8 45"
            {...props}
        >
            <g>
                <path
                    fill="currentColor"
                    transform="matrix(6.123233995736766e-17,1,-1,6.123233995736766e-17,0,0)"
                    d="M10.06 18.701a1.628 1.628 0 0 0 2.43-.676l.77-2.34a3.82 3.82 0 0 1 2.416-2.418l2.238-.727a1.6 1.6 0 0 0 .786-.595a1.62 1.62 0 0 0-.849-2.489l-2.215-.72a3.82 3.82 0 0 1-2.42-2.414l-.727-2.237a1.62 1.62 0 0 0-.594-.785a1.655 1.655 0 0 0-1.879 0a1.63 1.63 0 0 0-.6.8L8.68 6.365a3.82 3.82 0 0 1-2.359 2.37l-2.24.726a1.626 1.626 0 0 0 .02 3.073l2.216.72a3.86 3.86 0 0 1 1.816 1.286c.266.343.471.728.606 1.14l.728 2.234c.112.318.32.593.595.787m9.744 6.08a1.2 1.2 0 0 0 .696.22a1.2 1.2 0 0 0 .692-.217a1.2 1.2 0 0 0 .446-.6l.372-1.143a1.61 1.61 0 0 1 1.017-1.02l1.166-.378A1.21 1.21 0 0 0 25 20.505a1.2 1.2 0 0 0-.844-1.146l-1.144-.37a1.61 1.61 0 0 1-1.02-1.018l-.38-1.163a1.2 1.2 0 0 0-2.274.016l-.374 1.146a1.61 1.61 0 0 1-.993 1.017l-1.166.378a1.21 1.21 0 0 0-.807 1.139a1.2 1.2 0 0 0 .823 1.138l1.144.372a1.6 1.6 0 0 1 1.02 1.023l.379 1.163a1.2 1.2 0 0 0 .44.582"
                />
            </g>
        </svg>
    );
}, 'SparkleIcon');

const TagIconBundle = bundleIcon(TagFilled, TagRegular);

const TagSparkleIconBundle = bundleIcon(TagIconBundle, SparkleIcon);

export default createIconModule('TagDuoToneSparkle', TagSparkleIconBundle);
