import AbstractTaskQueue from './AbstractTaskQueue';

export type TaskFunc<T> = () => Promise<T>;

export interface Task<T> {
    func: TaskFunc<T>;
    resolve: (value: T) => void;
    reject: (reason?: any) => void;
}

export interface PriorityTaskQueueConfig {
    maxParallelTasks?: number;
    taskDelay?: number;
    taskTimeout?: number;
}

/**
 * Task queue class to queue the service calls.
 */
export class PriorityTaskQueue<T> extends AbstractTaskQueue<Task<T>> {
    protected taskList: {
        [priority: number]: Task<T>[];
    } = {}; // ordered as a FIFO
    protected config: Required<PriorityTaskQueueConfig>;

    constructor(config: PriorityTaskQueueConfig) {
        super();
        this.config = {
            maxParallelTasks: 1,
            taskDelay: 0,
            taskTimeout: 60000,
            ...config,
        };
    }

    add(func: TaskFunc<T>, priority: number = 0): Promise<T> {
        if (!this.taskList[priority]) {
            this.taskList[priority] = [];
        }

        return new Promise((resolve, reject) => {
            this.taskList[priority].unshift({
                func,
                resolve,
                reject,
            });
            this.scheduleTask();
        });
    }

    remove(): Task<T> | undefined {
        const nextPriority = Math.min.apply(null, this.priorities);
        const nextTask = this.taskList[nextPriority].pop();

        // If there are no more tasks then delete the key
        if (!this.taskList[nextPriority] || this.taskList[nextPriority].length == 0) {
            delete this.taskList[nextPriority];
        }

        return nextTask;
    }

    clear() {
        this.taskList = {};
    }

    hasHighPriorityTasks(): boolean {
        const highPriorityLists = Object.keys(this.taskList) as unknown as number[];
        return highPriorityLists.some((priority: number) => priority <= 10 /* Default */);
    }

    protected get priorities(): number[] {
        return Object.keys(this.taskList).map(p => parseInt(p));
    }

    protected get canRunMoreTasks(): boolean {
        return (
            this.numberOfTasksRunning < this.config.maxParallelTasks && this.priorities.length > 0
        );
    }

    protected runTask(task: Task<T>): Promise<void> {
        return task.func().then(task.resolve, task.reject);
    }
}
