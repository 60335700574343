import toggleCharm from '../actions/toggleCharm';
import { mutator } from 'satcheljs';
import { getStore } from '../store/store';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(toggleCharm, actionMessage => {
    const charm = actionMessage.charm;
    const store = getStore();
    if (store.activeCharm === charm) {
        store.activeCharm = null;
    } else {
        store.activeCharm = charm;
    }
});
