export const SingleLine = 'SingleLine';
export const Multiline = 'Multiline';
export const AppButton = 'AppButtonProps';
export const AppSplitButton = 'AppSplitButtonProps';
export const AppFlyoutAnchor = 'AppFlyoutAnchorProps';
export const AppGalleryButton = 'AppGalleryButtonProps';
export const AppInput = 'AppInputProps';
export const AppSearchBox = 'AppSearchBoxProps';
export const AppCheckBox = 'AppCheckboxProps';
export const AppColorPicker = 'AppColorPickerProps';
export const AppToggleButton = 'AppToggleButtonProps';
export const AppCompoundButton = 'AppCompoundButtonProps';
export const RibbonInsertTable = 'RibbonInsertTableProps';
