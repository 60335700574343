import type { MailboxInfo } from 'owa-client-types';
import { GroupFolderTreeLoadStateEnum } from '../store/schema/GroupFolderTreeLoadStateEnum';
import { getGroupFolderHierarchy } from './getGroupFolderHierarchy';

export function getGroupFolderTreeLoadingState(
    groupId: string,
    mailboxInfo: MailboxInfo
): GroupFolderTreeLoadStateEnum {
    const folderHierarchy = getGroupFolderHierarchy(groupId, mailboxInfo);
    return folderHierarchy?.loadingState || GroupFolderTreeLoadStateEnum.Uninitialized;
}
