import { LazyImport, LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "OneOutlookSuiteHeader" */ './lazyIndex')
);

// We don't want this to be a lazy component because it needs to be loaded and ready to render
// during boot
export const lazyOneOutlookSuiteHeader = new LazyImport(lazyModule, m => m.components);

export const lazyToggleMaximize = new LazyAction(lazyModule, m => m.toggleMaximize);

export const lazyCaptionButtonAction = new LazyAction(lazyModule, m => m.captionButtonAction);
