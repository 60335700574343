import type { MailRibbonConfigStore } from 'owa-mail-ribbon-store-shared-types';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { HelpRibbonTabId } from 'owa-ribbon-ids/lib/helpControlId';
import { type LabelPreferenceId } from 'owa-mail-ribbon-store-shared-types/lib/util/labelPreferencesIds';
import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { isFeatureEnabled } from 'owa-feature-flags';

function getTabScalingAndLayoutConfigInit(showLabelsPreference: LabelPreferenceId) {
    return {
        layout: [],
        controlsInOverflow: [],
        staticGroupIdOrdering: [],
        removedDefaultGroups: [],
        showLabelsPreference,
    };
}

export interface MailRibbonStoreMap {
    entries: ObservableMap<string, MailRibbonStore>;
    selectedTabMultiWindow: ObservableMap<Window, MailRibbonTabId | HelpRibbonTabId>;
}

export interface MailRibbonStore {
    ribbonConfig: MailRibbonConfigStore;
    hasLoaded: boolean;
}

function initialEmptyRibbonConfig(): MailRibbonConfigStore {
    return {
        version: 0,
        multiline: {
            homeTab: getTabScalingAndLayoutConfigInit(1),
            viewTab: getTabScalingAndLayoutConfigInit(1),
            messageTab: getTabScalingAndLayoutConfigInit(1),
            insertTab: getTabScalingAndLayoutConfigInit(1),
            formatTab: getTabScalingAndLayoutConfigInit(1),
            notesTab: getTabScalingAndLayoutConfigInit(1),
            tableFormatTab: getTabScalingAndLayoutConfigInit(1),
            readTab: getTabScalingAndLayoutConfigInit(1),
            optionsTab: getTabScalingAndLayoutConfigInit(1),
            helpTab: getTabScalingAndLayoutConfigInit(1),
            drawTab: getTabScalingAndLayoutConfigInit(1),
            pictureTab: getTabScalingAndLayoutConfigInit(1),
        },
        singleline: {
            homeTab: getTabScalingAndLayoutConfigInit(1),
            viewTab: getTabScalingAndLayoutConfigInit(1),
            messageTab: getTabScalingAndLayoutConfigInit(1),
            insertTab: getTabScalingAndLayoutConfigInit(1),
            formatTab: getTabScalingAndLayoutConfigInit(1),
            notesTab: getTabScalingAndLayoutConfigInit(1),
            tableFormatTab: getTabScalingAndLayoutConfigInit(1),
            readTab: getTabScalingAndLayoutConfigInit(1),
            optionsTab: getTabScalingAndLayoutConfigInit(1),
            helpTab: getTabScalingAndLayoutConfigInit(1),
            drawTab: getTabScalingAndLayoutConfigInit(1),
            pictureTab: getTabScalingAndLayoutConfigInit(1),
        },
        contextualTabs: [],
        /* the key here is the editorId, which is what we use to differenciate controls in multiple tabs as well */
        contextualTabsMultiWindows: new ObservableMap<string, MailRibbonTabId[]>(),
    };
}

const store = createStore<MailRibbonStoreMap>('MailRibbonConfiguration', {
    entries: new ObservableMap<string, MailRibbonStore>(),
    selectedTabMultiWindow: new ObservableMap<Window, MailRibbonTabId | HelpRibbonTabId>(),
})();

export function getStore(mailboxInfo?: MailboxInfo): MailRibbonStore {
    let key: string = getIndexerValueForMailboxInfo(getGlobalSettingsAccountMailboxInfo());
    if (isFeatureEnabled('mon-ribbon-customization-multiAccount') && mailboxInfo) {
        key = getIndexerValueForMailboxInfo(mailboxInfo);
    }
    const currentStore = store.entries.get(key);
    if (!currentStore) {
        const ribbonConfig = initialEmptyRibbonConfig();
        const ribbonStore: MailRibbonStore = { ribbonConfig, hasLoaded: false };
        store.entries.set(key, ribbonStore);
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        return store.entries.get(key)!;
    }
    return currentStore;
}

export function getMailRibbonConfigStore(mailboxInfo?: MailboxInfo): MailRibbonConfigStore {
    return getStore(mailboxInfo).ribbonConfig;
}

export function getSelectedTab(targetWindow: Window = window): MailRibbonTabId | HelpRibbonTabId {
    return store.selectedTabMultiWindow.get(targetWindow) || 1;
}

export function setSelectedTab(
    tabId: MailRibbonTabId | HelpRibbonTabId,
    targetWindow: Window = window
) {
    setSelectedTabMultiWindow(tabId, targetWindow);
}

export function setSelectedTabMultiWindow(
    tabId: MailRibbonTabId | HelpRibbonTabId | undefined,
    targetWindow: Window
) {
    if (tabId) {
        store.selectedTabMultiWindow.set(targetWindow, tabId);
    } else {
        store.selectedTabMultiWindow.delete(targetWindow);
    }
}
