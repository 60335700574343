import { logUsage } from 'owa-analytics';
import getDensityOptionState from '../store/store';
import { getSecondarySettingDefaultsForMode } from '../utils/getSecondarySettingDefaultsForMode';
import { saveDensityOption } from './saveDensityOption';

export default async function saveDensityOptionQuickSettings() {
    const densityOptionSelected = getDensityOptionState().densityModeSelected;

    // We have opinions about some secondary settings as well.
    const secondarySettings = getSecondarySettingDefaultsForMode(densityOptionSelected);

    logUsage('DisplayDensityQuickSettings', [densityOptionSelected]);
    return saveDensityOption(densityOptionSelected, secondarySettings);
}
