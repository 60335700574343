import { format } from 'owa-localize';
import { localizedFormatter } from './localizedFormatter';
import { getDayFormat } from './formatDay';
import { getLocalizedString } from 'owa-datetime-formatters-core';
import isOneSpecifierBeforeTheOther from './isOneSpecifierBeforeTheOther';

export function getMonthDayFormat(dateFormat: string, timeFormat: string) {
    const dayFormat = getDayFormat(dateFormat, timeFormat);
    const dayFirst = isOneSpecifierBeforeTheOther(dateFormat, 'd', 'M');
    const formatString = dayFirst
        ? getLocalizedString('monthDayFormatDayFirst')
        : getLocalizedString('monthDayFormatMonthFirst');
    return format(formatString, getLocalizedString('MMMM'), dayFormat);
}

export default localizedFormatter(getMonthDayFormat);
