import loc, { isCurrentCultureRightToLeft } from 'owa-localize';
import type { RibbonControlId } from 'owa-ribbon-ids';
import type { HotkeyCommand } from 'owa-hotkeys';
import type { IComputedValue } from 'mobx';
import { convertHotkeyToReadableText } from 'owa-readable-hotkeys/lib/utils/convertHotkeyToReadableText';
import type { ResourceId } from 'owa-localize';

/**
 * Creates a function that returns the hover tooltip for a given control id and name, based on the app-specific maps of descriptions and hotkeys.
 *
 * @param {IComputedValue<Map<RibbonControlId, ResourceId>>} controlDescMap map of control ids and descriptions
 * @param {IComputedValue<Map<RibbonControlId, HotkeyCommand>>} controlHotkeyMap map of control ids and hotkey command objects
 * @returns {Function} A function that takes in a control id, a control name, and an optional overrides object, and returns the hover tooltip, or undefined if none exists.
 */
export function createHoverTooltipFn(
    controlDescMap: IComputedValue<Map<RibbonControlId, ResourceId>>,
    controlHotkeyMap: IComputedValue<Map<RibbonControlId, HotkeyCommand>>
): (
    controlId: RibbonControlId | undefined,
    controlName: string,
    overrides?: HoverTooltipOverrides
) => string | undefined {
    return function (
        controlId: RibbonControlId | undefined,
        controlName: string,
        overrides?: HoverTooltipOverrides
    ) {
        const isRTL: boolean = isCurrentCultureRightToLeft();

        const hotkey =
            overrides?.hotkey ?? getHoverTooltipHotkey(controlHotkeyMap.get(), controlId);
        const map = controlDescMap.get();
        const controlDescription =
            controlId && map.has(controlId) && map.get(controlId)
                ? loc(map.get(controlId))
                : undefined;
        const description = overrides?.description ?? controlDescription;

        // If a hotkey is defined for the controlId and RTL --> (hotkey) controlName\n\n description
        // ---------------------------------------------LTR --> controlName (hotkey)\n\n description
        // \n\n description is omitted if description is not defined.
        const controlNameOrDescription = description ? description : controlName;
        if (hotkey) {
            return isRTL
                ? `(${hotkey}) ${controlNameOrDescription}`
                : `${controlNameOrDescription} (${hotkey})`;
        } else {
            return `${controlNameOrDescription}`;
        }
    };
}

export interface HoverTooltipOverrides {
    hotkey?: string;
    description?: string;
    fallbackToName?: boolean;
}

/**
 * Returns the hover tooltip hotkey for a given control id, based on a map of control ids and hotkey commands.
 * @param {Map} controlHotkeyMap A map of control ids and hotkey commands.
 * @param {RibbonControlId}  controlId The control id to get the hover tooltip hotkey for.
 * @returns {HotkeyCommand | undefined} The hover tooltip hotkey, or undefined if none exists.
 */

function getHoverTooltipHotkey(
    controlHotkeyMap: Map<RibbonControlId, HotkeyCommand>,
    controlId: RibbonControlId | undefined
): string | undefined {
    if (controlId) {
        const hotKeyCommand: HotkeyCommand | undefined = controlHotkeyMap.get(controlId);
        return hotKeyCommand ? convertHotkeyToReadableText(hotKeyCommand) : undefined;
    }
    return undefined;
}
