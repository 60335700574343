import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CommandRibbonLazyFunctions"*/ './lazyIndex'),
    { name: 'CommandRibbonLazyFunctions' }
);

export const lazyGetDefaultRibbonControlInMenuRendererFunction = new LazyAction(
    lazyModule,
    m => m.getDefaultRibbonControlInMenuRendererFunction
);
