import { mutator } from 'satcheljs';
import { setSelectionOnRow } from 'owa-mail-actions/lib/setSelectionOnRow';
import getListViewStore from 'owa-mail-list-store/lib/store/Store';

export default mutator(setSelectionOnRow, actionMessage => {
    const { rowKey, tableViewId, shouldSelect } = actionMessage;
    const tableView = getListViewStore().tableViews.get(tableViewId);
    if (!tableView) {
        return;
    }

    if (shouldSelect) {
        tableView.selectedRowKeys.set(rowKey, true);
    } else {
        tableView.selectedRowKeys.delete(rowKey);
    }
});
