import { action } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';

const onUninstallM365Acquisition = action(
    'onUninstallM365Acquisition',
    (hasAddin: boolean, appId: string | undefined, mailboxInfo?: MailboxInfo) => ({
        hasAddin,
        appId,
        mailboxInfo,
    })
);

export default onUninstallM365Acquisition;
