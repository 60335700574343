export enum OcsVersion {
    Beta = 'beta',
    V1 = 'v1',
}

export enum OcsSettingScope {
    Account = 'account',
    Global = 'global',
}

export enum OcsSettingType {
    Bool = 'Bool',
    Int = 'Int',
    Json = 'JSON',
    String = 'String',
    BlobArray = 'BlobArray',
    Blob = 'Blob',
}

export function makeOcsBaseUrl(version: OcsVersion, scope: OcsSettingScope) {
    return `ows/${version}/OutlookCloudSettings/settings/${scope}`;
}

export function makeOcsRequestUrl(
    version: OcsVersion,
    scope: OcsSettingScope,
    settings: string[]
): string {
    const ocsBaseUrl = makeOcsBaseUrl(version, scope);
    const settingNameTokens = settings.join(',');

    return `${ocsBaseUrl}?settingname=${settingNameTokens}`;
}

export type OcsRequestOptions = {
    /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
     * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
     *	> Optional object properties should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
    additionalHeaders?: any;
    correlationId?: string;
    returnFullResponse?: boolean;
};
