import { logUsage } from 'owa-analytics';
import type { ActionId } from 'owa-mail-list-item-animation-store';
import type { TableView, MailFolderTableQuery } from 'owa-mail-list-store';
import {
    isConversationView,
    getTableConversationRelation,
    MailRowDataPropertyGetter,
} from 'owa-mail-list-store';
import isDeletedItemsFolderFromAnyMailbox from 'owa-mail-store/lib/utils/isDeletedItemsFolderFromAnyMailbox';
import isUnderDeletedItemsFolderFromAnyMailbox from 'owa-mail-store/lib/utils/isUnderDeletedItemsFolderFromAnyMailbox';
import folderStore from 'owa-folders';
import isArchiveFolder from 'owa-mail-folder-store/lib/utils/isArchiveFolder';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';

export function canAnimateRows(
    tableView: TableView,
    row: Element,
    animationRowIds: string[],
    animationAction: ActionId
) {
    const scenario = (tableView.tableQuery as MailFolderTableQuery).scenarioType;
    let canItemsAnimate: boolean = true;

    // If the animate method does not exist on its parent object of type Element, we should not animate.
    // Certain browsers+versions do not support the animate method, but using isMinimumBrowserVersion wasn't always reliable so we are using feature detection instead.
    if (typeof row.animate !== 'function') {
        logUsage('ListViewAnimation_AnimateMethodNotSupported');
        return false;
    }

    if (animationAction === 'Archive' && isArchiveFolder(tableView.tableQuery.folderId)) {
        return false;
    }

    // If we are in a scenario other than mail or group, we will need to block animations in some cases where the rows don't actually leave
    // the list view or don't change position within the list view to avoid the weird user experience of seeing an item leave the list and pop back in to the same spot.
    if (scenario !== 'mail' && scenario !== 'group') {
        if (animationAction === 'Archive' || animationAction === 'Move') {
            return false;
        }
        const folderIds: string[] = [];

        // Get the parent folder ids for the rows are acting on
        if (isConversationView(tableView)) {
            animationRowIds.map(animationRowId => {
                const parentFolderId = getTableConversationRelation(
                    animationRowId,
                    tableView.id
                )?.parentFolderId;
                if (parentFolderId) {
                    folderIds.push(parentFolderId);
                }
            });
        } else if (tableView.tableQuery.listViewType === 1) {
            animationRowIds.map(animationRowId => {
                const parentFolderId = MailRowDataPropertyGetter.getParentFolderId(
                    animationRowId,
                    tableView
                );
                if (parentFolderId) {
                    folderIds.push(parentFolderId);
                }
            });
        }

        // We want to animate rows that are either being removed from the LV or are just shifting to a different position in the LV.
        // Pinning, unpinning, or permanently deleting items from deleted items folders or sub folders under deleted items folders will remove the item from the LV. Other triage actions may change
        // the folder badge of the item but the item doesn't actually get removed from the LV.
        canItemsAnimate = folderIds.every(folderId => {
            if (folderId) {
                const sourceFolder = folderStore.folderTable.get(folderId);
                return (
                    isDeletedItemsFolderFromAnyMailbox(folderId) ||
                    (sourceFolder && isUnderDeletedItemsFolderFromAnyMailbox(sourceFolder)) ||
                    willRowPositionChangeAfterAnimation(animationAction)
                );
            }
            return false;
        });
    }

    return canItemsAnimate;
}

function willRowPositionChangeAfterAnimation(animationAction: ActionId): boolean {
    return (
        animationAction === 'PinInitial' ||
        animationAction === 'PinMiddle' ||
        animationAction === 'PinFinal' ||
        animationAction === 'UnpinInitial' ||
        animationAction === 'UnpinMiddle' ||
        animationAction === 'UnpinFinal'
    );
}
