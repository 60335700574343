import { MAX_FOLDER_PANE_WIDTH, MAX_LIST_VIEW_WIDTH_SLV_RP } from '../internalConstants';
import { type BrowserWidthBucket, getBrowserWidthBucket } from 'owa-layout';
/**
 * Gets the left nav max width for the current browser window width
 */
export function getLeftNavMaxWidth(): number {
    const browserWidthBucket = getBrowserWidthBucket();

    if (browserWidthBucket <= 999) {
        return 198;
    }
    if (browserWidthBucket <= 1199) {
        return 236;
    }
    if (browserWidthBucket <= 1399) {
        return 254;
    }
    if (browserWidthBucket <= 1599) {
        return 300;
    }
    if (browserWidthBucket <= 1600) {
        return 320;
    }
    return MAX_FOLDER_PANE_WIDTH;
}

/**
 * Gets the SLV max width, when there's a right reading pane, for the current browser window width
 */
function getSLVListViewMaxWidth(browserWidthBucket: BrowserWidthBucket): number {
    if (browserWidthBucket <= 999) {
        return 350;
    }
    if (browserWidthBucket <= 1199) {
        return 380;
    }
    if (browserWidthBucket <= 1399) {
        return 452;
    }
    if (browserWidthBucket <= 1599) {
        return 682;
    }
    if (browserWidthBucket <= 1600) {
        return 862;
    }
    if (browserWidthBucket <= 1800) {
        return 863;
    }
    if (browserWidthBucket <= 2000) {
        return 1063;
    }
    if (browserWidthBucket <= 2200) {
        return 1263;
    }
    if (browserWidthBucket <= 2400) {
        return 1463;
    }
    if (browserWidthBucket <= 2600) {
        return 1663;
    }
    if (browserWidthBucket <= 2800) {
        return 1863;
    }
    if (browserWidthBucket <= 3000) {
        return 2063;
    }
    if (browserWidthBucket <= 3001) {
        return 2263;
    }

    return MAX_LIST_VIEW_WIDTH_SLV_RP;
}

/**
 * Gets the list view max width for the current browser window width
 */
export function getListViewMaxWidth(): number {
    const browserWidthBucket = getBrowserWidthBucket();

    // Allow for a wider List View
    return getSLVListViewMaxWidth(browserWidthBucket);
}
