import type { MailboxInfo } from 'owa-client-types';
import { getMailAccountSources } from 'owa-account-source-list-store';
import { trace } from 'owa-trace';

export function getReadingPaneRouteForPopoutV2(
    mailboxInfo?: MailboxInfo,
    itemId?: string,
    conversationId?: string,
    internetMessageId?: string
) {
    if (!conversationId && !itemId && !internetMessageId) {
        trace.warn('Conversation, Item, or InternetMessage Id must be provided');
        return '';
    }

    let route = '';

    if (internetMessageId) {
        route = 'readimid/';
        route += encodeURIComponent(btoa(internetMessageId));
    } else {
        route = conversationId ? 'readconv/' : 'read/';
        if (mailboxInfo) {
            const emailAddressParts = mailboxInfo.mailboxSmtpAddress.split('@');
            if (mailboxInfo.type == 'GroupMailbox') {
                route += 'group/';
                route += `${emailAddressParts[1]}/${emailAddressParts[0]}/`;
            } else if (getMailAccountSources().length > 1 && mailboxInfo.userIdentity) {
                route += 'account/';
                route += `${emailAddressParts[1]}/${emailAddressParts[0]}/`;
            }
        }

        route += encodeURIComponent((conversationId ? conversationId : itemId) || '');
    }

    return route;
}
