import { isFolderInMailboxType } from 'owa-folders';
import type Item from 'owa-service/lib/contract/Item';
import isSharedMailboxExplicitLogon from './isSharedMailboxExplicitLogon';

export function isSharedReadItemCheckForAddins(item?: Item): boolean {
    if (!item || !item.ParentFolderId) {
        return false;
    }

    const folderId = item.ParentFolderId.Id;
    return isFolderInMailboxType(folderId, 'SharedMailbox') || isSharedMailboxExplicitLogon();
}

export function isSharedComposeItemCheckForAddins(viewStateIsInSharedFolder: boolean): boolean {
    return viewStateIsInSharedFolder || isSharedMailboxExplicitLogon();
}
