import type {
    CalendarFormsRibbonId,
    CalendarFormsRibbonTabId,
    CalendarFormsRibbonGroupId,
    CalendarFormsRibbonControlId,
} from './calendarFormsRibbonId';
import type {
    CalendarRibbonId,
    CalendarRibbonTabId,
    CalendarRibbonGroupId,
    CalendarRibbonControlId,
} from './calendarRibbonId';
import type { FormatControlId } from './formatControlId';
import type { DrawControlId } from './drawControlId';
import type { ActionControlId, RuntimeControlId } from './actionControlId';
import type {
    MailRibbonId,
    MailRibbonTabId,
    MailRibbonGroupId,
    MailRibbonControlId,
} from './mailRibbonId';
import type {
    PeopleHubRibbonControlId,
    PeopleHubRibbonGroupId,
    PeopleHubRibbonTabId,
    PeopleHubRibbonId,
} from './peopleHubRibbonId';
import type { FloatieRibbonGroupId, FloatieRibbonId } from './floatieRibbonId';
import type {
    GroupsRibbonId,
    GroupsRibbonTabId,
    GroupsRibbonGroupId,
    GroupsRibbonControlId,
} from './groupsRibbonId';
import type {
    HelpRibbonId,
    HelpRibbonTabId,
    HelpRibbonGroupId,
    HelpRibbonControlId,
} from './helpControlId';

import type {
    NewsletterRibbonId,
    NewsletterRibbonTabId,
    NewsletterRibbonControlId,
    NewsletterRibbonGroupId,
} from './newsletterRibbonId';
import type {
    PeopleAppRibbonControlId,
    PeopleAppRibbonGroupId,
    PeopleAppRibbonId,
    PeopleAppRibbonTabId,
} from './peopleAppRibbonId';

/**
 * RibbonId is the most generic type, signaling any Id for any possible Ribbon element (tab/group/control).
 * RibbonId ranges:
 *     - MailRibbonId [0, 1999]
 *     - CalendarRibbonId [2000, 3999],
 *     - CalendarFormsRibbonId [4000, 5999],
 *     - FormatControlId [6000, 6999]
 *     - ActionControlId [7000, 7999]
 *     - PeopleHubRibbonId [8000, 8999]
 *     - FloatieRibbonId [9000, 10000]
 *     - GroupsRibbonId [10001, 10999]
 *     - HelpRibbonId [11000, 11999]
 *     - DrawControlId [12000, 13999]
 *     - NewsletterRibbonId [14000, 14999]
 *     - PeopleAppRibbonId [15000, 15999]
 *     - RuntimeControlId [90000, 99999]
 */
export type RibbonId =
    | MailRibbonId
    | CalendarRibbonId
    | CalendarFormsRibbonId
    | FormatControlId
    | ActionControlId
    | PeopleHubRibbonId
    | FloatieRibbonId
    | GroupsRibbonId
    | HelpRibbonId
    | RuntimeControlId
    | DrawControlId
    | NewsletterRibbonId
    | PeopleAppRibbonId;

/**
 * RibbonTabId is a sub-type to specify RibbonIds related to specifically tab components.
 */
export type RibbonTabId =
    | MailRibbonTabId
    | CalendarRibbonTabId
    | CalendarFormsRibbonTabId
    | PeopleHubRibbonTabId
    | GroupsRibbonTabId
    | HelpRibbonTabId
    | NewsletterRibbonTabId
    | PeopleAppRibbonTabId;

/**
 * RibbonGroupId is a sub-type to specify RibbonIds related to specifically group components.
 */
export type RibbonGroupId =
    | MailRibbonGroupId
    | CalendarRibbonGroupId
    | CalendarFormsRibbonGroupId
    | PeopleHubRibbonGroupId
    | FloatieRibbonGroupId
    | GroupsRibbonGroupId
    | HelpRibbonGroupId
    | NewsletterRibbonGroupId
    | PeopleAppRibbonGroupId;

/**
 * RibbonControlId is a sub-type to specify RibbonIds related to specifically control components.
 */
export type RibbonControlId =
    | MailRibbonControlId
    | CalendarRibbonControlId
    | CalendarFormsRibbonControlId
    | FormatControlId
    | ActionControlId
    | PeopleHubRibbonControlId
    | GroupsRibbonControlId
    | HelpRibbonControlId
    | RuntimeControlId
    | DrawControlId
    | NewsletterRibbonControlId
    | PeopleAppRibbonControlId;

export const getStringRibbonId = (id: RibbonId) => id.toString();

// A user can create and define their own Ribbon Group via the Ribbon Customizer.
// In this scenario, we assign their Ribbon Group a unique negative value such that
// we can determine whether the group is engineer-created or user-defined.
export const isUserCreatedRibbonGroupId = (id: RibbonId) => id < 0;
