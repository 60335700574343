import { lazyGetRulesSections } from 'owa-mail-ribbon-sections/lib/sections';

import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onRulesMenuClicked = (props: ReadOnlyRibbonControlDefProps) => {
    lazyGetRulesSections.import().then(getRulesSections => {
        setMenuDefinition('RulesMenuDefinitionStore', props.projectionTabId, () => {
            return {
                sections: getRulesSections(props),
            };
        });
    });
};
