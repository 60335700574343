import getStore from '../store/store';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { loadMailboxQuickSteps } from '../util/loadQuickSteps';

export enum QuickStepLoadState {
    Loading,
    Loaded,
    ConfigError,
}

export function canUseQuickSteps(mailboxInfo: MailboxInfo): QuickStepLoadState {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    const store = getStore().quickStepsStoreMap.get(key);
    // if we have that config loaded, return either loaded or error
    if (store) {
        if (store.errorState) {
            return QuickStepLoadState.ConfigError;
        } else {
            return QuickStepLoadState.Loaded;
        }
    }
    // if not, go load the store, but return false to not block the UI render
    else {
        loadMailboxQuickSteps(mailboxInfo);
        return QuickStepLoadState.Loading;
    }
}
