import type TabViewState from '../store/schema/TabViewState';
import { type TabState, TabType } from '../store/schema/TabViewState';
import { getStore } from '../store/tabStore';

export default function getAllComposeTabs(): TabViewState[] | null {
    const store = getStore();
    const composeTabs: TabViewState[] = [];

    for (const tab of store.tabs) {
        if (
            tab.type === TabType.MailCompose &&
            (tab.state === 2 || tab.state === 1 || tab.state === 0) // Hidden state is used for inline compose in conversation view
        ) {
            composeTabs.push(tab);
        }
    }
    return composeTabs;
}
