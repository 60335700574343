import { lazyCreateOrUpdateOptionsForFeature } from 'owa-outlook-service-options';
import { getOptionsForFeature, OwsOptionsFeatureType } from 'owa-outlook-service-option-store';
import type { PinnedPaneOptions, PinnablePane } from 'owa-outlook-service-option-store';

export async function savePinnedPaneSetting(newValue: PinnablePane): Promise<void> {
    const userOptions = getOptionsForFeature<PinnedPaneOptions>(OwsOptionsFeatureType.PinnedPane);
    if (userOptions.pinnedPane !== newValue) {
        return lazyCreateOrUpdateOptionsForFeature.importAndExecute(
            OwsOptionsFeatureType.PinnedPane,
            {
                ...userOptions,
                pinnedPane: newValue,
            } as PinnedPaneOptions
        );
    }
}
