import { mutatorAction } from 'satcheljs';
import { taskModules } from '../store/taskModule';
import type { InstanceStore } from '../store/schema/TaskModuleInstances';
import type { DialogInfo } from '../store/schema/Dialog';

function registerAction<T extends unknown[]>(
    name: string,
    callback: (store: InstanceStore, ...args: T) => void
) {
    return (id: string) =>
        /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
         * Baseline. DO NOT COPY AND PASTE!
         *	> Function should only be invoked in module scope */
        mutatorAction(`${name}_${id}`, function (...args: T) {
            const store = taskModules().instances.get(id);
            if (store) {
                callback(store, ...args);
            }
        });
}

export const registerOpenDialog = registerAction('OPEN_TASK_MODULE', (store: InstanceStore) => {
    store().isOpen = true;
    store().userClickTime = Date.now();
});

export const registerCloseDialog = registerAction('CLOSE_TASK_MODULE', (store: InstanceStore) => {
    store().isOpen = false;
    store().dialogInfo = {} as DialogInfo;
});

export const registerOnSubmit = registerAction('ON_SUBMIT_TASK_MODULE', (store: InstanceStore) => {
    store().isOpen = false;
});

export const registerOnDismiss = registerAction(
    'ON_DISMISS_TASK_MODULE',
    (store: InstanceStore) => {
        store().isOpen = false;
    }
);

export const registerUpdateDialog = registerAction(
    'UPDATE_TASK_MODULE',
    (store: InstanceStore, dialogInfo: DialogInfo) => {
        store().dialogInfo = { ...dialogInfo };
    }
);
