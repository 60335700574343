import { logUsage } from 'owa-analytics';
import onConversationOptionSaved from 'owa-options-actions/lib/onConversationOptionSaved';
import { onListViewTypeOptionSaved } from 'owa-options-actions/lib/onListViewTypeOptionSaved';
import type ConversationSortOrder from 'owa-service/lib/contract/ConversationSortOrder';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import type { UpdateUserConfigurationEntry } from 'owa-service/lib/types/UpdateUserConfigurationEntry';
import { getUserConfiguration, updateUserConfiguration } from 'owa-session-store';
import { updateUserConfigurationService } from 'owa-service/lib/utils/updateUserConfigurationService';
import store from '../store/store';
import saveReadingPaneConversationOption from './saveReadingPaneConversationOption';

async function saveConversationsOption(
    newListViewType: ReactListViewType | null,
    conversationSortOrder: ConversationSortOrder | undefined,
    hideDeletedItems: boolean | null,
    readingPaneConversationsEnabled: boolean | null | undefined
) {
    const userOptions = getUserConfiguration().UserOptions;
    const serviceUpdates: UpdateUserConfigurationEntry<'OWA.UserOptions'>[] = [];

    if (
        userOptions &&
        newListViewType !== null &&
        newListViewType !== userOptions.GlobalListViewTypeReact
    ) {
        updateUserConfiguration(config => {
            if (config.UserOptions) {
                config.UserOptions.GlobalListViewTypeReact = newListViewType;
            }
        });
        serviceUpdates.push({
            key: 'GlobalListViewTypeReact',
            valuetype: 'Integer32',
            value: [`${newListViewType}`],
        });

        onListViewTypeOptionSaved(newListViewType);
    }

    if (readingPaneConversationsEnabled != null) {
        // Save the new option
        await saveReadingPaneConversationOption(readingPaneConversationsEnabled);
    }

    if (conversationSortOrder) {
        updateUserConfiguration(config => {
            if (config.UserOptions) {
                config.UserOptions.ConversationSortOrderReact = conversationSortOrder;
            }
        });

        // The setting is actually stored as an int on the server, despite being sent down as a string
        // Copied from //depot/utah/sources/dev/clients/src/owa/bin/core/ConversationSortOrderReact.cs
        let sortOrderValue;

        switch (userOptions?.ConversationSortOrderReact) {
            case 'Chronological':
                sortOrderValue = 1;
                break;
            case 'Tree':
                sortOrderValue = 2;
                break;
            case 'NewestOnTop':
                sortOrderValue = 4;
                break;
            case 'NewestOnBottom':
                sortOrderValue = 8;
                break;
            case 'ChronologicalNewestOnTop':
                sortOrderValue = 5;
                break;
            case 'ChronologicalNewestOnBottom':
                sortOrderValue = 9;
                break;
            case 'TreeNewestOnBottom':
                sortOrderValue = 10;
                break;
        }

        serviceUpdates.push({
            key: 'ConversationSortOrderReact',
            valuetype: 'Integer32',
            value: [`${sortOrderValue}`],
        });
    }

    if (hideDeletedItems !== null) {
        updateUserConfiguration(config => {
            if (config.UserOptions) {
                config.UserOptions.HideDeletedItems = hideDeletedItems;
            }
        });
        serviceUpdates.push({
            key: 'HideDeletedItems',
            valuetype: 'Boolean',
            value: [`${userOptions?.HideDeletedItems}`],
        });
    }

    onConversationOptionSaved();

    if (serviceUpdates.length) {
        await updateUserConfigurationService(serviceUpdates);
    }
}

export async function saveConversationsOptionFull() {
    return saveConversationsOption(
        store.globalListViewType,
        store.conversationSortOrder,
        store.hideDeletedItems,
        store.readingPaneConversationEnabled
    );
}

const OPTION_QUICK_SETTING_USAGE_KEY = 'QuickSettingConversations';
const OPTION_PROGRAMMATIC_USAGE_KEY = 'ProgrammaticConversations';

export async function saveConversationsOptionQuick(
    listViewType: ReactListViewType | null,
    readingPaneConversationEnabled?: boolean,
    newSortOrder?: ConversationSortOrder
) {
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
    logUsage(OPTION_QUICK_SETTING_USAGE_KEY, [newSortOrder]);

    const hideDeletedItems = null;
    await saveConversationsOption(
        listViewType,
        newSortOrder,
        hideDeletedItems,
        readingPaneConversationEnabled
    );
}

export async function saveConversationsOptions(
    listViewType: ReactListViewType,
    readingPaneConversationEnabled?: boolean,
    newSortOrder?: ConversationSortOrder
) {
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
    logUsage(OPTION_PROGRAMMATIC_USAGE_KEY, [newSortOrder]);

    const hideDeletedItems = null;
    await saveConversationsOption(
        listViewType,
        newSortOrder,
        hideDeletedItems,
        readingPaneConversationEnabled
    );
}
