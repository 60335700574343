import { getCurrentCulture } from '../selectors/getCurrentCulture';
import type { LocalizedString } from '../LocalizedString';

/**
 * The set of types that can be used as arguments to the format function:
 * - Strings are fine and inserted as-is.
 * - Numbers are formatted according to the current culture. They will include thousands separators and decimal points
 *   and those can vary from culture to culture. For example, 1234567.1 will be formatted as 1,234,567.1 in en-US and
 *   1.234.567,1 in pt-BR. For other options, use Intl.NumberFormat.
 * - Null and undefined are treated as empty strings.
 *
 * Note that the format function does not support formatting dates, booleans, arrays, objects or other types:
 * - OwaDates should be formatted using the formatUserDateTime function or some other formatter from owa-datetime-formatters.
 * - Booleans should NOT be used because they would end up as simple 'true' or 'false' strings and not localized.
 *   Use separate string resources for each case instead.
 * - Objects would have ended up as '[object Object]', pretty pointless.
 * - Arrays would have ended up as comma-separated lists of values with no space between the items; this is not globalization
 *   friendly. Instead, applications should format lists using Intl.ListFormat and choosing conjunction (AND) or
 *   disjunction (OR) options as appropriate.
 */
export type FormatArg = string | number | null | undefined;

export function format<T extends LocalizedString | string>(formatString: T, ...args: FormatArg[]) {
    const userCulture = getCurrentCulture() || 'en-US';
    let output = '';

    function stringify(value: string | number): string {
        try {
            return value.toLocaleString(userCulture);
        } catch (err) {
            if ((err as Error).name === 'RangeError') {
                // If the browser doesn't recognize the culture, it can throw a RangeError.
                // Retry without the user culture and rely on the browser's configured culture
                return value.toLocaleString();
            } else {
                // No idea why this could happen, but just return a non-localized string
                return value.toString();
            }
        }
    }

    for (let i = 0; ; ) {
        var o = formatString.indexOf('{', i);
        var f = formatString.indexOf('}', i);

        if (o < 0 && f < 0) {
            output += formatString.slice(i);
            break;
        }
        if (f > 0 && (f < o || o < 0)) {
            output += formatString.slice(i, f + 1);
            i = f + 2;
            continue;
        }
        output += formatString.slice(i, o);
        i = o + 1;
        if (formatString.charAt(i) === '{') {
            output += '{';
            i++;
            continue;
        }
        if (f < 0) {
            break;
        }
        var s = formatString.substring(i, f);
        var l = parseInt(s, 10);
        var r = args[l];

        if (r !== undefined && r !== null) {
            output += stringify(r);
        }

        i = f + 1;
    }

    return output as T extends LocalizedString ? T : string;
}
