import { getDensityModeString } from 'owa-fabric-theme';
import { getListViewMaxWidth } from './getMaxWidths';
import { RECEIVED_COLUMN_WIDTH } from '../internalConstants';
export function getListViewColumnDefaultWidths(): {
    senderColumnWidth: number;
    subjectColumnWidth: number;
    receivedColumnWidth: number;
} {
    // Default sender column width is 174 in compact/cozy density mode and 178 in full density mode
    // Total min width of the sender column plus padding is 236px;
    const defaultSenderColumnWidth = getDensityModeString() === 'full' ? 174 : 178;

    const defaultSubjectColumnWidth =
        getListViewMaxWidth() - defaultSenderColumnWidth - RECEIVED_COLUMN_WIDTH;

    return {
        senderColumnWidth: defaultSenderColumnWidth,
        subjectColumnWidth: defaultSubjectColumnWidth,
        receivedColumnWidth: RECEIVED_COLUMN_WIDTH,
    };
}
