import { lazyUpdateHotkeysMapStore } from 'owa-hotkeys-map';
import { lazyMountAndShowFullOptions } from 'owa-options-view';
import { getCommandCategories, getCommands } from '../utils/MailModuleHotKeys';
import type { HotkeyCommand } from 'owa-hotkeys';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { owaComputedFn } from 'owa-computed-fn';

export function getMailHotkeysMapProperties() {
    const goToOptions = () => {
        lazyMountAndShowFullOptions.importAndExecute(
            'general',
            'accessibility',
            undefined /*option*/,
            window,
            getModuleContextMailboxInfo()
        );
    };

    /**
     * KeyboardShortcutsMap requires an array of HotkeyCommands, so convert
     * the object from MailModuleHotKeys to an array.
     */
    return {
        commandCategories: getCommandCategories(),
        goToOptions,
        hotkeyCommands: Object.values(getCommands()),
    };
}

const deepCopyHotkeyCommands = owaComputedFn(function (hotkeyCommands: HotkeyCommand[]) {
    return hotkeyCommands.map(key => ({ ...key } as HotkeyCommand));
});

export function setupMailHotkeysMapProperties() {
    const { commandCategories, hotkeyCommands } = getMailHotkeysMapProperties();

    const copiedHotkeyCommands = deepCopyHotkeyCommands(hotkeyCommands);

    lazyUpdateHotkeysMapStore.importAndExecute(commandCategories, copiedHotkeyCommands);
}
