import { isConnectedAccount } from 'owa-accounts-store';
import { getConnectedAccountHeaders } from 'owa-connected-account-headers';
import { createRestApiRequestHeadersWithFetchOptions } from './createRestApiRequestHeadersWithFetchOptions';

export function createRestApiRequestHeaders(userIdentity: string): Promise<Record<string, string>> {
    if (isConnectedAccount(userIdentity)) {
        return createRestApiRequestHeadersWithFetchOptions().then(headers => {
            const authHeadersPromise = getConnectedAccountHeaders(userIdentity);

            // Merge the connected account headers with the rest api request headers
            return authHeadersPromise.then(authHeaders => {
                Object.keys(authHeaders).forEach(
                    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
                     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
                     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
                    headerName => (headers[headerName] = authHeaders[headerName])
                );

                return headers;
            });
        });
    }
    return Promise.resolve({});
}
