// extracted by mini-css-extract-plugin
export var oneRowAttachmentPreviews = "dapZe";
export var oneRowAttachmentPreviewsCompact = "MVXvJ";
export var oneRowAttachmentPreviewsMedium = "xcoGG";
export var plusMoreNumber = "evkUo";
export var shortWellHeightFull = "pTvY_";
export var shortWellHeightMediumOrCompact = "f_Pqq";
export var shortWellLineHeightFull = "_EBYO";
export var shortWellLineHeightMediumOrCompact = "y1bNm";
export var wellHeightFull = "cCkOR";
export var wellHeightMediumOrCompact = "C_rfr";