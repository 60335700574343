import getOcpsTokenForBusiness from './getOcpsTokenForBusiness';
import { buildAuthorizationHeader } from 'owa-tokenprovider-utils';
import type { MailboxInfo } from 'owa-client-types';

export default async function getBusinessUserOcpsPolicies(
    fetchPoliciesEndpoint: string,
    tokenResourceUrl: string,
    mailboxInfo: MailboxInfo,
    correlationId: string
): Promise<Response> {
    const token = await getOcpsTokenForBusiness(tokenResourceUrl, mailboxInfo);

    const headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('Authorization', buildAuthorizationHeader(token as string));
    headers.append('x-Cid', correlationId);
    headers.append('CallerAppHint', 'OwaOcpsPolicy');

    const requestInit: RequestInit = {
        method: 'GET',
    };

    requestInit.headers = headers;

    return fetch(fetchPoliciesEndpoint, requestInit);
}
