import React from 'react';
import type { DraggablePropsSync } from './Draggable';
import { DraggableCore } from './Draggable';
import type { DroppableProps } from './Droppable';
import { DroppableCore } from './Droppable';
import { useConst } from '@fluentui/react-hooks';

export interface DragAndDroppableProps extends DraggablePropsSync, DroppableProps {
    children?: React.ReactNode;
}

export interface DragAndDroppableHandle {
    getDiv(): HTMLDivElement | undefined;
}

// TODO: Bug 14032: Consider a framework-independent design of owa-dnd
export default React.forwardRef(function DragAndDroppable(
    props: DragAndDroppableProps,
    ref: React.Ref<DragAndDroppableHandle>
) {
    React.useEffect(() => {
        return () => {
            dragCore.onUnmount();
        };
    }, []);
    const dragCore = useConst<DraggableCore>(() => new DraggableCore(props));
    const dropCore = useConst<DroppableCore>(() => new DroppableCore(props));
    dragCore.updateProps(props);
    dropCore.updateProps(props);
    React.useImperativeHandle(
        ref,
        () => ({
            getDiv() {
                return dragCore.getDiv();
            },
        }),
        []
    );
    return (
        <div
            draggable={props.isDraggable ? props.isDraggable() : true}
            className={props.classNames}
            ref={dragCore.refCallback}
            onDragStart={dragCore.onDragStart}
            onDragEnd={dragCore.onDragEnd}
            onDrop={dropCore.onDrop}
            onDragOver={dropCore.onDragOver}
            onDragEnter={dropCore.onDragEnter}
            onDragLeave={dropCore.onDragLeave}
        >
            {props.children}
        </div>
    );
});
