import type { MailboxInfo } from 'owa-client-types';
import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import { getOpxHostApi, isOpxHostInitialized } from 'owa-opx';
import { isResourceTokenPrefetchEnabled } from './isResourceTokenPrefetchEnabled';
import { getConfig } from 'owa-service/lib/config';

export async function getResourceToken(
    resource: string,
    wwwAuthenticateHeader?: string,
    mailboxInfo?: MailboxInfo,
    scope?: string
): Promise<TokenResponse | undefined> {
    const opxHostApi = isOpxHostInitialized() ? getOpxHostApi() : undefined;
    // for Monarch we dont rely on opxHostApi anymore, instead get it from config.
    const serviceConfig = getConfig();
    const getResourceTokenFromHost = opxHostApi?.getResourceToken ?? serviceConfig.getResourceToken;
    if ((await isResourceTokenPrefetchEnabled()) && getResourceTokenFromHost) {
        return getResourceTokenFromHost(resource, wwwAuthenticateHeader, mailboxInfo, scope);
    }

    return Promise.resolve(undefined);
}
