import type { ResolverContext } from 'owa-graph-schema';
import type { FetchPolicy } from '@apollo/client';
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { possibleTypes } from 'owa-graph-schema-possible-types';
import type { TypedTypePolicies } from 'owa-graph-schema-type-policies';

export function createApolloClient({
    links,
    context,
    bootTypePolicies,
}: {
    links: Array<ApolloLink>;
    context: Partial<ResolverContext>;
    bootTypePolicies: TypedTypePolicies;
}) {
    /**
     * For phase 1, where we intend to use GQL only for network layer abstraction
     * by default we are disabling the apollo cache as we would still rely on Mobx stores/caching.
     * For scenarios which wish to use Apollo caching either because
     * 1. They are new scenarios that intend to create new stores OR
     * 2. They are moving to phase 2 from 1 during this migration
     *
     * should use override caching policy.
     */
    const noCacheFetchPolicy: FetchPolicy = 'no-cache';
    const defaultOptions = {
        watchQuery: {
            fetchPolicy: noCacheFetchPolicy,
        },
        query: {
            fetchPolicy: noCacheFetchPolicy,
        },
    };

    const apolloClient = new ApolloClient({
        cache: new InMemoryCache({
            possibleTypes,
            typePolicies: bootTypePolicies,
        }),
        defaultOptions,
        connectToDevTools: process.env.NODE_ENV === 'dev',
    });

    context.client = apolloClient;
    apolloClient.setLink(ApolloLink.from(links));

    return apolloClient;
}
