import type VirtualizedMailGroupHeadersStore from '../store/schema/VirtualizedMailGroupHeadersStore';
import type VirtualizedGroupHeader from '../type/VirtualizedGroupHeader';
import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';
import type { GroupHeaderId } from '../type/GroupHeaderId';

const VirtualizedMailListGroupHeaderDefaultStore: VirtualizedMailGroupHeadersStore = {
    virtualizedGroupHeaders: new ObservableMap<GroupHeaderId, VirtualizedGroupHeader>({}),
};

const store = createStore<VirtualizedMailGroupHeadersStore>(
    'VirtualizedMailGroupHeader',
    VirtualizedMailListGroupHeaderDefaultStore
)();

export default function getStore() {
    return store;
}
