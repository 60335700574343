import type NativeadsUpsellEffectState from './schema/NativeadsUpsellEffectState';
import { createStore } from 'satcheljs';

const nativeadsUpsellEffectState: NativeadsUpsellEffectState = {
    isEffective: false,
};

const store = createStore<NativeadsUpsellEffectState>(
    'nativeadsUpsellEffectState',
    nativeadsUpsellEffectState
)();
export default store;
export const getStore = () => store;
