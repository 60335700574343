import { getLocalizedStringStore } from '../store/store';

/**
 * Look up current locale used to localize UX content for the user
 *
 * This is agnostic of backing storage or config, which can vary by runtime context (e.g. OWA, OPX, etc)
 */
export function getCurrentLanguage() {
    return getLocalizedStringStore().currentLocale;
}

/**
 * Returns the current language from the user's currently selected locale
 */
export function getCurrentLanguageFromCurrentLocale() {
    const currentLocale = getCurrentLanguage(); // en-US
    const countryBoundary = '-';

    if (!currentLocale.includes(countryBoundary)) {
        return currentLocale;
    }

    return currentLocale.split(countryBoundary)[0];
}
