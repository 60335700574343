import type { MailboxInfo } from 'owa-client-types';
import { getGroupsState } from './getGroupsState';
import { owaComputedFn } from 'owa-computed-fn';

const getDeletedGroupsList = owaComputedFn((mailboxInfo: MailboxInfo): string[] => {
    const deletedGroups: string[] = [];
    getGroupsState(mailboxInfo).groups.forEach(group => {
        if (!!group.basicInformation?.WhenSoftDeletedUtc && !!group.basicInformation.SmtpAddress) {
            deletedGroups.push(group.basicInformation.SmtpAddress);
        }
    });
    return deletedGroups;
});

export default getDeletedGroupsList;
