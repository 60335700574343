import React from 'react';
import type { ICheckboxProps } from '@fluentui/react/lib/Checkbox';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import {
    mclStyleOverride,
    checkmarkStyles,
    squareCheckbox,
    selectedMessageCheckboxStyle,
    indeterminateOrCheckedStyle,
} from './Checkbox.scss';

import { observer } from 'owa-mobx-react';

import classnames from 'owa-classnames';

export interface FluentCheckboxProps extends ICheckboxProps {
    isMessageSelected?: boolean;
    shouldOverrideStyleInMCL?: boolean;
}

export default observer(function FluentCheckbox(props: FluentCheckboxProps) {
    const { checked, indeterminate, isMessageSelected, shouldOverrideStyleInMCL } = props;

    const checkboxStyles = React.useMemo(
        () => ({
            ...props.styles,
            checkbox: classnames(
                squareCheckbox,
                shouldOverrideStyleInMCL && mclStyleOverride,
                isMessageSelected && selectedMessageCheckboxStyle,
                (checked || indeterminate) && indeterminateOrCheckedStyle
            ),
            checkmark: checkmarkStyles,
        }),
        [props.styles, isMessageSelected, checked, indeterminate, shouldOverrideStyleInMCL]
    );

    return <Checkbox {...props} styles={checkboxStyles} />;
}, 'FluentCheckbox');
