import {
    getSelectedTableView,
    getListViewState,
    MailRowDataPropertyGetter,
} from 'owa-mail-list-store';
import type { ActionSource } from 'owa-analytics-types';
import { lazyPrintConversation, lazyPrintItem } from 'owa-mail-message-actions';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { resetFocus } from 'owa-mail-focus-manager';
import getExpandedItemId from '../utils/getExpandedItemId';

export default function onPrint(actionSource: ActionSource, targetWindow?: Window) {
    const tableView = getSelectedTableView();
    const rowKeys = [...tableView.selectedRowKeys.keys()];

    const nodeIds = getListViewState().expandedConversationViewState.selectedNodeIds;
    if (nodeIds.length === 1) {
        const clientItemId = getExpandedItemId(tableView, nodeIds);
        lazyPrintItem.importAndExecute(clientItemId /* itemId */, actionSource, targetWindow);
    } else {
        const selectedRowId = MailRowDataPropertyGetter.getRowClientItemId(rowKeys[0], tableView);
        switch (tableView.tableQuery.listViewType) {
            case 0:
                lazyPrintConversation.importAndExecute(
                    selectedRowId /* conversationId */,
                    actionSource,
                    targetWindow
                );
                break;

            case 1:
                lazyPrintItem.importAndExecute(
                    selectedRowId /* itemId */,
                    actionSource,
                    targetWindow
                );
                break;
        }
    }

    resetFocus('printCommand');
}
