import { getAccountKeyForMailboxInfo } from 'owa-client-types';
import type { MailboxInfo } from 'owa-client-types';
import { logUsage } from 'owa-analytics';
import { getItem, setItem, itemExists } from 'owa-local-storage';
import { USER_OCPS_POLICY_STORE } from './constants';
import type { OcpsPolicyStore } from '../store/schema/OcpsPolicyStore';

export function onAccountRemoved(mailboxInfo: MailboxInfo) {
    const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);
    let ocpsPolicyStore: OcpsPolicyStore;

    if (itemExists(window, USER_OCPS_POLICY_STORE)) {
        ocpsPolicyStore = JSON.parse(
            getItem(window, USER_OCPS_POLICY_STORE) ?? JSON.stringify(null)
        );

        if (ocpsPolicyStore.policies[accountKey]) {
            delete ocpsPolicyStore.policies[accountKey];
            setItem(window, USER_OCPS_POLICY_STORE, JSON.stringify(ocpsPolicyStore));
            logUsage('OcpsStore_AccountRemoved');
        }
    }
}
