import { getOcpsPolicyFromLocalStore, OcpsPolicy } from 'owa-ocps-policy-store';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import type { MailboxInfo } from 'owa-client-types';

// Get LoopUnfurlForThirdPartyApp setting from OCPS
// if undefined then by default card loops will be blocked for exteran messages
export function isCardLoopsBlockedForExtMsgByAdmin(mailboxinfo?: MailboxInfo): boolean {
    if (!mailboxinfo) {
        mailboxinfo = getModuleContextMailboxInfo();
    }

    const policyState = getOcpsPolicyFromLocalStore(mailboxinfo, OcpsPolicy.ShowCardLoops);

    if (policyState) {
        if (policyState === 'Enabled') {
            return false;
        } else if (policyState === 'Disabled') {
            return true;
        }
    }

    return true;
}
