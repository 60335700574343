import { isAccountSourceListStoreInitialized } from 'owa-account-source-list-store';
import { logStartGreyError } from 'owa-analytics-start';
import type { MailboxInfo } from 'owa-client-types';
import { getAccountSettingsInternal } from './getAccountSettingsInternal';
import { mailboxInfoOrTemporaryGlobal } from './mailboxInfoOrTemporaryGlobal';
import { getSharedMailboxFolderInformationMap } from './sharedMailboxFolderInformationUtils';

export default function folderNameToId(
    folderName: string,
    mailboxInfo?: MailboxInfo,
    shouldSearchShared?: boolean
): string {
    if (!folderName) {
        const error = new Error('FolderNameToIdInvalidParameters');
        logStartGreyError('FolderNameToIdInvalidParameters', error);
        return '';
    }

    if (!isAccountSourceListStoreInitialized()) {
        const error = new Error('FolderNameToIdUninitializedAccountSourceListStore');
        logStartGreyError('FolderNameToIdUninitializedAccountSourceListStore', error);
        return '';
    }

    if (shouldSearchShared && mailboxInfo?.type == 'SharedMailbox') {
        const mailboxInformation = getSharedMailboxFolderInformationMap(mailboxInfo);
        if (mailboxInformation) {
            const folderId = mailboxInformation.defaultFolderNameToIdMap?.get(folderName);
            return folderId ?? '';
        }
    }

    const settings = getAccountSettingsInternal(mailboxInfoOrTemporaryGlobal(mailboxInfo));
    const folderId = settings?.defaultFolderNameToIdMap?.get(folderName);
    return folderId ?? '';
}
