import React from 'react';
import type { AttachmentPickers } from 'owa-mail-ribbon-utils';
import type AttachmentMultiPicker from 'owa-attachment-filepicker/lib/types/AttachmentMultiPicker';
import type { CommandRibbonProps } from 'owa-command-ribbon';
import { GovernPriority } from 'owa-tti';
import type { GovernedTask } from 'owa-tti/lib/types/GovernedTask';
import { type HelpRibbonTabId } from 'owa-ribbon-ids/lib/helpControlId';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { owaComputedFn } from 'owa-computed-fn';
import { getComposeViewState } from 'owa-mail-reading-pane-store-conversation/lib/utils/getComposeViewState';
import { getExtraTopRibbonControlsAfterTabs } from './util/getExtraTopRibbonControlsAfterTabs';
import { getExtraTopRibbonControlsBeforeTabs } from './util/getExtraTopRibbonControlsBeforeTabs';
import { getMailControlRendererFunction } from './util/getMailControlRendererFunction';
import { MailIconColorMap } from './util/mailIconColorMap';
import { getMailRibbonDefinitionsMLR } from './components/MLR/mailMultilineRibbonDefinition';
import { getMailRibbonDefinitionsSLR } from './components/SLR/mailSLRRibbonDefinition';
import { getSelectedTab } from 'owa-mail-ribbon-store/lib/store/store';
import { isNotesScenario } from 'owa-mail-ribbon-utils/lib/isNotesScenario';
import { lazyModule as lazyActionControlsSectionsModule } from 'owa-compose-actioncontrols-sections/lib/sections';
import { lazyModule as lazyFormatControlsSectionsModule } from 'owa-compose-formatcontrols-sections/lib/sections';
import { lazyModule as lazyMailComposeControlsSectionsModule } from 'owa-mail-compose-control-sections/lib/sections';
import { lazyModule as lazyRibbonSectionsModule } from 'owa-mail-ribbon-sections/lib/sections';
import { CopilotCommandCenter } from 'owa-copilot-command-center';
import saveSelectedTab from 'owa-mail-ribbon-store/lib/actions/saveSelectedTab';

const getAttachmentPickers = owaComputedFn(
    (
        attachmentMultiPicker: AttachmentMultiPicker,
        attachmentInlinePickers: AttachmentMultiPicker
    ): AttachmentPickers => {
        return {
            addFilesAsAttachments: attachmentMultiPicker,
            addImagesInline: attachmentInlinePickers,
        };
    }
);

export const getMailCommandRibbonProps = owaComputedFn(
    (
        attachmentInlinePickers: AttachmentMultiPicker,
        attachmentMultiPicker: AttachmentMultiPicker
    ): CommandRibbonProps => {
        const attachmentPickers = getAttachmentPickers(
            attachmentMultiPicker,
            attachmentInlinePickers
        );
        return {
            getSingleLineRibbonDefinition: () => getMailRibbonDefinitionsSLR(attachmentPickers),
            getMultiLineRibbonDefinition: () => getMailRibbonDefinitionsMLR(attachmentPickers),
            iconColorMap: MailIconColorMap,
            ribbonScenarioName: 'Mail',
            controlRendererFunction: getMailControlRendererFunction,
            getDefaultSelectedTabId,
            onTabClick,
            getExtraTopRibbonControlsBeforeTabs,
            getExtraTopRibbonControlsAfterTabs,
            copilotCommandCenter: <CopilotCommandCenter />,
            thisWindow: window,
            governedTasks: getMailRibbonGovernedTasks(),
        };
    }
);

const getMailRibbonGovernedTasks = owaComputedFn((): GovernedTask[] => {
    return [
        {
            // Import everything in owa-mail-ribbon-sections/lib/sections, such that sections are ready to click.
            task: () => lazyRibbonSectionsModule.importModule('MailCommandRibbonProps'),
            priority: GovernPriority.Ribbon,
            name: 'lazyRibbonSectionsModule',
        },
        {
            // Import everything in owa-compose-formatcontrols-sections/lib/sections, such that sections are ready to click.
            task: () => lazyFormatControlsSectionsModule.importModule('FormatControlsProps'),
            priority: GovernPriority.Compose,
            name: 'lazyFormatControlsSectionsModule',
        },
        {
            // Import everything in owa-compose-actioncontrols-sections/lib/sections, such that sections are ready to click.
            task: () => lazyActionControlsSectionsModule.importModule('ActionControlsProps'),
            priority: GovernPriority.Compose,
            name: 'lazyActionControlsSectionsModule',
        },
        {
            // Import everything in owa-mail-compose-control-sections/lib/sections, such that sections are ready to click.
            task: () =>
                lazyMailComposeControlsSectionsModule.importModule('MailComposeControlsProps'),
            priority: GovernPriority.Compose,
            name: 'lazyMailComposeControlsSectionsModule',
        },
    ];
});

const onTabClick = (tabId: string, targetWindow: Window) => {
    // Saves the selected tab when a new tab is clicked
    saveSelectedTab(tabId, targetWindow);
};

const readOnlyRibbonTabsArray = [1, 3, 11000];

/**
 * Returns the appropriate default Ribbon ID based on the scenario.
 * This is the function that will force certain RibbonTabs such as Message and Notes.
 */
const getDefaultSelectedTabId = (targetWindow: Window) => {
    const composeViewState = getComposeViewState();
    if (composeViewState?.isComposeRendered) {
        const selectedTab = getSelectedTab(targetWindow);
        return selectedTab;
    }

    if (isNotesScenario()) {
        return 6;
    }

    // Not in a forced tab scenario, so just return current selected tab.
    const currentStoreSelectedTab = getSelectedTab(targetWindow);
    if (readOnlyRibbonTabsArray.includes(currentStoreSelectedTab)) {
        return currentStoreSelectedTab;
    } else {
        // if it's read-only reading-pane tab and the selected ribbon tab doesn't exist on the read-only reading pane tab, then move it to default ("Home")
        return 1;
    }
};
