import type { MailboxInfo } from 'owa-client-types';
import type NewMailNotificationPayload from 'owa-service/lib/contract/NewMailNotificationPayload';
import { action } from 'satcheljs';

export default action(
    'NEW_MAIL_NOTIFICATION',
    (notification: NewMailNotificationPayload, mailboxInfo?: MailboxInfo) => ({
        notification,
        mailboxInfo,
    })
);
