import getResizeEventEmitter from './getResizeEventEmitter';
import eventName from './store/schema/eventName';

export default function subscribe(
    callback: () => void,
    key: string = '',
    windowRef: Window | null = null
): () => void {
    const eventEmitter = getResizeEventEmitter(key, windowRef);
    eventEmitter.on(eventName, callback);
    return () => {
        eventEmitter.off(eventName, callback);
    };
}
