import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { mutatorAction } from 'satcheljs';
import { getStore } from '../store/Store';
import { getListViewColumnDefaultWidths } from '../utils/getListViewColumnDefaultWidths';
import { isFeatureEnabled } from 'owa-feature-flags';
import { RECEIVED_COLUMN_WIDTH } from '../internalConstants';
import { getItem } from 'owa-local-storage';

const initializeListViewColumnWidths = mutatorAction('initializeListViewColumnWidths', () => {
    const { senderColumnWidth, subjectColumnWidth, receivedColumnWidth } =
        getListViewColumnWidthSettings();

    getStore().senderColumnWidth = senderColumnWidth;
    getStore().subjectColumnWidth = subjectColumnWidth;
    getStore().receivedColumnWidth = receivedColumnWidth;
});

/**
 * This function gets the default ML column width header values to render with
 * at boot. The value will be from local storage (previous session's column widths),
 * OWS' settings, or the default values (in the case where the user hasn't modified
 * the column widths yet).
 *
 * Note that we're getting these values from the UserConfiguration object instead
 * of from the "owa-outlook-service-options" package for boot bundle size health.
 */
const getListViewColumnWidthSettings = () => {
    const previouslySavedColumnWidths = getPreviouslySavedColumnWidths();
    if (previouslySavedColumnWidths) {
        return previouslySavedColumnWidths;
    }

    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();

    const isRelocateHoverActionsFlightEnabled = isFeatureEnabled('tri-mlRelocateHoverActions');

    const primeSettingsItems = getAccountScopeUserSettings(mailboxInfo)?.PrimeSettings?.Items;
    const primeListViewColumnHeadersOptions: any[] | undefined = primeSettingsItems?.filter(
        item => item?.Id == 'ListViewColumnHeadersOptions'
    );
    const listViewColumnHeadersWidths = primeListViewColumnHeadersOptions?.[0]?.Value?.options?.[0];

    const defaultWidths = getListViewColumnDefaultWidths();

    const senderColumnDefaultWidth = isRelocateHoverActionsFlightEnabled
        ? defaultWidths.senderColumnWidth
        : 332;

    const subjectColumnDefaultWidth = isRelocateHoverActionsFlightEnabled
        ? defaultWidths.subjectColumnWidth
        : 1000;

    // always initialize received  column to 80 pixels
    const receivedColumnDefaultWidth = isRelocateHoverActionsFlightEnabled
        ? RECEIVED_COLUMN_WIDTH
        : listViewColumnHeadersWidths?.receivedColumnWidth || 150;

    return {
        senderColumnWidth:
            listViewColumnHeadersWidths?.senderColumnWidth || senderColumnDefaultWidth,
        subjectColumnWidth:
            listViewColumnHeadersWidths?.subjectColumnWidth || subjectColumnDefaultWidth,
        receivedColumnWidth: receivedColumnDefaultWidth,
    };
};

export default initializeListViewColumnWidths;

const getPreviouslySavedColumnWidths = () => {
    const previousColumnWidthValues = getItem(window, 'MailListColumnWidths');

    if (previousColumnWidthValues) {
        const parsedPreviousColumnWidthValues = JSON.parse(previousColumnWidthValues);
        return {
            senderColumnWidth: parsedPreviousColumnWidthValues.senderColumnWidth,
            subjectColumnWidth: parsedPreviousColumnWidthValues.subjectColumnWidth,
            receivedColumnWidth: parsedPreviousColumnWidthValues.receivedColumnWidth,
        };
    }

    return null;
};
