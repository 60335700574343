import { LazyAction, LazyBootModule } from 'owa-bundling-light';

const lazyBootModule = new LazyBootModule(
    () => import(/* webpackChunkName: "StartAccountDataGql" */ './lazyIndex')
);

export const lazyGetStartAccountDataViaGql = new LazyAction(
    lazyBootModule,
    m => m.getStartAccountDataViaGql
);
