import type { CommandingOptions } from 'owa-outlook-service-option-store';
import { lazyGetServerOptionsForFeature } from 'owa-outlook-service-options';
import { OwsOptionsFeatureType } from 'owa-outlook-service-option-store';

export async function getCommandingOptionsFromServer() {
    const getServerOptionsForFeature = await lazyGetServerOptionsForFeature.import();
    const serverOptions =
        getServerOptionsForFeature &&
        (await getServerOptionsForFeature<CommandingOptions>(OwsOptionsFeatureType.Commanding));

    return serverOptions;
}
