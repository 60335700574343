import {
    getGlobalSettingsAccount,
    getGlobalSettingsAccountIndexer,
} from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { isPersistenceIdIndexerEnabled } from 'owa-client-types/lib/isPersistenceIdIndexerEnabled';
import { errorThatWillCauseAlert } from 'owa-trace';

export default function getFeatureFlagsIndexerForMailboxInfo(
    mailboxInfo: MailboxInfo | undefined | null,
    errorIfNoAccountKey?: boolean
): string {
    if (isPersistenceIdIndexerEnabled()) {
        if (mailboxInfo) {
            return getIndexerValueForMailboxInfo(mailboxInfo);
        }

        const globalSettingsAccountKey = getGlobalSettingsAccountIndexer(
            /*throwIfNotInitialized*/ false
        );

        if (!globalSettingsAccountKey && errorIfNoAccountKey) {
            // We should not be trying to access flights or application settings before the global account is initialized
            errorThatWillCauseAlert(
                'FeatureFlagsIndexerForMailboxInfo: Global account not initialized'
            );
        }

        return globalSettingsAccountKey;
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return isGlobalFeaturesAccount(mailboxInfo) ? '' : getIndexerValueForMailboxInfo(mailboxInfo!);
}

/** Use the global account features if the global account sourceId is provide or, by default
 *  if no name is provided
 * @param mailboxInfo the mailboxInfo to test
 * @returns true if the mailboxInfo is associated with features for the global account.
 */
function isGlobalFeaturesAccount(mailboxInfo: MailboxInfo | undefined | null): boolean {
    if (!mailboxInfo) {
        return true;
    } else {
        const globalAccountInfo = getGlobalSettingsAccount()?.mailboxInfo;
        if (!globalAccountInfo) {
            return true;
        } else {
            return (
                getIndexerValueForMailboxInfo(globalAccountInfo) ===
                getIndexerValueForMailboxInfo(mailboxInfo)
            );
        }
    }
}
