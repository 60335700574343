// Due to some strange typing naming in lib.dom.d.ts, this is added as a workaround
export interface DataTransferWorkaround extends DataTransfer {
    setDragImage: (element: HTMLElement, x: number, y: number) => void;
}

// Mininum mouse movement for the potential drag
export const MIN_MOUSE_MOVE_FOR_DRAG = 5;

export const DEFAULT_ITEM_TYPE = 'nothing';

// Firefox includes an additional value in the dataTransfer property
// we need to check this in order to permit the drag & drop of files
export const FIREFOX_DOCTYPE = 'application/x-moz-file';
