import virtualizedMailGroupHeadersStore from '../store/store';
import type { TableView } from 'owa-mail-list-store';

// Gets the indices of hidden rows from collapsed group headers
export default function getIndicesOfHiddenRowsInCollapsedGroupHeaders(
    tableView: TableView
): number[] {
    const hiddenRowIndices: number[] = [];
    const groupHeadersFromStore = virtualizedMailGroupHeadersStore().virtualizedGroupHeaders;

    for (const [_headerId, header] of groupHeadersFromStore.entries()) {
        if (header.isCollapsed) {
            const rowKeysInHeader = header.rowKeys;
            const firstRow = rowKeysInHeader[0];
            const startIndex = tableView.rowKeys.indexOf(firstRow);
            for (let i = startIndex; i < startIndex + rowKeysInHeader.length; i++) {
                hiddenRowIndices.push(i);
            }
        }
    }

    return hiddenRowIndices;
}
