import { filterBootstrapTitles } from './filterBootstrapTitles';
import { filterManifestVersion } from './filterManifestVersion';
import { filterBootIndicatorTitle } from './filterBootIndicatorTitle';

import type { M365Acquisition } from 'owa-m365-acquisitions/lib/types';

type AcquisitionFilter = (acquisition: M365Acquisition) => boolean;

/**
 * A list of functions that filter the acquisitions' cache set.
 */
const filterSteps: AcquisitionFilter[] = [
    filterBootstrapTitles,
    filterManifestVersion,
    filterBootIndicatorTitle,
];

/**
 * Runs a pipeline of filter steps on the acquisitions' cache set.
 *
 * For example, consider that the pipeline is composed of two filter steps: `F(A)` and `G(A)`.
 * The result of `F(A)` is passed to `G(A)`, and the result of `G(A)` is returned:
 * `INPUT & F(A) & G(A) => OUTPUT`
 * @param acquisitionRefs `Array<({__ref: 'M365Acquisition:{id}', __typename: 'M365Acquisition'})>`
 * @param cache
 * @returns
 */
export function filterAcquisitions(acquisitions: M365Acquisition[]): M365Acquisition[] {
    return filterSteps.reduce(
        (stepAcquisitions, filterStep) =>
            stepAcquisitions.filter(acquisition => !!acquisition && filterStep(acquisition)),
        acquisitions
    );
}
