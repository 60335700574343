import { getAccessIssuesForAttachments } from 'owa-attachment-policy-access-issue-checker';
import type { AttachmentWellViewState } from 'owa-attachment-well-data';
import {
    lazyGetAttachmentWellInitialValue,
    lazyInitializeAttachments,
    lazyReinitializeAttachments,
} from 'owa-attachment-well-data';
import type { ClientItem } from 'owa-mail-store';
import getSmimeAttachmentType from 'owa-smime/lib/utils/getSmimeAttachmentType';
import isSmimeDecoded from 'owa-smime/lib/utils/isSmimeDecoded';
import isSMIMEItem from 'owa-smime/lib/utils/isSMIMEItem';
import { mutatorAction } from 'satcheljs';
import type ItemViewState from '../store/schema/ItemViewState';
import registerGetAttachmentWellViewStateByItemHelper from '../utils/registerGetAttachmentWellViewStateByItemHelper';
import addInfoBarMessage from 'owa-info-bar/lib/actions/addInfoBarMessage';
import type Message from 'owa-service/lib/contract/Message';
import doesFolderIdEqualName from 'owa-session-store/lib/utils/doesFolderIdEqualName';
import type { ClientItemId } from 'owa-client-ids';

export default async function initializeAttachmentsForItem(
    itemViewState: ItemViewState,
    item: ClientItem | undefined,
    reinitialize: boolean = false
) {
    if (
        !item ||
        (isSMIMEItem(item) && !showSMIMEAttachments(itemViewState, item)) ||
        !item.Attachments
    ) {
        return;
    }
    const message = item as Message;
    const isJunkItem =
        !!message?.ParentFolderId?.Id &&
        doesFolderIdEqualName(message.ParentFolderId.Id, 'junkemail');

    if (!itemViewState.attachmentWell) {
        const getAttachmentWellInitialValue = await lazyGetAttachmentWellInitialValue.import();
        setAttachmentWellForItem(
            itemViewState,
            getAttachmentWellInitialValue(
                true /*isReadOnly*/,
                false /*isInitialized*/,
                '' /*composeId is empty in read only mode*/
            )
        );
    }

    registerGetAttachmentWellViewStateByItemHelper();

    const clientItemId: ClientItemId | undefined = item.ItemId
        ? {
              Id: item.ItemId.Id,
              mailboxInfo: item.MailboxInfo,
          }
        : undefined;

    if (reinitialize) {
        await lazyReinitializeAttachments.importAndExecute(
            item.Attachments,
            item.MailboxInfo,
            itemViewState.attachmentWell,
            true /* forceUpdateAttachmentsInStore */,
            undefined /* unsupportedMenuActions */,
            clientItemId /*parentItemId*/,
            !!item.RightsManagementLicenseData /* isMessageEncrypted */,
            isSMIMEItem(item),
            isProtectedUMVoiceMessageItemClass(item.ItemClass),
            isJunkItem
        );
    } else {
        await lazyInitializeAttachments.importAndExecute(
            item.Attachments,
            item.MailboxInfo,
            itemViewState.attachmentWell,
            true /* forceUpdateAttachmentsInStore */,
            false /* isInitializationForCompose */,
            undefined /* unsupportedMenuActions */,
            clientItemId /*parentItemId*/,
            !!item.RightsManagementLicenseData /* isMessageEncrypted */,
            isSMIMEItem(item),
            isProtectedUMVoiceMessageItemClass(item.ItemClass),
            isJunkItem
        );
    }

    const infoBarIds = getAccessIssuesForAttachments(item.Attachments, item.MailboxInfo);
    addInfoBarMessage(itemViewState, infoBarIds);

    const hasAttachments =
        (itemViewState.attachmentWell?.docViewAttachments &&
            itemViewState.attachmentWell?.docViewAttachments.length > 0) ||
        (itemViewState.attachmentWell?.imageViewAttachments &&
            itemViewState.attachmentWell?.imageViewAttachments.length > 0);
    if (isJunkItem && hasAttachments) {
        addInfoBarMessage(itemViewState, 'infoBlockJunkAttachments');
    }
}

const setAttachmentWellForItem = mutatorAction(
    'setAttachmentWellForItem',
    (itemViewState: ItemViewState, viewState: AttachmentWellViewState) => {
        itemViewState.attachmentWell = viewState;
    }
);

function showSMIMEAttachments(itemViewState: ItemViewState, item: ClientItem): boolean {
    // If the attachment type is not smime, it means this is not of p7m format and it should be treated as normal attachment and we should allow initialization
    if (!getSmimeAttachmentType(item)) {
        return true;
    }
    // For smime attachment types, we support them only in message view and after they have been decoded
    if (!itemViewState.isConversationItemPart && isSmimeDecoded(item)) {
        return true;
    }
    return false;
}

function isProtectedUMVoiceMessageItemClass(itemClass: string | undefined) {
    if (!itemClass) {
        return false;
    }
    switch (itemClass) {
        case 'IPM.Note.rpmsg.Microsoft.Voicemail.UM.CA':
        case 'IPM.Note.rpmsg.Microsoft.Voicemail.UM':
            return true;
        default:
            return false;
    }
}
