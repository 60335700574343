import { createStore } from 'satcheljs';
import type MessageAdListViewStatusStore from './schema/MessageAdListViewStatusStore';

// Import mutators so they are initialized at the same time as the store
import '../mutators/removeMessageAdListMutator';

const initialMessageAdListViewStatusStore: MessageAdListViewStatusStore = {
    selectedAdId: '',
    inboxFolderShowAdCount: -1,
    latestAdDeleteTime: 0,
    refreshAfterDeleteInterval: 0,
    isSuperClicker: false,
    adRemovedFolderIds: [],
    topFloatingAdDeleted: false,
    midFloatingAdDeleted: false,
    honorNonProactiveRefreshFlag: false,
};
const store = createStore<MessageAdListViewStatusStore>(
    'messageAdListViewStatusStore',
    initialMessageAdListViewStatusStore
)();
export default store;
export const getStore = () => store;
