import { getLocalizedString } from 'owa-datetime-formatters-core';
import { format } from 'owa-localize';
import { localizedFormatter } from './localizedFormatter';

export function getWeekDayMonthDayFormat(_dateFormat: string, _timeFormat: string): string {
    return format(
        getLocalizedString('weekDayMonthDayFormat'),
        getLocalizedString('MM'),
        getLocalizedString('dd')
    );
}

export default localizedFormatter(getWeekDayMonthDayFormat);
