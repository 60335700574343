import { mutatorAction } from 'satcheljs';
import type { FavoriteTreeData } from '../store/schema/FavoritesStore';
import favoritesStore from '../store/store';

export const setFavoriteTreeData = mutatorAction(
    'setFavoriteTreeData',
    (mailboxInfoKey: string, favoriteTreeData: FavoriteTreeData) => {
        favoritesStore.favoriteTreeData.set(mailboxInfoKey, favoriteTreeData);
    }
);
