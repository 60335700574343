import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailListJumpToPopover" */ './lazyIndex')
);

// Delay loaded components
export const LazyMailListJumpToPopover = createLazyComponent(
    lazyModule,
    m => m.MailListJumpToPopover
);

export { default as shouldShowJumpTo } from './utils/shouldShowJumpTo';
