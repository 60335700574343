import {
    lazyToggleRowPinnedState,
    lazyToggleRowsFlagState,
    lazySetItemsFlagStateFromItemIds,
    lazyMoveItemsBasedOnNodeIds,
} from 'owa-mail-triage-action';
import {
    lazyToggleRowReadState,
    lazyMarkItemsAsReadBasedOnNodeIds,
} from 'owa-mail-mark-read-actions';
import { getListViewState } from 'owa-mail-list-store';
import getItemForMailList from 'owa-mail-store/lib/selectors/getItemForMailList';
import type { ActionSource } from 'owa-analytics-types';
import type MailSwipeAction from 'owa-service/lib/contract/MailSwipeAction';
import type Message from 'owa-service/lib/contract/Message';
import type FlagStatus from 'owa-service/lib/contract/FlagStatus';
import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import getSelectedFolderMailboxInfoOrDefault from 'owa-mail-store/lib/utils/getSelectedFolderMailboxInfoOrDefault';
import { isFirstLevelExpanded } from 'owa-mail-list-store/lib/selectors/isConversationExpanded';

import { deleteItemPartRow, deleteRow, archiveRow } from './getClickHandler';

const archiveItemPartRow = async (
    tableViewId: string,
    nodeId: string,
    actionSource: ActionSource
) => {
    const moveItemsBasedOnNodeIds = await lazyMoveItemsBasedOnNodeIds.import();
    moveItemsBasedOnNodeIds(
        [nodeId],
        folderNameToId('archive', getSelectedFolderMailboxInfoOrDefault()),
        tableViewId,
        actionSource
    );
};

const togglePinRow = async (rowKey: string, tableViewId: string, actionSource: ActionSource) => {
    await lazyToggleRowPinnedState.importAndExecute(rowKey, tableViewId, actionSource);
};

const togglePinItemPartRow = async (
    tableViewId: string,
    _nodeId: string,
    actionSource: ActionSource
) => {
    const conversationRowKey = getListViewState().expandedConversationViewState?.expandedRowKey;

    if (conversationRowKey) {
        await lazyToggleRowPinnedState.importAndExecute(
            conversationRowKey,
            tableViewId,
            actionSource
        );
    }
};

const toggleReadRow = async (rowKey: string, tableViewId: string, actionSource: ActionSource) => {
    await lazyToggleRowReadState.importAndExecute(rowKey, tableViewId, actionSource);
};

const toggleReadItemPartRow = async (
    tableViewId: string,
    nodeId: string,
    actionSource: ActionSource
) => {
    const conversationRowKey = getListViewState().expandedConversationViewState?.expandedRowKey;

    if (conversationRowKey) {
        const isFirstLevelExpansion = isFirstLevelExpanded(conversationRowKey);

        const item: Message = getItemForMailList(nodeId, isFirstLevelExpansion) as Message;
        let isReadValueToSet = false;
        if (!item?.IsRead) {
            isReadValueToSet = true;
        }

        const markItemsAsReadBasedOnNodeIds = await lazyMarkItemsAsReadBasedOnNodeIds.import();
        markItemsAsReadBasedOnNodeIds(
            [nodeId],
            tableViewId,
            isReadValueToSet,
            true /* isExplicit */,
            actionSource
        );
    }
};

const toggleFlagRow = async (rowKey: string, tableViewId: string, actionSource: ActionSource) => {
    await lazyToggleRowsFlagState.importAndExecute([rowKey], tableViewId, actionSource);
};

const toggleFlagItemPartRow = async (
    tableViewId: string,
    nodeId: string,
    actionSource: ActionSource
) => {
    const conversationRowKey = getListViewState().expandedConversationViewState?.expandedRowKey;
    const isFirstLevelExpansion = isFirstLevelExpanded(conversationRowKey);

    const item: Message = getItemForMailList(nodeId, isFirstLevelExpansion) as Message;
    if (item?.ItemId) {
        let flagStatusValueToSet: FlagStatus;
        if (item?.Flag?.FlagStatus != 'Flagged') {
            flagStatusValueToSet = 'Flagged';
        } else {
            flagStatusValueToSet = 'NotFlagged';
        }

        await lazySetItemsFlagStateFromItemIds.importAndExecute(
            [item.ItemId.Id],
            [] /* InstrumentationContexts */,
            { FlagStatus: flagStatusValueToSet },
            tableViewId,
            actionSource
        );
    }
};

const SWIPE_ACTION_HANDLERS_ITEM = {
    [1]: deleteRow,
    [2]: archiveRow,
    [3]: togglePinRow,
    [4]: toggleReadRow,
    [5]: toggleFlagRow,
    [0]: null,
};

const SWIPE_ACTION_HANDLERS_ITEM_PART = {
    [1]: deleteItemPartRow,
    [2]: archiveItemPartRow,
    [3]: togglePinItemPartRow,
    [4]: toggleReadItemPartRow,
    [5]: toggleFlagItemPartRow,
    [0]: null,
};

export function getSwipeHandler(action: MailSwipeAction, isItemPart?: boolean) {
    if (isItemPart) {
        return SWIPE_ACTION_HANDLERS_ITEM_PART[action] || null;
    } else {
        return SWIPE_ACTION_HANDLERS_ITEM[action] || null;
    }
}
