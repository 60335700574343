import { store, isCloudCacheLoaded } from '../store/store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { logCoreUsage, logUsage } from 'owa-analytics';
import { type DatapointVariant } from 'owa-analytics-types';
import { isEasiIdUser } from './isEasiIdUser';

export function isEasiIdUserWithoutCloudCache() {
    if (!isEasiIdUser()) {
        return false;
    }

    // it is necessary to check if cloudcache is loaded before believe that we
    // have a valid information.
    if (!isCloudCacheLoaded()) {
        return false;
    }

    if (store.cloudCacheConfigItem.emailAddress) {
        if (isFeatureEnabled('growth-easiIdGmailCloudCacheV2')) {
            logCoreUsage('isEasiIdGmailUserWithCloudCacheV2', undefined, {
                // this function is called more than once per session,
                // so we added this flag to log only the first datapoint
                variant: 2,
            });
        } else {
            logCoreUsage('isEasiIdGmailUserWithCloudCache', undefined, {
                // this function is called more than once per session,
                // so we added this flag to log only the first datapoint
                variant: 2,
            });
        }

        return false;
    }

    if (isFeatureEnabled('growth-easiIdGmailCloudCacheV2')) {
        logUsage('isEasiIdGmailUserWithoutCloudCacheV2', undefined, {
            variant: 2,
        });
    } else {
        logUsage('isEasiIdGmailUserWithCloudCache', undefined, {
            variant: 2,
        });
    }

    return true;
}
