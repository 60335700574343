import { lazyLaunchAccountConfigDialog } from 'owa-account-config';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list/lib/flights';
import {
    getAccountConfigDialogContextData,
    stripContextDataFromQueryParam,
} from 'owa-add-account-context/lib/utils/accountConfigDialogContextData';

export default function addAccountDialogRouteHandler() {
    const { dialogTrigger, fallbackData, originContext } = getAccountConfigDialogContextData() || {
        dialogTrigger: 'Referral', // Since this route can only be reached via referral.
    };

    if (isMonarchMultipleAccountsEnabled()) {
        lazyLaunchAccountConfigDialog.importAndExecute(
            dialogTrigger,
            undefined,
            fallbackData,
            originContext
        );
    }

    stripContextDataFromQueryParam();
}
