import type { ShellControl } from '@1js/suiteux-shell-react';
// Render the region
function renderLeftCharmsSpacer(container: HTMLDivElement) {
    container.setAttribute('style', 'flex: 0 0 auto;min-width:' + 0 + 'px;display:flex');
}
// Define the shell control that will display the left charms region
export const LeftCharmsSpacer = {
    id: 'leftCharmsSpacer',
    flex: '1 1 auto',
    render: renderLeftCharmsSpacer,
} as ShellControl;

export function setLeftCharmsRegionWidth(leftCharmsRegionId: string, leftCharmsWidth: number) {
    const leftCharmsRegion = document.getElementById(leftCharmsRegionId);
    leftCharmsRegion?.setAttribute(
        'style',
        'flex: 0 0 auto;min-width:' + leftCharmsWidth + 'px;display:flex'
    );
}
