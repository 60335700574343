import type { MailboxInfo } from 'owa-client-types';
import { generateMailboxSmtpAddress } from 'owa-folders';
import { getAccountScopeUserSettings } from 'owa-session-store';

export default function getMailboxInfoForArchive(
    userMailboxInfo: MailboxInfo,
    mailboxGuid: string
): MailboxInfo {
    const orgDomain =
        getAccountScopeUserSettings(userMailboxInfo).SessionSettings?.OrganizationDomain;
    const mailboxSmtpAddress =
        mailboxGuid && orgDomain ? generateMailboxSmtpAddress(mailboxGuid, orgDomain) : '';

    return {
        type: 'ArchiveMailbox',
        userIdentity: userMailboxInfo.userIdentity,
        mailboxSmtpAddress,
        auxiliaryMailboxGuid: mailboxGuid,
    };
}
