import type { MailboxInfo } from 'owa-client-types';
import getStore from '../store/store';
import { setLocalOverride } from '../utils/setLocalOverride';

export default function setApplicationSettingOverrideAction(
    setting: string,
    value: any,
    mailboxInfo?: MailboxInfo
) {
    setLocalOverride(setting, value, mailboxInfo);
    getStore(mailboxInfo).overrides[setting] = value;
}
