import { Scenario } from '../services/types/serviceSettingTypes';
import type { ImplementedCopilotFeatures } from '../store/store';

export function isImplementedScenario(
    scenarioEnum: Scenario
): ImplementedCopilotFeatures | undefined {
    switch (scenarioEnum) {
        case Scenario.AttendanceSuggestion:
            return 'AttendanceSuggestion';
        case Scenario.Coach:
            return 'Coach';
        case Scenario.Elaborate:
            return 'Elaborate';
        case Scenario.ThreadSummarization:
            return 'Summarize';
        case Scenario.SuggestedDrafts:
            return 'SuggestedDrafts';
        case Scenario.ExplainPlacesReport:
            return 'ExplainPlacesReport';
        case Scenario.Rewrite:
            return 'Rewrite';
        case Scenario.ReplyByMeeting:
            return 'ReplyByMeeting';
        case Scenario.MeetingPrepSummary:
            return 'MeetingPrepSummary';
        case Scenario.Chat:
            return 'Chat';
        case Scenario.CoachApply:
            return 'CoachApply';
        case Scenario.InboxCatchup:
            return 'Inbox';
        case Scenario.TimeCopilotChat:
            return 'TimeCopilotChat';
        case Scenario.ThemeVisualization:
            return 'VisualTheming';
        case Scenario.MChat:
            return 'MChat';
        case Scenario.AttachmentSummarization:
            return 'AttachmentSummarization';
        case Scenario.AutoResourceBooking:
            return 'AutoResourceBooking';
        default:
            return undefined;
    }
}
