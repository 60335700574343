import type { MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from '../selectors/getAccountScopeUserSettings';

export function isShadowMailboxUser(mailboxInfo: MailboxInfo): boolean {
    const sessionSettings = getAccountScopeUserSettings(mailboxInfo)?.SessionSettings;
    /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
     * Adding IsShadowMailbox to restricted properties/methods.
     *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
    return sessionSettings?.IsShadowMailbox || false;
}
