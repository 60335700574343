import type { FluidOwaSource } from '../enums/FluidEnums';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isEdu } from 'owa-session-store';
import { isCollabSpaceReadEnabled } from './isCollabSpaceReadEnabled';
import type { MailboxInfo } from 'owa-client-types';

export function isCollabSpaceEnabledForSource(
    fluidOwaSource: FluidOwaSource,
    mailboxInfo: MailboxInfo
): boolean {
    if (isEdu(mailboxInfo)) {
        return false;
    }
    switch (fluidOwaSource) {
        case 'CalendarReadingPane':
        case 'MailCalendarCard':
            return isCollabSpaceReadEnabled();
        case 'CalendarCompose':
            return (
                isFeatureEnabled('cmp-prague') &&
                isFeatureEnabled('cal-cmp-fluidCollaborativeSpace')
            );
        default:
            return false;
    }
}
