import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';
import getInitialSxSViewState from 'owa-sxsdata/lib/utils/getInitialSxSViewState';
import type RecentFilesState from './schema/RecentFilesState';
import type RecentFilesListState from './schema/RecentFilesListState';

const initialState: RecentFilesState = {
    recentFilesPerGroup: new ObservableMap<string, RecentFilesListState>(),
    fileInformation: {},
    sxsState: {
        datapoint: null,
        viewState: getInitialSxSViewState(),
    },
    shouldShowCallout: false,
    calloutShown: false,
};

const store = createStore<RecentFilesState>('recentFilesStore', initialState)();
export const getRecentFilesStore = () => store;
