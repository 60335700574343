import type { FolderTextFieldOperation } from 'owa-mail-folder-store/lib/store/schema/FolderTextFieldViewState';
import { default as viewStateStore } from 'owa-mail-folder-store/lib/store/store';
import type { MailboxInfo } from 'owa-client-types';
import type { FolderForestTreeType } from 'owa-graph-schema';

export function shouldRenderNodeInEditMode(
    folderId: string,
    operation: FolderTextFieldOperation,
    mailboxSmtpAddress: string,
    treeType: FolderForestTreeType,
    mailboxInfo?: MailboxInfo
): boolean {
    const folderTextFieldViewState = viewStateStore.folderTextFieldViewState;
    return (
        !!folderTextFieldViewState &&
        folderTextFieldViewState.folderId === folderId &&
        folderTextFieldViewState.operation === operation &&
        folderTextFieldViewState.mailboxSmtpAddress.toLowerCase() ===
            mailboxSmtpAddress.toLowerCase() &&
        folderTextFieldViewState.folderTreeType === treeType &&
        folderTextFieldViewState?.mailboxInfo === mailboxInfo
    );
}
