import React from 'react';
import { observer } from 'owa-mobx-react';
import { errorThatWillCauseAlert } from 'owa-trace';
import type { TraceErrorObject } from 'owa-trace';

export interface ErrorComponentProps {
    error: Error;
    windowIcons?: React.FunctionComponent<any>;
}

export enum ErrorComponentType {
    Full,
    Simple,
    None,
}

export interface ErrorBoundaryProps {
    children?: React.ReactNode;
    onError?: (error: TraceErrorObject, errorInfo: React.ErrorInfo) => void;
    type?: ErrorComponentType;
    fullErrorComponent?: React.ComponentType<
        {
            error: Error;
        } & any
    >;
    /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
     * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
     *	> Optional object properties should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
    fullErrorComponentProps?: any;
    suppressErrorReport?: boolean;
    windowIcons?: React.FunctionComponent<any>;
}

export interface ErrorBoundaryState {
    error?: TraceErrorObject;
}

/* eslint-disable-next-line owa-custom-rules/prefer-react-functional-components -- (https://aka.ms/OWALintWiki)
 * This react class component should be re-factored as functional component, if is not possible, write a justification.
 *	> When implementing a simple component, write it as a functional component rather than as a class component */
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {};
    }

    componentDidCatch(error: TraceErrorObject, errorInfo: React.ErrorInfo) {
        this.props.onError?.(error, errorInfo);

        if (!this.props.suppressErrorReport) {
            error.component = true;
            error.additionalInfo = {
                ...error.additionalInfo,
                componentStack: errorInfo.componentStack,
            };
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * The error name (message) must be a string literal (no variables in it).
             *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
            errorThatWillCauseAlert(error);
        }

        this.setState({
            error,
        });
    }

    render() {
        const { error } = this.state;
        const { children, type, windowIcons } = this.props;

        if (error) {
            if (type == ErrorComponentType.None) {
                return null;
            } else if (type == ErrorComponentType.Simple || !this.props.fullErrorComponent) {
                return <div>{error.toString()}</div>;
            }

            const FullErrorComponent = this.props.fullErrorComponent;

            return (
                <FullErrorComponent
                    error={error}
                    windowIcons={windowIcons}
                    {...this.props.fullErrorComponentProps}
                />
            );
        } else {
            return children;
        }
    }
}

export default observer(ErrorBoundary);
