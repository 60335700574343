import setHideFavoritesListMutator from '../mutators/setHideFavoritesListMutator';
import { setShouldHideFavoritesList } from 'owa-bit-flags';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Using transaction to mitigate a perf issue; this should be refactored to use idiomatic
 * Satchel/MobX patterns */
import { transaction } from 'mobx';

export function setHideFavoritesList(hideFavoritesList: boolean) {
    // transaction ensures that multiple mutator calls don't cause multiple re-renders. Has mobx treat both calls as one re-render
    transaction(() => {
        setHideFavoritesListMutator(hideFavoritesList);
        setShouldHideFavoritesList(hideFavoritesList);
    });
}
