import { Cache } from 'memory-cache';
import { PriorityTaskQueue } from 'owa-task-queue';
import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import type { TokenRequestParams } from '../schema/TokenRequestParams';
import type { TokenCallbackType } from '../schema/TokenCallbackType';
import type { ITokenQueue } from './ITokenQueue';
import {
    MAX_NUM_OF_GET_ACCESS_TOKEN_REQUESTS,
    DELAY_FOR_GET_ACCESS_TOKEN_REQUESTS,
    TIMEOUT_FOR_TOKEN_REQUEST_PROMISE,
} from '../const/constants';
import type { ILogger } from './ILogger';

export class TokenQueue implements ITokenQueue {
    private requestPromiseCache: Cache<string, Promise<TokenResponse>>;
    private tokenQueue: PriorityTaskQueue<TokenResponse>;

    constructor() {
        this.tokenQueue = new PriorityTaskQueue<TokenResponse>({
            maxParallelTasks: MAX_NUM_OF_GET_ACCESS_TOKEN_REQUESTS,
            taskDelay: DELAY_FOR_GET_ACCESS_TOKEN_REQUESTS,
        });
        this.requestPromiseCache = new Cache();
    }

    /**
     * This provides caching to store promise to token request mapping (both business and consumer scenarios).
     * Used when similar requests are made for the same cacheKey, this cache
     * is looked up to return an existing promise to the caller.
     */
    public enqueueRequestIfNotCached(
        cacheKey: string,
        authParams: TokenRequestParams,
        authTaskCallback: TokenCallbackType,
        logger: ILogger
    ): Promise<TokenResponse> {
        const cachedRequestPromise = this.getCachedPromise(cacheKey);

        if (cachedRequestPromise) {
            logger.addCheckpoint('CachedPromise');
            return cachedRequestPromise;
        } else {
            const requestPromise: Promise<TokenResponse> = this.tokenQueue.add(() =>
                authTaskCallback(authParams, logger)
            );
            this.putPromiseInCache(cacheKey, requestPromise);
            logger.addCheckpoint('PromiseAddedToAuthCache');
            return requestPromise;
        }
    }

    /**
     * Gets the cached promise for the "already-seen" cacheKey.
     */
    public getCachedPromise(cacheKey: string): Promise<TokenResponse> | null {
        return this.requestPromiseCache.get(cacheKey);
    }

    /**
     * Deletes the cached promise for the "already-seen" cacheKey, when the Promise has been resolved
     */
    public deleteCachedPromise(cacheKey: string): boolean {
        return this.requestPromiseCache.del(cacheKey);
    }

    /**
     * Puts the duplicate request to the PromiseCache
     */
    public putPromiseInCache(cacheKey: string, promise: Promise<TokenResponse>) {
        this.requestPromiseCache.put(cacheKey, promise, TIMEOUT_FOR_TOKEN_REQUEST_PROMISE);
    }
}
