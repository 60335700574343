/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - satchel stores in the worker will need their data synced
 *	> 'createStore' import from 'satcheljs' is restricted. Satchel stores should not be created in the worker as the data will need to be synced */
import { createStore } from 'satcheljs';

const observableDateTimeStore = createStore('ObservableDateTime', {
    now: 0, // timestamp of 'now', updated every minute.
    today: 0, // timestamp of the start of 'today', in the user's time zone.
})();

const getObservableDateTimeStore = () => observableDateTimeStore;
export default getObservableDateTimeStore;
