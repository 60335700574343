export const MSQ_LB_CRITICAL_D_TIME = 'MSQLBCDT';
export const MSQ_LB_NEARING_D_TIME = 'MSQLBNDT';
export const ESQ_LB_CRITICAL_D_TIME = 'ESQLBCDT';
export const ESQ_LB_NEARING_D_TIME = 'ESQLBNDT';
export const BB_CRITICAL_D_TIME = 'BBCDT';
export const BB_NEARING_D_TIME = 'BBNDT';
export const EnforcementState_Active: string = 'enabled';
export const EnforcementState_Revoked: string = 'revoked';
export const OneDriveId = 'OneDrive';
export const OutlookId = 'Outlook';
export const BASIC_TIER_STORAGE_CAP = 15; // The storage given to a basic, non-premium user
export const PREMIUM_TIER_STORAGE_CAP = 50; // The storage given to a premium user, some non premium users can have storage values over 50GB.
