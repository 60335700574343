import getActiveContentTab from './getActiveContentTab';
import type SecondaryReadingPaneTabData from '../store/schema/SecondaryReadingPaneTabData';
import type FileErrorTabData from '../store/schema/FileErrorTabData';
import type TabViewState from '../store/schema/TabViewState';
import { TabType, type TabState } from '../store/schema/TabViewState';
import { getGuid } from 'owa-guid';
import addTabViewState from '../actions/addTabViewState';
import { isFeatureEnabled } from 'owa-feature-flags';
import { errorThatWillCauseAlertAndThrow, errorThatWillCauseAlert } from 'owa-trace';
import type { MailboxInfo } from 'owa-client-types';

let nextId = 1;
const SXSID_PREFIX = 'SXSID_';

export default function addTab(
    type: TabType,
    isShown: boolean,
    makeActive: boolean,
    data: any
): string {
    const currentTab = getActiveContentTab();
    const tabId: string = TabType[type] + (nextId++).toString();
    const baseTabViewState = {
        id: tabId,
        state: isShown ? 1 : 0,
        startTime: new Date().getTime(),
        parentTabId: currentTab ? currentTab.id : undefined,
        blink: isShown, // Set blink to true if the tab is shown. It will be set to false in activateTab() if we want to make active
        data,
        sxsId: (type === TabType.SxS && data) || SXSID_PREFIX + getGuid(), // re-use sxsId if we pop out from SxS, otherwise, create a new one
    };

    let tabViewState: TabViewState | undefined = undefined;
    switch (type) {
        case TabType.Primary:
            tabViewState = { ...baseTabViewState, type: TabType.Primary };
            break;
        case TabType.OverflowMenu:
            tabViewState = {
                ...baseTabViewState,
                type: TabType.OverflowMenu,
                data: data as TabViewState[],
            };
            break;
        case TabType.SecondaryReadingPane:
            tabViewState = {
                ...baseTabViewState,
                type: TabType.SecondaryReadingPane,
                data: data as SecondaryReadingPaneTabData,
            };
            break;
        case TabType.MailCompose:
            tabViewState = {
                ...baseTabViewState,
                type: TabType.MailCompose,
                data: data as string,
            };
            break;
        case TabType.SxS:
            tabViewState = { ...baseTabViewState, type: TabType.SxS };
            break;
        case TabType.CalendarCompose:
            tabViewState = {
                ...baseTabViewState,
                type: TabType.CalendarCompose,
                data: data as string,
            };
            break;
        case TabType.CalendarReadingPane:
            tabViewState = {
                ...baseTabViewState,
                type: TabType.CalendarReadingPane,
                data: data as string,
            };
            break;
        case TabType.FullOptions:
            if (!isFeatureEnabled('acct-fullOptionsProjectionPopout')) {
                errorThatWillCauseAlertAndThrow(
                    'addTab should NOT be called for FullOptions when acct-fullOptionsProjectionPopout flight is OFF'
                );
            }

            tabViewState = {
                ...baseTabViewState,
                type: TabType.FullOptions,
                data: data as MailboxInfo,
            };
            break;
        case TabType.Reminders:
            tabViewState = { ...baseTabViewState, type: TabType.Reminders, data: data as string };
            break;
        case TabType.AddCalendars:
            tabViewState = {
                ...baseTabViewState,
                type: TabType.AddCalendars,
                data: data as string,
            };
            break;
        case TabType.FileError:
            tabViewState = {
                ...baseTabViewState,
                type: TabType.FileError,
                data: data as FileErrorTabData,
            };
            break;
    }

    if (tabViewState) {
        addTabViewState(tabViewState, makeActive);
        return tabViewState.id;
    }

    errorThatWillCauseAlert('tabViewState must NOT be undefined');
    return '';
}
