export enum ReportType {
    Phishing = 'phish',
    Junk = 'junk',
    NotJunk = 'notJunk',
}

export enum PostReportAction {
    BlockSender = 'blockSender',
    Unsubscribe = 'unsubscribe',
    Multi = 'multi' /* This is a special case for multi-select actions */,
    None = 'none',
}
