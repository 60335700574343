import { createStore } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';

export interface MailManualSyncStore {
    isSyncingMailbox: boolean;
}

const mailManualSyncStoreMap = new Map<string, MailManualSyncStore>();

export function getMailManualSyncStore(mailboxInfo: MailboxInfo): MailManualSyncStore {
    // Use both userIdentity and mailboxSmtpAddress to get a unique value across mailboxes.
    const name = `${mailboxInfo.userIdentity}_${mailboxInfo.mailboxSmtpAddress}_mailManualSyncStore`;
    let cachedStore = mailManualSyncStoreMap.get(name);
    if (!cachedStore) {
        const store = createStore<MailManualSyncStore>(name, { isSyncingMailbox: false })();
        mailManualSyncStoreMap.set(name, store);
        cachedStore = store;
    }
    return cachedStore;
}
