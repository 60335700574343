import type { CopilotThemeOptions } from 'owa-copilot-themes-types';
import { getStore } from '../store/store';
import setIsThemeSelected from '../mutators/setIsThemeSelected';
import setThemeOptionsToOcs from '../utils/setThemeOptionsToOcs';

export default function updateCopilotThemeBit(isSelected: boolean) {
    if (isSelected !== getStore().options.isSelected) {
        setIsThemeSelected(isSelected);
        setThemeOptionsToOcs(getStore().options as CopilotThemeOptions);
    }
}
