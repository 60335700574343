import { type CopilotThemeRefreshRate } from 'owa-copilot-themes-types';
import {
    owaDate,
    addHours,
    addDays,
    addWeeks,
    addMonths,
    differenceInMilliseconds,
} from 'owa-datetime';
import { getStore } from '../store/store';

export default function getCopilotThemeUpdateDelay(): number {
    const refreshRate = getStore().options.refreshRate as CopilotThemeRefreshRate;
    const lastRefreshDate = Date.parse(getStore().options.lastRefreshDate);
    const currentDate = owaDate();
    let nextRefreshDate = owaDate();

    switch (refreshRate) {
        case 'FourHours':
            nextRefreshDate = addHours(owaDate('UTC', new Date(lastRefreshDate).toISOString()), 4);
            break;
        case 'Daily':
            nextRefreshDate = addDays(owaDate('UTC', new Date(lastRefreshDate).toISOString()), 1);
            break;
        case 'Weekly':
            nextRefreshDate = addWeeks(owaDate('UTC', new Date(lastRefreshDate).toISOString()), 1);
            break;
        case 'Monthly':
            nextRefreshDate = addMonths(owaDate('UTC', new Date(lastRefreshDate).toISOString()), 1);
            break;
    }

    return currentDate >= nextRefreshDate
        ? 0
        : differenceInMilliseconds(nextRefreshDate, currentDate);
}
