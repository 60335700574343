import { getGuid } from 'owa-guid';

import { registerInstance } from '../mutators/taskModuleInstancesMutators';
import {
    registerCloseDialog,
    registerOpenDialog,
    registerUpdateDialog,
    registerOnSubmit,
    registerOnDismiss,
} from '../mutators/taskModuleMutators';
import registerDataCollectors from './registerDataCollector';
import getInstance from '../selectors/getTaskModuleInstance';
import registerMigrationDataCollector from './registerMigrationDataCollector';

import type {
    TaskModuleState,
    InstanceStore,
    DialogActions,
} from '../store/schema/TaskModuleInstances';

export default function newTaskModule(): TaskModuleState {
    registerDataCollectors();
    registerMigrationDataCollector();
    const instanceId = getGuid();
    registerInstance(instanceId);
    const actions: DialogActions = {
        open: registerOpenDialog(instanceId),
        close: registerCloseDialog(instanceId),
        update: registerUpdateDialog(instanceId),
        onSubmit: registerOnSubmit(instanceId),
        onDismiss: registerOnDismiss(instanceId),
    };
    const store = getInstance(instanceId);
    return {
        store: store as InstanceStore,
        actions,
    };
}
