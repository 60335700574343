const MsalErrorCode = {
    // MSAL Error codes:https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-error-handling-js
    InvalidRequest: 'invalid_request',
    NoTokenFound: 'no_tokens_found',
    LoginRequired: 'login_required',
    InteractionRequired: 'interaction_required',
    ConsentRequired: 'consent_required',
    MonitorWindowTimeout: 'monitor_window_timeout',
    EndpointResolutionError: 'endpoints_resolution_error',
    // AAD Error codes: https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
    NoUserLogin: 'AADSTS50058',
    InvalidResourceUrl: 'AADSTS50001',
    NoPreAuth: 'AADSTS65001', // "AADSTS65001: The user or administrator has not consented to use the application with ID."
    UserAborted: 'AADSTS65004', //when user closes prompt
    NotConsent: 'AADSTS70011',
};

export default MsalErrorCode;
