import type { TimeZoneRange } from './TimeZoneRange';
import { MAX_JAVASCRIPT_TIMESTAMP, MIN_JAVASCRIPT_TIMESTAMP } from 'owa-date-constants';
import type TimeZoneEntry from 'owa-service/lib/contract/TimeZoneEntry';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - satchel stores in the worker will need their data synced
 *	> 'createStore' import from 'satcheljs' is restricted. Satchel stores should not be created in the worker as the data will need to be synced */
import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';

export type LoadState =
    | 'Uninitialized'
    | 'Initializing'
    | 'Initialized'
    | 'RequestFailure'
    | 'ResponseEmpty'
    | 'Skipped';

export let timeZoneRangeLoadState = 'Uninitialized';

export function setTimeZoneRangeLoadState(loadState: LoadState) {
    timeZoneRangeLoadState = loadState;
}

const store = createStore('owaDateTime', {
    AllTimeZones: [] as TimeZoneEntry[],
    DateFormat: '',
    LocalTimeZone: 'UTC',
    TimeFormat: '',
    TimeZoneRanges: {
        UTC: [
            {
                start: MIN_JAVASCRIPT_TIMESTAMP,
                end: MAX_JAVASCRIPT_TIMESTAMP,
                localStart: MIN_JAVASCRIPT_TIMESTAMP,
                localEnd: MAX_JAVASCRIPT_TIMESTAMP,
                offset: 0,
            },
        ],
    } as {
        [timeZoneId: string]: TimeZoneRange[];
    },
    TimeZoneAlternateNames: new ObservableMap<string, string[]>(),
})();
export const getStore = () => store;
