import type { MailboxInfo } from 'owa-client-types';
import { mutatorAction } from 'satcheljs';
import getGroupActivityState from '../selectors/getGroupActivityState';
import { type LoadState } from '../schema/GroupMemberRequestData';

export const setGroupActivityState = mutatorAction(
    'SET_GROUP_ACTIVITY_STATE',
    (mailboxInfo: MailboxInfo, loadState: LoadState) => {
        const groupActivityState = getGroupActivityState(mailboxInfo);
        groupActivityState.loadState = loadState;
    }
);

export default setGroupActivityState;
