import type { ActionSource } from 'owa-mail-store';
import { composeStore } from 'owa-mail-compose-store';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { lazyNewMessage } from '../index';
import { shouldPopoutNewMessage } from '../utils/shouldPopoutNewMessage';
import setNewMessageCreationInProgress from './setNewMessageCreationInProgress';

// VSO 29687: Check if a new message is in the process of being created,
// and if not (or complete), create a new message
export default async function checkAndCreateNewMessage(
    actionSource: ActionSource,
    targetId: string | null,
    eventTimestamp?: number
) {
    if (!composeStore.newMessageCreationInProgress) {
        setNewMessageCreationInProgress(true /*newMessageCreationInProgress*/);
        const shouldPopout = await shouldPopoutNewMessage();
        try {
            return lazyNewMessage.importAndExecute(actionSource, {
                targetId: targetId ?? undefined,
                isPopout: shouldPopout,
                eventTimestamp,
            });
        } finally {
            setNewMessageCreationInProgress(false /*newMessageCreationInProgress*/);
        }
    }

    return Promise.resolve();
}
