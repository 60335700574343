import { LazyModule, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "AdvancedSearchModule" */ './lazyIndex'),
    {
        name: 'AdvancedSearchModule',
    }
);

export const AdvancedSearchWrapper = createLazyComponent(lazyModule, m => m.AdvancedSearchWrapper);
