import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import { getMailboxInfoFromOperationStrict } from './getMailboxInfoFromOperationStrict';
import type { Operation } from '../types/Operation';

export function getMailboxInfoFromOperation(operation: Operation): MailboxInfo {
    const globalAccount: MailboxInfo = getGlobalSettingsAccountMailboxInfo();
    const mailboxInfo: MailboxInfo = getMailboxInfoFromOperationStrict(operation) || globalAccount;

    return mailboxInfo;
}
