import { serialize } from '../helpers/favoriteNodeParser';
import updateFavoritesUserOptionService from '../../services/v1/updateFavoritesUserOptionService';
import favoritesStore from '../../store/store';
import { updateUserConfiguration } from 'owa-session-store';

export default function updateFavoritesUserOption(): Promise<void> {
    const favoriteNodesRaw = serialize(
        favoritesStore.orderedFavoritesNodeIds,
        favoritesStore.favoritesFolderNodes,
        favoritesStore.favoritesPersonaNodes,
        favoritesStore.favoriteSearches,
        favoritesStore.favoriteCategories
    );
    // Update the local user configuration store
    updateUserConfiguration(userConfig => {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2532 (19,9): Object is possibly 'undefined'.
        // @ts-expect-error
        userConfig.UserOptions.FavoriteNodes = favoriteNodesRaw;
    });
    // Service request to update user options for FavoriteFolders
    return updateFavoritesUserOptionService(favoriteNodesRaw);
}
