import { updateUserConfigurationAndService } from 'owa-userconfiguration/lib/utils/updateUserConfigurationAndService';
import { frequentlyUsedFolders } from '../store/store';
import { trace } from 'owa-trace';

// save frequently used folder list to the server
export default function saveFrequentlyUsedFolders() {
    const serializedFolders: string[] = [];
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    frequentlyUsedFolders.forEach(folder => {
        serializedFolders.push(JSON.stringify(folder));
    });

    updateUserConfigurationAndService({
        UserOptions: {
            FrequentlyUsedFolders: serializedFolders,
        },
    }).catch(error => {
        // no-op if FUF fails to update
        trace.warn(`saveFrequentlyUsedFolders: ${error}`);
    });
}
