import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import type { CopilotThemeOptions } from 'owa-copilot-themes-types';
import type { OcsPatchEntry } from 'owa-ocs-service';
import { OcsSettingScope, OcsSettingType, OcsVersion, writeOcsSettings } from 'owa-ocs-service';
import { COPILOT_THEME_SETTING_NAME } from '../constants/constants';

export default function setThemeOptionsToOcs(copilotThemeData: CopilotThemeOptions) {
    const ocsCopilotThemeOptionsPatch: OcsPatchEntry = {
        name: COPILOT_THEME_SETTING_NAME,
        value: JSON.stringify(copilotThemeData),
        type: OcsSettingType.Json,
        scope: OcsSettingScope.Global,
        itemClass: 'RoamingSetting',
    };
    return writeOcsSettings(
        getGlobalSettingsAccountMailboxInfo(),
        OcsVersion.V1,
        OcsSettingScope.Global,
        [ocsCopilotThemeOptionsPatch]
    );
}
