import { mutatorAction } from 'satcheljs';
import getStore from '../store/store';

export const setFolderLoadingStateMutator = mutatorAction(
    'setFolderLoadingStateMutator',
    (folderId: string, isLoadingComplete: boolean) => {
        if (isLoadingComplete) {
            getStore().foldersInLoadingState = getStore().foldersInLoadingState.filter(
                id => id !== folderId
            );
        } else {
            getStore().foldersInLoadingState.push(folderId);
        }
    }
);
