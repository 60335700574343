import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type LeftNavGroupsSchema from '../store/schema/LeftNavGroups';
import { getLeftNavGroupsStore } from '../store/store';
import { ObservableMap } from 'mobx';
import type GroupNodeViewState from '../store/schema/GroupNodeViewState';
import type RestoreGroupState from '../store/schema/RestoreGroupState';

export default function getLeftNavGroups(mailboxInfo: MailboxInfo): LeftNavGroupsSchema {
    const index = getIndexerValueForMailboxInfo(mailboxInfo);

    const leftNavGroups = getLeftNavGroupsStore().leftNavGroupsByMailboxInfo.get(index);

    if (!leftNavGroups) {
        const defaultLeftNavGroups: LeftNavGroupsSchema = {
            myOrgGroups: [],
            withContextMenuGroup: null,
            shouldShowAllGroups: false,
            hasLoadedFromServer: false,
            loadGroupsError: false,
            groupNodeViewStates: new ObservableMap<string, GroupNodeViewState>({}),
            restoreGroupState: new ObservableMap<string, RestoreGroupState>(),
        };

        getLeftNavGroupsStore().leftNavGroupsByMailboxInfo.set(index, defaultLeftNavGroups);
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        return getLeftNavGroupsStore().leftNavGroupsByMailboxInfo.get(index)!;
    }

    return leftNavGroups;
}
