import { type MainWindow } from 'owa-freeze-dry';
import {
    getSelectedTableView,
    getFocusedFilterForTable,
    MailRowDataPropertyGetter,
} from 'owa-mail-list-store';
import { getSelectedNode } from 'owa-mail-folder-forest-store';

export function createMainWindowItem() {
    const tableView = getSelectedTableView();
    const rowKey = [...tableView?.selectedRowKeys?.keys()]?.[0];
    const mainWindow: Partial<MainWindow> = {
        folderId: getSelectedNode()?.id,
        rowKey,
        focusedViewFilter: getFocusedFilterForTable(getSelectedTableView()),
        globalItemId: MailRowDataPropertyGetter.getLatestGlobalItemId(rowKey, tableView),
    };
    return mainWindow;
}
