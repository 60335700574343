import type { ReadOnlyUserOptionsType } from 'owa-service/lib/ReadOnlyTypes';
import { initializeOwaDateTimeStore as initializeOwaDateTimeStoreAction } from '../actions/internalActions';
import type { GetTimeZoneOverrides } from './initializeTimeZones';
import { initializeTimeZones } from './initializeTimeZones';
import type TimeZoneEntry from 'owa-service/lib/contract/TimeZoneEntry';
import type TimeZoneOffsetsType from 'owa-service/lib/contract/TimeZoneOffsetsType';

/**
 * Updates the date time store with options and time zones
 * @param options
 * @param shouldInitializeTimeZoneAnonymously
 */
export async function updateOwaDateTimeStore(
    options:
        | Pick<
              ReadOnlyUserOptionsType,
              'DateFormat' | 'TimeFormat' | 'TimeZone' | 'MailboxTimeZoneOffset'
          >
        | undefined,
    shouldInitializeTimeZoneAnonymously?: boolean,
    getTimeZoneOverrides?: GetTimeZoneOverrides
): Promise<[TimeZoneOffsetsType[] | undefined, TimeZoneEntry[] | undefined]> {
    initializeOwaDateTimeStoreAction(options);

    return initializeTimeZones(shouldInitializeTimeZoneAnonymously, getTimeZoneOverrides);
}
