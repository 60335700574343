import type { ActionSource as ActionSourceAnalyticsActions } from 'owa-analytics-types';
import type { ActionSource as ActionSourceMailStore } from 'owa-mail-store';
import { lazyOnDelete } from 'owa-mail-commands-actions';

export default function onDelete(
    actionSourceMailStore: ActionSourceMailStore,
    actionSourceAnalyticsActions: ActionSourceAnalyticsActions,
    isExplicitSoftDelete?: boolean,
    targetWindow?: Window
) {
    lazyOnDelete.importAndExecute(
        actionSourceMailStore,
        actionSourceAnalyticsActions,
        isExplicitSoftDelete,
        targetWindow
    );
}
