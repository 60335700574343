import {
    accountRankTypeChecker,
    BootState,
    isAccountSourceListStoreInitialized,
    getGlobalSettingsAccount,
} from 'owa-account-source-list-store';

export default function isGlobalStartupComplete() {
    if (!isAccountSourceListStoreInitialized()) {
        // The store is not initialzied the startup cannot be complete
        return false;
    }

    const account = getGlobalSettingsAccount();
    if (accountRankTypeChecker.isCoprincipal(account)) {
        return account.bootState === BootState.StartupComplete;
    }

    return false;
}
