import saveFrequentlyUsedFolders from '../services/saveFrequentlyUsedFolders';
import type { FrequentlyUsedFolder } from 'owa-mail-store';
import { frequentlyUsedFolders } from '../store/store';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { trace } from 'owa-trace';
import { getFolderTable } from 'owa-folders';

export let isInitialized: boolean = false;

// Initialize store with frequently used folder list from user configuration
export default function initializeFrequentlyUsedFolders() {
    if (isInitialized) {
        return;
    }

    let isCorrupted = false;

    const userOptions = getUserConfiguration().UserOptions;
    if (userOptions?.FrequentlyUsedFolders) {
        userOptions.FrequentlyUsedFolders.forEach(folder => {
            try {
                const parsedFolder: FrequentlyUsedFolder = JSON.parse(folder);
                // If folder is not part of the Folder Table, dont add to the FUF list
                // and mark as corrupted so we update the list on the server
                if (getFolderTable().get(parsedFolder.FolderId)) {
                    frequentlyUsedFolders.push(parsedFolder);
                } else {
                    isCorrupted = true;
                }
            } catch (ex) {
                trace.warn('Unable to parse frequently used folders');
                isCorrupted = true;
            }
        });
    }

    isInitialized = true;

    // save back the list which excludes corrupted folders
    if (isCorrupted) {
        saveFrequentlyUsedFolders();
    }
}

export function test_setIsInitialized(value: boolean) {
    isInitialized = value;
}
