import { lazyGetDensitySections } from 'owa-mail-ribbon-sections/lib/sections';

import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onDensityMenuClicked = () => {
    lazyGetDensitySections.import().then(getDensitySections => {
        setMenuDefinition('DensityMenuDefinitionStore', undefined /* projectionTabId */, () => {
            return {
                sections: getDensitySections(),
            };
        });
    });
};
