import { LazyAction, LazyModule } from 'owa-bundling';
const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailListResponseProcessor"*/ './lazyIndex'),
    {
        name: 'MailListResponseProcessor',
    }
);
// Init/Fetching
export const lazyFetchRichContentForRows = new LazyAction(
    lazyModule,
    m => m.fetchRichContentForRows
);
export const lazyReloadTable = new LazyAction(lazyModule, m => m.reloadTable);
