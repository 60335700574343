import { isEdu } from 'owa-bpos-utils';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';

export enum Account {
    Business = 'Business',
    Consumer = 'Consumer',
    Edu = 'EDU',
}

export function getAccountKind(): Account {
    if (!isConsumer()) {
        return isEdu() ? Account.Edu : Account.Business;
    }
    return Account.Consumer;
}
