import type { MailboxInfo } from 'owa-client-types';
import { isOfflinePeopleDataViaPDVEnabled } from './isOfflinePeopleDataViaPDVEnabled';
import { getApplicationSettings } from 'owa-application-settings';

export function isPDVInvalidateLokiContactCacheEnabled(viewerMailboxInfo: MailboxInfo) {
    // This feature flag tracks if the Loki call to invalidate contact cache is enabled.

    return (
        isOfflinePeopleDataViaPDVEnabled(viewerMailboxInfo) &&
        getApplicationSettings('PDV').peoInvalidateLokiContactCache
    );
}
