import type { MailboxInfo } from 'owa-client-types';
import { getStore } from '../store/store';
import { makeEmptyAccountConfiguration } from './makeEmptyAccountConfiguration';

export const getSharedMailboxFolderInformationMap = (mailboxInfo: MailboxInfo) => {
    const store = getStore();
    return store.sharedMailboxFolderInformationMaps.get(mailboxInfo.mailboxSmtpAddress);
};

export const initializeSharedMailboxFolderInformationMap = (mailboxInfo: MailboxInfo) => {
    const store = getStore();
    const sharedMailboxMap = makeEmptyAccountConfiguration();
    store.sharedMailboxFolderInformationMaps.set(mailboxInfo.mailboxSmtpAddress, {
        ...sharedMailboxMap,
    });
};

export const getOrInitializeSharedMailboxFolderInformationMap = (mailboxInfo: MailboxInfo) => {
    const sharedMailboxMap = getSharedMailboxFolderInformationMap(mailboxInfo);
    if (!sharedMailboxMap) {
        initializeSharedMailboxFolderInformationMap(mailboxInfo);
    }
    return getSharedMailboxFolderInformationMap(mailboxInfo);
};
