import type { MailboxInfo } from 'owa-client-types';
import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
import { action } from 'satcheljs';

/**
 * This action is called whenever a user configuration is set in the owa-session-store, the action
 * will be called with the following params:
 *    userConfiguration - the user configuration that was set
 *    userIdentity - when the user configuration set was not the default configuration the user identity will be provided
 *    mailboxInfo - when the user configuration is set from the multi-account startup the mailbox info will be included, when
 *        the configuration was set from the OWA connected account initialization it will not be set
 */
export const userConfigurationSet = action(
    'userConfigurationSet',
    (userConfiguration: OwaUserConfiguration, userIdentity?: string, mailboxInfo?: MailboxInfo) => {
        return {
            userConfiguration,
            userIdentity,
            mailboxInfo,
        };
    }
);
