import getListViewStore from 'owa-mail-list-store/lib/store/Store';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import {
    rangeSelectItemPart,
    onItemPartSelectedOrChecked,
    itemPartSelected,
} from 'owa-mail-actions/lib/mailListSelectionActions';
import type React from 'react';
import { isMac } from 'owa-user-agent';

/**
 * A handler for events created by user clicking mail list item part(s)
 * @param evt the mouse event
 * @param selectionSource the source of event
 * @param nodeId the given nodeId for the conversation item part
 * @param tableViewId - the table view id that this conversation belong to (or null if we don't care)
 * @returns a promise with boolean value based on if we have handled the event here
 */
export default function onMailListItemPartClickHandler(
    evt: React.MouseEvent<unknown> | KeyboardEvent,
    selectionSource: MailListItemSelectionSource,
    nodeId: string,
    tableViewId: string,
    itemId: string = '',
    allNodeIds: string[] = []
): void {
    evt.stopPropagation();

    // If the OS is a Mac, use evt.metaKey to detect Cmd key
    const isCtrlOrCmdKeyDown = isMac() ? evt.metaKey : evt.ctrlKey;
    const tableView = getListViewStore().tableViews.get(tableViewId);

    if (!tableView) {
        return;
    }

    if (evt.shiftKey) {
        // If the user is holding down the shift key when they select an item part, then toggle select all rows between the anchor nodeId and the target nodeId
        rangeSelectItemPart(tableView, nodeId, isCtrlOrCmdKeyDown);
    } else if (
        isCtrlOrCmdKeyDown ||
        selectionSource === MailListItemSelectionSource.MailListItemCheckbox
    ) {
        // If the user is holding down the ctrl/cmd key or selecting an item part via the checkbox, toggle select the item part, keeping the previously selected item parts selected.
        onItemPartSelectedOrChecked(nodeId, tableView, isCtrlOrCmdKeyDown);
    } else {
        // If no cmd/ctrl or shift key pressed, single select the item part
        itemPartSelected(nodeId, itemId, allNodeIds, tableView, selectionSource);
    }
}
