import type { MailboxInfo } from 'owa-client-types';
import { getApplicationSettings } from 'owa-application-settings';
import { isOfflinePeopleDataViaPDVEnabled } from './isOfflinePeopleDataViaPDVEnabled';

export function isSubstrateSearchForPDVEnabled(viewerMailboxInfo: MailboxInfo) {
    // This feature flag tracks if the substrate search is enabled for People Search.
    return (
        isOfflinePeopleDataViaPDVEnabled(viewerMailboxInfo) &&
        getApplicationSettings('PDV').peoSubstrateSearch
    );
}
