import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { xor } from './utils/xor';
import type { TableView } from 'owa-mail-list-store';
import { getSelectedTableView } from 'owa-mail-list-store';
import { getGroupFolderWellKnownName } from 'owa-groups-shared-folders-store';
import { getGroupIdFromTableQuery, isGroupTableQuery } from 'owa-group-utils';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

export const folderRestriction = (restrictedFolders: string[], shouldHide?: boolean) => () => {
    const isFolderInRestrictionList = restrictedFolders.includes(
        getSelectedFolderDistinguishedId()
    );
    return xor(shouldHide, isFolderInRestrictionList);
};

function getSelectedFolderDistinguishedId(): string {
    // If we are not in inbox focusedViewFilter restriction does not apply
    let selectedFolderDistinguishedId = '';

    // Check if we are in the right folder.
    const tableView: TableView = getSelectedTableView();
    const selectedFolderId = tableView && tableView.tableQuery.folderId;

    if (isFeatureEnabled('grp-loadFolders') && isGroupTableQuery(tableView?.tableQuery)) {
        const groupId = getGroupIdFromTableQuery(tableView?.tableQuery);

        selectedFolderDistinguishedId = getGroupFolderWellKnownName(
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2345 (31,13): Argument of type 'string | null' is not assignable to parameter of type 'string'.
            // @ts-expect-error
            groupId,
            selectedFolderId,
            getModuleContextMailboxInfo()
        );
    } else {
        selectedFolderDistinguishedId = selectedFolderId && folderIdToName(selectedFolderId);
    }

    return selectedFolderDistinguishedId;
}
