import { unsubscribed } from 'owa-locstrings/lib/strings/unsubscribed.locstring.json';
import loc from 'owa-localize';
import MailDismissRegular from 'owa-fluent-icons-svg/lib/icons/MailDismissRegular';
import { lazyShowNotification } from 'owa-notification-bar';
import { getListViewNotificationDimensions } from 'owa-mail-layout';
import { getAccountScopeUserSettings } from 'owa-session-store';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Show notification on unsubscribing from suggested unsubscribe
 * @param mailboxInfo the mailboxInfo of the selected account where the notification will be shown
 */
export default function showNotificationOnUnsubscribe(mailboxInfo: MailboxInfo) {
    if (!getAccountScopeUserSettings(mailboxInfo).UserOptions?.ShowNotificationBar) {
        return;
    }

    const notificationDimensions = getListViewNotificationDimensions();

    lazyShowNotification.importAndExecute(
        'unsubscribeNotification',
        'MailModuleNotificationBarHost',
        window,
        loc(unsubscribed),
        {
            icon: MailDismissRegular,
            allowClear: true,
            // Want 12 px of padding on either side of the notification bar
            minWidth: notificationDimensions.minWidth,
            maxWidth: notificationDimensions.maxWidth,
        }
    );
}
