import { setAccountsStoreState } from '../actions';
import getStore from '../store/store';
import { mutator } from 'satcheljs';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(setAccountsStoreState, actionMessage => {
    getStore().accountsLoadedState = actionMessage.state;
});
