import getNativeContextMenuStateStore from '../store/store';
import { mutatorAction } from 'satcheljs';
import type { IContextualMenuItem, Point } from '@fluentui/react';

export const displayNativeContextMenu = mutatorAction(
    'displayNativeContextMenu',
    (mouseEvent: MouseEvent, contextualMenuItems: IContextualMenuItem[]) => {
        const mouseEventPoint: Point = { x: mouseEvent.x, y: mouseEvent.y };
        const store = getNativeContextMenuStateStore();
        store.point = mouseEventPoint;
        store.contextualMenuItems = contextualMenuItems;
        store.showContextMenu = true;
    }
);

export const hideNativeContextMenu = mutatorAction('hideNativeContextMenu', () => {
    getNativeContextMenuStateStore().showContextMenu = false;
});
