import type { SelectionType } from 'owa-addins-core';
import { lazyOnItemNavigation } from 'owa-addins-view/lib/lazyFunctions';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import type { MailboxInfo } from 'owa-client-types';

export default async function updateAddinOnItemNavigation(
    selectionType: SelectionType,
    mailboxInfo: MailboxInfo
) {
    !isConsumer(undefined /* smtpAddress */, mailboxInfo) &&
        (await lazyOnItemNavigation.importAndExecute(selectionType, '' /*hostItemIndex*/));
}
