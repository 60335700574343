import type { MailboxInfo } from 'owa-client-types';
import type MailboxJunkEmailConfigurationOptions from 'owa-service/lib/contract/MailboxJunkEmailConfigurationOptions';
import setMailboxJunkEmailConfiguration from '../services/setMailboxJunkEmailConfiguration';
import updateMailboxJunkEmailConfiguration from './updateMailboxJunkEmailConfiguration';

export default async function saveMailboxJunkMailConfiguration(
    mailboxJunkEmailConfiguration: MailboxJunkEmailConfigurationOptions,
    mailboxInfo: MailboxInfo
) {
    updateMailboxJunkEmailConfiguration(mailboxJunkEmailConfiguration, mailboxInfo);
    await setMailboxJunkEmailConfiguration(mailboxJunkEmailConfiguration, mailboxInfo);
}
