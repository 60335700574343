import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import { checkGlobalSettingsReady } from './checkGlobalSettingsReady';

export function mailboxInfoOrTemporaryGlobal(mailboxInfo?: MailboxInfo): MailboxInfo {
    if (mailboxInfo) {
        return mailboxInfo;
    } else {
        checkGlobalSettingsReady();
        return getGlobalSettingsAccountMailboxInfo();
    }
}
