import { action } from 'satcheljs';

import type { OwaNovaActionUnion } from '../types';

export const onOpenAppStoreLaunchPage = action('onOpenAppStoreLaunchPage', event => ({
    event,
    owaType: 'appStore',
})) as OwaNovaActionUnion<'appStore'>;

export const onOpenAppStoreMessageExtension = action('onOpenAppStoreMessageExtension', event => ({
    event,
    owaType: 'appStore',
})) as OwaNovaActionUnion<'appStore'>;

export const onOpenAppStoreAddIns = action('onOpenAppStoreAddIns', event => ({
    event,
    owaType: 'appStore',
})) as OwaNovaActionUnion<'appStore'>;
