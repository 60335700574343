import { updateUserConfigurationService } from 'owa-service/lib/utils/updateUserConfigurationService';

export function updatePontTypeValue(pontValue: number) {
    updateUserConfigurationService([
        {
            key: 'NewEnabledPonts',
            valuetype: 'Integer32',
            value: [`${pontValue}`],
        },
    ]);
}
