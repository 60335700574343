import { isReadingPanePositionOff } from 'owa-mail-layout/lib/selectors/readingPanePosition';
import { shouldShowReadingPane } from 'owa-mail-layout/lib/selectors/shouldShowReadingPane';
import getSelectedTableView from 'owa-mail-list-store/lib/utils/getSelectedTableView';

/**
 * @returns true if no item is selected in the list view
 */
export function isNoItemContext(): boolean {
    const tableView = getSelectedTableView();
    return tableView?.selectedRowKeys.size === 0 && !tableView?.isInVirtualSelectAllMode;
}

/**
 * @param isPopOut boolean indicating if we are in the reading pane or a popout
 * @returns true if more than one item is selected in the list view or if the reading pane is hidden
 */
export function isMultipleSelection(isPopOut: boolean): boolean {
    const tableView = getSelectedTableView();
    const selectedRowKeysCount = tableView?.selectedRowKeys?.size;

    return (
        (!isPopOut &&
            ((!!selectedRowKeysCount && selectedRowKeysCount > 1) || isReadingPaneHidden())) ||
        tableView?.isInVirtualSelectAllMode
    );
}

/**
 * @returns true if the reading pane is hidden
 */
export function isReadingPaneHidden(): boolean {
    return isReadingPanePositionOff() && !shouldShowReadingPane();
}
