import { format } from 'owa-localize';
import { getListViewTypeForFolder } from 'owa-mail-folder-store';
import type { MailFolderTableQuery } from 'owa-mail-list-store';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import type FolderChangeDigestResponse from 'owa-service/lib/contract/FolderChangeDigestResponse';
import type GetFolderChangeDigestResponse from 'owa-service/lib/contract/GetFolderChangeDigestResponse';
import type InboxViewType from 'owa-service/lib/contract/InboxViewType';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import folderId from 'owa-service/lib/factory/folderId';
import targetFolderId from 'owa-service/lib/factory/targetFolderId';
import { trace } from 'owa-trace';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';
import getFolderChangeDigest from '../services/getFolderChangeDigestService';
import { getStore } from '../store/store';
import { getMailboxInfoFromTableQuery } from 'owa-mail-mailboxinfo';
import { getAccountScopeUserSettings } from 'owa-session-store';
import type { MailboxInfo } from 'owa-client-types';

// Max number of unseen count to display in rollup
const MAX_UNSEEN_COUNT_TO_DISPLAY = 99;

/**
 * Action to get focused inbox rollup data
 * @param tableQuery for which to get rollup data
 */
export default action('getFolderChangeDigestAction')(function getFolderChangeDigestAction(
    tableQuery: MailFolderTableQuery
) {
    const mailboxInfo = getMailboxInfoFromTableQuery(tableQuery);
    const isFocusedView = tableQuery.focusedViewFilter == 0;
    const viewType = isFocusedView ? 1 : 2;
    const viewState = getAccountScopeUserSettings(mailboxInfo)?.ViewStateConfiguration;
    const watermark = isFocusedView
        ? viewState?.FocusedViewWatermark
        : viewState?.ClutterViewWatermark;
    getStore(mailboxInfo).viewType = viewType;
    getFolderChangeDigest(
        targetFolderId({ BaseFolderId: folderId({ Id: tableQuery.folderId }) }),
        viewType,
        watermark,
        getListViewTypeForFolder(tableQuery.folderId) == 0,
        mailboxInfo
    ).then(response => processResponse(response, mailboxInfo));
});

// Process GetFolderChangeDigestResponse
function processResponse(response: GetFolderChangeDigestResponse, mailboxInfo: MailboxInfo) {
    if (response.Folders == null || response.Folders.length == 0) {
        // GetFolderChangeDigestResponse.Folders can be null in case of server exception while processing the request
        trace.info('GetFolderChangeDigestResponse.Folders is null or empty');
        return;
    }

    const folder: FolderChangeDigestResponse = response.Folders[0];

    // Update rollup store state with response data
    const uniqueSenders = folder.RecentUniqueSenders || [];
    getStore(mailboxInfo).uniqueSenders = uniqueSenders.join('; ').toString();
    getStore(mailboxInfo).unseenCountToDisplay = getUnseenCountToDisplay(folder.UnseenCount);
}

// Get unseen count to dsiplay in rollup
function getUnseenCountToDisplay(count: number | undefined) {
    if (count === undefined || count === 0) {
        return undefined;
    }

    if (count > MAX_UNSEEN_COUNT_TO_DISPLAY) {
        return format('{0}+', MAX_UNSEEN_COUNT_TO_DISPLAY);
    }

    return count.toString();
}
