import { observer } from 'owa-mobx-react';
import React from 'react';
import Favorites from './Favorites';
import {
    getMailAccountSources,
    getGlobalSettingsAccountMailboxInfo,
    accountSourceDataTypeChecker,
} from 'owa-account-source-list-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { favoritesStore, isMultiAccountsCombinedFavoritesEnabled } from 'owa-favorites';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list';

export interface FavoritesTreeProps extends React.HTMLProps<HTMLDivElement> {
    setSize: number;
    positionInSet: number;
    style?: React.CSSProperties;
}

const FavoritesTree = observer(function FavoritesTree(props: FavoritesTreeProps) {
    if (!isMultiAccountsCombinedFavoritesEnabled() && isMonarchMultipleAccountsEnabled()) {
        const accountSources = getMailAccountSources();
        let { positionInSet, ...propsExcludingPositionInSet } = props;
        return (
            <div style={props.style}>
                {accountSources.map(
                    accountSource =>
                        accountSourceDataTypeChecker.isM365Mailbox(accountSource) && (
                            <Favorites
                                key={accountSource.sourceId}
                                mailboxInfo={accountSource.mailboxInfo}
                                positionInSet={positionInSet++}
                                {...propsExcludingPositionInSet}
                            />
                        )
                )}
            </div>
        );
    } else {
        return <Favorites mailboxInfo={getGlobalSettingsAccountMailboxInfo()} {...props} />;
    }
}, 'FavoritesTree');

function shouldShowFavoriteList(): boolean {
    if (isFeatureEnabled('tri-favorites-roaming')) {
        // When using the heterogeneous favorites API, show the favorites header when
        // there is any favorite folder or group to show
        return favoritesStore.outlookFavorites.size > 0;
    } else {
        let totalFavoritesCount = 0;
        totalFavoritesCount += favoritesStore.favoritesFolderNodes.size;
        totalFavoritesCount += favoritesStore.favoritesPersonaNodes.size;
        totalFavoritesCount += favoritesStore.favoriteCategories.size;

        return totalFavoritesCount > 0;
    }
}

export function getFavoritesNodeCount(): number {
    if (isMultiAccountsCombinedFavoritesEnabled()) {
        // For Multi Account, we will show the favorite nodes if the main account has favorites
        const accountSources = getMailAccountSources();
        return shouldShowFavoriteList()
            ? accountSources.filter(accountSource =>
                  accountSourceDataTypeChecker.isM365Mailbox(accountSource)
              ).length
            : 0;
    } else {
        return shouldShowFavoriteList() ? 1 : 0;
    }
}

export default FavoritesTree;
