import { isImmutableId } from './isImmutableId';

export type IdAndIndex = {
    id: string;
    idx: number;
};

export function partitionIds(
    inputIds?: (string | null | undefined)[]
): [IdAndIndex[], IdAndIndex[], IdAndIndex[]] {
    const immutableIds: IdAndIndex[] = [];
    const ewsIds: IdAndIndex[] = [];
    const smtpIds: IdAndIndex[] = [];

    inputIds?.forEach((id, i) => {
        if (typeof id == 'string') {
            // remove all nonstring IDs from the conversion pool
            // (e.g. null and undefined will be filtered out when
            // filtering)
            if (id.indexOf('@') > -1) {
                smtpIds.push({ id, idx: i });
            } else if (isImmutableId(id)) {
                immutableIds.push({
                    id,
                    idx: i,
                });
            } else {
                ewsIds.push({
                    id,
                    idx: i,
                });
            }
        }
    });

    return [immutableIds, ewsIds, smtpIds];
}
