import type { StrictTypedTypePolicies } from 'owa-graph-schema-type-policies';

type NullishKeyfieldType = {
    [key in keyof StrictTypedTypePolicies]: {
        keyFields: () => undefined;
    };
};

/**
 * Forces a type to not be normalized by Apollo cache.
 * Hence, that type is going to be stored as a whole —nested— object in the cache.
 * We usually need to do this for types that have values for `id` that are not unique.
 *
 * ```
 * >>> disableNormalizedForTypes(['Runtime', 'RuntimeInfo'])
 * {
 *     Runtime: {
 *      keyFields: () => undefined
 *    },
 *    RuntimeInfo: {
 *      keyFields: () => undefined
 *    }
 * }
 * ```
 * @param types
 * @returns A type policy object that forces the types to not be normalized.
 */
export function disableNormalizationForTypes(
    types: (keyof StrictTypedTypePolicies)[]
): NullishKeyfieldType {
    return types.reduce((acc: NullishKeyfieldType, type: keyof StrictTypedTypePolicies) => {
        acc[type] = {
            keyFields: () => undefined,
        };
        return acc;
    }, {});
}
