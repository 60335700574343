import getStore from '../store/store';
import { mutatorAction } from 'satcheljs';
import type { QuickStepsConfig } from '../store/schema/QuickStepsConfig';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { logUsage } from 'owa-analytics';

export default mutatorAction(
    'initializeQuickSteps',
    (mailboxInfo: MailboxInfo, quickStepsConfig: QuickStepsConfig) => {
        const store = getStore();
        const key = getIndexerValueForMailboxInfo(mailboxInfo);
        if (!store.quickStepsStoreMap.has(key)) {
            store.quickStepsStoreMap.set(key, {
                version: quickStepsConfig.version,
                quickSteps: quickStepsConfig.quickSteps,
                errorState: quickStepsConfig.errorState,
                runOnce: quickStepsConfig.runOnce,
            });
            logUsage('QuickStepTotal', [quickStepsConfig.quickSteps.length]);
        }
    }
);
