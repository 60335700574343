import type MailboxJunkEmailConfigurationOptions from 'owa-service/lib/contract/MailboxJunkEmailConfigurationOptions';
import getMailboxJunkEmailConfigurationOperation from 'owa-service/lib/operation/getMailboxJunkEmailConfigurationOperation';
import type { MailboxInfo } from 'owa-client-types';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';

export default async function loadMailboxJunkEmailConfiguration(
    mailboxInfo: MailboxInfo
): Promise<MailboxJunkEmailConfigurationOptions> {
    const requestOptions = getMailboxRequestOptions(mailboxInfo);
    return getMailboxJunkEmailConfigurationOperation(requestOptions).then(responseMessage => {
        if (responseMessage.WasSuccessful) {
            return responseMessage.Options;
        } else {
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Error constructor names can only be a string literals.
             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
            throw new Error(
                `getMailboxJunkEmailConfigurationOperation ${responseMessage.ErrorCode} ${responseMessage.ErrorMessage}`
            );
        }
    });
}
