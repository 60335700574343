import type { SharePointResourceTokenPrefetchState } from '../Schema/SharePointResourceTokenPrefetchState';
import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';

export interface SharePointResourceTokenPrefetchStateStore {
    tokenStatusPerResource: ObservableMap<string, SharePointResourceTokenPrefetchState>;
}

export const getInitialState = (): SharePointResourceTokenPrefetchStateStore => {
    return {
        tokenStatusPerResource: new ObservableMap<string, SharePointResourceTokenPrefetchState>(),
    };
};

// tokenStatusPerResource is a map from keys (sharepoint resource URL) to values (SharePointResourceTokenPrefetchState)
const store = createStore('sharePointResourceTokenPrefetchStateStore', getInitialState())();
export const getSharePointResourceTokenPrefetchStateStore = () => store;
