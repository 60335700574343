import { mutator } from 'satcheljs';

import { default as onAppAddSearchEventListener } from '../actions/onAppAddSearchEventListener';
import { default as onAppClearSearchListeners } from '../actions/onAppClearSearchListeners';

import getStore from '../store/appHostSearchStore';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(onAppAddSearchEventListener, _actionMessage => {
    getStore().isSearchEnabled = true;
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(onAppClearSearchListeners, _actionMessage => {
    getStore().isSearchEnabled = false;
});
