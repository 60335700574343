import { createContext, useContext } from 'react';
import type { ConditionalFormattingModification } from 'owa-conditional-formatting';

export interface MailListItemContainerContextProps {
    conditionalFormattingModifications: ConditionalFormattingModification[];
}
const MailListItemContainerContext = createContext<MailListItemContainerContextProps>({
    conditionalFormattingModifications: [],
});

export const MailListItemContainerContextProvider = MailListItemContainerContext.Provider;

export default function useMailListItemContainerContext() {
    return useContext(MailListItemContainerContext);
}
