/**
 * This function returns the sender name stripped of unnecessary whitespace to avoid
 * manipulation of the ML/RP UI.
 *
 * @param senderName
 * @returns senderName stripped of unnecessary whitespace
 */
export default function getSenderNameForDisplay(senderName: string | undefined | null): string {
    if (!senderName) {
        return '';
    }

    return sanitizeWhitespace(senderName);
}

function sanitizeWhitespace(input: string): string {
    // Define regex pattern to match any whitespace character, including Unicode characters
    const whitespaceRegex = /[\s\u00A0\u1680\u2000-\u200A\u202F\u205F\u3000\u2800]+/g;

    // Replace all whitespace characters with a single space
    const sanitizedString = input.replace(whitespaceRegex, ' ');

    // Remove leading and trailing spaces
    return sanitizedString.trim();
}
