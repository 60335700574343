import { getApplicationSettings } from 'owa-application-settings';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getGlobalSettingsAccount } from 'owa-account-source-list-store';
import { AccountSourceType } from 'owa-account-source-list-types';

let isOobe: boolean = false;

export function setIsOobe(isOobeValue: boolean) {
    isOobe = isOobeValue;
}

export function isPrivacyRoamingEnabled(): boolean {
    if (isOobe) {
        return isPrivacyRoamingEnabledByAccountType();
    }

    const isRoamingEnabled =
        isFeatureEnabled('settings-privacySettingsRoaming') ||
        getApplicationSettings('PrivacySettings').roamingEnabled;

    return isRoamingEnabled && isPrivacyEnabled();
}

function isPrivacyRoamingEnabledByAccountType(): boolean {
    const account = getGlobalSettingsAccount();

    switch (account?.sourceType) {
        case AccountSourceType.Office365:
        case AccountSourceType.OutlookDotCom:
            return true;

        default:
            return false;
    }
}

export function isPrivacyEnabled(): boolean {
    if (isOobe) {
        return true;
    }

    return (
        isFeatureEnabled('settings-privacySettings') ||
        getApplicationSettings('PrivacySettings').enabled
    );
}

export function isPrivacyRepromptV2Enabled(): boolean {
    if (isOobe) {
        return true;
    }

    return isFeatureEnabled('settings-privacyRepromptV2') && isPrivacyEnabled();
}
