import { getCoprincipalAccountForIndexerValue } from 'owa-account-source-list-store';
import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';

export function isMailboxSharedOrDelegateUserMailbox(mailboxInfo: MailboxInfo): boolean {
    if (mailboxInfo.type === 'UserMailbox') {
        if (mailboxInfo.isExplicitLogon) {
            return true;
        }

        const account = getCoprincipalAccountForIndexerValue(
            getIndexerValueForMailboxInfo(mailboxInfo)
        );
        if (account?.isAnotherMailbox) {
            return true;
        }
    }

    return false;
}
