import { getStore } from '../../store/store';

/**
 * Get the favorite id(guid) from folder id
 * @param folderId the folder id
 * @returns the favorite id
 */
export default function getFavoriteIdFromFolderId(folderId: string): string {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (12,5): Type 'string | undefined' is not assignable to type 'string'.
    // @ts-expect-error
    return getStore().favoriteSecondaryKeyMap.get(folderId);
}
