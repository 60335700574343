import type { MailListLog } from '../store/schema/MailListLogsStore';
import type { LogData } from 'owa-logging-utils';

// This is a helper function that enforces the mail list log schema when constructing a log to add to the store.
export function getMailListLogObjectToAddToStore(message: string, data: LogData): MailListLog {
    return {
        time: new Date(Date.now()).toISOString(),
        message,
        data,
    };
}
