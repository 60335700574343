import type { OwaPalette } from 'owa-theme-shared';
import type { BaseTheme } from '@1js/suiteux-shell-react';

export default function generateSuitePaletteFromFabricPalette(palette: OwaPalette): BaseTheme {
    return {
        LighterAlt: palette.themeLighterAlt,
        Darker: palette.themeDarker,
        Dark: palette.themeDark,
        DarkAlt: palette.themeDarkAlt,
        Light: palette.themeLight,
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        Primary: palette.themePrimary!,
        Secondary: palette.themeSecondary,
        Tertiary: palette.themeTertiary,
        Lighter: palette.themeLighter,
        NavBar: palette.headerBackground,
        DefaultText: palette.headerTextIcons,
        DefaultBackground: palette.headerButtonsBackground,
        AppName: palette.headerBrandText,
        SearchNavBar: palette.headerBackgroundSearch,
        SearchHoverBackground: palette.headerButtonsBackgroundSearchHover,
        SearchAccent: palette.headerBadgeBackground,
        ...(palette.optionalHeaderBadgeHoverText && {
            HoverText: palette.optionalHeaderBadgeHoverText,
        }),
        ...(palette.optionalHeaderBadgeHoverBackground && {
            HoverBackground: palette.optionalHeaderBadgeHoverBackground,
        }),
        ...(palette.optionalHeaderBadgeHoverText && {
            PressedText: palette.optionalHeaderBadgeHoverText,
        }),
        ...(palette.optionalHeaderBadgePressedBackground && {
            PressedBackground: palette.optionalHeaderBadgePressedBackground,
        }),
        ...(palette.optionalHeaderBadgeSelectedText && {
            SelectedText: palette.optionalHeaderBadgeSelectedText,
        }),
        ...(palette.optionalHeaderBadgeSelectedBackground && {
            SelectedBackground: palette.optionalHeaderBadgeSelectedBackground,
        }),
    };
}
