/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { isBulkActionInState, BulkActionStateEnum, isBulkActionValid } from '../index';

export default function isBulkActionRunning(folderId: string): boolean {
    return (
        (isBulkActionInState(folderId, BulkActionStateEnum.Uninitialized) ||
            isBulkActionInState(folderId, BulkActionStateEnum.Running)) &&
        isBulkActionValid(folderId)
    );
}
