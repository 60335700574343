import type { GroupFolderWellKnownName } from 'owa-folders-constants';
import type { MailboxInfo } from 'owa-client-types';
import { getGroupFolder } from './getGroupFolder';
import { getGroupFolderId } from './getGroupFolderId';

/**
 * @param groupId Smtp address of the group
 * @param folderId Folder Id of the group folder
 * @param wellKnownName WellKnownName of the default group folder
 * @param mailboxInfo mailboxInfo of the user account
 */
export function isSubFolderOfDefaultFolder(
    groupId: string,
    folderId: string,
    wellKnownName: GroupFolderWellKnownName,
    mailboxInfo: MailboxInfo
): boolean {
    const defaultFolderId = getGroupFolderId(groupId, wellKnownName, mailboxInfo);
    let parentFolderId = getGroupFolder(groupId, folderId, mailboxInfo)?.ParentFolderId;

    while (parentFolderId) {
        if (parentFolderId === defaultFolderId) {
            return true;
        }
        parentFolderId = getGroupFolder(groupId, parentFolderId, mailboxInfo)?.ParentFolderId;
    }

    return false;
}
