import {
    type LicenseType,
    type Scenario,
    type ExtensibilitySettingsResponse,
    type UserType,
} from './serviceSettingTypes';

// Keep in sync with server-outlook-gateway/src/Microsoft.OutlookService.Copilot/Models/Api/Settings/SupportedScenarios/Enums/ElaborateCapabilitiesEnum.cs
export enum ElaborateCapabilitiesType {
    TonePersonalization = 0,
    CalendarElaborate = 1,
}

// Keep in sync with server-outlook-gateway/src/Microsoft.OutlookService.Copilot/Models/Api/Settings/SupportedScenarios/Enums/RewriteCapabilitiesEnum.cs
export enum RewriteCapabilitiesType {
    HighlightAndRewrite = 0,
    CalendarRewrite = 1,
    AutoRewriteStaticPrompt = 2,
}

// Keep in sync with server-outlook-gateway/src/Microsoft.OutlookService.Copilot/Models/Api/Settings/SupportedScenarios/Enums/TimeCopilotChatCapabilitiesEnum.cs
export enum TimeCopilotChatCapabilitiesType {
    Triage = 0,
    SchedulingCompactCard = 1,
    WorkHoursLocation = 2,
    SpeedyMeetings = 3,
    DarkMode = 4,
    CategorizationUpdates = 5,
    CalTimeSuggestionsV2 = 6,
    MonCopilotChatContextIq = 7,
}

// Keep in sync with server-outlook-gateway/src/Microsoft.OutlookService.Copilot/Models/Api/Settings/SupportedScenarios/Enums/InboxPrioritizationCapabilities.cs
export enum InboxPrioritizationScenarios {
    BackgroundProcessing = 0,
}

interface SupportedScenarioBase {
    scenario: Scenario;
    supportedLanguages: string[];
}

interface GeneralScenario extends SupportedScenarioBase {
    scenario: Exclude<
        Scenario,
        Scenario.Elaborate | Scenario.Rewrite | Scenario.TimeCopilotChat | Scenario.InboxCatchup
    >;
}

interface ElaborateScenario extends SupportedScenarioBase {
    scenario: Scenario.Elaborate;
    capabilities: ElaborateCapabilitiesType[];
}

interface RewriteScenario extends SupportedScenarioBase {
    scenario: Scenario.Rewrite;
    capabilities: RewriteCapabilitiesType[];
}

interface TimeCopilotChatScenario extends SupportedScenarioBase {
    scenario: Scenario.TimeCopilotChat;
    capabilities: TimeCopilotChatCapabilitiesType[];
}

interface InboxPrioritizationScenario extends SupportedScenarioBase {
    scenario: Scenario.InboxCatchup;
    capabilities: InboxPrioritizationScenarios[];
}

export type SupportedScenario =
    | GeneralScenario
    | ElaborateScenario
    | RewriteScenario
    | TimeCopilotChatScenario
    | InboxPrioritizationScenario;

export interface CopilotCapable {
    capable: boolean;
}

export interface CopilotSettingsResponse {
    supportedScenarios: SupportedScenario[];
    copilotLicenses: LicenseType[];
    copilotCapable: CopilotCapable;
    isBetaUser: boolean;
    extensibility?: ExtensibilitySettingsResponse;
    userTypes: UserType[];
}
