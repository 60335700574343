import type MailboxJunkEmailConfigurationOptions from 'owa-service/lib/contract/MailboxJunkEmailConfigurationOptions';
import { mutatorAction } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import { getMailboxJunkEmailConfigurationStore } from '../store/store';

export default mutatorAction(
    'updateMailboxJunkEmailConfiguration',
    (
        value: MailboxJunkEmailConfigurationOptions,
        mailboxInfo: MailboxInfo,
        updateDomains: boolean = false
    ) => {
        const store = getMailboxJunkEmailConfigurationStore(mailboxInfo);
        store.mailboxJunkEmailConfiguration = value;

        if (updateDomains) {
            store.mailboxJunkEmailConfiguration.BlockedSendersAndDomains =
                store.mailboxJunkEmailConfiguration.BlockedSendersAndDomains?.sort();
            store.mailboxJunkEmailConfiguration.TrustedSendersAndDomains =
                store.mailboxJunkEmailConfiguration.TrustedSendersAndDomains?.sort();
            store.mailboxJunkEmailConfiguration.TrustedRecipientsAndDomains =
                store.mailboxJunkEmailConfiguration.TrustedRecipientsAndDomains?.sort();
        }

        store.initialized = true;
    }
);
