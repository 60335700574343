import { action } from 'satcheljs';

/**
 * Policies of regulatory and record type have delete restrictions.
 * Also such items and conversations cannot be moved to delete, junk folder etc.
 * This action is triggred to inform user about such retrictions.
 * The action string can take values 'Delete' or 'Move'.
 */
export const showActionRestrictionDueToPolicy = action(
    'SHOW_ACTION_RESTRICTION_DUE_TO_POLICY',
    (triageAction: string, targetWindow?: Window) => ({
        triageAction,
        targetWindow,
    })
);
