import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { logCoreUsage } from 'owa-analytics';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { getSelectedAccountMailboxInfo } from 'owa-account-source-list-store';

type EventNames =
    | 'clickedGmailCloudCacheAccountFromAccountSwitcher'
    | 'addCloudCacheAccountClickedFromAccountSwitcher'
    | 'addCloudCacheAccountClickedFromCallout'
    | 'addCloudCacheAccountClickedFromSettings'
    | 'AddCloudCacheConfig'
    | 'RemoveCloudCacheConfig';

type AllEventNames = `${EventNames}NonPremium` | `${EventNames}Unknown` | EventNames;

export function logUsageBasedOnPremiumFlag(eventName: EventNames, customData: any = null) {
    // we are unable to identify whether a user is premium or not as this information
    // is not loaded when the user is accessing its cloud cache account.
    // thus, we cannot rely solely on the IsPremiumConsumerMailbox flag for now
    /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
     * Adding IsShadowMailbox to restricted properties/methods.
     *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
    const customEventName: AllEventNames = getUserConfiguration().SessionSettings?.IsShadowMailbox
        ? `${eventName}Unknown`
        : /* eslint-disable-next-line owa-custom-rules/no-reflow-methods  -- (https://aka.ms/OWALintWiki)
         * Reflow related methods should not be used, see https://aka.ms/multiaccountlinter
         *	> Do Not Use Reflow: The getSelectedAccountMailboxInfo function should not be used for Respond multi-account support. */
        getAccountScopeUserSettings(getSelectedAccountMailboxInfo()).SessionSettings
              ?.IsPremiumConsumerMailbox
        ? eventName
        : `${eventName}NonPremium`;

    if (customEventName === 'clickedGmailCloudCacheAccountFromAccountSwitcher') {
        logCoreUsage('clickedGmailCloudCacheAccountFromAccountSwitcher', customData);
    } else if (customEventName === 'addCloudCacheAccountClickedFromAccountSwitcher') {
        logCoreUsage('addCloudCacheAccountClickedFromAccountSwitcher', customData);
    } else if (customEventName === 'addCloudCacheAccountClickedFromCallout') {
        logCoreUsage('addCloudCacheAccountClickedFromCallout', customData);
    } else if (customEventName === 'addCloudCacheAccountClickedFromSettings') {
        logCoreUsage('addCloudCacheAccountClickedFromSettings', customData);
    } else if (customEventName === 'AddCloudCacheConfig') {
        logCoreUsage('AddCloudCacheConfig', customData);
    } else if (customEventName === 'RemoveCloudCacheConfig') {
        logCoreUsage('RemoveCloudCacheConfig', customData);
    } else if (customEventName === 'clickedGmailCloudCacheAccountFromAccountSwitcherNonPremium') {
        logCoreUsage('clickedGmailCloudCacheAccountFromAccountSwitcherNonPremium', customData);
    } else if (customEventName === 'addCloudCacheAccountClickedFromAccountSwitcherNonPremium') {
        logCoreUsage('addCloudCacheAccountClickedFromAccountSwitcherNonPremium', customData);
    } else if (customEventName === 'addCloudCacheAccountClickedFromCalloutNonPremium') {
        logCoreUsage('addCloudCacheAccountClickedFromCalloutNonPremium', customData);
    } else if (customEventName === 'addCloudCacheAccountClickedFromSettingsNonPremium') {
        logCoreUsage('addCloudCacheAccountClickedFromSettingsNonPremiumUnknown', customData);
    } else if (customEventName === 'AddCloudCacheConfigNonPremium') {
        logCoreUsage('AddCloudCacheConfigNonPremium', customData);
    } else if (customEventName === 'RemoveCloudCacheConfigNonPremium') {
        logCoreUsage('RemoveCloudCacheConfigNonPremium', customData);
    } else if (customEventName === 'clickedGmailCloudCacheAccountFromAccountSwitcherUnknown') {
        logCoreUsage('clickedGmailCloudCacheAccountFromAccountSwitcherUnknown', customData);
    } else if (customEventName === 'addCloudCacheAccountClickedFromAccountSwitcherUnknown') {
        logCoreUsage('addCloudCacheAccountClickedFromAccountSwitcherUnknown', customData);
    } else if (customEventName === 'addCloudCacheAccountClickedFromCalloutUnknown') {
        logCoreUsage('addCloudCacheAccountClickedFromCalloutUnknown', customData);
    } else if (customEventName === 'addCloudCacheAccountClickedFromSettingsUnknown') {
        logCoreUsage('addCloudCacheAccountClickedFromSettingsUnknown', customData);
    } else if (customEventName === 'AddCloudCacheConfigUnknown') {
        logCoreUsage('AddCloudCacheConfigUnknown', customData);
    } else if (customEventName === 'RemoveCloudCacheConfigUnknown') {
        logCoreUsage('RemoveCloudCacheConfigUnknown', customData);
    }
}
