import { LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MoveFolderPosition" */ './lazyIndex')
);

export const lazyMoveFolderPosition = new LazyAction(lazyModule, m => m.onMoveFolderPosition);
export const lazyMoveFolderPositionUpDown = new LazyAction(
    lazyModule,
    m => m.onMoveFolderPositionUpDown
);
