import { action } from 'satcheljs';

export { setDiagnosticDataLevel } from './setDiagnosticDataLevelAction';
export { initializePrivacyUtilsAction } from './initializePrivacyUtilsAction';

export const setAnalyzeContentEnabled = action(
    'setAnalyzeContentEnabled',
    (analyzeContentEnabled: boolean) => ({ analyzeContentEnabled })
);

export const setDownloadContentEnabled = action(
    'setDownloadContentEnabled',
    (downloadContentEnabled: boolean) => ({ downloadContentEnabled })
);

export const setOptionalExperiencesEnabled = action(
    'setOptionalExperiencesEnabled',
    (optionalExperiencesEnabled: boolean) => ({ optionalExperiencesEnabled })
);

export const setOptionalConnectedExperiencesNoticeVersion = action(
    'setOptionalConnectedExperiencesNoticeVersion',
    (optionalConnectedExperiencesNoticeVersion: number) => ({
        optionalConnectedExperiencesNoticeVersion,
    })
);

export const setRequiredDiagnosticDataNoticeVersion = action(
    'setRequiredDiagnosticDataNoticeVersion',
    (requiredDiagnosticDataNoticeVersion: number) => ({ requiredDiagnosticDataNoticeVersion })
);

export const setOptionalDiagnosticDataConsentVersion = action(
    'setOptionalDiagnosticDataConsentVersion',
    (optionalDiagnosticDataConsentVersion: number) => ({ optionalDiagnosticDataConsentVersion })
);

export const setConnectedExperiencesNoticeVersion = action(
    'setConnectedExperiencesNoticeVersion',
    (connectedExperiencesNoticeVersion: number) => ({ connectedExperiencesNoticeVersion })
);
