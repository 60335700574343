import { getTokenScheme } from './getTokenScheme';
import {
    MIN_TIME_TO_EXPIRY_IN_MILLI_SECOND,
    MILLI_SECOND_TO_SECOND_MULTIPLIER,
    MSAUTH_SCHEME,
    DEFAULT_MSAUTH_EXPIRY,
    DEFAULT_BEARER_EXPIRY,
} from './constants';

export function getTokenExpiry(
    prefixedToken: string,
    expiresDuration?: number
): number | undefined {
    if (prefixedToken) {
        const tokenParts = prefixedToken.split(' ');
        const scheme = getTokenScheme(tokenParts);

        if (scheme) {
            if (expiresDuration) {
                // for Monarch fetches take expiration time passed by NativeHost into account
                const expiresIn =
                    expiresDuration * 1000 - Date.now() - MIN_TIME_TO_EXPIRY_IN_MILLI_SECOND;

                return expiresIn / MILLI_SECOND_TO_SECOND_MULTIPLIER;
            }

            // for Opx's default to hardcoded expiration times
            return scheme == MSAUTH_SCHEME ? DEFAULT_MSAUTH_EXPIRY : DEFAULT_BEARER_EXPIRY;
        }
    }

    return undefined;
}
