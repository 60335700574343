import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

/**
 * Checks the two mailbox infos to see if they have the same indexer values
 */
export default function doMailboxInfoIndexersMatch(
    first?: MailboxInfo,
    second?: MailboxInfo
): boolean {
    if (!first || !second) {
        // Must have two valid MailboxInfos to compare them
        return false;
    }

    return getIndexerValueForMailboxInfo(first) === getIndexerValueForMailboxInfo(second);
}
