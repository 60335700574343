import getListViewState from '../selectors/getListViewState';
import shouldShowUnstackedReadingPane from 'owa-mail-store/lib/utils/shouldShowUnstackedReadingPane';

export function isFirstLevelExpanded(rowKey: string): boolean {
    return (
        shouldShowUnstackedReadingPane() &&
        isConversationExpanded(rowKey) &&
        !!getListViewState().expandedConversationViewState.forks
    );
}

export function isSecondLevelExpanded(rowKey: string): boolean {
    if (shouldShowUnstackedReadingPane()) {
        return (
            isConversationExpanded(rowKey) &&
            !getListViewState().expandedConversationViewState.forks
        );
    }
    return isConversationExpanded(rowKey);
}

export function isLoadingSecondLevelExpansion(rowKey: string): boolean {
    const expansionState = getListViewState().expandedConversationViewState;
    const isLoadingExpansion =
        expansionState.expandedRowKey == rowKey && expansionState.shouldBeExpanded;
    return shouldShowUnstackedReadingPane() && isLoadingExpansion && !expansionState.forks;
}

function isConversationExpanded(rowKey: string): boolean {
    const listViewState = getListViewState();
    return (
        listViewState.expandedConversationViewState.expandedRowKey == rowKey &&
        !listViewState.expandedConversationViewState.shouldBeExpanded
    );
}
