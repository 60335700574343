import type { MailboxInfo } from 'owa-client-types';
import { getGroupFolderHierarchy } from './getGroupFolderHierarchy';

/**
 * @param groupId Smtp address of the group
 * @param mailboxInfo mailbox info of the account
 * @returns Boolean value whether there are user created folders for the specified group
 */
export function hasUserCreatedFolders(groupId: string, mailboxInfo: MailboxInfo): boolean {
    const folderHierarchy = getGroupFolderHierarchy(groupId, mailboxInfo)?.folderHierarchy;
    if (folderHierarchy) {
        for (const groupFolder of folderHierarchy.values()) {
            if (!groupFolder.WellKnownName) {
                return true;
            }
        }
    }
    return false;
}
