import { mutatorAction } from 'satcheljs';
import categoryStore from '../store/store';

/**
 * Sets the shouldShowAllCategories flag
 * @param shouldShow new flag to set
 */
export default mutatorAction(
    'setShouldShowAllCategories',
    function setShouldShowAllCategories(shouldShow: boolean) {
        categoryStore.categoryMenuViewState.shouldShowAllCategories = shouldShow;
    }
);
