import { ApolloLink } from '@apollo/client';
import type { ResolverContext } from 'owa-graph-schema';
import { getOperationType } from '../util/getOperationType';

export function analyticsLink() {
    const link = new ApolloLink((operation, forward) => {
        const context = operation.getContext() as ResolverContext;
        const perfDatapoint = context?.perfDatapoint;
        const waterfallSettings = context?.waterfallSettings;
        if (perfDatapoint) {
            perfDatapoint.didExecuteGqlQuery = true;
        }

        if (getOperationType(operation) === 'subscription') {
            return forward(operation);
        } else {
            // A dev needs to explicitely ask to opt-out of the GQL Checkpoints by setting disableGQLTimings to true.
            // See https://aka.ms/OWALayerCakeGraph for more information.
            if (perfDatapoint && !waterfallSettings?.disableGQLTimings) {
                perfDatapoint.addToPredefinedOrCustomWaterfall(
                    'GQL_Link_S',
                    waterfallSettings?.customGQLIndexes?.[0],
                    waterfallSettings?.discardIfDefined
                );
            }

            operation.setContext({
                ...context,
                perfDatapoint,
            });

            return forward(operation).map(result => {
                if (perfDatapoint && !waterfallSettings?.disableGQLTimings) {
                    perfDatapoint.addToPredefinedOrCustomWaterfall(
                        'GQL_Link_E',
                        waterfallSettings?.customGQLIndexes?.[1],
                        waterfallSettings?.discardIfDefined
                    );

                    if (result?.extensions) {
                        // 1st entry is the total absolute latency for the given microservice(s).
                        const backendTotalLatencyObj = Object.entries(
                            result.extensions?.['X-BackendServiceLatencies'] || {}
                        )[0];
                        const backendTotalLatency = backendTotalLatencyObj?.['X-Total-Latency'];

                        perfDatapoint.addToCustomWaterfall(
                            1,
                            'WF_GW_TotalLatency',
                            backendTotalLatency
                        );

                        // X-BackendLatency
                        const backendMicroServiceLatency =
                            backendTotalLatencyObj?.['X-BackendLatency'];

                        perfDatapoint.addToCustomWaterfall(
                            2,
                            'WF_GW_BackendLatency',
                            backendMicroServiceLatency
                        );
                    }

                    operation.setContext({
                        ...(operation.getContext() as ResolverContext),
                        perfDatapoint,
                    });
                }

                // Add the data analytics data to the extensions field
                const resultContext = operation.getContext() as ResolverContext;

                if (resultContext?.perfDatapoint) {
                    if (!result.extensions) {
                        result.extensions = {};
                    }

                    if (!result.extensions.analytics) {
                        result.extensions.analytics = {};
                    }

                    result.extensions.analytics = {
                        perfDatapoint: resultContext?.perfDatapoint?.toJSObject?.(),
                    };
                }

                return result;
            });
        }
    });

    return link;
}
