import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getGroupsActivityStore } from '../GroupsActivityStore';
import { type GroupActivityState, LoadState } from '../schema/GroupMemberRequestData';

export default function getGroupActivityState(mailboxInfo: MailboxInfo): GroupActivityState {
    const index = getIndexerValueForMailboxInfo(mailboxInfo);
    const groupActivityState = getGroupsActivityStore().groupActivityStateByMailboxInfo.get(index);

    if (!groupActivityState) {
        const defaultGroupActivityState: GroupActivityState = {
            memberRequestsData: [],
            loadState: LoadState.NotLoaded,
        };

        getGroupsActivityStore().groupActivityStateByMailboxInfo.set(
            index,
            defaultGroupActivityState
        );
        return defaultGroupActivityState;
    }

    return groupActivityState;
}
