import { onAddTable, onRemoveTable } from './onUpdateTables';
import type { TableView } from 'owa-mail-list-store';

// - We maintain a list of most recently used 5 tables by storing the table info
//   in the MRU list.
// - Whenever a new table is added to the MRU, we subscribe to the row notifications.
// - Whenever a table id entry is removed from the MRU we unsubscribe to the row
//   notifications.
// - This means at any time only 5 tables are active/live.
// - The MRU list is FILO, and an entry's position gets bumped to the front of
//   the list when that table view is re-visited.
const capacity = 5;
let tableIdList: string[] = [];
// Keep track of the folders that the table views in cache correspond too,
// as a proxy for the selectFolder code to determine if the folder contents are already in memory
const folderIdList: Map<string, string> = new Map<string, string>();

/**
 * Adds the table view to the front of the MRU list
 *
 * @param tableView Table view to add to the MRU list
 */
function addToFront(tableView: TableView) {
    tableIdList.unshift(tableView.id);
    folderIdList.set(tableView.id, tableView.tableQuery.folderId);
    onAddTable(tableView);
    invalidateOldEntries();
}

/**
 * This function adds the table view to the end of the MRU list, assuming the
 * list isn't full.
 *
 * @param tableView Table view to add to the MRU list
 */
function addToEnd(tableView: TableView) {
    if (canAddToList()) {
        tableIdList.push(tableView.id);
        folderIdList.set(tableView.id, tableView.tableQuery.folderId);
        onAddTable(tableView);
    }
}

/**
 * This function bumps a table view to the front of the MRU list. This is done
 * when a table view is re-visited so that it is prioritized over other tables.
 *
 * @param tableView Table view to bump to the front of the MRU list
 */
function bumpToFront(tableView: TableView) {
    const entry = tableView.id;
    tableIdList.splice(tableIdList.indexOf(entry), 1);
    tableIdList.unshift(entry);
}

/**
 * This function checks if the MRU list is full. If it is full, it will remove
 * the oldest entry from the list and unsubscribe from the row notifications.
 */
function invalidateOldEntries() {
    for (let i = capacity; i < tableIdList.length; i++) {
        folderIdList.delete(tableIdList[i]);
        onRemoveTable(tableIdList[i]);
        tableIdList.splice(i, 1);
    }
}

/**
 * This function updates the MRU list with the table view. If the table view
 * is already in the MRU list, it will be bumped to the front. If the table view
 * is not in the MRU list, it will be added to the front or end of the list
 * depending on the shouldAddToFront parameter.
 *
 * The shouldAddToFront is only false when we are updating the MRU list by way
 * of pre-fetching frequently used folders. Because these folders aren't actively
 * being visited by the user, we will prioritize them based on frequency and
 * just don't cache any folders beyond the capacity of the cache.
 *
 * @param tableView Table view to update in the MRU list
 * @param shouldAddToFront If true, the table view will be added to the front of
 * the MRU list. Otherwise it will be added to the end.
 */
export function update(tableView: TableView, shouldAddToFront: boolean = true): number {
    const entryIndex = tableIdList.indexOf(tableView.id);

    switch (entryIndex) {
        case -1:
            if (shouldAddToFront) {
                addToFront(tableView);
            } else {
                addToEnd(tableView);
            }
            break;
        case 0:
            break;
        default:
            bumpToFront(tableView);
    }

    return entryIndex;
}

export function remove(tableView: TableView) {
    const index = tableIdList.indexOf(tableView.id);
    if (index == -1) {
        throw new Error('TableViewId should be present in the mru list when remove is called');
    }

    folderIdList.delete(tableView.id);
    onRemoveTable(tableView.id);
    tableIdList.splice(index, 1);
}

export function canAddToList(): boolean {
    return tableIdList.length < capacity;
}

export function getMRUTableKey(index: number = 0): string {
    return tableIdList[index];
}

export function getTableKey(tableView: TableView): string {
    return tableView.id;
}

export function getLength(): number {
    return tableIdList.length;
}

export function test_resetTableIdList() {
    tableIdList = [];
    folderIdList.clear();
}

export function mruTableCacheHasFolderId(folderId: string): boolean {
    const folderIds = [...folderIdList.values()];
    return folderIds.includes(folderId);
}
