// extracted by mini-css-extract-plugin
export var compact = "QyySM";
export var container = "OVIE8";
export var containerPadding = "qQbyL";
export var containerWillChangeScrollPosition = "zjEPs";
export var contentsContainer = "WKi7w";
export var dropContainer = "rY50O";
export var droppableContainer = "slWCo";
export var favoritesContainer = "d6NfH";
export var foldersContainer = "KkZZ2";
export var isChromium = "ou4TM";
export var leftNavSectionWithSectionHeaders = "DPg26";
export var medium = "XcxaR";
export var safariScrollHandling = "bn_Zl";
export var scrollContainer = "sQ56h";