import type MruFolderCache from './schema/MoveToFolderMruState';
import { createStore } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

const mruFolders = new Map<string, MruFolderCache>();

export default function store(mailboxInfo: MailboxInfo): MruFolderCache {
    const name = `${getIndexerValueForMailboxInfo(mailboxInfo)}_mruFolderCache`;
    let cachedStore = mruFolders.get(name);
    if (!cachedStore) {
        const initialStore: MruFolderCache = {
            mruFolders: [],
            isInitialized: false,
        };

        const mruFolderStore = createStore<MruFolderCache>(name, initialStore)();
        mruFolders.set(name, mruFolderStore);
        cachedStore = mruFolderStore;
    }

    return cachedStore;
}
