import React from 'react';
import { observer } from 'owa-mobx-react';
import { AppFeatureManager, AppWindowProvider } from '@1js/acui-context/lib/components/AppContext';
import { AppTooltipManager } from '@1js/acui-tooltip/lib/components/AppTooltipManager';
import { getCurrentRibbonTheme } from 'owa-acui-theme/lib/utils/getCurrentRibbonTheme';
import { getRibbonExperiments } from '../../util/getRibbonExperiments';
import { ThemeProvider } from '@fluentui/react';
import { isFeatureEnabled } from 'owa-feature-flags';

export const RibbonContexts = observer(
    ({ appWindow, children }: { appWindow: Window; children: React.ReactNode }) => {
        return (
            <AppFeatureManager isExperimentalFeatureEnabled={getRibbonExperiments}>
                <Theme>
                    <AppWindowProvider appWindow={appWindow}>
                        <AppTooltipManager>{children}</AppTooltipManager>
                    </AppWindowProvider>
                </Theme>
            </AppFeatureManager>
        );
    },
    'RibbonContexts'
);

/** Used to only include the `ThemeProvider` when using Fluent UI v8 */
const Theme = observer(({ children }: { children: React.ReactNode }) => {
    if (isFeatureEnabled('mon-ribbon-fluent-v9-ribbon')) {
        return children;
    }

    return (
        <ThemeProvider applyTo="none" theme={getCurrentRibbonTheme()}>
            {children}
        </ThemeProvider>
    );
}, 'Theme');
