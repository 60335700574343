import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import type { IGetTokenApi } from './IGetTokenApi';
import type {
    EnterpriseTokenRequestParams,
    ConsumerTokenRequestParams,
    ShadowMailboxTokenRequestParams,
    TokenRequestParams,
} from './schema/TokenRequestParams';
import { getAuthTokenResponse } from './utils/getAuthTokenResponse';
import type { AuthTokenResponse } from './schema/AuthTokenResponse';
import { fetchTokenFromServerWithRetry } from './utils/fetchTokenFromServer';
import { fetchTokenFromMSA } from './utils/fetchTokenFromMSA';
import type { ILogger } from './utils/ILogger';
import type { ITokenQueue } from './utils/ITokenQueue';
import type { ITokenCache } from './utils/ITokenCache';
import { ScenarioType } from './schema/Scenario';
import { getGuid } from 'owa-guid';
import { getOrigin } from 'owa-url/lib/getOrigin';
import {
    AUTHORIZATION_URI,
    CLIENT_ID,
    NUM_OF_RETRIES,
    RETRY_DELAY,
    RETURN_URL_PATH,
} from './const/constants';
import { Logger } from './utils/Logger';
import { getTokenLatencyStore } from './getTokenLatencyStore';

export class GetTokenApi implements IGetTokenApi {
    public tokenQueue: ITokenQueue;
    public tokenCache: ITokenCache;
    public eventName: string;

    public readonly LoggerPrefix: string = 'GetTokenApi';

    constructor(queue: ITokenQueue, cache: ITokenCache, eventName: string) {
        this.tokenQueue = queue;
        this.tokenCache = cache;
        this.eventName = eventName;
    }

    public async getToken(params: TokenRequestParams): Promise<AuthTokenResponse | undefined> {
        params.requestId = params.requestId || getGuid();
        const logger = new Logger(this.eventName);

        this.logParams(params, logger);

        switch (params.scenarioType) {
            case ScenarioType.ShadowMailbox:
                return this.getTokenForShadowMailbox(params, logger);

            case ScenarioType.Consumer:
                return this.getTokenForConsumer(params as ConsumerTokenRequestParams, logger);

            case ScenarioType.Enterprise:
                return this.getTokenForEnterprise(params as EnterpriseTokenRequestParams, logger);
        }
    }

    public async getTokenForEnterprise(
        enterpriseParams: EnterpriseTokenRequestParams,
        logger: ILogger
    ): Promise<AuthTokenResponse | undefined> {
        let tokenResponse: TokenResponse | undefined;

        const startTime = Date.now();

        const cacheKey = this.tokenCache.getCacheKey(enterpriseParams);
        const latencyStore = getTokenLatencyStore();

        // Avoid fetching from cache if wwwAuthenticateHeader is passed
        if (!enterpriseParams.wwwAuthenticateHeader) {
            tokenResponse = this.tokenCache.getCachedToken(cacheKey);
        }

        if (tokenResponse) {
            logger.addCheckpoint(`${this.LoggerPrefix}EnterpriseCache_Success`);
            logger.logLatency();
            logger.end();
            latencyStore.setLatency(self, cacheKey, Date.now() - startTime);
            return getAuthTokenResponse(tokenResponse);
        }

        // Enqueue request to TokenQueue for repeated calls
        const promiseForResource = this.tokenQueue.enqueueRequestIfNotCached(
            cacheKey,
            enterpriseParams,
            this.getTokenForEnterpriseTask,
            logger
        );

        return promiseForResource
            .then(token => {
                this.tokenQueue.deleteCachedPromise(cacheKey);

                if (token) {
                    logger.addCheckpoint(`${this.LoggerPrefix}Enterprise_Success`);
                    latencyStore.setLatency(self, cacheKey, Date.now() - startTime);
                } else {
                    logger.addCheckpoint(`${this.LoggerPrefix}Enterprise_NullToken`);
                }

                logger.logLatency();
                logger.end();
                return getAuthTokenResponse(token);
            })
            .catch(error => {
                this.tokenQueue.deleteCachedPromise(cacheKey);
                logger.addCustomError(`${this.LoggerPrefix}Enterprise_Error`, error);
                logger.logLatency();
                logger.end();
                return undefined;
            });
    }

    /*
    This is a workaround to be able to pass this function by reference to TokenQueue
    Original callback has to be on the prototype change for `super` calls as fallback
    */
    public getTokenForEnterpriseTask = (params: TokenRequestParams, logger: ILogger) =>
        this.getTokenForEnterpriseCallback(params, logger);

    public async getTokenForEnterpriseCallback(
        params: TokenRequestParams,
        logger: ILogger
    ): Promise<TokenResponse> {
        const enterpriseParams = params as EnterpriseTokenRequestParams;
        const cacheKey = this.tokenCache.getCacheKey(enterpriseParams);

        logger.addCheckpoint(`${this.LoggerPrefix}EnterpriseCallback`);
        const tokenResponse = await fetchTokenFromServerWithRetry(
            logger,
            NUM_OF_RETRIES,
            RETRY_DELAY,
            enterpriseParams
        );

        this.tokenCache.putCachedToken(cacheKey, enterpriseParams, tokenResponse);

        return tokenResponse;
    }

    public async getTokenForConsumer(
        consumerParams: ConsumerTokenRequestParams,
        logger: ILogger
    ): Promise<AuthTokenResponse | undefined> {
        const cacheKey = this.tokenCache.getCacheKey(consumerParams);
        const tokenResponse = this.tokenCache.getCachedToken(cacheKey);
        const latencyStore = getTokenLatencyStore();
        const startTime = Date.now();

        if (tokenResponse?.AccessToken) {
            logger.addCheckpoint(`${this.LoggerPrefix}ConsumerCache_Success`);
            logger.logLatency();
            logger.end();
            latencyStore.setLatency(self, cacheKey, Date.now() - startTime);
            return getAuthTokenResponse(tokenResponse);
        } else {
            // Queue request to AuthTokenQueue to avoid making repeated n/w call for same resource
            const promiseForResource = this.tokenQueue.enqueueRequestIfNotCached(
                cacheKey,
                consumerParams,
                this.getTokenForConsumerTask,
                logger
            );

            return promiseForResource
                .then(token => {
                    this.tokenQueue.deleteCachedPromise(cacheKey);

                    if (token) {
                        logger.addCheckpoint(`${this.LoggerPrefix}Consumer_Success`);
                        latencyStore.setLatency(self, cacheKey, Date.now() - startTime);
                    } else {
                        logger.addCheckpoint(`${this.LoggerPrefix}Consumer_NullToken`);
                    }

                    logger.logLatency();
                    logger.end();
                    return getAuthTokenResponse(token);
                })
                .catch(error => {
                    this.tokenQueue.deleteCachedPromise(cacheKey);
                    logger.addCustomError(`${this.LoggerPrefix}Consumer_Error`, error);
                    logger.logLatency();
                    logger.end();
                    return undefined;
                });
        }
    }

    /*
    This is a workaround to be able to pass this function by reference to TokenQueue
    Original callback has to be on the prototype change for `super` calls as fallback
    */
    public getTokenForConsumerTask = (params: TokenRequestParams, logger: ILogger) =>
        this.getTokenForConsumerCallback(params, logger);

    public async getTokenForConsumerCallback(
        params: TokenRequestParams,
        logger: ILogger
    ): Promise<TokenResponse> {
        const consumerParams = params as ConsumerTokenRequestParams;
        this.populateAuthUrlParams(consumerParams);

        const scopeRequests = consumerParams.scope && consumerParams.scope.length > 0;
        const fetchFromServer = !scopeRequests;

        let tokenResponse;
        if (fetchFromServer) {
            logger.addCheckpoint(`${this.LoggerPrefix}ConsumerCallback_FetchTokenFromServer`);
            tokenResponse = await fetchTokenFromServerWithRetry(
                logger,
                NUM_OF_RETRIES,
                RETRY_DELAY,
                consumerParams
            );
        } else {
            logger.addCheckpoint(`${this.LoggerPrefix}ConsumerCallback_FetchTokenFromMSA`);
            tokenResponse = await fetchTokenFromMSA(logger, consumerParams);
        }

        const cacheKey = this.tokenCache.getCacheKey(consumerParams);

        this.tokenCache.putCachedToken(cacheKey, consumerParams, tokenResponse);

        return tokenResponse;
    }

    private populateAuthUrlParams(params: ConsumerTokenRequestParams) {
        params.redirect_uri = getOrigin() + RETURN_URL_PATH;
        params.authorization_uri = AUTHORIZATION_URI;
        params.client_id = CLIENT_ID;
    }

    public getTokenForShadowMailbox(
        _params: ShadowMailboxTokenRequestParams,
        logger: ILogger
    ): Promise<AuthTokenResponse | undefined> {
        const err = new Error('Method not implemented.');
        logger.addCustomError(`${this.LoggerPrefix}ShadowMailbox_NotImplemented`, err);
        logger.logLatency();
        logger.end();
        throw err;
    }

    private logParams(requestParams: TokenRequestParams, logger: ILogger) {
        for (const [key, value] of Object.entries(requestParams)) {
            if (value && key !== 'mailboxInfo') {
                logger.addCustomData(key, value);
            }
        }
    }
}
