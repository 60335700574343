import { isFeatureEnabled } from 'owa-feature-flags';
import { getStore } from 'owa-protection-store/lib/store/protectionStore';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import type { MailboxInfo } from 'owa-client-types';

export default function isCLPEnabled(mailboxInfo: MailboxInfo): boolean {
    const protectionStore = getStore(mailboxInfo);
    const { clpLabels } = protectionStore;
    return (
        isFeatureEnabled(
            'cmp-clp',
            mailboxInfo,
            !!mailboxInfo /* dontThrowErrorIfNotInitialized to help visually identify the scenarios hitting the error */
        ) &&
        clpLabels.length > 0 &&
        clpLabels.some(
            label =>
                (!label.children?.length && label.isLabelEnabled) ||
                label.children?.some(childLabel => childLabel.isLabelEnabled)
        ) &&
        !isConsumer(undefined /* smtpAddress */, mailboxInfo)
    );
}
