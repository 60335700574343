import type TableView from '../store/schema/TableView';
import isUnreadSearchFolder from '../utils/isUnreadSearchFolder';
import getViewFilterForTable from '../utils/getViewFilterForTable';
import {
    getIsBitSet,
    type ListViewBitFlagsMasks,
} from 'owa-bit-flags/lib/utils/listViewBitFlagsUtil';
import { logUsage } from 'owa-analytics';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

/**
 * Determines whether the given table supports auto mark as read
 * @param tableView tableView
 * @returns true if the table is unread and supports auto mark as read.
 */
export default function doesTableSupportAutoMarkRead(tableView: TableView) {
    const isUnreadTypeSearchFolder = isUnreadSearchFolder(tableView);
    if (getViewFilterForTable(tableView) !== 'Unread' && !isUnreadTypeSearchFolder) {
        // All tables that are not filtered by unread or displaying an unread search folder support auto mark as read
        return true;
    }

    const isAutoReadInUnreadFilterSet = getIsBitSet(64, getGlobalSettingsAccountMailboxInfo());

    // Logging some information around the unread filter property if it is set for a user or not.
    logUsage('Unread_Filter_Override', {
        isAutoReadInUnreadFilterSet,
    });

    // when filtered by unread auto read is supported only if setting is enabled.
    return isAutoReadInUnreadFilterSet;
}
