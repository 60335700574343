import type { AddinCommandSurfaceItem, ContextMenuDataItemClick } from 'owa-addins-types';
import type ContextMenuData from './ContextMenuData';
import getIconProps from '../components/SurfaceActionIconProps';
import type { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { ContextualMenuItemType } from '@fluentui/react/lib/ContextualMenu';

export default class ContextMenuDataItem {
    public description: string | undefined;
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2564 (14,12): Property 'accessibilityText' has no initializer and is not definitely assigned in the constructor.
    // @ts-expect-error
    public accessibilityText: string;
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2564 (18,12): Property 'subMenuData' has no initializer and is not definitely assigned in the constructor.
    // @ts-expect-error
    public subMenuData: ContextMenuData;
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2564 (22,12): Property 'getClickHandler' has no initializer and is not definitely assigned in the constructor.
    // @ts-expect-error
    public getClickHandler: ContextMenuDataItemClick;
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2564 (26,12): Property 'iconUrl' has no initializer and is not definitely assigned in the constructor.
    // @ts-expect-error
    public iconUrl: string;
    constructor(protected key?: string, protected name?: string) {}

    public generateData(): AddinCommandSurfaceItem {
        return {
            id: this.key,
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (35,13): Type 'string | undefined' is not assignable to type 'string'.
            // @ts-expect-error
            key: this.key,
            name: this.name,
            title: this.description,
            ariaLabel: this.accessibilityText,
            iconProps: getIconProps(this.iconUrl),
            itemType: ContextualMenuItemType.Normal,
            getClickHandler: this.getClickHandler,
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (45,13): Type '{ items: IContextualMenuItem[]; } | null' is not assignable to type 'IContextualMenuProps | undefined'.
            // @ts-expect-error
            subMenuProps: this.subMenuData
                ? {
                      items: [this.subMenuData.generateData() as IContextualMenuItem],
                  }
                : null,
        };
    }
}
