import { getStore } from '../store/tabStore';

export default function findTabIdBySxSId(sxsId: string | null): string {
    const store = getStore();

    for (const tab of store.tabs) {
        if (tab.sxsId === sxsId) {
            return tab.id;
        }
    }

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (15,5): Type 'null' is not assignable to type 'string'.
    // @ts-expect-error
    return null;
}
