import type RecipientWellWithFindControlViewState from 'owa-recipient-types/lib/types/RecipientWellWithFindControlViewState';
import type ReadWriteRecipientViewState from 'owa-recipient-types/lib/types/ReadWriteRecipientViewState';
import type ReadWriteRecipientWellViewState from 'owa-recipient-types/lib/types/ReadWriteRecipientWellViewState';
import getEmailWithRoutingType from 'owa-recipient-email-address/lib/utils/getEmailWithRoutingType';
import {
    lazySearchSuggestionsForSinglePersona,
    lazyLogManuallyAddedContact,
} from 'owa-recipient-suggestions';
import resolvePendingPersonaOnRecipient from './resolvePendingPersonaOnRecipient';
import { action, mutator, orchestrator } from 'satcheljs';
import { getGuid } from 'owa-guid';
import is3SLoggingSupported from 'owa-recipient-suggestions/lib/util/is3SLoggingSupported';
import { sendOpxIsDirtyMessage } from '../utils/sendIsOpxDirtyMessage';

/**
 * Action for adding recipients in bulk
 * @param newRecipients New recipients to be added
 * @param recipientWell View state of recipient well
 */
const addRecipientsToRecipientWell = action(
    'addRecipientsToRecipientWell',
    (
        newRecipients: ReadWriteRecipientViewState[],
        recipientWell: ReadWriteRecipientWellViewState,
        targetWindow: Window = window
    ) => ({
        newRecipients,
        recipientWell,
        targetWindow,
    })
);

export default addRecipientsToRecipientWell;

// Mutating recipientWell state
mutator(addRecipientsToRecipientWell, actionMessage => {
    const { recipientWell } = actionMessage;

    recipientWell.recipients.splice(
        recipientWell.recipients.length,
        0,
        ...actionMessage.newRecipients
    );
    recipientWell.isDirty = true;
});

// Async fetching recipients for new pending recipients
orchestrator(addRecipientsToRecipientWell, actionMessage => {
    const { newRecipients, recipientWell } = actionMessage;
    const mailboxInfo = (recipientWell as RecipientWellWithFindControlViewState).mailboxInfo;
    // Callers of addRecipientToRecipientWell should be passing RecipientWellWithFindControlViewState, if they do not we cannot resolve
    if (mailboxInfo) {
        /* eslint-disable-next-line no-async-in-foreach/no-async-in-foreach -- (https://aka.ms/OWALintWiki)
         * This is baseline exception, if you edit this file you need to fix this exception. Async functions in .forEach lead to promise branching and are not waited for by the calling code
         *	> An async callback inside `forEach` swallows promises. You should either convert to `for...of` syntax, or swap `forEach` for `map` and wrap in a `Promise.all`. */
        newRecipients.forEach(async newRecipient => {
            if (!newRecipient.isPendingResolution) {
                // Do nothing for recipients that are not pending.
                return;
            }
            // Search by their email address w/ routing type
            const emailWithRouting = getEmailWithRoutingType(newRecipient.persona.EmailAddress);

            const shouldLog3SEvents = is3SLoggingSupported(
                recipientWell as RecipientWellWithFindControlViewState
            );
            const singlePersona = await lazySearchSuggestionsForSinglePersona
                .import()
                .then(searchSuggestionsForSinglePersona =>
                    searchSuggestionsForSinglePersona(
                        mailboxInfo,
                        emailWithRouting,
                        shouldLog3SEvents
                    )
                );

            if (shouldLog3SEvents && singlePersona === null) {
                const recipientWellViewState =
                    recipientWell as RecipientWellWithFindControlViewState;
                const cvid =
                    recipientWellViewState.findPeopleFeedbackManager?.conversationId || getGuid();
                lazyLogManuallyAddedContact
                    .import()
                    .then(logManuallyAddedContact =>
                        logManuallyAddedContact(mailboxInfo, newRecipient.persona, cvid)
                    );
                if (recipientWellViewState.findPeopleFeedbackManager) {
                    recipientWellViewState.findPeopleFeedbackManager.conversationId = getGuid();
                }
            }

            resolvePendingPersonaOnRecipient(newRecipient, singlePersona, emailWithRouting);
        });
    }
    // Dirty state indication for Amplify for OPX metadata modification
    if (actionMessage.recipientWell.isDirty) {
        sendOpxIsDirtyMessage(actionMessage.recipientWell.postMessageToHostOnComposeDirty ?? false);
    }
});
