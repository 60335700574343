import { getStore } from '../store/store';
import { mutatorAction } from 'satcheljs';

export default mutatorAction(
    'setMessageAdListResetProperties',
    function (refreshAfterDeleteInterval: number, isSuperClicker: boolean): void {
        const store = getStore();
        if (store) {
            store.refreshAfterDeleteInterval = refreshAfterDeleteInterval;
            store.isSuperClicker = isSuperClicker;
        }
    }
);
