import { LazyAction, LazyImport, LazyModule } from 'owa-bundling';
import type { LazyActionOptions } from 'owa-bundling-light';
import wrapLazy from './utils/wrapLazy';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailComposeActions"*/ './lazyIndex'),
    { name: 'MailComposeActions' }
);
export const send = wrapLazy(lazyModule, 'send');
export const save = wrapLazy(lazyModule, 'save');
export const runPostOpenTasks = wrapLazy(lazyModule, 'runPostOpenTasks');
export const switchBodyType = wrapLazy(lazyModule, 'switchBodyType');
export const discard = wrapLazy(lazyModule, 'discard');
export const upConvert = wrapLazy(lazyModule, 'upConvert');
export const insertSignature = wrapLazy(lazyModule, 'insertSignature');
export const insertSignatureForAddin = wrapLazy(lazyModule, 'insertSignatureForAddin');
export const popoutCompose = wrapLazy(lazyModule, 'popoutCompose');
export const createAttachments = wrapLazy(lazyModule, 'createAttachments');
export const clickAttachButton = wrapLazy(lazyModule, 'clickAttachButton');
export const setFromAddress = wrapLazy(lazyModule, 'setFromAddress');
export const onClickFromMenu = wrapLazy(lazyModule, 'onClickFromMenu');
export const onExitMailMerge = wrapLazy(lazyModule, 'onExitMailMerge');
export const onSetupMailMerge = wrapLazy(lazyModule, 'onSetupMailMerge');

// import owa-options-message-options to load the message options not included in sessionData

const options: LazyActionOptions = {
    captureBundleTime: true,
};

export const lazyReplyToMessage = new LazyAction(lazyModule, m => m.replyToMessage, options);
export const lazyResendMessage = new LazyAction(lazyModule, m => m.resendMessage, options);
export const lazyForwardMessage = new LazyAction(lazyModule, m => m.forwardMessage, options);
export const lazyCloseCompose = new LazyAction(lazyModule, m => m.closeCompose);
export const lazyDiscardCompose = new LazyAction(lazyModule, m => m.discardCompose);
export const lazyLoadDraftToCompose = new LazyAction(lazyModule, m => m.loadDraftToCompose);
export const lazyOpenCompose = new LazyAction(lazyModule, m => m.openCompose);
export const lazyOpenComposeAndChangeFrom = new LazyAction(
    lazyModule,
    m => m.openComposeAndChangeFrom
);
export const lazyToggleFrom = new LazyAction(lazyModule, m => m.toggleFrom);
export const lazyTrySaveAndCloseCompose = new LazyAction(lazyModule, m => m.trySaveAndCloseCompose);
export const lazyTrySendMessageFromOnSendItemSwitchDialog = new LazyAction(
    lazyModule,
    m => m.trySendMessageFromOnSendItemSwitchDialog
);
export const lazyMoveComposeToTab = new LazyAction(lazyModule, m => m.moveComposeToTab);
export const lazyTryCreateResendDraft = new LazyAction(lazyModule, m => m.tryCreateResendDraft);
export const lazyReplyToApprovalMessage = new LazyAction(lazyModule, m => m.replyToApprovalMessage);
export const lazyOnEditDraftButtonClicked = new LazyAction(
    lazyModule,
    m => m.onEditDraftButtonClicked
);
export const lazyTrySaveMessage = new LazyAction(lazyModule, m => m.trySaveMessage);
export const lazyInsertReplyWithText = new LazyAction(lazyModule, m => m.insertReplyWithText);

export type { ComposeViewStateInitOptions } from './schema/ComposeViewStateInitOptions';
export type { ReplyToMessageInitOptions } from './schema/ReplyToMessageInitOptions';

// Delay loaded utilities
export const lazyGetToCcRecipientsForReply = new LazyImport(
    lazyModule,
    m => m.getToCcRecipientsForReply
);
export const lazyReplyToMessageAndCreateViewState = new LazyImport(
    lazyModule,
    m => m.replyToMessageAndCreateViewState
);
export const lazyCreateAttachments = new LazyImport(lazyModule, m => m.createAttachments);
export const lazyExtractComposePopoutData = new LazyImport(
    lazyModule,
    m => m.extractComposePopoutData
);

export const lazyPopoutComposeAndWaitForProjection = new LazyImport(
    lazyModule,
    m => m.popoutComposeAndWaitForProjection
);

export const lazyCheckShouldUseFallbackPicker = new LazyImport(
    lazyModule,
    m => m.checkShouldUseFallbackPicker
);
export const lazyTryGetSmimeViewState = new LazyImport(lazyModule, m => m.tryGetSmimeViewState);
export const lazyGetActingMailboxInfoForNewMessage = new LazyImport(
    lazyModule,
    m => m.getActingMailboxInfoForNewMessage
);
export const lazyGetRespondSubject = new LazyImport(lazyModule, m => m.getRespondSubject);
export const lazyWaitForServiceId = new LazyAction(lazyModule, m => m.waitForServiceId);
