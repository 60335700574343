import { createLazyComponent, LazyModule, registerLazyOrchestrator } from 'owa-bundling';
import { openOneNoteFeedPanel } from 'owa-notes-feed-bootstrap';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "OwaNotes" */ './lazyIndex'));

// Lazy orchestrator
registerLazyOrchestrator(openOneNoteFeedPanel, lazyModule, m => m.openOneNoteFeedPanelOrchestrator);

// Delay loaded components
export const NotesPane = createLazyComponent(lazyModule, m => m.NotesPane);
