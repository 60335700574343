import { SearchScenarioId, getScenarioStore } from 'owa-search-store';
import { type SearchHeaderType } from '../store/schema/SearchHeaderType';

export default function shouldShowModifiedQueryInformationalView(
    searchHeaderType: SearchHeaderType = 1
) {
    const { queryAlterationType } = getScenarioStore(SearchScenarioId.Mail);

    return searchHeaderType == 1 || queryAlterationType === 'NoResultFolderRefinerModification';
}
