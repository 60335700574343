import { getOrigin } from 'owa-url/lib/getOrigin';

const CURRENT_POPOUT_VERSION = 2;

export enum PopoutMessageType {
    Unknown = 0,
    PopoutReady = 1,
    SetData = 2,
    CloseWindow = 3,
    DeeplinkReady = 4,
    Discard = 5,
}

export interface PopoutMessage {
    version: number;
    type: PopoutMessageType;
    serializedData: string;
}

export function postMessage(targetWindow: Window, type: PopoutMessageType, data?: object) {
    const message: PopoutMessage = {
        version: CURRENT_POPOUT_VERSION,
        type,
        serializedData: JSON.stringify(data),
    };

    targetWindow.postMessage(JSON.stringify(message), targetWindow.location.origin);
}

export function parseMessage(e: MessageEvent): PopoutMessage {
    if (getOrigin() == e.origin) {
        let message: PopoutMessage;

        try {
            message = JSON.parse(e.data) as PopoutMessage;
        } catch {
            // Fail to parse message
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (40,13): Type 'null' is not assignable to type 'PopoutMessage'.
            // @ts-expect-error
            return null;
        }

        if (message) {
            if (CURRENT_POPOUT_VERSION == message.version) {
                return message;
            } else {
                // TODO: Handle version difference
            }
        }
    }

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (55,5): Type 'null' is not assignable to type 'PopoutMessage'.
    // @ts-expect-error
    return null;
}
