import normalizeThemeId from '../utils/normalizeThemeId';
import type { ThemeResources } from 'owa-theme-shared';
import {
    getThemeIdFromParameter,
    getIsDarkThemeFromParameter,
} from '../utils/optionalArgumentHelpers';
import { fetchThemeResource } from './fetchThemeResource';
import { shouldUseCobranding, getCobrandingThemeResources } from 'owa-theme-common';
import { ThemeConstants } from 'owa-theme-shared';
import { owaComputedFn } from 'owa-computed-fn';
import { getCopilotThemeResources } from 'owa-copilot-themes-config';
import getIsCurrentThemeCopilot from './getIsCurrentThemeCopilot';

const createResourcesForTheme = owaComputedFn(
    (normalizedTheme: string, isDarkTheme: boolean): ThemeResources => {
        if (shouldUseCobranding(normalizedTheme)) {
            // in dark mode the cobranding theme is overrided by the office neutral theme
            if (isDarkTheme) {
                return fetchThemeResource(
                    ThemeConstants.OFFICE_NEUTRAL_THEME_ID,
                    isDarkTheme
                ) as ThemeResources;
            } else {
                return getCobrandingThemeResources();
            }
        }

        return fetchThemeResource(normalizedTheme, isDarkTheme) as ThemeResources;
    }
);

export default function getResourcesForTheme(
    themeId?: string,
    isDarkTheme?: boolean
): ThemeResources {
    themeId = getThemeIdFromParameter(themeId);
    isDarkTheme = getIsDarkThemeFromParameter(isDarkTheme);
    const normalizedTheme = normalizeThemeId(themeId);

    // For copilot themes, this logic needs to be executed outside
    // the createResourcesForTheme computed function because it isn't idempotent
    if (getIsCurrentThemeCopilot(normalizedTheme)) {
        return getCopilotThemeResources(isDarkTheme);
    } else {
        return createResourcesForTheme(normalizedTheme, isDarkTheme);
    }
}
