import { isFeatureEnabled } from 'owa-feature-flags';
import { isSupportedAppServiceEnvironment } from './isSupportedAppServiceEnvironment';
import type { MailboxInfo } from 'owa-client-types';

export function isMOS3AppServiceAvailable(mailboxInfo?: MailboxInfo): boolean {
    return (
        isFeatureEnabled('mos-mos3AppService', mailboxInfo) &&
        isSupportedAppServiceEnvironment(mailboxInfo)
    );
}
