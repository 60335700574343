import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';
import {
    getStore as getListViewStore,
    getListViewState,
    MailRowDataPropertyGetter,
} from 'owa-mail-list-store';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { owaComputedFn } from 'owa-computed-fn';
import getIsChecked from './getIsChecked';

export const getIsIndeterminate = owaComputedFn(
    (tableView: TableView | undefined, rowKey: string, lastDeliveryTimestamp: string): boolean => {
        const expansionState = getListViewState().expandedConversationViewState;
        if (
            !tableView ||
            rowKey !== expansionState?.expandedRowKey ||
            tableView.tableQuery.listViewType !== 0
        ) {
            return false;
        }

        const conversationId = MailRowDataPropertyGetter.getConversationId(rowKey, tableView);
        const conversationItem = getListViewStore().conversationItems.get(conversationId);
        const globalMessageCount = conversationItem?.globalMessageCount || 0;

        // The indeterminate state is true when 1 or more but not all children of a conversation are checked.
        return (
            !getIsChecked(tableView, rowKey, lastDeliveryTimestamp) &&
            expansionState?.selectedNodeIds?.length > 0 &&
            globalMessageCount > 1
        );
    }
);
