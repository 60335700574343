import { getStore } from '../store';
import { type DatapointVariant, logGreyError } from 'owa-analytics';

// Mapping for specific exceptions not covered by the standard store lookup
const specialCaseTimeZoneMapping: {
    [key: string]: string;
} = {
    UTC: 'UTC',
    'Africa/Sao_Tome': 'GMT Standard Time',
};

export default function getWindowsTimeZoneIdFromIANAId(IANATimeZone: string): string | undefined {
    // Check if the IANA time zone is a special case handled in the static mapping
    const specialCaseTimeZone = specialCaseTimeZoneMapping[IANATimeZone];
    if (specialCaseTimeZone) {
        return specialCaseTimeZone;
    }

    const { TimeZoneAlternateNames } = getStore();
    let windowsTimeZone: string | undefined = undefined;

    for (const timeZone of TimeZoneAlternateNames.keys()) {
        const IANAAlternativeNames = TimeZoneAlternateNames.get(timeZone);
        if (IANAAlternativeNames?.includes(IANATimeZone)) {
            windowsTimeZone = timeZone;
            break;
        }
    }

    // Log an unhandled time zone to help identify gaps in the mappings that may need to be added, aria alert will be created.
    if (!windowsTimeZone) {
        logGreyError(
            'UnhandledIANATimeZone',
            new Error('Unhandled IANA time zone'),
            { IANATimeZone },
            {
                variant: 2,
            }
        );
    }

    return windowsTimeZone;
}
