import { getLocalizedString } from 'owa-datetime-formatters-core';
import { format } from 'owa-localize';
import { localizedFormatter } from './localizedFormatter';
import { getMonthDayFormat } from './formatMonthDay';
import { getShortTimeFormat } from './formatShortTime';

export function getMonthDayShortTimeFormat(dateFormat: string, timeFormat: string) {
    const monthDayFormat = getMonthDayFormat(dateFormat, timeFormat);
    const shortTimeFormat = getShortTimeFormat(dateFormat, timeFormat);
    return format(getLocalizedString('userDateTimeFormat'), monthDayFormat, shortTimeFormat);
}

export default localizedFormatter(getMonthDayShortTimeFormat);
