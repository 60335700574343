import { getStoreByMailboxInfo } from '../store/store';
import { mutatorAction } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import type ReportSubmissionPolicy from '../types/ReportSubmissionPolicy';

const onReportSubmissionPolicyFetched = mutatorAction(
    'onReportSubmissionPolicyFetched',
    (mailboxInfo: MailboxInfo, reportSubmissionPolicy: ReportSubmissionPolicy) => {
        const reportingStore = getStoreByMailboxInfo(mailboxInfo);
        reportingStore.reportSubmissionPolicy = reportSubmissionPolicy;
    }
);

export default onReportSubmissionPolicyFetched;
