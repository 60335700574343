import type { SearchResultEvent } from '../../data/schema/SubstrateSearchResponse';
import type CalendarItem from 'owa-service/lib/contract/CalendarItem';
import type CalendarItemTypeType from 'owa-service/lib/contract/CalendarItemTypeType';
import {
    convertRecipientToSingleRecipientType,
    convertToFolderId,
    convertToItemId,
    convertSearchSortOrder,
} from './converterHelpers';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

const DEFAULT_ITEMCLASS: string = 'IPM.Appointment';

export default function convertSearchResultEventToCalendarItemType(
    searchResult: SearchResultEvent
): CalendarItem {
    const organizer = convertRecipientToSingleRecipientType(searchResult.Organizer);
    return {
        CalendarItemType: searchResult.CalendarItemType.toString() as CalendarItemTypeType,
        Categories: searchResult.Categories,
        CharmId: searchResult.Charm,
        DisplayTo: searchResult.DisplayTo,
        End: searchResult.End,
        Start: searchResult.Start,
        HasAttachments: searchResult.HasAttachments,
        IsAllDayEvent: searchResult.IsAllDayEvent,
        IsMeeting: searchResult.IsMeeting,
        IsMeetingPollEvent: searchResult.IsMeetingPollEvent,
        ItemClass: searchResult.ItemClass ? searchResult.ItemClass : DEFAULT_ITEMCLASS,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (33,9): Type 'string | null' is not assignable to type 'string | undefined'.
        // @ts-expect-error
        FreeBusyType: searchResult.LegacyFreeBusyStatus
            ? searchResult.LegacyFreeBusyStatus.toString()
            : null,
        Location: { DisplayName: searchResult.Location },
        Sensitivity: searchResult.Sensitivity,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (41,9): Type 'string | null' is not assignable to type 'string | undefined'.
        // @ts-expect-error
        SeriesId: searchResult.SeriesMasterItemId ? searchResult.SeriesMasterItemId.Id : null,
        Subject: searchResult.Subject,
        Organizer: organizer,
        UID: searchResult.UID,
        ItemId: convertToItemId(searchResult.ItemId),
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (49,9): Type 'import("packages/framework/owa-graph-schema/lib/__generated__/schema.all.interface").FolderId' is not assignable to type 'import("C:/Users/loicbe/workspace/client-web/shared/internal/owa-service/lib/contract/FolderId").default'.
        // @ts-expect-error
        ParentFolderId: convertToFolderId(searchResult.ParentFolderId),
        SerializedImmutableId: searchResult.ImmutableId,
        SortOrderSource: convertSearchSortOrder(searchResult.SortOrderSource),
        Preview: searchResult.Preview,
        // sometimes 3S is wrong about whether you're the organizer, so also check organizer mailbox info
        IsOrganizer:
            searchResult.IsOrganizer &&
            getAccountScopeUserSettings(getModuleContextMailboxInfo()).SessionSettings
                ?.UserEmailAddress === organizer?.Mailbox?.EmailAddress,
        IsCancelled: searchResult.IsCancelled,
        ResponseType: searchResult.MyResponseType,
    };
}
