import type ChatProvider from './schema/ChatProvider';
import type ChatStore from './schema/ChatStore';
import { createStore } from 'satcheljs';

const initialChatStore: ChatStore = {
    chatProvider: 0,
};

const store = createStore<ChatStore>('chatStore', initialChatStore)();
export default store;
export const getStore = () => store;
