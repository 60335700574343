import { HighContrastSelector } from '@fluentui/react';
import type {
    IContextualMenuStyles,
    IDialogStyles,
    IDialogFooterStyles,
    ISearchBoxStyles,
    IDropdownStyles,
    IComboBoxStyles,
    ITextFieldStyles,
    ICalloutContentStyles,
    IChoiceGroupStyles,
    IButtonStyles,
    IToggleStyles,
    ISliderStyles,
    PartialTheme,
    ITooltipStyles,
} from '@fluentui/react';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { getPalette } from 'owa-theme';
export function useCustomFluentComponentsTheme(): PartialTheme {
    return useComputedValue((): PartialTheme => {
        const customContextualMenuStyles: Partial<IContextualMenuStyles> = {
            root: {
                backgroundColor: getPalette().neutralPrimarySurface,
            },
            subComponentStyles: {
                callout: {},
                menuItem: {
                    root: {
                        selectors: {
                            '.ms-ContextualMenu-secondaryText': {
                                color: getPalette().neutralPrimaryAlt,
                            },
                            '.fui-FluentProvider': {
                                color: 'inherit',
                                backgroundColor: 'inherit',
                            },
                            '.ms-ContextualMenu-link': {
                                backgroundColor: getPalette().neutralPrimarySurface,
                                color: getPalette().neutralPrimary,
                                '&:hover': {
                                    backgroundColor: getPalette().neutralLighter,
                                    color: getPalette().neutralPrimary,
                                },
                                '&:active': {
                                    backgroundColor: getPalette().neutralTertiaryAlt,
                                    color: getPalette().neutralPrimary,
                                },
                            },
                        },
                    },
                    item: {
                        color: getPalette().neutralPrimary,
                        selectors: {
                            '.ms-ContextualMenu-link.is-expanded': {
                                backgroundColor: getPalette().neutralTertiaryAlt,
                            },
                        },
                    },
                    subMenuIcon: {
                        color: getPalette().neutralPrimary,
                    },
                    divider: { backgroundColor: getPalette().neutralTertiaryAlt },
                },
            },
        };

        const customSearchBoxStyles: ISearchBoxStyles = {
            root: {
                background: getPalette().neutralPrimarySurface,
            },
            field: {
                color: getPalette().black,
            },
        };

        const customDropdownStyles: Partial<IDropdownStyles> = {
            callout: {
                selectors: {
                    '.ms-Callout-main': {
                        backgroundColor: getPalette().neutralPrimarySurface,
                    },
                },
            },
            dropdownItem: {
                color: getPalette().neutralPrimary,
                selectors: {
                    '&:hover': {
                        backgroundColor: getPalette().neutralLighter,
                        color: getPalette().neutralPrimary,
                    },
                },
            },
            dropdownItemSelected: {
                color: getPalette().neutralPrimary,
                backgroundColor: getPalette().neutralTertiaryAlt,
                selectors: {
                    '&:hover': {
                        color: getPalette().neutralPrimary,
                        backgroundColor: getPalette().neutralTertiaryAlt,
                    },
                },
            },
            dropdown: {
                backgroundColor: getPalette().neutralPrimarySurface,
            },
            title: {
                backgroundColor: 'inherit',
            },
        };

        const customToggleStyles: Partial<IToggleStyles> = {
            pill: {
                background: getPalette().neutralPrimarySurface,
                borderColor: getPalette().neutralPrimaryAlt,
                selectors: {
                    // Toggle disabled
                    '&:disabled': {
                        [HighContrastSelector]: {
                            background: 'ButtonFace',
                            borderColor: 'GrayText',
                            '.ms-Toggle-thumb': {
                                background: 'GrayText',
                            },
                        },
                    },
                    // Toggle checked & enabled
                    '&[aria-checked=true]:enabled': {
                        background: getPalette().themePrimary,
                        borderColor: getPalette().themePrimary,
                        '.ms-Toggle-thumb': {
                            background: getPalette().neutralPrimarySurface,
                        },
                        [HighContrastSelector]: {
                            background: 'Highlight',
                            borderColor: 'Highlight',
                            '.ms-Toggle-thumb': {
                                background: 'Canvas',
                                borderColor: 'Canvas',
                            },
                        },
                    },
                    // Toggle checked, enabled, and hovered
                    '&[aria-checked=true]:enabled:hover': {
                        background: getPalette().themeDark,
                        borderColor: 'transparent',
                        [HighContrastSelector]: {
                            background: 'Highlight',
                            borderColor: 'Highlight',
                            '.ms-Toggle-thumb': {
                                background: 'Canvas',
                                borderColor: 'Canvas',
                            },
                        },
                    },
                    // Toggle unchecked & enabled
                    '&[aria-checked=false]:enabled': {
                        [HighContrastSelector]: {
                            background: 'ButtonFace',
                            borderColor: 'ButtonText',
                            '.ms-Toggle-thumb': {
                                background: 'ButtonText',
                            },
                        },
                    },
                    // Toggle unchecked, enabled, and hovered
                    '&[aria-checked=false]:enabled:hover': {
                        [HighContrastSelector]: {
                            background: 'ButtonFace',
                            borderColor: 'Highlight',
                            '.ms-Toggle-thumb': {
                                background: 'Highlight',
                            },
                        },
                    },
                    // Toggle focused via keyboard
                    '.ms-Fabric--isFocusVisible &:focus::after': {
                        [HighContrastSelector]: {
                            borderColor: 'ButtonBorder',
                            borderWidth: 1,
                            inset: -7,
                        },
                    },
                },
            },
            thumb: {
                backgroundColor: getPalette().neutralPrimaryAlt,
            },
        };

        const customBaseButtonStyles: IButtonStyles = {
            root: {
                // Reverting these changes in `BaseButton.styles.ts`: https://github.com/microsoft/fluentui/pull/32380/files#diff-569554ae71c25e694545ea4f5fcad8ffade4f72934af919b9618f72181081c91
                display: 'inline-block',
                alignItems: 'normal',
                justifyContent: 'normal',
            },
        };

        const customDefaultButtonStyles: IButtonStyles = {
            root: {
                backgroundColor: getPalette().neutralPrimarySurface,
                borderColor: getPalette().neutralTertiary,
                // Reverting these changes in `DefaultButton.styles.ts`: https://github.com/microsoft/fluentui/pull/29346/files#diff-43901b860736dbe2d6a53c1b66e3ff0754bf9ac143374d1a10f8535bd76dd19c
                minHeight: 'auto',
                height: '32px',
                ...(customBaseButtonStyles.root as {}),
            },
            rootHovered: {
                backgroundColor: getPalette().neutralLighter,
                borderColor: getPalette().neutralTertiary,
            },
            rootPressed: {
                backgroundColor: getPalette().neutralLight,
                borderColor: getPalette().neutralTertiary,
            },
            rootDisabled: {
                backgroundColor: `${getPalette().neutralLighter} !important`,
                borderColor: `${getPalette().neutralLighter} !important`,
                color: `${getPalette().neutralTertiary} !important`,
            },
        };

        const customPrimaryButtonStyles: IButtonStyles = {
            root: {
                backgroundColor: getPalette().themePrimary,
                borderColor: getPalette().themePrimary,
                color: getPalette().white,
            },
            rootHovered: {
                backgroundColor: getPalette().themeDark,
                borderColor: getPalette().themeDark,
                color: getPalette().white,
            },
            rootPressed: {
                backgroundColor: getPalette().themeDarker,
                borderColor: getPalette().themeDarker,
                color: getPalette().white,
            },
            rootDisabled: {
                backgroundColor: `${getPalette().neutralLighter} !important`,
                borderColor: `${getPalette().neutralLighter} !important`,
                color: `${getPalette().neutralTertiary} !important`,
            },
        };

        const customChoiceGroupStyles: Partial<IChoiceGroupStyles> = {
            root: {
                selectors: {
                    '.ms-ChoiceField-field::before': {
                        backgroundColor: getPalette().neutralPrimarySurface,
                    },
                },
            },
        };

        const customCalloutStyles: Partial<ICalloutContentStyles> = {
            beak: {
                background: getPalette().neutralPrimarySurface,
            },
            beakCurtain: {
                background: getPalette().neutralPrimarySurface,
            },
            calloutMain: {
                backgroundColor: getPalette().neutralPrimarySurface,
            },
        };

        const customTooltipStyles: Partial<ITooltipStyles> = {
            content: {
                color: getPalette().black,
            },
        };

        const customSliderStyles: Partial<ISliderStyles> = {
            thumb: {
                backgroundColor: getPalette().neutralPrimarySurface,
            },
        };

        const customComboBoxStyles: Partial<IComboBoxStyles> = {
            callout: {
                selectors: {
                    '.ms-Callout-main': {
                        backgroundColor: getPalette().neutralPrimarySurface,
                    },
                },
            },
            root: {
                color: getPalette().neutralPrimary,
                backgroundColor: getPalette().neutralPrimarySurface,
            },
            input: {
                backgroundColor: 'inherit',
            },
        };

        const customTextFieldStyles: Partial<ITextFieldStyles> = {
            root: {
                color: getPalette().neutralPrimary,
            },
            fieldGroup: {
                backgroundColor: getPalette().neutralPrimarySurface,
            },
        };

        const customDialogStyles: IDialogStyles = {
            root: {
                selectors: {
                    '.ms-Dialog-header': {
                        '.ms-Dialog-title': {
                            color: getPalette().black,
                        },
                    },
                    '.ms-Dialog-inner': {
                        '.ms-Dialog-content': {
                            '.ms-Dialog-subText': {
                                color: getPalette().neutralPrimary,
                            },
                        },
                    },
                },
            },
            main: {
                backgroundColor: getPalette().neutralPrimarySurface,
            },
        };

        const customDialogFooterStyles: Partial<IDialogFooterStyles> = {
            action: {
                selectors: {
                    '.ms-Button.ms-Button--primary': {
                        backgroundColor: getPalette().themePrimary,
                        color: getPalette().white,
                        borderColor: getPalette().themePrimary,
                        '&:hover': {
                            '.ms-Button.ms-Button--primary': {
                                backgroundColor: getPalette().themeDark,
                            },
                        },
                        '&:active': {
                            '.ms-Button.ms-Button--primary': {
                                backgroundColor: getPalette().themeDarker,
                            },
                        },
                    },
                    '.ms-Button.ms-Button--default': {
                        backgroundColor: getPalette().neutralPrimarySurface,
                        borderColor: getPalette().neutralTertiary,
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        '&:hover': {
                            '.ms-Button.ms-Button--default': {
                                backgroundColor: getPalette().neutralLighter,
                            },
                        },
                        '&:active': {
                            '.ms-Button.ms-Button--default': {
                                backgroundColor: getPalette().neutralLight,
                            },
                        },
                    },
                },
            },
        };
        return {
            components: {
                ContextualMenu: { styles: customContextualMenuStyles },
                SearchBox: { styles: customSearchBoxStyles },
                Dropdown: { styles: customDropdownStyles },
                Dialog: { styles: customDialogStyles },
                DialogFooter: { styles: customDialogFooterStyles },
                ComboBox: { styles: customComboBoxStyles },
                TextField: { styles: customTextFieldStyles },
                ChoiceGroup: { styles: customChoiceGroupStyles },
                ActionButton: { styles: customBaseButtonStyles },
                CommandBarButton: { styles: customBaseButtonStyles },
                CompoundButton: { styles: customBaseButtonStyles },
                DefaultButton: { styles: customDefaultButtonStyles },
                IconButton: { styles: customBaseButtonStyles },
                PrimaryButton: { styles: customPrimaryButtonStyles },
                CommandButton: { styles: customDefaultButtonStyles },
                Toggle: { styles: customToggleStyles },
                Slider: { styles: customSliderStyles },
                CalloutContent: { styles: customCalloutStyles },
                Tooltip: { styles: customTooltipStyles },
            },
        };
    }, []);
}
