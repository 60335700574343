import type { LazyModule } from 'owa-bundling';
import { LazyImport } from 'owa-bundling';
import type { CoreOption, LazyOption } from '../store/schema/Option';
import type OptionComponentSettings from '../store/schema/OptionComponentSettings';

function createLazyOption<TFullModule>(
    coreOption: CoreOption,
    fullOptionLazyModule?: LazyModule<TFullModule>,
    fullSettingsGetter?: (m: TFullModule) => OptionComponentSettings
): LazyOption {
    const fullLazyImport =
        fullOptionLazyModule && fullSettingsGetter
            ? new LazyImport(fullOptionLazyModule, fullSettingsGetter)
            : null;

    return {
        ...coreOption,
        tryImportFullOptionFormSettingsSync: fullLazyImport
            ? () => fullLazyImport.tryImportForRender()
            : undefined,
    };
}

export { createLazyOption };
