import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import { isGeneratedAddress } from 'owa-client-ids/lib/utils/getDefaultRoutingKey';
import { isFeatureEnabled } from 'owa-feature-flags';
import { logCoreUsage } from 'owa-analytics';
import { type DatapointVariant } from 'owa-analytics-types';
import { getServerVersion } from 'owa-config/lib/envDiagnostics';
import { isGreaterThanOrEqualTo } from '../utils/semverCompare';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { getSelectedAccountMailboxInfo } from 'owa-account-source-list-store';

export function isEasiIdUser() {
    if (
        !isFeatureEnabled('growth-easiIdGmailCloudCache') &&
        !isFeatureEnabled('growth-easiIdGmailCloudCacheV2')
    ) {
        return false;
    }

    // TODO: In order to guarantee that a required substrate fix is available for every user
    // accessing this feature, this bandage is being added so we can proceed with the EASI ID
    // experiment. Once this build version is 100% available WW, this verification can be removed.
    const substrateFixVersion = '15.20.4846.000';
    const serverVersion = getServerVersion();
    if (serverVersion && !isGreaterThanOrEqualTo(serverVersion, substrateFixVersion)) {
        return false;
    }

    if (isNonPremiumUserAccessingCloudCacheInbox()) {
        logCoreUsage('nonPremiumUserAccessingCloudCache', undefined, {
            // this function is called more than once per session,
            // so we added this flag to log only the first datapoint
            variant: 2,
        });

        return false;
    }

    /* eslint-disable-next-line owa-custom-rules/no-reflow-methods  -- (https://aka.ms/OWALintWiki)
     * Reflow related methods should not be used, see https://aka.ms/multiaccountlinter
     *	> Do Not Use Reflow: The getSelectedAccountMailboxInfo function should not be used for Respond multi-account support. */
    const accountScopeUserSettings = getAccountScopeUserSettings(getSelectedAccountMailboxInfo());

    if (accountScopeUserSettings.SessionSettings?.WebSessionType != 1) {
        return false;
    }

    const emailAddress = accountScopeUserSettings.SessionSettings?.UserEmailAddress;
    const cid = accountScopeUserSettings.HexCID?.toUpperCase();

    // match returns null if the string does not match the pattern
    if (!emailAddress || !cid || !isGeneratedAddress(cid, emailAddress)) {
        return false;
    }

    // For the initial stage, we are going to consider only Gmail users as valid EASI ID users
    if (!isGmailUser()) {
        return false;
    }

    return true;
}

function isNonPremiumUserAccessingCloudCacheInbox() {
    return (
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * Adding IsShadowMailbox to restricted properties/methods.
         *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
        getUserConfiguration().SessionSettings?.IsShadowMailbox &&
        /* eslint-disable-next-line owa-custom-rules/no-reflow-methods  -- (https://aka.ms/OWALintWiki)
         * Reflow related methods should not be used, see https://aka.ms/multiaccountlinter
         *	> Do Not Use Reflow: The getSelectedAccountMailboxInfo function should not be used for Respond multi-account support. */
        !getAccountScopeUserSettings(getSelectedAccountMailboxInfo()).SessionSettings
            ?.IsPremiumConsumerMailbox
    );
}

function isGmailUser() {
    /* eslint-disable-next-line owa-custom-rules/no-reflow-methods  -- (https://aka.ms/OWALintWiki)
     * Reflow related methods should not be used, see https://aka.ms/multiaccountlinter
     *	> Do Not Use Reflow: The getSelectedAccountMailboxInfo function should not be used for Respond multi-account support. */
    const notManagedEmailAddresses = getAccountScopeUserSettings(getSelectedAccountMailboxInfo())
        .SessionSettings?.NotManagedEmailAddresses;

    if (
        !notManagedEmailAddresses ||
        notManagedEmailAddresses.length == 0 ||
        // we found that EasiId users have the first notManagedEmailAddresses array item filled with the original email
        // So, we use that to validate if it's a Gmail user based on its value
        notManagedEmailAddresses[0].indexOf('@gmail.com') < 0
    ) {
        return false;
    }

    return true;
}
