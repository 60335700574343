import { getSelectedApp } from 'owa-appbar-state';
import { createObservedTypePolicy } from 'owa-reactive-type-policies';
import getPaletteAsRawColors from 'owa-theme/lib/selectors/getPaletteAsRawColors';
import { disableNormalizationForTypes } from '../../utils/disableNormalizationForTypes';

import type {
    M365ApplicationFieldPolicy,
    M365ApplicationKeySpecifier,
    TypedTypePolicies,
} from 'owa-graph-schema-type-policies';
import type { TypePolicy } from '@apollo/client';

let commonTypePolicy:
    | (Omit<TypePolicy, 'fields' | 'keyFields'> & {
          keyFields?:
              | false
              | M365ApplicationKeySpecifier
              | (() => M365ApplicationKeySpecifier | undefined)
              | undefined;
          fields?: M365ApplicationFieldPolicy | undefined;
      })
    | undefined;

export const getM365ApplicationTypePolicy: () => TypedTypePolicies = () => {
    if (!commonTypePolicy) {
        commonTypePolicy = {
            keyFields: ['id', 'platformType'],
            /* Necessary to avoid AppBar's cache object overwriting from subsequent MessageExtension queries.
             * Without this specification, cache objects would share the same key and would be mistakenly overwritten */
            fields: {
                isInUse: {
                    read(_existing, { readField }) {
                        const idField: string | undefined = readField('id');
                        const selectedApp = getSelectedApp();
                        return selectedApp === idField;
                    },
                },
                brandingColor: createObservedTypePolicy(
                    'M365ApplicationType',
                    () => getPaletteAsRawColors().themePrimary
                ),
            },
        };
    }
    return {
        /**
         * Force field to be non-normalized (i.e. nested), as it has an (non-unique) `id` property, which Apollo,
         * by default, normalizes the field and uses the `id` as its key.
         * The secondary action has a static id, but each app can pass different event data
         */
        ...disableNormalizationForTypes(['AppSecondaryAction']),
        // Control behavior for Nova components
        ViewData: {
            merge: true,
        },
        // Theming and selected tile for the Nova appbar
        M365Application: commonTypePolicy,
        M365PlatformApplication: commonTypePolicy,
        M365HubApplication: commonTypePolicy,
    };
};
