import { action } from 'satcheljs';
import type AccountAuthState from '../store/schema/AccountAuthState';
import type { IdentityInfo } from 'owa-auth-types';

export default action(
    'setAccountAuthState',
    (identityInfo: IdentityInfo, authState: AccountAuthState) => ({
        identityInfo,
        authState,
    })
);
