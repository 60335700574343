import mailStore from 'owa-mail-store/lib/store/Store';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { isFirstLevelExpanded, isSecondLevelExpanded } from '../selectors/isConversationExpanded';
import type TableView from '../store/schema/TableView';
import getListViewState from '../selectors/getListViewState';
import { getConversationId } from '../selectors/mailRowDataPropertyGetter';

export default function getAreAllChildrenSelectedInConversation(
    rowKey: string,
    tableView: TableView
): boolean {
    const expansionState = getListViewState().expandedConversationViewState;

    if (expansionState?.expandedRowKey !== rowKey || tableView.tableQuery.listViewType !== 0) {
        return false;
    }

    const conversationId = getConversationId(rowKey, tableView);
    const conversation = mailStore.conversations.get(conversationId);
    let numConversationParts = 0;
    if (isFirstLevelExpanded(rowKey)) {
        numConversationParts = expansionState?.forks?.length || 0;
    } else if (isSecondLevelExpanded(rowKey)) {
        numConversationParts = conversation?.conversationNodeIds?.length || 0;
    }
    return (
        numConversationParts > 0 && numConversationParts === expansionState?.selectedNodeIds?.length
    );
}
