import { Module } from 'owa-workloads';

export function isAppBarEnabledForModule(module: Module): boolean {
    return (
        module === Module.Mail ||
        module === Module.Calendar ||
        module === Module.AppHost ||
        module === Module.People ||
        module === Module.FilesHub ||
        module === Module.Groups
    );
}
