import { action } from 'satcheljs';

import type { MessageExtensionType } from 'owa-graph-schema';
import type { MailboxInfo } from 'owa-client-types';

type OpenMessageExtensionsFlyout = {
    flyoutButtonElementId: string;
    targetWindow: Window;
    formId: string;
    readingPaneItemId: string;
    messageExtensionType: MessageExtensionType;
    mailboxInfo?: MailboxInfo;
    element?: HTMLElement;
};

export const onClickMessageExtensionFlyoutButton = action(
    'onClickMessageExtensionFlyoutButton',
    ({
        flyoutButtonElementId,
        targetWindow,
        formId,
        readingPaneItemId,
        messageExtensionType,
        mailboxInfo,
        element,
    }: OpenMessageExtensionsFlyout) => ({
        flyoutButtonElementId,
        targetWindow,
        formId,
        readingPaneItemId,
        messageExtensionType,
        mailboxInfo,
        element,
    })
);
