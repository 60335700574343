import type { MailboxInfo } from 'owa-client-types';
import type MailboxRequestOptions from './MailboxRequestOptions';
import type RequestOptions from './RequestOptions';

export default function getMailboxRequestOptions(
    mailboxInfo?: MailboxInfo | undefined | null,
    requestOptions?: RequestOptions | undefined | null
): MailboxRequestOptions | RequestOptions | undefined {
    if (mailboxInfo) {
        return {
            mailboxInfo,
            ...requestOptions,
        } as MailboxRequestOptions;
    } else {
        return requestOptions ?? undefined;
    }
}
