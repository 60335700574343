import type { MailboxInfo } from 'owa-client-types';
import { makePatchRequest } from 'owa-ows-gateway';
import type { OcsRequestOptions, OcsSettingScope, OcsSettingType, OcsVersion } from './common';
import { makeOcsBaseUrl } from './common';

export type OcsPatchEntry = {
    name: string;
    value: string;
    type: OcsSettingType;
    scope: OcsSettingScope.Global | string;
    itemClass: 'RoamingSetting';
    secondaryKey?: string;
};

export function writeOcsSettings(
    mailboxInfo: MailboxInfo,
    version: OcsVersion,
    scope: OcsSettingScope,
    patches: OcsPatchEntry[],
    options?: OcsRequestOptions
): Promise<any> {
    const requestHeaders = {
        'x-outlook-client': 'owa',
        Accept: 'application/json',
        ...options?.additionalHeaders,
    };

    const ocsBaseUrl = makeOcsBaseUrl(version, scope);
    const returnFullResponse = options?.returnFullResponse ?? false;
    const throwOnServiceError = true;

    return makePatchRequest(
        ocsBaseUrl,
        patches,
        options?.correlationId,
        returnFullResponse,
        requestHeaders,
        throwOnServiceError,
        undefined,
        'writeOcsSettings',
        mailboxInfo
    );
}
