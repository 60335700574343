import { format } from 'owa-localize';
import type { MailboxInfo } from 'owa-client-types';
import { makePatchRequest } from 'owa-ows-gateway';

const UNSUBSCRIBE_URL: string = 'ows/api/beta/subscriptions/{0}/unsubscribe';

function unsubscribeFromBrandSubscriptionService(
    smtpIdentifier: string,
    unsubscribeSilentUris: string[],
    mailboxInfo: MailboxInfo
): Promise<Response> {
    return makePatchRequest(
        format(UNSUBSCRIBE_URL, smtpIdentifier) /* requestURL */,
        {
            UnsubscribeSilentUris: unsubscribeSilentUris /* requestObject */,
            ShouldReturnFailedUris: true,
        },
        undefined /* correlationId */,
        true /* returnFullResponse */,
        undefined /* customHeaders */,
        undefined /* throwServiceError */,
        undefined /* includeCredentials */,
        'unsubscribeFromBrandSubscription' /* actionName */,
        mailboxInfo
    );
}

export default unsubscribeFromBrandSubscriptionService;
