import type { DataCollector } from 'diagnostics-data-collection';
import { lazyRegisterDataCollectorIfNotRegistered } from 'diagnostics-data-collection';
import { getItem, removeItem } from 'owa-local-storage';
import {
    ACCOUNTS_MIGRATION_UNALLOWED_PROVIDER_HINTS_KEY,
    ACCOUNTS_MIGRATION_PENDING_ACCOUNTS_POST_IMPORT_KEY,
    ACCOUNTS_MIGRATION_SUCCESFULLY_IMPORTED_ACCOUNT_KEY,
    ACCOUNTS_MIGRATION_TOGGLED_ACCOUNTS_KEY,
    ACCOUNTS_MIGRATION_UNALLOWED_ACCOUNT_TYPES_KEY,
} from 'owa-account-pending-store/lib/utils/constants';
import { accountMigrationDiagnosticDataDescription } from './registerMigrationDataCollector.locstring.json';
import loc from 'owa-localize';

export default async function registerDataCollectors() {
    const accountConfigDataCollector: DataCollector = {
        dataCollectionAction: accountMigrationDataCollectionAction,
        name: 'AccountMigrationData',
        odsFileDescription: 'Account migration data',
        description: loc(accountMigrationDiagnosticDataDescription),
    };

    await lazyRegisterDataCollectorIfNotRegistered.importAndExecute(accountConfigDataCollector);
}

function accountMigrationDataCollectionAction(): Promise<string> {
    const toggledAccounts = JSON.parse(
        getItem(window, ACCOUNTS_MIGRATION_TOGGLED_ACCOUNTS_KEY) ?? '{}'
    );
    const accountSuccesfullyImported = JSON.parse(
        getItem(window, ACCOUNTS_MIGRATION_SUCCESFULLY_IMPORTED_ACCOUNT_KEY) ?? '{}'
    );
    const accountsNotImportedDueToAccountType = JSON.parse(
        getItem(window, ACCOUNTS_MIGRATION_UNALLOWED_ACCOUNT_TYPES_KEY) ?? '{}'
    );
    const accountsNotImportedDueToMailboxHint = JSON.parse(
        getItem(window, ACCOUNTS_MIGRATION_UNALLOWED_PROVIDER_HINTS_KEY) ?? '{}'
    );
    const pendingAccountsAfterImport = JSON.parse(
        getItem(window, ACCOUNTS_MIGRATION_PENDING_ACCOUNTS_POST_IMPORT_KEY) ?? '{}'
    );
    const result = {
        toggledAccounts,
        accountSuccesfullyImported,
        pendingAccountsAfterImport,
        accountsNotImportedDueToAccountType,
        accountsNotImportedDueToMailboxHint,
    };

    removeItem(window, ACCOUNTS_MIGRATION_TOGGLED_ACCOUNTS_KEY);
    removeItem(window, ACCOUNTS_MIGRATION_SUCCESFULLY_IMPORTED_ACCOUNT_KEY);
    removeItem(window, ACCOUNTS_MIGRATION_UNALLOWED_PROVIDER_HINTS_KEY);
    removeItem(window, ACCOUNTS_MIGRATION_PENDING_ACCOUNTS_POST_IMPORT_KEY);
    removeItem(window, ACCOUNTS_MIGRATION_UNALLOWED_ACCOUNT_TYPES_KEY);

    return Promise.resolve(JSON.stringify(result));
}
