import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getOcpsPolicyFromLocalStore } from './getOcpsPolicyFromLocalStore';
import type { OcpsPolicy } from './OcpsPolicy';
import type { MailboxInfo } from 'owa-client-types';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { isOcpsStoreReady } from 'owa-ocps-get-policies';

export default function isOcpsPolicyEnabled(
    policy: OcpsPolicy,
    enabledIfUndefined?: boolean,
    mailboxInfo?: MailboxInfo
): boolean {
    if (!isFeatureEnabled('ocps-policy-check', mailboxInfo) || isConsumer(undefined, mailboxInfo)) {
        return true;
    }

    if (!mailboxInfo) {
        mailboxInfo = getModuleContextMailboxInfo();
    }

    const policyState = getOcpsPolicyFromLocalStore(mailboxInfo, policy);
    if (policyState) {
        return policyState === '1';
    }

    return (isOcpsStoreReady() && enabledIfUndefined) ?? false;
}
