import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { isTeamsFeatureEnabled } from 'owa-teams-policies/lib/selectors/isTeamsFeatureEnabled';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';
import { isBootFeatureEnabled } from 'owa-metatags';
import { getApp } from 'owa-config';
import { TeamsPoliciesScenario } from 'owa-teams-policies-lazy/lib/store/schema';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 */
export const presenceCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return isTeamsFeatureEnabled(TeamsPoliciesScenario.Presence);
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        return (
            (isFeatureEnabled('mon-rp-presence') || isPlacesAppWithPlacesAppId()) &&
            !isConsumer(undefined, mailboxInfo) &&
            isServiceRequestSupportedForMailbox(mailboxInfo)
        );
    },
};

function isPlacesAppWithPlacesAppId() {
    return isBootFeatureEnabled('auth-msaljs-places') && getApp() === 'Places';
}
