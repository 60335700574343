import getStore from '../store/appHostStore';

export default () => ({
    appId: getStore().hostedApp?.id,
    appDefinition: getStore().hostedApp,
    entityId: getStore().selectedEntityId,
    subEntityId: getStore().selectedSubEntityId,
    subEntityLabel: getStore().subEntityLabel,
    userClickTime: getStore().userClickTime,
});
