import type { AttachmentPolicyInfo } from './attachmentPolicyChecker';
import type AttachmentPolicyChecker from './attachmentPolicyChecker';
import { AttachmentPolicyLevel } from './attachmentPolicyChecker';
import { getAccountScopeUserSettings } from 'owa-session-store';

export default function getIsSaveToCloudSupported(
    isCloudyAttachmentOrLink: boolean,
    isReadOnly: boolean,
    isSMIMEItem: boolean,
    isProtectedVoiceMail: boolean,
    attachmentPolicyInfo: AttachmentPolicyInfo,
    attachmentPolicyChecker: AttachmentPolicyChecker
): boolean {
    if (isSMIMEItem || isProtectedVoiceMail) {
        return false;
    }

    return (
        attachmentPolicyInfo.level !== AttachmentPolicyLevel.Block &&
        attachmentPolicyChecker.saveAttachmentsToCloudEnabled &&
        !isCloudyAttachmentOrLink &&
        isReadOnly &&
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * Adding IsShadowMailbox to restricted properties/methods.
         *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
        !getAccountScopeUserSettings(attachmentPolicyChecker.mailboxInfo)?.SessionSettings
            ?.IsShadowMailbox
    );
}
