export function shouldShowBigHoverAction(
    action: string,
    canDelete: boolean,
    isInArchiveFolder: boolean,
    isInOutboxFolder: boolean
) {
    switch (action) {
        case 'Delete':
            return canDelete;
        case 'Archive':
            // Don't show archive big hover action in Archive and Outbox folders
            return !isInArchiveFolder && !isInOutboxFolder;
    }
    return null;
}
