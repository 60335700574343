import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { RibbonControlId } from 'owa-ribbon-ids';

/**
 * Used in Version #20 in `mailRibbonVersionManager.ts`.
 * We've received lots of reports dating far back of controls popping up in view tab.
 * The exact reason as to the cause of this is unknown, however we'll start by versioning these controls out.
 * Suspect that for most users, this will be a no-op.
 */
export const removeExtraViewTabControls: {
    tabVariableName: string;
    controlId: RibbonControlId;
}[] = [
    580, 587, 683, 684, 519, 505, 657, 572, 540, 658, 559, 678, 553, 552, 527, 548, 571, 509, 508,
    550, 556, 557, 555, 616, 529, 663,
].map(controlId => {
    return {
        tabVariableName: 'viewTab',
        controlId,
    };
});
