import type MailboxRequestOptions from 'owa-service/lib/MailboxRequestOptions';
import type { MailboxType } from 'owa-client-types';
import {
    getConnectedAccountHeaders,
    getConnectedAccountHeadersForMailboxInfo,
} from './getConnectedAccountHeaders';
import { isFeatureEnabled } from 'owa-feature-flags';
import { setHeaders, getRequestOptionsOrDefault } from 'owa-headers-core';
import type { RequestOptions, HeadersWithoutIterator } from 'owa-service/lib/RequestOptions';
import { errorThatWillCauseAlert } from 'owa-trace';
import { isMonarchMultipleAccountsEnabled } from 'owa-accounts-store';
import { isSharedCoprincipalAccountEnabled } from 'owa-anchormailbox/lib/isSharedCoprincipalAccountEnabled';
import { isMailboxSharedOrDelegateUserMailbox } from 'owa-anchormailbox/lib/isMailboxSharedOrDelegateUserMailbox';

/**
NOTE: Please do not use this function for any of the newer scenarios e.g. OneMailView,
OWA Auth team has put a new flow in place for all scenarios needing
Connected Accounts support. Please reach out to Auth team for more information
*/
export async function getConnectedAccountRequestOptions(
    mailboxRequestOptions: MailboxRequestOptions,
    preserveOriginalHeaders?: boolean
): Promise<RequestOptions> {
    const defaultRequestOptions = getRequestOptionsOrDefault(mailboxRequestOptions);
    defaultRequestOptions.headers = await getHeadersForConnectedAccount(
        mailboxRequestOptions,
        preserveOriginalHeaders
    );
    defaultRequestOptions.authNeededOnUnAuthorized = false;
    defaultRequestOptions.datapoint = defaultRequestOptions.datapoint || {};
    if (isMonarchMultipleAccountsEnabled() || isFeatureEnabled('acct-connected-tokens')) {
        defaultRequestOptions.mailboxInfo = mailboxRequestOptions?.mailboxInfo;
        defaultRequestOptions.datapoint.mailbox = 'Connected'; // TODO: Resolve this. VSO#127698
    } else {
        defaultRequestOptions.datapoint.mailbox = 'Connected';
    }
    return defaultRequestOptions;
}

async function getHeadersForConnectedAccount(
    mailboxRequestOptions: MailboxRequestOptions,
    preserveOriginalHeaders?: boolean
): Promise<HeadersWithoutIterator> {
    const headers =
        preserveOriginalHeaders && mailboxRequestOptions.headers?.set
            ? new Headers(<Headers>mailboxRequestOptions.headers)
            : new Headers();

    const useMailboxInfo =
        isMonarchMultipleAccountsEnabled() &&
        (isFeatureEnabled('acct-multiacctancmbx') || isSharedCoprincipalAccountEnabled());

    const isSharedCoprincipalAccount: boolean =
        useMailboxInfo &&
        isSharedCoprincipalAccountEnabled() &&
        isMailboxSharedOrDelegateUserMailbox(mailboxRequestOptions.mailboxInfo);

    const addExplicitLogonUser: boolean =
        isSharedTypeMailbox(
            mailboxRequestOptions.mailboxInfo.type,
            mailboxRequestOptions.mailboxInfo.mailboxSmtpAddress
        ) || isSharedCoprincipalAccount;

    const connectedAccountHeaders = useMailboxInfo
        ? getConnectedAccountHeadersForMailboxInfo(
              mailboxRequestOptions.mailboxInfo,
              addExplicitLogonUser,
              isSharedCoprincipalAccount
          )
        : await getConnectedAccountHeaders(
              mailboxRequestOptions.mailboxInfo.userIdentity,
              addExplicitLogonUser
                  ? mailboxRequestOptions.mailboxInfo.mailboxSmtpAddress
                  : undefined
          );
    setHeaders(headers, connectedAccountHeaders);

    return headers;
}

export function isSharedTypeMailbox(mailboxType: MailboxType, mailboxSmtpAddress: string): boolean {
    const isSharedTypeMailboxValue =
        mailboxType == 'GroupMailbox' ||
        mailboxType == 'TeamsMailbox' ||
        mailboxType == 'SharedMailbox' ||
        mailboxType == 'PublicMailbox';
    if (isSharedTypeMailboxValue && mailboxSmtpAddress == null) {
        errorThatWillCauseAlert(
            'getHeadersForConnectedAccount: mailboxSmtpAddress should be passed for Group or Teams or Shared or Public Mbx request'
        );
    }

    return isSharedTypeMailboxValue;
}
