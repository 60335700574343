import flexPaneOpened from '../actions/flexPaneOpened';
import getO365ShellShim from '../../utils/getO365ShellShim';
import { orchestrator } from 'satcheljs';

let isFlexPaneCallbackDefined: boolean = false;
const FLUENT_LAYER_HOST_ID = 'fluent-default-layer-host';
const FLEX_PANE_ID = 'FlexPane_Overflow_Container';

//TODO: WI 152412 Remove DOM manipulation logic once Layers in FlexPane are inserted in the Fluent layer host
/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(flexPaneOpened, () => {
    const shim = getO365ShellShim();
    if (shim && !isFlexPaneCallbackDefined) {
        shim.FlexPane.OnFlexPaneVisibilityChanged(isVisible => {
            if (isVisible) {
                const fluentLayerHost = document.getElementById(FLUENT_LAYER_HOST_ID);
                const flexPane = document.getElementById(FLEX_PANE_ID);
                if (fluentLayerHost && flexPane) {
                    document.body.insertBefore(flexPane, fluentLayerHost);
                }
            }
        });
        isFlexPaneCallbackDefined = true;
    }
});
