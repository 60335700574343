import { observer } from 'owa-mobx-react';
import { selectAllMessagesCommand } from 'owa-locstrings/lib/strings/selectallmessagescommand.locstring.json';
import loc from 'owa-localize';
import { FluentCheckbox } from 'owa-fluent-controls';
import { toggleSelectAllRows } from 'owa-mail-actions/lib/mailListSelectionActions';
import { resetFocus } from 'owa-mail-focus-manager';
import {
    getIsEverythingSelectedInTable,
    getTableHasCheckedItems,
    getStore as getListViewStore,
} from 'owa-mail-list-store';
import React from 'react';
import {
    getIsBitSet,
    type ListViewBitFlagsMasks,
} from 'owa-bit-flags/lib/utils/listViewBitFlagsUtil';
import { isSingleLineListView } from 'owa-mail-layout';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

import {
    full,
    medium,
    compact,
    mailListSecondRowLeftEntity,
    showCheckbox,
    listViewHasItems,
    pivotOffset,
    searchPivotOffset,
    checkBox,
    checkBoxSingleLine,
    checkBoxThreeColumn,
} from './MailListHeader.scss';

import classnames from 'owa-classnames';

export interface SelectAllCheckboxProps {
    tableViewId: string;
    isInboxWithPivots: boolean;
    isSearchRender?: boolean;
}

export default observer(function SelectAllCheckbox(
    props: SelectAllCheckboxProps
): JSX.Element | null {
    const { tableViewId, isInboxWithPivots = false } = props;
    const tableView = getListViewStore().tableViews.get(tableViewId);
    const isEverythingSelected = useComputedValue((): boolean => {
        return !!tableView && getIsEverythingSelectedInTable(tableView);
    }, [tableViewId]);
    const tableHasCheckedItems = useComputedValue((): boolean => {
        return !!tableView && getTableHasCheckedItems(tableView);
    }, [tableViewId]);
    const toggleSelectAll = React.useCallback(() => {
        if (!tableView || tableView.rowKeys.length === 0) {
            return;
        }
        toggleSelectAllRows(tableView);
        resetFocus('MailListToggleSelectAll');
    }, [tableViewId]);

    const onCheckKeyDown = React.useCallback(
        (evt: React.KeyboardEvent<HTMLDivElement>) => {
            if (evt.key === ' ' || evt.key === 'Enter') {
                toggleSelectAll();
                evt.preventDefault();
            }
        },
        [tableViewId]
    );

    const squareCheckboxInputProps = React.useMemo(
        () => ({
            tabIndex: 0,
            onKeyDown: onCheckKeyDown,
        }),
        [onCheckKeyDown]
    );

    if (!tableView) {
        return null;
    }

    const densityModeString = getDensityModeCssClass(full, medium, compact);
    const isListViewEmpty = tableView.rowKeys.length == 0;
    const isInCheckedMode = tableView.isInCheckedMode;
    const isSingleLineView = isSingleLineListView();
    const isSenderImageOff = getIsBitSet(8, getGlobalSettingsAccountMailboxInfo());
    const leftEntityClassNames = classnames(
        mailListSecondRowLeftEntity,
        isInCheckedMode && showCheckbox,
        !isListViewEmpty && listViewHasItems,
        isInboxWithPivots ? pivotOffset : searchPivotOffset,
        densityModeString
    );
    const checkBoxClassNames = classnames(
        checkBox,
        isSingleLineView ? checkBoxSingleLine : checkBoxThreeColumn
    );
    const checkBoxLabel = loc(selectAllMessagesCommand);

    const isTableInMultiSelectionMode =
        tableView.isInCheckedMode ||
        (isSingleLineView ? tableView.isInSelectAllStateSLV : tableView.isInSelectAllState);

    if (isListViewEmpty) {
        return null;
    }

    return (
        <FluentCheckbox
            className={classnames(leftEntityClassNames, checkBoxClassNames)}
            ariaLabel={checkBoxLabel}
            checked={isEverythingSelected}
            indeterminate={tableHasCheckedItems && !isEverythingSelected}
            onChange={toggleSelectAll}
            inputProps={squareCheckboxInputProps}
            shouldOverrideStyleInMCL={
                isSenderImageOff && isSingleLineView && !isTableInMultiSelectionMode
            }
        />
    );
},
'SelectAllCheckbox');
