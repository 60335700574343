import { getApplicationSettings } from 'owa-application-settings';
import {
    DesktopNotificationSetting,
    EventReminderDisplaySetting,
    MessageNotificationSetting,
} from '../utils/Constants';
import { getMonarchDesktopNotificationSettings } from './getMonarchDesktopNotificationSettings';
import getToggleStateForNotificationSettings from './getToggleStateForNotificationSettings';

export function isNewEmailDesktopNotificationEnabled(): boolean {
    const settings = getMonarchDesktopNotificationSettings();
    return (
        !!settings?.get(DesktopNotificationSetting.DesktopSettingEnabled) &&
        !!settings?.get(DesktopNotificationSetting.NewMailNotificationSettingEnabled)
    );
}

export function isNewEmailDesktopNotificationInboxEnabled(): boolean {
    const settings = getMonarchDesktopNotificationSettings();
    const desktopNotificationOption = settings?.get(
        DesktopNotificationSetting.NewMailNotificationOption
    );
    return desktopNotificationOption === MessageNotificationSetting.Inbox;
}

export function isNewEmailDesktopNotificationFavoritesOnlyEnabled(): boolean {
    const settings = getMonarchDesktopNotificationSettings();
    const desktopNotificationOption = settings?.get(
        DesktopNotificationSetting.NewMailNotificationOption
    );
    return desktopNotificationOption === MessageNotificationSetting.Favorite;
}

export function isRemindersPopupEnabled(): boolean {
    // Reminders popup is enabled when in Monarch, calendar reminders are enabled,
    // and reminderDisplayOption is set to Popup, or not set if the user migrated from Win32.
    if (
        getApplicationSettings('MonarchNotifications').enabled &&
        isReminderNotificationsEnabled()
    ) {
        const settings = getMonarchDesktopNotificationSettings();
        const reminderDisplayOption = settings?.get(
            DesktopNotificationSetting.EventReminderDisplayOption
        );
        return (
            reminderDisplayOption === EventReminderDisplaySetting.Popup ||
            (reminderDisplayOption === undefined && getToggleStateForNotificationSettings())
        );
    }
    return false;
}

// Returns true when "Desktop Notifications, For Events" is enabled in the legacy notifications settings UI
// or when the calendar notifications style is not set to "None" in the new UI
export function isReminderNotificationsEnabled() {
    const settings = getMonarchDesktopNotificationSettings();
    return (
        !!settings?.get(DesktopNotificationSetting.DesktopSettingEnabled) &&
        !!settings?.get(DesktopNotificationSetting.EventReminderNotificationSettingEnabled)
    );
}

export function isReminderDesktopNotificationEnabled(): boolean {
    const settings = getMonarchDesktopNotificationSettings();
    const reminderDisplayOption = settings?.get(
        DesktopNotificationSetting.EventReminderDisplayOption
    );

    // Reminders desktop notifications are enabled when the "Desktop Notifications, For Events" is enabled in the
    // legacy notifications settings UI and the reminders popup flight is off or the Desktop option is selected
    // instead of Reminders in the new UI
    return (
        !isRemindersPopupEnabled() &&
        isReminderNotificationsEnabled() &&
        (reminderDisplayOption === undefined ||
            reminderDisplayOption === EventReminderDisplaySetting.Desktop)
    );
}

export function isAutomaticallyDismissPastDueRemindersEnabled(): boolean {
    const settings = getMonarchDesktopNotificationSettings();
    return (
        isRemindersPopupEnabled() &&
        !!settings?.get(DesktopNotificationSetting.EventAutomaticallyDismissPastDueRemindersEnabled)
    );
}

export function isNativePushNotificationSettingEnabled() {
    const settings = getMonarchDesktopNotificationSettings();
    const toggleFromWin32 = getToggleStateForNotificationSettings();
    return (
        !!settings?.get(DesktopNotificationSetting.DesktopSettingEnabled) &&
        !!(
            settings?.get(DesktopNotificationSetting.NativePushNotificationSettingEnabled) ??
            !toggleFromWin32
        )
    );
}
