import { observer } from 'owa-mobx-react';

import React from 'react';
import { AdsPanel } from 'owa-mail-ads';
import { areDisplayAdsShown } from 'owa-mail-ads-checker';

import { adsContainer, hide, bottom } from './AdsPanelStub.scss';

export interface AdsPanelStubProps {
    isBottom: boolean;
    /**
     * Whether to hide the AdsPanel.
     * When temporarily hiding the AdsPanel, this is preferred to unmounting and remounting
     * due to needing to reinitialize and fetch ads whenever we have a different DOM Element
     */
    isHidden?: boolean;
}

export default observer(function AdsPanelStub(props: AdsPanelStubProps) {
    const adContainerClasses = [adsContainer];
    if (!areDisplayAdsShown() || props.isHidden) {
        adContainerClasses.push(hide);
    } else if (props.isBottom) {
        adContainerClasses.push(bottom);
    }
    return (
        <div className={adContainerClasses.join(' ')}>
            {areDisplayAdsShown() && <AdsPanel isBottom={props.isBottom} />}
        </div>
    );
}, 'AdsPanelStub');
