import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ReadingPaneOptions" */ './lazyIndex')
);

export const lazySaveReadingPaneOption = new LazyAction(
    lazyModule,
    m => m.saveReadingPaneOptionQuick
);
export { saveReadingPanePositionOption } from './actions/saveReadingPanePositionOption';
